export enum DateTypes {
    LAST_30_DAYS = "Last 30 days",
    THIS_MONTH = "This Month",
    LAST_MONTH = "Last Month",
    THIS_YEAR = "This year",
    ALL_TIME = "All time",
    CUSTOM = "Custom Range",
    LAST_7_DAYS = "Last 7 days",
    TODAY = "Today",
}

export type DateObj = {
    begin: Date | null | string;
    end: Date | null | string;
};

export type DateType = {
    name: DateTypes;
    date: DateObj;
};
