import React, { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import SignaturePad from "react-signature-canvas";
import { getBlobFromCanvas } from "../../../../../../utils/blob";

interface Props<T> {
    active: boolean;
    uploadSignatureLoading: boolean | null;
    uploadSignatureResponse: T | null;

    toggler: () => void;
    handleUploadSignature: (data: Blob) => void;
}

function OnscreenSignatureModal<T>(props: Props<T>): JSX.Element {
    const divRef = useRef<HTMLDivElement | null>(null);
    const signCanvas = useRef<SignaturePad | null>(null);

    const [width, setWidth] = useState<number>(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (props.uploadSignatureResponse) {
            signCanvas.current?.clear();
            setIsButtonDisabled(true);
        }
    }, [props.uploadSignatureResponse, signCanvas.current]);

    useEffect(() => {
        if (divRef.current?.getBoundingClientRect().width) {
            setWidth(divRef.current.getBoundingClientRect().width);
        }
    }, [divRef.current?.getBoundingClientRect().width]);

    const handleClearSignature = useCallback(() => {
        signCanvas.current?.clear();
        setIsButtonDisabled(true);
    }, [signCanvas.current]);

    const handleUploadSignature = useCallback(() => {
        if (!signCanvas.current || signCanvas.current.isEmpty() || isButtonDisabled) return;
        props.handleUploadSignature(getBlobFromCanvas(signCanvas.current.getTrimmedCanvas()));
    }, [signCanvas.current, isButtonDisabled]);

    return (
        <div>
            <Modal size="sm" active={props.active} toggler={props.toggler}>
                <ModalHeader onClose={props.toggler}>Sign On-Screen</ModalHeader>
                <ModalBody>
                    <div
                        className="h-80 w-full border-0.2 bg-white"
                        ref={divRef}
                        onTouchStart={() => setIsButtonDisabled(false)}
                        onMouseDown={() => setIsButtonDisabled(false)}
                    >
                        <SignaturePad
                            ref={signCanvas}
                            penColor="blue"
                            canvasProps={{
                                width: width,
                                height: 320,
                                className: "w-full",
                            }}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex w-full flex-col space-x-0 space-y-4 2xs:w-max 2xs:flex-row 2xs:space-x-4 2xs:space-y-0">
                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                            <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" fullWidth func={handleClearSignature}>
                                <span>Clear</span>
                            </ButtonComp>
                        </div>
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <ButtonComp
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                isLoading={props.uploadSignatureLoading}
                                fullWidth
                                disable={isButtonDisabled}
                                func={handleUploadSignature}
                            >
                                <span>Upload Signature</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default OnscreenSignatureModal;
