import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AccountSignatoriesResponse } from "../../modules/dashboard/applicationn/services/nigeria-application/registered/accountSignatories.types";
import Agreement from "../../models/account-opening/agreement/agreement";
import { ApplicationBusinessType } from "../../modules/dashboard/applicationn/services/application.constants";
import { BusinessAgreementResponse } from "../../modules/dashboard/applicationn/services/nigeria-application/registered/new/agreement.types";
import BusinessDetails from "../../models/account-opening/businessDetails";
import BusinessDetailsMin from "../../models/account-opening/businessDetailsMin";
import { BusinessDocumentResponse } from "../../modules/dashboard/applicationn/services/nigeria-application/registered/document.types";
import { BusinessInformationResponse } from "../../modules/dashboard/applicationn/services/nigeria-application/registered/company.types";
import { BusinessRefereeResponse } from "../../modules/dashboard/applicationn/services/nigeria-application/registered/referee.types";
import CreatedBy from "../../models/account-opening/createdBy";
import IncorporationDocumentNigeria from "../../models/account-opening/incorporation-documents/incorporationDocumentNigeria";
import { NewAccountOpeningInitState } from "../../modules/dashboard/applicationn/services/application.types";
import { NewCurrentSignatoryResponse } from "../../modules/dashboard/applicationn/services/nigeria-application/registered/currentSignatory.types";
import { NigeriaBusinessAccountManagerResponse } from "../../modules/dashboard/applicationn/services/nigeria-application/nigeria-application.types";
import OtherDirectorMin from "../../models/account-opening/otherDirectorMin";
import Referee from "../../models/account-opening/referee";
import SignatoryInfo from "../../models/account-opening/signatoryInfo";
import SignatoryMin from "../../models/account-opening/signatoryInfoMin";
import UserMin from "../../models/userMin";
import UtilityBill from "../../models/account-opening/utilityBill";

interface ApplicationState {
    init: NewAccountOpeningInitState | null;
    // applicationId: string | null;
    applicationBusinessType: ApplicationBusinessType;
    accountManager: {
        photo: string;
        name: string;
        email: string;
        phone: string;
    } | null;
    businessDetails: BusinessDetails | null;
    currentSignatory: SignatoryInfo | null;
    accountSignatories: {
        signatories: SignatoryMin[];
        otherDirectors: OtherDirectorMin[];
    } | null;
    businessDocuments: {
        applicationMin: BusinessDetailsMin;
        incorporationDocuments: IncorporationDocumentNigeria[];
        utilityBill: UtilityBill | null;
    } | null;
    agreement: {
        agreements: Agreement;
        applicationMin: BusinessDetailsMin;
        canHaveBoardResolution: boolean;
        createdBy: CreatedBy;
        directors: SignatoryMin[];
        currentUser: UserMin;
        signatories: SignatoryMin[];
    } | null;
    referees: Referee[] | null;
    isDirectorOrSignatory: boolean;
    clearAllFilter: boolean;
}

const initialState: ApplicationState = {
    init: null,
    // applicationId: null,
    accountManager: null,
    applicationBusinessType: ApplicationBusinessType.INITIAL,
    businessDetails: null,
    currentSignatory: null,
    accountSignatories: null,
    businessDocuments: null,
    agreement: null,
    referees: null,
    isDirectorOrSignatory: false,
    clearAllFilter: false,
};

export const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {
        setApplicationBusinessType: (state: ApplicationState, action: PayloadAction<ApplicationBusinessType>) => {
            state.applicationBusinessType = action.payload;
        },

        setAccountOpeningInit: (state: ApplicationState, action: PayloadAction<NewAccountOpeningInitState>) => {
            state.init = action.payload;
            // state.applicationId = action.payload.applicationMin.id;
        },

        // setApplicationId: (state: ApplicationState, action: PayloadAction<string>) => {
        //     state.applicationId = action.payload;
        // },
        setNigeriaAccountManager: (state: ApplicationState, action: PayloadAction<NigeriaBusinessAccountManagerResponse>) => {
            state.accountManager = action.payload;
        },

        updateCurrentSignatory: (state: ApplicationState, action: PayloadAction<NewCurrentSignatoryResponse>) => {
            state.currentSignatory = action.payload.signatory;
            if (state.init) {
                state.init.progress = action.payload.progress;
            }
        },
        resetCurrentSignatory: (state: ApplicationState) => {
            state.currentSignatory = null;
        },

        setBusinessDetails: (state: ApplicationState, action: PayloadAction<BusinessInformationResponse>) => {
            state.businessDetails = action.payload.application;
            if (state.init) {
                state.init.progress = action.payload.progress;
            }
        },
        updateBusinessDetails: (state: ApplicationState, action: PayloadAction<BusinessDetailsMin>) => {
            if (state.init) {
                state.init.applicationMin = action.payload;
            }
        },
        resetBusinessDetails: (state: ApplicationState) => {
            state.businessDetails = null;
        },
        setAccountSignatories: (state: ApplicationState, action: PayloadAction<AccountSignatoriesResponse>) => {
            state.accountSignatories = {
                signatories: action.payload.signatories,
                otherDirectors: action.payload.otherDirectors,
            };
            if (state.init) {
                state.init.progress = action.payload.progress;
            }
        },
        resetAccountSignatories: (state: ApplicationState) => {
            state.accountSignatories = null;
        },
        setBusinessDocuments: (state: ApplicationState, action: PayloadAction<BusinessDocumentResponse>) => {
            state.businessDocuments = {
                applicationMin: action.payload.applicationMin,
                incorporationDocuments: action.payload.incorporationDocuments,
                utilityBill: action.payload.utilityBill,
            };
            if (state.init) {
                state.init.progress = action.payload.progress;
                state.init.applicationMin = action.payload.applicationMin;
            }
        },
        resetBusinessDocuments: (state: ApplicationState) => {
            state.businessDocuments = null;
        },
        setAgreement: (state: ApplicationState, action: PayloadAction<BusinessAgreementResponse>) => {
            state.agreement = {
                agreements: action.payload.agreements,
                applicationMin: action.payload.applicationMin,
                canHaveBoardResolution: action.payload.canHaveBoardResolution,
                createdBy: action.payload.createdBy,
                directors: action.payload.directors,
                currentUser: action.payload.currentUser,
                signatories: action.payload.signatories,
            };
            if (state.init) {
                state.init.progress = action.payload.progress;
                state.init.createdBy = action.payload.createdBy;
                state.init.applicationMin = action.payload.applicationMin;
            }
        },
        resetAgreement: (state: ApplicationState) => {
            state.agreement = null;
        },
        setReferees: (state: ApplicationState, action: PayloadAction<BusinessRefereeResponse>) => {
            state.referees = action.payload.referees.filter((_ref) => !_ref.isRevoked);
            if (state.init) {
                state.init.progress = action.payload.progress;
            }
        },
        resetReferees: (state: ApplicationState) => {
            state.referees = null;
        },

        setIsDirectorOrSignatoryTrue: (state: ApplicationState) => {
            state.isDirectorOrSignatory = true;
        },
        setIsDirectorOrSignatoryFalse: (state: ApplicationState) => {
            state.isDirectorOrSignatory = false;
        },

        setClearAllFilter: (state: ApplicationState, action: PayloadAction<boolean>) => {
            state.clearAllFilter = action.payload;
        },

        resetAllApplicationState: (state: ApplicationState) => {
            state.applicationBusinessType = ApplicationBusinessType.INITIAL;
            state.accountManager = null;
            // state.applicationId = null;
            state.businessDetails = null;
            state.currentSignatory = null;
            state.accountSignatories = null;
            state.businessDocuments = null;
            state.agreement = null;
            state.referees = null;
            state.init = null;
            state.isDirectorOrSignatory = false;
            state.clearAllFilter = false;
        },
    },
});

export const {
    setAccountOpeningInit,
    setNigeriaAccountManager,

    updateCurrentSignatory,
    resetCurrentSignatory,

    setBusinessDetails,
    resetBusinessDetails,
    updateBusinessDetails,
    setAccountSignatories,
    resetAccountSignatories,

    setBusinessDocuments,
    resetBusinessDocuments,

    setAgreement,
    resetAgreement,

    setReferees,
    resetReferees,

    setIsDirectorOrSignatoryTrue,
    setIsDirectorOrSignatoryFalse,
    setApplicationBusinessType,

    resetAllApplicationState,
    setClearAllFilter,
} = applicationSlice.actions;

export default applicationSlice.reducer;
