import * as Yup from "yup";

import { Form, Formik } from "formik";
import { UserRole, UserRoleText, UserRolesForSignatories } from "../../../../../../../../../../../models/userAccount.constants";

import ButtonComp from "../../../../../../../../../../../components/button/ButtonComp";
import { EditSignatoryRequest } from "../../../../../../../../services/zambia-application/registered/new/accountSignatories.types";
import FormInput from "../../../../../../../../../../../components/inputs/FormInput";
import OtherDirectorMin from "../../../../../../../../../../../models/account-opening/otherDirectorMin";
import React from "react";
import SingleSelectDropdown from "../../../../../../../../../../../components/dropdown/single-select/single-select";
import ZambiaSignatoryMin from "../../../../../../../../../../../models/account-opening/zambiaSignatoryInfoMin";
import formikHasError from "../../../../../../../../../../../helpers/formikHasError";
import useDimension from "../../../../../../../../../../../hooks/useDimension";
import useEditSignatory from "../../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useEditSignatory";

interface Props {
    data: ZambiaSignatoryMin | OtherDirectorMin | null;
    handleCloseEditSignatoryForm: () => void;
}

function EditSignatory(props: Props): JSX.Element {
    const { width } = useDimension();
    const { isEditSignatoryLoading, handleEditSignatory, handleEditOtherDirector } = useEditSignatory(props.handleCloseEditSignatoryForm);

    const isSignatory = props.data instanceof ZambiaSignatoryMin;

    const EditSignatoryInitialState: EditSignatoryRequest = {
        id: props.data?.id || "",
        firstName: props.data?.firstName || "",
        surname: props.data?.surname || "",
        email: props.data?.email || "",
        userRole: props.data instanceof ZambiaSignatoryMin ? props.data?.userRole || undefined : UserRole.VIEWER,
    };

    const EditSignatoryFormValidation = Yup.object().shape({
        id: Yup.string().required("Required"),
        firstName: Yup.string().required("Required"),
        surname: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        userRole: Yup.number().required("Required"),
    });

    return (
        <>
            <Formik
                initialValues={{
                    ...EditSignatoryInitialState,
                }}
                validationSchema={EditSignatoryFormValidation}
                onSubmit={(values) => {
                    isSignatory
                        ? void handleEditSignatory(values)
                        : void handleEditOtherDirector({
                              id: values.id,
                              email: values.email,
                              surname: values.surname,
                              firstName: values.firstName,
                          });
                }}
                enableReinitialize
                validateOnChange
            >
                {(formik) => {
                    return (
                        <Form className="flex w-full flex-col items-start justify-start space-y-6 pb-8">
                            <div className="flex w-full flex-row items-center justify-between text-black-secondary">
                                <h4 className="font-medium text-black-secondary">Edit Signatory</h4>
                                <div className="max-w-4 cursor-pointer text-black-secondary" onClick={props.handleCloseEditSignatoryForm}>
                                    <span className="material-symbols-rounded text-xl">close</span>
                                </div>
                            </div>
                            <div className="flex w-full flex-col items-start space-y-4 text-base font-normal lg:items-center lg:px-0">
                                <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                    <div className="w-full lg:w-50%">
                                        <FormInput
                                            type="text"
                                            placeholder="First Name"
                                            name="firstName"
                                            value={formik.values.firstName}
                                            autoFocus={width > 475}
                                        />
                                    </div>
                                    <div className="w-full lg:w-50%">
                                        <FormInput type="text" placeholder="Surname" name="surname" value={formik.values.surname} />
                                    </div>
                                </div>
                                <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
                                    <div className="w-full">
                                        <FormInput type="text" placeholder="Email" name="email" value={formik.values.email} autoFocus={width < 476} />
                                    </div>
                                    {isSignatory && (
                                        <div className="w-full">
                                            <SingleSelectDropdown
                                                placeholder="Role"
                                                options={UserRolesForSignatories.map((el) => {
                                                    return {
                                                        text: UserRoleText[el],
                                                        value: el,
                                                    };
                                                })}
                                                onChange={(value) => {
                                                    if (value) {
                                                        void formik.getFieldHelpers("userRole").setValue(value);
                                                    }
                                                }}
                                                value={formik.values.userRole}
                                                defaultValue={formik.values.userRole}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                    <ButtonComp
                                        type="button"
                                        color="grey"
                                        ripple="light"
                                        buttonType="secondary"
                                        size="md"
                                        fullWidth
                                        func={props.handleCloseEditSignatoryForm}
                                    >
                                        <span>Cancel</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                    <ButtonComp
                                        type="submit"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        size="md"
                                        isLoading={isEditSignatoryLoading}
                                        fullWidth
                                        disable={formikHasError(formik.errors)}
                                    >
                                        <span>Apply changes</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default EditSignatory;
