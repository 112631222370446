import { useDispatch, useSelector } from "react-redux";

import ActivateAccount from "../../../../Accounts/Components/Modals/ActivateAccountModal";
import { IRootState } from "../../../../../../redux/rootReducer";
import PendingApprovalModal from "../../../../payments-new/componentss/modals/PendingApprovalModal";
import PreviewModal from "../../../../applicationn/components/modals/preview-modal";
import TagTransactionRecipient from "../../Modal/TagTransactionRecipient";
import { abortTransactionCardCalls } from "./Services/transactionDetailApi";
import { setTransactionData } from "../../../../../../redux/accounts/slice/accountsSlice";
import { useEffect } from "react";
import useTransactionDetailsAccountingReceipt from "./Hooks/useTransactionDetailsAccountingReceipt";
import useTransactionDetailsActivateAccount from "./Hooks/useTransactionDetailsActivateAccount";
import useTransactionDetailsCard from "./Hooks/useTransactionDetailsCard";
import useTransactionDetailsPendingApproval from "./Hooks/useTransactionDetailsPendingApproval";
import TransactionDetailsTray from "../../Tray/TransactionDetailsTray";

function TransactionDetailCard(): JSX.Element {
    const {
        domNode,
        transaction,
        canShowTransaction,
        showTagRecipientModal,
        canApproveTransaction,
        setShowTagRecipientModal,
        handleCloseTransactionDetailCard,
    } = useTransactionDetailsCard();

    const {
        isBigger,
        receiptBlob,
        isUploading: isUploadingReceipt,
        isPreviewDownloading,
        isPreviewModalOpen,
        handleClosePreviewModal,
        handleUploadAccountingReceipt,
        handlePreviewAccountingReceipt,
    } = useTransactionDetailsAccountingReceipt();

    // Activate Account Hook
    const { accountToActivate, setAccountToActivate, showActivateAccountModal, setShowActivateAccountModal, handleAccountActivationCompleted } =
        useTransactionDetailsActivateAccount();

    // Pending Transaction Hook
    const {
        showPendingApprovalModal,
        handleTransactionApproved,
        handleTransactionDeclined,
        handleTogglePendingApprovalShowModal,
        handleClosePendingApprovalShowModal,
    } = useTransactionDetailsPendingApproval();

    const globalActiveDataType = useSelector((state: IRootState) => state.init.globalActiveDataType);

    useEffect(() => {
        if (transaction) {
            dispatch(setTransactionData(transaction));
        }
    }, [transaction]);

    useEffect(() => {
        if (!canShowTransaction) {
            handleClosePendingApprovalShowModal();
            setShowTagRecipientModal(false);
            handleClosePreviewModal();
            setShowActivateAccountModal(false);
            abortTransactionCardCalls();
        }
    }, [canShowTransaction]);

    const dispatch = useDispatch();

    return (
        <>
            <div className="fixed z-70" ref={domNode} data-type={globalActiveDataType || "transaction"}>
                {transaction && (
                    <>
                        <PendingApprovalModal
                            active={showPendingApprovalModal}
                            data={transaction}
                            toggler={handleClosePendingApprovalShowModal}
                            onCompleteFunc={handleTransactionApproved}
                        />
                        <ActivateAccount
                            data={accountToActivate}
                            active={showActivateAccountModal}
                            toggler={() => setShowActivateAccountModal(false)}
                            onCompleteFunc={handleAccountActivationCompleted}
                        />
                        <PreviewModal
                            active={isPreviewModalOpen}
                            data={receiptBlob}
                            toggler={handleClosePreviewModal}
                            title="Transaction Attachment"
                        />
                        <TagTransactionRecipient
                            showModal={showTagRecipientModal}
                            toggler={() => setShowTagRecipientModal(false)}
                            transaction={transaction}
                        />
                    </>
                )}

                <TransactionDetailsTray
                    transaction={transaction}
                    canShowTransaction={canShowTransaction}
                    canApproveTransaction={canApproveTransaction}
                    setShowTagRecipientModal={setShowTagRecipientModal}
                    handleCloseTransactionDetailCard={handleCloseTransactionDetailCard}
                    isBigger={isBigger}
                    isUploadingReceipt={isUploadingReceipt}
                    isPreviewDownloading={isPreviewDownloading}
                    handleUploadAccountingReceipt={handleUploadAccountingReceipt}
                    handlePreviewAccountingReceipt={handlePreviewAccountingReceipt}
                    setAccountToActivate={setAccountToActivate}
                    setShowActivateAccountModal={setShowActivateAccountModal}
                    handleTransactionDeclined={handleTransactionDeclined}
                    handleTogglePendingApprovalShowModal={handleTogglePendingApprovalShowModal}
                    globalActiveDataType={globalActiveDataType}
                />
            </div>
        </>
    );
}

export default TransactionDetailCard;
