import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import TypeEntity from "./typeEntity";
import { immerable } from "immer";

export default class UtilityBill {
    [immerable] = true;

    constructor(
        public type: TypeEntity | null,
        public uploadId: string | null
    ) {}

    static create(obj: GenericObject): UtilityBill {
        return new UtilityBill(Parsers.classObject(obj.type, TypeEntity), Parsers.nullableString(obj.uploadId));
    }
}
