import { refereeReferenceForm, verifyReferenceForm } from "../../servicess/referenceFormApi";
import {
    refereeReferenceFormData,
    refereeReferenceFormDataResponse,
    referenceFormVerifyData,
    referenceFormVerifyDataResponse,
} from "../../servicess/referenceFormApi.types";
import { useCallback, useState } from "react";

import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface useReferenceFormStateData {
    referenceFormError: string | null;
    referenceFormResponse: refereeReferenceFormDataResponse | null;
    isReferenceFormLoading: boolean;
    verifyReferenceInviteError: string | null;
    verifyReferenceInviteResponse: referenceFormVerifyDataResponse | null;
    isVerifyReferenceInviteLoading: boolean;

    handleVerifyInviteReferee: (_data: referenceFormVerifyData) => Promise<void>;
    handleSubmitReferenceForm: (_data: refereeReferenceFormData) => Promise<void>;
}

const useReferenceFormState = (): useReferenceFormStateData => {
    const dispatch = useDispatch();
    const [verifyReferenceInviteError, setVerifyReferenceInviteError] = useState<string | null>(null);
    const [verifyReferenceInviteResponse, setVerifyReferenceInviteResponse] = useState<referenceFormVerifyDataResponse | null>(null);
    const [isVerifyReferenceInviteLoading, setIsVerifyReferenceInviteLoading] = useState<boolean>(false);

    const [referenceFormError, setReferenceFormError] = useState<string | null>(null);
    const [referenceFormResponse, setReferenceFormResponse] = useState<refereeReferenceFormDataResponse | null>(null);
    const [isReferenceFormLoading, setIsReferenceFormLoading] = useState<boolean>(false);

    const handleVerifyInviteReferee = useCallback(
        async (_data: referenceFormVerifyData) => {
            try {
                setVerifyReferenceInviteError(null);
                setIsVerifyReferenceInviteLoading(true);
                const res = await verifyReferenceForm(_data);
                setVerifyReferenceInviteResponse(res);
                // dispatch(messageTrue({message: "Signature uploaded successfully"}));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setVerifyReferenceInviteError(errorMessage);
            } finally {
                setIsVerifyReferenceInviteLoading(false);
            }
        },
        [dispatch]
    );

    const handleSubmitReferenceForm = useCallback(
        async (_data: refereeReferenceFormData) => {
            try {
                setReferenceFormError(null);
                setIsReferenceFormLoading(true);
                const res = await refereeReferenceForm(_data);
                setReferenceFormResponse(res);
                // dispatch(messageTrue({message: "Signature uploaded successfully"}));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setReferenceFormError(errorMessage);
            } finally {
                setIsReferenceFormLoading(false);
            }
        },
        [dispatch]
    );

    return {
        referenceFormError,
        referenceFormResponse,
        isReferenceFormLoading,
        verifyReferenceInviteError,
        verifyReferenceInviteResponse,
        isVerifyReferenceInviteLoading,

        handleVerifyInviteReferee,
        handleSubmitReferenceForm,
    };
};

export default useReferenceFormState;
