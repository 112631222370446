import React from "react";
import PosTransaction from "../../../../../models/posTransaction";
import { BasicStatusWithNone } from "../../../../../models/terminal.constants";

interface Props {
    terminal: PosTransaction | null;
}

export default function TerminalSettlementStatusTag({ terminal }: Props) {
    return (
        <div>
            {terminal?.settlement?.status === BasicStatusWithNone.FAILED && (
                <span className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error">
                    Failed
                </span>
            )}
            {terminal?.settlement?.status === BasicStatusWithNone.SUCCESSFUL && (
                <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                    Settled
                </span>
            )}
            {terminal?.settlement?.status === BasicStatusWithNone.PENDING && (
                <span className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning">
                    Pending
                </span>
            )}
            {terminal?.settlement?.status === BasicStatusWithNone.NONE && (
                <span className="bg-black-backdrop h-6 rounded border border-black-quin px-3 py-1 text-xs font-medium capitalize leading-none text-black">
                    None
                </span>
            )}
        </div>
    );
}
