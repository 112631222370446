import React, { useCallback, useState } from "react";

import AddTeamMemberToAccountsModal from "../team-members/add-account/AddTeamMemberToAccountsModal";
import GoogleIcon from "../../../../../components/google-icon";
import NewDropdownHead from "../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import RemoveTeamMemberModal from "../Modals/RemoveTeamMemberModal";
import { TeamMemberItem } from "../../Services/team-members/team-members.types";
import UserAccountMeta from "../../../../../models/userAccountMeta";
import useReinviteTeamMember from "../../Services/team-members/hooks/useReinviteTeamMember";
import useRemoveTeamMember from "../../Services/team-members/hooks/useRemoveTeamMember";

interface TeamMemberDetailsActionProps {
    teamMember: TeamMemberItem;

    onRemove: (id: string) => void;
    handleAddNewAccounts: (item: TeamMemberItem, _accounts: UserAccountMeta[]) => void;
}

function TeamMemberDetailsAction({ teamMember: data, onRemove: onRemoveFunc, handleAddNewAccounts }: TeamMemberDetailsActionProps): JSX.Element {
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
    const [isAddTeamMemberToAccountsOpen, setIsAddTeamMemberToAccountsOpen] = useState<boolean>(false);

    const onRemove = useCallback((id: string) => {
        onRemoveFunc(id);
        setIsRemoveModalOpen(false);
    }, []);
    const onAdd = useCallback((_item: TeamMemberItem, _updatedUserAccountMeta: UserAccountMeta[]) => {
        handleAddNewAccounts(_item, _updatedUserAccountMeta);
        setIsRemoveModalOpen(false);
    }, []);

    const { isRemoving, handleRemoveTeamMember } = useRemoveTeamMember(onRemove);

    // const { isReinviteTeamMember, handleReinviteTeamMember } = useReinviteTeamMember();
    const { handleReinviteTeamMember } = useReinviteTeamMember();

    return (
        <>
            <RemoveTeamMemberModal
                active={isRemoveModalOpen}
                handleRemoveTeamMember={() => void handleRemoveTeamMember(data.teamMember.id)}
                backFunc={() => setIsRemoveModalOpen(false)}
                teamMember={data.teamMember}
                isRemoving={isRemoving}
            />
            <AddTeamMemberToAccountsModal
                item={data}
                active={isAddTeamMemberToAccountsOpen}
                onClose={() => setIsAddTeamMemberToAccountsOpen(false)}
                onComplete={onAdd}
            />
            {data.teamMember.isInvitePending ? (
                <NewDropdownHead
                    size="sm"
                    color="grey"
                    buttonType="flat"
                    paddingSize="2xs"
                    dropdownSize="md"
                    dropdownPosition="right"
                    dropdownWidth="max"
                    borderSmall
                    fullWidth
                    isNoArrow
                    content={
                        <div className="flex items-center justify-start space-x-1">
                            <GoogleIcon icon="more_horiz" size="xs" isOutlined />
                        </div>
                    }
                >
                    <NewDropdownItem size="lg" onClick={() => setIsAddTeamMemberToAccountsOpen(true)}>
                        <div className="flex items-center justify-start px-1">
                            <GoogleIcon icon="add_circle" size="md" isOutlined />
                            <span className="ml-2 inline items-center justify-center text-sm text-current">Add Accounts</span>
                        </div>
                    </NewDropdownItem>
                    <NewDropdownItem size="lg" onClick={() => void handleReinviteTeamMember(data.teamMember.id)}>
                        <div className="flex items-center justify-start px-1">
                            <GoogleIcon icon="restart_alt" size="md" isOutlined />
                            <span className="ml-2 inline items-center justify-center text-sm text-current">Resend Invitation</span>
                        </div>
                    </NewDropdownItem>
                    <NewDropdownItem size="lg" color="red" onClick={() => setIsRemoveModalOpen(true)} className="bg-error-backdrop">
                        <div className="flex items-center justify-start px-1">
                            <GoogleIcon icon="delete" size="md" className="text-error" isOutlined />
                            <span className="ml-2 inline items-center justify-center text-sm text-current text-error">Remove from Team</span>
                        </div>
                    </NewDropdownItem>
                </NewDropdownHead>
            ) : (
                <NewDropdownHead
                    size="sm"
                    color="grey"
                    buttonType="flat"
                    paddingSize="2xs"
                    dropdownPosition="right"
                    dropdownWidth="max"
                    borderSmall
                    fullWidth
                    isNoArrow
                    content={
                        <div className="flex items-center justify-start space-x-1">
                            <GoogleIcon icon="more_horiz" size="xs" isOutlined />
                        </div>
                    }
                >
                    <NewDropdownItem size="lg" onClick={() => setIsAddTeamMemberToAccountsOpen(true)}>
                        <div className="flex items-center justify-start px-1">
                            <GoogleIcon icon="add_circle" size="md" isOutlined />
                            <span className="ml-2 inline items-center justify-center text-sm text-current">Add Accounts</span>
                        </div>
                    </NewDropdownItem>
                    <NewDropdownItem size="lg" color="red" onClick={() => setIsRemoveModalOpen(true)} className="bg-error-backdrop">
                        <div className="flex items-center justify-start px-1">
                            <GoogleIcon icon="delete" size="md" className="text-error" isOutlined />
                            <span className="ml-2 inline items-center justify-center text-sm text-current text-error">Remove from Team</span>
                        </div>
                    </NewDropdownItem>
                </NewDropdownHead>
            )}
        </>
    );
}

export default TeamMemberDetailsAction;
