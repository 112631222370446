import { BulkTransferSections } from "../../../hooks/state/send-money/payments.constants";
import PaymentHeader from "../../Layouts/payment/components/page-header";
import { useAppSelector } from "../../../../../../redux/hooks";

interface Props {
    header?: string;
    subtitle?: string;
}

function BulkPaymentHeaders(props: Props): JSX.Element {
    const paymentStage = useAppSelector((state) => state.sendMoney.paymentStage);
    return (
        <PaymentHeader
            header={props.header || BulkTransferSections[paymentStage].text}
            subtitle={props.subtitle || BulkTransferSections[paymentStage].subtext}
        />
    );
}

export default BulkPaymentHeaders;
