import { HintType, ListStyle, hintMarkItem } from "./hint.constant";
import { useCallback, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import { ReactComponent as CameraIcon } from "../../../../../../assets/svg/camera.svg";
// import ButtonComp from "../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../services/nigeria-application/registered/company.types";
import GoogleIcon from "../../../../../../components/google-icon";
import { ReactComponent as GuideIcon } from "../../../../../../assets/svg/dashboard/application/bulb.svg";
import { ReactComponent as InfoIcon } from "../../../../../../assets/svg/info.svg";
import MessageToast from "../../../../../../components/message-toast";

type FirstProps = {
    size?: "sm" | "md" | "full" | "fit";
    hintType: HintType;
    listStyle: ListStyle;
    firstList: CompanyHint[] | hintMarkItem[];
    infoText?: string;
    fullWidth?: boolean;
};
type SecondProps = {
    secondHint?: boolean;
    secondHintType?: HintType;
    secondListStyle?: ListStyle;
    secondList?: CompanyHint[] | hintMarkItem[];
};
type ThirdProps = {
    thirdHint?: boolean;
    thirdHintType?: HintType;
    thirdListStyle?: ListStyle;
    thirdList?: CompanyHint[] | hintMarkItem[];
};

type Props = FirstProps & SecondProps & ThirdProps;

function Hint(props: Props): JSX.Element {
    const [isHintOpen, setIsHintOpen] = useState(false);

    const handleCloseHint = useCallback(() => {
        setIsHintOpen(false);
    }, []);

    const handleToggleHint = useCallback(() => {
        setIsHintOpen((prev) => !prev);
    }, []);

    return (
        <>
            <div
                className={
                    "fixed left-0 top-0 z-30 flex w-full items-start justify-center lg:relative lg:h-full" +
                    ` ${props.size === "sm" ? "w-full max-w-18" : props.size === "md" ? "w-full max-w-[352px]" : props.size === "fit" ? "w-fit" : props.size === "full" ? "w-full" : "w-full max-w-[352px]"}`
                }
            >
                <div className="hidden w-full flex-col space-y-4 rounded-lg bg-blue-backdrop bg-white p-5 lg:flex">
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-row items-center justify-start space-x-2">
                            <div className="h-5 w-5">
                                {props.hintType === HintType.TIP && <CameraIcon className="h-5 w-5" />}
                                {props.hintType === HintType.INFO && <InfoIcon className="h-5 w-5" />}
                                {props.hintType === HintType.GUIDE && <GuideIcon className="h-5 w-5 stroke-current text-blue" />}
                            </div>
                            <span className="font-medium text-black">
                                {props.hintType === HintType.TIP ? "Tips" : ""}
                                {props.hintType === HintType.GUIDE ? "Guide" : ""}
                                {props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
                            </span>
                        </div>
                        {/* <div>{props.children && props.children}</div> */}
                        {props.listStyle === ListStyle.BULLET && props.hintType !== HintType.TOAST_INFO && (
                            <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                {(props.firstList as CompanyHint[]).map((_el, index) => (
                                    <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                        {_el.span}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {props.listStyle === ListStyle.BULLET && props.hintType === HintType.TOAST_INFO && (
                            <MessageToast
                                message={
                                    <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                        {(props.firstList as CompanyHint[]).map((_el, index) => (
                                            <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                {_el.span}
                                            </li>
                                        ))}
                                    </ul>
                                }
                                type="info"
                                noIcon
                                fullWidth
                            />
                        )}
                        {props.listStyle === ListStyle.PLAIN && (
                            <div className="flex flex-col items-start justify-start space-y-0">
                                {(props.firstList as CompanyHint[]).map((_el, index) => (
                                    <div className="text-sm text-black-secondary" key={index}>
                                        {_el.span}
                                    </div>
                                ))}
                            </div>
                        )}
                        {props.listStyle === ListStyle.CHECK && (
                            <div className="flex flex-col items-start justify-start space-y-0">
                                {(props.firstList as hintMarkItem[]).map((_el, index) => (
                                    <div className="flex flex-row items-center justify-start space-x-2" key={index}>
                                        <div
                                            className={
                                                `flex max-w-[20px] items-center justify-center overflow-hidden ` +
                                                `${_el.isChecked ? "text-blue" : "text-black-quat"}`
                                            }
                                        >
                                            <span className="material-symbols-rounded text-2xl leading-[100%]">check</span>
                                        </div>
                                        <div className={`text-sm capitalize ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
                                            {_el.text}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {props.secondHint && (
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-row items-start justify-start space-x-2">
                                <div className="h-4 w-4">
                                    {props.secondHintType === HintType.TIP && <CameraIcon />}
                                    {props.hintType === HintType.INFO && <InfoIcon />}
                                    {props.secondHintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
                                </div>
                                <span className="font-medium text-black">
                                    {props.secondHintType === HintType.TIP ? "Tips" : ""}
                                    {props.secondHintType === HintType.GUIDE ? "Guide" : ""}
                                    {props.secondHintType === HintType.INFO ? props.infoText || "Info" : ""}
                                </span>
                            </div>
                            {props.secondListStyle === ListStyle.BULLET && props.secondHintType !== HintType.TOAST_INFO && (
                                <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                    {(props.secondList as CompanyHint[]).map((_el, index) => (
                                        <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                            {_el.span}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {props.secondListStyle === ListStyle.BULLET && props.secondHintType === HintType.TOAST_INFO && (
                                <MessageToast
                                    message={
                                        <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                            {(props.secondList as CompanyHint[]).map((_el, index) => (
                                                <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                    {_el.span}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                    type="info"
                                    noIcon
                                    fullWidth
                                />
                            )}

                            {props.secondListStyle === ListStyle.PLAIN && (
                                <div className="flex flex-col items-start justify-start space-y-0">
                                    {(props.secondList as CompanyHint[]).map((_el, index) => (
                                        <div className="text-sm text-black-secondary" key={index}>
                                            {_el.span}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {props.secondListStyle === ListStyle.CHECK && (
                                <div className="flex flex-col items-start justify-start space-y-0">
                                    {(props.secondList as hintMarkItem[]).map((_el, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-2" key={index}>
                                            <div
                                                className={
                                                    `flex max-w-[20px] items-center justify-center overflow-hidden ` +
                                                    `${_el.isChecked ? "text-blue" : "text-black-quat"}`
                                                }
                                            >
                                                <span className="material-symbols-rounded text-2xl leading-[100%]">check</span>
                                            </div>
                                            <div className={`text-sm capitalize ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
                                                {_el.text}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {props.thirdHint && (
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-row items-start justify-start space-x-2">
                                <div className="h-4 w-4">
                                    {/* {props.thirdHintType === HintType.TIP && <CameraIcon />} */}
                                    {/* {props.hintType === HintType.INFO && <InfoIcon />} */}
                                    {props.thirdHintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
                                </div>
                                <span className="font-medium text-black">
                                    {props.thirdHintType === HintType.TIP ? "Tips" : ""}
                                    {props.thirdHintType === HintType.GUIDE ? "Guide" : ""}
                                    {props.thirdHintType === HintType.INFO ? props.infoText || "Info" : ""}
                                </span>
                            </div>
                            {/* <div>{props.children && props.children}</div> */}
                            {props.thirdListStyle === ListStyle.BULLET && props.thirdHintType !== HintType.TOAST_INFO && (
                                <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                    {(props.thirdList as CompanyHint[]).map((_el, index) => (
                                        <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                            {_el.span}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {props.thirdListStyle === ListStyle.BULLET && props.thirdHintType === HintType.TOAST_INFO && (
                                <MessageToast
                                    message={
                                        <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                            {(props.thirdList as CompanyHint[]).map((_el, index) => (
                                                <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                    {_el.span}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                    type="info"
                                    noIcon
                                    fullWidth
                                />
                            )}

                            {props.thirdListStyle === ListStyle.PLAIN && (
                                <div className="flex flex-col items-start justify-start space-y-0">
                                    {(props.thirdList as CompanyHint[]).map((_el, index) => (
                                        <div className="text-sm text-black-secondary" key={index}>
                                            {_el.span}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {props.thirdListStyle === ListStyle.CHECK && (
                                <div className="flex flex-col items-start justify-start space-y-0">
                                    {(props.thirdList as hintMarkItem[]).map((_el, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-2" key={index}>
                                            <div
                                                className={
                                                    `flex max-w-[20px] items-center justify-center overflow-hidden ` +
                                                    `${_el.isChecked ? "text-blue" : "text-black-quat"}`
                                                }
                                            >
                                                <span className="material-symbols-rounded text-2xl leading-[100%]">check</span>
                                            </div>
                                            <div className={`text-sm capitalize ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
                                                {_el.text}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* ======= For mobile view ======== */}
                <div
                    className={
                        `fixed inset-0 z-80 mx-2 grid place-items-center overflow-y-auto overflow-x-hidden px-2 outline-none transition-all duration-150 focus:outline-none lg:pointer-events-none lg:opacity-0 ` +
                        `${isHintOpen ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"} `
                    }
                    onClick={handleCloseHint}
                >
                    <div className="flex w-full max-w-sm flex-col space-y-4 rounded-lg bg-blue-backdrop px-5 py-8">
                        <div className="flex flex-col space-y-4" style={{ backgroundColor: "#FAFBFF" }}>
                            <div className="flex flex-row items-start justify-start space-x-2">
                                <div className="h-4 w-4">
                                    {/* {props.hintType === HintType.TIP && <CameraIcon />} */}
                                    {/* {props.hintType === HintType.INFO && <InfoIcon />} */}
                                    {props.hintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
                                </div>
                                <span className="text-base font-medium text-black">
                                    {props.hintType === HintType.TIP ? "Tips" : ""}
                                    {props.hintType === HintType.GUIDE ? "Guide" : ""}
                                    {props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
                                </span>
                            </div>
                            {/* <div>{props.children && props.children}</div> */}
                            {props.listStyle === ListStyle.BULLET && props.hintType !== HintType.TOAST_INFO && (
                                <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                    {(props.firstList as CompanyHint[]).map((_el, index) => (
                                        <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                            {_el.span}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {props.listStyle === ListStyle.BULLET && props.hintType === HintType.TOAST_INFO && (
                                <MessageToast
                                    message={
                                        <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                            {(props.firstList as CompanyHint[]).map((_el, index) => (
                                                <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                    {_el.span}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                    type="info"
                                    noIcon
                                    fullWidth
                                />
                            )}
                            {props.listStyle === ListStyle.PLAIN && (
                                <div className="flex flex-col items-start justify-start space-y-0">
                                    {(props.firstList as CompanyHint[]).map((_el, index) => (
                                        <div className="text-sm text-black-secondary" key={index}>
                                            {_el.span}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {props.listStyle === ListStyle.CHECK && (
                                <div className="flex flex-col items-start justify-start space-y-0">
                                    {(props.firstList as hintMarkItem[]).map((_el, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-2" key={index}>
                                            <div
                                                className={
                                                    `flex max-w-[20px] items-center justify-center overflow-hidden ` +
                                                    `${_el.isChecked ? "text-blue" : "text-black-quat"}`
                                                }
                                            >
                                                <span className="material-symbols-rounded text-2xl leading-[100%]">check</span>
                                            </div>
                                            <div className={`text-sm capitalize ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
                                                {_el.text}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {props.secondHint && (
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-row items-start justify-start space-x-2">
                                    <div className="h-4 w-4">
                                        {/* {props.hintType === HintType.TIP && <CameraIcon />} */}
                                        {/* {props.hintType === HintType.INFO && <InfoIcon />} */}
                                        {props.hintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
                                    </div>
                                    <span className="font-medium text-black">
                                        {props.hintType === HintType.TIP ? "Tips" : ""}
                                        {props.hintType === HintType.GUIDE ? "Guide" : ""}
                                        {props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
                                    </span>
                                </div>
                                {props.secondListStyle === ListStyle.BULLET && props.secondHintType !== HintType.TOAST_INFO && (
                                    <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                        {(props.secondList as CompanyHint[]).map((_el, index) => (
                                            <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                {_el.span}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {props.secondListStyle === ListStyle.BULLET && props.secondHintType === HintType.TOAST_INFO && (
                                    <MessageToast
                                        message={
                                            <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                                {(props.secondList as CompanyHint[]).map((_el, index) => (
                                                    <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                        {_el.span}
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                        type="info"
                                        noIcon
                                        fullWidth
                                    />
                                )}
                                {props.secondListStyle === ListStyle.PLAIN && (
                                    <div className="flex flex-col items-start justify-start space-y-0">
                                        {(props.secondList as CompanyHint[]).map((_el, index) => (
                                            <div className="text-sm text-black-secondary" key={index}>
                                                {_el.span}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {props.secondListStyle === ListStyle.CHECK && (
                                    <div className="flex flex-col items-start justify-start space-y-0">
                                        {(props.secondList as hintMarkItem[]).map((_el, index) => (
                                            <div className="flex flex-row items-center justify-start space-x-2" key={index}>
                                                <div
                                                    className={
                                                        `flex max-w-[20px] items-center justify-center overflow-hidden ` +
                                                        `${_el.isChecked ? "text-blue" : "text-black-quat"}`
                                                    }
                                                >
                                                    <span className="material-symbols-rounded text-2xl leading-[100%]">check</span>
                                                </div>
                                                <div
                                                    className={
                                                        `text-sm capitalize ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`
                                                    }
                                                >
                                                    {_el.text}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        {props.thirdHint && (
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-row items-start justify-start space-x-2">
                                    <div className="h-4 w-4">
                                        {/* {props.hintType === HintType.TIP && <CameraIcon />} */}
                                        {/* {props.hintType === HintType.INFO && <InfoIcon />} */}
                                        {props.hintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
                                    </div>
                                    <span className="font-medium text-black">
                                        {props.hintType === HintType.TIP ? "Tips" : ""}
                                        {props.hintType === HintType.GUIDE ? "Guide" : ""}
                                        {props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
                                    </span>
                                </div>
                                {/* <div>{props.children && props.children}</div> */}
                                {props.thirdListStyle === ListStyle.BULLET && props.thirdHintType !== HintType.TOAST_INFO && (
                                    <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                        {(props.thirdList as CompanyHint[]).map((_el, index) => (
                                            <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                {_el.span}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {props.thirdListStyle === ListStyle.BULLET && props.thirdHintType === HintType.TOAST_INFO && (
                                    <MessageToast
                                        message={
                                            <ul className="ml-5 flex list-disc flex-col items-start justify-start space-y-3">
                                                {(props.thirdList as CompanyHint[]).map((_el, index) => (
                                                    <li className="text-2xs text-black-secondary [&>span]:text-sm" key={index}>
                                                        {_el.span}
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                        type="info"
                                        noIcon
                                        fullWidth
                                    />
                                )}
                                {props.thirdListStyle === ListStyle.PLAIN && (
                                    <div className="flex flex-col items-start justify-start space-y-0">
                                        {(props.thirdList as CompanyHint[]).map((_el, index) => (
                                            <div className="text-sm text-black-secondary" key={index}>
                                                {_el.span}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {props.thirdListStyle === ListStyle.CHECK && (
                                    <div className="flex flex-col items-start justify-start space-y-0">
                                        {(props.thirdList as hintMarkItem[]).map((_el, index) => (
                                            <div className="flex flex-row items-center justify-start space-x-2" key={index}>
                                                <div
                                                    className={
                                                        `flex max-w-[20px] items-center justify-center overflow-hidden ` +
                                                        `${_el.isChecked ? "text-blue" : "text-black-quat"}`
                                                    }
                                                >
                                                    <span className="material-symbols-rounded text-2xl leading-[100%]">check</span>
                                                </div>
                                                <div
                                                    className={
                                                        `text-sm capitalize ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`
                                                    }
                                                >
                                                    {_el.text}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="fixed bottom-16 right-8 z-80 lg:hidden lg:w-36">
                    <ButtonComp ripple="dark" color="blue" type="button" buttonType="tertiary" data-type="section" func={handleToggleHint} fullWidth>
                        <div className="mx-1 mb-2 flex items-center justify-center rounded-3xl bg-blue-senary p-4 text-blue shadow-md">
                            {!isHintOpen && (
                                <>
                                    <GuideIcon className="h-5 w-5 stroke-current outline-none" tabIndex={-1} />
                                    <div className="ml-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm" tabIndex={-1}>
                                        Guide
                                    </div>
                                </>
                            )}
                            {isHintOpen && (
                                <>
                                    <div className="flex items-center justify-center">
                                        <GoogleIcon icon="close" />
                                    </div>
                                    <div
                                        className="ml-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm"
                                        data-type="section"
                                        tabIndex={-1}
                                    >
                                        Close
                                    </div>
                                </>
                            )}
                        </div>
                    </ButtonComp>
                </div>
                <div
                    className={
                        `fixed inset-0 z-70 bg-black transition-all duration-150 lg:pointer-events-none lg:opacity-0 ` +
                        `${isHintOpen ? "pointer-events-auto opacity-25" : "pointer-events-none opacity-0"} `
                    }
                    onClick={handleCloseHint}
                    // data-type={props.dataType && props.dataType}
                ></div>
            </div>
        </>
    );
}

export default Hint;
