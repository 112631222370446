import React from "react";
import { SelectableNotificationChannels } from "../../../../../../../models/userAccount.constants";
import TeamMemberAddNotificationChannel from "./TeamMemberAddNotificationChannel";
import TeamMemberNotificationChannelItem from "./TeamMemberNotificationChannelItem";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";

interface TeamMemberNotificationChannelsProps {
    accountMeta: UserAccountMeta;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    canUpdate: boolean;
}

function TeamMemberNotificationChannels({ accountMeta, updateAccountMeta, canUpdate }: TeamMemberNotificationChannelsProps): JSX.Element {
    return (
        <div className="flex flex-wrap">
            {accountMeta.notificationChannels.map((channel) => (
                <div key={channel} className="mb-2 mr-2">
                    <TeamMemberNotificationChannelItem
                        accountMeta={accountMeta}
                        channel={channel}
                        updateAccountMeta={updateAccountMeta}
                        canRemove={canUpdate && SelectableNotificationChannels.includes(channel)}
                    />
                </div>
            ))}
            {canUpdate && <TeamMemberAddNotificationChannel accountMeta={accountMeta} updateAccountMeta={updateAccountMeta} />}
        </div>
    );
}

export default TeamMemberNotificationChannels;
