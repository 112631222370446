import { FeeType } from "../../redux/init/slice/initSlice.types";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class ZambiaLencoFeeData {
    [immerable] = true;

    constructor(
        public feeType: FeeType | null,
        public value: number,
        public percentageCap: number | null
    ) {}

    static create(obj: GenericObject): ZambiaLencoFeeData {
        return new ZambiaLencoFeeData(
            Parsers.nullableEnum(obj.feeType, FeeType),
            Parsers.number(obj.value),
            Parsers.nullableNumber(obj.percentageCap)
        );
    }

    get isFlatFee(): boolean {
        return this.feeType === FeeType.FLAT_FEE;
    }
    get isPercentageFee(): boolean {
        return this.feeType === FeeType.PERCENTAGE;
    }
    get hasPercentageCap(): boolean {
        return !!this.percentageCap || !!(this.percentageCap && this.percentageCap > 0);
    }
}
