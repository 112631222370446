import {
    AccountSignatoriesResponse,
    AddSignatoryRequest,
    EditOtherDirectorRequest,
    EditSignatoryRequest,
    RemoveOtherDirectorRequest,
    SetSignatoryUserRoleRequest,
    SignatoryActionRequest,
} from "./accountSignatories.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../helpers/request/abortControllers";

import { GenericObject } from "../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import OtherDirectorMin from "../../../../../../models/account-opening/otherDirectorMin";
import Parsers from "../../../../../../utils/parsers";
import SignatoryMin from "../../../../../../models/account-opening/signatoryInfoMin";
import { makeRequestWithSignal } from "../../../../../../helpers/request/makeRequest";

export enum AccountSignatoriesRequests {
    INIT = "account-signatories.ini",
    ADD_SIGNATORY = "account-signatories.add-signatory",
    EDIT_SIGNATORY = "account-signatories.edit-signatory",
    REMOVE_SIGNATORY = "account-signatories.remove-signatory",
    EDIT_OTHER_DIRECTOR = "account-signatories.edit-other-director",
    REMOVE_OTHER_DIRECTOR = "account-signatories.remove-other-director",
    SET_SIGNATORY_AS_DIRECTOR = "account-signatories.set-signatory-as-director",
    REMOVE_SIGNATORY_AS_DIRECTOR = "account-signatories.remove-signatory-as-director",
    SET_SIGNATORY_AS_KEY_CONTACT = "account-signatories.set-signatory-as-key-contact",
    SET_SIGNATORY_USER_ROLE = "account-signatories.set-signatory-user-role",
}

function getAccountSignatoriesResponse(res: GenericObject | Error): AccountSignatoriesResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        signatories: Parsers.classObjectArray(res.signatories, SignatoryMin),
        otherDirectors: Parsers.classObjectArray(res.otherDirectors, OtherDirectorMin),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
    };
}

export const accountSignatories = async (): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/details", {}, signal);
    return getAccountSignatoriesResponse(res);
};

export const addAccountSignatory = async (_data: AddSignatoryRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.ADD_SIGNATORY);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/add", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const editAccountSignatory = async (_data: EditSignatoryRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.EDIT_SIGNATORY);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/edit", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const removeSignatory = async (_data: SignatoryActionRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.REMOVE_SIGNATORY);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/remove", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const setSignatoryAsDirector = async (_data: SignatoryActionRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.SET_SIGNATORY_AS_DIRECTOR);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/set-as-director", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const removeSignatoryAsDirector = async (_data: SignatoryActionRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.REMOVE_SIGNATORY_AS_DIRECTOR);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/remove-as-director", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const setSignatoryAsKeyContact = async (_data: SignatoryActionRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.SET_SIGNATORY_AS_KEY_CONTACT);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/set-key-contact", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const setSignatoryUserRole = async (_data: SetSignatoryUserRoleRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.SET_SIGNATORY_USER_ROLE);
    const res = await makeRequestWithSignal("/account-opening/ng/signatory/set-user-role", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const editOtherDirector = async (_data: EditOtherDirectorRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.EDIT_OTHER_DIRECTOR);
    const res = await makeRequestWithSignal("/account-opening/ng/other-director/edit", _data, signal);
    return getAccountSignatoriesResponse(res);
};
export const removeOtherDirector = async (_data: RemoveOtherDirectorRequest): Promise<AccountSignatoriesResponse> => {
    const signal = getAbortControllerSignal(AccountSignatoriesRequests.REMOVE_OTHER_DIRECTOR);
    const res = await makeRequestWithSignal("/account-opening/ng/other-director/remove", _data, signal);
    return getAccountSignatoriesResponse(res);
};

export function abortAccountSignatoriesRequest(type: AccountSignatoriesRequests): void {
    abortRequest(type);
}

export function abortAllAccountSignatoriesRequests(): void {
    Object.values(AccountSignatoriesRequests).forEach((type) => abortRequest(type));
}
