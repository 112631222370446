import ButtonComp from "../../../../../components/button/ButtonComp";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import useTransactionDetailsCardHeadSender from "../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsCardHeadSender";
import Transaction from "../../../../../models/transaction";
import useTransactionDetailsCard from "../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsCard";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../../redux/rootReducer";
import { MouseEvent, memo, useEffect, useState } from "react";
import TextArea from "../../../../../components/text-area";
// import FormInput from "../../../../../components/inputs/FormInput";

interface Props {
    active: boolean;
    toggler: () => void;
}

function EditTransactionAliasModal({ active, toggler }: Props): JSX.Element {
    const { transaction } = useTransactionDetailsCard();

    const transactionData = useSelector((state: IRootState) => state.account.transactionData);

    const [inputValue, setInputValue] = useState(transaction?.origination?.alias || transaction?.description);

    const { isSubmitting, submitForm } = useTransactionDetailsCardHeadSender(transaction as Transaction);

    useEffect(() => {
        setInputValue(transaction?.origination?.alias || transaction?.description);
    }, [active]);

    const handleEditAlias = (event: MouseEvent<Element, globalThis.MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        transactionData && void submitForm(transactionData, inputValue || "");
    };

    return (
        <>
            {transactionData && transactionData.isCredit() && (
                <Modal size="md" active={active} toggler={toggler} dataType="transaction-active">
                    <ModalHeader onClose={toggler}>Edit Sender Alias</ModalHeader>
                    <ModalBody>
                        <div className="flex w-full flex-col">
                            {/* {displayError && (
                            <MessageToasts toastMessage={isDeleteUserGroupError || ""} toastType={ToastType.ERROR} onClick={handleClearError} />
						)} */}
                            <TextArea
                                placeholder="Sender alias"
                                value={inputValue}
                                onChangeFunc={(e) => setInputValue(e)}
                                isLoading={isSubmitting}
                                data-type="transaction-active"
                            />
                            {/* <FormInput
                                data-type="transaction-active"
                                type="textarea"
                                name="alias"
                                isLoading={isSubmitting}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder="Sender alias"
                            /> */}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonComp
                            type="button"
                            ripple="light"
                            buttonType="secondary"
                            color="grey"
                            func={() => {
                                toggler();
                            }}
                        >
                            <span>Cancel</span>
                        </ButtonComp>
                        <ButtonComp
                            color="black"
                            ripple="light"
                            buttonType="primary"
                            isLoading={isSubmitting}
                            func={(event) => handleEditAlias(event)}
                        >
                            <span>Save Changes</span>
                        </ButtonComp>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}

export default memo(EditTransactionAliasModal);
