import * as Yup from "yup";

import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import FormInput from "../../../../../../../../../../components/inputs/FormInput";
import { MultipleRefereeForm } from "../../../../../../../services/nigeria-application/registered/referee.types";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import formikHasError from "../../../../../../../../../../helpers/formikHasError";
import { useAppSelector } from "../../../../../../../../../../redux/hooks";
import useInviteReferees from "../../../../../../../Hooks/nigeria-application/registered-business/invite-referee/useInviteReferees";

type LocationState = {
    from?: string;
};

function MultipleRefereeInviteForm(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isInviteRefereeLoading, handleRest, handleInviteReferee } = useInviteReferees();

    const isCreator = useAppSelector((state) => state.application.init?.isCreator);
    const accountOpening = useAppSelector((state) => state.application.init);

    const INITIAL_FORM_STATE: MultipleRefereeForm = {
        firstRefereeEmail: "",
        firstRefereeName: "",
        firstRefereePhone: "",
        secondRefereeEmail: "",
        secondRefereeName: "",
        secondRefereePhone: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        firstRefereeEmail: Yup.string().required(),
        firstRefereeName: Yup.string().required(),
        firstRefereePhone: Yup.string().required(),
        secondRefereeEmail: Yup.string().required(),
        secondRefereeName: Yup.string().required(),
        secondRefereePhone: Yup.string().required(),
    });

    useEffect(() => {
        return () => {
            handleRest();
        };
    }, []);

    const handleBack = () => {
        navigate(
            {
                pathname:
                    locationState?.from ||
                    (isCreator ? Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS : Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT),
            },
            {
                state: {
                    from: Routes.ACCOUNT_OPENING.REVIEW,
                    // beforeFrom: Routes.ACCOUNT_OPENING.REVIEW,
                },
            }
        );
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    void handleInviteReferee({
                        referees: [
                            {
                                name: values.firstRefereeName,
                                email: values.firstRefereeEmail,
                                phone: values.firstRefereePhone,
                            },
                            {
                                name: values.secondRefereeName,
                                email: values.secondRefereeEmail,
                                phone: values.secondRefereePhone,
                            },
                        ],
                    });
                }}
                enableReinitialize
                validateOnMount
                validateOnBlur
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <ApplicationHeader
                                header="Invite your Referees"
                                subTitle="Please inform your referees to look out for a reference letter from Lenco"
                                progress={
                                    accountOpening?.applicationMin.requestedProAccount
                                        ? 100
                                        : accountOpening?.progress.refereesCompletedProgress() || 0
                                }
                            >
                                <div className="flex w-full flex-col items-start space-y-8 text-base font-normal md:items-center md:px-0">
                                    <div className="flex w-full flex-col items-start space-y-4">
                                        <h3 className="font-medium text-black-secondary">Referee 1</h3>
                                        <div className="flex w-full flex-col items-start space-y-4">
                                            <div className="flex w-full flex-row">
                                                <FormInput
                                                    type="text"
                                                    placeholder="Full Name"
                                                    name="firstRefereeName"
                                                    value={formik.values.firstRefereeName}
                                                />
                                            </div>
                                            <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                <div className="w-full md:w-50%">
                                                    <FormInput
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        name="firstRefereePhone"
                                                        value={formik.values.firstRefereePhone}
                                                    />
                                                </div>
                                                <div className="w-full md:w-50%">
                                                    <FormInput
                                                        type="text"
                                                        placeholder="Email Address"
                                                        name="firstRefereeEmail"
                                                        value={formik.values.firstRefereeEmail}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-col items-start space-y-4">
                                        <h3 className="font-medium text-black-secondary">Referee 2</h3>
                                        <div className="flex w-full flex-col items-start space-y-4">
                                            <div className="flex w-full flex-row">
                                                <FormInput
                                                    type="text"
                                                    placeholder="Full Name"
                                                    name="secondRefereeName"
                                                    value={formik.values.secondRefereeName}
                                                />
                                            </div>
                                            <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                <div className="w-full md:w-50%">
                                                    <FormInput
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        name="secondRefereePhone"
                                                        value={formik.values.secondRefereePhone}
                                                    />
                                                </div>
                                                <div className="w-full md:w-50%">
                                                    <FormInput
                                                        type="text"
                                                        placeholder="Email Address"
                                                        name="secondRefereeEmail"
                                                        value={formik.values.secondRefereeEmail}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span className="normal-case">Back</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                    <ButtonComp
                                        type="submit"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        isLoading={isInviteRefereeLoading}
                                        disable={formikHasError(formik.errors)}
                                        fullWidth
                                    >
                                        <span>Invite Referees</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default MultipleRefereeInviteForm;
