import ApiAccessKeySettingDropdown from "./Components/Dropdown/ApiAccessKeyDropdown";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import { ReactComponent as InfoCircleIcon } from "../../../../../../../../assets/svg/LencoPay/InfoCircleIcon.svg";
import React from "react";
import { useSelector } from "react-redux";
import Text from "../../../../../../../../components/general/Text";

function ApiKeys(): JSX.Element {
    const selectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.selectedApiAccessKey);
    const apiAccessKeySecretKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.apiAccessKeySecretKey);

    return (
        <>
            {selectedApiAccessKey && (
                <div className="w-full space-y-6">
                    <div className="rounded-lg bg-white px-4 py-4">
                        <div className="flex flex-col items-start justify-between space-y-4 2xs:flex-row 2xs:items-center 2xs:space-y-0">
                            <div className="space-y-1">
                                <p className="text-sm font-medium">API Credentials</p>
                                <p className="text-sm text-black-tertiary">Authenticate and secure your API requests with unique credentials.</p>
                            </div>
                            <div className="w-full 2xs:max-w-max">
                                <ApiAccessKeySettingDropdown apiAccessKey={selectedApiAccessKey} />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 w-full rounded-lg bg-white px-4 py-4">
                        <div className="w-full space-y-4">
                            <div className="flex w-full flex-col justify-between space-y-4 2xs:flex-row 2xs:items-center 2xs:space-x-6 2xs:space-y-0">
                                <p className="min-w-10 text-sm font-medium text-black">API Name</p>
                                <Text fullWidth text={selectedApiAccessKey.name} isDisabled={!selectedApiAccessKey.isAccessible} />
                            </div>
                            <div className="w-full border-b border-grey-secondary"></div>
                            <div className="flex w-full flex-col justify-between space-y-4 2xs:flex-row 2xs:items-center 2xs:space-x-6 2xs:space-y-0">
                                <p className="min-w-10 text-sm font-medium text-black">Base URL</p>
                                <Text
                                    fullWidth
                                    text="https://api.lenco.co/access/v2"
                                    isDisabled={!selectedApiAccessKey.isAccessible}
                                    withClipboard={selectedApiAccessKey.isAccessible}
                                />
                            </div>
                            <div className="w-full border-b border-grey-secondary"></div>
                            <div className="flex w-full flex-col items-start justify-start space-y-2">
                                <div className="flex w-full flex-col justify-between space-y-4 2xs:flex-row 2xs:items-center 2xs:space-x-6 2xs:space-y-0">
                                    <p className="min-w-10 text-sm font-medium text-black">API (Secret) Key</p>
                                    <div className="w-full">
                                        {apiAccessKeySecretKey ? (
                                            <Text
                                                fullWidth
                                                text={apiAccessKeySecretKey}
                                                isDisabled={!selectedApiAccessKey.isAccessible}
                                                withClipboard={selectedApiAccessKey.isAccessible}
                                            />
                                        ) : (
                                            <Text
                                                fullWidth
                                                text="●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●"
                                                isDisabled={!selectedApiAccessKey.isAccessible}
                                                withClipboard={selectedApiAccessKey.isAccessible}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center justify-start space-x-2 2xs:ml-40 2xs:pl-5">
                                    <InfoCircleIcon className="stroke-current text-info" />
                                    <p className={"text-xs " + `${selectedApiAccessKey.isAccessible ? "text-info" : "text-info-quat"}`}>
                                        Copy your API key now as it won&apos;t be displayed again.
                                    </p>
                                </div>
                            </div>
                            <div className="w-full border-b border-grey-secondary"></div>
                            <div className="flex w-full flex-col justify-between space-y-4 2xs:flex-row 2xs:items-center 2xs:space-x-6 2xs:space-y-0">
                                <p className="min-w-10 text-sm font-medium text-black">Public Key</p>
                                <Text
                                    fullWidth
                                    text={selectedApiAccessKey.publicKey}
                                    isDisabled={!selectedApiAccessKey.isAccessible}
                                    withClipboard={selectedApiAccessKey.isAccessible}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ApiKeys;
