import { BillPurchaseCategoriesType, BillPurchaseStatusType } from "../../../../../models/billPurchase.constant";

export const BillPurchaseCategoriesTypeArray = [
    BillPurchaseCategoriesType.AIRTIME,
    BillPurchaseCategoriesType.AIRTIME_PIN,
    BillPurchaseCategoriesType.CABLE_TV,
    BillPurchaseCategoriesType.ELECTRICITY,
    BillPurchaseCategoriesType.INTERNET,
    BillPurchaseCategoriesType.MOBILE_COMBO,
    BillPurchaseCategoriesType.MOBILE_DATA,
    BillPurchaseCategoriesType.OTHER_BILLS,
];

export const BillPurchaseCategoriesTypeText = (_count: BillPurchaseCategoriesType): string => {
    switch (_count) {
        case BillPurchaseCategoriesType.AIRTIME:
            return "Airtime";
        case BillPurchaseCategoriesType.AIRTIME_PIN:
            return "Airtime Pin";
        case BillPurchaseCategoriesType.CABLE_TV:
            return "Cable TV";
        case BillPurchaseCategoriesType.ELECTRICITY:
            return "Electricity";
        case BillPurchaseCategoriesType.INTERNET:
            return "Internet";
        case BillPurchaseCategoriesType.MOBILE_COMBO:
            return "Mobile Combo";
        case BillPurchaseCategoriesType.MOBILE_DATA:
            return "Mobile Data";
        case BillPurchaseCategoriesType.OTHER_BILLS:
            return "Other Bills";
    }
};

export const BillPurchaseCategoryOptions = BillPurchaseCategoriesTypeArray.map((_count) => ({
    text: BillPurchaseCategoriesTypeText(_count),
    value: _count,
}));

export const BillPurchaseStatusTypeArray = [BillPurchaseStatusType.SUCCESS, BillPurchaseStatusType.PENDING, BillPurchaseStatusType.FAILED];

export const BillPurchaseStatusTypeText = (_count: BillPurchaseStatusType): string => {
    switch (_count) {
        case BillPurchaseStatusType.SUCCESS:
            return "Success";
        case BillPurchaseStatusType.PENDING:
            return "Pending";
        case BillPurchaseStatusType.FAILED:
            return "Failed";
    }
};

export const BillPurchaseStatusTypeOptions = BillPurchaseStatusTypeArray.map((_count) => ({
    text: BillPurchaseStatusTypeText(_count),
    value: _count,
}));
