import React from "react";

interface Props {
    title: string;
}

function SignatoryTags(props: Props): JSX.Element {
    return (
        <>
            <div className="w-max rounded-[4px] bg-grey-tertiary px-1 py-0.5 text-xs leading-none text-black-secondary">
                <span>{props.title}</span>
            </div>
        </>
    );
}

export default SignatoryTags;
