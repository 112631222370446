import SendableTextarea from "../../../../../../../components/text-area/sendable-text-area";
import Transaction from "../../../../../../../models/transaction";
import useTransactionDetailsCommentForm from "../Hooks/useTransactionDetailsCommentForm";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCommentForm({ dataType, transaction }: Props): JSX.Element {
    const { isSubmitting, handleAddTransactionComment, newCommentText, setNewCommentText } = useTransactionDetailsCommentForm();

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                void handleAddTransactionComment(transaction, newCommentText);
            }}
            data-type={dataType}
        >
            <SendableTextarea
                label="Comment"
                transactionCard
                value={newCommentText}
                onClickFunc={() => void handleAddTransactionComment(transaction, newCommentText)}
                onChangeFunc={setNewCommentText}
                isLoading={isSubmitting}
                data-type={dataType}
                buttonText="Comment"
                sm
            />
        </form>
    );
}

export default TransactionDetailCommentForm;
