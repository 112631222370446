import BillPurchase from "../../../../models/billPurchase";
import { BillPurchasesHistoryRequest } from "../../../../modules/dashboard/payments-new/services/billPayment/billPaymentApi.types";
import BillVendor from "../../../../models/billVendor";
import { FilterDateTypes } from "../../../../components/filter/filter.constant";
import Transaction from "../../../../models/transaction";

export enum BillPaymentPage {
    MAKE_PAYMENT = 1,
    PAYMENT_HISTORY = 2,
}

export enum BillPaymentStage {
    INITIAL = 1,
    PAYMENT_DETAILS = 2,
    REVIEW_PAYMENT = 3,
    MAKE_PAYMENT = 4,
    PROCESS_PAYMENT = 5,
}

export enum BillPaymentType {
    AIRTIME_PAYMENT = "airtime",
    DATA_PAYMENT = "data",
    CABLE_TV_PAYMENT = "cableTV",
    ELECTRICITY_PAYMENT = "electricity",
    BILL_PAYMENT_HISTORY = "bill_payment_history",
}

export interface BillPaymentState {
    billPaymentStage: BillPaymentStage;
    billPaymentType: BillPaymentType;
    billPaymentTypeBeforeBillHistoryType: BillPaymentType | null;
    recentBillPurchases: BillPurchase[] | null;
    isRecentBillPurchasesLoading: boolean;
    recentBillPurchasesError: boolean;
    vendorsList: BillVendor[] | null;
    isVendorsListLoading: boolean;
    vendorsListError: boolean;
    payFrom: string;
    billPaymentPage: BillPaymentPage;
    billPurchases: BillPurchase[];
    billPurchasesGroupSize: number;
    billPurchasesTotal: number;
    billPurchasesOffSet: number;
    selectedBillPurchase: BillPurchase | null;
    showBillPurchaseDetails: BillPurchase | null;
    isCustomModalOpen: boolean;
    billHistoryTransactionDateType: FilterDateTypes;
    selectedBillHistoryTransaction: Transaction | null;
    //filter
    billHistoryFilterState: BillPurchasesHistoryRequest;
    selectedBillHistoryFilterState: BillPurchasesHistoryRequest;
}
