import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { UpdateZambiaApiAccessKeyWidgetColorThemeRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { updateZambiaApiAccessKeyWidgetColorThemeApi } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { useDispatch } from "react-redux";

export interface UseUpdateZambiaApiAccessKeyWidgetColorThemeInterface {
    isUpdateZambiaApiAccessKeyWidgetColorThemeLoading: boolean;
    updateZambiaApiAccessKeyWidgetColorThemeErrorMessage: string;
    handleUpdateZambiaApiAccessKeyWidgetColorTheme: (_data: UpdateZambiaApiAccessKeyWidgetColorThemeRequest) => void;
}

// interface Props {
// onComplete: () => void;
// }

function useUpdateZambiaApiAccessKeyWidgetColorTheme(): UseUpdateZambiaApiAccessKeyWidgetColorThemeInterface {
    const dispatch = useDispatch();
    const [isUpdateZambiaApiAccessKeyWidgetColorThemeLoading, setIsUpdateZambiaApiAccessKeyWidgetColorThemeLoading] = useState(false);
    const [updateZambiaApiAccessKeyWidgetColorThemeErrorMessage, setUpdateZambiaApiAccessKeyWidgetColorThemeErrorMessage] = useState<string>("");

    const handleUpdateZambiaApiAccessKeyWidgetColorTheme = useCallback(
        async (_data: UpdateZambiaApiAccessKeyWidgetColorThemeRequest) => {
            try {
                setIsUpdateZambiaApiAccessKeyWidgetColorThemeLoading(true);
                setUpdateZambiaApiAccessKeyWidgetColorThemeErrorMessage("");
                const res = await updateZambiaApiAccessKeyWidgetColorThemeApi(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key widget color theme updated successfully."));
                // props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setUpdateZambiaApiAccessKeyWidgetColorThemeErrorMessage(errorMessage);
            } finally {
                setIsUpdateZambiaApiAccessKeyWidgetColorThemeLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isUpdateZambiaApiAccessKeyWidgetColorThemeLoading,
        updateZambiaApiAccessKeyWidgetColorThemeErrorMessage,
        handleUpdateZambiaApiAccessKeyWidgetColorTheme,
    };
}

export default useUpdateZambiaApiAccessKeyWidgetColorTheme;
