import { useCallback, useState } from "react";

import { LencoPayDefaultSetupRequest } from "../../Services/DefaultSetup/lencoPayDefaultSetupApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { updateDefaultSetup } from "../../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import { updateLencoPayDefaultSetup } from "../../Services/DefaultSetup/lencoPayDefaultSetupApi";
import { useDispatch } from "react-redux";

export interface UseUpdateLencoPayDefaultSetupInterface {
    isUpdateLencoPayDefaultSetupLoading: boolean;
    updateLencoPayDefaultSetupErrorMessage: string;
    handleUpdateLencoPayDefaultSetup: (_data: LencoPayDefaultSetupRequest) => Promise<void>;
}

// interface Props {
// onComplete: () => void;
// }

function useUpdateLencoPayDefaultSetup(): UseUpdateLencoPayDefaultSetupInterface {
    const dispatch = useDispatch();
    const [isUpdateLencoPayDefaultSetupLoading, setIsUpdateLencoPayDefaultSetupLoading] = useState(false);
    const [updateLencoPayDefaultSetupErrorMessage, setUpdateLencoPayDefaultSetupErrorMessage] = useState<string>("");

    const handleUpdateLencoPayDefaultSetup = useCallback(
        async (_data: LencoPayDefaultSetupRequest): Promise<void> => {
            try {
                setIsUpdateLencoPayDefaultSetupLoading(true);
                setUpdateLencoPayDefaultSetupErrorMessage("");
                const res = await updateLencoPayDefaultSetup(_data);
                if (res.success && res.defaultSetup) {
                    dispatch(updateDefaultSetup(res.defaultSetup));
                    dispatch(messageTrue("Lenco Pay Default Setup Updated successfully."));
                }
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setUpdateLencoPayDefaultSetupErrorMessage(errorMessage);
            } finally {
                setIsUpdateLencoPayDefaultSetupLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isUpdateLencoPayDefaultSetupLoading,
        updateLencoPayDefaultSetupErrorMessage,
        handleUpdateLencoPayDefaultSetup,
    };
}

export default useUpdateLencoPayDefaultSetup;
