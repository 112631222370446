import React, { useLayoutEffect } from "react";

import BusinessInformation from "../../registered-business/business-details/business-information";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../components/spinner";
import useBusinessDetailsInit from "../../../../../Hooks/zambia-application/registered-business/business-details/useBusinessDetailsInit";
import { useSelector } from "react-redux";

function RegisteredBusinessDetails(): JSX.Element {
    const { isBusinessDetailsInitLoading, businessDetailsInitError, handleReset, handleBusinessDetailsInit } = useBusinessDetailsInit();

    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    useLayoutEffect(() => {
        void handleBusinessDetailsInit();

        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            {isBusinessDetailsInitLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {businessDetailsInitError && (
                <div className="flex h-full w-full items-start justify-center">
                    <ErrorToast error={businessDetailsInitError} />
                </div>
            )}
            {accountOpening && !isBusinessDetailsInitLoading && !businessDetailsInitError && (
                <div className="flex h-full w-full flex-row items-start justify-start">
                    <BusinessInformation isUnregisteredBusiness />
                </div>
            )}
        </>
    );
}

export default RegisteredBusinessDetails;
