import React, { ReactNode } from "react";
import SkeletonElement from "../skeleton/SkeletonElement";
import GoogleIcon from "../google-icon";

export enum InfoButtonVariant {
    BORDERED = "BORDERED",
    TEXT = "TEXT",
}

interface Props {
    isLoading?: boolean;
    children?: ReactNode;
    onClick?: () => void;
    variant?: InfoButtonVariant;
    content?: ReactNode;
}

export default function InfoTextButton({ children, onClick, variant, isLoading, content }: Props) {
    return (
        <>
            {!isLoading && (
                <div
                    className={
                        "relative flex cursor-pointer items-center justify-center" +
                        `${variant === InfoButtonVariant.BORDERED && " rounded-lg border border-info-quin bg-info-backdrop px-4 py-2 text-xs"}`
                    }
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClick?.();
                    }}
                >
                    <GoogleIcon icon="info" className="text-info" dataType="section" isOutlined />
                    <div className="pl-2 text-sm font-medium">{content || children}</div>
                </div>
            )}
            {isLoading && (
                <div className="flex items-center space-x-2">
                    <div className="h-6 w-6 overflow-hidden rounded-full">
                        <SkeletonElement box />
                    </div>{" "}
                    <div className="mb-1.5 h-4 w-40">
                        <SkeletonElement box />
                    </div>
                </div>
            )}
        </>
    );
}
