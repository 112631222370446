import BillVendor from "../../../../../../../models/billVendor";
import BillVendorMin from "../../../../../../../models/billVendorMin";
import {
    resetCableTVPurchaseProductId,
    resetCableTVVerifiedAccountName,
    resetSmartCardNumber,
    setCableTVPurchaseVendorId,
} from "../../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseCableTVPurchaseVendorInterface {
    handleSelectVendor: (_vendor: BillVendor | BillVendorMin) => void;
    handleSelectVendorInstant: (_vendor: BillVendor | BillVendorMin) => void;
}

function useCableTVPurchaseVendor(): UseCableTVPurchaseVendorInterface {
    const dispatch = useDispatch();

    const handleSelectVendor = useCallback((_vendor: BillVendor | BillVendorMin) => {
        dispatch(resetSmartCardNumber());
        dispatch(resetCableTVPurchaseProductId());
        dispatch(resetCableTVVerifiedAccountName());
        dispatch(setCableTVPurchaseVendorId(_vendor));
    }, []);
    const handleSelectVendorInstant = useCallback((_vendor: BillVendor | BillVendorMin) => {
        dispatch(setCableTVPurchaseVendorId(_vendor));
    }, []);

    return { handleSelectVendor, handleSelectVendorInstant };
}

export default useCableTVPurchaseVendor;
