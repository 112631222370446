import BillPurchase from "../../../../../models/billPurchase";
import { BillPurchaseStatusType } from "../../../../../models/billPurchase.constant";

export function getBillPurchaseStatusBg(billPurchase: BillPurchase): { text: string; bgClass: string; textClass: string } {
    switch (billPurchase.status) {
        case BillPurchaseStatusType.SUCCESS:
            return { text: "Success", bgClass: "bg-success-backdrop border-success", textClass: "text-success" };
        case BillPurchaseStatusType.FAILED:
            return { text: "Failed", bgClass: "bg-error-backdrop border-error", textClass: "text-error" };
        case BillPurchaseStatusType.PENDING:
            return { text: "Pending", bgClass: "bg-warning-backdrop border-warning", textClass: "text-warning" };
    }
}

interface Props {
    data: BillPurchase;
}

export default function BillPurchaseStatus({ data }: Props): JSX.Element {
    const statusData = getBillPurchaseStatusBg(data);

    return (
        <>
            <div className={statusData.bgClass + " ml-auto max-w-min whitespace-nowrap rounded-[4px] border px-2 py-1"} data-type={"billPurchase"}>
                <p className={"text-xs font-medium " + statusData.textClass} data-type={"billPurchase"}>
                    {statusData.text}
                </p>
            </div>
        </>
    );
}
