import {
    ZambiaMobileMoneyNumberAirtelFormat,
    ZambiaMobileMoneyNumberFormat,
    ZambiaMobileMoneyNumberMTNFormat,
    ZambiaMobileMoneyNumberZamtelFormat,
    ZambiaMobileMoneyOperator,
} from "../zambia-single-transfer.constant";
import {
    setCanVerifyZambiaRecipientAccountDetails,
    setZambiaPaymentMethod,
    setZambiaSingleTransferRecipient,
    setZambiaSingleTransferRecipientAccountNumber,
} from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BankAccount from "../../../../../../../../models/bankAccount";
import Card from "../../../../../../../../models/card";
import CustomerAccount from "../../../../../../../../models/customerAccount";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import { RecipientAutocompleteRequest } from "../../../../../services/send-money/singleTransferApi.types";
import { RequestCancelledError } from "../../../../../../../../helpers/request/requestErrors";
import UserAccount from "../../../../../../../../models/userAccount";
import { ZambiaPaymentMethod } from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice.types";
import airtel from "../../../../../../../../assets/svg/dashboard/send-money/zambia-telco//airtel.png";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../../../../utils/isNullOrUndefined";
import mtn from "../../../../../../../../assets/svg/dashboard/send-money/zambia-telco//mtn.png";
import { recipientAutocomplete } from "../../../../../services/send-money/singleTransferApi";
import zamtel from "../../../../../../../../assets/svg/dashboard/send-money/zambia-telco//zamtel.png";

interface AccountAutoCompleteDetailsInterface {
    query: string;
    includeUserAccounts: boolean;
}

interface RecipientAutocompleteResponse {
    success: boolean;
    message: string;
    userAccounts: Array<UserAccount>;
    customerAccounts: Array<CustomerAccount>;
    cards: Array<Card>;
}

interface UseSingleTransferAccountNumberAutocompleteInterface {
    telcoImg: string | null;
    suggestedRecipients: (UserAccount | CustomerAccount | Card)[] | null;
    accountAutoCompleteDetails: AccountAutoCompleteDetailsInterface;
    isRecipientAutocompleteLoading: boolean;
    recipientAutocompleteResponse: RecipientAutocompleteResponse | null;
    handleSelectRecipient: (_bankAccount: BankAccount) => void;
    handleAccountNumberChange: (_accountNumber: string) => void;
}

interface Props {
    handleSelectBank: (_bankAccountCode: string) => void;
    handleSetVerifiedAccount: (_bankAccount: BankAccount) => void;
    handleResetVerifiedAccount: () => void;
}

function useSingleTransferAccountNumberAutocomplete({
    handleSelectBank,
    handleSetVerifiedAccount,
    handleResetVerifiedAccount,
}: Props): UseSingleTransferAccountNumberAutocompleteInterface {
    const dispatch = useDispatch();

    const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const paymentMethod = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails.paymentMethod);
    const customerAccountList = useSelector<IRootState, Map<string, CustomerAccount>>((state) => state.customerAccount.customerAccounts);
    const originatingAccountId = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails.originatingAccountId);

    const [accountAutoCompleteDetails, setAccountAutoCompleteDetails] = useState<AccountAutoCompleteDetailsInterface>({
        query: "",
        includeUserAccounts: true,
    });
    const [telcoImg, setTelcoImg] = useState<string | null>(null);
    const [isRecipientAutocompleteLoading, setIsRecipientAutocompleteLoading] = useState(false);
    const [recipientAutocompleteResponse, setRecipientAutocompleteResponse] = useState<RecipientAutocompleteResponse | null>(null);
    const [suggestedRecipients, setSuggestedRecipients] = useState<(UserAccount | CustomerAccount | Card)[] | null>(null);

    useEffect(() => {
        const { query } = accountAutoCompleteDetails;
        if (query.length < 3) return;
        void handleRecipientAutoComplete(accountAutoCompleteDetails);
    }, [accountAutoCompleteDetails]);

    useEffect(() => {
        if (!accounts) return;
        const doesAccountExist = accounts.some((el) => el.id === originatingAccountId);
        if (!doesAccountExist && !recipientAutocompleteResponse) return;
        if (doesAccountExist) {
            const userAccount = accounts.find((el) => el.id === originatingAccountId) as UserAccount;
            if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) {
                if (userAccount.accountRestrictions.customerAccountIds.length > 0) {
                    const customerAccounts = userAccount.accountRestrictions.customerAccountIds.map((el) =>
                        customerAccountList.get(el)
                    ) as CustomerAccount[];
                    return setSuggestedRecipients([
                        ...userAccount.accountRestrictions.cards,
                        ...userAccount.accountRestrictions.userAccounts,
                        ...customerAccounts,
                    ]);
                }
                return setSuggestedRecipients([...userAccount.accountRestrictions.cards, ...userAccount.accountRestrictions.userAccounts]);
            }
            if (recipientAutocompleteResponse) {
                return setSuggestedRecipients([
                    ...recipientAutocompleteResponse.customerAccounts.filter(
                        (el) => el.bankAccount && el.bankAccount.id !== userAccount.bankAccount?.id
                    ),
                    ...recipientAutocompleteResponse.userAccounts.filter((el) => el.bankAccount && el.bankAccount.id !== userAccount.bankAccount?.id),
                    ...recipientAutocompleteResponse.cards,
                ]);
            }
        } else if (recipientAutocompleteResponse) {
            return setSuggestedRecipients([
                ...recipientAutocompleteResponse.customerAccounts,
                ...recipientAutocompleteResponse.userAccounts,
                ...recipientAutocompleteResponse.cards,
            ]);
        }
    }, [recipientAutocompleteResponse, accounts, originatingAccountId, customerAccountList]);

    useEffect(() => {
        if (!payTo || !customerAccountList) return;
        const recipient = customerAccountList.get(payTo);
        if (!isNullOrUndefined(recipient)) {
            dispatch(setCanVerifyZambiaRecipientAccountDetails(false));
            dispatch(setZambiaSingleTransferRecipient(recipient.bankAccount as BankAccount));
            handleSetVerifiedAccount(recipient.bankAccount as BankAccount);
            dispatch(setZambiaPaymentMethod(recipient.bankAccount?.isLenco ? ZambiaPaymentMethod.LENCO_BUSINESS : ZambiaPaymentMethod.BANK_TRANSFER));
        }
    }, [payTo, customerAccountList]);

    const handleRecipientAutoComplete = useCallback(
        async (_data: RecipientAutocompleteRequest) => {
            try {
                setIsRecipientAutocompleteLoading(true);
                const res = await recipientAutocomplete(_data);
                setRecipientAutocompleteResponse(res);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            }
            setIsRecipientAutocompleteLoading(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    const handleAccountNumberChange = useCallback(
        (_accountNumber: string) => {
            setAccountAutoCompleteDetails((prev): AccountAutoCompleteDetailsInterface => {
                return {
                    ...prev,
                    query: _accountNumber,
                };
            });
            if (paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY) {
                if (!isPhoneNumberValid(_accountNumber)) {
                    handleSelectBank("");
                    setTelcoImg(null);
                } else {
                    const phoneDetails = phoneNumberOperatorDetails(_accountNumber);
                    if (phoneDetails.img) {
                        setTelcoImg(phoneDetails.img);
                        handleSelectBank(phoneDetails.bankCode);
                    }
                }
            }
            dispatch(setZambiaSingleTransferRecipientAccountNumber(_accountNumber));
            dispatch(setCanVerifyZambiaRecipientAccountDetails(true));
            handleResetVerifiedAccount();
        },
        [paymentMethod]
    );

    const isPhoneNumberValid = (_accountNumber: string) => {
        let firstFiveDigits = "";
        const firstThreeDigits = _accountNumber.slice(0, 3);
        const numStartWithPlus = _accountNumber.slice(0, 1) === "+";
        if (numStartWithPlus) {
            firstFiveDigits = _accountNumber.slice(1, 6);
        } else {
            firstFiveDigits = _accountNumber.slice(0, 5);
        }
        return Object.values(ZambiaMobileMoneyNumberFormat).some((_code: string) => _code === firstThreeDigits || _code === firstFiveDigits);
    };
    const phoneNumberOperatorDetails = (_accountNumber: string) => {
        let firstFiveDigits = "";
        const firstThreeDigits = _accountNumber.slice(0, 3);
        const numStartWithPlus = _accountNumber.slice(0, 1) === "+";
        if (numStartWithPlus) {
            firstFiveDigits = _accountNumber.slice(1, 6);
        } else {
            firstFiveDigits = _accountNumber.slice(0, 5);
        }
        const isMTN = Object.values(ZambiaMobileMoneyNumberMTNFormat).some(
            (_code: string) => _code === firstThreeDigits || _code === firstFiveDigits
        );
        const isZamtel = Object.values(ZambiaMobileMoneyNumberZamtelFormat).some(
            (_code: string) => _code === firstThreeDigits || _code === firstFiveDigits
        );
        const isAirtel = Object.values(ZambiaMobileMoneyNumberAirtelFormat).some(
            (_code: string) => _code === firstThreeDigits || _code === firstFiveDigits
        );
        return {
            img: isMTN ? mtn : isAirtel ? airtel : isZamtel ? zamtel : null,
            // img: null,
            bankCode: isMTN
                ? ZambiaMobileMoneyOperator.MTN
                : isAirtel
                  ? ZambiaMobileMoneyOperator.AIRTEL
                  : isZamtel
                    ? ZambiaMobileMoneyOperator.ZAMTEL
                    : "",
        };
    };

    const handleSelectRecipient = useCallback((_bankAccount: BankAccount) => {
        dispatch(setCanVerifyZambiaRecipientAccountDetails(false));
        dispatch(setZambiaSingleTransferRecipient(_bankAccount));
        handleSetVerifiedAccount(_bankAccount);
    }, []);

    return {
        telcoImg,
        suggestedRecipients,
        accountAutoCompleteDetails,
        isRecipientAutocompleteLoading,
        recipientAutocompleteResponse,
        handleSelectRecipient,
        handleAccountNumberChange,
    };
}

export default useSingleTransferAccountNumberAutocomplete;
