// import { openAddFundsModal, openZambiaAddFundsModal } from "../../../../../../redux/accounts/slice/accountsSlice";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";

import AccountCardDetail from "../../../../Accounts/Components/Cards/AccountCardDetail";
// import { ReactComponent as AddCircle } from "../../../../../../assets/svg/addcircle.svg";
// import ButtonComp from "../../../../../../components/button/ButtonComp";
import Corporate from "../../../../../../models/corporate";
import { DashboardDetailsResponse } from "../../../Services/homeApi.types";
// import { IRootState } from "../../../../../../redux/rootReducer";
import { Link } from "react-router-dom";
import SkeletonElement from "../../../../../../components/skeleton";
import TransactionCategory from "../../../../../../models/transactionCategory";
import User from "../../../../../../models/user";
import UserAccount from "../../../../../../models/userAccount";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import UserGroup from "../../../../../../models/userGroup";

interface AccountDetailsCardInterface {
    accountsData: {
        user: User;
        userAccountsMeta: Array<UserAccountMeta>;
        company: Corporate;
        accounts: Array<UserAccount>;
        userGroups: Array<UserGroup>;
        transactionCategories: Array<TransactionCategory>;
    } | null;
    showAmount: boolean;
    dashboardDetails: DashboardDetailsResponse | null;
}

function AccountDetailsCard({ accountsData = null, showAmount, dashboardDetails }: AccountDetailsCardInterface): JSX.Element {
    // const dispatch = useDispatch();

    // const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);

    const [data, setData] = useState<{
        user: User;
        userAccountsMeta: Array<UserAccountMeta>;
        company: Corporate;
        accounts: Array<UserAccount>;
        userGroups: Array<UserGroup>;
        transactionCategories: Array<TransactionCategory>;
    } | null>(null);

    useEffect(() => {
        setData(accountsData);
    }, [accountsData]);

    // const handleOpenAddFundsModal = useCallback(() => {
    //     dispatch(isZambia ? openZambiaAddFundsModal() : openAddFundsModal());
    // }, [isZambia]);

    return (
        <>
            <div className="flex h-full max-h-96 min-h-72 flex-col space-y-3 rounded-xl bg-white px-8 py-5 leading-normal shadow">
                <div className="flex flex-row justify-between leading-normal">
                    {data && dashboardDetails ? (
                        <div className="text-lg font-medium text-black">My Accounts</div>
                    ) : (
                        <div className="flex h-9 w-16 items-center justify-start">
                            <div className="h-5 w-16">
                                <SkeletonElement box />{" "}
                            </div>
                        </div>
                    )}

                    <>
                        {data && dashboardDetails ? (
                            data.accounts.length > 3 && (
                                <div className="flex flex-row items-center justify-end">
                                    <div className="whitespace-nowrap">
                                        <Link to={`/accounts`}>
                                            <p className="text-base font-medium text-blue">View all</p>
                                        </Link>
                                    </div>
                                </div>
                            )
                        ) : (
                            <>
                                {(data?.accounts.length || 0) > 3 && (
                                    <div className="flex w-full flex-col items-end justify-end">
                                        <div className="mt-3 flex w-40% items-end justify-end">
                                            <SkeletonElement title />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>

                    {/* {isZambia ? (
                            <>
                                {data && dashboardDetails ? (
                                    data.accounts.length > 5 && (
                                        <div className="flex flex-row items-center justify-end">
                                            <div className="whitespace-nowrap text-sm text-blue">
                                                <Link to={`/accounts`}>View all</Link>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <>
                                        {(data?.accounts.length || 0) > 5 && (
                                            <div className="flex h-9 w-16 items-center justify-end">
                                                <div className="h-5 w-14">
                                                    <SkeletonElement box />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {data && dashboardDetails ? (
                                    <ButtonComp
                                        type="submit"
                                        color="blue"
                                        ripple="light"
                                        buttonType="secondary"
                                        size="sm"
                                        func={handleOpenAddFundsModal}
                                    >
                                        <div className="flex items-center justify-center space-x-2">
                                            <span>Add Money</span>
                                        </div>
                                    </ButtonComp>
                                ) : (
                                    <div className="h-9 w-36">
                                        <SkeletonElement box />
                                    </div>
                                )}
                            </>
                        )} */}
                </div>

                <div className="flex w-full flex-col items-start justify-center pb-11 pt-2">
                    {data && dashboardDetails ? (
                        data?.accounts?.slice(0, 4)?.map((el, index) => {
                            return (
                                <AccountCardDetail
                                    accountData={el}
                                    key={index}
                                    index={index}
                                    showAmount={showAmount}
                                    arrayLength={data?.accounts?.slice(0, 4).length}
                                />
                            );
                        })
                    ) : (
                        <>
                            <div className="flex max-h-full w-full flex-col items-center justify-between space-y-3">
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default AccountDetailsCard;
