import AvatarDetails from "../../../../../avatar-details";

interface Props {
    onClick: () => void;
    countryName: string;
    countryId: string;
}

function BusinessItem(props: Props) {
    return (
        <>
            <div
                className="flex w-full cursor-pointer flex-row items-center justify-start space-x-4 px-6 py-4 hover:bg-grey-backdrop"
                onClick={props.onClick}
            >
                <AvatarDetails title={props.countryName} />
            </div>
        </>
    );
}

export default BusinessItem;
