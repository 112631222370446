import React, { useEffect, useState } from "react";

import Accordion from "../../../../../../../../../components/accordion";
import AgreementSignatureComponent from "./AgreementSignatureComponent";
import { AgreementType } from "../../../../../../services/nigeria-application/registered/new/agreement.types";
import GoogleIcon from "../../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import PreviewModal from "../../../../../modals/preview-modal";
import UploadedCard from "../../../../../cards/UploadedCard";
import joinArray from "../../../../../../../../../utils/joinArray";
import titleCase from "../../../../../../../../../hooks/titleCase";
import usePreviewBusinessAgreement from "../../../../../../Hooks/nigeria-application/registered-business/agreement/usePreviewBusinessAgreement";
import { useSelector } from "react-redux";

interface Props {
    isOpen: boolean;
    isExternalLink?: boolean;

    toggle: () => void;
}

function TermsAndConditionsComponent(props: Props): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreviewBusinessAgreement({
        downloadedDocumentName: "Terms and Conditions Document",
        onComplete: () => setShowPreviewModal(true),
    });

    const terms = useSelector((state: IRootState) => state.application.agreement?.agreements.terms);
    const isCreator = useSelector((state: IRootState) => state.application.init?.isCreator);
    const directors = useSelector((state: IRootState) => state.application.agreement?.directors);
    const createdBy = useSelector((state: IRootState) => state.application.agreement?.createdBy.name);
    const currentUser = useSelector((state: IRootState) => state.application.agreement?.currentUser);

    const [isSigned, setIsSigned] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    const isUploaded = !!(terms?.signedDocumentId && terms?.signedBy.length < 1) || false;
    const listOfTermsAndConditionSignedDirectors = terms?.signedBy || [];
    const listOfTermsAndConditionUnsignedDirectors = directors?.filter((f) => !terms?.signedBy.some((_el) => _el.id === f.id)) || [];

    useEffect(() => {
        setIsSigned(!!terms?.signedDocumentId || false);
    }, [props.isOpen, terms]);

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Partner Bank's Terms and Conditions Preview"
                />
                <Accordion
                    header="Partner Bank's Terms and Conditions"
                    checkActive={!!terms?.signedDocumentId}
                    isOpened={props.isOpen}
                    toggle={props.toggle}
                    hasCheck
                >
                    <div className="relative flex w-full flex-col items-start justify-start space-y-6">
                        <div className="ml-[25px] flex flex-col">
                            <div className="text-xs text-black-tertiary lg:text-sm">
                                {terms?.signedDocumentId && listOfTermsAndConditionSignedDirectors.length < 1 && (
                                    <div className="flex flex-row items-center justify-start space-x-3 text-success">
                                        <GoogleIcon icon="check" size="lg" />

                                        <span>Uploaded by: {titleCase(createdBy || "")}</span>
                                    </div>
                                )}
                                {terms?.signedDocumentId &&
                                    listOfTermsAndConditionSignedDirectors.map((_signedSig, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-3 text-success" key={index}>
                                            <GoogleIcon icon="check" size="lg" />

                                            <span>Signed by: {titleCase(_signedSig.name)}</span>
                                        </div>
                                    ))}
                                {!terms?.signedDocumentId && listOfTermsAndConditionUnsignedDirectors.length > 0 && (
                                    <div className="flex flex-row items-center justify-start space-x-3">
                                        <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                        <p className="text-xs text-black-tertiary lg:text-sm">
                                            Expecting{" "}
                                            <span className="font-medium capitalize text-black-secondary">
                                                {listOfTermsAndConditionUnsignedDirectors.length > 1 ? "one of" : ""}{" "}
                                                {titleCase(joinArray(listOfTermsAndConditionUnsignedDirectors.map((el) => el.name) || []))}
                                            </span>{" "}
                                            to sign
                                        </p>
                                    </div>
                                )}
                                {!terms?.signedDocumentId &&
                                    listOfTermsAndConditionSignedDirectors.length < 1 &&
                                    listOfTermsAndConditionUnsignedDirectors.length > 0 && (
                                        <>
                                            <div className="flex flex-row items-center justify-start space-x-3">
                                                <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                                <p className="text-xs text-black-tertiary lg:text-sm">
                                                    Or{" "}
                                                    <span className="font-medium capitalize text-black-secondary">{titleCase(createdBy || "")}</span>{" "}
                                                    to upload the signed document
                                                </p>
                                            </div>
                                        </>
                                    )}
                                {!terms?.signedDocumentId &&
                                    listOfTermsAndConditionSignedDirectors.length < 1 &&
                                    listOfTermsAndConditionUnsignedDirectors.length < 1 && (
                                        <>
                                            <div className="flex flex-row items-center justify-start space-x-3">
                                                <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                                <p className="text-xs text-black-tertiary lg:text-sm">
                                                    {/* <span className="font-medium text-black-secondary capitalize">{createdBy || ""}</span>  */}
                                                    Invite a <span className="font-medium capitalize text-black-secondary">Director</span> to sign or
                                                    upload the signed document
                                                </p>
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>
                        <div className="relative w-full">
                            {!isSigned && (
                                <AgreementSignatureComponent
                                    type={AgreementType.TERMS_AND_CONDITIONS}
                                    isCreator={!!isCreator}
                                    onComplete={() => setIsSigned(true)}
                                    canAppendSignature={!!currentUser?.hasSignature}
                                />
                            )}
                            {isSigned && (
                                <UploadedCard
                                    text={isUploaded ? "Document Uploaded" : "Document Signed"}
                                    primaryButtonText="Sign again"
                                    handlePreviewDocumentFunc={() => void handlePreviewDocument(AgreementType.TERMS_AND_CONDITIONS)}
                                    handleRedo={() => setIsSigned(false)}
                                    isNoPrimaryButton={isUploaded}
                                />
                            )}
                        </div>
                    </div>
                </Accordion>
            </div>
        </>
    );
}

export default TermsAndConditionsComponent;
