import { useEffect, useState } from "react";

import AccountNumberInputItem from "../../../../../components/account-number-input/component/account-number-input-item";
import Card from "../../../../../models/card";
import CustomerAccount from "../../../../../models/customerAccount";
import NewSelect from "../../../../../components/new-select";
import UserAccount from "../../../../../models/userAccount";
import { useAppSelector } from "../../../../../redux/hooks";

interface Props {
    value: string;
    options: (Card | UserAccount | CustomerAccount)[] | null;
    dataType?: string;
    onClick: (e: UserAccount | CustomerAccount | Card) => void;
}

function AccountNumberSelect(props: Props) {
    const cardBalances = useAppSelector((state) => state.transferBetween.cardBalances);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredAccounts, setFilteredAccounts] = useState<Array<UserAccount | CustomerAccount | Card>>([]);

    useEffect(() => {
        const accounts = props.options || [];
        const query = searchTerm.trim().toLowerCase();

        if (!query.length) {
            setFilteredAccounts(accounts);
        } else {
            setFilteredAccounts(
                accounts.filter((item: UserAccount | CustomerAccount | Card) => {
                    if (item instanceof UserAccount) {
                        return (
                            item.accountName.toLowerCase().includes(query) ||
                            item.accountNumber.toLowerCase().includes(query) ||
                            item.lencoNameMin.toLowerCase().includes(query)
                        );
                    }
                    if (item instanceof CustomerAccount) {
                        return (
                            item.accountName.toLowerCase().includes(query) ||
                            item.accountNumber.toLowerCase().includes(query) ||
                            item.alias?.toLowerCase().includes(query)
                        );
                    }
                    if (item instanceof Card) {
                        return item.name.toLowerCase().includes(query) || item.maskedPan.toLowerCase().includes(query);
                    }
                })
            );
        }
    }, [props.options, searchTerm]);

    return (
        <>
            <NewSelect
                size="xl"
                label="Select Recipient"
                value={props.value || ""}
                dataType={props.dataType}
                searchTerm={searchTerm}
                dropdownSize="xl"
                optionsLength={filteredAccounts.length}
                handleChangeSearchTerm={(_value) => setSearchTerm(_value)}
                clickAndClose
                isSearchable
                fitHeight
            >
                {(_index) => (
                    <AccountNumberInputItem
                        data={filteredAccounts[_index]}
                        onClick={() => props.onClick(filteredAccounts[_index])}
                        cardBalance={cardBalances.get(
                            filteredAccounts[_index] instanceof Card ? (filteredAccounts[_index] as Card).createRequestId : ""
                        )}
                    />
                )}
            </NewSelect>
        </>
    );
}

export default AccountNumberSelect;
