import { DropdownItem, DropdownItemValueType } from "../../helpers/types";
import { useCallback, useEffect, useState } from "react";

import CustomSelect from "./custom-select";
import Input from "../inputs/Input";

interface Props<T extends DropdownItemValueType, U extends DropdownItemValueType> {
    value: T | U | undefined;
    options: Array<DropdownItem<T>>;
    canCancel?: boolean;
    otherValue: string | undefined;
    placeholder?: string;
    otherOptionValue: U; // the "value" that will be passed to onChange when the user selects the "other" option

    reset?: () => void;
    onSelect: (value: T | U | undefined, otherText: string | undefined) => void;
    onCancel?: () => void;
    resetDropdownValue?: () => void;
}

function CustomSelectWithOther<T extends DropdownItemValueType, U extends DropdownItemValueType>(props: Props<T, U>): JSX.Element {
    const [showSelect, setShowSelect] = useState(true);
    const [optionsWithOther, setOptionsWithOther] = useState<Array<DropdownItem<T | U>>>([]);
    const [autofocusTextInput, setAutofocusTextInput] = useState<boolean>(false);
    const [initialValue, setInitialValue] = useState<T | U | undefined>(undefined);

    // add "other" to the display options
    useEffect(() => {
        setOptionsWithOther([...props.options, { value: props.otherOptionValue, text: "Other" }]);
    }, [props.options, props.otherOptionValue]);

    const switchToInput = (focus = true) => {
        // only set this here when switching between dropdown and input, so that it does not autofocus when the component first loads
        if (focus) {
            setAutofocusTextInput(true);
        }
        props.resetDropdownValue && props.resetDropdownValue();
        setShowSelect(false);
    };

    const switchToDropdown = useCallback(() => {
        setAutofocusTextInput(false);
        setShowSelect(true);
        if (props.reset) {
            props.reset();
        } else {
            props.onSelect(initialValue, undefined);
        }
    }, [props.reset, initialValue]);

    // useEffect(() => {
    // if (props.value === props.otherOptionValue && showSelect) {
    // switchToInput(false);
    // }
    // if (props.value !== props.otherOptionValue && !showSelect) {
    // switchToDropdown();
    // }
    // }, [props.value, props.otherOptionValue, showSelect]);

    //keep track of initial value
    useEffect(() => {
        if (props.otherValue) return;
        setInitialValue(props.value);
    }, [props.value, props.otherValue]);

    useEffect(() => {
        if (!props.otherValue) return;
        setShowSelect(false);
    }, [props.otherValue]);

    return (
        <>
            {showSelect && (
                <CustomSelect
                    placeholder={props.placeholder}
                    value={props.value}
                    options={optionsWithOther}
                    onSelect={(value: T | U | undefined) => {
                        if (value === props.otherOptionValue) {
                            switchToInput();
                        }
                        if (value !== props.otherOptionValue) {
                            props.onSelect(value, undefined);
                        }
                    }}
                    canCancel={props.canCancel}
                    onCancel={props.onCancel}
                    // clickOutsideFunc={() => props.onSelect(initialValue, undefined)}
                />
            )}

            {!showSelect && (
                <Input
                    placeholder={props.placeholder}
                    value={props.otherValue}
                    // Can change the icon
                    icon={
                        <div className="flex flex-row items-center justify-start">
                            <div className="flex max-w-[20px] items-center justify-center overflow-hidden" onClick={switchToDropdown}>
                                <span className="material-symbols-rounded text-lg leading-[100%]">arrow_back</span>
                            </div>
                            <p>Other:</p>
                        </div>
                    }
                    type="text"
                    onChange={(text) => {
                        props.onSelect(props.otherOptionValue, text);
                    }}
                    isActive={autofocusTextInput}
                />
            )}
        </>
    );
}

export default CustomSelectWithOther;
