import { CardStatus, CardWalletType } from "../../../../../models/card.constants";
import React, { useEffect, useState } from "react";
import { removeFromActiveOpenedCardActivationForms, removeFromActiveOpenedReassignCardForms } from "../../../../../redux/cards/slice/cardsSlice";
import { useDispatch, useSelector } from "react-redux";
import ActivatedCard from "./Element/ActivatedCard";
import ButtonComp from "../../../../../components/button/ButtonComp";
import CancelCard from "./Element/CancelCard";
import CardAssignedTo from "./Element/CardAssignedTo";
import CardLineDropdown from "../Dropdown/CardLineDropdown";
import CardLinkedTo from "./Element/CardLinkedTo";
import CardMain from "../../../../../models/cardMain";
import CardRequestStatusUpdate from "./Element/CardRequestStatusUpdate";
import { CardSectionEnum } from "../../Services/cardsApi.types";
import FullPageTray from "../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import { IRootState } from "../../../../../redux/rootReducer";
import LencoCard from "./Element/LencoCard";
import { PaymentsType } from "../../../payments-new/hooks/state/send-money/payments.constants";
import RecentTransactions from "./Element/RecentTransaction";
import RequestDefaultPIN from "./Element/RequestDefaultPIN";
import SetPaymentChannel from "./Element/SetPaymentChannel";
import SetSecurity from "./Element/SetSecurity";
import UpdateSpendingLimit from "./Element/UpdateSpendingLimit";
import { ReactComponent as WhiteFreezeIcon } from "../../../../../assets/svg/Cards/WhiteFreeze.svg";
import useActivateCard from "../../Hooks/useActivateCard";
import useCardInformation from "../../Hooks/State/useCardInformation";
import useCardTray from "../../Hooks/State/useCardTray";
import useFreezeCard from "../../Hooks/State/useFreezeCard";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../../../../components/google-icon";

export interface CardDetailProps {
    selectedCard: CardMain | null;
    isSelectedCardDetailsOpen: boolean;
    handleReset: () => void;
}

export const CARD_DETAILS_DATATYPE = "card-details";
function CardDetails(props: CardDetailProps): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isShowCardDetails, setIsShowCardDetails] = useState(false);

    const {
        cardPin,
        isGetCardPinLoading,
        isActivateCardLoading,
        handleReset: handleResetActivateCard,
        handleActivateCard,
        handleGetCardPinCard,
    } = useActivateCard();
    const { isFreezeCardLoading, isUnFreezeCardLoading, handleReset: handleRestFreezeCard, handleFreezeCard, handleUnFreezeCard } = useFreezeCard();
    const { isCardInformationLoading, cardInformationResponse, handleReset, handleCardInformation } = useCardInformation({
        onComplete: () => setIsShowCardDetails(true),
    });
    const {
        activeCardTraySections,
        onSendMoney,
        onOpenCancelCardSection,
        onOpenDefaultCardSection,
        onOpenActivateCardSection,
        onOpenReassignCardCardSection,
        onOpenSpendingLimitCardSection,
        onOpenRequestDefaultPINSection,
        onOpenSetPaymentChannelsCardSection,
        onOpenSecurityCardSection,
    } = useCardTray();

    const currentUserId = useSelector((state: IRootState) => state.init.main?.companyDetails.user.id);

    const isCardAssignedToUser = props.selectedCard?.card?.assignedToCorporateAccountMember?.id === currentUserId;

    const handleResetAll = () => {
        props?.handleReset?.();
        dispatch(removeFromActiveOpenedReassignCardForms(props.selectedCard?.createRequest.id || ""));
        dispatch(removeFromActiveOpenedCardActivationForms(props.selectedCard?.createRequest.id || ""));
        handleReset?.();
        handleRestFreezeCard();
        handleResetActivateCard();
        onOpenDefaultCardSection();
        setIsShowCardDetails(false);
    };

    useEffect(() => {
        if (!props.selectedCard) return;
        setIsShowCardDetails(false);
        handleReset?.();
        handleRestFreezeCard();
        handleResetActivateCard();
        dispatch(removeFromActiveOpenedReassignCardForms(props.selectedCard?.createRequest.id || ""));
        dispatch(removeFromActiveOpenedCardActivationForms(props.selectedCard?.createRequest.id || ""));
    }, [props.selectedCard?.createRequest.id]);

    return (
        <>
            {props.selectedCard && props.isSelectedCardDetailsOpen && (
                <FullPageTray active={props.isSelectedCardDetailsOpen} dataType={CARD_DETAILS_DATATYPE} handleReset={handleResetAll} withClickOutside>
                    <FullPageTrayHeader
                        toggler={handleResetAll}
                        dataType={CARD_DETAILS_DATATYPE}
                        menu={
                            props.selectedCard.card && props.selectedCard.card?.status !== CardStatus.DEACTIVATED ? (
                                <CardLineDropdown
                                    isAssignedToUser={isCardAssignedToUser}
                                    onActivateCard={() => onOpenActivateCardSection(props.selectedCard?.createRequest.id || "")}
                                    onCancelCard={() => onOpenCancelCardSection(props.selectedCard?.createRequest.id || "")}
                                    onPaymentChannel={() => onOpenSetPaymentChannelsCardSection(props.selectedCard?.createRequest.id || "")}
                                    onReassignCard={() => onOpenReassignCardCardSection(props.selectedCard?.createRequest.id || "")}
                                    onSendMoney={() => onSendMoney(props.selectedCard?.createRequest.id || "", props.selectedCard?.card?.id || "")}
                                    onSpendingLimit={() => onOpenSpendingLimitCardSection(props.selectedCard?.createRequest.id || "")}
                                    onUnfreezeCard={() => {
                                        handleUnFreezeCard({ createRequestId: props.selectedCard?.createRequest.id || "" });
                                    }}
                                    onFreezeCard={() => {
                                        handleFreezeCard({ createRequestId: props.selectedCard?.createRequest.id || "" });
                                    }}
                                    onRequestDefaultPIN={() => {
                                        onOpenRequestDefaultPINSection();
                                    }}
                                    onSecurity={() => onOpenSecurityCardSection(props.selectedCard?.createRequest.id || "")}
                                    data={props.selectedCard}
                                />
                            ) : undefined
                        }
                    >
                        <div className="flex items-center space-x-1">
                            {props.selectedCard.card && props.selectedCard.card?.status !== CardStatus.DEACTIVATED && (
                                <button
                                    className="flex h-10 w-10 items-center justify-center rounded-md bg-grey-tertiary outline-none transition-all focus:outline-none"
                                    onClick={handleResetAll}
                                    data-type={CARD_DETAILS_DATATYPE}
                                >
                                    <GoogleIcon icon="arrow_left_alt" size="lg" />
                                </button>
                            )}
                            <span> Card Details</span>
                        </div>
                    </FullPageTrayHeader>
                    <FullPageTrayBody dataType={CARD_DETAILS_DATATYPE}>
                        <div data-type={props.isSelectedCardDetailsOpen ? "card-active" : "card"} className="w-full pb-10">
                            {activeCardTraySections?.includes(CardSectionEnum.CREDIT_CARD_SECTION) ? (
                                <>
                                    <LencoCard
                                        isFreezingCardLoading={isFreezeCardLoading}
                                        data={props.selectedCard}
                                        isCard={!!(props.selectedCard instanceof CardMain)}
                                        data-type={props.isSelectedCardDetailsOpen ? "card-active" : "card"}
                                        showDetails={!!isShowCardDetails}
                                        cardInformationResponse={cardInformationResponse}
                                        isCardInformationLoading={isCardInformationLoading}
                                    />
                                    {props.selectedCard?.card?.isActive || props.selectedCard?.card?.status === CardStatus.FROZEN ? (
                                        <div
                                            className="flex w-full items-center justify-center pb-6 pt-6"
                                            data-type={props.isSelectedCardDetailsOpen ? "card-active" : "card"}
                                        >
                                            <div
                                                className="flex max-w-md space-x-2"
                                                data-type={props.isSelectedCardDetailsOpen ? "card-active" : "card"}
                                            >
                                                {props.selectedCard?.card &&
                                                props.selectedCard?.card.isActive &&
                                                props.selectedCard?.card.walletType === CardWalletType.INDEPENDENT_WALLET &&
                                                !props.selectedCard?.card.linkedAccount ? (
                                                    <ButtonComp
                                                        type="submit"
                                                        color="blue"
                                                        ripple="light"
                                                        buttonType="primary"
                                                        disable={false}
                                                        isLoading={isActivateCardLoading}
                                                        func={() => {
                                                            navigate("/payments/make/single", {
                                                                state: {
                                                                    typeOfTransfer: PaymentsType.FUND_CARD,
                                                                    to: props.selectedCard?.card?.id || "",
                                                                    pageFrom: "cards",
                                                                    temp: true,
                                                                },
                                                            });
                                                        }}
                                                        size="sm"
                                                    >
                                                        <span className="w-full text-center">Fund Card</span>
                                                    </ButtonComp>
                                                ) : null}
                                                {!isShowCardDetails &&
                                                isCardAssignedToUser &&
                                                props.selectedCard?.card &&
                                                props.selectedCard?.card?.isActive ? (
                                                    <ButtonComp
                                                        type="submit"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        disable={isCardInformationLoading}
                                                        isLoading={isCardInformationLoading}
                                                        func={() => {
                                                            cardInformationResponse
                                                                ? setIsShowCardDetails(true)
                                                                : void handleCardInformation({
                                                                      createRequestId: props.selectedCard?.createRequest.id || "",
                                                                  });
                                                        }}
                                                        size="sm"
                                                    >
                                                        <span className="w-full text-center">Show Card Details</span>
                                                    </ButtonComp>
                                                ) : null}
                                                {isShowCardDetails &&
                                                isCardAssignedToUser &&
                                                props.selectedCard?.card &&
                                                props.selectedCard?.card?.isActive ? (
                                                    <ButtonComp
                                                        type="submit"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        disable={isCardInformationLoading}
                                                        isLoading={isCardInformationLoading}
                                                        func={() => setIsShowCardDetails(false)}
                                                        size="sm"
                                                    >
                                                        <span className="w-full text-center">Hide Card Details</span>
                                                    </ButtonComp>
                                                ) : null}
                                                {props.selectedCard?.card?.status === CardStatus.FROZEN ? (
                                                    <ButtonComp
                                                        type="submit"
                                                        color="blue"
                                                        ripple="light"
                                                        buttonType="primary"
                                                        disable={false}
                                                        isLoading={isUnFreezeCardLoading}
                                                        func={() => {
                                                            if (props.selectedCard?.createRequest.id)
                                                                handleUnFreezeCard({ createRequestId: props.selectedCard?.createRequest.id });
                                                        }}
                                                        size="sm"
                                                    >
                                                        <WhiteFreezeIcon className="fill-current stroke-current" />
                                                        <span className="ml-2 w-full text-center">Unfreeze Card</span>
                                                    </ButtonComp>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.CARD_REQUEST_STATUS_UPDATE_SECTION) &&
                            !(
                                props.selectedCard?.card?.isActive ||
                                props.selectedCard?.card?.isFrozen ||
                                props.selectedCard?.card?.isExpired ||
                                props.selectedCard?.card?.isDeactivated
                            ) ? (
                                <CardRequestStatusUpdate
                                    data={props.selectedCard}
                                    onActivateCard={handleActivateCard}
                                    isActivateCardLoading={isActivateCardLoading}
                                    isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen}
                                />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.ACTIVATE_CARD) ? (
                                <ActivatedCard
                                    cardId={props.selectedCard.createRequest.id || ""}
                                    cardPin={cardPin}
                                    isPrepaidCard={
                                        props.selectedCard.card?.walletType === CardWalletType.INDEPENDENT_WALLET &&
                                        !props.selectedCard.card?.linkedAccount
                                    }
                                    isGetCardPinLoading={isGetCardPinLoading}
                                />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.REQUEST_DEFAULT_PIN) ? (
                                <RequestDefaultPIN
                                    cardId={props.selectedCard.createRequest.id || ""}
                                    cardPin={cardPin}
                                    isPrepaidCard={
                                        props.selectedCard.card?.walletType === CardWalletType.INDEPENDENT_WALLET &&
                                        !props.selectedCard.card?.linkedAccount
                                    }
                                    isGetCardPinLoading={isGetCardPinLoading}
                                    handleGetCardPinCard={handleGetCardPinCard}
                                />
                            ) : null}

                            {activeCardTraySections?.includes(CardSectionEnum.ACCOUNT_LINKED_TO_CARD) && props.selectedCard.card?.linkedAccount ? (
                                <CardLinkedTo cardData={props.selectedCard} isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen} />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.ASSIGN_CARD_TO_SECTION) ? (
                                <CardAssignedTo
                                    cardData={props.selectedCard}
                                    isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen}
                                    handleReassignCard={() => {
                                        onOpenReassignCardCardSection(props.selectedCard?.createRequest.id || "");
                                    }}
                                    handleBack={() => {
                                        dispatch(removeFromActiveOpenedReassignCardForms(props.selectedCard?.createRequest.id || ""));
                                        onOpenDefaultCardSection();
                                    }}
                                />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.SECURITY_CVV) ? (
                                <SetSecurity
                                    cardData={props.selectedCard}
                                    isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen}
                                    handleBack={onOpenDefaultCardSection}
                                />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.UPDATE_CARD_SPENDING_LIMIT_SECTION) ? (
                                <UpdateSpendingLimit
                                    data={props.selectedCard}
                                    handleBack={onOpenDefaultCardSection}
                                    isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen}
                                />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.RESENT_TRANSACTION_SECTION) &&
                            (props.selectedCard?.card?.isActive || props.selectedCard?.card?.isFrozen || props.selectedCard?.card?.isDeactivated) ? (
                                <RecentTransactions cardId={props.selectedCard.card.id} isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen} />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.CANCEL_CARD_SECTION) ? (
                                <CancelCard cardData={props.selectedCard} isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen} />
                            ) : null}
                            {activeCardTraySections?.includes(CardSectionEnum.SET_CARD_PAYMENT_CHANNEL_SECTION) ? (
                                <SetPaymentChannel
                                    cardData={props.selectedCard}
                                    isSelectedCardDetailsOpen={props.isSelectedCardDetailsOpen}
                                    handleBack={onOpenDefaultCardSection}
                                />
                            ) : null}
                        </div>
                    </FullPageTrayBody>
                </FullPageTray>
            )}
        </>
    );
}

export default CardDetails;
