import React, { useEffect, useRef, useState } from "react";

import ButtonComp from "../../button/ButtonComp";
import useClickOutside from "../../../hooks/useClickOutside";

interface SendableTextareaProps {
    sm?: boolean;
    label?: string;
    value: string | null;
    isLoading?: boolean;
    buttonText: string;
    placeholder?: string;
    transactionCard?: boolean;

    onClickFunc: (e: React.MouseEvent) => void;
    onChangeFunc: (newValue: string) => void;
}

function SendableTextarea({ label, onChangeFunc, isLoading, value, transactionCard, buttonText }: SendableTextareaProps): JSX.Element {
    const [active, setActive] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const domNode = useClickOutside(() => setActive(false));

    useEffect(() => {
        setHasValue(!!value && value.trim().length > 0);
    }, [value]);

    return (
        <div ref={domNode} className={`relative ${isLoading ? "pointer-events-none" : ""}`} data-type={transactionCard ? "transaction-active" : ""}>
            <div
                className={
                    `relative w-full cursor-text overflow-hidden rounded-lg pb-3 pt-1 capitalize outline-none transition-all duration-75 focus:outline-none ` +
                    `whitespace-nowrap border border-solid bg-transparent text-base leading-relaxed shadow-none hover:text-black lg:hover:border-black` +
                    `${isLoading ? "pointer-events-none" : "pointer-events-auto"} ` +
                    `${isHover ? "text-black" : ""} ` +
                    `${
                        hasValue
                            ? !active
                                ? "border-black-quin text-black-secondary hover:text-black lg:hover:border-black"
                                : active
                                  ? "border-black"
                                  : "border-black-quin text-black-tertiary hover:text-black lg:hover:border-black"
                            : active
                              ? "border-black text-black"
                              : "border-black-quin text-black-tertiary"
                    } `
                }
                onFocus={() => {
                    if (textAreaRef.current) {
                        textAreaRef.current.focus();
                    }
                    setActive(true);
                }}
                onBlur={() => {
                    setActive(false);
                }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => {
                    if (textAreaRef.current) {
                        textAreaRef.current.focus();
                    }
                    setActive(true);
                }}
                data-type={transactionCard ? "transaction-active" : ""}
            >
                <textarea
                    className={`z-10 h-full w-full resize-none px-4 placeholder-transparent focus:border-none focus:outline-none ${hasValue ? "text-black-secondary" : ""} text-black`}
                    ref={textAreaRef}
                    value={value || ""}
                    // placeholder={placeholder}
                    onClick={() => setActive(true)}
                    onChange={(e) => {
                        onChangeFunc(e.target.value);
                    }}
                    data-type={transactionCard ? "transaction-active" : ""}
                />
                <label
                    htmlFor="text"
                    className={`space-x-none absolute z-10 cursor-text duration-75 ease-in-out ${
                        active || hasValue ? "hidden" : "left-4 top-3 text-base"
                    } ${active ? "text-black" : ""} ${active ? "text-black" : hasValue ? "text-black-quat" : "text-black-quat"} ${isHover ? "text-black" : ""}`}
                    onClick={() => {
                        if (domNode.current) {
                            domNode.current.focus();
                        }
                    }}
                    data-type={transactionCard ? "transaction-active" : ""}
                >
                    {label}
                </label>
                <div className="flex w-full flex-row items-end justify-end pr-4" data-type={transactionCard ? "transaction-active" : ""}>
                    <ButtonComp type="submit" buttonType="primary" color="black" size="sm" isLoading={isLoading} disable={!hasValue} borderSmall>
                        <span className="text-sm">{buttonText}</span>
                    </ButtonComp>
                </div>
            </div>
        </div>
    );
}

export default SendableTextarea;
