import BusinessDetails from "../../../../../../models/account-opening/businessDetails";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import { hintMarkItem } from "../../../components/element/hint/hint.constant";

export type UpdateBusinessDetailsRequest = {
    name: string;
    industryId: number;
    businessNature: string;
    operatingAddress: string;
};

export type BusinessDetailsResponse = {
    success: boolean;
    message: string;
    progress: NewAccountOpeningProgress;
    application: BusinessDetails;
};

export enum BusinessInformationFields {
    NAME = "name",
    TURNOVER = "turnover",
    INDUSTRY = "industryId",
    BUSINESS_NATURE = "businessNature",
    OPERATING_ADDRESS = "operatingAddress",
}

export type BusinessInformationForm = {
    [BusinessInformationFields.NAME]: string;
    [BusinessInformationFields.TURNOVER]: number | null;
    [BusinessInformationFields.INDUSTRY]: number | null;
    [BusinessInformationFields.BUSINESS_NATURE]: string;
    [BusinessInformationFields.OPERATING_ADDRESS]: string;
};

export const BusinessInformationFieldTexts: {
    [type in BusinessInformationFields]: string;
} = {
    [BusinessInformationFields.NAME]: "Legal Business Name",
    [BusinessInformationFields.TURNOVER]: "Annual Turnover",
    [BusinessInformationFields.INDUSTRY]: "Sector/Industry",
    [BusinessInformationFields.BUSINESS_NATURE]: "Describe Your Business",
    [BusinessInformationFields.OPERATING_ADDRESS]: "Operating Address",
};

export const BusinessInformationTextArray = [
    { formikName: BusinessInformationFields.NAME, text: BusinessInformationFieldTexts[BusinessInformationFields.NAME] },
    { formikName: BusinessInformationFields.TURNOVER, text: BusinessInformationFieldTexts[BusinessInformationFields.TURNOVER] },
    { formikName: BusinessInformationFields.INDUSTRY, text: BusinessInformationFieldTexts[BusinessInformationFields.INDUSTRY] },
    { formikName: BusinessInformationFields.BUSINESS_NATURE, text: BusinessInformationFieldTexts[BusinessInformationFields.BUSINESS_NATURE] },
    { formikName: BusinessInformationFields.OPERATING_ADDRESS, text: BusinessInformationFieldTexts[BusinessInformationFields.OPERATING_ADDRESS] },
];

export const BusinessInformationTextMap = BusinessInformationTextArray.reduce((accumulator, obj) => {
    return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getBusinessInformationHints(_formikList: string[], _uncompleted: string[], _exempted: string[]): hintMarkItem[] {
    let computedList: hintMarkItem[] = [];
    const _completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));
    for (const _string of _completed) {
        computedList.push({
            isChecked: true,
            text: BusinessInformationTextMap.get(_string) || "",
        });
    }
    for (const _string of _uncompleted) {
        computedList = computedList.map((_) =>
            _.text === BusinessInformationTextMap.get(_string)
                ? {
                      isChecked: false,
                      text: _.text,
                  }
                : _
        );
    }

    return computedList;
}
