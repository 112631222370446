import { useCallback, useState } from "react";

import { EditUserGroupRequest } from "../servicess/people-api.types";
import { MainInitCompanyDetailsInterface } from "../../../../redux/init/slice/initSlice.types";
import UserGroup from "../../../../models/userGroup";
import { editUserGroup } from "../servicess/peopleApi";
import { errorTrue } from "../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { mainUpdateCompanyDetails } from "../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseEditUserGroupInterface {
    userGroupName: string;
    editUserGroupError: string;
    editUserGroupResponse: UserGroup | null;
    isEditUserGroupLoading: boolean;

    handleResetAll: () => void;
    handleResetError: () => void;
    handleEditUserGroup: (_data: EditUserGroupRequest) => Promise<void>;
    handleChangeUserGroupName: (_name: string) => void;
}

function useEditUserGroup(): UseEditUserGroupInterface {
    const dispatch = useDispatch();

    const [userGroupName, setUserGroupName] = useState<string>("");

    const [editUserGroupError, setEditUserGroupError] = useState<string>("");
    const [editUserGroupResponse, setEditUserGroupResponse] = useState<UserGroup | null>(null);
    const [isEditUserGroupLoading, setIsEditUserGroupLoading] = useState<boolean>(false);

    const handleEditUserGroup = useCallback(
        async (_data: EditUserGroupRequest) => {
            try {
                setEditUserGroupError("");
                setIsEditUserGroupLoading(true);
                const res = await editUserGroup(_data);
                setEditUserGroupResponse(res.userGroup);
                // update in state.init.main.companyDetails.userGroups
                dispatch(
                    mainUpdateCompanyDetails({
                        callback: (companyDetails: MainInitCompanyDetailsInterface) => {
                            companyDetails.userGroups = companyDetails.userGroups.map((userGroup) => {
                                if (userGroup.id === res.userGroup.id) {
                                    return res.userGroup;
                                } else {
                                    return userGroup;
                                }
                            });
                        },
                    })
                );
                dispatch(messageTrue({ message: "Group Updated" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setEditUserGroupError(errorMessage);
            } finally {
                setIsEditUserGroupLoading(false);
            }
        },
        [dispatch]
    );

    const handleChangeUserGroupName = useCallback((_name: string) => {
        setUserGroupName(_name);
    }, []);

    const handleResetError = useCallback(() => {
        setEditUserGroupError("");
    }, []);

    const handleResetAll = useCallback(() => {
        setUserGroupName("");
        setEditUserGroupError("");
        setIsEditUserGroupLoading(false);
        setEditUserGroupResponse(null);
    }, []);

    return {
        userGroupName,
        editUserGroupError,
        editUserGroupResponse,
        isEditUserGroupLoading,

        handleResetAll,
        handleResetError,
        handleEditUserGroup,
        handleChangeUserGroupName,
    };
}

export default useEditUserGroup;
