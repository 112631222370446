import { immerable } from "immer";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";

export default class CardInformation {
    [immerable] = true;

    constructor(
        public pan: string,
        public cvv: string | null,
        public expiry: string,
        public accountType: string
    ) {}

    static create(obj: GenericObject): CardInformation {
        return new CardInformation(
            Parsers.string(obj.pan),
            Parsers.nullableString(obj.cvv),
            Parsers.string(obj.expiry),
            Parsers.string(obj.accountType)
        );
    }
}
