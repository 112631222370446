import { AddMoneyFundingStageType, AddMoneyFundingType } from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as ActiveStageIcon } from "../../../../../../assets/svg/dashboard/send-money/active-payment-stage.svg";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import { ReactComponent as InactiveStageIcon } from "../../../../../../assets/svg/dashboard/send-money/inactive-payment-stage.svg";
import PageLayout from "../../../../../../components/layouts/page-layout";
import React from "react";
import { useSelector } from "react-redux";

interface AddMoneyLayoutInterface {
    children: React.ReactNode;
}

type AddMoneyUrlParams = {
    urlFundingType: AddMoneyFundingType | undefined;
};

function AddMoneyLayout({ children }: AddMoneyLayoutInterface): JSX.Element {
    const navigate = useNavigate();
    const paymentStage = useSelector((state: IRootState) => state.addMoney.fundingStage);
    const params = useParams<AddMoneyUrlParams>();

    return (
        <>
            <PageLayout
                pageTitle="Add Money"
                subTitle="Add Money to your Lenco account"
                headerButtons={
                    paymentStage === AddMoneyFundingStageType.INITIAL && (
                        <ButtonComp size="md" color="grey" buttonType="secondary" func={() => navigate("/payments/add-money/history")} borderSmall>
                            <span className="text-black">Add Money History</span>
                        </ButtonComp>
                    )
                }
            >
                {paymentStage !== AddMoneyFundingStageType.PROCESS_PAYMENT && params.urlFundingType !== AddMoneyFundingType.BANK_TRANSFER && (
                    <div className="hidden h-11 min-h-[44px] w-max flex-row items-center justify-center space-x-4 self-center overflow-x-auto rounded-md bg-white px-6 lg:flex">
                        <div className="flex items-center justify-start space-x-2">
                            {paymentStage > AddMoneyFundingStageType.INITIAL && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {paymentStage === AddMoneyFundingStageType.INITIAL && <ActiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${paymentStage > AddMoneyFundingStageType.INITIAL ? "font-medium" : ""} ` +
                                    `${paymentStage === AddMoneyFundingStageType.INITIAL ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                {/* Type of Funding */}
                                Select account to fund
                            </p>
                        </div>
                        <div className="h-[1px] w-full min-w-6 max-w-6 bg-grey"></div>
                        <div className="flex items-center justify-start space-x-2">
                            {paymentStage > AddMoneyFundingStageType.PAYMENT_DETAILS && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {paymentStage === AddMoneyFundingStageType.PAYMENT_DETAILS && <ActiveStageIcon />}
                            {paymentStage < AddMoneyFundingStageType.PAYMENT_DETAILS && <InactiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${paymentStage > AddMoneyFundingStageType.PAYMENT_DETAILS ? "font-medium" : "text-sm"} ` +
                                    `${paymentStage === AddMoneyFundingStageType.PAYMENT_DETAILS ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Add money details
                                {/* Payment Details */}
                            </p>
                        </div>
                    </div>
                )}
                <div
                    className={
                        "flex w-full max-w-xl flex-col self-center" + `${paymentStage !== AddMoneyFundingStageType.PROCESS_PAYMENT ? " pt-12" : ""}`
                    }
                >
                    {children}
                </div>
            </PageLayout>
        </>
    );
}

export default AddMoneyLayout;
