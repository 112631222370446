import { MobileTeamMemberListPageState, TeamMemberListMobileBreakpoint } from "../team-members.constants";
import { TeamMemberItem, TeamMembersMap } from "../team-members.types";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../redux/rootReducer";
import { PageTitle } from "../../../../../../helpers/AppConstants";
import User from "../../../../../../models/user";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import { classArrayToMap } from "../../../../../../utils/map";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { listTeamMembers } from "../team-members.api";
import { produce } from "immer";
import useDimension from "../../../../../../hooks/useDimension";

export interface useTeamMemberListResponse {
    filterTerm: string;
    isMobileLayout: boolean;
    selectedTeamMember: TeamMemberItem | null;
    filteredTeamMembers: TeamMemberItem[];
    isLoadingTeamMembers: boolean;
    mobileLayoutPageState: MobileTeamMemberListPageState;
    isSelectedTeamMemberLoading: boolean;

    // setFilterTerm: Dispatch<SetStateAction<string>>;
    addNewTeamMember: (item: TeamMemberItem) => void;
    removeTeamMember: (memberId: string) => void;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    handleSetFilterTerm: (_text: string) => void;
    backToMobileListPage: () => void;
    handleAddNewAccounts: (item: TeamMemberItem, _accounts: UserAccountMeta[]) => void;
    handleSelectTeamMember: (id: string) => void;
    removeAccountMetaFromTeamMember: (accountMetaToRemove: UserAccountMeta) => void;
    handleNameAbbrev: (name: string) => string;

    userAccountNames: Map<string, string>;
    user: User | undefined;
}

function useTeamMemberList(): useTeamMemberListResponse {
    const { width } = useDimension();
    const dispatch = useDispatch();

    const user = useSelector<IRootState, User | undefined>((state) => state.init.main?.companyDetails?.user);
    const userAccountNames: Map<string, string> = useSelector<IRootState, Map<string, string>>((state) => {
        const res = new Map<string, string>();
        state.init.main?.companyDetails?.accounts.forEach((a) => {
            res.set(a.id, a.accountName || a.lencoNameMin);
        });
        return res;
    });

    const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);
    const [isSelectedTeamMemberLoading, setIsSelectedTeamMemberLoading] = useState<boolean>(false);
    const [mobileLayoutPageState, setMobileLayoutPageState] = useState<MobileTeamMemberListPageState>(MobileTeamMemberListPageState.LIST);
    const [selectedTeamMember, setSelectedTeamMember] = useState<TeamMemberItem | null>(null);
    const [filterTerm, setFilterTerm] = useState<string>("");

    const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);
    const [teamMembers, setTeamMembers] = useState<Map<string, TeamMemberItem>>(new Map());
    const [teamMembersArray, setTeamMembersArray] = useState<Array<TeamMemberItem>>([]);
    const [filteredTeamMembers, setFilteredTeamMembers] = useState<Array<TeamMemberItem>>([]);

    useLayoutEffect(() => {
        document.title = PageTitle.TEAM_MEMBERS_SETTINGS_PAGE;
    }, []);

    const getDefaultTeamMember = useCallback((): TeamMemberItem | null => {
        const teamMemberIds = Array.from(teamMembers.keys());
        const defaultMemberId = teamMemberIds.find((memberId) => memberId === user?.id) || teamMemberIds[0];
        return teamMembers.get(defaultMemberId) || null;
    }, [user, teamMembers]);

    const handleGetTeamMembers = useCallback(async () => {
        setIsLoadingTeamMembers(true);

        try {
            const res = await listTeamMembers();
            setTeamMembersArray(res);
            setTeamMembers(classArrayToMap(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsLoadingTeamMembers(false);
        }
    }, [dispatch]);

    const handleSelectTeamMember = useCallback(
        (id: string) => {
            const teamMember = teamMembers.get(id);
            if (teamMember) {
                setSelectedTeamMember(teamMember);
                setMobileLayoutPageState(MobileTeamMemberListPageState.DETAILS);
                // setFilterTerm("");
            }
        },
        [teamMembers]
    );

    const addNewTeamMember = useCallback((item: TeamMemberItem) => {
        setTeamMembers(
            produce((draft: TeamMembersMap) => {
                draft.set(item.id, item);
            })
        );
        setSelectedTeamMember(item);
        setTeamMembersArray(
            produce((draft: TeamMemberItem[]) => {
                draft.push(item);
            })
        );
    }, []);

    const handleAddNewAccounts = useCallback(
        (item: TeamMemberItem, _accounts: UserAccountMeta[]) => {
            setTeamMembers(
                produce((draft: TeamMembersMap) => {
                    (draft.get(item.id) as TeamMemberItem).accountsMeta.push(..._accounts);
                })
            );
        },
        [teamMembers]
    );

    const backToMobileListPage = useCallback(() => {
        setMobileLayoutPageState(MobileTeamMemberListPageState.LIST);
    }, []);

    const updateAccountMeta = useCallback((updatedAccountMeta: UserAccountMeta) => {
        // use the id and userAccountId to find the teamMember.
        // And replace the previous accountMeta with the updatedAccountMeta in teamMember.accountsMeta
        setTeamMembers(
            produce((draft: TeamMembersMap) => {
                const teamMemberItem = draft.get(updatedAccountMeta.id);
                if (teamMemberItem) {
                    teamMemberItem.accountsMeta = teamMemberItem.accountsMeta.map((a) => {
                        if (a.id === updatedAccountMeta.id && a.userAccountId === updatedAccountMeta.userAccountId) {
                            return updatedAccountMeta;
                        }
                        return a;
                    });
                }
            })
        );
    }, []);

    const removeTeamMember = useCallback((memberId: string) => {
        setTeamMembers(
            produce((draft: TeamMembersMap) => {
                draft.delete(memberId);
            })
        );
        setTeamMembersArray((prev) => prev.filter((el) => el.teamMember.id !== memberId));
    }, []);

    const handleSetFilterTerm = useCallback((_text: string) => {
        setFilterTerm(_text);
    }, []);

    const removeAccountMetaFromTeamMember = useCallback((accountMetaToRemove: UserAccountMeta) => {
        setTeamMembers(
            produce((draft: TeamMembersMap) => {
                const teamMemberItem = draft.get(accountMetaToRemove.id);
                if (teamMemberItem) {
                    teamMemberItem.accountsMeta = teamMemberItem.accountsMeta.filter(
                        (item) => item.userAccountId !== accountMetaToRemove.userAccountId
                    );
                }
            })
        );
    }, []);

    const handleNameAbbrev = (name: string | undefined) => {
        if (name === undefined) {
            return "NA";
        }
        if (name.split(" ").length < 2) {
            return name.split(" ")[0].substring(0, 2);
        }

        const abbrev = name.split(" ")[0].charAt(0) + name.split(" ")[1].charAt(0);
        return abbrev;
    };

    // if teamMembers changes, update selectedTeamMember or choose a default one
    useEffect(() => {
        setSelectedTeamMember((prev: TeamMemberItem | null) => {
            if (!prev || !teamMembers.has(prev.id)) {
                return getDefaultTeamMember();
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return teamMembers.get(prev.id)!; // I added non-null assertion here because I already checked if the map contains the id above
        });
        setIsSelectedTeamMemberLoading(true);
    }, [teamMembers]);

    // restructure page when width is small
    useEffect(() => {
        setIsMobileLayout(width <= TeamMemberListMobileBreakpoint);
    }, [width]);

    // get list of team members, on mount
    useEffect(() => {
        void handleGetTeamMembers();
    }, []);

    useEffect(() => {
        const filtered: TeamMemberItem[] = (teamMembersArray || []).filter((item: TeamMemberItem) => {
            if (!item || !item.teamMember.name) {
                return false;
            }
            if (!filterTerm || filterTerm.trim().length === 0) {
                return true;
            }
            return item.teamMember.name.toLowerCase().includes(filterTerm.toLowerCase());
        });
        setFilteredTeamMembers(filtered);
    }, [teamMembersArray, filterTerm]);

    return {
        user,
        filterTerm,
        isMobileLayout,
        userAccountNames,
        selectedTeamMember,
        filteredTeamMembers,
        isLoadingTeamMembers,
        mobileLayoutPageState,
        isSelectedTeamMemberLoading,

        removeTeamMember,
        addNewTeamMember,
        updateAccountMeta,
        handleSetFilterTerm,
        backToMobileListPage,
        handleAddNewAccounts,
        handleSelectTeamMember,
        removeAccountMetaFromTeamMember,
        handleNameAbbrev,
    };
}

export default useTeamMemberList;
