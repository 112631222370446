import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { IRootState } from "../../../../redux/rootReducer";
import Parsers from "../../../../utils/parsers";
import { ZambiaCollectionsStageType } from "../../../../redux/zambia/collections/collectionsSlice.types";
import PageLayout from "../../../../components/layouts/page-layout";
import ZambiaCollectionLayout from "../Components/Collections/Layout/CollectionLayout";
import ZambiaCollectionTransactions from "../Components/Collections/Transactions";
import ZambiaCollectionSettlements from "../Components/Collections/Settlements";
import { setZambiaCollectionsStage } from "../../../../redux/zambia/collections/collectionsSlice";
import useLencoPayDefaultSetupInit from "../hookss/DefaultSetup/useLencoPayDefaultSetupInit";

export type ZambiaCollectionParams = {
    type: string;
};

function ZambiaCollections(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { type } = useParams<ZambiaCollectionParams>();

    const { handleLencoPayDefaultSetupInit } = useLencoPayDefaultSetupInit();
    const collectionsStage = useSelector((state: IRootState) => state.zambiaCollections.collectionsStage);

    useEffect(() => {
        const collectionsType = Parsers.nullableEnum(type, ZambiaCollectionsStageType);
        handleLencoPayDefaultSetupInit();
        if (collectionsType) {
            dispatch(setZambiaCollectionsStage(collectionsType));
        } else {
            navigate("/lenco-pay/collections/transactions");
        }
    }, [type]);

    return (
        <>
            <PageLayout pageTitle="Collections" subTitle="List of your collections and settlements">
                <ZambiaCollectionLayout>
                    {collectionsStage === ZambiaCollectionsStageType.TRANSACTION && (
                        <>
                            <ZambiaCollectionTransactions />
                        </>
                    )}
                    {collectionsStage === ZambiaCollectionsStageType.SETTLEMENTS && (
                        <>
                            <ZambiaCollectionSettlements />
                        </>
                    )}
                </ZambiaCollectionLayout>
            </PageLayout>
        </>
    );
}

export default ZambiaCollections;
