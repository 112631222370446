import {
    ZambiaApplicationStagesType,
    ZambiaUnregisteredMeansOfIDStages,
    ZambiaUnregisteredPersonalStages,
} from "../../../../../services/zambia-application/zambia-application.types";

import { ApplicationSectionType } from "../../../../../services/application.constants";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import LencoSpinner from "../../../../../../../../components/spinner";
import MeansOfId from "../../registered-business/account-signatories/components/means-of-id";
import PersonalDetails from "../../registered-business/account-signatories/components/personal-details";
import Photograph from "../../registered-business/account-signatories/components/photograph";
import useAccountSignatoriesInit from "../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useAccountSignatoriesInit";
import { useLayoutEffect } from "react";
import { useParams } from "react-router";

type ApplicationUrlParams = {
    stage: ZambiaApplicationStagesType | undefined;
    section: ApplicationSectionType;
};
function PersonalInformation(): JSX.Element {
    const { stage } = useParams<ApplicationUrlParams>();

    const { currentSignatoryError, isCurrentSignatoryLoading, handleReset, handleCurrentSignatoryInit } = useAccountSignatoriesInit();

    useLayoutEffect(() => {
        void handleCurrentSignatoryInit();
        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            {isCurrentSignatoryLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {currentSignatoryError && (
                <div className="flex h-full w-full items-start justify-center">
                    <ErrorToast error={currentSignatoryError} />
                </div>
            )}
            {!isCurrentSignatoryLoading && !currentSignatoryError && (
                <div className="flex h-full w-full flex-row items-start justify-start">
                    {stage === ZambiaUnregisteredPersonalStages.PERSONAL_DETAILS && <PersonalDetails />}
                    {stage === ZambiaUnregisteredMeansOfIDStages.ID && <MeansOfId />}
                    {stage === ZambiaUnregisteredMeansOfIDStages.PHOTOGRAPH && <Photograph />}
                </div>
            )}
        </>
    );
}
export default PersonalInformation;
