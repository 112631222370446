import { setElectricityPurchaseAmount } from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseElectricityPurchaseAmountInterface {
    handleAmountChange: (_amount: string) => void;
}

function useElectricityPurchaseAmount(): UseElectricityPurchaseAmountInterface {
    const dispatch = useDispatch();

    const handleAmountChange = useCallback((_amount: string) => {
        dispatch(setElectricityPurchaseAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useElectricityPurchaseAmount;
