import { resetAllApplicationState, updateBusinessDetails } from "../../../../redux/application/applicationSlice";
import { resetZambiaAllApplicationState, updateZambiaBusinessDetailsType } from "../../../../redux/application/zambiaApplicationSlice";
import { useCallback, useState } from "react";

import { Routes } from "../../../../routes/routes.constants";
import { UpdateNigeriaBusinessTypeRequest } from "../services/nigeria-application/nigeria-application.types";
import { errorTrue } from "../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { updateNigeriaBusinessType } from "../services/nigeria-application/nigeiraAccountOpeningInit";
import { updateZambiaBusinessType } from "../services/zambia-application/zambiaAccountOpeningInit";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

interface useSelectBusinessTypeInterface {
    isRegistrationStatusLoading: boolean;
    handleReset: () => void;
    handleSetZambiaRegistrationStatus: (_data: UpdateNigeriaBusinessTypeRequest) => Promise<void>;
    handleSetNigeriaRegistrationStatus: (_data: UpdateNigeriaBusinessTypeRequest) => Promise<void>;
}

const useSelectBusinessType = (): useSelectBusinessTypeInterface => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isRegistrationStatusLoading, setIsRegistrationStatusLoading] = useState<boolean>(false);

    const handleSetNigeriaRegistrationStatus = useCallback(
        async (_data: UpdateNigeriaBusinessTypeRequest): Promise<void> => {
            try {
                setIsRegistrationStatusLoading(true);
                const res = await updateNigeriaBusinessType(_data);
                dispatch(updateBusinessDetails(res.applicationMin));
                navigate(Routes.ACCOUNT_OPENING.STATUS);
                dispatch(resetZambiaAllApplicationState());
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsRegistrationStatusLoading(false);
            }
        },
        [dispatch]
    );

    const handleSetZambiaRegistrationStatus = useCallback(
        async (_data: UpdateNigeriaBusinessTypeRequest): Promise<void> => {
            try {
                setIsRegistrationStatusLoading(true);
                const res = await updateZambiaBusinessType(_data);
                dispatch(updateZambiaBusinessDetailsType(res.isRegistered));
                navigate(Routes.ACCOUNT_OPENING.STATUS);
                dispatch(resetAllApplicationState());
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsRegistrationStatusLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = () => {
        setIsRegistrationStatusLoading(false);
    };

    return {
        isRegistrationStatusLoading,
        handleReset,
        handleSetZambiaRegistrationStatus,
        handleSetNigeriaRegistrationStatus,
    };
};
export default useSelectBusinessType;
