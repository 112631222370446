function LockedAccountDetailsCard(): JSX.Element {
    return (
        <>
            <div className="flex max-h-80 flex-col space-y-3 rounded-xl px-10 py-7 shadow">
                <span className="text-sm text-black-tertiary">Lenco</span>
                <div>
                    <h1 className="text-base font-medium text-black">Account Name</h1>
                    <p className="text-sm leading-3">Alias - Company Name</p>
                </div>
                <div className="space-y-3 text-sm">
                    <div className="flex flex-col items-start justify-start space-y-3 xs:flex-row xs:items-center xs:justify-between">
                        <span>Account Number</span>
                        <div className="xs:space-y-none flex w-full flex-row items-center justify-between rounded-lg bg-grey-backdrop px-6 py-2 xs:ml-2 xs:w-70%">
                            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">7900XXXXXX</span>
                            <span className="ml-2 whitespace-nowrap text-xs text-blue">Unlock Account</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start space-y-3 xs:flex-row xs:items-center xs:justify-between">
                        <span>Bank</span>
                        <div className="flex w-full flex-row items-center justify-between rounded-lg bg-grey-backdrop px-6 py-2 xs:ml-2 xs:w-70%">
                            <span className="text-black-tertiary">Partner bank is unavailable</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start space-y-3 xs:flex-row xs:items-center xs:justify-between">
                        <span>Address</span>
                        <div className="flex w-full flex-row items-center justify-between rounded-lg bg-grey-backdrop px-6 py-2 xs:ml-2 xs:w-70%">
                            <span className="text-black-tertiary">Partner bank address is unavailable</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LockedAccountDetailsCard;
