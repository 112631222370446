export enum BankType {
    COMMERCIAL_BANK = 1,
    MICRO_FINANCE_BANK = 2,
    MOBILE_MONEY = 3,
    MERCHANT_BANK = 4,
    COMMUNITY_BANK = 5,
    PAYMENT_SOLUTION = 6,
    DISCOUNT_HOUSE = 7,
    OTHER_FINANCIAL_INSTITUTION = 8,
}
