import { BusinessDocumentResponse, DeleteIncorporationDocumentRequest, IncorporationDocumentRequest, UtilityBillRequest } from "./document.types";
import {
    ErrorMessage,
    makeRequestDownloadFile,
    makeRequestUploadFile,
    makeRequestUploadFileWithSignal,
    makeRequestWithSignal,
} from "../../../../../../helpers/request/makeRequest";
import { abortRequest, getAbortControllerSignal } from "../../../../../../helpers/request/abortControllers";

import BusinessDetailsMin from "../../../../../../models/account-opening/businessDetailsMin";
import { GenericObject } from "../../../../../../helpers/types";
import IncorporationDocumentNigeria from "../../../../../../models/account-opening/incorporation-documents/incorporationDocumentNigeria";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../utils/parsers";
import UtilityBill from "../../../../../../models/account-opening/utilityBill";
import { toFormData } from "../../../../../../utils/converters";

export enum BusinessDocumentRequests {
    INIT = "business-document.init",
    UPLOAD_INCORPORATION_DOCUMENT = "business-document.upload-incorporation-document",
    ADD_INCORPORATION_DOCUMENT_FILE = "business-document.add-incorporation-document-file",
    DELETE_INCORPORATION_DOCUMENT = "business-document.delete-incorporation-document",
    UPLOAD_UTILITY_BILL = "business-document.upload-utility-bill",
    ADD_UTILITY_BILL_FILE = "business-document.add-utility-bill-file",
    DELETE_UTILITY_BILL = "business-document.delete-utility-bill",
}

function getBusinessDocumentResponse(res: GenericObject | Error): BusinessDocumentResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        applicationMin: Parsers.classObjectNonNullable(res.applicationMin, BusinessDetailsMin),
        incorporationDocuments: Parsers.classObjectArray(res.incorporationDocuments, IncorporationDocumentNigeria),
        utilityBill: Parsers.classObject(res.utilityBill, UtilityBill),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
    };
}

export async function documentDetailsInit(): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/ng/document/details", {}, signal);
    return getBusinessDocumentResponse(res);
}
export async function uploadIncorporationDocument(_data: IncorporationDocumentRequest): Promise<BusinessDocumentResponse> {
    // const signal = getAbortControllerSignal(BusinessDocumentRequests.UPLOAD_INCORPORATION_DOCUMENT);
    const res = await makeRequestUploadFile("/account-opening/ng/document/incorporation-document/upload", toFormData(_data));
    return getBusinessDocumentResponse(res);
}
export async function addIncorporationDocumentFile(_data: IncorporationDocumentRequest): Promise<BusinessDocumentResponse> {
    // const signal = getAbortControllerSignal(BusinessDocumentRequests.ADD_INCORPORATION_DOCUMENT_FILE);
    const res = await makeRequestUploadFile("/account-opening/ng/document/incorporation-document/add-file", toFormData(_data));
    return getBusinessDocumentResponse(res);
}
export async function deleteIncorporationDocument(_data: DeleteIncorporationDocumentRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.DELETE_INCORPORATION_DOCUMENT);
    const res = await makeRequestWithSignal("/account-opening/ng/document/incorporation-document/delete", _data, signal);
    return getBusinessDocumentResponse(res);
}
export async function uploadUtilityBill(_data: UtilityBillRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.UPLOAD_UTILITY_BILL);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/document/utility-bill/upload", toFormData(_data), signal);
    return getBusinessDocumentResponse(res);
}
export async function addUtilityBillFile(_data: UtilityBillRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.ADD_UTILITY_BILL_FILE);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/document/utility-bill/add-file", toFormData(_data), signal);
    return getBusinessDocumentResponse(res);
}
export async function deleteUtilityBill(): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.DELETE_UTILITY_BILL);
    const res = await makeRequestWithSignal("/account-opening/ng/document/utility-bill/delete", {}, signal);
    return getBusinessDocumentResponse(res);
}

export async function downloadDocument(documentId: string): Promise<Blob> {
    const res = await makeRequestDownloadFile("/account-opening/ng/download", { id: documentId });
    if (res instanceof Error) {
        throw new Error(ErrorMessage.GENERIC_ERROR);
    }
    return res;
}

export function abortBusinessDocumentRequest(type: BusinessDocumentRequests): void {
    abortRequest(type);
}

export function abortAllBusinessDocumentRequests(): void {
    Object.values(BusinessDocumentRequests).forEach((type) => abortRequest(type));
}
