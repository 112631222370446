import { FilterDateObj, FilterDateType, FilterDateTypes } from "../../../../../../../components/filter/filter.constant";
import React, { useEffect, useState } from "react";
import {
    ZambiaCollectionPaymentChannel,
    ZambiaCollectionSource,
    ZambiaCollectionStatus,
} from "../../../../../../../models/zambia/zambiaCollectionRequest.constant";
import {
    ZambiaCollectionsTransactionsChannelOptions,
    ZambiaCollectionsTransactionsSourceOptions,
    ZambiaCollectionsTransactionsStatusOptions,
} from "../../../../hookss/Collections/zambiaCollectionsTransactions.constants";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../../../../../components/dropdown/custom-select";
import DateModal from "../../../../../../../components/modal/date-modal";
import { DateParse } from "../../../../../../../utils/dateParsers";
import ExportModal from "../../../../../../../components/modal/export-modal";
import FullPageTray from "../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import { IRootState } from "../../../../../../../redux/rootReducer";
import Input from "../../../../../../../components/inputs/Input";
import { setIsExportZambiaCollectionsTransactionsTrayOpen } from "../../../../../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import useClickOutside from "../../../../../../../hooks/useClickOutside";
import useExportZambiaCollectionsTransactions from "../../../../hookss/Collections/useExportZambiaCollectionsTransactions";
import ButtonComp from "../../../../../../../components/button/ButtonComp";

const DATA_TYPE = "export-zambia-collections-transaction";

function ExportZambiaCollectionsTransactionsTray(): JSX.Element {
    const dispatch = useDispatch();

    const isExportZambiaCollectionsTransactionsTrayOpen: boolean = useSelector(
        (state: IRootState) => state.zambiaCollectionsTransactions.isExportZambiaCollectionsTransactionsTrayOpen
    );

    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [isExportedModalOpen, setIsExportedModalOpen] = useState(false);

    const [exportFilter, setExportFilter] = useState<{
        query: string;
        offset: number;
        status: ZambiaCollectionStatus[];
        source: ZambiaCollectionSource[];
        channel: ZambiaCollectionPaymentChannel[];
        selectedDateType: FilterDateTypes;
        date: FilterDateObj;
    }>({
        query: "",
        offset: 0,
        status: [],
        source: [],
        channel: [],
        selectedDateType: FilterDateTypes.ALL_TIME,
        date: {
            begin: null,
            end: null,
        },
    });

    const { isExportZambiaCollectionsTransactionsLoading, handleExportZambiaCollectionsTransactions } = useExportZambiaCollectionsTransactions({
        onComplete: () => {
            setIsExportedModalOpen(true);
            dispatch(setIsExportZambiaCollectionsTransactionsTrayOpen(false));
        },
    });

    useEffect(() => {
        if (!isExportZambiaCollectionsTransactionsTrayOpen) {
            setTimeout(() => {
                setExportFilter({
                    query: "",
                    offset: 0,
                    status: [],
                    source: [],
                    channel: [],
                    selectedDateType: FilterDateTypes.ALL_TIME,
                    date: {
                        begin: null,
                        end: null,
                    },
                });
            }, 500);
        }
    }, [isExportZambiaCollectionsTransactionsTrayOpen]);

    const handleCloseExportZambiaCollectionsTransactionsTray = () => {
        dispatch(setIsExportZambiaCollectionsTransactionsTrayOpen(false));
    };

    const domNode = useClickOutside(
        () => {
            handleCloseExportZambiaCollectionsTransactionsTray();
        },
        (eventTarget: HTMLElement) => {
            return !!(eventTarget.dataset.type !== DATA_TYPE || !eventTarget.dataset.type.includes(DATA_TYPE));
        },
        ["mousedown", "keydown"]
    );

    return (
        <>
            {/*<===Terminal Transaction Details Code goes here===>*/}
            {/* {zambiaCollectionsTransactionsDetail && ( */}
            <div className="w-full" ref={domNode}>
                <DateModal
                    isActive={isDateModalOpen}
                    is3MonthsMax
                    dataType={DATA_TYPE}
                    handleToggleModal={() => setIsDateModalOpen(false)}
                    handleSelectDate={(_data: FilterDateType) =>
                        setExportFilter((prev) => ({
                            ...prev,
                            selectedDateType: _data.name,
                            date: _data.date,
                        }))
                    }
                />
                <ExportModal active={isExportedModalOpen} handleClose={() => setIsExportedModalOpen(false)} />
                <FullPageTray
                    active={isExportZambiaCollectionsTransactionsTrayOpen}
                    dataType={DATA_TYPE}
                    handleReset={handleCloseExportZambiaCollectionsTransactionsTray}
                    // withClickOutside
                >
                    <FullPageTrayHeader toggler={handleCloseExportZambiaCollectionsTransactionsTray} dataType={DATA_TYPE}>
                        Export
                    </FullPageTrayHeader>
                    <FullPageTrayBody dataType={DATA_TYPE}>
                        <div className="mb-10 flex w-full flex-col space-y-4">
                            <div className="cursor-pointer" onClick={() => setIsDateModalOpen(true)}>
                                <Input
                                    type="text"
                                    placeholder="Date"
                                    name="date"
                                    value={
                                        exportFilter.date.begin && exportFilter.date.end
                                            ? `From ${DateParse.getDateString(exportFilter.date.begin)} To ${DateParse.getDateString(
                                                  exportFilter.date.end
                                              )}`
                                            : undefined
                                    }
                                    readOnly
                                    inputSize="lg"
                                />
                            </div>

                            <CustomSelect
                                placeholder="Channel"
                                value={exportFilter.channel[0]}
                                options={ZambiaCollectionsTransactionsChannelOptions.map((item) => ({
                                    text: item.name,
                                    value: item.value,
                                }))}
                                onSelect={(value) =>
                                    value &&
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        channel: prev.channel.some((_) => _ === value) ? prev.channel.filter((_) => _ !== value) : [value],
                                    }))
                                }
                                size="lg"
                                dropdownSize="lg"
                            />

                            <CustomSelect
                                placeholder="Status"
                                value={exportFilter.status[0]}
                                options={ZambiaCollectionsTransactionsStatusOptions.map((item) => ({
                                    text: item.name,
                                    value: item.value,
                                }))}
                                onSelect={(value) =>
                                    value &&
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        status: prev.status.some((_) => _ === value) ? prev.status.filter((_) => _ !== value) : [value],
                                    }))
                                }
                                dropdownSize="lg"
                                size="lg"
                            />
                            <CustomSelect
                                placeholder="Source"
                                value={exportFilter.source[0]}
                                options={ZambiaCollectionsTransactionsSourceOptions.map((item) => ({
                                    text: item.name,
                                    value: item.value,
                                }))}
                                onSelect={(value) =>
                                    value &&
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        source: prev.source.some((_) => _ === value) ? prev.source.filter((_) => _ !== value) : [value],
                                    }))
                                }
                                size="lg"
                                dropdownSize="lg"
                            />
                        </div>
                        <div className="flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-6">
                            <ButtonComp
                                fullWidth
                                type="button"
                                size="md"
                                buttonType="secondary"
                                onClick={handleCloseExportZambiaCollectionsTransactionsTray}
                                color={"grey"}
                            >
                                <span className="w-full text-center text-black-tertiary">Cancel</span>
                            </ButtonComp>

                            <ButtonComp
                                fullWidth
                                type="button"
                                size="md"
                                buttonType="primary"
                                disable={
                                    exportFilter.channel.length < 1 &&
                                    exportFilter.source.length < 1 &&
                                    exportFilter.status.length < 1 &&
                                    !exportFilter.date.begin
                                }
                                isLoading={isExportZambiaCollectionsTransactionsLoading}
                                onClick={() =>
                                    handleExportZambiaCollectionsTransactions({
                                        date: exportFilter.date,
                                        query: exportFilter.query,
                                        offset: exportFilter.offset,
                                        source: exportFilter.source[0],
                                        status: exportFilter.status[0],
                                        channel: exportFilter.channel[0],
                                    })
                                }
                                color={"black"}
                            >
                                <span className="w-full text-center">Export</span>
                            </ButtonComp>
                        </div>
                    </FullPageTrayBody>
                </FullPageTray>
            </div>
            {/* )} */}
        </>
    );
}

export default ExportZambiaCollectionsTransactionsTray;
