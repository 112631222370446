export enum Month {
    JANUARY = 0,
    FEBRUARY = 1,
    MARCH = 2,
    APRIL = 3,
    MAY = 4,
    JUNE = 5,
    JULY = 6,
    AUGUST = 7,
    SEPTEMBER = 8,
    OCTOBER = 9,
    NOVEMBER = 10,
    DECEMBER = 11,
}

export const Months: { [value: number]: string } = {
    [Month.JANUARY]: "January",
    [Month.FEBRUARY]: "February",
    [Month.MARCH]: "March",
    [Month.APRIL]: "April",
    [Month.MAY]: "May",
    [Month.JUNE]: "June",
    [Month.JULY]: "July",
    [Month.AUGUST]: "August",
    [Month.SEPTEMBER]: "September",
    [Month.OCTOBER]: "October",
    [Month.NOVEMBER]: "November",
    [Month.DECEMBER]: "December",
};

export enum WeekDay {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}

export const WeekDays: { [value: number]: string } = {
    [WeekDay.SUNDAY]: "Sunday",
    [WeekDay.MONDAY]: "Monday",
    [WeekDay.TUESDAY]: "Tuesday",
    [WeekDay.WEDNESDAY]: "Wednesday",
    [WeekDay.THURSDAY]: "Thursday",
    [WeekDay.FRIDAY]: "Friday",
    [WeekDay.SATURDAY]: "Saturday",
};

export enum DateFormat {
    DATE = "Y-m-d",
    DATETIME = "Y-m-d H:i:s",
    MONTH = "Y-m",
}
