import Agreement from "../../../../../../../models/account-opening/agreement/agreement";
import BusinessDetailsMin from "../../../../../../../models/account-opening/businessDetailsMin";
import CreatedBy from "../../../../../../../models/account-opening/createdBy";
import NewAccountOpeningProgress from "../../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import SignatoryMin from "../../../../../../../models/account-opening/signatoryInfoMin";
import UserMin from "../../../../../../../models/userMin";
import { hintMarkItem } from "../../../../components/element/hint/hint.constant";

export type UploadBusinessAgreementRequest = {
    type: AgreementType;
    file: File;
};
export type BusinessAgreementRequest = {
    type: AgreementType;
};
export type BusinessAgreementResponse = {
    agreements: Agreement;
    applicationMin: BusinessDetailsMin;
    createdBy: CreatedBy;
    canHaveBoardResolution: boolean;
    directors: SignatoryMin[];
    signatories: SignatoryMin[];
    currentUser: UserMin;
    progress: NewAccountOpeningProgress;
};

export enum AgreementType {
    SEARCH_REPORT = "searchReport",
    TERMS_AND_CONDITIONS = "terms",
    ACCOUNT_OPENING_DECLARATION = "declaration",
    BOARD_RESOLUTION = "boardResolution",
    ACCOUNT_MANDATE_CARD = "mandateCard",
}

export const AllAgreements = [
    AgreementType.SEARCH_REPORT,
    AgreementType.TERMS_AND_CONDITIONS,
    AgreementType.ACCOUNT_OPENING_DECLARATION,
    AgreementType.ACCOUNT_MANDATE_CARD,
    AgreementType.BOARD_RESOLUTION,
];

export const AgreementRequirements = [
    AgreementType.ACCOUNT_MANDATE_CARD,
    AgreementType.SEARCH_REPORT,
    AgreementType.ACCOUNT_OPENING_DECLARATION,
    AgreementType.TERMS_AND_CONDITIONS,
    // AgreementType.LENCO_INDEMNITY,
];

export const AgreementRequirementsWithBoardResolutions = [
    AgreementType.ACCOUNT_MANDATE_CARD,
    AgreementType.SEARCH_REPORT,
    AgreementType.ACCOUNT_OPENING_DECLARATION,
    AgreementType.TERMS_AND_CONDITIONS,
    AgreementType.BOARD_RESOLUTION,
    // AgreementType.LENCO_INDEMNITY,
];

export const AgreementFieldTexts: {
    [type in AgreementType]: string;
} = {
    [AgreementType.SEARCH_REPORT]: "Authority to Debit Account for Search Report",
    [AgreementType.BOARD_RESOLUTION]: "Board Resolution",
    [AgreementType.TERMS_AND_CONDITIONS]: "Partner Bank's Terms and Conditions",
    [AgreementType.ACCOUNT_MANDATE_CARD]: "Account Mandate",
    [AgreementType.ACCOUNT_OPENING_DECLARATION]: "Account Opening Declaration",
    // [AgreementType.LENCO_INDEMNITY]: "Indemnity",
};

export const AgreementTextArray = [
    { formikName: AgreementType.TERMS_AND_CONDITIONS, text: AgreementFieldTexts[AgreementType.TERMS_AND_CONDITIONS] },
    { formikName: AgreementType.SEARCH_REPORT, text: AgreementFieldTexts[AgreementType.SEARCH_REPORT] },
    { formikName: AgreementType.BOARD_RESOLUTION, text: AgreementFieldTexts[AgreementType.BOARD_RESOLUTION] },
    { formikName: AgreementType.ACCOUNT_MANDATE_CARD, text: AgreementFieldTexts[AgreementType.ACCOUNT_MANDATE_CARD] },
    { formikName: AgreementType.ACCOUNT_OPENING_DECLARATION, text: AgreementFieldTexts[AgreementType.ACCOUNT_OPENING_DECLARATION] },
    // {formikName: "indemnity", text: AgreementFieldTexts[AgreementType.LENCO_INDEMNITY]},
];

export const AgreementTextMap = AgreementTextArray.reduce((accumulator, obj) => {
    return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getAgreementHints(_formikList: string[], _uncompleted: string[], _exempted: string[]): hintMarkItem[] {
    let computedList: hintMarkItem[] = [];
    const _completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));

    for (const _string of _completed) {
        computedList.push({
            isChecked: true,
            text: AgreementTextMap.get(_string) || "",
        });
    }
    for (const _string of _uncompleted) {
        computedList = computedList.map((_) =>
            _.text === AgreementTextMap.get(_string)
                ? {
                      isChecked: false,
                      text: _.text,
                  }
                : _
        );
    }
    return computedList;
}
