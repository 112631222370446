import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class MerchantTerminalGroup {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string,
        public phone: string | null,
        public address: string | null
    ) {}

    static create(obj: GenericObject): MerchantTerminalGroup {
        return new MerchantTerminalGroup(
            Parsers.string(obj.id),
            Parsers.string(obj.name),
            Parsers.nullableString(obj.phone),
            Parsers.nullableString(obj.address)
        );
    }
}
