import { addMultiplePendingAccount, setAccounts } from "../../../../../redux/accounts/slice/accountsSlice";
import { useCallback, useState } from "react";

import { accountsDetails } from "../../Services/accounts-api";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

interface UseAccountsListInterface {
    isAccountsDetailsLoading: boolean;
    accountsDetailsError: string;
    handleAccountsDetails: () => Promise<void>;
}

function useAccountsList(): UseAccountsListInterface {
    const dispatch = useDispatch();
    const [isAccountsDetailsLoading, setIsAccountsDetailsLoading] = useState<boolean>(false);
    const [accountsDetailsError, setAccountsDetailsError] = useState<string>("");

    const handleAccountsDetails = useCallback(async () => {
        try {
            setAccountsDetailsError("");
            setIsAccountsDetailsLoading(true);
            const res = await accountsDetails();
            dispatch(setAccounts([...res.accounts.map((_account) => _account.id), ...res.pendingAccounts.map((_account) => _account.id)]));
            dispatch(addMultiplePendingAccount(res.pendingAccounts));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
            setAccountsDetailsError(errorMessage);
        } finally {
            setIsAccountsDetailsLoading(false);
        }
    }, [dispatch]);

    return {
        isAccountsDetailsLoading,
        accountsDetailsError,
        handleAccountsDetails,
    };
}

export default useAccountsList;
