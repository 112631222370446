import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class CollectionAccount {
    [immerable] = true;

    private constructor(
        public accountName: string,
        public accountNumber: string,
        public bankName: string
    ) {}

    static create(obj: GenericObject): CollectionAccount {
        return new CollectionAccount(Parsers.string(obj.accountName), Parsers.string(obj.accountNumber), Parsers.string(obj.bankName));
    }
}
