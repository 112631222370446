import { AccountBalanceInstructionType } from "./userAccount.constants";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";
import isNullOrUndefined from "../utils/isNullOrUndefined";

class AccountBalanceInstructionRatio {
    [immerable] = true;

    constructor(
        public percentage: number,
        public userAccountId: string
    ) {}

    static create(obj: GenericObject): AccountBalanceInstructionRatio {
        return new AccountBalanceInstructionRatio(Parsers.number(obj.percentage), Parsers.string(obj.userAccountId));
    }
}

export default class AccountBalanceInstruction {
    [immerable] = true;

    constructor(
        public type: AccountBalanceInstructionType,
        public amount: number | null,
        public ratio: AccountBalanceInstructionRatio[] | null
    ) {}

    static create(obj: GenericObject): AccountBalanceInstruction {
        return new AccountBalanceInstruction(
            Parsers.number(obj.type),
            Parsers.nullableNumber(obj.amount),
            isNullOrUndefined(obj.ratio) ? null : Parsers.classObjectArray(obj.ratio, AccountBalanceInstructionRatio)
        );
    }
    get isLowBalanceInstruction() {
        return this.type === AccountBalanceInstructionType.LOW_BALANCE_ALERT;
    }
    get isExcessBalanceInstruction() {
        return this.type === AccountBalanceInstructionType.EXCESS_BALANCE_ALERT;
    }
    get isSplitInflow() {
        return this.type === AccountBalanceInstructionType.SPLIT_INFLOW;
    }
}
