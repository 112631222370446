import { AppToastState, AppToastType } from "./app-toast-slice.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: AppToastState = {
    message: null,
};

export const appToastSlice = createSlice({
    name: "appToast",
    initialState,
    reducers: {
        messageTrue: (state: AppToastState, action: PayloadAction<string | { message: string; type?: AppToastType }>) => {
            state.message = {
                message: typeof action.payload === "string" ? action.payload : action.payload.message,
                type: typeof action.payload === "string" ? AppToastType.MESSAGE : action.payload.type || AppToastType.MESSAGE,
                index: Math.floor(Math.random() * 100000000000000) + 1,
            };
        },
        errorTrue: (state: AppToastState, action: PayloadAction<string | { message: string }>) => {
            state.message = {
                message: typeof action.payload === "string" ? action.payload : action.payload.message,
                type: AppToastType.ERROR,
                index: Math.floor(Math.random() * 100000000000000) + 1,
            };
        },
        // messageFalse: (state: AppToastState) => {
        //     state.message = null;
        // },
        resetAppToast: (state: AppToastState) => {
            state.message = null;
        },
    },
});

export const { messageTrue, errorTrue, resetAppToast } = appToastSlice.actions;

export default appToastSlice.reducer;
