import LencoSpinner from "../../../../../../../components/spinner";
import useScanCode from "../../../../Services/security/token-app/hooks/useScanCode";

function ScanCode(): JSX.Element {
    const { qrCodeData, isGeneratingQrCode, isScanned, handleGenerateQrCode } = useScanCode();

    return (
        <div className="space-y-6">
            <div className="flex w-full flex-col items-start justify-start space-y-3">
                <span className="text-sm font-semibold text-black-secondary">Step 2</span>
                <span className="text-sm font-normal text-black-secondary">Scan this QR code with the Lenco token app.</span>
            </div>

            <div className="relative flex flex-col items-start justify-center pt-4 md:items-center">
                <div>
                    {!qrCodeData && (
                        <div className="flex h-44 w-60 flex-row items-start justify-start space-x-2 px-8 py-4 md:items-center md:justify-center">
                            <div className="flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg bg-blue-senary text-center text-blue">
                                {isGeneratingQrCode && <LencoSpinner />}

                                {!isGeneratingQrCode && (
                                    <div className="cursor-pointer" onClick={() => void handleGenerateQrCode()}>
                                        click here
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {qrCodeData && (
                        <>
                            <div className="relative flex flex-col items-center justify-center">
                                {isScanned && <div className="top-15 absolute z-20">{/* <GreenCheck /> */}</div>}
                                <div
                                    className={`absolute top-2 z-10 h-90% w-90% opacity-80 ${isScanned ? "bg w-60 bg-success-backdrop py-5" : ""}`}
                                />
                                <img src={`${qrCodeData.qrCode}`} alt="" className="z-0" />
                            </div>
                            <div className="text-center">{qrCodeData && <span>{qrCodeData.shortCode}</span>}</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ScanCode;
