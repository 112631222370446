import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TransferBetweenInternalStage, TransferBetweenResponse, TransferBetweenState } from "./transferBetweenSlice.types";

import Card from "../../../../models/card";
import CustomerAccount from "../../../../models/customerAccount";
import UserAccount from "../../../../models/userAccount";

const initialState: TransferBetweenState = {
    transferBetweenDetails: {
        originatingAccountId: "",
        recipientAccountId: "",
        originatingCardId: "",
        amount: "",
        purpose: "",
        otp: "",
    },
    cardBalances: new Map<string, number>(),
    selectedCard: null,
    selectedUserAccount: null,
    recipientBankAccount: null,
    transferBetweenResponse: null,
    transferBetweenInternalStage: TransferBetweenInternalStage.PAYMENT_DETAILS_STAGE,
    canVerifyRecipientAccountDetails: true,
};

export const transferBetweenSlice = createSlice({
    name: "transferBetween",
    initialState,
    reducers: {
        setSingleCardBalance: (state: TransferBetweenState, action: PayloadAction<{ id: string; balance: number }>) => {
            state.cardBalances.set(action.payload.id, action.payload.balance);
        },
        setTransferBetweenOriginatingAccount: (state: TransferBetweenState, action: PayloadAction<string>) => {
            state.transferBetweenDetails.originatingAccountId = action.payload;
        },
        setTransferBetweenOriginatingCardAccount: (state: TransferBetweenState, action: PayloadAction<string>) => {
            state.transferBetweenDetails.originatingCardId = action.payload;
        },
        setTransferBetweenAmount: (state: TransferBetweenState, action: PayloadAction<string>) => {
            state.transferBetweenDetails.amount = action.payload;
        },
        setTransferBetweenPurpose: (state: TransferBetweenState, action: PayloadAction<string>) => {
            state.transferBetweenDetails.purpose = action.payload;
        },
        setTransferBetweenOtp: (state: TransferBetweenState, action: PayloadAction<string>) => {
            state.transferBetweenDetails.otp = action.payload;
        },
        setTransferBetweenRecipient: (state: TransferBetweenState, action: PayloadAction<UserAccount | CustomerAccount>) => {
            state.transferBetweenDetails.recipientAccountId = action.payload.id;
            state.recipientBankAccount = action.payload.bankAccount;
        },
        setSelectedUserAccount: (state: TransferBetweenState, action: PayloadAction<UserAccount>) => {
            state.selectedUserAccount = action.payload;
        },
        setSelectedCard: (state: TransferBetweenState, action: PayloadAction<Card>) => {
            state.selectedCard = action.payload;
        },
        setTransferBetweenResponse: (state: TransferBetweenState, action: PayloadAction<TransferBetweenResponse>) => {
            state.transferBetweenResponse = action.payload;
        },
        setTransferBetweenInternalStage: (state: TransferBetweenState, action: PayloadAction<TransferBetweenInternalStage>) => {
            state.transferBetweenInternalStage = action.payload;
        },
        resetTransferBetweenRecipient: (state: TransferBetweenState) => {
            state.recipientBankAccount = null;
            state.transferBetweenDetails.recipientAccountId = "";
        },
        resetTransferBetweenOriginatingCard: (state: TransferBetweenState) => {
            state.selectedCard = null;
            state.transferBetweenDetails.originatingCardId = "";
        },
        resetTransferBetweenOriginatingAccount: (state: TransferBetweenState) => {
            state.selectedUserAccount = null;
            state.transferBetweenDetails.originatingAccountId = "";
        },
        resetAllTransferBetweenData: (state: TransferBetweenState) => {
            state.cardBalances.clear();
            state.selectedCard = null;
            state.selectedUserAccount = null;
            state.canVerifyRecipientAccountDetails = true;
            state.recipientBankAccount = null;
            state.transferBetweenResponse = null;
            state.transferBetweenInternalStage = TransferBetweenInternalStage.PAYMENT_DETAILS_STAGE;
            state.transferBetweenDetails = {
                originatingAccountId: "",
                recipientAccountId: "",
                originatingCardId: "",
                amount: "",
                purpose: "",
                otp: "",
            };
        },
    },
});

export const {
    setSelectedCard,
    setSingleCardBalance,
    setTransferBetweenOtp,
    setSelectedUserAccount,
    setTransferBetweenAmount,
    setTransferBetweenPurpose,
    setTransferBetweenResponse,
    resetAllTransferBetweenData,
    setTransferBetweenRecipient,
    resetTransferBetweenRecipient,
    setTransferBetweenInternalStage,
    resetTransferBetweenOriginatingCard,
    setTransferBetweenOriginatingAccount,
    resetTransferBetweenOriginatingAccount,
    setTransferBetweenOriginatingCardAccount,
} = transferBetweenSlice.actions;

export default transferBetweenSlice.reducer;
