import SkeletonElement from "../../../../../components/skeleton";

function PendingApprovalSkeleton(): JSX.Element {
    return (
        <>
            <tr className="h-14">
                <td className="w-20% px-2 py-4">
                    <SkeletonElement text />
                </td>
                <td className="w-20% px-2 py-4">
                    <SkeletonElement text />
                </td>
                <td className="w-20% px-2 py-4">
                    <SkeletonElement text />
                </td>
                <td className="w-20% px-2 py-4">
                    <SkeletonElement text />
                </td>
                <td className="w-10% px-2 py-4" style={{ height: "42px" }}>
                    <div className="flex h-6 items-center justify-between space-x-8">
                        <SkeletonElement box />
                    </div>
                </td>
            </tr>
        </>
    );
}

export default PendingApprovalSkeleton;
