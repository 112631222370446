import { BillPurchaseCategoriesType, BillPurchaseStatusType } from "./billPurchase.constant";

import BillProduct from "./billProduct";
import BillPurchaseExtraData from "./billPurchaseExtraData";
import BillVendorMin from "./billVendorMin";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import UserAccount from "./userAccount";
import { immerable } from "immer";

class BillProductRequestBy {
    [immerable] = true;

    constructor(
        public id: string,
        public firstName: string,
        public lastName: string
    ) {}

    static create(obj: GenericObject): BillProductRequestBy {
        return new BillProductRequestBy(Parsers.string(obj.id), Parsers.string(obj.firstName), Parsers.string(obj.lastName));
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}

export default class BillPurchase {
    [immerable] = true;

    constructor(
        public id: string,
        public datetime: string,
        public amount: number,
        public vendor: BillVendorMin,
        public category: BillPurchaseCategoriesType,
        public product: BillProduct,
        public debitAccount: UserAccount,
        public transactionId: string,
        public status: BillPurchaseStatusType,
        public failedReason: string | null,
        public instructions: string | null,
        public narration: string,
        public requestedBy: BillProductRequestBy | null,
        public extraData: BillPurchaseExtraData
    ) {}

    static create(obj: GenericObject): BillPurchase {
        return new BillPurchase(
            Parsers.string(obj.id),
            Parsers.string(obj.datetime),
            Parsers.number(obj.amount),
            Parsers.classObjectNonNullable(obj.vendor, BillVendorMin),
            Parsers.number(obj.category),
            Parsers.classObjectNonNullable(obj.product, BillProduct),
            Parsers.classObjectNonNullable(obj.debitAccount, UserAccount),
            Parsers.string(obj.transactionId),
            Parsers.number(obj.status),
            Parsers.nullableString(obj.failedReason),
            Parsers.nullableString(obj.instructions),
            Parsers.string(obj.narration),
            Parsers.classObject(obj.requestedBy, BillProductRequestBy),
            Parsers.classObjectNonNullable(obj.extraData, BillPurchaseExtraData)
        );
    }

    get isSuccess(): boolean {
        return this.status === BillPurchaseStatusType.SUCCESS;
    }

    get isFailed(): boolean {
        return this.status === BillPurchaseStatusType.FAILED;
    }

    get isProcessing(): boolean {
        return this.status === BillPurchaseStatusType.PENDING;
    }

    get statusText(): string {
        return this.status === BillPurchaseStatusType.PENDING
            ? "Pending"
            : this.status === BillPurchaseStatusType.SUCCESS
              ? "Success"
              : this.status === BillPurchaseStatusType.FAILED
                ? "Failed"
                : "";
    }

    get getCategory(): string {
        switch (this.category) {
            case BillPurchaseCategoriesType.AIRTIME:
                return "Airtime";
            case BillPurchaseCategoriesType.ELECTRICITY:
                return "Electricity";
            case BillPurchaseCategoriesType.MOBILE_DATA:
                return "Data Subscription";
            case BillPurchaseCategoriesType.CABLE_TV:
                return "Cable TV";
            case BillPurchaseCategoriesType.INTERNET:
                return "Internet";
            case BillPurchaseCategoriesType.AIRTIME_PIN:
                return "Airtime Pin";
            case BillPurchaseCategoriesType.MOBILE_COMBO:
                return "Mobile Combo";
            case BillPurchaseCategoriesType.OTHER_BILLS:
                return "Other Bills";
        }
    }
}
