import accountsSlice from "./accounts/slice/accountsSlice";
import addMoneyCollectionRequestSlice from "./payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice";
import addMoneySlice from "./payments/addMoney/slice/addMoneySlice";
import airtimePurchaseSlice from "./payments/airtimePurchase/slice/airtimePurchaseSlice";
import apiAccessKeysSlice from "./zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import appToastSlice from "./app-toast/app-toast-slice";
import applicationSlice from "./application/applicationSlice";
import billPaymentSlice from "./payments/billPayment/slice/billPaymentSlice";
import bulkTransferSlice from "./payments/bulkTransfer/slice/bulkTransferSlice";
import cableTVPurchaseSlice from "./payments/cableTVPurchase/slice/cableTVPurchaseSlice";
import cardsSlice from "./cards/slice/cardsSlice";
import { combineReducers } from "redux";
import customerAccountReducer from "./customer-account/customerAccountSlice";
import dataPurchaseSlice from "./payments/dataPurchase/slice/dataPurchaseSlice";
import electricityPurchaseSlice from "./payments/electricityPurchase/slice/electricityPurchaseSlice";
import externalApplicationSlice from "./externalApplication/externalApplicationSlice";
import fundCardSlice from "./payments/fundCard/slice/fundCardSlice";
import initReducer from "./init/slice/initSlice";
import landingSlice from "./landing/landingSlice";
import peopleSlice from "./people/slice/peopleSlice";
import recipientsSlice from "./payments/recipients/slice/recipientsSlice";
import sendMoneySlice from "./payments/sendMoney/slice/sendMoneySlice";
import settingsSecuritySlice from "./settings/security/settingsSecuritySlice";
import singleTransferSlice from "./payments/singleTransfer/slice/singleTransferSlice";
import teamMembersSettingsSlice from "./settings/teamMembers/slice/teamMembersSettingsSlice";
import terminalsSlice from "./terminals/slice/terminalsSlice";
import transactionSlice from "./transaction/slice/transactionSlice";
import transactionsSlice from "./transactions/slice/transactionsSlice";
import transferBetweenSlice from "./payments/transferBetween/slice/transferBetweenSlice";
import zambiaApplicationSlice from "./application/zambiaApplicationSlice";
import zambiaBulkTransferSlice from "./payments/zambia/bulkTransfer/slice/bulkTransferSlice";
import zambiaCollectionSlice from "./zambia/collections/collectionsSlice";
import zambiaCollectionsSettlementsSlice from "./zambia/collections/settlements/zambiaCollectionsSettlementsSlice";
import zambiaCollectionsTransactionsSlice from "./zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import zambiaDefaultSetupSlice from "./zambia/defaultSetup/zambiaDefaultSetupSlice";
import zambiaPayoutsSlice from "./zambia/payouts/zambiaPayoutsSlice";
import zambiaSingleTransferSlice from "./payments/zambia/singleTransfer/slice/singleTransferSlice";

// import dataPurchaseSlice from "./payments/dataPurchase/slice/dataPurchaseSlice";
// import electricityPurchaseSlice from "./payments/electricityPurchase/slice/electricityPurchaseSlice";
// import errorReducer from "./error/slice/errorSlice";
// import externalApplicationSlice from "./externalApplication/externalApplicationSlice";
// import filterSlice from "./filter/slice/filterSlice";
// import fundCardSlice from "./payments/fundCard/slice/fundCardSlice";
// import landingSlice from "./landing/landingSlice";
// import messageReducer from "./message/slice/messageSlice";
// import peopleSlice from "./people/slice/peopleSlice";
// import recipientsSlice from "./payments/recipients/slice/recipientsSlice";
// import sendMoneySlice from "./payments/sendMoney/slice/sendMoneySlice";
// import singleTransferSlice from "./payments/singleTransfer/slice/singleTransferSlice";
// import terminalTransactionsSlice from "./terminalTransactions/slice/terminalTransactionsSlices";
// import terminalsSlice from "./terminals/slice/terminalsSlice";
// import transactionSlice from "./transaction/slice/transactionSlice";
// import transactionsSlice from "./transactions/slice/transactionsSlice";
// import transferBetweenSlice from "./payments/transferBetween/slice/transferBetweenSlice";
// import zambiaApplicationSlice from "./application/zambiaApplicationSlice";
// import zambiaBulkTransferSlice from "./payments/zambia/bulkTransfer/slice/bulkTransferSlice";
// import zambiaCollectionsSettlementsSlice from "./zambia/collections/settlements/zambiaCollectionsSettlementsSlice";
// import zambiaCollectionsSlice from "./zambia/collections/slice/collectionsSlice";
// import zambiaCollectionsTransactionsSlice from "./zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
// import zambiaDefaultSetupSlice from "./zambia/defaultSetup/zambiaDefaultSetupSlice";
// import zambiaPayoutsSlice from "./zambia/payouts/zambiaPayoutsSlice";
// import zambiaSingleTransferSlice from "./payments/zambia/singleTransfer/slice/singleTransferSlice";

const rootReducer = combineReducers({
    init: initReducer,
    landing: landingSlice,
    appToast: appToastSlice,
    application: applicationSlice,
    customerAccount: customerAccountReducer,
    settingsSecurity: settingsSecuritySlice,
    zambiaApplication: zambiaApplicationSlice,
    transaction: transactionSlice,
    transactions: transactionsSlice,
    people: peopleSlice,
    account: accountsSlice,
    teamMemberSettings: teamMembersSettingsSlice,

    // error: errorReducer,
    // message: messageReducer,
    singleTransfer: singleTransferSlice,
    transferBetween: transferBetweenSlice,
    bulkTransfer: bulkTransferSlice,
    zambiaSingleTransfer: zambiaSingleTransferSlice,
    zambiaBulkTransfer: zambiaBulkTransferSlice,
    airtimePurchase: airtimePurchaseSlice,
    dataPurchase: dataPurchaseSlice,
    cableTVPurchase: cableTVPurchaseSlice,
    electricityPurchase: electricityPurchaseSlice,
    fundCard: fundCardSlice,
    recipients: recipientsSlice,
    sendMoney: sendMoneySlice,
    billPayment: billPaymentSlice,
    terminals: terminalsSlice,
    externalApplication: externalApplicationSlice,
    // filter: filterSlice,
    // cards: cardsSlice,
    addMoney: addMoneySlice,
    addMoneyHistory: addMoneyCollectionRequestSlice,
    zambiaApiAccessKeys: apiAccessKeysSlice,
    zambiaPayouts: zambiaPayoutsSlice,
    zambiaCollectionsSettlements: zambiaCollectionsSettlementsSlice,
    zambiaCollectionsTransactions: zambiaCollectionsTransactionsSlice,
    zambiaDefaultSetup: zambiaDefaultSetupSlice,
    zambiaCollections: zambiaCollectionSlice,
    cards: cardsSlice,
});

export default rootReducer;

export type IRootState = ReturnType<typeof rootReducer>;
