import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../components/spinner";
import NewDropdownHead from "../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import React from "react";
import useExportTransactionsState from "../../Hooks/State/useExportTransactionsState";
import { useSelector } from "react-redux";

function ExportDropdown(): JSX.Element {
    const { handleExportTransactionsDetails } = useExportTransactionsState();
    const isExportTransactionsLoading = useSelector((state: IRootState) => state.transactions.isExportTransactionsLoading);

    return (
        <>
            <NewDropdownHead
                size="md"
                color="grey"
                buttonType="secondary"
                paddingSize="2xs"
                borderSmall
                fullWidth
                content={
                    <div className="flex items-center justify-start space-x-1">
                        <div className="w-full min-w-[24px] max-w-[24px]">
                            {isExportTransactionsLoading ? <LencoSpinner size="sm" /> : <GoogleIcon icon="download" size="xs" isOutlined />}
                        </div>
                        <span className="text-xs">Export</span>
                    </div>
                }
            >
                <NewDropdownItem size="md" onClick={() => handleExportTransactionsDetails({ type: "pdf" })}>
                    <div className="pointer-events-none text-sm">PDF (.pdf)</div>
                </NewDropdownItem>
                <NewDropdownItem size="md" onClick={() => handleExportTransactionsDetails({ type: "csv" })}>
                    <div className="pointer-events-none text-sm">Excel (.csv)</div>
                </NewDropdownItem>
            </NewDropdownHead>
        </>
    );
}

export default ExportDropdown;
