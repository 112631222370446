import * as Yup from "yup";

import { Form, Formik } from "formik";
import { useCallback, useLayoutEffect, useState } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import FormInput from "../../../../../components/inputs/FormInput";
import LencoSpinner from "../../../../../components/spinner";
import ReferenceSignatureComponent from "../componentss/ReferenceSignatureComponent";
import SuccessPage from "./success-page";
import titleCase from "../../../../../hooks/titleCase";
import { useParams } from "react-router";
import useReferenceFormState from "../hookss/State/useReferenceFormState";

interface referenceForm {
    corporateName: string;
    fullName: string;
    phone: string;
    address: string;
    bankName: string;
    branch: string;
    accountNumber: string;
}

type ReferenceUrlParams = {
    a: string;
    b: string;
};

function ReferenceForm(): JSX.Element {
    const {
        referenceFormError,
        referenceFormResponse,
        isReferenceFormLoading,
        verifyReferenceInviteError,
        verifyReferenceInviteResponse,
        isVerifyReferenceInviteLoading,

        handleVerifyInviteReferee,
        handleSubmitReferenceForm,
    } = useReferenceFormState();

    const { a, b } = useParams<ReferenceUrlParams>();

    const INITIAL_FORM_STATE: referenceForm = {
        corporateName: "",
        fullName: verifyReferenceInviteResponse?.referee.name || "",
        phone: verifyReferenceInviteResponse?.referee.phone || "",
        address: "",
        bankName: "",
        branch: "",
        accountNumber: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        corporateName: Yup.string().required("Required"),
        fullName: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        bankName: Yup.string().required("Required"),
        branch: Yup.string().required("Required"),
        accountNumber: Yup.string().required("Required"),
    });

    const [isRefereeDocumentSigned, setIsRefereeDocumentSigned] = useState(false);

    //determine link invite
    useLayoutEffect(() => {
        //check if params exists
        if (!a || !b) return;
        void handleVerifyInviteReferee({ a, b });
    }, [a, b]);

    const handleRefereeDocumentSigned = useCallback(() => {
        setIsRefereeDocumentSigned(true);
    }, []);

    return (
        <>
            <div
                className={
                    `flex min-h-screen w-full flex-col items-center justify-center bg-white p-3 ` +
                    `${isRefereeDocumentSigned ? "" : "px-8 lg:px-16"} `
                }
            >
                {!verifyReferenceInviteError && verifyReferenceInviteResponse && isRefereeDocumentSigned && (
                    <SuccessPage title="Referee" companyName={verifyReferenceInviteResponse.company} />
                )}
                {!verifyReferenceInviteError && !isRefereeDocumentSigned && verifyReferenceInviteResponse && (
                    <div className="flex w-full max-w-5xl flex-col justify-center">
                        <div className="my-4 mt-8 text-center text-3xl font-medium text-black">Reference Form</div>
                        <div className="mt-8">
                            <div className="text-black-secondary">Hello, {titleCase(verifyReferenceInviteResponse.referee.name)} </div>
                            <div className="mt-4 text-black-secondary">
                                {titleCase(verifyReferenceInviteResponse.creator)} of {titleCase(verifyReferenceInviteResponse.company)} has requested
                                you to be a referee for their account opening with Lenco. Lenco is a banking platform for the modern business. Banking
                                service is provided by Providus Bank
                            </div>
                            <div className="mt-4 text-black-secondary">Kindly complete the reference form below:</div>
                        </div>
                        <div className="my-4 pb-12 pt-6 2xs:border 2xs:border-grey 2xs:px-6">
                            <div className="flex flex-col justify-between md:flex-row">
                                <div className="space-y-6 text-black-secondary">
                                    <div>
                                        <div>To:</div>
                                        <div>The Manager</div>
                                        <div>Providus Bank PLC</div>
                                    </div>
                                    <div>
                                        <div>Through:</div>
                                        <div>Lenco Technology Limited</div>
                                    </div>
                                </div>

                                <div className="mt-6 h-fit rounded-lg bg-warning-backdrop p-5 text-sm text-warning md:mt-0">
                                    <div className="font-semibold">CAUTION:</div>
                                    <div>It is dangerous to introduce an Individual/Corporate</div>
                                    <div>not well known to you</div>
                                </div>
                            </div>
                            <div>
                                <div className="mt-14 text-black-secondary">Dear Sir/Ma,</div>
                                <div className="mt-4 text-black-secondary">
                                    <span className="font-bold uppercase">{verifyReferenceInviteResponse.company}</span> wishes to open a current
                                    account with you. They are well known to us and we consider them suitable to maintain this account with you. Below
                                    are our details;
                                </div>
                            </div>
                            <div className="w-full">
                                <Formik
                                    initialValues={{
                                        ...INITIAL_FORM_STATE,
                                    }}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={(values) => {
                                        if (a && b) {
                                            void handleSubmitReferenceForm({ ...values, a, b, isCorporate: true });
                                        }
                                    }}
                                    enableReinitialize
                                    validateOnMount
                                >
                                    {(formik) => {
                                        return (
                                            <div>
                                                {!referenceFormResponse && (
                                                    <>
                                                        <div className="mt-12 font-medium text-black-secondary">Referee Details</div>
                                                        {referenceFormError && (
                                                            <div className="flex w-full items-center justify-center pb-5 pt-7">
                                                                <ErrorToast error={referenceFormError || ""} />
                                                            </div>
                                                        )}
                                                        <Form className="w-full space-y-4 pt-4 lg:w-auto">
                                                            <div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                                <div className="lg:w-6/12">
                                                                    <FormInput
                                                                        type="text"
                                                                        name="corporateName"
                                                                        placeholder="Company Name"
                                                                        value={formik.values.corporateName}
                                                                    />
                                                                </div>

                                                                <div className="lg:w-6/12">
                                                                    <FormInput
                                                                        type="text"
                                                                        name="phone"
                                                                        placeholder="Your Phone Number"
                                                                        value={formik.values.phone}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="w-full">
                                                                <FormInput
                                                                    type="text"
                                                                    name="fullName"
                                                                    placeholder="Your Full Name"
                                                                    value={formik.values.fullName}
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <FormInput
                                                                    type="textarea"
                                                                    name="address"
                                                                    placeholder="Company Address"
                                                                    value={formik.values.address}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col items-start space-y-4 text-base font-normal lg:px-0">
                                                                <div className="mt-12 font-medium text-black-secondary">Bank Details</div>
                                                                <div className="my-4 grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
                                                                    <FormInput
                                                                        type="text"
                                                                        name="bankName"
                                                                        placeholder="Bank Name"
                                                                        value={formik.values.bankName}
                                                                    />

                                                                    <FormInput
                                                                        type="text"
                                                                        name="branch"
                                                                        placeholder="Branch"
                                                                        value={formik.values.branch}
                                                                    />

                                                                    <FormInput
                                                                        type="text"
                                                                        name="accountNumber"
                                                                        placeholder="Account Number"
                                                                        value={formik.values.accountNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="m-auto w-fit pt-12">
                                                                <ButtonComp
                                                                    type="submit"
                                                                    color="black"
                                                                    ripple="light"
                                                                    buttonType="primary"
                                                                    disable={
                                                                        !!(
                                                                            formik.errors.corporateName ||
                                                                            formik.errors.fullName ||
                                                                            formik.errors.phone ||
                                                                            formik.errors.address ||
                                                                            formik.errors.bankName ||
                                                                            formik.errors.branch ||
                                                                            formik.errors.accountNumber
                                                                        )
                                                                    }
                                                                    isLoading={isReferenceFormLoading}
                                                                >
                                                                    <span>Continue</span>
                                                                </ButtonComp>
                                                            </div>
                                                        </Form>
                                                    </>
                                                )}

                                                {referenceFormResponse && (
                                                    <div className="flex w-full flex-col items-center justify-center space-y-4">
                                                        <div className="flex w-full max-w-lg flex-col items-center justify-center space-y-4">
                                                            <div className="flex w-full flex-col items-start justify-start space-y-8 pt-6">
                                                                <div className="box-border w-full flex-col space-y-7 rounded-lg bg-blue-backdrop p-5">
                                                                    <div className="flex w-full flex-row items-start justify-between">
                                                                        <p className="text-sm text-black-secondary" data-type="transaction-active">
                                                                            Name of Bank
                                                                        </p>
                                                                        <p className="max-w-55% whitespace-normal break-words text-right text-sm font-normal text-black-secondary">
                                                                            {formik.values.bankName}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex w-full flex-row items-start justify-between">
                                                                        <p className="text-sm text-black-secondary" data-type="transaction-active">
                                                                            Branch
                                                                        </p>
                                                                        <p className="max-w-55% whitespace-normal break-words text-right text-sm font-normal text-black-secondary">
                                                                            {formik.values.branch}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex w-full flex-row items-start justify-between">
                                                                        <p className="text-sm text-black-secondary" data-type="transaction-active">
                                                                            Account Number
                                                                        </p>
                                                                        <p className="max-w-55% whitespace-normal break-words text-right text-sm font-normal text-black-secondary">
                                                                            {formik.values.accountNumber}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex w-full flex-row items-start justify-between">
                                                                        <p className="text-sm text-black-secondary" data-type="transaction-active">
                                                                            Account Name
                                                                        </p>
                                                                        <p className="max-w-55% whitespace-normal break-words text-right text-sm font-normal text-black-secondary">
                                                                            {formik.values.fullName}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex w-full flex-row items-start justify-between">
                                                                        <p className="text-sm text-black-secondary" data-type="transaction-active">
                                                                            Phone Number
                                                                        </p>
                                                                        <p className="max-w-55% whitespace-normal break-words text-right text-sm font-normal text-black-secondary">
                                                                            {formik.values.phone}
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex w-full flex-row items-start justify-between">
                                                                        <p className="text-sm text-black-secondary" data-type="transaction-active">
                                                                            Address
                                                                        </p>
                                                                        <p className="max-w-55% whitespace-normal break-words text-right text-sm font-normal text-black-secondary">
                                                                            {formik.values.address}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex w-full max-w-lg items-center justify-center">
                                                            {a && b && (
                                                                <ReferenceSignatureComponent
                                                                    a={a}
                                                                    b={b}
                                                                    handleRefereeDocumentSigned={handleRefereeDocumentSigned}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                )}
                {isVerifyReferenceInviteLoading && (
                    <div className="flex h-full w-full flex-col items-center justify-center">
                        <LencoSpinner />
                    </div>
                )}
                {verifyReferenceInviteError && (
                    <div className="flex h-full w-full flex-col items-center justify-center">
                        <ErrorToast error={verifyReferenceInviteError} />
                    </div>
                )}
            </div>
        </>
    );
}

export default ReferenceForm;
