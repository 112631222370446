import { BusinessRefereeRequests, abortBusinessRefereeRequest, accountType } from "../../../../services/nigeria-application/registered/referee.api";
import { useCallback, useState } from "react";

import { AccountTypeRequest } from "../../../../services/nigeria-application/registered/referee.types";
import { Routes } from "../../../../../../../routes/routes.constants";
import { errorTrue, messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { updateBusinessDetails } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

export interface UseAccountTypeInterface {
    isAccountTypeLoading: boolean;

    handleRest: () => void;
    handleAccountType: (data: AccountTypeRequest) => Promise<void>;
}
type LocationState = {
    from?: string;
};

const useAccountType = (): UseAccountTypeInterface => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const [isAccountTypeLoading, setIsAccountTypeLoading] = useState<boolean>(false);

    const handleAccountType = useCallback(
        async (data: AccountTypeRequest): Promise<void> => {
            try {
                setIsAccountTypeLoading(true);
                const res = await accountType(data);
                dispatch(updateBusinessDetails(res.applicationMin));
                if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                    dispatch(messageTrue("Referee choice updated successfully"));
                    if (!data.requestedProAccount) {
                        navigate(
                            {
                                pathname: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.INVITE,
                            },
                            {
                                state: {
                                    from: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS,
                                    beforeFrom: locationState?.from,
                                },
                            }
                        );
                    }
                    return;
                }
                navigate(
                    {
                        pathname: data.requestedProAccount ? Routes.ACCOUNT_OPENING.REVIEW : Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.INVITE,
                    },
                    {
                        state: {
                            from: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS,
                            beforeFrom: locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? Routes.ACCOUNT_OPENING.REVIEW : undefined,
                        },
                    }
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsAccountTypeLoading(false);
            }
        },
        [dispatch]
    );

    const handleRest = () => {
        setIsAccountTypeLoading(false);
        abortBusinessRefereeRequest(BusinessRefereeRequests.ACCOUNT_TYPE);
    };

    return {
        isAccountTypeLoading,
        handleRest,
        handleAccountType,
    };
};
export default useAccountType;
