import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import OtherDirectorMin from "../../../../../../models/account-opening/otherDirectorMin";
import SignatoryMin from "../../../../../../models/account-opening/signatoryInfoMin";
import { UserRole } from "../../../../../../models/userAccount.constants";

export enum SignatoryStages {
    INITIAL = 1,
    BVN = 2,
    PERSONAL_DETAILS = 3,
    NEXT_OF_KIN = 4,
    MEANS_OF_ID = 5,
    PHOTOGRAPH = 6,
    SIGNATURE = 7,
    DIRECTORS_AND_SIGNATORIES = 8,
}

export enum MaxedSignatories {
    NUMBER = 3,
}

export type SignatoryKeyContactForm = {
    keyContact: string;
};

export interface AccountSignatoriesResponse {
    signatories: SignatoryMin[];
    otherDirectors: OtherDirectorMin[];
    progress: NewAccountOpeningProgress;
}

export type AddNewSignatoryItem = {
    email: string;
    index: string | null;
    surname: string;
    firstName: string;
    isDirector: boolean;
    isSignatory: boolean;
    isKeyContact: boolean;
    userRole: UserRole | null | 0;
    canDelete: boolean;
    isDisabled: boolean;
};

export type AddSignatoryItem = {
    firstName: string;
    surname: string;
    email: string;
    userRole: UserRole | undefined;
    isDirector: boolean;
    isAccountSignatory: boolean;
};

export type AddSignatoryRequest = {
    signatories: AddSignatoryItem[];
};

export type EditSignatoryRequest = {
    id: string;
    firstName: string;
    surname: string;
    email: string;
    userRole: UserRole | undefined;
};
export type EditOtherDirectorRequest = {
    id: string;
    firstName: string;
    surname: string;
    email: string;
};

export type RemoveOtherDirectorRequest = {
    id: string;
    otherDirectorId: string;
};

export type SignatoryActionRequest = {
    id: string;
    signatoryId: string;
};

export type SetSignatoryUserRoleRequest = {
    id: string;
    userRole: UserRole;
};

export type AddOtherDirectorItem = {
    firstName: string;
    surname: string;
    email: string;
};

export type AddOtherDirectorsForm = {
    otherDirectors: AddOtherDirectorItem[];
};

export type EditOtherDirectorForm = {
    id: string;
    firstName: string;
    surname: string;
    email: string;
};
