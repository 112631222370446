import Transaction from "../../../../../../models/transaction";

export enum MoreAction {
    APPROVE_ALL = "approve-all",
    DECLINE_ALL = "decline-all",
    CANCEL_ALL = "cancel-all",
    CLEAR_ALL = "clear-all",
}

export const moreActionsOption = [
    {
        text: "Approve All",
        value: MoreAction.APPROVE_ALL,
    },
    {
        text: "Decline All",
        value: MoreAction.DECLINE_ALL,
    },
    {
        text: "Cancel All",
        value: MoreAction.CANCEL_ALL,
    },
    {
        text: "Clear All",
        value: MoreAction.CLEAR_ALL,
    },
];

export enum DeclineReason {
    INCORRECT_DETAILS = "Incorrect Details",
    INCORRECT_AMOUNT = "Incorrect Amount",
    WRONG_PURPOSE = "Wrong Purpose",
    OTHERS = "Others",
}
export interface SelectedTransaction {
    isApproved: boolean;
    isDeclined: boolean;
    isCancelled: boolean;
    transaction: Transaction;
    declinedReason?: DeclineReason | undefined;
    cancelledReason?: DeclineReason | undefined;
}

export interface UsePendingApprovalInterface {
    showModal: boolean;
    selectedArr: SelectedTransaction[];
    pendingPaymentList: Transaction[];
    isBulkPaymentLoading: boolean;
    userCanApprovePayment: boolean;
    userInitiatedAnyPayment: boolean;
    pendingPaymentListsTotal: number;
    pendingPaymentListsOffset: number;
    userInitiatedEveryPayment: boolean;
    isBulkCancelPaymentLoading: boolean;
    pendingPaymentListsGroupSize: number;
    isPendingPaymentListsLoading: boolean;
    isAllSelectedPaymentCancelled: boolean;

    handleApprovePayment: (_data: Transaction) => void;
    handleDeclinePayment: (_data: Transaction) => void;
    handleApproveAllPayments: () => void;
    handleDeclineAllPayments: () => void;
    handleCancelAllPayments: () => void;
    handleSelectOrChangeDeclineReason: (_data: DeclinedTransaction) => void;
    handleRemoveDeclineOrChangeToDecline: (data: Transaction) => void;
    handleProcessBulkPayment: () => void;
    handleCloseBulkProcessingModal: () => void;
    handleClearSelectedArr: () => void;
    handleUpdateOffset: (_newOffset: number) => void;
    handleSelectOrChangeCancelReason: (_data: DeclinedTransaction) => void;
    handleRemoveCancelOrChangeToCancel: (data: Transaction) => void;
}

export interface DeclinedTransaction {
    reason: DeclineReason;
    transaction: Transaction;
}
