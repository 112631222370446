export enum TableColumnType {
    TEXT = 1,
    LOGO_FROM_TEXT = 2,
    LOGO_AND_TREND_FROM_TEXT = 3,
    LOGO_TREND_SUBTEXT_FROM_TEXT = 4,
    LOGO_SUBTEXT_FROM_TEXT = 5,
    TREND_LOGO = 6,
    AMOUNT = 7,
    AMOUNT_WITH_SUBTEXT = 8,
    STATUS = 9,
    ACTION = 10,
    TEXT_WITH_SUBTEXT = 11,
    PENDING_APPROVAL = 12,
    PROGRESS = 13,
}
