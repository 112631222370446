import { AccountBalanceInstructionType, ActivationStatus } from "./userAccount.constants";

import AccountBalanceInstruction from "./accountBalanceInstruction";
import AccountRestriction from "./accountRestriction";
import BankAccount from "./bankAccount";
import { CorporateAccountType } from "./corporate.constants";
import Currency from "./currency";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";
import isNullOrUndefined from "../utils/isNullOrUndefined";

class UserAccountMonthBalance {
    [immerable] = true;

    constructor(
        public debit: null | number,
        public credit: null | number
    ) {}

    static create(obj: GenericObject): UserAccountMonthBalance {
        return new UserAccountMonthBalance(Parsers.nullableNumber(obj.debit), Parsers.nullableNumber(obj.credit));
    }

    static createDefault(): UserAccountMonthBalance {
        return new UserAccountMonthBalance(null, null);
    }
}

export default class UserAccount {
    [immerable] = true;

    // todo -> when we start multiple currencies, refactor and include this
    // currency: Currency | null = null;

    constructor(
        public type: CorporateAccountType,
        public id: string,
        public bankAccount: BankAccount | null,
        public description: string | null,
        public isMain: boolean,
        public mainAccountShortName: string | null,
        public subAccountShortName: string | null,
        public accountRestrictions: AccountRestriction,
        public balanceInstructions: AccountBalanceInstruction[],
        public balance: number | null,
        public monthBalance: UserAccountMonthBalance,
        public nipDailyTransferLimit: number,
        public activationStatus: ActivationStatus,
        public todayTransfersTotal: number | null
    ) {}

    get name(): string {
        return this.bankAccount ? this.bankAccount.accountName : "";
    }

    get alias(): string {
        return "";
    }

    get accountName(): string {
        return this.name;
    }

    get singleName(): string {
        return this.name;
    }

    get lencoName(): string {
        if (!this.bankAccount) {
            return "";
        }
        const suffix = `**${this.bankAccount.accountNumber.substr(Math.max(0, this.bankAccount.accountNumber.length - 4))}`;
        return `${this.lencoNameMin} ${suffix}`;
    }

    get lencoNameMin(): string {
        if (this.isMain) {
            return "Main";
        }
        if (this.subAccountShortName) {
            return this.subAccountShortName;
        }
        if (!this.bankAccount) {
            return "";
        }

        return this.bankAccount.accountName;
    }

    get accountNumber(): string {
        return this.bankAccount ? this.bankAccount.accountNumber : "";
    }

    get bankCode(): string {
        return this.bankAccount ? this.bankAccount.bankCode : "";
    }

    get bankAccountCurrency(): Currency | null {
        return this.bankAccount ? this.bankAccount.currency : null;
    }

    get isActivated(): boolean {
        return this.activationStatus === ActivationStatus.ACTIVATED;
    }

    get isUnactivated(): boolean {
        return this.activationStatus === ActivationStatus.NOT_ACTIVATED;
    }

    get isActivationOngoing(): boolean {
        return this.activationStatus === ActivationStatus.ONGOING;
    }

    static create(obj: GenericObject): UserAccount {
        return new UserAccount(
            Parsers.number(obj.type),
            Parsers.string(obj.id),
            Parsers.classObject(obj.bankAccount, BankAccount),
            Parsers.nullableString(obj.description),
            Parsers.boolean(obj.isMain),
            Parsers.nullableString(obj.mainAccountShortName),
            Parsers.nullableString(obj.subAccountShortName),
            Parsers.classObjectNonNullable(obj.accountRestrictions, AccountRestriction),
            Parsers.classObjectArray(obj.balanceInstructions, AccountBalanceInstruction),
            Parsers.nullableNumber(obj.balance),
            obj.monthBalance ? Parsers.classObjectNonNullable(obj.monthBalance, UserAccountMonthBalance) : UserAccountMonthBalance.createDefault(),
            Parsers.number(obj.nipDailyTransferLimit),
            Parsers.number(obj.activationStatus),
            Parsers.nullableNumber(obj.todayTransfersTotal)
        );
    }

    update(userAccount: UserAccount): void {
        // todo -> might ignore some if the data is not complete (i.e the object is not full)
        this.bankAccount = userAccount.bankAccount;
        this.description = userAccount.description;
        this.updateBalance(userAccount.balance);
        this.isMain = userAccount.isMain;
        this.subAccountShortName = userAccount.subAccountShortName;
        this.accountRestrictions = userAccount.accountRestrictions;
        this.balanceInstructions = userAccount.balanceInstructions;
        this.activationStatus = userAccount.activationStatus;
        if (userAccount.monthBalance) {
            this.monthBalance = userAccount.monthBalance;
        }
        this.type = userAccount.type;
        if (!isNullOrUndefined(userAccount.todayTransfersTotal)) {
            this.todayTransfersTotal = userAccount.todayTransfersTotal;
        }
    }

    updateBalance(balance: number | null | undefined): void {
        if (balance !== null && balance !== undefined) {
            this.balance = balance;
        }
    }

    get lowBalanceInstruction(): AccountBalanceInstruction | undefined {
        return this.balanceInstructions.find((b) => b.type === AccountBalanceInstructionType.LOW_BALANCE_ALERT);
    }

    get excessBalanceInstruction(): AccountBalanceInstruction | undefined {
        return this.balanceInstructions.find((b) => b.type === AccountBalanceInstructionType.EXCESS_BALANCE_ALERT);
    }

    get splitInflowInstruction(): AccountBalanceInstruction | undefined {
        return this.balanceInstructions.find((b) => b.type === AccountBalanceInstructionType.SPLIT_INFLOW);
    }

    getBalanceInstructionByType(type: AccountBalanceInstructionType): AccountBalanceInstruction | undefined {
        return this.balanceInstructions.find((instruction) => instruction.type === type);
    }

    hasBalanceInstructionByType(type: AccountBalanceInstructionType): boolean {
        return this.balanceInstructions.some((instruction) => instruction.type === type);
    }

    get accountTypeName() {
        if (this.type === CorporateAccountType.STARTER) return "Starter";
        if (this.type === CorporateAccountType.BETA) return "Prime";
        return "Pro";
    }
    get accountTypeDescription() {
        if (this.type === CorporateAccountType.STARTER)
            return "Accounts built for Individuals and freelancers. Account comes in the individual name.";
        if (this.type === CorporateAccountType.BETA)
            return "Accounts built for registered businesses, sole traders and freelancers. Account comes in the directors name.";
        return "Accounts built for registered businesses, sole traders and freelancers. Account comes in the directors name.";
    }
}
