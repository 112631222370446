import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../redux/rootReducer";
// import { Recipient } from "../pending-approval/useSavedRecipientsState.types";
import { RecipientActionType } from "../../../../../../redux/payments/recipients/slice/recipientsSlice.types";
import { clearRecipientsAction } from "../../../../../../redux/payments/recipients/slice/recipientsSlice";
import { Recipient } from "../pending-approval/use-saved-recipients-state.types";

export interface CommonModalActionResponse {
    recipient: Recipient | undefined;
    isModalOpen: boolean;
    isSubmitting: boolean;
    errorMessage: string | undefined;
    recipientActionType: RecipientActionType | undefined;

    openModal: () => void;
    closeModal: () => void;
    setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
    setIsSubmitting: Dispatch<SetStateAction<boolean>>;
    toggleIsModalOpen: () => void;
}

function useCommonModalAction(): CommonModalActionResponse {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const recipient = useSelector<IRootState, Recipient | undefined>((state: IRootState) => {
        const actionCustomerAccountId = state.recipients.action.customerAccountId;
        if (!actionCustomerAccountId || !state.recipients.recipients) {
            return undefined;
        }
        return state.recipients.recipients.get(actionCustomerAccountId);
    });

    const recipientActionType = useSelector((state: IRootState) => state.recipients.action.type);

    return {
        isModalOpen,
        openModal: () => setIsModalOpen(true),
        closeModal: () => {
            setIsModalOpen(false);
            dispatch(clearRecipientsAction());
        },
        toggleIsModalOpen: () => {
            const currentValue = isModalOpen;
            setIsModalOpen(!currentValue);
            if (currentValue) {
                // if it was open, then we are closing. So clear
                dispatch(clearRecipientsAction());
            }
        },
        isSubmitting,
        setIsSubmitting,
        errorMessage,
        setErrorMessage,
        recipient,
        recipientActionType,
    };
}

export default useCommonModalAction;
