import { AddMoneyHistoryRequest, AddMoneyHistoryResponse } from "./addMoneyApi.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";

import Parsers from "../../../../../utils/parsers";
import ZambiaCollectionRequest from "../../../../../models/zambia/zambiaCollectionRequest";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";

export enum AddMoneyRequestType {
    REQUEST_LIST = "add-money.request-list",
}

export const addMoneyHistory = async (_data: AddMoneyHistoryRequest): Promise<AddMoneyHistoryResponse> => {
    const signal = getAbortControllerSignal(AddMoneyRequestType.REQUEST_LIST);
    const res = await makeRequestWithSignal("/main/payments/request/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        collections: Parsers.classObjectArray(res.collections, ZambiaCollectionRequest),
    };
};

export function abortAddMoneyRequest(type: AddMoneyRequestType): void {
    abortRequest(type);
}

export function abortAllAddMoneyRequests(): void {
    Object.values(AddMoneyRequestType).forEach((type) => abortRequest(type));
}
