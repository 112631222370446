import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class ZambiaCardPaymentCardDetails {
    [immerable] = true;

    constructor(
        public name: string | null,
        public maskedPan: string | null,
        public cardType: string | null
    ) {}

    static create(obj: GenericObject): ZambiaCardPaymentCardDetails {
        return new ZambiaCardPaymentCardDetails(
            Parsers.nullableString(obj.name),
            Parsers.nullableString(obj.maskedPan),
            Parsers.nullableString(obj.cardType)
        );
    }
}
