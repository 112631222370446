import { downloadDocumentToSign, referenceFormUploadDownloadedDocument, referenceFormUploadSignature } from "../../servicess/referenceFormApi";
import {
    referenceDownloadDocumentData,
    referenceUploadDocumentData,
    referenceUploadDocumentDataResponse,
    referenceUploadSignatureData,
    referenceUploadSignatureDataResponse,
} from "../../servicess/referenceFormApi.types";
import { useCallback, useState } from "react";

import { downloadDocument } from "../../../../../dashboard/applicationn/services/nigeria-application/registered/document.api";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { openBlobInNewTab } from "../../../../../../utils/blob";
import { useDispatch } from "react-redux";

export interface useReferenceFormApiData {
    uploadSignatureError: string | null;
    uploadSignatureLoading: boolean;
    uploadSignatureResponse: referenceUploadSignatureDataResponse | null;
    uploadRefereeDocumentError: string | null;
    uploadRefereeDocumentLoading: boolean;
    uploadRefereeDocumentResponse: referenceUploadDocumentDataResponse | null;

    handlePreviewDocument: (_data: string) => Promise<void>;
    handleUploadSignature: (_data: referenceUploadSignatureData) => Promise<void>;
    handleUploadRefereeDocument: (_data: referenceUploadDocumentData) => Promise<void>;
    handleDownloadRefereeDocument: (_data: referenceDownloadDocumentData) => Promise<void>;
}
function useReferenceSignature(): useReferenceFormApiData {
    const dispatch = useDispatch();

    const [uploadSignatureLoading, setIsUploadSignatureLoading] = useState<boolean>(false);
    const [uploadSignatureError, setIsUploadSignatureError] = useState<string | null>(null);

    const [uploadSignatureResponse, setUploadSignatureResponse] = useState<referenceUploadSignatureDataResponse | null>(null);

    const [uploadRefereeDocumentLoading, setIsUploadRefereeDocumentLoading] = useState<boolean>(false);
    const [uploadRefereeDocumentError, setIsUploadRefereeDocumentError] = useState<string | null>(null);
    const [uploadRefereeDocumentResponse, setUploadRefereeDocumentResponse] = useState<referenceUploadDocumentDataResponse | null>(null);

    const handlePreviewDocument = useCallback(
        async (_data: string) => {
            try {
                const blob = await downloadDocument(_data);
                if (blob.type === "application/pdf") {
                    openBlobInNewTab(blob, "Signatory Document");
                    return;
                }
            } catch (err) {
                return;
            }
        },
        [dispatch]
    );

    const handleUploadSignature = useCallback(
        async (_data: referenceUploadSignatureData) => {
            try {
                setIsUploadSignatureError(null);
                setIsUploadSignatureLoading(true);
                const res = await referenceFormUploadSignature(_data);
                setUploadSignatureResponse(res);
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setIsUploadSignatureError(errorMessage);
            } finally {
                setIsUploadSignatureLoading(false);
            }
        },
        [dispatch]
    );

    const handleDownloadRefereeDocument = useCallback(
        async (data: referenceDownloadDocumentData) => {
            try {
                await downloadDocumentToSign(data);
            } catch (err) {
                return;
            }
        },
        [dispatch]
    );

    const handleUploadRefereeDocument = useCallback(
        async (_data: referenceUploadDocumentData) => {
            try {
                setIsUploadRefereeDocumentError(null);
                setIsUploadRefereeDocumentLoading(true);
                const res = await referenceFormUploadDownloadedDocument(_data);
                setUploadRefereeDocumentResponse(res);
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setIsUploadRefereeDocumentError(errorMessage);
            } finally {
                setIsUploadRefereeDocumentLoading(false);
            }
        },
        [dispatch]
    );

    return {
        uploadSignatureError,
        uploadSignatureLoading,
        uploadSignatureResponse,
        uploadRefereeDocumentError,
        uploadRefereeDocumentLoading,
        uploadRefereeDocumentResponse,

        handlePreviewDocument,
        handleUploadSignature,
        handleUploadRefereeDocument,
        handleDownloadRefereeDocument,
    };
}

export default useReferenceSignature;
