import Merchant from "../../../../models/merchant";
import { BasicFinalStatus } from "../../../../models/posTransaction.constants";
import Terminal from "../../../../models/terminal";
import TerminalRequest from "../../../../models/terminalRequest";
import { TransactionType } from "../../../../models/transaction.constants";
import User from "../../../../models/user";

export interface TerminalsInitResponse {
    success: boolean;
    message: string;
    meta: {
        setupVideoUrl: string | null;
        purchaseCost: number | null;
        cautionFee: number | null;
    };
    merchant: Merchant | null;
    terminalsData: { terminals: Array<Terminal>; groupSize: number; total: number };
    terminalRequestsData: { terminalRequests: Array<TerminalRequest>; groupSize: number; total: number };
    settings: { teamMembersWithManageAccess: Array<User>; teamMembersWithViewAccess: Array<User> };
}

export enum LencoTerminalAccessStage {
    RESET = 0,
    LIST_OF_TEAM_MEMBERS = 1,
    CREATE_ACCESS = 2,
    DONE = 3,
}

export enum LencoTerminalAccessLevel {
    VIEWER = "viewer",
    MANAGER = "manager",
}

export type LencoTerminalAccessForm = {
    memberIds: string[];
    accessLevel: LencoTerminalAccessLevel | null;
};

export enum TerminalRequestsFilters {
    STATUS = "Status",
    PURCHASE_TYPE = "Purchase Type",
}

export enum RequestTerminalStage {
    STAGE_ONE = 1,
    STAGE_TWO = 2,
    STAGE_THREE = 3,
}

export enum TransactionVolume {
    VOL_0_100K = "NGN0.00 - NGN100,000.00",
    VOL_100K_300K = "NGN100,000.00 - NGN300,000.00",
    VOL_300K_500K = "NGN300,000.00 - NGN500,000.00",
    VOL_500K_1M = "NGN500,000.00 - NGN1,000,000.00",
    VOL_1M_ABOVE = "NGN1,000,000.00 and above",
}
export enum TransactionCount {
    COUNT_0_10 = "0 - 10",
    COUNT_10_30 = "10 - 30",
    COUNT_30_60 = "30 - 60",
    COUNT_60_100 = "60 - 100",
    COUNT_100_200 = "100 - 200",
    COUNT_200_300 = "200 - 300",
}

export enum TerminalsFilters {
    DATE = "Date",
    TYPES = "Type",
    STATUS = "Status",
}

export const LencoTerminalAccessLevelText = { [LencoTerminalAccessLevel.VIEWER]: "View Only", [LencoTerminalAccessLevel.MANAGER]: "Manage" };

export const LencoTerminalAccessLevels = [LencoTerminalAccessLevel.VIEWER, LencoTerminalAccessLevel.MANAGER];

export type TerminalTransactionFilterTypes =
    | { query: string }
    | { endDate: string }
    | { startDate: string }
    | { offset: number }
    | { types: Array<TransactionType> }
    | { statuses: Array<BasicFinalStatus> }
    | { terminalId: string };

export type DateObj = {
    begin: string;
    end: string;
};
