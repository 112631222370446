import { ZambiaRegisteredRoutes, ZambiaRegisteredSections, ZambiaUnregisteredRoutes, ZambiaUnregisteredSections } from "./zambia-application.types";

import { ApplicationPage } from "../application.types";
import { GenericApplicationPageType } from "../application.constants";
import { Routes } from "../../../../../routes/routes.constants";

//Navigation
export const RegisteredZambiaNavigation: {
    [type in ZambiaRegisteredRoutes]: string[];
} = {
    [ZambiaRegisteredRoutes.BUSINESS_DETAILS]: ["business details"],
    [ZambiaRegisteredRoutes.BUSINESS_CONTACT]: ["business details", "business contact details"],
    [ZambiaRegisteredRoutes.BUSINESS_INFORMATION]: ["business details", "business information"],
    [ZambiaRegisteredRoutes.SIGNATORY_PHOTOGRAPH]: ["personal details", " photograph"],
    [ZambiaRegisteredRoutes.SIGNATORY_MEANS_OF_ID]: ["personal details", " means of ID"],
    [ZambiaRegisteredRoutes.SIGNATORY_PERSONAL_DETAILS]: ["personal details", " personal information"],
    [ZambiaRegisteredRoutes.SIGNATORY_DETAILS]: ["directors and signatories", " invite directors and signatories"],
    [ZambiaRegisteredRoutes.SIGNATORY_PERSONNEL]: ["directors and signatories", " ownership"],
    [ZambiaRegisteredRoutes.DOCUMENT_UPLOAD]: ["business document", " upload company document"],
    [ZambiaRegisteredRoutes.REVIEW]: ["review"],
};

export const UnregisteredZambiaNavigation: {
    [type in ZambiaUnregisteredRoutes]: string[];
} = {
    //Unregistered
    [ZambiaUnregisteredRoutes.BUSINESS_DETAILS]: ["business details"],
    [ZambiaUnregisteredRoutes.DOCUMENT]: ["business document", " upload company document"],
    [ZambiaUnregisteredRoutes.ID]: ["personal details", " means of ID"],
    [ZambiaUnregisteredRoutes.PHOTOGRAPH]: ["personal details", " photograph"],
    [ZambiaUnregisteredRoutes.PERSONAL_DETAILS]: ["personal details", " personal information"],
    [ZambiaUnregisteredRoutes.REVIEW]: ["review"],
};

export const getRegisteredZambiaNavigationPath = (
    _section: ZambiaRegisteredSections | GenericApplicationPageType,
    _stage: ZambiaRegisteredRoutes
): string[] => {
    switch (_section) {
        case ZambiaRegisteredSections.COMPANY:
            if (!_stage) return [""];
            return RegisteredZambiaNavigation[_stage];
        case ZambiaRegisteredSections.SIGNATORY:
            if (!_stage) return RegisteredZambiaNavigation[ZambiaRegisteredRoutes.SIGNATORY_DETAILS];
            return RegisteredZambiaNavigation[_stage];
        case ZambiaRegisteredSections.DOCUMENT:
            if (!_stage) return RegisteredZambiaNavigation[ZambiaRegisteredRoutes.DOCUMENT_UPLOAD];
            return RegisteredZambiaNavigation[_stage];
        case ZambiaRegisteredSections.REVIEW:
            if (!_stage) return RegisteredZambiaNavigation[ZambiaRegisteredRoutes.REVIEW];
            return [""];
        default:
            return [""];
    }
};

export const getUnregisteredZambiaNavigationPath = (
    _section: ZambiaUnregisteredSections | GenericApplicationPageType,
    _stage: ZambiaUnregisteredRoutes
): string[] => {
    switch (_section) {
        case ZambiaUnregisteredSections.BUSINESS:
            return UnregisteredZambiaNavigation[ZambiaUnregisteredRoutes.BUSINESS_DETAILS];
        case ZambiaUnregisteredSections.IDENTITY:
            return UnregisteredZambiaNavigation[_stage];
        case ZambiaUnregisteredSections.PERSONAL:
            return UnregisteredZambiaNavigation[ZambiaUnregisteredRoutes.PERSONAL_DETAILS];
        case ZambiaUnregisteredSections.DOCUMENT:
            return UnregisteredZambiaNavigation[ZambiaUnregisteredRoutes.DOCUMENT];
        case ZambiaUnregisteredSections.REVIEW:
            if (!_stage) return UnregisteredZambiaNavigation[ZambiaUnregisteredRoutes.REVIEW];
            return [""];
        default:
            return [""];
    }
};
//sections
export const ZambiaRegisteredPages: ApplicationPage[] = [
    {
        value: ZambiaRegisteredSections.COMPANY,
        title: "Business Details",
        description: "Tell us about your company",
        index: 1,
        route: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION,
        progress: 0,
    },
    {
        value: ZambiaRegisteredSections.SIGNATORY,
        title: "Signatories and Directors",
        description: "Tell us about your business directors",
        index: 2,
        route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL,
        progress: 0,
    },
    {
        value: ZambiaRegisteredSections.DOCUMENT,
        title: "Business Documents",
        description: "Upload documents to verify your business",
        index: 3,
        route: Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT,
        progress: 0,
    },
    {
        value: ZambiaRegisteredSections.PERSONAL,
        title: "Personal Details",
        description: "Tell us about yourself",
        index: 4,
        route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
        progress: 0,
    },
];
export const ZambiaUnregisteredPages: ApplicationPage[] = [
    {
        value: ZambiaUnregisteredSections.BUSINESS,
        title: "Business Details",
        description: "Tell us about your business",
        index: 1,
        route: Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS,
        progress: 0,
    },
    {
        value: ZambiaUnregisteredSections.PERSONAL,
        title: "Personal Details",
        description: "Tell us about yourself",
        index: 2,
        route: Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS,
        progress: 0,
    },
    {
        value: ZambiaUnregisteredSections.IDENTITY,
        title: "Verify your Identity",
        description: "Take a photo and upload an identity card",
        index: 3,
        route: Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID,
        progress: 0,
    },
    {
        value: ZambiaUnregisteredSections.DOCUMENT,
        title: "Business Documents",
        description: "Upload documents to verify your business",
        index: 4,
        route: Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DOCUMENT,
        progress: 0,
    },
];
