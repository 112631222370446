import { CardSpendLimitType, CardStatus, CreateCardRequestBankingAppStatus } from "../../../../../../models/card.constants";
import React, { useState } from "react";

import CardInformation from "../../../../../../models/cardInformation";
import CardInformationDetails from "./CardInformationDetails";
import CardMain from "../../../../../../models/cardMain";
// import useCardInformation from "../../../Hooks/useCardInformation";
import CardSpendLimitBar from "../../ProgressBar/CardSpendLimitBar";
import EmptyCardImage from "../../../../../../assets/images/Card/Card.png";
import { ReactComponent as PasswordCloseIcon } from "../../../../../../assets/svg/passwordCloseIcon.svg";
import { ReactComponent as PasswordIcon } from "../../../../../../assets/svg/passwordIcon.svg";
import ShowCardDetailSkeleton from "../../Skeleton/ShowCardDetailSkeleton";
import { TailSpin } from "react-loader-spinner";
import { ReactComponent as VerveIcon } from "../../../../../../assets/svg/Cards/verve-card.svg";
import formatNumber from "../../../../../../utils/formatNumber";
import titleCase from "../../../../../../hooks/titleCase";
import { useNumberToMoney } from "../../../../../../hooks/useNumberToMoney";
import CurrencyCode from "../../../../../../components/currency-code";

interface Props {
    data: CardMain;
    isCard: boolean;
    showDetails: boolean;
    cardInformationResponse: CardInformation | null;
    isCardInformationLoading: boolean;
    isFreezingCardLoading: boolean;
}
function LencoCard(props: Props): JSX.Element {
    const [showAmount, setShowAmount] = useState<boolean>(false);

    return (
        <>
            <div className="relative w-full">
                <div className="rounded-custom shadow-lg" style={{ height: "233px", maxHeight: "233px" }}>
                    <img src={EmptyCardImage} className="w-full" alt="" />
                </div>
                {props.isFreezingCardLoading ? (
                    <div className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center rounded-custom bg-blue-backdrop bg-opacity-80">
                        <TailSpin color="#5466F9" height={24} width={24} data-type="card" />
                    </div>
                ) : null}
                {props.data.card?.status === CardStatus.FROZEN ||
                props.data.card?.status === CardStatus.DEACTIVATED ||
                props.data.card?.status === CardStatus.EXPIRED ? (
                    <div className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center rounded-custom bg-white opacity-50"></div>
                ) : null}
                <div className="absolute left-0 top-0 h-full w-full">
                    <div
                        className={
                            `flex h-full flex-col items-center justify-between space-y-4 rounded-custom p-4 ` +
                            `${!props.isCard || !props.data?.card?.isActive ? "text-black-quat" : ""}`
                        }
                    >
                        <div className="flex w-full flex-row items-start justify-between">
                            <div className="flex flex-col">
                                <span className="text-xs text-black-tertiary">
                                    {props.data.card?.linkedAccount ? "Account to Debit" : "Available Balance"}
                                </span>
                                {!props.data.card?.linkedAccount &&
                                    (props.data.card?.status === CardStatus.ACTIVE ||
                                        props.data.card?.status === CardStatus.FROZEN ||
                                        props.data.card?.status === CardStatus.DEACTIVATED ||
                                        props.data.card?.status === CardStatus.EXPIRED) && (
                                        <div className="flex items-center justify-start space-x-2 pt-1">
                                            <div className="flex h-5 items-center justify-start">
                                                {showAmount ? (
                                                    <p className="m-0 text-xl font-bold leading-none text-black-secondary" data-type="card">
                                                        <CurrencyCode data-type="card" />
                                                        {formatNumber(useNumberToMoney(props.data.balance || 0))}
                                                    </p>
                                                ) : (
                                                    <div className="flex flex-row items-center justify-center">
                                                        <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                        <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                        <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                        <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                        <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cursor-pointer" onClick={() => setShowAmount((prev) => !prev)}>
                                                {showAmount ? (
                                                    <PasswordCloseIcon className="stroke-current" />
                                                ) : (
                                                    <PasswordIcon className="stroke-current" />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {(props.data.card?.linkedAccount ||
                                    !(
                                        props.data.card?.status === CardStatus.ACTIVE ||
                                        props.data.card?.status === CardStatus.FROZEN ||
                                        props.data.card?.status === CardStatus.DEACTIVATED ||
                                        props.data.card?.status === CardStatus.EXPIRED
                                    )) && (
                                    <div className="h-7 pt-1">
                                        <span className="text-black" data-type="card">
                                            {titleCase(
                                                (props.data.card?.linkedAccount?.isMain
                                                    ? props.data.card?.linkedAccount.accountName
                                                    : props.data.card?.linkedAccount?.lencoNameMin) || "-"
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className="z-30 flex flex-row items-center space-x-1">
                                <div
                                    className={`h-2 w-2 rounded-full ${props.data?.card?.status === CardStatus.ACTIVE ? "bg-success" : ""} ${props.data?.card?.status === CardStatus.FROZEN ? "bg-info" : ""} ${
                                        props.data?.card?.status === CardStatus.UNACTIVATED ? "bg-black-secondary" : ""
                                    } ${
                                        props.data?.card?.status === CardStatus.EXPIRED ||
                                        props.data?.card?.status === CardStatus.DEACTIVATED ||
                                        props.data.createRequest.status === CreateCardRequestBankingAppStatus.DECLINED
                                            ? "bg-error"
                                            : ""
                                    } ${
                                        (props.data.createRequest.status === CreateCardRequestBankingAppStatus.REQUESTED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.PRINTED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.SHIPPED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.APPROVED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.DELIVERED) &&
                                        props.data?.card?.status !== CardStatus.ACTIVE &&
                                        props.data?.card?.status !== CardStatus.EXPIRED &&
                                        props.data?.card?.status !== CardStatus.DEACTIVATED &&
                                        props.data?.card?.status !== CardStatus.UNACTIVATED &&
                                        props.data?.card?.status !== CardStatus.FROZEN
                                            ? "bg-black-secondary"
                                            : ""
                                    }`}
                                ></div>

                                <p
                                    className={`text-xs font-medium ${props.data?.card?.status === CardStatus.ACTIVE ? "text-success" : ""} ${props.data?.card?.status === CardStatus.FROZEN ? "text-info" : ""} ${
                                        props.data?.card?.status === CardStatus.UNACTIVATED ? "text-black-secondary" : ""
                                    } ${
                                        props.data?.card?.status === CardStatus.EXPIRED ||
                                        props.data?.card?.status === CardStatus.DEACTIVATED ||
                                        props.data.createRequest.status === CreateCardRequestBankingAppStatus.DECLINED
                                            ? "text-error"
                                            : ""
                                    } ${
                                        (props.data.createRequest.status === CreateCardRequestBankingAppStatus.REQUESTED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.PRINTED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.SHIPPED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.APPROVED ||
                                            props.data.createRequest.status === CreateCardRequestBankingAppStatus.DELIVERED) &&
                                        props.data?.card?.status !== CardStatus.ACTIVE &&
                                        props.data?.card?.status !== CardStatus.EXPIRED &&
                                        props.data?.card?.status !== CardStatus.DEACTIVATED &&
                                        props.data?.card?.status !== CardStatus.UNACTIVATED &&
                                        props.data?.card?.status !== CardStatus.FROZEN
                                            ? "text-black-secondary"
                                            : ""
                                    }`}
                                >
                                    {props.data?.card?.status === CardStatus.ACTIVE ? "Active" : ""}
                                    {props.data?.card?.status === CardStatus.FROZEN ? "Frozen" : ""}
                                    {props.data?.card?.status === CardStatus.EXPIRED ? "Expired" : ""}
                                    {props.data?.card?.status === CardStatus.DEACTIVATED ? "Deactivated" : ""}
                                    {props.data?.card?.status === CardStatus.UNACTIVATED ? "Unactivated" : ""}
                                    {props.data.createRequest.status === CreateCardRequestBankingAppStatus.DECLINED ? "Declined" : ""}
                                    {(props.data.createRequest.status === CreateCardRequestBankingAppStatus.REQUESTED ||
                                        props.data.createRequest.status === CreateCardRequestBankingAppStatus.PRINTED ||
                                        props.data.createRequest.status === CreateCardRequestBankingAppStatus.SHIPPED ||
                                        props.data.createRequest.status === CreateCardRequestBankingAppStatus.APPROVED ||
                                        props.data.createRequest.status === CreateCardRequestBankingAppStatus.DELIVERED) &&
                                    props.data?.card?.status !== CardStatus.ACTIVE &&
                                    props.data?.card?.status !== CardStatus.EXPIRED &&
                                    props.data?.card?.status !== CardStatus.DEACTIVATED &&
                                    props.data?.card?.status !== CardStatus.UNACTIVATED &&
                                    props.data?.card?.status !== CardStatus.FROZEN
                                        ? "Unactivated"
                                        : ""}
                                </p>
                            </div>
                        </div>

                        {!props.isCardInformationLoading && !props.showDetails ? (
                            <div className="flex w-full items-center justify-between space-x-4">
                                <div className="w-50%">
                                    {
                                        <CardSpendLimitBar
                                            amountSpent={Number(props?.data?.cardSpend?.spent || 0)}
                                            spendingLimit={Number(props?.data?.cardSpend?.limit || 0)}
                                            interval={props?.data?.cardSpend?.type || CardSpendLimitType.MONTHLY_ACCUMULATIVE}
                                        />
                                    }
                                </div>
                                {props.data.card?.accountType && (
                                    <span className="whitespace-nowrap text-xs font-normal text-black-secondary">{props.data.card.accountType}</span>
                                )}
                            </div>
                        ) : null}
                        {props.isCardInformationLoading ? <ShowCardDetailSkeleton /> : null}
                        {props.showDetails && props.cardInformationResponse ? (
                            <CardInformationDetails type={props.data.card?.accountType || ""} info={props.cardInformationResponse} />
                        ) : null}
                        <div className="flex w-full flex-row items-center justify-between">
                            <span className="text-sm font-medium uppercase leading-none tracking-tight text-black-secondary">
                                {props.data?.createRequest?.approvedNameOnCard || props.data?.createRequest?.requestedNameOnCard}
                            </span>
                            <VerveIcon />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LencoCard;
