import {
    GrantUserLencoTerminalAccessRequest,
    GrantUserLencoTerminalManagerAccessResponse,
    GrantUserLencoTerminalViewAccessResponse,
    ListTerminalRequestsRequest,
    ListTerminalRequestsResponse,
    ListTerminalsResponseDTO,
    PosTransactionsListRequest,
    PosTransactionsListResponse,
    RemoveLencoTerminalUserAccessDTO,
    RequestTerminalRequest,
    RequestTerminalResponse,
    TerminalListDTO,
    TerminalRequestType,
    TerminalsInitResponse,
    UpdateTerminalSettingsDto,
    UpdateTerminalSettingsResponse,
} from "./terminalsApi.types";
import { getAbortControllerSignal } from "../../../../helpers/request/abortControllers";

import { GenericObject } from "../../../../helpers/types";
import Merchant from "../../../../models/merchant";
import Parsers from "../../../../utils/parsers";
import PosTransaction from "../../../../models/posTransaction";
import Terminal from "../../../../models/terminal";
import TerminalRequest from "../../../../models/terminalRequest";
import User from "../../../../models/user";
import { makeRequestWithSignal } from "../../../../helpers/request/makeRequest";

export const terminalsInit = async (): Promise<TerminalsInitResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.INIT);
    const res = await makeRequestWithSignal("/main/point-of-sale/init", {}, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        meta: {
            setupVideoUrl: Parsers.nullableString((res.meta as GenericObject).setupVideoUrl),
            purchaseCost: Parsers.nullableNumber((res.meta as GenericObject).purchaseCost),
            cautionFee: Parsers.nullableNumber((res.meta as GenericObject).cautionFee),
        },
        merchant: Parsers.classObject(res.merchant, Merchant),
        terminalsData: {
            terminals: Parsers.classObjectArray((res.terminalsData as GenericObject).terminals, Terminal),
            groupSize: Parsers.number((res.terminalsData as GenericObject).groupSize),
            total: Parsers.number((res.terminalsData as GenericObject).total),
        },
        terminalRequestsData: {
            terminalRequests: Parsers.classObjectArray((res.terminalRequestsData as GenericObject).terminalRequests, TerminalRequest),
            groupSize: Parsers.number((res.terminalRequestsData as GenericObject).groupSize),
            total: Parsers.number((res.terminalRequestsData as GenericObject).total),
        },
        settings: {
            teamMembersWithManageAccess: Parsers.classObjectArray((res.settings as GenericObject).teamMembersWithManageAccess, User),
            teamMembersWithViewAccess: Parsers.classObjectArray((res.settings as GenericObject).teamMembersWithViewAccess, User),
        },
    };
};

export const requestTerminal = async (_data: RequestTerminalRequest): Promise<RequestTerminalResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.REQUEST_NEW_TERMINAL);
    const res = await makeRequestWithSignal("/main/point-of-sale/requests/create", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        terminalRequest: Parsers.classObjectNonNullable(res.terminalRequest, TerminalRequest),
    };
};

export const listTerminalRequest = async (_data: ListTerminalRequestsRequest): Promise<ListTerminalRequestsResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.LIST_TERMINAL_REQUESTS);
    const res = await makeRequestWithSignal("/main/point-of-sale/requests/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        terminalRequests: Parsers.classObjectArray(res.terminalRequests, TerminalRequest),
    };
};

export const listTerminals = async (_data: TerminalListDTO): Promise<ListTerminalsResponseDTO> => {
    const signal = getAbortControllerSignal(TerminalRequestType.LIST_TERMINALS);
    const res = await makeRequestWithSignal("/main/point-of-sale/terminals/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        terminals: Parsers.classObjectArray(res.terminals, Terminal),
    };
};

export const terminalTransactionsList = async (_data: PosTransactionsListRequest): Promise<PosTransactionsListResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.LIST_TERMINAL_TRANSACTIONS);
    const res = await makeRequestWithSignal("/main/point-of-sale/transactions/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        transactions: Parsers.classObjectArray(res.transactions, PosTransaction),
    };
};

export const addTerminalManageAccess = async (_data: GrantUserLencoTerminalAccessRequest): Promise<GrantUserLencoTerminalManagerAccessResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.LIST_TERMINAL_TRANSACTIONS);
    const res = await makeRequestWithSignal("/main/point-of-sale/settings/manage-access/add", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        teamMembersWithManageAccess: Parsers.classObjectArray(res.teamMembersWithManageAccess, User),
    };
};

export const addTerminalViewerAccess = async (_data: GrantUserLencoTerminalAccessRequest): Promise<GrantUserLencoTerminalViewAccessResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.LIST_TERMINAL_TRANSACTIONS);
    const res = await makeRequestWithSignal("/main/point-of-sale/settings/view-access/add", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        teamMembersWithViewAccess: Parsers.classObjectArray(res.teamMembersWithViewAccess, User),
    };
};

export const removeTerminalManageAccess = async (_data: RemoveLencoTerminalUserAccessDTO): Promise<GrantUserLencoTerminalManagerAccessResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.REMOVE_TERMINAL_MANAGE_ACCESS);
    const res = await makeRequestWithSignal("/main/point-of-sale/settings/manage-access/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        teamMembersWithManageAccess: Parsers.classObjectArray(res.teamMembersWithManageAccess, User),
    };
};

export const removeTerminalViewerAccess = async (_data: RemoveLencoTerminalUserAccessDTO): Promise<GrantUserLencoTerminalViewAccessResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.REMOVE_TERMINAL_VIEWER_ACCESS);
    const res = await makeRequestWithSignal("/main/point-of-sale/settings/view-access/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        teamMembersWithViewAccess: Parsers.classObjectArray(res.teamMembersWithViewAccess, User),
    };
};

export const updateTerminalSettings = async (_data: UpdateTerminalSettingsDto): Promise<UpdateTerminalSettingsResponse> => {
    const signal = getAbortControllerSignal(TerminalRequestType.TERMINAL_SETTINGS);
    const res = await makeRequestWithSignal("/main/point-of-sale/terminals/setup", _data, signal);
    // console.log({ res });
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        terminal: Parsers.classObjectNonNullable(res.terminal, Terminal),
    };
};
