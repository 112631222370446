import React, { ReactElement } from "react";
import {
    resetTempSelectedZambiaApiAccessKey,
    setIsZambiaRegenerateApiAccessKeyModalOpen,
} from "../../../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import Modal from "../../../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../../../components/modal/modal-body";
import ModalHeader from "../../../../../../../../components/modal/modal-header";
import useRegenerateZambiaApiAccessKey from "../../../../../hookss/ApiAccessKeys/useRegenerateZambiaApiAccessKey";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";

function RegenerateKeyModal(): ReactElement {
    const dispatch = useDispatch();

    const { handleRegenerateZambiaApiAccessKey, isRegenerateZambiaApiAccessKeyLoading } = useRegenerateZambiaApiAccessKey();

    const tempSelectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.tempSelectedApiAccessKey);
    const isRegenerateApiAccessKeyModalOpen = useSelector((state: IRootState) => state.zambiaApiAccessKeys.isRegenerateApiAccessKeyModalOpen);

    const handleToggle = () => {
        dispatch(resetTempSelectedZambiaApiAccessKey());
        dispatch(setIsZambiaRegenerateApiAccessKeyModalOpen(false));
    };

    return (
        <Modal size="md" active={isRegenerateApiAccessKeyModalOpen} toggler={handleToggle}>
            <ModalHeader onClose={handleToggle}>Regenerate Key</ModalHeader>
            <ModalBody>
                <div className="w-full items-center">
                    <p className="text-sm font-normal text-black-tertiary">
                        Are you sure you want to Regenerate this API key? Your existing keys will stop working. `
                    </p>
                </div>
                <div className="flex w-full flex-row items-center justify-end space-x-4 pt-6">
                    <ButtonComp type="button" size="md" buttonType="secondary" onClick={handleToggle} color={"grey"}>
                        <span className="w-full text-center text-black-tertiary">Cancel</span>
                    </ButtonComp>
                    <ButtonComp
                        type="button"
                        size="md"
                        buttonType="primary"
                        disable={isRegenerateZambiaApiAccessKeyLoading}
                        isLoading={isRegenerateZambiaApiAccessKeyLoading}
                        onClick={() => handleRegenerateZambiaApiAccessKey({ id: tempSelectedApiAccessKey?.id || "" })}
                        color={"black"}
                    >
                        <span className="w-full text-center">Continue</span>
                    </ButtonComp>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default RegenerateKeyModal;
