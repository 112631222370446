import React, { useCallback, useState } from "react";
import { ReactComponent as FormInfoInco } from "../../../../../../assets/svg/Cards/FormInfo.svg";
import { ReactComponent as ActivateCardIcon } from "../../../../../../assets/svg/Cards/ActivateCardIcon.svg";
import { IActivateCard } from "../../../Services/cardsApi.types";
import titleCase from "../../../../../../hooks/titleCase";
import Input from "../../../../../../components/inputs/Input";
import TooltipIcons from "../../../../../../components/tooltip-icons";
import ExpiryInput from "../../../../../../components/inputs/expiry-input";
import ButtonComp from "../../../../../../components/button/ButtonComp";

function ActivateCard({ isActivateCardLoading, onCancel, onCardActivation }: IActivateCard): JSX.Element {
    const [expiryDate, setExpiryDate] = useState("");
    const [lastSixDigit, setLastSixDigit] = useState("");

    const onLastDigitChange = useCallback((e: string) => {
        if (/^\d{0,6}$/.test(e)) {
            setLastSixDigit(e);
        }
    }, []);

    const onExpiryDateChange = useCallback((e: string) => {
        setExpiryDate(e);
    }, []);

    const onActivateCardSubmit = useCallback(() => {
        const splitExpiryDate = expiryDate?.split("/");
        return onCardActivation({
            last6Digits: lastSixDigit,
            expiryMonth: splitExpiryDate[0],
            expiryYear: splitExpiryDate[1],
        });
    }, [lastSixDigit, expiryDate, onCardActivation]);

    return (
        <>
            <div className="mt-4 w-full space-y-4">
                <div className="max-w-md space-y-3">
                    <div className="flex items-center justify-between">
                        <h3 className="flex items-center gap-x-1 text-base font-medium leading-none text-black">
                            <ActivateCardIcon className="h-4" /> <span>Activate Card</span>
                        </h3>
                    </div>
                </div>
                <p className="text-sm font-normal text-black-secondary">Please follow the activation instructions included with your card.</p>
                <div className="flex w-full flex-col justify-between">
                    <div className="flex flex-col items-start space-y-2 pt-2 text-base font-normal md:items-center md:px-0 lg:items-center lg:px-0">
                        <div className="flex w-full flex-col space-y-4">
                            <div className="flex w-full flex-col items-start justify-start space-y-4 lg:flex-row">
                                <div className="relative flex w-full max-w-sm flex-col space-y-2">
                                    <Input placeholder="Last Six Digits" value={titleCase(lastSixDigit)} onChange={onLastDigitChange} fullWidth />
                                    <div className="absolute right-3 z-10 flex items-center justify-center leading-none">
                                        <TooltipIcons
                                            dataType="lastSixDigits"
                                            icon={<FormInfoInco className="stroke-current" />}
                                            message="The last 6 digits found at the front of your card"
                                            placement="top"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="relative flex w-full max-w-sm flex-col items-start justify-start space-y-2">
                                <ExpiryInput hidePrefixIcon placeholder="Expiry Date" value={expiryDate} onChange={onExpiryDateChange} />
                                <div className="absolute right-3 z-10 flex items-center justify-center leading-none">
                                    <TooltipIcons
                                        dataType="expiryDate"
                                        icon={<FormInfoInco className="stroke-current" />}
                                        message="The expiry date found at the front of your card"
                                        placement="top"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-6">
                        <ButtonComp fullWidth type="button" size="md" buttonType="secondary" onClick={onCancel} color={"grey"}>
                            <span className="w-full text-center text-black-tertiary">Cancel</span>
                        </ButtonComp>

                        <ButtonComp
                            fullWidth
                            type="button"
                            size="md"
                            buttonType="primary"
                            disable={!lastSixDigit || !expiryDate || lastSixDigit?.length !== 6 || expiryDate.length !== 5}
                            isLoading={isActivateCardLoading}
                            onClick={onActivateCardSubmit}
                            color={"black"}
                        >
                            <span className="w-full text-center">Activate Card</span>
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ActivateCard;
