import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import Errorhandler from "../../../helpers/request/error-handler";
import { clearCustomerAccounts } from "../../../redux/customer-account/customerAccountSlice";
// import { clearCustomerAccounts } from "../../../redux/customer-account/customerAccountSlice";
import { initReset } from "../../../redux/init/slice/initSlice";
import { logout } from "../services/init-api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function useLogout(): UseMutationResult<void, AxiosError | null, void, unknown> {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutApi = useMutation({
        mutationFn: async () => await logout(),
        onSuccess() {
            console.log("logout by clicking");
            navigate("/login");
            dispatch(initReset());

            dispatch(clearCustomerAccounts());
            // dispatch(resetZambiaCachedCollectionsTransactionsList());
            // dispatch(resetZambiaCachedCollectionsSettlementsList());
            // dispatch(resetZambiaCachedPayouts());
            // dispatch(resetTransactionState());
            // dispatch(resetZambiaAllApplicationState());
            // dispatch(resetAllApplicationState());
            navigate("/login");
            console.log("logout by clicking bottom");
        },

        onError(error: AxiosError) {
            console.log("in error of logout");

            Errorhandler(error);
        },
    });

    return logoutApi;
}

export default useLogout;
