import React, { useCallback, useEffect } from "react";
import {
    resetAllZambiaCollectionsTransactionsData,
    setIsExportZambiaCollectionsTransactionsTrayOpen,
    setIsZambiaCollectionsTransactionsDetailCardOpen,
    setSelectedZambiaCollectionsTransactionsDetails,
    setSelectedZambiaCollectionsTransactionsFilterStateInstant,
} from "../../../../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import ExportZambiaCollectionsTransactionsTray from "./Tray/ExportZambiaCollectionsTransactionsTray";
import { SubTextType } from "../../../../../../components/Table/Type/SubTextType";
import Table from "../../../../../../components/Table";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import { ZambiaCollectionsStageType } from "../../../../../../redux/zambia/collections/collectionsSlice.types";
import ZambiaCollectionsTransactionTray from "./Tray/ZambiaCollectionsTransactionTray";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useZambiaCollectionsTransactions from "../../../hookss/Collections/useZambiaCollectionsTransactions";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../../components/google-icon";

const COLLECTION_TRANSACTION_DATATYPE = "zambia-collections-transaction";

export type ZambiaCollectionTransactionsParams = {
    type: string;
    query: string;
};

const allCollectionTransactionsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "From", pos: 2, headType: TableColumnType.TEXT },
    { text: "Channel", pos: 3, headType: TableColumnType.PROGRESS },
    { text: "Channel Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Amount", pos: 4, headType: TableColumnType.AMOUNT },
    { text: "Status", pos: 4, headType: TableColumnType.STATUS },
];

function ZambiaCollectionTransactions(): JSX.Element {
    const dispatch = useDispatch();
    const { type, query } = useParams<ZambiaCollectionTransactionsParams>();

    const {
        handleClearFilter,
        filter,
        filterOption,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsTransactions,
        zambiaCollectionsTransactionsList,
        zambiaCollectionsTransactionsListTotal,
        zambiaCollectionsTransactionsListGroupSize,
        zambiaCollectionsTransactionsPaginationOffset,
        isZambiaCollectionsTransactionsPaginationLoading,
    } = useZambiaCollectionsTransactions();

    useEffect(() => {
        if (!type || type !== ZambiaCollectionsStageType.TRANSACTION) return;
        if (query) {
            dispatch(setSelectedZambiaCollectionsTransactionsFilterStateInstant({ offset: 0, query: query || "" }));
        }
        void handleFilteredZambiaCollectionsTransactions({ offset: 0, query: query || "" });
    }, [query]);

    const onExport = useCallback(() => {
        dispatch(setIsExportZambiaCollectionsTransactionsTrayOpen(true));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetAllZambiaCollectionsTransactionsData());
        };
    }, []);

    return (
        <>
            <ZambiaCollectionsTransactionTray />
            <ExportZambiaCollectionsTransactionsTray />

            <div className="relative mt-4 flex h-full w-full flex-col items-start justify-start">
                <div className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-start">
                    <Table
                        addExtraButton
                        extraButton={
                            <ButtonComp func={onExport} size="md" buttonType="secondary" ripple="dark" color={"white"}>
                                <span className="flex text-black-tertiary">
                                    <GoogleIcon icon="download" size="sm" /> Export
                                </span>
                            </ButtonComp>
                        }
                        addFilter
                        filter={filterOption}
                        handleClearFilter={handleClearFilter}
                        totalSelectedItems={
                            (filter?.channel ? 1 : 0) + (filter?.date?.begin ? 1 : 0) + (filter?.status ? 1 : 0) + (filter?.source ? 1 : 0)
                        }
                        dataType={COLLECTION_TRANSACTION_DATATYPE}
                        heads={allCollectionTransactionsHeader}
                        isPaginateLoading={isZambiaCollectionsTransactionsPaginationLoading}
                        groupSize={zambiaCollectionsTransactionsListGroupSize}
                        offset={zambiaCollectionsTransactionsPaginationOffset}
                        total={zambiaCollectionsTransactionsListTotal}
                        isLoading={!!isZambiaCollectionsTransactionsPaginationLoading}
                        rows={
                            zambiaCollectionsTransactionsList?.map((obj) => ({
                                onRecordClick: () => {
                                    dispatch(setIsZambiaCollectionsTransactionsDetailCardOpen(true));
                                    dispatch(setSelectedZambiaCollectionsTransactionsDetails(obj.id || ""));
                                },
                                record: [
                                    {
                                        key: "Date",
                                        text: obj?.createdAt,
                                        textType: TextType.DATE,
                                        subText: obj?.createdAt,
                                        subTextType: SubTextType.TIME,
                                    },
                                    {
                                        key: "From",
                                        text:
                                            obj?.attempts?.sort?.((a, b) => (a.isSuccessful === b?.isSuccessful ? 0 : a?.isSuccessful ? -1 : 1))?.[0]
                                                ?.mobileMoneyCollection?.debitAccountName ||
                                            obj?.attempts?.sort?.((a, b) => (a.isSuccessful === b?.isSuccessful ? 0 : a?.isSuccessful ? -1 : 1))?.[0]
                                                ?.cardPaymentCollection?.cardDetails?.name ||
                                            "-",
                                    },
                                    {
                                        key: "channel",
                                        text:
                                            obj?.attempts?.sort?.((a, b) => (a.isSuccessful === b?.isSuccessful ? 0 : a?.isSuccessful ? -1 : 1))?.[0]
                                                ?.channelText || "-",
                                    },
                                    {
                                        key: "Channel Type",
                                        text:
                                            obj?.attempts?.sort?.((a, b) => (a.isSuccessful === b?.isSuccessful ? 0 : a?.isSuccessful ? -1 : 1))?.[0]
                                                ?.cardPaymentCollection?.cardDetails?.cardType ||
                                            obj?.attempts?.sort?.((a, b) => (a.isSuccessful === b?.isSuccessful ? 0 : a?.isSuccessful ? -1 : 1))?.[0]
                                                ?.mobileMoneyCollection?.serviceProviderText ||
                                            "-",
                                    },
                                    {
                                        key: "Amount",
                                        text: obj.amount,
                                        textType: TextType.BALANCE,
                                        currency: obj.currency,
                                    },
                                    {
                                        key: "Status",
                                        text: obj.statusText,
                                        textType: obj?.isSuccessful
                                            ? TextType.TRANSACTION_STATUS_SUCCESS
                                            : obj?.isPending
                                              ? TextType.TRANSACTION_STATUS_PROCESSING
                                              : obj?.isExpired
                                                ? TextType.CARD_STATUS_EXPIRED
                                                : obj?.isCancelled
                                                  ? TextType.TRANSACTION_STATUS_CANCELLED
                                                  : TextType.TRANSACTION_STATUS_FAILED,
                                    },
                                ],
                            })) || []
                        }
                        paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                    />
                </div>
            </div>
        </>
    );
}

export default ZambiaCollectionTransactions;
