import { useEffect, useState } from "react";

import AddMoneyHeader from "../../../../Layouts/AddMoney/Components/AddMoneyHeader";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import Otp from "../../../../../../../../components/otp";
import useMakePayment from "../../../../../../Accounts/Hooks/State/Zambia/MobileMoney/useMakePayment";
import { useSelector } from "react-redux";

interface Props {
    keyId: string;
    recipientPhoneNumber: string;
    handleBack: () => void;
    handleNext: () => void;
}

function MobileMoneyMakePayment(props: Props): JSX.Element {
    const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

    const [otp, setOtp] = useState("");

    const { isMakePaymentLoading, makePaymentError, handleMakePayment } = useMakePayment({
        onComplete: () => props.handleNext(),
    });

    useEffect(() => {
        if (otp.length < 6) return;
        void handleMakePayment({
            key: props.keyId,
            otp: otp,
        });
    }, [otp]);

    const handleOtpChange = (_otp: string) => {
        setOtp(_otp);
    };

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center">
                <AddMoneyHeader title="Add Money" subtitle="" />
                <div className="flex w-full flex-col space-y-9">
                    <p className="text-center text-base text-black-secondary">
                        {!isAuthAppSetup && "Enter the OTP sent to your phone number ending with "}
                        ••••{props.recipientPhoneNumber.slice(-4)}
                        <span> to authorize payment</span>
                    </p>
                    <div className="flex w-full flex-col rounded-lg bg-white p-6">
                        <Otp
                            value={otp || undefined}
                            onChange={handleOtpChange}
                            isError={makePaymentError}
                            isResendOtpLoading={isMakePaymentLoading}
                        />
                    </div>
                    <div className="flex w-full flex-row items-center justify-center space-x-4">
                        <ButtonComp size="xl" color="grey" ripple="light" buttonType="secondary" func={props.handleBack}>
                            Back
                        </ButtonComp>

                        <ButtonComp
                            size="xl"
                            color="black"
                            ripple="light"
                            buttonType="primary"
                            isLoading={isMakePaymentLoading}
                            disable={otp.length < 6 || isMakePaymentLoading}
                            func={() =>
                                void handleMakePayment({
                                    key: props.keyId,
                                    otp: otp,
                                })
                            }
                        >
                            Authorize Payment
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileMoneyMakePayment;
