import {
    CompanyDocumentTypes,
    DeleteIncorporationDocumentRequest,
    IncorporationDocumentRequest,
} from "../../../../services/nigeria-application/registered/document.types";
import {
    abortAllBusinessDocumentRequests,
    addIncorporationDocumentFile,
    deleteIncorporationDocument,
    uploadIncorporationDocument,
} from "../../../../services/nigeria-application/registered/document.api";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setBusinessDocuments } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface UseUploadBusinessDocumentsInterface {
    isUploadBusinessDocumentsLoading: boolean | null;
    deleteIncorporationDocumentError: string | null;
    isDeleteIncorporationDocumentLoading: boolean;
    isAddIncorporationDocumentFileLoading: boolean | null;
    handleReset: () => void;
    handleUploadBusinessDocuments: (data: IncorporationDocumentRequest) => Promise<void>;
    handleDeleteIncorporationDocument: (data: DeleteIncorporationDocumentRequest) => Promise<void>;
    handleAddIncorporationDocumentFile: (data: IncorporationDocumentRequest) => Promise<void>;
}

interface Props {
    onError: () => void;
    onComplete: () => void;
}

const useUploadBusinessDocuments = (props?: Props): UseUploadBusinessDocumentsInterface => {
    const dispatch = useDispatch();

    const [isUploadBusinessDocumentsLoading, setIsUploadBusinessDocumentsLoading] = useState<boolean | null>(false);
    const [deleteIncorporationDocumentError, setDeleteIncorporationDocumentError] = useState<string | null>(null);
    const [isDeleteIncorporationDocumentLoading, setIsDeleteIncorporationDocumentLoading] = useState<boolean>(false);
    const [isAddIncorporationDocumentFileLoading, setIsAddIncorporationDocumentFileLoading] = useState<boolean | null>(false);

    const handleUploadBusinessDocuments = useCallback(
        async (_data: IncorporationDocumentRequest) => {
            try {
                setIsUploadBusinessDocumentsLoading(true);
                if (_data.type === CompanyDocumentTypes.INCORPORATION_DOCUMENT) {
                    void handleUploadBusinessDocumentsCo7({
                        file: _data.file,
                        type: "incorporationDocument",
                    });
                    void handleUploadBusinessDocumentsCo7({
                        file: _data.file,
                        type: "formC02",
                    });
                    void handleUploadBusinessDocumentsCo7({
                        file: _data.file,
                        type: "formC07",
                    });
                }
                const res = await uploadIncorporationDocument(_data);
                dispatch(setBusinessDocuments(res));
                dispatch(messageTrue({ message: "Company document uploaded successfully" }));
                props?.onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                props?.onError();
            } finally {
                setIsUploadBusinessDocumentsLoading(false);
            }
        },
        [dispatch]
    );

    const handleUploadBusinessDocumentsCo7 = useCallback(
        async (_data: { file: File; type: string }) => {
            try {
                // setIsUploadBusinessDocumentsLoading(true);
                const res = await uploadIncorporationDocument(_data as IncorporationDocumentRequest);
                dispatch(setBusinessDocuments(res));
                // dispatch(messageTrue({ message: "Company document uploaded successfully" }));
                // props?.onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                props?.onError();
            }
            // finally {
            // setIsUploadBusinessDocumentsLoading(false);
            // }
        },
        [dispatch]
    );

    const handleAddIncorporationDocumentFile = useCallback(
        async (_data: IncorporationDocumentRequest) => {
            try {
                setIsAddIncorporationDocumentFileLoading(true);
                const res = await addIncorporationDocumentFile(_data);
                dispatch(setBusinessDocuments(res));
                dispatch(messageTrue({ message: "Company document uploaded successfully" }));
                props?.onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                props?.onError();
            } finally {
                setIsAddIncorporationDocumentFileLoading(false);
            }
        },
        [dispatch]
    );

    const handleDeleteIncorporationDocument = useCallback(
        async (_data: DeleteIncorporationDocumentRequest) => {
            try {
                setDeleteIncorporationDocumentError(null);
                setIsDeleteIncorporationDocumentLoading(true);
                const res = await deleteIncorporationDocument(_data);
                dispatch(setBusinessDocuments(res));
                dispatch(messageTrue({ message: "Company document deleted successfully" }));
                props?.onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDeleteIncorporationDocumentError(errorMessage);
            } finally {
                setIsDeleteIncorporationDocumentLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = () => {
        abortAllBusinessDocumentRequests();
        setIsUploadBusinessDocumentsLoading(null);
        setDeleteIncorporationDocumentError(null);
        setIsDeleteIncorporationDocumentLoading(false);
        setIsAddIncorporationDocumentFileLoading(null);
    };

    return {
        deleteIncorporationDocumentError,
        isUploadBusinessDocumentsLoading,
        isDeleteIncorporationDocumentLoading,
        isAddIncorporationDocumentFileLoading,
        handleReset,
        handleUploadBusinessDocuments,
        handleDeleteIncorporationDocument,
        handleAddIncorporationDocumentFile,
    };
};
export default useUploadBusinessDocuments;
