import { DeclinedTransaction, SelectedTransaction } from "../../../../hooks/state/pending-approval/usePendingPayments.types";

import PendingApproval from "..";
import React from "react";
import Transaction from "../../../../../../../models/transaction";

interface Props {
    transaction: Transaction;
    selectedPayment: SelectedTransaction | undefined;
    handleApprovePayment: (_data: Transaction) => void;
    handleSelectOrChangeDeclineReason: (_data: DeclinedTransaction) => void;
    handleRemoveDeclineOrChangeToDecline: (_data: Transaction) => void;
}

function BulkPaymentPendingApproval(props: Props): JSX.Element {
    return (
        <>
            <PendingApproval
                isApproved={!!props.selectedPayment?.isApproved}
                isDeclined={!!props.selectedPayment?.isDeclined}
                transaction={props.transaction}
                declinedReason={props.selectedPayment?.declinedReason}
                handleApprovePayment={props.handleApprovePayment}
                handleSelectOrChangeDeclineReason={props.handleSelectOrChangeDeclineReason}
                handleRemoveDeclineOrChangeToDecline={props.handleRemoveDeclineOrChangeToDecline}
            />
        </>
    );
}

export default BulkPaymentPendingApproval;
