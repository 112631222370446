import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { VerifyEmailData, VerifyEmailDataResponse } from "../services/email-verification.types";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { verifyEmail } from "../services/email-verification.api";

function useEmailVerification(): UseMutationResult<VerifyEmailDataResponse, AxiosError | null, VerifyEmailData, unknown> {
    const filter = useMutation({
        mutationFn: async (_data: VerifyEmailData) => await verifyEmail(_data),
        onError(error: AxiosError) {
            return Errorhandler(error, true);
        },
    });

    return filter;
}

export default useEmailVerification;
