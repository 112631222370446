import AccountFaq from "../../../models/faq/accountFaq";
// import {AccountOpeningInitState} from "../../../modules/dashboard/application/services/application.types";
import AccountType from "../../../models/accountType";
import BandData from "../../../models/bandData";
import Bank from "../../../models/bank";
import Card from "../../../models/card";
import { CompanyInterface } from "../../../helpers/types";
import Corporate from "../../../models/corporate";
import TransactionCategory from "../../../models/transactionCategory";
import User from "../../../models/user";
import UserAccount from "../../../models/userAccount";
import UserAccountMeta from "../../../models/userAccountMeta";
import UserGroup from "../../../models/userGroup";
import UserMin from "../../../models/userMin";

export enum LencoActiveCountries {
    NIGERIA = "NG",
    ZAMBIA = "ZM",
}

//T Todo - Take these away to their own file i.e fee.constant or transaction.constant file
export enum FeeType {
    FLAT_FEE = "flat-fee",
    PERCENTAGE = "percentage",
}
export enum TransferTaxOn {
    TRANSFER_AMOUNT = 1,
    TRANSFER_FEE = 2,
}
export interface MainInitCompanyDetailsInterface {
    user: User;
    userAccountsMeta: Array<UserAccountMeta>;
    company: Corporate;
    accounts: Array<UserAccount>;
    cards: Array<Card>;
    activePrepaidCards: Array<Card>;
    userGroups: Array<UserGroup>;
    transactionCategories: Array<TransactionCategory>;
}
export interface InitState {
    main: MainInitState | null;
    error: string | null;
    account: AccountInitState | null;
    loading: boolean;
    isLoggedIn: boolean;
    // accountOpening: AccountOpeningInitState | nulls;
    canAccessCards: boolean;
    mainInitLoading: boolean;
    isAccountCreated: boolean;
    selectedCompanyId: string | null;
    canAccessTerminals: boolean;
    accountInitLoading: boolean;
    isSwitchingBusiness: boolean;
    globalActiveDataType: string;
    selectedApplicationId: string | null;
    isAccountOpeningLoading: boolean;
}

export interface AccountInitState {
    user: UserMin;
    companies: Array<CompanyInterface>;
    selectedCompanyId: string | null;
}
export interface TransferCharges {
    1: number; // transfer charge for band 1 (less than or equal to 5000)
    2: number; // transfer charge for band 2 (greater than 5000 and less than or equal to 50000)
    3: number; // transfer charge for band 3 (greater than 50000)
}
export interface CardCharges {
    1: number; // transfer charge for band 1 (less than or equal to 5000)
}
export interface MainInitState {
    options: {
        banks: Array<Bank>;
    };
    companyDetails: MainInitCompanyDetailsInterface;
    meta: {
        accountTypes: Array<AccountType>; // name and meta for each account type
        addMoneyBankAccount: {
            accountName: string;
            accountNumber: string;
            bank: string;
            branch: string;
            swiftCode: string;
        } | null;
        api: {
            isActive: boolean;
            virtualAccounts: boolean;
        };
        card: {
            hasMadeRequest: boolean;
            ngnPhysical: {
                issuanceFee: number;
                monthlyMaintenance: number;
                shouldChargeMonthlyMaintenance: boolean;
                minimumBalanceToRequest: number;
            };
        };
        cardCreationCharges: CardCharges;
        faqs: Array<AccountFaq>; // for each account type
        lencoPay: {
            isEnabled: boolean;
        };
        transferCharges: TransferCharges;
        transferFee: {
            ng: TransferCharges | null;
            zm: {
                lencoMoney: {
                    bandData: Array<BandData>;
                    minAmount: number;
                    maxAmount: number;
                };
                lencoMerchant: {
                    bandData: Array<BandData>;
                    minAmount: number;
                    maxAmount: number;
                };
                mobileMoney: {
                    bandData: Array<BandData>;
                    minAmount: number;
                    maxAmount: number;
                };
                bankAccount: {
                    bandData: Array<BandData>;
                    minAmount: number;
                    maxAmount: number;
                };
            } | null;
        };
    };

    socket: {
        token: string; // token to subscribe to websocket for realtime communication
        url: string; // url to subscribe to
        secure: boolean; // whether to connect over ssl or not
    };
}
