import { ReactComponent as Android } from "../../../../../../assets/svg/Android.svg";
import { ReactComponent as AppleStore } from "../../../../../../assets/svg/AppleStore.svg";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { Link } from "react-router-dom";
import useUnlinkMobileApp from "../../../Services/security/mobile-app/useUnlinkMobileApp";

// const AppLinks = {
//     AppleAppStore: process.env.REACT_APP_MOBILE_APP_APPLE_STORE_URL,
//     GooglePlayStore: process.env.REACT_APP_MOBILE_APP_GOOGLE_PLAY_URL,
// };

function MobileAppSettings(): JSX.Element {
    const { isLinked, deviceName, isUnlinking, handleUnlinkMobileApp } = useUnlinkMobileApp();

    return (
        <div className="flex w-full flex-col items-start justify-between space-y-3 rounded-custom bg-white p-5 lg:flex-row lg:items-center lg:space-y-0">
            <div className="flex w-full flex-col items-start justify-start">
                <span className="text-base font-medium text-black">Lenco Mobile App</span>
                <span className="text-sm font-normal text-black-tertiary">
                    {isLinked && deviceName && `Your Account is linked to ${deviceName}`}
                    {isLinked && !deviceName && "our Account is linked"}
                    {!isLinked && "Download the Lenco Mobile App"}
                </span>
            </div>

            {!isLinked && (
                <div className="flex items-center justify-start space-x-3 lg:justify-center">
                    <div>
                        <Link to="https://play.google.com/store/apps/details?id=co.lenco" target="_blank">
                            <Android className="h-fit w-40" />
                        </Link>
                    </div>
                    <div>
                        <Link to="https://apps.apple.com/us/app/lenco-bank/id1571311372" target="_blank">
                            <AppleStore className="h-fit w-36" />
                        </Link>
                    </div>
                </div>
            )}

            {isLinked && (
                <div className="w-ful">
                    <ButtonComp
                        type="button"
                        ripple="light"
                        buttonType="secondary"
                        color="blue"
                        size="md"
                        func={() => void handleUnlinkMobileApp()}
                        isLoading={isUnlinking}
                    >
                        <span>Unlink Mobile App</span>
                    </ButtonComp>
                </div>
            )}
        </div>
    );
}

export default MobileAppSettings;
