export enum SubTextType {
    DATE = 1,
    TIME = 2,
    DATE_TIME = 3,
    STRING = 4,
    TRUNCATED_TEXT = 5,
    REACT_NODE = 6,
    TRANSACTION_STATUS_SUCCESS = 12,
    TRANSACTION_STATUS_FAILED = 13,
    TRANSACTION_STATUS_CANCELLED = 14,
    TRANSACTION_STATUS_PROCESSING = 15,
    TERMINAL_STATUS_ACTIVE = 16,
    TERMINAL_STATUS_REVOKED = 17,
    AMOUNT_FEE = 18,
    TRANSACTION_PENDING_APPROVAL = 19,
}
