import "../../../Style/cardTransaction.css";
import React, { useEffect, useRef } from "react";
import CardTransactionRow from "../../TableRow/CardTransactionRow";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { ToastType } from "../../../../../../helpers/AppConstants";
import useRecentCardTransactions from "../../../Hooks/State/useRecentCardTransactions";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";

interface IRecentTransactions {
    cardId: string;
    isSelectedCardDetailsOpen: boolean;
}

function RecentTransactions({ cardId, isSelectedCardDetailsOpen }: IRecentTransactions): JSX.Element {
    const { recentCardTransactions, isRecentCardTransactionLoading, recentCardTransactionsError, handleReset, handleGetTransactions } =
        useRecentCardTransactions();

    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (cardId) {
            handleGetTransactions({
                cardIds: [cardId],
            });
        }
        return () => {
            handleReset();
        };
    }, [cardId]);

    return (
        <div className="mt-4 w-full" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
            <div className="max-w-md" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
                <div className="flex items-center justify-between py-3" ref={divRef} data-type="card">
                    <h3 className="text-base font-medium leading-none text-black" data-type="card">
                        Recent Transactions
                    </h3>
                </div>
            </div>
            <div className="mt-3 flex h-full w-full items-center justify-center 2xs:relative" data-type="card">
                {isRecentCardTransactionLoading && (
                    <div className="flex h-24 items-center justify-center" data-type="card">
                        <TailSpin color="#5466F9" height={24} width={24} data-type="card" />
                    </div>
                )}
                {recentCardTransactionsError && !isRecentCardTransactionLoading && (
                    <div className="flex h-full w-full items-center justify-center" data-type="card">
                        <div className="w-max" data-type="card">
                            <MessageToasts toastMessage={recentCardTransactionsError || ""} toastType={ToastType.ERROR} data-type="card" />
                        </div>
                    </div>
                )}
                {!isRecentCardTransactionLoading && recentCardTransactions && !recentCardTransactionsError && (
                    <>
                        {recentCardTransactions.transactions.length > 0 ? (
                            <div className="w-full" data-type="card">
                                <table className="w-full font-normal text-black" data-type="card">
                                    <tbody data-type="card">
                                        {recentCardTransactions.transactions.slice(0, 5).map((_transaction, index) => (
                                            <CardTransactionRow data={_transaction} onClick={() => divRef.current?.scrollIntoView()} key={index} />
                                        ))}
                                    </tbody>
                                </table>
                                <div className="flex w-full justify-center pt-8" data-type="card">
                                    <Link to={`/transactions/cards/${cardId}`} data-type="card">
                                        <ButtonComp color="blue" size="sm" buttonType="tertiary" disable={false} dataType="card">
                                            <span className="capitalize" data-type="card">
                                                View All Transactions
                                            </span>
                                        </ButtonComp>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="pb-2 text-center" data-type="card">
                                <p className="text text-sm text-black-tertiary" data-type="card">
                                    Your recent transactions will show here
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
export default RecentTransactions;
