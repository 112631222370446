import { removeAccountFromUserGroup, userGroupAccounts } from "../servicess/peopleApi";
import { removeCustomerAccount, setCustomerAccountList } from "../../../../redux/people/slice/peopleSlice";
import { setCustomerAccount, setMultipleCustomerAccount } from "../../../../redux/customer-account/customerAccountSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../redux/rootReducer";
import UserGroup from "../../../../models/userGroup";
import { UserGroupAccountsRequest } from "../servicess/people-api.types";
import { errorTrue } from "../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../redux/app-toast/app-toast-slice";

function useUserGroups() {
    const dispatch = useDispatch();

    const userGroups = useSelector((state: IRootState) => state.init.main?.companyDetails.userGroups);
    const customerAccountsId = useSelector((state: IRootState) => state.people.customerAccountList);
    const [placeholder, setPlaceHolder] = useState<string | null>(null);

    const [isUserGroupAccountsError, setIsUserGroupAccountsError] = useState<string>("");
    const [isUserGroupAccountsLoading, setIsUserGroupAccountsLoading] = useState<boolean>(false);

    const [isRemoveFromUserGroupError, setIsRemoveFromUserGroupError] = useState<string>("");
    const [isRemoveFromUserGroupLoading, setIsRemoveFromUserGroupLoading] = useState<boolean>(false);

    const handleUserGroupAccounts = useCallback(
        async (_data: UserGroupAccountsRequest) => {
            try {
                setIsUserGroupAccountsError("");
                setIsUserGroupAccountsLoading(true);
                const res = await userGroupAccounts(_data);
                dispatch(setCustomerAccountList(res.accounts.map((_el) => _el.id)));
                dispatch(setMultipleCustomerAccount(res.accounts));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setIsUserGroupAccountsError(errorMessage);
            } finally {
                setIsUserGroupAccountsLoading(false);
            }
        },
        [dispatch]
    );

    const handleRemoveFromUserGroups = useCallback(
        async (_data: string) => {
            try {
                setIsRemoveFromUserGroupError("");
                setIsRemoveFromUserGroupLoading(true);
                const res = await removeAccountFromUserGroup(_data);
                dispatch(removeCustomerAccount(res.id));
                // update state.customerAccount
                dispatch(setCustomerAccount(res));
                dispatch(messageTrue({ message: "Recipient removed successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setIsRemoveFromUserGroupError(errorMessage);
            } finally {
                setIsRemoveFromUserGroupLoading(false);
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (!userGroups || customerAccountsId) return;
        const userGroup: UserGroup | undefined = userGroups.find((el) => !el.isDeleted);
        if (userGroup) {
            void handleUserGroupAccounts({
                userGroupId: userGroup.id,
                offset: 0,
            });
            setPlaceHolder(userGroup.name);
        }
    }, [handleUserGroupAccounts, customerAccountsId, userGroups]);

    const handleResetError = useCallback(() => {
        setIsUserGroupAccountsError("");
    }, []);

    const handleChangeUserGroup = useCallback(({ uid, name }: { uid: string; name: string }) => {
        void handleUserGroupAccounts({
            userGroupId: uid,
            offset: 0,
        });
        setPlaceHolder(name);
    }, []);

    //remove user from user group
    const handleRemoveFromUserGroup = useCallback((el: string) => {
        void handleRemoveFromUserGroups(el);
    }, []);

    return {
        userGroups: userGroups || [],
        placeholder,
        isUserGroupAccountsError,
        isUserGroupAccountsLoading,

        handleResetError,
        handleUserGroupAccounts,

        isRemoveFromUserGroupLoading,
        isRemoveFromUserGroupError,
        handleChangeUserGroup,
        handleRemoveFromUserGroup,
    };
}

export default useUserGroups;
