import {
    resetZambiaCollectionsSettlementsDateFilterState,
    resetZambiaCollectionsSettlementsFilterState,
    setIsZambiaCollectionsSettlementsPaginationLoading,
    setZambiaCollectionsSettlementsList,
    setZambiaCollectionsSettlementsListGroupSize,
    setZambiaCollectionsSettlementsListTotal,
    setZambiaCollectionsSettlementsPaginationOffset,
} from "../../../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { ZambiaCollectionsSettlementRequest } from "../../Services/Collections/zambiaCollectionsSettlementApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useAppSelector } from "../../../../../redux/hooks";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { zambiaCollectionsSettlement } from "../../Services/Collections/zambiaCollectionsSettlementApi";
import { FilterItemSelectType, Item, TableFilterProps } from "../../../../../components/Table/components/TableFilter";
import { DateObj } from "../../../../../components/Table/Type";
import {
    ZambiaCollectionsSettlementsChannelOptions,
    ZambiaCollectionsSettlementsStatusOptions,
    ZambiaCollectionsSettlementsTypeOptions,
} from "./zambiaCollectionsSettlements.constants";
import {
    ZambiaCollectionPaymentChannel,
    ZambiaSettlementStatus,
    ZambiaSettlementType,
} from "../../../../../models/zambia/zambiaCollectionRequest.constant";

function useZambiaCollectionsSettlements() {
    const dispatch = useDispatch();

    const [filter, setFilter] = useState<ZambiaCollectionsSettlementRequest>({});
    const selectedZambiaCollectionsSettlementsFilterState = useAppSelector(
        (state) => state.zambiaCollectionsSettlements.selectedZambiaCollectionsSettlementsFilterState
    );

    const zambiaCollectionsSettlementsList = useAppSelector((state) => state.zambiaCollectionsSettlements.zambiaCollectionsSettlementsList);
    const zambiaCollectionsSettlementsListTotal = useAppSelector((state) => state.zambiaCollectionsSettlements.zambiaCollectionsSettlementsListTotal);
    const zambiaCollectionsSettlementsListGroupSize = useAppSelector(
        (state) => state.zambiaCollectionsSettlements.zambiaCollectionsSettlementsListGroupSize
    );
    const zambiaCollectionsSettlementsPaginationOffset = useAppSelector(
        (state) => state.zambiaCollectionsSettlements.zambiaCollectionsSettlementsPaginationOffset
    );
    const isZambiaCollectionsSettlementsPaginationLoading = useAppSelector(
        (state) => state.zambiaCollectionsSettlements.isZambiaCollectionsSettlementsPaginationLoading
    );

    const handleGetZambiaCollectionsSettlements = useCallback(
        async (_data: ZambiaCollectionsSettlementRequest) => {
            try {
                dispatch(setIsZambiaCollectionsSettlementsPaginationLoading(true));
                const res = await zambiaCollectionsSettlement(_data);
                dispatch(setZambiaCollectionsSettlementsList(res.settlements));
                dispatch(setZambiaCollectionsSettlementsListTotal(res.total));
                dispatch(setZambiaCollectionsSettlementsListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsZambiaCollectionsSettlementsPaginationLoading(false));
            }
        },
        [dispatch]
    );

    const handleFilteredZambiaCollectionsSettlements = useCallback(
        async (_data: ZambiaCollectionsSettlementRequest) => {
            try {
                dispatch(setIsZambiaCollectionsSettlementsPaginationLoading(true));
                const res = await zambiaCollectionsSettlement(_data);
                dispatch(setZambiaCollectionsSettlementsList(res.settlements));
                dispatch(setZambiaCollectionsSettlementsListTotal(res.total));
                dispatch(setZambiaCollectionsSettlementsListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsZambiaCollectionsSettlementsPaginationLoading(false));
            }
        },
        [selectedZambiaCollectionsSettlementsFilterState]
    );

    const handleClearFilter = useCallback(() => {
        setFilter({ query: "" });
        handleFilteredZambiaCollectionsSettlements({ offset: 0, query: "" });
    }, [filter]);

    const filterOption: TableFilterProps = useMemo(
        () =>
            ({
                items: [
                    {
                        text: "Date",
                        selectType: FilterItemSelectType.DATE_OPTION,
                        onClick: (data) => {
                            setFilter((prev) => ({ ...prev, date: { begin: (data as DateObj)?.begin, end: (data as DateObj)?.end } }));
                        },
                    },
                    {
                        text: "Status",
                        selectType: FilterItemSelectType.RADIO_OPTION,
                        subList: ZambiaCollectionsSettlementsStatusOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: ZambiaSettlementStatus }) => {
                            filter.status = data?.value;
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Channel",
                        selectType: FilterItemSelectType.RADIO_OPTION,
                        subList: ZambiaCollectionsSettlementsChannelOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: ZambiaCollectionPaymentChannel }) => {
                            filter.channel = data?.value;
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Type",
                        selectType: FilterItemSelectType.RADIO_OPTION,
                        subList: ZambiaCollectionsSettlementsTypeOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: ZambiaSettlementType }) => {
                            filter.settlementType = data?.value;
                            setFilter({ ...filter });
                        },
                    },
                ] as Item[],
                handleApplyFilter: () => {
                    handleFilteredZambiaCollectionsSettlements(filter);
                },
            }) as TableFilterProps,
        [filter]
    );

    const handlePaginationRequest = useCallback((offset: number) => {
        dispatch(setZambiaCollectionsSettlementsPaginationOffset(offset));
        handleFilteredZambiaCollectionsSettlements({ offset });
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetZambiaCollectionsSettlementsFilterState());
        dispatch(resetZambiaCollectionsSettlementsDateFilterState());
        void handleGetZambiaCollectionsSettlements({
            query: "",
            offset: 0,
            channel: undefined,
            status: undefined,
            date: {
                begin: "",
                end: "",
            },
        });
    }, []);

    return {
        zambiaCollectionsSettlementsList,
        zambiaCollectionsSettlementsListTotal,
        zambiaCollectionsSettlementsListGroupSize,
        zambiaCollectionsSettlementsPaginationOffset,
        isZambiaCollectionsSettlementsPaginationLoading,
        filter,
        filterOption,
        handleClearFilter,
        handleClearAll,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsSettlements,
    };
}

export default useZambiaCollectionsSettlements;
