import { AllUserRoles, UserRoleText } from "../../../../../../models/userAccount.constants";
import React, { useCallback, useState } from "react";

import EditApprovalLimitModal from "./EditApprovalLimitModal";
import SetApprovalLimitModal from "./SetApprovalLimitModal";
import TeamMemberApprovalLimitDropdown from "./TeamMemberApprovalLimitDropdown";
import TeamMemberDropdown from "./TeamMemberDropdown";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import useTeamMemberRole from "../../../Services/team-members/hooks/useTeamMemberRole";

interface TeamMemberRoleProps {
    accountMeta: UserAccountMeta;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    canUpdate: boolean;
}

function TeamMemberRole({ accountMeta, updateAccountMeta, canUpdate }: TeamMemberRoleProps): JSX.Element {
    const { isSubmitting, handleUpdateRole } = useTeamMemberRole(updateAccountMeta);
    const [maxApprovalLimit, setMaxApprovalLimit] = useState<number | null>(null);
    const [isSetApprovalLimitModalOpen, setIsSetApprovalLimitModalOpen] = useState<boolean>(false);
    const [isEditApprovalLimitModalOpen, setIsEditApprovalLimitModalOpen] = useState<boolean>(false);

    const handleOpenSetApprovalLimitModal = useCallback(() => {
        setIsSetApprovalLimitModalOpen(true);
    }, []);

    const handleCloseSetApprovalLimitModal = useCallback(() => {
        setMaxApprovalLimit(null);
        setIsSetApprovalLimitModalOpen(false);
    }, []);

    const handleOpenEditApprovalLimitModal = useCallback(() => {
        setIsEditApprovalLimitModalOpen(true);
    }, []);

    const handleCloseEditApprovalLimitModal = useCallback(() => {
        setMaxApprovalLimit(null);
        setIsEditApprovalLimitModalOpen(false);
    }, []);

    const handleOpenEditApprovalLimitModalWithAmount = useCallback(() => {
        setMaxApprovalLimit(accountMeta.maxApprovalAmount);
        handleOpenEditApprovalLimitModal();
    }, [accountMeta]);

    return (
        <>
            <SetApprovalLimitModal
                data={accountMeta}
                active={isSetApprovalLimitModalOpen}
                toggler={handleCloseSetApprovalLimitModal}
                updateAccountMeta={updateAccountMeta}
            />
            <EditApprovalLimitModal
                data={accountMeta}
                maxApprovalLimit={maxApprovalLimit ? String(maxApprovalLimit) : null}
                active={isEditApprovalLimitModalOpen}
                toggler={handleCloseEditApprovalLimitModal}
                updateAccountMeta={updateAccountMeta}
            />
            <div className="flex flex-row items-start justify-start space-x-2 space-y-0 sm:flex-col sm:space-x-0 sm:space-y-2">
                <TeamMemberDropdown
                    canUpdate={canUpdate}
                    text={UserRoleText[accountMeta.role]}
                    currentValue={accountMeta.role}
                    isSubmitting={isSubmitting}
                    onDropdownItemSelect={(role) => void handleUpdateRole(accountMeta, role)}
                    options={AllUserRoles.map((role) => ({
                        value: role,
                        text: UserRoleText[role],
                    }))}
                    handleOpenSetApprovalLimitModal={handleOpenSetApprovalLimitModal}
                    // roleType
                />
                {accountMeta.canApprove() && !accountMeta.isAdmin && (
                    <TeamMemberApprovalLimitDropdown
                        data={accountMeta}
                        hasApprovalLimit={!!(accountMeta.maxApprovalAmount && accountMeta.maxApprovalAmount > 0)}
                        updateAccountMeta={updateAccountMeta}
                        handleOpenSetApprovalLimitModal={handleOpenSetApprovalLimitModal}
                        handleOpenSetApprovalLimitModalWithAmount={handleOpenEditApprovalLimitModalWithAmount}
                    />
                )}
            </div>
        </>
    );
}

export default TeamMemberRole;
