import React from "react";
import { CardUsageStage } from "../../../Types";

interface Props {
    stage: CardUsageStage;
}

export default function RequestCardHeeder({ stage }: Props) {
    return (
        <div className="space-y-4">
            {stage === CardUsageStage.USAGE_DESCRIPTION && <p>How would you use this card?</p>}

            {stage === CardUsageStage.CUSTOMIZATION && (
                <div className="space-y-2">
                    <p>Request a Card</p>
                    <p className="text-base font-normal text-black-secondary">Customize your card</p>
                </div>
            )}

            {stage === CardUsageStage.HOLDER && (
                <div className="space-y-2">
                    <p>Assign Card</p>
                    <p className="text-base font-normal text-black-secondary">Select who would hold this card</p>
                </div>
            )}

            {stage === CardUsageStage.DELIVERY_DETAILS && (
                <div className="space-y-2">
                    <p>Delivery Details</p>
                    <p className="text-base font-normal text-black-secondary">Enter delivery and contact details for the card</p>
                </div>
            )}

            {stage === CardUsageStage.CARD_REQUEST && <></>}
            <div className={`${stage === CardUsageStage.CARD_REQUEST ? "invisible" : "flex space-x-2"} `}>
                <div className="w-5 rounded-lg border-b-4 border-blue" />
                <div className={`${!(stage === CardUsageStage.USAGE_DESCRIPTION) ? "border-blue" : "border-grey"} w-5 rounded-lg border-b-4`} />
                <div
                    className={`${
                        !(stage === CardUsageStage.USAGE_DESCRIPTION) && !(stage === CardUsageStage.CUSTOMIZATION) ? "border-blue" : "border-grey"
                    } w-5 rounded-lg border-b-4`}
                />
                <div className={`${stage === CardUsageStage.DELIVERY_DETAILS ? "border-blue" : "border-grey"} w-5 rounded-lg border-b-4`} />
            </div>
        </div>
    );
}
