import { CableTvSections } from "../../../hooks/state/BillPayment/billPayments.constants";
import { IRootState } from "../../../../../../redux/rootReducer";
import PaymentHeader from "../../Layouts/payment/components/page-header";
import { useSelector } from "react-redux";

function CableTVPurchaseHeaders(): JSX.Element {
    const billPaymentStage = useSelector((state: IRootState) => state.billPayment.billPaymentStage);
    return <PaymentHeader header={CableTvSections[billPaymentStage].text} subtitle={CableTvSections[billPaymentStage].subtext} />;
}

export default CableTVPurchaseHeaders;
