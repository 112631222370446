import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";
import Transaction from "../../../../../models/transaction";
import Parsers from "../../../../../utils/parsers";
import { ExportZambiaPayoutsRequest, ExportZambiaPayoutsResponse, ZambiaPayoutsRequest, ZambiaPayoutsResponse } from "./zambiaPayoutsApi.types";

export enum ZambiaPayoutsRequestType {
    EXPORT = "zambia-payouts.export",
    PAYOUT_LIST = "zambia-payouts.payout-list",
}

export const zambiaPayouts = async (_data: ZambiaPayoutsRequest): Promise<ZambiaPayoutsResponse> => {
    const signal = getAbortControllerSignal(ZambiaPayoutsRequestType.PAYOUT_LIST);
    const res = await makeRequestWithSignal("/main/lenco-pay/payouts/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        transactions: Parsers.classObjectArray(res.transactions, Transaction),
    };
};

export const exportZambiaPayouts = async (_data: ExportZambiaPayoutsRequest): Promise<ExportZambiaPayoutsResponse> => {
    const signal = getAbortControllerSignal(ZambiaPayoutsRequestType.EXPORT);
    const res = await makeRequestWithSignal("/main/lenco-pay/payouts/export", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        eventName: Parsers.string(res.eventName),
        key: Parsers.string(res.key),
    };
};

export function abortZambiaPayoutsRequest(type: ZambiaPayoutsRequestType): void {
    abortRequest(type);
}

export function abortAllZambiaPayoutsRequests(): void {
    Object.values(ZambiaPayoutsRequestType).forEach((type) => abortRequest(type));
}
