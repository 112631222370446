import { addUtilityBillFile, deleteUtilityBill, uploadUtilityBill } from "../../../../services/nigeria-application/registered/document.api";
import { useCallback, useState } from "react";

import { UtilityBillRequest } from "../../../../services/nigeria-application/registered/document.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setBusinessDocuments } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface UseUploadBusinessUtilityBillInterface {
    deleteBusinessUtilityBillError: string | null;
    isUploadBusinessUtilityBillLoading: boolean | null;
    isDeleteBusinessUtilityBillLoading: boolean;
    isAddBusinessUtilityBillFileLoading: boolean | null;
    handleUploadBusinessUtilityBill: (data: UtilityBillRequest) => Promise<void>;
    handleDeleteBusinessUtilityBill: () => Promise<void>;
    handleAddBusinessUtilityBillFile: (data: UtilityBillRequest) => Promise<void>;
}

interface Props {
    onError: () => void;
    onDeleteComplete?: () => void;
}

const useUploadBusinessUtilityBill = (props?: Props): UseUploadBusinessUtilityBillInterface => {
    const dispatch = useDispatch();

    const [isUploadBusinessUtilityBillLoading, setIsUploadBusinessUtilityBillLoading] = useState<boolean | null>(false);
    const [deleteBusinessUtilityBillError, setDeleteBusinessUtilityBillError] = useState<string | null>(null);
    const [isDeleteBusinessUtilityBillLoading, setIsDeleteBusinessUtilityBillLoading] = useState<boolean>(false);
    const [isAddBusinessUtilityBillFileLoading, setIsAddBusinessUtilityBillFileLoading] = useState<boolean | null>(false);

    const handleUploadBusinessUtilityBill = useCallback(
        async (_data: UtilityBillRequest) => {
            try {
                setIsUploadBusinessUtilityBillLoading(true);
                const res = await uploadUtilityBill(_data);
                dispatch(setBusinessDocuments(res));
                dispatch(messageTrue({ message: "Utility bill uploaded successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                props?.onError();
            } finally {
                setIsUploadBusinessUtilityBillLoading(false);
            }
        },
        [dispatch]
    );

    const handleAddBusinessUtilityBillFile = useCallback(
        async (_data: UtilityBillRequest) => {
            try {
                setIsAddBusinessUtilityBillFileLoading(true);
                const res = await addUtilityBillFile(_data);
                dispatch(setBusinessDocuments(res));
                dispatch(messageTrue({ message: "Utility bill uploaded successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                props?.onError();
            } finally {
                setIsAddBusinessUtilityBillFileLoading(false);
            }
        },
        [dispatch]
    );

    const handleDeleteBusinessUtilityBill = useCallback(async () => {
        try {
            setDeleteBusinessUtilityBillError(null);
            setIsDeleteBusinessUtilityBillLoading(true);
            const res = await deleteUtilityBill();
            dispatch(setBusinessDocuments(res));
            dispatch(messageTrue({ message: "Utility bill deleted successfully" }));
            props?.onDeleteComplete && props.onDeleteComplete();
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
            setDeleteBusinessUtilityBillError(errorMessage);
        } finally {
            setIsDeleteBusinessUtilityBillLoading(false);
        }
    }, [dispatch]);

    return {
        deleteBusinessUtilityBillError,
        isUploadBusinessUtilityBillLoading,
        isDeleteBusinessUtilityBillLoading,
        isAddBusinessUtilityBillFileLoading,
        handleUploadBusinessUtilityBill,
        handleAddBusinessUtilityBillFile,
        handleDeleteBusinessUtilityBill,
    };
};
export default useUploadBusinessUtilityBill;
