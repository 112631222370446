import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import CreatorReview from "./components/creatorReview";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import InvitedReview from "./components/InvitedReview";
import LencoSpinner from "../../../../../../../../components/spinner";
import PreviewModal from "../../../../modals/preview-modal";
import { Routes } from "../../../../../../../../routes/routes.constants";
import Skeleton from "../../../../../../../../components/skeleton";
import UseAgreementInit from "../../../../../Hooks/nigeria-application/registered-business/agreement/useAgreementInit";
import useAccountSignatoriesInit from "../../../../../Hooks/nigeria-application/registered-business/account-signatories/useAccountSignatoriesInit";
import useBusinessDetailsInit from "../../../../../Hooks/nigeria-application/registered-business/business-details/useBusinessDetailsInit";
import useBusinessDocumentsInit from "../../../../../Hooks/nigeria-application/registered-business/business-documents/useBusinessDocumentsInit";
import useNigeriaInit from "../../../../../Hooks/nigeria-application/general/useNigeriaInit";
import usePreview from "../../../../../Hooks/nigeria-application/registered-business/account-signatories/usePreview";
import usePreviewBusinessAgreement from "../../../../../Hooks/nigeria-application/registered-business/agreement/usePreviewBusinessAgreement";
import useRefereeInit from "../../../../../Hooks/nigeria-application/registered-business/invite-referee/useRefereesInit";
import { useSelector } from "react-redux";

type LocationState = {
    from?: string;
};

function BusinessReview(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
    });
    const { previewBlob: agreementPreviewBlob, handlePreviewDocument: handlePreviewAgreementDocument } = usePreviewBusinessAgreement({
        onComplete: () => setShowPreviewModal(true),
    });

    const { businessDetailsInitError, isBusinessDetailsInitLoading, handleBusinessDetailsInit } = useBusinessDetailsInit();
    const {
        currentSignatoryError,
        accountSignatoriesError,
        isCurrentSignatoryLoading,
        isAccountSignatoriesLoading,
        handleCurrentSignatoryInit,
        handleAccountSignatoriesInit,
    } = useAccountSignatoriesInit();
    const { documentDetailsError, isDocumentDetailsLoading, handleDocumentDetailsInit } = useBusinessDocumentsInit();
    const { agreementDetailsError, isAgreementDetailsLoading, handleAgreementInit } = UseAgreementInit();
    const { refereesInitError, isRefereesInitLoading, handleRefereesInit } = useRefereeInit();
    const { nigeriaAccountOpeningInitError, isNigeriaAccountOpeningInitLoading, handleNigeriaAccountOpeningInit } = useNigeriaInit();

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const currentSignatory = useSelector((state: IRootState) => state.application.currentSignatory);

    const divRef = useRef<HTMLDivElement | null>(null);

    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    useLayoutEffect(() => {
        void handleNigeriaAccountOpeningInit();
        void handleBusinessDetailsInit();
        void handleCurrentSignatoryInit();
        void handleAccountSignatoriesInit();
        void handleDocumentDetailsInit();
        void handleRefereesInit();
        void handleAgreementInit();
    }, []);

    useEffect(() => {
        if (currentSignatory) void handlePreviewDocument(currentSignatory?.photoId || "", true);
    }, [currentSignatory]);

    const handleBack = useCallback(() => {
        navigate(locationState?.from || Routes.ACCOUNT_OPENING.STATUS);
    }, [location]);

    return (
        <>
            <PreviewModal
                active={showPreviewModal}
                toggler={() => setShowPreviewModal(false)}
                data={previewBlob || agreementPreviewBlob}
                title="Preview"
            />

            {(isBusinessDetailsInitLoading ||
                isCurrentSignatoryLoading ||
                isDocumentDetailsLoading ||
                isAgreementDetailsLoading ||
                isAccountSignatoriesLoading ||
                isRefereesInitLoading ||
                isNigeriaAccountOpeningInitLoading) && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <LencoSpinner />
                </div>
            )}
            {(businessDetailsInitError ||
                currentSignatoryError ||
                accountSignatoriesError ||
                documentDetailsError ||
                agreementDetailsError ||
                refereesInitError ||
                nigeriaAccountOpeningInitError) && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <ErrorToast error={currentSignatoryError || businessDetailsInitError || nigeriaAccountOpeningInitError} />
                </div>
            )}
            {!isBusinessDetailsInitLoading &&
                !isCurrentSignatoryLoading &&
                !isDocumentDetailsLoading &&
                !isAgreementDetailsLoading &&
                !isAccountSignatoriesLoading &&
                !isRefereesInitLoading &&
                !isNigeriaAccountOpeningInitLoading &&
                !businessDetailsInitError &&
                !currentSignatoryError &&
                !accountSignatoriesError &&
                !documentDetailsError &&
                !agreementDetailsError &&
                !refereesInitError &&
                !nigeriaAccountOpeningInitError &&
                accountOpening && (
                    <div className="flex w-full flex-row items-start justify-start space-x-6">
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Review your Application"
                                subTitle="You can make any necessary changes as you complete your application."
                                progress={
                                    accountOpening.isCreator
                                        ? accountOpening.progress.registeredProgress()
                                        : accountOpening.progress.invitedRegisteredProgress()
                                }
                            >
                                <div className="relative flex w-full flex-col items-start justify-start space-y-8">
                                    <div className="flex w-full items-center justify-center">
                                        <div className="h-24 w-24 overflow-hidden rounded-full border">
                                            {previewBlob ? (
                                                <img className="h-full w-full object-cover" src={URL.createObjectURL(previewBlob)} />
                                            ) : (
                                                <Skeleton box />
                                            )}
                                        </div>
                                    </div>
                                    {accountOpening.isCreator ? (
                                        <CreatorReview
                                            handlePreviewDocument={(_data) => void handlePreviewDocument(_data)}
                                            handlePreviewAgreementDocument={(_data) => void handlePreviewAgreementDocument(_data)}
                                        />
                                    ) : (
                                        <InvitedReview
                                            handlePreviewDocument={(_data) => void handlePreviewDocument(_data)}
                                            handlePreviewAgreementDocument={(_data) => void handlePreviewAgreementDocument(_data)}
                                        />
                                    )}
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4" ref={divRef}>
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span>Back</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                    <ButtonComp
                                        type="button"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        func={() =>
                                            navigate(
                                                {
                                                    pathname: Routes.ACCOUNT_OPENING.DONE,
                                                },
                                                {
                                                    state: {
                                                        from: Routes.ACCOUNT_OPENING.REVIEW,
                                                    },
                                                }
                                            )
                                        }
                                        disable={
                                            accountOpening.isCreator
                                                ? accountOpening.progress.registeredProgress() !== 100
                                                : accountOpening.progress.invitedRegisteredProgress() !== 100
                                        }
                                        fullWidth
                                    >
                                        <span>Complete Application</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </div>
                        {/* <div className="fixed bottom-16 right-8 z-40">
                            <ButtonComp
                                color="white"
                                buttonType="flat"
                                size="sm"
                                fullBorder
                                func={() => {
                                    divRef.current?.scrollIntoView({ behavior: "smooth" });
                                }}
                            >
                                <div className="flex max-w-[20px] items-center justify-center overflow-hidden">
                                    <span className="material-symbols-rounded">keyboard_double_arrow_down</span>
                                </div>
                                <span className="ml-2 text-sm font-medium normal-case text-blue">Jump to end</span>
                            </ButtonComp>
                        </div> */}
                        {/* <div className="flex justify-end items-end w-full h-full relative">
							<div
								className="flex flex-row justify-center items-center fixed top-90% cursor-pointer"
								onClick={() => {
									divRef.current?.scrollIntoView({behavior: "smooth"});
								}}
							></div>
						</div> */}
                    </div>
                )}
        </>
    );
}

export default BusinessReview;
