import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ActiveRegBusiness from "../../../../assets/images/general/active-reg.svg";
import ActiveUnRegBusiness from "../../../../assets/images/general/active-un-reg.svg";
import { ApplicationBusinessType } from "../services/application.constants";
import BusinessTypeCard from "../../../landing/business-type/components/business-type-card";
import { BusinessTypeText } from "../../../landing/business-type/service/business-type.constant";
import { IRootState } from "../../../../redux/rootReducer";
import InActiveRegBusiness from "../../../../assets/images/general/inactive-reg.svg";
import InActiveUnRegBusiness from "../../../../assets/images/general/inactive-un-reg.svg";
import { LencoActiveCountries } from "../../../../redux/init/slice/initSlice.types";
import { PageTitle } from "../../../../helpers/app-constants";
import { setApplicationBusinessType } from "../../../../redux/application/applicationSlice";
import { setZambiaApplicationBusinessType } from "../../../../redux/application/zambiaApplicationSlice";
import useSelectBusinessType from "../Hooks/useSelectTypeOfBusiness";

function SelectTypeOfBusiness(): JSX.Element {
    document.title = PageTitle.APPLICATION_PAGE;

    const dispatch = useDispatch();

    const [isZambia, setIsZambia] = useState(false);

    const companies = useSelector((state: IRootState) => state.init.account?.companies);
    const selectedCompanyId = useSelector((state: IRootState) => state.init.selectedCompanyId);
    const applicationBusinessType = useSelector((state: IRootState) => state.application.applicationBusinessType);
    const zambiaApplicationBusinessType = useSelector((state: IRootState) => state.zambiaApplication.applicationBusinessType);

    const { isRegistrationStatusLoading, handleReset, handleSetZambiaRegistrationStatus, handleSetNigeriaRegistrationStatus } =
        useSelectBusinessType();

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    useEffect(() => {
        const country = companies?.find((_) => _.id === selectedCompanyId);
        if (!country) return;
        setIsZambia(country.country === LencoActiveCountries.ZAMBIA);
    }, [companies, selectedCompanyId]);

    const handleSelectRegisteredBusiness = () => {
        if (isZambia) {
            dispatch(setZambiaApplicationBusinessType(ApplicationBusinessType.REGISTERED));
            void handleSetZambiaRegistrationStatus({
                isRegistered: true,
            });
        } else {
            dispatch(setApplicationBusinessType(ApplicationBusinessType.REGISTERED));
            void handleSetNigeriaRegistrationStatus({
                isRegistered: true,
            });
        }
    };

    const handleSelectUnregisteredBusiness = () => {
        if (isZambia) {
            dispatch(setZambiaApplicationBusinessType(ApplicationBusinessType.UNREGISTERED));
            void handleSetZambiaRegistrationStatus({
                isRegistered: false,
            });
        } else {
            dispatch(setApplicationBusinessType(ApplicationBusinessType.UNREGISTERED));
            void handleSetNigeriaRegistrationStatus({
                isRegistered: false,
            });
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-start pb-20">
                <div className="flex w-full max-w-lg flex-col items-center justify-center pt-16">
                    <h3 className="pt-4 text-center text-xl font-medium text-black">What best describes your business?</h3>
                    <div className="w-full pb-6 pt-2 text-center text-sm font-normal text-black-secondary">
                        <p>
                            No matter what type of business you run, Lenco would provide you a better business account and help you manage your
                            financial operations.
                        </p>
                        <p className="mt-1 font-medium">Select your business type.</p>
                    </div>
                    <div className="flex flex-col justify-start space-y-6">
                        <BusinessTypeCard
                            icon={<img src={InActiveRegBusiness} alt="Registered Business Icon" />}
                            hoverIcon={<img src={ActiveRegBusiness} alt="Registered Business Icon" />}
                            header="Registered Business"
                            onClick={handleSelectRegisteredBusiness}
                            isLoading={
                                (applicationBusinessType === ApplicationBusinessType.REGISTERED ||
                                    zambiaApplicationBusinessType === ApplicationBusinessType.REGISTERED) &&
                                isRegistrationStatusLoading
                            }
                            subtitleOne={
                                isZambia
                                    ? BusinessTypeText[LencoActiveCountries.ZAMBIA].registered.Subtitle
                                    : BusinessTypeText[LencoActiveCountries.NIGERIA].registered.Subtitle
                            }
                        />

                        <BusinessTypeCard
                            icon={<img src={InActiveUnRegBusiness} alt="UnRegistered Business Icon" />}
                            hoverIcon={<img src={ActiveUnRegBusiness} alt="UnRegistered Business Icon" />}
                            header="Unregistered Business"
                            onClick={handleSelectUnregisteredBusiness}
                            isLoading={
                                (applicationBusinessType === ApplicationBusinessType.UNREGISTERED ||
                                    zambiaApplicationBusinessType === ApplicationBusinessType.UNREGISTERED) &&
                                isRegistrationStatusLoading
                            }
                            subtitleOne={
                                isZambia
                                    ? BusinessTypeText[LencoActiveCountries.ZAMBIA].unregistered.Subtitle
                                    : BusinessTypeText[LencoActiveCountries.NIGERIA].unregistered.Subtitle
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default SelectTypeOfBusiness;
