import React, { useCallback, useEffect } from "react";
import {
    resetAllZambiaPayoutsData,
    setIsExportZambiaPayoutsTrayOpen,
    setIsZambiaPayoutsDetailCardOpen,
    setSelectedZambiaPayoutsDetails,
    setSelectedZambiaPayoutsFilterStateInstant,
} from "../../../../redux/zambia/payouts/zambiaPayoutsSlice";
import ExportZambiaPayoutsTray from "../Components/Payouts/Tray/ExportZambiaPayoutsTray";
import PageLayout from "../../../../components/layouts/page-layout";
import { SubTextType } from "../../../../components/Table/Type/SubTextType";
import Table from "../../../../components/Table";
import { TableColumnType } from "../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../components/Table/Type/TextType";
import ZambiaLencoPayPayoutsTray from "../Components/Payouts/Tray/ZambiaLencoPayPayoutsTray";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useZambiaPayouts from "../hookss/payoutss/useZambiaPayouts";
import ButtonComp from "../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../components/google-icon";

export type ZambiaCollectionSettlementsParams = {
    query?: string;
};

export const PAYOUT_TRANSACTION_DATATYPE = "PAYOUT_TRANSACTION_DATATYPE";
const allPayoutsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "Payout Account", pos: 2, headType: TableColumnType.TEXT },
    { text: "Recipient", pos: 3, headType: TableColumnType.PROGRESS },
    { text: "Payout Amount", pos: 4, headType: TableColumnType.TEXT },
];

function ZambiaPayouts(): JSX.Element {
    const { query } = useParams<ZambiaCollectionSettlementsParams>();
    const dispatch = useDispatch();
    const {
        zambiaPayoutsList,
        zambiaPayoutsListTotal,
        zambiaPayoutsListGroupSize,
        zambiaPayoutsPaginationOffset,
        isZambiaPayoutsPaginationLoading,
        filter,
        filterOption,
        handleClearFilter,
        handlePaginationRequest,
        handleFilteredZambiaPayouts,
    } = useZambiaPayouts();

    const onExport = useCallback(() => {
        dispatch(setIsExportZambiaPayoutsTrayOpen(true));
    }, []);

    useEffect(() => {
        if (query) {
            dispatch(setSelectedZambiaPayoutsFilterStateInstant({ offset: 0, query: query || "" }));
        }
        void handleFilteredZambiaPayouts({ offset: 0, query: query || "" });
    }, [query]);

    useEffect(() => {
        return () => {
            dispatch(resetAllZambiaPayoutsData());
        };
    }, []);

    return (
        <>
            <ExportZambiaPayoutsTray />
            <ZambiaLencoPayPayoutsTray />

            <PageLayout pageTitle="Payouts" subTitle="List of your payouts">
                <div className="relative flex h-full w-full flex-col items-start justify-start">
                    <div className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-start">
                        <Table
                            addExtraButton
                            extraButton={
                                <ButtonComp func={onExport} size="md" buttonType="secondary" ripple="dark" color={"white"}>
                                    <span className="flex text-black-tertiary">
                                        <GoogleIcon icon="download" size="sm" /> Export
                                    </span>
                                </ButtonComp>
                            }
                            addFilter
                            filter={filterOption}
                            totalSelectedItems={
                                (filter?.date?.begin ? 1 : 0) + (filter.statuses?.length || 0) + (filter?.userAccountIds?.length || 0)
                            }
                            handleClearFilter={handleClearFilter}
                            dataType={PAYOUT_TRANSACTION_DATATYPE}
                            heads={allPayoutsHeader}
                            isPaginateLoading={isZambiaPayoutsPaginationLoading}
                            groupSize={zambiaPayoutsListGroupSize}
                            offset={zambiaPayoutsPaginationOffset}
                            total={zambiaPayoutsListTotal}
                            isLoading={!!isZambiaPayoutsPaginationLoading}
                            rows={
                                zambiaPayoutsList?.map((obj) => ({
                                    onRecordClick: () => {
                                        dispatch(setIsZambiaPayoutsDetailCardOpen(true));
                                        dispatch(setSelectedZambiaPayoutsDetails(obj.id));
                                    },
                                    record: [
                                        {
                                            key: "Date",
                                            text: obj?.singleDatetime,
                                            textType: TextType.DATE,
                                            subText: obj?.singleDatetime,
                                            subTextType: SubTextType.TIME,
                                        },
                                        {
                                            key: "Payout Account",
                                            text: obj?.origination?.account?.accountName || "-",
                                        },
                                        {
                                            key: "Recipient",
                                            text: obj?.destination?.account?.accountName || "-",
                                        },
                                        {
                                            key: "RecipPayout Amountient",
                                            text: obj?.amount,
                                            textType: TextType.BALANCE,
                                        },
                                    ],
                                })) || []
                            }
                            paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                        />
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default ZambiaPayouts;
