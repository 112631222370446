import { useCallback, useState } from "react";

import { DropdownItem } from "../../../../../../helpers/types";
import NewDropdownItem from "../../../../../../components/new-dropdown-item";
import { UserRole } from "../../../../../../models/userAccount.constants";

interface TeamMemberDropdownUserRolesProps<T extends number> {
    data: DropdownItem<T>;
    roleType: boolean;
    handleSetApprovalLimit?: () => void;
}

function TeamMemberDropdownUserRoles<T extends number>(props: TeamMemberDropdownUserRolesProps<T>): JSX.Element {
    const [isHover, setIsHover] = useState<boolean>(false);

    const handleMouseEnter = useCallback(() => {
        setIsHover(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsHover(false);
    }, []);

    return (
        <div className="relative w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <NewDropdownItem padding="sm" size="sm">
                <div className="flex w-full flex-row items-center justify-between space-x-1">
                    <span className="text-sm">{props.data.text}</span>
                    {/* {(props.data.value === UserRole.BOTH || props.data.value === UserRole.APPROVER) && props.roleType && <RightIcon />} */}
                </div>
            </NewDropdownItem>

            {(props.data.value === UserRole.BOTH || props.data.value === UserRole.APPROVER) && (
                <div className={`absolute left-44 top-0 ml-1 flex h-full flex-col rounded-lg bg-white shadow ` + `${!isHover ? "hidden" : "flex"}`}>
                    <NewDropdownItem padding="sm" size="sm" onClick={props.handleSetApprovalLimit && props.handleSetApprovalLimit}>
                        <p className="text-xs">Set approval level</p>
                    </NewDropdownItem>
                </div>
            )}
        </div>
    );
}

export default TeamMemberDropdownUserRoles;
