import LencoSpinner from "../../../../../components/spinner";
import React from "react";

interface Props {
    icon: React.ReactNode;
    hoverIcon: React.ReactNode;
    header: string;
    isActive?: boolean;
    isLoading: boolean;
    subtitleOne: string;
    subtitleTwo?: string | undefined;

    onClick: () => void;
}

function BusinessTypeCard(props: Props): JSX.Element {
    return (
        <div
            className={
                `relative flex h-full w-full max-w-lg flex-col justify-between space-y-4 ` +
                `${props.isLoading ? "cursor-not-allowed" : "cursor-pointer"} `
            }
            onClick={props.onClick}
        >
            <div
                className={
                    `relative flex h-full w-full flex-row items-center justify-between rounded-lg border px-6 py-6 2xs:px-12 ` +
                    `${props.isLoading ? "bg-white bg-opacity-10" : "hover:shadow-custom-v2"} ` +
                    `${props.isActive ? "border-blue bg-blue-backdrop hover:border-blue-hover" : "group/icon border-grey bg-white hover:border-blue"} `
                }
            >
                {props.isLoading && (
                    <div className="pointer-events-none absolute left-0 right-0 z-30 flex h-full w-full items-center justify-center rounded-lg bg-blue-backdrop bg-opacity-90">
                        <LencoSpinner color="blue" />
                    </div>
                )}
                <div className={`flex w-full flex-col items-start justify-start space-y-4 2xs:flex-row 2xs:items-center 2xs:space-x-8 2xs:space-y-0`}>
                    <div className="relative h-12 w-12">
                        <div className="absolute bottom-0 left-0 right-0 top-0">
                            <div
                                className={
                                    "box-border hidden h-12 w-12 items-center justify-center rounded-full duration-150 2xs:flex " +
                                    `${props.isActive ? "!opacity-0" : "group-hover/icon:opacity-0"}`
                                }
                            >
                                {props.icon}
                            </div>
                        </div>
                        <div
                            className={
                                "box-border hidden h-12 w-12 items-center justify-center rounded-full duration-150 2xs:flex " +
                                `${props.isActive ? "!opacity-100" : "group-hover/icon:opacity-100"}`
                            }
                        >
                            {props.hoverIcon}
                        </div>
                    </div>

                    <div className="flex flex-col whitespace-normal text-sm">
                        <span className="font-medium text-black">{props.header}</span>
                        <div className="mt-2 text-sm">
                            <p className="text-black-secondary">
                                {props.subtitleOne} {props.subtitleTwo || ""}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessTypeCard;
