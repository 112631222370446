import LoginFooter from "../../../../../components/layouts/components/landing/login-footer";
import { ReactComponent as Mailbox } from "../../../../../assets/svg/mail-box.svg";

interface verifyEmailProps {
    message: string;
    infoTitle: string | null | JSX.Element;
    forgotPassword?: boolean;
}

function VerifyEmail({ message, infoTitle, forgotPassword = false }: verifyEmailProps): JSX.Element {
    return (
        <>
            <div className="relative flex w-full max-w-[476px] flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex flex-col items-center justify-start space-y-5">
                    <div>
                        <Mailbox />
                    </div>
                    <div className="flex flex-col items-center justify-start space-y-4">
                        <h3 className="text-center text-2xl font-medium text-black">{infoTitle}</h3>
                        <div className="flex flex-row items-center justify-center">
                            {!forgotPassword ? (
                                <>
                                    <p className="text-center font-normal text-black-secondary">
                                        An email has been sent to you at <span className="font-medium">{message}</span>
                                    </p>
                                    <p className="text-center font-normal text-black-secondary">
                                        Please click on the link in the email to verify your email.
                                    </p>
                                </>
                            ) : (
                                <p className="text-center font-normal text-black-secondary">{message}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter isLogin />
        </>
    );
}

export default VerifyEmail;
