import { useCallback, useEffect, useRef } from "react";

import Transaction from "../models/transaction";
import { errorTrue } from "../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../utils/getErrorMessage";
import { getTransactionDetails } from "../modules/dashboard/payments-new/componentss/Cards/BillPurchaseDetailCard/Services/transactionDetailApi";
import { setSingleTransaction } from "../redux/transaction/slice/transactionSlice";
import { useDispatch } from "react-redux";

export default function useCheckStatus(transaction: Transaction | null): void {
    const dispatch = useDispatch();
    const pollingRef = useRef<ReturnType<typeof setInterval> | null>();
    useEffect(() => {
        if (!transaction || !transaction.isProcessing)
            return () => {
                if (pollingRef.current) {
                    clearInterval(pollingRef.current);
                }
            };

        pollingRef.current = setInterval(() => {
            void handleGetTransaction();
        }, 10000);
        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
        };
    }, [transaction]);

    const handleGetTransaction = useCallback(async (): Promise<void> => {
        if (transaction) {
            try {
                const updatedTransaction = await getTransactionDetails(transaction.id);
                if (!updatedTransaction.isProcessing) {
                    dispatch(setSingleTransaction(updatedTransaction));
                }
            } catch (err) {
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            }
        }
    }, [dispatch]);
}
