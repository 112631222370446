import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
interface Props {
    balance?: number;
    fullWidth?: boolean;
    isApprovalError?: boolean;
    zambiaMinAmount?: number;
    zambiaMaxAmount?: number;
    isZambiaMinAmount?: boolean;
    isZambiaMaxAmount?: boolean;
    maxApprovalAmount?: number;
    isInsufficientFunds?: boolean;
    canNotSendFromAccount?: boolean;
    canSendMoneyToSpecificAccounts?: boolean;
}

function SendMoneyErrorCard(props: Props): JSX.Element {
    return (
        <>
            <MessageToast
                message={
                    <>
                        {props.isZambiaMinAmount && (
                            <p>
                                The minimum you can send is <Money amount={props.zambiaMinAmount || 0} />
                            </p>
                        )}
                        {props.isZambiaMaxAmount && (
                            <p>
                                The maximum you can send is <Money amount={props.zambiaMaxAmount || 0} />
                            </p>
                        )}
                        {props.isApprovalError && !isNullOrUndefined(props.maxApprovalAmount) && (
                            <p>
                                You can only send <Money amount={props.maxApprovalAmount || 0} /> or less on this account. Any amount more than this
                                will be initiated and need to be approved by an admin.
                            </p>
                        )}
                        {props.isInsufficientFunds && !isNullOrUndefined(props.balance) && (
                            <p>
                                You do not have enough funds. Your account balance is <Money amount={props.balance || 0} />
                            </p>
                        )}
                        {props.canNotSendFromAccount && <p>You can not send money from this account.</p>}
                        {props.canSendMoneyToSpecificAccounts && <p>You can only send to specific recipients.</p>}
                    </>
                }
                type={props.isApprovalError || props.canSendMoneyToSpecificAccounts ? "info" : "error"}
                fullWidth={props.fullWidth}
            />
        </>
    );
}

export default SendMoneyErrorCard;
