import * as Yup from "yup";

import { isAllRulesPassed } from "../../../../utils/password-hints/password-hints.service";

export enum SignUpStages {
    INITIAL = 1,
    EMAIL_OTP = 2,
    SUCCESSFUL_VERIFICATION = 3,
}

export enum SignUpCountries {
    NIGERIA = "NG",
    ZAMBIA = "ZM",
}

export const SIGN_UP_FORM_VALIDATION = Yup.object().shape({
    isInvite: Yup.boolean(),
    email: Yup.string()
        .email()
        .when("isInvite", ([isInvite], schema) => {
            return isInvite ? schema.notRequired() : schema.required("Required");
        }),
    phone: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    businessName: Yup.string().required("Required"),
    password: Yup.string()
        .test("isValidPass", "is not valid", (value) => {
            return value ? isAllRulesPassed(value) : false;
        })
        .required("Required"), // add an additional rule
    repeatPassword: Yup.string()
        .oneOf([Yup.ref("password"), undefined], "Password must match")
        .required("Confirm password is required"),
});
