import * as Yup from "yup";

import {
    BusinessInformationFields,
    UpdateBusinessDetailsRequest,
    getBusinessInformationHints,
} from "../../../../../services/nigeria-application/unregistered/business.types";
import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../element/hint/hint.constant";
import { useLocation, useNavigate } from "react-router-dom";

import ApplicationHeader from "../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import FormInput from "../../../../../../../../components/inputs/FormInput";
import Hint from "../../../../element/hint";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../components/spinner";
import { Routes } from "../../../../../../../../routes/routes.constants";
import SingleSelectDropdown from "../../../../../../../../components/dropdown/single-select/single-select";
import formikHasError from "../../../../../../../../helpers/formikHasError";
import useBusinessDetailsInit from "../../../../../Hooks/nigeria-application/registered-business/business-details/useBusinessDetailsInit";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import useUnregisteredBusinessInformation from "../../../../../Hooks/nigeria-application/unregistered-business/useUnregisteredBusinessInformation";

type LocationState = {
    from?: string;
};

function UnregisteredBusinessDetails(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isBusinessInformationLoading, handleUpdateBusinessInformation } = useUnregisteredBusinessInformation();
    const { isBusinessDetailsInitLoading, businessDetailsInitError, handleReset, handleBusinessDetailsInit } = useBusinessDetailsInit();

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const businessDetails = useSelector((state: IRootState) => state.application.businessDetails);

    useLayoutEffect(() => {
        void handleBusinessDetailsInit();

        return () => {
            handleReset();
        };
    }, []);

    const INITIAL_FORM_STATE: UpdateBusinessDetailsRequest = {
        [BusinessInformationFields.NAME]: businessDetails?.name || "",
        [BusinessInformationFields.INDUSTRY]: Number(businessDetails?.industry?.id || undefined),
        [BusinessInformationFields.BUSINESS_NATURE]: businessDetails?.businessNature || "",
        [BusinessInformationFields.OPERATING_ADDRESS]: businessDetails?.operatingAddress || "",
        // [BusinessInformationFields.TURNOVER]: businessDetails?.annualTurnover || null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        [BusinessInformationFields.NAME]: Yup.string().required("Required"),
        // [BusinessInformationFields.TURNOVER]: Yup.number().nullable().required("Required"),
        [BusinessInformationFields.INDUSTRY]: Yup.number().required("Required"),
        [BusinessInformationFields.BUSINESS_NATURE]: Yup.string().required("Required"),
        [BusinessInformationFields.OPERATING_ADDRESS]: Yup.string().required("Required"),
    });

    return (
        <>
            {isBusinessDetailsInitLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {businessDetailsInitError && (
                <div className="flex h-full w-full items-center justify-center">
                    <ErrorToast error={businessDetailsInitError} />
                </div>
            )}
            {accountOpening && !isBusinessDetailsInitLoading && !businessDetailsInitError && (
                <Formik
                    initialValues={{
                        ...INITIAL_FORM_STATE,
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => {
                        void handleUpdateBusinessInformation(values);
                    }}
                    enableReinitialize
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <Form className="w-full">
                                <div className="flex w-full flex-row items-start justify-start space-x-6">
                                    {accountOpening && (
                                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                                            <ApplicationHeader
                                                header="Business Information"
                                                subTitle="To get started, tell us about your business"
                                                progress={accountOpening.progress.businessCompletedProgress()}
                                            >
                                                <div className="flex flex-col items-start space-y-4 lg:items-center">
                                                    <div className="flex w-full flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                        <div className="md:w-6/12">
                                                            <FormInput
                                                                type="text"
                                                                placeholder="Legal Business Name"
                                                                name="name"
                                                                value={formik.values.name}
                                                            />
                                                        </div>
                                                        <div className="w-full md:w-50%">
                                                            {accountOpening && (
                                                                <SingleSelectDropdown
                                                                    placeholder="Sector / Industry"
                                                                    options={accountOpening?.options.industry.map((el) => {
                                                                        return {
                                                                            text: el.name,
                                                                            value: el.id,
                                                                        };
                                                                    })}
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            void formik.getFieldHelpers("industryId").setValue(value);
                                                                        }
                                                                    }}
                                                                    defaultValue={formik.values.industryId || undefined}
                                                                    bigDropdown={false}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="w-full">
                                                        <FormInput
                                                            type="textarea"
                                                            placeholder="Describe Your Business"
                                                            name="businessNature"
                                                            value={formik.values.businessNature}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <FormInput
                                                            type="textarea"
                                                            placeholder="Operating Business Address"
                                                            name="operatingAddress"
                                                            value={formik.values.operatingAddress}
                                                        />
                                                    </div>
                                                </div>
                                            </ApplicationHeader>
                                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                    <ButtonComp
                                                        type="button"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        func={() => navigate(locationState?.from || Routes.ACCOUNT_OPENING.STATUS)}
                                                        fullWidth
                                                    >
                                                        <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                                    </ButtonComp>
                                                </div>
                                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                    <ButtonComp
                                                        type={formik.dirty ? "submit" : "button"}
                                                        color="black"
                                                        ripple="light"
                                                        buttonType="primary"
                                                        disable={
                                                            (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW && !formik.dirty) ||
                                                            formikHasError(formik.errors)
                                                        }
                                                        isLoading={isBusinessInformationLoading}
                                                        func={() => {
                                                            !formik.dirty
                                                                ? navigate(
                                                                      {
                                                                          pathname: Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS,
                                                                      },
                                                                      {
                                                                          state: {
                                                                              from: Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS,
                                                                          },
                                                                      }
                                                                  )
                                                                : undefined;
                                                        }}
                                                        fullWidth
                                                    >
                                                        <span>
                                                            {locationState?.from === Routes.ACCOUNT_OPENING.REVIEW
                                                                ? "Save changes"
                                                                : "Save and Continue"}
                                                        </span>
                                                    </ButtonComp>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <Hint
                                        hintType={HintType.GUIDE}
                                        listStyle={ListStyle.CHECK}
                                        firstList={getBusinessInformationHints(
                                            Object.keys(formik.values || []),
                                            Object.keys(formik.errors || []),
                                            []
                                            // [BusinessInformationFields.]
                                        )}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
}

export default UnregisteredBusinessDetails;
