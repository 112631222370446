import Parsers from "../utils/parsers";
import { GenericObject } from "../helpers/types";
import { CorporateAccountType } from "./corporate.constants";
import AccountFaqMin from "./faq/accountFaqMin";
import { immerable } from "immer";

export default class AccountType {
    [immerable] = true;

    constructor(
        public type: CorporateAccountType,
        public name: string,
        public faqs: Array<AccountFaqMin>,
        public transferLimit: number,
        public subAccount: boolean
    ) {}

    static create(obj: GenericObject): AccountType {
        return new AccountType(
            Parsers.number(obj.type),
            Parsers.string(obj.name),
            Parsers.classObjectArray(obj.faqs, AccountFaqMin),
            Parsers.number(obj.transferLimit),
            Parsers.boolean(obj.subAccount)
        );
    }
}
