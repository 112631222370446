import {
    resetCableTVVerifiedAccountName,
    setCableTVPurchaseAccountId,
    setCableTVVerifiedAccountName,
} from "../../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";
import { useCallback, useState } from "react";

import { BillVerifyAccountRequest } from "../../../../services/billPayment/billPaymentApi.types";
import { RequestCancelledError } from "../../../../../../../helpers/request/requestErrors";
import { billVerifyAccount } from "../../../../services/billPayment/billPaymentApi";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

interface UseCableTVPurchaseVerifyAccountInterface {
    isCableTVPurchaseVerifyAccountLoading: boolean;
    cableTVPurchaseVerifyAccountError: string;

    handleResetError: () => void;
    handleCableTVPurchase: (_data: BillVerifyAccountRequest) => Promise<void>;
}

function useCableTVPurchaseVerifyAccount(): UseCableTVPurchaseVerifyAccountInterface {
    const dispatch = useDispatch();

    const [isCableTVPurchaseVerifyAccountLoading, setIsCableTVPurchaseVerifyAccountLoading] = useState<boolean>(false);
    const [cableTVPurchaseVerifyAccountError, setCableTVPurchaseVerifyAccountError] = useState<string>("");

    const handleCableTVPurchase = useCallback(
        async (_data: BillVerifyAccountRequest) => {
            try {
                dispatch(resetCableTVVerifiedAccountName());
                setIsCableTVPurchaseVerifyAccountLoading(true);
                setCableTVPurchaseVerifyAccountError("");
                const res = await billVerifyAccount(_data);
                dispatch(setCableTVVerifiedAccountName(res.name));
                dispatch(setCableTVPurchaseAccountId(_data.accountId));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setCableTVPurchaseVerifyAccountError(errorMessage);
            }
            setIsCableTVPurchaseVerifyAccountLoading(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    const handleResetError = useCallback(() => {
        setCableTVPurchaseVerifyAccountError("");
    }, []);
    return { cableTVPurchaseVerifyAccountError, isCableTVPurchaseVerifyAccountLoading, handleResetError, handleCableTVPurchase };
}

export default useCableTVPurchaseVerifyAccount;
