// import { ReactComponent as AgreementsIcon } from "../../../../../../../../../assets/svg/general/Documents/business-agreements.svg";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
interface Props {
    text: string;
    isCanNotPreview?: boolean;

    handlePreviewDocumentFunc: () => void;
}

function AgreementsPreviewItem(props: Props): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-col items-center justify-between space-y-4 4xs:flex-row 4xs:space-y-0">
                <div className="flex flex-row items-center space-x-2 4xs:space-x-4">
                    <div className="flex h-full max-w-[20px] items-center justify-center overflow-hidden">
                        {!props.isCanNotPreview ? (
                            <span className="material-symbols-rounded icon-fill text-xl text-success">check_circle</span>
                        ) : (
                            <span className="material-symbols-rounded icon-fill text-xl text-black-quin">check_circle</span>
                        )}
                    </div>
                    <span className="text-sm text-black-tertiary 4xs:text-base">{props.text}</span>
                </div>
                <div className="flex flex-row justify-start">
                    {props.isCanNotPreview && <span className="cursor-default">-</span>}
                    {!props.isCanNotPreview && (
                        <div className="pl-2">
                            <ButtonComp color="blue" buttonType="flat" size="sm" func={props.handlePreviewDocumentFunc}>
                                <p>Preview</p>
                                <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden">
                                    <span className="material-symbols-outlined icon-fill text-xl">visibility</span>
                                </div>
                            </ButtonComp>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AgreementsPreviewItem;
