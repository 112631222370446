import { BusinessRefereeRequests, abortBusinessRefereeRequest, inviteReferee } from "../../../../services/nigeria-application/registered/referee.api";
import { useCallback, useState } from "react";

import { InviteRefereeForm } from "../../../../services/nigeria-application/registered/referee.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setReferees } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

export interface useInviteRefereesInterface {
    isInviteRefereeLoading: boolean;

    handleRest: () => void;
    handleInviteReferee: (data: InviteRefereeForm) => Promise<void>;
}

const useInviteReferees = (): useInviteRefereesInterface => {
    const dispatch = useDispatch();

    const [isInviteRefereeLoading, setIsInviteRefereeLoading] = useState<boolean>(false);

    const handleInviteReferee = useCallback(
        async (data: InviteRefereeForm): Promise<void> => {
            try {
                setIsInviteRefereeLoading(true);
                const res = await inviteReferee(data);
                dispatch(setReferees(res));
                dispatch(messageTrue({ message: `Referee${data.referees.length > 1 ? "s" : ""} Invited` }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsInviteRefereeLoading(false);
            }
        },
        [dispatch]
    );

    const handleRest = () => {
        setIsInviteRefereeLoading(false);
        abortBusinessRefereeRequest(BusinessRefereeRequests.INVITE);
    };

    return {
        isInviteRefereeLoading,
        handleRest,
        handleInviteReferee,
    };
};
export default useInviteReferees;
