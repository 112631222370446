import React, { ReactNode, useCallback } from "react";

import { Tooltip } from "react-tooltip";

interface Props {
    size?: "xs" | "sm";
    icon: ReactNode;
    message: string;
    dataType?: string;
    placement: "top" | "bottom" | "left" | "right";
    noHoverBg?: boolean;

    onClick?: () => void;
}

function TooltipIcons({ onClick, icon, message, noHoverBg = false, placement = "top", size = "sm", dataType = "transaction" }: Props): JSX.Element {
    const handleOnClick = useCallback(
        (e: React.MouseEvent) => {
            if (onClick) {
                e.preventDefault();
                e.stopPropagation();
                onClick();
            }
        },
        [onClick]
    );

    return (
        <>
            <div data-type={dataType}>
                <span data-tip data-for={message}>
                    <div
                        className={
                            `relative flex cursor-pointer items-center justify-center rounded-lg outline-none ` +
                            `${size === "xs" ? "h-6 w-6" : "h-8 w-8"} ` +
                            `${!noHoverBg ? "bg-grey-backdrop" : ""} `
                        }
                        onClick={handleOnClick}
                        data-tip
                        data-for={`tip-${message}`}
                        data-place={placement}
                        data-type={dataType}
                    >
                        {icon}
                    </div>
                </span>
                <Tooltip id={message} place={placement} variant="dark" float>
                    <span>{message}</span>
                </Tooltip>
            </div>
        </>
    );
}

export default TooltipIcons;
