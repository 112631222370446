import { setSelectedCardDetails, updateCard } from "../../../../../redux/cards/slice/cardsSlice";
import { useCallback, useState } from "react";

import Card from "../../../../../models/card";
import { DeactivateCardRequest } from "../../Services/cardsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { deactivateCard } from "../../Services/cardsApi";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { mainInitUpdateCard } from "../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

interface UseCardsInterface {
    deactivateCardError: string | null;
    isDeactivateCardDone: boolean;
    isDeactivateCardLoading: boolean;

    handleReset: () => void;
    handleDeactivateCard: (_data: DeactivateCardRequest) => Promise<void>;
}

const useDeactivateCard = (): UseCardsInterface => {
    const dispatch = useDispatch();
    const [deactivateCardError, setDeactivateCardError] = useState<string | null>(null);
    const [isDeactivateCardDone, setIsDeactivateCardDone] = useState<boolean>(false);
    const [isDeactivateCardLoading, setIsDeactivateCardLoading] = useState<boolean>(false);

    const handleDeactivateCard = useCallback(
        async (_data: DeactivateCardRequest) => {
            try {
                setIsDeactivateCardDone(false);
                setDeactivateCardError(null);
                setIsDeactivateCardLoading(true);
                const res = await deactivateCard(_data);
                setIsDeactivateCardDone(true);
                dispatch(updateCard(res.cardData));
                dispatch(mainInitUpdateCard(res.cardData.card as Card));
                dispatch(setSelectedCardDetails(_data.createRequestId));
                dispatch(messageTrue({ message: "Card Deactivated Successfully" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDeactivateCardError(errorMessage);
            } finally {
                setIsDeactivateCardLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsDeactivateCardDone(false);
        setDeactivateCardError(null);
        setIsDeactivateCardLoading(false);
    }, []);

    return {
        deactivateCardError,
        isDeactivateCardDone,
        isDeactivateCardLoading,

        handleReset,
        handleDeactivateCard,
    };
};

export default useDeactivateCard;
