import React from "react";

interface ISkeletonElement {
    avatar?: boolean;
    text?: boolean;
    title?: boolean;
    thumbnail?: boolean;
    box?: boolean;
}

export default function SkeletonElement({ avatar, title, text, thumbnail, box }: ISkeletonElement) {
    return (
        <div
            className={
                `animate-skeleton rounded bg-gray-100 ` +
                `${avatar ? "h-full w-full rounded-full" : ""} ` +
                `${text ? "h-3.5 w-full rounded" : ""} ` +
                `${title ? "h-5 w-1/3 rounded" : ""} ` +
                `${thumbnail ? "h-24 w-24 rounded" : ""} ` +
                `${box ? "h-full w-full rounded" : ""} `
            }
        />
    );
}
