import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { UpdateZambiaApiAccessKeyCollectionFeeSourceRequest } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { setUpdateZambiaApiAccessKey } from "../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { updateZambiaApiAccessKeyCollectionFeeSourceApi } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { useDispatch } from "react-redux";

export interface UseUpdateZambiaApiAccessKeyCollectionFeeSourceInterface {
    isUpdateZambiaApiAccessKeyCollectionFeeSourceLoading: boolean;
    updateZambiaApiAccessKeyCollectionFeeSourceErrorMessage: string;
    handleUpdateZambiaApiAccessKeyCollectionFeeSource: (_data: UpdateZambiaApiAccessKeyCollectionFeeSourceRequest) => void;
}

interface Props {
    onComplete: () => void;
}

function useUpdateZambiaApiAccessKeyCollectionFeeSource(props: Props): UseUpdateZambiaApiAccessKeyCollectionFeeSourceInterface {
    const dispatch = useDispatch();
    const [isUpdateZambiaApiAccessKeyCollectionFeeSourceLoading, setIsUpdateZambiaApiAccessKeyCollectionFeeSourceLoading] = useState(false);
    const [updateZambiaApiAccessKeyCollectionFeeSourceErrorMessage, setUpdateZambiaApiAccessKeyCollectionFeeSourceErrorMessage] =
        useState<string>("");

    const handleUpdateZambiaApiAccessKeyCollectionFeeSource = useCallback(
        async (_data: UpdateZambiaApiAccessKeyCollectionFeeSourceRequest) => {
            try {
                setIsUpdateZambiaApiAccessKeyCollectionFeeSourceLoading(true);
                setUpdateZambiaApiAccessKeyCollectionFeeSourceErrorMessage("");
                const res = await updateZambiaApiAccessKeyCollectionFeeSourceApi(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key collection fee source updated successfully."));
                props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setUpdateZambiaApiAccessKeyCollectionFeeSourceErrorMessage(errorMessage);
            } finally {
                setIsUpdateZambiaApiAccessKeyCollectionFeeSourceLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isUpdateZambiaApiAccessKeyCollectionFeeSourceLoading,
        updateZambiaApiAccessKeyCollectionFeeSourceErrorMessage,
        handleUpdateZambiaApiAccessKeyCollectionFeeSource,
    };
}

export default useUpdateZambiaApiAccessKeyCollectionFeeSource;
