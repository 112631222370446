import { immerable } from "immer";
import Parsers from "../../utils/parsers";
import { GenericObject } from "../../helpers/types";

export default class NextOfKin {
    [immerable] = true;

    constructor(
        public name: string,
        public phone: string,
        public state: number,
        public address: string,
        public relationship: number | null,

        public gender: number,
        public otherRelationshipText: string | null
    ) {}

    static create(obj: GenericObject): NextOfKin {
        return new NextOfKin(
            Parsers.string(obj.name),
            Parsers.string(obj.phone),
            Parsers.number(obj.state),
            Parsers.string(obj.address),
            Parsers.nullableNumber(obj.relationship),
            Parsers.number(obj.gender),
            Parsers.nullableString(obj.otherRelationshipText)
        );
    }
}
