import { useCallback } from "react";
import { useDispatch } from "react-redux";

import BillProduct from "../../../../../../../models/billProduct";
import { setElectricityPurchaseProductId } from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";

interface UseElectricityPurchaseProductIdInterface {
    handleSelectProduct: (_vendor: BillProduct) => void;
}

function useElectricityPurchaseProduct(): UseElectricityPurchaseProductIdInterface {
    const dispatch = useDispatch();

    const handleSelectProduct = useCallback((_product: BillProduct) => {
        dispatch(setElectricityPurchaseProductId(_product));
    }, []);

    return { handleSelectProduct };
}

export default useElectricityPurchaseProduct;
