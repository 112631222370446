import React, { ReactElement, useCallback, useEffect } from "react";
import { resetTeamMemberToRemoveManageAccess, setIsCardAccessModalOpen } from "../../../../../redux/cards/slice/cardsSlice";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../redux/rootReducer";
import { ToastType } from "../../../../../helpers/AppConstants";
import useRemoveManageCardAccess from "../../Hooks/State/useRemoveManageCardAccess";
import Modal from "../../../../../components/modal/Modal";
import ModalHeader from "../../../../../components/modal/modal-header";
import ModalBody from "../../../../../components/modal/modal-body";
import MessageToasts from "../../../../../components/general/MessageToasts/MessageToasts";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ButtonComp from "../../../../../components/button/ButtonComp";

function RemoveManageCardAccessModal(): ReactElement {
    const dispatch = useDispatch();

    const { removeManageCardAccessError, isRemoveManageCardAccessDone, isRemoveManageCardAccessLoading, handleReset, handleRemoveManageCardAccess } =
        useRemoveManageCardAccess();

    const teamMemberToRemoveManageAccess = useSelector((state: IRootState) => state.cards.teamMemberToRemoveManageAccess);

    useEffect(() => {
        if (!isRemoveManageCardAccessDone) return;
        handleReset();
        dispatch(setIsCardAccessModalOpen(true));
        dispatch(resetTeamMemberToRemoveManageAccess());
    }, [handleReset, isRemoveManageCardAccessDone]);

    const handleToggle = useCallback(() => {
        dispatch(resetTeamMemberToRemoveManageAccess());
    }, []);

    const handleBack = useCallback(() => {
        dispatch(resetTeamMemberToRemoveManageAccess());
        handleReset();
        // props.handlePreviousStage();
    }, [handleReset]);

    const handleDelete = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!teamMemberToRemoveManageAccess) return;
            void handleRemoveManageCardAccess({ memberId: teamMemberToRemoveManageAccess.id });
        },
        [teamMemberToRemoveManageAccess]
    );

    return (
        <>
            <Modal size="md" active={!!teamMemberToRemoveManageAccess} toggler={handleToggle}>
                <ModalHeader onClose={handleToggle}>Remove Card Access</ModalHeader>
                <form className="w-full" onSubmit={handleDelete}>
                    <ModalBody>
                        <div className="flex w-full flex-col space-y-4">
                            {removeManageCardAccessError && <MessageToasts toastMessage={removeManageCardAccessError} toastType={ToastType.ERROR} />}
                            <div className="flex w-full flex-col items-start justify-start text-sm font-normal">
                                <div className="flex w-full flex-col items-start text-start">
                                    <span className="text-left text-base font-normal text-black-secondary">
                                        Are you sure you want to remove{" "}
                                        <span className="m-auto max-w-xs break-words">{teamMemberToRemoveManageAccess?.name}&apos;s access ?</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                            <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" fullWidth func={handleBack}>
                                    <span>Back</span>
                                </ButtonComp>
                            </div>
                            <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                <ButtonComp
                                    type="submit"
                                    color="red"
                                    ripple="light"
                                    buttonType="primary"
                                    fullWidth
                                    isLoading={isRemoveManageCardAccessLoading}
                                >
                                    <span>Remove Access</span>
                                </ButtonComp>
                            </div>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
}

export default RemoveManageCardAccessModal;
