import {
    BulkPaymentResponse,
    BulkTransferDetail,
    TransferDetail,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import { useCallback, useEffect, useState } from "react";

import { BulkPaymentInitRequest } from "../../../../services/send-money/bulkTransferApi.types";
import { IRootState } from "../../../../../../../redux/rootReducer";
import useBulkTransfer from "./useBulkTransfer";
import { useSelector } from "react-redux";

interface UseBulkTransferOtpInterface {
    bulkPaymentOtp: string;
    bulkPaymentError: boolean;
    bulkPaymentResponse: BulkPaymentResponse | null;
    transferDetailArray: TransferDetail[];
    isBulkPaymentLoading: boolean;
    handleOtpChange: (_amount: string) => void;
    handleBulkTransfer: (_data: BulkTransferDetail) => Promise<void>;
}

function useBulkTransferOtp(): UseBulkTransferOtpInterface {
    const { bulkPaymentResponse, handleBulkTransfer, isBulkPaymentLoading, bulkPaymentError } = useBulkTransfer();

    const transferDetailArray = useSelector((state: IRootState) => state.bulkTransfer.transferDetailArray);
    const [bulkPaymentOtp, setBulkPaymentOtp] = useState<string>("");

    useEffect(() => {
        if (bulkPaymentOtp.length < 6) return;
        const bulkPaymentDetails: BulkPaymentInitRequest = {
            payments: transferDetailArray.map((_el) => ({
                originatingAccountId: _el.originatingAccountId as string,
                recipient: _el.recipient,
                amount: _el.amount,
                purpose: _el.purpose,
                key: _el.key,
            })),
            otp: bulkPaymentOtp,
        };
        void handleBulkTransfer(bulkPaymentDetails);
    }, [bulkPaymentOtp]);

    const handleOtpChange = useCallback((_otp: string) => {
        setBulkPaymentOtp(_otp);
    }, []);

    return {
        bulkPaymentOtp,
        bulkPaymentError,
        transferDetailArray,
        bulkPaymentResponse,
        isBulkPaymentLoading,
        handleOtpChange,
        handleBulkTransfer,
    };
}

export default useBulkTransferOtp;
