import {
    DirectorNavigation,
    DirectorPageType,
    ExternalDirectorPageType,
    ExternalDirectorSubPageType,
    GenericDirectorPageType,
    MeansOfIDPageType,
    NextOfKinPageType,
} from "../../service/director.constants";
import React, { useCallback } from "react";

import { useNavigate } from "react-router";

interface Props {
    url: string;
    stage?: ExternalDirectorSubPageType;
    section?: ExternalDirectorPageType;
    children: React.ReactNode;
}

function ExternalDirectorLayout(props: Props): JSX.Element {
    const navigate = useNavigate();
    const getPath = useCallback((_section: ExternalDirectorPageType, _stage: ExternalDirectorSubPageType | ""): string => {
        switch (_section) {
            case DirectorPageType.PERSONAL:
                return DirectorNavigation[_stage];
            case DirectorPageType.NEXT_OF_KIN:
                return DirectorNavigation[NextOfKinPageType.KIN];
            case DirectorPageType.MEANS_OF_ID:
                return DirectorNavigation[MeansOfIDPageType.ID];
            case DirectorPageType.REVIEW:
                return DirectorNavigation[GenericDirectorPageType.REVIEW];
            default:
                return "";
        }
    }, []);

    return (
        <>
            <div className="flex h-full w-full flex-col">
                <div className="relative flex h-full w-full flex-col items-start space-y-6 lg:px-0">
                    <div className="flex min-h-[26px] w-full items-center justify-start space-x-2 overflow-x-auto pt-[1px] text-sm scrollbar-hide sm:overflow-hidden lg:w-max lg:max-w-max">
                        <div className="w-max select-none rounded-[4px] bg-white px-2 py-1">
                            <p className="text-xs leading-[100%] text-black-tertiary xs:text-sm">Application</p>
                        </div>
                        <div className="flex w-max items-center justify-start space-x-2 text-2xs xs:text-xs">
                            <span className="material-symbols-outlined max-w-[20px] overflow-hidden text-lg text-black-quat">chevron_right</span>
                            <div
                                className={
                                    "flex items-center justify-start text-xs leading-none" +
                                    " w-max rounded-[4px] bg-white px-2 py-1" +
                                    " font-medium text-black-secondary"
                                }
                            >
                                <p className={"select-none text-xs capitalize leading-[100%] xs:text-sm" + " font-medium text-black-secondary"}>
                                    {props.section && getPath(props.section, props.stage || "")}
                                </p>
                                <div
                                    className="ml-2 max-w-[20px] cursor-pointer overflow-hidden text-black"
                                    onClick={(e) => {
                                        navigate(`${props.url}`);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <span className="material-symbols-rounded text-sm">close</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-fit-available flex w-full flex-shrink flex-grow basis-auto flex-col items-start justify-start">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ExternalDirectorLayout;
