import PaymentHeader from "../../payment/components/page-header";

interface Props {
    title?: string;
    subtitle?: string;
}

function AddMoneyHeader(props: Props): JSX.Element {
    return <PaymentHeader header={props.title || ""} subtitle={props.subtitle || ""} />;
}

export default AddMoneyHeader;
