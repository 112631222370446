import { useCallback, useEffect, useState } from "react";

import { OtpActivityType } from "../../../../../../../../helpers/AppConstants";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { otpInit } from "../../../../../services/send-money/singleTransferApi";
import { useDispatch } from "react-redux";

interface OtpInitRequest {
    action: OtpActivityType;
}

interface OtpInitResponse {
    success: boolean;
    message: string;
    email: string;
    phone: string;
}
interface UseOtpInitInterface {
    minutes: number;
    seconds: number;
    otpInitError: string;
    initOTPResponse: OtpInitResponse | null;
    isInitOTPLoading: boolean;
    handleOTPInit: (data: OtpInitRequest) => Promise<void>;
    handleResendOTPInit: (data: OtpInitRequest) => void;
}

interface Props {
    active?: boolean;
}

function useOtpInit({ active }: Props): UseOtpInitInterface {
    const dispatch = useDispatch();
    const [isInitOTPLoading, setIsInitOTPLoading] = useState<boolean>(false);
    const [initOTPResponse, setInitOTPResponse] = useState<OtpInitResponse | null>(null);
    const [minutes, setMinutes] = useState<number>(3);
    const [seconds, setSeconds] = useState<number>(0);
    const [otpInitError, setOtpInitError] = useState<string>("");

    useEffect(() => {
        if (active === false) return;
        const optTimerInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(optTimerInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(optTimerInterval);
        };
    });

    useEffect(() => {
        if (active === false) {
            setOtpInitError("");
            setInitOTPResponse(null);
            setMinutes(2);
            setSeconds(59);
        }
    }, [active]);

    const handleOTPInit = useCallback(
        async (data: OtpInitRequest) => {
            try {
                setOtpInitError("");
                setIsInitOTPLoading(true);
                const res = await otpInit(data);
                setInitOTPResponse(res);
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setOtpInitError(errorMessage);
                dispatch(
                    errorTrue({
                        message: errorMessage,
                    })
                );
            } finally {
                setIsInitOTPLoading(false);
            }
        },
        [dispatch]
    );

    const handleResendOTPInit = useCallback((data: OtpInitRequest) => {
        setMinutes(2);
        setSeconds(59);
        void handleOTPInit(data);
    }, []);

    return {
        minutes,
        seconds,
        otpInitError,
        initOTPResponse,
        isInitOTPLoading,
        handleOTPInit,
        handleResendOTPInit,
    };
}

export default useOtpInit;
