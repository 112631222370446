import { useCallback, useState } from "react";

import { RemoveZambiaApiAccessKeyAccountAccessRequest } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { removeZambiaApiAccessKeyAccountAccessApi } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { setUpdateZambiaApiAccessKey } from "../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch } from "react-redux";

export interface UseRemoveZambiaApiAccessKeyAccountAccessInterface {
    isRemoveZambiaApiAccessKeyAccountAccessLoading: boolean;
    removeZambiaApiAccessKeyAccountAccessErrorMessage: string;
    handleRemoveZambiaApiAccessKeyAccountAccess: (_data: RemoveZambiaApiAccessKeyAccountAccessRequest) => void;
}

interface Props {
    onComplete: () => void;
}

function useRemoveZambiaApiAccessKeyAccountAccess(props: Props): UseRemoveZambiaApiAccessKeyAccountAccessInterface {
    const dispatch = useDispatch();
    const [isRemoveZambiaApiAccessKeyAccountAccessLoading, setIsRemoveZambiaApiAccessKeyAccountAccessLoading] = useState(false);
    const [removeZambiaApiAccessKeyAccountAccessErrorMessage, setRemoveZambiaApiAccessKeyAccountAccessErrorMessage] = useState<string>("");

    const handleRemoveZambiaApiAccessKeyAccountAccess = useCallback(
        async (_data: RemoveZambiaApiAccessKeyAccountAccessRequest) => {
            try {
                setIsRemoveZambiaApiAccessKeyAccountAccessLoading(true);
                setRemoveZambiaApiAccessKeyAccountAccessErrorMessage("");
                const res = await removeZambiaApiAccessKeyAccountAccessApi(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key account removed successfully."));
                props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRemoveZambiaApiAccessKeyAccountAccessErrorMessage(errorMessage);
            } finally {
                setIsRemoveZambiaApiAccessKeyAccountAccessLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isRemoveZambiaApiAccessKeyAccountAccessLoading,
        removeZambiaApiAccessKeyAccountAccessErrorMessage,
        handleRemoveZambiaApiAccessKeyAccountAccess,
    };
}

export default useRemoveZambiaApiAccessKeyAccountAccess;
