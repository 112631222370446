import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CopyToClipboard } from "react-copy-to-clipboard";
import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import NewDropdownHead from "../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import { PaymentsType } from "../../../payments-new/hooks/state/send-money/payments.constants";
import UserAccount from "../../../../../models/userAccount";
import doesUserHaveAccessToAccount from "../../../../../helpers/doesUserHaveAccessToAccount";
import { openEditAccountDescriptionModal } from "../../../../../redux/accounts/slice/accountsSlice";
import { setAddMoneySelectedId } from "../../../../../redux/payments/addMoney/slice/addMoneySlice";
import useDownloadAccountDetails from "../../Hooks/State/useDownloadAccountDetails";
import { useNavigate } from "react-router-dom";

interface AccountLineDropdownInterface {
    data?: UserAccount;
}

function AccountLineDropdown(props: AccountLineDropdownInterface): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleDownloadAccountDetails: handleDownload, handleResetAll } = useDownloadAccountDetails();

    const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isZambia);

    const individualAccount = useSelector((state: IRootState) => state.account.individualAccount);

    const [userAccount, setUserAccount] = useState<UserAccount | null>(null);

    const [clipSuccess, setClipSuccess] = useState(false);

    useEffect(() => {
        if (!individualAccount) return;
        setUserAccount(individualAccount);
    }, [individualAccount]);

    useEffect(() => {
        if (!props.data) return;
        setUserAccount(props.data);
    }, [props.data]);

    useEffect(() => {
        if (clipSuccess) {
            setTimeout(() => {
                setClipSuccess(false);
            }, 800);
        } else {
            return;
        }
    }, [clipSuccess]);

    useEffect(() => {
        return () => {
            handleResetAll();
        };
    }, []);

    const handleDownloadAccountDetails = useCallback(() => {
        if (!userAccount) return;
        void handleDownload({
            userAccountId: userAccount.id,
        });
    }, [userAccount]);

    const handleOpenEditAccountDescriptionModal = useCallback(() => {
        dispatch(openEditAccountDescriptionModal());
    }, []);

    const handleClickCopy = useCallback(() => {
        setClipSuccess(true);
    }, []);

    return (
        <>
            {userAccount && (
                <>
                    <NewDropdownHead
                        content={<GoogleIcon icon="more_vert" />}
                        buttonType="tertiary"
                        dropdownWidth="max"
                        dropdownPosition="right"
                        isNoArrow
                    >
                        <>
                            {doesUserHaveAccessToAccount(props.data && props.data.id) && (
                                <NewDropdownItem
                                    linkTo={{
                                        pathname: "/payments/make/single",
                                        search: `?from=${userAccount.id}`,
                                    }}
                                    state={{
                                        typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                                        from: userAccount.id,
                                    }}
                                    size="md"
                                    padding="md"
                                    isLink
                                >
                                    {/* <SingleTransferIcon className="stroke-current" /> */}
                                    <GoogleIcon icon="send" />
                                    <span className="ml-2 text-sm">Send Money</span>
                                </NewDropdownItem>
                            )}

                            {/* //To-do */}
                            {isZambia && (
                                <NewDropdownItem
                                    onClick={() => {
                                        dispatch(setAddMoneySelectedId(userAccount.id));

                                        navigate("/payments/add-money/make");
                                    }}
                                    size="md"
                                    padding="md"
                                    isLink
                                >
                                    <GoogleIcon icon="add_circle" isOutlined />
                                    <span className="ml-2 text-sm">Add Money</span>
                                </NewDropdownItem>
                            )}
                            <NewDropdownItem linkTo={`/transactions/account/${userAccount.id}`} size="md" padding="md" isLink>
                                <GoogleIcon icon="list_alt" isOutlined />
                                <span className="ml-2 text-sm">Transactions</span>
                            </NewDropdownItem>
                            <NewDropdownItem linkTo={`/settings/account/${userAccount.id}`} size="md" padding="md" isLink>
                                <GoogleIcon icon="page_info" isOutlined />
                                <span className="ml-2 text-sm">Account Rules</span>
                            </NewDropdownItem>
                            {doesUserHaveAccessToAccount(props.data && props.data.id) && (
                                <NewDropdownItem onClick={handleOpenEditAccountDescriptionModal} size="md" padding="md" isLink>
                                    <GoogleIcon icon="edit_square" isOutlined />
                                    <span className="ml-2 text-sm">Edit Account Description</span>
                                </NewDropdownItem>
                            )}
                            <NewDropdownItem onClick={handleDownloadAccountDetails} size="md" padding="md" isLink>
                                <GoogleIcon icon="convert_to_text" isOutlined />
                                <span className="ml-2 text-sm">Download Account Details</span>
                            </NewDropdownItem>
                            <CopyToClipboard text={userAccount.bankAccount?.accountNumber || ""} onCopy={handleClickCopy}>
                                <NewDropdownItem size="md" padding="md">
                                    <GoogleIcon icon={clipSuccess ? "check" : "content_copy"} className="" isOutlined />
                                    <span className="ml-2 text-sm">Copy Account Number</span>
                                </NewDropdownItem>
                            </CopyToClipboard>
                        </>
                    </NewDropdownHead>
                </>
            )}
        </>
    );
}

export default AccountLineDropdown;
