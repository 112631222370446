import {
    BulkPaymentInitRequest,
    BulkPaymentInitResponse,
    BulkPaymentItem,
    BulkTransferStatusRequest,
    BulkTransferStatusResponse,
    BulkUploadResponse,
} from "./bulkTransferApi.types";
import { makeRequest, makeRequestDownloadFile, makeRequestUploadFile } from "../../../../../helpers/request/makeRequest";

import BankAccount from "../../../../../models/bankAccount";
import BulkPaymentV2StatusItem from "../../../../../models/bulkPaymentV2StatusItem";
import CustomerAccount from "../../../../../models/customerAccount";
// import {GenericObject} from "../../../../../helpers/types";
import Parsers from "../../../../../utils/parsers";
// import {TransactionStatus} from "../../../../../models/transaction.constants";
// import Transaction from "../../../../../models/transaction";
// import {TransactionStatus} from "../../../../../models/transaction.constants";
import { downloadBlob } from "../../../../../utils/blob";
import { toFormData } from "../../../../../utils/converters";

export const bulkPaymentInit = async (data: BulkPaymentInitRequest): Promise<BulkPaymentInitResponse> => {
    // spread out data to ensure that customerAccountId always has a value
    const requestData: BulkPaymentInitRequest = {
        payments: data.payments.map((paymentItem) => ({
            originatingAccountId: paymentItem.originatingAccountId,
            recipient: {
                externalAccount: {
                    accountNumber: paymentItem.recipient.externalAccount.accountNumber || "",
                    bankCode: paymentItem.recipient.externalAccount.bankCode || "",
                    bankAccountId: paymentItem.recipient.externalAccount.bankAccountId || "",
                    customerAccountId: paymentItem.recipient.externalAccount.customerAccountId || "",
                },
                internalAccountId: paymentItem.recipient.internalAccountId || "",
                cardId: paymentItem.recipient.cardId || "",
            },
            amount: paymentItem.amount,
            purpose: paymentItem.purpose,
            key: paymentItem.key,
        })),
        otp: data.otp,
    };
    // const res = await makeRequest("/main/payments/initiate/bulk", requestData);
    const res = await makeRequest("/main/payments/initiate/bulk/v2", requestData);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        // transactions: Parsers.classObjectArray(res.transactions, Transaction),
        // isApproved: Parsers.boolean(res.isApproved),
        // processing: Parsers.boolean(res.processing),
        bulkPaymentKey: Parsers.string(res.bulkPaymentKey),
        accountsToActivate: Parsers.stringArray(res.accountsToActivate),
    };
};

export const getBulkTransferStatus = async (_data: BulkTransferStatusRequest): Promise<BulkTransferStatusResponse> => {
    const res = await makeRequest("/main/payments/initiate/bulk/v2/status", _data);

    if (res instanceof Error) {
        throw res;
    }

    return {
        bulkPaymentKey: Parsers.string(res.bulkPaymentKey),
        // payments: (res.payments as GenericObject[]).map((item) => ({
        // key: Parsers.string(item.key),
        // status: item.status ? Parsers.number(item.status) : TransactionStatus.PROCESSING,
        // failedReason: item.failedReason ? Parsers.string(item.failedReason) : "",
        // transactionId: item.transactionId ? Parsers.string(item.transactionId) : "",
        // })),
        payments: Parsers.classObjectArray(res.payments, BulkPaymentV2StatusItem),
        timestamp: Parsers.number(res.timestamp),
    };
};

export const downloadTemplate = async (): Promise<void> => {
    const res = await makeRequestDownloadFile("/main/payments/bulk/download-template");
    if (res instanceof Blob) {
        downloadBlob(res, "Bulk Template.csv");
        return;
    } else {
        throw res;
    }
};

export const bulkUpload = async (file: FormData): Promise<BulkUploadResponse> => {
    const res = await makeRequestUploadFile("/main/payments/bulk/upload", toFormData(file));
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        data: res.data as BulkPaymentItem[],
        customerAccounts: Parsers.classObjectArray(res.customerAccounts, CustomerAccount),
        bankAccounts: Parsers.classObjectArray(res.bankAccounts, BankAccount),
    };
};
