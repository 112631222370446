import FullPageTray from "../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import Money from "../../../../../../../components/money";
import { formatDateAndTime } from "../../../../../../../utils/formatDate";
import { setIsAddMoneyCollectionRequestDetailCardOpen } from "../../../../../../../redux/payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice";
import titleCase from "../../../../../../../hooks/titleCase";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

const DATA_TYPE = "add-money";

function AddMoneyHistoryTray(): JSX.Element {
    const dispatch = useDispatch();

    const addMoneyCollectionRequestDetail = useAppSelector((state) => state.addMoneyHistory.addMoneyCollectionRequestDetail);
    const isAddMoneyCollectionRequestDetailCardOpen: boolean = useAppSelector(
        (state) => state.addMoneyHistory.isAddMoneyCollectionRequestDetailCardOpen
    );

    const successfulOrLastAttempt = addMoneyCollectionRequestDetail?.attempts.sort((a, b) =>
        a.isSuccessful === b.isSuccessful ? 0 : a.isSuccessful ? -1 : 1
    )[0];

    const handleCloseAddMoneyCollectionRequestTray = () => {
        dispatch(setIsAddMoneyCollectionRequestDetailCardOpen(false));
    };

    return (
        <>
            {/*<===Terminal Transaction Details Code goes here===>*/}
            {/* {addMoneyCollectionRequestDetail && ( */}
            <FullPageTray
                active={isAddMoneyCollectionRequestDetailCardOpen}
                dataType={DATA_TYPE}
                handleReset={handleCloseAddMoneyCollectionRequestTray}
                withClickOutside
            >
                <FullPageTrayHeader toggler={handleCloseAddMoneyCollectionRequestTray} dataType={DATA_TYPE}>
                    Add Money Details
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={DATA_TYPE}>
                    <div className="mb-10 flex w-full flex-col space-y-4">
                        {/* <div className="flex w-full flex-col space-y-4 rounded-lg border-grey-secondary bg-grey-backdrop p-4"></div> */}

                        <div className="flex w-full flex-col space-y-4 rounded-lg border-grey-secondary bg-grey-backdrop p-4">
                            <div className="flex w-full items-center justify-between" data-type={DATA_TYPE}>
                                <p className="mt-1 text-left text-2xl font-medium leading-none text-black-secondary" data-type={DATA_TYPE}>
                                    <Money amount={addMoneyCollectionRequestDetail?.amount || ""} />
                                </p>

                                <div>
                                    <div
                                        className={
                                            "w-max rounded-lg bg-error-backdrop p-1 pl-3 pr-3 " +
                                            `${addMoneyCollectionRequestDetail?.isSuccessful ? "border-success bg-success-backdrop text-success" : ""} ` +
                                            `${addMoneyCollectionRequestDetail?.isPending ? "border-warning bg-warning-backdrop text-warning" : ""} ` +
                                            `${addMoneyCollectionRequestDetail?.isFailed ? "border-error bg-error-backdrop text-error" : ""} `
                                        }
                                    >
                                        <span className="text-sm">{addMoneyCollectionRequestDetail?.statusText}</span>
                                    </div>
                                </div>
                            </div>
                            {addMoneyCollectionRequestDetail?.isFailed && addMoneyCollectionRequestDetail.failedReason && (
                                <div className="flex w-full items-start justify-between" data-type={DATA_TYPE}>
                                    <p className="text-sm font-normal text-black-tertiary" data-type={DATA_TYPE}>
                                        Failed Reason
                                    </p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-error">
                                        {addMoneyCollectionRequestDetail.failedReason}
                                    </p>
                                </div>
                            )}
                            <div className="flex w-full items-start justify-between" data-type={DATA_TYPE}>
                                <p className="text-sm font-normal text-black-tertiary" data-type={DATA_TYPE}>
                                    Channel
                                </p>
                                <p className="max-w-55% break-words text-right text-sm font-medium">
                                    {titleCase(addMoneyCollectionRequestDetail?.channelText || "")}
                                </p>
                            </div>
                            <div className="flex w-full items-start justify-between" data-type={DATA_TYPE}>
                                <p className="text-sm font-normal text-black-tertiary" data-type={DATA_TYPE}>
                                    Type
                                </p>
                                <p className="max-w-55% break-words text-right text-sm font-medium">
                                    {titleCase(successfulOrLastAttempt?.mobileMoneyCollection?.serviceProviderText || "")}
                                </p>
                            </div>
                            <div className="flex w-full items-start justify-between" data-type={DATA_TYPE}>
                                <p className="text-sm font-normal text-black-tertiary" data-type={DATA_TYPE}>
                                    Phone Number
                                </p>
                                <p className="max-w-55% break-words text-right text-sm font-medium">
                                    {addMoneyCollectionRequestDetail?.attempts[0].mobileMoneyCollection?.debitPhoneNumber}
                                </p>
                            </div>
                        </div>

                        <div className="flex w-full flex-col space-y-4 rounded-lg border-grey-secondary bg-grey-backdrop p-4">
                            {addMoneyCollectionRequestDetail?.createdAt && (
                                <div className="flex w-full items-start justify-between" data-type={DATA_TYPE}>
                                    <p className="text-sm font-normal text-black-tertiary" data-type={DATA_TYPE}>
                                        Date
                                    </p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                        {formatDateAndTime(addMoneyCollectionRequestDetail?.createdAt)}
                                    </p>
                                </div>
                            )}
                            <div className="flex w-full items-start justify-between" data-type={DATA_TYPE}>
                                <p className="text-sm font-normal text-black-tertiary" data-type={DATA_TYPE}>
                                    Transaction Reference
                                </p>
                                <p className="max-w-55% break-words text-right text-sm font-medium">
                                    {addMoneyCollectionRequestDetail?.paymentReference}
                                </p>
                            </div>
                            <div className="flex w-full items-start justify-between" data-type={DATA_TYPE}>
                                <p className="text-sm font-normal text-black-tertiary" data-type={DATA_TYPE}>
                                    Fees
                                </p>
                                <p className="max-w-55% break-words text-right text-sm font-medium">
                                    <Money amount={addMoneyCollectionRequestDetail?.attempts[0].mobileMoneyCollection?.lencoFee || 0} />
                                </p>
                            </div>
                        </div>
                    </div>
                </FullPageTrayBody>
            </FullPageTray>
            {/* )} */}
        </>
    );
}

export default AddMoneyHistoryTray;
