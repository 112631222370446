import { AccountSettingsSectionValue, AccountSettingsSections } from "../../Services/account-settings/account-settings.constants";

import React from "react";

interface Props {
    stage: AccountSettingsSectionValue;
}

function AccountSettingsDetailsHeader({ stage }: Props): JSX.Element {
    return (
        <div className="flex w-full flex-col space-y-1">
            <span className="text-sm font-medium text-black md:text-xl">{AccountSettingsSections[stage].text}</span>
            <span className="text-xs text-black-tertiary md:text-sm">{AccountSettingsSections[stage].subtext}</span>
        </div>
    );
}

export default AccountSettingsDetailsHeader;
