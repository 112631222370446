import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class BillVendorMin {
    [immerable] = true;

    constructor(
        public id: string | null,
        public name: string | null,
        public logoUrl: string | null
    ) {}

    static create(obj: GenericObject): BillVendorMin {
        return new BillVendorMin(Parsers.nullableString(obj.id), Parsers.nullableString(obj.name), Parsers.nullableString(obj.logoUrl));
    }
}
