import { useCallback, useEffect } from "react";
import useCommonModalAction, { CommonModalActionResponse } from "./useCommonModalAction";

import { Recipient } from "../pending-approval/use-saved-recipients-state.types";
import { RecipientActionType } from "../../../../../../redux/payments/recipients/slice/recipientsSlice.types";
import { deleteRecipient } from "../../../services/savedRecipients/savedRecipients.api";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { removeRecipient } from "../../../../../../redux/payments/recipients/slice/recipientsSlice";
import { useDispatch } from "react-redux";

export interface UseDeleteRecipientResponse extends Omit<CommonModalActionResponse, "setIsSubmitting" | "setErrorMessage" | "recipientActionType"> {
    handleDeleteRecipient: (recipient: Recipient) => Promise<void>;
}

function useDeleteRecipient(): UseDeleteRecipientResponse {
    const dispatch = useDispatch();

    const {
        isModalOpen,
        openModal,
        closeModal,
        toggleIsModalOpen,
        isSubmitting,
        setIsSubmitting,
        errorMessage,
        setErrorMessage,
        recipient,
        recipientActionType,
    } = useCommonModalAction();

    useEffect(() => {
        if (recipientActionType === RecipientActionType.DELETE) {
            openModal();
        }
    }, [recipientActionType]);

    const handleDeleteRecipient = useCallback(
        async (_recipient: Recipient) => {
            try {
                setErrorMessage(undefined);
                setIsSubmitting(true);

                await deleteRecipient(_recipient.customerAccount.id);
                closeModal();

                dispatch(removeRecipient(_recipient.customerAccount.id));
                dispatch(messageTrue({ message: "Recipient has been deleted" }));
            } catch (err) {
                const error = getErrorMessage(err);
                dispatch(errorTrue({ message: error }));
                setErrorMessage(error);
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    return {
        isModalOpen,
        openModal,
        closeModal,
        toggleIsModalOpen,
        isSubmitting,
        errorMessage,
        handleDeleteRecipient,
        recipient,
    };
}

export default useDeleteRecipient;
