import { CardShowCVVRequest, CardsListRequest } from "../../Services/cardsApi.types";
import { cardInit, cardShowCVV, cardsGeneralSettings, cardsList, teamMembersWithManageAccess } from "../../Services/cardsApi";
import {
    resetCardsFilterState,
    setCardUsers,
    setCardsFilterState,
    setIsCardsFiltering,
    setIsCardsPaginationLoading,
    setNewCards,
    setShowCVV,
    setTeamMembersWithManageAccess,
    setTeamMembersWithViewAccess,
} from "../../../../../redux/cards/slice/cardsSlice";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../redux/rootReducer";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { listTeamMembers } from "../../../settings-new/Services/team-members/team-members.api";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { produce } from "immer";
import { FilterItemSelectType, Item, TableFilterProps } from "../../../../../components/Table/components/TableFilter";
import { CardStatusArray, CardStatusName, CardWalletArray, CardWalletName } from "../../Services/cards.constants";
import { CardStatus, CardWalletType } from "../../../../../models/card.constants";

const useCards = () => {
    const dispatch = useDispatch();

    const [filter, setFilter] = useState<CardsListRequest>({
        cardStatuses: [],
        assignedToIds: [],
        query: "",
    });

    const [cardInitError, setCardInitError] = useState<string | null>(null);
    const [isCardInitLoading, setIsCardInitLoading] = useState<boolean>(false);

    const membersWithManageCardAccess = useSelector((state: IRootState) => state.cards.teamMembersWithManageAccess);
    const selectedCardsFilterState = useSelector((state: IRootState) => state.cards.selectedCardsFilterState);

    const [cardsListError, setCardsListError] = useState<string | null>(null);
    const [isCardsListLoading, setIsCardsListLoading] = useState<boolean>(false);
    const [isFilteredCardsListLoading, setIsFilteredCardsListLoading] = useState<boolean>(false);

    const [cardsGeneralSettingsError, setCardsGeneralSettingsError] = useState<string | null>(null);
    const [isCardsGeneralSettingsLoading, setIsCardsGeneralSettingsLoading] = useState<boolean>(false);

    const [cardShowCvvError, setCardShowCvvError] = useState<string | null>(null);
    const [isCardShowCvvLoading, setIsCardShowCvvLoading] = useState<boolean>(false);

    const [teamMembersWithManageAccessError, setTeamMembersWithManageAccessError] = useState<string | null>(null);
    const [isTeamMembersWithManageAccessLoading, setIsTeamMembersWithManageAccessLoading] = useState(false);

    const [teamMembersError, setTeamMembersError] = useState<string | null>(null);
    const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);
    const [cardsTotal, setCardsTotal] = useState(0);
    const [cardsOffset, setCardsOffset] = useState(0);
    const [cardsGroupSize, setCardsGroupSize] = useState(0);

    const [startInit, setStartInit] = useState(true);

    const handleCardInit = useCallback(async (data?: CardsListRequest) => {
        try {
            setCardInitError(null);
            setIsCardInitLoading(true);
            const res = await cardInit(data);
            const usersRes = await listTeamMembers();
            const users = usersRes.map((_el) => _el.teamMember);
            dispatch(setCardUsers(users));
            dispatch(setNewCards(res.cardsData));
            setCardsTotal(res.total);
            setCardsGroupSize(res.groupSize);
            dispatch(setTeamMembersWithManageAccess(res.teamMembersWithManageAccess));
            dispatch(setTeamMembersWithViewAccess(res.teamMembersWithViewAccess));
            dispatch(setShowCVV(!!res?.ngnPhysical?.showCvv));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setCardInitError(errorMessage);
        } finally {
            setIsCardInitLoading(false);
            setStartInit(false);
        }
    }, []);

    const handleCardGeneralSettings = useCallback(async () => {
        try {
            setIsCardsGeneralSettingsLoading(true);
            setCardsGeneralSettingsError(null);
            const res = await cardsGeneralSettings();
            dispatch(setShowCVV(!!res?.ngnPhysical?.showCvv));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setCardsGeneralSettingsError(errorMessage);
        } finally {
            setIsCardsGeneralSettingsLoading(false);
        }
    }, [dispatch]);

    const handleCardShowCVV = useCallback(
        async (_data: CardShowCVVRequest) => {
            try {
                setIsCardShowCvvLoading(true);
                setCardShowCvvError(null);
                const res = await cardShowCVV(_data);
                dispatch(setShowCVV(!!res?.ngnPhysical?.showCvv));
                dispatch(messageTrue({ message: "Cards Security updated successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                dispatch(errorTrue({ message: errorMessage }));
                setCardsGeneralSettingsError(errorMessage);
            } finally {
                setIsCardShowCvvLoading(false);
            }
        },
        [dispatch]
    );

    const handleCardsList = useCallback(
        async (_data: CardsListRequest, _isFilter: boolean = false) => {
            try {
                setCardsListError(null);
                dispatch(setIsCardsFiltering(_isFilter));
                !_isFilter ? setIsCardsListLoading(true) : setIsFilteredCardsListLoading(true);
                const res = await cardsList(_data);
                const usersRes = await listTeamMembers();
                const users = usersRes.map((_el) => _el.teamMember);
                dispatch(setCardUsers(users));
                dispatch(setNewCards(res.cardsData));
                setCardsTotal(res.total);
                setCardsGroupSize(res.groupSize);
                if (!_isFilter) {
                    dispatch(setIsCardsFiltering(res.cardsData.length > 0));
                }
                // dispatch(mainInitAllCardsUpdate(res.cardsData.map((_data) => _data.card)));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                dispatch(errorTrue({ message: errorMessage }));
                setCardsListError(errorMessage);
            } finally {
                !_isFilter ? setIsCardsListLoading(false) : setIsFilteredCardsListLoading(false);
            }
        },
        [dispatch]
    );

    const handleClearFilter = useCallback(async () => {
        dispatch(setIsCardsFiltering(false));
        setFilter({
            cardStatuses: [],
            assignedToIds: [],
            query: "",
        });
        await handleCardsList(
            {
                cardStatuses: [],
                assignedToIds: [],
                query: "",
            },
            true
        );
    }, [filter]);

    const filterOption: TableFilterProps = useMemo(
        () =>
            ({
                items: [
                    {
                        text: "Assigned To",
                        selectType: FilterItemSelectType.CHECKBOX_OPTION,
                        subList: membersWithManageCardAccess?.map((_member) => ({
                            text: _member.name,
                            value: _member.id,
                        })),
                        onClick: (data: { value: string }) => {
                            if (filter?.assignedToIds?.includes(data?.value)) {
                                filter.assignedToIds = filter.assignedToIds?.filter((_el) => _el != data?.value);
                            } else {
                                filter.assignedToIds?.push(data?.value);
                            }
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Status",
                        selectType: FilterItemSelectType.CHECKBOX_OPTION,
                        subList: CardStatusArray?.map((status) => ({
                            text: CardStatusName[status],
                            value: status,
                        })),
                        onClick: (data: { value: CardStatus }) => {
                            if (filter?.cardStatuses?.includes(data?.value)) {
                                filter.cardStatuses = filter.cardStatuses?.filter((_el) => _el != data?.value);
                            } else {
                                filter.cardStatuses?.push(data?.value);
                            }
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Type",
                        selectType: FilterItemSelectType.RADIO_OPTION,
                        subList: CardWalletArray?.map((type) => ({
                            text: CardWalletName[type],
                            value: type,
                        })),
                        onClick: (data: { value: CardWalletType; checked: boolean }) => {
                            filter.walletType = data?.value;
                            setFilter({ ...filter });
                        },
                    },
                ] as Item[],
                handleApplyFilter: async () => {
                    dispatch(setIsCardsFiltering(true));
                    await handleCardsList(filter, true);
                },
            }) as TableFilterProps,
        [filter, membersWithManageCardAccess]
    );

    const handleGetTeamMembers = useCallback(async () => {
        try {
            setTeamMembersError(null);
            setIsLoadingTeamMembers(true);
            const res = await listTeamMembers();
            const users = res.map((_el) => _el.teamMember);
            dispatch(setCardUsers(users));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setTeamMembersError(errorMessage);
        } finally {
            setIsLoadingTeamMembers(false);
        }
    }, [dispatch]);

    const handleGetTeamMembersWithManageAccess = useCallback(async () => {
        try {
            setTeamMembersWithManageAccessError(null);
            setIsTeamMembersWithManageAccessLoading(true);
            const res = await teamMembersWithManageAccess();
            dispatch(setTeamMembersWithManageAccess(res.teamMembersWithManageAccess));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setTeamMembersWithManageAccessError(errorMessage);
        } finally {
            setIsTeamMembersWithManageAccessLoading(false);
        }
    }, [dispatch]);

    const handleUpdateCardRequestOffset = useCallback(
        (_newOffset: number) => {
            setCardsOffset(_newOffset);
            void handleCardsList({ ...selectedCardsFilterState, offset: _newOffset }, true);
        },
        [selectedCardsFilterState]
    );

    const handleFilteredDetails = useCallback(
        <T>(data: T) => {
            const updatedList = produce(selectedCardsFilterState, (draft) => {
                return (draft = { ...draft, ...data });
            });
            dispatch(setCardsFilterState(updatedList));
            dispatch(setIsCardsPaginationLoading(true));
            void handleCardsList(updatedList, true);
        },
        [selectedCardsFilterState]
    );

    const handleClearAll = useCallback(() => {
        dispatch(resetCardsFilterState());
        void handleCardsList(
            {
                offset: 0,
                assignedToIds: [],
                cardStatuses: [],
                walletType: undefined,
                query: "",
            },
            true
        );
    }, []);

    return {
        cardsTotal,
        cardsOffset,
        cardsListError,
        cardsGroupSize,
        teamMembersError,
        isCardsListLoading,
        isLoadingTeamMembers,
        isFilteredCardsListLoading,
        teamMembersWithManageAccessError,
        isTeamMembersWithManageAccessLoading,
        cardsGeneralSettingsError,
        isCardsGeneralSettingsLoading,
        cardShowCvvError,
        isCardShowCvvLoading,
        cardInitError,
        isCardInitLoading,
        filter,
        filterOption,
        startInit,
        handleClearAll,
        handleCardsList,
        handleGetTeamMembers,
        handleFilteredDetails,
        handleUpdateCardRequestOffset,
        handleGetTeamMembersWithManageAccess,
        handleCardGeneralSettings,
        handleCardShowCVV,
        handleCardInit,
        handleClearFilter,
    };
};

export default useCards;
