import { useEffect, useLayoutEffect, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../../components/message-toast/sub/error-toast";
import LencoSpinner from "../../../../../../components/spinner";
import Money from "../../../../../../components/money";
import Otp from "../../../../../../components/otp";
import { OtpActivityType } from "../../../../../../helpers/AppConstants";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import TransferBetweenAccountPaymentHeaders from "./TransferBetweenAccountPaymentHeaders";
import { setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { setTransferBetweenOtp } from "../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import titleCase from "../../../../../../hooks/titleCase";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useOtpInit from "../../../hooks/state/send-money/single-transfer/useOtpInit";
import useTransferBetween from "../../../hooks/state/send-money/TransferBetween/useTransferBetween";
import useTransferBetweenOtp from "../../../hooks/state/send-money/TransferBetween/useTransferBetweenOtp";

function MakePayment(): JSX.Element {
    const dispatch = useDispatch();

    const { handleOtpChange } = useTransferBetweenOtp();
    const { otpInitError, isInitOTPLoading, handleOTPInit, handleResendOTPInit } = useOtpInit({});
    const { isTransferBetweenLoading, transferBetweenAccountResponse, transferBetweenError, handleTransferBetween } = useTransferBetween();

    const isAuthAppSetup = useAppSelector((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);
    const recipientBankAccount = useAppSelector((state) => state.transferBetween.recipientBankAccount);
    const transferBetweenDetails = useAppSelector((state) => state.transferBetween.transferBetweenDetails);

    useLayoutEffect(() => {
        if (isAuthAppSetup) return;
        void handleOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION });
    }, [handleOTPInit, isAuthAppSetup]);

    useEffect(() => {
        if (transferBetweenDetails.otp.length < 6) return;
        void handleTransferBetween(transferBetweenDetails);
    }, [transferBetweenDetails]);

    useEffect(() => {
        if (!transferBetweenAccountResponse) return;
        dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
    }, [transferBetweenAccountResponse]);
    const [resendOtpText, setResendOtpText] = useState(false);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center">
                <TransferBetweenAccountPaymentHeaders />

                {!isAuthAppSetup && isInitOTPLoading && (
                    <div className="flex w-full flex-row items-center justify-center space-x-4 pt-2 text-sm">
                        <LencoSpinner size="sm" />
                        <span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
                    </div>
                )}
                {!isInitOTPLoading && !otpInitError && (
                    <>
                        <div className="flex w-full flex-col space-y-9">
                            <p className="pt-2 text-center text-base text-black-secondary">
                                {isAuthAppSetup && "Enter the OTP generated from your token App to approve the payment of "}
                                {!isAuthAppSetup && "One-time password (OTP) has been sent to your email to approve payment of "}
                                <Money amount={transferBetweenDetails.amount} /> to {titleCase(recipientBankAccount?.accountName || "")}
                            </p>
                            <div className="flex w-full flex-col rounded-lg bg-white p-6">
                                <Otp
                                    value={transferBetweenDetails.otp || undefined}
                                    active={!isInitOTPLoading}
                                    isError={transferBetweenError}
                                    onChange={handleOtpChange}
                                    canResendOtp={!isAuthAppSetup}
                                    isResendOtpLoading={isInitOTPLoading}
                                    handleResend={() => {
                                        handleResendOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION });
                                        setResendOtpText(true);
                                    }}
                                />
                            </div>
                            <div className="flex w-full flex-row items-center justify-center space-x-4">
                                <ButtonComp
                                    type="button"
                                    size="xl"
                                    color="grey"
                                    ripple="light"
                                    buttonType="secondary"
                                    func={() => {
                                        dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT));
                                        dispatch(setTransferBetweenOtp(""));
                                    }}
                                >
                                    Back
                                </ButtonComp>

                                <ButtonComp
                                    type="submit"
                                    size="xl"
                                    color="black"
                                    ripple="light"
                                    disable={transferBetweenDetails.otp.length < 6}
                                    buttonType="primary"
                                    isLoading={isTransferBetweenLoading}
                                    func={() => void handleTransferBetween(transferBetweenDetails)}
                                >
                                    <span>Authorize</span>
                                </ButtonComp>
                            </div>
                        </div>
                    </>
                )}
                {otpInitError && <ErrorToast error={<p>{otpInitError}</p>} isReactNode />}
            </div>
        </>
    );
}

export default MakePayment;
