import { setZambiaSingleTransferRecipientBankCode } from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseSingleTransferRecipientBankCodeInterface {
    handleSelectBank: (_bankAccountCode: string) => void;
}

function useSingleTransferRecipientBankCode(): UseSingleTransferRecipientBankCodeInterface {
    const dispatch = useDispatch();

    const handleSelectBank = useCallback((_bankAccountCode: string) => {
        dispatch(setZambiaSingleTransferRecipientBankCode(_bankAccountCode));
        // dispatch(setCanVerifyZambiaRecipientAccountDetails(true));
    }, []);

    return { handleSelectBank };
}

export default useSingleTransferRecipientBankCode;
