import React from "react";
import SkeletonElement from "../../../../../components/skeleton";

function AccountCardSkeleton(): JSX.Element {
    return (
        <>
            <div className="box-border flex h-[296px] max-h-[296px] min-h-[296px] w-full flex-col justify-between rounded-lg p-5 shadow-custom">
                <div>
                    <div className="flex h-10 w-full justify-between py-2">
                        <div className="w-12">
                            <SkeletonElement box />
                        </div>
                        <div className="w-4">
                            <SkeletonElement box />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center px-20% pt-4">
                        <SkeletonElement text />
                    </div>
                    <div className="flex flex-row justify-center px-25% pt-4">
                        <SkeletonElement text />
                    </div>
                    <div className="flex flex-row justify-center px-30% pt-2">
                        <SkeletonElement text />
                    </div>
                    <div className="flex flex-row justify-center px-30% pt-2">
                        <SkeletonElement text />
                    </div>
                </div>
                <div className="flex w-full items-center justify-between pt-2">
                    <div className="flex flex-row space-x-2 pt-2">
                        {" "}
                        <div className="h-7 w-6">
                            <SkeletonElement box />
                        </div>
                        <div className="w-14 flex-col">
                            <div>
                                {" "}
                                <SkeletonElement text />
                            </div>
                            <div className="w-8 pt-1">
                                {" "}
                                <SkeletonElement text />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row space-x-2 pt-2">
                        {" "}
                        <div className="h-7 w-6">
                            <SkeletonElement box />
                        </div>
                        <div className="w-14 flex-col">
                            <div>
                                {" "}
                                <SkeletonElement text />
                            </div>
                            <div className="w-8 pt-1">
                                {" "}
                                <SkeletonElement text />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountCardSkeleton;
