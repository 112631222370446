import { AccountBalanceAlertType, AccountBalanceInstructionType } from "../../../../../../models/userAccount.constants";
import React, { useState } from "react";
import {
    setBalanceType,
    setIsBalanceInstructionTray,
    setIsEditExcessBalance,
    setIsEditMinimumBalance,
} from "../../../../../../redux/settings/security/settingsSecuritySlice";

import AccountSettingsDetailsHeader from "../AccountSettingsDetailsHeader";
import { AccountSettingsSectionValue } from "../../../Services/account-settings/account-settings.constants";
import AccountSettingsSummaryItem from "../AccountSettingsSummaryItem";
// import { IRootState } from "../../../../../../redux/rootReducer";
import Money from "../../../../../../components/money";
import UserAccount from "../../../../../../models/userAccount";
import useBalanceAlertInstruction from "../../../Services/account-settings/hooks/useBalanceAlertInstruction";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../../../redux/rootReducer";
import { classArrayToMap } from "../../../../../../utils/map";

interface Props {
    account: UserAccount;
    type: AccountBalanceAlertType;
    stage: AccountSettingsSectionValue;
}

function BalanceAlertInstruction({ account, type, stage }: Props): JSX.Element {
    // const isEditMinimumBalance = useSelector((state: IRootState) => state.settingsSecurity.isEditMinimumBalance);
    // const isEditExcessBalance = useSelector((state: IRootState) => state.settingsSecurity.isEditExcessBalance);

    const selectAccounts = (state: IRootState) => state.init.main?.companyDetails.accounts || [];
    const getAccountsMap = createSelector(selectAccounts, (accounts) => new Map(classArrayToMap(accounts)));
    const accountsMap = useSelector(getAccountsMap);

    const dispatch = useDispatch();

    const { setIsEditMode, minBalanceInstruction, excessBalanceInstruction, handleRemoveInstruction } = useBalanceAlertInstruction(account, type);

    const [isUpdatingExcess, setIsUpdatingExcess] = useState(false);
    const [isUpdatingMinimum, setIsUpdatingMinimum] = useState(false);

    const handleEditMinimumBalance = () => {
        setIsEditMode(true);
        dispatch(setBalanceType(AccountBalanceInstructionType.LOW_BALANCE_ALERT));
        dispatch(setIsBalanceInstructionTray(true));
        dispatch(setIsEditMinimumBalance(true));
    };

    const handleEditExcessBalance = () => {
        setIsEditMode(true);
        dispatch(setBalanceType(AccountBalanceInstructionType.EXCESS_BALANCE_ALERT));
        dispatch(setIsBalanceInstructionTray(true));
        dispatch(setIsEditExcessBalance(true));
    };

    return (
        <div className="flex h-full w-full flex-col space-y-9 md:px-8">
            {/* <div className="flex w-full max-w-md flex-col space-y-9 pb-8 md:px-8"> */}
            <AccountSettingsDetailsHeader stage={stage} />

            <div className="w-full space-y-6">
                <div className="flex flex-col items-start justify-between space-y-2 xl:flex-row xl:items-center xl:space-y-0">
                    <div className="w-64 space-y-1">
                        <p className="text-base font-medium">Minimum Balance</p>
                        <p className="text-sm text-black-tertiary">Notify me when my balance falls below</p>
                    </div>
                    <div className="flex h-full w-full max-w-72 items-center justify-start">
                        <AccountSettingsSummaryItem
                            account={account}
                            accountsMap={accountsMap}
                            handleEditIconClick={handleEditMinimumBalance}
                            canRemove={!!minBalanceInstruction}
                            isRemoving={isUpdatingMinimum}
                            handleRemoveIconClick={() =>
                                handleRemoveInstruction(AccountBalanceInstructionType.LOW_BALANCE_ALERT, setIsUpdatingMinimum)
                            }
                            size="sm"
                            fullWidth
                        >
                            {!minBalanceInstruction || (minBalanceInstruction.amount || 0) < 1 ? (
                                <p className="text-sm text-black-tertiary">Enter Amount</p>
                            ) : (
                                <Money currency={account.bankAccountCurrency} amount={minBalanceInstruction?.amount || 0} />
                            )}
                        </AccountSettingsSummaryItem>
                    </div>
                </div>

                <div className="flex flex-col items-start justify-between space-y-2 xl:flex-row xl:items-center xl:space-y-0">
                    <div className="w-64 space-y-1">
                        <p className="text-base font-medium">Excess Balance</p>
                        <p className="text-sm text-black-tertiary">Notify me when my balance exceeds</p>
                    </div>
                    <div className="flex h-full w-full max-w-72 items-center justify-start">
                        <AccountSettingsSummaryItem
                            account={account}
                            accountsMap={accountsMap}
                            handleEditIconClick={handleEditExcessBalance}
                            canRemove={!!excessBalanceInstruction}
                            isRemoving={isUpdatingExcess}
                            handleRemoveIconClick={() =>
                                handleRemoveInstruction(AccountBalanceInstructionType.EXCESS_BALANCE_ALERT, setIsUpdatingExcess)
                            }
                            size="sm"
                            fullWidth
                        >
                            {!excessBalanceInstruction || (excessBalanceInstruction.amount || 0) < 1 ? (
                                <p className="text-sm text-black-tertiary">Enter Amount</p>
                            ) : (
                                <Money currency={account.bankAccountCurrency} amount={excessBalanceInstruction?.amount || 0} />
                            )}
                        </AccountSettingsSummaryItem>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BalanceAlertInstruction;
