import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import AvatarDetails from "../../../../../../components/avatar-details";
import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import CableTVPurchaseHeaders from "./CableTVPurchaseHeaders";
import { IRootState } from "../../../../../../redux/rootReducer";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import getInitials from "../../../../../../helpers/get-initials";
import { setBillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";

function ReviewPayment(): JSX.Element {
    const dispatch = useDispatch();

    const selectedVendorName = useSelector((state: IRootState) => state.cableTVPurchase.selectedVendorName);
    const selectedUserAccount = useSelector((state: IRootState) => state.cableTVPurchase.selectedUserAccount);
    const selectedProductName = useSelector((state: IRootState) => state.cableTVPurchase.selectedProductName);
    const cableTVPurchaseDetails = useSelector((state: IRootState) => state.cableTVPurchase.cableTVPurchaseDetails);

    const handleBack = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.PAYMENT_DETAILS));
    }, []);

    const handleNextPage = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.MAKE_PAYMENT));
    }, []);

    return (
        <>
            {cableTVPurchaseDetails && selectedUserAccount && (
                <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-9">
                    <CableTVPurchaseHeaders />

                    <div className="flex w-full flex-col space-y-6">
                        <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                            <div className="flex flex-col items-center justify-center space-y-3 pb-6">
                                <AvatarDetails
                                    size="lg"
                                    initials={getInitials(selectedUserAccount?.bankAccount?.accountName || "")}
                                    icon="outflow"
                                    fullWidth={false}
                                />
                                <p className="text-sm font-normal !leading-[100%] text-black-tertiary">You&apos;re buying</p>
                                <p className="text-[32px] font-medium !leading-[100%] text-black">
                                    <Money amount={cableTVPurchaseDetails.amount} />
                                </p>
                                <p className="text-sm font-normal !leading-[100%] text-black-tertiary">{selectedVendorName} Subscription</p>
                            </div>
                            <div className="flex w-full flex-col space-y-3">
                                <p className="text-sm font-medium leading-[100%] text-black-tertiary">From</p>
                                <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Account Name</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">
                                            {selectedUserAccount && selectedUserAccount?.bankAccount?.accountName}
                                        </p>
                                    </div>
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Account Number</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">
                                            {selectedUserAccount && selectedUserAccount?.bankAccount?.accountNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-col space-y-3">
                                <p className="text-sm font-medium leading-[100%] text-black-tertiary">To</p>
                                <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Provider</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">{selectedVendorName}</p>
                                    </div>
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Customer ID</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">{cableTVPurchaseDetails.accountId}</p>
                                    </div>
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Package</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">{selectedProductName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MessageToast
                            type="security"
                            message="Ensure you verify that the recipient is genuine as payments can not be reversed after approval."
                            fullWidth
                        />
                    </div>
                    <div className="flex w-full flex-row items-center justify-center space-x-4">
                        <ButtonComp size="xl" color="grey" ripple="light" buttonType="secondary" func={handleBack}>
                            Back
                        </ButtonComp>
                        <ButtonComp size="xl" color="black" ripple="light" buttonType="primary" func={handleNextPage}>
                            Make Payment
                        </ButtonComp>
                    </div>
                </div>
            )}
        </>
    );
}

export default ReviewPayment;
