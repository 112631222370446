import CurrencyCode from "../../currency-code";
import { DataColumn } from "../Type/DataRow";
import formatNumber from "../../../utils/formatNumber";
import { TextType } from "../Type/TextType";
import Money from "../../money";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function TransactionAmount({ dataColumn, dataType }: Props) {
    return (
        <span data-type={dataType} className="flex items-center gap-x-2">
            {dataColumn.textType === TextType.FINAL_GAIN && (
                <p data-type={dataType} className="text-base font-normal text-success">
                    <Money amount={dataColumn.text as string} positive currency={dataColumn.currency} />
                </p>
            )}
            {dataColumn.textType === TextType.FINAL_LOSS && (
                <span data-type={dataType} className="text-base font-normal capitalize text-black-secondary">
                    <span>- </span>
                    <CurrencyCode currency={dataColumn.currency} />
                    {formatNumber(dataColumn.text?.toString())}
                </span>
            )}
            {dataColumn.textType === TextType.PROCESSING && (
                <span
                    data-type={dataType}
                    className="rounded-lg border border-warning-quin bg-warning-backdrop px-3 py-2 text-xs font-medium capitalize leading-none text-warning"
                >
                    Processing
                </span>
            )}
            {dataColumn.textType === TextType.FAILED && (
                <span
                    data-type={dataType}
                    className="rounded-lg border border-error-quin bg-error-backdrop px-3 py-2 text-xs font-medium capitalize leading-none text-error"
                >
                    Failed
                </span>
            )}
            {dataColumn.textType === TextType.TRANSACTION_STATUS_DECLINED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error"
                >
                    Declined
                </span>
            )}
            {dataColumn.textType === TextType.FAILED_STRIKE && (
                <span
                    data-type={dataType}
                    className="rounded-lg border border-error-quin bg-error-backdrop px-3 py-2 text-xs font-medium capitalize leading-none text-error line-through"
                >
                    Failed
                </span>
            )}
            {dataColumn.textType === TextType.CANCELLED && (
                <span
                    data-type={dataType}
                    className="rounded-lg border border-black-quin bg-grey-backdrop px-3 py-2 text-xs font-medium capitalize leading-none text-black-secondary"
                >
                    Cancelled
                </span>
            )}
            {dataColumn.textType === TextType.BALANCE && (
                <span data-type={dataType} className="text-base font-normal capitalize text-black-secondary">
                    {typeof dataColumn.text === "number" || typeof dataColumn.text === "string" ? (
                        <>
                            {/* <Money amount={dataColumn.text} currency={dataColumn.currency} /> */}
                            <CurrencyCode currency={dataColumn.currency} />
                            {formatNumber(dataColumn.text?.toString())}
                        </>
                    ) : (
                        "-"
                    )}
                </span>
            )}
            {dataColumn.textType === TextType.BALANCE_STRIKE && (
                <span data-type={dataType} className="text-base font-normal capitalize text-black-secondary line-through">
                    <CurrencyCode currency={dataColumn.currency} />
                    {formatNumber(dataColumn.text?.toString())}
                </span>
            )}
            {dataColumn.textType === TextType.TRANSACTION_STATUS_SUCCESS && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success"
                >
                    Success
                </span>
            )}
            {dataColumn.textType === TextType.TRANSACTION_STATUS_FAILED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error"
                >
                    Failed
                </span>
            )}
            {dataColumn.textType === TextType.TRANSACTION_STATUS_PROCESSING && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning"
                >
                    Processing
                </span>
            )}
            {dataColumn.textType === TextType.TRANSACTION_STATUS_PENDING_ACTIVATION && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning"
                >
                    Pending Activation
                </span>
            )}
            {dataColumn.textType === TextType.TRANSACTION_STATUS_PENDING_APPROVAL && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning"
                >
                    Pending Approval
                </span>
            )}
            {dataColumn.textType === TextType.TRANSACTION_STATUS_CANCELLED && (
                <span
                    data-type={dataType}
                    className="border-grey-quin h-6 rounded border bg-grey-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-black-tertiary"
                >
                    Canceled
                </span>
            )}
            {(dataColumn.textType === TextType.TERMINAL_STATUS_ACTIVE || dataColumn.textType === TextType.CARD_STATUS_ACTIVE) && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success"
                >
                    Active
                </span>
            )}
            {dataColumn.textType === TextType.CARD_STATUS_FROZEN && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-info-quin bg-info-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-info"
                >
                    Frozen
                </span>
            )}
            {dataColumn.textType === TextType.CARD_STATUS_UNACTIVATED && (
                <span
                    data-type={dataType}
                    className="bg-black-backdrop h-6 rounded border border-black-quin px-3 py-1 text-xs font-medium capitalize leading-none text-black"
                >
                    Unactivated
                </span>
            )}
            {dataColumn.textType === TextType.CARD_STATUS_EXPIRED && (
                <span
                    data-type={dataType}
                    className="border-grey-quin h-6 rounded border bg-grey-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-grey"
                >
                    Expired
                </span>
            )}
            {dataColumn.textType === TextType.CARD_STATUS_DEACTIVATED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error"
                >
                    Deactivated
                </span>
            )}
            {dataColumn.textType === TextType.TERMINAL_STATUS_REVOKED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error"
                >
                    Revoked
                </span>
            )}
            {(dataColumn.textType === TextType.CARD_REQUEST_STATUS_APPROVED || dataColumn.textType === TextType.TERMINAL_REQUEST_STATUS_APPROVED) && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success"
                >
                    Approved
                </span>
            )}
            {(dataColumn.textType === TextType.TERMINAL_REQUEST_STATUS_DECLINED || dataColumn.textType === TextType.CARD_REQUEST_STATUS_DECLINED) && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error"
                >
                    Declined
                </span>
            )}
            {dataColumn.textType === TextType.TERMINAL_REQUEST_STATUS_DELIVERED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success"
                >
                    Delivered
                </span>
            )}
            {(dataColumn.textType === TextType.TERMINAL_REQUEST_STATUS_REQUESTED ||
                dataColumn.textType === TextType.CARD_REQUEST_STATUS_REQUESTED) && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning"
                >
                    Requested
                </span>
            )}
            {(dataColumn.textType === TextType.TERMINAL_REQUEST_STATUS_SHIPPED || dataColumn.textType === TextType.CARD_REQUEST_STATUS_SHIPPED) && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-info-quin bg-info-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-info"
                >
                    Shipped
                </span>
            )}
            {dataColumn.textType === TextType.TERMINAL_REQUEST_STATUS_TERMINAL_ASSIGNED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success"
                >
                    Terminal Assigned
                </span>
            )}
            {dataColumn.textType === TextType.CARD_REQUEST_STATUS_PRINTED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-info-quin bg-info-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-info"
                >
                    Printed
                </span>
            )}
            {/* API_LIVE = 42, API_PAUSED = 43, API_DEACTIVATED = 44, API_DISABLED_BY_LENCO = 45, */}
            {/* ### */}
            {dataColumn.textType === TextType.API_LIVE && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success"
                >
                    Live
                </span>
            )}
            {dataColumn.textType === TextType.API_PAUSED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning"
                >
                    Paused
                </span>
            )}
            {dataColumn.textType === TextType.API_DEACTIVATED && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error"
                >
                    Deactivated
                </span>
            )}
            {dataColumn.textType === TextType.API_DISABLED_BY_LENCO && (
                <span
                    data-type={dataType}
                    className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error"
                >
                    Disabled by Lenco
                </span>
            )}
        </span>
    );
}
