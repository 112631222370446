import AccountSettingsDetailsHeader from "../AccountSettingsDetailsHeader";
// import { AccountRestrictionTypeDetails, AccountSettingsSectionValue } from "../../../Services/account-settings/account-settings.constants";
import { AccountSettingsSectionValue } from "../../../Services/account-settings/account-settings.constants";
import GoogleIcon from "../../../../../../components/google-icon";
import RecipientAccountItem from "./RecipientAccountItem";
import UserAccount from "../../../../../../models/userAccount";
interface Props {
    account: UserAccount;
    handleIsSendMoneyRuleTray: () => void;
}

function AccountRestrictions({ account, handleIsSendMoneyRuleTray }: Props): JSX.Element {
    const accountLength = account.accountRestrictions.customerAccounts.length;

    return (
        <div className="flex h-full w-full flex-col space-y-9 md:px-8">
            <AccountSettingsDetailsHeader stage={AccountSettingsSectionValue.RESTRICTIONS} />

            <div className="w-full space-y-6">
                <div className="flex flex-col items-start justify-between space-y-2 xl:flex-row xl:items-center xl:space-y-0">
                    <div className="w-64 space-y-1">
                        <p className="text-base font-medium">Inflow Money Rule</p>
                        <p className="text-sm text-black-tertiary">You can receive money from</p>
                    </div>
                    <div className="w-96">
                        <div className="flex items-center justify-between space-x-11 rounded-md border border-black-quin bg-white px-4 py-3">
                            <div className="flex items-center space-x-2">
                                <GoogleIcon icon="check_circle" size="sm" isOutlined />
                                <p className="text-sm font-medium">Receive Money from any Account</p>
                            </div>
                            <div className="relative h-5 w-5 cursor-not-allowed text-black-tertiary text-opacity-disabled">
                                <GoogleIcon icon="border_color" size="sm" isOutlined />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-start justify-between space-y-2 xl:flex-row xl:items-center xl:space-y-0">
                    <div className="h-full w-64 space-y-1 self-start">
                        <p className="text-base font-medium">Send Money Rule</p>
                        <p className="text-sm text-black-tertiary">You can send money to</p>
                    </div>
                    <div className="w-96 space-y-2">
                        <div className="flex items-center justify-between space-x-11 rounded-md border border-black-quin bg-white px-4 py-3">
                            <div className="flex items-center space-x-2">
                                {account.accountRestrictions.canSendMoney && account.accountRestrictions.canSendMoneyToSpecificAccounts && (
                                    <>
                                        <GoogleIcon icon="checklist" size="sm" isOutlined />
                                        <p className="text-sm font-medium">Send Money to Specific Accounts</p>
                                    </>
                                )}
                                {account.accountRestrictions.canSendMoney && account.accountRestrictions.canSendMoneyToAnyAccount && (
                                    <>
                                        <GoogleIcon icon="checklist" size="sm" isOutlined />
                                        <p className="text-sm font-medium">Send Money to Any Account</p>
                                    </>
                                )}
                                {account.accountRestrictions.cannotSendMoney && (
                                    <>
                                        <div className="text-error">
                                            <GoogleIcon icon="block" size="sm" isOutlined />
                                        </div>
                                        <p className="text-sm font-medium text-error">Can Not Send Money</p>
                                    </>
                                )}
                            </div>
                            <div className="flex cursor-pointer text-black-tertiary duration-150 hover:text-blue" onClick={handleIsSendMoneyRuleTray}>
                                <GoogleIcon icon="border_color" size="sm" isOutlined />
                            </div>
                        </div>
                        <div
                            className={
                                account.accountRestrictions.canSendMoneyToSpecificAccounts
                                    ? "w-full space-y-4 rounded-custom border border-grey bg-white p-4"
                                    : "hidden"
                            }
                        >
                            {/*If restriction type is "send to specific recipients", show the recipients*/}
                            {account.accountRestrictions.canSendMoneyToSpecificAccounts &&
                                account.accountRestrictions.cards.map((cards, index) => (
                                    <RecipientAccountItem
                                        key={`recipient-${cards.id}`}
                                        data={cards}
                                        id={cards.id}
                                        canRemove={false}
                                        index={index}
                                        accountLength={accountLength}
                                    />
                                ))}
                            {account.accountRestrictions.canSendMoneyToSpecificAccounts &&
                                account.accountRestrictions.userAccounts.map((_userAccount, index) => (
                                    <RecipientAccountItem
                                        key={`recipient-${_userAccount.id}`}
                                        data={_userAccount}
                                        id={_userAccount.id}
                                        canRemove={false}
                                        index={index}
                                        accountLength={accountLength}
                                    />
                                ))}
                            {account.accountRestrictions.canSendMoneyToSpecificAccounts &&
                                account.accountRestrictions.customerAccounts.map((_customerAccount, index) => (
                                    <RecipientAccountItem
                                        key={`recipient-${_customerAccount.id}`}
                                        data={_customerAccount}
                                        id={_customerAccount.id}
                                        canRemove={false}
                                        index={index}
                                        accountLength={accountLength}
                                    />
                                ))}

                            {/* {isEditMode && <EditAccountRestrictions account={account} handleCancel={() => setIsEditMode(false)} />} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountRestrictions;
