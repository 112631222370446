// import { ReactComponent as CommentListIcon } from "../../../../../../../assets/svg/commentList.svg";
import Transaction from "../../../../../../../models/transaction";
import TransactionComment from "../../../../../../../models/transactionComment";
import { formatDateAndTime } from "../../../../../../../utils/formatDate";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCommentsList({ dataType, transaction }: Props): JSX.Element {
    return (
        <>
            {transaction.comments.length > 0 && (
                <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg bg-grey-backdrop p-4" data-type={dataType}>
                    {/* <span className="text-sm font-medium text-black-secondary" data-type={dataType}>
                        Comments
                    </span> */}
                    {transaction.comments.map((com: TransactionComment, index: number) => {
                        return (
                            <div className="flex w-full flex-col space-y-2 text-xs text-black-tertiary" data-type={dataType} key={index}>
                                <div className="flex items-center justify-between" data-type={dataType}>
                                    <span className="text-xs font-medium text-black-secondary" data-type={dataType}>
                                        {com.createdBy && com.createdBy.name}
                                    </span>
                                    <span className="text-2xs font-normal text-black-tertiary" data-type={dataType}>
                                        {com.createdOn && formatDateAndTime(com.createdOn)}
                                    </span>
                                </div>
                                <div className="text-xs font-normal text-black-tertiary" data-type={dataType}>
                                    {com.text}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}

export default TransactionDetailCommentsList;
