import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../../../components/message-toast/sub/error-toast";
import { FileDrop } from "react-file-drop";
import { IRootState } from "../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../components/spinner";
import { ReactComponent as ReceiptAttached } from "../../../../../../../assets/svg/ReceiptAttached.svg";
import Transaction from "../../../../../../../models/transaction";
import { setIsTriggerUploadFunc } from "../../../../../../../redux/transactions/slice/transactionsSlice";

interface TransactionDetailCardUploadInterface {
    transactionDetail: Transaction;
    dataType: string;
    isBigger: boolean;
    isUploadingReceipt: boolean;
    isPreviewDownloading: boolean;
    isCancel?: boolean;
    handleCancel?: () => void;
    handleUploadAccountingReceipt: (transaction: Transaction, file: File) => Promise<void>;
    handlePreviewAccountingReceipt: (transaction: Transaction) => Promise<void>;

    // triggerClick?: boolean;
}

function TransactionDetailCardUpload({
    transactionDetail,
    dataType,
    isBigger,
    isCancel,
    isUploadingReceipt,
    // isPreviewDownloading,
    handleCancel = undefined,
    handleUploadAccountingReceipt,
    handlePreviewAccountingReceipt,
}: TransactionDetailCardUploadInterface): JSX.Element {
    const dispatch = useDispatch();

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const isTriggerUploadFunc = useSelector((state: IRootState) => state.transactions.isTriggerUploadFunc);

    useEffect(() => {
        if (isTriggerUploadFunc) {
            onTargetClick();
        }
        dispatch(setIsTriggerUploadFunc(false));
    }, [isTriggerUploadFunc]);

    const onSelectFile = useCallback(
        (file: File) => {
            void handleUploadAccountingReceipt(transactionDetail, file);
        },
        [handleUploadAccountingReceipt, transactionDetail]
    );

    const handlePreview = useCallback(() => {
        void handlePreviewAccountingReceipt(transactionDetail);
    }, [handlePreviewAccountingReceipt, transactionDetail]);

    const onTargetClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };

    return (
        <>
            {(transactionDetail.receiptUploaded || transactionDetail.invoiceUploaded) && (
                <div className={"flex w-full flex-col items-start justify-start space-y-4 " + `${isCancel ? "" : ""}`} data-type={dataType}>
                    <div className="w-full text-black-secondary" data-type={dataType}>
                        <div
                            className="flex h-14 w-full flex-row items-center justify-between space-x-2 rounded-lg bg-grey-backdrop p-4 text-black-tertiary"
                            data-type={dataType}
                        >
                            <div className="flex flex-row items-center justify-start" data-type={dataType}>
                                <div data-type={dataType}>
                                    <div className="h-10 w-10 rounded-lg" data-type={dataType}>
                                        <div className="flex h-full w-full items-center justify-center" data-type={dataType}>
                                            <ReceiptAttached />
                                        </div>
                                    </div>
                                </div>
                                {isCancel ? (
                                    <span className="ml-4 text-sm" data-type={dataType}>
                                        Document/Receipt Name
                                    </span>
                                ) : (
                                    <span className="ml-4 text-sm font-medium" data-type={dataType}>
                                        Receipt Attached
                                    </span>
                                )}
                            </div>

                            {isCancel ? (
                                <div data-type={dataType}>
                                    <ButtonComp
                                        buttonType="tertiary"
                                        color="blue"
                                        func={handleCancel}
                                        size="sm"
                                        data-type={dataType}
                                        dataType={dataType}
                                    >
                                        <span className="material-symbols-outlined">close</span>
                                    </ButtonComp>
                                </div>
                            ) : (
                                <div data-type={dataType}>
                                    <ButtonComp
                                        buttonType="tertiary"
                                        color="blue"
                                        func={handlePreview}
                                        size="sm"
                                        data-type={dataType}
                                        dataType={dataType}
                                    >
                                        <span className="text-sm" data-type={dataType}>
                                            Preview
                                        </span>
                                    </ButtonComp>
                                </div>
                            )}
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={(event) => {
                                if (event.target.files && event.target.files.length > 0) {
                                    onSelectFile(event.target.files[0]);
                                }
                            }}
                            accept="image/jpeg, image/png, application/pdf, .pdf"
                        />
                    </div>
                </div>
            )}
            {!(transactionDetail.receiptUploaded || transactionDetail.invoiceUploaded) && (
                <div className={"w-full text-black-secondary " + `${transactionDetail.isFailed ? "mt-2" : ""}`} data-type={dataType}>
                    {isBigger && (
                        <div className="pb-4">
                            <ErrorToast error="File exceeded 5MB" />
                        </div>
                    )}

                    <div
                        className={
                            transactionDetail.receiptUploaded || transactionDetail.invoiceUploaded
                                ? "relative flex w-full cursor-pointer flex-col items-center justify-start space-y-2 rounded-lg border border-dashed hover:bg-blue-senary"
                                : "hidden"
                        }
                    >
                        {isUploadingReceipt && (
                            <div
                                className={`absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white ${
                                    isUploadingReceipt ? "opacity-100" : "pointer-events-none opacity-0"
                                }`}
                            >
                                <LencoSpinner size="sm" />
                            </div>
                        )}
                        <FileDrop
                            className="h-fit"
                            onTargetClick={onTargetClick}
                            onDrop={(files: FileList | null) => {
                                if (files && files.length > 0) {
                                    onSelectFile(files[0]);
                                }
                            }}
                        >
                            <input
                                type="file"
                                ref={fileInputRef}
                                className="hidden"
                                onChange={(event) => {
                                    if (event.target.files && event.target.files.length > 0) {
                                        onSelectFile(event.target.files[0]);
                                    }
                                }}
                                accept="image/jpeg, image/png, application/pdf, .pdf"
                            />
                        </FileDrop>
                    </div>
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardUpload;
