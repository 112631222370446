import React from "react";
import FullPageTray from "../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayHeader from "../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTrayBody from "../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import TestCodeForm from "./TestCodeForm";

interface ISetupTokenTrayProps {
    active: boolean;
    handleToggleTray: (isOpen: boolean) => void;
}

function SetupTokenTray({ active, handleToggleTray }: ISetupTokenTrayProps): JSX.Element {
    const handleConfirm = () => {
        handleToggleTray(false);
    };

    return (
        <FullPageTray active={active} dataType="setup-token-tray" handleReset={handleConfirm} withClickOutside>
            <FullPageTrayHeader
                subTitle="Setup Lenco's token app for faster login and transaction authentication"
                toggler={() => handleToggleTray(false)}
                dataType="setup-token-tray"
            >
                <p className="text-2xl font-medium text-black">Setup Token App</p>
            </FullPageTrayHeader>
            <FullPageTrayBody dataType="setup-token-tray">{active && <TestCodeForm closeFunc={handleConfirm} />}</FullPageTrayBody>
        </FullPageTray>
    );
}

export default SetupTokenTray;
