import React, { useState } from "react";

import CurrencyCode from "../../../../../../components/currency-code";
import LencoButton from "../../../../../../components/button/button";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import { SpecificTerminalStageType } from "../../../../../../redux/terminals/slice/terminalsSlice.types";
import ToggleSwitch from "../../../../../../components/general/ToggleSwitch/ToggleSwitch";
// import UserAccountDropdown from "../../../../payments-new/componentss/DropDowns/UserAccountDropdown";
import formatNumber from "../../../../../../utils/formatNumber";
import { setSpecificTerminalStage } from "../../../../../../redux/terminals/slice/terminalsSlice";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useTerminals from "../../../Hooks/State/useTerminals";

function TerminalSettings(): JSX.Element {
    const dispatch = useDispatch();
    const accounts = useAppSelector((state) => state.init.main?.companyDetails.accounts);
    const merchant = useAppSelector((state) => state.terminals.merchant);
    const selectedTerminal = useAppSelector((state) => state.terminals.specificallySelectedTerminal);
    const canAcceptTransfer = useAppSelector((state) => state.terminals.merchant?.canAcceptTransfer);

    const [selectedAccountId, setSelectedAccountId] = useState(selectedTerminal?.settlementAccount?.id);

    const { handleUpdateTerminalSettings, isUpdateTerminalSettingsLoading } = useTerminals();

    const handleCancel = () => {
        dispatch(setSpecificTerminalStage(SpecificTerminalStageType.TERMINAL_TRANSACTIONS));
    };

    return (
        <>
            <div className="flex w-full justify-between">
                <div className="w-full max-w-2xl space-y-6 pb-6">
                    <div className="flex w-full flex-col items-stretch justify-between space-y-4 rounded-lg bg-white p-5 sm:space-y-0 xs:flex-row">
                        <div className="w-52 space-y-1">
                            <h3 className="text-base font-medium text-black">Settlement Type</h3>
                            <p className="text-sm text-black-secondary">{merchant?.merchantSettlementType || "-"}</p>
                        </div>
                        <div className="hidden flex-grow bg-black-quat xs:block" style={{ maxWidth: "1px" }}></div>
                        <div className="w-52 space-y-1">
                            <h3 className="text-base font-medium text-black">Processing fee</h3>
                            <p className="text-sm text-black-secondary">
                                Fee: {merchant?.feeType?.feePercentage || "0"}% capped at <CurrencyCode />
                                {formatNumber(merchant?.feeType?.feeCap)}
                            </p>
                        </div>
                    </div>

                    <div className="flex w-full flex-col items-stretch justify-between space-y-4 rounded-lg bg-white p-5">
                        <div className="flex w-full flex-col items-start justify-between space-x-0 space-y-4 border-b-0.5 border-grey-secondary pb-4 xs:flex-row xs:items-center xs:space-x-4 xs:space-y-0">
                            <div className="w-80">
                                <p className="text-base font-medium text-black-secondary">Settlement Account</p>
                                <p className="text-sm text-black-tertiary">Choose an account for settlement</p>
                            </div>
                            <div className="relative w-full max-w-15">
                                <NewUserAccountDropdown
                                    value={selectedAccountId || selectedTerminal?.settlementAccount?.id || ""}
                                    options={accounts || null}
                                    onClick={(accountId) => {
                                        setSelectedAccountId(accountId);
                                    }}
                                    isDisabled={accounts?.length === 1}
                                    size="md"
                                    initiatorCanSelect
                                    isHideInitials
                                />
                            </div>
                        </div>

                        <h3 className="text-base font-medium text-black">Collection Channels</h3>

                        <div className="flex w-full flex-col items-start justify-between space-x-0 space-y-4 xs:flex-row xs:items-center xs:space-x-4 xs:space-y-0">
                            <div className="w-80">
                                <p className="text-base font-medium text-black-secondary">Card</p>
                                <p className="text-sm text-black-tertiary">Accept all card payments</p>
                            </div>
                            <ToggleSwitch isActive={true} isDisable onToggle={() => undefined} />
                        </div>
                        <div className="flex w-full flex-col items-start justify-between space-x-0 space-y-4 xs:flex-row xs:items-center xs:space-x-4 xs:space-y-0">
                            <div className="w-80">
                                <p className="text-base font-medium text-black-secondary">Bank Transfer</p>
                                <p className="text-sm text-black-tertiary">Accept bank transfers on your POS</p>
                            </div>
                            <ToggleSwitch isDisable isActive={!!canAcceptTransfer} onToggle={() => undefined} />
                        </div>
                    </div>

                    <div className="flex w-full items-center justify-center space-x-3 pt-4">
                        <LencoButton type="button" variant="bordered" onClick={handleCancel}>
                            {<span className="font-medium">Cancel</span>}
                        </LencoButton>
                        <LencoButton
                            type="button"
                            variant="solid"
                            isLoading={isUpdateTerminalSettingsLoading}
                            isDisabled={selectedTerminal?.settlementAccount?.id === selectedAccountId}
                            onClick={() => {
                                selectedTerminal &&
                                    void handleUpdateTerminalSettings({
                                        settlementAccountId: selectedAccountId,
                                        serialNumber: selectedTerminal?.serialNumber,
                                    });
                            }}
                        >
                            {<span className="px-3 font-medium">Save Changes</span>}
                        </LencoButton>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TerminalSettings;
