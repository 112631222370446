import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BillPaymentStage } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import { IRootState } from "../../../../../redux/rootReducer";
import MakePayment from "./DataPurchase/MakePayment";
import PaymentDetails from "./DataPurchase/PaymentDetails";
import ProcessingPayment from "./DataPurchase/ProcessingPayment";
import ReviewPayment from "./DataPurchase/ReviewPayment";
import { resetAllBillPaymentData } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { resetAllDataPurchaseData } from "../../../../../redux/payments/dataPurchase/slice/dataPurchaseSlice";

function DataPurchase(): JSX.Element {
    const dispatch = useDispatch();
    const billPaymentStage = useSelector((state: IRootState) => state.billPayment.billPaymentStage);

    useEffect(() => {
        return () => {
            dispatch(resetAllBillPaymentData());
            dispatch(resetAllDataPurchaseData());
        };
    }, []);

    return (
        <>
            {billPaymentStage === BillPaymentStage.PAYMENT_DETAILS && <PaymentDetails />}
            {billPaymentStage === BillPaymentStage.REVIEW_PAYMENT && <ReviewPayment />}
            {billPaymentStage === BillPaymentStage.MAKE_PAYMENT && <MakePayment />}
            {billPaymentStage === BillPaymentStage.PROCESS_PAYMENT && <ProcessingPayment />}
        </>
    );
}

export default DataPurchase;
