import React, { useEffect, useState } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import UserAccount from "../../../../../models/userAccount";
import getAvatarColorClass from "../../../../../helpers/avatarColors";
import getBankName from "../../../../../helpers/getBankName";

interface ActivateAccountListProps {
    ShowAboutModal: boolean;
    SetShowAboutModal: () => void;
    accountsToActivate: (UserAccount | undefined)[] | undefined;
    onClick: (e: UserAccount) => void;
    isSingleAccount: boolean;
}
function ActivateAccountList(props: ActivateAccountListProps): JSX.Element {
    const { ShowAboutModal, SetShowAboutModal, accountsToActivate, onClick, isSingleAccount } = props;
    const [data, setData] = useState<(UserAccount | undefined)[]>();
    useEffect(() => {
        if (!accountsToActivate) return;
        setData(accountsToActivate);
    }, [accountsToActivate]);
    return (
        <>
            <Modal size="fit" active={ShowAboutModal} toggler={SetShowAboutModal}>
                <ModalHeader onClose={SetShowAboutModal}>Activate Account</ModalHeader>
                <ModalBody>
                    <div className="mx-auto flex flex-col items-center justify-center text-sm font-normal">
                        <div className="flex w-full flex-col items-center space-y-6 text-center">
                            <span className="text-left text-base font-normal">
                                {isSingleAccount ? "Activate this account for payout" : "Activate these accounts for payout"}
                            </span>
                            <div className="flex w-full flex-col items-center justify-start space-y-4">
                                {isSingleAccount
                                    ? data &&
                                      data.map((el, index) => (
                                          <div
                                              className="flex min-h-14 w-full items-center justify-start space-x-4 rounded-lg bg-grey-backdrop p-4"
                                              key={index}
                                          >
                                              <div>
                                                  <div
                                                      className={`flex h-8 w-8 items-center justify-center rounded-full ${getAvatarColorClass(
                                                          el?.id || ""
                                                      )}`}
                                                  >
                                                      {el?.bankAccount && el.bankAccount.accountName.slice(0, 1)}
                                                  </div>
                                              </div>
                                              <div className="flex h-full flex-col items-start justify-center">
                                                  <span className="max-w-sm overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-sm">
                                                      {el?.bankAccount && el.bankAccount.accountName}
                                                  </span>
                                                  <div className="flex flex-row items-center justify-start space-x-2 text-xs text-black-tertiary">
                                                      <span>{el?.bankAccount && el.bankAccount.accountNumber}</span>
                                                      <div>
                                                          <div className="h-1 w-1 rounded-full bg-black-tertiary"></div>
                                                      </div>
                                                      <div className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-black-tertiary">
                                                          {getBankName((el?.bankAccount && el.bankAccount)?.bankCode)}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                    : data &&
                                      data.map((el, index) => (
                                          <div
                                              className="flex min-h-14 w-full items-center justify-start space-x-20 rounded-lg bg-grey-backdrop p-4"
                                              key={index}
                                          >
                                              <div className="flex min-h-14 w-full items-center justify-start space-x-4">
                                                  <div>
                                                      <div
                                                          className={`flex h-8 w-8 items-center justify-center rounded-full ${getAvatarColorClass(
                                                              el?.id || ""
                                                          )}`}
                                                      >
                                                          {el?.bankAccount && el.bankAccount.accountName.slice(0, 1)}
                                                      </div>
                                                  </div>
                                                  <div className="flex h-full flex-col items-start justify-center">
                                                      <span className="max-w-sm overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-sm">
                                                          {el?.bankAccount && el.bankAccount.accountName}
                                                      </span>
                                                      <div className="flex flex-row items-center justify-start space-x-2 text-xs text-black-tertiary">
                                                          <span>{el?.bankAccount && el.bankAccount.accountNumber}</span>
                                                          <div>
                                                              <div className="h-1 w-1 rounded-full bg-black-tertiary"></div>
                                                          </div>
                                                          <div className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-black-tertiary">
                                                              {getBankName(el?.bankAccount?.bankCode)}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                              <span className="cursor-pointer text-blue" onClick={() => onClick(el as UserAccount)}>
                                                  Activate
                                              </span>
                                          </div>
                                      ))}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                {isSingleAccount && (
                    <ModalFooter>
                        <ButtonComp
                            type="submit"
                            color="blue"
                            ripple="light"
                            buttonType="primary"
                            func={() => onClick((data && data[0]) as UserAccount)}
                        >
                            <span className="p-2">Activate My Account</span>
                        </ButtonComp>
                    </ModalFooter>
                )}
            </Modal>
        </>
    );
}

export default ActivateAccountList;
