export default function getInitials(name: string) {
    const formattedName = name.toLocaleLowerCase().includes("/")
        ? name.toLocaleLowerCase().split("/")
        : name.toLocaleLowerCase().includes("-")
          ? name.toLocaleLowerCase().split("-")
          : name.toLocaleLowerCase().includes("lenco(")
            ? name.toLocaleLowerCase().split("lenco(")
            : ["", name];
    const purifiedName = formattedName[formattedName.length - 1].trim();
    const spaceIndex = (purifiedName || "").indexOf(" ");

    return spaceIndex === -1
        ? (purifiedName || "").slice(0, 2)
        : `${(purifiedName || "").slice(0, 1)}${(purifiedName || "").slice(spaceIndex + 1, spaceIndex + 2)}`;
}
