import AvatarDetails from "../../avatar-details";
import Card from "../../../models/card";
import CustomerAccount from "../../../models/customerAccount";
import Money from "../../money";
import NewDropdownItem from "../../new-dropdown-item";
import UserAccount from "../../../models/userAccount";
import getBankName from "../../../helpers/getBankName";
import getInitials from "../../../helpers/get-initials";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import titleCase from "../../../hooks/titleCase";

interface Props {
    data: UserAccount | CustomerAccount | Card;
    message?: string;
    isDisabled?: boolean;
    showBalance?: boolean;
    cardBalance?: number;

    onClick: () => void;
}

function AccountNumberInputItem(props: Props): JSX.Element {
    const accountName = titleCase(
        (props.data instanceof UserAccount && props.data.lencoNameMin) ||
            (props.data instanceof CustomerAccount && props.data.singleName) ||
            (props.data instanceof Card && props.data.name) ||
            ""
    );

    return (
        <>
            <NewDropdownItem onClick={props.onClick} size="2xl" isDisabled={props.isDisabled}>
                <div className="flex w-full items-center justify-start space-x-2">
                    <div className="w-max">
                        <AvatarDetails initials={getInitials(accountName)} />
                    </div>
                    <div className="flex flex-col items-start justify-center">
                        <span className="text-sm">{accountName}</span>
                        {props.message ? (
                            <div className="flex flex-row items-center justify-start space-x-2 text-xs">{props.message}</div>
                        ) : (
                            <>
                                {props.showBalance &&
                                    props.data instanceof UserAccount &&
                                    !isNullOrUndefined(props.data instanceof UserAccount && props.data.balance) && (
                                        <div className="flex flex-row items-center justify-start space-x-2 text-xs" data-type="dropdown">
                                            <Money amount={(props.data instanceof UserAccount && props.data.balance) || 0} />
                                        </div>
                                    )}
                                {!props.showBalance && (props.data instanceof UserAccount || props.data instanceof CustomerAccount) && (
                                    <div className="flex flex-row items-center justify-start space-x-2 text-xs">
                                        <span>{props.data.bankAccount && props.data.bankAccount.accountNumber} </span>
                                        <div>
                                            <div className="h-1 w-1 rounded-full bg-black-tertiary" />
                                        </div>
                                        <div>{getBankName(props.data.bankAccount?.bankCode)}</div>
                                    </div>
                                )}
                                {props.data instanceof Card && (
                                    <>
                                        {!!props.cardBalance && (
                                            <div className="text-xs">
                                                <Money amount={props.cardBalance} />
                                            </div>
                                        )}
                                        {!props.cardBalance && (
                                            <div className="text-xs">
                                                <span>••••{props.data.maskedPan.slice(-4)}</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </NewDropdownItem>
        </>
    );
}

export default AccountNumberInputItem;
