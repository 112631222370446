import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

class BillPurchaseMobileData {
    [immerable] = true;

    constructor(
        public phone: string,
        public parsedPhone: string,
        public contactName: string
    ) {}

    static create(obj: GenericObject): BillPurchaseMobileData {
        return new BillPurchaseMobileData(Parsers.string(obj.phone), Parsers.string(obj.parsedPhone), Parsers.string(obj.contactName));
    }
}

class BillPurchaseAccountData {
    [immerable] = true;

    constructor(
        public accountId: string,
        public customerName: string
    ) {}

    static create(obj: GenericObject): BillPurchaseAccountData {
        return new BillPurchaseAccountData(Parsers.string(obj.accountId), Parsers.string(obj.customerName));
    }
}

export default class BillPurchaseExtraData {
    [immerable] = true;

    constructor(
        public mobile: BillPurchaseMobileData,
        public account: BillPurchaseAccountData
    ) {}

    static create(obj: GenericObject): BillPurchaseExtraData {
        return new BillPurchaseExtraData(
            Parsers.classObjectNonNullable(obj.mobile, BillPurchaseMobileData),
            Parsers.classObjectNonNullable(obj.account, BillPurchaseAccountData)
        );
    }
}
