import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AirtimePurchaseState } from "./airtimePurchaseSlice.types";
import { BillPurchaseInitResponse } from "../../../../modules/dashboard/payments-new/services/billPayment/billPaymentApi.types";
import BillVendor from "../../../../models/billVendor";
import UserAccount from "../../../../models/userAccount";

const initialState: AirtimePurchaseState = {
    airtimePurchaseDetails: {
        debitAccountId: "",
        productId: "",
        accountId: "",
        phone: "",
        amount: "",
        otp: "",
        bioAuth: "",
        contactName: "",
    },
    selectedUserAccount: null,
    selectedVendorName: null,
    selectedVendorRange: null,
    airtimePurchaseResponse: null,
};

/* Since the accountId and phone number require the same data no need for a set phone number reducer */

export const airtimePurchaseSlice = createSlice({
    name: "airtimePurchase",
    initialState,
    reducers: {
        setAirtimePurchaseDebitAccountId: (state: AirtimePurchaseState, action: PayloadAction<string>) => {
            state.airtimePurchaseDetails.debitAccountId = action.payload;
        },
        setAirtimePurchaseSelectedUserAccount: (state: AirtimePurchaseState, action: PayloadAction<UserAccount>) => {
            state.selectedUserAccount = action.payload;
        },
        setAirtimePurchaseAccountId: (state: AirtimePurchaseState, action: PayloadAction<string>) => {
            state.airtimePurchaseDetails.accountId = action.payload;
            state.airtimePurchaseDetails.phone = action.payload;
        },
        setAirtimePurchaseProductId: (state: AirtimePurchaseState, action: PayloadAction<BillVendor>) => {
            state.airtimePurchaseDetails.productId = action.payload.products[0].id;
            state.selectedVendorName = action.payload.name;
            state.selectedVendorRange = action.payload.products[0].rangeAmount;
        },
        setAirtimePurchaseAmount: (state: AirtimePurchaseState, action: PayloadAction<string>) => {
            state.airtimePurchaseDetails.amount = action.payload;
        },
        setAirtimePurchaseOtp: (state: AirtimePurchaseState, action: PayloadAction<string>) => {
            state.airtimePurchaseDetails.otp = action.payload;
        },
        setAirtimePurchaseResponse: (state: AirtimePurchaseState, action: PayloadAction<BillPurchaseInitResponse>) => {
            state.airtimePurchaseResponse = action.payload;
        },
        resetAllAirtimePurchaseData: (state: AirtimePurchaseState) => {
            state.selectedUserAccount = null;
            state.selectedVendorName = null;
            state.airtimePurchaseResponse = null;
            state.selectedVendorRange = null;
            state.airtimePurchaseDetails = {
                debitAccountId: "",
                productId: "",
                accountId: "",
                phone: "",
                amount: "",
                otp: "",
                bioAuth: "",
                contactName: "",
            };
        },
    },
});

export const {
    setAirtimePurchaseOtp,
    setAirtimePurchaseAmount,
    setAirtimePurchaseResponse,
    resetAllAirtimePurchaseData,
    setAirtimePurchaseAccountId,
    setAirtimePurchaseProductId,
    setAirtimePurchaseDebitAccountId,
    setAirtimePurchaseSelectedUserAccount,
} = airtimePurchaseSlice.actions;

export default airtimePurchaseSlice.reducer;
