import BillPurchase from "../../../../../../../models/billPurchase";
import BillPurchaseStatus from "../../../Status";
import Money from "../../../../../../../components/money";
interface Props {
    data: BillPurchase;
    dataType: string;
}

export default function BillPurchaseAmountCard({ data, dataType }: Props): JSX.Element {
    return (
        <div className="flex w-full flex-col space-y-4 rounded-lg border-grey-secondary bg-grey-backdrop p-4">
            <div className="flex w-full flex-col items-center justify-center" data-type={dataType}>
                <div className="flex w-full items-center justify-between" data-type={dataType}>
                    <p className="mt-1 text-left text-2xl font-medium leading-none text-black-secondary" data-type={dataType}>
                        <Money amount={data.amount} currency={data.debitAccount.bankAccountCurrency} />
                    </p>

                    <BillPurchaseStatus data={data} />
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center" data-type={dataType}>
                <div className="flex w-full items-start justify-between" data-type={dataType}>
                    <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                        Debit Account
                    </h1>
                    <div className="text-right">
                        <div className="text-sm font-medium text-black-secondary">{data.debitAccount.accountName}</div>
                        <div className="text-xs font-normal text-black-tertiary">{data.debitAccount.accountNumber}</div>
                        <div className="text-xs font-normal text-black-tertiary">{data.debitAccount.bankAccount?.accountName}</div>
                    </div>
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center" data-type={dataType}>
                <div className="flex w-full items-start justify-between" data-type={dataType}>
                    <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                        Vendor
                    </h1>
                    <div className="text-right">
                        <div className="text-sm font-medium text-black-secondary">{data.product.vendor?.name}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
