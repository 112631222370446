import { CardSpendLimitType } from "../Type/CardSpendLimitType";
import CurrencyCode from "../../currency-code";
import React from "react";
import formatNumber from "../../../utils/formatNumber";
import { useNumberToMoney } from "../../../hooks/useNumberToMoney";

interface ICardSpendLimitBar {
    amountSpent: number;
    spendingLimit: number;
    interval: CardSpendLimitType;
}

export default function CardSpendLimitBar({ amountSpent, spendingLimit, interval }: ICardSpendLimitBar): JSX.Element {
    const rawPercent = ((amountSpent || 0) / (spendingLimit || 0)) * 100;
    const percent = rawPercent > 100 ? 100 : rawPercent;
    const currentInterval =
        interval === CardSpendLimitType.DAILY_ACCUMULATIVE
            ? "Day"
            : interval === CardSpendLimitType.WEEKLY_ACCUMULATIVE
              ? "Week"
              : interval === CardSpendLimitType.MONTHLY_ACCUMULATIVE
                ? "Month"
                : "Once";

    return (
        <>
            {!amountSpent && !spendingLimit ? (
                <p className="m-0 text-sm leading-none text-black-secondary" data-type={"card"}>
                    <CurrencyCode data-type="card" />
                    {amountSpent || 0}/<CurrencyCode data-type="card" />
                    {spendingLimit || 0}
                </p>
            ) : null}
            {spendingLimit ? (
                <p className="m-0 text-sm leading-none text-black-secondary" data-type={"card"}>
                    <CurrencyCode data-type="card" />
                    {formatNumber(useNumberToMoney(amountSpent))}/<CurrencyCode data-type="card" />
                    {formatNumber(useNumberToMoney(spendingLimit))}
                </p>
            ) : null}
            <div className="mr-1 mt-2 h-1.5 w-full min-w-8 rounded-sm bg-grey-secondary" data-type="card">
                <div
                    className={`h-1.5 rounded-sm ${percent >= 20 ? "bg-blue" : "bg-black-quat"}`}
                    style={{ width: percent ? `${percent}%` : "1%" }}
                    data-type="card"
                ></div>
            </div>
            {!amountSpent ? (
                <span className="text-xs leading-none text-black-tertiary" data-type={"card"}>
                    {`${formatNumber(amountSpent, false)}%`} of {currentInterval} Limit
                </span>
            ) : null}
            {amountSpent ? (
                <span className="text-xs leading-none text-black-tertiary" data-type={"card"}>
                    {`${formatNumber(percent, false)}% of ${currentInterval} Limit`}
                </span>
            ) : null}
        </>
    );
}
