import { useDispatch } from "react-redux";
import Table from "../../../../../components/Table";
import { TableHead } from "../../../../../components/Table/Type/HeadRow";
import { SubTextType } from "../../../../../components/Table/Type/SubTextType";
import { TableColumnType } from "../../../../../components/Table/Type/TableColumnType";
import { TextType } from "../../../../../components/Table/Type/TextType";
import { useAppSelector } from "../../../../../redux/hooks";
import AllTerminalsSummary from "../../Elements/Card/TerminalSummary/AllTerminalsSummary";
import { setAllTerminalStageToSpecificTerminal } from "../../../../../redux/terminals/slice/terminalsSlice";
import { useCallback } from "react";
import Terminal from "../../../../../models/terminal";
import useTerminals from "../../Hooks/State/useTerminals";
import { GenericObject } from "../../../../../helpers/types";

const allTerminalHeader: TableHead = [
    { text: "Terminal Name", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "Settlement Account", pos: 2, headType: TableColumnType.TEXT },
    { text: "Transaction Volume", pos: 3, headType: TableColumnType.AMOUNT },
    { text: "Status", pos: 4, headType: TableColumnType.STATUS },
];

function AllTerminals(): JSX.Element {
    const dispatch = useDispatch();
    const terminals = useAppSelector((state) => state.terminals.terminals);
    const terminalListTotal = useAppSelector((state) => state.terminals.terminalListTotal);
    const isPaginationLoading = useAppSelector((state) => state.terminals.isTerminalPaginationLoading);
    const terminalListGroupSize = useAppSelector((state) => state.terminals.terminalListGroupSize);
    const terminalPaginationOffset = useAppSelector((state) => state.terminals.terminalPaginationOffset);

    const { handleTerminalList, isTerminalListLoading } = useTerminals();

    const onSelectTerminalRow = useCallback((selectedTerminal: Terminal) => {
        dispatch(setAllTerminalStageToSpecificTerminal(selectedTerminal));
    }, []);

    const handleSearch = useCallback(async (data: GenericObject) => {
        await handleTerminalList({ query: (data?.search as string) || "" });
    }, []);

    return (
        <>
            <AllTerminalsSummary />
            <div className="relative flex h-full w-full flex-col items-start justify-start">
                <Table
                    addSearch
                    handleSearch={handleSearch}
                    heads={allTerminalHeader}
                    isPaginateLoading={isPaginationLoading}
                    groupSize={terminalListGroupSize}
                    offset={terminalPaginationOffset}
                    total={terminalListTotal}
                    isLoading={!!isTerminalListLoading}
                    rows={terminals?.map((terminal) => ({
                        onRecordClick: () => onSelectTerminalRow(terminal),
                        record: [
                            { key: "Terminal Name", text: terminal.name || "-", subText: terminal.serialNumber, subTextType: SubTextType.STRING },
                            {
                                key: "Settlement Account",
                                text: terminal.settlementAccount?.accountName,
                            },
                            {
                                key: "Transaction Volume",
                                text: terminal?.stats?.total?.volume || "",
                                textType: TextType.BALANCE,
                            },
                            { key: "Status", text: terminal.status, textType: terminal.getTableStatusType() },
                        ],
                    }))}
                    paginateFunction={(_, pageOffset) => handleTerminalList({ offset: pageOffset })}
                />
            </div>
        </>
    );
}

export default AllTerminals;
