import {
    resetTempSelectedZambiaApiAccessKey,
    setIsZambiaRegenerateApiAccessKeyModalOpen,
    setUpdateZambiaApiAccessKey,
    setZambiaApiAccessKeySecretKey,
} from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useCallback, useState } from "react";

import { RegenerateApiAccessKeysRequest } from "../../Services/ApiAccessKeys/apiAccessKeysApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { regenerateApiAccessKey } from "../../Services/ApiAccessKeys/apiAccessKeysApi";
import { useDispatch } from "react-redux";

export interface UseRegenerateZambiaApiAccessKeysInterface {
    isRegenerateZambiaApiAccessKeyLoading: boolean;
    regenerateZambiaApiAccessKeyErrorMessage: string;
    handleRegenerateZambiaApiAccessKey: (_data: RegenerateApiAccessKeysRequest) => void;
}

function useRegenerateZambiaApiAccessKey(): UseRegenerateZambiaApiAccessKeysInterface {
    const dispatch = useDispatch();
    const [isRegenerateZambiaApiAccessKeyLoading, setIsRegenerateZambiaApiAccessKeyLoading] = useState(false);
    const [regenerateZambiaApiAccessKeyErrorMessage, setRegenerateZambiaApiAccessKeyErrorMessage] = useState<string>("");

    const handleRegenerateZambiaApiAccessKey = useCallback(
        async (_data: RegenerateApiAccessKeysRequest) => {
            try {
                setIsRegenerateZambiaApiAccessKeyLoading(true);
                setRegenerateZambiaApiAccessKeyErrorMessage("");
                const res = await regenerateApiAccessKey(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(setZambiaApiAccessKeySecretKey(res.secretKey));
                dispatch(resetTempSelectedZambiaApiAccessKey());
                dispatch(setIsZambiaRegenerateApiAccessKeyModalOpen(false));
                dispatch(messageTrue("API Access Key Regenerated Successfully."));
                //Todo ===> set to settings stage
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRegenerateZambiaApiAccessKeyErrorMessage(errorMessage);
            } finally {
                setIsRegenerateZambiaApiAccessKeyLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isRegenerateZambiaApiAccessKeyLoading,
        regenerateZambiaApiAccessKeyErrorMessage,
        handleRegenerateZambiaApiAccessKey,
    };
}

export default useRegenerateZambiaApiAccessKey;
