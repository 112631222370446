import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { VerifySignUpInviteRequest, VerifySignUpInviteResponse } from "../services/sign-up-api.types";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
// import { useDispatch } from "react-redux";
import { verifyInvite } from "../services/sign-up-api";

function useVerifySignUpInvite(): UseMutationResult<VerifySignUpInviteResponse, AxiosError | null, VerifySignUpInviteRequest, unknown> {
    // const dispatch = useDispatch();
    const verifySignUpInvite = useMutation({
        mutationFn: async (_data: VerifySignUpInviteRequest) => {
            const res = await verifyInvite(_data);
            return res;
        },
        onSuccess(data: VerifySignUpInviteResponse) {
            console.log(data);
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return verifySignUpInvite;
}

export default useVerifySignUpInvite;
