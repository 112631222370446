import React from "react";
import BaseSkeleton from "./skeleton";

interface Props {
    totalColumns: number;
}

export default function TableSkeleton({ totalColumns }: Props) {
    return (
        <>
            {new Array(3).fill(null).map((row, idy) => (
                <tr key={idy} className="h-14">
                    {new Array(totalColumns).fill(null).map((c, idx) => (
                        <td key={idx} className="w-22.5% p-6">
                            <BaseSkeleton text />
                        </td>
                    ))}
                </tr>
            ))}
        </>
    );
}
