import {
    BusinessNameDocuments,
    CompanyDocumentFieldTexts,
    CompanyDocumentTypes,
    IncorporationDocumentType,
    IncorporationDocumentTypeText,
    LLCDocuments,
    NGODocuments,
    getCompanyDocumentHints,
} from "../../../../../services/zambia-application/registered/new/document.types";
import { HintType, ListStyle } from "../../../../element/hint/hint.constant";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../element/ApplicationHeader";
import BusinessDocumentsPreview from "./components/BusinessDocumentsPreview";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
// import {CompanyHint} from "../../../../../services/zambia-application/registered/new/company.types";
import Hint from "../../../../element/hint";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../components/spinner";
import ProofOfResidenceAccordion from "./components/ProofOfResidenceAccordion";
import { Routes } from "../../../../../../../../routes/routes.constants";
import ShareHoldersAccordion from "./components/ShareHoldersAccordion";
import UploadDocumentAccordion from "./components/UploadDocumentAccordion";
import useBusinessDocumentsInit from "../../../../../Hooks/zambia-application/registered-business/business-documents/useBusinessDocumentsInit";
import { useSelector } from "react-redux";

type LocationState = {
    from?: string;
};

function RegisteredBusinessDocuments(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { documentDetailsError, isDocumentDetailsLoading, handleReset, handleDocumentDetailsInit } = useBusinessDocumentsInit();

    const businessDocs = useSelector((state: IRootState) => state.zambiaApplication.businessDocuments?.incorporationDocuments);
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const proofOfResidence = useSelector((state: IRootState) => state.zambiaApplication.businessDocuments?.proofOfResidence);
    const businessDocuments = useSelector((state: IRootState) => state.zambiaApplication.businessDocuments?.application);

    const isCreator = accountOpening?.isCreator;
    const isRegistered = accountOpening?.application.isRegistered;

    const [show, setShow] = useState({
        [CompanyDocumentTypes.ASSOCIATION_CONSTITUTION]: false,
        [CompanyDocumentTypes.FORM_3]: false,
        [CompanyDocumentTypes.INCORPORATION_CERTIFICATE]: false,
        [CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION]: false,
        [CompanyDocumentTypes.OPERATING_LICENSE]: false,
        [CompanyDocumentTypes.PACRA_PRINT_OUT]: false,
        [CompanyDocumentTypes.PROOF_OF_RESIDENCE]: false,
        [CompanyDocumentTypes.SHAREHOLDERS_INFORMATION]: false,
    });
    const completedDocs = [
        ...(businessDocs ? Object.values(businessDocs.map((_docs) => (_docs.type ? IncorporationDocumentTypeText[_docs.type] : ""))) : []),
        ...(proofOfResidence ? (proofOfResidence.uploadId ? [CompanyDocumentTypes.PROOF_OF_RESIDENCE] : []) : []),
    ];
    const listOfDocuments = [
        ...(!isRegistered
            ? [CompanyDocumentTypes.PROOF_OF_RESIDENCE]
            : businessDocuments
              ? businessDocuments.isLLC
                  ? LLCDocuments
                  : businessDocuments.isNGO
                    ? NGODocuments
                    : businessDocuments.isBusinessName
                      ? BusinessNameDocuments
                      : []
              : []),
    ];
    const uncompletedDocuments = listOfDocuments.filter((_doc) => !completedDocs.some((_completedDoc) => _completedDoc === _doc));

    useLayoutEffect(() => {
        void handleDocumentDetailsInit();
        return () => {
            handleReset();
        };
    }, []);

    useEffect(() => {
        toggle(uncompletedDocuments[0]);
    }, []);

    const toggle = useCallback((key: CompanyDocumentTypes) => {
        setShow((prev) => {
            if (prev[key]) {
                return { ...prev, [key]: !prev[key] };
            } else {
                return {
                    [CompanyDocumentTypes.ASSOCIATION_CONSTITUTION]: false,
                    [CompanyDocumentTypes.FORM_3]: false,
                    [CompanyDocumentTypes.INCORPORATION_CERTIFICATE]: false,
                    [CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION]: false,
                    [CompanyDocumentTypes.OPERATING_LICENSE]: false,
                    [CompanyDocumentTypes.PACRA_PRINT_OUT]: false,
                    [CompanyDocumentTypes.PROOF_OF_RESIDENCE]: false,
                    [CompanyDocumentTypes.SHAREHOLDERS_INFORMATION]: false,
                    [key]: true,
                };
            }
        });
    }, []);

    const handleBack = useCallback(() => {
        navigate(
            locationState?.from ||
                (isRegistered
                    ? isCreator
                        ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH
                        : Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT
                    : Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS)
        );
    }, [location, isCreator]);

    return (
        <>
            {isDocumentDetailsLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {!isDocumentDetailsLoading && documentDetailsError && (
                <div className="flex h-full w-full items-start justify-center">
                    <ErrorToast error={documentDetailsError} />
                </div>
            )}
            {!isDocumentDetailsLoading && accountOpening && businessDocuments && (
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                        <ApplicationHeader
                            header="Upload Company Document"
                            subTitle="The details in the company document must match all previously provided company information."
                            progress={accountOpening.progress.documentsCompletedProgress()}
                        >
                            {!isCreator && (
                                <BusinessDocumentsPreview
                                    data={businessDocs || []}
                                    isLLC={businessDocuments.isLLC}
                                    isNGO={businessDocuments.isNGO}
                                    isBusinessName={businessDocuments.isBusinessName}
                                    proofOfResidenceId={proofOfResidence?.uploadId || ""}
                                    isCanNotPreview
                                />
                            )}
                            {isCreator && (
                                <div className="flex w-full flex-col space-y-4">
                                    {isRegistered ? (
                                        <>
                                            {businessDocuments.isBusinessName && (
                                                <>
                                                    <UploadDocumentAccordion
                                                        active={show[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                                        toggler={() => toggle(CompanyDocumentTypes.INCORPORATION_CERTIFICATE)}
                                                        type={CompanyDocumentTypes.INCORPORATION_CERTIFICATE}
                                                        typeId={IncorporationDocumentType.INCORPORATION_CERTIFICATE}
                                                        title={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                                        description={""}
                                                        fileId={
                                                            businessDocs?.find(
                                                                (_doc) => _doc.type === IncorporationDocumentType.INCORPORATION_CERTIFICATE
                                                            )?.uploadId || null
                                                        }
                                                    />
                                                    <ProofOfResidenceAccordion
                                                        active={show[CompanyDocumentTypes.PROOF_OF_RESIDENCE]}
                                                        toggler={() => toggle(CompanyDocumentTypes.PROOF_OF_RESIDENCE)}
                                                        title={CompanyDocumentFieldTexts[CompanyDocumentTypes.PROOF_OF_RESIDENCE]}
                                                        description={""}
                                                        fileId={proofOfResidence?.uploadId || null}
                                                    />
                                                </>
                                            )}
                                            {businessDocuments.isNGO && (
                                                <>
                                                    <UploadDocumentAccordion
                                                        active={show[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                                        toggler={() => toggle(CompanyDocumentTypes.INCORPORATION_CERTIFICATE)}
                                                        type={CompanyDocumentTypes.INCORPORATION_CERTIFICATE}
                                                        title={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                                        description={""}
                                                        typeId={IncorporationDocumentType.INCORPORATION_CERTIFICATE}
                                                        fileId={
                                                            businessDocs?.find(
                                                                (_doc) => _doc.type === IncorporationDocumentType.INCORPORATION_CERTIFICATE
                                                            )?.uploadId || null
                                                        }
                                                    />
                                                    <UploadDocumentAccordion
                                                        active={show[CompanyDocumentTypes.ASSOCIATION_CONSTITUTION]}
                                                        toggler={() => toggle(CompanyDocumentTypes.ASSOCIATION_CONSTITUTION)}
                                                        type={CompanyDocumentTypes.ASSOCIATION_CONSTITUTION}
                                                        typeId={IncorporationDocumentType.ZAMBIA__ASSOCIATION_CONSTITUTION}
                                                        title={CompanyDocumentFieldTexts[CompanyDocumentTypes.ASSOCIATION_CONSTITUTION]}
                                                        description={""}
                                                        fileId={
                                                            businessDocs?.find(
                                                                (_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__ASSOCIATION_CONSTITUTION
                                                            )?.uploadId || null
                                                        }
                                                    />
                                                </>
                                            )}
                                            {businessDocuments.isLLC && (
                                                <>
                                                    <UploadDocumentAccordion
                                                        active={show[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                                        toggler={() => toggle(CompanyDocumentTypes.INCORPORATION_CERTIFICATE)}
                                                        type={CompanyDocumentTypes.INCORPORATION_CERTIFICATE}
                                                        typeId={IncorporationDocumentType.INCORPORATION_CERTIFICATE}
                                                        title={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                                        description={""}
                                                        fileId={
                                                            businessDocs?.find(
                                                                (_doc) => _doc.type === IncorporationDocumentType.INCORPORATION_CERTIFICATE
                                                            )?.uploadId || null
                                                        }
                                                    />
                                                    <UploadDocumentAccordion
                                                        active={show[CompanyDocumentTypes.OPERATING_LICENSE]}
                                                        toggler={() => toggle(CompanyDocumentTypes.OPERATING_LICENSE)}
                                                        type={CompanyDocumentTypes.OPERATING_LICENSE}
                                                        title={CompanyDocumentFieldTexts[CompanyDocumentTypes.OPERATING_LICENSE]}
                                                        typeId={IncorporationDocumentType.ZAMBIA__OPERATING_LICENSE}
                                                        description={""}
                                                        fileId={
                                                            businessDocs?.find(
                                                                (_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__OPERATING_LICENSE
                                                            )?.uploadId || null
                                                        }
                                                    />
                                                    <ShareHoldersAccordion
                                                        active={show[CompanyDocumentTypes.SHAREHOLDERS_INFORMATION]}
                                                        toggler={() => toggle(CompanyDocumentTypes.SHAREHOLDERS_INFORMATION)}
                                                        title={CompanyDocumentFieldTexts[CompanyDocumentTypes.SHAREHOLDERS_INFORMATION]}
                                                        description={""}
                                                        uploadedDocument={
                                                            businessDocs?.find(
                                                                (_doc) =>
                                                                    _doc.type === IncorporationDocumentType.MEMORANDUM_OF_ASSOCIATION ||
                                                                    _doc.type === IncorporationDocumentType.ZAMBIA__FORM_3 ||
                                                                    _doc.type === IncorporationDocumentType.ZAMBIA__PACRA_PRINT_OUT
                                                            ) || null
                                                        }
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <ProofOfResidenceAccordion
                                                active={show[CompanyDocumentTypes.PROOF_OF_RESIDENCE]}
                                                toggler={() => toggle(CompanyDocumentTypes.PROOF_OF_RESIDENCE)}
                                                title={CompanyDocumentFieldTexts[CompanyDocumentTypes.PROOF_OF_RESIDENCE]}
                                                description={""}
                                                fileId={proofOfResidence?.uploadId || null}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </ApplicationHeader>
                        {isCreator ? (
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                    </ButtonComp>
                                </div>
                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                    <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                        <ButtonComp
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname: isRegistered
                                                            ? Routes.ACCOUNT_OPENING.REVIEW
                                                            : Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS,
                                                    },
                                                    {
                                                        state: {
                                                            from: isRegistered
                                                                ? Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT
                                                                : Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DOCUMENT,
                                                        },
                                                    }
                                                )
                                            }
                                            fullWidth
                                        >
                                            <span>{isRegistered ? "Review Application" : "Save and Continue"}</span>
                                        </ButtonComp>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                    </ButtonComp>
                                </div>
                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                    <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                        <ButtonComp
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname: Routes.ACCOUNT_OPENING.STATUS,
                                                    },
                                                    {
                                                        state: {
                                                            from: Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT,
                                                        },
                                                    }
                                                )
                                            }
                                            fullWidth
                                        >
                                            <span>Return to Application</span>
                                        </ButtonComp>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {isCreator && (
                        <Hint
                            hintType={HintType.GUIDE}
                            listStyle={ListStyle.CHECK}
                            firstList={getCompanyDocumentHints(listOfDocuments, uncompletedDocuments, [])}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default RegisteredBusinessDocuments;
