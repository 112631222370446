import {
    CompanyDocumentFieldTexts,
    CompanyDocumentTypes,
    IncorporationDocumentType,
} from "../../../../../../services/zambia-application/registered/new/document.types";
import { useRef, useState } from "react";

import ApplicationDeleteModal from "../../../../../modals/deleteModal";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import { FileDrop } from "react-file-drop";
import GoogleIcon from "../../../../../../../../../components/google-icon";
import LencoSpinner from "../../../../../../../../../components/spinner";
import PreviewModal from "../../../../../modals/preview-modal";
import usePreview from "../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/usePreview";
import useUploadBusinessDocuments from "../../../../../../Hooks/zambia-application/registered-business/business-documents/useUploadBusinessDocuments";

interface UploadDocumentAccordionProps {
    type: CompanyDocumentTypes;
    typeId: IncorporationDocumentType;
    title: string;
    fileId: string | null;
    active: boolean;
    description: string;
    isExternalLink?: boolean;

    toggler: () => void;
}

function UploadDocumentAccordion(props: UploadDocumentAccordionProps): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
        downloadedDocumentName: CompanyDocumentFieldTexts[props.type],
    });

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const addNewFileInputRef = useRef<HTMLInputElement | null>(null);

    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);

    const {
        deleteIncorporationDocumentError,
        isUploadBusinessDocumentsLoading,
        isDeleteIncorporationDocumentLoading,
        isAddIncorporationDocumentFileLoading,
        handleReset,
        handleUploadBusinessDocuments,
        handleDeleteIncorporationDocument,
        handleAddIncorporationDocumentFile,
    } = useUploadBusinessDocuments({
        onComplete: () => {
            if (fileInputRef.current && addNewFileInputRef.current) {
                fileInputRef.current.value = "";
                addNewFileInputRef.current.value = "";
            }

            setIsDeleteModalOpen(false);
        },
        onError: () => {
            if (fileInputRef.current && addNewFileInputRef.current) {
                fileInputRef.current.value = "";
                addNewFileInputRef.current.value = "";
            }
        },
    });

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    const uploaded = !!props.fileId;

    return (
        <div className="w-full">
            <PreviewModal active={showPreviewModal} toggler={() => setShowPreviewModal(false)} data={previewBlob} title={`${props.title} Preview`} />
            <ApplicationDeleteModal
                header="Delete Document"
                onSubmit={() => void handleDeleteIncorporationDocument({ type: props.typeId })}
                error={deleteIncorporationDocumentError || ""}
                deleteButtonText="Delete"
                active={isDeleteModalOpen}
                isSubmitLoading={isDeleteIncorporationDocumentLoading}
                toggler={() => (setIsDeleteModalOpen(false), handleReset())}
                body={
                    <>
                        <p className="w-full whitespace-normal break-words text-left text-base font-normal text-black-tertiary">
                            This action cannot be undone. Are you sure you want to delete this document?
                        </p>
                        {/* <p className="font-normal text-base text-left break-words w-full whitespace-normal">.</p> */}
                    </>
                }
            />
            <div className={"w-full " + `${uploaded ? "rounded-lg border border-grey px-5 py-6" : ""}`}>
                <div className="w-full justify-between">
                    <div className={"flex flex-row justify-between " + `${uploaded ? "items-center space-x-4" : ""}`}>
                        <div
                            className={
                                "flex flex-row justify-start " +
                                `${!props.description ? "items-center" : ""} ` +
                                `${!uploaded ? "w-full rounded-l-lg border border-r-0 border-grey px-5 py-6" : ""}`
                            }
                        >
                            {uploaded && <GoogleIcon icon="check" className="text-success" />}
                            {!uploaded && <GoogleIcon icon="add_notes" />}
                            <div className="ml-3 flex flex-col items-start justify-start">
                                <h3 className="font-medium text-black-secondary">{props.title}</h3>
                                {props.description && <div className="text-sm font-normal text-black-tertiary">{props.description}</div>}
                            </div>
                        </div>

                        {!uploaded && (
                            <div
                                className="flex w-full min-w-30% max-w-30% cursor-pointer items-center justify-center rounded-r-lg border border-dashed border-grey bg-blue-senary"
                                onClick={() => {
                                    fileInputRef.current && fileInputRef.current.value === "";
                                    fileInputRef.current && fileInputRef.current.click();
                                }}
                            >
                                <FileDrop
                                    onDrop={(files: FileList | null) => {
                                        if (files && files[0].size > 5242880) {
                                            return setIsFileSizeExceeded(true);
                                        }
                                        setIsFileSizeExceeded(false);

                                        files && files[0] && void handleUploadBusinessDocuments({ type: props.typeId, file: files[0] });
                                    }}
                                >
                                    <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
                                        {isUploadBusinessDocumentsLoading ? (
                                            <LencoSpinner color="blue" size="sm" />
                                        ) : (
                                            <GoogleIcon icon="upload" className="text-blue" isOutlined />
                                        )}
                                        <p className="text-center text-xs font-medium text-blue">Click or Drag and Drop</p>
                                        {isFileSizeExceeded && <span className="text-xs text-error">*File exceeded 5MB</span>}
                                    </div>
                                </FileDrop>
                            </div>
                        )}
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={(event) => {
                                if (event.target.files && event.target.files[0].size > 5242880) {
                                    return setIsFileSizeExceeded(true);
                                }
                                setIsFileSizeExceeded(false);
                                event.target.files && void handleUploadBusinessDocuments({ type: props.typeId, file: event.target.files[0] });
                            }}
                            accept="image/jpeg, image/png, application/pdf, .pdf"
                            // multiple
                        />
                    </div>
                </div>

                {uploaded && (
                    <div className="w-full pt-4">
                        <div className="flex w-full flex-col items-center justify-between space-y-4 overflow-x-auto rounded-[4px] border border-grey bg-grey-backdrop px-2 py-2.5 scrollbar-hide 2xs:flex-row 2xs:space-x-4 2xs:space-y-0">
                            <div
                                className="group/delete flex cursor-pointer flex-row items-center space-x-2"
                                onClick={() => setIsDeleteModalOpen(true)}
                            >
                                <GoogleIcon icon="delete" className="text-black-tertiary group-hover/delete:text-error-tertiary" isOutlined />
                                <span className="whitespace-nowrap text-sm text-black-tertiary 4xs:text-base">Document</span>
                            </div>
                            <div className="flex w-max flex-col items-center justify-center space-x-0 space-y-4 4xs:flex-row 4xs:items-stretch 4xs:justify-start 4xs:space-x-4 4xs:space-y-0">
                                <ButtonComp
                                    color="white"
                                    buttonType="flat"
                                    size="sm"
                                    func={() => fileInputRef.current && fileInputRef.current.click()}
                                    isLoading={isUploadBusinessDocumentsLoading}
                                >
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        onChange={(event) => {
                                            if (event.target.files && event.target.files[0].size > 5242880) {
                                                return setIsFileSizeExceeded(true);
                                            }
                                            setIsFileSizeExceeded(false);
                                            event.target.files &&
                                                void handleUploadBusinessDocuments({ type: props.typeId, file: event.target.files[0] });
                                        }}
                                        accept="image/jpeg, image/png, application/pdf, .pdf"
                                        // multiple
                                    />
                                    <span>Re-upload</span>
                                    <GoogleIcon icon="restart_alt" className="ml-2" isOutlined />
                                </ButtonComp>
                                <div className="hidden w-[1px] items-center justify-center 4xs:flex">
                                    <div className="h-6 w-full bg-black-quin"></div>
                                </div>
                                <ButtonComp color="white" buttonType="flat" size="sm" func={() => void handlePreviewDocument(props.fileId || "")}>
                                    <div className="flex w-full items-center justify-center">
                                        <p className="text-sm font-medium">Preview</p>
                                        <GoogleIcon icon="visibility" className="ml-2" isOutlined isFilled />
                                    </div>
                                </ButtonComp>
                            </div>
                        </div>
                        <div className="flex w-full flex-row items-center justify-between pt-4">
                            {uploaded && (
                                <>
                                    <ButtonComp
                                        type="button"
                                        color="blue"
                                        ripple="light"
                                        buttonType="tertiary"
                                        size="sm"
                                        isLoading={isAddIncorporationDocumentFileLoading}
                                        func={() => addNewFileInputRef.current && addNewFileInputRef.current.click()}
                                    >
                                        <input
                                            type="file"
                                            ref={addNewFileInputRef}
                                            className="hidden"
                                            onChange={(event) => {
                                                if (event.target.files && event.target.files[0].size > 5242880) {
                                                    return setIsFileSizeExceeded(true);
                                                }
                                                setIsFileSizeExceeded(false);
                                                event.target.files &&
                                                    void handleAddIncorporationDocumentFile({ type: props.typeId, file: event.target.files[0] });
                                            }}
                                            accept="image/jpeg, image/png, application/pdf, .pdf"
                                            multiple
                                        />
                                        <div className="flex items-center justify-center space-x-1">
                                            <GoogleIcon icon="add_circle" />
                                            <span>Add Other</span>
                                        </div>
                                    </ButtonComp>

                                    {isFileSizeExceeded && <span className="text-xs text-error">*File exceeded 5MB</span>}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UploadDocumentAccordion;
