import { ZambiaCardPaymentCollectionStatus, ZambiaCollectionFeeBearer } from "./zambiaCollectionRequest.constant";

import BasicFeeData from "../basicFeeData";
import Currency from "../currency";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import ZambiaCardPaymentCardDetails from "./zambiaCardPaymentCardDetails";
import { immerable } from "immer";

export default class ZambiaCardPaymentCollection {
    [immerable] = true;

    constructor(
        public id: string,
        public createdAt: string,
        public requestedAmount: string,
        public requestedCurrency: Currency,
        public totalTransactionAmount: string,
        public status: ZambiaCardPaymentCollectionStatus | null,
        public completedAt: string | null,
        public failedReason: string | null,
        public lencoFee: string,
        public lencoFeeData: BasicFeeData,
        public feeBearer: ZambiaCollectionFeeBearer | null,
        public cardDetails: ZambiaCardPaymentCardDetails | null
    ) {}

    static create(obj: GenericObject): ZambiaCardPaymentCollection {
        return new ZambiaCardPaymentCollection(
            Parsers.string(obj.id),
            Parsers.string(obj.createdAt),
            Parsers.string(obj.requestedAmount),
            Parsers.classObjectNonNullable(obj.requestedCurrency, Currency),
            Parsers.string(obj.totalTransactionAmount),
            Parsers.nullableEnum(obj.status, ZambiaCardPaymentCollectionStatus),
            Parsers.nullableString(obj.completedAt),
            Parsers.nullableString(obj.failedReason),
            Parsers.string(obj.lencoFee),
            Parsers.classObjectNonNullable(obj.lencoFeeData, BasicFeeData),
            Parsers.nullableEnum(obj.feeBearer, ZambiaCollectionFeeBearer),
            Parsers.classObject(obj.cardDetails, ZambiaCardPaymentCardDetails)
        );
    }
}
