import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { accountInitFailure, mainInitRequest, mainInitSuccess, setCanAccessCards, setCanAccessTerminals } from "../../../redux/init/slice/initSlice";

import { AxiosError } from "axios";
import Errorhandler from "../../../helpers/request/error-handler";
import IntercomHelper from "../../../helpers/intercom";
// import IntercomHelper from "../../../helpers/intercom";
import { MainInitState } from "../../../redux/init/slice/initSlice.types";
import SocketWrapper from "../../../helpers/websocket/socketWrapper";
// import SocketWrapper from "../../../helpers/websocket/socketWrapper";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { mainInit } from "../services/init-api";
import { registerEventHandlers } from "../../../helpers/websocket/eventHandlers";
// import { registerEventHandlers } from "../../../helpers/websocket/eventHandlers";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function useMainInit(): UseMutationResult<MainInitState, AxiosError | null, void, unknown> {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const accountMainInitApi = useMutation({
        mutationFn: async () => {
            dispatch(mainInitRequest());
            return await mainInit();
        },
        onSuccess(data) {
            // if there is only one company or if there is selectedCompanyId, select it
            dispatch(mainInitSuccess(data));
            const user = data.companyDetails.user;
            dispatch(
                setCanAccessCards(user.isAdmin || data.companyDetails.cards.some((_card) => _card?.assignedToCorporateAccountMember?.id === user.id))
            );
            dispatch(setCanAccessTerminals(user.hasManageTerminalAccess || user.hasViewTerminalAccess));
            const socket = SocketWrapper.setup(data.socket.url, data.socket.secure, data.socket.token);
            registerEventHandlers(socket);
            IntercomHelper.update({
                plan: data.companyDetails.company.typeName,
                phone: data.companyDetails.user.phones[0],
            });
            // if (window.location.pathname.includes("/application")) {
            //     navigate("/");
            // }
        },

        onError(error: AxiosError) {
            Errorhandler(error);
            dispatch(accountInitFailure(getErrorMessage(error)));
            navigate("/login");
        },
    });

    return accountMainInitApi;
}

export default useMainInit;
