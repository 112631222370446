import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ZambiaBulkPaymentResponse, ZambiaBulkTransferState, ZambiaTransferDetail } from "./bulkTransferSlice.types";

import BankAccount from "../../../../../models/bankAccount";
import { TransactionStatus } from "../../../../../models/transaction.constants";
import UserAccount from "../../../../../models/userAccount";
import { ZambiaPaymentMethod } from "../../singleTransfer/slice/singleTransferSlice.types";
import { produce } from "immer";

const initialState: ZambiaBulkTransferState = {
    transferDetailArray: [],
    transferDetail: {
        originatingAccountId: "",
        recipient: {
            externalAccount: {
                accountNumber: "",
                bankCode: "",
                bankAccountId: "",
                customerAccountId: "",
            },
            internalAccountId: "", // if recipient is an internal account (i.e. transfer between accounts)
            cardId: "", // if recipient is a card (i.e. card funding)
        },
        mobileMoneyRecipient: {
            mobileNumber: "",
            firstName: "",
            lastName: "",
        },
        transactionId: "",
        paymentMethod: null,
        amount: "",
        purpose: "",
        status: TransactionStatus.PROCESSING,
        selectedUserAccount: null,
        recipientBankAccount: null,
        fee: 0,
        canApprove: true,
        key: 0,
    },
    isUpdatingTransferDetail: false,
    canVerifyRecipientAccountDetails: true,
    bulkTransferResponse: null,
    bulkTransfersAmountTotal: 0,
};

export const zambiaBulkTransferSlice = createSlice({
    name: "zambia-bulk-transfer",
    initialState,
    reducers: {
        addMultipleZambiaTransferDetailToTransferList: (state: ZambiaBulkTransferState, action: PayloadAction<ZambiaTransferDetail[]>) => {
            const updatedArray = produce(state.transferDetailArray, (draft) => {
                action.payload.forEach((_t) => draft.push(_t));
            });
            state.transferDetailArray = updatedArray;
        },
        addZambiaTransferDetailToTransferList: (state: ZambiaBulkTransferState, action: PayloadAction<ZambiaTransferDetail>) => {
            const updatedArray = produce(state.transferDetailArray, (draft) => {
                draft.push(action.payload);
            });
            state.transferDetailArray = updatedArray;
        },
        updateZambiaTransferDetailToTransferList: (state: ZambiaBulkTransferState, action: PayloadAction<ZambiaTransferDetail>) => {
            const updatedArray = produce(state.transferDetailArray, (draft) => {
                const index = draft.findIndex((_el) => _el.key === action.payload.key);
                if (index !== -1) draft[index] = action.payload;
            });
            state.transferDetailArray = updatedArray;
        },
        updateZambiaTransferDetailStatus: (
            state: ZambiaBulkTransferState,
            action: PayloadAction<{ key: number; transactionId: string; status: TransactionStatus }>
        ) => {
            const updatedArray = produce(state.transferDetailArray, (draft) => {
                const index = draft.findIndex((_el) => _el.key === action.payload.key);
                if (index !== -1) {
                    draft[index].status = action.payload.status;
                    draft[index].transactionId = action.payload.transactionId;
                }
            });
            state.transferDetailArray = updatedArray;
        },
        editZambiaTransferDetailToTransferList: (state: ZambiaBulkTransferState, action: PayloadAction<ZambiaTransferDetail>) => {
            const updatedArray = produce(state.transferDetailArray, (draft) => {
                const index = draft.findIndex((_el) => _el.key === action.payload.key);
                if (index !== -1) draft[index] = action.payload;
            });
            state.transferDetailArray = updatedArray;
        },
        removeZambiaTransferDetailToTransferList: (state: ZambiaBulkTransferState, action: PayloadAction<number>) => {
            state.transferDetailArray = state.transferDetailArray.filter((_el) => _el.key !== action.payload);
        },
        setZambiaTransferDetail: (state: ZambiaBulkTransferState, action: PayloadAction<ZambiaTransferDetail>) => {
            state.transferDetail = action.payload;
        },
        setIsUpdatingZambiaTransferDetail: (state: ZambiaBulkTransferState, action: PayloadAction<boolean>) => {
            state.isUpdatingTransferDetail = action.payload;
        },
        setZambiaTransferDetailOriginatingAccount: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.originatingAccountId = action.payload;
        },
        setZambiaTransferDetailAmount: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.amount = action.payload;
        },
        setZambiaTransferDetailFee: (state: ZambiaBulkTransferState, action: PayloadAction<number>) => {
            state.transferDetail.fee = action.payload;
        },
        setZambiaTransferDetailPurpose: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.purpose = action.payload;
        },
        // setZambiaTransferDetailRecipient: (state: ZambiaBulkTransferState, action: PayloadAction<BankAccount>) => {
        // state.transferDetail.recipient.accountNumber = action.payload.accountNumber;
        // state.transferDetail.recipient.bankAccountId = action.payload.id;
        // state.transferDetail.recipient.bankCode = action.payload.bankCode;
        // state.transferDetail.recipient.customerAccountId = "";
        // state.transferDetail.recipientBankAccount = action.payload;
        // },

        setZambiaPaymentMethod: (state: ZambiaBulkTransferState, action: PayloadAction<ZambiaPaymentMethod>) => {
            state.transferDetail.paymentMethod = action.payload;
        },
        setZambiaMobileMoneyRecipientNumber: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.mobileMoneyRecipient.mobileNumber = action.payload;
        },
        setZambiaMobileMoneyFirstName: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.mobileMoneyRecipient.firstName = action.payload;
        },
        setZambiaMobileMoneyLastName: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.mobileMoneyRecipient.lastName = action.payload;
        },

        setZambiaTransferDetailUserAccountRecipient: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.recipient.externalAccount = {
                accountNumber: "",
                bankCode: "",
                bankAccountId: "",
                customerAccountId: "",
            };
            state.transferDetail.recipient.cardId = "";
            state.transferDetail.recipientBankAccount = null;
            state.transferDetail.recipient.internalAccountId = action.payload;
        },
        setZambiaTransferDetailCustomerAccountRecipient: (state: ZambiaBulkTransferState, action: PayloadAction<BankAccount>) => {
            state.transferDetail.recipient.externalAccount = {
                accountNumber: action.payload.accountNumber,
                bankCode: action.payload.bankCode,
                bankAccountId: action.payload.id,
                customerAccountId: "",
            };
            state.transferDetail.recipient.cardId = "";
            state.transferDetail.recipientBankAccount = action.payload;
            state.transferDetail.recipient.internalAccountId = "";
        },
        setZambiaTransferDetailCardRecipient: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            state.transferDetail.recipient.externalAccount = {
                accountNumber: "",
                bankCode: "",
                bankAccountId: "",
                customerAccountId: "",
            };
            state.transferDetail.recipientBankAccount = null;
            state.transferDetail.recipient.cardId = action.payload;
            state.transferDetail.recipient.internalAccountId = "";
        },

        setZambiaTransferDetailRecipientAccountNumber: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            // state.transferDetail.recipient.accountNumber = action.payload;
            state.transferDetail.recipient.externalAccount = {
                accountNumber: action.payload,
                bankCode: state.transferDetail.recipient.externalAccount.bankCode || "",
                bankAccountId: state.transferDetail.recipient.externalAccount.bankAccountId || "",
                customerAccountId: state.transferDetail.recipient.externalAccount.customerAccountId || "",
            };
        },
        setZambiaTransferDetailRecipientBankCode: (state: ZambiaBulkTransferState, action: PayloadAction<string>) => {
            // state.transferDetail.recipient.bankCode = action.payload;
            state.transferDetail.recipient.externalAccount = {
                accountNumber: state.transferDetail.recipient.externalAccount.accountNumber || "",
                bankCode: action.payload,
                bankAccountId: state.transferDetail.recipient.externalAccount.bankAccountId || "",
                customerAccountId: state.transferDetail.recipient.externalAccount.customerAccountId || "",
            };
        },
        setZambiaSelectedUserAccount: (state: ZambiaBulkTransferState, action: PayloadAction<UserAccount>) => {
            state.transferDetail.selectedUserAccount = action.payload;
        },
        setZambiaTransferDetailIndex: (state: ZambiaBulkTransferState) => {
            const randomId = Math.floor(Math.random() * 100000000000000) + 1;
            const existingRandomId = state.transferDetailArray.find((_el) => _el.key === randomId)?.key;

            if (existingRandomId) {
                state.transferDetail.key = existingRandomId + 2;
            } else {
                state.transferDetail.key = randomId;
            }
        },
        setZambiaCanApprove: (state: ZambiaBulkTransferState, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.transferDetail.canApprove = action.payload;
                state.transferDetail.status = TransactionStatus.PROCESSING;
            } else {
                state.transferDetail.canApprove = action.payload;
                state.transferDetail.status = TransactionStatus.PENDING_APPROVAL;
            }
        },
        setCanVerifyZambiaRecipientAccountDetails: (state: ZambiaBulkTransferState, action: PayloadAction<boolean>) => {
            state.canVerifyRecipientAccountDetails = action.payload;
        },
        setZambiaBulkTransferResponse: (state: ZambiaBulkTransferState, action: PayloadAction<ZambiaBulkPaymentResponse>) => {
            state.bulkTransferResponse = action.payload;
        },
        setZambiaBulkTransfersAmountTotal: (state: ZambiaBulkTransferState, action: PayloadAction<number>) => {
            state.bulkTransfersAmountTotal = action.payload;
        },
        resetZambiaTransferDetail: (state: ZambiaBulkTransferState) => {
            state.transferDetail.originatingAccountId = "";
            state.transferDetail.recipient = {
                externalAccount: {
                    accountNumber: "",
                    bankCode: "",
                    bankAccountId: "",
                    customerAccountId: "",
                },
                internalAccountId: "",
                cardId: "",
            };
            state.transferDetail.paymentMethod = null;
            state.transferDetail.amount = "";
            state.transferDetail.purpose = "";
            state.transferDetail.selectedUserAccount = null;
            state.transferDetail.recipientBankAccount = null;
            state.transferDetail.fee = 0;
            state.transferDetail.canApprove = true;
            state.transferDetail.key = 0;
            state.isUpdatingTransferDetail = false;
            state.canVerifyRecipientAccountDetails = true;
        },
        resetZambiaTransferDetailRecipient: (state: ZambiaBulkTransferState) => {
            state.transferDetail.recipientBankAccount = null;
            state.transferDetail.recipient = {
                externalAccount: {
                    accountNumber: "",
                    bankCode: "",
                    bankAccountId: "",
                    customerAccountId: "",
                },
                internalAccountId: "",
                cardId: "",
            };
        },
        resetZambiaTransferDetailRecipientBankAccount: (state: ZambiaBulkTransferState) => {
            state.transferDetail.recipientBankAccount = null;
        },
        resetAllZambiaBulkTransferData: (state: ZambiaBulkTransferState) => {
            state.transferDetailArray = [];
            state.transferDetail.originatingAccountId = "";
            state.transferDetail.recipient = {
                externalAccount: {
                    accountNumber: "",
                    bankCode: "",
                    bankAccountId: "",
                    customerAccountId: "",
                },
                internalAccountId: "",
                cardId: "",
            };
            state.transferDetail.amount = "";
            state.transferDetail.purpose = "";
            state.transferDetail.selectedUserAccount = null;
            state.transferDetail.recipientBankAccount = null;
            state.transferDetail.fee = 0;
            state.transferDetail.canApprove = true;
            state.transferDetail.key = 0;
            state.isUpdatingTransferDetail = false;
            state.canVerifyRecipientAccountDetails = true;
            state.bulkTransferResponse = null;
            state.bulkTransfersAmountTotal = 0;
        },
    },
});

export const {
    addMultipleZambiaTransferDetailToTransferList,
    addZambiaTransferDetailToTransferList,
    updateZambiaTransferDetailToTransferList,
    updateZambiaTransferDetailStatus,
    editZambiaTransferDetailToTransferList,
    removeZambiaTransferDetailToTransferList,
    setZambiaTransferDetail,
    setIsUpdatingZambiaTransferDetail,
    setZambiaTransferDetailOriginatingAccount,
    setZambiaTransferDetailAmount,
    setZambiaPaymentMethod,
    setZambiaMobileMoneyRecipientNumber,
    setZambiaMobileMoneyFirstName,
    setZambiaMobileMoneyLastName,
    setZambiaTransferDetailFee,
    setZambiaTransferDetailPurpose,
    setZambiaTransferDetailUserAccountRecipient,
    setZambiaTransferDetailCustomerAccountRecipient,
    setZambiaTransferDetailCardRecipient,
    setZambiaTransferDetailRecipientAccountNumber,
    setZambiaTransferDetailRecipientBankCode,
    setZambiaSelectedUserAccount,
    setZambiaTransferDetailIndex,
    setZambiaCanApprove,
    setCanVerifyZambiaRecipientAccountDetails,
    setZambiaBulkTransferResponse,
    setZambiaBulkTransfersAmountTotal,
    resetZambiaTransferDetail,
    resetZambiaTransferDetailRecipient,
    resetZambiaTransferDetailRecipientBankAccount,
    resetAllZambiaBulkTransferData,
} = zambiaBulkTransferSlice.actions;

export default zambiaBulkTransferSlice.reducer;
