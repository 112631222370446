import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class PosTransactionStatItem {
    [immerable] = true;

    constructor(
        public volume: string | null,
        public count: number | null
    ) {}

    static create(obj: GenericObject): PosTransactionStatItem {
        return new PosTransactionStatItem(Parsers.nullableString(obj.volume), Parsers.nullableNumber(obj.count));
    }
}
