import { ExportZambiaPayoutsRequest, ExportZambiaPayoutsResponse } from "../../Services/Payouts/zambiaPayoutsApi.types";
import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { exportZambiaPayouts } from "../../Services/Payouts/zambiaPayoutsApi";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseExportZambiaPayoutsInterface {
    isExportZambiaPayoutsLoading: boolean;
    exportZambiaPayoutsErrorMessage: string;
    handleExportZambiaPayouts: (_data: ExportZambiaPayoutsRequest) => void;
}

interface Props {
    onComplete: (_data: ExportZambiaPayoutsResponse) => void;
}

function useExportZambiaPayouts(props: Props): UseExportZambiaPayoutsInterface {
    const dispatch = useDispatch();

    const [isExportZambiaPayoutsLoading, setIsExportZambiaPayoutsLoading] = useState(false);
    const [exportZambiaPayoutsErrorMessage, setExportZambiaPayoutsErrorMessage] = useState<string>("");

    const handleExportZambiaPayouts = useCallback(
        async (_data: ExportZambiaPayoutsRequest) => {
            try {
                setIsExportZambiaPayoutsLoading(true);
                setExportZambiaPayoutsErrorMessage("");
                const res = await exportZambiaPayouts(_data);
                dispatch(messageTrue("Statement Requested Successful"));
                props.onComplete(res);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setExportZambiaPayoutsErrorMessage(errorMessage);
            } finally {
                setIsExportZambiaPayoutsLoading(false);
            }
        },
        [dispatch]
    );
    return {
        isExportZambiaPayoutsLoading,
        exportZambiaPayoutsErrorMessage,
        handleExportZambiaPayouts,
    };
}

export default useExportZambiaPayouts;
