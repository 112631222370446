import { useCallback, useEffect, useState } from "react";

import NewDropdownItem from "../../../../../../components/new-dropdown-item";
import { TailSpin } from "react-loader-spinner";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import useCancelTeamMemberApproverLimit from "../../../Services/team-members/hooks/useCancelTeamMemberApproverLimit";
import useClickOutside from "../../../../../../hooks/useClickOutside";

interface TeamMemberApprovalLimitDropdownProps {
    data: UserAccountMeta;
    hasApprovalLimit: boolean;

    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    handleOpenSetApprovalLimitModal: () => void;
    handleOpenSetApprovalLimitModalWithAmount: () => void;
}

function TeamMemberApprovalLimitDropdown(props: TeamMemberApprovalLimitDropdownProps): JSX.Element {
    const { isSetApproverLimitDone, isLoading, handleSetApproverLimit } = useCancelTeamMemberApproverLimit(props.updateAccountMeta);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const domNode = useClickOutside(() => setIsDropdownOpen(false));

    useEffect(() => {
        if (isSetApproverLimitDone) {
            setIsDropdownOpen(false);
        }
    }, [isSetApproverLimitDone]);

    const toggleDropdown = useCallback(() => {
        setIsDropdownOpen((prev) => !prev);
    }, []);

    const handleCancelApprovalLimit = useCallback(() => {
        void handleSetApproverLimit({
            memberId: props.data.id,
            userAccountId: props.data.userAccountId,
            maxApprovalAmount: null,
            minApprovalAmount: null,
        });
    }, [props.data]);

    return (
        <div className="relative w-max max-w-max" ref={domNode}>
            <div
                className={
                    `flex h-6 max-w-max items-center justify-center space-x-2 rounded-md border border-grey px-2 text-xs ${props.data.isAdmin ? "bg-grey-tertiary" : "bg-white"} ` +
                    "relative mb-1 cursor-pointer whitespace-nowrap text-black-secondary"
                }
            >
                <span className="border-r border-grey pr-2">Approval Limit</span>
                <div className="" onClick={toggleDropdown}>
                    {isLoading && (
                        <div className="pointer-events-none flex h-full items-center justify-center">
                            <TailSpin color="#5466F9" height={15} width={15} />
                        </div>
                    )}
                    {!isLoading && <span className="material-symbols-outlined text-sm text-black-secondary">more_horiz</span>}
                </div>
            </div>

            <div
                className={
                    `absolute top-8 z-50 w-max max-w-max rounded-b-md shadow ${props.data.isAdmin ? "bg-grey-tertiary" : "bg-white"} ` +
                    "left-0 origin-top-left transform rounded-md transition-none duration-300 ease-in-out" +
                    " w-full [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary" +
                    ` ${!isDropdownOpen ? " pointer-events-none scale-0 opacity-0" : " scale-100 opacity-100"}`
                }
            >
                {props.hasApprovalLimit && (
                    <NewDropdownItem size="sm" padding="sm" onClick={props.handleOpenSetApprovalLimitModalWithAmount}>
                        <p className="text-xs text-black-secondary">Edit approval limit</p>
                    </NewDropdownItem>
                )}
                {!props.hasApprovalLimit && (
                    <NewDropdownItem size="sm" padding="sm" onClick={props.handleOpenSetApprovalLimitModal}>
                        <p className="text-xs text-black-secondary">Set approval level</p>
                    </NewDropdownItem>
                )}
                {props.hasApprovalLimit && (
                    <NewDropdownItem size="sm" padding="sm" color="red" onClick={handleCancelApprovalLimit}>
                        <p className="text-xs text-black-secondary">Remove Approval Limit</p>
                    </NewDropdownItem>
                )}
            </div>
        </div>
    );
}

export default TeamMemberApprovalLimitDropdown;
