// export enum UserRole {
// BOTH = 1,
// APPROVER = 2,
// INITIATOR = 3,
// VIEWER = 4,
// NOTIFICATION_ONLY = 5,
// }
// export const RoleOptions: Array<DropdownItem<UserRole>> = AllUserRoles.map((roleItem) => ({
// value: roleItem,
// text: UserRoleText[roleItem],
// subtext: UserRoleDescription[roleItem],
// }));
// enum  TransactionFilters{
// {id: 0, name: "Date"},
// {id: 1, name: "Accounts"},
// {id: 2, name: "Categories"},
// {id: 3, name: "Recipients"},
// // {id: 4, name: "Recurring"},
// {id: 5, name: "Others"},
// }

export enum TransactionFilters {
    DATE = "Date",
    ACCOUNTS = "Accounts",
    RECIPIENTS = "Recipients",
    CATEGORIES = "Categories",
    TYPE = "Type",
    STATUS = "Status",
    CARDS = "Cards",
}

export const TransactionFiltersArray = [
    TransactionFilters.DATE,
    TransactionFilters.ACCOUNTS,
    TransactionFilters.CATEGORIES,
    TransactionFilters.TYPE,
    TransactionFilters.STATUS,
    TransactionFilters.RECIPIENTS,
    TransactionFilters.CARDS,
];

export const TransactionFiltersData = Object.values(TransactionFilters).map((_filter) => ({
    text: _filter,
    value: _filter,
}));
