import { BillPurchaseHeader, BillPurchaseRow } from "../../hooks/billPayment.constants";
import {
    resetAllBillPaymentData,
    resetBillHistoryFilterState,
    setBillHistoryFilterState,
    setBillHistoryPaginationOffset,
    setBillPaymentPage,
    setSelectedBillPurchase,
    setShowBillPurchaseDetails,
} from "../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BillHistoryFilterSection from "./Dropdown/Filter/BillHistoryFilterSection";
import { BillPaymentPage } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import BillPurchaseDetailCard from "../Cards/BillPurchaseDetailCard";
import { BillPurchasesHistoryRequest } from "../../services/billPayment/billPaymentApi.types";
import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import { PageTitle } from "../../../../../helpers/AppConstants";
import Table from "../../../../../components/Table";
import { produce } from "immer";
import useBillPayment from "../../hooks/state/BillPayment/useBillPayment";
import useBillPaymentHistory from "../../hooks/state/BillPayment/useBillPaymentHistory";

export default function BillPaymentHistory(): JSX.Element {
    const dispatch = useDispatch();
    const { handleBillPaymentVendors } = useBillPayment();

    const isPaginationLoading = useSelector((state: IRootState) => state.transactions.isTransactionPaginationLoading);
    const billPurchases = useSelector((state: IRootState) => state.billPayment.billPurchases);
    const billPurchasesTotal = useSelector((state: IRootState) => state.billPayment.billPurchasesTotal);
    const billPurchasesGroupSize = useSelector((state: IRootState) => state.billPayment.billPurchasesGroupSize);
    const billPurchasesOffSet = useSelector((state: IRootState) => state.billPayment.billPurchasesOffSet);
    // const selectedBillPurchase = useSelector((state: IRootState) => state.billPayment.selectedBillPurchase);
    const billHistoryFilterState = useSelector((state: IRootState) => state.billPayment.billHistoryFilterState);

    const { isBillPurchasesLoading, handleBillPurchaseHistory } = useBillPaymentHistory();

    document.title = PageTitle.BILL_HISTORY_PAGE;

    const handleCloseHistoryTable = useCallback(() => {
        dispatch(setBillPaymentPage(BillPaymentPage.MAKE_PAYMENT));
    }, []);

    const handleFilteredDetails = useCallback((_data: BillPurchasesHistoryRequest) => {
        return handleBillPurchaseHistory(_data);
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetBillHistoryFilterState());
        return handleBillPurchaseHistory(billHistoryFilterState);
    }, [billHistoryFilterState]);

    const handlePaginationRequest = useCallback(
        (offset: number) => {
            const updatedList = produce(billHistoryFilterState, (draft) => {
                return (draft = { ...draft, offset });
            });
            dispatch(setBillHistoryFilterState(updatedList));
            dispatch(setBillHistoryPaginationOffset(offset));
            void handleBillPurchaseHistory(updatedList);
        },
        [billHistoryFilterState]
    );

    useEffect(() => {
        void handleBillPurchaseHistory({
            query: "",
            offset: 0,
            statuses: [],
            vendorIds: [],
            categories: [],
            userAccountIds: [],
            date: {
                begin: "",
                end: "",
            },
        });

        void handleBillPaymentVendors({
            categories: [],
        });
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetAllBillPaymentData());
        };
    }, []);

    return (
        <>
            <BillPurchaseDetailCard />
            <div className="flex h-full max-h-full w-full flex-col space-y-6">
                <div className="relative flex w-full items-center justify-start space-x-2">
                    <div className="w-max select-none rounded-[4px] bg-white px-2 py-1">
                        <p className="text-xs leading-[100%] text-black-tertiary xs:text-sm">Bill Payments</p>
                    </div>
                    <GoogleIcon icon="chevron_right" isOutlined />
                    <div
                        className={
                            "flex items-center justify-start text-xs leading-none" +
                            " w-max rounded-[4px] bg-white px-2 py-1" +
                            " font-medium text-black-secondary"
                        }
                    >
                        <p className="select-none text-sm font-medium capitalize leading-[100%] text-black-secondary xs:text-sm">
                            Bill Payment History
                        </p>
                        <div
                            className="ml-2 max-w-[20px] cursor-pointer overflow-hidden text-black"
                            onClick={(e) => {
                                handleCloseHistoryTable();
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <GoogleIcon icon="close" />
                        </div>
                    </div>
                </div>

                <div className="relative flex h-full w-full flex-col items-start justify-start">
                    <div className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-start space-y-4">
                        <BillHistoryFilterSection
                            handleClearAll={() => void handleClearAll()}
                            handleFilteredDetails={(_data) => void handleFilteredDetails(_data)}
                        />
                        <Table
                            heads={BillPurchaseHeader}
                            total={billPurchasesTotal}
                            offset={billPurchasesOffSet}
                            dataType="bill-purchase"
                            groupSize={billPurchasesGroupSize}
                            isLoading={!!isBillPurchasesLoading}
                            isPaginateLoading={isPaginationLoading}
                            rows={billPurchases?.map((_billPurchase) => ({
                                onRecordClick: () => {
                                    dispatch(setSelectedBillPurchase(_billPurchase));
                                    dispatch(setShowBillPurchaseDetails(_billPurchase));
                                },
                                record: BillPurchaseRow(_billPurchase),
                            }))}
                            paginateFunction={(_, pageOffset) => handlePaginationRequest(pageOffset)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
