import { getAccountDetails, getAccountSummary } from "../../Services/accounts-api";
import { getAccountDetailsRequest, getAccountSummaryRequest } from "../../Services/accounts-api.types";
import { setIndividualAccount, setIndividualAccountSummary } from "../../../../../redux/accounts/slice/accountsSlice";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../redux/rootReducer";
import Transaction from "../../../../../models/transaction";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { setMultipleTransactions } from "../../../../../redux/transaction/slice/transactionSlice";
import { useParams } from "react-router";

type IndividualAccountDetailsUrlParams = {
    accountId: string;
};

export interface UseIndividualAccountInterface {
    inflow: Transaction[];
    outflow: Transaction[];
    pending: Transaction[];
    isLoaded: boolean;
    showAmount: boolean;
    getAccountDetailsError: string;
    getAccountSummaryError: string;
    isGetAccountDetailsLoading: boolean;
    isGetAccountSummaryLoading: boolean;
    handleToggleShowAmount: () => void;
    handleGetAccountSummary: (_data: getAccountSummaryRequest) => Promise<void>;
    handleGetAccountDetails: (_data: getAccountDetailsRequest) => Promise<void>;
}

function useIndividualAccount(): UseIndividualAccountInterface {
    const dispatch = useDispatch();

    const { accountId: userAccountId } = useParams<IndividualAccountDetailsUrlParams>();

    const initLoading: boolean = useSelector<IRootState, boolean>((state: IRootState) => state.init.loading);
    const transactions = useSelector((state: IRootState) => state.transaction.transactions);
    const accountSummary = useSelector((state: IRootState) => state.account.individualAccountSummary);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [inflow, setInflow] = useState<Transaction[]>([]);
    const [outflow, setOutFlow] = useState<Transaction[]>([]);
    const [pending, setPending] = useState<Transaction[]>([]);
    const [getAccountDetailsError, setGetAccountDetailsError] = useState<string>("");
    const [getAccountSummaryError, setGetAccountSummaryError] = useState<string>("");
    const [isGetAccountDetailsLoading, setIsGetAccountDetailsLoading] = useState<boolean>(false);
    const [isGetAccountSummaryLoading, setIsGetAccountSummaryLoading] = useState<boolean>(false);
    const [showAmount, setShowAmount] = useState<boolean>(false);

    const handleToggleShowAmount = () => {
        setShowAmount((prev) => !prev);
    };

    const handleGetAccountSummary = useCallback(
        async (_data: getAccountSummaryRequest) => {
            try {
                setGetAccountSummaryError("");
                setIsGetAccountSummaryLoading(true);
                const res = await getAccountSummary(_data);
                dispatch(setIndividualAccountSummary(res));
                dispatch(setMultipleTransactions([...res.pending.transactions, ...res.recent.credit, ...res.recent.debit]));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setGetAccountSummaryError(errorMessage);
            } finally {
                setIsGetAccountSummaryLoading(false);
            }
        },
        [dispatch]
    );

    const handleGetAccountDetails = useCallback(
        async (_data: getAccountDetailsRequest) => {
            try {
                setGetAccountDetailsError("");
                setIsGetAccountDetailsLoading(true);
                const res = await getAccountDetails(_data);
                dispatch(setIndividualAccount(res.account));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setGetAccountDetailsError(errorMessage);
            } finally {
                setIsGetAccountDetailsLoading(false);
            }
        },
        [dispatch]
    );

    useLayoutEffect(() => {
        if (initLoading || !userAccountId) return;
        void handleGetAccountDetails({ userAccountId });
        void handleGetAccountSummary({
            userAccountId,
        });
    }, [userAccountId, initLoading]);

    useEffect(() => {
        if (!accountSummary || !transactions) return;
        setInflow(accountSummary.recent.credit.map((el) => transactions.get(el.id) as Transaction).filter((el) => el !== undefined));
        setOutFlow(accountSummary.recent.debit.map((el) => transactions.get(el.id) as Transaction).filter((el) => el !== undefined));
        setPending(accountSummary.pending.transactions.map((el) => transactions.get(el.id) as Transaction).filter((el) => el !== undefined));
        setIsLoaded(true);
    }, [accountSummary, transactions]);

    useEffect(() => {
        return () => {
            setInflow([]);
            setOutFlow([]);
            setPending([]);
        };
    }, []);

    return {
        inflow,
        outflow,
        pending,
        isLoaded,
        showAmount,
        getAccountDetailsError,
        getAccountSummaryError,
        isGetAccountDetailsLoading,
        isGetAccountSummaryLoading,
        handleToggleShowAmount,
        handleGetAccountSummary,
        handleGetAccountDetails,
    };
}

export default useIndividualAccount;
