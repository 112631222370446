import { useEffect, useRef, useState } from "react";

import EditAccountRestrictions from "../account-settings/restrictions/EditAccountRestrictions";
import FullPageTray from "../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import UserAccount from "../../../../../models/userAccount";

interface IProps {
    active: boolean;
    toggle: () => void;
    account: UserAccount | undefined;
}

function SendMoneyRuleTray({ active, account, toggle }: IProps): JSX.Element {
    const [isEditMode, setIsEditMode] = useState(active);

    const myRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setIsEditMode(true);
    }, [active]);

    const handleCancel = () => {
        toggle();
        setIsEditMode(false);
    };

    return (
        <>
            <FullPageTray active={active} dataType="setup-money-rule-tray" handleReset={handleCancel} withClickOutside>
                <FullPageTrayHeader toggler={toggle} dataType="setup-money-rule-tray">
                    <p className="text-2xl">Send Money Rule</p>
                </FullPageTrayHeader>
                <FullPageTrayBody dataType="setup-money-rule-tray">
                    <div className="relative h-full w-full space-y-7 pb-5">
                        <div className="h-full w-full space-y-10" ref={myRef}>
                            <div className="h-full w-full space-y-4">
                                {isEditMode && account && <EditAccountRestrictions account={account} handleCancel={handleCancel} />}
                            </div>
                        </div>
                    </div>
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default SendMoneyRuleTray;
