import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import PosTransactionStatItem from "./PosTransactionStatItem";
import { immerable } from "immer";

export default class PosTransactionStat {
    [immerable] = true;

    constructor(
        public total: PosTransactionStatItem,
        public successful: PosTransactionStatItem
    ) {}

    static create(obj: GenericObject): PosTransactionStat {
        return new PosTransactionStat(
            Parsers.classObjectNonNullable(obj.total, PosTransactionStatItem),
            Parsers.classObjectNonNullable(obj.successful, PosTransactionStatItem)
        );
    }
}
