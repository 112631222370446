import { AppToastType } from "../../../../../../redux/app-toast/app-toast-slice.types";
import { ApplicationNote } from "../../../services/application.constants";
import { ReactComponent as ChatIcon } from "../../../../../../assets/svg/dashboard/application/chat.svg";
import React from "react";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface ApplicationSectionItemProps {
    icon: React.ReactNode;
    title: string;
    notes?: ApplicationNote[] | undefined;
    ctaIcon?: React.ReactNode;
    progress: number;
    isDisabled?: boolean;
    description: string;
    onClick?: () => void;
}

function ApplicationSectionItem(props: ApplicationSectionItemProps): JSX.Element {
    const dispatch = useDispatch();
    const accountOpening = useAppSelector((state) => state.application.init);

    return (
        <div
            className="w-full"
            onClick={() => {
                if (props.isDisabled) {
                    dispatch(
                        messageTrue({
                            message: accountOpening?.isCreator
                                ? "Fill business details before proceeding"
                                : "Wait for your business director to fill business details before proceeding",
                            type: AppToastType.INFORMATION,
                        })
                    );
                }
            }}
        >
            <div
                className={
                    `justify flex flex-col space-y-4 p-4 ` +
                    `cursor-pointer rounded-lg border border-grey bg-white` +
                    ` ${props.isDisabled ? "pointer-events-none opacity-50" : ""}`
                }
                onClick={() => {
                    if (props.onClick && !props.isDisabled) {
                        props.onClick();
                    }
                }}
            >
                <div className="flex flex-row items-center justify-between space-x-4">
                    <div className="flex flex-row items-center justify-center space-x-4">
                        <div>
                            <div className="flex h-12 w-12 items-center justify-center">{props.icon}</div>
                        </div>
                        <div className="flex flex-col space-y-2">
                            <h6 className="text-base font-medium leading-[100%] text-black">{props.title}</h6>
                            <p className="text-sm leading-[100%] text-black-tertiary">{props.description}</p>
                        </div>
                    </div>
                    <p className="text-sm font-medium text-black-secondary">
                        {props.progress}
                        {!isNullOrUndefined(props.progress) ? "%" : ""}
                    </p>
                </div>
                {props.notes && props.notes.length > 0 && (
                    <div className="rounded-lg bg-grey-backdrop p-4">
                        <div className="flex flex-row items-center justify-start space-x-2">
                            <ChatIcon />
                            <span className="text-sm font-medium text-black-secondary">For your Attention:</span>
                        </div>
                        <div className="mt-2 flex w-full flex-col items-start justify-start space-y-4">
                            {props.notes.map((_note, index) => (
                                <div className="w-full rounded-md bg-white px-4 py-2" key={index}>
                                    <p className="text-sm text-black-tertiary">{_note.note}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ApplicationSectionItem;
