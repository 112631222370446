import React, { useEffect, useState } from "react";
import NewDropdownItem from "../../../../new-dropdown-item";
import RadioButton from "../../../../radio-button";
import { ItemSubList } from "./CheckboxOption";
import { FilterItemSelectType } from "..";
import { GenericObject } from "../../../../../helpers/types";
import { useAppSelector } from "../../../../../redux/hooks";
import { setClearAllFilter } from "../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface Props {
    onClick?: (data: GenericObject) => void;
    options: ItemSubList[];
}

export default function RadioOption({ onClick, options }: Props) {
    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState<number>(0);
    const clearAllFilter = useAppSelector((state) => state.application.clearAllFilter);

    useEffect(() => {
        if (clearAllFilter) {
            setSelectedOption(0);
            dispatch(setClearAllFilter(false));
        }
        return () => {};
    }, [clearAllFilter]);
    return (
        <>
            {options?.map((option, idx) => {
                return (
                    <NewDropdownItem
                        key={idx}
                        size="lg"
                        className={`${selectedOption === idx + 1 ? "bg-grey-tertiary" : ""}`}
                        onClick={() => {
                            onClick?.({
                                value: option.value,
                                type: FilterItemSelectType.RADIO_OPTION,
                                key: "test_key",
                                checked: selectedOption === idx + 1,
                            });
                            setSelectedOption(idx + 1 || 0);
                        }}
                    >
                        <div className="flex h-full w-full flex-row items-center justify-start pr-4 text-sm">
                            <div className="max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
                                <RadioButton
                                    id="date-radio"
                                    size="sm"
                                    checked={selectedOption === idx + 1}
                                    text={
                                        <div className="ml-1">
                                            <p className="text-sm font-medium text-black-tertiary">{option.text}</p>
                                            <p className="text-xs text-black-tertiary">{option.subText}</p>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </NewDropdownItem>
                );
            })}
        </>
    );
}
