import React, { useEffect, useState } from "react";

import SendableDropdown from "../../../../../../../components/dropdown/sendable-dropdown";
import Transaction from "../../../../../../../models/transaction";
import TransactionCategory from "../../../../../../../models/transactionCategory";
import useTransactionDetailsCategory from "../Hooks/useTransactionDetailsCategory";

interface TransactionDetailCardCategoriesInterface {
    dataType: string;
    transaction: Transaction;
    showTransactionDetailCard: boolean;
}

function TransactionDetailCardCategories({
    dataType,
    transaction,
    showTransactionDetailCard,
}: TransactionDetailCardCategoriesInterface): JSX.Element {
    const {
        isError,
        isSubmitting,
        transactionCategories: categoriesOptions,
        handleSetTransactionCategory,
        handleSetNewTransactionCategory,
    } = useTransactionDetailsCategory();

    const [displayText, setDisplayText] = useState<string>("");

    useEffect(() => {
        setDisplayText(transaction.category?.name || "");
    }, [transaction]);

    useEffect(() => {
        if (!isError) return;
        setDisplayText("");
    }, [isError]);

    return (
        <>
            {(transaction.isSuccess || transaction.isPendingApproval || transaction.isProcessing) && (
                <div className={`relative w-full ${!showTransactionDetailCard ? "hidden" : ""}`} data-type={dataType}>
                    <SendableDropdown
                        data={categoriesOptions}
                        label="Categorize Transaction"
                        inputValue={transaction.category?.name || ""}
                        value={displayText}
                        changeValue={setDisplayText}
                        isLoading={isSubmitting}
                        clickAndClose
                        createFunc={(name: string) => void handleSetNewTransactionCategory(transaction, name)}
                        cancelFunc={() => void handleSetTransactionCategory(transaction, "")}
                        onClickFunc={(option: TransactionCategory) => {
                            setDisplayText(option.name);
                            void handleSetTransactionCategory(transaction, option.id);
                        }}
                        data-type={dataType}
                        isDropdownPositionUp
                    />
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardCategories;
