import { setCableTVPurchaseOtp } from "../../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseCableTVOtpInterface {
    handleOtpChange: (_amount: string) => void;
}

function useCableTVPurchaseOtp(): UseCableTVOtpInterface {
    const dispatch = useDispatch();

    const handleOtpChange = useCallback((_otp: string) => {
        dispatch(setCableTVPurchaseOtp(_otp));
    }, []);

    return { handleOtpChange };
}

export default useCableTVPurchaseOtp;
