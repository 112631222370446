import TeamMemberDetailsAction from "../../Menu/TeamMemberDetailsAction";
import { TeamMemberItem } from "../../../Services/team-members/team-members.types";
import User from "../../../../../../models/user";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import getAvatarColorClass from "../../../../../../helpers/avatarColors";
import titleCase from "../../../../../../hooks/titleCase";
import AvatarDetails from "../../../../../../components/avatar-details";
import getInitials from "../../../../../../helpers/get-initials";

const getSummaryText = (teamMember: User): string => {
    if (teamMember.isAdmin) {
        return "Admin";
    } else if (teamMember.isInvitePending) {
        return "Invitation Pending";
    } else {
        return "Member";
    }
};

interface TeamMemberDetailsHeaderProps {
    teamMember: TeamMemberItem;

    goBack: () => void;
    onRemove: (id: string) => void;
    handleAddNewAccounts: (item: TeamMemberItem, _accounts: UserAccountMeta[]) => void;
}

function TeamMemberDetailsHeader({ teamMember: data, goBack, onRemove, handleAddNewAccounts }: TeamMemberDetailsHeaderProps): JSX.Element {
    return (
        <div className="flex w-full flex-col">
            {/* Back section for Mobile */}
            <div className="flex h-12 w-full items-center justify-between border-b-0.2 border-grey-secondary sm:hidden">
                <div onClick={goBack} className="cursor-pointer">
                    {/* <BackIcon /> */}
                </div>
                <div className="flex w-max flex-row items-center justify-start space-x-4 sm:hidden">
                    <div>
                        <div className={`${getAvatarColorClass(data.teamMember.id)} flex h-8 w-8 items-center justify-center rounded-full text-sm`}>
                            {data.teamMember.nameAcronym}
                        </div>
                    </div>
                    <span className="whitespace-nowrap text-base text-black-secondary sm:font-normal">{data.teamMember.name}</span>
                    {!data.teamMember.isAdmin && (
                        <TeamMemberDetailsAction teamMember={data} onRemove={onRemove} handleAddNewAccounts={handleAddNewAccounts} />
                    )}
                </div>
            </div>
            {/* End of Back section for Mobile */}

            <div className="space-y-5 rounded-lg bg-white px-6 py-5">
                <div className="flex w-full flex-col items-start justify-start space-y-2 sm:flex-row md:justify-between">
                    <>
                        <AvatarDetails
                            dataType="team-member"
                            initials={getInitials(data.teamMember.name)}
                            title={
                                <div className="">
                                    <div className="hidden text-base font-normal sm:inline">
                                        <div className="flex items-center space-x-3">
                                            <span className="text-lg font-medium text-black-secondary">{titleCase(data.teamMember.name)}</span>
                                            <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                                                Active
                                            </span>
                                        </div>
                                    </div>
                                    <div className={"text-sm text-black-tertiary"}>{getSummaryText(data.teamMember)}</div>
                                </div>
                            }
                            subtitle={getSummaryText(data.teamMember)}
                        />

                        <div className="hidden w-full items-center justify-end sm:flex">
                            <div>
                                {!data.teamMember.isAdmin && (
                                    <>
                                        <TeamMemberDetailsAction teamMember={data} onRemove={onRemove} handleAddNewAccounts={handleAddNewAccounts} />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                </div>
                <hr />
                <div className="flex space-x-16">
                    <div className="item-center mr-6 flex h-full flex-col justify-between sm:ml-0 sm:pl-0">
                        <span className="text-2xs capitalize text-black-tertiary">Full name</span>
                        {data.teamMember.firstName && data.teamMember.lastName && (
                            <span className="py-0.5 text-xs font-medium text-black-secondary">
                                {data.teamMember.firstName + " " + data.teamMember.lastName}
                            </span>
                        )}
                    </div>
                    <div className="item-center mr-6 flex h-full flex-col justify-between sm:ml-0 sm:pl-0">
                        <span className="text-2xs capitalize text-black-tertiary">Email Address</span>
                        {data.teamMember.email && <span className="py-0.5 text-xs font-medium text-black-secondary">{data.teamMember.email}</span>}
                    </div>

                    <div className="item-center mr-6 flex h-full flex-col justify-between sm:ml-0 sm:pl-0">
                        <span className="text-2xs capitalize text-black-tertiary">Phone Number</span>
                        {data.teamMember.phones.length > 0 && (
                            <span className="py-0.5 text-xs font-medium text-black-secondary">{data.teamMember.phones[0]}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamMemberDetailsHeader;
