import { PurchaseType } from "../../../../models/terminal.constants";
import * as Yup from "yup";
import { RequestTerminalRequest } from "../Services/terminalsApi.types";

interface PropsResponse {
    terminalRequestFormFormInit: RequestTerminalRequest;
    terminalRequestValidation: Yup.AnyObject;
}

export function getFormInit(): PropsResponse {
    return {
        terminalRequestFormFormInit: {
            debitAccountId: "",
            deliveryAddress: "",
            estimatedDailyTransactionCount: "",
            estimatedDailyTransactionVolume: "",
            numUnits: "1",
            purchaseType: PurchaseType.OUTRIGHT_PURCHASE,
        },
        terminalRequestValidation: Yup.object().shape({
            debitAccountId: Yup.string().required("Required"),
            deliveryAddress: Yup.string().required("Required"),
            estimatedDailyTransactionCount: Yup.string().required("Required"),
            estimatedDailyTransactionVolume: Yup.string().required("Required"),
            numUnits: Yup.number().required("Required"),
            purchaseType: Yup.number().required("Required"),
        }),
    };
}
