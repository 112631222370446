import { ReactComponent as CalendarIcon } from "../../assets/svg/calendar.svg";
import Input from "./Input";
import React from "react";

interface Props {
    value: string;
    onChange: (_value: string) => void;
    placeholder?: string;
    inputPlaceholder?: string;
    hidePrefixIcon?: boolean;
}

function ExpiryInput({ value, placeholder, inputPlaceholder, hidePrefixIcon = false, onChange }: Props): JSX.Element {
    return (
        <Input
            placeholder={placeholder}
            name="expiryDate"
            icon={!hidePrefixIcon ? <CalendarIcon /> : undefined}
            onChange={onChange}
            inputPlaceholder={inputPlaceholder ? undefined : "MM/YY"}
            type="text"
            value={value}
            isExpiry
        />
    );
}

export default ExpiryInput;
