import Clipboard from "../../../../../../components/general/clipboard/Clipboard";
import CardInformation from "../../../../../../models/cardInformation";
import { IRootState } from "../../../../../../redux/rootReducer";
import React from "react";
import { useSelector } from "react-redux";

export default function CardInformationDetails({ info, type }: { info: CardInformation; type: string }): JSX.Element {
    const showCvv = useSelector((state: IRootState) => state.cards.showCVV);
    return (
        <div className="flex w-full items-center justify-between">
            <div>
                <div className="relative h-6 text-lg font-medium tracking-widest">
                    <Clipboard text={info.pan} withCopyIcon={false}>
                        <div className="flex h-full w-full flex-row space-x-1.5 border-b border-dashed border-transparent leading-none opacity-100 hover:border-blue">
                            {info.pan}
                        </div>
                    </Clipboard>
                </div>

                <div className="flex w-full flex-row items-center justify-start space-x-4 text-sm text-black-secondary">
                    {info.cvv && showCvv && (
                        <div className="mt-1 flex min-w-5 flex-row items-center justify-start space-x-1">
                            <span className="text-sm font-normal uppercase text-black-quat">CVV</span>
                            <span>{info.cvv}</span>
                        </div>
                    )}
                    <div className="mt-1 flex flex-row items-center justify-start space-x-1">
                        <span className="text-sm font-normal uppercase text-black-quat">Exp</span>
                        <span>{info.expiry}</span>
                    </div>
                </div>
            </div>

            {type && <span className="whitespace-nowrap text-xs font-normal text-black-secondary">{type}</span>}
        </div>
    );
}
