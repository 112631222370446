import {
    BusinessDocumentRequests,
    abortBusinessDocumentRequest,
    documentDetailsInit,
} from "../../../../services/zambia-application/registered/new/document.api";
import { resetZambiaBusinessDocuments, setZambiaBusinessDocuments } from "../../../../../../../redux/application/zambiaApplicationSlice";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

interface UseBusinessDocumentsInitInterface {
    documentDetailsError: string;
    isDocumentDetailsLoading: boolean | null;
    handleReset: () => void;
    handleDocumentDetailsInit: () => Promise<void>;
}

const useBusinessDocumentsInit = (): UseBusinessDocumentsInitInterface => {
    const dispatch = useDispatch();

    const [documentDetailsError, setDocumentDetailsError] = useState<string>("");
    const [isDocumentDetailsLoading, setIsDocumentDetailsLoading] = useState<boolean | null>(false);

    const handleDocumentDetailsInit = useCallback(async () => {
        try {
            setDocumentDetailsError("");
            setIsDocumentDetailsLoading(true);
            const res = await documentDetailsInit();
            dispatch(setZambiaBusinessDocuments(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
            setDocumentDetailsError(errorMessage);
        } finally {
            setIsDocumentDetailsLoading(false);
        }
    }, [dispatch]);

    const handleReset = useCallback(() => {
        setDocumentDetailsError("");
        setIsDocumentDetailsLoading(false);
        dispatch(resetZambiaBusinessDocuments());
        abortBusinessDocumentRequest(BusinessDocumentRequests.INIT);
    }, []);

    return { documentDetailsError, isDocumentDetailsLoading, handleReset, handleDocumentDetailsInit };
};
export default useBusinessDocumentsInit;
