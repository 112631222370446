import React from "react";
import { ReactComponent as DoubleBorderedArrowIcon } from "../../../../../assets/svg/Cards/double-bordered-arrow.svg";
import { ReactComponent as DoubleBorderedArrowDownIcon } from "../../../../../assets/svg/Cards/double-bordered-circle-down.svg";
import UserAccount from "../../../../../models/userAccount";
import Money from "../../../../../components/money";

interface Props {
    account?: UserAccount | null;
}

export default function AccountLimitCard({ account }: Props) {
    return (
        <div className="flex flex-col gap-x-4 gap-y-4 sm:flex-row">
            <div className="flex min-h-32 w-full flex-col justify-between gap-y-5 rounded-lg border border-grey-secondary px-4 py-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-md bg-blue-backdrop">
                    <DoubleBorderedArrowIcon />
                </div>
                <div className="gap-y-2">
                    <h3 className="text-black-black text-sm font-medium">Payout Limits:</h3>
                    <p className="text-xs text-black-secondary">
                        <Money amount={account?.nipDailyTransferLimit || 0} currency={account?.bankAccountCurrency} /> Daily
                    </p>
                </div>
            </div>
            <div className="flex min-h-32 w-full flex-col justify-between gap-y-5 rounded-lg border border-grey-secondary px-4 py-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-md">
                    <DoubleBorderedArrowDownIcon />
                </div>
                <div className="gap-y-2">
                    <h3 className="text-black-black text-sm font-medium">Inflow Limits:</h3>
                    <p className="text-xs text-black-secondary">Unlimited</p>
                </div>
            </div>{" "}
        </div>
    );
}
