import { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../button/ButtonComp";
import Modal from "../../../../../modal/Modal";
import ModalBody from "../../../../../modal/modal-body";
import ModalFooter from "../../../../../modal/modal-footer";
import ModalHeader from "../../../../../modal/modal-header";
import { PingTime } from "../../../../../../helpers/ping/service/ping-api.constant";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router";
import useLogout from "../../../../../init/hooks/use-logout";
import usePing from "../../../../../../helpers/ping/hook/use-ping";

function IdleModal(): JSX.Element {
    const location = useLocation();

    const { mutate: mutatePing } = usePing();
    const { mutate: mutateLogout } = useLogout();

    const [isIdle, setIsIdle] = useState(false);
    const [counter, setCounter] = useState(59);

    const idleTimeRef = useRef(null);
    const countDownTimeoutRef = useRef<number | undefined>(undefined);

    useEffect(() => {
        if (!counter || !isIdle) return;
        countDownTimeoutRef.current = window.setInterval(() => setCounter(counter - 1), 1000);
        return () => window.clearInterval(countDownTimeoutRef.current);
    }, [counter, isIdle]);

    const handleStayActive = useCallback(() => {
        mutatePing();
        setIsIdle(false);
        setCounter(59);
        window.clearInterval(countDownTimeoutRef.current);
    }, [countDownTimeoutRef.current]);

    const handleIdle = useCallback(() => {
        setIsIdle(true);
    }, []);

    useEffect(() => {
        if (counter < 1 && isIdle) {
            mutateLogout();
        }
    }, [counter, isIdle]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useIdleTimer({
        crossTab: true,
        ref: idleTimeRef,
        // timeout: location.pathname === "/application" ? 10 * 1000 : 10 * 1000,
        timeout: location.pathname === "/application" ? PingTime.MAIN_APP_IDLE * 60 * 1000 : PingTime.MAIN_APP_IDLE * 60 * 1000,
        onActive: () => !isIdle && handleStayActive(),
        onIdle: handleIdle,
    });

    return (
        <>
            <div className={`fixed z-80 h-screen w-screen ` + `${isIdle ? "opacity-100" : "pointer-events-none opacity-0"}`}>
                <Modal size="fit" active={isIdle} toggler={handleStayActive}>
                    <ModalHeader onClose={handleStayActive}>Warning</ModalHeader>
                    <ModalBody>
                        <div className="w-full text-left text-black-secondary">
                            Your session will timeout in{" "}
                            <span className="text-error">
                                {" "}
                                {counter < 10 && "0"}
                                {counter}s{" "}
                            </span>{" "}
                            due <br />
                            to inactivity.
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonComp color="red" buttonType="primary" onClick={() => mutateLogout()} isText>
                            Logout
                        </ButtonComp>
                        <ButtonComp color="black" buttonType="primary" onClick={handleStayActive} isText>
                            Continue
                        </ButtonComp>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    );
}

export default IdleModal;
