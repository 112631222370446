import React, { useEffect } from "react";

import CustomSelect from "../../../../../components/dropdown/custom-select";
import SimpleModal from "../../../../../components/modal/simple-modal";
import useSetRecipientGroup from "../../hooks/state/SavedRecipient/useSetRecipientGroup";
import useUserGroups from "../../hooks/state/SavedRecipient/useUserGroups";

function SetRecipientGroupModal(): JSX.Element {
    const data = useSetRecipientGroup();
    const { userGroups, selectedUserGroup, setSelectedUserGroup } = useUserGroups();

    useEffect(() => {
        setSelectedUserGroup(data.recipient?.customerAccount.userGroup || null);
    }, [data.recipient]);

    return (
        <SimpleModal
            active={data.isModalOpen}
            onClose={data.closeModal}
            isSubmitting={data.isSubmitting}
            onSubmit={() => {
                if (data.recipient && selectedUserGroup) {
                    void data.handleSetRecipientGroup(data.recipient, selectedUserGroup);
                }
            }}
            errorMessage={data.errorMessage}
            header="Set Group"
            canSubmit={!!selectedUserGroup}
            confirmText="Update"
        >
            <CustomSelect
                placeholder="Select Group"
                value={selectedUserGroup?.id}
                options={userGroups.map((u) => ({
                    value: u.id,
                    text: u.name,
                }))}
                onSelect={(value) => {
                    if (!value || !data.recipient) {
                        return;
                    }

                    const userGroup = userGroups.find((u) => u.id === value);
                    if (!userGroup) {
                        return;
                    }

                    setSelectedUserGroup(userGroup);
                }}
            />
        </SimpleModal>
    );
}

export default SetRecipientGroupModal;
