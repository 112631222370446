import {
    CurrentSignatoryRequests,
    abortCurrentSignatoryRequest,
    bioUpdate,
} from "../../../../services/nigeria-application/registered/currentSignatory.api";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import { Routes } from "../../../../../../../routes/routes.constants";
import { SignatoryPersonalDetailsForm } from "../../../../services/nigeria-application/registered/currentSignatory.types";
import { errorTrue, messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { updateCurrentSignatory } from "../../../../../../../redux/application/applicationSlice";
import { useLocation, useNavigate } from "react-router";

export interface UseBioInterface {
    isBioError: string | null;
    isBioLoading: boolean | null;
    handleReset: () => void;
    handleUpdateBio: (_data: SignatoryPersonalDetailsForm) => Promise<void>;
}

type LocationState = {
    from?: string;
};

const useBio = (): UseBioInterface => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const isRegistered = useSelector((state: IRootState) => state.application.init?.applicationMin.isRegistered);

    const [isBioError, setIsBioError] = useState<string | null>(null);
    const [isBioLoading, setIsBioLoading] = useState<boolean | null>(false);

    const handleUpdateBio = useCallback(
        async (_data: SignatoryPersonalDetailsForm): Promise<void> => {
            try {
                setIsBioError(null);
                setIsBioLoading(true);
                const res = await bioUpdate(_data);
                // dispatch(messageTrue({message: "Personal Details Submitted Successful"}));
                if (res.signatory) {
                    dispatch(updateCurrentSignatory(res));
                }
                if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                    dispatch(messageTrue("Personal details updated successfully"));
                    return;
                }
                navigate(
                    {
                        pathname: isRegistered
                            ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.NEXT_OF_KIN
                            : Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID,
                    },
                    {
                        state: {
                            from: isRegistered
                                ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS
                                : Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL,
                        },
                    }
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setIsBioError(errorMessage);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsBioLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsBioError(null);
        setIsBioLoading(false);
        abortCurrentSignatoryRequest(CurrentSignatoryRequests.BIO_UPDATE);
    }, []);

    return {
        isBioError,
        isBioLoading,
        handleReset,
        handleUpdateBio,
    };
};
export default useBio;
