/* eslint-disable @typescript-eslint/no-misused-promises */
import { FilterDateObj, FilterDateType, FilterDateTypes } from "../../../../../components/filter/filter.constant";
import { TransactionStatus, TransactionType } from "../../../../../models/transaction.constants";
import { ZambiaPayoutsStatusOptions, ZambiaPayoutsTypeOptions } from "../../../lenco-pay/hookss/payoutss/zambia-payouts.constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import DateModal from "../../../../../components/modal/date-modal";
import { DateParse } from "../../../../../utils/dateParsers";
import ExportModal from "../../../../../components/modal/export-modal";
import FullPageTray from "../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import Input from "../../../../../components/inputs/Input";
import MultiSelectDropdown from "../../../../../components/dropdown/multi-select-dropdown";
import { setIsExportZambiaTransactionsTrayOpen } from "../../../../../redux/transactions/slice/transactionsSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import useClickOutside from "../../../../../hooks/useClickOutside";
import useExportZambiaTransactions from "../../Hooks/State/useExportZambiaTransactions";

const DATA_TYPE = "export-zambia-transaction";

function ExportZambiaTransactionsTray(): JSX.Element {
    const dispatch = useDispatch();

    const cards = useSelector((state: IRootState) => state.init.main?.companyDetails?.cards || []);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts || []);
    const userGroups = useSelector((state: IRootState) => state.init.main?.companyDetails.userGroups || []);
    const categories = useSelector((state: IRootState) => state.init.main?.companyDetails.transactionCategories || []);

    const isExportZambiaTransactionsTrayOpen = useAppSelector((state) => state.transactions.isExportZambiaTransactionsTrayOpen);

    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [isExportedModalOpen, setIsExportedModalOpen] = useState(false);

    const [exportFilter, setExportFilter] = useState<{
        query: string;
        offset: number;
        userAccountIds: string[];
        transactionTypes: TransactionType[];
        transactionStatuses: TransactionStatus[];
        userGroupIds: string[];
        categoryIds: string[];
        cardIds: string[];
        selectedDateType: FilterDateTypes;
        date: FilterDateObj;
    }>({
        query: "",
        offset: 0,
        userAccountIds: [],
        transactionTypes: [],
        transactionStatuses: [],
        userGroupIds: [],
        cardIds: [],
        categoryIds: [],
        selectedDateType: FilterDateTypes.ALL_TIME,
        date: {
            begin: null,
            end: null,
        },
    });

    const { isExportZambiaTransactionsLoading, handleExportZambiaTransactions } = useExportZambiaTransactions({
        onComplete: () => {
            setIsExportedModalOpen(true);
            dispatch(setIsExportZambiaTransactionsTrayOpen(false));
        },
    });

    useEffect(() => {
        if (!isExportZambiaTransactionsTrayOpen) {
            setTimeout(() => {
                setExportFilter({
                    query: "",
                    offset: 0,
                    userAccountIds: [],
                    transactionTypes: [],
                    transactionStatuses: [],
                    userGroupIds: [],
                    cardIds: [],
                    categoryIds: [],
                    selectedDateType: FilterDateTypes.ALL_TIME,
                    date: {
                        begin: null,
                        end: null,
                    },
                });
            }, 500);
        }
    }, [isExportZambiaTransactionsTrayOpen]);

    const handleCloseExportZambiaTransactionsTray = () => {
        dispatch(setIsExportZambiaTransactionsTrayOpen(false));
    };

    const domNode = useClickOutside(
        () => {
            handleCloseExportZambiaTransactionsTray();
        },
        (eventTarget: HTMLElement) => {
            return !!(eventTarget.dataset.type !== DATA_TYPE || !eventTarget.dataset.type.includes(DATA_TYPE));
        },
        ["mousedown", "keydown"]
    );

    return (
        <>
            {/*<===Terminal Transaction Details Code goes here===>*/}
            {/* {zambiaTransactionsDetail && ( */}
            <div className="w-full" ref={domNode}>
                <DateModal
                    isActive={isDateModalOpen}
                    is3MonthsMax
                    dataType={DATA_TYPE}
                    handleToggleModal={() => setIsDateModalOpen(false)}
                    handleSelectDate={(_data: FilterDateType) =>
                        setExportFilter((prev) => ({
                            ...prev,
                            selectedDateType: _data.name,
                            date: _data.date,
                        }))
                    }
                />
                <ExportModal active={isExportedModalOpen} handleClose={() => setIsExportedModalOpen(false)} />
                <FullPageTray
                    active={isExportZambiaTransactionsTrayOpen}
                    dataType={DATA_TYPE}
                    handleReset={handleCloseExportZambiaTransactionsTray}
                    // withClickOutside
                >
                    <FullPageTrayHeader toggler={handleCloseExportZambiaTransactionsTray} dataType={DATA_TYPE}>
                        <p className="text-2xl font-medium text-black">Export</p>
                    </FullPageTrayHeader>
                    <FullPageTrayBody dataType={DATA_TYPE}>
                        <div className="mb-10 flex w-full flex-col space-y-4">
                            <div className="cursor-pointer" onClick={() => setIsDateModalOpen(true)}>
                                <Input
                                    type="text"
                                    placeholder={
                                        <>
                                            {/* <div className="flex items-center justify-start space-x-2"> */}
                                            <p>Date</p>
                                            {/* </div> */}
                                        </>
                                    }
                                    name="date"
                                    value={
                                        exportFilter.date.begin && exportFilter.date.end
                                            ? `From ${DateParse.getDateString(exportFilter.date.begin)} To ${DateParse.getDateString(
                                                  exportFilter.date.end
                                              )}`
                                            : undefined
                                    }
                                    appendOuterIcon={<GoogleIcon icon="calendar_month" />}
                                    readOnly
                                />
                            </div>

                            <MultiSelectDropdown
                                placeholder="Accounts"
                                value={exportFilter.userAccountIds}
                                options={accounts.map((item) => ({
                                    text: item.lencoNameMin,
                                    value: item.id,
                                }))}
                                onChange={(value) => {
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        userAccountIds: value,
                                    }));
                                }}
                                size="xl"
                            />
                            <MultiSelectDropdown
                                placeholder="Status"
                                value={exportFilter.transactionStatuses}
                                options={ZambiaPayoutsStatusOptions.map((item) => ({
                                    text: item.name,
                                    value: item.value,
                                }))}
                                onChange={(value) => {
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        transactionStatuses: value,
                                    }));
                                }}
                                size="xl"
                            />

                            <MultiSelectDropdown
                                placeholder="Types"
                                value={exportFilter.transactionTypes}
                                options={ZambiaPayoutsTypeOptions.map((item) => ({
                                    text: item.name,
                                    value: item.value,
                                }))}
                                onChange={(value) => {
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        transactionTypes: value,
                                    }));
                                }}
                                size="xl"
                            />

                            <MultiSelectDropdown
                                placeholder="Recipients"
                                value={exportFilter.userGroupIds}
                                options={userGroups.map((item) => ({
                                    text: item.name,
                                    value: item.id,
                                }))}
                                onChange={(value) => {
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        userGroupIds: value,
                                    }));
                                }}
                                size="xl"
                            />

                            <MultiSelectDropdown
                                placeholder="Categories"
                                value={exportFilter.categoryIds}
                                options={categories.map((item) => ({
                                    text: item.name,
                                    value: item.id,
                                }))}
                                onChange={(value) => {
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        categoryIds: value,
                                    }));
                                }}
                                size="xl"
                            />

                            {cards.length > 0 && (
                                <MultiSelectDropdown
                                    placeholder="Cards"
                                    value={exportFilter.cardIds}
                                    options={cards.map((item) => ({
                                        text: item.name,
                                        value: item.id,
                                    }))}
                                    onChange={(value) => {
                                        setExportFilter((prev) => ({
                                            ...prev,
                                            cardIds: value,
                                        }));
                                    }}
                                />
                            )}
                        </div>
                        <div className="mb-8 mt-24 flex w-full items-center justify-center">
                            <div className="flex w-full flex-col space-y-4 2xs:flex-row 2xs:space-x-4 2xs:space-y-0" data-type={DATA_TYPE}>
                                <div className="w-full" data-type={DATA_TYPE}>
                                    <ButtonComp
                                        type="button"
                                        buttonType="secondary"
                                        color="grey"
                                        ripple="light"
                                        fullWidth
                                        func={handleCloseExportZambiaTransactionsTray}
                                        dataType={DATA_TYPE}
                                    >
                                        <span data-type={DATA_TYPE}>Cancel</span>
                                    </ButtonComp>
                                </div>
                                <div className="w-full" data-type={DATA_TYPE}>
                                    <ButtonComp
                                        type="button"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        fullWidth
                                        dataType={DATA_TYPE}
                                        isLoading={isExportZambiaTransactionsLoading}
                                        disable={
                                            exportFilter.cardIds.length < 1 &&
                                            exportFilter.categoryIds.length < 1 &&
                                            exportFilter.transactionStatuses.length < 1 &&
                                            exportFilter.transactionTypes.length < 1 &&
                                            exportFilter.userAccountIds.length < 1 &&
                                            exportFilter.userGroupIds.length < 1 &&
                                            !exportFilter.date.begin
                                        }
                                        func={() =>
                                            void handleExportZambiaTransactions({
                                                date: exportFilter.date,
                                                query: exportFilter.query,
                                                offset: exportFilter.offset,
                                                cardIds: exportFilter.cardIds,
                                                categoryIds: exportFilter.categoryIds,
                                                transactionStatuses: exportFilter.transactionStatuses,
                                                transactionTypes: exportFilter.transactionTypes,
                                                userAccountIds: exportFilter.userAccountIds,
                                                userGroupIds: exportFilter.userGroupIds,
                                            })
                                        }
                                    >
                                        <span data-type={DATA_TYPE}>Export</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </div>
                    </FullPageTrayBody>
                </FullPageTray>
            </div>
        </>
    );
}

export default ExportZambiaTransactionsTray;
