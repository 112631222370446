import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    resetAllTerminalTransactionsData,
    setIsTerminalTransactionsDetailCardOpen,
    setSelectedTerminalTransactionsDetails,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import { TableHead } from "../../../../../components/Table/Type/HeadRow";
import Table from "../../../../../components/Table";
import { TableColumnType } from "../../../../../components/Table/Type/TableColumnType";
import useTerminalsTransactions from "../../Hooks/State/useTerminalsTransactions";
import { useAppSelector } from "../../../../../redux/hooks";
import { TextType } from "../../../../../components/Table/Type/TextType";
import { SubTextType } from "../../../../../components/Table/Type/SubTextType";
import { BasicFinalStatus } from "../../../../../models/posTransaction.constants";
import TerminalTransactionSummary from "../../Elements/Card/TerminalSummary/TerminalTransactionSummary";
import { TERMINAL_TRANSACTION_DATATYPE } from "../Cards/TerminalTransactionDetailsCard/TerminalTransactionDetailsCard";

const terminalTransactionsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "From", pos: 2, headType: TableColumnType.TEXT },
    { text: "Terminal Name", pos: 3, headType: TableColumnType.TEXT },
    { text: "Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Amount", pos: 5, headType: TableColumnType.AMOUNT },
    { text: "Status", pos: 6, headType: TableColumnType.STATUS },
];

function AllTerminalsTransactions(): JSX.Element {
    const dispatch = useDispatch();

    const { handlePaginationRequest, handleFilteredTerminalTransactions, filterOption, filter, handleClearFilter } = useTerminalsTransactions();

    const terminalsTransactions = useAppSelector((state) => state.terminals.transactions);
    const isTransactionPaginationLoading = useAppSelector((state) => state.terminals.isTransactionPaginationLoading);
    const terminalTransactionsListTotal = useAppSelector((state) => state.terminals.transactionsListTotal);
    const terminalTransactionsListGroupSize = useAppSelector((state) => state.terminals.transactionsListGroupSize);
    const terminalTransactionPaginationOffset = useAppSelector((state) => state.terminals.transactionPaginationOffset);

    const handleOpenTerminalTransaction = useCallback((transactionId: string) => {
        dispatch(setIsTerminalTransactionsDetailCardOpen(true));
        dispatch(setSelectedTerminalTransactionsDetails(transactionId));
    }, []);

    useEffect(() => {
        void handleFilteredTerminalTransactions({ offset: 0 });
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetAllTerminalTransactionsData());
        };
    }, []);

    return (
        <>
            <TerminalTransactionSummary />

            <div className="relative flex h-full w-full flex-col items-start justify-start">
                <Table
                    addFilter
                    filter={filterOption}
                    handleClearFilter={handleClearFilter}
                    totalSelectedItems={(filter.startDate ? 1 : 0) + (filter.statuses?.length || 0) + (filter.types?.length || 0)}
                    dataType={TERMINAL_TRANSACTION_DATATYPE}
                    heads={terminalTransactionsHeader}
                    isPaginateLoading={false}
                    groupSize={terminalTransactionsListGroupSize}
                    offset={terminalTransactionPaginationOffset}
                    total={terminalTransactionsListTotal}
                    isLoading={!!isTransactionPaginationLoading}
                    rows={terminalsTransactions?.map((transaction) => ({
                        onRecordClick: () => handleOpenTerminalTransaction(transaction.id),
                        record: [
                            {
                                key: "Date",
                                text: transaction.datetime,
                                textType: TextType.DATE,
                                subText: transaction.datetime,
                                subTextType: SubTextType.TIME,
                            },
                            {
                                key: "From",
                                text: transaction?.transferIn?.sender || transaction?.cardPayment?.maskedPan,
                                textType: TextType.STRING,
                            },
                            {
                                key: "Terminal Name",
                                text: transaction.terminal?.serialNumber || transaction.terminal?.name || "",
                                textType: TextType.STRING,
                            },
                            {
                                key: "Type",
                                text: transaction.getType,
                                textType: TextType.STRING,
                            },
                            {
                                key: "Amount",
                                text: transaction.transactionAmount,
                                textType: transaction.status === BasicFinalStatus.FAILED ? TextType.BALANCE_STRIKE : TextType.BALANCE,
                            },
                            {
                                key: "Status",
                                text: transaction.getStatus,
                                textType:
                                    transaction.status === BasicFinalStatus.FAILED
                                        ? TextType.TRANSACTION_STATUS_FAILED
                                        : transaction.status === BasicFinalStatus.SUCCESSFUL
                                          ? TextType.TRANSACTION_STATUS_SUCCESS
                                          : TextType.BALANCE,
                            },
                        ],
                    }))}
                    paginateFunction={(_, pageOffset) => handlePaginationRequest(pageOffset)}
                />
            </div>
        </>
    );
}

export default AllTerminalsTransactions;
