import User from "../../../../models/user";

export enum LencoPayAccessLevel {
    VIEWER = "viewer",
    MANAGER = "manager",
}

export const LencoPayAccessLevels = [LencoPayAccessLevel.VIEWER, LencoPayAccessLevel.MANAGER];

export type LencoPayAccessForm = {
    memberIds: string[];
    accessLevel: LencoPayAccessLevel | null;
};

export enum LencoPayAccessStage {
    RESET = 0,
    LIST_OF_TEAM_MEMBERS = 1,
    CREATE_ACCESS = 2,
    DONE = 3,
}

export type RemoveLencoPayAccess = {
    memberId: string;
};

export type LencoPayManagerAccessResponse = {
    success: boolean;
    message: string;
    manageAccess: User[];
};

export type LencoPayViewAccessResponse = {
    success: boolean;
    message: string;
    viewAccess: User[];
};

export const LencoPayAccessLevelText = { [LencoPayAccessLevel.VIEWER]: "Viewer", [LencoPayAccessLevel.MANAGER]: "Manager" };
