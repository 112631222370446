import { useEffect, useState } from "react";

import ButtonComp from "../../../../components/button/ButtonComp";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import LoginFooter from "../../../../components/layouts/components/landing/login-footer";
import Otp from "../../../../components/otp";
import { OtpRecipientItem } from "../servicess/resetPasswordApi.types";
import { ResetForgotUrlParams } from "../hookss/statee/useResetForgotPasswordState";
import { UseResetPasswordVerifyOtpInterface } from "../hookss/statee/useResetPasswordVerifyOtp";

interface Props {
    params: UseResetPasswordVerifyOtpInterface;
    recipient: OtpRecipientItem | null;
    baseParams: ResetForgotUrlParams;
}

function ResetPasswordEnterOtpForm({ params, baseParams, recipient }: Props): JSX.Element {
    const [otp, setOtp] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!otp || otp.length < 6) return;
        void params.handleSubmitForm({ ...baseParams, otp });
    }, [otp]);

    return (
        <>
            <div className="relative mx-auto flex w-full max-w-[600px] flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex w-full flex-col items-center justify-start space-y-4">
                    <h3 className="break-words text-center text-2xl font-medium text-black">Reset Your Password</h3>
                    <p className="break-words text-center text-sm font-normal text-black-secondary">
                        Enter the OTP {recipient && <>that was sent to {recipient.text}</>}
                    </p>
                </div>

                {params.errorMessage && (
                    <div className="mb-4 mt-4 flex w-max flex-col items-center justify-center text-center">
                        <ErrorToast error={params.errorMessage} />
                    </div>
                )}

                <Otp value={otp} onChange={setOtp} isError={otp !== undefined && otp.length !== 6} />

                <ButtonComp color="black" buttonType="primary" disable={!otp || otp.length !== 6} isLoading={params.isSubmitting} fullWidth>
                    Send OTP
                </ButtonComp>
            </div>
            <LoginFooter />
        </>
    );
}

export default ResetPasswordEnterOtpForm;
