import ButtonComp from "../../../../../components/button/ButtonComp";
import React from "react";
import UserAccount from "../../../../../models/userAccount";
import getBankName from "../../../../../helpers/getBankName";
import titleCase from "../../../../../hooks/titleCase";

interface Props {
    data?: UserAccount;
    handleActivate: () => void;
}

function ActivateSubAccountCard(props: Props): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-row items-center justify-between rounded-lg border p-4">
                <div className="flex flex-col items-start space-y-2 text-start text-sm text-black-secondary">
                    <span>{titleCase(props?.data?.subAccountShortName || props?.data?.accountName || "")}</span>
                    <span className="leading-none">{getBankName(props?.data?.bankAccount?.bankCode)}</span>
                    <span className="leading-none">{props?.data?.bankAccount?.accountNumber}</span>
                </div>
                <div>
                    {!props?.data?.isActivated && (
                        <ButtonComp buttonType="tertiary" color="blue" func={props?.handleActivate} size="sm">
                            <span>Activate</span>
                        </ButtonComp>
                    )}
                    {props?.data?.isActivated && (
                        <>
                            <span className="text-sm text-success">Activated</span>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default ActivateSubAccountCard;
