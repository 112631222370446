import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CreateApiAccessKeyRequest } from "../../../../../Services/ApiAccessKeys/apiAccessKeysApi.types";
import FormInput from "../../../../../../../../components/inputs/FormInput";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoButton from "../../../../../../../../components/button/button";
import Modal from "../../../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../../../components/modal/modal-header";
import { setIsZambiaCreateApiAccessKeyModalOpen } from "../../../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import useCreateZambiaApiAccessKey from "../../../../../hookss/ApiAccessKeys/useCreateZambiaApiAccessKey";

const DATA_TYPE = "api-access-key";

function CreateApiKeyModal(): JSX.Element {
    const dispatch = useDispatch();

    const { handleCreateZambiaApiAccessKey, isCreateZambiaApiAccessKeyLoading } = useCreateZambiaApiAccessKey();

    const isCreateApiAccessKeyModalOpen = useSelector((state: IRootState) => state.zambiaApiAccessKeys.isCreateApiAccessKeyModalOpen);

    const formikRef = useRef<FormikProps<CreateApiAccessKeyRequest> | null>(null);

    const INITIAL_FORM_STATE = {
        name: "",
    };
    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required(),
    });

    const handleToggle = () => {
        dispatch(setIsZambiaCreateApiAccessKeyModalOpen(false));
    };

    useEffect(() => {
        if (!isCreateApiAccessKeyModalOpen) {
            setTimeout(() => {
                formikRef.current?.setFieldValue("name", "");
            }, 500);
        }
    }, [isCreateApiAccessKeyModalOpen]);

    return (
        <>
            <Modal size="md" active={isCreateApiAccessKeyModalOpen} toggler={handleToggle} dataType={DATA_TYPE}>
                <ModalHeader onClose={handleToggle} subTitle="Create a new API Key" dataType={DATA_TYPE}>
                    API Key
                </ModalHeader>

                <Formik
                    initialValues={INITIAL_FORM_STATE}
                    innerRef={formikRef}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => {
                        handleCreateZambiaApiAccessKey(values);
                    }}
                    enableReinitialize
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <Form className="w-full" tabIndex={-1} data-type={DATA_TYPE}>
                                <ModalBody dataType={DATA_TYPE}>
                                    <FormInput type="text" name="name" placeholder="API Name" inputSize="lg" dataType={DATA_TYPE} />
                                </ModalBody>
                                <ModalFooter dataType={DATA_TYPE}>
                                    <LencoButton type="button" variant="bordered" dataType={DATA_TYPE} onClick={handleToggle}>
                                        <span data-type={DATA_TYPE}>Create API Key</span>
                                    </LencoButton>

                                    <LencoButton
                                        type="submit"
                                        variant="solid"
                                        dataType={DATA_TYPE}
                                        isDisabled={!!formik.errors.name}
                                        isLoading={isCreateZambiaApiAccessKeyLoading}
                                    >
                                        <span data-type={DATA_TYPE}>Create API Key</span>
                                    </LencoButton>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}

export default CreateApiKeyModal;
