import {
    ZambiaCollectionFeeBearer,
    ZambiaCollectionPaymentChannel,
    ZambiaCollectionSource,
    ZambiaCollectionStatus,
} from "./zambiaCollectionRequest.constant";

import ApiRequestPayment from "../apiRequestPayment";
import Currency from "../currency";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import RequestPayment from "../requestPayment";
import RequestPaymentAttempt from "./zambiaRequestPaymentAttempt";
import ZambiaCollectionSettlement from "./zambiaCollectionSettlement";
import ZambiaLencoFeeData from "./zambiaLencoFeeData";
import { immerable } from "immer";

export default class ZambiaCollectionRequest {
    [immerable] = true;

    constructor(
        public bankingAppRequestPayment: RequestPayment | null,
        public apiRequestPayment: ApiRequestPayment | null,
        public attempts: RequestPaymentAttempt[],
        public settlement: ZambiaCollectionSettlement | null,
        public id: string,
        public createdAt: Date | null,
        public amount: string,
        public currency: Currency,
        public channels: ZambiaCollectionPaymentChannel[],
        public status: ZambiaCollectionStatus | null,
        public paymentReference: string,
        public source: ZambiaCollectionSource | null,
        public completedAt: Date | null,
        public failedReason: string | null,
        public lencoFee: string | null,
        public lencoFeeData: ZambiaLencoFeeData | null,
        public settlementAmount: string | null,
        public feeBearer: ZambiaCollectionFeeBearer | null
    ) {}

    static create(obj: GenericObject): ZambiaCollectionRequest {
        return new ZambiaCollectionRequest(
            Parsers.classObject(obj.bankingAppRequestPayment, RequestPayment),
            Parsers.classObject(obj.apiRequestPayment, ApiRequestPayment),
            Parsers.classObjectArray(obj.attempts, RequestPaymentAttempt),
            Parsers.classObject(obj.settlement, ZambiaCollectionSettlement),
            Parsers.string(obj.id),
            Parsers.date(obj.createdAt),
            Parsers.string(obj.amount),
            Parsers.classObjectNonNullable(obj.currency, Currency),
            Parsers.nullableEnumArray(obj.channels, ZambiaCollectionPaymentChannel),
            Parsers.nullableEnum(obj.status, ZambiaCollectionStatus),
            Parsers.string(obj.paymentReference),
            Parsers.nullableEnum(obj.source, ZambiaCollectionSource),
            Parsers.date(obj.completedAt),
            Parsers.nullableString(obj.failedReason),
            Parsers.nullableString(obj.lencoFee),
            Parsers.classObject(obj.lencoFeeData, ZambiaLencoFeeData),
            Parsers.nullableString(obj.settlementAmount),
            Parsers.nullableEnum(obj.feeBearer, ZambiaCollectionFeeBearer)
        );
    }

    get channelText(): string {
        return this.channels[0] === ZambiaCollectionPaymentChannel.BANK_ACCOUNT
            ? "Bank Account"
            : this.channels[0] === ZambiaCollectionPaymentChannel.CARD
              ? "Card"
              : this.channels[0] === ZambiaCollectionPaymentChannel.MOBILE_MONEY
                ? "Mobile Money"
                : "";
    }

    get isSuccessful(): boolean {
        return this.status === ZambiaCollectionStatus.SUCCESSFUL;
    }

    get isFailed(): boolean {
        return this.status === ZambiaCollectionStatus.FAILED;
    }

    get isPending(): boolean {
        return this.status === ZambiaCollectionStatus.PENDING;
    }

    get isExpired(): boolean {
        return this.status === ZambiaCollectionStatus.EXPIRED;
    }
    get isCancelled(): boolean {
        return this.status === ZambiaCollectionStatus.CANCELLED;
    }

    get isApi(): boolean {
        return this.source === ZambiaCollectionSource.API;
    }

    get isBanking(): boolean {
        return this.source === ZambiaCollectionSource.BANKING_APP;
    }
    get statusText(): string {
        return this.isSuccessful
            ? "Success"
            : this.isFailed
              ? "Failed"
              : this.isPending
                ? "Pending"
                : this.isCancelled
                  ? "Cancelled"
                  : this.isExpired
                    ? "Expired"
                    : "";
    }
}
