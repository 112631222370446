import React, { useEffect, useState } from "react";

import BusinessHead from "./component/business-head";
import BusinessItem from "./component/business-item";
import ButtonComp from "../../../button/ButtonComp";
import { CompanyInterface } from "../../../../helpers/types";
import { ReactComponent as EmptyX } from "../../../../assets/images/empty/empty-x.svg";
import LandingLayout from "../../../layouts/landing-layout";
import { LencoActiveCountries } from "../../../../redux/init/slice/initSlice.types";
import NigeriaFlag from "../../../../assets/images/countries/nigeria.png";
import SearchBar from "../../../search-bar";
import SelectBusinessSkeleton from "./component/skeleton/select-business-skeleton";
import ZambiaFlag from "../../../../assets/images/countries/zambia.png";
import { useAppSelector } from "../../../../redux/hooks";
import useLogout from "../../hooks/use-logout";

interface SelectBusinessModelProps {
    accountCompaniesData?: CompanyInterface[] | null;
    selectCompanyFunc?: ((id: string) => void) | null;
}

const enum SelectedCountryBusiness {
    NIGERIA = "nigeria",
    ZAMBIA = "zambia",
}

function SelectBusinessModal({ accountCompaniesData = null, selectCompanyFunc = null }: SelectBusinessModelProps): JSX.Element {
    const isZambia = useAppSelector((state) => state.init.account?.companies[0].country === LencoActiveCountries.ZAMBIA);

    const [selectedCountry, setSelectedCountry] = useState<SelectedCountryBusiness | null>(
        isZambia ? SelectedCountryBusiness.ZAMBIA : SelectedCountryBusiness.NIGERIA
    );

    const [nigerianCompanies, setNigerianCompanies] = useState<CompanyInterface[]>(
        accountCompaniesData?.filter((_company) => _company.country === LencoActiveCountries.NIGERIA) || []
    );
    const [zambianCompanies, setZambianCompanies] = useState<CompanyInterface[]>(
        accountCompaniesData?.filter((_company) => _company.country === LencoActiveCountries.ZAMBIA) || []
    );

    const [searchTerm, setSearchTerm] = useState("");

    const { mutate: logoutMutate, isPending: isLogoutPending } = useLogout();

    useEffect(() => {
        if (!accountCompaniesData) return;
        if (searchTerm !== "") {
            const filteredData = accountCompaniesData.filter((item) => {
                return Object.values(item.name).join("").toLowerCase().includes(searchTerm.toLowerCase());
            });
            setNigerianCompanies(filteredData.filter((_company) => _company.country === LencoActiveCountries.NIGERIA));
            setZambianCompanies(filteredData.filter((_company) => _company.country === LencoActiveCountries.ZAMBIA));
        } else {
            setNigerianCompanies(accountCompaniesData?.filter((_company) => _company.country === LencoActiveCountries.NIGERIA));
            setZambianCompanies(accountCompaniesData?.filter((_company) => _company.country === LencoActiveCountries.ZAMBIA));
        }
    }, [searchTerm, accountCompaniesData]);

    const searchQuery = (searchValue: string) => {
        setSearchTerm(searchValue);
    };

    return (
        <>
            <LandingLayout>
                <div className="flex h-full w-full flex-col items-center justify-center space-y-6 bg-grey-backdrop">
                    <div
                        // className="flex w-full max-w-sm flex-col items-center justify-start space-y-4 overflow-hidden rounded-lg py-8 3xs:shadow"
                        className="relative mx-auto flex w-full max-w-md flex-col items-center justify-center space-y-4 rounded-lg bg-white py-4 3xs:py-8"
                    >
                        <div className="text-2xl font-medium text-black">Select a Business</div>
                        <div className="w-full px-4 pt-2">
                            {accountCompaniesData && accountCompaniesData.length > 5 && (
                                <SearchBar placeholder="Search business name" value={searchTerm || ""} onChange={(e: string) => searchQuery(e)} />
                            )}
                        </div>

                        {accountCompaniesData ? (
                            <div className="w-full">
                                {nigerianCompanies.length > 0 && (
                                    <div className="w-full">
                                        <BusinessHead
                                            country="Nigeria"
                                            isActive={selectedCountry === SelectedCountryBusiness.NIGERIA}
                                            countryAlt="Nigerian flag"
                                            countryImg={NigeriaFlag}
                                            onClick={() =>
                                                setSelectedCountry((prev) =>
                                                    prev === SelectedCountryBusiness.NIGERIA ? null : SelectedCountryBusiness.NIGERIA
                                                )
                                            }
                                        />
                                        <div
                                            className={
                                                `${selectedCountry === SelectedCountryBusiness.NIGERIA ? "max-h-76" : "max-h-0"} ` +
                                                "overflow-hidden transition-all duration-300 ease-in-out"
                                            }
                                        >
                                            <div className="flex w-full flex-col items-start justify-start">
                                                <div className="flex h-full max-h-76 w-full flex-col overflow-y-auto">
                                                    {nigerianCompanies.map((_item, index) => (
                                                        <React.Fragment key={index}>
                                                            <BusinessItem
                                                                countryId={_item.id}
                                                                countryName={_item.name}
                                                                onClick={() => (selectCompanyFunc ? selectCompanyFunc(_item.id) : null)}
                                                            />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {zambianCompanies.length > 0 && (
                                    <div className="w-full">
                                        <BusinessHead
                                            country="Zambia"
                                            isActive={selectedCountry === SelectedCountryBusiness.ZAMBIA}
                                            countryAlt="Zambia flag"
                                            countryImg={ZambiaFlag}
                                            onClick={() =>
                                                setSelectedCountry((prev) =>
                                                    prev === SelectedCountryBusiness.ZAMBIA ? null : SelectedCountryBusiness.ZAMBIA
                                                )
                                            }
                                        />

                                        <div
                                            className={
                                                `${selectedCountry === SelectedCountryBusiness.ZAMBIA ? "max-h-76" : "max-h-0"} ` +
                                                "overflow-hidden transition-all duration-300 ease-in-out"
                                            }
                                        >
                                            <div className="flex h-full max-h-76 w-full flex-col overflow-y-scroll">
                                                {zambianCompanies?.map((_item, index) => (
                                                    <React.Fragment key={index}>
                                                        <BusinessItem
                                                            countryId={_item.id}
                                                            countryName={_item.name}
                                                            onClick={() => (selectCompanyFunc ? selectCompanyFunc(_item.id) : null)}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {nigerianCompanies.length < 1 && zambianCompanies.length < 1 && (
                                    <div className="flex flex-col items-center justify-center space-y-2 py-12 text-center">
                                        <EmptyX className="h-16 w-16" />
                                        <span className="text-sm text-black-secondary">No result</span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="w-full space-y-4 px-8">
                                <SelectBusinessSkeleton />
                                <SelectBusinessSkeleton />
                            </div>
                        )}
                    </div>
                    <ButtonComp color="black" buttonType="tertiary" isLoading={isLogoutPending} onClick={() => logoutMutate()} size="sm" isText>
                        Log Out
                    </ButtonComp>
                </div>
            </LandingLayout>
        </>
    );
}

export default SelectBusinessModal;
