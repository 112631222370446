import { useCallback, useState } from "react";

import { CreateUserGroupRequest } from "../servicess/people-api.types";
import { MainInitCompanyDetailsInterface } from "../../../../redux/init/slice/initSlice.types";
import UserGroup from "../../../../models/userGroup";
import { createUserGroup } from "../servicess/peopleApi";
import { errorTrue } from "../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { mainUpdateCompanyDetails } from "../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseCreateUserGroupInterface {
    userGroupName: string;
    createUserGroupError: string;
    createUserGroupResponse: UserGroup | null;
    isCreateUserGroupLoading: boolean;

    handleResetAll: () => void;
    handleResetError: () => void;
    handleCreateUserGroup: (_data: CreateUserGroupRequest) => Promise<void>;
    handleChangeUserGroupName: (_name: string) => void;
}

function useCreateUserGroup(): UseCreateUserGroupInterface {
    const dispatch = useDispatch();

    const [userGroupName, setUserGroupName] = useState<string>("");

    const [createUserGroupError, setCreateUserGroupError] = useState<string>("");
    const [createUserGroupResponse, setCreateUserGroupResponse] = useState<UserGroup | null>(null);
    const [isCreateUserGroupLoading, setIsCreateUserGroupLoading] = useState<boolean>(false);

    const handleCreateUserGroup = useCallback(
        async (_data: CreateUserGroupRequest) => {
            try {
                setCreateUserGroupError("");
                setIsCreateUserGroupLoading(true);
                const res = await createUserGroup(_data);
                setCreateUserGroupResponse(res.userGroup);
                // add to in state.init.main.companyDetails.createUserGroups
                dispatch(
                    mainUpdateCompanyDetails({
                        callback: (companyDetails: MainInitCompanyDetailsInterface) => {
                            companyDetails.userGroups.push(res.userGroup);
                        },
                    })
                );
                dispatch(messageTrue({ message: "Group Created Successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setCreateUserGroupError(errorMessage);
            } finally {
                setIsCreateUserGroupLoading(false);
            }
        },
        [dispatch]
    );

    const handleChangeUserGroupName = useCallback((_name: string) => {
        setUserGroupName(_name);
    }, []);

    const handleResetError = useCallback(() => {
        setCreateUserGroupError("");
    }, []);

    const handleResetAll = useCallback(() => {
        setUserGroupName("");
        setCreateUserGroupError("");
        setIsCreateUserGroupLoading(false);
        setCreateUserGroupResponse(null);
    }, []);

    return {
        userGroupName,
        createUserGroupError,
        createUserGroupResponse,
        isCreateUserGroupLoading,

        handleResetAll,
        handleResetError,
        handleCreateUserGroup,
        handleChangeUserGroupName,
    };
}

export default useCreateUserGroup;
