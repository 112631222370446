import { abortAllBusinessRefereeRequests, refereeInit } from "../../../../services/nigeria-application/registered/referee.api";
import { resetReferees, setReferees } from "../../../../../../../redux/application/applicationSlice";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface UseRefereeInitInterface {
    refereesInitError: string;
    isRefereesInitLoading: boolean;

    handleReset: () => void;
    handleRefereesInit: () => Promise<void>;
}

const useRefereeInit = (): UseRefereeInitInterface => {
    const dispatch = useDispatch();

    const [refereesInitError, setRefereesInitError] = useState<string>("");
    const [isRefereesInitLoading, setIsRefereesInitLoading] = useState<boolean>(false);

    const handleRefereesInit = useCallback(async (): Promise<void> => {
        try {
            setRefereesInitError("");
            setIsRefereesInitLoading(true);
            const res = await refereeInit();
            dispatch(setReferees(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue(errorMessage));
            setRefereesInitError(errorMessage);
        } finally {
            setIsRefereesInitLoading(false);
        }
    }, [dispatch]);

    const handleReset = () => {
        setRefereesInitError("");
        setIsRefereesInitLoading(false);
        abortAllBusinessRefereeRequests();
        dispatch(resetReferees());
    };

    return {
        refereesInitError,
        isRefereesInitLoading,
        handleReset,
        handleRefereesInit,
    };
};

export default useRefereeInit;
