//Duration
export const MAIN_APP_IDLE = 10; // 10 minutes
export const CREATE_ACCOUNT_IDLE = 60; // 60 minutes
export const KEEP_ALIVE_PING = 5; // 5 minutes
export const RESEND_TOKEN = 3; // 3 minutes => for approving a transaction i.e. when to resend otp after sending one

//Icon Type
// export enum IconType {
//     SHARE = "share_icon",
//     NEW_PAYMENT = "new_payment_icon",
//     COMMENT = "comment_icon",
//     UPLOAD = "upload_icon",
//     DOWNLOAD = "download_icon",
//     TRANSACTION_DETAILS = "transaction_details_icon",
//     PREVIEW = "preview_icon",
// }

// action for generating otp
export enum OtpActivityType {
    LOGIN = 1,
    INITIATE_TRANSACTION = 11,
    APPROVE_TRANSACTION = 12,
    PROCESS_TRANSACTION = 14,
    CHANGE_PASSWORD = 23,
    // DECLINE_TRANSACTION = 13,
    // CANCEL_TRANSACTION = 15,
}

//Page titles
export enum PageTitle {
    CARDS_PAGE = "Cards | Lenco - Better banking built for Businesses",
    PEOPLE_PAGE = "People | Lenco - Better banking built for Businesses",
    ACCOUNTS_PAGE = "Accounts | Lenco - Better banking built for Businesses",
    PAYMENTS_PAGE = "Payments | Lenco - Better banking built for Businesses",
    SECURITY_PAGE = "Security | Lenco - Better banking built for Businesses",
    DIRECTORS_PAGE = "Director Information | Lenco - Better banking built for Businesses",
    DASHBOARD_PAGE = "Dashboard | Lenco - Better banking built for Businesses",
    APPLICATION_PAGE = "Application | Lenco - Better banking built for Businesses",
    TRANSACTIONS_PAGE = "Transactions | Lenco - Better banking built for Businesses",
    BILL_HISTORY_PAGE = "Bill History | Lenco - Better banking built for Businesses",
    PENDING_APPROVAL_PAGE = "Pending Approval | Lenco - Better banking built for Businesses",
    SAVED_RECIPIENTS_PAGE = "Recipients | Lenco - Better banking built for Businesses",
    ACCOUNTS_SETTINGS_PAGE = "Account Settings | Lenco - Better banking built for Businesses",
    INDIVIDUAL_ACCOUNT_PAGE = "Account Details | Lenco - Better banking built for Businesses",
    TEAM_MEMBERS_SETTINGS_PAGE = "Team Members | Lenco - Better banking built for Businesses",

    LOGIN_PAGE = "Login | Lenco - Better banking built for Businesses",
    SIGNUP_PAGE = "Signup | Lenco - Better banking built for Businesses",
    LANDING_PAGE = "Lenco | Lenco - Better banking built for Businesses",
    EMAIL_VERIFIED_PAGE = "Email Verified | Lenco - Better banking built for Businesses",
    FORGOT_PASSWORD_PAGE = "Forgot Password | Lenco - Better banking built for Businesses",
    PAYMENT_RECEIPT_PAGE = "Payment Receipt | Lenco - Better banking built for Businesses",

    // ACCOUNT_CREATED_PAGE = "Account Created | Lenco - Better banking built for Businesses",
    // COMPANY_BASE_PAGE = "Company Base | Lenco - Better banking built for Businesses",
    // EXTERNAL_APPLICATION_PAGE = "External Application | Lenco - Better banking built for Businesses",
    // INVITE_USER_SIGNUP_PAGE = "Invite User Signup | Lenco - Better banking built for Businesses",
    // INVITE_USER_LOGIN_PAGE = "Invite User Login | Lenco - Better banking built for Businesses",
    // OTHER_COUNTRIES_PAGE = "Other LencoActiveCountries | Lenco - Better banking built for Businesses",
    // RESET_PASSWORD_PAGE = "Reset Password | Lenco - Better banking built for Businesses",
}

//Todo - To delete
//Toast Message
export enum ToastType {
    ERROR = "error_toast",
    WARNING = "warning_toast",
    INFORMATION = "information_toast",
    SUCCESS_TOAST = "success_toast",
}
