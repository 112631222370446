import { GenericBackendError, RequestCancelledError } from "../../../../../../../helpers/request/requestErrors";
import {
    ZambiaMobileMoneyNumberAirtelFormat,
    ZambiaMobileMoneyNumberFormat,
    ZambiaMobileMoneyNumberMTNFormat,
    ZambiaMobileMoneyNumberZamtelFormat,
    ZambiaMobileMoneyOperator,
} from "../../../../../payments-new/hooks/state/send-money/Zambia/zambia-single-transfer.constant";
import { useCallback, useEffect, useState } from "react";

import BankAccount from "../../../../../../../models/bankAccount";
import { ErrorMessage } from "../../../../../../../helpers/request/makeRequest";
import { VerifyAccountRequest } from "../../../../../payments-new/services/send-money/singleTransferApi.types";
import airtel from "../../../../../../../assets/svg/dashboard/send-money/zambia-telco/airtel.png";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import mtn from "../../../../../../../assets/svg/dashboard/send-money/zambia-telco/mtn.png";
import { sanitizePhoneNumber } from "../../../../../../../helpers/sanitizePhoneNumber";
import { verifyAccount } from "../../../../../payments-new/services/send-money/singleTransferApi";
import zamtel from "../../../../../../../assets/svg/dashboard/send-money/zambia-telco/zamtel.png";

export interface UseVerifyMobileMoneyPhoneInterface {
    telcoImg: string;
    bankCode: string;
    isZamtelLine: boolean;
    accountNumber: string;
    verifiedAccount: BankAccount | null;
    verifyAccountError: string | null;
    isVerifyAccountLoading: boolean;
    handleAccountNumberChange: (_accountNumber: string) => void;
}

function useVerifyMobileMoneyPhone(): UseVerifyMobileMoneyPhoneInterface {
    const [verifiedAccount, setVerifiedAccount] = useState<BankAccount | null>(null);
    const [verifyAccountError, setVerifyAccountError] = useState<string | null>(null);
    const [isVerifyAccountLoading, setIsVerifyAccountLoading] = useState(false);

    const [telcoImg, setTelcoImg] = useState<string>("");
    const [bankCode, setBankCode] = useState<string>("");
    const [isZamtelLine, setIsZamtelLine] = useState<boolean>(false);
    const [accountNumber, setAccountNumber] = useState<string>("");

    useEffect(() => {
        if (
            (isValidPhoneNumberLongFormat(accountNumber) && sanitizePhoneNumber(accountNumber).length < 12) ||
            (isValidPhoneNumberShortFormat(accountNumber) && accountNumber.length < 10) ||
            accountNumber.length < 9 ||
            bankCode.length < 2 ||
            isZamtelLine
        )
            return;

        void handleVerifyAccount({
            bankCode: bankCode,
            accountNumber: accountNumber,
        });
    }, [bankCode, accountNumber, isZamtelLine]);

    const handleVerifyAccount = useCallback(async (_data: VerifyAccountRequest) => {
        try {
            setIsVerifyAccountLoading(true);
            const res = await verifyAccount(_data);
            setVerifiedAccount(res.bankAccount);
            setVerifyAccountError(null);
        } catch (err) {
            if (err instanceof RequestCancelledError || err instanceof GenericBackendError) {
                return; // do nothing
            }
            const errorMessage = getErrorMessage(err);
            if (errorMessage === (ErrorMessage.BACKEND_GENERIC_ERROR as string)) {
                return; // do nothing
            }
            setVerifiedAccount(null);
            setVerifyAccountError(errorMessage);
        } finally {
            setIsVerifyAccountLoading(false); // set outside catch block, because finally will ignore the return in catch block
        }
    }, []);

    const handleAccountNumberChange = useCallback((_accountNumber: string) => {
        setAccountNumber(_accountNumber);
        if (!(isValidPhoneNumberShortFormat(_accountNumber) || isValidPhoneNumberLongFormat(_accountNumber))) {
            setTelcoImg("");
            setIsZamtelLine(false);
        } else {
            const phoneDetails = phoneNumberOperatorDetails(_accountNumber);
            if (phoneDetails.img) {
                setTelcoImg(phoneDetails.img);
                setBankCode(phoneDetails.bankCode);
                setIsZamtelLine(phoneDetails.bankCode === (ZambiaMobileMoneyOperator.ZAMTEL as string));
            }
        }
        setVerifiedAccount(null);
        setVerifyAccountError(null);
    }, []);

    const isValidPhoneNumberShortFormat = (_accountNumber: string): boolean => {
        const firstThreeDigits = _accountNumber.slice(0, 3);
        return Object.values(ZambiaMobileMoneyNumberFormat).some((_code: string) => _code === firstThreeDigits);
    };

    const isValidPhoneNumberLongFormat = (_accountNumber: string): boolean => {
        let firstFiveDigits = "";
        const numStartWithPlus = _accountNumber.slice(0, 1) === "+";
        if (numStartWithPlus) {
            firstFiveDigits = _accountNumber.slice(1, 6);
        } else {
            firstFiveDigits = _accountNumber.slice(0, 5);
        }
        return Object.values(ZambiaMobileMoneyNumberFormat).some((_code: string) => _code === firstFiveDigits);
    };

    const phoneNumberOperatorDetails = (_accountNumber: string) => {
        let firstFiveDigits = "";
        const firstThreeDigits = _accountNumber.slice(0, 3);
        const numStartWithPlus = _accountNumber.slice(0, 1) === "+";
        if (numStartWithPlus) {
            firstFiveDigits = _accountNumber.slice(1, 6);
        } else {
            firstFiveDigits = _accountNumber.slice(0, 5);
        }
        const isMTN = Object.values(ZambiaMobileMoneyNumberMTNFormat).some(
            (_code: string) => _code === firstThreeDigits || _code === firstFiveDigits
        );
        const isZamtel = Object.values(ZambiaMobileMoneyNumberZamtelFormat).some(
            (_code: string) => _code === firstThreeDigits || _code === firstFiveDigits
        );
        const isAirtel = Object.values(ZambiaMobileMoneyNumberAirtelFormat).some(
            (_code: string) => _code === firstThreeDigits || _code === firstFiveDigits
        );
        return {
            img: isMTN ? mtn : isAirtel ? airtel : isZamtel ? zamtel : null,
            bankCode: isMTN
                ? ZambiaMobileMoneyOperator.MTN
                : isAirtel
                  ? ZambiaMobileMoneyOperator.AIRTEL
                  : isZamtel
                    ? ZambiaMobileMoneyOperator.ZAMTEL
                    : "",
        };
    };

    return {
        telcoImg,
        bankCode,
        isZamtelLine,
        accountNumber,
        verifiedAccount,
        verifyAccountError,
        isVerifyAccountLoading,
        handleAccountNumberChange,
    };
}

export default useVerifyMobileMoneyPhone;
