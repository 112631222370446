import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import RequestedBy from "./RequestedBy";
import UserAccount from "./userAccount";
import { immerable } from "immer";

export default class RequestPayment {
    [immerable] = true;

    constructor(
        public key: string,
        public requestedAt: string,
        public requestedBy: RequestedBy | null,
        public recipientAccount: UserAccount
    ) {}

    static create(obj: GenericObject): RequestPayment {
        return new RequestPayment(
            Parsers.string(obj.key),
            Parsers.string(obj.requestedAt),
            Parsers.classObject(obj.currency, RequestedBy),
            Parsers.classObjectNonNullable(obj.recipientAccount, UserAccount)
        );
    }
}
