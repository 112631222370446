import {
    AccountSignatoriesRequests,
    abortAccountSignatoriesRequest,
    removeSignatoryAsDirector,
} from "../../../../services/nigeria-application/registered/accountSignatories.api";
import { useCallback, useState } from "react";

import { SignatoryActionRequest } from "../../../../services/nigeria-application/registered/accountSignatories.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setAccountSignatories } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface useSignatoriesInterface {
    isRemoveAsDirectorLoading: boolean | null;

    handleReset: () => void;
    handleRemoveAsDirector: (data: SignatoryActionRequest) => Promise<void>;
}

const useRemoveAsDirector = (): useSignatoriesInterface => {
    const dispatch = useDispatch();

    const [isRemoveAsDirectorLoading, setIsRemoveAsDirectorLoading] = useState<boolean | null>(false);

    const handleRemoveAsDirector = useCallback(async (_data: SignatoryActionRequest) => {
        try {
            setIsRemoveAsDirectorLoading(true);
            const res = await removeSignatoryAsDirector(_data);
            dispatch(setAccountSignatories(res));
            dispatch(messageTrue({ message: `Signatory removed as director successfully` }));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsRemoveAsDirectorLoading(false);
        }
    }, []);

    const handleReset = useCallback(() => {
        abortAccountSignatoriesRequest(AccountSignatoriesRequests.REMOVE_SIGNATORY_AS_DIRECTOR);
    }, []);

    return {
        isRemoveAsDirectorLoading,

        handleReset,
        handleRemoveAsDirector,
    };
};
export default useRemoveAsDirector;
