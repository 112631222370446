import { RecentBillPurchasesRequest, VendorRequest } from "../../../services/billPayment/billPaymentApi.types";
import { recentBillPurchases, vendorsList } from "../../../services/billPayment/billPaymentApi";
import {
    setBillPaymentVendorsError,
    setBillPaymentVendorsList,
    setBillPaymentVendorsLoading,
    setRecentBillPurchasesError,
    setRecentBillPurchasesList,
    setRecentBillPurchasesLoading,
} from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";

import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseBillPaymentInterface {
    handleBillPaymentVendors: (_data: VendorRequest) => Promise<void>;
    handleBillPaymentRecent: (_data: RecentBillPurchasesRequest) => Promise<void>;
}

function useBillPayment(): UseBillPaymentInterface {
    const dispatch = useDispatch();

    const handleBillPaymentVendors = useCallback(
        async (_data: VendorRequest) => {
            try {
                dispatch(setBillPaymentVendorsLoading());
                const res = await vendorsList(_data);
                dispatch(setBillPaymentVendorsList(res.vendors));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                dispatch(setBillPaymentVendorsError());
            }
        },
        [dispatch]
    );

    const handleBillPaymentRecent = useCallback(
        async (_data: RecentBillPurchasesRequest) => {
            try {
                dispatch(setRecentBillPurchasesLoading());
                const res = await recentBillPurchases(_data);
                dispatch(setRecentBillPurchasesList(res.purchases));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                dispatch(setRecentBillPurchasesError());
            }
        },
        [dispatch]
    );

    return {
        handleBillPaymentRecent,
        handleBillPaymentVendors,
    };
}

export default useBillPayment;
