import { ReactNode, useEffect, useState } from "react";

import RadioButton from "../radio-button";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

interface Props {
    title: string;
    subtitle: string | ReactNode;
    readOnly?: boolean;
    checked?: boolean;
    func?: () => void;
}

function RadioCard(props: Props): JSX.Element {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        if (isNullOrUndefined(props.checked)) return;
        setIsChecked(props.checked);
    }, [props.checked]);

    const handleClick = () => {
        setIsChecked((prev) => !prev);
        props.func && props.func();
    };

    return (
        <>
            <div
                className={
                    `flex h-full flex-row items-start justify-start rounded-lg border border-black-quin p-5 ` +
                    `${props.readOnly ? "pointer-events-none" : "cursor-pointer"} ` +
                    `${isChecked ? "border-blue bg-blue-backdrop" : ""} `
                }
                onClick={handleClick}
            >
                <div className="flex h-full w-full flex-col items-start space-y-3">
                    <div className="flex flex-row items-start justify-start space-x-2">
                        <div className="mt-1.5">
                            <RadioButton id="radio-card" checked={props.checked} readOnly={props.readOnly} size="sm" />
                        </div>
                        <h3 className="break-all text-lg font-medium text-black-secondary">{props.title}</h3>
                    </div>
                    <div className="ml-6 text-sm text-black-tertiary">{props.subtitle}</div>
                </div>
            </div>
        </>
    );
}

export default RadioCard;
