import * as Yup from "yup";

import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import FormInput from "../../../../../../../../../../components/inputs/FormInput";
import RefereeCard from "../RefereeCard";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import { SingleRefereeForm } from "../../../../../../../services/nigeria-application/registered/referee.types";
import formikHasError from "../../../../../../../../../../helpers/formikHasError";
import { useAppSelector } from "../../../../../../../../../../redux/hooks";
import useInviteReferees from "../../../../../../../Hooks/nigeria-application/registered-business/invite-referee/useInviteReferees";

type LocationState = {
    from?: string;
};

function SingleRefereeInviteForm(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isInviteRefereeLoading, handleRest, handleInviteReferee } = useInviteReferees();

    const referees = useAppSelector((state) => state.application.referees);
    const isCreator = useAppSelector((state) => state.application.init?.isCreator);
    const accountOpening = useAppSelector((state) => state.application.init);

    const INITIAL_FORM_STATE: SingleRefereeForm = {
        refereeEmail: "",
        refereeName: "",
        refereePhone: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        refereeEmail: Yup.string().required(),
        refereeName: Yup.string().required(),
        refereePhone: Yup.string().required(),
    });

    useEffect(() => {
        return () => {
            handleRest();
        };
    }, []);

    const handleBack = () => {
        navigate(
            {
                pathname:
                    locationState?.from ||
                    (isCreator ? Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS : Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT),
            },
            {
                state: {
                    from: Routes.ACCOUNT_OPENING.REVIEW,
                    // beforeFrom: Routes.ACCOUNT_OPENING.REVIEW,
                },
            }
        );
        // navigate(isCreator ? Routes.ACCOUNT_OPENING.REGISTERED.AGREEMENT : Routes.ACCOUNT_OPENING.STATUS);
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    void handleInviteReferee({
                        referees: [
                            {
                                name: values.refereeName,
                                email: values.refereeEmail,
                                phone: values.refereePhone,
                            },
                        ],
                    });
                }}
                enableReinitialize
                validateOnMount
                validateOnBlur
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <ApplicationHeader
                                header="Invite your Referees"
                                subTitle="Please inform your referees to look out for a reference letter from Lenco"
                                progress={
                                    accountOpening?.applicationMin.requestedProAccount
                                        ? 100
                                        : accountOpening?.progress.refereesCompletedProgress() || 0
                                }
                            >
                                <div className="w-full">
                                    <div className="flex w-full flex-col items-start space-y-8 border-b border-grey-secondary pb-4 text-base font-normal md:items-center md:px-0">
                                        <div className="flex w-full flex-col items-start space-y-4">
                                            <h3 className="font-medium text-black-secondary">Referee 2</h3>
                                            <div className="flex w-full flex-col items-start space-y-4">
                                                <div className="flex w-full flex-row">
                                                    <FormInput
                                                        type="text"
                                                        placeholder="Full Name"
                                                        name="refereeName"
                                                        value={formik.values.refereeName}
                                                    />
                                                </div>
                                                <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                    <div className="w-full md:w-50%">
                                                        <FormInput
                                                            type="text"
                                                            placeholder="Phone Number"
                                                            name="refereePhone"
                                                            value={formik.values.refereePhone}
                                                        />
                                                    </div>
                                                    <div className="w-full md:w-50%">
                                                        <FormInput
                                                            type="text"
                                                            placeholder="Email Address"
                                                            name="refereeEmail"
                                                            value={formik.values.refereeEmail}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-col space-y-4 pt-4">
                                        {referees?.map((_referee, index) => <RefereeCard key={index} data={_referee} />)}
                                    </div>
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span className="normal-case">Back</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                    <ButtonComp
                                        type="submit"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        isLoading={isInviteRefereeLoading}
                                        disable={formikHasError(formik.errors)}
                                        fullWidth
                                    >
                                        <span>Invite Referee</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default SingleRefereeInviteForm;
