import {
    IdentificationInterface,
    ResidencyPermitSubForm,
    RoleInterface,
} from "../../modules/dashboard/applicationn/services/zambia-application/registered/new/signatory.types";

import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import UserMin from "../userMin";
import { UserRole } from "../userAccount.constants";
import { immerable } from "immer";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

export default class ZambiaSignatoryInfo {
    [immerable] = true;

    constructor(
        public id: string,
        public userId: string | null,
        public user: UserMin | null,
        public firstName: string,
        public surname: string,
        public otherName: string,
        public dateOfBirth: string,
        public gender: number,
        public phone: string,
        public email: string,
        public isKeyContact: boolean,
        public isDirector: boolean,
        public role: RoleInterface | null,
        public otherRoleText: string | null,
        public address: string,
        public isZambian: boolean,
        public nonZambianNationality: string | null,
        public residencyPermit: ResidencyPermitSubForm | null,
        public identification: IdentificationInterface | null,
        public passportPhotoUploadId: string | null,
        public userRole: UserRole | null
    ) {}

    static create(obj: GenericObject): ZambiaSignatoryInfo {
        return new ZambiaSignatoryInfo(
            Parsers.string(obj.id),
            Parsers.nullableString(obj.userId),
            Parsers.classObject(obj.user, UserMin),
            Parsers.string(obj.firstName),
            Parsers.string(obj.surname),
            Parsers.string(obj.otherName),
            Parsers.string(obj.dateOfBirth),
            Parsers.number(obj.gender),
            Parsers.string(obj.phone),
            Parsers.string(obj.email),
            Parsers.boolean(obj.isKeyContact),
            Parsers.boolean(obj.isDirector),
            isNullOrUndefined(obj.role) ? null : (obj.role as RoleInterface),
            Parsers.nullableString(obj.otherRoleText),
            Parsers.string(obj.address),
            Parsers.boolean(obj.isZambian),
            obj.nonZambianNationality === null || obj.nonZambianNationality === undefined ? null : Parsers.string(obj.nonZambianNationality),
            isNullOrUndefined(obj.residencyPermit) ? null : (obj.residencyPermit as ResidencyPermitSubForm),
            isNullOrUndefined(obj.identification) ? null : (obj.identification as IdentificationInterface),
            Parsers.nullableString(obj.passportPhotoUploadId),
            Parsers.nullableNumber(obj.userRole)
        );
    }

    isCompletedBio(): boolean {
        return !!this.surname && (!!this.role || !!this.otherRoleText) && !!this.address;
    }

    isCompletedIdentification(): boolean {
        return !!this.identification && !!this.identification.type && !!this.identification.uploadId;
    }

    isCompletedMinIdentification(): boolean {
        return !!this.identification && !!this.identification.uploadId;
    }

    isStarterPersonalDetailsCompleted(): boolean {
        return (
            !!this.firstName &&
            !!this.surname &&
            !!this.phone &&
            !!this.email &&
            !!this.address &&
            !!this.dateOfBirth &&
            !!(this.role || this.otherRoleText) &&
            !!(this.isZambian || this.nonZambianNationality)
        );
    }
}
