import {
    NigeriaApplicationSectionType,
    NigeriaApplicationStagesType,
    NigeriaRegisteredCompanyStages,
} from "../../../../../services/nigeria-application/nigeria-application.types";

import BusinessContact from "./business-contact";
import BusinessInformation from "./business-information";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { GenericApplicationPageType } from "../../../../../services/application.constants";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../components/spinner";
import useBusinessDetailsInit from "../../../../../Hooks/nigeria-application/registered-business/business-details/useBusinessDetailsInit";
import { useLayoutEffect } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

type ApplicationUrlParams = {
    stage: NigeriaApplicationStagesType | undefined;
    section: NigeriaApplicationSectionType | GenericApplicationPageType;
};

function RegisteredBusinessDetails(): JSX.Element {
    const { stage } = useParams<ApplicationUrlParams>();

    const { isBusinessDetailsInitLoading, businessDetailsInitError, handleReset, handleBusinessDetailsInit } = useBusinessDetailsInit();

    const accountOpening = useSelector((state: IRootState) => state.application.init);

    useLayoutEffect(() => {
        void handleBusinessDetailsInit();

        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            {isBusinessDetailsInitLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {businessDetailsInitError && (
                <div className="flex h-full w-full items-center justify-center">
                    <ErrorToast error={businessDetailsInitError} />
                </div>
            )}
            {accountOpening && !isBusinessDetailsInitLoading && !businessDetailsInitError && (
                <div className="flex h-full w-full flex-row items-start justify-start">
                    {/* {stage === NigeriaRegisteredCompanyStages.BUSINESS_DETAILS && <BusinessDetails />} */}
                    {stage === NigeriaRegisteredCompanyStages.BUSINESS_INFORMATION && <BusinessInformation />}
                    {stage === NigeriaRegisteredCompanyStages.BUSINESS_CONTACT && <BusinessContact />}
                </div>
            )}
        </>
    );
}

export default RegisteredBusinessDetails;
