import { useCallback, useState } from "react";

import { BillPurchaseInitRequest } from "../../../../services/billPayment/billPaymentApi.types";
import { billPurchaseInit } from "../../../../services/billPayment/billPaymentApi";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { setElectricityPurchaseResponse } from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { useDispatch } from "react-redux";

interface UseElectricityPurchaseInterface {
    isElectricityPurchaseLoading: boolean;
    electricityPurchaseError: boolean;
    handleElectricityPurchase: (_data: BillPurchaseInitRequest) => Promise<void>;
}

function useElectricityPurchase(): UseElectricityPurchaseInterface {
    const dispatch = useDispatch();
    const [isElectricityPurchaseLoading, setIsElectricityPurchaseLoading] = useState(false);
    const [electricityPurchaseError, setElectricityPurchaseError] = useState<boolean>(false);

    const handleElectricityPurchase = useCallback(
        async (_data: BillPurchaseInitRequest) => {
            try {
                setIsElectricityPurchaseLoading(true);
                setElectricityPurchaseError(false);
                const res = await billPurchaseInit(_data);
                dispatch(setElectricityPurchaseResponse(res));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setElectricityPurchaseError(true);
            } finally {
                setIsElectricityPurchaseLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isElectricityPurchaseLoading,
        electricityPurchaseError,
        handleElectricityPurchase,
    };
}

export default useElectricityPurchase;
