import { BulkProcessPendingPaymentRequest, OtpInitRequest } from "../../../services/send-money/singleTransferApi.types";
import { useCallback, useEffect, useState } from "react";

import { bulkProcessPendingPayment } from "../../../services/pending-approval/pendingPayments";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { updatePendingTransaction } from "../../../../../../redux/transaction/slice/transactionSlice";
import { useDispatch } from "react-redux";
import useOtpInit from "../send-money/single-transfer/useOtpInit";

export interface UseBulkPaymentInterface {
    otp: string | null;
    minutes: number;
    seconds: number;
    isInitOTPLoading: boolean;
    bulkPaymentError: string | null;
    isBulkPaymentLoading: boolean;

    handleReset: () => void;
    handleOTPInit: (data: OtpInitRequest) => Promise<void>;
    handleOtpChange: (_otp: string) => void;
    handleResendOTPInit: (data: OtpInitRequest) => void;
    handleBulkProcessing: (_data: BulkProcessPendingPaymentRequest) => Promise<void>;
}

interface Props {
    active?: boolean;
    onComplete?: () => void;
}

const useBulkPayment = ({ active, onComplete }: Props): UseBulkPaymentInterface => {
    const dispatch = useDispatch();

    const { minutes, seconds, isInitOTPLoading, handleOTPInit, handleResendOTPInit } = useOtpInit({ active });

    const [otp, setOtp] = useState<string | null>(null);

    const [bulkPaymentError, sesBulkPaymentError] = useState<string | null>(null);
    const [isBulkPaymentLoading, setIsBulkPaymentLoading] = useState<boolean>(false);

    useEffect(() => {
        if (active) return;
        setOtp(null);
    }, [active]);

    const handleBulkProcessing = useCallback(
        async (_data: BulkProcessPendingPaymentRequest) => {
            try {
                sesBulkPaymentError(null);
                setIsBulkPaymentLoading(true);
                // const res = await bulkProcessPendingPayment(_data);
                await bulkProcessPendingPayment(_data);
                dispatch(updatePendingTransaction(_data.transactions.map((el) => ({ id: el.id, approve: el.approve }))));
                // dispatch(setMultipleTransactions(res.transactions));
                dispatch(messageTrue({ message: "Payments processed Successfully" }));
                onComplete && onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                sesBulkPaymentError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsBulkPaymentLoading(false);
            }
        },
        [dispatch]
    );

    const handleOtpChange = (e: string) => {
        setOtp(e);
    };

    const handleReset = () => {
        setOtp("");
        sesBulkPaymentError("");
        setIsBulkPaymentLoading(false);
    };

    return {
        otp,
        minutes,
        seconds,
        isInitOTPLoading,
        bulkPaymentError,
        isBulkPaymentLoading,
        handleReset,
        handleOTPInit,
        handleOtpChange,
        handleBulkProcessing,
        handleResendOTPInit,
    };
};

export default useBulkPayment;
