import React, { useEffect, useState } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
// import LencoInput from "../../../../../components/inputs/lenco-input";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import Transaction from "../../../../../models/transaction";
import useTransactionDetailsTagRecipient from "../Cards/TransactionDetailCard/Hooks/useTransactionDetailsTagRecipient";
import Input from "../../../../../components/inputs/Input";

interface TagTransactionRecipientInterface {
    showModal: boolean;
    transaction: Transaction;
    toggler(): void;
}

function TagTransactionRecipient(props: TagTransactionRecipientInterface): JSX.Element {
    const { isSent, isSubmitting, handleTagRecipient } = useTransactionDetailsTagRecipient();

    const [tagRecipientContact, setTagRecipientContact] = useState<string>("");

    useEffect(() => {
        if (props.showModal) return;
        setTagRecipientContact("");
    }, [props.showModal]);

    useEffect(() => {
        if (!isSent) return;
        props.toggler();
    }, [isSent]);

    return (
        <>
            <Modal size="md" active={props.showModal} toggler={() => props.toggler()}>
                <ModalHeader onClose={() => props.toggler()} subTitle="The payment receipt will be sent to the recipient">
                    Share Receipt
                </ModalHeader>
                <ModalBody>
                    <div className="flex w-full flex-col">
                        <div className="w-full">
                            {/* <LencoInput placeholder="Enter Email or Phone number"
                             value={tagRecipientContact} onChange={setTagRecipientContact} /> */}
                            <Input value={tagRecipientContact} onChange={setTagRecipientContact} placeholder="Enter Email or Phone number" />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={() => props.toggler()} disable={isSubmitting}>
                        <span>Cancel</span>
                    </ButtonComp>

                    <ButtonComp
                        type="submit"
                        color="black"
                        ripple="light"
                        buttonType="primary"
                        func={() => void handleTagRecipient(props.transaction, tagRecipientContact)}
                        isLoading={isSubmitting}
                        disable={tagRecipientContact.length === 0}
                    >
                        <span>Share Receipt</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default TagTransactionRecipient;
