import { setTransferDetailAmount, setTransferDetailFee } from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import { useCallback } from "react";
import { useMoneyToNumber } from "../../../../../../../hooks/useMoneyToNumber";

interface UseBulkTransferPurposeInterface {
    handleAmountChange: (_amount: string) => void;
}

function useBulkTransferAmount(): UseBulkTransferPurposeInterface {
    const dispatch = useDispatch();
    const fees = useSelector((state: IRootState) => state.init.main?.meta.transferCharges);

    const handleAmountChange = useCallback((_amount: string) => {
        if (!fees) return;
        dispatch(setTransferDetailAmount(_amount));
        const amount = useMoneyToNumber(_amount);
        dispatch(setTransferDetailFee(Number(amount) > 0 ? (Number(amount) < 5000 ? fees[1] : Number(amount) < 50000 ? fees[2] : fees[3]) : 0));
    }, []);

    return { handleAmountChange };
}

export default useBulkTransferAmount;
