import ButtonComp from "../../../../../components/button/ButtonComp";
import { useNavigate } from "react-router";
interface SuccessPageProps {
    title: string;
    companyName: string;
}
function SuccessPage(props: SuccessPageProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex w-full flex-col items-center justify-center">
                <div className="relative flex w-full max-w-sm flex-col items-center justify-center rounded-lg px-4 3xs:p-12 3xs:shadow">
                    <div className="relative flex w-full flex-col items-center justify-center">
                        <div className={`flex w-full flex-col items-center justify-center space-y-4 text-center`}>
                            <div className="text-lg font-medium text-black"> All Done!</div>
                            <div className="font-normal text-black-secondary">
                                Thank you for completing the {props.title}`s form for {props.companyName}.
                            </div>
                            <div>
                                <ButtonComp
                                    type="submit"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    disable={false}
                                    func={() => {
                                        navigate("/signup");
                                    }}
                                >
                                    Open an Account
                                </ButtonComp>
                            </div>
                        </div>

                        <div className="flex min-w-full flex-row justify-center space-x-8"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SuccessPage;
