import React from "react";
import ZambiaSettlement from "../../../../../../../models/zambia/zambiaSettlement";

interface Props {
    settlement: ZambiaSettlement | null;
}

export default function SettlementStatusTag({ settlement }: Props) {
    return (
        <div>
            {settlement?.isSettled && (
                <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                    Settled
                </span>
            )}
            {settlement?.isPending && (
                <span className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning">
                    Pending
                </span>
            )}
        </div>
    );
}
