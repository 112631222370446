export enum LoginStages {
    FORM = "form",
    OTP = "otp",
}

export type LoginInviteParams = {
    a: string;
    b: string;
    type: string;
};

export type LoginForm = {
    username: string;
    password: string;
};
