/* eslint-disable max-len */
import { CancelCardOptionsEnum, CancelCardRequest } from "../../../Services/cardsApi.types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CancelCardOptionsList } from "../../../Services/cards.constants";
import CardMain from "../../../../../../models/cardMain";
import useCardTray from "../../../Hooks/State/useCardTray";
import useDeactivateCard from "../../../Hooks/State/useDeactivateCard";
import RadioButton from "../../../../../../components/radio-button";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { ReactComponent as Info } from "../../../../../../assets/svg/Cards/Info.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../../../../components/inputs/FormInput";

interface ICancelCard {
    isSelectedCardDetailsOpen: boolean;
    cardData: CardMain;
}

function CancelCard({ isSelectedCardDetailsOpen, cardData }: ICancelCard): JSX.Element {
    const { onOpenDefaultCardSection } = useCardTray();

    const FORM_VALIDATION = Yup.object().shape({
        other_reason: Yup.string(),
    });

    const divRef = useRef<HTMLDivElement | null>(null);

    const [reasonObj, setReasonObj] = useState<CancelCardRequest | null>(null);
    const { handleDeactivateCard, isDeactivateCardLoading } = useDeactivateCard();

    useEffect(() => {
        divRef.current?.scrollIntoView();
    }, []);

    const handleCancelCardRequest = useCallback(async () => {
        if (reasonObj?.reason) {
            await handleDeactivateCard({
                reason: reasonObj?.reason === CancelCardOptionsEnum.OTHER ? undefined : reasonObj?.reason,
                otherReasonText: reasonObj?.otherReasonText,
                createRequestId: cardData.createRequest.id,
            });
            onOpenDefaultCardSection();
        }
    }, [reasonObj]);

    return (
        <div className="mt-4 w-full space-y-3 pb-4" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"} ref={divRef}>
            <div className="max-w-md space-y-3" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
                <div className="flex items-center justify-between py-3">
                    <h3 className="text-base font-medium leading-none text-black">Cancel Card</h3>
                </div>
            </div>
            <p className="text-sm font-normal text-black-secondary">Are you sure you want to cancel this card? </p>

            <p className="text-sm font-normal text-black-secondary">
                This card would be completely stopped from carrying out any transaction. This action is irreversible{" "}
            </p>
            <div className="flex w-full flex-col justify-between">
                <div className="flex flex-col items-start space-y-2 pt-2 text-base font-normal md:items-center md:px-0 lg:items-center lg:px-0">
                    <div className="flex w-full flex-col space-y-4">
                        <div className="w-full space-y-4">
                            {CancelCardOptionsList?.map((e, idx) => (
                                <div key={idx} className="w-full">
                                    <div className="relative flex w-full max-w-sm flex-col space-y-2">
                                        <RadioButton
                                            func={() => {
                                                setReasonObj((prevState) => ({
                                                    reason: e.value,
                                                    otherReasonText: e.value === CancelCardOptionsEnum.OTHER ? prevState?.otherReasonText : undefined,
                                                }));
                                            }}
                                            checked={reasonObj?.reason === e.value}
                                            size="sm"
                                            id={"cancelCard"}
                                            text={e.text}
                                        />
                                    </div>

                                    <form>
                                        {e?.hasAnyOtherReason && reasonObj?.reason === CancelCardOptionsEnum.OTHER ? (
                                            <div className="w-full pt-4">
                                                <Formik
                                                    initialValues={{}}
                                                    validationSchema={FORM_VALIDATION}
                                                    onSubmit={() => undefined}
                                                    enableReinitialize
                                                    validateOnChange
                                                >
                                                    {() => {
                                                        return (
                                                            <Form className="flex w-full">
                                                                <FormInput
                                                                    type="textarea"
                                                                    placeholder="Description"
                                                                    onChange={(_el) => {
                                                                        setReasonObj(() => {
                                                                            return {
                                                                                reason: CancelCardOptionsEnum.OTHER,
                                                                                otherReasonText: _el.target.value,
                                                                            };
                                                                        });
                                                                    }}
                                                                    value={reasonObj.otherReasonText || ""}
                                                                    name={"other_reason"}
                                                                />
                                                            </Form>
                                                        );
                                                    }}
                                                </Formik>
                                            </div>
                                        ) : null}{" "}
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-6">
                    <ButtonComp fullWidth type="button" size="lg" ripple="light" buttonType="secondary" color="grey" func={onOpenDefaultCardSection}>
                        <span className="w-full text-center">No, Keep Card</span>
                    </ButtonComp>
                    <ButtonComp
                        fullWidth
                        type="button"
                        color="red"
                        ripple="light"
                        buttonType="primary"
                        size="lg"
                        disable={!reasonObj?.reason || (reasonObj.reason === CancelCardOptionsEnum.OTHER && !reasonObj.otherReasonText)}
                        isLoading={isDeactivateCardLoading}
                        func={handleCancelCardRequest}
                    >
                        <span className="w-full text-center">Cancel Card</span>
                    </ButtonComp>
                </div>
                <div className="mt-6 flex items-center justify-center space-x-2 rounded-lg border border-info-quin bg-info-backdrop px-4 py-2 text-info">
                    <span className="self-start pt-1 md:self-center md:pt-0">
                        <Info />
                    </span>

                    <p className="text-xs">Freeze the card instead of canceling if you would still like to use the card in the future</p>
                </div>
            </div>
        </div>
    );
}
export default CancelCard;
