import React, { useCallback, useState } from "react";

// import { ReactComponent as ActiveCancel } from "../../../../../../assets/svg/general/Trash/ActiveTrash.svg";
// import { ReactComponent as Cancel } from "../../../../../../assets/svg/general/Trash/InactiveTrash.svg";
import Currency from "../../../../../../models/currency";
import CurrencyCode from "../../../../../../components/currency-code";
import { IRootState } from "../../../../../../redux/rootReducer";
import RemoveTeamMemberAccountModal from "../../Modals/RemoveFromAccountModal";
import TeamMemberNotificationChannels from "./notification-channel/TeamMemberNotificationChannels";
import TeamMemberNotificationType from "./TeamMemberNotificationType";
import TeamMemberRole from "./TeamMemberRole";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import formatNumber from "../../../../../../utils/formatNumber";
import useRemoveFromAccount from "../../../Services/team-members/hooks/useRemoveFromAccount";
import { useSelector } from "react-redux";

interface TeamMembersCardDetailsProps {
    isSelf: boolean;
    isLoading?: boolean;
    accountMeta: UserAccountMeta;
    userAccountNames: Map<string, string>;

    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    onRemoveFromTeam: (memberId: string) => void;
    removeAccountMetaFromTeamMember: (accountMetaToRemove: UserAccountMeta) => void;
}

function TeamMembersCard({
    isSelf,
    accountMeta,
    userAccountNames,
    onRemoveFromTeam: onRemoveFromTeamFunc,
    updateAccountMeta,
    removeAccountMetaFromTeamMember: removeAccountMetaFromTeamMemberFunc,
}: TeamMembersCardDetailsProps): JSX.Element {
    const currency = useSelector<IRootState, Currency | null | undefined>((state) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);

    const [isRemoveHover, setIsRemoveHover] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const onRemoveFromTeam = useCallback((memberId: string) => {
        onRemoveFromTeamFunc(memberId);
        setIsRemoveModalOpen(false);
    }, []);

    const removeAccountMetaFromTeamMember = useCallback((accountMetaToRemove: UserAccountMeta) => {
        removeAccountMetaFromTeamMemberFunc(accountMetaToRemove);
        setIsRemoveModalOpen(false);
    }, []);

    const { isRemoving, handleRemoveFromAccount } = useRemoveFromAccount(removeAccountMetaFromTeamMember, onRemoveFromTeam);

    return (
        <div>
            <RemoveTeamMemberAccountModal
                active={isRemoveModalOpen}
                backFunc={() => setIsRemoveModalOpen(false)}
                accountMeta={accountMeta}
                isRemoving={isRemoving}
                handleRemoveFromAccount={() => void handleRemoveFromAccount(accountMeta)}
            />
            <div className="flex w-full flex-row justify-between pt-4">
                <div className="flex w-full flex-col space-y-4 rounded-lg border p-6">
                    <div className="w-full">
                        <div className="flex w-full flex-row items-center justify-between space-x-2 text-sm font-normal">
                            <p className="break-all leading-none text-black-secondary">Account</p>

                            {!accountMeta.isAdmin && (
                                <div onClick={(e) => e.preventDefault()}>
                                    <div className="h-6 w-6 cursor-pointer" onClick={() => setIsRemoveModalOpen(true)}>
                                        <div
                                            className="relative mt-2 h-5 w-5 cursor-pointer"
                                            onMouseEnter={() => setIsRemoveHover(true)}
                                            onMouseLeave={() => setIsRemoveHover(false)}
                                        >
                                            <div className={`absolute left-0 top-0 ${isRemoveHover ? "opacity-0" : ""} transition-all duration-200`}>
                                                {/* <Cancel className="stroke-current text-blue" /> */}
                                            </div>
                                            <div className={`absolute left-0 top-0 ${isRemoveHover ? "" : "opacity-0"} transition-all duration-200`}>
                                                {" "}
                                                {/* <ActiveCancel /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex w-full flex-col items-start justify-start pt-2 text-sm font-normal text-black-secondary">
                            <p className="break-all font-bold"> {userAccountNames.get(accountMeta.userAccountId) || ""}</p>
                            {(accountMeta.maxApprovalAmount || accountMeta.minApprovalAmount) && (
                                <p className="block pt-0.5 text-xs text-black-tertiary">
                                    <span>Approval Limit:</span>
                                    <span className="block">
                                        <CurrencyCode currency={currency} />
                                        {formatNumber(accountMeta.maxApprovalAmount)}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex w-full flex-row items-center justify-start space-x-2 text-sm font-normal">
                            <p className="break-all leading-none text-black-secondary">Role</p>
                        </div>
                        <div className="flex w-full flex-row items-center justify-start pt-2 text-sm font-normal text-black-tertiary">
                            <TeamMemberRole accountMeta={accountMeta} updateAccountMeta={updateAccountMeta} canUpdate={!accountMeta.isAdmin} />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex w-full flex-row items-center justify-between space-x-2 text-sm font-normal">
                            <p className="break-all leading-none text-black-secondary">Notification Type</p>
                        </div>
                        <div className="item-start flex w-full flex-col justify-start space-y-2 pt-2">
                            <TeamMemberNotificationType
                                accountMeta={accountMeta}
                                updateAccountMeta={updateAccountMeta}
                                canUpdate={!accountMeta.isAdmin}
                            />
                            <TeamMemberNotificationChannels
                                accountMeta={accountMeta}
                                updateAccountMeta={updateAccountMeta}
                                canUpdate={!accountMeta.isAdmin || isSelf}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamMembersCard;
