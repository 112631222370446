import { AddMoneyFundingStageType, AddMoneyFundingType } from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import { ZambiaCollectionPaymentChannel, ZambiaCollectionStatus } from "../../../../../../models/zambia/zambiaCollectionRequest.constant";

import { DataColumn } from "../../../../../../components/Table/Type/DataRow";
import { DropdownItem } from "../../../../../../helpers/types";
import { SubTextType } from "../../../../../../components/Table/Type/SubTextType";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import ZambiaCollectionRequest from "../../../../../../models/zambia/zambiaCollectionRequest";
import titleCase from "../../../../../../hooks/titleCase";

export const BankTransferSections: {
    [value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
} = {
    [AddMoneyFundingStageType.INITIAL]: {
        value: AddMoneyFundingStageType.INITIAL,
        text: "",
        subtext: "",
    },
    [AddMoneyFundingStageType.PAYMENT_DETAILS]: {
        value: AddMoneyFundingStageType.PAYMENT_DETAILS,
        text: "Add Money",
        subtext: "You can add money to your account via Bank Transfer",
    },
    [AddMoneyFundingStageType.MAKE_PAYMENT]: {
        value: AddMoneyFundingStageType.MAKE_PAYMENT,
        text: "",
        subtext: "",
    },
    [AddMoneyFundingStageType.PROCESS_PAYMENT]: {
        value: AddMoneyFundingStageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const MobileMoneySections: {
    [value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
} = {
    [AddMoneyFundingStageType.INITIAL]: {
        value: AddMoneyFundingStageType.INITIAL,
        text: "",
        subtext: "",
    },
    [AddMoneyFundingStageType.PAYMENT_DETAILS]: {
        value: AddMoneyFundingStageType.PAYMENT_DETAILS,
        text: "Add Money",
        subtext: "You can add money to your account via Mobile Money",
    },
    [AddMoneyFundingStageType.MAKE_PAYMENT]: {
        value: AddMoneyFundingStageType.MAKE_PAYMENT,
        text: "Add Money",
        subtext: " ",
    },
    [AddMoneyFundingStageType.PROCESS_PAYMENT]: {
        value: AddMoneyFundingStageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const CardPaymentSections: {
    [value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
} = {
    [AddMoneyFundingStageType.INITIAL]: {
        value: AddMoneyFundingStageType.INITIAL,
        text: "",
        subtext: "",
    },
    [AddMoneyFundingStageType.PAYMENT_DETAILS]: {
        value: AddMoneyFundingStageType.PAYMENT_DETAILS,
        text: "Add Money",
        subtext: "You can add money to your account using your Card",
    },
    [AddMoneyFundingStageType.MAKE_PAYMENT]: {
        value: AddMoneyFundingStageType.MAKE_PAYMENT,
        text: "",
        subtext: "",
    },
    [AddMoneyFundingStageType.PROCESS_PAYMENT]: {
        value: AddMoneyFundingStageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
    // [AddMoneyFundingStageType.REVIEW_PAYMENT]: {
    // value: AddMoneyFundingStageType.REVIEW_PAYMENT,
    // text: "Review your purchase",
    // subtext: "Confirm the airtime purchase details",
    // },
};

export const AddMoneySections =
    //: {
    // [value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
    // }
    {
        [AddMoneyFundingType.BANK_TRANSFER]: BankTransferSections,
        // [AddMoneyFundingType.CARD_PAYMENT]: CardPaymentSections,
        [AddMoneyFundingType.MOBILE_MONEY]: MobileMoneySections,
    };

export enum RequestCollectionRequestStage {
    STAGE_ONE = 1,
    STAGE_TWO = 2,
    STAGE_THREE = 3,
}

export enum ZambiaCollectionRequestFilters {
    DATE = "date",
    STATUS = "status",
    CHANNEL = "channel",
}

export const ZambiaCollectionRequestFiltersArray = [
    ZambiaCollectionRequestFilters.DATE,
    ZambiaCollectionRequestFilters.STATUS,
    ZambiaCollectionRequestFilters.CHANNEL,
];

export const ZambiaCollectionRequestFiltersText = {
    [ZambiaCollectionRequestFilters.DATE]: "Date",
    [ZambiaCollectionRequestFilters.STATUS]: "Status",
    [ZambiaCollectionRequestFilters.CHANNEL]: "Channel",
};

export const ZambiaCollectionRequestStatusArray = [
    ZambiaCollectionStatus.FAILED,
    ZambiaCollectionStatus.EXPIRED,
    ZambiaCollectionStatus.PENDING,
    ZambiaCollectionStatus.CANCELLED,
    ZambiaCollectionStatus.SUCCESSFUL,
];

export const getZambiaCollectionRequestStatusName = (_status: ZambiaCollectionStatus): string => {
    switch (_status) {
        case ZambiaCollectionStatus.FAILED:
            return "Failed";
        case ZambiaCollectionStatus.EXPIRED:
            return "Expired";
        case ZambiaCollectionStatus.PENDING:
            return "Pending";
        case ZambiaCollectionStatus.CANCELLED:
            return "Cancelled";
        case ZambiaCollectionStatus.SUCCESSFUL:
            return "Successful";
        default:
            return "";
    }
};

export const ZambiaCollectionRequestStatusOptions = ZambiaCollectionRequestStatusArray.map((_status) => ({
    name: getZambiaCollectionRequestStatusName(_status),
    value: _status,
}));

export const ZambiaCollectionRequestChannelArray = [
    ZambiaCollectionPaymentChannel.CARD,
    ZambiaCollectionPaymentChannel.BANK_ACCOUNT,
    ZambiaCollectionPaymentChannel.MOBILE_MONEY,
];

export const getZambiaCollectionChannelName = (_status: ZambiaCollectionPaymentChannel): string => {
    switch (_status) {
        case ZambiaCollectionPaymentChannel.CARD:
            return "Card";
        case ZambiaCollectionPaymentChannel.BANK_ACCOUNT:
            return "Bank Account";
        case ZambiaCollectionPaymentChannel.MOBILE_MONEY:
            return "Mobile Money";
        default:
            return "";
    }
};

export const ZambiaCollectionRequestChannelOptions = ZambiaCollectionRequestChannelArray.map((_status) => ({
    name: getZambiaCollectionChannelName(_status),
    value: _status,
}));

export const ZambiaCollectionRequestFiltersDefaultValues = {
    [ZambiaCollectionRequestFilters.STATUS]: ZambiaCollectionStatus.PENDING,
    [ZambiaCollectionRequestFilters.CHANNEL]: ZambiaCollectionPaymentChannel.CARD,
};

export const ZambiaCollectionRequestFiltersOptions: {
    [type in ZambiaCollectionRequestFilters]:
        | {
              name: string;
              value: ZambiaCollectionStatus | ZambiaCollectionPaymentChannel;
          }[]
        | undefined;
} = {
    [ZambiaCollectionRequestFilters.STATUS]: ZambiaCollectionRequestStatusOptions,
    [ZambiaCollectionRequestFilters.CHANNEL]: ZambiaCollectionRequestChannelOptions,
    [ZambiaCollectionRequestFilters.DATE]: undefined,
};

export const ZambiaCollectionRequestFiltersData = ZambiaCollectionRequestFiltersArray.map((_collectionRequest) => ({
    text: ZambiaCollectionRequestFiltersText[_collectionRequest],
    value: _collectionRequest,
    options: ZambiaCollectionRequestFiltersOptions[_collectionRequest],
}));

export const TableStatusTextType: {
    [value in ZambiaCollectionStatus]: TextType;
} = {
    [ZambiaCollectionStatus.CANCELLED]: TextType.TRANSACTION_STATUS_CANCELLED,
    [ZambiaCollectionStatus.EXPIRED]: TextType.CARD_STATUS_EXPIRED,
    [ZambiaCollectionStatus.FAILED]: TextType.TRANSACTION_STATUS_FAILED,
    [ZambiaCollectionStatus.PENDING]: TextType.TRANSACTION_STATUS_PENDING_APPROVAL,
    [ZambiaCollectionStatus.SUCCESSFUL]: TextType.TRANSACTION_STATUS_SUCCESS,
};

export const ZambiaCollectionRequestTableHeader: TableHead = [
    { text: "Account", headType: TableColumnType.LOGO_SUBTEXT_FROM_TEXT },
    { text: "Type", headType: TableColumnType.TEXT },
    { text: "Channel", headType: TableColumnType.TEXT },
    { text: "Amount", headType: TableColumnType.AMOUNT_WITH_SUBTEXT },
    { text: "Status", headType: TableColumnType.STATUS },
];

export const ZambiaCollectionRequestTableRow: (_collectionRequest: ZambiaCollectionRequest) => Array<DataColumn> = (
    _collectionRequest: ZambiaCollectionRequest
) => {
    return [
        {
            key: "Account",
            text: _collectionRequest.bankingAppRequestPayment?.recipientAccount.accountName,
            textType: TextType.STRING,
            subText: _collectionRequest.createdAt,
            gainTrend: false,
            subTextType: SubTextType.DATE_TIME,
        },
        {
            key: "Type",
            text: titleCase(
                _collectionRequest?.attempts.sort((a, b) => (a.isSuccessful === b.isSuccessful ? 0 : a.isSuccessful ? -1 : 1))[0]
                    ?.mobileMoneyCollection?.serviceProviderText || ""
            ),
            textType: TextType.STRING,
        },
        {
            key: "Channel",
            text: _collectionRequest?.channelText,
            textType: TextType.STRING,
        },
        {
            key: "Amount",
            text: _collectionRequest?.amount,
            textType: TextType.BALANCE,
            currency: _collectionRequest.currency,
            subText: _collectionRequest?.attempts.sort((a, b) => (a.isSuccessful === b.isSuccessful ? 0 : a.isSuccessful ? -1 : 1))[0]
                .mobileMoneyCollection?.lencoFee,
            subTextType: SubTextType.AMOUNT_FEE,
            // textType: _collectionRequest.isSuccessful
            //     ? TextType.BALANCE
            //     : _collectionRequest.isPending
            //       ? TextType.TRANSACTION_STATUS_PENDING_APPROVAL
            //       : _collectionRequest.isExpired
            //         ? TextType.TRANSACTION_STATUS_DECLINED
            //         : _collectionRequest.isFailed
            //           ? TextType.FINAL_LOSS
            //           : _collectionRequest.isCancelled
            //             ? TextType.TRANSACTION_STATUS_CANCELLED
            //             : TextType.TRANSACTION_STATUS_CANCELLED,
        },
        {
            key: "Status",
            text: _collectionRequest.status ? _collectionRequest.statusText : "-",
            textType: _collectionRequest.status ? TableStatusTextType[_collectionRequest.status] : TextType.STRING,
        },
    ];
};
//  TextType.TRANSACTION_STATUS_FAILED
//                                                       : trans.isPendingApproval
//                                                         ? TextType.TRANSACTION_STATUS_PENDING_APPROVAL
//                                                         : trans.isDeclined
//                                                           ? TextType.TRANSACTION_STATUS_DECLINED
//                                                           : trans.isCancelled
//                                                             ? TextType.TRANSACTION_STATUS_CANCELLED
//                                                             : trans.isActivationPending
//                                                               ? TextType.TRANSACTION_STATUS_PENDING_ACTIVATION
//                                                               : trans.isProcessing
//                                                                 ? TextType.TRANSACTION_STATUS_PROCESSING
//                                                                 : TextType.BALANCE,
