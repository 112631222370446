import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import ElectricityPurchaseHeaders from "./ElectricityPurchaseHeaders";
import LencoSpinner from "../../../../../../components/spinner";
import Money from "../../../../../../components/money";
import Otp from "../../../../../../components/otp";
import { OtpActivityType } from "../../../../../../helpers/AppConstants";
import { setBillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useElectricityPurchase from "../../../hooks/state/BillPayment/ElectricityPurchase/useElectricityPurchase";
import useElectricityPurchaseOtp from "../../../hooks/state/BillPayment/ElectricityPurchase/useElectricityPurchaseOtp";
import useOtpInit from "../../../hooks/state/send-money/single-transfer/useOtpInit";

function MakePayment(): JSX.Element {
    const dispatch = useDispatch();

    const { handleOtpChange } = useElectricityPurchaseOtp();
    const { electricityPurchaseError, handleElectricityPurchase, isElectricityPurchaseLoading } = useElectricityPurchase();
    const { isInitOTPLoading, handleOTPInit, handleResendOTPInit } = useOtpInit({});

    const electricityPurchaseDetails = useAppSelector((state) => state.electricityPurchase.electricityPurchaseDetails);
    const electricityPurchaseResponse = useAppSelector((state) => state.electricityPurchase.electricityPurchaseResponse);

    const isAuthAppSetup = useAppSelector((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

    const [resendOtpText, setResendOtpText] = useState(false);

    useLayoutEffect(() => {
        if (isAuthAppSetup) return;
        void handleOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION });
    }, [handleOTPInit, isAuthAppSetup]);

    useEffect(() => {
        if (electricityPurchaseDetails.otp.length < 6) return;
        void handleElectricityPurchase(electricityPurchaseDetails);
    }, [electricityPurchaseDetails]);

    useEffect(() => {
        if (!electricityPurchaseResponse) return;
        dispatch(setBillPaymentStage(BillPaymentStage.PROCESS_PAYMENT));
    }, [electricityPurchaseResponse]);

    const handleBack = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.REVIEW_PAYMENT));
        handleOtpChange("");
    }, []);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center">
                <ElectricityPurchaseHeaders />

                {!isAuthAppSetup && isInitOTPLoading && (
                    <div className="flex w-full flex-row items-center justify-center space-x-4 pt-2 text-sm">
                        <LencoSpinner size="sm" />
                        <span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
                    </div>
                )}
                {!isInitOTPLoading && (
                    <>
                        <div className="flex w-full flex-col space-y-9">
                            <p className="pt-2 text-center text-base text-black-secondary">
                                {isAuthAppSetup && "Enter the OTP generated from your token App to approve the payment of "}
                                {!isAuthAppSetup && "One-time password (OTP) has been sent to your email to approve payment of "}
                                <Money amount={electricityPurchaseDetails.amount} />
                            </p>
                            <div className="flex w-full flex-col rounded-lg bg-white p-6">
                                <Otp
                                    value={electricityPurchaseDetails.otp || undefined}
                                    active={!isInitOTPLoading}
                                    isError={electricityPurchaseError}
                                    onChange={handleOtpChange}
                                    canResendOtp={!isAuthAppSetup}
                                    isResendOtpLoading={isInitOTPLoading}
                                    handleResend={() => {
                                        handleResendOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION });
                                        setResendOtpText(true);
                                    }}
                                />
                            </div>
                            <div className="flex w-full flex-row items-center justify-center space-x-4">
                                <ButtonComp size="xl" color="grey" ripple="light" buttonType="secondary" func={handleBack}>
                                    Back
                                </ButtonComp>

                                <ButtonComp
                                    size="xl"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    isLoading={isElectricityPurchaseLoading}
                                    disable={electricityPurchaseDetails.otp.length < 6}
                                    func={() => void handleElectricityPurchase(electricityPurchaseDetails)}
                                >
                                    Authorize Purchase
                                </ButtonComp>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default MakePayment;
