import { BusinessDetailsResponse, UpdateBusinessDetailsRequest } from "./business.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../helpers/request/abortControllers";

import BusinessDetails from "../../../../../../models/account-opening/businessDetails";
import { GenericObject } from "../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../utils/parsers";
import { makeRequestWithSignal } from "../../../../../../helpers/request/makeRequest";

function parseResponse(res: GenericObject | Error): BusinessDetailsResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
        application: Parsers.classObjectNonNullable(res.application, BusinessDetails),
    };
}

export enum NigerianUnregisteredCompanyRequests {
    UPDATE_UNREGISTERED_BUSINESS_INFORMATION = "nigerian-unregistered-business.update",
}

export const updateUnregisteredBusinessInformation = async (_data: UpdateBusinessDetailsRequest): Promise<BusinessDetailsResponse> => {
    const signal = getAbortControllerSignal(NigerianUnregisteredCompanyRequests.UPDATE_UNREGISTERED_BUSINESS_INFORMATION);
    const res = await makeRequestWithSignal("/account-opening/ng/business/unregistered/update", _data, signal);
    return parseResponse(res);
};

export function abortNigerianUnregisteredCompanyRequest(type: NigerianUnregisteredCompanyRequests): void {
    abortRequest(type);
}

export function abortAllNigerianUnregisteredCompanyRequests(): void {
    Object.values(NigerianUnregisteredCompanyRequests).forEach((type) => abortRequest(type));
}
