import { setMultipleTransactions, updatePendingTransaction } from "../../../../../../redux/transaction/slice/transactionSlice";
import { useCallback, useState } from "react";

import { BulkCancelPendingPaymentRequest } from "../../../services/send-money/singleTransferApi.types";
import { bulkCancelPendingPayment } from "../../../services/pending-approval/pendingPayments";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseBulkCancelPaymentInterface {
    bulkCancelPaymentError: string | null;
    isBulkCancelPaymentLoading: boolean;

    handleBulkCancelPayment: (_data: BulkCancelPendingPaymentRequest) => Promise<void>;
}

interface Props {
    onComplete?: () => void;
}

const useBulkCancelPayment = (props: Props): UseBulkCancelPaymentInterface => {
    const dispatch = useDispatch();

    const [bulkCancelPaymentError, setBulkCancelPaymentError] = useState<string | null>(null);
    const [isBulkCancelPaymentLoading, setIsBulkCancelPaymentLoading] = useState<boolean>(false);

    const handleBulkCancelPayment = useCallback(
        async (_data: BulkCancelPendingPaymentRequest) => {
            try {
                setBulkCancelPaymentError(null);
                setIsBulkCancelPaymentLoading(true);
                const res = await bulkCancelPendingPayment(_data);
                dispatch(updatePendingTransaction(res?.transactions?.map((_el) => ({ id: _el.id, approve: false, isCancelled: true }))));
                dispatch(setMultipleTransactions(res.transactions));
                dispatch(messageTrue({ message: "Payments cancelled successfully" }));
                props.onComplete && props.onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setBulkCancelPaymentError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsBulkCancelPaymentLoading(false);
            }
        },
        [dispatch]
    );

    return {
        bulkCancelPaymentError,
        isBulkCancelPaymentLoading,
        handleBulkCancelPayment,
    };
};

export default useBulkCancelPayment;
