import ButtonComp from "../button/ButtonComp";
import Email from "../../assets/images/lencopay/email.png";
import Modal from "./Modal";
import ModalBody from "./modal-body";
import ModalFooter from "./modal-footer";
import ModalHeader from "./modal-header";
import { useAppSelector } from "../../redux/hooks";

interface Props {
    active: boolean;
    handleClose: () => void;
}

function ExportModal(props: Props): JSX.Element {
    const user = useAppSelector((state) => state.init.main?.companyDetails.user);
    return (
        <>
            <Modal size="sm" active={props.active} toggler={props.handleClose}>
                <ModalHeader onClose={props.handleClose}>{""}</ModalHeader>
                <ModalBody>
                    <div className="flex w-full flex-col items-center justify-center space-y-6">
                        <div>
                            <div className="h-24 w-24 rounded-full bg-grey-backdrop">
                                <img src={Email} className="h-full w-full" alt="mail box" />
                            </div>
                        </div>
                        <p className="text-center text-base text-black-secondary">
                            Your requested statement will be sent to
                            <span className="font-medium"> {user?.email} </span>
                            once the export is completed
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <ButtonComp type="button" color="black" ripple="light" buttonType="primary" size="lg" fullWidth func={props.handleClose}>
                                <span>Done</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ExportModal;
