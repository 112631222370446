import { Link } from "react-router-dom";
import SkeletonElement from "../../../../../components/skeleton";
import Transaction from "../../../../../models/transaction";
import { showTransactionDetails } from "../../../../../redux/transaction/slice/transactionSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { TableColumnType } from "../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../components/Table/Type/HeadRow";
import Table from "../../../../../components/Table";
import { SubTextType } from "../../../../../components/Table/Type/SubTextType";
import { TextType } from "../../../../../components/Table/Type/TextType";

const miniHeaders: TableHead = [
    { text: "name", pos: 1, headType: TableColumnType.LOGO_TREND_SUBTEXT_FROM_TEXT },
    { text: "amount", pos: 2, headType: TableColumnType.AMOUNT_WITH_SUBTEXT },
];

const TRANSACTION_DATATYPE = "transaction";

interface Props {
    isLoading: boolean;
    transactions: Transaction[];
    type: "Transfer" | "Inflow" | "Payout";
}

function InflowCard({ isLoading, transactions, type }: Props): JSX.Element {
    const dispatch = useDispatch();

    const handleShowTransactionDetail = useCallback(
        (transaction: Transaction) => {
            dispatch(showTransactionDetails(transaction.id));
        },
        [dispatch]
    );

    return (
        <>
            {!isLoading && (
                <div className={`flex min-h-72 flex-col rounded-xl bg-white px-8 py-5 shadow`}>
                    <div className="flex items-center justify-between text-black-secondary">
                        {type && type === "Transfer" ? (
                            <div className="flex flex-row items-center justify-start font-medium text-black-secondary">Pending Approval</div>
                        ) : type && type === "Inflow" ? (
                            <div className="flex flex-row items-center justify-start font-medium text-black-secondary">Inflows</div>
                        ) : type && type === "Payout" ? (
                            <div className="flex flex-row items-center justify-start font-medium text-black-secondary">Payout</div>
                        ) : (
                            <div>Last 30 Days</div>
                        )}

                        {transactions.length > 4 && (
                            <div className="flex cursor-pointer flex-row items-center justify-end pt-3">
                                {type && type === "Inflow" ? (
                                    <Link to={`/transactions/inflow`}>
                                        <span className="text-sm text-blue">View all </span>
                                    </Link>
                                ) : type === "Payout" ? (
                                    <Link to={`/transactions/payout`}>
                                        <span className="text-sm text-blue">View all </span>
                                    </Link>
                                ) : type === "Transfer" ? (
                                    <div className="flex cursor-pointer flex-row items-center justify-end pt-3">
                                        <Link to={`/payments/pending`}>
                                            <span className="text-sm text-blue">View all </span>
                                        </Link>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </div>

                    <Table
                        dataType={TRANSACTION_DATATYPE}
                        heads={miniHeaders}
                        hideHeader
                        hideBorder
                        rowSize="md"
                        rows={transactions.slice(0, 4)?.map((_el) => ({
                            onRecordClick: () => {
                                handleShowTransactionDetail(_el);
                            },
                            record: [
                                {
                                    key: "name",
                                    text:
                                        (_el.destination && _el.destination.userAccount && _el.destination.userAccount.isMain && "MAIN") ||
                                        (_el.destination &&
                                            _el.destination.userAccount &&
                                            !_el.destination.userAccount.isMain &&
                                            _el.destination.userAccount.subAccountShortName) ||
                                        (_el.destination &&
                                            _el.destination.customerAccount &&
                                            _el.destination.customerAccount.bankAccount &&
                                            _el.destination.customerAccount.bankAccount.accountName) ||
                                        (_el.destination && _el.destination.card && _el.destination.card.name) ||
                                        (_el.destination && _el.destination.narration) ||
                                        "",
                                    subText: _el.createdOn,
                                    leftAlign: true,
                                    subTextType: SubTextType.DATE,
                                    gainTrend: type === "Inflow" ? true : type === "Payout" ? false : false,
                                },
                                {
                                    key: "amount",
                                    text: _el.amount,
                                    rightAlign: true,
                                    textType:
                                        type === "Inflow"
                                            ? TextType.FINAL_GAIN
                                            : type === "Payout"
                                              ? TextType.FINAL_LOSS
                                              : type === "Transfer"
                                                ? TextType.FINAL_LOSS
                                                : TextType.BALANCE,
                                    subTextType: SubTextType.TRANSACTION_PENDING_APPROVAL,
                                },
                            ],
                        }))}
                        noRecordText={
                            type === "Inflow"
                                ? "You have no inflow"
                                : type === "Payout"
                                  ? "You have no payout"
                                  : type === "Transfer"
                                    ? "You have no transfers pending approval"
                                    : ""
                        }
                    />
                </div>
            )}
            {isLoading && (
                <div className={`flex min-h-72`}>
                    <SkeletonElement box /> <SkeletonElement box />
                </div>
            )}
        </>
    );
}

export default InflowCard;
