import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CollectionsState, ZambiaCollectionsStageType } from "./collectionsSlice.types";

const initialState: CollectionsState = {
    collectionsStage: null,
};

const zambiaCollectionSlice = createSlice({
    name: "zambiaCollections",
    initialState,
    reducers: {
        setZambiaCollectionsStage: (state: CollectionsState, action: PayloadAction<ZambiaCollectionsStageType>) => {
            state.collectionsStage = action.payload;
        },
        resetAllZambiaCollectionsData: (state: CollectionsState) => {
            state.collectionsStage = null;
        },
    },
});

export const { setZambiaCollectionsStage, resetAllZambiaCollectionsData } = zambiaCollectionSlice.actions;

export default zambiaCollectionSlice.reducer;
