import BankAccount from "../../../../../../models/bankAccount";
import LencoSpinner from "../../../../../../components/spinner";

interface Props {
    bankAccount: BankAccount | undefined;
    isSearching?: boolean;
    isSubmitting?: boolean;
    handleConfirm: () => void;
}

function ConfirmNewAccount({ bankAccount, handleConfirm, isSearching = false, isSubmitting = false }: Props): JSX.Element {
    return (
        <div className="flex h-14 w-full flex-row items-center justify-between rounded-lg bg-grey-tertiary px-4">
            {isSearching ? (
                <div className="flex items-center">
                    <LencoSpinner size="sm" />
                    <div className="pl-4">Verifying account...</div>
                </div>
            ) : (
                <>
                    {bankAccount && (
                        <>
                            <div className="space-y- py-2">
                                <p className="text-2xs text-black-tertiary">Account Name</p>
                                <p className="-mb-0.5 text-base text-black-secondary">{bankAccount.accountName}</p>
                            </div>

                            {isSubmitting ? (
                                <LencoSpinner size="sm" />
                            ) : (
                                <span className="cursor-pointer whitespace-nowrap text-xs font-medium text-blue" onClick={() => handleConfirm()}>
                                    Add account
                                </span>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default ConfirmNewAccount;
