import { Outlet, useNavigate } from "react-router-dom";
import {
    accountOpeningInitFailure,
    accountOpeningInitRequest,
    accountOpeningInitSuccess,
    initRequest,
    isSwitchingBusinessFalse,
    setAccountCreated,
    setCompanyId,
} from "../../redux/init/slice/initSlice";
import {
    nigeriaAccountOpeningInit,
    nigeriaBusinessAccountManger,
} from "../../modules/dashboard/applicationn/services/nigeria-application/nigeiraAccountOpeningInit";
import { resetAllApplicationState, setAccountOpeningInit, setNigeriaAccountManager } from "../../redux/application/applicationSlice";
import { resetZambiaAllApplicationState, setZambiaAccountManager, setZambiaAccountOpeningInit } from "../../redux/application/zambiaApplicationSlice";
import { useCallback, useLayoutEffect, useState } from "react";
import {
    zambiaAccountOpeningInit,
    zambiaBusinessAccountManger,
} from "../../modules/dashboard/applicationn/services/zambia-application/zambiaAccountOpeningInit";

import DashboardLayout from "../layouts/dashboard-layout";
import DashboardToast from "../layouts/components/dashboard/dashboard-toast";
import ErrorToast from "../message-toast/sub/error-toast";
import FullScreenLoader from "./components/loader";
import { LencoActiveCountries } from "../../redux/init/slice/initSlice.types";
import SelectBusinessModal from "./components/modal/select-business-modal";
import { accountInitWithSignInKey } from "./services/init-api";
import { errorTrue } from "../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../utils/getErrorMessage";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import useAccountInit from "./hooks/use-account-init";
import { useAppSelector } from "../../redux/hooks";
import { useDispatch } from "react-redux";
import useMainInit from "./hooks/use-main-init";

// import { canShowCardModal, saveCardModalSkipped } from "../helpers/cardReminder";
// import FullScreenLoader from "./components/full-screen-loader";
// import CardOnboardingModal from "../modules/Dashboard/Home/Components/Modals/CardOnboardingModal";
// import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
// import FullScreenLoader from "../components/Loaders/FullScreenLoader";
// import SelectBusinessModal from "../components/DashboardLayout/Modal/SelectBusinessModal";

function InitWrapper(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [isCardOnboardingModalOpen, setIsCardOnboardingModalOpen] = useState<boolean>(true);

    const account = useAppSelector((state) => state.init.account);
    const mainInit = useAppSelector((state) => state.init.main);
    const initError = useAppSelector((state) => state.init.error);
    //This is the loading state of main init and account opening requests
    const isInitLoading = useAppSelector((state) => state.init.loading);
    const accountOpening = useAppSelector((state) => state.application.init);
    const selectedCompanyId = useAppSelector((state) => state.init.selectedCompanyId);
    const isSwitchingBusiness = useAppSelector((state) => state.init.isSwitchingBusiness);
    const zambiaAccountOpening = useAppSelector((state) => state.zambiaApplication.init);
    const selectedApplicationId = useAppSelector((state) => state.init.selectedApplicationId);
    const isAccountOpeningLoading = useAppSelector((state) => state.init.isAccountOpeningLoading);

    const [isAccessingAccount, setIsAccessingAccount] = useState(false);
    const [showSelectBusiness, setShowSelectBusiness] = useState(false);

    //the loading state of main init and account opening requests are in "isInitLoading"
    const { mutate: mainInitMutate, error: mainInitError } = useMainInit();
    const { mutate: accountInitMutate, isPending: accountInitLoading, error: accountInitError } = useAccountInit();

    const handleSelectCompany = (_companyId: string) => {
        const selectedCompany = account?.companies.find((_) => _.id === _companyId);
        if (!isNullOrUndefined(selectedCompany)) {
            navigate(selectedCompany.accountCreated ? "/" : "/application");
        }
        setShowSelectBusiness(false);
        setIsAccessingAccount(true);
        dispatch(setCompanyId(_companyId));
    };

    const checkForSignInKey = useCallback(async (): Promise<boolean> => {
        // check if the url is for the dashboard, and there is a query "key"
        try {
            if ((window.location.pathname === "/" || window.location.pathname === "") && window.location.search) {
                const searchParams = new URLSearchParams(window.location.search);
                if (searchParams.has("key")) {
                    return await accountInitWithSignInKey({ key: searchParams.get("key") as string });
                }
            }
            return false;
        } catch (err) {
            return false;
        }
    }, [dispatch, window.location]);

    const handleAccountInit = useCallback(async () => {
        // console.log("This is a redirecting");
        if (await checkForSignInKey()) {
            window.location.replace("/");
            return;
        }
        accountInitMutate();
    }, []);

    const handleNigeriaAccountOpeningInit = useCallback(async () => {
        dispatch(initRequest());
        dispatch(accountOpeningInitRequest());
        dispatch(resetAllApplicationState());
        try {
            const res = await nigeriaAccountOpeningInit();
            dispatch(setAccountOpeningInit(res));
            dispatch(resetZambiaAllApplicationState());
            dispatch(accountOpeningInitSuccess(res.applicationMin.id));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(accountOpeningInitFailure(errorMessage));
            dispatch(errorTrue(errorMessage));
        }
    }, [dispatch]);

    const handleZambiaAccountOpeningInit = useCallback(async () => {
        dispatch(initRequest());
        dispatch(accountOpeningInitRequest());
        dispatch(resetZambiaAllApplicationState());
        try {
            const res = await zambiaAccountOpeningInit();
            dispatch(setZambiaAccountOpeningInit(res));
            dispatch(resetAllApplicationState());
            dispatch(accountOpeningInitSuccess(res.application.id));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(accountOpeningInitFailure(errorMessage));
            dispatch(errorTrue(errorMessage));
        }
    }, [dispatch]);

    const handleNigeriaAccountManager = useCallback(async () => {
        try {
            const res = await nigeriaBusinessAccountManger();
            dispatch(setNigeriaAccountManager(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue(errorMessage));
        }
    }, [dispatch]);

    const handleZambiaAccountManager = useCallback(async () => {
        try {
            const res = await zambiaBusinessAccountManger();
            dispatch(setZambiaAccountManager(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue(errorMessage));
        }
    }, [dispatch]);

    // on mount, try account init
    useLayoutEffect(() => {
        if (!account) void handleAccountInit();
    }, []);

    // Get company details on company switch and company select
    useLayoutEffect(() => {
        if (selectedCompanyId === mainInit?.companyDetails.company.id) return;
        if (selectedApplicationId === accountOpening?.applicationMin.id) return;
        if (selectedApplicationId === zambiaAccountOpening?.application.id) return;

        if (selectedCompanyId && account && !accountInitLoading) {
            const selectedCompany = account.companies.find((c) => c.id === selectedCompanyId);
            if (selectedCompany) {
                dispatch(setAccountCreated(selectedCompany.accountCreated));
                if (selectedCompany.accountCreated) {
                    mainInitMutate();
                } else {
                    if (selectedCompany.country === LencoActiveCountries.NIGERIA) {
                        void handleNigeriaAccountOpeningInit();
                        void handleNigeriaAccountManager();
                    } else {
                        void handleZambiaAccountOpeningInit();
                        void handleZambiaAccountManager();
                    }
                }
            }
        }
    }, [selectedCompanyId, accountInitLoading]);

    useLayoutEffect(() => {
        if (!account || !!selectedCompanyId) return;
        setShowSelectBusiness(true);
    }, [account, selectedCompanyId]);

    useLayoutEffect(() => {
        if (isInitLoading || isAccountOpeningLoading) return;
        dispatch(isSwitchingBusinessFalse());
    }, [isInitLoading, isAccountOpeningLoading]);
    // console.log(isInitLoading);
    return (
        <>
            <div className="w-full">
                <DashboardToast />
                {(mainInitError || accountInitError || initError) && (
                    <div className="flex h-screen w-full flex-grow items-center justify-center">
                        <ErrorToast error={mainInitError || accountInitError || initError} />
                    </div>
                )}
                {showSelectBusiness && <SelectBusinessModal accountCompaniesData={account?.companies} selectCompanyFunc={handleSelectCompany} />}
                {(isInitLoading || accountInitLoading || isAccountOpeningLoading) && (
                    <FullScreenLoader isSwitchingBusiness={isSwitchingBusiness} isAccessingAccount={isAccessingAccount} />
                )}
                {!isInitLoading &&
                    !accountInitLoading &&
                    !isAccountOpeningLoading &&
                    !showSelectBusiness &&
                    account &&
                    (mainInit || accountOpening || zambiaAccountOpening) && (
                        <DashboardLayout>
                            <Outlet />
                        </DashboardLayout>
                    )}
            </div>
        </>
    );
}

export default InitWrapper;
