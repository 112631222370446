import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import React from "react";
import ZambiaBusinessDetails from "../../../../../../../../../../models/account-opening/zambiaBusinessDetails";
import { useSelector } from "react-redux";

interface Props {
    data: ZambiaBusinessDetails | null;
}

function BusinessContactReview(props: Props): JSX.Element {
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    return (
        <>
            {props.data && accountOpening && (
                <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey px-4 py-5">
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Operating Business Address</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.operatingAddress || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Phone Number (Optional)</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.phone || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Email Address (Optional)</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.email || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Website (Optional)</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.website || "-"}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export default BusinessContactReview;
