import { GenericBackendError, RequestCancelledError } from "../../../../../../../../helpers/request/requestErrors";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BankAccount from "../../../../../../../../models/bankAccount";
import { ErrorMessage } from "../../../../../../../../helpers/request/makeRequest";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import { VerifyAccountRequest } from "../../../../../services/send-money/singleTransferApi.types";
import { ZambiaMobileMoneyNumberFormat } from "../zambia-single-transfer.constant";
import { ZambiaPaymentMethod } from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice.types";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { sanitizePhoneNumber } from "../../../../../../../../helpers/sanitizePhoneNumber";
import { setZambiaSingleTransferRecipient } from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { verifyAccount } from "../../../../../services/send-money/singleTransferApi";

export interface UseSingleTransferVerifyRecipientAccountDetailsInterface {
    verifiedAccount: BankAccount | null;
    verifyAccountError: { message: string } | null;
    isVerifyAccountLoading: boolean;
    handleSetVerifiedAccount: (_bankAccount: BankAccount) => void;
    handleResetVerifiedAccount: () => void;
}

function useSingleTransferVerifyRecipientAccountDetails(): UseSingleTransferVerifyRecipientAccountDetailsInterface {
    const dispatch = useDispatch();

    const selectedUserAccount = useSelector((state: IRootState) => state.zambiaSingleTransfer.selectedUserAccount);
    const singleTransferDetails = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails);
    const canVerifyRecipientAccountDetails = useSelector((state: IRootState) => state.zambiaSingleTransfer.canVerifyRecipientAccountDetails);

    const [isVerifyAccountLoading, setIsVerifyAccountLoading] = useState(false);
    const [verifyAccountError, setVerifyAccountError] = useState<{ message: string } | null>(null);
    const [verifiedAccount, setVerifiedAccount] = useState<BankAccount | null>(null);

    useEffect(() => {
        if (!selectedUserAccount) return;
        if (selectedUserAccount.accountRestrictions.canSendMoneyToSpecificAccounts) {
            setVerifyAccountError(null);
            setVerifiedAccount(null);
        }
    }, [selectedUserAccount]);

    // useEffect(() => {
    // if (!singleTransferDetails.paymentMethod) return;
    // setIsVerifyAccountLoading(false);
    // setVerifyAccountError(null);
    // setVerifiedAccount(null);
    // }, [singleTransferDetails.paymentMethod]);

    useEffect(() => {
        if (!singleTransferDetails.paymentMethod) return;
        if (
            singleTransferDetails.paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER &&
            (singleTransferDetails.recipient.accountNumber.length < 6 ||
                singleTransferDetails.recipient.bankCode.length < 2 ||
                !canVerifyRecipientAccountDetails)
        )
            return;
        if (
            singleTransferDetails.paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS &&
            (singleTransferDetails.recipient.accountNumber.length < 6 ||
                singleTransferDetails.recipient.bankCode.length < 3 ||
                !canVerifyRecipientAccountDetails)
        )
            return;
        if (
            singleTransferDetails.paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY &&
            ((isValidPhoneNumberLongFormat(singleTransferDetails.recipient.accountNumber) &&
                sanitizePhoneNumber(singleTransferDetails.recipient.accountNumber).length < 12) ||
                (isValidPhoneNumberShortFormat(singleTransferDetails.recipient.accountNumber) &&
                    singleTransferDetails.recipient.accountNumber.length < 10) ||
                singleTransferDetails.recipient.accountNumber.length < 9 ||
                singleTransferDetails.recipient.bankCode.length < 2 ||
                !canVerifyRecipientAccountDetails)
        )
            return;
        void handleVerifyAccount({
            bankCode: singleTransferDetails.recipient.bankCode,
            accountNumber: singleTransferDetails.recipient.accountNumber,
        });
    }, [singleTransferDetails.recipient.accountNumber, singleTransferDetails.recipient.bankCode, canVerifyRecipientAccountDetails]);

    const isValidPhoneNumberShortFormat = (_accountNumber: string): boolean => {
        const firstThreeDigits = _accountNumber.slice(0, 3);
        return Object.values(ZambiaMobileMoneyNumberFormat).some((_code) => (_code as string) === firstThreeDigits);
    };
    const isValidPhoneNumberLongFormat = (_accountNumber: string): boolean => {
        let firstFiveDigits = "";
        const numStartWithPlus = _accountNumber.slice(0, 1) === "+";
        if (numStartWithPlus) {
            firstFiveDigits = _accountNumber.slice(1, 6);
        } else {
            firstFiveDigits = _accountNumber.slice(0, 5);
        }
        return Object.values(ZambiaMobileMoneyNumberFormat).some((_code) => (_code as string) === firstFiveDigits);
    };

    const handleVerifyAccount = useCallback(
        async (_data: VerifyAccountRequest) => {
            try {
                setIsVerifyAccountLoading(true);
                const res = await verifyAccount(_data);
                dispatch(setZambiaSingleTransferRecipient(res.bankAccount));
                setVerifiedAccount(res.bankAccount);
                setVerifyAccountError(null);
            } catch (err) {
                if (err instanceof RequestCancelledError || err instanceof GenericBackendError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                if (errorMessage === (ErrorMessage.BACKEND_GENERIC_ERROR as string)) {
                    return; // do nothing
                }
                // dispatch(errorTrue({message: errorMessage}));
                setVerifiedAccount(null);
                setVerifyAccountError({
                    message: errorMessage,
                });
            } finally {
                setIsVerifyAccountLoading(false); // set outside catch block, because finally will ignore the return in catch block
            }
        },
        [dispatch]
    );

    const handleSetVerifiedAccount = useCallback((_bankAccount: BankAccount) => {
        setVerifiedAccount(_bankAccount);
        setVerifyAccountError(null);
    }, []);

    const handleResetVerifiedAccount = useCallback(() => {
        setVerifiedAccount(null);
        setVerifyAccountError(null);
    }, []);

    return {
        isVerifyAccountLoading,
        verifyAccountError,
        verifiedAccount,
        handleSetVerifiedAccount,
        handleResetVerifiedAccount,
    };
}

export default useSingleTransferVerifyRecipientAccountDetails;
