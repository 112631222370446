import Kenya from "../../../assets/images/countries/kenya.png";
import { LencoActiveCountries } from "../../../redux/init/slice/initSlice.types";
import Nigeria from "../../../assets/images/countries/nigeria.png";
import OtherCountries from "../../../assets/images/countries/other-countries.png";
import Zambia from "../../../assets/images/countries/zambia.png";

export enum Countries {
    NIGERIA = "nigeria",
    ZAMBIA = "zambia",
    KENYA = "kenya",
    OTHER_COUNTRIES = "other countries",
}

export const CountryValue: {
    [type in Countries]: {
        value: string;
        icon: string;
    };
} = {
    [Countries.NIGERIA]: {
        value: LencoActiveCountries.NIGERIA,
        icon: Nigeria,
    },
    [Countries.ZAMBIA]: {
        value: LencoActiveCountries.ZAMBIA,
        icon: Zambia,
    },
    [Countries.KENYA]: {
        value: "ke",
        icon: Kenya,
    },

    [Countries.OTHER_COUNTRIES]: {
        value: "other",
        icon: OtherCountries,
    },
};

export const CountryCodeTextValue = (_countryCode: string): string => {
    switch (_countryCode) {
        case LencoActiveCountries.NIGERIA as string:
            return "Nigeria";
        case LencoActiveCountries.ZAMBIA as string:
            return "Zambia";
        default:
            return "";
    }
};
export const CountryCodeLinkValue = (_countryCode: string): string => {
    switch (_countryCode) {
        case Countries.NIGERIA as string:
            return "/signup/business-type";
        case Countries.ZAMBIA as string:
            return "/signup/business-type";
        case Countries.OTHER_COUNTRIES as string:
            return "/wait-list/join";
        default:
            return "";
    }
};

export const getLencoCountryCode = (_countryCode: Countries): string => {
    switch (_countryCode) {
        case Countries.NIGERIA as string:
            return LencoActiveCountries.NIGERIA;
        case Countries.ZAMBIA as string:
            return LencoActiveCountries.ZAMBIA;
        default:
            return LencoActiveCountries.NIGERIA;
    }
};

export const LencoCountriesOptions: {
    icon: string;
    link: string;
    name: Countries;
    value: LencoActiveCountries;
}[] = [
    {
        icon: Nigeria,
        link: "?country=NG",
        name: Countries.NIGERIA,
        value: LencoActiveCountries.NIGERIA,
    },
    {
        icon: Zambia,
        link: "?country=ZM",
        name: Countries.ZAMBIA,
        value: LencoActiveCountries.ZAMBIA,
    },
];

export const countryOptions: {
    text: string;
    icon?: string;
    value: Countries;
}[] = [
    {
        icon: Nigeria,
        value: Countries.NIGERIA,
        text: "Nigeria",
    },
    {
        icon: Zambia,
        value: Countries.ZAMBIA,
        text: "Zambia",
    },
    {
        icon: OtherCountries,
        value: Countries.OTHER_COUNTRIES,
        text: "Other Countires",
    },
];

export const lencoCountryOptions: {
    text: string;
    icon?: string;
    value: Countries;
}[] = [
    {
        icon: Nigeria,
        value: Countries.NIGERIA,
        text: "Nigeria",
    },
    {
        icon: Zambia,
        value: Countries.ZAMBIA,
        text: "Zambia",
    },
];

export const lencoCountryDashboardOptions: {
    text: string;
    icon?: string;
    value: LencoActiveCountries;
}[] = [
    {
        icon: Nigeria,
        value: LencoActiveCountries.NIGERIA,
        text: "Nigeria",
    },
    {
        icon: Zambia,
        value: LencoActiveCountries.ZAMBIA,
        text: "Zambia",
    },
];

export type WaitListCountryOption = {
    icon: string;
    text: string;
    value: string;
};

export const WaitListCountryOptions: WaitListCountryOption[] = [
    {
        icon: Kenya,
        text: "Kenya",
        value: "ke",
    },
    {
        icon: OtherCountries,
        text: "Other",
        value: "other",
    },
];
