import Clipboard from "../../../../../../../../components/clipboard";
import React from "react";
import titleCase from "../../../../../../../../hooks/titleCase";
// import CopyToClipboard from "react-copy-to-clipboard";

interface AddMoneyCardProps {
    accountNumber: string;
    medium: string;
    accountName: string;
    branch?: string;
    swift?: string;
    isWhiteBg?: boolean;
}

function AddMoneyCard(props: AddMoneyCardProps): JSX.Element {
    return (
        <div
            className={
                `relative box-border flex w-full flex-row items-center justify-between space-x-1 rounded-lg p-8 ` +
                `${props.isWhiteBg ? "bg-white" : " bg-grey-backdrop"}`
            }
        >
            <div className="flex flex-col">
                <p className="flex flex-row whitespace-nowrap pr-8 text-2xl font-normal text-black">{titleCase(props.accountNumber || "")}</p>
                <p className="text-sm font-normal text-black-secondary">{props.medium}</p>
                <p className="text-sm font-normal text-black-secondary">{props.accountName}</p>
                {props.branch && <p className="text-sm font-normal text-black-secondary">{props.branch}</p>}
                {props.swift && <p className="text-sm font-normal text-black-secondary">{props.swift}</p>}
            </div>

            <div className="flex flex-col items-center">
                <Clipboard>{props.accountNumber || ""}</Clipboard>
                {/* <CopyToClipboard text={props.accountNumber || ""}>
                    <span className="material-symbols-outlined animate-drip-expand cursor-pointer text-xl text-black-secondary">content_copy</span>
                </CopyToClipboard> */}
            </div>
        </div>
    );
}

export default AddMoneyCard;
