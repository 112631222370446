import * as Yup from "yup";

import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { changePassword, changePasswordWithOtp } from "./change-password.api";

import { ChangePasswordPageState } from "./change-password.constants";
import { ChangePasswordRequest } from "./change-password.types";
import { FormikProps } from "formik";
import { ObjectSchema } from "yup";
import { OtpActivityType } from "../../../../../../helpers/AppConstants";
import { PasswordRules } from "../../../Components/security/change-password/password-hints/password-hints.service";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { generateOtp } from "../../../../payments-new/services/send-money/singleTransferApi";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseChangePasswordResponse {
    isSubmitting: boolean;
    pageState: ChangePasswordPageState;
    setPageState: Dispatch<SetStateAction<ChangePasswordPageState>>;
    error: string | null;
    clearError: () => void;
    otpError: string | null;
    handleChangePassword(data: ChangePasswordRequest): Promise<void>;
    handleChangePasswordWithOtp(data: ChangePasswordRequest, otp: string): Promise<void>;
    handleResendOtp: () => Promise<void>;
    countDownTimer: [number, number];
    resetCountDownTimer: () => void;
    initialFormState: ChangePasswordRequest;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValidation: ObjectSchema<any>;
    formikRef: MutableRefObject<FormikProps<ChangePasswordRequest>>;
}

function useChangePassword(active: boolean): UseChangePasswordResponse {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [pageState, setPageState] = useState<ChangePasswordPageState>(ChangePasswordPageState.FORM);
    const [error, setError] = useState<string | null>(null);
    const [otpError, setOtpError] = useState<string | null>(null);
    // [minutes, seconds]
    const [countDownTimer, setCountDownTimer] = useState<[number, number]>([3, 0]);

    useEffect(() => {
        return () => {
            setError(null);
        };
    }, [active]);

    const handleChangePassword = useCallback(
        async (data: ChangePasswordRequest) => {
            setError(null);
            setIsSubmitting(true);

            try {
                const res = await changePassword(data);
                if (res.requiresOtp) {
                    setPageState(ChangePasswordPageState.OTP);
                } else {
                    setPageState(ChangePasswordPageState.SUCCESS);
                    dispatch(messageTrue("Your password has been updated"));
                }
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    const handleChangePasswordWithOtp = useCallback(
        async (data: ChangePasswordRequest, otp: string) => {
            setError(null);
            setIsSubmitting(true);

            try {
                const res = await changePasswordWithOtp(data, otp);
                if (res) {
                    setPageState(ChangePasswordPageState.SUCCESS);
                    dispatch(messageTrue("Your password has been updated"));
                }
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setOtpError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    const handleResendOtp = useCallback(async () => {
        setError(null);
        setIsSubmitting(true);

        try {
            await generateOtp(OtpActivityType.CHANGE_PASSWORD);
        } catch (err) {
            const errorMessage = "Failed to generate OTP. Please try again or contact support";
            setError(errorMessage);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const clearError = useCallback(() => {
        setError(null);
    }, []);

    const resetCountDownTimer = useCallback(() => {
        setCountDownTimer([3, 0]);
    }, []);

    const initialFormState: ChangePasswordRequest = {
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: "",
    };

    const newPasswordValidation = Yup.string().required("Required");
    for (const rule of Object.values(PasswordRules)) {
        newPasswordValidation.matches(rule.regex, rule.text);
    }

    const formValidation = Yup.object().shape({
        currentPassword: Yup.string().required("Required"), // add an additional rule
        newPassword: newPasswordValidation,
        repeatNewPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), undefined], "Password must match")
            .required("Confirm password is required"),
    });

    const formikRef = useRef<FormikProps<ChangePasswordRequest>>();

    return {
        isSubmitting,
        pageState,
        setPageState,
        error,
        clearError,
        otpError,
        handleChangePassword,
        handleChangePasswordWithOtp,
        handleResendOtp,
        countDownTimer,
        resetCountDownTimer,
        initialFormState,
        formValidation,
        formikRef: formikRef as MutableRefObject<FormikProps<ChangePasswordRequest>>,
    };
}

export default useChangePassword;
