import {
    CreateSubAccountResponse,
    CreateSubAccountStatusResponse,
    EditAccountDescriptionRequest,
    EditAccountDescriptionResponse,
    accountsDetailsResponse,
    activateAccountFinishRequest,
    activateAccountFinishResponse,
    activateAccountInitRequest,
    activateAccountInitResponse,
    activateAccountResendOtpRequest,
    activateAccountResendOtpResponse,
    createSubAccountRequest,
    createSubAccountStatusRequest,
    getAccountDetailsRequest,
    getAccountDetailsResponse,
} from "./accounts-api.types";
import { downloadAccountDetailsRequest, getAccountSummaryRequest, getAccountSummaryResponse } from "./accounts-api.types";
import { makeRequest, makeRequestDownloadFile, makeRequestWithSignal } from "../../../../helpers/request/makeRequest";

import CreateAccountRequest from "../../../../models/createAccountRequest";
import { GenericObject } from "../../../../helpers/types";
import Parsers from "../../../../utils/parsers";
import Transaction from "../../../../models/transaction";
import UserAccount from "../../../../models/userAccount";
import UserAccountMeta from "../../../../models/userAccountMeta";
import { downloadBlob } from "../../../../utils/blob";

let controller = new AbortController();
let individualController = new AbortController();
let individualSummaryController = new AbortController();

export const accountsDetails = async (): Promise<accountsDetailsResponse> => {
    controller = new AbortController();
    const res = await makeRequestWithSignal("/main/accounts", {}, controller.signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        accounts: Parsers.classObjectArray(res.accounts, UserAccount),
        pendingAccounts: Parsers.classObjectArray(res.pendingAccounts, CreateAccountRequest),
    };
};
export const createSubAccount = async (data: createSubAccountRequest): Promise<CreateSubAccountResponse> => {
    const res = await makeRequest("/main/accounts/create/sub-account", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        pendingAccount: Parsers.classObjectNonNullable(res.pendingAccount, CreateAccountRequest),
    };
};

export const editAccountDescription = async (data: EditAccountDescriptionRequest): Promise<EditAccountDescriptionResponse> => {
    const res = await makeRequest("/main/accounts/edit", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        account: Parsers.classObjectNonNullable(res.account, UserAccount),
    };
};
export const createSubAccountStatus = async (data: createSubAccountStatusRequest): Promise<CreateSubAccountStatusResponse> => {
    const res = await makeRequest("/main/accounts/create/sub-account/check-status", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        pendingAccount: Parsers.classObjectNonNullable(res.pendingAccount, CreateAccountRequest),
        account: Parsers.classObject(res.account, UserAccount),
        userAccountMeta: Parsers.classObject(res.userAccountMeta, UserAccountMeta),
    };
};

export async function downloadAccountDetails(data: downloadAccountDetailsRequest): Promise<Blob> {
    const res = await makeRequestDownloadFile("/main/accounts/download-details", data);
    if (res instanceof Error) {
        throw res;
    }

    if (res instanceof Blob) {
        downloadBlob(res, "Account details.pdf");
    }
    return res;
}

export const getAccountDetails = async (data: getAccountDetailsRequest): Promise<getAccountDetailsResponse> => {
    individualController = new AbortController();

    const res = await makeRequestWithSignal("/main/accounts/details", data, individualController.signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        account: Parsers.classObjectNonNullable(res.account, UserAccount),
    };
};
export const getAccountSummary = async (data: getAccountSummaryRequest): Promise<getAccountSummaryResponse> => {
    individualSummaryController = new AbortController();

    const res = await makeRequestWithSignal("/main/accounts/summary", data, individualSummaryController.signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        balance: Parsers.number(res.balance),
        pending: {
            transactions: res.pending ? Parsers.classObjectArray((res.pending as GenericObject).transactions, Transaction) : [],
            total: res.pending ? Parsers.number((res.pending as GenericObject).total) : 0,
        },
        recent: {
            credit: res.recent ? Parsers.classObjectArray((res.recent as GenericObject).credit, Transaction) : [],
            debit: res.recent ? Parsers.classObjectArray((res.recent as GenericObject).debit, Transaction) : [],
            combined: res.recent ? Parsers.classObjectArray((res.recent as GenericObject).combined, Transaction) : [],
        },
        chart: !res.chart
            ? []
            : (res.chart as GenericObject[]).map((item) => ({
                  date: Parsers.string(item.date),
                  balance: Parsers.number(item.balance),
              })),
    };
};
export const activateAccountInit = async (data: activateAccountInitRequest): Promise<activateAccountInitResponse> => {
    const res = await makeRequest("/main/accounts/activate/start", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};
export const activateAccountResendOtp = async (data: activateAccountResendOtpRequest): Promise<activateAccountResendOtpResponse> => {
    const res = await makeRequest("/main/accounts/activate/resend-otp", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};
export const activateAccountFinish = async (data: activateAccountFinishRequest): Promise<activateAccountFinishResponse> => {
    const res = await makeRequest("/main/accounts/activate/finish", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};

export const abortAccountTransfer = (): void => {
    controller.abort();
};

export const abortIndividualAccountTransfer = (): void => {
    individualController.abort();
    individualSummaryController.abort();
};
