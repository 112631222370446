import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class BillProductRangeAmount {
    [immerable] = true;

    constructor(
        public min: number,
        public max: number
    ) {}

    static create(obj: GenericObject): BillProductRangeAmount {
        return new BillProductRangeAmount(Parsers.number(obj.min), Parsers.number(obj.max));
    }
}
