import { CompanyDocumentTypes } from "../../../modules/dashboard/applicationn/services/nigeria-application/registered/document.types";
import { GenericObject } from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import { immerable } from "immer";

export default class IncorporationDocumentNigeria {
    [immerable] = true;

    constructor(
        public type: CompanyDocumentTypes | null,
        public uploadId: string
    ) {}

    static create(obj: GenericObject): IncorporationDocumentNigeria {
        return new IncorporationDocumentNigeria(Parsers.nullableEnum(obj.type, CompanyDocumentTypes), Parsers.string(obj.uploadId));
    }
}
