import {
    CompanyDocumentFieldTexts,
    CompanyDocumentTypes,
    IncorporationDocumentType,
} from "../../../../../../../services/zambia-application/registered/new/document.types";

import BusinessContactReview from "../review/BusinessContactReview";
import BusinessDocumentPreviewItem from "../BusinessDocumentPreviewItem";
import BusinessInformationReview from "../review/BusinessInformationReview";
import BusinessPersonalDetailsReview from "../review/BusinessPersonalDetailsReview";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import PreviewCard from "../../../../../../cards/PreviewCard";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import SignatoryCard from "../../../account-signatories/components/signatories/components/SignatoryCard";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

interface Props {
    handlePreviewDocument: (_data: string) => void;
}

function CreatorReview(props: Props): JSX.Element {
    const navigate = useNavigate();
    const signatories = useSelector((state: IRootState) => state.zambiaApplication.accountSignatories?.signatories || []);
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const businessDetails = useSelector((state: IRootState) => state.zambiaApplication.businessDetails);
    const currentSignatory = useSelector((state: IRootState) => state.zambiaApplication.currentSignatory);
    const businessDocuments = useSelector((state: IRootState) => state.zambiaApplication.businessDocuments);

    const certificateId = businessDocuments?.incorporationDocuments?.find(
        (_doc) => _doc.type === IncorporationDocumentType.INCORPORATION_CERTIFICATE
    )?.uploadId;
    const memorandumOfAssociationId = businessDocuments?.incorporationDocuments?.find(
        (_doc) => _doc.type === IncorporationDocumentType.MEMORANDUM_OF_ASSOCIATION
    )?.uploadId;
    const associationConstitutionId = businessDocuments?.incorporationDocuments?.find(
        (_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__ASSOCIATION_CONSTITUTION
    )?.uploadId;
    const form3Id = businessDocuments?.incorporationDocuments?.find((_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__FORM_3)?.uploadId;
    const operatingLicenseId = businessDocuments?.incorporationDocuments?.find(
        (_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__OPERATING_LICENSE
    )?.uploadId;
    const pacraPrintOutId = businessDocuments?.incorporationDocuments?.find(
        (_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__PACRA_PRINT_OUT
    )?.uploadId;

    const proofOfResidenceId = businessDocuments?.proofOfResidence?.uploadId;

    return (
        <>
            {accountOpening && (
                <>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Business information</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessInformationReview data={businessDetails} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Business Contact Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessContactReview data={businessDetails} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Personal Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessPersonalDetailsReview data={currentSignatory || undefined} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Means of ID</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">add_notes</span>}
                            text={accountOpening?.options.idType?.find((_el) => _el.id === currentSignatory?.identification?.type)?.name || "-"}
                            canNotPreview={!currentSignatory?.identification?.uploadId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.identification?.uploadId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Photograph</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">account_circle</span>}
                            text="Photograph"
                            canNotPreview={!currentSignatory?.passportPhotoUploadId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.passportPhotoUploadId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="w-full text-left font-medium text-black-secondary">Account Signatories</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.DETAILS,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <div
                            className={
                                "grid w-full gap-4 " + ` ${signatories.length === 1 ? "grid-cols-1 xl:grid-cols-2" : "grid-cols-1 sm:grid-cols-2"}`
                            }
                        >
                            {signatories.map((_signatories, index) => (
                                <SignatoryCard key={index} data={_signatories} isReview />
                            ))}
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Business Documents</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey p-4 [&>div:not(:last-child)]:border-b [&>div:not(:last-child)]:border-grey-secondary [&>div:not(:last-child)]:pb-4">
                            {businessDocuments?.application.isBusinessName && (
                                <>
                                    <BusinessDocumentPreviewItem
                                        text={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                        isCanNotPreview={!certificateId}
                                        handlePreviewDocumentFunc={() => props.handlePreviewDocument(certificateId || "")}
                                    />
                                    <BusinessDocumentPreviewItem
                                        text={CompanyDocumentFieldTexts[CompanyDocumentTypes.PROOF_OF_RESIDENCE]}
                                        isCanNotPreview={!proofOfResidenceId}
                                        handlePreviewDocumentFunc={() => props.handlePreviewDocument(proofOfResidenceId || "")}
                                    />
                                </>
                            )}
                            {businessDocuments?.application.isNGO && (
                                <>
                                    <BusinessDocumentPreviewItem
                                        text={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                        isCanNotPreview={!certificateId}
                                        handlePreviewDocumentFunc={() => props.handlePreviewDocument(certificateId || "")}
                                    />
                                    <BusinessDocumentPreviewItem
                                        text={CompanyDocumentFieldTexts[CompanyDocumentTypes.ASSOCIATION_CONSTITUTION]}
                                        isCanNotPreview={!associationConstitutionId}
                                        handlePreviewDocumentFunc={() => props.handlePreviewDocument(associationConstitutionId || "")}
                                    />
                                </>
                            )}
                            {businessDocuments?.application.isLLC && (
                                <>
                                    <BusinessDocumentPreviewItem
                                        text={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                        isCanNotPreview={!certificateId}
                                        handlePreviewDocumentFunc={() => props.handlePreviewDocument(certificateId || "")}
                                    />
                                    <BusinessDocumentPreviewItem
                                        text={CompanyDocumentFieldTexts[CompanyDocumentTypes.OPERATING_LICENSE]}
                                        isCanNotPreview={!operatingLicenseId}
                                        handlePreviewDocumentFunc={() => props.handlePreviewDocument(operatingLicenseId || "")}
                                    />
                                    <BusinessDocumentPreviewItem
                                        text={CompanyDocumentFieldTexts[CompanyDocumentTypes.SHAREHOLDERS_INFORMATION]}
                                        isCanNotPreview={!(memorandumOfAssociationId || form3Id || pacraPrintOutId)}
                                        handlePreviewDocumentFunc={() =>
                                            props.handlePreviewDocument(memorandumOfAssociationId || form3Id || pacraPrintOutId || "")
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default CreatorReview;
