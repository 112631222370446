import ButtonComp from "../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import LencoSpinner from "../../../../../components/spinner";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import Otp from "../../../../../components/otp";
import Skeleton from "../../../../../components/skeleton";
import UserAccount from "../../../../../models/userAccount";
import useActivateAccountState from "../../Hooks/State/useActivateAccountState";
import { useEffect } from "react";

interface ActivateAccountProps {
    data: UserAccount | null;
    active: boolean;

    toggler: () => void;
    onCompleteFunc: (account: UserAccount) => void;
}

function ActivateAccount(props: ActivateAccountProps): JSX.Element {
    const { active, data, toggler, onCompleteFunc } = props;
    const {
        otp,
        initOTPError,
        isInitOTPLoading,
        isActivateAccountFinishError,
        activateAccountFinishResponse,
        isActivateAccountFinishLoading,

        handleOTPInit,
        handleOtpChange,
        handleResendOTPInit,
        handleActivateAccountFinish,
    } = useActivateAccountState(active);

    useEffect(() => {
        if (!data || !active) return;
        void handleOTPInit({ userAccountId: data.id });
    }, [data, active, handleOTPInit]);

    useEffect(() => {
        if (!otp || otp.length < 6 || !data) return;
        void handleActivateAccountFinish({ userAccountId: data.id, otp });
    }, [data, handleActivateAccountFinish, otp]);

    useEffect(() => {
        if (!activateAccountFinishResponse || !data) return;
        onCompleteFunc(data);
        toggler();
    }, [activateAccountFinishResponse, toggler, onCompleteFunc]);

    return (
        <>
            <Modal size="md" active={active} toggler={toggler}>
                <ModalHeader onClose={toggler}>
                    <h3>Activate Account</h3>
                    <div className="pt-2">
                        {isInitOTPLoading ? (
                            <div className="flex w-full flex-row items-start justify-start space-x-1 pb-4">
                                <LencoSpinner size="sm" /> <span className="text-left text-sm">Generating OTP...</span>
                            </div>
                        ) : (
                            <span className="text-left text-sm text-black-tertiary">This is a one-time action on this account.</span>
                        )}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="relative flex w-full flex-col">
                        {(initOTPError || isActivateAccountFinishError) && (
                            <div className="mb-4 flex w-full items-center justify-center">
                                <ErrorToast error={initOTPError || isActivateAccountFinishError || ""} fullWidth />
                            </div>
                        )}
                        {!initOTPError && (
                            <>
                                {!isInitOTPLoading ? (
                                    <>
                                        <p className="pb-4 text-left text-sm">
                                            To enable {data && data.subAccountShortName} for payout, we sent a one-time password to your phone number
                                            to activate the account with our bank partner.
                                        </p>
                                        <Otp
                                            value={otp}
                                            active={active}
                                            isError={isActivateAccountFinishError}
                                            onChange={handleOtpChange}
                                            resendMins={3}
                                            handleResend={() => void handleResendOTPInit({ userAccountId: data?.id || "" })}
                                            isResendOtpLoading={isInitOTPLoading}
                                            canResendOtp
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className="h-24 w-full">
                                            <Skeleton box />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={toggler}>
                        <span>Back</span>
                    </ButtonComp>
                    <ButtonComp
                        color="black"
                        ripple="light"
                        buttonType="primary"
                        isLoading={isActivateAccountFinishLoading}
                        disable={!isActivateAccountFinishLoading}
                    >
                        <span>Authorize</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ActivateAccount;
