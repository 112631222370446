import React, { useCallback, useState } from "react";
import RemoveFromAccountButton from "../../Buttons/TeamMembers/RemoveFromAccountButton";
import RemoveTeamMemberAccountModal from "../../Modals/RemoveFromAccountModal";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import useRemoveFromAccount from "../../../Services/team-members/hooks/useRemoveFromAccount";

interface RemoveFromAccountProps {
    accountMeta: UserAccountMeta;
    onRemoveFromTeam: (memberId: string) => void;
    removeAccountMetaFromTeamMember: (accountMetaToRemove: UserAccountMeta) => void;
}

function RemoveFromAccount({
    accountMeta,
    onRemoveFromTeam: onRemoveFromTeamFunc,
    removeAccountMetaFromTeamMember: removeAccountMetaFromTeamMemberFunc,
}: RemoveFromAccountProps): JSX.Element {
    const [isRemoveHover, setIsRemoveHover] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const onRemoveFromTeam = useCallback((memberId: string) => {
        onRemoveFromTeamFunc(memberId);
        setIsRemoveModalOpen(false);
    }, []);

    const removeAccountMetaFromTeamMember = useCallback((accountMetaToRemove: UserAccountMeta) => {
        removeAccountMetaFromTeamMemberFunc(accountMetaToRemove);
        setIsRemoveModalOpen(false);
    }, []);

    const { isRemoving, handleRemoveFromAccount } = useRemoveFromAccount(removeAccountMetaFromTeamMember, onRemoveFromTeam);

    return (
        <>
            <RemoveTeamMemberAccountModal
                active={isRemoveModalOpen}
                backFunc={() => setIsRemoveModalOpen(false)}
                accountMeta={accountMeta}
                isRemoving={isRemoving}
                handleRemoveFromAccount={() => void handleRemoveFromAccount(accountMeta)}
            />
            <div className="hidden h-6 w-6 cursor-pointer sm:inline" onClick={() => setIsRemoveModalOpen(true)}>
                <div
                    className="relative h-3 w-3 cursor-pointer"
                    onMouseEnter={() => setIsRemoveHover(true)}
                    onMouseLeave={() => setIsRemoveHover(false)}
                >
                    <div className={`absolute left-0 top-0 ${isRemoveHover ? "opacity-0" : ""} transition-all duration-200`}>
                        {/* <Cancel className="stroke-current text-blue" /> */}
                    </div>
                    <div className={`absolute left-0 top-0 ${isRemoveHover ? "" : "opacity-0"} transition-all duration-200`}>
                        {/* <ActiveCancel /> */}
                    </div>
                </div>
                {/* <RemoveUserIcon className="text-error stroke-current" /> */}
                <span className="material-symbols-outlined text-xl text-black-tertiary">delete</span>
            </div>
            <div className="cursor-pointer sm:hidden">
                <RemoveFromAccountButton />
            </div>
        </>
    );
}

export default RemoveFromAccount;
