import { MenuSlots, PopoverSlots, SlotsToClasses } from "@nextui-org/react";

/*
    So it goes styles for;
        - Main dropdown head
        - Dropdown menu
        - Dropdown item
*/

// <===== Main dropdown head =====>

export type DropdownCustomPropsTypes = {
    classNames?: SlotsToClasses<PopoverSlots>;
};

export const dropdownCustomProps = (): DropdownCustomPropsTypes => {
    return {
        classNames: { content: "!p-0 overflow-hidden !rounded-lg" },
    };
};

// <===== Dropdown menu =====>

export type DropdownMenuCustomPropsTypes = {
    variant?: "flat" | "shadow" | "solid" | "bordered" | "light" | "faded" | undefined;
    classNames?: SlotsToClasses<MenuSlots>;
};

export const dropdownMenuCustomProps = (): DropdownMenuCustomPropsTypes => {
    return {
        variant: "flat",
        classNames: {
            list: "!p-0 [&>li:not(:first-child)]:border-t [&>li:not(:first-child)]:border-grey-secondary [&>li:not(:first-child)]:rounded-none overflow-hidden !gap-0",
            emptyContent: "!p-0",
            base: "!p-0",
        },
    };
};

// <===== Dropdown item =====>
export interface MenuItemCustomClassProps {
    size?: "sm" | "md";
    color?: "black" | "blue" | "red" | "grey" | "none";
}

export type MenuItemCustomClassesTypes = {
    className?: string;
};

export const menuItemCustomClasses = (props?: MenuItemCustomClassProps): MenuItemCustomClassesTypes => {
    const color = props?.color || "grey";
    const size = props?.size || "md";

    return {
        className:
            `${size === "md" ? "h-16" : "h-12"} ` +
            `py-2 px-3 focus:!outline-none rounded-none flex justify-between items-center w-full ` +
            `${color === "blue" ? "data-[selectable=true]:focus:hover:!bg-blue-backdrop hover:!bg-blue-backdrop focus:!bg-blue-backdrop hover:!text-blue text-blue " : ""}` +
            `${color === "black" ? "data-[selectable=true]:focus:hover:!bg-grey-backdrop hover:!bg-grey-backdrop focus:!bg-grey-backdrop hover:!text-black text-black" : ""}` +
            `${color === "red" ? "data-[selectable=true]:focus:hover:!bg-error-backdrop hover:!bg-error-backdrop focus:!bg-error-backdrop hover:!text-error text-error" : ""}` +
            `${color === "none" ? "data-[selectable=true]:focus:hover:!bg-white hover:!bg-white bg-white focus:!bg-white" : ""}` +
            `${color === "grey" ? "data-[selectable=true]:focus:hover:!bg-grey-backdrop hover:!bg-grey-backdrop focus:!bg-grey-backdrop hover:!text-black text-black-tertiary" : ""}`,
    };
};

export const menuItemCustomOnClick = (onClick: () => void) => {
    return {
        onClick: () => onClick(),
        onKeyDown: (e: React.KeyboardEvent<HTMLLIElement> | KeyboardEvent) => (e.key === "Enter" ? onClick() : undefined),
    };
};
