import Parsers from "../utils/parsers";
import UserMin from "./userMin";
import { GenericObject } from "../helpers/types";
import { immerable } from "immer";

export default class TransactionComment {
    [immerable] = true;

    constructor(
        public id: string,
        public text: string,
        public createdOn: Date | null,
        public createdBy: UserMin | null
    ) {}

    static create(obj: GenericObject): TransactionComment {
        return new TransactionComment(
            Parsers.string(obj.id),
            Parsers.string(obj.text),
            Parsers.date(obj.createdOn),
            Parsers.classObject(obj.createdBy, UserMin)
        );
    }
}
