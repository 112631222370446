import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { VerifyEmailOtpRequest, VerifyEmailOtpResponse } from "../../email-verification/services/email-verification.types";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { verifyEmailOtp } from "../../email-verification/services/email-verification.api";

interface Props {
    onComplete: (_username: string) => void;
}

function useVerifyEmailOtp(props: Props): UseMutationResult<VerifyEmailOtpResponse, AxiosError | null, VerifyEmailOtpRequest, unknown> {
    const verifyOtp = useMutation({
        mutationFn: async (_data: VerifyEmailOtpRequest) => {
            const res = await verifyEmailOtp(_data);
            return res;
        },
        onSuccess(data) {
            props.onComplete(data.username);
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return verifyOtp;
}

export default useVerifyEmailOtp;
