import GoogleIcon from "../../../../../components/google-icon";
import LencoSpinner from "../../../../../components/spinner";

interface Props {
    icon: string;
    text: string;
    isActive?: boolean;
    isLoading?: boolean;
    onClick: () => void;
}

function CategorizeCard(props: Props) {
    return (
        <>
            <div
                className={
                    "relative flex h-20 w-full cursor-pointer flex-col items-center justify-center space-y-2 rounded-[6px] border duration-150" +
                    `${props.isLoading ? "border-black-tertiary" : props.isActive ? " border-blue" : " border-grey hover:border-black-tertiary"}`
                }
                onClick={() => {
                    !props.isActive && !props.isLoading && props.onClick();
                }}
            >
                <div>
                    <img className="h-8 w-8" src={props.icon} alt={props.text} />
                </div>
                <p className="text-xs text-black">{props.text}</p>
                {props.isActive && !props.isLoading && (
                    <div className="absolute left-2 top-0">
                        <GoogleIcon icon="check_circle" className="icon-fill text-blue" />
                    </div>
                )}
                {props.isLoading && (
                    <div className="absolute left-2 top-0">
                        <LencoSpinner size="sm" />
                    </div>
                )}
            </div>
        </>
    );
}

export default CategorizeCard;
