import React, { useCallback, useEffect, useState } from "react";

import ChangePasswordForm from "./ChangePasswordForm";
import ChangePasswordOtp from "./ChangePasswordOtp";
import { ChangePasswordPageState } from "../../../Services/security/change-password/change-password.constants";
import { ChangePasswordRequest } from "../../../Services/security/change-password/change-password.types";
import ChangePasswordSuccess from "./ChangePasswordSuccess";
import useChangePassword from "../../../Services/security/change-password/useChangePassword";

interface ChangePasswordModalProps {
    active: boolean;
    closeFunc: () => void;
}

function ChangePasswordModal({ active, closeFunc }: ChangePasswordModalProps): JSX.Element {
    const useChangePasswordData = useChangePassword(active);
    const { pageState } = useChangePasswordData;

    const [otp, setOtp] = useState<number | string>("");

    const doneHandler = () => {
        closeFunc();
        useChangePasswordData.formikRef.current?.resetForm();
        setTimeout(() => useChangePasswordData.setPageState(ChangePasswordPageState.FORM), 1000);
        setOtp("");
    };
    const [values, setValues] = useState<ChangePasswordRequest>();

    const checkAndHandleOtpSubmit = useCallback(() => {
        if (String(otp).length >= 6 && values) {
            void useChangePasswordData.handleChangePasswordWithOtp(values, String(otp));
        }
    }, [otp, values]);

    useEffect(() => {
        if (String(otp).length >= 6) {
            checkAndHandleOtpSubmit();
        }
    }, [otp]);

    return (
        <>
            {pageState === ChangePasswordPageState.FORM && (
                <ChangePasswordForm
                    show={active}
                    doneHandler={doneHandler}
                    formikRef={useChangePasswordData.formikRef}
                    initialValues={useChangePasswordData.initialFormState}
                    useChangePasswordData={useChangePasswordData}
                    setValues={setValues}
                />
            )}
            {pageState === ChangePasswordPageState.OTP && (
                <ChangePasswordOtp
                    otpFunc={setOtp}
                    value={String(otp)}
                    error={useChangePasswordData.otpError}
                    active={active}
                    toggler={doneHandler}
                    counterMinutes={useChangePasswordData.countDownTimer[0]}
                    counterSeconds={useChangePasswordData.countDownTimer[1]}
                    isLoading={useChangePasswordData.isSubmitting}
                    resendFunc={() => void useChangePasswordData.handleResendOtp()}
                    handleSubmit={checkAndHandleOtpSubmit}
                />
            )}
            {pageState === ChangePasswordPageState.SUCCESS && <ChangePasswordSuccess active={active} toggler={doneHandler} />}
        </>
    );
}

export default ChangePasswordModal;
