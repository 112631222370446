import Card from "../../../../../../models/card";
import CustomerAccount from "../../../../../../models/customerAccount";
import UserAccount from "../../../../../../models/userAccount";
import getBankName from "../../../../../../helpers/getBankName";
import AvatarDetails from "../../../../../../components/avatar-details";

interface Props {
    data: UserAccount | CustomerAccount | Card;
    canRemove: boolean;
    handleRemoveIconClick?: () => void;
    id: string;
    index: number;
    accountLength?: number;
}

function RecipientAccountItem({ data, canRemove, handleRemoveIconClick, index, accountLength }: Props): JSX.Element {
    return (
        <div className="w-full space-y-4">
            <div className="flex flex-row items-center justify-between">
                <AvatarDetails
                    title={data instanceof UserAccount || (data instanceof CustomerAccount && data.accountName)}
                    subtitle={
                        (data instanceof UserAccount || data instanceof CustomerAccount) && (
                            <div className="flex text-xs font-normal text-black-tertiary">
                                <span className="pr-2"> {data.bankAccount?.accountNumber}</span> &nbsp; &nbsp;
                                <ul className="list-disc lowercase">
                                    <li className="capitalize"> {getBankName(data.bankAccount?.bankCode)}</li>
                                </ul>
                            </div>
                        )
                    }
                />
                {canRemove && (
                    <div className="flex flex-row items-center justify-between space-x-4">
                        <span className="material-symbols-outlined cursor-pointer text-xl text-black-tertiary" onClick={handleRemoveIconClick}>
                            delete
                        </span>
                    </div>
                )}
            </div>

            {accountLength && index < accountLength - 1 && <hr className="border-grey-secondary" />}
        </div>
    );
}

export default RecipientAccountItem;
