import { DropdownItem } from "../../../../../../helpers/types";
import { ERROR } from "../../../../../../helpers/AppConstants";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { SubTextType } from "../../../../../../components/Table/Type/SubTextType";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import { TransactionStatus } from "../../../../../../models/transaction.constants";
import { TransferDetail } from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import getBankName from "../../../../../../helpers/getBankName";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";

export enum PaymentsType {
    SINGLE_TRANSFER = "single",
    TRANSFER_BETWEEN_ACCOUNT = "accounts",
    BULK_TRANSFER = "bulk",
    RECIPIENT = "recipient",
    TYPE_OF_TRANSFERS = "typeoftransfer",
    FUND_CARD = "cards",
}
export enum StageType {
    INITIAL = 1,
    PAYMENT_DETAILS = 2,
    REVIEW_PAYMENT = 3,
    MAKE_PAYMENT = 4,
    PROCESS_PAYMENT = 5,
}

export const SingleTransferSections: {
    [value in PaymentStageType]: DropdownItem<PaymentStageType>;
} = {
    [PaymentStageType.INITIAL]: {
        value: PaymentStageType.INITIAL,
        text: "",
        subtext: "",
    },
    [PaymentStageType.PAYMENT_DETAILS]: {
        value: PaymentStageType.PAYMENT_DETAILS,
        text: "Make a single transfer",
        subtext: "Send money to recipient",
    },
    [PaymentStageType.REVIEW_PAYMENT]: {
        value: PaymentStageType.REVIEW_PAYMENT,
        text: "Review your payment",
        subtext: "Confirm the transaction details",
    },
    [PaymentStageType.MAKE_PAYMENT]: {
        value: PaymentStageType.MAKE_PAYMENT,
        text: "Authorize payment",
        subtext: "",
    },
    [PaymentStageType.PROCESS_PAYMENT]: {
        value: PaymentStageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const TransferBetweenAccountSections: {
    [value in StageType]: DropdownItem<StageType>;
} = {
    [StageType.INITIAL]: {
        value: StageType.INITIAL,
        text: "",
        subtext: "",
    },
    [StageType.PAYMENT_DETAILS]: {
        value: StageType.PAYMENT_DETAILS,
        text: "Transfer Between Accounts",
        subtext: "Send money to your sub-account",
    },
    [StageType.REVIEW_PAYMENT]: {
        value: StageType.REVIEW_PAYMENT,
        text: "Review your payment",
        subtext: "Confirm the transaction details",
    },
    [StageType.MAKE_PAYMENT]: {
        value: StageType.MAKE_PAYMENT,
        text: "Authorize payment",
        subtext: "",
    },
    [StageType.PROCESS_PAYMENT]: {
        value: StageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const FundCardSections: {
    [value in StageType]: DropdownItem<StageType>;
} = {
    [StageType.INITIAL]: {
        value: StageType.INITIAL,
        text: "",
        subtext: "",
    },
    [StageType.PAYMENT_DETAILS]: {
        value: StageType.PAYMENT_DETAILS,
        text: "Fund Card",
        subtext: "Send money to your Prepaid Card",
    },
    [StageType.REVIEW_PAYMENT]: {
        value: StageType.REVIEW_PAYMENT,
        text: "Review your card funding",
        subtext: "Confirm the card funding details",
    },
    [StageType.MAKE_PAYMENT]: {
        value: StageType.MAKE_PAYMENT,
        text: "Authorize card funding",
        subtext: "",
    },
    [StageType.PROCESS_PAYMENT]: {
        value: StageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const BulkTransferSections: {
    [value in PaymentStageType]: DropdownItem<PaymentStageType>;
} = {
    [PaymentStageType.INITIAL]: {
        value: PaymentStageType.INITIAL,
        text: "",
        subtext: "",
    },
    [PaymentStageType.PAYMENT_DETAILS]: {
        value: PaymentStageType.PAYMENT_DETAILS,
        text: "Bulk Transfer",
        subtext: "Send money to multiple recipients or sub-accounts all at once",
    },
    [PaymentStageType.REVIEW_PAYMENT]: {
        value: PaymentStageType.REVIEW_PAYMENT,
        text: "Review your payments",
        subtext: "Confirm the transaction details",
    },
    [PaymentStageType.MAKE_PAYMENT]: {
        value: PaymentStageType.MAKE_PAYMENT,
        text: "Authorize payments",
        subtext: "Give approval to these transactions",
    },
    [PaymentStageType.PROCESS_PAYMENT]: {
        value: PaymentStageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

// export const TableStatusTextType: {
//     [value in TransactionStatus]: TextType;
// } = {
//     [BillPurchaseStatusType.SUCCESS]: TextType.TRANSACTION_STATUS_SUCCESS,
//     [BillPurchaseStatusType.PENDING]: TextType.TRANSACTION_STATUS_PROCESSING,
//     [BillPurchaseStatusType.FAILED]: TextType.TRANSACTION_STATUS_FAILED,
// };

export const BulkTransferHeader: TableHead = [
    { text: "From", headType: TableColumnType.LOGO_FROM_TEXT },
    { text: "To", headType: TableColumnType.LOGO_SUBTEXT_FROM_TEXT },
    { text: "Purpose", headType: TableColumnType.TEXT },
    { text: "Amount", headType: TableColumnType.AMOUNT_WITH_SUBTEXT },
    { text: "Action", headType: TableColumnType.ACTION },
];
export const BulkTransferHeaderReview: TableHead = [
    { text: "From", headType: TableColumnType.LOGO_FROM_TEXT },
    { text: "To", headType: TableColumnType.LOGO_SUBTEXT_FROM_TEXT },
    { text: "Purpose", headType: TableColumnType.TEXT },
    { text: "Amount", headType: TableColumnType.AMOUNT_WITH_SUBTEXT },
];

export const BulkTransferRowReview = (_bill: TransferDetail) => {
    return [
        {
            key: "From",
            text: _bill.originatingAccountId === ERROR ? "Sender account is not valid" : _bill.selectedUserAccount?.bankAccount?.accountName,
        },
        {
            key: "To",
            text: isNullOrUndefined(_bill.recipientBankAccount) ? "Recipient account is not valid" : _bill.recipientBankAccount.accountName,
            subText: isNullOrUndefined(_bill.recipientBankAccount)
                ? ""
                : `${_bill.recipientBankAccount.accountNumber} • ${getBankName(_bill.recipientBankAccount.bankCode)}`,
        },
        {
            key: "Purpose",
            text: _bill.purpose === ERROR ? "Purpose was not given" : _bill.purpose,
        },
        {
            key: "Amount",
            text: _bill.amount === ERROR ? "Amount is not valid" : _bill.amount,
            textType: TextType.BALANCE,
            subText: _bill.fee,
            subTextType: SubTextType.AMOUNT_FEE,
        },
    ];
};

export const BulkTransferRow = (_index: number, _bill: TransferDetail, onEdit: (e: TransferDetail) => void, onRemove: (e: number) => void) => {
    return [
        {
            key: "From",
            text: _bill.originatingAccountId === ERROR ? "Sender account is not valid" : _bill.selectedUserAccount?.bankAccount?.accountName,
            textType: _bill.originatingAccountId === ERROR ? TextType.ERROR : undefined,
            // TableColumnType.LOGO_FROM_TEXT
        },
        {
            key: "To",
            text: isNullOrUndefined(_bill.recipientBankAccount) ? "Recipient account is not valid" : _bill.recipientBankAccount.accountName,
            subText: isNullOrUndefined(_bill.recipientBankAccount)
                ? ""
                : `${_bill.recipientBankAccount.accountNumber} • ${getBankName(_bill.recipientBankAccount.bankCode)}`,
            textType: isNullOrUndefined(_bill.recipientBankAccount) ? TextType.ERROR : undefined,
        },
        {
            key: "Purpose",
            text: _bill.purpose === ERROR ? "Purpose was not given" : _bill.purpose,
            textType: _bill.purpose === ERROR ? TextType.ERROR : undefined,
        },
        {
            key: "Amount",
            text: _bill.amount === ERROR ? "Amount is not valid" : _bill.amount,
            textType: _bill.amount === ERROR ? TextType.ERROR : TextType.BALANCE,

            subText: _bill.amount === ERROR ? undefined : _bill.fee,
            subTextType: _bill.amount === ERROR ? undefined : SubTextType.AMOUNT_FEE,
        },
        {
            key: "Action",
            text: "",
            textType: TextType.EDIT_DELETE,
            onEdit: () => onEdit(_bill),
            onRemove: () => onRemove(_index),
        },
    ];
};

export const TableStatusTextType: {
    [value in TransactionStatus]: TextType;
} = {
    [TransactionStatus.CANCELLED]: TextType.TRANSACTION_STATUS_CANCELLED,
    [TransactionStatus.DECLINED]: TextType.TRANSACTION_STATUS_FAILED_STRIKE,
    [TransactionStatus.FAILED]: TextType.TRANSACTION_STATUS_FAILED,
    [TransactionStatus.PENDING_APPROVAL]: TextType.TRANSACTION_STATUS_PENDING_APPROVAL,
    [TransactionStatus.PENDING_FRAUD_REVIEW]: TextType.TRANSACTION_STATUS_PENDING_FRAUD_REVIEW,
    [TransactionStatus.PROCESSING]: TextType.TRANSACTION_STATUS_PROCESSING,
    [TransactionStatus.SUCCESS]: TextType.TRANSACTION_STATUS_SUCCESS,
};

export const BulkTransferHeaderProcessing: TableHead = [
    { pos: 1, text: "From", headType: TableColumnType.LOGO_FROM_TEXT },
    { pos: 2, text: "To", headType: TableColumnType.LOGO_SUBTEXT_FROM_TEXT },
    { pos: 3, text: "Purpose", headType: TableColumnType.TEXT },
    { pos: 4, text: "Amount", headType: TableColumnType.AMOUNT_WITH_SUBTEXT },
    { pos: 5, text: "Status", headType: TableColumnType.STATUS },
];

export const BulkTransferRowProcessing = (_bill: TransferDetail) => {
    return [
        {
            key: "From",
            text: _bill.originatingAccountId === ERROR ? "Sender account is not valid" : _bill.selectedUserAccount?.bankAccount?.accountName,
        },
        {
            key: "To",
            text: isNullOrUndefined(_bill.recipientBankAccount) ? "Recipient account is not valid" : _bill.recipientBankAccount.accountName,
            subText: isNullOrUndefined(_bill.recipientBankAccount)
                ? ""
                : `${_bill.recipientBankAccount.accountNumber} • ${getBankName(_bill.recipientBankAccount.bankCode)}`,
        },
        {
            key: "Purpose",
            text: _bill.purpose === ERROR ? "Purpose was not given" : _bill.purpose,
        },
        {
            key: "Amount",
            text: _bill.amount === ERROR ? "Amount is not valid" : _bill.amount,
            textType: TextType.BALANCE,
            subText: _bill.fee,
            subTextType: SubTextType.AMOUNT_FEE,
        },
        {
            key: "Status",
            // key: "Status",
            text: _bill.status,
            textType: TableStatusTextType[_bill.status],
        },
    ];
};
