import React from "react";
import TerminalRequested from "../../../../../../assets/svg/Terminal/terminal-requested.svg";
import { ReactComponent as TerminalRequestedStepper } from "../../../../../../assets/svg/Terminal/terminal-requested-stepper.svg";

interface Props {
    sm?: boolean;
    isTray?: boolean;
}

function TerminalRequestedCard(props: Props): JSX.Element {
    return (
        <>
            {!props.sm && (
                <div className="flex flex-col items-start justify-start space-y-5 px-6 py-4">
                    <div className="flex flex-col items-start justify-start space-y-3">
                        <div className="flex items-center justify-start space-x-2">
                            <img src={TerminalRequested} alt="check mark" />
                            <span className="text-base font-medium text-black-secondary md:text-lg">Terminal Requested</span>
                        </div>
                        <TerminalRequestedStepper />
                    </div>
                    <span className="text-xs font-normal text-black-tertiary md:text-sm">You will get a feedback on your request in 24 - 48 hrs</span>
                </div>
            )}
            {props.sm && (
                <div className={"flex flex-col items-start justify-start " + `${props.isTray ? "space-y-4" : "space-y-1"}`}>
                    <div className="flex items-center justify-start space-x-2">
                        <img src={TerminalRequested} alt="check mark" />
                        <span className="text-base font-medium text-black-secondary">Terminal Requested</span>
                    </div>
                    {props.isTray && <TerminalRequestedStepper />}
                    <span className={"text-sm font-normal " + `${props.isTray ? "text-black-secondary" : "text-black-tertiary"}`}>
                        You will get a feedback on your request in 24 - 48 hrs
                    </span>
                </div>
            )}
        </>
    );
}

export default TerminalRequestedCard;
