import React, { useCallback, useEffect, useState } from "react";

interface SwitchProps {
    isDisable?: boolean;
    isActive: boolean;
    onToggle: (state: boolean) => void;
}

function ToggleSwitch({ isActive = false, onToggle, isDisable }: SwitchProps): JSX.Element {
    const [active, setActive] = useState(isActive);

    useEffect(() => {
        setActive(isActive);
    }, [isActive]);

    const handleToggle = useCallback(() => {
        setActive((prev) => !prev);
        onToggle(!active);
    }, [active, onToggle]);

    return (
        <div
            role="checkbox"
            tabIndex={isDisable ? -1 : 0} // Disable focus if isDisable is true
            onClick={isDisable ? undefined : handleToggle} // Prevent click if isDisable is true
            aria-checked={active ? "true" : "false"}
            className={
                `relative flex h-5 w-9 items-center rounded-full px-1.5 ` +
                `${active ? (isDisable ? "bg-grey" : "bg-black") : "justify-end bg-grey"} ` +
                `${isDisable ? "cursor-not-allowed" : "cursor-pointer"}`
            }
        >
            <div
                className={
                    `absolute left-0.5 h-4 w-4 transform rounded-full bg-white duration-200 ease-out ` +
                    `${active ? "translate-x-4" : "translate-x-0"}`
                }
            />
        </div>
    );
}

export default ToggleSwitch;
