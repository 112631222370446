import { ExcludeProps, InputPropsToExclude } from "./types";
import React, { InputHTMLAttributes, ReactNode, useEffect, useState } from "react";

import CurrencyCode from "../currency-code";
import Input from "./Input";
import formatNumber from "../../utils/formatNumber";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { useMoneyToNumber } from "../../hooks/useMoneyToNumber";

interface Props extends ExcludeProps<InputHTMLAttributes<HTMLInputElement>, InputPropsToExclude> {
    fee?: ReactNode;
    type?: "number" | "string";
    icon?: ReactNode;
    label?: string;
    value?: string | undefined;
    currency?: ReactNode;
    iconType?: string;
    isActive?: boolean;
    iconRight?: ReactNode;
    inputSize?: "sm" | "md" | "lg" | "xl" | "2xl";
    isLoading?: boolean;
    isDisabled?: boolean;
    helperText?: React.ReactNode;
    placeholder?: ReactNode;
    alwaysActive?: boolean;
    mobileHelperText?: boolean;
    inputPlaceholder?: string;
    isControlledInput?: boolean;

    onChange?: (amount: string | number) => void;
    onClickRightIcon?: () => void;
}

function removeNonNumericChars(string: string | number): string {
    return String(string).replace(new RegExp(`[^0-9.]`, "gi"), "");
}

function parse(newValue: string | number): [number, string] {
    const prepared = removeNonNumericChars(newValue);
    let num = Number(prepared);

    const decimalIndex = prepared.indexOf(".");
    let str: string;
    if (decimalIndex > -1) {
        const whole = prepared.substring(0, decimalIndex);
        const fractional = prepared.substring(decimalIndex + 1);
        str = formatNumber(Number(whole), false, false) + "." + fractional.replace(".", "");
    } else {
        str = formatNumber(num, false, false);
    }

    if (isNullOrUndefined(num)) {
        num = Number(removeNonNumericChars(str));
    }

    return [num, str];
}

function MoneyInput(props: Props): JSX.Element {
    const [valueFormatted, setValueFormatted] = useState<string>("");

    const isFeeValid = useMoneyToNumber(valueFormatted) > 0;

    useEffect(() => {
        if (!props.value) return setValueFormatted("");
        const values = parse(props.value);
        setValueFormatted(values[1]);
    }, [props.value]);

    return (
        <Input
            {...props}
            type="text"
            label={props.label}
            value={useMoneyToNumber(valueFormatted) ? valueFormatted : ""}
            icon={props.currency || <CurrencyCode />}
            isActive={useMoneyToNumber(valueFormatted) > 0}
            inputSize={props.inputSize || "xl"}
            iconRight={props.iconRight || props.fee}
            helperText={props.helperText}
            mobileHelperText={props.mobileHelperText}
            onClickRightIcon={props.onClickRightIcon}
            iconRightClassName={props.fee ? (isFeeValid ? "!border-none !pr-0" : "hidden") : ""}
            onChange={(newValue) => {
                const values = parse(newValue);
                if (props.max && values[0] > Number(props.max)) return;
                if (!props.isControlledInput) setValueFormatted(values[1]);
                if (props.type === "number") {
                    return props.onChange && props.onChange(values[0]);
                }
                props.onChange && props.onChange(values[1]);
            }}
        />
    );
}

export default MoneyInput;
