import React from "react";
import PosTransaction from "../../../../../models/posTransaction";
import { BasicFinalStatus } from "../../../../../models/posTransaction.constants";

interface Props {
    terminal: PosTransaction | null;
}

export default function TerminalStatusTag({ terminal }: Props) {
    return (
        <div>
            {terminal?.status === BasicFinalStatus.FAILED && (
                <span className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error">
                    Failed
                </span>
            )}
            {terminal?.status === BasicFinalStatus.SUCCESSFUL && (
                <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                    Success
                </span>
            )}
        </div>
    );
}
