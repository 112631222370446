import { useEffect, useState } from "react";

import CurrencyCode from "../currency-code";
import { IRootState } from "../../redux/rootReducer";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { useSelector } from "react-redux";

interface Props {
    value: string | number;
}

function Fee({ value }: Props): JSX.Element {
    const fees = useSelector((state: IRootState) => state.init.main?.meta.transferCharges);

    const [fee, setFee] = useState<string>("");

    useEffect(() => {
        if (!fees) return;

        let valueToEdit: number;

        if (isNullOrUndefined(value)) {
            return setFee("");
        }

        if (typeof value === "string") {
            const newValue = String(value).replace(new RegExp(`[^0-9.]`, "gi"), "");
            valueToEdit = Number(newValue);
        } else {
            valueToEdit = value;
        }

        if (valueToEdit <= 5000) return setFee(String(fees[1]));
        if (valueToEdit <= 50000) return setFee(String(fees[2]));
        if (valueToEdit > 50000) return setFee(String(fees[3]));
    }, [value]);

    return (
        <span>
            {<CurrencyCode />}
            {fee}{" "}
        </span>
    );
}

export default Fee;
