import { makeRequest } from "../../../../../../helpers/request/makeRequest";
import Parsers from "../../../../../../utils/parsers";

export async function unlinkMobileApp(): Promise<boolean> {
    const res = await makeRequest("/main/settings/security/app/unlink-device");
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.boolean(res.success);
}
