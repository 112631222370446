import { GenericObject } from "../../../helpers/types";
import Parsers from "../../../utils/parsers";

export default class NewAccountOpeningProgressGeneric {
    constructor(
        public total: number,
        public isCompleted: boolean,
        public numCompleted: number
    ) {}

    static create(obj: GenericObject): NewAccountOpeningProgressGeneric {
        return new NewAccountOpeningProgressGeneric(Parsers.number(obj.total), Parsers.boolean(obj.isCompleted), Parsers.number(obj.numCompleted));
    }

    progressPercentage(): number {
        if (this.total === 0) return 0;
        return Number(((this.numCompleted / this.total) * 100).toFixed());
    }
}
