import { AddManageCardAccessRequest, AddViewCardAccessRequest, RemoveCardUserAccessDTO } from "../../Services/cardsApi.types";
import { addManageCardAccess, addViewCardAccess, removeManageCardAccess, removeViewCardAccess } from "../../Services/cardsApi";
import {
    removeTeamMembersWithManageAccess,
    removeTeamMembersWithViewAccess,
    setTeamMembersWithManageAccess,
    setTeamMembersWithViewAccess,
} from "../../../../../redux/cards/slice/cardsSlice";
import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";
import { AppToastType } from "../../../../../redux/app-toast/app-toast-slice.types";

const useManageCardAccess = () => {
    const dispatch = useDispatch();
    const [ManageCardAccessError, setManageCardAccessError] = useState<string | null>(null);
    const [isManageCardAccessDone, setIsManageCardAccessDone] = useState<boolean>(false);
    const [isManageCardAccessLoading, setIsManageCardAccessLoading] = useState<boolean>(false);
    const [removeViewerAccessError, setRemoveViewerAccessError] = useState<string | null>(null);
    const [isRemoveViewerAccessDone, setIsRemoveViewerAccessDone] = useState<boolean>(false);
    const [isRemoveViewerAccessLoading, setIsRemoveViewerAccessLoading] = useState<boolean>(false);
    const [removeManageAccessError, setRemoveManageAccessError] = useState<string | null>(null);
    const [isRemoveManageAccessDone, setIsRemoveManageAccessDone] = useState<boolean>(false);
    const [isRemoveManageAccessLoading, setIsRemoveManageAccessLoading] = useState<boolean>(false);

    const handleRemoveViewerAccess = useCallback(
        async (_data: RemoveCardUserAccessDTO) => {
            try {
                setIsRemoveViewerAccessDone(false);
                setRemoveViewerAccessError(null);
                setIsRemoveViewerAccessLoading(true);
                await removeViewCardAccess({ memberId: _data.memberId });
                dispatch(removeTeamMembersWithViewAccess([_data.memberId]));
                dispatch(messageTrue({ message: `Team member's Access Removed` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                dispatch(messageTrue({ message: errorMessage, type: AppToastType.ERROR }));
                setRemoveViewerAccessError(errorMessage);
            } finally {
                setIsRemoveViewerAccessLoading(false);
                setIsRemoveViewerAccessDone(true);
            }
        },
        [dispatch]
    );

    const handleRemoveManageAccess = useCallback(
        async (_data: RemoveCardUserAccessDTO) => {
            try {
                setIsRemoveManageAccessDone(false);
                setRemoveManageAccessError(null);
                setIsRemoveManageAccessLoading(true);
                await removeManageCardAccess({ memberId: _data.memberId });
                dispatch(removeTeamMembersWithManageAccess([_data.memberId]));
                // dispatch(messageTrue({ message: `Team member's Access Removed` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                // dispatch(messageTrue({ message: errorMessage, type: AppToastType.ERROR }));
                setRemoveManageAccessError(errorMessage);
            } finally {
                setIsRemoveManageAccessLoading(false);
                setIsRemoveManageAccessDone(true);
            }
        },
        [dispatch]
    );

    const handleManageCardAccess = useCallback(
        async (_data: AddManageCardAccessRequest) => {
            try {
                setIsManageCardAccessDone(false);
                setManageCardAccessError(null);
                setIsManageCardAccessLoading(true);
                const res = await addManageCardAccess(_data);
                setIsManageCardAccessDone(true);
                dispatch(setTeamMembersWithManageAccess(res.teamMembersWithManageAccess));
                dispatch(messageTrue({ message: `Team member${_data.memberIds.length > 1 ? "s" : ""} granted access` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setManageCardAccessError(errorMessage);
            } finally {
                setIsManageCardAccessLoading(false);
            }
        },
        [dispatch]
    );

    const handleAddViewCardAccess = useCallback(
        async (_data: AddViewCardAccessRequest) => {
            try {
                setIsManageCardAccessDone(false);
                setManageCardAccessError(null);
                setIsManageCardAccessLoading(true);
                const res = await addViewCardAccess(_data);

                setIsManageCardAccessDone(true);
                dispatch(setTeamMembersWithViewAccess(res.teamMembersWithViewAccess));
                dispatch(messageTrue({ message: `Team member${_data.memberIds.length > 1 ? "s" : ""} granted access` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setManageCardAccessError(errorMessage);
            } finally {
                setIsManageCardAccessLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsManageCardAccessDone(false);
        setManageCardAccessError(null);
        setIsManageCardAccessLoading(false);
    }, []);

    return {
        ManageCardAccessError,
        isManageCardAccessDone,
        isManageCardAccessLoading,
        removeViewerAccessError,
        isRemoveViewerAccessDone,
        isRemoveViewerAccessLoading,
        removeManageAccessError,
        isRemoveManageAccessDone,
        isRemoveManageAccessLoading,
        handleRemoveViewerAccess,
        handleRemoveManageAccess,
        handleReset,
        handleManageCardAccess,
        handleAddViewCardAccess,
    };
};

export default useManageCardAccess;
