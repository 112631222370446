import { HintType, ListStyle } from "../../../../../../element/hint/hint.constant";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../../../services/zambia-application/registered/new/company.types";
import { FileDrop } from "react-file-drop";
import Hint from "../../../../../../element/hint";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../../../components/spinner";
import NewUploadedCard from "../../../../../../cards/new-uploaded-card";
import PreviewModal from "../../../../../../modals/preview-modal";
import RadioButton from "../../../../../../../../../../components/radio-button";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import useIdentityUploadComponent from "../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useIdentityUploadComponent";
import usePreview from "../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/usePreview";
import { useSelector } from "react-redux";

const MeansOfIdHints: CompanyHint[] = [
    {
        index: 0,
        span: <span>Choose your preferred means of identification before uploading.</span>,
    },
    {
        index: 1,
        span: <span>Ensure your ID number is visible on your means of ID.</span>,
    },
    {
        index: 2,
        span: <span>Ensure your ID is within its validity period.</span>,
    },
];

type LocationState = {
    from?: string;
};

function MeansOfId(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
        downloadedDocumentName: "Identification Document",
    });

    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const currentSignatory = useSelector((state: IRootState) => state.zambiaApplication.currentSignatory);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const fileInputRefSecond = useRef<HTMLInputElement | null>(null);

    const [type, setType] = useState<number | null>(null);
    const [uploaded, setIsUploaded] = useState(!!currentSignatory?.identification?.uploadId);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);
    const [secondSelectedFile, setSecondSelectedFile] = useState<File | null>(null);

    const isRegistered = accountOpening?.application.isRegistered;
    const isInternationalPassportSelected = accountOpening?.options.idType.find((_idType) => _idType.id === type)?.name === "International Passport";

    const { handleReset, handleSubmitIdentification, isSubmitIdentificationLoading } = useIdentityUploadComponent({
        onComplete: () => {
            if (fileInputRef?.current) {
                fileInputRef.current.value = "";
                setSelectedFile(null);
            }
            if (fileInputRefSecond?.current) {
                fileInputRefSecond.current.value = "";
                setSecondSelectedFile(null);
            }
            setIsUploaded(true);
        },
    });

    useEffect(() => {
        if (accountOpening?.options.idType.find((_idType) => _idType.id === type)?.name !== "International Passport") {
            if (!selectedFile || !secondSelectedFile) return;
            void handleSubmitIdentification({ type: type as number, front: selectedFile, back: secondSelectedFile });
        } else {
            if (!selectedFile) return;
            void handleSubmitIdentification({ type: type as number, file: selectedFile });
        }
    }, [type, selectedFile, secondSelectedFile]);

    const onFileChange = (_files: FileList | null) => {
        setSelectedFile(_files && _files[0]);
        // if (fileInputRef.current) fileInputRef.current.value = _files;
        // handleSubmitIdentification({type: type as number, file: _files});
    };

    const onSecondFileChange = (_files: FileList | null) => {
        setSecondSelectedFile(_files && _files[0]);
    };

    const handleReUpload = () => {
        setIsUploaded(false);
        setSelectedFile(null);
        setSecondSelectedFile(null);
    };

    const onTargetClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };
    const onSecondTargetClick = () => {
        fileInputRefSecond.current && fileInputRefSecond.current.click();
    };

    const handleBack = () => {
        navigate(
            locationState?.from ||
                (isRegistered ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS : Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS)
        );
    };

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Identification Preview"
                />
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    {accountOpening && (
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Means of ID"
                                subTitle="Provide us with a valid means of ID"
                                progress={uploaded ? 100 : 0}
                                withBorder={!uploaded}
                            >
                                <div className="w-full">
                                    {!uploaded && (
                                        <div className="flex w-full flex-col items-start justify-start space-y-4">
                                            <div className="flex w-full flex-col items-center space-x-0 space-y-4 xs:flex-row xs:space-x-10 xs:space-y-0">
                                                <div className="flex w-max min-w-max flex-col items-start justify-start space-y-4">
                                                    {accountOpening?.options.idType?.map((el) => (
                                                        <RadioButton
                                                            id={String(el.id)}
                                                            checked={type === el.id}
                                                            text={el.name}
                                                            key={el.id}
                                                            func={() => {
                                                                if (fileInputRef?.current) {
                                                                    fileInputRef.current.value = "";
                                                                    setSelectedFile(null);
                                                                }
                                                                if (fileInputRefSecond?.current) {
                                                                    fileInputRefSecond.current.value = "";
                                                                    setSecondSelectedFile(null);
                                                                }
                                                                handleReset();
                                                                setType(el.id);
                                                            }}
                                                            size="sm"
                                                        />
                                                    ))}
                                                </div>
                                                <div className="w-full cursor-pointer">
                                                    {!isInternationalPassportSelected && (
                                                        <p className="w-full text-center text-base font-medium text-black-secondary">
                                                            Select document to upload
                                                        </p>
                                                    )}
                                                    <div
                                                        className={
                                                            "flex flex-col items-start justify-start space-y-4 xs:flex-row xs:space-x-4 xs:space-y-0 " +
                                                            `${!isInternationalPassportSelected ? "mt-4" : ""}`
                                                        }
                                                    >
                                                        <div className="flex h-32 w-full cursor-pointer flex-col items-center justify-center space-y-2 rounded-lg border border-dashed border-black-quat bg-grey-backdrop px-2 transition-all hover:bg-blue-senary">
                                                            <FileDrop
                                                                onTargetClick={onTargetClick}
                                                                onDrop={(files: FileList | null) => onFileChange(files)}
                                                            >
                                                                <input
                                                                    type="file"
                                                                    ref={fileInputRef}
                                                                    className="hidden"
                                                                    onChange={(event) => {
                                                                        if (event.target.files && event.target.files[0].size > 5242880) {
                                                                            return setIsFileSizeExceeded(true);
                                                                        }
                                                                        setIsFileSizeExceeded(false);
                                                                        onFileChange(event.target.files);
                                                                    }}
                                                                    accept="image/jpeg, image/png, application/pdf, .pdf"
                                                                    multiple
                                                                />
                                                                {!selectedFile && (
                                                                    <>
                                                                        {/* <UploadCloud /> */}
                                                                        <p className="text-center text-sm font-medium text-blue">
                                                                            {isInternationalPassportSelected ? "Biodata Page" : "Front Page"}
                                                                        </p>
                                                                        <p className="text-center text-sm text-black-tertiary">
                                                                            or Drag and Drop it here
                                                                        </p>
                                                                        {isFileSizeExceeded && (
                                                                            <>
                                                                                <br />
                                                                                <span className="text-xs text-error">*File exceeded 5MB</span>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {selectedFile && (
                                                                    <div className="flex flex-col items-center justify-center space-y-2">
                                                                        <span className="break-all text-black-tertiary">{selectedFile.name}</span>
                                                                        {isSubmitIdentificationLoading && <LencoSpinner size="sm" />}
                                                                    </div>
                                                                )}
                                                            </FileDrop>
                                                        </div>
                                                        {!isInternationalPassportSelected && (
                                                            <div className="flex h-32 w-full cursor-pointer flex-col items-center justify-center space-y-2 rounded-lg border border-dashed border-black-quat bg-grey-backdrop px-2 transition-all hover:bg-blue-senary">
                                                                <FileDrop
                                                                    onTargetClick={onSecondTargetClick}
                                                                    onDrop={(files: FileList | null) => onSecondFileChange(files)}
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        ref={fileInputRefSecond}
                                                                        className="hidden"
                                                                        onChange={(event) => {
                                                                            if (event.target.files && event.target.files[0].size > 5242880) {
                                                                                return setIsFileSizeExceeded(true);
                                                                            }
                                                                            setIsFileSizeExceeded(false);
                                                                            onSecondFileChange(event.target.files);
                                                                        }}
                                                                        accept="image/jpeg, image/png, application/pdf, .pdf"
                                                                        multiple
                                                                    />
                                                                    {!secondSelectedFile && (
                                                                        <>
                                                                            {/* <UploadCloud /> */}
                                                                            <p className="text-center text-sm font-medium text-blue">Back Page</p>
                                                                            <p className="text-center text-sm text-black-tertiary">
                                                                                or Drag and Drop it here
                                                                            </p>
                                                                            {isFileSizeExceeded && (
                                                                                <>
                                                                                    <br />
                                                                                    <span className="text-xs text-error">*File exceeded 5MB</span>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {secondSelectedFile && (
                                                                        <div className="flex flex-col items-center justify-center space-y-2">
                                                                            <span className="break-all text-black-tertiary">
                                                                                {secondSelectedFile.name}
                                                                            </span>
                                                                            {isSubmitIdentificationLoading && <LencoSpinner size="sm" />}
                                                                        </div>
                                                                    )}
                                                                </FileDrop>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {uploaded && (
                                        <NewUploadedCard
                                            text={
                                                accountOpening?.options.idType.find((_) => _.id === currentSignatory?.identification?.type)?.name ||
                                                "Means of ID"
                                            }
                                            handleRedo={handleReUpload}
                                            handlePreviewDocument={() => void handlePreviewDocument(currentSignatory?.identification?.uploadId || "")}
                                        />
                                    )}
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp
                                        type="button"
                                        color="grey"
                                        ripple="light"
                                        buttonType="secondary"
                                        func={currentSignatory?.identification?.uploadId && !uploaded ? () => setIsUploaded(true) : handleBack}
                                        fullWidth
                                    >
                                        <span>
                                            {currentSignatory?.identification?.uploadId && !uploaded
                                                ? "Cancel"
                                                : `Back${locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}`}
                                        </span>
                                    </ButtonComp>
                                </div>
                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                    <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                        <ButtonComp
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            // isLoading={isUpdateCompanyDetailsLoading}
                                            disable={!uploaded}
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname: isRegistered
                                                            ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH
                                                            : Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.PHOTOGRAPH,
                                                    },
                                                    {
                                                        state: {
                                                            from: isRegistered
                                                                ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID
                                                                : Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID,
                                                        },
                                                    }
                                                )
                                            }
                                            fullWidth
                                        >
                                            <span>Continue</span>
                                        </ButtonComp>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={MeansOfIdHints} />
                </div>
            </div>
        </>
    );
}

export default MeansOfId;
