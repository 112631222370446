import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../../redux/rootReducer";
import { ZambiaCollectionsStageType } from "../../../../../../redux/zambia/collections/collectionsSlice.types";
import { resetAllZambiaCollectionsData, setZambiaCollectionsStage } from "../../../../../../redux/zambia/collections/collectionsSlice";
import TabButton from "../../../../../../components/TabButton";

interface Props {
    children: React.ReactNode;
}

function ZambiaCollectionLayout(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const collectionsStage = useSelector((state: IRootState) => state.zambiaCollections.collectionsStage);

    useEffect(() => {
        return () => {
            dispatch(resetAllZambiaCollectionsData());
        };
    }, []);

    return (
        <>
            <div className="flex h-full max-h-full w-full flex-col">
                <TabButton
                    buttons={[
                        {
                            text: "Transactions",
                            isActive: collectionsStage === ZambiaCollectionsStageType.TRANSACTION,
                            onClick: () => dispatch(setZambiaCollectionsStage(ZambiaCollectionsStageType.TRANSACTION)),
                        },
                        {
                            text: "Settlements",
                            isActive: collectionsStage === ZambiaCollectionsStageType.SETTLEMENTS,
                            onClick: () => dispatch(setZambiaCollectionsStage(ZambiaCollectionsStageType.SETTLEMENTS)),
                        },
                    ]}
                />

                <div className="relative flex w-full flex-grow flex-col">{props.children}</div>
            </div>
        </>
    );
}

export default ZambiaCollectionLayout;
