import React from "react";
import Transaction from "../../../../../../models/transaction";

interface Props {
    transaction: Transaction | null;
}

export default function PayoutStatusTag({ transaction }: Props) {
    return (
        <div>
            {transaction?.isSuccess && (
                <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                    Settled
                </span>
            )}
            {transaction?.isProcessing && (
                <span className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning">
                    Pending
                </span>
            )}
        </div>
    );
}
