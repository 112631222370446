import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { setLandingCountry, setLandingLayoutInviteURL } from "../../redux/landing/landingSlice";
import { useLayoutEffect, useState } from "react";

import CountrySelect from "./components/landing/country-select";
import { InviteUrlParams } from "../../modules/landing/landing.constant";
import { LencoActiveCountries } from "../../redux/init/slice/initSlice.types";
import { LencoCountriesOptions } from "./services/country.constant";
import LencoSpinner from "../spinner";
import { ReactComponent as Logo } from "../../assets/svg/lencoLogo.svg";
import Parsers from "../../utils/parsers";
import Slider from "react-slick";
import { SliderSettings } from "./services/landing-layout.constant";
import img1 from "../../assets/images/landing/payment.png";
import img2 from "../../assets/images/landing/collections.png";
import img3 from "../../assets/images/landing/management.png";
import { useAppSelector } from "../../redux/hooks";
import { useDispatch } from "react-redux";

interface Props {
    isLoading?: boolean;
    children?: React.ReactNode;
}

function LandingLayout(props: Props): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { a, b, type } = useParams<InviteUrlParams>();

    const currentCountry = useAppSelector((state) => state.landing.country);

    // const isZambia = currentCountry === LencoActiveCountries.ZAMBIA;
    // const isNigeria = currentCountry === LencoActiveCountries.NIGERIA;
    const queryParams = new URLSearchParams(location.search);
    const countryParam = queryParams.get("country");
    const activeCountry = LencoCountriesOptions.find((_country) => _country.value === currentCountry);
    const selectedCountry: LencoActiveCountries | undefined = countryParam
        ? Parsers.nullableEnum(countryParam, LencoActiveCountries) || undefined
        : undefined;

    const [isCountryNotSelectable, setIsCountryNotSelectable] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (!a || !b || !type) return;
        dispatch(setLandingLayoutInviteURL(`invite/${type}/${a}/${b}`));
    }, [a, b, type]);

    useLayoutEffect(() => {
        // setIsShowLogin(
        //     location.pathname.includes("/signup") ||
        //         location.pathname.includes("/forgot-password") ||
        //         location.pathname.includes("/signup/business-type") ||
        //         location.pathname.includes("/signup/account") ||
        //         location.pathname.includes("/wait-list") ||
        //         location.pathname.includes("/director") ||
        //         location.pathname.includes("/referee")
        // );
        setIsCountryNotSelectable(location.pathname.includes("/signup"));
        if (selectedCountry) {
            dispatch(setLandingCountry(selectedCountry));
        }
    }, [location, selectedCountry]);

    return (
        <>
            <div className="layout-section grid h-screen w-full grid-cols-1 overflow-x-hidden bg-grey-backdrop lg:grid-cols-12" tabIndex={-1}>
                <div className="relative col-span-7 m-0 flex h-full w-full flex-col overflow-y-auto bg-grey-backdrop p-0" tabIndex={-1}>
                    <div className="flex items-center justify-between px-4 pt-6 2xs:px-10">
                        <Logo className="cursor-pointer" onClick={() => navigate("/login")} />
                        {location.pathname !== "/signup" && location.pathname.includes("/signup") && activeCountry?.icon && (
                            <CountrySelect
                                img={activeCountry?.icon || null}
                                options={LencoCountriesOptions.map((_options) => ({ value: _options.value, text: _options.name }))}
                                onClick={(_value) => dispatch(setLandingCountry(_value))}
                                isDisabled={isCountryNotSelectable}
                            />
                        )}
                    </div>
                    <main className="relative flex w-full max-w-6xl flex-grow flex-col items-center justify-start overflow-y-auto py-8">
                        <div className="flex w-full flex-grow flex-col items-center justify-center px-4 lg:px-8">
                            {props.isLoading ? (
                                <div className="flex h-full items-center justify-center">
                                    <LencoSpinner size="lg" />
                                </div>
                            ) : (
                                props.children || <Outlet />
                            )}
                        </div>
                    </main>
                </div>

                <div
                    className="col-span-5 hidden h-0 flex-row justify-center overflow-hidden bg-grey-backdrop lg:visible lg:flex lg:h-full lg:flex-col lg:bg-grey-secondary"
                    tabIndex={-1}
                >
                    <Slider {...SliderSettings}>
                        <div className="item" tabIndex={-1}>
                            <img src={img1} alt="Payout" className="m-auto w-full max-w-26" />
                            <div className="mx-auto my-6 max-w-md text-center">
                                <h1 className="text-2xl font-bold text-black">Payout</h1>
                                <p className="pt-6 text-center text-sm font-normal text-black-secondary">
                                    Make up to 5000 payments instantly with no effort. Upload a file to make bulk payouts from the dashboard or
                                    integrate our API to automate instant payouts,
                                </p>
                            </div>
                        </div>
                        <div className="item" tabIndex={-1}>
                            <img src={img3} alt="Expense Management" className="m-auto w-full max-w-26" />
                            <div className="mx-auto my-6 max-w-md text-center">
                                <h1 className="text-2xl font-bold text-black">Expense Management</h1>
                                <p className="pt-6 text-center text-sm font-normal text-black-secondary">
                                    Experience the power of automated reconciliation with transaction categorization, recipient grouping and post
                                    transaction comments.
                                </p>
                            </div>
                        </div>
                        <div className="item" tabIndex={-1}>
                            <img src={img2} alt="Bill Payment" className="m-auto w-full max-w-26" />
                            <div className="mx-auto my-6 max-w-md text-center">
                                <h1 className="text-2xl font-bold text-black">Collections</h1>
                                <p className="pt-6 text-center text-sm font-normal text-black-secondary">
                                    Accept payments locally and globally from customers through local and international cards, mobile money and bank
                                    transfer.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default LandingLayout;
