import { DropdownItem, DropdownItemValueType } from "../../helpers/types";

import NewDropdownItem from "../new-dropdown-item";
import NewSelect from "../new-select";

// import { ReactNode } from "react";

interface Props<T extends DropdownItemValueType> {
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    value: T | undefined;
    label?: string;
    options: Array<DropdownItem<T>>;
    withIcon?: boolean;
    fitHeight?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    dropdownSize?: "sm" | "md" | "lg" | "xl" | "2xl" | "fit";
    isSearchable?: boolean;

    onSelect: (value: T | undefined) => void;
    clickOutsideFunc?: () => void;

    //Todo- delete these from all components
    big?: boolean;
    canCancel?: boolean;
    onCancel?: () => void;
}

function CustomSelect<T extends DropdownItemValueType>({
    size = "xl",
    value,
    label = "",
    options,
    withIcon,
    fitHeight = false,
    isDisabled = false,
    placeholder,
    dropdownSize = "xl",
    isSearchable = false,
    onSelect,
    clickOutsideFunc = undefined,
}: Props<T>): JSX.Element {
    return isSearchable ? (
        <NewSelect
            size={size}
            icon={options.find((item) => item.value === value)?.icon || ""}
            value={options.find((item) => item.value === value)?.text || ""}
            label={label || placeholder || ""}
            withIcon={withIcon}
            fitHeight={fitHeight}
            isDisabled={isDisabled}
            clickOutsideFunc={clickOutsideFunc}
            clickAndClose
            // dropdownAlwaysDown
            isSearchable
        >
            {(_index) => (
                <NewDropdownItem
                    size={fitHeight ? "fit" : dropdownSize}
                    onClick={() => onSelect(options[_index].value)}
                    isDisabled={options[_index].value === value}
                >
                    <div className="flex w-full flex-row items-center justify-start space-x-2 text-black">
                        {options[_index].icon && (
                            <img
                                className={`${dropdownSize === "xl" || dropdownSize === "2xl" ? "h-6 w-6" : "h-5 w-5"}`}
                                src={options[_index].icon}
                                alt={options[_index].text + "icon"}
                            />
                        )}
                        <div className="flex w-full flex-col items-start justify-start space-y-0.5">
                            <span className="text-sm capitalize text-black-secondary">{options[_index].text}</span>
                            {options[_index].subtext && (
                                <p className="inline justify-center whitespace-pre-wrap break-words text-left text-xs text-black-tertiary">
                                    {options[_index].subtext}
                                </p>
                            )}
                        </div>
                    </div>
                </NewDropdownItem>
            )}
        </NewSelect>
    ) : (
        <NewSelect
            size={size}
            icon={options.find((item) => item.value === value)?.icon || ""}
            value={options.find((item) => item.value === value)?.text || ""}
            label={label || placeholder || ""}
            withIcon={withIcon}
            fitHeight={fitHeight}
            isDisabled={isDisabled}
            clickOutsideFunc={clickOutsideFunc}
            clickAndClose
            // dropdownAlwaysDown
        >
            {options &&
                options.map((option, index) => (
                    <NewDropdownItem
                        key={index}
                        size={fitHeight ? "fit" : dropdownSize}
                        onClick={() => onSelect(option.value)}
                        isDisabled={option.value === value}
                    >
                        <div className="flex w-full flex-row items-center justify-start space-x-2 text-black">
                            {option.icon && (
                                <img
                                    className={`${dropdownSize === "xl" || dropdownSize === "2xl" ? "h-6 w-6" : "h-5 w-5"}`}
                                    src={option.icon}
                                    alt={option.text + "icon"}
                                />
                            )}
                            <div className="flex w-full flex-col items-start justify-start space-y-0.5">
                                <span className="text-sm font-medium capitalize text-black-secondary">{option.text}</span>
                                {option.subtext && (
                                    <p className="inline justify-center whitespace-pre-wrap break-words text-left text-xs text-black-tertiary">
                                        {option.subtext}
                                    </p>
                                )}
                            </div>
                        </div>
                    </NewDropdownItem>
                ))}
        </NewSelect>
    );
}

export default CustomSelect;
