import React, { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import OnscreenSignatureModal from "../../modals/sign-on-screen-modal/OnscreenSignatureModa";
import SignatureManualIcon from "../../../../../../assets/svg/dashboard/application/signature-manual.svg";
import SignatureOnScreenIcon from "../../../../../../assets/svg/dashboard/application/signature-on-screen.svg";
import { SignatureStage } from "../../../services/nigeria-application/registered/registered.constant";
import useDimension from "../../../../../../hooks/useDimension";

interface Props<T> {
    isCreator: boolean | null;
    canAppendSignature: boolean;
    isPreviewBeforeSign?: boolean;
    isSignDocumentLoading: boolean | null;
    signDocumentResponse: T | null;
    isUploadSignedDocumentLoading: boolean | null;
    isAppendSignatureToDocumentLoading?: boolean | undefined;

    handleSignDocument: (file: Blob) => void;
    handlePreviewDocument?: () => void;
    handleUploadSignedDocument: (file: File) => void;
    handleDownloadDocumentToSign: () => void;
    handleAppendSignatureToDocument?: () => void;
}

function SignatureCard<T>(props: Props<T>): JSX.Element {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [signatureStage, setSignatureStage] = useState(SignatureStage.SIGN_ON_SCREEN);
    const [isSignOnScreenModalOpen, setIsSignOnScreenModalOpen] = useState(false);
    const { width } = useDimension();
    useEffect(() => {
        if (props.signDocumentResponse) setIsSignOnScreenModalOpen(false);
    }, [props.signDocumentResponse]);

    const onTargetClick = useCallback(() => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, [fileInputRef.current]);

    const onFileChange = useCallback((files: File | null) => {
        if (files) {
            props.handleUploadSignedDocument(files);
        }
    }, []);

    return (
        <>
            <div className="w-full">
                <OnscreenSignatureModal
                    active={isSignOnScreenModalOpen}
                    toggler={() => setIsSignOnScreenModalOpen(false)}
                    handleUploadSignature={props.handleSignDocument}
                    uploadSignatureLoading={props.isSignDocumentLoading}
                    uploadSignatureResponse={props.signDocumentResponse}
                />

                <div className="flex w-full flex-col space-y-5">
                    <div className="flex-start flex w-full space-x-4 border-b border-grey">
                        <div
                            className={
                                `w-fit pb-2 ` + `${signatureStage === SignatureStage.SIGN_ON_SCREEN ? "border-b-2 border-blue" : "cursor-pointer"}`
                            }
                            onClick={() => setSignatureStage(SignatureStage.SIGN_ON_SCREEN)}
                        >
                            <div
                                className={
                                    `mx-auto text-xs font-medium 2xs:text-sm ` +
                                    `${signatureStage === SignatureStage.SIGN_ON_SCREEN ? "pointer-events-none text-blue" : "text-black-quat"} `
                                }
                            >
                                SIGN ON-SCREEN
                            </div>
                        </div>
                        {props.isCreator && (
                            <div
                                className={
                                    `w-fit pb-2 ` + `${signatureStage === SignatureStage.SIGN_MANUALLY ? "border-b-2 border-blue" : "cursor-pointer"}`
                                }
                                onClick={() => setSignatureStage(SignatureStage.SIGN_MANUALLY)}
                            >
                                <div
                                    className={
                                        `mx-auto px-2 text-xs font-medium 2xs:text-sm ` +
                                        `${signatureStage === SignatureStage.SIGN_MANUALLY ? "pointer-events-none text-blue" : "text-black-quat"} `
                                    }
                                >
                                    SIGN MANUALLY
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex h-fit w-full flex-row items-start justify-between sm:h-24">
                        {/* <div */}
                        <div className="flex h-full w-full flex-grow flex-col items-start justify-between sm:w-70% sm:max-w-sm">
                            <div className="text-sm text-black-tertiary lg:text-base">
                                {signatureStage === SignatureStage.SIGN_ON_SCREEN && !props.isPreviewBeforeSign && (
                                    <p>You can use the tip of your finger (if using a touch device)</p>
                                )}
                                {signatureStage === SignatureStage.SIGN_ON_SCREEN && props.isPreviewBeforeSign && (
                                    <div className="flex flex-row flex-wrap items-center justify-start">
                                        <span>Read the document before signing it.</span>
                                        <div className="ml-1">
                                            <ButtonComp
                                                size="sm"
                                                type="button"
                                                color="blue"
                                                ripple="light"
                                                buttonType="tertiary"
                                                func={props.handlePreviewDocument}
                                                fullWidth
                                            >
                                                <span>Preview</span>
                                            </ButtonComp>
                                        </div>
                                        {/* <span className="text-blue cursor-pointer" onClick={props.handleDownloadDocumentToSign}>
											{" "}
											Preview
										</span> */}
                                    </div>
                                )}
                                {signatureStage === SignatureStage.SIGN_MANUALLY && <p>Download the document, sign it and upload it back here.</p>}
                            </div>
                            {signatureStage === SignatureStage.SIGN_ON_SCREEN && (
                                <div className="flex w-full flex-col justify-start space-x-0 space-y-4 pt-0 sm:!pt-0 2xs:flex-row 2xs:space-x-4 2xs:space-y-0 2xs:pt-4">
                                    {props.handleAppendSignatureToDocument && props.canAppendSignature && (
                                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                            <ButtonComp
                                                size="sm"
                                                type="button"
                                                color="blue"
                                                ripple="light"
                                                buttonType="flat"
                                                isLoading={props.isAppendSignatureToDocumentLoading}
                                                func={props.handleAppendSignatureToDocument}
                                                fullWidth
                                            >
                                                <span className="font-medium">Append Signature</span>
                                            </ButtonComp>
                                        </div>
                                    )}
                                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                        <ButtonComp
                                            size="sm"
                                            color="blue"
                                            ripple="light"
                                            buttonType="flat"
                                            fullWidth
                                            disable={!!props.isSignDocumentLoading}
                                            func={() => setIsSignOnScreenModalOpen(true)}
                                        >
                                            <span className="font-medium">Sign On-Screen</span>
                                        </ButtonComp>
                                    </div>
                                </div>
                            )}
                            {signatureStage === SignatureStage.SIGN_MANUALLY && (
                                <div className="flex w-full flex-col justify-end space-x-0 space-y-4 pt-2 2xs:flex-row 2xs:space-x-4 2xs:space-y-0 2xs:pt-6">
                                    <div className="relative order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            className="hidden"
                                            onChange={(event) => onFileChange(event.target.files && event.target.files[0])}
                                            accept="image/jpeg, image/png, application/pdf, .pdf"
                                            multiple
                                        />
                                        <ButtonComp
                                            size="sm"
                                            type="button"
                                            color="blue"
                                            ripple="light"
                                            buttonType="flat"
                                            isLoading={props.isUploadSignedDocumentLoading}
                                            func={onTargetClick}
                                            fullWidth
                                        >
                                            <span className="font-medium">{width > 1024 && width < 1280 ? "Upload" : "Upload Signed Document"}</span>
                                        </ButtonComp>
                                    </div>
                                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                        <ButtonComp
                                            type="button"
                                            color="blue"
                                            ripple="light"
                                            buttonType="flat"
                                            size="sm"
                                            fullWidth
                                            // isLoading={props.isAppendSignatureToDocumentLoading}
                                            func={props.handleDownloadDocumentToSign}
                                        >
                                            <span className="font-medium">Download Document</span>
                                        </ButtonComp>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="mx-2 hidden h-full border border-grey-secondary sm:block"></div>
                        <div className="hidden h-full w-full max-w-30% border-grey-secondary sm:block">
                            <div className="flex h-full w-full items-center justify-center rounded-lg bg-grey-backdrop">
                                {signatureStage === SignatureStage.SIGN_MANUALLY && (
                                    <img
                                        src={SignatureManualIcon}
                                        className="ml-1 h-70% w-70%"
                                        alt="image that depicts a finger signing on a screen"
                                    />
                                )}
                                {signatureStage === SignatureStage.SIGN_ON_SCREEN && (
                                    <img
                                        src={SignatureOnScreenIcon}
                                        className="ml-1 h-70% w-70%"
                                        alt="image that depicts a finger signing on a screen"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignatureCard;
