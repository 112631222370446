import {
    AddAccountToNewUserGroupRequest,
    AddAccountToNewUserGroupResponse,
    AddAccountToUserGroupRequest,
    CreateUserGroupRequest,
    CreateUserGroupResponse,
    DeleteUserGroupRequest,
    DeleteUserGroupResponse,
    EditCustomerAccountAliasRequest,
    EditUserGroupRequest,
    EditUserGroupResponse,
    UserGroupAccountsRequest,
    UserGroupAccountsResponse,
    UserGroupsResponse,
} from "./people-api.types";
import { makeRequest, makeRequestThrowError, makeRequestWithSignal } from "../../../../helpers/request/makeRequest";

import CustomerAccount from "../../../../models/customerAccount";
import Parsers from "../../../../utils/parsers";
import UserGroup from "../../../../models/userGroup";

let controller = new AbortController();

export const userGroupAccounts = async (data: UserGroupAccountsRequest): Promise<UserGroupAccountsResponse> => {
    controller = new AbortController();
    const res = await makeRequestWithSignal("/main/user-groups/accounts", data, controller.signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        accounts: Parsers.classObjectArray(res.accounts, CustomerAccount),
        groupSize: Parsers.number(res.groupSize),
        total: Parsers.number(res.total),
    };
};

export const userGroups = async (): Promise<UserGroupsResponse> => {
    const res = await makeRequest("/main/user-groups", {});
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        userGroups: Parsers.classObjectArray(res.userGroups, UserGroup),
        groupSize: Parsers.number(res.groupSize),
    };
};
export const createUserGroup = async (data: CreateUserGroupRequest): Promise<CreateUserGroupResponse> => {
    const res = await makeRequest("/main/user-groups/create", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        userGroup: Parsers.classObjectNonNullable(res.userGroup, UserGroup),
    };
};
export const editUserGroup = async (data: EditUserGroupRequest): Promise<EditUserGroupResponse> => {
    const res = await makeRequest("/main/user-groups/edit", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        userGroup: Parsers.classObjectNonNullable(res.userGroup, UserGroup),
    };
};
export const deleteUserGroup = async (data: DeleteUserGroupRequest): Promise<DeleteUserGroupResponse> => {
    const res = await makeRequest("main/user-groups/delete", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};

export async function addAccountToUserGroup(data: AddAccountToUserGroupRequest): Promise<CustomerAccount> {
    const res = await makeRequestThrowError("/main/user-groups/accounts/add", data);
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.classObjectNonNullable(res.account, CustomerAccount);
}

export async function addAccountToNewUserGroup(data: AddAccountToNewUserGroupRequest): Promise<AddAccountToNewUserGroupResponse> {
    const res = await makeRequestThrowError("/main/user-groups/accounts/add/new", data);

    if (res instanceof Error) {
        throw res;
    }
    return {
        account: Parsers.classObjectNonNullable(res.account, CustomerAccount),
        userGroup: Parsers.classObjectNonNullable(res.userGroup, UserGroup),
    };
}

export async function removeAccountFromUserGroup(customerAccountId: string): Promise<CustomerAccount> {
    const res = await makeRequestThrowError("/main/user-groups/accounts/remove", { customerAccountId });
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.classObjectNonNullable(res.account, CustomerAccount);
}

export async function editCustomerAccountAlias(data: EditCustomerAccountAliasRequest): Promise<CustomerAccount> {
    const res = await makeRequestThrowError("/main/user-groups/accounts/edit-alias", data);
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.classObjectNonNullable(res.account, CustomerAccount);
}

export const abortPeople = (): void => {
    controller.abort();
};
