import { useCallback, useState } from "react";

import { RemoveManageCardAccessRequest } from "../../Services/cardsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { removeManageCardAccess } from "../../Services/cardsApi";
import { setTeamMembersWithManageAccess } from "../../../../../redux/cards/slice/cardsSlice";
import { useDispatch } from "react-redux";

interface UseCardsInterface {
    removeManageCardAccessError: string | null;
    isRemoveManageCardAccessDone: boolean;
    isRemoveManageCardAccessLoading: boolean;

    handleReset: () => void;
    handleRemoveManageCardAccess: (_data: RemoveManageCardAccessRequest) => Promise<void>;
}

const useRemoveManageCardAccess = (): UseCardsInterface => {
    const dispatch = useDispatch();
    const [removeManageCardAccessError, setRemoveManageCardAccessError] = useState<string | null>(null);
    const [isRemoveManageCardAccessDone, setIsRemoveManageCardAccessDone] = useState<boolean>(false);
    const [isRemoveManageCardAccessLoading, setIsRemoveManageCardAccessLoading] = useState<boolean>(false);

    const handleRemoveManageCardAccess = useCallback(
        async (_data: RemoveManageCardAccessRequest) => {
            try {
                setIsRemoveManageCardAccessDone(false);
                setRemoveManageCardAccessError(null);
                setIsRemoveManageCardAccessLoading(true);
                const res = await removeManageCardAccess(_data);
                setIsRemoveManageCardAccessDone(true);
                dispatch(setTeamMembersWithManageAccess(res.teamMembersWithManageAccess));
                dispatch(messageTrue({ message: "Team member access removed" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRemoveManageCardAccessError(errorMessage);
            } finally {
                setIsRemoveManageCardAccessLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsRemoveManageCardAccessDone(false);
        setRemoveManageCardAccessError(null);
        setIsRemoveManageCardAccessLoading(false);
    }, []);

    return {
        removeManageCardAccessError,
        isRemoveManageCardAccessDone,
        isRemoveManageCardAccessLoading,

        handleReset,
        handleRemoveManageCardAccess,
    };
};

export default useRemoveManageCardAccess;
