import { ExportZambiaTransactionsRequest, ExportZambiaTransactionsResponse } from "../../Services/transactionApi.types";
import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { exportZambiaTransactions } from "../../Services/transactionApi";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseExportZambiaTransactionsInterface {
    isExportZambiaTransactionsLoading: boolean;
    exportZambiaTransactionsErrorMessage: string;
    handleExportZambiaTransactions: (_data: ExportZambiaTransactionsRequest) => Promise<void>;
}

interface Props {
    onComplete: (_data: ExportZambiaTransactionsResponse) => void;
}

function useExportZambiaTransactions(props: Props): UseExportZambiaTransactionsInterface {
    const dispatch = useDispatch();

    const [isExportZambiaTransactionsLoading, setIsExportZambiaTransactionsLoading] = useState(false);
    const [exportZambiaTransactionsErrorMessage, setExportZambiaTransactionsErrorMessage] = useState<string>("");

    const handleExportZambiaTransactions = useCallback(
        async (_data: ExportZambiaTransactionsRequest) => {
            try {
                setIsExportZambiaTransactionsLoading(true);
                setExportZambiaTransactionsErrorMessage("");
                const res = await exportZambiaTransactions(_data);
                dispatch(messageTrue("Statement Requested Successful"));
                props.onComplete(res);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setExportZambiaTransactionsErrorMessage(errorMessage);
            } finally {
                setIsExportZambiaTransactionsLoading(false);
            }
        },
        [dispatch]
    );
    return {
        isExportZambiaTransactionsLoading,
        exportZambiaTransactionsErrorMessage,
        handleExportZambiaTransactions,
    };
}

export default useExportZambiaTransactions;
