import React, { useState } from "react";

import MemberCardHeaderSkeleton from "../../Skeleton/TeamMembers/MemberCardHeaderSkeleton";
import { MobileTeamMemberListPageState } from "../../../Services/team-members/team-members.constants";
import Skeleton from "../../../../../../components/skeleton";
import TeamMemberAccountDetails from "./teamMemberAccount/TeamMemberAccountDetails";
import TeamMemberDetailsHeader from "./TeamMemberDetailsHeader";
import TeamMemberFeatureDetails from "./teamMemberFeature/TeamMemberFeatureDetails";
import { TeamMemberItem } from "../../../Services/team-members/team-members.types";
import UserAccountMeta from "../../../../../../models/userAccountMeta";

interface TeamMemberDetailsProps {
    isSelf: boolean;
    isMobileLayout: boolean;
    userAccountNames: Map<string, string>;
    selectedTeamMember: TeamMemberItem | null;
    mobileLayoutPageState: MobileTeamMemberListPageState;
    isSelectedTeamMemberLoading: boolean;

    goBack: () => void;
    onRemoveFromTeam: (memberId: string) => void;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    handleAddNewAccounts: (item: TeamMemberItem, _accounts: UserAccountMeta[]) => void;
    removeAccountMetaFromTeamMember: (accountMetaToRemove: UserAccountMeta) => void;
}

function TeamMemberDetails(props: TeamMemberDetailsProps): JSX.Element {
    const [teamMemberTab, setTeamMemberTab] = useState(1);

    const handleTabSelection = (tabNumber: number) => {
        setTeamMemberTab(tabNumber);
    };

    return (
        <div
            className={
                "h-fit-available max-h-fit-available h-full w-full xl:pl-8 " +
                `${props.isMobileLayout && props.mobileLayoutPageState === MobileTeamMemberListPageState.LIST ? "hidden" : "flex flex-col"} `
            }
        >
            <div className="w-full sm:border-b-0.2 sm:pb-6">
                <div className="flex w-full flex-col sm:flex-row sm:justify-between">
                    {!props.selectedTeamMember && <MemberCardHeaderSkeleton />}

                    {props.selectedTeamMember && (
                        <TeamMemberDetailsHeader
                            goBack={props.goBack}
                            teamMember={props.selectedTeamMember}
                            handleAddNewAccounts={props.handleAddNewAccounts}
                            onRemove={props.onRemoveFromTeam}
                        />
                    )}
                </div>
            </div>

            <div className="flex w-fit items-center space-x-2 pt-6 text-sm font-medium text-black">
                {props.selectedTeamMember ? (
                    <>
                        <div
                            className={`cursor-pointer ${teamMemberTab === 1 ? "rounded bg-white p-2" : "bg-inherit"} p-2`}
                            onClick={() => handleTabSelection(1)}
                        >
                            <p>Account</p>
                        </div>
                        <div
                            className={`cursor-pointer ${teamMemberTab === 2 ? "rounded bg-white" : "bg-inherit"} p-2`}
                            onClick={() => handleTabSelection(2)}
                        >
                            <p>Features</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="h-9 w-20">
                            <Skeleton box />
                        </div>
                        <div className="h-9 w-20">
                            <Skeleton box />
                        </div>
                    </>
                )}
            </div>

            {teamMemberTab === 1 && (
                <TeamMemberAccountDetails
                    selectedTeamMember={props.selectedTeamMember}
                    onRemoveFromTeam={props.onRemoveFromTeam}
                    updateAccountMeta={props.updateAccountMeta}
                    removeAccountMetaFromTeamMember={props.removeAccountMetaFromTeamMember}
                    userAccountNames={props.userAccountNames}
                    isSelf={props.isSelf}
                />
            )}
            {teamMemberTab === 2 && (
                <TeamMemberFeatureDetails
                    selectedTeamMember={props.selectedTeamMember}
                    updateAccountMeta={props.updateAccountMeta}
                    removeAccountMetaFromTeamMember={props.removeAccountMetaFromTeamMember}
                    userAccountNames={props.userAccountNames}
                    isSelf={props.isSelf}
                />
            )}
        </div>
    );
}

export default TeamMemberDetails;
