import ButtonComp from "../../../../../button/ButtonComp";
import GoogleIcon from "../../../../../google-icon";

interface Props {
    country: string;
    isActive: boolean;
    countryAlt: string;
    countryImg: string;
    onClick: () => void;
}

function BusinessHead(props: Props) {
    return (
        <>
            <ButtonComp buttonType="tertiary" color="transparent" type="button" data-type="section" onClick={props.onClick} fullWidth>
                <div className="flex w-full items-center justify-between bg-grey-tertiary px-6 py-2">
                    <div className={`relative flex h-10 w-full items-center justify-start tracking-normal`} data-type="section" tabIndex={-1}>
                        <img
                            className="rounded-full border border-black-quat"
                            src={props.countryImg}
                            alt={props.countryAlt}
                            width="24px"
                            height="24px"
                        />
                        <div className="ml-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm" data-type="section" tabIndex={-1}>
                            {props.country}
                        </div>
                    </div>
                    <div
                        className={`flex items-center justify-center transition-all ` + `${props.isActive ? "rotate-0 transform" : "-rotate-180"} `}
                        data-type="section"
                    >
                        <GoogleIcon icon="keyboard_arrow_up" />
                    </div>
                </div>
            </ButtonComp>
        </>
    );
}

export default BusinessHead;
