import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

import SearchBar from "../../search-bar";
import useClickOutside from "../../../hooks/useClickOutside";
import { DropdownItem } from "../../../helpers/types";
import GoogleIcon from "../../google-icon";

interface LabelDropdownHeadProps<T extends number | string> {
    size?: "sm" | "md" | "lg";
    noArrow?: boolean;
    children: React.ReactNode;
    hasInput?: boolean;
    isCancel?: boolean;
    fitHeight?: boolean;
    inputValue: number | string | null;
    searchTerm?: string;
    placeholder: React.ReactNode;
    isDisabled?: boolean;
    isSearchable?: boolean;
    clickAndClose?: boolean;
    searchPlaceholder?: string;
    dropdownAlwaysDown?: boolean;
    optionsArray?: Array<DropdownItem<T>>;
    handleRemove?: (option: DropdownItem<T> | undefined) => void;
    cancelFunc?: () => void;
    onChangeFunc?: (e: string) => void;
    clickOutsideFunc?: () => void;
    handleChangeSearchTerm?: (e: string) => void;
}

function LabelTagCloudDropdownHead<T extends number | string>({
    size = "lg",
    noArrow = false,
    children,
    hasInput = false,
    isCancel = false,
    fitHeight = false,
    isDisabled = false,
    searchTerm = "",
    inputValue,
    placeholder,
    isSearchable = false,
    clickAndClose = false,
    searchPlaceholder = "Search",
    dropdownAlwaysDown = false,
    optionsArray,
    handleRemove,
    cancelFunc = undefined,
    onChangeFunc = undefined,
    clickOutsideFunc = undefined,
    handleChangeSearchTerm = undefined,
}: LabelDropdownHeadProps<T>): JSX.Element {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [y, setY] = useState<number | null>(null);
    const [value, setValue] = useState<number | string | null>(null);
    const [active, setActive] = useState<boolean>(false);
    const [isHover, setIsHover] = useState<boolean>(false);
    const [hasValue, setHasValue] = useState<boolean>(false);
    const [positionTop, setPositionTop] = useState<boolean>(false);

    const innerHeight = window.innerHeight;

    const domNode = useClickOutside(() => {
        setActive(false);
        clickOutsideFunc && clickOutsideFunc();
        handleChangeSearchTerm && handleChangeSearchTerm("");
    });

    useEffect(() => {
        if (domNode.current) {
            setY(domNode.current.getBoundingClientRect().top);
        }
    });

    useEffect(() => {
        if (dropdownAlwaysDown) {
            setPositionTop(false);
        } else {
            if (y) {
                const shouldSetPositionTop = y > innerHeight / 1.65;
                setPositionTop(shouldSetPositionTop);
            }
        }
    }, [innerHeight, y, dropdownAlwaysDown]);

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    useEffect(() => {
        setHasValue(!!value && ((typeof value === "string" && value.length > 0) || (typeof value === "number" && value > 0)));
    }, [value]);

    const handleKeypress = (event: KeyboardEvent<HTMLDivElement>) => {
        //it triggers by pressing the enter key
        if (event.key === "Enter") {
            setActive((prev) => !prev);
        }
    };

    return (
        <div
            className={`group/input relative flex h-full w-full flex-col items-center justify-start ` + `${isDisabled ? "pointer-events-none" : ""}`}
            id="dropdownDiv"
            ref={domNode}
        >
            <div
                onMouseEnter={() => !isDisabled && setIsHover(true)}
                onMouseLeave={() => !isDisabled && setIsHover(false)}
                className={
                    `group/input relative flex h-14 w-full items-center space-x-4 rounded-lg bg-white font-normal outline-none` +
                    ` px-4 capitalize transition-all duration-150 focus:outline-none` +
                    ` cursor-pointer border border-solid bg-transparent text-left text-base leading-relaxed shadow-none lg:hover:border-black-tertiary lg:hover:text-black-secondary lg:focus:border-black-secondary lg:focus:text-black-secondary` +
                    ` ${size === "lg" ? "h-12" : ""} ` +
                    ` ${size === "md" ? "h-10" : ""} ` +
                    ` ${size === "sm" ? "h-8" : ""} ` +
                    // ` ${hasValue ? "max-w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap" : "justify-end"} ` +
                    ` ${hasValue ? "max-w-full justify-between overflow-auto overflow-ellipsis whitespace-nowrap" : "justify-end"} ` +
                    ` ${hasValue && active ? "overflow-auto border-black" : ""} ` +
                    ` ${hasValue && !active ? "border-black-quin text-black-secondary lg:hover:border-black-tertiary lg:hover:text-black-secondary lg:focus:border-black-secondary lg:focus:text-black-secondary" : ""} ` +
                    ` ${!hasValue && active ? "border-black-secondary text-black-secondary" : ""} ` +
                    ` ${!hasValue && !active ? "border-black-quin text-black-tertiary" : ""} ` +
                    ` ${isHover ? "lg:text-black-secondary" : ""} `
                }
                tabIndex={isDisabled ? -1 : 0}
                onClick={() => {
                    if (hasInput) {
                        inputRef?.current?.focus();
                    }
                    if (isCancel && hasValue) {
                        setActive((prev) => prev);
                    } else {
                        setActive((prev) => !prev);
                    }
                }}
                onKeyDown={handleKeypress}
            >
                {!hasInput && value && (
                    <div
                        className={
                            `flex h-fit w-full max-w-full space-x-2 self-end overflow-auto overflow-ellipsis whitespace-nowrap pb-1 text-base text-black-secondary ` +
                            ` ${isDisabled ? "pointer-events-none bg-transparent text-black-quat" : ""} `
                        }
                    >
                        {/* {value} */}
                        {optionsArray?.map((item, index) => (
                            <div
                                key={index}
                                className=""
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove && handleRemove(item);
                                }}
                            >
                                <div className="flex items-center space-x-1 rounded bg-grey-secondary px-2 py-1.5">
                                    <span className="text-xs capitalize">
                                        {item.text.length > 4 ? item.text.slice(0, 4).toLowerCase() + "..." : item.text.toLowerCase()}
                                    </span>

                                    {/* cancel icon */}
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="#9A9DAD">
                                        <path
                                            d="m339-288 141-141 141 141 51-51-141-141 141-141-51-51-141 141-141-141-51
51 141 141-141 141 51 51ZM480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80
30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {!noArrow && (
                    <div
                        className={
                            `flex max-w-[20px] transform items-center justify-center self-center text-lg transition-transform duration-150 group-hover/input:!text-black-tertiary` +
                            ` ${active ? "rotate-180" : "rotate-0"} ` +
                            ` ${isDisabled ? "text-black-quat" : "text-black-secondary"} `
                        }
                    >
                        <GoogleIcon icon={"keyboard_arrow_down"} className="text-current" />
                    </div>
                )}

                {isCancel && hasValue && (
                    <div
                        className={`flex max-w-[20px] items-center justify-end text-lg text-black-secondary`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (cancelFunc) {
                                cancelFunc();
                            }
                        }}
                    >
                        <span className="material-symbols-rounded">close</span>
                    </div>
                )}
                {hasInput && (
                    <input
                        id={(placeholder as string) || ""}
                        name={(placeholder as string) || ""}
                        ref={inputRef}
                        type="text"
                        value={(inputValue as string) || ""}
                        onChange={(e) => {
                            onChangeFunc && onChangeFunc(e.target.value);
                        }}
                        autoComplete="off"
                        className={
                            `h-10 w-full placeholder-transparent focus:border-none focus:outline-none ` +
                            `${inputValue && (inputValue as string).length > 0 ? "text-black-secondary" : active ? "text-black" : "text-black"} ` +
                            `${isDisabled ? "pointer-events-none bg-transparent text-black-quat" : ""} `
                        }
                        placeholder="john@doe.com"
                    />
                )}
            </div>

            {placeholder && (
                <label
                    htmlFor="text"
                    className={
                        `pointer-events-none absolute left-4 z-10 !ml-0 leading-6 duration-150 group-hover/input:!text-black-tertiary ` +
                        ` ${active ? "top-0 text-2xs text-black-tertiary" : " top-4 text-base"} ` +
                        ` ${hasValue ? "!top-0 !text-2xs !text-black-tertiary" : "text-black-quat"} ` +
                        ` ${isDisabled ? "text-black-quat" : ""} `
                    }
                    onClick={() => inputRef?.current?.focus()}
                >
                    <span className="h-fit max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{placeholder}</span>
                </label>
            )}

            <div
                className={
                    `absolute z-40 h-fit w-full transform cursor-pointer overflow-hidden overflow-y-auto rounded bg-white shadow ` +
                    `${positionTop ? "bottom-full left-0 mb-2 origin-bottom" : "left-0 top-full mt-1 origin-top"} ` +
                    `${!active ? "pointer-events-none scale-0 opacity-0" : "scale-100 opacity-100"} ` +
                    `${!fitHeight ? "max-h-56" : ""} `
                }
            >
                {isSearchable && handleChangeSearchTerm && (
                    <div className="flex h-14 w-full items-center justify-start px-4">
                        <SearchBar placeholder={searchPlaceholder} value={searchTerm} onChange={handleChangeSearchTerm} />
                    </div>
                )}
                <div
                    className={`w-full`}
                    onClick={() => {
                        if (clickAndClose) {
                            setActive((prev) => !prev);
                        }
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default LabelTagCloudDropdownHead;

// import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

// import SearchBar from "../../search-bar";
// import useClickOutside from "../../../hooks/useClickOutside";
// import { DropdownItem } from "../../../helpers/types";

// interface LabelDropdownHeadProps<T extends number | string> {
//     size?: "sm" | "md" | "lg";
//     noArrow?: boolean;
//     children: React.ReactNode;
//     hasInput?: boolean;
//     isCancel?: boolean;
//     fitHeight?: boolean;
//     inputValue: number | string | null;
//     searchTerm?: string;
//     placeholder: React.ReactNode;
//     isDisabled?: boolean;
//     isSearchable?: boolean;
//     clickAndClose?: boolean;
//     searchPlaceholder?: string;
//     dropdownAlwaysDown?: boolean;
//     optionsArray?: Array<DropdownItem<T>>;
//     handleRemove?: (option: DropdownItem<T> | undefined) => void;
//     cancelFunc?: () => void;
//     onChangeFunc?: (e: string) => void;
//     clickOutsideFunc?: () => void;
//     handleChangeSearchTerm?: (e: string) => void;
// }

// function LabelTagCloudDropdownHead<T extends number | string>({
//     size = "lg",
//     noArrow = false,
//     children,
//     hasInput = false,
//     isCancel = false,
//     fitHeight = false,
//     isDisabled = false,
//     searchTerm = "",
//     inputValue,
//     placeholder,
//     isSearchable = false,
//     clickAndClose = false,
//     searchPlaceholder = "Search",
//     dropdownAlwaysDown = false,
//     optionsArray,
//     handleRemove,
//     cancelFunc = undefined,
//     onChangeFunc = undefined,
//     clickOutsideFunc = undefined,
//     handleChangeSearchTerm = undefined,
// }: LabelDropdownHeadProps<T>): JSX.Element {
//     const inputRef = useRef<HTMLInputElement | null>(null);

//     const [y, setY] = useState<number | null>(null);
//     const [value, setValue] = useState<number | string | null>(null);
//     const [active, setActive] = useState<boolean>(false);
//     const [isHover, setIsHover] = useState<boolean>(false);
//     const [hasValue, setHasValue] = useState<boolean>(false);
//     const [positionTop, setPositionTop] = useState<boolean>(false);

//     const innerHeight = window.innerHeight;

//     const domNode = useClickOutside(() => {
//         setActive(false);
//         clickOutsideFunc && clickOutsideFunc();
//         handleChangeSearchTerm && handleChangeSearchTerm("");
//     });

//     useEffect(() => {
//         if (domNode.current) {
//             setY(domNode.current.getBoundingClientRect().top);
//         }
//     });

//     useEffect(() => {
//         if (dropdownAlwaysDown) {
//             setPositionTop(false);
//         } else {
//             if (y) {
//                 const shouldSetPositionTop = y > innerHeight / 1.65;
//                 setPositionTop(shouldSetPositionTop);
//             }
//         }
//     }, [innerHeight, y, dropdownAlwaysDown]);

//     useEffect(() => {
//         setValue(inputValue);
//     }, [inputValue]);

//     useEffect(() => {
//         setHasValue(!!value && ((typeof value === "string" && value.length > 0) || (typeof value === "number" && value > 0)));
//     }, [value]);

//     const handleKeypress = (event: KeyboardEvent<HTMLDivElement>) => {
//         //it triggers by pressing the enter key
//         if (event.key === "Enter") {
//             setActive((prev) => !prev);
//         }
//     };

//     return (
//         <div
//             className={`group/input relative flex h-full w-full flex-col items-center justify-start ` +
//`${isDisabled ? "pointer-events-none" : ""}`}
//             id="dropdownDiv"
//             ref={domNode}
//         >
//             <div
//                 onMouseEnter={() => !isDisabled && setIsHover(true)}
//                 onMouseLeave={() => !isDisabled && setIsHover(false)}
//                 className={
//                     `group/input relative flex h-14 w-full items-center space-x-4 rounded-lg bg-white font-normal outline-none` +
//                     ` px-4 capitalize transition-all duration-150 focus:outline-none` +
//                     ` cursor-pointer border border-solid bg-transparent text-left text-base
// leading-relaxed shadow-none lg:hover:border-black-tertiary lg:hover:text-black-secondary
// lg:focus:border-black-secondary lg:focus:text-black-secondary` +
//                     ` ${size === "lg" ? "h-12" : ""} ` +
//                     ` ${size === "md" ? "h-10" : ""} ` +
//                     ` ${size === "sm" ? "h-8" : ""} ` +
//                     ` ${hasValue ? "max-w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap" : "justify-end"} ` +
//                     ` ${hasValue && active ? "border-black" : ""} ` +
//                     ` ${hasValue && !active ? "border-black-quin text-black-secondary
// lg:hover:border-black-tertiary lg:hover:text-black-secondary lg:focus:border-black-secondary lg:focus:text-black-secondary" : ""} ` +
//                     ` ${!hasValue && active ? "border-black-secondary text-black-secondary" : ""} ` +
//                     ` ${!hasValue && !active ? "border-black-quin text-black-tertiary" : ""} ` +
//                     ` ${isHover ? "lg:text-black-secondary" : ""} `
//                 }
//                 tabIndex={isDisabled ? -1 : 0}
//                 onClick={() => {
//                     if (hasInput) {
//                         inputRef?.current?.focus();
//                     }
//                     if (isCancel && hasValue) {
//                         setActive((prev) => prev);
//                     } else {
//                         setActive((prev) => !prev);
//                     }
//                 }}
//                 onKeyDown={handleKeypress}
//             >
//                 {!hasInput && value && (
//                     <div
//                         className={
//                             `flex h-fit w-full max-w-full space-x-2 self-end overflow-hidden
// overflow-ellipsis whitespace-nowrap pb-1 text-base text-black-secondary ` +
//                             ` ${isDisabled ? "pointer-events-none bg-transparent text-black-quat" : ""} `
//                         }
//                     >
//                         {/* {value} */}
//                         {optionsArray?.map((item, index) => (
//                             <div
//                                 key={index}
//                                 className=""
//                                 onClick={(e) => {
//                                     e.stopPropagation();
//                                     handleRemove && handleRemove(item);
//                                 }}
//                             >
//                                 <div className="flex items-center space-x-1 rounded bg-grey-secondary px-2 py-1.5">
//                                     <span className="text-xs capitalize">
//                                         {item.text.length > 4 ? item.text.slice(0, 4).toLowerCase() + "..." : item.text.toLowerCase()}
//                                     </span>

//                                     {/* cancel icon */}
//                                     <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="#9A9DAD">
//                                         <path
//                                             d="m339-288 141-141 141 141 51-51-141-141 141-141-51-51-141 141-141-141-51
// 51 141 141-141 141 51 51ZM480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80
// 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z"
//                                         />
//                                     </svg>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 )}

//                 {!noArrow && (
//                     <div
//                         className={
//                             `flex max-w-[20px] transform items-center justify-center
// self-center text-lg transition-transform duration-150 group-hover/input:!text-black-tertiary` +
//                             ` ${active ? "rotate-180" : "rotate-0"} ` +
//                             ` ${isDisabled ? "text-black-quat" : "text-black-secondary"} `
//                         }
//                     >
//                         <span className="material-symbols-outlined text-current">keyboard_arrow_up</span>
//                     </div>
//                 )}

//                 {isCancel && hasValue && (
//                     <div
//                         className={`flex max-w-[20px] items-center justify-end text-lg text-black-secondary`}
//                         onClick={(e) => {
//                             e.preventDefault();
//                             e.stopPropagation();
//                             if (cancelFunc) {
//                                 cancelFunc();
//                             }
//                         }}
//                     >
//                         <span className="material-symbols-rounded">close</span>
//                     </div>
//                 )}
//                 {hasInput && (
//                     <input
//                         id={(placeholder as string) || ""}
//                         name={(placeholder as string) || ""}
//                         ref={inputRef}
//                         type="text"
//                         value={(inputValue as string) || ""}
//                         onChange={(e) => {
//                             onChangeFunc && onChangeFunc(e.target.value);
//                         }}
//                         autoComplete="off"
//                         className={
//                             `h-10 w-full placeholder-transparent focus:border-none focus:outline-none ` +
//                             `${inputValue && (inputValue as string).length > 0 ? "text-black-secondary" : active ? "text-black" : "text-black"} ` +
//                             `${isDisabled ? "pointer-events-none bg-transparent text-black-quat" : ""} `
//                         }
//                         placeholder="john@doe.com"
//                     />
//                 )}
//             </div>

//             {placeholder && (
//                 <label
//                     htmlFor="text"
//                     className={
//                         `pointer-events-none absolute left-4 z-10 !ml-0 leading-6 duration-150 group-hover/input:!text-black-tertiary ` +
//                         ` ${active ? "top-0 text-2xs text-black-tertiary" : " top-3 text-base"} ` +
//                         ` ${hasValue ? "!top-0 !text-2xs !text-black-tertiary" : "text-black-quat"} ` +
//                         ` ${isDisabled ? "text-black-quat" : ""} `
//                     }
//                     onClick={() => inputRef?.current?.focus()}
//                 >
//                     <span className="h-fit max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{placeholder}</span>
//                 </label>
//             )}

//             <div
//                 className={
//                     `absolute z-40 h-fit w-full transform cursor-pointer overflow-hidden overflow-y-auto rounded bg-white shadow ` +
//                     `${positionTop ? "bottom-full left-0 mb-2 origin-bottom" : "left-0 top-full mt-1 origin-top"} ` +
//                     `${!active ? "pointer-events-none scale-0 opacity-0" : "scale-100 opacity-100"} ` +
//                     `${!fitHeight ? "max-h-56" : ""} `
//                 }
//             >
//                 {isSearchable && handleChangeSearchTerm && (
//                     <div className="flex h-14 w-full items-center justify-start px-4">
//                         <SearchBar placeholder={searchPlaceholder} value={searchTerm} onChange={handleChangeSearchTerm} />
//                     </div>
//                 )}
//                 <div
//                     className={`w-full`}
//                     onClick={() => {
//                         if (clickAndClose) {
//                             setActive((prev) => !prev);
//                         }
//                     }}
//                 >
//                     {children}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default LabelTagCloudDropdownHead;
