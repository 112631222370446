import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { createRecipient, getRecipient } from "../../../services/savedRecipients/savedRecipients.api";
import useCommonModalAction, { CommonModalActionResponse } from "./useCommonModalAction";

import BankAccount from "../../../../../../models/bankAccount";
import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { setCustomerAccount } from "../../../../../../redux/customer-account/customerAccountSlice";
import { updateRecipient } from "../../../../../../redux/payments/recipients/slice/recipientsSlice";
import { useDispatch } from "react-redux";
import { verifyAccount } from "../../../services/send-money/singleTransferApi";

export interface UseAddNewRecipientResponse extends Omit<CommonModalActionResponse, "recipient" | "recipientActionType"> {
    accountNumber: string;
    setAccountNumber: Dispatch<SetStateAction<string>>;
    bankCode: string | undefined;
    setBankCode: Dispatch<SetStateAction<string | undefined>>;
    alias: string;
    setAlias: Dispatch<SetStateAction<string>>;

    bankAccount: BankAccount | undefined;
    isVerifyingAccount: boolean;
    handleVerifyAccount: (accountNumber: string, bankCode: string) => Promise<void>;
    handleCreateRecipient: (bankAccount: BankAccount, alias: string) => Promise<void>;
}

function useAddNewRecipient(): UseAddNewRecipientResponse {
    const dispatch = useDispatch();

    const { isModalOpen, openModal, closeModal, toggleIsModalOpen, isSubmitting, setIsSubmitting, errorMessage, setErrorMessage } =
        useCommonModalAction();

    const [accountNumber, setAccountNumber] = useState<string>("");
    const [bankCode, setBankCode] = useState<string | undefined>(undefined);
    const [alias, setAlias] = useState<string>("");

    const [bankAccount, setBankAccount] = useState<BankAccount | undefined>(undefined);

    const [isVerifyingAccount, setIsVerifyingAccount] = useState<boolean>(false);

    const reset = useCallback(() => {
        setAccountNumber("");
        setBankCode(undefined);
        setAlias("");

        setBankAccount(undefined);

        setIsVerifyingAccount(false);

        setErrorMessage(undefined);
        setIsSubmitting(false);
    }, []);

    const handleVerifyAccount = useCallback(
        async (_accountNumber: string, _bankCode: string) => {
            try {
                setIsVerifyingAccount(true);
                setErrorMessage(undefined);
                const res = await verifyAccount({ accountNumber: _accountNumber, bankCode: _bankCode });
                setBankAccount(res.bankAccount);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                setErrorMessage(getErrorMessage(err));
                dispatch(errorTrue(getErrorMessage(err)));
            }
            setIsVerifyingAccount(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    const handleCreateRecipient = useCallback(
        async (_bankAccount: BankAccount, _alias: string) => {
            try {
                setIsSubmitting(true);
                const res = await createRecipient({
                    accountNumber: _bankAccount.accountNumber,
                    bankCode: _bankAccount.bankCode,
                    bankAccountId: _bankAccount.id,
                    alias: _alias,
                });
                if (res instanceof Error) {
                    throw res;
                }
                dispatch(messageTrue({ message: "Recipient added successfully" }));
                reset();
                closeModal();
                dispatch(setCustomerAccount(res.customerAccount));
                dispatch(updateRecipient(getRecipient(res.customerAccount)));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                setErrorMessage(getErrorMessage(err));
                dispatch(errorTrue(getErrorMessage(err)));
            }
            setIsSubmitting(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    useEffect(() => {
        if (accountNumber.length === 10 && bankCode) {
            void handleVerifyAccount(accountNumber, bankCode);
        }
    }, [accountNumber, bankCode]);

    return {
        isModalOpen,
        openModal: () => {
            reset();
            openModal();
        },
        closeModal,
        toggleIsModalOpen: () => {
            if (!isModalOpen) {
                // if it was closed, reset before opening it
                reset();
            }
            toggleIsModalOpen();
        },
        isSubmitting,
        setIsSubmitting,
        errorMessage,
        setErrorMessage,
        accountNumber,
        setAccountNumber,
        bankCode,
        setBankCode,
        alias,
        setAlias,
        bankAccount,
        isVerifyingAccount,
        handleVerifyAccount,
        handleCreateRecipient,
    };
}

export default useAddNewRecipient;
