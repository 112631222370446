import { DataColumn } from "../Type/DataRow";
import { ReactComponent as DocumentIcon } from "../svg/grey-curved-document.svg";
import { ReactComponent as EditIcon } from "../svg/edit.svg";
import LineDropdown from "../../dropdown/line-dropdown/LineDropdown";
import { ReactComponent as ShareIcon } from "../svg/grey-curved-send.svg";
import SimpleDropdownLink from "../../dropdown/dropdown-components/simple-dropdown-link";
import { ReactComponent as TrashIcon } from "../svg/delete.svg";
import stopEventPropagation from "../utils/stopEventPropagation";
import { TextType } from "../Type/TextType";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function ColumnAction({ dataColumn, dataType }: Props) {
    const { textType, onEdit, onDelete, onShare, onDocument, dropDownOptions, isDisabled } = dataColumn;
    return (
        <>
            <div onClick={stopEventPropagation} data-type={dataType}>
                {textType === TextType.EDIT_DELETE && (
                    <span data-type={dataType} className="ml-auto flex w-full cursor-pointer items-center justify-end space-x-4 rounded-full">
                        {onEdit && <EditIcon className="hover:scale-110" onClick={onEdit} />}{" "}
                        {onDelete && <TrashIcon className="hover:scale-110" onClick={onDelete} />}
                    </span>
                )}
                {textType === TextType.SHARE_DOCUMENT && onShare && (
                    <span data-type={dataType} className="ml-auto flex w-full cursor-pointer items-center justify-end space-x-4 rounded-full">
                        {onShare && <ShareIcon className="hover:scale-110" onClick={onShare} />}
                        {onDocument && <DocumentIcon className="hover:scale-110" onClick={onDocument} />}
                    </span>
                )}
                {textType === TextType.MEAT_BALL_MENU && (
                    <div data-type={dataType} className="w-full">
                        <span data-type={dataType} className="ml-auto flex h-max w-6 cursor-pointer justify-end rounded-full py-1">
                            <LineDropdown isDisabled={isDisabled} noOverflow data-type={dataType}>
                                {!isDisabled
                                    ? dropDownOptions?.map((_el, idx) => (
                                          <>
                                              {_el.show ? (
                                                  <SimpleDropdownLink
                                                      key={idx}
                                                      content={{
                                                          text: _el.text,
                                                          leftIcon: <>{_el.icon} </>,
                                                          value: null,
                                                      }}
                                                      onClick={_el.func}
                                                      to={_el.to}
                                                      color={_el?.isDanger ? "red" : undefined}
                                                      redHover={_el?.isDanger}
                                                  />
                                              ) : null}
                                          </>
                                      ))
                                    : null}
                            </LineDropdown>
                        </span>
                    </div>
                )}
            </div>
        </>
    );
}
