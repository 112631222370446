import React, { useCallback } from "react";

import AddTeamMemberToAccountsForm from "./AddTeamMemberToAccountsForm";
import Modal from "../../../../../../components/modal/Modal";
import ModalHeader from "../../../../../../components/modal/modal-header";
import { TeamMemberItem } from "../../../Services/team-members/team-members.types";
import UserAccountMeta from "../../../../../../models/userAccountMeta";

interface AddTeamMemberToAccountsModalProps {
    item: TeamMemberItem;
    active: boolean;

    onClose: () => void;
    onComplete: (item: TeamMemberItem, _accounts: UserAccountMeta[]) => void;
}

function AddTeamMemberToAccountsModal({ item, active, onClose, onComplete }: AddTeamMemberToAccountsModalProps): JSX.Element {
    const handleOnComplete = useCallback((_item: TeamMemberItem, _updatedUserAccountMeta: UserAccountMeta[]) => {
        onClose();
        onComplete(_item, _updatedUserAccountMeta);
    }, []);

    return (
        <Modal size="md" active={active} toggler={onClose}>
            <ModalHeader onClose={onClose}>Add Team Member To Accounts</ModalHeader>
            <AddTeamMemberToAccountsForm item={item} active={active} onComplete={handleOnComplete} onClose={onClose} />
        </Modal>
    );
}

export default AddTeamMemberToAccountsModal;
