import { LoginInviteOtpRequest, LoginInviteOtpResponse } from "../services/login-api.types";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { loginInviteOtp } from "../services/login-api";
import { loginSuccess } from "../../../../redux/init/slice/initSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function useLoginInviteOtp(): UseMutationResult<LoginInviteOtpResponse, AxiosError | null, LoginInviteOtpRequest, unknown> {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const otpInviteLogin = useMutation({
        mutationFn: async (_data: LoginInviteOtpRequest) => await loginInviteOtp(_data),
        onSuccess() {
            dispatch(loginSuccess());
            navigate("/");
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return otpInviteLogin;
}

export default useLoginInviteOtp;
