export enum SendMoneyOptionType {
    SINGLE_TRANSFER = "single-transfer",
    TRANSFER_BETWEEN_ACCOUNTS = "transfer-between-accounts",
    BULK_TRANSFER = "bulk-transfer",
    ADD_MONEY = "add-money",
    FUND_CARD = "fund-card",
}

export enum ZambiaSendMoneyOptionType {
    MOBILE_MONEY = "mobile-money",
    CARD_PAYMENT = "card-payment",
    BANK_TRANSFER = "bank-transfer",
}
