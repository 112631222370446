import AvatarDetails from "../../../../avatar-details";
import GoogleIcon from "../../../../google-icon";
import IntercomHelper from "../../../../../helpers/intercom";
import NewDropdownHead from "../../../../new-dropdown";
import NewDropdownItem from "../../../../new-dropdown-item";
import { accountOptions } from "./service/account-dropdown.constant";
import { useAppSelector } from "../../../../../redux/hooks";
import useLogout from "../../../../init/hooks/use-logout";

function AccountDropdown(): JSX.Element {
    const user = useAppSelector((state) => state.init.account?.user);
    const { mutate: logoutMutate } = useLogout();

    const handleShowIntercom = () => {
        IntercomHelper.show();
    };
    return (
        <>
            <div className="min-w-52">
                <NewDropdownHead
                    size="md"
                    color="grey"
                    buttonType="secondary"
                    paddingSize="2xs"
                    borderSmall
                    fullWidth
                    content={<AvatarDetails initials={user?.nameAcronym || ""} title={user?.name || ""} size="2xs" />}
                >
                    {accountOptions.map((_option, index) => {
                        return (
                            <NewDropdownItem
                                key={index}
                                color={_option.key === "logout" ? "red" : undefined}
                                onClick={() =>
                                    _option.key === "get-help" ? handleShowIntercom() : _option.key === "logout" ? logoutMutate() : undefined
                                }
                            >
                                <div className="flex w-full items-center justify-start space-x-4">
                                    <GoogleIcon icon={_option.icon} isOutlined />
                                    <p className="text-sm">{_option.text}</p>
                                </div>
                            </NewDropdownItem>
                        );
                    })}
                </NewDropdownHead>
            </div>
        </>
    );
}

export default AccountDropdown;
