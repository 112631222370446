import { IRootState } from "../../../../../../../redux/rootReducer";
import PaymentHeader from "../../../Layouts/payment/components/page-header";
import { SingleTransferSections } from "../../../../hooks/state/send-money/payments.constants";
import { useSelector } from "react-redux";

function SinglePaymentHeaders(): JSX.Element {
    const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);
    return <PaymentHeader header={SingleTransferSections[paymentStage].text} subtitle={SingleTransferSections[paymentStage].subtext} />;
}

export default SinglePaymentHeaders;
