import { ApplicationBusinessType, ApplicationSectionType, ApplicationStageType, GenericApplicationPageType } from "../services/application.constants";
import {
    NigeriaApplicationSectionType,
    NigeriaApplicationStagesType,
    NigeriaRegisteredSections,
    NigeriaUnregisteredSections,
} from "../services/nigeria-application/nigeria-application.types";
import {
    ZambiaApplicationSectionType,
    ZambiaApplicationStagesType,
    ZambiaRegisteredSections,
    ZambiaUnregisteredSections,
} from "../services/zambia-application/zambia-application.types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import ApplicationCompleted from "../components/application-completed";
import ApplicationLayout from "../components/layout";
import { IRootState } from "../../../../redux/rootReducer";
import NigeriaApplication from "../components/nigeria-application";
import { Routes } from "../../../../routes/routes.constants";
import SelectTypeOfBusiness from "./SelectTypeOfBusiness";
import ZambiaApplication from "../components/zambia-application";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import { setApplicationBusinessType } from "../../../../redux/application/applicationSlice";
import { setZambiaApplicationBusinessType } from "../../../../redux/application/zambiaApplicationSlice";
import { useLayoutEffect } from "react";

// import usePing from "../../../../helpers/ping/hook/use-ping";

type ApplicationUrlParams = {
    stage: ApplicationStageType | undefined;
    section: ApplicationSectionType | GenericApplicationPageType | undefined;
};

function Application(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { mutate: initPing } = usePing();
    const { stage, section } = useParams<ApplicationUrlParams>();
    const mainInit = useSelector((state: IRootState) => state.init.main);
    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const zambiaAccountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    useLayoutEffect(() => {
        if (accountOpening) {
            // initPing();
            !Object.values(GenericApplicationPageType).some((val) => val === section)
                ? accountOpening?.applicationMin.isRegistered
                    ? !Object.values(NigeriaRegisteredSections).some((val) => val === section)
                        ? navigate(Routes.ACCOUNT_OPENING.STATUS)
                        : undefined
                    : !Object.values(NigeriaUnregisteredSections).some((val) => val === section)
                      ? navigate(Routes.ACCOUNT_OPENING.STATUS)
                      : undefined
                : undefined;

            !isNullOrUndefined(accountOpening.applicationMin.isRegistered)
                ? accountOpening.applicationMin.isRegistered
                    ? dispatch(setApplicationBusinessType(ApplicationBusinessType.REGISTERED))
                    : dispatch(setApplicationBusinessType(ApplicationBusinessType.UNREGISTERED))
                : undefined;
        } else {
            if (mainInit) {
                navigate("/");
            }
        }
    }, [accountOpening, mainInit]);

    useLayoutEffect(() => {
        if (zambiaAccountOpening) {
            // initPing();
            !Object.values(GenericApplicationPageType).some((val) => val === section)
                ? zambiaAccountOpening?.application.isRegistered
                    ? !Object.values(ZambiaRegisteredSections).some((val) => val === section)
                        ? navigate(Routes.ACCOUNT_OPENING.STATUS)
                        : undefined
                    : !Object.values(ZambiaUnregisteredSections).some((val) => val === section)
                      ? navigate(Routes.ACCOUNT_OPENING.STATUS)
                      : undefined
                : undefined;

            !isNullOrUndefined(zambiaAccountOpening.application.isRegistered)
                ? zambiaAccountOpening.application.isRegistered
                    ? dispatch(setZambiaApplicationBusinessType(ApplicationBusinessType.REGISTERED))
                    : dispatch(setZambiaApplicationBusinessType(ApplicationBusinessType.UNREGISTERED))
                : undefined;
        }
    }, [zambiaAccountOpening]);

    return (
        <ApplicationLayout>
            <div className="flex h-full w-full flex-col">
                {section === GenericApplicationPageType.DONE && <ApplicationCompleted />}
                {section === GenericApplicationPageType.STATUS && <SelectTypeOfBusiness />}
                {section !== GenericApplicationPageType.STATUS && section !== GenericApplicationPageType.DONE && (
                    <>
                        {zambiaAccountOpening && (
                            <ZambiaApplication
                                isRegistered={zambiaAccountOpening.application.isRegistered || false}
                                section={section as ZambiaApplicationSectionType | GenericApplicationPageType}
                                stage={stage as ZambiaApplicationStagesType}
                            />
                        )}
                        {accountOpening && (
                            <NigeriaApplication
                                isRegistered={accountOpening?.applicationMin.isRegistered || false}
                                section={section as NigeriaApplicationSectionType | GenericApplicationPageType}
                                stage={stage as NigeriaApplicationStagesType}
                            />
                        )}
                    </>
                )}
            </div>
        </ApplicationLayout>
    );
}

export default Application;
