import { generateQrCode, isQrCodeScanned } from "../token-app.api";
import { useCallback, useEffect, useRef, useState } from "react";

import { GenerateQrCodeResponse } from "../token-app.types";
import SocketWrapper from "../../../../../../../helpers/websocket/socketWrapper";
import { WebSocketEvent } from "../../../../../../../helpers/websocket/websocket.constants";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface UseScanCodeResponse {
    qrCodeData: GenerateQrCodeResponse | null;
    isGeneratingQrCode: boolean;
    isScanned: boolean;
    handleGenerateQrCode: () => Promise<void>;
}

function useScanCode(): UseScanCodeResponse {
    const dispatch = useDispatch();

    const [qrCodeData, setQrCodeData] = useState<GenerateQrCodeResponse | null>(null);
    const [isGeneratingQrCode, setIsGeneratingQrCode] = useState<boolean>(false);
    const [isScanned, setIsScanned] = useState<boolean>(false);
    const [checkingIsScanned, setCheckingIsScanned] = useState<boolean>(false);

    const isQrCodeScannedIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const qrCodeDataRef = useRef<GenerateQrCodeResponse | null>(null);
    qrCodeDataRef.current = qrCodeData;

    const clearIsQrCodeScannedInterval = useCallback(() => {
        if (isQrCodeScannedIntervalRef.current) {
            clearTimeout(isQrCodeScannedIntervalRef.current);
        }
    }, []);

    /*
	on load:
		- start the timer,
		- call generateQrCode,
		- listen for scan webscocket event
	 */
    useEffect(() => {
        // start the timer
        isQrCodeScannedIntervalRef.current = setInterval(() => {
            void handleIsQrCodeScanned();
        }, 5000);

        // generate the qr code
        void handleGenerateQrCode();

        // listen for scan websocket event
        listenForScannedEvent();

        return () => {
            clearIsQrCodeScannedInterval();
            stopListenerForScannedEvent();
        };
    }, []);

    const handleScannedEvent = useCallback(() => {
        setIsScanned(true);
    }, []);

    const listenForScannedEvent = useCallback(() => {
        if (SocketWrapper.socket) {
            SocketWrapper.socket.registerEventHandler(WebSocketEvent.AUTH_APP_SCANNED, handleScannedEvent);
        }
    }, []);

    const stopListenerForScannedEvent = useCallback(() => {
        if (SocketWrapper.socket) {
            SocketWrapper.socket.deregisterEventHandler(WebSocketEvent.AUTH_APP_SCANNED, handleScannedEvent);
        }
    }, []);

    const handleIsQrCodeScanned = useCallback(async () => {
        if (checkingIsScanned || !qrCodeDataRef.current || isScanned) {
            return;
        }

        setCheckingIsScanned(true);

        try {
            const res = await isQrCodeScanned();
            setIsScanned(res);
            // if isScanned, clear the interval
            if (res) {
                clearIsQrCodeScannedInterval();
                stopListenerForScannedEvent();
            }
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setCheckingIsScanned(false);
        }
    }, [dispatch]);

    const handleGenerateQrCode = useCallback(async () => {
        if (isGeneratingQrCode) {
            return;
        }

        setIsGeneratingQrCode(true);

        try {
            const res = await generateQrCode();
            setQrCodeData(res);
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsGeneratingQrCode(false);
        }
    }, [dispatch]);

    return {
        qrCodeData,
        isGeneratingQrCode,
        isScanned,
        handleGenerateQrCode,
    };
}

export default useScanCode;
