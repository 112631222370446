import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { closeAboutMyAccountModal } from "../../../../../../redux/accounts/slice/accountsSlice";
import AccountLimitCard from "../../Cards/AccountLimitCard";
import { useCallback } from "react";
import Accordion from "../../../../../../components/Accordion2";
import titleCase from "../../../../../../hooks/titleCase";

const CREATE_SUB_ACCOUNT_DATATYPE = "about.my.account";

function AboutMyAccount(): JSX.Element {
    const dispatch = useDispatch();

    const metafaqs = useAppSelector((state) => state.init.main?.meta.faqs);

    const individualAccount = useAppSelector((state) => state.account.individualAccount);
    const isAboutMyAccountModalOpen = useAppSelector((state) => state.account.isAboutMyAccountModalOpen);
    const accountType = useAppSelector((state) => state.init.main?.meta.accountTypes.find((t) => t.type === individualAccount?.type));

    const handleCloseTray = useCallback(() => {
        dispatch(closeAboutMyAccountModal());
    }, []);

    return (
        <FullPageTray active={isAboutMyAccountModalOpen} dataType={CREATE_SUB_ACCOUNT_DATATYPE} handleReset={handleCloseTray} withClickOutside>
            <FullPageTrayHeader toggler={handleCloseTray} dataType={CREATE_SUB_ACCOUNT_DATATYPE}>
                <span className="text-2xl font-medium text-black">About My Account</span>
            </FullPageTrayHeader>
            <FullPageTrayBody dataType={CREATE_SUB_ACCOUNT_DATATYPE}>
                <div className="-mt-5 w-full space-y-4">
                    <div>
                        <h1 className="text-base font-medium text-black">{titleCase(`${individualAccount?.accountTypeName} Account` || "")}</h1>
                        <p className="max-w-sm overflow-hidden overflow-ellipsis text-sm text-black-tertiary">
                            {individualAccount?.accountTypeDescription || ""}
                        </p>
                    </div>

                    <AccountLimitCard account={individualAccount} />
                    <Accordion
                        items={
                            metafaqs
                                ?.filter((faqs) => accountType?.faqs.map((e) => e.questionId).includes(faqs.id))
                                .map((el) => ({
                                    header: el.question,
                                    body: el?.answers[0]?.text,
                                })) || []
                        }
                    />
                    {/* {accountType && (
                        <div className="flex flex-col">
                            <div className="w-full">
                                {accountType.faqs.map((el) => (
                                    <QuestionCard faqMin={el} key={el.questionId} />
                                ))}
                            </div>
                        </div>
                    )} */}
                </div>
            </FullPageTrayBody>{" "}
        </FullPageTray>
    );
}

export default AboutMyAccount;
