import React from "react";
import SkeletonElement from "../../../../../components/skeleton";

function PendingSubAccountLoader(): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-row items-center justify-between space-x-8 rounded-lg border p-4">
                <div className="flex w-full flex-col items-start space-y-2 text-start text-sm text-black-secondary">
                    <div className="h-5 w-full">
                        <SkeletonElement box />
                    </div>
                    <div className="h-5 w-1/2">
                        <SkeletonElement box />
                    </div>
                    <div className="h-5 w-2/5">
                        <SkeletonElement box />
                    </div>
                </div>
                <div className="h-5 w-36">
                    <SkeletonElement box />
                </div>
            </div>
        </>
    );
}

export default PendingSubAccountLoader;
