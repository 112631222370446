import React, { useEffect, useState } from "react";
import NewDropdownItem from "../../../../new-dropdown-item";
import RadioButton from "../../../../radio-button";
import { useAppSelector } from "../../../../../redux/hooks";
import moment from "moment";
import { DateType, DateTypes } from "../../../Type";
import { GenericObject } from "../../../../../helpers/types";
import { useDispatch } from "react-redux";
import { setClearAllFilter } from "../../../../../redux/application/applicationSlice";

interface Props {
    onClick?: (data: GenericObject) => void;
    onCustomSelect?: () => void;
}

export default function DateDropdownOption({ onClick, onCustomSelect }: Props) {
    const dispatch = useDispatch();
    const data = useAppSelector((state) => state.init.main?.companyDetails?.company?.accountCreatedOn || null);
    const clearAllFilter = useAppSelector((state) => state.application.clearAllFilter);

    const [options, setOptions] = useState<DateType[]>([]);
    const [currentDate, setCurrentDate] = useState<string | Date>("");
    const [firstDate, setFirstDate] = useState<Date | null>(null);
    const [last30Days, setLast30Days] = useState<Date | string>("");
    const [firstDayOfMonth, setFirstDayOfMonth] = useState<Date | string>("");
    const [lastDayOfMonth, setLastDayOfMonth] = useState<Date | string>("");
    const [firstDayOfLastMonth, setFirstDayOfLastMonth] = useState<Date | string>("");
    const [lastDayOfLastMonth, setLastDayOfLastMonth] = useState<Date | string>("");
    const [firstDayOfYear, setFirstDayOfYear] = useState<Date | string>("");
    const [last7Days, setLast7Days] = useState<Date | string>("");

    // const [loading, setLoading] = useState<boolean>(false);

    const [selectedOption, setSelectedOption] = useState<string | null>(DateTypes.ALL_TIME);

    //set first day a transaction was made
    useEffect(() => {
        setFirstDate(data);
    }, [data]);

    useEffect(() => {
        //current date
        const cDate = new Date();
        setCurrentDate(cDate.toISOString().slice(0, 10));
        // first 30 days
        const l30 = new Date();
        l30.setDate(l30.getDate() - 30);
        setLast30Days(l30.toISOString().slice(0, 10));
        //get last day of last month
        const lDLMonth = new Date();
        lDLMonth.setMonth(lDLMonth.getMonth(), 0);
        setLastDayOfLastMonth(lDLMonth.toISOString().slice(0, 10));
        //get first day of last month
        const fDLMonth = new Date();
        fDLMonth.setMonth(fDLMonth.getMonth() - 1, 1);
        setFirstDayOfLastMonth(fDLMonth.toISOString().slice(0, 10));
        //get first day of this month
        const fDMonth = new Date();
        fDMonth.setDate(1);
        setFirstDayOfMonth(fDMonth.toISOString().slice(0, 10));
        //get last day of this month
        const lDMonth = new Date();
        lDMonth.setMonth(lDMonth.getMonth() + 1, 0);
        setLastDayOfMonth(lDMonth.toISOString().slice(0, 10));
        //get first day of year
        const fDYear = new Date(new Date().getFullYear(), 0, 1, 1);
        setFirstDayOfYear(fDYear.toISOString().slice(0, 10));

        //get last 7 days
        const l7 = new Date();
        l7.setDate(l7.getDate() - 7);
        setLast7Days(l7.toISOString().slice(0, 10));
    }, []);

    //set options and selected option
    useEffect(() => {
        //set options
        setOptions([
            {
                name: DateTypes.ALL_TIME,
                date: { begin: firstDate, end: currentDate },
            },
            {
                name: DateTypes.TODAY,
                date: { begin: currentDate, end: currentDate },
            },
            {
                name: DateTypes.LAST_7_DAYS,
                date: { begin: last7Days, end: currentDate },
            },
            {
                name: DateTypes.LAST_30_DAYS,
                date: { begin: last30Days, end: currentDate },
            },
            {
                name: DateTypes.CUSTOM,
                date: { begin: "", end: currentDate },
            },
        ]);
    }, [currentDate, firstDate, firstDayOfLastMonth, firstDayOfMonth, firstDayOfYear, last30Days, lastDayOfLastMonth, lastDayOfMonth, last7Days]);

    useEffect(() => {
        if (clearAllFilter) {
            setSelectedOption(DateTypes.ALL_TIME);
            dispatch(setClearAllFilter(false));
        }
        return () => {};
    }, [clearAllFilter]);

    moment.locale("de");

    return (
        <>
            {options?.map((option, idx) => {
                return (
                    <NewDropdownItem
                        onClick={() => {
                            if (selectedOption === option.name) {
                                setSelectedOption(DateTypes.ALL_TIME);
                                onClick?.({ begin: firstDate, end: currentDate });
                            } else {
                                setSelectedOption(option.name);
                                onClick?.(option.date);
                            }
                            if (option.name === DateTypes.CUSTOM) return onCustomSelect?.();
                        }}
                        key={idx}
                        size="lg"
                        className={`${selectedOption !== DateTypes.CUSTOM && selectedOption === option.name ? "bg-grey-tertiary" : ""}`}
                    >
                        <div className="flex h-full w-full flex-row items-center justify-start pr-4 text-sm">
                            <div className="w-fit max-w-full">
                                <RadioButton
                                    id="date-radio"
                                    size="sm"
                                    checked={selectedOption === option.name}
                                    text={
                                        <div className="ml-1">
                                            <p className="text-sm font-medium text-black-secondary">{option.name}</p>
                                            <p className="text-xs text-black-tertiary">
                                                {option.date.begin === option.date.end
                                                    ? moment(option.date.begin).format("MMMM D")
                                                    : option.date.begin && option.date.end && option.name !== DateTypes.ALL_TIME
                                                      ? `${moment(option.date.begin).format("MMMM D")} - ${moment(option.date.end).format("MMMM D")}`
                                                      : ""}
                                            </p>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </NewDropdownItem>
                );
            })}
        </>
    );
}
