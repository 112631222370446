import {
    abortAllCurrentSignatoryRequests,
    documentAppendSignature,
    documentSign,
    documentUpload,
    downloadToSign,
} from "../../../../services/nigeria-application/registered/currentSignatory.api";
import { useCallback, useState } from "react";

// import {CurrentSignatoryResponse} from "../../../../services/nigeria-application/registered/signatory.types";
import { downloadBlob } from "../../../../../../../utils/blob";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { updateCurrentSignatory } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface UseSignatureInterface {
    isUploadSignatureLoading: boolean;
    isAppendSignatureLoading: boolean;
    isUploadSignatoryDocumentLoading: boolean;

    handleReset: () => void;
    handleAppendSignature: () => Promise<void>;
    handleUploadSignature: (_data: Blob | null) => Promise<void>;
    handleUploadSignatoryDocument: (_data: Blob | null) => Promise<void>;
    handleDownloadSignatoryDocument: () => Promise<void>;
}

const useSignature = (onComplete?: () => void): UseSignatureInterface => {
    const [isUploadSignatureLoading, setIsUploadSignatureLoading] = useState<boolean>(false);
    const [isAppendSignatureLoading, setIsAppendSignatureLoading] = useState<boolean>(false);
    const [isUploadSignatoryDocumentLoading, setIsUploadSignatoryDocumentLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleAppendSignature = useCallback(async () => {
        try {
            setIsAppendSignatureLoading(true);
            const res = await documentAppendSignature();
            if (res.signatory) {
                dispatch(updateCurrentSignatory(res));
            }
            dispatch(messageTrue({ message: "Document Signed Successfully" }));
            onComplete && onComplete();
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsAppendSignatureLoading(false);
        }
    }, [dispatch]);

    const handleUploadSignature = useCallback(
        async (_data: Blob | null) => {
            try {
                setIsUploadSignatureLoading(true);
                const res = await documentSign({ file: _data });
                if (res.signatory) {
                    dispatch(updateCurrentSignatory(res));
                }
                dispatch(messageTrue({ message: "Signature uploaded successfully" }));
                onComplete && onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsUploadSignatureLoading(false);
            }
        },
        [dispatch]
    );

    const handleDownloadSignatoryDocument = useCallback(async () => {
        try {
            dispatch(messageTrue({ message: "Downloading ..." }));
            const res = await downloadToSign();
            downloadBlob(res, "Signatory Document.pdf");
            dispatch(messageTrue({ message: "Downloaded successfully" }));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        }
    }, [dispatch]);

    const handleUploadSignatoryDocument = useCallback(
        async (_data: Blob | null) => {
            try {
                setIsUploadSignatoryDocumentLoading(true);
                const res = await documentUpload({ file: _data });
                if (res.signatory) {
                    dispatch(updateCurrentSignatory(res));
                }

                dispatch(messageTrue({ message: "Signature document uploaded successfully" }));
                onComplete && onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsUploadSignatoryDocumentLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = () => {
        setIsUploadSignatureLoading(false);
        setIsAppendSignatureLoading(false);
        setIsUploadSignatoryDocumentLoading(false);
        abortAllCurrentSignatoryRequests();
    };

    return {
        isUploadSignatureLoading,
        isAppendSignatureLoading,
        isUploadSignatoryDocumentLoading,

        handleReset,
        handleAppendSignature,
        handleUploadSignature,
        handleUploadSignatoryDocument,
        handleDownloadSignatoryDocument,
    };
};
export default useSignature;
