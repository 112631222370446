import { AccountTypeRequest, AccountTypeResponse, BusinessRefereeResponse, InviteRefereeForm, RemoveBusinessRefereeRequest } from "./referee.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../helpers/request/abortControllers";

import BusinessDetailsMin from "../../../../../../models/account-opening/businessDetailsMin";
import { GenericObject } from "../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../utils/parsers";
import Referee from "../../../../../../models/account-opening/referee";
import { makeRequestWithSignal } from "../../../../../../helpers/request/makeRequest";

export enum BusinessRefereeRequests {
    INIT = "business-referee.init",
    INVITE = "business-referee.invite",
    ACCOUNT_TYPE = "business-referee.account-type",
    REMOVE_INVITE = "business-referee.remove-invite",
}

function getBusinessRefereeResponse(res: GenericObject | Error): BusinessRefereeResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        referees: Parsers.classObjectArray(res.referees, Referee),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
    };
}

export async function refereeInit(): Promise<BusinessRefereeResponse> {
    const signal = getAbortControllerSignal(BusinessRefereeRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/ng/referee/details", {}, signal);
    return getBusinessRefereeResponse(res);
}

export async function inviteReferee(_data: InviteRefereeForm): Promise<BusinessRefereeResponse> {
    const signal = getAbortControllerSignal(BusinessRefereeRequests.INVITE);
    const res = await makeRequestWithSignal("/account-opening/ng/referee/invite", _data, signal);
    return getBusinessRefereeResponse(res);
}

export async function removeReferee(_data: RemoveBusinessRefereeRequest): Promise<BusinessRefereeResponse> {
    const signal = getAbortControllerSignal(BusinessRefereeRequests.REMOVE_INVITE);
    const res = await makeRequestWithSignal("/account-opening/ng/referee/remove", _data, signal);
    return getBusinessRefereeResponse(res);
}

export async function accountType(_data: AccountTypeRequest): Promise<AccountTypeResponse> {
    const signal = getAbortControllerSignal(BusinessRefereeRequests.ACCOUNT_TYPE);
    const res = await makeRequestWithSignal("/account-opening/ng/requested-account-type/update", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        applicationMin: Parsers.classObjectNonNullable(res.applicationMin, BusinessDetailsMin),
    };
}

export function abortBusinessRefereeRequest(type: BusinessRefereeRequests): void {
    abortRequest(type);
}

export function abortAllBusinessRefereeRequests(): void {
    Object.values(BusinessRefereeRequests).forEach((type) => abortRequest(type));
}
