import {
    ForgotPasswordGenericResponseData,
    ForgotPasswordSendOtpData,
    ForgotPasswordSendOtpResponseData,
    ForgotPasswordSetNewPasswordData,
    ForgotPasswordVerifyData,
    ForgotPasswordVerifyOtpData,
    ForgotPasswordVerifyResponseData,
    OtpRecipientItem,
} from "./resetPasswordApi.types";

import Parsers from "../../../../utils/parsers";
import { makeRequest } from "../../../../helpers/request/makeRequest";

export const changePasswordVerifyLink = async (data: ForgotPasswordVerifyData): Promise<ForgotPasswordVerifyResponseData> => {
    const res = await makeRequest("/accounts/change-password/verify", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        otpRequired: Parsers.boolean(res.otpRequired),
        otpRecipients: Parsers.classObjectArray(res.otpRecipients, OtpRecipientItem),
    };
};

export const changePasswordSendOtp = async (data: ForgotPasswordSendOtpData): Promise<ForgotPasswordSendOtpResponseData> => {
    const res = await makeRequest("/accounts/change-password/send-otp", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        otpRecipient: Parsers.classObjectNonNullable(res.otpRecipient, OtpRecipientItem),
    };
};

export const changePasswordVerifyOtp = async (data: ForgotPasswordVerifyOtpData): Promise<ForgotPasswordGenericResponseData> => {
    const res = await makeRequest("/accounts/change-password/verify-otp", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};

export const changePasswordSetNewPassword = async (data: ForgotPasswordSetNewPasswordData): Promise<ForgotPasswordGenericResponseData> => {
    const res = await makeRequest("/accounts/change-password/set-password", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};
