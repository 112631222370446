import { useDispatch, useSelector } from "react-redux";

import AccountRestrictions from "../Components/account-settings/restrictions/AccountRestrictions";
import { AccountSettingsSectionValue } from "../Services/account-settings/account-settings.constants";
import AccountSettingsSideMenu from "../Components/account-settings/AccountSettingsSideMenu";
import BalanceAlertInstruction from "../Components/account-settings/balance-alert/BalanceAlertInstruction";
import BalanceInstructionTray from "../Components/Trays/BalanceInstructionTray";
import { IRootState } from "../../../../redux/rootReducer";
import LencoSpinner from "../../../../components/spinner";
import PageLayout from "../../../../components/layouts/page-layout";
import { PageTitle } from "../../../../helpers/AppConstants";
import SendMoneyRuleTray from "../Components/Trays/SendMoneyRuleTray";
import SplitInflowInstruction from "../Components/account-settings/split-inflow/SplitInflowInstruction";
import { setIsBalanceInstructionTray, setIsSplitInflowScreen, setSendMoneyRuleTray } from "../../../../redux/settings/security/settingsSecuritySlice";
import { useEffect, useState } from "react";

function AccountSettings(): JSX.Element {
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const isAdmin = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.isAdmin || false);
    const isBalanceInstructionTray = useSelector((state: IRootState) => state.settingsSecurity.isBalanceInstructionTray);
    // const sendMoneyRuleTray = useSelector((state: IRootState) => state.settingsSecurity.isBalanceInstructionTray);

    const balanceType = useSelector((state: IRootState) => state.settingsSecurity.balanceType);
    const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(undefined);
    const selectedAccount = useSelector((state: IRootState) => {
        if (!selectedAccountId) {
            return undefined;
        }
        return state.init.main?.companyDetails.accounts.find((a) => a.id === selectedAccountId);
    });

    const [stage, setStage] = useState<AccountSettingsSectionValue>(AccountSettingsSectionValue.RESTRICTIONS);
    const [isSendMoneyRuleTray, setIsSendMoneyRuleTray] = useState(false);

    const dispatch = useDispatch();

    document.title = PageTitle.ACCOUNTS_SETTINGS_PAGE;

    useEffect(() => {
        dispatch(setSendMoneyRuleTray(isSendMoneyRuleTray));
    }, [isSendMoneyRuleTray]);

    // const handleIsSendMoneyRuleTray = () => setIsSendMoneyRuleTray((prev) => !prev);
    const handleIsSendMoneyRuleTray = () => setIsSendMoneyRuleTray((prev) => !prev);
    const handleToggleSendMoneyRuleTray = () => setIsSendMoneyRuleTray(false);
    const handleToggleBalanceInstructionTray = () => dispatch(setIsBalanceInstructionTray(false));

    useEffect(() => {
        dispatch(setIsSplitInflowScreen(stage === AccountSettingsSectionValue.SPLIT_INFLOW));
    }, [stage]);

    return (
        <>
            <SendMoneyRuleTray active={isSendMoneyRuleTray} account={selectedAccount} toggle={handleToggleSendMoneyRuleTray} />
            {selectedAccount && (
                <BalanceInstructionTray
                    type={balanceType}
                    active={isBalanceInstructionTray}
                    account={selectedAccount}
                    toggle={handleToggleBalanceInstructionTray}
                />
            )}

            <PageLayout pageTitle="Settings" subTitle="Add instructions and set restrictions to your Lenco bank accounts">
                <div className="flex h-full max-h-full w-full flex-col">
                    <div className="flex h-full max-h-full w-full flex-col place-content-stretch place-items-stretch justify-between md:grid md:h-full md:grid-cols-settings md:grid-rows-1 md:flex-row">
                        <AccountSettingsSideMenu
                            stage={stage}
                            handleSelectStage={(value) => setStage(value)}
                            selectedAccountId={selectedAccountId}
                            handleSelectAccount={setSelectedAccountId}
                            canSplitInstructions={!!(accounts && accounts.length > 1)}
                        />

                        <div className="relative flex h-full w-full max-w-4xl flex-grow flex-col">
                            {!selectedAccount && (
                                <div className="flex h-full w-full items-center justify-center">
                                    <LencoSpinner />
                                </div>
                            )}

                            {!isAdmin && <div className="absolute left-0 top-0 z-40 h-full w-full"></div>}

                            {selectedAccount && (
                                <>
                                    {stage === AccountSettingsSectionValue.RESTRICTIONS && (
                                        <AccountRestrictions account={selectedAccount} handleIsSendMoneyRuleTray={handleIsSendMoneyRuleTray} />
                                    )}

                                    {stage === AccountSettingsSectionValue.MINIMUM_BALANCE && (
                                        <BalanceAlertInstruction account={selectedAccount} type={balanceType} stage={stage} />
                                    )}

                                    {stage === AccountSettingsSectionValue.SPLIT_INFLOW && accounts && accounts.length > 1 && (
                                        <SplitInflowInstruction account={selectedAccount} />
                                    )}
                                </>
                            )}
                            <div />
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default AccountSettings;
