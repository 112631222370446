import { ReactComponent as BlueCheck } from "../../../../assets/svg/dashboard/application/blue-circle-check.svg";
import ButtonComp from "../../../../components/button/ButtonComp";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";

interface Props {
    url: string;
}

function DirectorApplicationDone(props: Props): JSX.Element {
    const navigate = useNavigate();
    const accountOpening = useAppSelector((state) => state.application.init);
    const accountManager = useAppSelector((state) => state.application.accountManager);
    const zambiaAccountManager = useAppSelector((state) => state.zambiaApplication.accountManager);
    const zambiaAccountOpening = useAppSelector((state) => state.zambiaApplication.init);

    return (
        <>
            <div className="w-full pb-20">
                <div className="flex w-full flex-col items-start justify-start space-y-6">
                    <div className="flex w-full flex-col items-center justify-center space-y-8 rounded-lg bg-white p-8">
                        <div className="flex w-full flex-col items-center justify-center space-y-4">
                            <BlueCheck />
                            <h1 className="text-2xl font-medium text-black">All done!</h1>
                            <div className="text-center text-sm text-black-secondary">
                                <p>
                                    Your application has been submitted successfully and is being reviewed by{" "}
                                    <span className="text-blue">{accountManager?.name || zambiaAccountManager?.name || ""}.</span>
                                </p>
                                <p>
                                    {zambiaAccountOpening &&
                                        "We estimate that you will hear back from us within 1 business day. If you have more information to provide please add them to your application."}
                                    {accountOpening &&
                                        "We estimate that we will reach out to you within the next 1-2 business days. Have a great day"}
                                </p>
                            </div>
                        </div>
                        <ButtonComp type="button" color="black" ripple="light" size="md" buttonType="primary" func={() => navigate(`${props.url}`)}>
                            <span>Return to Application</span>
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DirectorApplicationDone;
