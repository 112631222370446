import React, { useEffect, useState } from "react";

// import { ReactComponent as Filled } from "../../../assets/svg/radio-filled.svg";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

interface Props {
    id: string;
    size?: "sm" | "md";
    text?: React.ReactNode;
    checked?: boolean;
    truncate?: boolean;
    readOnly?: boolean;
    children?: React.ReactNode;
    fullWidth?: boolean;
    isDisabled?: boolean;
    hideRadioButton?: boolean;

    func?: (_value?: boolean) => void;
}

function RadioButton(props: Props): JSX.Element {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        if (isNullOrUndefined(props.checked)) return;
        setIsChecked(props.checked);
    }, [props.checked]);

    const handleClick = () => {
        // setIsChecked((prev) => !prev);
        props.func && props.func();
    };

    return (
        <>
            <div
                className={
                    `flex flex-row items-center justify-start ` +
                    `${props.readOnly || props.isDisabled ? "pointer-events-none" : "cursor-pointer"} ` +
                    `${props.fullWidth ? "w-full" : "w-max"}`
                }
                onClick={props.readOnly || props.isDisabled ? undefined : handleClick}
                data-type="transaction"
            >
                <div className={"flex items-center space-x-2"}>
                    <div data-type="transaction" className={`${props.hideRadioButton && "invisible"}`}>
                        <div
                            className={
                                `relative flex h-12 items-center justify-center rounded-full border border-solid border-black-quin transition-all duration-150 ` +
                                `${props.size === "sm" ? "h-4 w-4" : "h-5 w-5"} ` +
                                `${isChecked ? "border-blue bg-white" : ""} ` +
                                `${(props.readOnly || props.isDisabled) && isChecked ? "!border-blue-tertiary" : ""} ` +
                                `${(props.readOnly || props.isDisabled) && !isChecked ? "!border-black-quin" : ""} `
                            }
                            data-type="transaction"
                        >
                            <input
                                className={"absolute left-0 top-0 hidden h-full w-full placeholder-transparent outline-none focus:outline-none"}
                                checked={isChecked}
                                type={"checkbox"}
                                id={props.id}
                                disabled={props.isDisabled}
                                readOnly
                                data-type="transaction"
                            />
                            {isChecked && (
                                <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-full p-[1.5px]">
                                    <div className={"h-full w-full rounded-full " + ` ${props.isDisabled ? "bg-blue-tertiary" : "bg-blue"}`}></div>
                                </div>
                            )}
                        </div>
                    </div>
                    {props.children}
                </div>

                {props.text && (
                    <div
                        className={
                            `max-w-full pl-2 leading-none ` +
                            `${props.readOnly ? "text-black-tertiary" : " text-black-secondary"} ` +
                            `${props.size === "sm" ? "text-sm" : "text-base"}` +
                            `${props.fullWidth ? "w-full" : ""} ` +
                            `${props.truncate ? "overflow-hidden overflow-ellipsis whitespace-nowrap leading-none" : ""} ` +
                            `${props.isDisabled ? " !text-black-quin" : ""}`
                        }
                        data-type="transaction"
                    >
                        {props.text || ""}
                    </div>
                )}
            </div>
        </>
    );
}

export default RadioButton;
