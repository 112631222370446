import { ButtonColor } from "../button/button.constant";
import React from "react";
import SimpleModal from "./simple-modal";

export interface ConfirmModalProps {
    active: boolean;
    header: React.ReactNode;
    children: React.ReactNode;
    cancelText?: string;
    confirmText?: string;
    isSubmitting: boolean;
    errorMessage: string | undefined;
    confirmColor?: ButtonColor;

    onClose: () => void;
    onConfirm: () => void;
}

function ConfirmModal(props: ConfirmModalProps): JSX.Element {
    return (
        <SimpleModal size="md" confirmText={props.confirmText || "Yes"} cancelText={props.cancelText || "No"} onSubmit={props.onConfirm} {...props}>
            {props.children}
        </SimpleModal>
    );
}

export default ConfirmModal;
