import { AppToastItem, AppToastType } from "../../../../../../../redux/app-toast/app-toast-slice.types";
import { useEffect, useState } from "react";

import { ReactComponent as Error } from "../../../../../../../assets/svg/dashboard/app-toast/error.svg";
import { ReactComponent as Info } from "../../../../../../../assets/svg/dashboard/app-toast/info.svg";
import { ReactComponent as Success } from "../../../../../../../assets/svg/dashboard/app-toast/success.svg";

interface ToastProps {
    data: AppToastItem;
    dataType: string;
    onRemove: (_id: number) => void;
}

function AppToast({ data, dataType, onRemove }: ToastProps): JSX.Element {
    const [remove, setRemove] = useState(false);
    const [displayToast, setDisplayToast] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setDisplayToast(true);
        }, 100);
    }, []);

    // Remove error in a second and a half
    useEffect(() => {
        if (!displayToast) return;
        const timeOut = setTimeout(() => {
            setDisplayToast(false);
        }, 5000);
        return () => {
            clearTimeout(timeOut);
        };
    }, [displayToast]);

    useEffect(() => {
        if (remove) return;
        const timeOut = setTimeout(() => {
            setRemove(true);
            onRemove(data.index);
        }, 5500);
        return () => {
            clearTimeout(timeOut);
        };
    }, [remove]);

    return (
        <div
            className={
                `mb-4 flex w-full max-w-sm transform flex-row items-center justify-start space-x-3 rounded-[10px] bg-[#1F2130CC] px-4 py-4 text-center transition-all duration-700 2xs:w-max 2xs:justify-center 2xs:py-2 ` +
                `${remove ? "hidden" : ""} ` +
                `${displayToast ? "pointer-events-auto translate-x-0 opacity-100" : "pointer-events-none translate-x-12 opacity-0"}`
            }
            data-type={dataType}
        >
            {data.type === AppToastType.ERROR ? (
                <Error data-type={dataType} />
            ) : data.type === AppToastType.INFORMATION ? (
                <Info data-type={dataType} />
            ) : (
                <Success data-type={dataType} />
            )}
            <p className="text-left text-sm font-normal text-white" data-type={dataType}>
                {data.message}
            </p>
        </div>
    );
}

export default AppToast;
