import { useCallback, useState } from "react";

import { DeleteUserGroupRequest } from "../servicess/people-api.types";
import { MainInitCompanyDetailsInterface } from "../../../../redux/init/slice/initSlice.types";
import { deleteUserGroup } from "../servicess/peopleApi";
import { errorTrue } from "../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { mainUpdateCompanyDetails } from "../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseDeleteUserGroupInterface {
    deleteUserGroupError: string;
    deleteUserGroupResponse: boolean;
    isDeleteUserGroupLoading: boolean;

    handleResetAll: () => void;
    handleResetError: () => void;
    handleDeleteUserGroup: (_data: DeleteUserGroupRequest) => Promise<void>;
}

function useDeleteUserGroup(): UseDeleteUserGroupInterface {
    const dispatch = useDispatch();

    const [deleteUserGroupError, setDeleteUserGroupError] = useState<string>("");
    const [deleteUserGroupResponse, setDeleteUserGroupResponse] = useState<boolean>(false);
    const [isDeleteUserGroupLoading, setIsDeleteUserGroupLoading] = useState<boolean>(false);

    const handleDeleteUserGroup = useCallback(
        async (_data: DeleteUserGroupRequest) => {
            try {
                setDeleteUserGroupError("");
                setIsDeleteUserGroupLoading(true);
                const res = await deleteUserGroup(_data);
                setDeleteUserGroupResponse(res.success);
                // remove from state.init.main.companyDetails.userGroups
                dispatch(
                    mainUpdateCompanyDetails({
                        callback: (companyDetails: MainInitCompanyDetailsInterface) => {
                            companyDetails.userGroups = companyDetails.userGroups.filter((userGroup) => userGroup.id !== _data.userGroupId);
                        },
                    })
                );
                dispatch(messageTrue({ message: "Group Deleted" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDeleteUserGroupError(errorMessage);
            } finally {
                setIsDeleteUserGroupLoading(false);
            }
        },
        [dispatch]
    );

    const handleResetError = useCallback(() => {
        setDeleteUserGroupError("");
    }, []);

    const handleResetAll = useCallback(() => {
        setDeleteUserGroupError("");
        setIsDeleteUserGroupLoading(false);
        setDeleteUserGroupResponse(false);
    }, []);

    return {
        deleteUserGroupError,
        deleteUserGroupResponse,
        isDeleteUserGroupLoading,

        handleResetAll,
        handleResetError,
        handleDeleteUserGroup,
    };
}

export default useDeleteUserGroup;
