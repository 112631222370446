import { useCallback, useState } from "react";

import Card from "../../../../../models/card";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { mainInitAddCard } from "../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { updateCard } from "../../../../../redux/cards/slice/cardsSlice";
import { updateCardPaymentChannel } from "../../Services/cardsApi";
import { updateCardPaymentChannelDTO } from "../../Services/cardsApi.types";
import { useDispatch } from "react-redux";

interface UsePaymentChannel {
    paymentChannelError: string | null;
    paymentChannelDone: boolean;
    isPaymentChannelLoading: boolean;
    handleUpdatePaymentChannel: (input: updateCardPaymentChannelDTO) => void;
    handleReset: () => void;
}

const usePaymentChannel = (): UsePaymentChannel => {
    const dispatch = useDispatch();

    const [paymentChannelError, setPaymentChannelError] = useState<string | null>(null);
    const [paymentChannelDone, setPaymentChannelDone] = useState<boolean>(false);
    const [isPaymentChannelLoading, setIsPaymentChannelLoading] = useState<boolean>(false);

    const handleUpdatePaymentChannel = useCallback(
        async (_data: updateCardPaymentChannelDTO) => {
            try {
                setPaymentChannelDone(false);
                setPaymentChannelError(null);
                setIsPaymentChannelLoading(true);
                const res = await updateCardPaymentChannel(_data);
                setPaymentChannelDone(true);
                dispatch(mainInitAddCard(res.cardData.card as Card));
                dispatch(updateCard(res.cardData));
                dispatch(messageTrue({ message: "Card Payment Channel Updated Successfully" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setPaymentChannelError(errorMessage);
            } finally {
                setIsPaymentChannelLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setPaymentChannelDone(false);
        setPaymentChannelError(null);
        setIsPaymentChannelLoading(false);
    }, []);

    return {
        paymentChannelError,
        paymentChannelDone,
        isPaymentChannelLoading,
        handleUpdatePaymentChannel,
        handleReset,
    };
};

export default usePaymentChannel;
