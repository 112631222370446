import { errorTrue, messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useCallback, useState } from "react";

import { EditAccountDescriptionRequest } from "../../Services/accounts-api.types";
import { MainInitCompanyDetailsInterface } from "../../../../../redux/init/slice/initSlice.types";
import { editAccountDescription } from "../../Services/accounts-api";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { mainUpdateCompanyDetails } from "../../../../../redux/init/slice/initSlice";
import { setIndividualAccount } from "../../../../../redux/accounts/slice/accountsSlice";
import { useDispatch } from "react-redux";

export interface UseEditAccountDescriptionInterface {
    description: string;
    editAccountDescriptionError: string;
    editAccountDescriptionResponse: boolean;
    isEditAccountDescriptionLoading: boolean;

    handleResetAll: () => void;
    handleEditAccountDescription: (_data: EditAccountDescriptionRequest) => Promise<void>;
    handleChangeUserAccountDescription: (_name: string) => void;
}

function useEditAccountDescription(): UseEditAccountDescriptionInterface {
    const dispatch = useDispatch();

    const [description, setDescription] = useState<string>("");

    const [isEditAccountDescriptionLoading, setIsEditAccountDescriptionLoading] = useState<boolean>(false);
    const [editAccountDescriptionResponse, setEditAccountDescriptionResponse] = useState<boolean>(false);
    const [editAccountDescriptionError, setEditAccountDescriptionError] = useState<string>("");

    const handleEditAccountDescription = useCallback(
        async (_data: EditAccountDescriptionRequest) => {
            try {
                setEditAccountDescriptionError("");
                setIsEditAccountDescriptionLoading(true);
                const res = await editAccountDescription(_data);
                dispatch(
                    mainUpdateCompanyDetails({
                        callback: (companyDetails: MainInitCompanyDetailsInterface) => {
                            companyDetails.accounts = companyDetails.accounts.map((_userAccount) =>
                                _userAccount.id === _data.userAccountId ? res.account : _userAccount
                            );
                        },
                    })
                );
                dispatch(setIndividualAccount(res.account));
                setEditAccountDescriptionResponse(true);
                dispatch(
                    messageTrue({
                        message: "Description updated successfully",
                    })
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setEditAccountDescriptionError(errorMessage);
            } finally {
                setIsEditAccountDescriptionLoading(false);
            }
        },
        [dispatch]
    );

    const handleChangeUserAccountDescription = useCallback((_name: string) => {
        setDescription(_name);
    }, []);

    const handleResetAll = useCallback(() => {
        setDescription("");
        setEditAccountDescriptionError("");
        setIsEditAccountDescriptionLoading(false);
        setEditAccountDescriptionResponse(false);
    }, []);

    return {
        description,
        editAccountDescriptionError,
        editAccountDescriptionResponse,
        isEditAccountDescriptionLoading,

        handleResetAll,
        handleEditAccountDescription,
        handleChangeUserAccountDescription,
    };
}

export default useEditAccountDescription;
