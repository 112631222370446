import React, { useEffect, useState } from "react";

import Accordion from "../../../../../../../../../components/accordion";
import AgreementSignatureComponent from "./AgreementSignatureComponent";
import { AgreementType } from "../../../../../../services/nigeria-application/registered/new/agreement.types";
import GoogleIcon from "../../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import PreviewModal from "../../../../../modals/preview-modal";
import UploadedCard from "../../../../../cards/UploadedCard";
import titleCase from "../../../../../../../../../hooks/titleCase";
import usePreviewBusinessAgreement from "../../../../../../Hooks/nigeria-application/registered-business/agreement/usePreviewBusinessAgreement";
import { useSelector } from "react-redux";

interface Props {
    isOpen: boolean;
    isExternalLink?: boolean;

    toggle: () => void;
}

function BoardResolutionComponent(props: Props): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreviewBusinessAgreement({
        downloadedDocumentName: "Board Resolution Document",
        onComplete: () => setShowPreviewModal(true),
    });

    const isCreator = useSelector((state: IRootState) => state.application.init?.isCreator);
    const createdBy = useSelector((state: IRootState) => state.application.agreement?.createdBy.name);
    const signatories = useSelector((state: IRootState) => state.application.agreement?.signatories);
    const currentUser = useSelector((state: IRootState) => state.application.agreement?.currentUser);
    const boardResolution = useSelector((state: IRootState) => state.application.agreement?.agreements.boardResolution);

    const [isSigned, setIsSigned] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    const isUploaded = !!(boardResolution?.signedDocumentId && boardResolution?.signedBy.length < 1) || false;
    const listOfBoardResolutionSignedSignatories = boardResolution?.signedBy || [];
    const listOfBoardResolutionUnsignedSignatories = signatories?.filter((f) => !boardResolution?.signedBy.some((_el) => _el.id === f.id)) || [];

    useEffect(() => {
        setIsSigned(!!(boardResolution?.signedBy.some((_el) => _el.userId === currentUser?.id) || boardResolution?.signedDocumentId) || false);
    }, [props.isOpen, boardResolution]);

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Board Resolution Preview"
                />
                <Accordion
                    header="Board Resolution"
                    checkActive={!!boardResolution?.signedDocumentId}
                    isOpened={props.isOpen}
                    toggle={props.toggle}
                    hasCheck
                >
                    <div className="relative flex w-full flex-col items-start justify-start space-y-6">
                        <div className="ml-[25px] flex flex-col">
                            <div className="text-xs text-black-tertiary lg:text-sm">
                                {boardResolution?.signedDocumentId && listOfBoardResolutionSignedSignatories.length < 1 && (
                                    <div className="flex flex-row items-center justify-start space-x-3 text-success">
                                        <GoogleIcon icon="check" size="lg" />

                                        <span>Uploaded by: {titleCase(createdBy || "")}</span>
                                    </div>
                                )}
                                {listOfBoardResolutionSignedSignatories.length > 0 &&
                                    listOfBoardResolutionSignedSignatories.map((_signedSig, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-3 text-success" key={index}>
                                            <GoogleIcon icon="check" size="lg" />

                                            <span>Signed by: {titleCase(_signedSig.name)}</span>
                                        </div>
                                    ))}
                                {!boardResolution?.signedDocumentId &&
                                    listOfBoardResolutionUnsignedSignatories.map((_unsignedSig, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-3" key={index}>
                                            <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                            <span>
                                                Expecting{" "}
                                                <span className="font-medium capitalize text-black-secondary">{titleCase(_unsignedSig.name)}</span> to
                                                sign
                                            </span>
                                        </div>
                                    ))}
                                {!boardResolution?.signedDocumentId && listOfBoardResolutionSignedSignatories.length < 1 && (
                                    <>
                                        <div className="flex flex-row items-center justify-start space-x-3">
                                            <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                            <p className="text-xs text-black-tertiary lg:text-sm">
                                                Or <span className="font-medium capitalize text-black-secondary">{titleCase(createdBy || "")}</span>{" "}
                                                to upload the signed document
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="relative w-full">
                            {!isSigned && (
                                <AgreementSignatureComponent
                                    type={AgreementType.BOARD_RESOLUTION}
                                    isCreator={!!isCreator}
                                    onComplete={() => setIsSigned(true)}
                                    canAppendSignature={!!currentUser?.hasSignature}
                                />
                            )}
                            {isSigned && (
                                <UploadedCard
                                    text={isUploaded ? "Document Uploaded" : "Document Signed"}
                                    primaryButtonText="Sign again"
                                    handlePreviewDocumentFunc={() => void handlePreviewDocument(AgreementType.BOARD_RESOLUTION)}
                                    handleRedo={() => setIsSigned(false)}
                                    isNoPrimaryButton={isUploaded}
                                />
                            )}
                        </div>
                    </div>
                </Accordion>
            </div>
        </>
    );
}

export default BoardResolutionComponent;
