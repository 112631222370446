import { NotificationType, UserRole } from "../../../../../../models/userAccount.constants";
import React, { useEffect } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import Checkbox from "../../../../../../components/checkbox";
import CurrencyCode from "../../../../../../components/currency-code";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import MoneyInput from "../../../../../../components/inputs/money-input";
import SingleSelectDropdown from "../../DropDowns/TeamMembers/SingleSelectDropdown";
import { TeamMemberItem } from "../../../Services/team-members/team-members.types";
import { ToastType } from "../../../../../../helpers/AppConstants";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import formatNumber from "../../../../../../utils/formatNumber";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import titleCase from "../../../../../../hooks/titleCase";
import useAddTeamMemberToAccounts from "../../../Services/team-members/hooks/useAddTeamMemberToAccounts";
import MultiTagCloudSelectDropdown from "../../../../../../components/dropdown/multi-select-dropdown/MultiTagCloudSelectDropdown";

interface AddTeamMemberToAccountsFormProps {
    item: TeamMemberItem;
    active: boolean;

    onClose: () => void;
    onComplete: (item: TeamMemberItem, _accounts: UserAccountMeta[]) => void;
}

function AddTeamMemberToAccountsForm({ onClose, onComplete, active, item }: AddTeamMemberToAccountsFormProps): JSX.Element {
    const useAddTeamMemberToAccountsData = useAddTeamMemberToAccounts(item, active, onComplete);

    useEffect(() => {
        if (useAddTeamMemberToAccountsData.settingsForm.role === UserRole.NOTIFICATION_ONLY) {
            useAddTeamMemberToAccountsData.setNotificationType(NotificationType.TRUNCATED);
        }
    }, [useAddTeamMemberToAccountsData.settingsForm.role]);

    useEffect(() => {
        if (useAddTeamMemberToAccountsData.settingsForm.notificationType === NotificationType.NONE) {
            useAddTeamMemberToAccountsData.setNotificationChannels([]);
        }
    }, [useAddTeamMemberToAccountsData.settingsForm.notificationType]);

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    useAddTeamMemberToAccountsData.handleFormSubmit();
                }}
                className="w-full"
            >
                <ModalBody>
                    <div className="flex flex-col items-start space-y-4 text-base font-normal md:items-center md:px-0 lg:items-center lg:px-0">
                        <MultiTagCloudSelectDropdown
                            placeholder="Select Account(s)"
                            value={useAddTeamMemberToAccountsData.settingsForm.userAccountIds}
                            options={useAddTeamMemberToAccountsData.options.account}
                            onChange={useAddTeamMemberToAccountsData.setUserAccountIds}
                            active={active}
                        />
                        <SingleSelectDropdown
                            placeholder="Role"
                            options={useAddTeamMemberToAccountsData.options.role}
                            onChange={useAddTeamMemberToAccountsData.setRole}
                            active={active}
                            fitHeight
                        />
                        <SingleSelectDropdown
                            placeholder="Notification Type"
                            options={useAddTeamMemberToAccountsData.options.notificationType}
                            onChange={useAddTeamMemberToAccountsData.setNotificationType}
                            isDisabled={useAddTeamMemberToAccountsData.settingsForm.role === UserRole.NOTIFICATION_ONLY}
                            value={useAddTeamMemberToAccountsData.settingsForm.notificationType}
                            active={active}
                            fitHeight
                        />
                        <MultiTagCloudSelectDropdown
                            placeholder="Notification Channel"
                            options={useAddTeamMemberToAccountsData.options.notificationChannel}
                            onChange={useAddTeamMemberToAccountsData.setNotificationChannels}
                            isDisabled={useAddTeamMemberToAccountsData.settingsForm.notificationType === NotificationType.NONE}
                            value={useAddTeamMemberToAccountsData.settingsForm.notificationChannels}
                            active={active}
                        />
                        {(useAddTeamMemberToAccountsData.settingsForm.role === UserRole.APPROVER ||
                            useAddTeamMemberToAccountsData.settingsForm.role === UserRole.BOTH) && (
                            <div className="w-full">
                                <Checkbox
                                    id="approval-level"
                                    size="sm"
                                    text="Set amount based approval access for your team members"
                                    func={useAddTeamMemberToAccountsData.handleToggleApprovalLevel}
                                    checked={useAddTeamMemberToAccountsData.isSettingApprovalLevel}
                                    truncate={false}
                                />
                            </div>
                        )}
                        {useAddTeamMemberToAccountsData.isSettingApprovalLevel && (
                            <MoneyInput
                                placeholder="Amount"
                                icon={<CurrencyCode />}
                                value={useAddTeamMemberToAccountsData.settingsForm.maxApprovalAmount || ""}
                                onChange={(amount) => {
                                    if (!amount || amount === 0 || Number(amount) === 0) {
                                        useAddTeamMemberToAccountsData.setMaxApprovalAmount("");
                                        return;
                                    }
                                    useAddTeamMemberToAccountsData.setMaxApprovalAmount(String(amount));
                                }}
                                helperText={
                                    <span>
                                        {titleCase(item.teamMember.name)} will only be able to approve payments below <CurrencyCode />
                                        {useAddTeamMemberToAccountsData.settingsForm.maxApprovalAmount
                                            ? formatNumber(useAddTeamMemberToAccountsData.settingsForm.maxApprovalAmount)
                                            : "0.00"}
                                    </span>
                                }
                            />
                        )}
                        {!!useAddTeamMemberToAccountsData.error && (
                            <div className="max-w-md pt-1.5">
                                <MessageToasts toastMessage={useAddTeamMemberToAccountsData.error || ""} toastType={ToastType.ERROR} />
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp size="lg" type="button" ripple="light" buttonType="secondary" color="grey" func={onClose}>
                        <span>Cancel</span>
                    </ButtonComp>

                    <ButtonComp
                        size="lg"
                        type="submit"
                        color="black"
                        ripple="light"
                        buttonType="primary"
                        isLoading={useAddTeamMemberToAccountsData.isSubmitting}
                        disable={
                            !useAddTeamMemberToAccountsData.isFormValid ||
                            (useAddTeamMemberToAccountsData.isSettingApprovalLevel &&
                                (isNullOrUndefined(useAddTeamMemberToAccountsData.settingsForm.maxApprovalAmount) ||
                                    (!isNullOrUndefined(useAddTeamMemberToAccountsData.settingsForm.maxApprovalAmount) &&
                                        Number(parseFloat(useAddTeamMemberToAccountsData.settingsForm.maxApprovalAmount)) < 1) ||
                                    !useAddTeamMemberToAccountsData.settingsForm.maxApprovalAmount))
                        }
                    >
                        {/* <span>Add {`${useAddTeamMemberToAccountsData.settingsForm.userAccountIds.length > 1 ? "Accounts" : "Account"}`}</span> */}
                        <span>Proceed </span>
                    </ButtonComp>
                </ModalFooter>
            </form>
        </>
    );
}

export default AddTeamMemberToAccountsForm;
