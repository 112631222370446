import {
    AccountSignatoriesRequests,
    abortAccountSignatoriesRequest,
    accountSignatories,
} from "../../../../services/nigeria-application/registered/accountSignatories.api";
import {
    CurrentSignatoryRequests,
    abortCurrentSignatoryRequest,
    currentSignatory,
} from "../../../../services/nigeria-application/registered/currentSignatory.api";
import {
    resetAccountSignatories,
    resetCurrentSignatory,
    setAccountSignatories,
    updateCurrentSignatory,
} from "../../../../../../../redux/application/applicationSlice";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Routes } from "../../../../../../../routes/routes.constants";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface useAccountSignatoriesInitInterface {
    currentSignatoryError: string | null;
    isCurrentSignatoryLoading: boolean;
    accountSignatoriesError: string | null;
    isAccountSignatoriesLoading: boolean;
    handleReset: () => void;
    handleCurrentSignatoryInit: () => Promise<void>;
    handleAccountSignatoriesInit: () => Promise<void>;
}

const useAccountSignatoriesInit = (): useAccountSignatoriesInitInterface => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [currentSignatoryError, setCurrentSignatoryError] = useState<string | null>(null);
    const [isCurrentSignatoryLoading, setIsCurrentSignatoryLoading] = useState<boolean>(false);

    const [accountSignatoriesError, setAccountSignatoriesError] = useState<string | null>(null);
    const [isAccountSignatoriesLoading, setIsAccountSignatoriesLoading] = useState<boolean>(false);

    const handleCurrentSignatoryInit = useCallback(async (): Promise<void> => {
        try {
            setCurrentSignatoryError(null);
            setIsCurrentSignatoryLoading(true);
            const res = await currentSignatory();
            if (res.signatory) {
                dispatch(updateCurrentSignatory(res));
                return;
            }
            if (
                location.pathname === Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS ||
                location.pathname === Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.NEXT_OF_KIN ||
                location.pathname === Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID ||
                location.pathname === Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH ||
                location.pathname === Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.SIGNATURE
            ) {
                navigate(Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL);
            }
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            setCurrentSignatoryError(errorMessage);
            dispatch(errorTrue(errorMessage));
        } finally {
            setIsCurrentSignatoryLoading(false);
        }
    }, [dispatch]);

    const handleAccountSignatoriesInit = useCallback(async (): Promise<void> => {
        try {
            setAccountSignatoriesError(null);
            setIsAccountSignatoriesLoading(true);
            const res = await accountSignatories();
            dispatch(setAccountSignatories(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            setAccountSignatoriesError(errorMessage);
            dispatch(errorTrue(errorMessage));
        } finally {
            setIsAccountSignatoriesLoading(false);
        }
    }, [dispatch]);

    const handleReset = () => {
        setCurrentSignatoryError(null);
        setIsCurrentSignatoryLoading(false);
        setAccountSignatoriesError(null);
        setIsAccountSignatoriesLoading(false);
        dispatch(resetCurrentSignatory());
        dispatch(resetAccountSignatories());
        abortCurrentSignatoryRequest(CurrentSignatoryRequests.INIT);
        abortAccountSignatoriesRequest(AccountSignatoriesRequests.INIT);
    };

    return {
        currentSignatoryError,
        isCurrentSignatoryLoading,
        accountSignatoriesError,
        isAccountSignatoriesLoading,
        handleReset,
        handleCurrentSignatoryInit,
        handleAccountSignatoriesInit,
    };
};
export default useAccountSignatoriesInit;
