import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BillPaymentStage } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import { IRootState } from "../../../../../redux/rootReducer";
import MakePayment from "./AirtimePurchase/MakePayment";
import PaymentDetails from "./AirtimePurchase/PaymentDetails";
import ProcessingPayment from "./AirtimePurchase/ProcessingPayment";
import ReviewPayment from "./AirtimePurchase/ReviewPayment";
import { resetAllAirtimePurchaseData } from "../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import { resetAllBillPaymentData } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice";

function AirtimePurchase(): JSX.Element {
    const dispatch = useDispatch();
    const billPaymentStage = useSelector((state: IRootState) => state.billPayment.billPaymentStage);

    useEffect(() => {
        return () => {
            dispatch(resetAllBillPaymentData());
            dispatch(resetAllAirtimePurchaseData());
        };
    }, []);

    return (
        <>
            {billPaymentStage === BillPaymentStage.PAYMENT_DETAILS && <PaymentDetails />}
            {billPaymentStage === BillPaymentStage.REVIEW_PAYMENT && <ReviewPayment />}
            {billPaymentStage === BillPaymentStage.MAKE_PAYMENT && <MakePayment />}
            {billPaymentStage === BillPaymentStage.PROCESS_PAYMENT && <ProcessingPayment />}
        </>
    );
}

export default AirtimePurchase;
