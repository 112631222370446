import { ReactComponent as Info } from "../../../../../../assets/svg/Terminal/error_info.svg";
import React from "react";
import TerminalRequest from "../../../../../../models/terminalRequest";
import titleCase from "../../../../../../hooks/titleCase";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import TerminalRequestStatusTag from "../../Statustag/TerminalRequestStatusTag";
import { PurchaseType } from "../../../../../../models/terminal.constants";
import formatNumber from "../../../../../../utils/formatNumber";
import CurrencyCode from "../../../../../../components/currency-code";

export const TERMINAL_REQUEST_TRAY_DATATYPE = "terminal-request";

interface Props {
    active: boolean;
    terminalRequest: TerminalRequest | null;
    toggle: () => void;
}

function TerminalRequestTray(props: Props): JSX.Element {
    return (
        <>
            {props.terminalRequest && (
                <FullPageTray active={props.active} dataType={TERMINAL_REQUEST_TRAY_DATATYPE} handleReset={props.toggle} withClickOutside>
                    <FullPageTrayHeader toggler={props.toggle} dataType={TERMINAL_REQUEST_TRAY_DATATYPE}>
                        Request Details
                    </FullPageTrayHeader>
                    <FullPageTrayBody dataType={TERMINAL_REQUEST_TRAY_DATATYPE}>
                        <div className="w-full space-y-6">
                            <div className="flex w-full justify-between space-x-8 py-2">
                                <p className="text-sm font-normal text-black-tertiary">Request Status</p>

                                <div className="">
                                    <TerminalRequestStatusTag request={props.terminalRequest} />
                                </div>
                            </div>
                            {props.terminalRequest.isDeclined && (
                                <div className="space-y-3 rounded-lg border border-error-quin bg-error-backdrop px-3 py-3 text-xs font-medium capitalize leading-none text-error">
                                    <div className="flex items-center justify-start space-x-2">
                                        <Info />
                                        <p className="text-xs font-medium text-black-secondary">Reason for Decline</p>
                                    </div>
                                    <p className="text-xs font-normal text-black-tertiary">{props.terminalRequest.declineReason}</p>
                                </div>
                            )}
                            <div className="flex w-full flex-col space-y-2 rounded-lg bg-grey-tertiary px-4 py-4">
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Number of Terminal</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {props.terminalRequest.numUnits || ""}
                                    </p>
                                </div>

                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Purchase Type</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {props.terminalRequest.getPurchaseText || ""}
                                    </p>
                                </div>

                                {props.terminalRequest.numUnits && props?.terminalRequest?.unitPurchaseCost && (
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">
                                            {props.terminalRequest.purchaseType === PurchaseType.OUTRIGHT_PURCHASE ? "Purchase Fee" : "Caution Fee"}
                                        </p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            <CurrencyCode currency={props.terminalRequest.debitAccount?.bankAccountCurrency} />
                                            {formatNumber(
                                                (props.terminalRequest.purchaseType === PurchaseType.OUTRIGHT_PURCHASE
                                                    ? props.terminalRequest.numUnits * Number(props.terminalRequest.unitPurchaseCost)
                                                    : props.terminalRequest.numUnits * Number(props.terminalRequest.unitCautionFee)) || ""
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="flex w-full flex-col space-y-2 rounded-lg bg-grey-tertiary px-4 py-4">
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Est.Daily Transaction Volume </p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {props.terminalRequest.estimatedDailyTransactionVolume.replace(/NGN/g, "₦") || ""}
                                    </p>
                                </div>

                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Est.Daily Transaction Count </p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {props.terminalRequest.estimatedDailyTransactionCount || ""}
                                    </p>
                                </div>
                            </div>

                            <div className="flex w-full flex-col space-y-2 rounded-lg bg-grey-tertiary px-4 py-4">
                                <div className="flex w-full flex-row items-start justify-between" data-type={TERMINAL_REQUEST_TRAY_DATATYPE}>
                                    <p className="text-sm text-black-tertiary" data-type={TERMINAL_REQUEST_TRAY_DATATYPE}>
                                        Requested By
                                    </p>
                                    <p
                                        className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary"
                                        data-type={TERMINAL_REQUEST_TRAY_DATATYPE}
                                    >
                                        {titleCase(props.terminalRequest.requestedBy?.firstName || "")}{" "}
                                        {titleCase(props.terminalRequest.requestedBy?.lastName || "")}
                                    </p>
                                </div>

                                <div className="flex w-full flex-row items-start justify-between" data-type={TERMINAL_REQUEST_TRAY_DATATYPE}>
                                    <p className="text-sm text-black-tertiary" data-type={TERMINAL_REQUEST_TRAY_DATATYPE}>
                                        Account to Debit
                                    </p>
                                    <p
                                        className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary"
                                        data-type={TERMINAL_REQUEST_TRAY_DATATYPE}
                                    >
                                        {titleCase(
                                            props.terminalRequest.debitAccount?.isMain
                                                ? props.terminalRequest.debitAccount?.name || ""
                                                : props.terminalRequest.debitAccount?.lencoNameMin || ""
                                        )}
                                    </p>
                                </div>
                                <div className="flex w-full flex-row items-start justify-between" data-type={TERMINAL_REQUEST_TRAY_DATATYPE}>
                                    <p className="text-sm text-black-tertiary" data-type={TERMINAL_REQUEST_TRAY_DATATYPE}>
                                        Delivery Address
                                    </p>
                                    <p
                                        className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary"
                                        data-type={TERMINAL_REQUEST_TRAY_DATATYPE}
                                    >
                                        {props.terminalRequest.deliveryAddress || ""}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </FullPageTrayBody>
                </FullPageTray>
            )}
        </>
    );
}

export default TerminalRequestTray;
