import AccountOpeningProgress from "../../../../../../../models/account-opening/progress/accountOpeningProgress";
import CompanyDocument from "../../../../../../../models/account-opening/companyDocument";
import IncorporationDocumentZambia from "../../../../../../../models/account-opening/incorporation-documents/incorporationDocumentZambia";
import NewAccountOpeningProgress from "../../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import ProofOfResidence from "../../../../../../../models/account-opening/proofOfResidence";
import ZambiaBusinessDetails from "../../../../../../../models/account-opening/zambiaBusinessDetails";
import { hintMarkItem } from "../../../../components/element/hint/hint.constant";

export enum ProofOfResidenceType {
    UTILITY_BILL = 1,
    BANK_STATEMENT = 2,
}

export enum BusinessRegistrationCategory {
    LLC = 1,
    BUSINESS_NAME = 2,
    NGO = 3,
}

export enum SignatoryStatus {
    INVITE_PENDING = 1,
    SIGNED_UP = 2,
    REMOVED = 3,
}

export enum IncorporationDocumentType {
    INCORPORATION_CERTIFICATE = 1,
    MEMORANDUM_OF_ASSOCIATION = 2,
    ZAMBIA__FORM_3 = 102,
    ZAMBIA__PACRA_PRINT_OUT = 104,
    ZAMBIA__ASSOCIATION_CONSTITUTION = 106,
    ZAMBIA__OPERATING_LICENSE = 107,
}

export const shareholdersOptions = [
    {
        id: IncorporationDocumentType.MEMORANDUM_OF_ASSOCIATION,
        name: "Articles of Association",
    },
    {
        id: IncorporationDocumentType.ZAMBIA__FORM_3,
        name: "Form III",
    },
    {
        id: IncorporationDocumentType.ZAMBIA__PACRA_PRINT_OUT,
        name: "PACRA Print Out",
    },
];

export enum CompanyDocumentTypes {
    INCORPORATION_CERTIFICATE = "certificate",
    MEMORANDUM_OF_ASSOCIATION = "memorandumOfAssociation",
    FORM_3 = "form",
    PACRA_PRINT_OUT = "pacraPrintOut",
    ASSOCIATION_CONSTITUTION = "associationConstitution",
    OPERATING_LICENSE = "operatingLicense",
    PROOF_OF_RESIDENCE = "proofOfResidence",
    SHAREHOLDERS_INFORMATION = "shareholdersInformation",
}

export const AllCompanyDocuments = [
    CompanyDocumentTypes.ASSOCIATION_CONSTITUTION,
    CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION,
    CompanyDocumentTypes.FORM_3,
    CompanyDocumentTypes.PACRA_PRINT_OUT,
    CompanyDocumentTypes.ASSOCIATION_CONSTITUTION,
    CompanyDocumentTypes.OPERATING_LICENSE,
    CompanyDocumentTypes.PROOF_OF_RESIDENCE,
];

export const NGODocuments = [CompanyDocumentTypes.INCORPORATION_CERTIFICATE, CompanyDocumentTypes.ASSOCIATION_CONSTITUTION];
export const BusinessNameDocuments = [CompanyDocumentTypes.INCORPORATION_CERTIFICATE, CompanyDocumentTypes.PROOF_OF_RESIDENCE];
export const LLCDocuments = [
    CompanyDocumentTypes.INCORPORATION_CERTIFICATE,
    CompanyDocumentTypes.OPERATING_LICENSE,
    CompanyDocumentTypes.SHAREHOLDERS_INFORMATION,
];

export type UploadCompanyDocumentForm = {
    certificate: Blob | null;
    memorandumOfAssociation: Blob | null;
    formC02: Blob | null;
    formC07: Blob | null;
    incorporationDocument: Blob | null;
    utilityBillType: number | null;
    utilityBill: Blob | null;
    scumlCertificate: Blob | null;
};

export const CompanyDocumentFieldTexts: {
    [type in CompanyDocumentTypes]: string;
} = {
    [CompanyDocumentTypes.INCORPORATION_CERTIFICATE]: "Certificate",
    [CompanyDocumentTypes.FORM_3]: "Form III",
    [CompanyDocumentTypes.SHAREHOLDERS_INFORMATION]: "Shareholder's Information",
    [CompanyDocumentTypes.OPERATING_LICENSE]: "Operating License",
    [CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION]: "Memorandum and Articles of Association",
    [CompanyDocumentTypes.PACRA_PRINT_OUT]: "PACRA Printout",
    [CompanyDocumentTypes.PROOF_OF_RESIDENCE]: "Proof Of Residence",
    [CompanyDocumentTypes.ASSOCIATION_CONSTITUTION]: "Association Constitution",
};

export const IncorporationDocumentTypeText: { [type in IncorporationDocumentType]: CompanyDocumentTypes } = {
    [IncorporationDocumentType.INCORPORATION_CERTIFICATE]: CompanyDocumentTypes.INCORPORATION_CERTIFICATE,
    [IncorporationDocumentType.ZAMBIA__ASSOCIATION_CONSTITUTION]: CompanyDocumentTypes.ASSOCIATION_CONSTITUTION,
    [IncorporationDocumentType.ZAMBIA__OPERATING_LICENSE]: CompanyDocumentTypes.OPERATING_LICENSE,
    [IncorporationDocumentType.MEMORANDUM_OF_ASSOCIATION]: CompanyDocumentTypes.SHAREHOLDERS_INFORMATION,
    [IncorporationDocumentType.ZAMBIA__FORM_3]: CompanyDocumentTypes.SHAREHOLDERS_INFORMATION,
    [IncorporationDocumentType.ZAMBIA__PACRA_PRINT_OUT]: CompanyDocumentTypes.SHAREHOLDERS_INFORMATION,
};
// export const CompanyDocumentFieldSubTexts: {
// [type in CompanyDocumentTypes]: string;
// } = {
// [CompanyDocumentTypes.CERTIFICATE]: "This is your company registration certificate issued by Corporate Affair Commission (CAC)",
// [CompanyDocumentTypes.FORM_C02]: "This information about the shareholders of the company",
// [CompanyDocumentTypes.FORM_C07]: "This contains information about the Directors of the company",
// [CompanyDocumentTypes.INCORPORATION_DOCUMENT]: "This contains information on the formation and legal structure of your business",
// [CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION]: "This contains information about the nature and structure of business",
// [CompanyDocumentTypes.SCUML_CERTIFICATE]: "SCUML: Special Control Unit against Money Laundering",
// [CompanyDocumentTypes.UTILITY_BILL]: "Only utility bills less than 3 months are accepted",
// };

export const CompanyDocumentTextArray = [
    { formikName: CompanyDocumentTypes.FORM_3, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.FORM_3] },
    { formikName: CompanyDocumentTypes.PACRA_PRINT_OUT, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.PACRA_PRINT_OUT] },
    { formikName: CompanyDocumentTypes.OPERATING_LICENSE, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.OPERATING_LICENSE] },
    { formikName: CompanyDocumentTypes.PROOF_OF_RESIDENCE, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.PROOF_OF_RESIDENCE] },
    { formikName: CompanyDocumentTypes.SHAREHOLDERS_INFORMATION, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.SHAREHOLDERS_INFORMATION] },
    { formikName: CompanyDocumentTypes.ASSOCIATION_CONSTITUTION, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.ASSOCIATION_CONSTITUTION] },
    { formikName: CompanyDocumentTypes.INCORPORATION_CERTIFICATE, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE] },
    { formikName: CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION, text: CompanyDocumentFieldTexts[CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION] },
];

export const CompanyDocumentTextMap = CompanyDocumentTextArray.reduce((accumulator, obj) => {
    return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getCompanyDocumentHints(_formikList: string[], _uncompleted: string[], _exempted: string[]): hintMarkItem[] {
    let computedList: hintMarkItem[] = [];
    const _completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));

    for (const _string of _completed) {
        computedList.push({
            isChecked: true,
            text: CompanyDocumentTextMap.get(_string) || "",
        });
    }
    for (const _string of _uncompleted) {
        computedList = computedList.map((_) =>
            _.text === CompanyDocumentTextMap.get(_string)
                ? {
                      isChecked: false,
                      text: _.text,
                  }
                : _
        );
    }
    return computedList;
}

export interface CompanyDocumentResponse {
    companyDocument: CompanyDocument;
    progress: AccountOpeningProgress;
}

export type IncorporationDocumentRequest = {
    // type: CompanyDocumentTypes;
    type: IncorporationDocumentType;
    file: File;
};

export type DeleteIncorporationDocumentRequest = {
    // type: CompanyDocumentTypes;
    type: IncorporationDocumentType;
};

export type DeleteProofOfResidenceRequest = {
    type: number;
};

export type ProofOfResidenceRequest = {
    type: number;
    file: File;
};

export type BusinessDocumentResponse = {
    application: ZambiaBusinessDetails;
    incorporationDocuments: IncorporationDocumentZambia[];
    proofOfResidence: ProofOfResidence | null;
    progress: NewAccountOpeningProgress;
};
