import React, { useEffect } from "react";

import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import Failed from "../../../../../../../../../assets/svg/MobileMoney/failed.svg";
import Modal from "../../../../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../../../../components/modal/modal-header";
import Success from "../../../../../../../../../assets/svg/MobileMoney/success.svg";
import Waiting from "../../../../../../../../../assets/svg/MobileMoney/waiting.svg";
import useMobileMoneyStatus from "../../../../../../Hooks/State/Zambia/MobileMoney/useMobileMoneyStatus";

interface Props {
    keyId: string;
    handleClose: () => void;
    handleUpdateState: () => void;
}

function MobileMoneyProcessPayment(props: Props): JSX.Element {
    const { transaction } = useMobileMoneyStatus(props.keyId);

    useEffect(() => {
        if (transaction?.isSuccess || transaction?.isFailed) props.handleUpdateState();
    }, [transaction]);

    return (
        <>
            <Modal size="sm" active toggler={props.handleClose}>
                <ModalHeader onClose={props.handleClose}>{""}</ModalHeader>
                <ModalBody>
                    <div className="flex w-full flex-col items-center justify-center space-y-4 rounded-lg bg-grey-backdrop px-8 py-6">
                        <img src={transaction?.isSuccess ? Success : transaction?.isFailed ? Failed : Waiting} alt="process status" />
                        <p className="text-sm font-bold text-black-secondary 2xs:text-base">
                            {transaction?.isSuccess ? "Payment Successful" : transaction?.isFailed ? "Payment Failed" : "Phone Number Verified"}
                        </p>
                        <p className="text-sm text-black-tertiary">
                            {transaction?.isSuccess
                                ? "Your payment has been received successfully"
                                : transaction?.isFailed
                                  ? transaction.failedReason
                                  : "Check your device to authorize payment"}
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                        {/* <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
							<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleClose} fullWidth>
								<span>Cancel</span>
							</ButtonComp>
						</div> */}
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <ButtonComp color="black" buttonType="primary" fullWidth disable={transaction?.isProcessing} func={props.handleClose}>
                                <span>Done</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default MobileMoneyProcessPayment;
