import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class Referee {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string,
        public phone: string,
        public email: string,
        public isCompleted: boolean,
        public isRevoked: boolean,
        public documentId: string | null
    ) {}

    static create(obj: GenericObject): Referee {
        return new Referee(
            Parsers.string(obj.id),
            Parsers.string(obj.name),
            Parsers.string(obj.phone),
            Parsers.string(obj.email),
            Parsers.boolean(obj.isCompleted),
            Parsers.boolean(obj.isRevoked),
            Parsers.nullableString(obj.documentId)
        );
    }

    get statusText(): string {
        if (this.isCompleted) {
            return "Completed";
        }
        if (this.isRevoked) {
            return "Revoked";
        }

        return "Pending";
    }

    get isPending(): boolean {
        return !this.isCompleted && !this.isRevoked;
    }
}
