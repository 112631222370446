import "react-circular-progressbar/dist/styles.css";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { ReactNode } from "react";

// import useClickOutside from "../../../hooks/useClickOutside";

interface Props {
    header: ReactNode;
    tooltip?: boolean;
    progress: number;
    subTitle?: string;
    subTitle2?: string;
    withBorder?: boolean;
    children: ReactNode;
}

function ApplicationHeader(props: Props): JSX.Element {
    return (
        <div className="w-full rounded-lg bg-white p-5">
            <div
                className={
                    "flex w-full flex-row items-center justify-between space-x-44 " +
                    `${props.withBorder ? "mb-7 border-b border-grey-secondary pb-7" : "pb-10"}`
                }
            >
                <div className="text-md flex flex-col justify-start font-medium text-black-secondary">
                    <div className="flex items-start justify-start capitalize">
                        <h3 className="text-2xl font-medium text-black sm:leading-[100%]">{props.header}</h3>
                        {props.tooltip && <span className="ml-2">i</span>}
                    </div>
                    {props.subTitle && (
                        <p className="pt-4 text-base font-normal text-black-secondary">
                            {props.subTitle} <br />
                            {props.subTitle2}
                        </p>
                    )}
                </div>
                <div className="flex h-full w-fit items-center justify-center self-center">
                    <div>
                        <div className="h-10 w-10">
                            <CircularProgressbar
                                value={props.progress}
                                text={`${props.progress}%`}
                                styles={buildStyles({
                                    pathColor: `rgba(84, 102, 249, 1)`,
                                    textColor: "rgba(113, 116, 140, 1)",
                                    trailColor: "rgba(241, 241, 244, 1)",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full">{props.children}</div>
        </div>
    );
}

export default ApplicationHeader;
