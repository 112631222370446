import { MainInitCompanyDetailsInterface } from "../../../../../redux/init/slice/initSlice.types";
import React from "react";

interface Props {
    data: MainInitCompanyDetailsInterface | null;
}

function ActivateAccountMessage({ data }: Props): JSX.Element {
    return (
        <>
            <div className="relative flex w-full items-center justify-center pt-4 text-xs sm:text-sm xs:text-base">
                {/* show if account created is false */}
                <div className="flex w-max flex-row items-center justify-center rounded-3xl bg-success-backdrop px-3 py-2 text-success 2xs:px-5 xs:px-10">
                    {" "}
                    {/* <LockIcon /> */}
                    <p className="ml-2 w-full break-words text-center">
                        Activate your {data?.company?.isTypeStarter ? "Starter" : data?.company?.isTypeBeta ? "Pro" : "Full"} Account
                    </p>
                </div>
                <div className="absolute right-0 hidden h-full flex-row items-center justify-center sm:flex">
                    <div className="hidden sm:inline">{/* <QuestionIcon /> */}</div>
                    <p className="ml-2">
                        <span className="hidden sm:inline">About</span>{" "}
                        <span className="hidden xl:inline">
                            {" "}
                            {data?.company?.isTypeStarter ? "Starter" : data?.company?.isTypeBeta ? "Pro" : "Full"} account
                        </span>{" "}
                    </p>
                </div>
            </div>
        </>
    );
}

export default ActivateAccountMessage;
