import React, { KeyboardEvent, useEffect, useState } from "react";

import GoogleIcon from "../../google-icon";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import useClickOutside from "../../../hooks/useClickOutside";

interface DropdownHeadProps {
    placeholder: React.ReactNode;
    placeholderClose?: React.ReactNode;
    children: React.ReactNode;
    clickAndClose?: boolean;
    icon?: boolean;
    placement: "center" | "right" | "left";
    outline?: boolean;
    noArrow?: boolean;
    withArrowBorder?: boolean;
    outlineBg?: boolean;
    loadingColor?: string;
    outlineBorder?: boolean;
    outlineBorderHover?: boolean;
    noOutlineBorder?: boolean;
    noHoverBg?: boolean;
    noTextHover?: boolean;
    value?: boolean;
    size?: "2xs" | "xs" | "sm" | "lg";
    filled?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    color?: string;
    isActive?: boolean;
    isHover?: boolean;
    isSelected?: boolean;
    isSearchable?: boolean;
    searchTerm?: string;
    fitDropdown?: boolean;
    isFilterOpen?: boolean;
    noYPadding?: boolean;
    noOverflow?: boolean;
    triggerLower?: boolean;
    searchPlaceholder?: string;
    borderColor?: string;
    handleOpen?: () => void;
    handleClose?: () => void;
    handleChangeSearchTerm?: (e: string) => void;
    handleAdditionalCheck?: (e: HTMLElement) => boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isFilter?: boolean;
    isFilterBg?: boolean;
}

function DropdownHead({
    placeholder,
    placeholderClose,
    children,
    clickAndClose = false,
    icon = false,
    // loadingColor = "",
    withArrowBorder,
    placement,
    noArrow = false,
    outline = true,
    outlineBg = false,
    outlineBorder = false,
    outlineBorderHover = false,
    noOutlineBorder = false,
    noHoverBg = false,
    noTextHover = false,
    value = false,
    noYPadding = false,
    size,
    filled = false,
    isLoading = false,
    color,
    isActive = false,
    isHover = false,
    isSelected = false,
    isSearchable = false,
    // searchTerm = "",
    noOverflow = false,
    isDisabled = false,
    // searchPlaceholder = "Search",
    borderColor = "border-black-quin",
    handleChangeSearchTerm = undefined,
    handleClose = undefined,
    handleOpen = undefined,
    handleAdditionalCheck = undefined,
    triggerLower = false,
    fitDropdown = false,
    isFilterOpen = false,
    isFilter = false,
    isFilterBg,
}: DropdownHeadProps): JSX.Element {
    const [active, setActive] = useState(false);
    const [positionTop, setPositionTop] = useState(false);
    const [y, setY] = useState<number | null>(null);

    const innerHeight = window.innerHeight;

    const domNode = useClickOutside(() => {
        setActive(false);
        handleChangeSearchTerm && handleChangeSearchTerm("");
    }, handleAdditionalCheck && handleAdditionalCheck);

    useEffect(() => {
        if (domNode.current) {
            setY(domNode.current.getBoundingClientRect().top);
        }
    });

    useEffect(() => {
        if (y) {
            let shouldSetPositionTop;
            if (triggerLower) {
                shouldSetPositionTop = y > innerHeight / 1.25;
            } else {
                shouldSetPositionTop = y > innerHeight / 1.65;
            }
            setPositionTop(shouldSetPositionTop);
        }
    }, [innerHeight, y, triggerLower]);

    useEffect(() => {
        setActive(isActive);
    }, [isActive]);

    useEffect(() => {
        if (active) return;
        handleClose && handleClose();
    }, [active]);

    useEffect(() => {
        if (!active) return;
        handleOpen && handleOpen();
    }, [active]);

    const handleKeypress = (event: KeyboardEvent<HTMLDivElement>) => {
        //it triggers by pressing the enter key
        if (event.key === "Enter") {
            !isDisabled && setActive((prev) => !prev);
        }
    };

    return (
        <div className={`relative flex h-full w-full flex-col items-center justify-start transition-all duration-300 ease-in-out`} ref={domNode}>
            {icon ? (
                <div
                    className="relative flex h-10 cursor-pointer items-center justify-center"
                    onClick={() => !isDisabled && !isLoading && setActive((prev) => !prev)}
                >
                    {isLoading ? (
                        <span className="ml-2 flex items-center justify-center">
                            {/* <TailSpin
                                color={color === "red" ? "#C5046C" : "#5466F9"}
                                height={size === "xs" ? 15 : size === "sm" ? 20 : size === "lg" ? 25 : 20}
                                width={size === "xs" ? 15 : size === "sm" ? 20 : size === "lg" ? 25 : 20}
                            /> */}
                        </span>
                    ) : (
                        <>
                            <div className="pointer-events-none z-20">{placeholder}</div>
                            <div className="absolute top-10% z-10 flex h-8 w-8 items-center justify-center rounded-full transition-all duration-300 ease-in-out hover:bg-blue-senary" />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div
                        className={
                            `relative flex w-full items-center justify-between font-normal capitalize outline-none ` +
                            `whitespace-nowrap leading-relaxed transition-all duration-300 focus:outline-none` +
                            `bg-transparent text-left shadow-none` +
                            `${!isDisabled ? "cursor-pointer" : "pointer-events-none cursor-default"} ` +
                            `${outlineBorder ? "border-blue text-black hover:bg-blue-senary" : ""} ` +
                            `${!isDisabled && outlineBg && !noTextHover && color !== "red" ? "bg-blue-backdrop hover:text-black focus:border-blue-focused" : ""} ` +
                            `${
                                !noOutlineBorder
                                    ? outline && size === "xs"
                                        ? `border border-solid ${borderColor ? `${borderColor} !border` : ""} ${noYPadding ? "" : "py-1.5"} px-2 text-xs`
                                        : `border-0 border-solid ${borderColor ? `${borderColor} !border` : ""} px-4 ${noYPadding ? "" : "py-3"} text-sm`
                                    : outline && size === "xs"
                                      ? `border border-solid px-2 ${noYPadding ? "" : "py-1.5"} text-xs`
                                      : `border-0 px-4 ${noYPadding ? "" : "py-3"} text-sm`
                            } ` +
                            `${filled ? "bg-blue text-white hover:bg-blue-hover focus:bg-blue-focused" : isFilterBg ? "bg-grey" : "bg-white text-black-tertiary"} ` +
                            `${size === "2xs" ? "h-6 !px-2 text-xs" : ""} ` +
                            `${size === "xs" ? "h-8 text-xs" : ""} ` +
                            `${size === "sm" ? "h-10 text-sm" : ""} ` +
                            `${size === "lg" ? "h-12 text-base" : ""} ` +
                            `${isNullOrUndefined(size) ? "h-14 text-base" : ""} ` +
                            `${outline && !filled && color === "red" && size === "xs" ? `border border-solid px-2 ${noYPadding ? "" : "py-1.5"} hover:bg-error-backdrop hover:text-error` : ""} ` +
                            `${outline && !filled && color === "black" && size === "xs" ? `border border-solid ${borderColor ? `${borderColor} !border` : ""} px-2 ${noYPadding ? "" : "py-1.5"} text-black-secondary hover:text-black` : ""} ` +
                            `${outline && !filled && color === "red" && size !== "xs" ? `border border-solid ${borderColor ? `${borderColor} !border` : ""} px-4 ${noYPadding ? "" : "py-3"} hover:bg-error-backdrop hover:text-error` : ""} ` +
                            `${outline && !filled && color === "black" && size !== "xs" ? `${borderColor ? `${borderColor} !border` : ""} border border-solid text-black-secondary hover:text-black` : ""} ` +
                            `${outline && !filled && color !== "red" && color !== "black" && !noHoverBg && !noTextHover ? `px-2 ${noYPadding ? "" : "py-1.5"} hover:bg-blue-senary hover:text-black` : ""} ` +
                            `${!isDisabled && outline && !filled && !outlineBorder && color !== "black" && color !== "red" ? "hover:border-0" : ""} ` +
                            `${outline && !filled && !outlineBorder && color !== "black" && color !== "red" && !noTextHover ? "hover:text-black" : ""} ` +
                            // `${active ? "rounded-lg border-0" : "rounded-lg"} ` +
                            `${active ? `${size === "2xs" ? "rounded-[4px]" : "rounded-lg"} border-0` : `${size === "2xs" ? "rounded-[4px]" : "rounded-lg"}`} ` +
                            `${active && placement === "center" && !outlineBorder && color !== "black" && color !== "red" ? "rounded-b-none rounded-t-lg border-blue text-black" : ""}` +
                            `${active && !filled && color === "red" ? "bg-error-backdrop text-error" : ""}` +
                            `${active && !filled && color === "black" ? `${borderColor ? `${borderColor} !border` : ""} text-black-secondary` : ""} ` +
                            `${active && !filled && color === "red" ? "border-error text-error" : ""} ` +
                            `${active && !filled && color !== "red" && color !== "black" ? "border-blue text-black" : ""} ` +
                            `${active && outlineBorder ? "bg-blue-senary" : ""} ` +
                            `${!active && value ? `${borderColor ? `${borderColor} !border` : ""} text-black-secondary` : ""} ` +
                            `${outlineBorderHover && !filled && !active ? "text-black-tertiary hover:border-black-secondary hover:text-black-secondary" : ""} ` +
                            `${active && outlineBorderHover ? `${borderColor ? `${borderColor} !border` : ""} border text-black` : ""} ` +
                            `${isDisabled ? "border-transparent" : noOutlineBorder && color !== "red" ? "border-transparent focus:border-blue-focused" : ""} ` +
                            `${noOutlineBorder && color === "red" ? "border-transparent" : ""} ` +
                            `${isHover && outline && !filled && color === "red" ? "bg-error-backdrop text-error" : ""} `
                        }
                        tabIndex={0}
                        onClick={(e) => {
                            e.preventDefault();
                            !isDisabled && setActive((prev) => !prev);
                        }}
                        onKeyDown={handleKeypress}
                    >
                        {placeholder}
                        <span className="ml-2 flex items-center justify-center" onClick={(e) => e.stopPropagation()}>
                            {placeholderClose}
                        </span>

                        {isLoading && (
                            <span className="ml-2 flex items-center justify-center">
                                {/* <TailSpin color={loadingColor || color === "red" ? "#C5046C" : "#5466F9"} height={15} width={15} /> */}
                            </span>
                        )}

                        {!noArrow && !isLoading && !isFilterOpen && (
                            <div
                                className={
                                    `flex h-full cursor-pointer items-center justify-center ` +
                                    `${
                                        withArrowBorder
                                            ? color === "red"
                                                ? active
                                                    ? "border-l border-error-quin pl-2"
                                                    : "border-l border-grey-secondary pl-2"
                                                : color === "yellow"
                                                  ? active
                                                      ? "border-l border-warning-quin pl-2"
                                                      : "border-l border-grey-secondary pl-2"
                                                  : ""
                                            : ""
                                    } ` +
                                    `${isSelected && color === "red" ? "text-error" : ""} ` +
                                    `${active && color === "red" ? "text-error" : ""} ` +
                                    `${isSelected && color === "red" ? "text-warning" : ""} ` +
                                    `${active && color === "red" ? "text-warning" : ""} ` +
                                    `mt-0.5 ${size === "xs" ? "h-2.5 w-2.5" : "ml-2 h-3.5 w-3.5"} ${size === "2xs" ? "!mt-0" : ""} `
                                }
                            >
                                <div
                                    className={
                                        `flex transform cursor-pointer items-center justify-center text-lg transition-transform duration-150 ease-in-out ` +
                                        `${!isSelected ? "rotate-0" : ""} ` +
                                        `${!active ? "rotate-180" : ""} ` +
                                        `${isFilter ? "!rotate-0" : ""} `
                                    }
                                >
                                    {!isFilter && <GoogleIcon icon="keyboard_arrow_up" />}
                                    {isFilter && <GoogleIcon icon="filter_list" />}
                                </div>
                            </div>
                        )}

                        {!isLoading && !isSelected && isFilterOpen && (
                            <span
                                className={
                                    `flex transform cursor-pointer items-center justify-center text-lg transition-transform duration-150 ease-in-out ` +
                                    `${!isSelected ? "rotate-0" : ""} ` +
                                    `${isSelected && color === "red" ? "text-error" : ""} ` +
                                    `${active ? "-rotate-180" : ""} ` +
                                    `${active && color === "red" ? "text-error" : ""} ` +
                                    `mt-0.5 max-w-[20px] ${size === "xs" ? "h-2.5 w-2.5" : "ml-2 h-3.5 w-3.5"} `
                                }
                            >
                                <GoogleIcon icon="close" />
                            </span>
                        )}
                    </div>
                </>
            )}

            <div
                className={
                    `absolute z-60 w-full transform bg-white shadow transition-none duration-300 ease-in-out ` +
                    `${!noOverflow ? "overflow-auto" : ""} ` +
                    `${positionTop ? "bottom-full" : "top-full"} ` +
                    `${placement !== "center" ? "min-w-max" : ""} ` +
                    `${placement === "right" ? `right-0 rounded-lg ${positionTop ? "mb-1 origin-bottom-right" : "mt-1 origin-top-right"}` : ""} ` +
                    `${placement === "center" ? `left-0 rounded-b-lg ${positionTop ? "origin-bottom" : "origin-top"}` : ""} ` +
                    `${placement !== "center" && placement !== "right" ? `left-0 ${positionTop ? "mb-1 origin-bottom-left rounded-b-lg" : "mt-1 origin-top-left rounded-lg"}` : ""} ` +
                    `${filled ? "min-w-12" : ""} ` +
                    `${icon || !outline ? "min-w-12 rounded-lg" : ""} ` +
                    `${active ? "scale-100 opacity-100" : "pointer-events-none scale-0 opacity-0"} ` +
                    `${fitDropdown ? "max-h-fit" : ""} `
                }
            >
                {isSearchable && handleChangeSearchTerm && (
                    <div className="flex h-14 w-full items-center justify-start px-4">
                        {/* <SearchBar placeholder={searchPlaceholder} value={searchTerm} onChange={handleChangeSearchTerm} /> */}
                    </div>
                )}
                <div
                    className="w-full [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary"
                    onClick={() => {
                        if (clickAndClose) {
                            setActive((prev) => !prev);
                        }
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default DropdownHead;
