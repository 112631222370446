// import BankTransfer from "../../../../assets/svg/bank-transfer.svg";
// import CardPayment from "../../../../assets/svg/card-payment.svg";
// import MobileMoney from "../../../../assets/svg/mobile-money.svg";

export enum AddMoneyFundingStageType {
    INITIAL = 1,
    PAYMENT_DETAILS = 2,
    MAKE_PAYMENT = 3,
    PROCESS_PAYMENT = 4,
}

export enum AddMoneyFundingType {
    MOBILE_MONEY = "mobile-money",
    // CARD_PAYMENT = "card-payment",
    BANK_TRANSFER = "bank-transfer",
}

export enum AddMoneyPhase {
    ACCOUNT_AND_METHOD = 1,
    FUNDING = 2,
}

export interface AddMoneyState {
    payTo: string;
    payFrom: string;
    pageFrom: string;
    selectedId: string | null;
    fundingType: AddMoneyFundingType | null;
    fundingStage: AddMoneyFundingStageType;
    addMoneyPhase: AddMoneyPhase;
}

export enum AccountModalStageType {
    INITIAL = 0,
    DETAILS = 1,
    ACTIVATE = 2,
}

export enum ZambiaAddMoneyModalSections {
    ACCOUNT_AND_METHOD = 1,
    FUNDING = 2,
}

export enum ZambiaFundingMethod {
    BANK_TRANSFER = 1,
    MOBILE_MONEY = 2,
    CARD_PAYMENT = 3,
}

export const AddMoneyFundingTypes = [
    {
        id: AddMoneyFundingType.MOBILE_MONEY,
        name: "Mobile Money",
        icon: "",
        // icon: MobileMoney,
        link: "/payments/add-money/make/mobile-money",
    },
    // {
    // id: AddMoneyFundingType.CARD_PAYMENT,
    // name: "Card Payment",
    // icon: CardPayment,
    // link: "/payments/add-money/make/card-payment",
    // },
    {
        id: AddMoneyFundingType.BANK_TRANSFER,
        name: "Bank Transfer",
        icon: "",
        // icon: BankTransfer,
        link: "/payments/add-money/make/bank-transfer",
    },
];

export const AddMoneyFundingLink = {
    [AddMoneyFundingType.MOBILE_MONEY]: {
        link: "/payments/add-money/make/mobile-money",
    },
    // [AddMoneyFundingType.CARD_PAYMENT]: {
    // link: "/payments/add-money/make/card-payment",
    // },
    [AddMoneyFundingType.BANK_TRANSFER]: {
        link: "/payments/add-money/make/bank-transfer",
    },
};
