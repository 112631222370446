import React, { useRef } from "react";

import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import LencoSpinner from "../../../../../../../../../../components/spinner";
import upload from "../../../../../../../../../../assets/svg/LencoPay/upload.svg";

interface Props {
    logo: string;
    isRemoving: boolean;
    isDisabled: boolean;
    isUploading: boolean;
    isImgLoading: boolean;
    handleUploadImage: (file: File) => void;
    handleRemoveImage: () => void;
}

function ImageUpload(props: Props): JSX.Element {
    const imageInputRef = useRef<HTMLInputElement | null>(null);

    const onFileChange = (_files: File | null) => {
        if (_files) {
            props.handleUploadImage(_files);
        }
    };

    return (
        <>
            <div className="flex w-full flex-col items-start justify-between space-y-2 pt-4 lg:flex-row">
                <div className="w-full">
                    <h5 className="text-base font-medium capitalize text-black">Upload Logo</h5>
                </div>
                <div className="w-full max-w-md space-y-0.5 rounded-md bg-grey-backdrop p-2 px-3 lg:px-0">
                    {props.isImgLoading ? (
                        <div className="flex h-24 w-full items-center justify-center">
                            <LencoSpinner />
                        </div>
                    ) : (
                        <>
                            <div className="flex w-full items-center justify-start space-x-6">
                                <img
                                    src={props.logo || upload}
                                    className="w-24 cursor-pointer"
                                    onClick={() => imageInputRef.current && imageInputRef.current.click()}
                                />

                                <div className="space-y-6">
                                    <div>
                                        <p className="text-sm text-black-tertiary">Upload your business logo Supported: SVG, PNG, or JPG.</p>
                                    </div>
                                    <div className="hidden space-x-4 2xs:flex">
                                        <ButtonComp
                                            type="button"
                                            buttonType="secondary"
                                            color="grey"
                                            size="sm"
                                            disable={props.isDisabled}
                                            isLoading={props.isUploading}
                                            onClick={() => imageInputRef.current && imageInputRef.current.click()}
                                        >
                                            <span>{props.logo ? "Change" : "Upload"}</span>
                                        </ButtonComp>

                                        <ButtonComp
                                            buttonType="secondary"
                                            color="red"
                                            size="sm"
                                            disable={props.isDisabled}
                                            isLoading={props.isRemoving}
                                            onClick={props.handleRemoveImage}
                                        >
                                            <span>Remove</span>
                                        </ButtonComp>
                                        <input
                                            type="file"
                                            ref={imageInputRef}
                                            disabled={props.isDisabled}
                                            className="pointer-events-none h-0 w-0 opacity-0"
                                            onChange={(event) => onFileChange(event.target.files && event.target.files[0])}
                                            accept="image/jpeg, image/png"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex w-full flex-col space-y-4 2xs:hidden 2xs:flex-row 2xs:space-x-4 2xs:space-y-0">
                                <LencoButton
                                    type="button"
                                    variant="bordered"
                                    size="sm"
                                    isDisabled={props.isDisabled}
                                    onClick={() => imageInputRef.current && imageInputRef.current.click()}
                                    isLoading={props.isUploading}
                                    fullWidth
                                >
                                    <span>{props.logo ? "Change" : "Upload"}</span>
                                </LencoButton>
                                <LencoButton
                                    variant="bordered"
                                    size="sm"
                                    isDisabled={props.isDisabled}
                                    isLoading={props.isRemoving}
                                    onClick={props.handleRemoveImage}
                                    fullWidth
                                >
                                    <span>Remove</span>
                                </LencoButton>
                                <input
                                    type="file"
                                    ref={imageInputRef}
                                    disabled={props.isDisabled}
                                    className="pointer-events-none h-0 w-0 opacity-0"
                                    onChange={(event) => onFileChange(event.target.files && event.target.files[0])}
                                    accept="image/jpeg, image/png"
                                />
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default ImageUpload;
