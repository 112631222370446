import {
    setCanVerifyRecipientAccountDetails,
    setTransferDetailRecipientBankCode,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";

import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseBulkTransferRecipientBankCodeInterface {
    handleSelectBank: (_bankAccountCode: string) => void;
}

function useBulkTransferRecipientBankCode(): UseBulkTransferRecipientBankCodeInterface {
    const dispatch = useDispatch();

    const handleSelectBank = useCallback((_bankAccountCode: string) => {
        dispatch(setTransferDetailRecipientBankCode(_bankAccountCode));
        dispatch(setCanVerifyRecipientAccountDetails(true));
    }, []);

    return { handleSelectBank };
}

export default useBulkTransferRecipientBankCode;
