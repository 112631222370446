import {
    ZambiaApplicationSectionType,
    ZambiaApplicationStagesType,
    ZambiaRegisteredSignatoryStages,
} from "../../../../../services/zambia-application/zambia-application.types";

import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { GenericApplicationPageType } from "../../../../../services/application.constants";
import LencoSpinner from "../../../../../../../../components/spinner";
import MeansOfId from "./components/means-of-id";
import PersonalDetails from "./components/personal-details";
import Personnel from "./components/personnel";
import Photograph from "./components/photograph";
import SignatoriesDetails from "./components/signatories";
import useAccountSignatoriesInit from "../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useAccountSignatoriesInit";
import { useLayoutEffect } from "react";
import { useParams } from "react-router";

type ApplicationUrlParams = {
    stage: ZambiaApplicationStagesType | undefined;
    section: ZambiaApplicationSectionType | GenericApplicationPageType;
};

function AccountSignatories(): JSX.Element {
    const { stage } = useParams<ApplicationUrlParams>();

    // const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    const {
        currentSignatoryError,
        accountSignatoriesError,
        isCurrentSignatoryLoading,
        isAccountSignatoriesLoading,
        handleReset,
        handleCurrentSignatoryInit,
        handleAccountSignatoriesInit,
    } = useAccountSignatoriesInit();

    useLayoutEffect(() => {
        void handleCurrentSignatoryInit();
        void handleAccountSignatoriesInit();
        return () => {
            handleReset();
        };
    }, []);

    // useEffect(() => {
    // if(accountOpening){
    // accountOpening.progress.;
    // }
    // }, [accountOpening]);

    return (
        <>
            {(isCurrentSignatoryLoading || isAccountSignatoriesLoading) && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {(currentSignatoryError || accountSignatoriesError) && (
                <div className="flex h-full w-full items-start justify-center">
                    <ErrorToast error={currentSignatoryError || accountSignatoriesError} />
                </div>
            )}
            {!(isCurrentSignatoryLoading || isAccountSignatoriesLoading) && !(currentSignatoryError || accountSignatoriesError) && (
                <div className="flex h-full w-full flex-row items-start justify-start">
                    {stage === ZambiaRegisteredSignatoryStages.SIGNATORY_PERSONNEL && <Personnel />}
                    {!stage && <SignatoriesDetails />}

                    {stage === ZambiaRegisteredSignatoryStages.SIGNATORY_PERSONAL_DETAILS && <PersonalDetails />}
                    {stage === ZambiaRegisteredSignatoryStages.SIGNATORY_MEANS_OF_ID && <MeansOfId />}
                    {stage === ZambiaRegisteredSignatoryStages.SIGNATORY_PHOTOGRAPH && <Photograph />}
                </div>
            )}
        </>
    );
}

export default AccountSignatories;
