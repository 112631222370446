import { AllNotificationTypes, NotificationTypeText } from "../../../../../../models/userAccount.constants";

import React from "react";
import TeamMemberDropdown from "./TeamMemberDropdown";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import useTeamMemberNotificationType from "../../../Services/team-members/hooks/useTeamMemberNotificationType";

interface TeamMemberNotificationTypeProps {
    accountMeta: UserAccountMeta;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    canUpdate: boolean;
}

function TeamMemberNotificationType({ accountMeta, updateAccountMeta, canUpdate }: TeamMemberNotificationTypeProps): JSX.Element {
    const { isSubmitting, handleUpdateNotificationType } = useTeamMemberNotificationType(updateAccountMeta);

    return (
        <div>
            <TeamMemberDropdown
                canUpdate={canUpdate}
                text={NotificationTypeText[accountMeta.notificationType]}
                currentValue={accountMeta.notificationType}
                isSubmitting={isSubmitting}
                onDropdownItemSelect={(notificationType) => void handleUpdateNotificationType(accountMeta, notificationType)}
                options={AllNotificationTypes.map((notificationType) => ({
                    value: notificationType,
                    text: NotificationTypeText[notificationType],
                }))}
            />
        </div>
    );
}

export default TeamMemberNotificationType;
