import React, { useCallback, useEffect } from "react";
import {
    resetAllZambiaCollectionsSettlementsData,
    setIsExportZambiaCollectionsSettlementsTrayOpen,
    setIsZambiaCollectionsSettlementsDetailCardOpen,
    setSelectedZambiaCollectionsSettlementsDetails,
    setSelectedZambiaCollectionsSettlementsFilterStateInstant,
} from "../../../../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";

import CollectionSettlementTray from "./Tray/CollectionSettlementTray";
import ExportZambiaCollectionsSettlementsTray from "./Tray/ExportZambiaCollectionsSettlementsTray";
// import FilterSection from "./FilterSection/FilterSection";
import { SubTextType } from "../../../../../../components/Table/Type/SubTextType";
import Table from "../../../../../../components/Table";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import { ZambiaCollectionsStageType } from "../../../../../../redux/zambia/collections/collectionsSlice.types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useZambiaCollectionsSettlements from "../../../hookss/Collections/useZambiaCollectionsSettlement";
import GoogleIcon from "../../../../../../components/google-icon";
import ButtonComp from "../../../../../../components/button/ButtonComp";

export const COLLECTION_SETTLEMENT_DATATYPE = "";

const allCollectionSettlementsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "From", pos: 2, headType: TableColumnType.TEXT },
    { text: "Channel", pos: 3, headType: TableColumnType.TEXT },
    { text: "Channel Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Transaction Amount", pos: 5, headType: TableColumnType.AMOUNT },
    { text: "Settlement Amount", pos: 6, headType: TableColumnType.AMOUNT },
];

export type ZambiaCollectionSettlementsParams = {
    type: string;
    query: string;
};

function ZambiaCollectionSettlements(): JSX.Element {
    const dispatch = useDispatch();
    const { type, query } = useParams<ZambiaCollectionSettlementsParams>();

    const {
        filterOption,
        handleClearFilter,
        filter,
        zambiaCollectionsSettlementsList,
        zambiaCollectionsSettlementsListTotal,
        zambiaCollectionsSettlementsListGroupSize,
        zambiaCollectionsSettlementsPaginationOffset,
        isZambiaCollectionsSettlementsPaginationLoading,
        // handleClearAll,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsSettlements,
    } = useZambiaCollectionsSettlements();

    const onExport = useCallback(() => {
        dispatch(setIsExportZambiaCollectionsSettlementsTrayOpen(true));
    }, []);

    useEffect(() => {
        if (!type || type !== ZambiaCollectionsStageType.SETTLEMENTS) return;

        if (query) {
            dispatch(setSelectedZambiaCollectionsSettlementsFilterStateInstant({ offset: 0, query: query || "" }));
        }
        void handleFilteredZambiaCollectionsSettlements({ offset: 0, query: query || "" });
    }, [query]);

    useEffect(() => {
        void handleFilteredZambiaCollectionsSettlements({ offset: 0, query: "" });
        return () => {
            dispatch(resetAllZambiaCollectionsSettlementsData());
        };
    }, []);

    return (
        <>
            <CollectionSettlementTray />
            <ExportZambiaCollectionsSettlementsTray />

            <div className="relative mt-4 flex h-full w-full flex-col items-start justify-start">
                <div className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-start">
                    <Table
                        addExtraButton
                        extraButton={
                            <ButtonComp func={onExport} size="md" buttonType="secondary" ripple="dark" color={"white"}>
                                <span className="flex text-black-tertiary">
                                    <GoogleIcon icon="download" size="sm" /> Export
                                </span>
                            </ButtonComp>
                        }
                        addFilter
                        totalSelectedItems={
                            (filter?.channel ? 1 : 0) + (filter?.date?.begin ? 1 : 0) + (filter?.status ? 1 : 0) + (filter?.settlementType ? 1 : 0)
                        }
                        filter={filterOption}
                        handleClearFilter={handleClearFilter}
                        dataType={COLLECTION_SETTLEMENT_DATATYPE}
                        heads={allCollectionSettlementsHeader}
                        isPaginateLoading={isZambiaCollectionsSettlementsPaginationLoading}
                        groupSize={zambiaCollectionsSettlementsListGroupSize}
                        offset={zambiaCollectionsSettlementsPaginationOffset}
                        total={zambiaCollectionsSettlementsListTotal}
                        isLoading={!!isZambiaCollectionsSettlementsPaginationLoading}
                        paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                        rows={
                            zambiaCollectionsSettlementsList?.map((obj) => ({
                                onRecordClick: () => {
                                    dispatch(setIsZambiaCollectionsSettlementsDetailCardOpen(true));
                                    dispatch(setSelectedZambiaCollectionsSettlementsDetails(obj.id));
                                },
                                record: [
                                    {
                                        key: "Date",
                                        text: "45",
                                        textType: TextType.DATE,
                                        subText: obj?.createdAt,
                                        subTextType: SubTextType.TIME,
                                    },
                                    {
                                        key: "From",
                                        text: obj.mobileMoneyCollection?.debitAccountName ?? (obj.cardPaymentCollection?.cardDetails?.name || "-"),
                                    },
                                    {
                                        key: "Channel",
                                        text: obj?.channelText,
                                    },
                                    {
                                        key: "Channel Type",
                                        text:
                                            obj.mobileMoneyCollection?.serviceProviderText ||
                                            obj?.cardPaymentCollection?.cardDetails?.cardType ||
                                            "-",
                                    },
                                    {
                                        key: "Transaction Amount",
                                        text: obj.collectionRequest?.amount,
                                        textType: TextType.BALANCE,
                                        currency: obj.collectionRequest?.currency,
                                    },
                                    {
                                        key: "Settlement Amount",
                                        text: obj.settlementAmount,
                                        textType: TextType.BALANCE,
                                        currency: obj.currency,
                                    },
                                ],
                            })) || []
                        }
                    />
                </div>
            </div>
        </>
    );
}

export default ZambiaCollectionSettlements;
