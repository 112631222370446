import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import ErrorCard from "../../Cards/send-money-error-card";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../components/message-toast";
import MoneyInput from "../../../../../../components/inputs/money-input";
import NewAllAccountDropdown from "../../../../../../components/all-account-dropdown";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import TransferBetweenAccountPaymentHeaders from "./TransferBetweenAccountPaymentHeaders";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import { resetAllTransferBetweenData } from "../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import { setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { useCallback } from "react";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";
import { useNavigate } from "react-router";
import useTransferBetweenAccountNumberAutocomplete from "../../../hooks/state/send-money/TransferBetween/useTransferBetweenAccountNumberAutocomplete";
import useTransferBetweenAmount from "../../../hooks/state/send-money/TransferBetween/useTransferBetweenAmount";
import useTransferBetweenPayFrom from "../../../hooks/state/send-money/TransferBetween/useTransferBetweenPayFrom";
import useTransferBetweenPurpose from "../../../hooks/state/send-money/TransferBetween/useTransferBetweenPurpose";

// import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);
    const pageFrom = useSelector((state: IRootState) => state.sendMoney.pageFrom);
    // const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);
    const selectedCard = useSelector((state: IRootState) => state.transferBetween.selectedCard);
    const cardBalances = useSelector((state: IRootState) => state.transferBetween.cardBalances);
    const selectedUserAccount = useSelector((state: IRootState) => state.transferBetween.selectedUserAccount);
    const recipientBankAccount = useSelector((state: IRootState) => state.transferBetween.recipientBankAccount);
    const transferBetweenDetails = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails);

    const { handleAmountChange } = useTransferBetweenAmount();
    const { handlePurposeChange } = useTransferBetweenPurpose();
    const { getPayToOptions, handleSelectRecipient } = useTransferBetweenAccountNumberAutocomplete();
    const { getPayFromOptions, currentUserAccountMeta, isCardBalanceLoading, handleSelectAccount } = useTransferBetweenPayFrom();

    const isCannotSend = selectedUserAccount?.accountRestrictions.cannotSendMoney;
    const isOnlySendToSpecificAccounts = selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts;

    const cardBalance = cardBalances.get(selectedCard?.createRequestId || "");
    const accountBalance = selectedUserAccount?.balance;

    const isInsufficientFunds =
        selectedUserAccount && !isNullOrUndefined(accountBalance) ? useMoneyToNumber(transferBetweenDetails.amount) > accountBalance : false;
    const isInsufficientCardFunds =
        selectedCard && !isNullOrUndefined(cardBalance) ? useMoneyToNumber(transferBetweenDetails.amount) > cardBalance : false;

    const handleBack = useCallback(() => {
        if (pageFrom && payTo) {
            navigate(
                {
                    pathname: `/${pageFrom}`,
                },
                {
                    state: {
                        cardId: payTo,
                    },
                }
            );
        }
        dispatch(setPaymentStage(PaymentStageType.INITIAL));
        dispatch(resetAllTransferBetweenData());
    }, []);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <TransferBetweenAccountPaymentHeaders />
                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Pay from</p>
                        <NewAllAccountDropdown
                            value={selectedCard || selectedUserAccount || null}
                            options={getPayFromOptions || null}
                            onClick={handleSelectAccount}
                            isDisabled={isCannotSend}
                            dropdownSize="xl"
                            showBalance
                        />
                    </div>

                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                        <MoneyInput
                            label="Amount"
                            value={transferBetweenDetails.amount || ""}
                            onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                            inputSize="xl"
                        />
                        <Input placeholder="Purpose" value={transferBetweenDetails.purpose} onChange={handlePurposeChange} inputSize="xl" fullWidth />
                    </div>

                    {isCannotSend && <MessageToast message="You can not send money from this account" type="error" fullWidth />}
                    {isOnlySendToSpecificAccounts && <MessageToast message="You can only send to specific recipients" type="info" fullWidth />}
                    {currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
                        <ErrorCard maxApprovalAmount={currentUserAccountMeta?.maxApprovalAmount} isApprovalError fullWidth />
                    )}
                    {isInsufficientFunds && selectedUserAccount && !isNullOrUndefined(accountBalance) && (
                        <ErrorCard balance={accountBalance} isInsufficientFunds fullWidth />
                    )}
                    {isInsufficientCardFunds && selectedCard && !isNullOrUndefined(cardBalance) && (
                        <ErrorCard balance={cardBalance} isInsufficientFunds fullWidth />
                    )}

                    {!isCannotSend && (
                        <div className="flex w-full flex-col space-y-4">
                            <p className="text-sm font-medium text-black-secondary">{isOnlySendToSpecificAccounts ? "Select Recipient" : "Pay To"}</p>
                            <NewAllAccountDropdown
                                value={recipientBankAccount || null}
                                options={getPayToOptions || null}
                                onClick={handleSelectRecipient}
                                showBalance
                            />
                        </div>
                    )}
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="xl" func={handleBack}>
                        Back
                    </ButtonComp>

                    <ButtonComp
                        size="xl"
                        color="black"
                        buttonType="primary"
                        func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
                        disable={
                            isCannotSend ||
                            isInsufficientFunds ||
                            isCardBalanceLoading ||
                            !recipientBankAccount ||
                            isInsufficientCardFunds ||
                            !transferBetweenDetails.amount ||
                            !transferBetweenDetails.purpose ||
                            useMoneyToNumber(transferBetweenDetails.amount) === 0
                        }
                    >
                        Review Payment
                    </ButtonComp>
                </div>
            </div>
            {/* <div className="flex w-full flex-col items-center justify-center lg:items-start lg:justify-start">
                <div className="w-full max-w-sm">
                    <TransferBetweenAccountPaymentHeaders />
                    <div className="w-full">
                        <div className="flex w-full flex-col justify-between">
                            <div className="flex min-h-72 flex-col items-start space-y-4 pt-2 text-base font-normal
                            md:items-center md:px-0 lg:items-center lg:px-0">
                                <div className="flex w-full max-w-sm flex-col space-y-4">
                                    <MoneyInput
                                        placeholder="Amount"
                                        icon={<CurrencyCode />}
                                        value={transferBetweenDetails.amount || ""}
                                        onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                                    />
                                    <div className="w-full">
                                        <AccountNumberDropdown
                                            data={getPayFromOptions}
                                            inputValue={selectedCard?.name || selectedUserAccount?.accountName || ""}
                                            placeholder="Pay from"
                                            onClickFunc={(e) => handleSelectAccount(e.id)}
                                            isSearchable={!!getPayFromOptions && getPayFromOptions.length > 4}
                                            isLoading={isCardBalanceLoading}
                                            isInputDisabled
                                            clickAndClose
                                            showBalance
                                            isPayFrom
                                            showArrow
                                        />
                                        {selectedUserAccount && !isNullOrUndefined(accountBalance) && (
                                            <div className="w-full pt-2 text-left text-xs text-black-tertiary">
                                                Available Balance: <Money amount={accountBalance} />
                                            </div>
                                        )}
                                        {selectedCard && !isNullOrUndefined(cardBalance) && (
                                            <div className="w-full pt-2 text-left text-xs text-black-tertiary">
                                                Available Balance: <Money amount={cardBalance} />
                                            </div>
                                        )}
                                    </div>

                                    {isOnlySendToSpecificAccounts && <MessageToast message="You can only send to specific recipients" type="info" />}
                                    {isCannotSend && <MessageToast message="You can not send money from this account" type="error" />}
                                    <AccountNumberDropdown
                                        data={getPayToOptions}
                                        inputValue={recipientBankAccount?.accountName || ""}
                                        placeholder={isOnlySendToSpecificAccounts ? "Select Recipient" : "Pay To"}
                                        onClickFunc={(e) => handleSelectRecipient(e.id)}
                                        isSearchable={!!getPayToOptions && getPayToOptions.length > 4}
                                        isDisabled={isCannotSend}
                                        isInputDisabled
                                        clickAndClose
                                        showArrow
                                    />
                                    <TextArea
                                        placeholder="Purpose of payment"
                                        value={transferBetweenDetails.purpose}
                                        onChangeFunc={handlePurposeChange}
                                    />
                                </div>
                                {currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
                                    <ErrorCard maxApprovalAmount={currentUserAccountMeta?.maxApprovalAmount} isApprovalError />
                                )}
                                {isInsufficientFunds && selectedUserAccount && !isNullOrUndefined(accountBalance) && (
                                    <ErrorCard balance={accountBalance} isInsufficientFunds />
                                )}
                                {isInsufficientCardFunds && selectedCard && !isNullOrUndefined(cardBalance) && (
                                    <ErrorCard balance={cardBalance} isInsufficientFunds />
                                )}
                            </div>

                            <div className="flex w-full flex-row items-center justify-center space-x-4 pb-8 pt-8">
                                <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={handleBack}>
                                    <span>Back</span>
                                </ButtonComp>

                                <ButtonComp
                                    type="submit"
                                    color="blue"
                                    ripple="light"
                                    buttonType="primary"
                                    disable={
                                        isCannotSend ||
                                        isInsufficientFunds ||
                                        isCardBalanceLoading ||
                                        !recipientBankAccount ||
                                        isInsufficientCardFunds ||
                                        !transferBetweenDetails.amount ||
                                        !transferBetweenDetails.purpose ||
                                        useMoneyToNumber(transferBetweenDetails.amount) === 0
                                    }
                                    func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
                                >
                                    <span>Next</span>
                                </ButtonComp>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default PaymentDetails;
