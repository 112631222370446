import React from "react";
import TerminalArrived from "../../../../../../assets/svg/Terminal/terminal-arrived.svg";
import { ReactComponent as TerminalArrivedStepper } from "../../../../../../assets/svg/Terminal/terminal-arrived-stepper.svg";

interface Props {
    sm?: boolean;
    isTray?: boolean;
}

function TerminalArrivedCard(props: Props): JSX.Element {
    return (
        <>
            {!props.sm && (
                <div className="flex flex-col items-start justify-start space-y-5 px-6 py-4">
                    <div className="flex flex-col items-start justify-start space-y-3">
                        <div className="flex items-center justify-start space-x-2">
                            <img src={TerminalArrived} alt="mail box" />
                            <span className="text-base font-medium text-black-secondary md:text-lg">Your Terminal is here</span>
                        </div>
                        <TerminalArrivedStepper />
                    </div>
                    <div className="flex flex-col">
                        <span className="text-xs font-normal text-black-tertiary md:text-sm">
                            Your terminal is ready for use, start receiving card payment at your outlet
                        </span>
                    </div>
                </div>
            )}
            {props.sm && (
                <div className={"flex flex-col items-start justify-start " + `${props.isTray ? "space-y-4" : "space-y-1"}`}>
                    <div className="flex items-center justify-start space-x-2">
                        <img src={TerminalArrived} alt="mail box" />
                        <span className="text-base font-medium text-black-secondary">Your Terminal is here</span>
                    </div>
                    {props.isTray && <TerminalArrivedStepper />}
                    <span className={"text-sm font-normal " + `${props.isTray ? "text-black-secondary" : "text-black-tertiary"}`}>
                        Your terminal is ready for use, start receiving card payment at your outlet
                    </span>
                </div>
            )}
        </>
    );
}

export default TerminalArrivedCard;
