import { setZambiaSingleTransferOtp } from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseSingleTransferOtpInterface {
    handleOtpChange: (_amount: string) => void;
}

function useSingleTransferOtp(): UseSingleTransferOtpInterface {
    const dispatch = useDispatch();

    const handleOtpChange = useCallback((_otp: string) => {
        dispatch(setZambiaSingleTransferOtp(_otp));
    }, []);

    return { handleOtpChange };
}

export default useSingleTransferOtp;
