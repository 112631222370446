import { useCallback, useState } from "react";

import { RemoveZambiaApiAccessKeyLogoRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { removeZambiaApiAccessKeyLogoApi } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch } from "react-redux";

export interface RemoveZambiaApiAccessKeyLogoInterface {
    isRemoveZambiaApiAccessKeyLogoLoading: boolean;
    removeZambiaApiAccessKeyLogoErrorMessage: string;
    handleRemoveZambiaApiAccessKeyLogo: (_data: RemoveZambiaApiAccessKeyLogoRequest) => void;
}

// interface Props {
// onComplete: () => void;
// }

function useRemoveZambiaApiAccessKeyLogo(): RemoveZambiaApiAccessKeyLogoInterface {
    const dispatch = useDispatch();
    const [isRemoveZambiaApiAccessKeyLogoLoading, setIsRemoveZambiaApiAccessKeyLogoLoading] = useState(false);
    const [removeZambiaApiAccessKeyLogoErrorMessage, setRemoveZambiaApiAccessKeyLogoErrorMessage] = useState<string>("");

    const handleRemoveZambiaApiAccessKeyLogo = useCallback(
        async (_data: RemoveZambiaApiAccessKeyLogoRequest) => {
            try {
                setIsRemoveZambiaApiAccessKeyLogoLoading(true);
                setRemoveZambiaApiAccessKeyLogoErrorMessage("");
                const res = await removeZambiaApiAccessKeyLogoApi(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key logo removed successfully."));
                // props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRemoveZambiaApiAccessKeyLogoErrorMessage(errorMessage);
            } finally {
                setIsRemoveZambiaApiAccessKeyLogoLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isRemoveZambiaApiAccessKeyLogoLoading,
        removeZambiaApiAccessKeyLogoErrorMessage,
        handleRemoveZambiaApiAccessKeyLogo,
    };
}

export default useRemoveZambiaApiAccessKeyLogo;
