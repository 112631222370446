export const SliderSettings = {
    infinite: true,
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToScroll: 1,
    speed: 500,
    pauseOnHover: true,
};
