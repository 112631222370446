import { AccountBalanceAlertType } from "../../../models/userAccount.constants";

export interface SettingsSecurityState {
    isChangePasswordModal1: boolean;
    isSendMoneyRuleTray: boolean;
    specificAccounts: SpecificAccountsType[];
    selectedAcc: string;
    isSelectedAccRestricted: boolean;
    accountSettings: AccountSettingsEnum;
    minBalanceAmount: string;
    excessBalanceAmount: string;
    mockAccs: mockAccountsType[];
    isBalanceInstructionTray: boolean;
    isEditMinimumBalance: boolean;
    isEditExcessBalance: boolean;
    balanceType: AccountBalanceAlertType;
    isSplitInflowScreen: boolean;
}

export type SpecificAccountsType = {
    text: string;
    value: string;
};

export enum AccountSettingsEnum {
    ACCOUNT_RESTRICITON = "account_restriction",
    BALANCE_INSTRUCTION = "balance_instruction",
    SPLIT_INFLOW_INSTRUCTION = "split_inflow_instruction",
}

export type mockAccountsType = { text: string; value: string; restricted?: boolean; splitInflowPercentage?: number };
