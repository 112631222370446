import { IRootState } from "../../../../../../redux/rootReducer";
import PaymentHeader from "../../Layouts/payment/components/page-header";
import { TransferBetweenAccountSections } from "../../../hooks/state/send-money/payments.constants";
import { useSelector } from "react-redux";

function TransferBetweenAccountPaymentHeaders(): JSX.Element {
    const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);
    return (
        <PaymentHeader header={TransferBetweenAccountSections[paymentStage].text} subtitle={TransferBetweenAccountSections[paymentStage].subtext} />
    );
}

export default TransferBetweenAccountPaymentHeaders;
