import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActivateAccount from "../Modals/ActivateAccountModal";
import { ActivationStatus } from "../../../../../models/userAccount.constants";
import AddFundsCard from "./AddFundsCard";
import { ReactComponent as BlueRightIcon } from "../../../../../assets/svg/blueRightIcon.svg";
import CurrencyCode from "../../../../../components/currency-code";
import { IRootState } from "../../../../../redux/rootReducer";
import LineDropdown from "../Dropdowns/LineDropdown";
import UserAccount from "../../../../../models/userAccount";
import formatNumber from "../../../../../utils/formatNumber";
import { mainUpdateUserAccountCallback } from "../../../../../redux/init/slice/initSlice";
import { setIndividualAccount } from "../../../../../redux/accounts/slice/accountsSlice";
import useActivateAccountState from "../../Hooks/State/useActivateAccountState";
import { useNavigate } from "react-router-dom";

interface AccountCardProps {
    data: UserAccount;
}
function AccountCard(props: AccountCardProps): JSX.Element {
    const { data } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleReset } = useActivateAccountState();

    const accountType = useSelector((state: IRootState) => state.init.main?.meta.accountTypes.find((t) => t.type === data.type));

    const [showActiveAccountModal, setShowActiveAccountModal] = useState<boolean>(false);
    const [individualData, setIndividualData] = useState<UserAccount | null>(null);

    const handleAccountActivationCompleted = useCallback((account: UserAccount) => {
        dispatch(
            mainUpdateUserAccountCallback({
                id: account.id,
                callback: (userAccount: UserAccount) => {
                    userAccount.activationStatus = ActivationStatus.ACTIVATED;
                },
            })
        );
    }, []);

    return (
        <>
            <ActivateAccount
                active={showActiveAccountModal}
                data={individualData}
                toggler={() => setShowActiveAccountModal(false)}
                onCompleteFunc={(account) => handleAccountActivationCompleted(account)}
            />

            {data && (
                <div className="cursor-pointer" onClick={() => navigate(`/accounts/${data.id}`)}>
                    <div
                        className={`box-border w-full bg-white shadow ${
                            data.activationStatus === ActivationStatus.NOT_ACTIVATED || data.activationStatus === ActivationStatus.ONGOING
                                ? "rounded-b-none rounded-t-lg"
                                : "rounded-lg"
                        } relative`}
                    >
                        <div className="flex flex-col space-y-2 p-5" key={data.id}>
                            <div className="flex h-10 flex-row items-center justify-between">
                                {accountType && (
                                    <div className="bg-black-backdrop rounded-md border border-grey bg-grey-backdrop px-1.5 py-0.5 text-sm font-medium">
                                        <div className="text-black-secondary">{accountType.name}</div>
                                    </div>
                                )}
                                <div
                                    className="flex h-full items-center justify-center"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        dispatch(setIndividualAccount(data));
                                    }}
                                >
                                    <LineDropdown data={data} />
                                </div>
                            </div>
                            <div className="pt-2">
                                <div className="text-xs font-normal text-black-tertiary">Account Balance</div>
                                <div className="flex flex-row justify-start text-black-secondary">
                                    <div className="text-2xl font-medium">
                                        <CurrencyCode currency={data.bankAccountCurrency} />
                                        {formatNumber(data.balance || 0)}
                                    </div>
                                </div>
                            </div>
                            {data && <AddFundsCard userAccount={data} isCard />}
                            {(data.activationStatus === ActivationStatus.NOT_ACTIVATED || data.activationStatus === ActivationStatus.ONGOING) && (
                                <div
                                    onClick={(e) => {
                                        setShowActiveAccountModal(true);
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIndividualData(data);
                                        handleReset();
                                    }}
                                    className="flex items-center justify-end space-x-1 py-2 text-sm font-medium text-blue-secondary"
                                >
                                    <span>Activate Account</span>
                                </div>
                            )}
                            {!(data.activationStatus === ActivationStatus.NOT_ACTIVATED || data.activationStatus === ActivationStatus.ONGOING) && (
                                <div className="flex items-center justify-end space-x-1 py-2 text-sm font-medium text-blue-secondary">
                                    <span>Account Details</span> <BlueRightIcon className="" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AccountCard;
