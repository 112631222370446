import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountBalanceInstruction from "../../../../../../models/accountBalanceInstruction";
import { AccountBalanceInstructionType } from "../../../../../../models/userAccount.constants";
import AccountSettingsDetailsHeader from "../AccountSettingsDetailsHeader";
import { AccountSettingsSectionValue } from "../../../Services/account-settings/account-settings.constants";
import AccountSettingsSummaryItem from "../AccountSettingsSummaryItem";
import EditSplitInflowInstruction from "./EditSplitInflowInstruction";
import { IRootState } from "../../../../../../redux/rootReducer";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";
import { SplitInflowRatio } from "../../../Services/account-settings/account-settings.types";
import { ToastType } from "../../../../../../helpers/AppConstants";
import UserAccount from "../../../../../../models/userAccount";
import { classArrayToMap } from "../../../../../../utils/map";
import { createSelector } from "reselect";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import { mainUpdateUserAccountCallback } from "../../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { updateAccountBalanceInstructions } from "../../../Services/account-settings/account-settings.api";

export function getSummary(account: UserAccount, instruction: AccountBalanceInstruction, accountsMap: Map<string | number, UserAccount>): string[] {
    if (!instruction) {
        return [];
    }
    if (!instruction.ratio) {
        return [];
    }
    const keepRatio = instruction.ratio.find((r) => r.userAccountId === account.id);
    if (!keepRatio) {
        return [];
    }
    const res: string[] = [];
    res.push(`Keep ${keepRatio.percentage}% in ${accountsMap.get(keepRatio.userAccountId)?.lencoNameMin || ""} `);
    instruction.ratio.forEach((r) => {
        if (r.userAccountId !== account.id && accountsMap.has(r.userAccountId)) {
            res.push(`Send ${r.percentage}% to ${accountsMap.get(r.userAccountId)?.lencoNameMin || ""} `);
        }
    });
    return res;
}

const selectAccounts = (state: IRootState) => state.init.main?.companyDetails.accounts || [];
export const getAccountsMap = createSelector(selectAccounts, (accounts) => new Map(classArrayToMap(accounts)));

interface Props {
    account: UserAccount;
}

function SplitInflowInstruction({ account }: Props): JSX.Element {
    // const accountsMap = useSelector<IRootState, Map<string, UserAccount>>(
    //     (state) => new Map(classArrayToMap(state.init.main?.companyDetails.accounts || []))
    // );
    const accountsMap = useSelector(getAccountsMap);

    const [isEditMode, setIsEditMode] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [percentageInfo, setPercentageInfo] = useState<
        {
            name: string;
            percentage: string;
            type: string;
        }[]
    >();

    const dispatch = useDispatch();

    useEffect(() => {
        const percentageRatio = getSummary(account, account.balanceInstructions[0], accountsMap).map((rat) => rat.split(" ")[1]);
        const percentageType = getSummary(account, account.balanceInstructions[0], accountsMap).map((rat) => rat.split(" ")[0]);
        const accNames = getSummary(account, account.balanceInstructions[0], accountsMap).map((rat) => rat.split(" ")[rat.split(" ").length - 2]);
        const accInfo = accNames.map((info, index) => {
            return { name: info, percentage: percentageRatio[index], type: percentageType[index] };
        });
        setPercentageInfo(accInfo);
    }, [getSummary]);

    const handleUpdateSplitInflowInstruction = useCallback(
        async (ratio: SplitInflowRatio[] | null, setIsSubmitting: Dispatch<SetStateAction<boolean>>) => {
            try {
                setIsSubmitting(true);
                const res = await updateAccountBalanceInstructions(account.id, {
                    amount: null,
                    type: AccountBalanceInstructionType.SPLIT_INFLOW,
                    ratio: ratio,
                });
                dispatch(
                    mainUpdateUserAccountCallback({
                        id: res.userAccountId,
                        callback: (userAccount: UserAccount) => {
                            userAccount.accountRestrictions = res.restrictions;
                            userAccount.balanceInstructions = res.balanceInstructions;
                        },
                    })
                );
                dispatch(messageTrue({ message: "Instructions updated" }));
                setIsSubmitting(false);
                setIsEditMode(false);
            } catch (err) {
                dispatch(errorTrue({ message: getErrorMessage(err) }));
                setIsSubmitting(false);
            }
        },
        [account]
    );

    const handleRemoveInstruction = () => {
        void handleUpdateSplitInflowInstruction(null, setIsRemoving);
    };

    return (
        <div className="flex h-full w-full flex-col space-y-9">
            <div className="flex w-full flex-col md:px-8">
                <AccountSettingsDetailsHeader stage={AccountSettingsSectionValue.SPLIT_INFLOW} />
            </div>
            {accountsMap.size <= 1 && (
                <div className="w-full max-w-md md:pl-8">
                    <MessageToasts toastType={ToastType.INFORMATION} toastMessage="You can not set this instruction because have only one account" />
                </div>
            )}

            <div className="relative flex h-full w-full max-w-lg flex-col justify-start">
                <div className="absolute top-0 flex h-full w-full flex-col justify-start overflow-y-auto pt-2 md:px-8">
                    {!isEditMode && account.splitInflowInstruction && account.splitInflowInstruction.ratio && (
                        <AccountSettingsSummaryItem
                            account={account}
                            accountsMap={accountsMap}
                            handleUpdateSplitInflowInstruction={handleUpdateSplitInflowInstruction}
                            handleEditIconClick={() => setIsEditMode(true)}
                            canRemove
                            isRemoving={isRemoving}
                            handleRemoveIconClick={handleRemoveInstruction}
                            percentageInfo={percentageInfo}
                        />
                    )}

                    {/* {isEditMode && ( */}
                    {(isEditMode || !account.splitInflowInstruction) && (
                        <EditSplitInflowInstruction
                            account={account}
                            accountsMap={accountsMap}
                            canCancel={!!account.splitInflowInstruction}
                            // canCancel={true}
                            isDisableCancel={isNullOrUndefined(account.splitInflowInstruction)}
                            handleCancel={() => setIsEditMode(false)}
                            handleUpdateSplitInflowInstruction={handleUpdateSplitInflowInstruction}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SplitInflowInstruction;
