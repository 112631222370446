import {
    addTransferDetailToTransferList,
    resetTransferDetailRecipient,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { bulkUpload, downloadTemplate } from "../../../../services/send-money/bulkTransferApi";
import { messageTrue, resetAppToast } from "../../../../../../../redux/app-toast/app-toast-slice";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BankAccount from "../../../../../../../models/bankAccount";
import CustomerAccount from "../../../../../../../models/customerAccount";
import { ERROR } from "../../../../../../../helpers/AppConstants";
import { IRootState } from "../../../../../../../redux/rootReducer";
import { TransactionStatus } from "../../../../../../../models/transaction.constants";
import UserAccount from "../../../../../../../models/userAccount";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";

export interface BulkUploadResponse {
    success: boolean;
    message: string;
    data: Array<BulkPaymentItem>;
    customerAccounts: Array<CustomerAccount>;
    bankAccounts: Array<BankAccount>;
}
export type BulkPaymentItem = {
    originatingAccountId: string;
    recipient: {
        bankAccountId: string;
        customerAccountId: string;
    };
    amount: number;
    purpose: string;
    from: string;
    to: {
        accountNumber: string;
        bank: string;
    };
};

export interface UseBulkTransferUploadModalInterface {
    showUploadModal: boolean;
    isBulkUploadLoading: boolean;
    isDownloadTemplateLoading: boolean;

    handleBulkUpload: (_data: FormData) => Promise<void>;
    handleShowUploadModal: () => void;
    handleDownloadTemplate: () => Promise<void>;
    handleCloseShowUploadModal: () => void;
}

interface Props {
    onComplete: () => void;
}

function useBulkTransferUploadModal(props?: Props): UseBulkTransferUploadModalInterface {
    const dispatch = useDispatch();

    const fees = useSelector((state: IRootState) => state.init.main?.meta.transferCharges);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);

    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [isDownloadTemplateLoading, setIsDownloadTemplateLoading] = useState(false);

    // const [bulkUploadResponse, setBulkUploadResponse] = useState<BulkUploadResponse | null>(null);
    const [isBulkUploadLoading, setIsBulkUploadLoading] = useState(false);

    const handleShowUploadModal = useCallback(() => {
        setShowUploadModal(true);
    }, []);

    const handleCloseShowUploadModal = useCallback(() => {
        setShowUploadModal(false);
        dispatch(resetTransferDetailRecipient());
    }, []);

    const handleDownloadTemplate = useCallback(async () => {
        try {
            setIsDownloadTemplateLoading(true);
            dispatch(messageTrue({ message: "Downloading..." }));
            await downloadTemplate();
            dispatch(resetAppToast());
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsDownloadTemplateLoading(false);
        }
    }, [dispatch]);

    const handleBulkUpload = useCallback(async (_data: FormData) => {
        try {
            setIsBulkUploadLoading(true);
            const res = await bulkUpload(_data);
            handleResult(res);
            props?.onComplete();
            dispatch(messageTrue({ message: "Spreadsheet uploaded successfully" }));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsBulkUploadLoading(false);
        }
    }, []);

    const handleResult = useCallback(
        (_data: BulkUploadResponse) => {
            if (!accounts || !userAccountsMeta || !fees) return;
            _data.data.forEach((_el: BulkPaymentItem) => {
                const doesOriginatingAccountExist = accounts.some((_acc) => _acc.id === _el.originatingAccountId);
                const recipientBankAccount = _data.bankAccounts.find((_bank) => _bank.id === _el.recipient.bankAccountId) || null;
                if (doesOriginatingAccountExist) {
                    const originatingAccount = accounts.find((_acc) => _acc.id === _el.originatingAccountId) as UserAccount;
                    const isOriginatingAccountRestricted = originatingAccount.accountRestrictions.canSendMoneyToSpecificAccounts;
                    const canOriginatingAccountSendToAny = originatingAccount.accountRestrictions.canSendMoneyToAnyAccount;
                    const canOriginatingAccountNotSend = originatingAccount.accountRestrictions.cannotSendMoney;

                    if (isOriginatingAccountRestricted) {
                        const canOriginatingAccountSendToRecipient = originatingAccount.accountRestrictions.recipientBankAccounts.some(
                            (_bank) => _bank.id === _el.recipient.bankAccountId
                        );
                        dispatch(
                            addTransferDetailToTransferList({
                                originatingAccountId: canOriginatingAccountSendToRecipient ? _el.originatingAccountId : ERROR,
                                recipient: {
                                    externalAccount: {
                                        bankAccountId: _el.recipient.bankAccountId,
                                        customerAccountId: _el.recipient.customerAccountId || "",
                                        accountNumber: recipientBankAccount?.accountNumber || "",
                                        bankCode: recipientBankAccount?.bankCode || "",
                                    },
                                    internalAccountId: "",
                                    cardId: "",
                                },
                                amount:
                                    _el.amount > 0 && originatingAccount.balance && originatingAccount.balance > _el.amount
                                        ? String(_el.amount)
                                        : ERROR,
                                purpose: _el.purpose.trim().length > 0 ? _el.purpose : ERROR,
                                status: TransactionStatus.PROCESSING,
                                transactionId: "",
                                selectedUserAccount: canOriginatingAccountSendToRecipient ? originatingAccount : null,
                                selectedUserAccountMeta: userAccountsMeta?.find((_it) => _it.userAccountId === originatingAccount.id) || null,
                                recipientBankAccount: recipientBankAccount || null,
                                fee: _el.amount > 0 ? (_el.amount < 5000 ? fees[1] : _el.amount < 50000 ? fees[2] : fees[3]) : 0,
                                canApprove: canOriginatingAccountSendToRecipient
                                    ? userAccountsMeta.find((_it) => _it.userAccountId === _el.originatingAccountId)?.isApprover || false
                                    : false,
                                key: Math.floor(Math.random() * 100000000000000) + 1,
                            })
                        );
                    }
                    if (canOriginatingAccountSendToAny) {
                        dispatch(
                            addTransferDetailToTransferList({
                                originatingAccountId: _el.originatingAccountId,
                                recipient: {
                                    externalAccount: {
                                        bankAccountId: _el.recipient.bankAccountId,
                                        customerAccountId: _el.recipient.customerAccountId || "",
                                        accountNumber: recipientBankAccount?.accountNumber || "",
                                        bankCode: recipientBankAccount?.bankCode || "",
                                    },
                                    internalAccountId: "",
                                    cardId: "",
                                },
                                amount:
                                    _el.amount > 0 && originatingAccount.balance && originatingAccount.balance > _el.amount
                                        ? String(_el.amount)
                                        : ERROR,
                                purpose: _el.purpose.trim().length > 0 ? _el.purpose : ERROR,
                                status: TransactionStatus.PROCESSING,
                                transactionId: "",
                                selectedUserAccount: originatingAccount,
                                selectedUserAccountMeta: userAccountsMeta?.find((_it) => _it.userAccountId === originatingAccount.id) || null,
                                recipientBankAccount: recipientBankAccount || null,
                                fee: _el.amount > 0 ? (_el.amount < 5000 ? fees[1] : _el.amount < 50000 ? fees[2] : fees[3]) : 0,
                                canApprove: userAccountsMeta.find((_it) => _it.userAccountId === _el.originatingAccountId)?.isApprover || false,
                                key: Math.floor(Math.random() * 100000000000000) + 1,
                            })
                        );
                    }
                    if (canOriginatingAccountNotSend) {
                        dispatch(
                            addTransferDetailToTransferList({
                                originatingAccountId: ERROR,
                                recipient: {
                                    externalAccount: {
                                        bankAccountId: _el.recipient.bankAccountId,
                                        customerAccountId: _el.recipient.customerAccountId || "",
                                        accountNumber: recipientBankAccount?.accountNumber || "",
                                        bankCode: recipientBankAccount?.bankCode || "",
                                    },
                                    internalAccountId: "",
                                    cardId: "",
                                },
                                amount:
                                    _el.amount > 0 && originatingAccount.balance && originatingAccount.balance > _el.amount
                                        ? String(_el.amount)
                                        : ERROR,
                                purpose: _el.purpose.trim().length > 0 ? _el.purpose : ERROR,
                                status: TransactionStatus.PROCESSING,
                                transactionId: "",
                                selectedUserAccount: null,
                                selectedUserAccountMeta: null,
                                recipientBankAccount: recipientBankAccount || null,
                                fee: _el.amount > 0 ? (_el.amount < 5000 ? fees[1] : _el.amount < 50000 ? fees[2] : fees[3]) : 0,
                                canApprove: false,
                                key: Math.floor(Math.random() * 100000000000000) + 1,
                            })
                        );
                    }
                }
                if (!doesOriginatingAccountExist) {
                    dispatch(
                        addTransferDetailToTransferList({
                            originatingAccountId: ERROR,
                            recipient: {
                                externalAccount: {
                                    bankAccountId: _el.recipient.bankAccountId,
                                    customerAccountId: _el.recipient.customerAccountId,
                                    accountNumber: recipientBankAccount?.accountNumber || "",
                                    bankCode: recipientBankAccount?.bankCode || "",
                                },
                                internalAccountId: "",
                                cardId: "",
                            },
                            amount: _el.amount > 0 ? String(_el.amount) : ERROR,
                            purpose: _el.purpose.trim().length > 0 ? _el.purpose : ERROR,
                            status: TransactionStatus.PROCESSING,
                            transactionId: "",
                            selectedUserAccount: null,
                            selectedUserAccountMeta: null,
                            recipientBankAccount: recipientBankAccount || null,
                            fee: _el.amount > 0 ? (_el.amount < 5000 ? fees[1] : _el.amount < 50000 ? fees[2] : fees[3]) : 0,
                            canApprove: false,
                            key: Math.floor(Math.random() * 100000000000000) + 1,
                        })
                    );
                }
            });
            setShowUploadModal(false);
        },
        [accounts, userAccountsMeta, fees]
    );

    return {
        showUploadModal,
        isDownloadTemplateLoading,
        isBulkUploadLoading,
        handleShowUploadModal,
        handleCloseShowUploadModal,
        handleDownloadTemplate,
        handleBulkUpload,
    };
}

export default useBulkTransferUploadModal;
