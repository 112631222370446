import { useCallback, useRef, useState } from "react";

import { forgotPassword } from "../../servicess/forgotPasswordApi";
import { forgotPasswordData } from "../../servicess/forgotPasswordApi.types";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";

export interface useForgotPasswordData {
    reCaptchaRef: React.MutableRefObject<null>;
    captchaToken: string | null;
    isCaptchaOpen: boolean;
    forgotPasswordError: string | null;
    forgotPasswordMessage: string | null;
    isResetPasswordLoading: boolean;

    handleToken: (info: string | null) => void;
    handleExpire: () => void;
    handleOpenCaptcha: () => void;
    handleForgotPassword: (_info: forgotPasswordData) => void;
}

const useForgotPasswordState = (): useForgotPasswordData => {
    const reCaptchaRef = useRef(null);

    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [isCaptchaOpen, setIsCaptchaOpen] = useState<boolean>(false);
    const [forgotPasswordError, setForgotPasswordError] = useState<string | null>(null);
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState<string | null>(null);
    const [isResetPasswordLoading, setIsResetPasswordLoading] = useState<boolean>(false);

    const handleToken = useCallback((token: string | null) => {
        setCaptchaToken(token);
    }, []);

    const handleExpire = useCallback(() => {
        setCaptchaToken(null);
    }, []);

    const handleOpenCaptcha = useCallback(() => {
        setIsCaptchaOpen(true);
    }, []);

    const handleForgotPassword = useCallback(async (_info: forgotPasswordData) => {
        setForgotPasswordError(null);
        setIsResetPasswordLoading(true);
        try {
            const res = await forgotPassword(_info);
            setForgotPasswordMessage(res.message);
        } catch (err) {
            setForgotPasswordError(getErrorMessage(err));
        } finally {
            setIsResetPasswordLoading(false);
        }
    }, []);

    return {
        reCaptchaRef,
        captchaToken,
        isCaptchaOpen,
        forgotPasswordError,
        isResetPasswordLoading,
        forgotPasswordMessage,

        handleToken,
        handleExpire,
        handleOpenCaptcha,
        handleForgotPassword,
    };
};

export default useForgotPasswordState;
