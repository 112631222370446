import { Dispatch, SetStateAction, useCallback, useState } from "react";
import UserAccount from "../../../../../../../models/userAccount";
import { useDispatch } from "react-redux";
import { mainUpdateUserAccount } from "../../../../../../../redux/init/slice/initSlice";

export interface UseTransactionDetailsActivateAccountResponse {
    accountToActivate: UserAccount | null;
    setAccountToActivate: Dispatch<SetStateAction<UserAccount | null>>;
    showActivateAccountModal: boolean;
    setShowActivateAccountModal: Dispatch<SetStateAction<boolean>>;
    handleAccountActivationCompleted: (account: UserAccount) => void;
}

function useTransactionDetailsActivateAccount(): UseTransactionDetailsActivateAccountResponse {
    const dispatch = useDispatch();

    const [accountToActivate, setAccountToActivate] = useState<UserAccount | null>(null);

    const [showActivateAccountModal, setShowActivateAccountModal] = useState<boolean>(false);

    const handleAccountActivationCompleted = useCallback((account: UserAccount) => {
        dispatch(mainUpdateUserAccount(account));
        setShowActivateAccountModal(false);
    }, []);

    return {
        accountToActivate,
        setAccountToActivate,

        showActivateAccountModal,
        setShowActivateAccountModal,

        handleAccountActivationCompleted,
    };
}

export default useTransactionDetailsActivateAccount;
