import { GenericObject } from "../../../../helpers/types";
import Parsers from "../../../../utils/parsers";

export type forgotPasswordUsernameData = {
    username: string;
};

export interface forgotPasswordUsernameResponseData {
    success: boolean;
    message: string;
}

export type ForgotPasswordVerifyData = {
    a: string;
    b: string;
    c: string;
};

export class OtpRecipientItem {
    constructor(
        public key: string,
        public text: string
    ) {}

    static create(obj: GenericObject): OtpRecipientItem {
        return new OtpRecipientItem(Parsers.string(obj.key), Parsers.string(obj.text));
    }
}

export interface ForgotPasswordVerifyResponseData {
    success: boolean;
    message: string;
    otpRequired: boolean;
    otpRecipients: OtpRecipientItem[];
}

export type ForgotPasswordSendOtpData = ForgotPasswordVerifyData & {
    recipient: string;
};

export interface ForgotPasswordSendOtpResponseData {
    success: boolean;
    message: string;
    otpRecipient: OtpRecipientItem;
}

export type ForgotPasswordVerifyOtpData = ForgotPasswordVerifyData & {
    otp: string;
};

export type ForgotPasswordSetNewPasswordData = ForgotPasswordVerifyData & {
    password: string;
    repeatPassword: string;
};

export interface ForgotPasswordGenericResponseData {
    success: boolean;
    message: string;
}
