import GoogleIcon from "../google-icon";
import LencoSpinner from "../spinner";

interface Props {
    icon: string;
    size?: "sm" | "md" | "lg";
    text?: string;
    color?: "white" | "grey";
    dataType?: string;
    isFilled?: boolean;
    isLoading?: boolean;
    className?: string;
    isOutlined?: boolean;

    onClick?: () => void;
}

function NewTransactionIcon(props: Props): JSX.Element {
    const { color = "grey" } = props;
    return (
        <div className="flex cursor-pointer flex-col items-center justify-center space-y-1" onClick={props.onClick}>
            <div
                className={
                    "flex h-10 w-10 items-center justify-center rounded-lg text-black-secondary " +
                    `${color === "white" ? "bg-white" : ""}` +
                    `${color === "grey" ? "bg-grey-tertiary" : ""}`
                }
                data-type={props.dataType}
            >
                {props.isLoading ? (
                    <LencoSpinner size="sm" />
                ) : (
                    <GoogleIcon
                        icon={props.icon}
                        size={props.size || "lg"}
                        dataType={props.dataType}
                        isFilled={props.isFilled}
                        isOutlined={props.isOutlined}
                    />
                )}
            </div>
            {props.text && <p className="text-xs capitalize text-black-secondary">{props.text}</p>}
        </div>
    );
}
export default NewTransactionIcon;
