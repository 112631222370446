import React, { useCallback, useEffect, useState } from "react";
import DropdownHead from "../../../dropdown/dropdown-components/dropdown-head";
import ButtonComp from "../../../button/ButtonComp";
import { GenericObject } from "../../../../helpers/types";
import DropdownLink from "../../../dropdown/dropdown-components/dropdown-link";
import SearchBar from "../../../search-bar";
import CheckboxOption, { ItemSubList } from "./FilterOption/CheckboxOption";
import DateDropdownOption from "./FilterOption/DateDropdownOption";
import RadioOption from "./FilterOption/RadioOption";
import CustomDatePickerModal from "../CustomDatePickerModal";
import { useDispatch } from "react-redux";
import { setClearAllFilter } from "../../../../redux/application/applicationSlice";

export enum FilterItemSelectType {
    RADIO_OPTION = "radio",
    CHECKBOX_OPTION = "checkbox",
    DATE_OPTION = "date",
}

export type Item = { text: string; onClick?: (data: GenericObject) => void; selectType?: FilterItemSelectType; subList?: ItemSubList[] };

export type TableFilterProps = {
    items: Item[];
    handleApplyFilter: (data: GenericObject) => void;
    placement?: "left" | "right" | "center";
    totalSelectedItems?: number;
    handleClearFilter?: () => void;
};

let customDateFunc: null | ((data: GenericObject) => void) | undefined = null;
export default function TableFilter({ items, handleApplyFilter, handleClearFilter, totalSelectedItems = 0, placement = "right" }: TableFilterProps) {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isHover, setIsHover] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);

    const handleOpenFilter = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setIsOpen(false);
        setIsHover(0);
    }, []);

    const handleClearAll = useCallback(() => {
        handleClearFilter?.();
        dispatch(setClearAllFilter(true));
    }, []);

    const handleMouseEnter = useCallback((index: number) => {
        setIsHover(index);
    }, []);

    const handleChangeSearchTerm = useCallback((search: string) => {
        setSearchTerm(search);
    }, []);

    const handleCustomDateSelect = useCallback(
        ({ onClick }: { onClick: ((data: GenericObject) => void) | undefined }) => {
            customDateFunc = onClick;
            setIsCustomDateOpen(true);
        },
        [items]
    );

    useEffect(() => {
        return () => {
            dispatch(setClearAllFilter(false));
        };
    }, []);

    return (
        <div className="flex flex-col md:flex-row md:justify-between">
            <CustomDatePickerModal
                toggle={() => setIsCustomDateOpen((prev) => !prev)}
                active={isCustomDateOpen}
                filterFunc={(_el) => {
                    customDateFunc?.({ begin: _el?.date?.startDate, end: _el?.date?.endDate });
                }}
            />
            <div className="flex flex-col flex-wrap 4xs:flex-row">
                <div className="w-max">
                    <div className="w-full">
                        <div className="relative m-auto flex w-24 cursor-pointer flex-row rounded-lg">
                            <DropdownHead
                                placeholder={<span className="text-sm">Filter</span>}
                                size="sm"
                                placeholderClose={undefined}
                                icon={false}
                                placement={placement}
                                outlineBg={false}
                                outlineBorder={false}
                                outlineBorderHover={false}
                                noOutlineBorder={false}
                                noHoverBg={false}
                                value={false}
                                filled={false}
                                isLoading={false}
                                isActive={isOpen}
                                handleOpen={handleOpenFilter}
                                handleClose={handleCloseFilter}
                                fitDropdown
                                noOverflow
                                color={"black"}
                                isFilter={true}
                                handleAdditionalCheck={(eventTarget: HTMLElement) =>
                                    !!(eventTarget.dataset.type !== "custom-date" || !eventTarget.dataset.type.includes("custom-date"))
                                }
                            >
                                <div
                                    className={
                                        "absolute -top-12 z-10 mt-0.5 w-64 rounded-lg border bg-white pb-2.5 shadow-lg" +
                                        `${placement === "left" && " right-24 mr-1"} ${placement === "right" && " left-24 ml-1"}`
                                    }
                                >
                                    <div className="flex h-10 w-full flex-row items-center justify-between px-4">
                                        <p className="text-base font-medium text-black-secondary">Filter by</p>
                                        <ButtonComp
                                            buttonType="tertiary"
                                            color="blue"
                                            size="sm"
                                            disable={totalSelectedItems < 1 && true}
                                            func={handleClearAll}
                                        >
                                            <span className="text-xs font-medium">Clear all</span>
                                        </ButtonComp>
                                    </div>

                                    {/* Filter Menu */}
                                    <div className="w-full border-t border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary">
                                        {items?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={
                                                    "relative w-full hover:bg-grey-tertiary" + `${index + 1 === isHover && " bg-grey-tertiary"}`
                                                }
                                                onMouseEnter={() => handleMouseEnter(index + 1)}
                                            >
                                                <DropdownLink>
                                                    <div className="flex w-full flex-row items-center justify-between">
                                                        <span className="text-sm font-medium text-black-secondary">{item.text}</span>
                                                        <div className="">
                                                            <span className="material-symbols-outlined text-xl text-black-tertiary">
                                                                chevron_right
                                                            </span>
                                                        </div>
                                                    </div>
                                                </DropdownLink>

                                                <div
                                                    className={
                                                        `absolute top-0 w-64 overflow-hidden rounded-lg border bg-white shadow-lg ` +
                                                        `${isHover === index + 1 ? "block" : "hidden"} ${placement === "left" && " right-64 mr-0.5"} ${placement === "right" && " left-64 ml-0.5"}`
                                                    }
                                                >
                                                    <div>
                                                        {item?.subList &&
                                                            item?.subList?.length > 7 &&
                                                            item.selectType !== FilterItemSelectType.DATE_OPTION && (
                                                                <div
                                                                    className="relative flex h-12 w-full items-center justify-start border-b px-4"
                                                                    tabIndex={-1}
                                                                >
                                                                    <SearchBar
                                                                        placeholder="Search"
                                                                        value={searchTerm}
                                                                        onChange={handleChangeSearchTerm}
                                                                    />
                                                                </div>
                                                            )}

                                                        {item?.selectType === FilterItemSelectType.RADIO_OPTION && (
                                                            <RadioOption
                                                                onClick={item?.onClick}
                                                                options={
                                                                    item?.subList?.filter?.((_el) =>
                                                                        _el?.text?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                                                                    ) || []
                                                                }
                                                            />
                                                        )}

                                                        {item?.selectType === FilterItemSelectType.CHECKBOX_OPTION && (
                                                            <div className="h-fit max-h-88 overflow-y-auto" data-type="transaction">
                                                                {item?.subList
                                                                    ?.filter?.((_el) => _el?.text?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
                                                                    ?.map((option, idy) => (
                                                                        <CheckboxOption key={idy} {...option} onClick={item?.onClick} />
                                                                    ))}
                                                            </div>
                                                        )}

                                                        {item?.subList && item?.subList?.length < 1 && (
                                                            <div className="flex h-14 w-full flex-row items-center justify-center space-x-2 text-sm text-black-tertiary">
                                                                {/* <EmptyXX className="h-10 w-10" /> */}
                                                                <span>No {item.text} Found</span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {item.selectType === FilterItemSelectType.DATE_OPTION && (
                                                        <DateDropdownOption
                                                            onClick={item?.onClick}
                                                            onCustomSelect={() => handleCustomDateSelect({ onClick: item?.onClick })}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        {/* Apply Button */}
                                        <div
                                            className={`${totalSelectedItems && totalSelectedItems > 0 ? "w-full justify-between" : "w-full justify-end"} flex items-center px-4 pt-2`}
                                        >
                                            {totalSelectedItems && totalSelectedItems > 0 ? (
                                                <div className="text-xs text-black-tertiary">{totalSelectedItems} Filter Selected</div>
                                            ) : null}
                                            <ButtonComp
                                                type="button"
                                                color="black"
                                                ripple="light"
                                                buttonType="primary"
                                                size="sm"
                                                borderSmall
                                                // isLoading={isTransactionPaginationLoading}
                                                func={() => handleApplyFilter({})}
                                            >
                                                <span className="text-xs">Apply Filter</span>
                                            </ButtonComp>
                                        </div>
                                    </div>
                                </div>
                            </DropdownHead>
                        </div>
                    </div>
                </div>
                {/* <div className="relative">
                    <div className="flex w-full flex-row flex-wrap">
                        {transactionFilterState.query.length > 0 && (
                            <>
                                <SearchCard handleFilteredDetails={props.handleFilteredDetails} />
                            </>
                        )}
                        {transactionFilterState.userAccountIds.length > 0 && (
                            <>
                                <AccountCard handleFilteredDetails={props.handleFilteredDetails} />
                            </>
                        )}
                        {transactionFilterState.userGroupIds.length > 0 && (
                            <>
                                <RecipientCard handleFilteredDetails={props.handleFilteredDetails} />
                            </>
                        )}
                        {transactionFilterState.categoryIds.length > 0 && (
                            <>
                                <CategoryCard handleFilteredDetails={props.handleFilteredDetails} />
                            </>
                        )}
                        {transactionFilterState.cardIds.length > 0 && (
                            <>
                                <CardsCard handleFilteredDetails={props.handleFilteredDetails} />
                            </>
                        )}
                        {(transactionFilterState.transactionTypes.length > 0 || transactionFilterState.transactionStatuses.length > 0) && (
                            <>
                                <OthersCard handleFilteredDetails={props.handleFilteredDetails} />
                            </>
                        )}
                        {selectedTransactionDate !== TransactionDateTypes.ALL_TIME && (
                            <>
                                <DateCard handleFilteredDetails={props.handleFilteredDetails} />
                            </>
                        )}
                    </div>
                </div> */}
            </div>
            {/* {isZambia ? (
                <div className="relative h-fit max-w-max pt-2">
                    <ButtonComp
                        type="button"
                        ripple="light"
                        buttonType="secondary"
                        color="grey"
                        func={() => {
                            dispatch(setIsExportZambiaTransactionsTrayOpen(true));
                        }}
                        size="md"
                    >
                        <span className="material-symbols-outlined text-base text-black-tertiary">download</span>
                        <span className="ml-2 text-xs">Export</span>
                    </ButtonComp>
                </div>
            ) : (
                <div className="relative h-fit max-w-max cursor-pointer pt-2">
                    <ExportDropdown />
                </div>
            )} */}
        </div>
    );
}

{
    /* <DropdownLink key={idy} data-type="transaction">
    <div className="flex h-full w-full flex-row items-center justify-start pr-4 text-sm" data-type="transaction">
        <div className="pointer-events-none w-fit max-w-full" data-type="transaction"></div>
    </div>
</DropdownLink>; */
}
