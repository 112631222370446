import {
    resetZambiaApiAccessKeysFilterState,
    setIsZambiaApiAccessKeysPaginationLoading,
    setSelectedZambiaApiAccessKeysFilterState,
    setZambiaApiAccessKeysFilterState,
    setZambiaApiAccessKeysList,
    setZambiaApiAccessKeysListGroupSize,
    setZambiaApiAccessKeysListTotal,
    setZambiaApiAccessKeysPaginationOffset,
} from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";

import { ApiAccessKeysRequest } from "../../Services/ApiAccessKeys/apiAccessKeysApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { apiAccessKeys } from "../../Services/ApiAccessKeys/apiAccessKeysApi";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { produce } from "immer";
import { useAppSelector } from "../../../../../redux/hooks";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

function useZambiaApiAccessKeys() {
    const dispatch = useDispatch();

    const selectedApiAccessKeysFilterState = useAppSelector((state) => state.zambiaApiAccessKeys.selectedApiAccessKeysFilterState);
    const apiAccessKeysList = useAppSelector((state) => state.zambiaApiAccessKeys.apiAccessKeysList);
    const apiAccessKeysListTotal = useAppSelector((state) => state.zambiaApiAccessKeys.apiAccessKeysListTotal);
    const apiAccessKeysListGroupSize = useAppSelector((state) => state.zambiaApiAccessKeys.apiAccessKeysListGroupSize);
    const apiAccessKeysPaginationOffset = useAppSelector((state) => state.zambiaApiAccessKeys.apiAccessKeysPaginationOffset);
    const isApiAccessKeysPaginationLoading = useAppSelector((state) => state.zambiaApiAccessKeys.isApiAccessKeysPaginationLoading);

    const handleGetZambiaApiAccessKeys = useCallback(
        async (_data: ApiAccessKeysRequest) => {
            try {
                dispatch(setIsZambiaApiAccessKeysPaginationLoading(true));
                const res = await apiAccessKeys(_data);
                dispatch(setZambiaApiAccessKeysList(res.apiAccessKeys));
                dispatch(setZambiaApiAccessKeysListTotal(res.total));
                dispatch(setZambiaApiAccessKeysListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsZambiaApiAccessKeysPaginationLoading(false));
            }
        },
        [dispatch]
    );

    const handleFilteredZambiaApiAccessKeys = useCallback(
        <T>(data: T) => {
            const updatedList = produce(selectedApiAccessKeysFilterState, (draft) => {
                return (draft = { ...draft, ...data });
            });
            dispatch(setSelectedZambiaApiAccessKeysFilterState());
            dispatch(setZambiaApiAccessKeysFilterState(updatedList));
            dispatch(setIsZambiaApiAccessKeysPaginationLoading(true));
            void handleGetZambiaApiAccessKeys({
                offset: updatedList.offset,
                query: updatedList.query,
                status: updatedList.status[0],
            });
        },
        [selectedApiAccessKeysFilterState]
    );

    const handlePaginationRequest = useCallback((offset: number) => {
        dispatch(setZambiaApiAccessKeysPaginationOffset(offset));
        handleFilteredZambiaApiAccessKeys({ offset });
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetZambiaApiAccessKeysFilterState());
        void handleGetZambiaApiAccessKeys({
            query: "",
            offset: 0,
            status: undefined,
        });
    }, []);

    return {
        apiAccessKeysList,
        apiAccessKeysListTotal,
        apiAccessKeysListGroupSize,
        apiAccessKeysPaginationOffset,
        isApiAccessKeysPaginationLoading,
        handleClearAll,
        handlePaginationRequest,
        handleFilteredZambiaApiAccessKeys,
    };
}

export default useZambiaApiAccessKeys;
