type AccountOptionType = {
    icon: string;
    key: "get-help" | "faq" | "logout";
    link?: string;
    text: string;
};

export const accountOptions: AccountOptionType[] = [
    {
        key: "get-help",
        icon: "headset_mic",
        text: "Get Help",
    },
    {
        key: "faq",
        icon: "quiz",
        text: "FAQ",
        link: process.env.REACT_APP_FAQ_URL,
    },
    {
        key: "logout",
        icon: "logout",
        text: "Logout",
    },
];
