import React, { useEffect, useState } from "react";

// import { ReactComponent as AddCircle } from "../../../../../assets/svg/addcircle.svg";
// import ButtonComp from "../../../../../components/button/ButtonComp";
import ChartCanvas from "../../../../../components/chart";
// import ChartCanvas from "../../../../../components/General/Chart/Chart";
import Corporate from "../../../../../models/corporate";
import Currency from "../../../../../models/currency";
import { DashboardDetailsResponse } from "../../Services/homeApi.types";
import GoogleIcon from "../../../../../components/google-icon";
// import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import Money from "../../../../../components/money";
import NigeriaFlag from "../../../../../assets/images/countries/nigeria.png";
import { Num } from "../../../../../utils/math";
// import { ReactComponent as PasswordCloseIcon } from "../../../../../assets/svg/General/Password/passwordCloseIcon.svg";
// import { ReactComponent as PasswordIcon } from "../../../../../assets/svg/General/Password/passwordIcon.svg";
import SkeletonElement from "../../../../../components/skeleton";
import TransactionCategory from "../../../../../models/transactionCategory";
import User from "../../../../../models/user";
import UserAccount from "../../../../../models/userAccount";
import UserAccountMeta from "../../../../../models/userAccountMeta";
import UserGroup from "../../../../../models/userGroup";
import ZambiaFlag from "../../../../../assets/images/countries/zambia.png";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
// import { openAddFundsModal, openZambiaAddFundsModal } from "../../../../../redux/accounts/slice/accountsSlice";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";

interface ChartCardInterface {
    data: DashboardDetailsResponse | null;
    handleToggleShowAmount: () => void;
    showAmount: boolean;
    totalInflow: number;
    totalOutflow: number;
    accountsData: {
        user: User;
        userAccountsMeta: Array<UserAccountMeta>;
        company: Corporate;
        accounts: Array<UserAccount>;
        userGroups: Array<UserGroup>;
        transactionCategories: Array<TransactionCategory>;
    } | null;
}

interface BalanceItem {
    currency: Currency | undefined;
    amount: Num | undefined;
}

type Balances = { [currencyCode: string]: BalanceItem };

function ChartCard({ data, handleToggleShowAmount, showAmount, totalInflow, totalOutflow, accountsData }: ChartCardInterface): JSX.Element {
    // const isNigeria = useAppSelector((state) => state.init.main?.companyDetails?.company.isNigeria || state.application.init);
    const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isNigeria);
    const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);

    // const dispatch = useDispatch();

    // sum up all the account balances, and group by the currency
    const totalBalance: BalanceItem[] = useSelector((state: IRootState) => {
        const balances: Balances | undefined = state.init.main?.companyDetails.accounts.reduce(
            (accumulator: Balances, account: UserAccount): Balances => {
                const currency = account.bankAccountCurrency || undefined;
                // if the currency is not set, let currencyCode be "undefined" as a string
                const currencyCode = String(currency?.isoCode);

                if (!(currencyCode in accumulator)) {
                    accumulator[currencyCode] = {
                        currency: currency,
                        amount: new Num(0),
                    };
                }

                if (isNullOrUndefined(accumulator[currencyCode].amount) || isNullOrUndefined(account.balance)) {
                    accumulator[currencyCode].amount = undefined;
                } else {
                    (accumulator[currencyCode].amount as Num).add(account.balance);
                }

                return accumulator;
            },
            {}
        );

        if (!balances) {
            return [];
        }

        return Object.values(balances).filter((item) => !isNullOrUndefined(item.amount));
    });

    const [dashboardDetails, setDashboardDetails] = useState<DashboardDetailsResponse | null>(null);
    const toggleAmount = () => {
        handleToggleShowAmount();
    };
    useEffect(() => {
        setDashboardDetails(data);
    }, [data]);

    return (
        <>
            <div className="flex h-full max-h-96 min-h-96 flex-col space-y-2 rounded-xl bg-white px-8 py-5 text-base shadow">
                <div className="mb-6 flex h-20 w-full items-start justify-between">
                    <div className="w-full space-y-6">
                        <div className="w-full space-y-4">
                            {dashboardDetails && accountsData ? (
                                <div className="flex w-full items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <p className="text-sm text-black-tertiary">Total Balance</p>
                                        <div
                                            className="flex cursor-pointer items-center justify-center rounded bg-grey-tertiary p-1"
                                            onClick={toggleAmount}
                                        >
                                            {showAmount ? (
                                                <GoogleIcon icon="visibility_off" className="text-black-secondary" size="xs" isOutlined />
                                            ) : (
                                                <GoogleIcon icon="visibility" className="text-black-secondary" size="xs" isOutlined />
                                            )}
                                        </div>
                                    </div>
                                    {isNigeria && (
                                        <div className="flex items-center justify-center space-x-4 rounded bg-grey-tertiary p-2">
                                            <div className="flex items-center justify-center space-x-2">
                                                <img
                                                    className="rounded-full border border-black-quat"
                                                    src={NigeriaFlag}
                                                    alt="Nigerian flag"
                                                    width="20px"
                                                    height="20px"
                                                />
                                                <p className="text-sm text-black-secondary">NGN</p>
                                            </div>
                                            <span className="material-symbols-outlined hidden text-base">keyboard_arrow_down</span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="relative pt-1">
                                        <div className="absolute right-2 flex w-full flex-col items-end justify-end">
                                            <div className="mt-1 flex w-35% items-end justify-end">
                                                <SkeletonElement title />
                                            </div>
                                        </div>

                                        <div className="h-6 w-44">
                                            <SkeletonElement box />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="relative flex w-lg items-center justify-start">
                                <div className="flex flex-row items-end">
                                    {dashboardDetails && accountsData ? (
                                        <>
                                            {totalBalance.length > 0 && (
                                                <div className="h-8">
                                                    {/* <div className={!showAmount ? "h-4" : ""}> */}
                                                    {showAmount ? (
                                                        totalBalance.map((item, index) => (
                                                            <div className="text-3xl font-bold text-black-secondary" key={`balance-i-${index}`}>
                                                                <Money amount={(item.amount as Num).valueOf()} currency={item.currency} />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="flex flex-row items-center justify-center pt-2">
                                                            <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                            <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                            <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                            <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                                            <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="mt-2 h-4 w-20">
                                            <SkeletonElement box />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isZambia && (
                        <div className="hidden 2xs:block">
                            {dashboardDetails && accountsData ? (
                                <div className="flex w-max items-center justify-center space-x-4 rounded bg-grey-tertiary p-2">
                                    <div className="flex items-center justify-center space-x-2">
                                        <img
                                            className="rounded-full border border-black-quat"
                                            src={ZambiaFlag}
                                            alt="Zambian flag"
                                            width="20px"
                                            height="20px"
                                        />
                                        <p className="text-sm text-black-secondary">ZMW</p>
                                    </div>
                                    {/* <GoogleIcon icon="keyboard_arrow_down"/> */}
                                </div>
                            ) : (
                                <div className="h-9 w-36">
                                    <SkeletonElement box />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {data && (
                    <div className="space-y-3">
                        <p className="text-lg font-medium text-black-secondary">Insight for the Month</p>
                        <div className="flex h-12 items-center justify-between">
                            <div>
                                <div className="flex items-center justify-start space-x-1">
                                    <p className="text-sm font-normal text-black-tertiary">Inflow</p>
                                    <GoogleIcon icon="trending_down" className="text-success" size="sm" />
                                </div>
                                <div className="flex h-6 items-center justify-start text-lg font-medium text-black-secondary">
                                    {showAmount ? (
                                        <Money
                                            amount={(totalInflow as unknown as Num).valueOf()}
                                            currency={totalBalance[0] && totalBalance[0].currency}
                                        />
                                    ) : (
                                        <div className="flex h-2 flex-row items-center justify-center">
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="md:text-right">
                                <div className="flex items-center justify-start space-x-1 md:justify-end">
                                    <p className="text-sm font-normal text-black-tertiary">Payout</p>
                                    <GoogleIcon icon="trending_up" className="text-error" size="sm" />
                                </div>
                                <div className="flex h-6 items-center justify-start text-lg font-medium text-black-secondary">
                                    {showAmount ? (
                                        <Money
                                            amount={(totalOutflow as unknown as Num).valueOf()}
                                            currency={totalBalance[0] && totalBalance[0].currency}
                                        />
                                    ) : (
                                        <div className="flex h-2 flex-row items-center justify-center">
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                            <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex h-full max-h-72 w-full items-center justify-center">
                    {dashboardDetails && accountsData ? (
                        <ChartCanvas
                            data={dashboardDetails.chart.map((point) => {
                                return { date: point.date, balance: point.balance as number };
                            })}
                            numPoints={30}
                        />
                    ) : (
                        <SkeletonElement box />
                    )}
                </div>
            </div>
        </>
    );
}

export default ChartCard;
