import { useCallback, useState } from "react";

import { BillPurchasesHistoryRequest } from "../../../services/billPayment/billPaymentApi.types";
import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { billPurchaseHistory } from "../../../services/billPayment/billPaymentApi";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { setBillPurchases } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch } from "react-redux";

interface UseBillPaymentInterface {
    handleBillPurchaseHistory: (_data: BillPurchasesHistoryRequest) => Promise<void>;
    isBillPurchasesLoading: boolean;
    BillPurchasesError: string | null;
    billPurchasesOffset: number;
    billHistoryGroupSize: number;
    billHistoryTotal: number;
}

export default function useBillPaymentHistory(): UseBillPaymentInterface {
    const dispatch = useDispatch();
    const [billPurchasesOffset, setBillPurchasesOffset] = useState(0);
    const [billHistoryGroupSize, setBillHistoryGroupSize] = useState(0);
    const [billHistoryTotal, setBillHistoryTotal] = useState(0);
    const [BillPurchasesError, setBillPurchasesError] = useState<string | null>(null);
    const [isBillPurchasesLoading, setIsBillPurchasesLoading] = useState<boolean>(false);

    const handleBillPurchaseHistory = useCallback(
        async (_data: BillPurchasesHistoryRequest) => {
            setIsBillPurchasesLoading(true);
            try {
                setBillPurchasesOffset(_data.offset || 0);
                const res = await billPurchaseHistory(_data);

                setBillHistoryGroupSize(res.groupSize);
                setBillHistoryTotal(res.total);
                dispatch(setBillPurchases(res));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setBillPurchasesError(errorMessage);
            } finally {
                setIsBillPurchasesLoading(false);
            }
        },
        [dispatch]
    );

    return { isBillPurchasesLoading, BillPurchasesError, handleBillPurchaseHistory, billPurchasesOffset, billHistoryGroupSize, billHistoryTotal };
}
