import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";

import BulkPaymentHeaders from "./BulkPaymentHeaders";
import { BulkPaymentInitRequest } from "../../../services/send-money/bulkTransferApi.types";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../components/spinner";
import Money from "../../../../../../components/money";
import Otp from "../../../../../../components/otp";
import { OtpActivityType } from "../../../../../../helpers/AppConstants";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import useBulkTransferOtp from "../../../hooks/state/send-money/BulkTransfer/useBulkTransferOtp";
import useOtpInit from "../../../hooks/state/send-money/single-transfer/useOtpInit";

function MakePayment(): JSX.Element {
    const dispatch = useDispatch();

    const { otpInitError, isInitOTPLoading, handleOTPInit, handleResendOTPInit } = useOtpInit({});
    const { bulkPaymentOtp, bulkPaymentError, transferDetailArray, bulkPaymentResponse, isBulkPaymentLoading, handleOtpChange, handleBulkTransfer } =
        useBulkTransferOtp();

    const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);
    const bulkTransfersAmountTotal = useSelector((state: IRootState) => state.bulkTransfer.bulkTransfersAmountTotal);

    const [resendOtpText, setResendOtpText] = useState(false);

    useLayoutEffect(() => {
        if (isAuthAppSetup) return;
        void handleOTPInit({ action: OtpActivityType.PROCESS_TRANSACTION });
    }, [handleOTPInit, isAuthAppSetup]);

    useEffect(() => {
        if (!bulkPaymentResponse) return;
        dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
    }, [bulkPaymentResponse]);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center">
                <BulkPaymentHeaders />
                {!isAuthAppSetup && isInitOTPLoading && (
                    <div className="flex w-full flex-row items-center justify-center space-x-4 pt-2 text-sm">
                        <LencoSpinner size="sm" />
                        <span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
                    </div>
                )}
                {!isInitOTPLoading && !otpInitError && (
                    <>
                        <div className="flex w-full flex-col space-y-9">
                            <p className="pt-2 text-center text-base text-black-secondary">
                                {isAuthAppSetup && "Enter the OTP generated from your token App to approve the bulk payment of "}
                                {!isAuthAppSetup && "One-time password (OTP) has been sent to your email to approve bulk payment of "}
                                <Money amount={bulkTransfersAmountTotal} />
                            </p>
                            <div className="flex w-full flex-col rounded-lg bg-white p-6">
                                <Otp
                                    value={bulkPaymentOtp || undefined}
                                    active={!isInitOTPLoading}
                                    isError={bulkPaymentError}
                                    onChange={handleOtpChange}
                                    canResendOtp={!isAuthAppSetup}
                                    isResendOtpLoading={isInitOTPLoading}
                                    handleResend={() => {
                                        handleResendOTPInit({ action: OtpActivityType.PROCESS_TRANSACTION });

                                        setResendOtpText(true);
                                    }}
                                />
                            </div>
                            <div className="flex w-full flex-row items-center justify-center space-x-4">
                                <ButtonComp
                                    size="xl"
                                    color="grey"
                                    ripple="light"
                                    buttonType="secondary"
                                    func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
                                >
                                    Back
                                </ButtonComp>

                                <ButtonComp
                                    size="xl"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    isLoading={isBulkPaymentLoading}
                                    disable={bulkPaymentOtp.length < 6}
                                    func={() => {
                                        const bulkPaymentDetails: BulkPaymentInitRequest = {
                                            payments: transferDetailArray.map((_el) => ({
                                                originatingAccountId: _el.originatingAccountId as string,
                                                recipient: _el.recipient,
                                                amount: _el.amount,
                                                purpose: _el.purpose,
                                                key: _el.key,
                                            })),
                                            otp: bulkPaymentOtp,
                                        };
                                        void handleBulkTransfer(bulkPaymentDetails);
                                    }}
                                >
                                    Authorize Payment
                                </ButtonComp>
                            </div>
                        </div>
                    </>
                )}
                {otpInitError && <ErrorToast error={<p>{otpInitError}</p>} isReactNode />}
            </div>
        </>
    );
}

export default MakePayment;
