import Currency from "../../../../models/currency";
import NewDropdownItem from "../../../new-dropdown-item";
import UserAccount from "../../../../models/userAccount";
import UserAccountDetails from "../user-account-details";

interface Props {
    data: UserAccount;
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    message?: string;
    dataType?: string;
    currency?: Currency | undefined | null;
    isDisabled?: boolean;
    showBalance?: boolean;
    onClick?: (e: string) => void;
}

function UserAccountItem(props: Props): JSX.Element {
    return (
        <>
            {props.data && (
                <NewDropdownItem
                    key={props.data.id}
                    size={props.size || "2xl"}
                    isDisabled={props.isDisabled}
                    onClick={() => props.onClick && props.onClick(props.data.id)}
                    dataType={props.dataType}
                >
                    <UserAccountDetails
                        data={props.data}
                        size={props.size === "md" || props.size === "sm" ? "2xs" : "xs"}
                        message={props.message}
                        currency={props.currency}
                        showBalance={props.showBalance}
                        dataType={props.dataType}
                    />
                </NewDropdownItem>
            )}
        </>
    );
}

export default UserAccountItem;
