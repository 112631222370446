import { DirectorPageType, DirectorPages, DirectorRoutes } from "../../service/director.constants";

import ApplicationSectionCard from "../../../../../dashboard/applicationn/components/cards/applicationSectionCard";
import BusinessDetailsIcon from "../../../../../../assets/images/dashboard/application/business-details.png";
import CompletedIcon from "../../../../../../assets/images/dashboard/application/reviewed.png";
import { DirectorPage } from "../../service/directors.types";
import InviteRefereesIcon from "../../../../../../assets/images/dashboard/application/invite-referees.png";
import OtherDirector from "../../../../../../models/account-opening/otherDirector";
import { ReactNode } from "react";
import SignatoriesIcon from "../../../../../../assets/images/dashboard/application/account-directors.png";
import Support from "../../../../../dashboard/applicationn/components/cards/supportCard";
import Tags from "../../../../../dashboard/applicationn/components/tags/ApplicationStatusTag";
import titleCase from "../../../../../../hooks/titleCase";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

function getIcon(page: DirectorPage): ReactNode {
    switch (page.value) {
        case DirectorPageType.PERSONAL:
            return <img src={BusinessDetailsIcon} alt="icon to depict a company" />;
        case DirectorPageType.NEXT_OF_KIN:
            return <img src={SignatoriesIcon} alt="icon to depict signatories" />;
        case DirectorPageType.MEANS_OF_ID:
            return <img src={InviteRefereesIcon} alt="icon to depict referees" />;
        default:
            return <></>;
    }
}

function getPreviewIcon(page: DirectorPage, progress: OtherDirector): ReactNode {
    switch (page.value) {
        case DirectorPageType.PERSONAL:
            if (isCompleted(page, progress)) {
                return <img src={CompletedIcon} alt="icon to depict a completed section" />;
            } else {
                return getIcon(page);
            }
        case DirectorPageType.NEXT_OF_KIN:
            if (isCompleted(page, progress)) {
                return <img src={CompletedIcon} alt="icon to depict a completed section" />;
            } else {
                return getIcon(page);
            }
        case DirectorPageType.MEANS_OF_ID:
            if (isCompleted(page, progress)) {
                return <img src={CompletedIcon} alt="icon to depict a completed section" />;
            } else {
                return getIcon(page);
            }
        default:
            return <></>;
    }
}

function isCompleted(page: DirectorPage, progress: OtherDirector): boolean {
    switch (page.value) {
        case DirectorPageType.PERSONAL:
            return progress.isCompletedBio();
        case DirectorPageType.NEXT_OF_KIN:
            return progress.isCompletedNextOfKin();
        case DirectorPageType.MEANS_OF_ID:
            return progress.isCompletedIdentification();

        default:
            return false;
    }
}
function getSections(
    page: DirectorPage[],
    progress: OtherDirector | null
): { todoList: DirectorPage[]; underReviewList: DirectorPage[]; preview: DirectorPage[] } {
    const preview: DirectorPage[] = [];
    const todoList: DirectorPage[] = [];
    const underReviewList: DirectorPage[] = [];

    if (progress) {
        !progress.isCompletedBio()
            ? todoList.push({ ...page.sort()[0], progress: progress.bioProgress() })
            : underReviewList.push({ ...page.sort()[0], progress: progress.bioProgress() });

        !progress.isCompletedNextOfKin()
            ? todoList.push({ ...page.sort()[1], progress: progress.nextOfKinProgress() })
            : underReviewList.push({ ...page.sort()[1], progress: progress.nextOfKinProgress() });

        !progress.isCompletedIdentification()
            ? todoList.push({ ...page.sort()[2], progress: progress.identificationProgress() })
            : underReviewList.push({ ...page.sort()[2], progress: progress.identificationProgress() });

        // preview.push({...page.sort()[0], progress: progress.businessCompletedProgress()});
    }
    return { todoList, underReviewList, preview };
}

function DirectorStatus(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const url = useAppSelector((state) => state.externalApplication.externalLink || "");
    const accountManager = useAppSelector((state) => state.application.accountManager);
    const externalApplicationInit = useAppSelector((state) => state.externalApplication.init);

    const sections = getSections(DirectorPages, externalApplicationInit?.director || null);

    return (
        <>
            {externalApplicationInit && (
                <div className="flex h-full w-full flex-col justify-start">
                    <div className="flex w-full flex-col justify-start pb-16 xl:flex-row">
                        <div className="flex h-full w-full flex-col space-y-10 pt-6">
                            <div className="flex h-full w-full flex-col space-y-6">
                                <div>
                                    <h3 className="text-xl font-medium capitalize text-black">Application Overview</h3>
                                    <p className="pt-2 text-base text-black-tertiary">
                                        <span className="font-medium text-black-secondary">{titleCase(externalApplicationInit.creator)}</span> has
                                        listed you as a Director of{" "}
                                        <span className="font-medium text-black-secondary">{titleCase(externalApplicationInit.businessName)}</span>{" "}
                                        for their account opening with lenco.
                                    </p>
                                    <p className="text-base text-black-tertiary">Kindly complete your personal information below:</p>
                                </div>
                                <div className="flex w-full flex-col items-start justify-start space-y-6 xl:min-w-max xl:max-w-lg">
                                    {sections.todoList.length > 0 && (
                                        <div className="flex w-full flex-col items-start justify-start space-y-4">
                                            <Tags text="To do" type="info" />
                                            {externalApplicationInit.director &&
                                                sections.todoList.sort().map((page) => (
                                                    <ApplicationSectionCard
                                                        key={page.value}
                                                        icon={getIcon(page)}
                                                        title={page.title}
                                                        description={page.description}
                                                        onClick={() => {
                                                            navigate(
                                                                {
                                                                    pathname: `${url}${DirectorRoutes[page.value]}`,
                                                                },
                                                                {
                                                                    state: {
                                                                        from: location.pathname,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        progress={page.progress}
                                                        isDisabled={false}
                                                    />
                                                ))}
                                        </div>
                                    )}
                                    {sections.underReviewList.length > 0 && (
                                        <div className="flex w-full flex-col items-start justify-start space-y-4">
                                            <Tags text="Under Review" type="warning" />
                                            {externalApplicationInit.director &&
                                                sections.underReviewList.sort().map((page) => (
                                                    <ApplicationSectionCard
                                                        key={page.value}
                                                        icon={getIcon(page)}
                                                        title={page.title}
                                                        description={page.description}
                                                        onClick={() => {
                                                            navigate(
                                                                {
                                                                    pathname: `${url}${DirectorRoutes[page.value]}`,
                                                                },
                                                                {
                                                                    state: {
                                                                        from: location.pathname,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        progress={page.progress}
                                                        isDisabled={false}
                                                    />
                                                ))}
                                        </div>
                                    )}
                                    {sections.preview.length > 0 && (
                                        <div className="flex w-full flex-col items-start justify-start space-y-4">
                                            <Tags text="Preview" type="success" />
                                            {externalApplicationInit.director &&
                                                sections.preview.sort().map((page) => (
                                                    <ApplicationSectionCard
                                                        key={page.value}
                                                        icon={
                                                            externalApplicationInit.director ? (
                                                                getPreviewIcon(page, externalApplicationInit.director)
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        title={page.title}
                                                        description={page.description}
                                                        onClick={() => {
                                                            navigate(
                                                                {
                                                                    pathname: `${url}${DirectorRoutes[page.value]}`,
                                                                },
                                                                {
                                                                    state: {
                                                                        from: location.pathname,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        progress={page.progress}
                                                        isDisabled={false}
                                                    />
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4.5 flex w-full items-start justify-center pt-10 xl:h-full xl:w-30% xl:pt-40">
                            <div className="mx-auto w-max">
                                <Support
                                    email={accountManager?.email}
                                    name={accountManager?.name}
                                    phone={accountManager?.phone}
                                    photo={accountManager?.photo}
                                    username={`${externalApplicationInit.director.firstName}` + ` ${externalApplicationInit.director.surname}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DirectorStatus;
