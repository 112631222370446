import { useEffect, useState } from "react";

import CustomerAccount from "../../../../../../../models/customerAccount";
import { IRootState } from "../../../../../../../redux/rootReducer";
import SendableDropdown from "../../../../../../../components/dropdown/sendable-dropdown";
import Transaction from "../../../../../../../models/transaction";
import { useSelector } from "react-redux";
import useTransactionDetailsUserGroups from "../Hooks/useTransactionDetailsUserGroups";

interface Props {
    dataType: string;
    transaction: Transaction;
    showTransactionDetailCard: boolean;
}

function TransactionDetailCardUserGroups({ dataType, transaction, showTransactionDetailCard }: Props): JSX.Element {
    const {
        userGroupOptions,
        isError,
        isSubmitting,
        handleAddAccountToUserGroup,
        handleCreateAndAddAccountToUserGroup,
        handleRemoveAccountFromUserGroup,
    } = useTransactionDetailsUserGroups();

    const [displayText, setDisplayText] = useState<string>("");

    const recipientCustomerAccount = useSelector<IRootState, CustomerAccount | undefined>((state) =>
        state.customerAccount.customerAccounts.get(transaction.destination?._customerAccountId || "")
    );

    useEffect(() => {
        setDisplayText(transaction.destination?.customerAccount?.userGroup?.name || "");
    }, [transaction]);

    useEffect(() => {
        if (!isError) return;
        setDisplayText("");
    }, [isError]);

    return (
        <>
            {transaction && transaction.isDebit() && transaction.isSuccess && !!recipientCustomerAccount && userGroupOptions && (
                <div className={`w-full ` + `${!showTransactionDetailCard ? "hidden" : ""}`} data-type={dataType}>
                    <SendableDropdown
                        data={userGroupOptions}
                        label="Group Recipient"
                        value={displayText}
                        changeValue={setDisplayText}
                        clickAndClose
                        isLoading={isSubmitting}
                        cancelFunc={() => void handleRemoveAccountFromUserGroup(recipientCustomerAccount)}
                        onClickFunc={(option) => void handleAddAccountToUserGroup(recipientCustomerAccount, option.id)}
                        createFunc={(name) => void handleCreateAndAddAccountToUserGroup(recipientCustomerAccount, name)}
                        data-type={dataType}
                        isDropdownPositionUp
                    />
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardUserGroups;
