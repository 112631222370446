import { AddNewSignatoryItem, MaxedSignatories } from "../../../../../../../../services/nigeria-application/registered/accountSignatories.types";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import ButtonComp from "../../../../../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../../../../../redux/rootReducer";
import NewSignatory from "./NewSignatory";
import { UserRole } from "../../../../../../../../../../../models/userAccount.constants";
import useAddSignatories from "../../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useAddSignatories";
import { useEmailCheck } from "../../../../../../../../../../../hooks/useEmailCheck";
import { useSelector } from "react-redux";

interface Props {
    addSignatory?: boolean;
    isEditingSignatory: boolean;
    handleHideAllButtons: (_data: boolean) => void;
    handleResetAddSignatory?: () => void;
    handleCloseEditSignatory: () => void;
}

function AddSignatories(props: Props): JSX.Element {
    const { isAddSignatoryLoading, handleAddSignatory } = useAddSignatories(() => {
        setNewSignatoryItems((_prev) => _prev.filter((_item) => !_item.canDelete));
        props.handleResetAddSignatory && props.handleResetAddSignatory();
    });

    const currentUser = useSelector((state: IRootState) => state.application.init?.user);
    const accountSignatories = useSelector((state: IRootState) => state.application.accountSignatories?.signatories);

    const [newSignatoryItems, setNewSignatoryItems] = useState<AddNewSignatoryItem[]>([]);

    const MaxedSignatory = MaxedSignatories.NUMBER as number;
    const numberOfSignatories = (accountSignatories?.length || 0) + (newSignatoryItems.filter((_new) => _new.isSignatory) || []).length;
    const isSignatoriesMaxed = !!(numberOfSignatories >= MaxedSignatory);

    useLayoutEffect(() => {
        if (accountSignatories && accountSignatories.length < 1) {
            handleAddKeySignatoryFunc();
        }
    }, []);

    useEffect(() => {
        props.handleHideAllButtons(newSignatoryItems.length > 0);
    }, [newSignatoryItems]);

    const handleAddKeySignatoryFunc = useCallback(() => {
        if (currentUser) {
            props.handleCloseEditSignatory();
            setNewSignatoryItems((prev) => [
                ...prev,
                {
                    firstName: currentUser.firstName,
                    surname: currentUser.lastName,
                    email: currentUser.email,
                    isDirector: false,
                    isKeyContact: false,
                    isSignatory: true,
                    index: `${Math.floor(Math.random() * 100000000000000) + 1}`,
                    userRole: null,
                    canDelete: false,
                    isDisabled: true,
                },
            ]);
        }
    }, [currentUser]);

    const handleAddNewSignatoryFunc = () => {
        props.handleCloseEditSignatory();
        setNewSignatoryItems((prev) => [
            ...prev,
            {
                firstName: "",
                surname: "",
                email: "",
                isDirector: false,
                isKeyContact: false,
                isSignatory: !isSignatoriesMaxed,
                index: `${Math.floor(Math.random() * 100000000000000) + 1}`,
                userRole: isSignatoriesMaxed ? UserRole.VIEWER : null,
                canDelete: true,
                isDisabled: false,
            },
        ]);
    };

    const handleDeleteNewSignatories = useCallback(
        (_userId: string) => {
            props.handleCloseEditSignatory();
            setNewSignatoryItems((_prev) => _prev.filter((_item) => _item.index !== _userId));
            if (newSignatoryItems.length === 1) props.handleResetAddSignatory && props.handleResetAddSignatory();
        },
        [newSignatoryItems]
    );

    const handleResetNewSignatories = useCallback(() => {
        props.handleCloseEditSignatory();
        props.handleResetAddSignatory && props.handleResetAddSignatory();
        setNewSignatoryItems((_prev) => _prev.filter((_item) => !_item.canDelete));
    }, []);

    const handleChangeNewSignatoryValue = useCallback((_userId: string, _key: string, _value: string | UserRole | boolean | null | number) => {
        setNewSignatoryItems((prev) =>
            prev.map((_item) =>
                _item.index === _userId
                    ? {
                          ..._item,
                          [_key]: _value,
                      }
                    : _item
            )
        );
    }, []);

    const handleSubmitNewSignatories = useCallback(() => {
        void handleAddSignatory({
            signatories: newSignatoryItems.map((_item) => ({
                firstName: _item.firstName,
                surname: _item.surname,
                email: _item.email,
                isDirector: _item.isDirector,
                userRole: _item.userRole as UserRole,
                isAccountSignatory: _item.isSignatory,
            })),
        });
    }, [newSignatoryItems]);

    useEffect(() => {
        if (props.addSignatory) handleAddNewSignatoryFunc();
    }, [props.addSignatory]);

    return (
        <>
            {/* {accountSignatories && accountSignatories.length > 0 && ( */}
            {newSignatoryItems && newSignatoryItems.length > 0 && (
                <div className="flex w-full flex-row items-center justify-start">
                    <ButtonComp type="button" color="blue" ripple="light" buttonType="tertiary" func={handleAddNewSignatoryFunc} size="sm">
                        <div className="flex items-center justify-center space-x-1">
                            <div className="flex max-w-[20px] items-center justify-center overflow-hidden">
                                <span className="material-symbols-rounded text-lg">add_circle</span>
                            </div>
                            <span className="font-medium">Add Other Director{newSignatoryItems.length > 0 ? "s" : ""}</span>
                        </div>
                    </ButtonComp>
                </div>
            )}
            {!props.isEditingSignatory && newSignatoryItems.length > 0 && (
                <div className="!mt-4 flex w-full flex-col items-center justify-start border-b border-grey-secondary pb-8">
                    <div className="flex w-full flex-col items-center justify-start space-y-8">
                        {newSignatoryItems.map((_item, _index) => (
                            <NewSignatory
                                key={_index}
                                data={_item}
                                index={accountSignatories && accountSignatories.length > 0 ? _index + 1 + accountSignatories.length : _index + 1}
                                isSignatoriesMaxed={isSignatoriesMaxed}
                                handleDeleteNewSignatories={handleDeleteNewSignatories}
                                handleChangeNewSignatoryValue={handleChangeNewSignatoryValue}
                                // handleChangeNewKeySignatoryValue={handleChangeNewKeySignatoryValue}
                            />
                        ))}
                    </div>
                    {/* <div className="flex flex-row justify-start items-center w-full pt-4">
						<ButtonComp type="button" color="blue" ripple="light" buttonType="tertiary" func={handleAddNewSignatoryFunc}>
							<div className="flex justify-center items-center space-x-1">
								<Plus className="stroke-current" />
								<span>Invite More</span>
							</div>
						</ButtonComp>
					</div> */}
                    <div className="flex w-full flex-col items-center justify-center pt-6 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                        {accountSignatories && accountSignatories.length > 0 && (
                            <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                <ButtonComp
                                    type="button"
                                    color="grey"
                                    ripple="light"
                                    size="md"
                                    buttonType="secondary"
                                    fullWidth
                                    func={handleResetNewSignatories}
                                >
                                    <span>Cancel</span>
                                </ButtonComp>
                            </div>
                        )}
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <ButtonComp
                                type="button"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                isLoading={isAddSignatoryLoading}
                                size="md"
                                // disable={isAddSignatoryFormDisabled}
                                disable={
                                    !!(
                                        (
                                            newSignatoryItems.some((_item) => !useEmailCheck(_item.email)) ||
                                            newSignatoryItems.some((_item) => _item.firstName.length < 1) ||
                                            newSignatoryItems.some((_item) => _item.surname.length < 1) ||
                                            (accountSignatories?.some((_item) => _item.isKeyContact)
                                                ? false
                                                : !newSignatoryItems.some((_item) => _item.isKeyContact)) ||
                                            isSignatoriesMaxed ||
                                            newSignatoryItems.some((_item) => _item.isSignatory && !_item.userRole)
                                        )
                                        // newSignatoryItems.some((_item) => !_item.isSignatory && !_item.userRole)
                                    )
                                }
                                fullWidth
                                func={handleSubmitNewSignatories}
                            >
                                <span>Send Invite</span>
                            </ButtonComp>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddSignatories;
