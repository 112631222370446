import { Button, ButtonProps } from "@nextui-org/react";
import { LencoButtonProps, getButtonClass, getLoaderColor, getLoaderSize } from "./button.constant";

import LencoSpinner from "../spinner";

function LencoButton(props: ButtonProps & LencoButtonProps) {
    const { type = "button" } = props;
    return (
        <>
            <Button
                {...props}
                color={undefined}
                // size={props.size || "lg"}
                size={undefined}
                type={props.isDisabled ? "button" : type}
                radius={props.radius || "sm"}
                onClick={props.isDisabled ? undefined : props.onClick}
                tabIndex={props.noTabIndex || props.isDisabled ? -1 : 0}
                className={getButtonClass(props)}
                data-type={(props.dataType && props.dataType) || props.datatype}
                isLoading={!!props.isLoading}
                spinner={<></>}
            >
                <div
                    className={`absolute left-0 top-0 h-full w-full items-center justify-center ` + `${props.isLoading ? "flex" : "hidden"} `}
                    data-type={props.dataType && props.dataType}
                >
                    <LencoSpinner
                        size={getLoaderSize(props.size) || "md"}
                        color={getLoaderColor(props.variant || "solid", props.buttonColor || "black")}
                        data-type={props.dataType && props.dataType}
                    />
                </div>
                <div className={`${props.isLoading ? "opacity-0" : ""} ` + ` ${props.fullWidth ? "w-full" : ""}`}>
                    {props.isText ? <span>{props.children}</span> : props.children}
                </div>
            </Button>
        </>
    );
}

export default LencoButton;
