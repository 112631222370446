import { NotificationChannel, NotificationChannelText } from "../../../../../../../models/userAccount.constants";

// import { ReactComponent as CancelIcon } from "../../../../../../../assets/svg/Transfer/Cancel.svg";
import { TailSpin } from "react-loader-spinner";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";
import useRemoveTeamMemberNotificationChannel from "../../../../Services/team-members/hooks/useRemoveTeamMemberNotificationChannel";

interface TeamMemberNotificationChannelItemProps {
    accountMeta: UserAccountMeta;
    channel: NotificationChannel;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    canRemove: boolean;
}

function TeamMemberNotificationChannelItem(props: TeamMemberNotificationChannelItemProps): JSX.Element {
    const { isRemoving, handleRemoveNotificationChannel } = useRemoveTeamMemberNotificationChannel(props.updateAccountMeta);

    return (
        <div
            className={
                `flex h-6 max-w-max items-center justify-center space-x-1.5 rounded-md border border-grey px-2 py-1 text-xs ${props.canRemove ? "bg-white" : "bg-grey-tertiary"} ` +
                "relative flex cursor-default whitespace-nowrap text-black-secondary" +
                `${props.canRemove ? "cursor-pointer hover:bg-grey-tertiary" : ""}`
            }
        >
            <div>{NotificationChannelText[props.channel]}</div>

            {props.canRemove && (
                <div className="flex items-center">
                    {isRemoving && (
                        <div className="pointer-events-none ml-3 flex h-full items-center justify-center">
                            <TailSpin color="#5466F9" height={15} width={15} />
                        </div>
                    )}

                    {!isRemoving && (
                        <span
                            className="cursor-pointer text-xs"
                            onClick={() => void handleRemoveNotificationChannel(props.accountMeta, props.channel)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="#999999">
                                <path d="m339-288 141-141 141 141 51-51-141-141 141-141-51-51-141 141-141-141-51 51 141 141-141 141 51 51ZM480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z" />
                            </svg>
                        </span>
                    )}
                </div>
            )}
        </div>
    );
}

export default TeamMemberNotificationChannelItem;
