import { AxiosError } from "axios";
import { GenericObject } from "../types";
import Parsers from "../../utils/parsers";
import { errorTrue } from "../../redux/app-toast/app-toast-slice";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import store from "../../redux/store";

function errorhandler(err: AxiosError, isLanding?: boolean) {
    let errorMessage = "";
    if (err instanceof Error) {
        errorMessage = err.message;
    } else if (typeof err === "object" && !isNullOrUndefined(err) && "message" in err) {
        errorMessage = Parsers.string((err as GenericObject).message);
    } else if (typeof err === "string") {
        errorMessage = err;
    }

    if (!isLanding) {
        store.dispatch(errorTrue(errorMessage));
    }
}

export default errorhandler;
