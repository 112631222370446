import { Skeleton } from "@nextui-org/react";

function SelectBusinessSkeleton(): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-row items-center justify-start space-x-4">
                <div className="flex items-center justify-center">
                    <div className="h-8 w-8">
                        <Skeleton className="w-full" />
                    </div>
                </div>
                <div className="w-full max-w-10">
                    <Skeleton className="w-full" />
                </div>
            </div>
        </>
    );
}

export default SelectBusinessSkeleton;
