import { useCallback, useEffect, useState } from "react";

import ApplicationDeleteModal from "../../../../../modals/deleteModal";
import DropdownHead from "../../../../../../../../../components/dropdown/dropdown-components/dropdown-head";
import DropdownLink from "../../../../../../../../../components/dropdown/dropdown-components/dropdown-link";
import Referee from "../../../../../../../../../models/account-opening/referee";
import GoogleIcon from "../../../../../../../../../components/google-icon";

interface RefereesLineDropdownProps {
    data: Referee;
    isDeleteLoaded?: boolean | null;
    removeRefereeError: string;

    deleteFunc: () => void;
}

function RefereesLineDropdown(props: RefereesLineDropdownProps): JSX.Element {
    const [isRemoveRefereeModalOpen, setIsRemoveRefereeModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.isDeleteLoaded) return;
        setIsRemoveRefereeModalOpen(false);
    }, [props.isDeleteLoaded]);

    const handleRemoveReferee = useCallback(() => {
        props.deleteFunc();
    }, []);

    const handleCloseRemoveRefereeModal = useCallback(() => {
        setIsRemoveRefereeModalOpen(false);
    }, []);

    const handleOpenRemoveRefereeModal = useCallback(() => {
        setIsRemoveRefereeModalOpen(true);
    }, []);

    return (
        <>
            <ApplicationDeleteModal
                header="Remove Referee"
                onSubmit={handleRemoveReferee}
                error={props.removeRefereeError}
                deleteButtonText="Delete"
                active={isRemoveRefereeModalOpen}
                isSubmitLoading={!!props.isDeleteLoaded}
                toggler={handleCloseRemoveRefereeModal}
                body={
                    <>
                        <span className="w-full whitespace-normal break-words text-left text-base font-normal text-black-tertiary">
                            This action cannot be undone. Are you sure you want to remove {props.data.name} as a referee ?
                        </span>
                    </>
                }
            />
            <div>
                <DropdownHead
                    placeholder={<GoogleIcon icon="more_horiz" size="lg" />}
                    placement="right"
                    icon
                    placeholderClose={undefined}
                    outlineBg={false}
                    outlineBorder={false}
                    outlineBorderHover={false}
                    noOutlineBorder={false}
                    noHoverBg={false}
                    value={false}
                    size={"xs"}
                    filled={false}
                    isLoading={false}
                    color={""}
                    clickAndClose
                >
                    <DropdownLink onClick={handleOpenRemoveRefereeModal} redHover>
                        <div className="flex h-full w-full flex-row items-center justify-start">
                            <GoogleIcon icon="delete" size="sm" isOutlined />
                            <span className="ml-4 text-sm">Revoke Invite</span>
                        </div>
                    </DropdownLink>
                </DropdownHead>
            </div>
        </>
    );
}

export default RefereesLineDropdown;
