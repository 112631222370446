// import { ReactComponent as Cancel } from "../../../../../assets/svg/ChangeableTrash.svg";
// import { ReactComponent as DeleteIcon } from "../../../../../assets/svg/TrashIcon.svg";
// import { ReactComponent as Edit } from "../../../../../assets/svg/InactiveTrash.svg";
// import { ReactComponent as EditIcon } from "../../../../../assets/svg/EditIcon.svg";
import GoogleIcon from "../../../../../components/google-icon";
// import LineDropdown from "../../../../../components/dropdown/dropdown-components/line-dropdown";
// import LineDropdownLink from "../../../../../components/dropdown/dropdown-components/line-dropdown-link";
import NewDropdownHead from "../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import { PaymentsType } from "../../hooks/state/send-money/payments.constants";
import { Recipient } from "../../hooks/state/pending-approval/use-saved-recipients-state.types";
import { RecipientActionType } from "../../../../../redux/payments/recipients/slice/recipientsSlice.types";
// import { ReactComponent as SingleTransferIcon } from "../../../../../assets/svg/SingleTransferIcon.svg";
import { setRecipientsAction } from "../../../../../redux/payments/recipients/slice/recipientsSlice";
import { useDispatch } from "react-redux";

interface Props {
    recipient: Recipient;
}

function RecipientItemActions({ recipient }: Props): JSX.Element {
    const dispatch = useDispatch();

    return (
        <>
            <NewDropdownHead content={<GoogleIcon icon="more_vert" />} buttonType="tertiary" dropdownWidth="max" dropdownPosition="right" isNoArrow>
                <NewDropdownItem
                    linkTo={{
                        pathname: "/payments/make/single",
                        search: `?to=${recipient.customerAccount.id || ""}`,
                    }}
                    state={{
                        typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                        to: recipient.customerAccount.id || "",
                    }}
                    size="md"
                    padding="md"
                    isLink
                >
                    {/* <SingleTransferIcon className="stroke-current" /> */}
                    <GoogleIcon icon="send" />
                    <span className="ml-2 text-sm">Send Money</span>
                </NewDropdownItem>
                <NewDropdownItem
                    onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.EDIT_ALIAS, recipient }))}
                    size="md"
                    padding="md"
                >
                    <GoogleIcon icon="edit_note" />
                    <span className="ml-2 text-sm">{`${recipient.customerAccount.hasUserGroup ? "Edit" : "Set"} Alias`}</span>
                </NewDropdownItem>
                <NewDropdownItem
                    onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.EDIT_RECIPIENT_GROUP, recipient }))}
                    size="md"
                    padding="md"
                >
                    <GoogleIcon icon="edit" />
                    <span className="ml-2 text-sm">{`${recipient.customerAccount.hasUserGroup ? "Edit" : "Set"} Group`}</span>
                </NewDropdownItem>
                <>
                    {recipient.customerAccount.hasUserGroup && (
                        <NewDropdownItem
                            onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.REMOVE_FROM_GROUP, recipient }))}
                            color="red"
                            size="md"
                            padding="md"
                        >
                            <GoogleIcon icon="delete" />
                            <span className="ml-2 text-sm">Remove from Group</span>
                        </NewDropdownItem>
                    )}
                </>
                <NewDropdownItem
                    onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.DELETE, recipient }))}
                    color="red"
                    size="md"
                    padding="md"
                >
                    <GoogleIcon icon="delete_forever" />
                    <span className="ml-2 text-sm">Delete</span>
                </NewDropdownItem>
            </NewDropdownHead>
            {/* <LineDropdown clickAndClose>
                <LineDropdownLink
                    text="Send Money"
                    icon={<SingleTransferIcon className="stroke-current" />}
                    to={{
                        pathname: "/payments/make/single",
                        search: `?to=${recipient.customerAccount.id || ""}`,
                    }}
                    state={{
                        state: {
                            typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                            to: recipient.customerAccount.id || "",
                        },
                    }}
                />

                <LineDropdownLink
                    text={`${recipient.customerAccount.hasUserGroup ? "Edit" : "Set"} Alias`}
                    icon={<Edit className="stroke-current" />}
                    onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.EDIT_ALIAS, recipient }))}
                />

                <LineDropdownLink
                    text={`${recipient.customerAccount.hasUserGroup ? "Edit" : "Set"} Category`}
                    icon={<EditIcon className="stroke-current" />}
                    onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.EDIT_RECIPIENT_GROUP, recipient }))}
                />

                {recipient.customerAccount.hasUserGroup && (
                    <LineDropdownLink
                        text="Remove from Category"
                        icon={<DeleteIcon className="stroke-current ease-in-out" />}
                        redHover
                        onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.REMOVE_FROM_GROUP, recipient }))}
                    />
                )}

                <LineDropdownLink
                    text="Delete"
                    icon={<Cancel className="stroke-current ease-in-out" />}
                    redHover
                    onClick={() => dispatch(setRecipientsAction({ type: RecipientActionType.DELETE, recipient }))}
                />
            </LineDropdown> */}
        </>
    );
}

export default RecipientItemActions;
