import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Num, add } from "../../../../../../utils/math";

import { SplitInflowRatio } from "../account-settings.types";
import UserAccount from "../../../../../../models/userAccount";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
// import { DropdownItem } from "../../../../../../helpers/types";
import { mapToArray } from "../../../../../../utils/map";
import { produce } from "immer";

interface FormInterface {
    numParts: number;
    ratio: Array<SplitInflowRatio>;
}

export interface UseEditSplitInflowInstructionResponse {
    isSubmitting: boolean;
    isFormValid: boolean;
    numPartsOptions: Array<number>;
    totalPercentage: number;
    form: FormInterface;
    setNumParts: (value: number) => void;
    setRatio: (index: number, accountId: string | undefined, percentage: number | undefined) => void;
    removeRatioItem: (index: number) => void;
    handleSubmit: (e: FormEvent) => void;
    getAccountOptions: (includeUserAccountId: string) => Array<UserAccount>;
}

function useEditSplitInflowInstruction(
    account: UserAccount,
    accountsMap: Map<string | number, UserAccount>,
    handleUpdateSplitInflowInstruction?: (ratio: SplitInflowRatio[], setIsSubmitting: Dispatch<SetStateAction<boolean>>) => Promise<void>
): UseEditSplitInflowInstructionResponse {
    const [form, setForm] = useState<FormInterface>({
        numParts: 2,
        ratio: [],
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [numPartsOptions, setNumPartsOptions] = useState<Array<number>>([]);
    const [totalPercentage, setTotalPercentage] = useState<number>(0);

    const setNumParts = useCallback(
        (value: number) => {
            if (value >= 2 && value <= 5) {
                setForm(
                    produce((draft) => {
                        draft.numParts = value;
                    })
                );
            }
        },
        [setForm]
    );

    const setRatio = useCallback(
        (index: number, accountId: string | undefined, percentage: number | undefined) => {
            setForm(
                produce((draft) => {
                    if (draft.ratio.length > index) {
                        if (!isNullOrUndefined(accountId)) {
                            draft.ratio[index].userAccountId = accountId;
                        }
                        if (!isNullOrUndefined(percentage) && percentage >= 0 && percentage <= 100) {
                            draft.ratio[index].percentage = percentage;
                        }
                    }
                })
            );
        },
        [setForm]
    );
    // // populate "num parts" options
    useEffect(() => {
        const res: number[] = [];
        for (let i = 2; i <= 5; i++) {
            if (accountsMap.size >= i) {
                res.push(i);
            }
        }
        setNumPartsOptions(res);
    }, [accountsMap]);

    // // populate "num parts" options
    // useEffect(() => {
    //     const res = Array.from({ length: 4 }, (_, i) => i + 2).filter((num) => accountsMap.size >= num);

    //     // Only update if the result is different to prevent unnecessary re-renders
    //     setNumPartsOptions((prevOptions) => {
    //         if (prevOptions.length === res.length && prevOptions.every((val, idx) => val === res[idx])) {
    //             return prevOptions; // No need to update if the options haven't changed
    //         }
    //         return res;
    //     });
    // }, [accountsMap]);

    useEffect(() => {
        setForm(() => {
            if (account.splitInflowInstruction?.ratio) {
                return {
                    ratio: account.splitInflowInstruction.ratio,
                    numParts: account.splitInflowInstruction.ratio.length,
                };
            } else {
                // set the first one
                return {
                    ratio: [
                        { percentage: 0, userAccountId: account.id },
                        { percentage: 0, userAccountId: "" },
                    ],
                    numParts: 2,
                };
            }
        });
    }, [account]);

    useLayoutEffect(() => {
        setForm(
            produce((draft) => {
                if (draft.ratio.length > draft.numParts) {
                    draft.ratio.splice(draft.numParts);
                } else if (draft.ratio.length < draft.numParts) {
                    for (let i = draft.ratio.length; i < draft.numParts; i++) {
                        draft.ratio.push({ percentage: 0, userAccountId: "" });
                    }
                }
            })
        );
    }, [form.numParts]);

    useLayoutEffect(() => {
        setTotalPercentage(add(...form.ratio.map((item) => item.percentage)));
    }, [form.ratio]);

    useLayoutEffect(() => {
        setIsFormValid(
            form.numParts >= 2 &&
                form.numParts === form.ratio.length &&
                form.ratio.some((item) => item.userAccountId === account.id) &&
                form.ratio.every((item) => !!item.userAccountId && item.percentage > 0 && item.percentage < 100) &&
                form.ratio.reduce((accumulator: Num, item: SplitInflowRatio) => accumulator.add(item.percentage), new Num(0)).valueOf() === 100
        );
    }, [form]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isFormValid) {
            handleUpdateSplitInflowInstruction && void handleUpdateSplitInflowInstruction(form.ratio, setIsSubmitting);
        }
    };

    const removeRatioItem = (index: number) => {
        if (form.ratio.length > index && form.ratio.length > 2) {
            setForm(
                produce((draft) => {
                    draft.ratio.splice(index, 1);
                    draft.numParts = draft.ratio.length;
                })
            );
        }
    };

    const getAccountOptions = (includeUserAccountId: string): Array<UserAccount> => {
        return mapToArray(accountsMap)
            .filter((a) => {
                // if is includeUserAccountId, include it
                if (a.id === includeUserAccountId) {
                    return true;
                }
                // if is already selected, exclude it
                if (form.ratio.some((r) => r.userAccountId === a.id)) {
                    return false;
                }

                // otherwise, include it
                return true;
            })
            .sort((a1: UserAccount, a2: UserAccount) => {
                if (a1.isMain && !a2.isMain) {
                    return -1;
                } else if (a2.isMain && !a1.isMain) {
                    return 1;
                } else {
                    return a1.lencoNameMin.localeCompare(a2.lencoNameMin);
                }
            });
    };

    // const getAccountOptions = (
    // includeUserAccountId: string
    // ): Array<DropdownItem<string>> => {
    // return mapToArray(accountsMap)
    // .filter((a) => {
    // // if is includeUserAccountId, include it
    // if (a.id === includeUserAccountId) {
    // return true;
    // }
    // // if is already selected, exclude it
    // if (form.ratio.some((r) => r.userAccountId === a.id)) {
    // return false;
    // }

    // // otherwise, include it
    // return true;
    // })
    // .sort((a1: UserAccount, a2: UserAccount) => {
    // if (a1.isMain && !a2.isMain) {
    // return -1;
    // } else if (a2.isMain && !a1.isMain) {
    // return 1;
    // } else {
    // return a1.lencoNameMin.localeCompare(a2.lencoNameMin);
    // }
    // })
    // .map((a) => ({
    // value: a.id,
    // text: a.lencoNameMin,
    // }));
    // };

    return {
        isSubmitting,
        isFormValid,
        numPartsOptions,
        totalPercentage,
        form,
        setNumParts,
        setRatio,
        removeRatioItem,
        handleSubmit,
        getAccountOptions,
    };
}

export default useEditSplitInflowInstruction;
