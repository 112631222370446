import {
    NigeriaAccountOpeningInitRequests,
    abortNigeriaAccountOpeningInitRequest,
    nigeriaAccountOpeningInit,
} from "../../../services/nigeria-application/nigeiraAccountOpeningInit";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { setAccountOpeningInit } from "../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface UseNigeriaInitInterface {
    nigeriaAccountOpeningInitError: string | null;
    isNigeriaAccountOpeningInitLoading: boolean;
    handleReset: () => void;
    handleNigeriaAccountOpeningInit: () => Promise<void>;
}

const useNigeriaInit = (): UseNigeriaInitInterface => {
    const [nigeriaAccountOpeningInitError, setNigeriaAccountOpeningInitError] = useState<string | null>(null);
    const [isNigeriaAccountOpeningInitLoading, setNigeriaAccountOpeningInitLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleNigeriaAccountOpeningInit = useCallback(async (): Promise<void> => {
        try {
            setNigeriaAccountOpeningInitError(null);
            setNigeriaAccountOpeningInitLoading(true);
            const res = await nigeriaAccountOpeningInit();
            dispatch(setAccountOpeningInit(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            setNigeriaAccountOpeningInitError(errorMessage);
            dispatch(errorTrue(errorMessage));
        } finally {
            setNigeriaAccountOpeningInitLoading(false);
        }
    }, [dispatch]);

    const handleReset = () => {
        setNigeriaAccountOpeningInitError(null);
        setNigeriaAccountOpeningInitLoading(false);
        abortNigeriaAccountOpeningInitRequest(NigeriaAccountOpeningInitRequests.INIT);
    };

    return {
        nigeriaAccountOpeningInitError,
        isNigeriaAccountOpeningInitLoading,
        handleReset,
        handleNigeriaAccountOpeningInit,
    };
};
export default useNigeriaInit;
