import { FieldHelperProps, FormikErrors } from "formik";
import React, { useEffect } from "react";
import { CardWalletType } from "../../../../../../models/card.constants";
import RadioButton from "../../../../../../components/radio-button";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import LencoButton from "../../../../../../components/button/button";
import { CardUsageStage } from "../../../Types";

type CardAccessForm = {
    walletType: CardWalletType;
};
interface ICardSelectionProps {
    values: CardAccessForm;
    getFieldHelpers: <Value>(name: string) => FieldHelperProps<Value>;
    errors: FormikErrors<CardAccessForm>;
    onCancel: () => void;
    onNext: (stage: CardUsageStage) => void;
}

function CardSelection({ values, getFieldHelpers, errors, onCancel, onNext }: ICardSelectionProps): JSX.Element {
    useEffect(() => {
        if (values.walletType === CardWalletType.INDEPENDENT_WALLET) {
            getFieldHelpers("linkedAccountId").setValue("");
        }
    }, [values.walletType]);

    return (
        <>
            <ModalBody>
                <div className="space-y-6">
                    <div
                        className={`${
                            values.walletType === CardWalletType.LINKED_TO_ACCOUNT ? "border-blue" : "border-transparent"
                        } rounded-lg border-2 bg-blue-backdrop p-4`}
                        onClick={() => {
                            getFieldHelpers("walletType").setValue(CardWalletType.LINKED_TO_ACCOUNT);
                        }}
                    >
                        <div className="cursor-pointer space-y-2">
                            <div>
                                <RadioButton id="walletType" text="Debit Card" checked={values.walletType === CardWalletType.LINKED_TO_ACCOUNT} />
                            </div>
                            <div>
                                <p className="text-sm text-black-tertiary">
                                    This card would be directly linked to your account. Your account balance will be debited per transactions.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${
                            values.walletType === CardWalletType.INDEPENDENT_WALLET ? "border-blue" : "border-transparent"
                        } rounded-lg border-2 bg-blue-backdrop p-4`}
                    >
                        <div
                            className="cursor-pointer space-y-2"
                            onClick={() => getFieldHelpers("walletType").setValue(CardWalletType.INDEPENDENT_WALLET)}
                        >
                            <div>
                                <RadioButton id="walletType" text="Prepaid Card" checked={values.walletType === CardWalletType.INDEPENDENT_WALLET} />
                            </div>
                            <div>
                                <p className="text-sm text-black-tertiary">
                                    This card would NOT be linked to your account. It would have its balance which you will have to fund
                                </p>
                            </div>
                        </div>
                    </div>
                    {errors.walletType && <p className="pl-2 text-xs text-error">{errors.walletType}</p>}
                </div>
            </ModalBody>

            <ModalFooter>
                <>
                    <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                        <LencoButton onClick={onCancel} type="button" color="primary" size="sm" variant="bordered" isText>
                            <span className="normal-case tracking-wider">Cancel</span>
                        </LencoButton>
                    </div>
                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                        <LencoButton
                            type="button"
                            color="primary"
                            size="sm"
                            variant="solid"
                            isText
                            isDisabled={!!errors.walletType}
                            onClick={() => onNext(CardUsageStage.CUSTOMIZATION)}
                        >
                            <span className="normal-case tracking-wider">Proceed</span>
                        </LencoButton>
                    </div>
                </>
            </ModalFooter>
        </>
    );
}

export default CardSelection;
