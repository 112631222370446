import AgreementGeneric from "./agreementGeneric";
import { GenericObject } from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import { immerable } from "immer";

export default class Agreement {
    [immerable] = true;

    constructor(
        public searchReport: AgreementGeneric,
        public terms: AgreementGeneric,
        public declaration: AgreementGeneric,
        public mandateCard: AgreementGeneric,
        public boardResolution: AgreementGeneric
    ) {}

    static create(obj: GenericObject): Agreement {
        return new Agreement(
            Parsers.classObjectNonNullable(obj.searchReport, AgreementGeneric),
            Parsers.classObjectNonNullable(obj.terms, AgreementGeneric),
            Parsers.classObjectNonNullable(obj.declaration, AgreementGeneric),
            Parsers.classObjectNonNullable(obj.mandateCard, AgreementGeneric),
            Parsers.classObjectNonNullable(obj.boardResolution, AgreementGeneric)
        );
    }
}
