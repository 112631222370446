import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import AvatarDetails from "../../../../../../components/avatar-details";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../redux/rootReducer";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import TransferBetweenAccountPaymentHeaders from "./TransferBetweenAccountPaymentHeaders";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import getBankName from "../../../../../../helpers/getBankName";
import getInitials from "../../../../../../helpers/get-initials";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import { setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import titleCase from "../../../../../../hooks/titleCase";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";
import useTransferBetweenPurpose from "../../../hooks/state/send-money/TransferBetween/useTransferBetween";

function ReviewPayment(): JSX.Element {
    const dispatch = useDispatch();
    const { isTransferBetweenLoading, transferBetweenAccountResponse, handleTransferBetween } = useTransferBetweenPurpose();
    const selectedCard = useSelector((state: IRootState) => state.transferBetween.selectedCard);
    const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
    const selectedUserAccount = useSelector((state: IRootState) => state.transferBetween.selectedUserAccount);
    const recipientBankAccount = useSelector((state: IRootState) => state.transferBetween.recipientBankAccount);
    const transferBetweenDetails = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails);

    const canApprove = useMemo((): boolean => {
        if (selectedCard) return true;
        if (!userAccountsMeta || !selectedUserAccount) return false;
        const doesAccountExist = userAccountsMeta.some((userAccountMeta) => userAccountMeta.userAccountId === selectedUserAccount.id);
        if (!isNullOrUndefined(doesAccountExist)) {
            const userAccountMeta = userAccountsMeta.find((_el) => _el.userAccountId === selectedUserAccount.id) as UserAccountMeta;
            return userAccountMeta.isApprover
                ? userAccountMeta.maxApprovalAmount
                    ? useMoneyToNumber(transferBetweenDetails.amount) <= userAccountMeta.maxApprovalAmount
                    : true
                : false;
        }
        return false;
    }, [selectedCard, userAccountsMeta, selectedUserAccount, transferBetweenDetails]);

    useEffect(() => {
        if (canApprove || !transferBetweenAccountResponse) return;
        dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
    }, [canApprove, transferBetweenAccountResponse]);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-9">
                <TransferBetweenAccountPaymentHeaders />
                <div className="flex w-full flex-col space-y-6">
                    <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                        <div className="flex flex-col items-center justify-center space-y-3 pb-6">
                            <AvatarDetails
                                size="lg"
                                initials={getInitials(selectedUserAccount?.bankAccount?.accountName || "")}
                                icon="outflow"
                                fullWidth={false}
                            />
                            <p className="text-sm font-normal !leading-[100%] text-black-tertiary">You&apos;re sending</p>
                            <p className="text-[32px] font-medium !leading-[100%] text-black">
                                <Money amount={useMoneyToNumber(transferBetweenDetails.amount)} />
                            </p>
                        </div>
                        <div className="flex w-full flex-col space-y-3">
                            <p className="text-sm font-medium leading-[100%] text-black-tertiary">From</p>
                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Account Name</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {selectedCard && titleCase(selectedCard.name)}
                                        {selectedUserAccount && titleCase(selectedUserAccount.bankAccount?.accountName || "")}
                                    </p>
                                </div>
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Account Number</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {selectedCard && titleCase(selectedCard?.maskedPan || "")}
                                        {selectedUserAccount && selectedUserAccount.bankAccount?.accountNumber}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col space-y-3">
                            <p className="text-sm font-medium leading-[100%] text-black-tertiary">To</p>
                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Account Name</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {titleCase(recipientBankAccount?.accountName || "")}
                                    </p>
                                </div>
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Bank Name</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {titleCase(getBankName(recipientBankAccount?.bankCode))}
                                    </p>
                                </div>
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Purpose</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {transferBetweenDetails && transferBetweenDetails.purpose}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MessageToast
                        type="security"
                        message="Ensure you verify that the recipient is genuine as payments can not be reversed after approval."
                        fullWidth
                    />
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp
                        size="xl"
                        type="button"
                        color="grey"
                        ripple="light"
                        buttonType="secondary"
                        func={() => dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS))}
                    >
                        Back
                    </ButtonComp>
                    <ButtonComp
                        size="xl"
                        type="button"
                        color="black"
                        ripple="light"
                        buttonType="primary"
                        func={() => {
                            canApprove
                                ? dispatch(setPaymentStage(PaymentStageType.MAKE_PAYMENT))
                                : void handleTransferBetween(transferBetweenDetails);
                        }}
                        isLoading={isTransferBetweenLoading}
                    >
                        {canApprove ? "Make Payment" : "Initiate Payment"}
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default ReviewPayment;
