import { GenericObject } from "../../../helpers/types";
import { IncorporationDocumentType } from "../../../modules/dashboard/applicationn/services/zambia-application/registered/new/document.types";
import Parsers from "../../../utils/parsers";
import { immerable } from "immer";

export default class IncorporationDocumentZambia {
    [immerable] = true;

    constructor(
        public type: IncorporationDocumentType | null,
        public uploadId: string
    ) {}

    static create(obj: GenericObject): IncorporationDocumentZambia {
        return new IncorporationDocumentZambia(Parsers.number(obj.type), Parsers.string(obj.uploadId));
    }
}
