import { GenericObject } from "../../helpers/types";
import { OtherDirectorStatus } from "./accountOpening.constants";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class OtherDirectorMin {
    [immerable] = true;

    constructor(
        public id: string,
        public firstName: string,
        public surname: string,
        public email: string,
        public status: OtherDirectorStatus
    ) {}

    static create(obj: GenericObject): OtherDirectorMin {
        return new OtherDirectorMin(
            Parsers.string(obj.id),
            Parsers.string(obj.firstName),
            Parsers.string(obj.surname),
            Parsers.string(obj.email),
            Parsers.number(obj.status)
        );
    }

    get isPending(): boolean {
        return this.status === OtherDirectorStatus.INVITE_PENDING;
    }

    get isCompleted(): boolean {
        return this.status === OtherDirectorStatus.COMPLETED;
    }

    get isRemoved(): boolean {
        return this.status === OtherDirectorStatus.REMOVED;
    }

    get name(): string {
        return `${this.firstName} ${this.surname}`.trim();
    }

    get statusText(): string {
        if (this.isCompleted) {
            return "Completed";
        } else if (this.isPending) {
            return "Pending";
        } else {
            return "Removed";
        }
    }
}
