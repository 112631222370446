import { HintType, ListStyle } from "../../../../../../element/hint/hint.constant";
import { useCallback, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import AddSignatories from "./components/AddSignatories";
import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../../../services/zambia-application/registered/new/company.types";
import EditSignatory from "./components/EditSignatory";
import ErrorToast from "../../../../../../../../../../components/message-toast/sub/error-toast";
import Hint from "../../../../../../element/hint";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../../../components/spinner";
import OtherDirectorMin from "../../../../../../../../../../models/account-opening/otherDirectorMin";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import SignatoryCard from "./components/SignatoryCard";
import ZambiaSignatoryMin from "../../../../../../../../../../models/account-opening/zambiaSignatoryInfoMin";
import useAccountSignatoriesInit from "../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useAccountSignatoriesInit";
import { useSelector } from "react-redux";

const SignatoriesDetailsGuides: CompanyHint[] = [
    {
        index: 0,
        span: <span>Provide the details of your business directors.</span>,
    },
    {
        index: 1,
        span: <span>An account signatory has access to manage and maintain the business account.</span>,
    },
];
type LocationState = {
    from?: string;
};

function SignatoriesDetails(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { accountSignatoriesError, isAccountSignatoriesLoading, handleAccountSignatoriesInit } = useAccountSignatoriesInit();

    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const accountSignatories = useSelector((state: IRootState) => state.zambiaApplication.accountSignatories);

    const [editData, setEditData] = useState<ZambiaSignatoryMin | OtherDirectorMin | null>(null);
    const [isHideAllButtons, setIsHideAllButtons] = useState(false);
    const [isAddingSignatory, setIsAddingSignatory] = useState(false);
    const [isEditingSignatory, setIsEditingSignatory] = useState(false);

    const doesKeyContactExist = (accountSignatories?.signatories.length || 0) > 0;
    const signatories = accountSignatories?.signatories || null;

    useLayoutEffect(() => {
        void handleAccountSignatoriesInit();
    }, []);

    const handleEditSignatoryFun = useCallback((_signatory: ZambiaSignatoryMin | OtherDirectorMin) => {
        setEditData(_signatory);
        setIsEditingSignatory(true);
    }, []);

    const handleCloseEditSignatoryForm = useCallback(() => {
        setIsEditingSignatory(false);
        setIsAddingSignatory(false);
        setEditData(null);
    }, []);

    const handleBack = () => {
        navigate(locationState?.from || Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL);
    };

    return (
        <>
            {isAccountSignatoriesLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {accountSignatoriesError && (
                <div className="flex h-full w-full items-start justify-center">
                    <ErrorToast error={accountSignatoriesError} />
                </div>
            )}
            {!isAccountSignatoriesLoading && !accountSignatoriesError && (
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    {accountOpening && (
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Tell us about your Signatories and Directors"
                                subTitle="Invite all registered directors of the company to complete their profile and assign account signatories"
                                progress={accountOpening.progress.signatoriesCompletedProgress()}
                            >
                                <div className="w-full">
                                    <div className="flex w-full flex-col items-start justify-start space-y-8">
                                        {isEditingSignatory ? (
                                            <EditSignatory data={editData} handleCloseEditSignatoryForm={handleCloseEditSignatoryForm} />
                                        ) : (
                                            <AddSignatories
                                                addSignatory={isAddingSignatory}
                                                isEditingSignatory={isEditingSignatory}
                                                handleResetAddSignatory={() => setIsAddingSignatory(false)}
                                                handleHideAllButtons={(_value) => setIsHideAllButtons(_value)}
                                                handleCloseEditSignatory={() => setIsEditingSignatory(false)}
                                            />
                                        )}
                                        {signatories &&
                                            signatories.map((_signatories, index) => (
                                                <SignatoryCard key={index} data={_signatories} editFunc={handleEditSignatoryFun} />
                                            ))}
                                        {!isAddingSignatory && !isEditingSignatory && (
                                            <div className="!mt-4 flex w-full flex-row items-center justify-start">
                                                <ButtonComp
                                                    type="button"
                                                    color="blue"
                                                    ripple="light"
                                                    buttonType="tertiary"
                                                    func={() => setIsAddingSignatory(true)}
                                                    size="sm"
                                                >
                                                    <div className="flex items-center justify-center space-x-1">
                                                        <div className="flex max-w-[20px] items-center justify-center overflow-hidden">
                                                            <span className="material-symbols-rounded text-lg">add_circle</span>
                                                        </div>
                                                        <span className="font-medium">Add Other Directors</span>
                                                    </div>
                                                </ButtonComp>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ApplicationHeader>
                            {!isEditingSignatory && !isHideAllButtons && (
                                <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                    <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                        <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                            <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                        </ButtonComp>
                                    </div>
                                    {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                        <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                            <ButtonComp
                                                type="button"
                                                color="black"
                                                ripple="light"
                                                buttonType="primary"
                                                fullWidth
                                                func={() =>
                                                    navigate(
                                                        {
                                                            pathname: doesKeyContactExist
                                                                ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS
                                                                : Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.KEY_CONTACT,
                                                        },
                                                        {
                                                            state: {
                                                                from: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.DETAILS,
                                                            },
                                                        }
                                                    )
                                                }
                                            >
                                                <span>Save and Continue</span>
                                            </ButtonComp>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={SignatoriesDetailsGuides} />
                </div>
            )}
        </>
    );
}

export default SignatoriesDetails;
