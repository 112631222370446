import { ZambiaCollectionPaymentChannel, ZambiaRequestPaymentAttemptStatus } from "./zambiaCollectionRequest.constant";

import Collection from "../collection";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import ZambiaCardPaymentCollection from "./zambiaCardPaymentCollection";
import { immerable } from "immer";

export default class ZambiaRequestPaymentAttempt {
    [immerable] = true;

    constructor(
        public id: string,
        public startedAt: string,
        public status: ZambiaRequestPaymentAttemptStatus | null,
        public completedAt: string | null,
        public failedReason: string | null,
        public channel: ZambiaCollectionPaymentChannel | null,
        public cardPaymentCollection: ZambiaCardPaymentCollection | null,
        public mobileMoneyCollection: Collection | null
    ) {}

    static create(obj: GenericObject): ZambiaRequestPaymentAttempt {
        return new ZambiaRequestPaymentAttempt(
            Parsers.string(obj.id),
            Parsers.string(obj.startedAt),
            Parsers.nullableEnum(obj.status, ZambiaRequestPaymentAttemptStatus),
            Parsers.nullableString(obj.completedAt),
            Parsers.nullableString(obj.failedReason),
            Parsers.nullableEnum(obj.channel, ZambiaCollectionPaymentChannel),
            Parsers.classObject(obj.cardPaymentCollection, ZambiaCardPaymentCollection),
            Parsers.classObject(obj.mobileMoneyCollection, Collection)
        );
    }

    get isSuccessful(): boolean {
        return this.status === ZambiaRequestPaymentAttemptStatus.SUCCESSFUL;
    }

    get isFailed(): boolean {
        return this.status === ZambiaRequestPaymentAttemptStatus.FAILED;
    }

    get isPending(): boolean {
        return this.status === ZambiaRequestPaymentAttemptStatus.PENDING;
    }

    get isExpired(): boolean {
        return this.status === ZambiaRequestPaymentAttemptStatus.EXPIRED;
    }
    get isCancelled(): boolean {
        return this.status === ZambiaRequestPaymentAttemptStatus.CANCELLED;
    }

    get isCard(): boolean {
        return this.channel === ZambiaCollectionPaymentChannel.CARD;
    }

    get channelText(): string {
        return this.channel === ZambiaCollectionPaymentChannel.BANK_ACCOUNT
            ? "Bank Account"
            : this.channel === ZambiaCollectionPaymentChannel.CARD
              ? "Card"
              : this.channel === ZambiaCollectionPaymentChannel.MOBILE_MONEY
                ? "Mobile Money"
                : "-";
    }
}
