import {
    AccountSignatoriesRequests,
    abortAccountSignatoriesRequest,
    setSignatoryAsKeyContact,
} from "../../../../../services/zambia-application/registered/new/accountSignatories.api";
import { useCallback, useState } from "react";

import { SignatoryActionRequest } from "../../../../../services/zambia-application/registered/new/accountSignatories.types";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { setZambiaAccountSignatories } from "../../../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface useSignatoriesInterface {
    isSetAsKeyContactLoading: boolean | null;

    handleReset: () => void;
    handleSetAsKeyContact: (data: SignatoryActionRequest) => Promise<void>;
}

const useSetAsKeyContact = (): useSignatoriesInterface => {
    const dispatch = useDispatch();

    const [isSetAsKeyContactLoading, setIsSetAsKeyContactLoading] = useState<boolean | null>(false);

    const handleSetAsKeyContact = useCallback(async (_data: SignatoryActionRequest) => {
        try {
            setIsSetAsKeyContactLoading(true);
            const res = await setSignatoryAsKeyContact(_data);
            dispatch(setZambiaAccountSignatories(res));
            dispatch(messageTrue({ message: `Signatory updated successfully` }));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsSetAsKeyContactLoading(false);
        }
    }, []);

    const handleReset = useCallback(() => {
        abortAccountSignatoriesRequest(AccountSignatoriesRequests.SET_SIGNATORY_AS_KEY_CONTACT);
    }, []);

    return {
        isSetAsKeyContactLoading,
        handleReset,
        handleSetAsKeyContact,
    };
};
export default useSetAsKeyContact;
