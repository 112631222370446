import { ZambiaFundingMethod, ZambiaFundingMethods } from "../../../../../../../redux/accounts/slice/accountsSlice.types";

import ButtonComp from "../../../../../../../components/button/ButtonComp";
import CustomSelect from "../../../../../../../components/dropdown/custom-select";
import { IRootState } from "../../../../../../../redux/rootReducer";
import Modal from "../../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../../components/modal/modal-header";
import UserAccountSelect from "../../../../../../../components/user-account-select";
import { useSelector } from "react-redux";

interface Props {
    paymentMethod: ZambiaFundingMethod | null;
    selectedAccountId: string;
    handleNext: () => void;
    handleClose: () => void;
    handleSelectAccount: (_accountId: string) => void;
    handleSelectPaymentMethod: (_paymentMethod: number) => void;
}

function SelectAccountAndMethod(props: Props): JSX.Element {
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    return (
        <>
            <Modal size="md" active toggler={props.handleClose}>
                <ModalHeader onClose={props.handleClose} subTitle="Select an account to fund">
                    Add Money
                </ModalHeader>
                <ModalBody>
                    <div className="flex w-full flex-col space-y-4">
                        <UserAccountSelect
                            label="Account to Fund"
                            value={props.selectedAccountId || ""}
                            options={accounts || null}
                            onClick={props.handleSelectAccount}
                            isDisabled={!!(accounts && accounts.length < 2)}
                            size="xl"
                            // initiatorCanSelect
                        />
                        <CustomSelect
                            placeholder="Payment Method"
                            value={props.paymentMethod}
                            options={ZambiaFundingMethods.map((item) => ({
                                text: item.name,
                                value: item.id,
                                icon: item.icon,
                            }))}
                            onSelect={(value) => value && props.handleSelectPaymentMethod(value)}
                            big
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                            <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleClose} fullWidth>
                                <span>Cancel</span>
                            </ButtonComp>
                        </div>
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <ButtonComp
                                type="button"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                disable={!props.selectedAccountId || !props.paymentMethod}
                                fullWidth
                                func={props.handleNext}
                            >
                                <span>Next</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default SelectAccountAndMethod;
