import { HintType, ListStyle } from "../../../../../dashboard/applicationn/components/element/hint/hint.constant";
import { useLocation, useNavigate } from "react-router";
import { useRef, useState } from "react";

import ApplicationHeader from "../../../../../dashboard/applicationn/components/element/ApplicationHeader";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../dashboard/applicationn/services/nigeria-application/registered/company.types";
import Hint from "../../../../../dashboard/applicationn/components/element/hint";
import { IRootState } from "../../../../../../redux/rootReducer";
import NewUploadedCard from "../../../../../dashboard/applicationn/components/cards/new-uploaded-card";
import PreviewModal from "../../../../../dashboard/applicationn/components/modals/preview-modal";
import RadioButton from "../../../../../../components/radio-button";
import { Routes } from "../../../../../../routes/routes.constants";
// import { ReactComponent as UploadCloud } from "../../../../../../assets/svg/upload-cloud.svg";
import useExternalDirectorPreview from "../../hookss/useExternalDirectorPreview";
import { useSelector } from "react-redux";
import useUploadExternalDirectorId from "../../hookss/useUploadExternalDirectorId";

const MeansOfIdHints: CompanyHint[] = [
    {
        index: 0,
        span: <span>Choose your preferred means of identification before uploading.</span>,
    },
    {
        index: 1,
        span: <span>Ensure your ID number is visible on your means of ID.</span>,
    },
    {
        index: 2,
        span: <span>Ensure your ID is within its validity period.</span>,
    },
];

interface LocationState {
    from?: string;
}

function IdentificationForm(): JSX.Element {
    const navigate = useNavigate();

    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const { previewBlob, handlePreviewDocument } = useExternalDirectorPreview({
        onComplete: () => setShowPreviewModal(true),
    });
    const { isExternalDirectorIdLoading, handleUploadExternalDirectorId } = useUploadExternalDirectorId({ onComplete: () => setIsUploaded(true) });

    const url = useSelector((state: IRootState) => state.externalApplication.externalLink || "");
    const externalParams = useSelector((state: IRootState) => state.externalApplication.externalParams);
    const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

    const director = externalApplicationInit?.director || null;

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [type, setType] = useState<number | null>(null);
    const [uploaded, setIsUploaded] = useState(!!director?.identification?.fileId);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);

    const handleReUpload = () => {
        setIsUploaded(false);
    };

    const handleBack = () => {
        locationState?.from === url ? navigate(url) : navigate(`${url}${Routes.EXTERNAL_DIRECTOR.NEXT_OF_KIN}`);
    };

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Identification Preview"
                />
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    {externalApplicationInit && director && (
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Means of ID"
                                subTitle="Provide us with a valid means of ID"
                                progress={uploaded ? 100 : 0}
                                withBorder={!uploaded}
                            >
                                <div className="w-full">
                                    {!uploaded && (
                                        <div className="flex w-full flex-col items-start justify-start space-y-4">
                                            <div className="flex w-full flex-col items-center space-x-0 space-y-4 rounded-lg border p-5 lg:flex-row lg:space-x-10 lg:space-y-0">
                                                <div className="flex w-64 flex-col items-start justify-start space-y-4">
                                                    {externalApplicationInit.options.idType?.map((el) => (
                                                        <RadioButton
                                                            id={String(el.id)}
                                                            checked={type === el.id}
                                                            text={el.name}
                                                            key={el.id}
                                                            func={() => setType(el.id)}
                                                            size="sm"
                                                        />
                                                    ))}
                                                </div>
                                                <div className="flex w-full items-center justify-center overflow-x-auto scrollbar-hide 4xs:items-end 4xs:justify-end 4xs:self-end">
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        className="hidden"
                                                        onChange={(event) => {
                                                            const files = event.target.files;
                                                            if (files && files[0] && externalParams) {
                                                                if (files[0].size > 5242880) {
                                                                    return setIsFileSizeExceeded(true);
                                                                }
                                                                setIsFileSizeExceeded(false);
                                                                void handleUploadExternalDirectorId(externalParams, {
                                                                    typeId: type as number,
                                                                    file: files[0],
                                                                });
                                                            }
                                                        }}
                                                        accept="image/jpeg, image/png, application/pdf, .pdf"
                                                        multiple
                                                    />
                                                    <div>
                                                        {isFileSizeExceeded && <p className="pb-1 text-xs text-error">*File exceeded 5MB</p>}
                                                        <ButtonComp
                                                            color="blue"
                                                            size="sm"
                                                            buttonType="flat"
                                                            borderSmall
                                                            isLoading={isExternalDirectorIdLoading}
                                                            func={() => fileInputRef.current && fileInputRef.current.click()}
                                                            disable={!type}
                                                        >
                                                            <div className="flex items-center justify-center space-x-2">
                                                                <p className="font-medium">upload document</p>
                                                                <span className="material-symbols-rounded text-xl">upload</span>
                                                            </div>
                                                        </ButtonComp>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {uploaded && (
                                        <NewUploadedCard
                                            text="Means of ID"
                                            handleRedo={handleReUpload}
                                            primaryButtonText="Re-upload ID"
                                            handlePreviewDocument={() => externalParams && void handlePreviewDocument(externalParams)}
                                        />
                                    )}
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span>Back</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                    <ButtonComp
                                        type="button"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        disable={!uploaded}
                                        func={() =>
                                            navigate(
                                                {
                                                    pathname: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
                                                },
                                                {
                                                    state: {
                                                        from: navigate(`${url}${Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID}`),
                                                    },
                                                }
                                            )
                                        }
                                        fullWidth
                                    >
                                        <span>Continue</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </div>
                    )}
                    <Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={MeansOfIdHints} />
                </div>
            </div>
        </>
    );
}

export default IdentificationForm;
