export enum PingTime {
    MAIN_APP_IDLE = 1500,
}

// export type PingRequest = {};

export type PingResponse = {
    success: boolean;
    message: string;
};
