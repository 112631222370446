import "react-circular-progressbar/dist/styles.css";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import GoogleIcon from "../../../../../components/google-icon";
import React from "react";
import { useAppSelector } from "../../../../../redux/hooks";

interface Props {
    children: React.ReactNode;
}

function ApplicationLayout(props: Props): JSX.Element {
    const accountOpening = useAppSelector((state) => state.application.init);
    const zambiaAccountOpening = useAppSelector((state) => state.zambiaApplication.init);

    return (
        <div className="flex h-full w-full flex-col">
            <div className="relative flex h-full w-full flex-col items-start space-y-10">
                <div className="flex w-full items-start justify-start space-y-2 border-b border-grey pb-3 pt-2.5">
                    <div className="flex w-full flex-col items-start justify-start space-y-2">
                        <h1 className="text-3xl font-medium capitalize text-black">
                            {accountOpening?.applicationMin.name || zambiaAccountOpening?.application.name || ""}
                        </h1>
                        <p className="text-base font-normal text-black-tertiary">Complete your application in minutes</p>
                    </div>
                    <div>
                        <div className="flex h-16 items-center justify-start space-x-4 rounded-lg bg-white px-4 py-2.5">
                            <div>
                                <div className="relative h-11 w-11">
                                    <CircularProgressbar
                                        value={
                                            accountOpening
                                                ? accountOpening?.applicationMin.isRegistered
                                                    ? accountOpening.isCreator
                                                        ? accountOpening?.progress.registeredProgress()
                                                        : accountOpening?.progress.invitedRegisteredProgress()
                                                    : accountOpening?.progress.unregisteredProgress()
                                                : zambiaAccountOpening?.application.isRegistered
                                                  ? zambiaAccountOpening.isCreator
                                                      ? zambiaAccountOpening?.progress.zambiaRegisteredProgress()
                                                      : zambiaAccountOpening?.progress.invitedZambiaRegisteredProgress()
                                                  : zambiaAccountOpening?.progress.zambiaUnregisteredProgress() || 0
                                        }
                                        styles={buildStyles({
                                            pathColor: `rgba(84, 102, 249, 1)`,
                                            textColor: "rgba(113, 116, 140, 1)",
                                            trailColor: "rgba(241, 241, 244, 1)",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                        })}
                                    />
                                    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                                        <GoogleIcon icon="contract_edit" className="text-blue" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-max flex-col space-y-1">
                                <h6 className="text-lg font-medium leading-[100%] text-black">
                                    {accountOpening
                                        ? accountOpening?.applicationMin.isRegistered
                                            ? accountOpening.isCreator
                                                ? accountOpening?.progress.registeredProgress()
                                                : accountOpening?.progress.invitedRegisteredProgress()
                                            : accountOpening?.progress.unregisteredProgress()
                                        : zambiaAccountOpening?.application.isRegistered
                                          ? zambiaAccountOpening.isCreator
                                              ? zambiaAccountOpening?.progress.zambiaRegisteredProgress()
                                              : zambiaAccountOpening?.progress.invitedZambiaRegisteredProgress()
                                          : zambiaAccountOpening?.progress.zambiaUnregisteredProgress()}
                                    %
                                </h6>
                                <p className="text-xs text-black-tertiary">of total application</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="h-fit-available relative flex w-full flex-shrink flex-grow basis-auto flex-col items-center justify-start">
                    <div className="h-fit-available absolute left-0 top-0 flex w-full flex-shrink flex-grow basis-auto flex-col items-start justify-start overflow-y-auto scrollbar-hide">
                        <div className="h-fit-available flex w-full flex-shrink flex-grow basis-auto flex-col items-start justify-start pb-10">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationLayout;
