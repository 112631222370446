import {
    resetElectricityVerifiedAccountName,
    setElectricityPurchaseAccountId,
    setElectricityVerifiedAccountName,
} from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { useCallback, useState } from "react";

import { BillVerifyAccountRequest } from "../../../../services/billPayment/billPaymentApi.types";
import { RequestCancelledError } from "../../../../../../../helpers/request/requestErrors";
import { billVerifyAccount } from "../../../../services/billPayment/billPaymentApi";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

interface UseElectricityPurchaseVerifyAccountInterface {
    isElectricityPurchaseVerifyAccountLoading: boolean;
    electricityPurchaseVerifyAccountError: string;

    handleResetError: () => void;
    handleElectricityPurchase: (_data: BillVerifyAccountRequest) => Promise<void>;
}

function useElectricityPurchaseVerifyAccount(): UseElectricityPurchaseVerifyAccountInterface {
    const dispatch = useDispatch();

    const [isElectricityPurchaseVerifyAccountLoading, setIsElectricityPurchaseVerifyAccountLoading] = useState<boolean>(false);
    const [electricityPurchaseVerifyAccountError, setElectricityPurchaseVerifyAccountError] = useState<string>("");

    const handleElectricityPurchase = useCallback(
        async (_data: BillVerifyAccountRequest) => {
            try {
                dispatch(resetElectricityVerifiedAccountName());
                setIsElectricityPurchaseVerifyAccountLoading(true);
                setElectricityPurchaseVerifyAccountError("");
                const res = await billVerifyAccount(_data);
                dispatch(setElectricityVerifiedAccountName(res.name));
                dispatch(setElectricityPurchaseAccountId(_data.accountId));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setElectricityPurchaseVerifyAccountError(errorMessage);
            }
            setIsElectricityPurchaseVerifyAccountLoading(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    const handleResetError = useCallback(() => {
        setElectricityPurchaseVerifyAccountError("");
    }, []);
    return { electricityPurchaseVerifyAccountError, isElectricityPurchaseVerifyAccountLoading, handleResetError, handleElectricityPurchase };
}

export default useElectricityPurchaseVerifyAccount;
