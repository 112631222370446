import React from "react";
import TerminalOne from "../../../../../assets/svg/Terminal/Media1.png";
import TerminalThree from "../../../../../assets/svg/Terminal/Media3.png";
import TerminalTwo from "../../../../../assets/svg/Terminal/Media2.png";
import LencoButton from "../../../../../components/button/button";
import MessageToast from "../../../../../components/message-toast";
import CurrencyCode from "../../../../../components/currency-code";

interface Props {
    toggleRequestTerminalModal: () => void;
}

export default function NoTerminal({ toggleRequestTerminalModal }: Props) {
    return (
        <div className="flex h-full w-full flex-col items-center space-y-8 px-6">
            <div className="flex-col items-center justify-center space-y-4 text-center">
                <p className="text-2xl font-medium">Get started with Lenco Terminals</p>
                <p className="text-sm text-black-secondary">Request a terminal to improve cash collections</p>
            </div>
            <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                    <div className="flex justify-center rounded-lg w-fit-available">
                        <img src={TerminalOne} alt="Lenco card light" className="w-full" />
                    </div>
                    <div className="space-y-3">
                        <p className="text-base font-medium">Accept Card Payments</p>
                        <p className="text-sm text-black-tertiary">
                            Accept card payments with ease using the Lenco POS Terminal - a seamless way to accept in-store card payment
                        </p>
                    </div>
                </div>

                <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                    <div className="flex justify-center rounded-lg w-fit-available">
                        <img src={TerminalTwo} alt="Lenco card dark" className="w-full" />
                    </div>
                    <div className="space-y-3">
                        <p className="text-base font-medium">Accept Transfers</p>
                        <p className="text-sm text-black-tertiary">
                            Seamlessly accept transfers into the Lenco POS Terminal and get instant confirmation and receipt
                        </p>
                    </div>
                </div>
                <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                    <div className="flex justify-center rounded-lg w-fit-available">
                        <img src={TerminalThree} alt="Lenco card dark" className="w-full" />
                    </div>
                    <div className="space-y-3">
                        <p className="text-base font-medium">Quick Settlement</p>
                        <p className="text-sm text-black-tertiary">Get access instantly to all payments made on the Lenco POS Terminal.</p>
                        <div className="w-full">
                            <MessageToast
                                message={
                                    <p>
                                        Fee: 0.5% capped at <CurrencyCode /> 100
                                    </p>
                                }
                                className="mx-auto w-max lg:w-full"
                                type="info"
                                size="sm"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex w-full justify-center pb-5">
                <LencoButton onClick={toggleRequestTerminalModal} type="button" color="primary" size="md" variant="solid" isText>
                    <span className="px-3 normal-case tracking-wider">Get Started</span>
                </LencoButton>
            </div>
        </div>
    );
}
