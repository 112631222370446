import Skeleton from "../../../../../components/skeleton";

function IndividualAccountsDetailsCardSkeleton(): JSX.Element {
    return (
        <>
            <div className="flex h-full items-center justify-center rounded-xl px-2 py-5 leading-normal shadow sm:px-8">
                <div className="flex h-full w-full flex-col items-center justify-between">
                    <div className="flex h-10 w-full justify-between py-2">
                        <div className="w-16">
                            <Skeleton box />
                        </div>
                        <div className="w-4">
                            <Skeleton box />
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-start space-y-3 overflow-hidden xs:flex-row xs:items-center xs:space-y-0">
                        {" "}
                        <div className="h-5 w-30%">
                            <div className="h-full w-60%">
                                <Skeleton box />
                            </div>
                        </div>{" "}
                        <div className="h-14 w-70%">
                            <Skeleton box />
                        </div>{" "}
                    </div>
                    <div className="flex w-full flex-col items-start space-y-3 overflow-hidden xs:flex-row xs:items-center xs:space-y-0">
                        {" "}
                        <div className="h-5 w-30%">
                            <div className="h-full w-60%">
                                <Skeleton box />
                            </div>
                        </div>{" "}
                        <div className="h-14 w-70%">
                            <Skeleton box />
                        </div>{" "}
                    </div>
                    <div className="flex w-full flex-col items-start space-y-3 overflow-hidden xs:flex-row xs:items-center xs:space-y-0">
                        {" "}
                        <div className="h-5 w-30%">
                            <div className="h-full w-60%">
                                <Skeleton box />
                            </div>
                        </div>{" "}
                        <div className="h-14 w-70%">
                            <Skeleton box />
                        </div>{" "}
                    </div>
                    <div className="flex w-full flex-col items-start space-y-3 overflow-hidden xs:flex-row xs:items-center xs:space-y-0">
                        {" "}
                        <div className="h-5 w-30%">
                            <div className="h-full w-60%">
                                <Skeleton box />
                            </div>
                        </div>{" "}
                        <div className="h-14 w-70%">
                            <Skeleton box />
                        </div>{" "}
                    </div>
                </div>
                <br />
            </div>
        </>
    );
}

export default IndividualAccountsDetailsCardSkeleton;
