import { BillPaymentPage, BillPaymentStage, BillPaymentType } from "../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import React, { useEffect, useLayoutEffect } from "react";
import { resetAllBillPaymentData, setBillPaymentStage, setBillPaymentType } from "../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch, useSelector } from "react-redux";

import AirtimePurchase from "../componentss/BillPayment/AirtimePurchase";
import BillPaymentHistory from "../componentss/BillPayment/BillPaymentHistory";
import BillPaymentHistoryLayout from "../componentss/Layouts/BillPaymentHistory";
import BillPaymentLayout from "../componentss/Layouts/BillPayment";
import CableTVPurchase from "../componentss/BillPayment/CableTVPurchase";
import DataPurchase from "../componentss/BillPayment/DataPurchase";
import ElectricityPurchase from "../componentss/BillPayment/ElectricityPurchase";
import { IRootState } from "../../../../redux/rootReducer";
import { PageTitle } from "../../../../helpers/AppConstants";
import TypeOfBill from "../componentss/BillPayment/TypeOfBill";
import { abortBillPayment } from "../services/billPayment/billPaymentApi";
import { useLocation } from "react-router";

interface LocationState {
    typeOfTransfer: BillPaymentType;
}

function BillPayment(): JSX.Element {
    const dispatch = useDispatch();
    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    document.title = PageTitle.PAYMENTS_PAGE;

    const billPaymentStage = useSelector((state: IRootState) => state.billPayment.billPaymentStage);
    const billPaymentPage = useSelector((state: IRootState) => state.billPayment.billPaymentPage);
    const billPaymentType = useSelector((state: IRootState) => state.billPayment.billPaymentType);

    useLayoutEffect(() => {
        if (billPaymentStage === BillPaymentStage.INITIAL) {
            window.history.replaceState({}, "Bill Payments", `/payments/bill/make`);
        }
        if (billPaymentStage === BillPaymentStage.PAYMENT_DETAILS) {
            window.history.replaceState({}, "Bill Payments", `/payments/bill/make/${billPaymentType}`);
        }
        if (billPaymentStage === BillPaymentStage.REVIEW_PAYMENT) {
            window.history.replaceState({}, "Bill Payments", `/payments/bill/make/${billPaymentType}/review`);
        }
        if (billPaymentStage === BillPaymentStage.MAKE_PAYMENT) {
            window.history.replaceState({}, "Bill Payments", `/payments/bill/make/${billPaymentType}/authorize`);
        }
        if (billPaymentStage === BillPaymentStage.PROCESS_PAYMENT) {
            window.history.replaceState({}, "Bill Payments", `/payments/bill/make/${billPaymentType}/completed`);
        }
    }, [billPaymentStage, billPaymentType]);

    useEffect(() => {
        if (!location || !locationState) return;
        const { typeOfTransfer } = locationState;
        if (typeOfTransfer) {
            dispatch(setBillPaymentType(typeOfTransfer));
            dispatch(setBillPaymentStage(BillPaymentStage.PAYMENT_DETAILS));
        }
    }, [location]);

    useEffect(() => {
        return () => {
            abortBillPayment();
            dispatch(resetAllBillPaymentData());
        };
    }, []);

    return (
        <>
            {billPaymentPage === BillPaymentPage.MAKE_PAYMENT && (
                <BillPaymentLayout>
                    {billPaymentStage === BillPaymentStage.INITIAL && <TypeOfBill />}
                    {billPaymentStage !== BillPaymentStage.INITIAL && billPaymentType === BillPaymentType.AIRTIME_PAYMENT && <AirtimePurchase />}
                    {billPaymentStage !== BillPaymentStage.INITIAL && billPaymentType === BillPaymentType.DATA_PAYMENT && <DataPurchase />}
                    {billPaymentStage !== BillPaymentStage.INITIAL && billPaymentType === BillPaymentType.CABLE_TV_PAYMENT && <CableTVPurchase />}
                    {billPaymentStage !== BillPaymentStage.INITIAL && billPaymentType === BillPaymentType.ELECTRICITY_PAYMENT && (
                        <ElectricityPurchase />
                    )}
                </BillPaymentLayout>
            )}
            {billPaymentPage === BillPaymentPage.PAYMENT_HISTORY && (
                <BillPaymentHistoryLayout>
                    <BillPaymentHistory />
                </BillPaymentHistoryLayout>
            )}
        </>
    );
}

export default BillPayment;
