import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BillPaymentStage } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import { IRootState } from "../../../../../redux/rootReducer";
import MakePayment from "./ElectricityPurchase/MakePayment";
import PaymentDetails from "./ElectricityPurchase/PaymentDetails";
import ProcessingPayment from "./ElectricityPurchase/ProcessingPayment";
import ReviewPayment from "./ElectricityPurchase/ReviewPayment";
import { resetAllBillPaymentData } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { resetAllElectricityPurchaseData } from "../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";

function ElectricityPurchase(): JSX.Element {
    const dispatch = useDispatch();
    const billPaymentStage = useSelector((state: IRootState) => state.billPayment.billPaymentStage);

    useEffect(() => {
        return () => {
            dispatch(resetAllBillPaymentData());
            dispatch(resetAllElectricityPurchaseData());
        };
    }, []);

    return (
        <>
            {billPaymentStage === BillPaymentStage.PAYMENT_DETAILS && <PaymentDetails />}
            {billPaymentStage === BillPaymentStage.REVIEW_PAYMENT && <ReviewPayment />}
            {billPaymentStage === BillPaymentStage.MAKE_PAYMENT && <MakePayment />}
            {billPaymentStage === BillPaymentStage.PROCESS_PAYMENT && <ProcessingPayment />}
        </>
    );
}

export default ElectricityPurchase;
