import AccountSignatoriesLineDropdown from "./dropdowns/AccountSignatoriesLineDropdown";
import ApplicationTags from "../../../../../../../tags/SignatoryTag";
import SignatoryIcon from "../../../../../../../../../../../assets/images/dashboard/application/signatory.png";
import ZambiaSignatoryMin from "../../../../../../../../../../../models/account-opening/zambiaSignatoryInfoMin";
import titleCase from "../../../../../../../../../../../hooks/titleCase";
import { useCallback } from "react";

interface Props {
    data: ZambiaSignatoryMin;
    isReview?: boolean;
    editFunc?: (_signatory: ZambiaSignatoryMin) => void;
}
function SignatoryCard(props: Props): JSX.Element {
    const handleEditSignatoryFunc = useCallback(() => {
        props.editFunc && props.editFunc(props.data);
    }, [props.data]);

    return (
        <>
            <div
                className={
                    "flex w-full flex-col items-start justify-start space-y-2 rounded-lg border border-grey p-4 " +
                    `${props.isReview && props.data.isPending ? "border-warning-tertiary" : "border-grey"}`
                }
            >
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <div className="hidden h-full flex-grow items-center justify-center 2xs:flex">
                        <div className="flex w-16 items-center justify-center rounded-lg bg-blue-senary">
                            <img src={SignatoryIcon} alt="icon depicting user" />
                        </div>
                    </div>
                    <div className="relative flex w-full flex-col items-start justify-start">
                        <div className="flex h-full flex-grow items-center justify-center 2xs:hidden">
                            <div className="flex w-16 items-center justify-center rounded-lg bg-blue-senary">
                                <img src={SignatoryIcon} alt="icon depicting user" />
                            </div>
                        </div>
                        <span className="!mt-0 text-base font-medium text-black">{titleCase(props.data.name)}</span>
                        <span className="break-all text-sm text-black-tertiary">{props.data.email}</span>
                        {props.isReview && props.data.isPending && (
                            <div
                                className={
                                    `absolute right-0 top-0 rounded-[4px] border px-2 py-1 text-left text-xs font-medium ` +
                                    `${props.data.isCompleted ? "border-success-quin bg-success-backdrop text-success" : "border-warning-quin bg-warning-backdrop text-warning"} `
                                }
                            >
                                {props.data.statusText}
                            </div>
                        )}
                        {!props.isReview && (
                            <div className="-ml-2 flex w-full flex-row flex-wrap space-x-2">
                                {props.data.isKeyContact && (
                                    <div className="ml-2 mt-2">
                                        <ApplicationTags title="Key Contact" />
                                    </div>
                                )}
                                <div className="ml-2 mt-2">
                                    <ApplicationTags title={props.data.isDirector ? "Director & Signatory" : "Signatory"} />
                                </div>
                                <div className="mt-2">
                                    <ApplicationTags title={props.data.userRoleText} />
                                </div>
                            </div>
                        )}
                    </div>
                    {!props.isReview && (
                        <div
                            className={
                                "-mt-2.5 flex h-full w-max flex-col self-start pt-0.5 " +
                                `${props.isReview ? "items-start justify-start" : "items-end justify-end"}`
                            }
                        >
                            {!props.data.isCompleted && (
                                <AccountSignatoriesLineDropdown data={props.data} editFunc={handleEditSignatoryFunc} clickAndClose />
                            )}
                            <div
                                className={
                                    `mt-2 rounded-[4px] border px-2 py-1 text-left text-xs font-medium ` +
                                    `${props.data.isCompleted ? "border-success-quin bg-success-backdrop text-success" : "border-warning-quin bg-warning-backdrop text-warning"} `
                                }
                            >
                                {props.data.statusText}
                            </div>
                        </div>
                    )}
                </div>
                {props.isReview && (
                    <div className="-ml-2 flex w-full flex-row flex-wrap space-x-2">
                        {props.data.isKeyContact && (
                            <div className="ml-2 mt-2">
                                <ApplicationTags title="Key Contact" />
                            </div>
                        )}
                        <div className="ml-2 mt-2">
                            <ApplicationTags title={props.data.isDirector ? "Director & Signatory" : "Signatory"} />
                        </div>
                        <div className="mt-2">
                            <ApplicationTags title={props.data.userRoleText} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default SignatoryCard;
