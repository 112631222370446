import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { UpdateZambiaApiAccessKeyWebhookUrlRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { updateZambiaApiAccessKeyWebhookUrl } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { useDispatch } from "react-redux";

export interface UseUpdateZambiaApiAccessKeyWebhookUrlInterface {
    isUpdateZambiaApiAccessKeyWebhookUrlLoading: boolean;
    updateZambiaApiAccessKeyWebhookUrlErrorMessage: string;
    handleUpdateZambiaApiAccessKeyWebhookUrl: (_data: UpdateZambiaApiAccessKeyWebhookUrlRequest) => void;
}

function useUpdateZambiaApiAccessKeyWebhookUrl(): UseUpdateZambiaApiAccessKeyWebhookUrlInterface {
    const dispatch = useDispatch();
    const [isUpdateZambiaApiAccessKeyWebhookUrlLoading, setIsUpdateZambiaApiAccessKeyWebhookUrlLoading] = useState(false);
    const [updateZambiaApiAccessKeyWebhookUrlErrorMessage, setUpdateZambiaApiAccessKeyWebhookUrlErrorMessage] = useState<string>("");

    const handleUpdateZambiaApiAccessKeyWebhookUrl = useCallback(
        async (_data: UpdateZambiaApiAccessKeyWebhookUrlRequest) => {
            try {
                setIsUpdateZambiaApiAccessKeyWebhookUrlLoading(true);
                setUpdateZambiaApiAccessKeyWebhookUrlErrorMessage("");
                const res = await updateZambiaApiAccessKeyWebhookUrl(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key webhook updated successfully."));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setUpdateZambiaApiAccessKeyWebhookUrlErrorMessage(errorMessage);
            } finally {
                setIsUpdateZambiaApiAccessKeyWebhookUrlLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isUpdateZambiaApiAccessKeyWebhookUrlLoading,
        updateZambiaApiAccessKeyWebhookUrlErrorMessage,
        handleUpdateZambiaApiAccessKeyWebhookUrl,
    };
}

export default useUpdateZambiaApiAccessKeyWebhookUrl;
