import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class ZambiaApplicationIndustry {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string,
        public requireOperatingLicense: boolean,
        public isDeleted: boolean
    ) {}
    static create(obj: GenericObject): ZambiaApplicationIndustry {
        return new ZambiaApplicationIndustry(
            Parsers.string(obj.id),
            Parsers.string(obj.name),
            Parsers.boolean(obj.requireOperatingLicense),
            Parsers.boolean(obj.isDeleted)
        );
    }
}
