import { useEffect, useState } from "react";

import BandData from "../../models/bandData";
import Money from "../money";
import { Num } from "../../utils/math";
import { ZambiaPaymentMethod } from "../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice.types";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { useAppSelector } from "../../redux/hooks";

interface Props {
    value: string;
    isWithTax?: boolean;
    paymentMethod: ZambiaPaymentMethod;
}

function ZambiaFee({ value, isWithTax = false, paymentMethod }: Props): JSX.Element {
    const mobileMoney = useAppSelector((state) => state.init.main?.meta.transferFee.zm?.mobileMoney);
    const bankAccount = useAppSelector((state) => state.init.main?.meta.transferFee.zm?.bankAccount);
    const lencoMerchant = useAppSelector((state) => state.init.main?.meta.transferFee.zm?.lencoMerchant);

    const [fee, setFee] = useState<string>("");
    const [tax, setTax] = useState<string>("");

    useEffect(() => {
        let valueToEdit: number;

        if (isNullOrUndefined(value)) {
            return setFee("");
        }

        if (typeof value === "string") {
            const newValue = String(value).replace(new RegExp(`[^0-9.]`, "gi"), "");
            valueToEdit = Number(newValue);
        } else {
            valueToEdit = value;
        }
        const isBankAccount = paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER;
        const isMobileMoney = paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY;
        const isLencoMerchant = paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS;

        const bandType = isMobileMoney ? mobileMoney : isBankAccount ? bankAccount : isLencoMerchant ? lencoMerchant : null;

        const bandData = bandType?.bandData.find((_bandData) => valueToEdit >= _bandData.band.startAmount && valueToEdit <= _bandData.band.endAmount);
        if (bandData) calculateFee(bandData, valueToEdit);
    }, [value, paymentMethod]);

    const calculateFee = (_bandData: BandData, _amount: number) => {
        let taxFee = 0;
        let bandFee = 0;

        if (_bandData.isFlatFee) {
            bandFee = _bandData.value;
        }
        if (_bandData.isPercentageFee) {
            //round this to two decimal places using the round function praise sent and
            // check if it is less than or equal 0 to know if to show it or not
            const percentageFee = _amount * (_bandData.value / 100);
            bandFee = _bandData.percentageCap ? (_bandData.percentageCap > percentageFee ? percentageFee : _bandData.percentageCap) : percentageFee;
        }
        if (_bandData.tax) {
            const taxAmount = _bandData.tax.isTaxAmount ? _amount : bandFee;
            if (_bandData.tax.isFlatFee) {
                taxFee = _bandData.tax.value;
            }
            if (_bandData.tax.isPercentageFee) {
                // const percentageFee = taxAmount * (_bandData.tax.value / 100);
                const percentageFee = new Num(taxAmount).multiply(_bandData.tax.value).divide(100).valueOf();
                taxFee = _bandData.tax.percentageCap
                    ? _bandData.tax.percentageCap > percentageFee
                        ? percentageFee
                        : _bandData.tax.percentageCap
                    : percentageFee;
            }
        }
        setFee(String(bandFee === 0 || bandFee < 0.001 ? "" : bandFee));
        setTax(String(taxFee === 0 || bandFee < 0.001 ? "" : taxFee));
    };

    return (
        <>
            {value && value.length > 0 && Number(value) > 0 && (
                <div className="flex flex-col">
                    <p className="w-max text-xs text-black-secondary">
                        Fee:{" "}
                        <span className="font-bold">
                            <Money amount={fee} />
                        </span>
                    </p>

                    {tax && isWithTax && (
                        <p className="w-max text-xs text-black-secondary">
                            Tax:{" "}
                            <span className="font-bold">
                                <Money amount={tax} />
                            </span>
                        </p>
                    )}
                </div>
            )}
        </>
    );
}

export default ZambiaFee;
