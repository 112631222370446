import { abortRequest, getAbortControllerSignal } from "../../request/abortControllers";

import Parsers from "../../../utils/parsers";
import { PingResponse } from "./ping-api.constant";
import { makeRequestWithSignal } from "../../request/makeRequest";

export enum PingRequestsType {
    INIT = "ping.init",
}

export const pingApi = async (): Promise<PingResponse> => {
    const signal = getAbortControllerSignal(PingRequestsType.INIT);

    const res = await makeRequestWithSignal("/ping", {}, signal);

    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};

export function PingRequests(type: PingRequestsType): void {
    abortRequest(type);
}
export function abortAllPingRequests(): void {
    Object.values(PingRequestsType).forEach((type) => abortRequest(type));
}
