import { BasicFinalStatus, TransactionType } from "./posTransaction.constants";

import CardPaymentMin from "./cardPaymentMin";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import Settlement from "./settlement";
import Terminal from "./terminal";
import TransferIn from "./transferIn";
import TransferOut from "./transferOut";
import { immerable } from "immer";

export default class PosTransaction {
    [immerable] = true;
    constructor(
        public id: string,
        public reference: string,
        public transactionAmount: string,
        public stampDuty: string,
        public lencoFee: string,
        public settledAmount: string,
        public type: TransactionType,
        public status: BasicFinalStatus,
        public datetime: Date | null,
        public message: string,
        public cardPayment: CardPaymentMin | null,
        public transferIn: TransferIn | null,
        public transferOut: TransferOut | null,
        public terminal: Terminal | null,
        public settlement: Settlement | null
    ) {}

    static create(obj: GenericObject): PosTransaction {
        return new PosTransaction(
            Parsers.string(obj.id),
            Parsers.string(obj.reference),
            Parsers.string(obj.transactionAmount),
            Parsers.string(obj.stampDuty),
            Parsers.string(obj.lencoFee),
            Parsers.string(obj.settledAmount),
            Parsers.number(obj.type),
            Parsers.number(obj.status),
            Parsers.date(obj.datetime),
            Parsers.string(obj.message),
            Parsers.classObject(obj.cardPayment, CardPaymentMin),
            Parsers.classObject(obj.transferIn, TransferIn),
            Parsers.classObject(obj.transferOut, TransferOut),
            Parsers.classObject(obj.terminal, Terminal),
            Parsers.classObject(obj.settlement, Settlement)
        );
    }

    get isSuccessful(): boolean {
        return this.status === BasicFinalStatus.SUCCESSFUL;
    }
    get isFailed(): boolean {
        return this.status === BasicFinalStatus.FAILED;
    }

    get getStatus(): string {
        return this.status === BasicFinalStatus.SUCCESSFUL ? "Success" : "Failed";
    }
    get getType(): string {
        return this.type === TransactionType.CARD_PAYMENT ? "Card Purchase" : "Bank Transfer";
    }
}
