import { DateFormat, Months, WeekDays } from "./date.constants";

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-useless-escape */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { GenericObject } from "../helpers/types";
import isNullOrUndefined from "./isNullOrUndefined";

/********
 Copied from dateutil
 *******/

function date(y?: string, m?: string, d?: string, h?: string, n?: string, s?: string, ms?: string): Date {
    const ts = !arguments.length
        ? now()
        : Date.UTC(
              parseInt(y || "0", 10),
              parseInt(m || "0", 10),
              parseInt(d || "1", 10),
              parseInt(h || "0", 10),
              parseInt(n || "0", 10),
              parseInt(s || "0", 10),
              parseInt(ms || "0", 10)
          );
    return new Date(ts);
}

// return timestamp for the moment
const now =
    typeof Date.now === "function"
        ? Date.now
        : function (): number {
              return +new Date();
          };

enum DateEnum {
    DATE = "date",
    YEAR_AND_MONTH = "year_and_month",
    YEAR = "year",
}
const DateTypes = [DateEnum.DATE, DateEnum.YEAR, DateEnum.YEAR_AND_MONTH];

const dateParsers: {
    [DateEnum.DATE]: {
        test: RegExp;
        parse: (str: string) => Date;
    };
    [DateEnum.YEAR_AND_MONTH]: {
        test: RegExp;
        parse: (str: string) => Date;
    };
    [DateEnum.YEAR]: {
        test: RegExp;
        parse: (str: string) => Date;
    };
} = {
    // year + month + day
    date: {
        test: /^[+\-]?\d{4,6}(?:\-\d\d\-\d\d|-?\d\d\d\d)$/,
        parse: function (str: string) {
            const s = str.replace(/\D/g, "");
            return date(s.substring(0, s.length - 4), `${+s.substr(s.length - 4, 2) - 1}`, s.substr(s.length - 2));
        },
    },

    // year + month
    year_and_month: {
        test: /^[+\-]?\d{4,6}[\/\-](?:0[1-9]|1[012])$/,
        parse: function (str: string) {
            const b = str.split(/[\/\-]/);
            return date(b[0], `${+b[1] - 1}`, "1");
        },
    },

    // year
    year: {
        test: /^[+\-]?\d{4,6}$/,
        parse: function (str: string) {
            return date(str, "0", "1");
        },
    },
};

function parseDate(date: string | Date): Date {
    if (date instanceof Date) {
        return date;
    }

    DateTypes.forEach((type) => {
        if (((dateParsers[type] as GenericObject).test as RegExp).test(date)) {
            return dateParsers[type].parse(date);
        }
    });

    // for (const type as  in dateParsers) {
    // // if (dateParsers.hasOwnProperty(type)) {
    // if (((dateParsers[type] as GenericObject).test as RegExp).test(date)) {
    // return dateParsers[type].parse(date);
    // }
    // // }
    // }

    return new Date(date);
}
/********
 End of copied code
 *******/

function leftPad(number: number): string {
    return number.toString().padStart(2, "0");
}

export class DateParse {
    static parseDate(date: Date | string): Date {
        return parseDate(date);
    }

    static getDaySuffix(date: Date | string): string {
        date = parseDate(date);
        const day = Number(date.getDate());
        if (day === 11 || day === 12 || day === 13) {
            return "th";
        }

        const c = day % 10;
        switch (c) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    static getTimeAndSuffix(date: Date | string): string {
        date = parseDate(date);
        let hours = date.getHours(),
            period;
        if (hours > 12) {
            hours = hours - 12;
            period = "pm";
        } else if (hours === 12) {
            period = "pm";
        } else if (hours === 0) {
            hours = 12;
            period = "am";
        } else {
            period = "am";
        }
        const minutes = leftPad(date.getMinutes());
        return `${hours}:${minutes} ${period}`;
    }

    static getMonthName(date: Date | string, shortName = false): string {
        date = parseDate(date);
        const month = Months[date.getMonth()];
        return !shortName ? month : month.substr(0, 3);
    }

    static getMonthAndYear(date: Date | string, shortName = false): string {
        date = parseDate(date);
        return `${DateParse.getMonthName(date, shortName)} ${date.getFullYear()}`;
    }

    static getDayAndSuffix(date: Date | string): string {
        date = parseDate(date);
        return `${date.getDate()}${DateParse.getDaySuffix(date)}`;
    }

    static getDayOfWeek(date: Date | string, shortName = false): string {
        date = parseDate(date);
        const day = WeekDays[date.getDay()];
        if (!shortName) {
            return day;
        }
        if (date.getDay() === 4) {
            return day.substr(0, 4);
        }
        return day.substr(0, 3);
    }

    static differenceInDays(startDate: Date | string, endDate: Date | string = new Date(), absoluteValue = false): number {
        startDate = parseDate(startDate);
        endDate = parseDate(endDate);
        const d1 = new Date(startDate.getTime());
        d1.setHours(12);
        d1.setMinutes(0);
        d1.setSeconds(0);
        d1.setMilliseconds(0);

        const d2 = new Date(endDate.getTime());
        d2.setHours(12);
        d2.setMinutes(0);
        d2.setSeconds(0);
        d2.setMilliseconds(0);

        const timeDiff = absoluteValue ? Math.abs(d2.getTime() - d1.getTime()) : d2.getTime() - d1.getTime();
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        return daysDiff >= 0 ? Math.floor(daysDiff) : Math.ceil(daysDiff);
    }

    static isSameYear(date1: Date | string, date2: Date | string = new Date()): boolean {
        date1 = parseDate(date1);
        date2 = parseDate(date2);
        return date1.getFullYear() === date2.getFullYear();
    }

    static isSameMonth(date1: Date | string, date2: Date | string): boolean {
        date1 = parseDate(date1);
        date2 = parseDate(date2);
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
    }

    static isSameDay(date1: Date | string, date2: Date | string): boolean {
        date1 = parseDate(date1);
        date2 = parseDate(date2);
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }

    static getMonth(date: Date | string): string {
        date = parseDate(date);
        return leftPad(date.getMonth() + 1);
    }

    static firstDateOfMonth(date: Date | string): Date {
        date = parseDate(date);
        const d = new Date();
        d.setFullYear(date.getFullYear(), date.getMonth(), 1);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    static lastDateOfMonth(date: Date | string): Date {
        date = parseDate(date);
        const d = new Date();
        d.setFullYear(date.getFullYear(), date.getMonth() + 1, 0);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    static isSaturday(date: Date | string): boolean {
        date = parseDate(date);
        return date.getDay() === 6;
    }

    static isSunday(date: Date | string): boolean {
        date = parseDate(date);
        return date.getDay() === 0;
    }

    static isMonday(date: Date | string): boolean {
        date = parseDate(date);
        return date.getDay() === 1;
    }

    static getDateString(date: Date | string): string {
        date = parseDate(date);
        return `${date.getFullYear()}-${DateParse.getMonth(date)}-${leftPad(date.getDate())}`;
    }

    static getMonthString(date: Date | string): string {
        date = parseDate(date);
        return `${date.getFullYear()}-${DateParse.getMonth(date)}`;
    }

    static getPreviousMonth(date: Date | string): Date {
        date = parseDate(date);
        const d = new Date();
        d.setFullYear(date.getFullYear(), date.getMonth() - 1, 1);
        return d;
    }

    static getNextMonth(date: Date | string): Date {
        date = parseDate(date);
        const d = new Date();
        d.setFullYear(date.getFullYear(), date.getMonth() + 1, 1);
        return d;
    }

    static getDatesInRange(startDate: Date | string, endDate: Date | string, includeEndDate = true): Date[] {
        startDate = new Date(parseDate(startDate).valueOf());
        endDate = new Date(parseDate(endDate).valueOf());
        endDate.setHours(0, 0, 0, 0);
        const endTime = endDate.getTime();

        if (startDate.getTime() > endTime) {
            return [startDate];
        }

        const dates = [];

        const currentDate = startDate;
        currentDate.setHours(0, 0, 0, 0);

        while (currentDate.getTime() < endTime || (includeEndDate && currentDate.getTime() === endTime)) {
            dates.push(new Date(currentDate.getTime()));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }

    static formatDate(date: string | Date, format: string = DateFormat.DATE): string {
        date = parseDate(date);
        if (!date.valueOf() || !format) {
            return "";
        }
        /*
		FORMATS: Similar to php date
			d -> Day of the month, 2 digits with leading zeros---
			j -> Day of the month without leading zeros---
			D -> short day of the week (i.e. Sun)---
			l -> day of the week (i.e. Sunday)---
			S -> suffix (th, st, etc)---

			F -> month name (i.e. January)---
			M -> short month name (i.e. Jan)---
			m -> Numeric representation of a month, with leading zeros---
			n -> Numeric representation of a month, without leading zeros---

			Y -> year
			Y2 -> 2 digit year -> non standard, I know, but I have already been using 'y' for optional year
			y -> optional year

			A -> AM or PM---
			a -> am or pm---
			g -> 12-hour format of an hour without leading zeros---
			G -> 24-hour format of an hour without leading zeros---
			h -> 12-hour format of an hour with leading zeros---
			H -> 24-hour format of an hour with leading zeros---
			i -> Minutes with leading zeros---
			s -> Seconds, with leading zeros---
		*/

        // for each format key in the "format" var, use a place holder to keep them and get the data
        const replacementObject: any = {};
        let res = format;

        if (res.includes("d")) {
            res = res.replace(/d/g, "{{r1}}");
            replacementObject[1] = DateParse.getDate(date, true);
        }

        if (res.includes("j")) {
            res = res.replace(/j/g, "{{r2}}");
            replacementObject[2] = DateParse.getDate(date);
        }

        if (res.includes("D")) {
            res = res.replace(/D/g, "{{r3}}");
            replacementObject[3] = DateParse.getDayName(date, true);
        }

        if (res.includes("l")) {
            res = res.replace(/l/g, "{{r4}}");
            replacementObject[4] = DateParse.getDayName(date);
        }

        if (res.includes("S")) {
            res = res.replace(/S/g, "{{r5}}");
            replacementObject[5] = DateParse.getDaySuffix(date);
        }

        if (res.includes("F")) {
            res = res.replace(/F/g, "{{r6}}");
            replacementObject[6] = DateParse.getMonthName(date);
        }

        if (res.includes("M")) {
            res = res.replace(/M/g, "{{r7}}");
            replacementObject[7] = DateParse.getMonthName(date, true);
        }

        if (res.includes("m")) {
            res = res.replace(/m/g, "{{r8}}");
            replacementObject[8] = DateParse.getMonth(date);
        }

        if (res.includes("n")) {
            res = res.replace(/n/g, "{{r9}}");
            replacementObject[9] = DateParse.getMonth(date);
        }

        if (res.includes("Y2")) {
            res = res.replace(/Y2/g, "{{r010}}");
            replacementObject["010"] = String(date.getFullYear()).substring(2);
        }

        if (res.includes("Y")) {
            res = res.replace(/Y/g, "{{r10}}");
            replacementObject[10] = date.getFullYear();
        }

        if (res.includes("A")) {
            res = res.replace(/A/g, "{{r11}}");
            replacementObject[11] = DateParse.getTimeMeridiem(date, true);
        }

        if (res.includes("a")) {
            res = res.replace(/a/g, "{{r12}}");
            replacementObject[12] = DateParse.getTimeMeridiem(date);
        }

        if (res.includes("g")) {
            res = res.replace(/g/g, "{{r13}}");
            replacementObject[13] = DateParse.getHours(date, true, false);
        }

        if (res.includes("G")) {
            res = res.replace(/G/g, "{{r14}}");
            replacementObject[14] = DateParse.getHours(date, false, false);
        }

        if (res.includes("h")) {
            res = res.replace(/h/g, "{{r15}}");
            replacementObject[15] = DateParse.getHours(date, true, true);
        }

        if (res.includes("H")) {
            res = res.replace(/H/g, "{{r16}}");
            replacementObject[16] = DateParse.getHours(date, false, true);
        }

        if (res.includes("i")) {
            res = res.replace(/i/g, "{{r17}}");
            replacementObject[17] = DateParse.getMinutes(date);
        }

        if (res.includes("s")) {
            res = res.replace(/s/g, "{{r18}}");
            replacementObject[18] = DateParse.getSeconds(date);
        }

        if (res.includes("y")) {
            res = res.replace(/y/g, "{{r19}}");
            replacementObject[19] = DateParse.isSameYear(date) ? "" : date.getFullYear();
        }

        // having gone thru all, set the data in the placeholder
        for (const key in replacementObject) {
            if (replacementObject.hasOwnProperty(key)) {
                res = res.replace(new RegExp(`{{r${key}}}`, "g"), replacementObject[key] as string);
            }
        }
        return res.trim().replace(/,\s*$/, ""); // trim spaces and remove trailing comma
    }

    static formatDateNullable(date: string | Date | null | undefined, format: string = DateFormat.DATE): string | null {
        if (isNullOrUndefined(date)) {
            return null;
        }

        date = parseDate(date);
        if (!date.valueOf() || !format) {
            return null;
        }

        return DateParse.formatDate(date, format);
    }

    static getDayName(date: Date | string, short = false): string {
        date = parseDate(date);
        if (!date.valueOf()) {
            return "";
        }
        const day = WeekDays[date.getDay()];
        return short ? day.substr(0, 3) : day;
    }

    static getTimeMeridiem(date: Date | string, uppercase = false): string {
        date = parseDate(date);
        if (!date.valueOf()) {
            return "";
        }
        const period = date.getHours() >= 12 ? "pm" : "am";
        return uppercase ? period.toUpperCase() : period;
    }

    static getDate(date: Date | string, withLeadingZero = false): string {
        date = parseDate(date);
        if (!date.valueOf()) {
            return "";
        }
        const monthDate = date.getDate().toString();
        return withLeadingZero ? monthDate.padStart(2, "0") : monthDate;
    }

    static getMinutes(date: Date | string): string {
        date = parseDate(date);
        if (!date.valueOf()) {
            return "";
        }
        return date.getMinutes().toString().padStart(2, "0");
    }

    static getSeconds(date: Date | string): string {
        date = parseDate(date);
        if (!date.valueOf()) {
            return "";
        }
        return date.getSeconds().toString().padStart(2, "0");
    }

    static getHours(date: Date | string, format12 = false, withLeadingZero = false): string {
        date = parseDate(date);
        if (!date.valueOf()) {
            return "";
        }
        let hours = date.getHours();

        if (format12) {
            if (hours > 12) {
                hours -= 12;
            } else if (hours === 0) {
                hours = 12;
            }
        }

        return withLeadingZero ? hours.toString().padStart(2, "0") : hours.toString();
    }

    static addDays(date: Date | string, days = 1): Date {
        date = parseDate(date);

        const d = new Date(date.valueOf());
        d.setDate(d.getDate() + days);
        return d;
    }

    static subDays(date: Date | string, days = 1): Date {
        date = parseDate(date);

        const d = new Date(date.valueOf());
        d.setDate(d.getDate() - days);
        return d;
    }

    // considers only the date portion of the date. i.e. not the time of the day
    static isDatePast(date: Date | string): boolean {
        return DateParse.differenceInDays(new Date(), date, false) < 0;
    }

    // considers only the date portion of the date. i.e. not the time of the day
    static isToday(date: Date | string): boolean {
        return DateParse.differenceInDays(new Date(), date, false) === 0;
    }

    // considers only the date portion of the date. i.e. not the time of the day
    static isFuture(date: Date | string): boolean {
        const difference = DateParse.differenceInDays(new Date(), date, false);
        return difference > 0;
        // return differenceInDays((new Date()), date, false) > 0;
    }

    // considers both the date and time
    static isDateTimePast(date: Date | string): boolean {
        return DateParse.differenceInSeconds(date) > 0;
    }

    // considers both the date and time
    static isDateTimeFuture(date: Date | string): boolean {
        return DateParse.differenceInSeconds(date) < 0;
    }

    static fromMonthString(month: string): Date | null {
        const monthArray = month.split("-");

        if (monthArray.length < 2) {
            return null;
        }

        return new Date(Number(monthArray[0]), Number(monthArray[1]) - 1, 1);
    }

    static relativeDate(date: Date | string, format = "M jS"): string {
        date = parseDate(date);

        const diffInDays = DateParse.differenceInDays(date, new Date(), false);
        if (diffInDays === 0) {
            return "Today";
        } else if (diffInDays === 1) {
            return "Yesterday";
        } else if (diffInDays === -1) {
            return "Tomorrow";
        } else if (diffInDays > 1 && diffInDays < 7) {
            return `${diffInDays} days ago`;
        } else if (diffInDays < -1 && diffInDays > -7) {
            return `${diffInDays} days from now`;
        } else {
            return DateParse.formatDate(date, format);
        }
    }

    static fromTime(timeString: string): Date | null {
        const timeRegex = /^(?:[0-1][0-9]|2[0-3]):(?:[0-5][0-9]):(?:[0-5][0-9])$/;
        if (timeRegex.test(timeString)) {
            const today = new Date();
            return date(
                String(today.getFullYear()),
                String(today.getMonth()),
                String(today.getDate()),
                timeString.substring(0, 2),
                timeString.substring(3, 5),
                timeString.substring(6, 8)
            );
        }
        return null;
    }

    static isSameHour(date1: Date | string, date2: Date | string = new Date()): boolean {
        date1 = parseDate(date1);
        date2 = parseDate(date2);
        return date1.getHours() === date2.getHours();
    }

    static isSameHourAndMinutes(date1: Date | string, date2: Date | string = new Date()): boolean {
        date1 = parseDate(date1);
        date2 = parseDate(date2);
        return DateParse.isSameHour(date1, date2) && date1.getMinutes() === date2.getMinutes();
    }

    static differenceInYear(startDate: Date | string, endDate: Date | string = new Date(), absoluteValue = false): number {
        startDate = parseDate(startDate);
        endDate = parseDate(endDate);
        const d1 = new Date(startDate.getTime());
        d1.setHours(12);
        d1.setMinutes(0);
        d1.setSeconds(0);
        d1.setMilliseconds(0);

        const d2 = new Date(endDate.getTime());
        d2.setHours(12);
        d2.setMinutes(0);
        d2.setSeconds(0);
        d2.setMilliseconds(0);

        const timeDiff = absoluteValue ? Math.abs(d2.getTime() - d1.getTime()) : d2.getTime() - d1.getTime();
        const yearDiff = timeDiff / (1000 * 3600 * 24 * 365);
        return yearDiff >= 0 ? Math.floor(yearDiff) : Math.ceil(yearDiff);
    }

    static inBetweenTime(startDate: Date | string, endDate: Date | string, currentDate = new Date()): boolean {
        startDate = parseDate(startDate);
        endDate = parseDate(endDate);
        currentDate = parseDate(currentDate);

        return currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime();
    }

    static differenceInHours(startDate: Date | string, endDate: Date | string = new Date(), absoluteValue = false): number {
        startDate = parseDate(startDate);
        endDate = parseDate(endDate);

        const timeDiff = absoluteValue ? Math.abs(endDate.getTime() - startDate.getTime()) : endDate.getTime() - startDate.getTime();
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        return hoursDiff >= 0 ? Math.floor(hoursDiff) : Math.ceil(hoursDiff);
    }

    static differenceInMinutes(startDate: Date | string, endDate: Date | string = new Date(), absoluteValue = false): number {
        startDate = parseDate(startDate);
        endDate = parseDate(endDate);

        const timeDiff = absoluteValue ? Math.abs(endDate.getTime() - startDate.getTime()) : endDate.getTime() - startDate.getTime();
        const minutesDiff = timeDiff / (1000 * 60);
        return minutesDiff >= 0 ? Math.floor(minutesDiff) : Math.ceil(minutesDiff);
    }

    static differenceInSeconds(startDate: Date | string, endDate: Date | string = new Date(), absoluteValue = false): number {
        startDate = parseDate(startDate);
        endDate = parseDate(endDate);

        const timeDiff = absoluteValue ? Math.abs(endDate.getTime() - startDate.getTime()) : endDate.getTime() - startDate.getTime();
        const secondsDiff = timeDiff / 1000;
        return secondsDiff >= 0 ? Math.floor(secondsDiff) : Math.ceil(secondsDiff);
    }

    static addMinutes(date: Date | string, minutes = 1): Date {
        date = parseDate(date);

        const d = new Date(date.valueOf());
        d.setMinutes(d.getMinutes() + minutes);
        return d;
    }

    static subMinutes(date: Date | string, minutes = 1): Date {
        date = parseDate(date);

        const d = new Date(date.valueOf());
        d.setMinutes(d.getMinutes() - minutes);
        return d;
    }

    static addSeconds(date: Date | string, seconds = 1): Date {
        date = parseDate(date);

        const d = new Date(date.valueOf());
        d.setSeconds(d.getSeconds() + seconds);
        return d;
    }

    /* For a given date, get the ISO week number
     *
     * Based on information at:
     *
     *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
     *
     * Algorithm is to find nearest thursday, it's year
     * is the year of the week number. Then get weeks
     * between that date and the first day of that year.
     *
     * Note that dates in one year can be weeks of previous
     * or next year, overlap is up to 3 days.
     *
     * e.g. 2014/12/29 is Monday in week  1 of 2015
     *      2012/1/1   is Sunday in week 52 of 2011
     */
    static getWeekNumber(date: Date | string): number {
        date = parseDate(date);

        // Copy date so don't modify original
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
        // Get first day of year
        const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        const weekNumber = Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
        // Return array of year and week number
        return weekNumber;
    }

    static getISOString(date: Date | string): string {
        date = parseDate(date);

        return (
            `${date.getUTCFullYear()}` +
            `-` +
            leftPad(date.getUTCMonth() + 1) +
            "-" +
            leftPad(date.getUTCDate()) +
            "T" +
            leftPad(date.getUTCHours()) +
            ":" +
            leftPad(date.getUTCMinutes()) +
            ":" +
            leftPad(date.getUTCSeconds()) +
            "." +
            (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
            "Z"
        );
    }
}
