import { DeleteProofOfResidenceRequest, ProofOfResidenceRequest } from "../../../../services/zambia-application/registered/new/document.types";
import {
    abortAllBusinessDocumentRequests,
    addProofOfResidenceFile,
    deleteProofOfResidence,
    uploadProofOfResidence,
} from "../../../../services/zambia-application/registered/new/document.api";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setZambiaBusinessDocuments } from "../../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface UseUploadBusinessProofOfResidenceInterface {
    deleteBusinessProofOfResidenceError: string | null;
    isUploadBusinessProofOfResidenceLoading: boolean | null;
    isDeleteBusinessProofOfResidenceLoading: boolean;
    isAddBusinessProofOfResidenceFileLoading: boolean | null;
    handleReset: () => void;
    handleUploadBusinessProofOfResidence: (data: ProofOfResidenceRequest) => Promise<void>;
    handleDeleteBusinessProofOfResidence: (data: DeleteProofOfResidenceRequest) => Promise<void>;
    handleAddBusinessProofOfResidenceFile: (data: ProofOfResidenceRequest) => Promise<void>;
}

interface Props {
    onError: () => void;
    onComplete: () => void;
}

const useUploadBusinessProofOfResidence = (props?: Props): UseUploadBusinessProofOfResidenceInterface => {
    const dispatch = useDispatch();

    const [isUploadBusinessProofOfResidenceLoading, setIsUploadBusinessProofOfResidenceLoading] = useState<boolean | null>(false);
    const [deleteBusinessProofOfResidenceError, setDeleteBusinessProofOfResidenceError] = useState<string | null>(null);
    const [isDeleteBusinessProofOfResidenceLoading, setIsDeleteBusinessProofOfResidenceLoading] = useState<boolean>(false);
    const [isAddBusinessProofOfResidenceFileLoading, setIsAddBusinessProofOfResidenceFileLoading] = useState<boolean | null>(false);

    const handleUploadBusinessProofOfResidence = useCallback(
        async (_data: ProofOfResidenceRequest) => {
            try {
                setIsUploadBusinessProofOfResidenceLoading(true);
                const res = await uploadProofOfResidence(_data);
                dispatch(setZambiaBusinessDocuments(res));
                dispatch(messageTrue({ message: "Utility bill uploaded successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                props?.onError();
            } finally {
                setIsUploadBusinessProofOfResidenceLoading(false);
            }
        },
        [dispatch]
    );

    const handleAddBusinessProofOfResidenceFile = useCallback(
        async (_data: ProofOfResidenceRequest) => {
            try {
                setIsAddBusinessProofOfResidenceFileLoading(true);
                const res = await addProofOfResidenceFile(_data);
                dispatch(setZambiaBusinessDocuments(res));
                dispatch(messageTrue({ message: "Utility bill uploaded successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                props?.onError();
            } finally {
                setIsAddBusinessProofOfResidenceFileLoading(false);
            }
        },
        [dispatch]
    );

    const handleDeleteBusinessProofOfResidence = useCallback(
        async (_data: DeleteProofOfResidenceRequest) => {
            try {
                setDeleteBusinessProofOfResidenceError(null);
                setIsDeleteBusinessProofOfResidenceLoading(true);
                const res = await deleteProofOfResidence(_data);
                dispatch(setZambiaBusinessDocuments(res));
                props?.onComplete();
                dispatch(messageTrue({ message: "Utility bill deleted successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDeleteBusinessProofOfResidenceError(errorMessage);
            } finally {
                setIsDeleteBusinessProofOfResidenceLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = () => {
        setIsUploadBusinessProofOfResidenceLoading(false);
        setDeleteBusinessProofOfResidenceError(null);
        setIsDeleteBusinessProofOfResidenceLoading(false);
        setIsAddBusinessProofOfResidenceFileLoading(false);
        abortAllBusinessDocumentRequests();
    };

    return {
        deleteBusinessProofOfResidenceError,
        isUploadBusinessProofOfResidenceLoading,
        isDeleteBusinessProofOfResidenceLoading,
        isAddBusinessProofOfResidenceFileLoading,
        handleReset,
        handleUploadBusinessProofOfResidence,
        handleAddBusinessProofOfResidenceFile,
        handleDeleteBusinessProofOfResidence,
    };
};
export default useUploadBusinessProofOfResidence;
