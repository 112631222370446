import { BusinessContactForm, BusinessDetailsResponse, BusinessInformationForm, BusinessInformationResponse } from "./company.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../helpers/request/abortControllers";

import BusinessDetails from "../../../../../../models/account-opening/businessDetails";
import { GenericObject } from "../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../utils/parsers";
import { makeRequestWithSignal } from "../../../../../../helpers/request/makeRequest";

function parseResponse(res: GenericObject | Error): BusinessDetailsResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
        application: Parsers.classObjectNonNullable(res.application, BusinessDetails),
    };
}

export enum NigerianCompanyRequests {
    INIT = "nigerian-business.init",
    BASIC_INFO = "nigerian-business.basic-info",
    CONTACT_INFO = "nigerian-business.contact-info",
}

export const businessDetails = async (): Promise<BusinessDetailsResponse> => {
    const signal = getAbortControllerSignal(NigerianCompanyRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/ng/business/details", {}, signal);
    return parseResponse(res);
};

export const businessInformation = async (data: BusinessInformationForm): Promise<BusinessInformationResponse> => {
    const signal = getAbortControllerSignal(NigerianCompanyRequests.BASIC_INFO);
    const res = await makeRequestWithSignal("/account-opening/ng/business/registered/basic-info/update", data, signal);
    return parseResponse(res);
};

export const businessContact = async (data: BusinessContactForm): Promise<BusinessInformationResponse> => {
    const signal = getAbortControllerSignal(NigerianCompanyRequests.CONTACT_INFO);
    const res = await makeRequestWithSignal("/account-opening/ng/business/registered/contact-info/update", data, signal);
    return parseResponse(res);
};

export function abortNigerianCompanyRequest(type: NigerianCompanyRequests): void {
    abortRequest(type);
}

export function abortAllNigerianCompanyRequests(): void {
    Object.values(NigerianCompanyRequests).forEach((type) => abortRequest(type));
}
