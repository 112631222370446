import { DataSections } from "../../../hooks/state/BillPayment/billPayments.constants";
import PaymentHeader from "../../Layouts/payment/components/page-header";
import { useAppSelector } from "../../../../../../redux/hooks";

function DataPurchaseHeaders(): JSX.Element {
    const billPaymentStage = useAppSelector((state) => state.billPayment.billPaymentStage);
    return <PaymentHeader header={DataSections[billPaymentStage].text} subtitle={DataSections[billPaymentStage].subtext} />;
}

export default DataPurchaseHeaders;
