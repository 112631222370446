import {
    BillProductsRequest,
    BillProductsResponse,
    BillPurchaseInitRequest,
    BillPurchaseInitResponse,
    BillPurchasesHistoryRequest,
    BillPurchasesHistoryResponse,
    BillVerifyAccountRequest,
    BillVerifyAccountResponse,
    RecentBillPurchasesRequest,
    RecentBillPurchasesResponse,
    VendorRequest,
    VendorResponse,
} from "./billPaymentApi.types";
import { makeRequest, makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";

import BillProduct from "../../../../../models/billProduct";
import BillPurchase from "../../../../../models/billPurchase";
import BillVendor from "../../../../../models/billVendor";
import Parsers from "../../../../../utils/parsers";
import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";

export enum BillPaymentRequestType {
    VENDORS_LIST = "bill-payment.vendors-list",
    VERIFY_ACCOUNT = "bill-payment.verify-account",
    RECENT_PURCHASES = "bill-payment.recent-purchases",
    BILL_PURCHASE_INIT = "bill-payment.bill-purchase-init",
}

export const vendorsList = async (data: VendorRequest): Promise<VendorResponse> => {
    const signal = getAbortControllerSignal(BillPaymentRequestType.VENDORS_LIST);
    const res = await makeRequestWithSignal("/main/bills/vendors", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        vendors: Parsers.classObjectArray(res.vendors, BillVendor),
    };
};

export const billProducts = async (data: BillProductsRequest): Promise<BillProductsResponse> => {
    const res = await makeRequest("/main/bills/products", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        products: Parsers.classObjectArray(res.vendors, BillProduct),
    };
};

export const billVerifyAccount = async (data: BillVerifyAccountRequest): Promise<BillVerifyAccountResponse> => {
    const signal = getAbortControllerSignal(BillPaymentRequestType.VERIFY_ACCOUNT);
    const res = await makeRequestWithSignal("/main/bills/verify-account", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        name: Parsers.string(res.name),
    };
};

export const billPurchaseInit = async (data: BillPurchaseInitRequest): Promise<BillPurchaseInitResponse> => {
    const signal = getAbortControllerSignal(BillPaymentRequestType.BILL_PURCHASE_INIT);

    const res = await makeRequestWithSignal("/main/bills/purchase", data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        purchase: Parsers.classObjectNonNullable(res.purchase, BillPurchase),
    };
};

export const recentBillPurchases = async (data: RecentBillPurchasesRequest): Promise<RecentBillPurchasesResponse> => {
    const signal = getAbortControllerSignal(BillPaymentRequestType.RECENT_PURCHASES);

    const res = await makeRequestWithSignal("/main/bills/purchases/recent", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        purchases: Parsers.classObjectArray(res.purchases, BillPurchase),
    };
};

export const billPurchaseHistory = async (data: BillPurchasesHistoryRequest): Promise<BillPurchasesHistoryResponse> => {
    const res = await makeRequest("/main/bills/purchases/history", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        offset: Parsers.number(data.offset),
        purchases: Parsers.classObjectArray(res.purchases, BillPurchase),
    };
};

export const abortBillPayment = (): void => {
    abortRequest(BillPaymentRequestType.VENDORS_LIST);
    abortRequest(BillPaymentRequestType.RECENT_PURCHASES);
};
