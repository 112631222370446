import {
    Countries,
    CountryCodeLinkValue,
    CountryValue,
    LencoCountriesOptions,
    countryOptions,
} from "../../../../components/layouts/services/country.constant";
import { useLayoutEffect, useState } from "react";

import ButtonComp from "../../../../components/button/ButtonComp";
import CustomSelect from "../../../../components/dropdown/custom-select";
import LoginFooter from "../../../../components/layouts/components/landing/login-footer";
import Parsers from "../../../../utils/parsers";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";

function CompanyBase() {
    const navigate = useNavigate();

    const inviteURL = useAppSelector((state) => state.landing.inviteURL);

    const [link, setLink] = useState<string>("");
    const [value, setValue] = useState<Countries | null>(null);

    const queryParams = new URLSearchParams(location.search);
    const searchQuery = Parsers.nullableEnum((queryParams.get("country") || "").toLocaleLowerCase(), Countries);

    useLayoutEffect(() => {
        if (!searchQuery) return;
        const country = LencoCountriesOptions.find((_country) => _country.name === searchQuery);
        if (country) {
            setValue(country.name);
            setLink(`${country.link}${inviteURL || ""}?country=${country.value}`);
        }
    }, [searchQuery]);

    return (
        <>
            <div className="relative flex w-full max-w-[476px] flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex w-full flex-col items-center justify-start space-y-4">
                    <h3 className="break-words text-center text-2xl font-medium text-black">Tell us where your company is based</h3>
                    <div className="break-words text-center text-sm font-normal text-black-tertiary">
                        Let us know where your company is based so that we can assist you with your Lenco account opening. We adapt the requested
                        information based on the country you chose.
                    </div>
                </div>

                <CustomSelect
                    size="xl"
                    value={value}
                    label="Select Country"
                    options={countryOptions}
                    onSelect={(_value) => {
                        if (_value) {
                            setValue(_value);
                            setLink(`${CountryCodeLinkValue(_value)}${inviteURL || ""}`);
                        }
                    }}
                    withIcon
                />

                <ButtonComp
                    size="xl"
                    color="black"
                    buttonType="primary"
                    func={() => value && navigate({ pathname: link, search: `?country=${CountryValue[value].value}` })}
                    disable={!value}
                    fullWidth
                    isText
                >
                    Get started
                </ButtonComp>
            </div>
            <LoginFooter isLogin />
        </>
    );
}

export default CompanyBase;
