import React, { ReactNode } from "react";

interface Props {
    children: ReactNode;
    banner?: ReactNode;
}

export default function TableSummary({ children, banner }: Props) {
    return (
        <div
            className="relative my-2 flex w-full items-center overflow-hidden"
            style={{
                minHeight: "157px",
            }}
        >
            <div
                className="flex h-full w-full items-center justify-between overflow-hidden rounded-xl bg-white"
                style={{
                    height: "133px",
                }}
            >
                <div className="h-full w-full flex-1 py-4">
                    <div className="flex h-full w-full flex-1 items-center justify-center border-r-0 border-black-quin py-4 lg:border-r-0.5">
                        <div className="flex h-full w-full items-center">{children}</div>
                    </div>
                </div>
                {banner && <div className="hidden h-full w-96 items-end justify-center lg:flex">{banner}</div>}
            </div>
        </div>
    );
}
