import React from "react";
import Transaction from "../../../../../../../models/transaction";
import { formatDateAndTime } from "../../../../../../../utils/formatDate";
import titleCase from "../../../../../../../hooks/titleCase";
// import TransactionDetailCardChannel from "./TransactionDetailCardChannel";
// import TransactionDetailCardCharges from "./TransactionDetailCardCharges";
// import TransactionDetailCardClientReference from "./TransactionDetailCardClientReference";
// import TransactionDetailCardDate from "./TransactionDetailCardDate";
// import TransactionDetailCardDescription from "./TransactionDetailCardDescription";
// import TransactionDetailCardMerchantName from "./TransactionDetailCardMerchantName";
// import TransactionDetailCardNIP from "./TransactionDetailCardNIP";
// import TransactionDetailCardReference from "./TransactionDetailCardReference";
// import TransactionDetailCardRetrievalReferenceNumber from "./TransactionDetailCardRetrievalReferenceNumber";
// import TransactionDetailCardTime from "./TransactionDetailCardTime";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardBy({ dataType, transaction }: Props): JSX.Element {
    return (
        <div className="w-full rounded-lg bg-grey-backdrop p-4" data-type={dataType}>
            <div className="flex w-full flex-col items-start justify-start space-y-4" data-type={dataType}>
                {transaction.destination?.card && transaction.initiatedBy && (
                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                        <div>
                            <p className="text-sm text-black-secondary">Initiated By</p>
                        </div>

                        <div className="space-y-2 text-right" data-type={dataType}>
                            <p className="max-w-full break-words text-right text-sm font-medium text-black-secondary" data-type={dataType}>
                                {/* {titleCase(transaction.destination?.card?.name || "")} */}
                                {transaction.initiatedBy &&
                                    titleCase(transaction.initiatedBy.firstName + " " + titleCase(transaction.initiatedBy.lastName))}
                            </p>
                            <p className="max-w-full break-words text-right text-xs text-black-tertiary" data-type={dataType}>
                                {transaction.initiatedOn && formatDateAndTime(transaction.initiatedOn)}
                            </p>
                        </div>
                    </div>
                )}

                {transaction.destination?.card && transaction.approvedBy && (
                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                        <div>
                            <p className="text-sm text-black-secondary">Approved By</p>
                        </div>

                        <div className="space-y-2 text-right" data-type={dataType}>
                            <p className="max-w-full break-words text-right text-sm font-medium text-black-secondary" data-type={dataType}>
                                {transaction.approvedBy &&
                                    titleCase(transaction.approvedBy.firstName + " " + titleCase(transaction.approvedBy.lastName))}
                            </p>
                            <p className="max-w-full break-words text-right text-xs text-black-tertiary" data-type={dataType}>
                                {transaction.approvedOn && formatDateAndTime(transaction.approvedOn)}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TransactionDetailCardBy;
