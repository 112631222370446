import { LoginOtpRequest, LoginOtpResponse } from "../services/login-api.types";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { loginOtp } from "../services/login-api";
import { loginSuccess } from "../../../../redux/init/slice/initSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function useLoginOtp(): UseMutationResult<LoginOtpResponse, AxiosError | null, LoginOtpRequest, unknown> {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const otpLogin = useMutation({
        mutationFn: async (_data: LoginOtpRequest) => await loginOtp(_data),
        onSuccess() {
            dispatch(loginSuccess());
            navigate("/");
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return otpLogin;
}

export default useLoginOtp;
