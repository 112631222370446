import BusinessDetailsMin from "../../../../../models/account-opening/businessDetailsMin";

export type UpdateZambiaBusinessTypeRequest = {
    isRegistered: boolean;
};

export type UpdateZambiaBusinessTypeResponse = {
    isRegistered: boolean;
};
export type ZambiaBusinessAccountManagerResponse = {
    name: string;
    photo: string;
    email: string;
    phone: string;
};
export type UpdateZambiaBusinessAccountTypeRequest = {
    requestedProAccount: boolean;
};

export type UpdateZambiaBusinessAccountTypeResponse = {
    applicationMin: BusinessDetailsMin;
};

//Registered enums
export enum ZambiaRegisteredCompanyStages {
    BUSINESS_DETAILS = "details",
    BUSINESS_INFORMATION = "information",
    BUSINESS_CONTACT = "contact",
}
export enum ZambiaRegisteredSignatoryStages {
    SIGNATORY_ID_NUMBER = "id-number",
    SIGNATORY_DETAILS = "signatories",
    SIGNATORY_PERSONNEL = "personnel",
    SIGNATORY_SIGNATURE = "signature",
    SIGNATORY_PHOTOGRAPH = "photograph",
    SIGNATORY_NEXT_OF_KIN = "next-of-kin",
    SIGNATORY_MEANS_OF_ID = "means-of-id",
    SIGNATORY_KEY_CONTACT = "key-contact",
    SIGNATORY_PERSONAL_DETAILS = "personal-details",
}
export enum ZambiaRegisteredDocumentStages {
    DOCUMENT_UPLOAD = "upload",
}

export enum ZambiaRegisteredSections {
    COMPANY = "company",
    SIGNATORY = "signatory",
    UPLOAD = "upload",
    DOCUMENT = "documents",
    SIGNATURE = "signature",
    REVIEW = "review",
    DONE = "done",
    REGISTRATION = "registration-status",
    PERSONAL = "personal",
}

//Unregistered enums
export enum ZambiaUnregisteredPersonalStages {
    BVN = "bvn",
    PERSONAL_DETAILS = "details",
}
export enum ZambiaUnregisteredBusinessStages {
    BUSINESS_DETAILS = "business-details",
}
export enum ZambiaUnregisteredMeansOfIDStages {
    ID = "id",
    PHOTOGRAPH = "photograph",
}
export enum ZambiaUnregisteredSections {
    REVIEW = "review",
    BUSINESS = "business",
    PERSONAL = "personal",
    IDENTITY = "identity",
    DOCUMENT = "document",
}

//General
export enum ZambianReviewStages {
    REVIEW = "review",
}

//Zambia Sections
export type ZambiaApplicationSectionType = ZambiaRegisteredSections | ZambiaUnregisteredSections;

//Zambia Application Navigation
export type ZambiaRegisteredStagesType =
    | ZambiaRegisteredCompanyStages
    | ZambiaRegisteredSignatoryStages
    | ZambiaRegisteredDocumentStages
    | ZambianReviewStages;

//Zambia Application Navigation
export type ZambiaUnregisteredStagesType =
    | ZambiaUnregisteredPersonalStages
    | ZambiaUnregisteredBusinessStages
    | ZambiaUnregisteredMeansOfIDStages
    | ZambianReviewStages;

//Zambia Stages
export type ZambiaApplicationStagesType = ZambiaRegisteredStagesType | ZambiaUnregisteredStagesType;

//Zambia Stages
export const AllZambiaRegisteredStagesType = {
    ...ZambiaRegisteredCompanyStages,
    ...ZambiaRegisteredSignatoryStages,
    ...ZambiaRegisteredDocumentStages,
    ...ZambianReviewStages,
};
export type AllZambiaRegisteredStagesType = typeof AllZambiaRegisteredStagesType;

//Zambia Stages
export const AllZambiaUnregisteredStagesType = {
    ...ZambiaUnregisteredPersonalStages,
    ...ZambiaUnregisteredBusinessStages,
    ...ZambiaUnregisteredMeansOfIDStages,
    ...ZambianReviewStages,
};
export type AllZambiaUnregisteredStagesType = typeof AllZambiaUnregisteredStagesType;

//Unregistered Routes
export enum ZambiaUnregisteredRoutes {
    PERSONAL_DETAILS = "details",
    BUSINESS_DETAILS = "business-details",
    DOCUMENT = "document",
    ID = "id",
    PHOTOGRAPH = "photograph",
    REVIEW = "review",
}

//Registered Routes
export enum ZambiaRegisteredRoutes {
    BUSINESS_DETAILS = "details",
    BUSINESS_INFORMATION = "information",
    BUSINESS_CONTACT = "contact",
    SIGNATORY_DETAILS = "signatories",
    SIGNATORY_PERSONNEL = "personnel",
    SIGNATORY_PHOTOGRAPH = "photograph",
    SIGNATORY_MEANS_OF_ID = "means-of-id",
    SIGNATORY_PERSONAL_DETAILS = "personal-details",
    DOCUMENT_UPLOAD = "upload",
    REVIEW = "review",
}
