import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiAccessKeysFilterTypes, ApiAccessKeysStage, ApiAccessKeysState, ZambiaApiAccessKeySettingsTab } from "./apiAccessKeysSlice.types";
import { ApiAccessStatus } from "../../../../models/zambia/lencoPay/apiAccess.constant";
import ApiAccess from "../../../../models/zambia/lencoPay/apiAccess";

const initialState: ApiAccessKeysState = {
    apiAccessKeySettingTab: ZambiaApiAccessKeySettingsTab.API_KEYS,
    isCreateApiAccessKeyModalOpen: false,
    isRegenerateApiAccessKeyModalOpen: false,
    isDeactivateApiAccessKeyModalOpen: false,
    apiAccessKeysStage: ApiAccessKeysStage.LIST,
    apiAccessKeySecretKey: null,
    selectedApiAccessKey: null,
    tempSelectedApiAccessKey: null,
    apiAccessKeysList: null,
    isApiAccessKeysPaginationLoading: false,
    apiAccessKeysPaginationOffset: 0,
    apiAccessKeysListTotal: 0,
    apiAccessKeysListGroupSize: 0,
    isApiAccessKeysListEmpty: false,
    apiAccessKeysFilterState: {
        query: "",
        offset: 0,
        status: [],
    },
    selectedApiAccessKeysFilterState: {
        query: "",
        offset: 0,
        status: [],
    },
};

export const zambiaApiAccessKeysSlice = createSlice({
    name: "zambiaApiAccessKeys",
    initialState,
    reducers: {
        setZambiaApiAccessKeysStageToList: (state: ApiAccessKeysState) => {
            state.apiAccessKeysStage = ApiAccessKeysStage.LIST;
        },
        setZambiaApiAccessKeysStageToSettings: (state: ApiAccessKeysState) => {
            state.apiAccessKeysStage = ApiAccessKeysStage.SETTINGS;
        },

        setZambiaApiAccessKeyTab: (state: ApiAccessKeysState, action: PayloadAction<ZambiaApiAccessKeySettingsTab>) => {
            state.apiAccessKeySettingTab = action.payload;
        },

        setIsZambiaCreateApiAccessKeyModalOpen: (state: ApiAccessKeysState, action: PayloadAction<boolean>) => {
            state.isCreateApiAccessKeyModalOpen = action.payload;
        },
        setIsZambiaRegenerateApiAccessKeyModalOpen: (state: ApiAccessKeysState, action: PayloadAction<boolean>) => {
            state.isRegenerateApiAccessKeyModalOpen = action.payload;
        },
        setIsZambiaDeactivateApiAccessKeyModalOpen: (state: ApiAccessKeysState, action: PayloadAction<boolean>) => {
            state.isDeactivateApiAccessKeyModalOpen = action.payload;
        },

        setZambiaApiAccessKeySecretKey: (state: ApiAccessKeysState, action: PayloadAction<string>) => {
            state.apiAccessKeySecretKey = action.payload;
        },
        setSelectedZambiaApiAccessKey: (state: ApiAccessKeysState, action: PayloadAction<ApiAccess>) => {
            state.selectedApiAccessKey = action.payload;
            state.apiAccessKeysStage = ApiAccessKeysStage.SETTINGS;
        },
        setTempSelectedZambiaApiAccessKey: (state: ApiAccessKeysState, action: PayloadAction<ApiAccess>) => {
            state.tempSelectedApiAccessKey = action.payload;
        },

        setIsZambiaApiAccessKeysPaginationLoading: (state: ApiAccessKeysState, action: PayloadAction<boolean>) => {
            state.isApiAccessKeysPaginationLoading = action.payload;
        },
        setIsZambiaApiAccessKeysListEmpty: (state: ApiAccessKeysState, action: PayloadAction<boolean>) => {
            state.isApiAccessKeysListEmpty = action.payload;
        },

        setZambiaApiAccessKeysList: (state: ApiAccessKeysState, action: PayloadAction<ApiAccess[]>) => {
            state.apiAccessKeysList = action.payload;
        },
        setUpdateZambiaApiAccessKey: (state: ApiAccessKeysState, action: PayloadAction<ApiAccess>) => {
            if (state.apiAccessKeysList) {
                state.apiAccessKeysList = state.apiAccessKeysList.map((_apiAccesskey) =>
                    _apiAccesskey.id === action.payload.id ? action.payload : _apiAccesskey
                );
                state.selectedApiAccessKey = action.payload;
            }
        },
        setAddZambiaApiAccessKey: (state: ApiAccessKeysState, action: PayloadAction<ApiAccess>) => {
            if (state.apiAccessKeysList) {
                state.apiAccessKeysList = [...state.apiAccessKeysList, action.payload];
            } else {
                state.apiAccessKeysList = [action.payload];
            }
        },
        setZambiaApiAccessKeysListTotal: (state: ApiAccessKeysState, action: PayloadAction<number>) => {
            state.apiAccessKeysListTotal = action.payload;
        },
        setZambiaApiAccessKeysPaginationOffset: (state: ApiAccessKeysState, action: PayloadAction<number>) => {
            state.apiAccessKeysPaginationOffset = action.payload;
        },
        setZambiaApiAccessKeysListGroupSize: (state: ApiAccessKeysState, action: PayloadAction<number>) => {
            state.apiAccessKeysListGroupSize = action.payload;
        },
        setZambiaApiAccessKeysFilterState: (state: ApiAccessKeysState, action: PayloadAction<ApiAccessKeysFilterTypes>) => {
            state.apiAccessKeysFilterState = { ...state.apiAccessKeysFilterState, ...action.payload };
        },
        setSelectedZambiaApiAccessKeysFilterState: (state: ApiAccessKeysState) => {
            state.selectedApiAccessKeysFilterState = { ...state.apiAccessKeysFilterState };
        },

        setZambiaApiAccessKeysStatusFilter: (state: ApiAccessKeysState, action: PayloadAction<ApiAccessStatus>) => {
            state.selectedApiAccessKeysFilterState.status = state.selectedApiAccessKeysFilterState.status.filter(
                (_status) => _status !== action.payload
            );
            state.apiAccessKeysFilterState.status = state.apiAccessKeysFilterState.status.filter((_status) => _status !== action.payload);
        },

        resetZambiaApiAccessKeyTab: (state: ApiAccessKeysState) => {
            state.apiAccessKeySettingTab = ZambiaApiAccessKeySettingsTab.API_KEYS;
        },
        resetSelectedZambiaApiAccessKey: (state: ApiAccessKeysState) => {
            state.selectedApiAccessKey = null;
        },
        resetZambiaApiAccessKeySecretKey: (state: ApiAccessKeysState) => {
            state.apiAccessKeySecretKey = null;
        },
        resetTempSelectedZambiaApiAccessKey: (state: ApiAccessKeysState) => {
            state.tempSelectedApiAccessKey = null;
        },

        resetZambiaApiAccessKeysList: (state: ApiAccessKeysState) => {
            state.apiAccessKeysList = null;
        },

        resetZambiaApiAccessKeysStatusFilterState: (state: ApiAccessKeysState) => {
            state.selectedApiAccessKeysFilterState = { ...state.selectedApiAccessKeysFilterState, status: [] };
            state.apiAccessKeysFilterState = { ...state.apiAccessKeysFilterState, status: [] };
        },

        resetZambiaApiAccessKeysQueryFilterState: (state: ApiAccessKeysState) => {
            state.selectedApiAccessKeysFilterState = { ...state.selectedApiAccessKeysFilterState, query: "" };
            state.apiAccessKeysFilterState = { ...state.apiAccessKeysFilterState, query: "" };
        },
        resetZambiaApiAccessKeysFilterState: (state: ApiAccessKeysState) => {
            state.apiAccessKeysFilterState = {
                query: "",
                offset: 0,
                status: [],
            };
            state.selectedApiAccessKeysFilterState = {
                query: "",
                offset: 0,
                status: [],
            };
        },
        resetAllApiAccessKeysData: (state: ApiAccessKeysState) => {
            state.isCreateApiAccessKeyModalOpen = false;
            state.isRegenerateApiAccessKeyModalOpen = false;
            state.isDeactivateApiAccessKeyModalOpen = false;
            state.apiAccessKeysStage = ApiAccessKeysStage.LIST;
            state.apiAccessKeySettingTab = ZambiaApiAccessKeySettingsTab.API_KEYS;
            state.selectedApiAccessKey = null;
            state.apiAccessKeySecretKey = null;
            state.tempSelectedApiAccessKey = null;

            state.apiAccessKeysList = null;
            state.apiAccessKeysListTotal = 0;
            state.apiAccessKeysPaginationOffset = 0;
            state.apiAccessKeysListGroupSize = 0;
            state.isApiAccessKeysListEmpty = false;
            state.isApiAccessKeysPaginationLoading = false;
            state.apiAccessKeysFilterState = {
                query: "",
                offset: 0,
                status: [],
            };
            state.selectedApiAccessKeysFilterState = {
                query: "",
                offset: 0,
                status: [],
            };
        },
    },
});

export const {
    setIsZambiaCreateApiAccessKeyModalOpen,
    setIsZambiaRegenerateApiAccessKeyModalOpen,
    setIsZambiaDeactivateApiAccessKeyModalOpen,

    setZambiaApiAccessKeysStageToList,
    setZambiaApiAccessKeysStageToSettings,

    setZambiaApiAccessKeyTab,
    setZambiaApiAccessKeysList,
    setAddZambiaApiAccessKey,
    setUpdateZambiaApiAccessKey,
    setZambiaApiAccessKeysListTotal,
    setZambiaApiAccessKeysPaginationOffset,
    setZambiaApiAccessKeysFilterState,
    setIsZambiaApiAccessKeysListEmpty,
    setZambiaApiAccessKeysListGroupSize,
    setIsZambiaApiAccessKeysPaginationLoading,
    setZambiaApiAccessKeysStatusFilter,
    setSelectedZambiaApiAccessKeysFilterState,

    setSelectedZambiaApiAccessKey,
    setZambiaApiAccessKeySecretKey,
    setTempSelectedZambiaApiAccessKey,
    resetSelectedZambiaApiAccessKey,
    resetZambiaApiAccessKeySecretKey,
    resetTempSelectedZambiaApiAccessKey,

    resetZambiaApiAccessKeyTab,
    resetZambiaApiAccessKeysList,
    resetZambiaApiAccessKeysFilterState,
    resetZambiaApiAccessKeysQueryFilterState,
    resetZambiaApiAccessKeysStatusFilterState,
    resetAllApiAccessKeysData,
} = zambiaApiAccessKeysSlice.actions;

export default zambiaApiAccessKeysSlice.reducer;
