import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";
import ApiAccess from "../../../../../models/zambia/lencoPay/apiAccess";
import Parsers from "../../../../../utils/parsers";
import {
    ApiAccessKeysRequest,
    ApiAccessKeysResponse,
    CreateApiAccessKeyRequest,
    CreateApiAccessKeyResponse,
    DeactivateApiAccessKeysRequest,
    DeactivateApiAccessKeysResponse,
    PauseApiAccessKeysRequest,
    PauseApiAccessKeysResponse,
    RegenerateApiAccessKeysRequest,
    RegenerateApiAccessKeysResponse,
    RestoreApiAccessKeysRequest,
    RestoreApiAccessKeysResponse,
} from "./apiAccessKeysApi.types";

export enum ApiAccessRequestType {
    LIST = "api-access.list",
    PAUSE = "api-access.pause",
    CREATE = "api-access.create",
    RESTORE = "api-access.restore",
    DEACTIVATE = "api-access.deactivate",
    REGENERATE = "api-access.regenerate",
}

export const createApiAccessKey = async (_data: CreateApiAccessKeyRequest): Promise<CreateApiAccessKeyResponse> => {
    const signal = getAbortControllerSignal(ApiAccessRequestType.CREATE);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/create", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        secretKey: Parsers.string(res.secretKey),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const apiAccessKeys = async (_data: ApiAccessKeysRequest): Promise<ApiAccessKeysResponse> => {
    const signal = getAbortControllerSignal(ApiAccessRequestType.LIST);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        apiAccessKeys: Parsers.classObjectArray(res.apiAccessKeys, ApiAccess),
    };
};

export const pauseApiAccessKey = async (_data: PauseApiAccessKeysRequest): Promise<PauseApiAccessKeysResponse> => {
    const signal = getAbortControllerSignal(ApiAccessRequestType.PAUSE);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/pause", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const restoreApiAccessKey = async (_data: RestoreApiAccessKeysRequest): Promise<RestoreApiAccessKeysResponse> => {
    const signal = getAbortControllerSignal(ApiAccessRequestType.RESTORE);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/restore", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const deactivateApiAccessKey = async (_data: DeactivateApiAccessKeysRequest): Promise<DeactivateApiAccessKeysResponse> => {
    const signal = getAbortControllerSignal(ApiAccessRequestType.DEACTIVATE);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/deactivate", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const regenerateApiAccessKey = async (_data: RegenerateApiAccessKeysRequest): Promise<RegenerateApiAccessKeysResponse> => {
    const signal = getAbortControllerSignal(ApiAccessRequestType.REGENERATE);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/regenerate", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        secretKey: Parsers.string(res.secretKey),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export function abortApiAccessRequest(type: ApiAccessRequestType): void {
    abortRequest(type);
}

export function abortAllApiAccessRequests(): void {
    Object.values(ApiAccessRequestType).forEach((type) => abortRequest(type));
}
