import { HintType, ListStyle } from "../../applicationn/components/element/hint/hint.constant";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../components/button/ButtonComp";
import { CompanyHint } from "../../applicationn/services/nigeria-application/registered/company.types";
import DefaultSetupChannelsTray from "../Components/DefaultSetup/Trays/ChannelsTray";
import DefaultSetupCollectionFeesTray from "../Components/DefaultSetup/Trays/CollectionFeesTray";
import DefaultSetupOtherAccountsTray from "../Components/DefaultSetup/Trays/OtherAccountsTray";
import Hint from "../../applicationn/components/element/hint";
import { IRootState } from "../../../../redux/rootReducer";
import LencoPayTeamAccessModal from "../Components/Modals/LencoPayTeamAccessModal";
import LencoSpinner from "../../../../components/spinner";
import NewUserAccountDropdown from "../../../../components/user-account-dropdown";
import PageLayout from "../../../../components/layouts/page-layout";
import { Routes } from "../../../../routes/routes.constants";
import { ReactComponent as ShadowedProfile } from "../../../../assets/svg/Button/shadowed-profile.svg";
import { updateSettlementAccountIdDefaultSetupConfiguration } from "../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import useLencoPayDefaultSetupInit from "../hookss/DefaultSetup/useLencoPayDefaultSetupInit";
import { useNavigate } from "react-router-dom";
import useUpdateLencoPayDefaultSetup from "../hookss/DefaultSetup/useUpdateLencoPayDefaultSetup";

const ConfigurationGuide: CompanyHint[] = [
    {
        index: 0,
        span: (
            <span>
                The default settings would only apply to subsequent Lenco Pay setup. To edit specific API settings go to setup under the API you would
                like to edit.
            </span>
        ),
    },
];

function Configuration(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showTeamMemberAccessModal, setShowTeamMemberAccessModal] = useState(false);
    // const [userAccountsDropdownIsDisabled, setUserAccountsDropdownIsDisabled] = useState(true);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const tempDefaultSetupConfiguration = useSelector((state: IRootState) => state.zambiaDefaultSetup.tempDefaultSetupConfiguration);

    const [isChannelTrayOpen, setIsChannelTrayOpen] = useState(false);
    const [isCollectionFeeTrayOpen, setIsCollectionFeeTrayOpen] = useState(false);
    const [isOtherAccountsTrayOpen, setIsOtherAccountsTrayOpen] = useState(false);

    // const [listOfViewers, setListOfViewers] = useState<User[]>([]);
    // const [listOfManagers, setListOfManagers] = useState<User[]>([]);
    // const [selectedUserToRemove, setSelectedUserToRemove] = useState<User | null>(null);

    const handleShowTeamMemberAccessModal = useCallback(() => {
        setShowTeamMemberAccessModal(true);
    }, []);

    const isLencoPayEnabled = useSelector((state: IRootState) => state.init.main?.meta.lencoPay.isEnabled);
    const { isLencoPayDefaultSetupInitLoading, handleLencoPayDefaultSetupInit } = useLencoPayDefaultSetupInit();
    const { isUpdateLencoPayDefaultSetupLoading, handleUpdateLencoPayDefaultSetup } = useUpdateLencoPayDefaultSetup();
    // const {isUpdateLencoPayDefaultSetupLoading, handleUpdateLencoPayDefaultSetup} = useRemoveTeamMemberAccess();

    // const handleShowRemoveTeamMemberAccessModal = useCallback((_user: User) => {
    //     dispatch(setRemoveTeamMember(_user));
    //     setShowTeamMemberAccessModal(false);
    // }, []);

    useEffect(() => {
        void handleLencoPayDefaultSetupInit();
    }, []);

    /*useEffect(() => {
		if (accounts && accounts?.length < 2) setUserAccountsDropdownIsDisabled(true);
		else setUserAccountsDropdownIsDisabled(false);
	}, [accounts]);*/

    return (
        <>
            <LencoPayTeamAccessModal show={showTeamMemberAccessModal} toggle={() => setShowTeamMemberAccessModal((prev) => !prev)} />
            <DefaultSetupOtherAccountsTray
                settlementAccountId={tempDefaultSetupConfiguration.settlementAccountId || ""}
                active={isOtherAccountsTrayOpen}
                toggler={() => setIsOtherAccountsTrayOpen(false)}
            />
            <DefaultSetupCollectionFeesTray active={isCollectionFeeTrayOpen} toggler={() => setIsCollectionFeeTrayOpen(false)} />
            <DefaultSetupChannelsTray active={isChannelTrayOpen} toggler={() => setIsChannelTrayOpen(false)} />
            <PageLayout
                pageTitle="Default Settings"
                subTitle="Improve your business collections"
                headerButtons={
                    <div className="flex justify-center space-x-3">
                        <ButtonComp color="grey" func={handleShowTeamMemberAccessModal} type="button" buttonType="secondary" size="md">
                            <div className="flex items-center justify-center space-x-1">
                                <ShadowedProfile />
                                <span>Lenco Pay Access</span>
                            </div>
                        </ButtonComp>
                    </div>
                }
            >
                {isLencoPayDefaultSetupInitLoading && (
                    <div className="flex h-full w-full items-center justify-center">
                        <LencoSpinner />
                    </div>
                )}

                {!isLencoPayDefaultSetupInitLoading && tempDefaultSetupConfiguration && (
                    <>
                        <div className="flex w-full flex-col items-start justify-start space-x-8 pt-6 xl:flex-row">
                            <div className="w-full max-w-2xl space-y-8 pb-8">
                                <div className="flex w-full flex-col space-y-6 rounded-lg bg-white px-6 py-4">
                                    <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 2xs:grid-cols-2 2xs:justify-items-end">
                                        <div className="w-full">
                                            <h5 className="text-base font-medium capitalize text-black">Settlement Type</h5>
                                        </div>
                                        <div className="w-full max-w-15 space-y-0.5 rounded-md bg-grey-backdrop p-2 px-3">
                                            <div className="grid grid-cols-2">
                                                <p className="text-xs text-black-secondary">Mobile Money</p>
                                                <p className="text-right text-xs font-medium">Instant</p>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <p className="text-xs text-black-secondary">Cards</p>
                                                <p className="text-right text-xs font-medium">
                                                    T+1 <span className="font-normal">(Next Day)</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white px-6 py-4">
                                    <div className="flex w-full flex-col items-center justify-between xl:flex-row">
                                        <div className="flex w-full flex-col space-y-0.5">
                                            <h5 className="text-base font-medium capitalize text-black">Settlement account</h5>
                                            <p className="text-sm font-normal text-black-tertiary">Choose an account for settlement</p>
                                        </div>
                                        <div className="relative flex w-full max-w-15 justify-end">
                                            <NewUserAccountDropdown
                                                value={
                                                    !isLencoPayEnabled &&
                                                    accounts &&
                                                    accounts?.length < 2 &&
                                                    !tempDefaultSetupConfiguration.settlementAccountId
                                                        ? accounts[0].id
                                                        : tempDefaultSetupConfiguration.settlementAccountId || ""
                                                }
                                                options={accounts || null}
                                                onClick={(_value) => dispatch(updateSettlementAccountIdDefaultSetupConfiguration(_value))}
                                                size="md"
                                                initiatorCanSelect
                                                isHideInitials
                                            />
                                        </div>
                                    </div>

                                    <div className="w-full border-b border-grey-secondary"></div>

                                    <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 2xs:grid-cols-2 2xs:justify-items-end">
                                        <div className="flex w-full flex-1 flex-col space-y-0.5">
                                            <h5 className="text-base font-medium capitalize text-black">Collection Fees</h5>
                                            <p className="text-sm font-normal text-black-tertiary">Select who pays the cost of collections</p>
                                        </div>
                                        <ButtonComp
                                            color="blue"
                                            type="button"
                                            buttonType="tertiary"
                                            size="md"
                                            func={() => setIsCollectionFeeTrayOpen(true)}
                                        >
                                            <span className="font-medium">Manage</span>
                                        </ButtonComp>
                                    </div>
                                    <div className="w-full border-b border-grey-secondary"></div>

                                    <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 2xs:grid-cols-2 2xs:justify-items-end">
                                        <div className="flex w-full flex-1 flex-col space-y-0.5">
                                            <h5 className="text-base font-medium capitalize text-black">Channels</h5>
                                            <p className="text-sm font-normal text-black-tertiary">Select what services the API can access</p>
                                        </div>
                                        <ButtonComp
                                            color="blue"
                                            type="button"
                                            buttonType="tertiary"
                                            size="md"
                                            func={() => setIsChannelTrayOpen(true)}
                                        >
                                            <span className="font-medium">Manage</span>
                                        </ButtonComp>
                                    </div>

                                    <div className="w-full border-b border-grey-secondary"></div>

                                    <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 pb-2 2xs:grid-cols-2 2xs:justify-items-end">
                                        <div className="flex w-full flex-1 flex-col space-y-0.5">
                                            <h5 className="text-base font-medium capitalize text-black">
                                                Other Accounts <span className="font-normal">(Optional)</span>
                                            </h5>
                                            <p className="text-sm font-normal text-black-tertiary">Select other accounts that the API can access</p>
                                        </div>
                                        <ButtonComp
                                            color="blue"
                                            type="button"
                                            buttonType="tertiary"
                                            size="md"
                                            func={() => setIsOtherAccountsTrayOpen(true)}
                                        >
                                            <span className="font-medium">Manage</span>
                                        </ButtonComp>
                                    </div>
                                </div>
                                <div className="flex w-full items-center justify-center space-x-4 pt-2">
                                    {!isLencoPayEnabled && (
                                        <ButtonComp
                                            type="button"
                                            size="lg"
                                            color="grey"
                                            buttonType="secondary"
                                            func={() => navigate("/lenco-pay/get-started")}
                                        >
                                            <span className="px-5"> Cancel</span>
                                        </ButtonComp>
                                    )}
                                    <ButtonComp
                                        type="button"
                                        color="black"
                                        buttonType="primary"
                                        isLoading={isUpdateLencoPayDefaultSetupLoading}
                                        onClick={async () => {
                                            if (tempDefaultSetupConfiguration.settlementAccountId) {
                                                await handleUpdateLencoPayDefaultSetup({
                                                    ipWhitelist: tempDefaultSetupConfiguration.ipWhitelist,
                                                    otherAccountsAccessIds: tempDefaultSetupConfiguration.otherAccountsAccessIds,
                                                    webhookUrl: tempDefaultSetupConfiguration.webhookUrl || undefined,
                                                    scopes: tempDefaultSetupConfiguration.scopes,
                                                    collectionFeeSource: tempDefaultSetupConfiguration.collectionFeeSource,
                                                    settlementAccountId: tempDefaultSetupConfiguration.settlementAccountId,
                                                });
                                            }
                                            if (
                                                !isLencoPayEnabled &&
                                                accounts &&
                                                accounts?.length < 2 &&
                                                !tempDefaultSetupConfiguration.settlementAccountId
                                            ) {
                                                await handleUpdateLencoPayDefaultSetup({
                                                    ipWhitelist: tempDefaultSetupConfiguration.ipWhitelist || [],
                                                    otherAccountsAccessIds: tempDefaultSetupConfiguration.otherAccountsAccessIds || [],
                                                    webhookUrl: tempDefaultSetupConfiguration.webhookUrl || undefined,
                                                    scopes: tempDefaultSetupConfiguration.scopes,
                                                    collectionFeeSource: tempDefaultSetupConfiguration.collectionFeeSource,
                                                    settlementAccountId: accounts[0].id || "",
                                                });

                                                // route to the API table
                                                navigate(Routes.LENCO_PAY.APIS, {
                                                    state: {
                                                        from: "LencoPay initialization page",
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        {isLencoPayEnabled ? <span className="px-5 font-medium">Save Changes</span> : null}
                                        {!isLencoPayEnabled ? <span className="px-5 font-medium">Save & Continue</span> : null}
                                    </ButtonComp>
                                </div>
                            </div>

                            <div className="fixed w-full flex-1 lg:relative">
                                <Hint hintType={HintType.GUIDE} fullWidth listStyle={ListStyle.PLAIN} firstList={ConfigurationGuide} />
                            </div>
                        </div>
                    </>
                )}
            </PageLayout>
        </>
    );
}

export default Configuration;
