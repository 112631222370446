import {
    AccountSignatoriesRequests,
    abortAccountSignatoriesRequest,
    removeSignatory,
} from "../../../../../services/zambia-application/registered/new/accountSignatories.api";
import { useCallback, useState } from "react";

import { SignatoryActionRequest } from "../../../../../services/zambia-application/registered/new/accountSignatories.types";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { setZambiaAccountSignatories } from "../../../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface useRemoveSignatoryInterface {
    removeSignatoryError: string;
    isRemoveSignatoryLoading: boolean;

    handleReset: () => void;
    handleRemoveSignatory: (data: SignatoryActionRequest) => Promise<void>;
}

const useRemoveSignatory = (onComplete: () => void): useRemoveSignatoryInterface => {
    const [isRemoveSignatoryLoading, setIsRemoveSignatoryLoading] = useState<boolean>(false);
    const [removeSignatoryError, setIsRemoveSignatoryError] = useState<string>("");

    const dispatch = useDispatch();

    const handleRemoveSignatory = useCallback(async (_data: SignatoryActionRequest) => {
        try {
            setIsRemoveSignatoryError("");
            setIsRemoveSignatoryLoading(true);
            const res = await removeSignatory(_data);
            dispatch(setZambiaAccountSignatories(res));
            dispatch(messageTrue({ message: `Signatory removed successfully` }));
            onComplete();
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
            setIsRemoveSignatoryError(getErrorMessage(err));
        } finally {
            setIsRemoveSignatoryLoading(false);
        }
    }, []);

    const handleReset = useCallback(() => {
        setIsRemoveSignatoryError("");
        setIsRemoveSignatoryLoading(false);
        abortAccountSignatoriesRequest(AccountSignatoriesRequests.REMOVE_SIGNATORY);
    }, []);

    return {
        removeSignatoryError,
        isRemoveSignatoryLoading,

        handleReset,
        handleRemoveSignatory,
    };
};
export default useRemoveSignatory;
