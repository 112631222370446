import React from "react";
import SkeletonElement from "../../../../../../components/skeleton";

function MemberCardSkeleton(): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-row items-center justify-start space-x-4">
                <div className="flex items-center justify-center">
                    <div className="h-8 w-8">
                        <SkeletonElement avatar />
                    </div>
                </div>

                <SkeletonElement text />
            </div>
        </>
    );
}

export default MemberCardSkeleton;
