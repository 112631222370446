import { DeclineReason, DeclinedTransaction, SelectedTransaction } from "../../hooks/state/pending-approval/usePendingPayments.types";

import AvatarDetails from "../../../../../components/avatar-details";
import BulkPaymentCancelPayment from "../Elements/CancelPayment/BulkPaymentCancelPayment";
import BulkPaymentPendingApproval from "../Elements/PendingApproval/BulkPaymentPendingApproval";
import { IRootState } from "../../../../../redux/rootReducer";
import Money from "../../../../../components/money";
import React from "react";
import Transaction from "../../../../../models/transaction";
import getBankName from "../../../../../helpers/getBankName";
import titleCase from "../../../../../hooks/titleCase";
import { useSelector } from "react-redux";

interface PendingApprovalDataProps {
    data: Transaction;
    selectedPayment: SelectedTransaction | undefined;
    handleApprovePayment: (_data: Transaction) => void;
    handleDeclinePayment: (_data: Transaction, reason: DeclineReason) => void;
    handleSelectOrChangeCancelReason: (_data: DeclinedTransaction) => void;
    handleSelectOrChangeDeclineReason: (_data: DeclinedTransaction) => void;
    handleRemoveCancelOrChangeToCancel: (_data: Transaction) => void;
    handleRemoveDeclineOrChangeToDecline: (_data: Transaction) => void;
}
function PendingApprovalData(props: PendingApprovalDataProps): JSX.Element {
    const {
        data,
        selectedPayment,
        handleApprovePayment,
        handleSelectOrChangeCancelReason,
        handleSelectOrChangeDeclineReason,
        handleRemoveCancelOrChangeToCancel,
        handleRemoveDeclineOrChangeToDecline,
    } = props;

    const currentUserId = useSelector((state: IRootState) => state.init.main?.companyDetails.user.id);
    const userAccountMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);

    const currentUserAccountMeta = userAccountMeta?.find((_el) => _el.userAccountId === data?.userAccount?.id);
    const isApprover = !!(
        currentUserAccountMeta?.isApprover &&
        (currentUserAccountMeta.maxApprovalAmount ? Number(data.amount) <= currentUserAccountMeta.maxApprovalAmount : true)
    );
    const isInitiator = data.initiatedBy?.id === currentUserId;

    return (
        <>
            {data && (
                <tr className="h-20 max-w-full text-black-secondary">
                    <td>
                        <AvatarDetails
                            title={titleCase(
                                (data.origination && data.origination.userAccount
                                    ? data.origination.userAccount.bankAccount?.accountName
                                    : data.origination?.customerAccount?.bankAccount && data.origination.customerAccount.bankAccount?.accountName) ||
                                    ""
                            )}
                            size="xs"
                        />
                    </td>
                    <td>
                        <AvatarDetails
                            title={titleCase(
                                data.destination?.userAccount?.bankAccount?.accountName ||
                                    data.destination?.customerAccount?.bankAccount?.accountName ||
                                    data?.destination?.card?.name ||
                                    ""
                            )}
                            subtitle={`${
                                data.destination?.card
                                    ? data.destination?.card.panLast4Digits || ""
                                    : data.destination?.userAccount || data.destination?.customerAccount
                                      ? `${
                                            data.destination?.userAccount && data.destination.userAccount
                                                ? data.destination.userAccount.bankAccount?.accountNumber
                                                : data.destination?.customerAccount && data.destination.customerAccount.bankAccount?.accountNumber
                                        } • ${data.destination?.customerAccount ? titleCase(getBankName(data.destination.customerAccount.bankAccount?.bankCode || "")) : data.destination?.userAccount ? titleCase(getBankName(data.destination.userAccount.bankAccount?.bankCode || "")) : ""}`
                                      : ""
                            }`}
                            size="xs"
                        />
                    </td>
                    <td>
                        <p className="text-sm text-black-tertiary">{titleCase(data.description)}</p>
                    </td>
                    <td>
                        <div>
                            <p className="text-lg font-medium text-black-secondary">
                                <Money amount={data.amount} />
                            </p>
                            <p className="text-xs text-black-tertiary">
                                Initiated by: {titleCase((data.initiatedBy && data.initiatedBy.name) || "")}
                            </p>
                            {/* <span className="text-xs font-normal text-black-tertiary">
                                {data.userAccount?.bankCode !== data.destination?.customerAccount?.bankCode && (
                                    <>
                                        Fee: <Fee value={data.amount} />
                                    </>
                                )}
                                {data.userAccount?.bankCode === data.destination?.customerAccount?.bankCode && "Free"}
                            </span> */}
                        </div>
                    </td>
                    <td>
                        <div className="min-w-11">
                            {data.isPendingApproval && isApprover && (
                                <BulkPaymentPendingApproval
                                    transaction={data}
                                    selectedPayment={selectedPayment}
                                    handleApprovePayment={handleApprovePayment}
                                    handleSelectOrChangeDeclineReason={handleSelectOrChangeDeclineReason}
                                    handleRemoveDeclineOrChangeToDecline={handleRemoveDeclineOrChangeToDecline}
                                />
                            )}
                            {data.isPendingApproval && isInitiator && (
                                <BulkPaymentCancelPayment
                                    transaction={data}
                                    selectedPayment={selectedPayment}
                                    handleSelectOrChangeCancelReason={handleSelectOrChangeCancelReason}
                                    handleRemoveCancelOrChangeToCancel={handleRemoveCancelOrChangeToCancel}
                                />
                            )}
                            {data.isSuccess && (
                                <div className="flex items-center justify-center">
                                    <div className="items-center rounded-lg border border-success-quin bg-success-backdrop text-xs">
                                        <div className="p-2 px-4 text-success">Approved</div>
                                    </div>
                                </div>
                            )}
                            {data.isDeclined && (
                                <div className="flex items-center justify-center">
                                    <div className="items-center rounded-lg border border-error-quin bg-error-backdrop text-xs">
                                        <div className="p-2 px-4 text-error">Declined</div>
                                    </div>
                                </div>
                            )}
                            {data.isCancelled && (
                                <div className="flex items-center justify-center">
                                    <div className="items-center rounded-lg border border-error-quin bg-error-backdrop text-xs">
                                        <div className="p-2 px-4 text-error">Cancelled</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

export default PendingApprovalData;
