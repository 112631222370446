import { GenericObject } from "../../../helpers/types";
import Parsers from "../../../utils/parsers";

export default class ApiAccessWebhook {
    constructor(
        public key: string,
        public value: string
    ) {}

    static create(obj: GenericObject): ApiAccessWebhook {
        return new ApiAccessWebhook(Parsers.string(obj.key), Parsers.string(obj.value));
    }
}
