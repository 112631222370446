import { AccountBalanceAlertType, AccountBalanceInstructionType } from "../../../../../models/userAccount.constants";
import { setIsEditExcessBalance, setIsEditMinimumBalance } from "../../../../../redux/settings/security/settingsSecuritySlice";
import { useDispatch, useSelector } from "react-redux";

import EditBalanceAlertInstruction from "../account-settings/balance-alert/EditBalanceAlertInstruction";
import FullPageTray from "../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import { IRootState } from "../../../../../redux/rootReducer";
import UserAccount from "../../../../../models/userAccount";
import useBalanceAlertInstruction from "../../Services/account-settings/hooks/useBalanceAlertInstruction";

interface IProps {
    type: AccountBalanceAlertType;
    active: boolean;
    account: UserAccount;
    toggle: () => void;
}

function BalanceInstructionTray({ active, toggle, account, type }: IProps): JSX.Element {
    const { setIsEditMode, instruction, handleUpdateBalanceInstruction } = useBalanceAlertInstruction(account, type);

    const isEditMinimumBalance = useSelector((state: IRootState) => state.settingsSecurity.isEditMinimumBalance);
    const isEditExcessBalance = useSelector((state: IRootState) => state.settingsSecurity.isEditExcessBalance);

    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(setIsEditMinimumBalance(false));
        dispatch(setIsEditExcessBalance(false));
        setIsEditMode(false);
        toggle();
    };

    return (
        <>
            <FullPageTray active={active} dataType="setup-money-rule-tray" handleReset={handleCancel} withClickOutside>
                <FullPageTrayHeader toggler={toggle} dataType="setup-money-rule-tray">
                    <p className="text-2xl font-medium">{type === AccountBalanceInstructionType.LOW_BALANCE_ALERT ? "Minimum" : "Excess"} Balance</p>
                </FullPageTrayHeader>
                <FullPageTrayBody dataType="setup-money-rule-tray">
                    {(isEditMinimumBalance || isEditExcessBalance || !instruction) && (
                        <EditBalanceAlertInstruction
                            account={account}
                            type={
                                isEditExcessBalance
                                    ? AccountBalanceInstructionType.EXCESS_BALANCE_ALERT
                                    : AccountBalanceInstructionType.LOW_BALANCE_ALERT
                            }
                            handleCancel={handleCancel}
                            handleUpdateBalanceInstruction={handleUpdateBalanceInstruction}
                            canCancel
                        />
                    )}
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default BalanceInstructionTray;
