import {
    NigeriaApplicationSectionType,
    NigeriaApplicationStagesType,
    NigeriaRegisteredRoutes,
    NigeriaRegisteredSections,
    NigeriaUnregisteredRoutes,
    NigeriaUnregisteredSections,
} from "../../../../services/nigeria-application/nigeria-application.types";
import {
    getRegisteredNigeriaNavigationPath,
    getUnregisteredNigeriaNavigationPath,
} from "../../../../services/nigeria-application/nigeria-application.constants";
import { useLocation, useNavigate } from "react-router";

import { GenericApplicationPageType } from "../../../../services/application.constants";
import GoogleIcon from "../../../../../../../components/google-icon";
import Parsers from "../../../../../../../utils/parsers";
import React from "react";
import { Routes } from "../../../../../../../routes/routes.constants";

type LocationState = {
    from?: string;
    beforeFrom?: string;
};
interface Props {
    children: React.ReactNode;
    stage: NigeriaApplicationStagesType | undefined;
    section: NigeriaApplicationSectionType | GenericApplicationPageType;
    isRegistered: boolean;
}

function ApplicationBusinessLayout(props: Props): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const section = props.isRegistered
        ? Parsers.nullableEnum(props.section, NigeriaRegisteredSections) || Parsers.nullableEnum(props.section, GenericApplicationPageType)
        : Parsers.nullableEnum(props.section, NigeriaUnregisteredSections) || Parsers.nullableEnum(props.section, GenericApplicationPageType);

    const stage = props.isRegistered
        ? Parsers.nullableEnum(props.stage, NigeriaRegisteredRoutes)
        : Parsers.nullableEnum(props.stage, NigeriaUnregisteredRoutes);

    const navigation = props.isRegistered
        ? getRegisteredNigeriaNavigationPath(section as NigeriaRegisteredSections | GenericApplicationPageType, stage as NigeriaRegisteredRoutes)
        : getUnregisteredNigeriaNavigationPath(
              section as NigeriaUnregisteredSections | GenericApplicationPageType,
              stage as NigeriaUnregisteredRoutes
          ) || [];

    return (
        <div className="flex h-full w-full flex-col">
            <div className="relative flex h-full w-full flex-col items-start lg:px-0">
                <div className="flex w-full items-center justify-start space-x-2 overflow-auto pb-8 text-sm scrollbar-hide sm:overflow-hidden lg:w-max lg:max-w-max lg:pb-0.5">
                    <div className="w-max select-none rounded-[4px] bg-white px-2 py-1">
                        <p className="text-xs leading-[100%] text-black-tertiary xs:text-sm">Application</p>
                    </div>
                    {(locationState?.from === Routes.ACCOUNT_OPENING.REVIEW || locationState?.beforeFrom === Routes.ACCOUNT_OPENING.REVIEW) &&
                        !location.pathname.includes("/review") && (
                            <>
                                <GoogleIcon icon="chevron_right" size="sm" className="text-black-quat" isOutlined />
                                <div className="w-max select-none rounded-[4px] bg-white px-2 py-1">
                                    <p className="text-xs leading-[100%] text-black-tertiary xs:text-sm">Review</p>
                                </div>
                            </>
                        )}
                    {navigation.map((_route, index) => {
                        const isLast = index + 1 === navigation.length;
                        return (
                            <div className="flex w-max items-center justify-start space-x-2 text-2xs xs:text-xs" key={index}>
                                <GoogleIcon icon="chevron_right" size="sm" className="text-black-quat" isOutlined />
                                <div
                                    className={
                                        "flex items-center justify-start text-xs leading-none" +
                                        " w-max rounded-[4px] bg-white px-2 py-1" +
                                        ` ${isLast ? "font-medium text-black-secondary" : "select-none text-black-quat"}`
                                    }
                                >
                                    <p
                                        className={
                                            "select-none text-xs capitalize leading-[100%] xs:text-sm" +
                                            ` ${isLast ? "font-medium text-black-secondary" : "text-black-tertiary"}`
                                        }
                                    >
                                        {_route}
                                    </p>
                                    {isLast && (
                                        <div
                                            className="ml-2 max-w-[20px] cursor-pointer overflow-hidden text-black"
                                            onClick={(e) => {
                                                locationState?.from === Routes.ACCOUNT_OPENING.REVIEW
                                                    ? navigate(Routes.ACCOUNT_OPENING.REVIEW)
                                                    : navigate(Routes.ACCOUNT_OPENING.STATUS);

                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            <span className="material-symbols-rounded text-sm">close</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="h-fit-available flex w-full flex-shrink flex-grow basis-auto flex-col items-start justify-start lg:pt-12">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default ApplicationBusinessLayout;
