import { DropdownItem } from "../../../../../../../helpers/types";
import GoogleIcon from "../../../../../../../components/google-icon";
import NewDropdownItem from "../../../../../../../components/new-dropdown-item";
// import { ReactComponent as RightIcon } from "../../../../../../../assets/svg/RightIcon.svg";
import { UserRole } from "../../../../../../../models/userAccount.constants";

interface TeamMemberDropdownUserRolesProps<T extends number> {
    data: DropdownItem<T>;
    handleSetApprovalLimit?: () => void;
    roleType: boolean;
}

function TeamMemberDropdownUserRoles<T extends number>(props: TeamMemberDropdownUserRolesProps<T>): JSX.Element {
    return (
        <div className="relative w-full">
            <NewDropdownItem padding="sm" size="sm">
                <div className="flex w-full flex-row items-center justify-between space-x-1">
                    <span className="text-sm">{props.data.text}</span>
                    {(props.data.value === UserRole.BOTH || props.data.value === UserRole.APPROVER) && props.roleType && (
                        <GoogleIcon icon="keyboard_arrow_down" isOutlined size="xs" />
                    )}
                </div>
            </NewDropdownItem>

            {/* {(props.data.value === UserRole.BOTH || props.data.value === UserRole.APPROVER) && (
				<div className={`absolute left-44 top-0 ml-1 flex h-full flex-col rounded-lg bg-white shadow ` + `${!isHover ? "hidden" : "flex"}`}>
					<DropdownLink onClick={props.handleSetApprovalLimit && props.handleSetApprovalLimit}>
						<div className="flex h-full w-full flex-row items-center justify-start px-4 text-xs">Set approval level</div>
					</DropdownLink>
				</div>
			)} */}
        </div>
    );
}

export default TeamMemberDropdownUserRoles;
