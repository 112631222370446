import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { SignatoryStatus } from "./accountOpening.constants";
import { UserRole } from "../userAccount.constants";
import { immerable } from "immer";
import joinArray from "../../utils/joinArray";

export default class ZambiaSignatoryMin {
    [immerable] = true;

    constructor(
        public id: string,
        public userId: string | null,
        public firstName: string,
        public surname: string,
        public phone: string,
        public email: string,
        public isKeyContact: boolean,
        public isDirector: boolean,
        public status: SignatoryStatus,
        public userRole: UserRole | null // public tempKeyContact = false, // public tempDirector = false
    ) {}

    static create(obj: GenericObject): ZambiaSignatoryMin {
        return new ZambiaSignatoryMin(
            Parsers.string(obj.id),
            Parsers.nullableString(obj.userId),
            Parsers.string(obj.firstName),
            Parsers.string(obj.surname),
            Parsers.string(obj.phone),
            Parsers.string(obj.email),
            Parsers.boolean(obj.isKeyContact),
            Parsers.boolean(obj.isDirector),
            Parsers.number(obj.status),
            Parsers.nullableNumber(obj.userRole)
        );
    }

    get name(): string {
        return `${this.firstName} ${this.surname}`.trim();
    }

    get userRoleText(): string {
        switch (this.userRole) {
            case UserRole.BOTH:
                return "Initiator and Approver";
            case UserRole.APPROVER:
                return "Approver only";
            case UserRole.INITIATOR:
                return "Initiator only";
            default:
                return "";
        }
    }

    get signatoryDesignation(): string {
        const res = ["Signatory"];
        if (this.isDirector) {
            res.unshift("Director");
        }
        if (this.isKeyContact) {
            res.unshift("Key Contact");
        }
        return joinArray(res);
    }

    get isPending(): boolean {
        return this.status === SignatoryStatus.INVITE_PENDING;
    }

    get isSignedUp(): boolean {
        return this.status === SignatoryStatus.SIGNED_UP;
    }

    get isCompleted(): boolean {
        return this.status === SignatoryStatus.SIGNED_UP;
    }

    get isRemoved(): boolean {
        return this.status === SignatoryStatus.REMOVED;
    }

    get statusText(): string {
        return this.isSignedUp ? "Completed" : this.isRemoved ? "Removed" : this.isPending ? "Pending" : "";
    }
}
