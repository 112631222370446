import AddNewRecipientModal from "./AddNewRecipientModal";
import { ReactComponent as BlueCirclePlus } from "../../../../../assets/svg/Button/blue-circled-plus.svg";
import ButtonComp from "../../../../../components/button/ButtonComp";
import React from "react";
import useAddNewRecipient from "../../hooks/state/SavedRecipient/useAddNewRecipient";

function AddNewRecipient(): JSX.Element {
    const addNewRecipientData = useAddNewRecipient();

    return (
        <>
            <div className="w-max">
                <AddNewRecipientModal addNewRecipientData={addNewRecipientData} />

                <ButtonComp color="grey" type="button" buttonType="secondary" size="md" func={addNewRecipientData.openModal}>
                    <div className="flex items-center justify-center space-x-4">
                        <BlueCirclePlus /> <span className="text-black">Add New Recipient</span>
                    </div>
                </ButtonComp>
            </div>
        </>
    );
}

export default AddNewRecipient;
