import { GenericObject } from "../../helpers/types";
import MetaEntity from "./metaEntity";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class KinRelationship extends MetaEntity {
    [immerable] = true;

    constructor(
        public id: number,
        public name: string,
        public genderId: number | null
    ) {
        super(id, name);
    }

    static create(obj: GenericObject): KinRelationship {
        return new KinRelationship(Parsers.number(obj.id), Parsers.string(obj.name), Parsers.nullableNumber(obj.genderId));
    }
}
