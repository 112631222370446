import { setNewTransactionCategory, setTransactionCategory } from "../Services/transactionDetailApi";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import Transaction from "../../../../../../../models/transaction";
import TransactionCategory from "../../../../../../../models/transactionCategory";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { mainInitCategoriesUpdate } from "../../../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setSingleTransaction } from "../../../../../../../redux/transaction/slice/transactionSlice";

export interface UseTransactionDetailsCategoryResponse {
    transactionCategories: TransactionCategory[];
    isSubmitting: boolean;
    isError: boolean;
    handleSetTransactionCategory: (transaction: Transaction, categoryId: string) => Promise<void>;
    handleSetNewTransactionCategory: (transaction: Transaction, name: string) => Promise<void>;
}

function useTransactionDetailsCategory(): UseTransactionDetailsCategoryResponse {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const transactionCategories = useSelector<IRootState, TransactionCategory[]>(
        (state) => state.init.main?.companyDetails.transactionCategories || []
    );

    const handleSetTransactionCategory = useCallback(
        async (transaction: Transaction, categoryId: string): Promise<void> => {
            try {
                setIsError(false);
                setIsSubmitting(true);
                const updatedTransaction = await setTransactionCategory(transaction.id, categoryId);
                dispatch(messageTrue({ message: "Transaction Category Updated" }));
                dispatch(setSingleTransaction(updatedTransaction));
            } catch (err) {
                setIsError(true);
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    const handleSetNewTransactionCategory = useCallback(
        async (transaction: Transaction, name: string): Promise<void> => {
            try {
                setIsError(false);
                setIsSubmitting(true);
                const res = await setNewTransactionCategory(transaction.id, name);
                dispatch(messageTrue({ message: "Transaction Category Updated" }));
                dispatch(setSingleTransaction(res.transaction));
                dispatch(mainInitCategoriesUpdate(res.category));
            } catch (err) {
                setIsError(true);
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    return {
        isError,
        isSubmitting,
        transactionCategories,
        handleSetTransactionCategory,
        handleSetNewTransactionCategory,
    };
}

export default useTransactionDetailsCategory;
