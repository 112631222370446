import { CompanyDocumentFieldTexts, CompanyDocumentTypes } from "../../../../../../services/nigeria-application/registered/document.types";
import { useEffect, useRef, useState } from "react";

import ApplicationDeleteModal from "../../../../../modals/deleteModal";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import { FileDrop } from "react-file-drop";
import GoogleIcon from "../../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../../components/spinner";
import PreviewModal from "../../../../../modals/preview-modal";
import RadioButton from "../../../../../../../../../components/radio-button";
import usePreview from "../../../../../../Hooks/nigeria-application/registered-business/account-signatories/usePreview";
import { useSelector } from "react-redux";
import useUploadBusinessUtilityBill from "../../../../../../Hooks/nigeria-application/registered-business/business-documents/useUploadBusinessUtilityBill";

interface UtilityBillAccordionProps {
    title: string;
    active: boolean;
    fileId: string | null;
    fileType: number | null;
    description: string;
    isExternalLink?: boolean;

    toggler: () => void;
}

function UtilityBillAccordion(props: UtilityBillAccordionProps): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
        downloadedDocumentName: CompanyDocumentFieldTexts[CompanyDocumentTypes.UTILITY_BILL],
    });

    const accountOpening = useSelector((state: IRootState) => state.application.init);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const {
        deleteBusinessUtilityBillError,
        isUploadBusinessUtilityBillLoading,
        isDeleteBusinessUtilityBillLoading,
        // isAddBusinessUtilityBillFileLoading,
        handleUploadBusinessUtilityBill,
        handleDeleteBusinessUtilityBill,
        // handleAddBusinessUtilityBillFile,
    } = useUploadBusinessUtilityBill({
        onDeleteComplete: () => {
            setIsDeleteModalOpen(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
            setType(null);
        },
        onError: () => {
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        },
    });

    const [type, setType] = useState<number | null>(null);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);

    useEffect(() => {
        setIsUploaded(!!props.fileId);
        setType(props.fileType);
    }, [props.active, props.fileId, props.fileType]);

    return (
        <div>
            <PreviewModal active={showPreviewModal} toggler={() => setShowPreviewModal(false)} data={previewBlob} title={`${props.title} Preview`} />
            <ApplicationDeleteModal
                header="Delete Document"
                onSubmit={() => void handleDeleteBusinessUtilityBill()}
                error={deleteBusinessUtilityBillError || ""}
                deleteButtonText="Delete"
                active={isDeleteModalOpen}
                isSubmitLoading={isDeleteBusinessUtilityBillLoading}
                toggler={() => setIsDeleteModalOpen(false)}
                body={
                    <>
                        <span className="w-full whitespace-normal break-words text-left text-base font-normal">
                            This action cannot be undone. Are you sure you want to delete this document?
                        </span>
                    </>
                }
            />
            <div className="flex w-full flex-col space-y-6 rounded-md border border-grey-secondary px-5 py-6">
                <div className="flex flex-row justify-start border-b border-grey-secondary pb-6">
                    <div className="flex h-full w-full max-w-[20px] items-center justify-center overflow-hidden">
                        {isUploaded && <span className="material-symbols-rounded text-xl text-success">check</span>}
                        {!isUploaded && <span className="material-symbols-rounded text-xl">add_notes</span>}
                    </div>
                    <div className="ml-3 flex flex-col items-start justify-start">
                        <h3 className="font-medium text-black-secondary">{props.title}</h3>
                        {props.description && <div className="text-sm font-normal text-black-tertiary">{props.description}</div>}
                    </div>
                </div>
                <div className="flex w-full flex-col items-start justify-start space-y-4 xs:flex-row xs:space-x-4 xs:space-y-0">
                    <div className="flex w-max flex-col items-start justify-start space-y-4">
                        {accountOpening?.options?.utilityBillType.map((el) => (
                            <RadioButton
                                id={String(el.id)}
                                checked={type === el.id}
                                text={el.name}
                                key={el.id}
                                isDisabled={isUploaded}
                                func={() => (type === el.id ? setType(null) : setType(el.id))}
                                size="sm"
                            />
                        ))}
                    </div>
                    <div className="flex h-full w-full flex-col items-end justify-end self-end">
                        <div className="flex w-full items-center justify-center overflow-x-auto scrollbar-hide 4xs:items-end 4xs:justify-end 4xs:self-end">
                            {!isUploaded ? (
                                <>
                                    <div
                                        className="flex h-24 w-full min-w-15 max-w-15 cursor-pointer items-center justify-center rounded-lg border border-dashed border-grey bg-blue-senary"
                                        onClick={() => {
                                            fileInputRef.current && fileInputRef.current.value === "";
                                            fileInputRef.current && fileInputRef.current.click();
                                        }}
                                    >
                                        <FileDrop
                                            onDrop={(files: FileList | null) => {
                                                if (files && files[0].size > 5242880) {
                                                    return setIsFileSizeExceeded(true);
                                                }
                                                setIsFileSizeExceeded(false);

                                                files &&
                                                    files[0] &&
                                                    void handleUploadBusinessUtilityBill({ file: files[0], utilityBillTypeId: type || 0 });
                                            }}
                                        >
                                            <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
                                                {isUploadBusinessUtilityBillLoading ? (
                                                    <LencoSpinner color="blue" size="sm" />
                                                ) : (
                                                    <GoogleIcon icon="upload" className="text-blue" isOutlined />
                                                )}
                                                <p className="text-center text-xs font-medium text-blue">
                                                    Click or Drag and Drop
                                                    {/* Click to upload
                                            <br />
                                            or
                                            <br />
                                            Drag and Drop */}
                                                </p>
                                                {isFileSizeExceeded && <span className="text-xs text-error">*File exceeded 5MB</span>}
                                            </div>
                                        </FileDrop>
                                    </div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        onChange={(event) => {
                                            if (event.target.files && event.target.files[0].size > 5242880) {
                                                return setIsFileSizeExceeded(true);
                                            }
                                            setIsFileSizeExceeded(false);
                                            const files = event.target.files;
                                            if (files && files[0]) {
                                                void handleUploadBusinessUtilityBill({ file: files[0], utilityBillTypeId: type || 0 });
                                            }
                                        }}
                                        accept="image/jpeg, image/png, application/pdf, .pdf"
                                        multiple
                                    />
                                    {/* <ButtonComp
                                        color="blue"
                                        size="sm"
                                        buttonType="flat"
                                        borderSmall
                                        isLoading={isUploadBusinessUtilityBillLoading}
                                        func={() => fileInputRef.current && fileInputRef.current.click()}
                                        disable={!type}
                                    >
                                        <div className="flex items-center justify-center space-x-2">
                                            <p className="font-medium">upload document</p>
                                            <span className="material-symbols-rounded text-xl">upload</span>
                                        </div>
                                    </ButtonComp> */}
                                </>
                            ) : (
                                <div className="flex flex-col items-center justify-center space-y-4 self-center 4xs:flex-row 4xs:justify-start 4xs:space-x-4 4xs:space-y-0">
                                    <ButtonComp
                                        color="blue"
                                        buttonType="flat"
                                        size="sm"
                                        isLoading={isUploadBusinessUtilityBillLoading}
                                        func={() => {
                                            if (fileInputRef.current) {
                                                fileInputRef.current.value = "";
                                            }
                                            setIsUploaded(false);
                                        }}
                                    >
                                        <span>Re-upload</span>
                                        <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden">
                                            <span className="material-symbols-outlined text-xl">restart_alt</span>
                                        </div>
                                    </ButtonComp>
                                    <div className="hidden w-[1px] items-center justify-center 4xs:flex">
                                        <div className="h-6 w-full bg-black-quin"></div>
                                    </div>
                                    <ButtonComp color="blue" buttonType="flat" size="sm" func={() => void handlePreviewDocument(props.fileId || "")}>
                                        <div className="flex w-full items-center justify-center">
                                            <p className="text-sm font-medium">Preview</p>
                                            <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden text-xl">
                                                <span className="material-symbols-outlined icon-fill">visibility</span>
                                            </div>
                                        </div>
                                    </ButtonComp>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <BoxDropdown
                header={props.title}
                subTitle={props.description}
                toggle={props.toggler}
                isOpened={props.active}
                checkActive={isUploaded}
                hasCheck
            >
                <div className="w-full border-t border-grey-secondary">
                    <div className="mt-5 flex w-full flex-col items-start space-y-4 text-base font-normal lg:items-center lg:px-0">
                        {!isUploaded && (
                            <div className="flex w-full flex-col items-start justify-start space-y-4 xs:flex-row xs:space-x-4 xs:space-y-0">
                                <div className="flex w-64 flex-col items-start justify-start space-y-4">
                                    {accountOpening?.options?.utilityBillType.map((el) => (
                                        <RadioButton
                                            id={String(el.id)}
                                            checked={type === el.id}
                                            text={el.name}
                                            key={el.id}
                                            func={() => (type === el.id ? setType(null) : setType(el.id))}
                                            size="sm"
                                        />
                                    ))}
                                </div>
                                <div className="flex w-full items-end justify-end self-end">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        onChange={(event) => {
                                            const files = event.target.files;
                                            if (files && files[0]) {
                                                void handleUploadBusinessUtilityBill({ file: files[0], utilityBillTypeId: type || 0 });
                                            }
                                        }}
                                        accept="image/jpeg, image/png, application/pdf, .pdf"
                                        multiple
                                    />
                                    <ButtonComp
                                        color="blue"
                                        size="sm"
                                        buttonType="flat"
                                        borderSmall
                                        isLoading={isUploadBusinessUtilityBillLoading}
                                        func={() => fileInputRef.current && fileInputRef.current.click()}
                                        disable={!type}
                                    >
                                        <div className="flex items-center justify-center space-x-2">
                                            <p className="font-medium">upload document</p>
                                            <span className="material-symbols-rounded text-xl">upload</span>
                                        </div>
                                    </ButtonComp>
                                </div>
                            </div>
                        )}
                        {isUploaded && (
                            <div className="flex w-full flex-row items-center justify-between">
                                <div className="flex cursor-pointer flex-row items-center space-x-2" onClick={() => setIsDeleteModalOpen(true)}>
                                    <div className="flex w-full max-w-4 items-center justify-center text-base">
                                        <span className="material-symbols-outlined">delete</span>
                                    </div>
                                    <span className="whitespace-nowrap text-sm text-black-tertiary 4xs:text-base">Document</span>
                                </div>
                                <div className="flex flex-row justify-start space-x-2 divide-x-2 border-black-quin">
                                    <div className="border-black-quin">
                                        <ButtonComp
                                            color="blue"
                                            buttonType="tertiary"
                                            size="md"
                                            isLoading={isUploadBusinessUtilityBillLoading}
                                            func={() => {
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = "";
                                                }
                                                setIsUploaded(false);
                                            }}
                                        >
                                            <span>Re-upload</span>
                                            <div className="ml-1 max-w-[20px] overflow-hidden text-xl">
                                                <span className="material-symbols-outlined">restart_alt</span>
                                            </div>
                                        </ButtonComp>
                                    </div>

                                    <div className="border-black-quin pl-2">
                                        <ButtonComp
                                            color="blue"
                                            buttonType="tertiary"
                                            size="md"
                                            func={() => void handlePreviewDocument(props.fileId || "")}
                                        >
                                            <span>Preview</span>
                                            <div className="ml-1 max-w-[20px] overflow-hidden text-xl">
                                                <span className="material-symbols-outlined">visibility</span>
                                            </div>
                                        </ButtonComp>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </BoxDropdown> */}
        </div>
    );
}

export default UtilityBillAccordion;
