import { useEffect, useState } from "react";

import Bank from "../../../../../models/bank";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import NewSelect from "../../../../../components/new-select";
import { suggestBanks } from "../../../../../helpers/bankSuggestion";
import titleCase from "../../../../../hooks/titleCase";
import useBanksList from "../../../../../helpers/useBanksList";

interface Props {
    label?: string;
    bankCode: string;
    isDisabled?: boolean;
    withoutLenco?: boolean;
    accountNumber: string;

    onClick: (bankCode: string) => void;
}

interface LabelInterface {
    name: string;
}

function BankSelect({ label, onClick, bankCode, isDisabled = false, withoutLenco, accountNumber }: Props): JSX.Element {
    const { banksList } = useBanksList({ withoutLenco: !!withoutLenco });
    const [test, setTest] = useState<Bank[] | null>(null);
    const [banks, setBanks] = useState<Array<Bank | LabelInterface>>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedBank, setSelectedBank] = useState<Bank | null>(null);

    useEffect(() => {
        if (!banksList) return;
        setBanks(banksList);
    }, [banksList]);

    useEffect(() => {
        if (!bankCode || !banksList) return setSelectedBank(null);
        setSelectedBank((banksList.find((el) => el.code === bankCode) as Bank) || null);
        return;
    }, [banksList, bankCode]);

    useEffect(() => {
        if (!accountNumber || accountNumber.length < 8 || !banksList) return;
        setTest(suggestBanks(banksList, accountNumber));
    }, [accountNumber, banksList]);

    useEffect(() => {
        if (!banksList) return;
        if (test && test.length > 0) {
            const arrayContainer = [];
            arrayContainer.push({ name: "Suggested Banks" });
            arrayContainer.push(...test);
            arrayContainer.push({ name: "All Banks" });
            arrayContainer.push(...banksList);
            setBanks(arrayContainer);
        } else {
            const arrayContainer = [];
            arrayContainer.push({ name: "All Banks" });
            arrayContainer.push(...banksList);
            setBanks(arrayContainer);
        }
        return;
    }, [test, banksList]);

    useEffect(() => {
        const filteredAllBanks = (banksList || []).filter((item: Bank | LabelInterface) => {
            if (!item || !item.name) {
                return false;
            }
            if (!searchTerm || searchTerm.trim().length === 0) {
                return true;
            }
            if (item instanceof Bank) {
                return item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.shortName.toLowerCase().includes(searchTerm.toLowerCase());
            }
            return false;
        });
        if (searchTerm.length === 0 || searchTerm.trim().length === 0) {
            if (test && test?.length > 0) {
                return setBanks([{ name: "Suggested Banks" }, ...test, { name: "All Banks" }, ...filteredAllBanks]);
            }
            return setBanks([{ name: "All Banks" }, ...filteredAllBanks]);
        }
        if (filteredAllBanks.length > 0) {
            setBanks([{ name: "Filtered Banks" }, ...filteredAllBanks]);
        } else {
            setBanks([]);
        }
    }, [banksList, searchTerm]);

    const handleChangeSearchTerm = (e: string) => {
        setSearchTerm(e);
    };

    return (
        <>
            <NewSelect
                size="xl"
                label={label || "Select Bank"}
                value={titleCase((selectedBank && selectedBank.name) || "")}
                searchTerm={searchTerm}
                dropdownSize="md"
                optionsLength={banks?.length}
                searchPlaceholder="Search for bank"
                handleChangeSearchTerm={handleChangeSearchTerm}
                clickAndClose
                isSearchable
                isDisabled={isDisabled}
                fitHeight
            >
                {(index) => (
                    <>
                        {!(banks[index] as Bank).code && (
                            <NewDropdownItem size="md" padding="none" onClick={() => onClick((banks[index] as Bank).code)}>
                                <div className="flex h-full w-full items-center justify-start bg-gray-100 px-4">
                                    <p className="text-sm text-black">{banks[index].name}</p>
                                </div>
                            </NewDropdownItem>
                        )}
                        {(banks[index] as Bank).code && (
                            <NewDropdownItem size="md" onClick={() => onClick((banks[index] as Bank).code)}>
                                <p className="text-sm">{titleCase(banks[index].name)}</p>
                            </NewDropdownItem>
                        )}
                    </>
                )}
            </NewSelect>
        </>
    );
}

export default BankSelect;
