import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    ZambiaPaymentMethod,
    ZambiaSinglePaymentInternalStage,
    ZambiaSinglePaymentResponse,
    ZambiaSingleTransferState,
} from "./singleTransferSlice.types";

import BankAccount from "../../../../../models/bankAccount";
import UserAccount from "../../../../../models/userAccount";

const initialState: ZambiaSingleTransferState = {
    singleTransferDetails: {
        originatingAccountId: "",
        recipient: {
            bankAccountId: "",
            customerAccountId: "",
            accountNumber: "",
            bankCode: "",
        },
        mobileMoneyRecipient: {
            mobileNumber: "",
            firstName: "",
            lastName: "",
        },
        paymentMethod: null,
        amount: "",
        purpose: "",
        otp: "",
    },
    selectedUserAccount: null,
    canVerifyRecipientAccountDetails: true,
    recipientBankAccountName: "",
    singleTransferResponse: null,
    singlePaymentInternalStage: ZambiaSinglePaymentInternalStage.PAYMENT_DETAILS_STAGE,
};

export const zambiaSingleTransferSlice = createSlice({
    name: "zambia-single-transfer",
    initialState,
    reducers: {
        setZambiaSingleTransferOriginatingAccount: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.originatingAccountId = action.payload;
        },
        setZambiaSingleTransferAmount: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.amount = action.payload;
        },
        resetZambiaSingleTransferAmount: (state: ZambiaSingleTransferState) => {
            state.singleTransferDetails.amount = "";
        },
        setZambiaSingleTransferPurpose: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.purpose = action.payload;
        },
        setZambiaSingleTransferOtp: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.otp = action.payload;
        },
        setZambiaSingleTransferRecipient: (state: ZambiaSingleTransferState, action: PayloadAction<BankAccount>) => {
            state.singleTransferDetails.recipient.accountNumber = action.payload.accountNumber;
            state.singleTransferDetails.recipient.bankAccountId = action.payload.id;
            state.singleTransferDetails.recipient.bankCode = action.payload.bankCode;
            state.singleTransferDetails.recipient.customerAccountId = "";
            state.recipientBankAccountName = action.payload.accountName;
        },
        setZambiaSingleTransferRecipientAccountNumber: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.recipient.accountNumber = action.payload;
        },
        setZambiaSingleTransferRecipientBankCode: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.recipient.bankCode = action.payload;
        },
        setSelectedZambiaUserAccount: (state: ZambiaSingleTransferState, action: PayloadAction<UserAccount>) => {
            state.selectedUserAccount = action.payload;
        },
        setCanVerifyZambiaRecipientAccountDetails: (state: ZambiaSingleTransferState, action: PayloadAction<boolean>) => {
            state.canVerifyRecipientAccountDetails = action.payload;
        },
        setZambiaSingleTransferResponse: (state: ZambiaSingleTransferState, action: PayloadAction<ZambiaSinglePaymentResponse>) => {
            state.singleTransferResponse = action.payload;
        },

        setZambiaPaymentMethod: (state: ZambiaSingleTransferState, action: PayloadAction<ZambiaPaymentMethod>) => {
            state.singleTransferDetails.paymentMethod = action.payload;
        },
        setZambiaMobileMoneyRecipientNumber: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.mobileMoneyRecipient.mobileNumber = action.payload;
        },
        setZambiaMobileMoneyFirstName: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.mobileMoneyRecipient.firstName = action.payload;
        },
        setZambiaMobileMoneyLastName: (state: ZambiaSingleTransferState, action: PayloadAction<string>) => {
            state.singleTransferDetails.mobileMoneyRecipient.lastName = action.payload;
        },

        setZambiaSinglePaymentInternalStage: (state: ZambiaSingleTransferState, action: PayloadAction<ZambiaSinglePaymentInternalStage>) => {
            state.singlePaymentInternalStage = action.payload;
        },
        resetZambiaSingleTransferRecipient: (state: ZambiaSingleTransferState) => {
            state.singleTransferDetails.recipient.accountNumber = "";
            state.singleTransferDetails.recipient.bankAccountId = "";
            state.singleTransferDetails.recipient.bankCode = "";
            state.singleTransferDetails.recipient.customerAccountId = "";
        },
        resetAllZambiaSingleTransferData: (state: ZambiaSingleTransferState) => {
            state.selectedUserAccount = null;
            state.canVerifyRecipientAccountDetails = true;
            state.recipientBankAccountName = "";
            state.singleTransferResponse = null;
            state.singlePaymentInternalStage = ZambiaSinglePaymentInternalStage.PAYMENT_DETAILS_STAGE;
            state.singleTransferDetails = {
                originatingAccountId: "",
                recipient: {
                    bankAccountId: "",
                    customerAccountId: "",
                    accountNumber: "",
                    bankCode: "",
                },
                mobileMoneyRecipient: {
                    mobileNumber: "",
                    firstName: "",
                    lastName: "",
                },
                paymentMethod: null,
                amount: "",
                purpose: "",
                otp: "",
            };
        },
    },
});

export const {
    setZambiaSingleTransferOriginatingAccount,
    setZambiaSingleTransferAmount,
    setZambiaSingleTransferPurpose,
    setZambiaSingleTransferOtp,
    setZambiaSingleTransferRecipient,
    setSelectedZambiaUserAccount,
    setZambiaPaymentMethod,
    setZambiaSingleTransferRecipientAccountNumber,
    setZambiaSingleTransferRecipientBankCode,
    setCanVerifyZambiaRecipientAccountDetails,
    setZambiaSingleTransferResponse,
    setZambiaMobileMoneyLastName,
    setZambiaMobileMoneyFirstName,
    setZambiaMobileMoneyRecipientNumber,
    setZambiaSinglePaymentInternalStage,
    resetZambiaSingleTransferAmount,
    resetZambiaSingleTransferRecipient,
    resetAllZambiaSingleTransferData,
} = zambiaSingleTransferSlice.actions;

export default zambiaSingleTransferSlice.reducer;
