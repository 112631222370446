import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange, RangeKeyDict } from "react-date-range";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import useDimension from "../../../hooks/useDimension";
import { TerminalTransactionDateType } from "../../../redux/terminals/slice/terminalsSlice.types";
import { DateTypes } from "../Type";
import Modal from "../../modal/Modal";
import ModalBody from "../../modal/modal-body";
import ModalFooter from "../../modal/modal-footer";
import ButtonComp from "../../button/ButtonComp";
import ModalHeader from "../../modal/modal-header";

interface Props {
    active: boolean;
    toggle: () => void;
    filterFunc: (_data: TerminalTransactionDateType) => void;
}

interface CustomDateObj {
    startDate: Date;
    endDate: Date;
    key: string;
}

function CustomDatePickerModal({ active: isCustomModalOpen, toggle, filterFunc }: Props): JSX.Element {
    const { width } = useDimension();

    const [date, setDate] = useState<CustomDateObj[]>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    useEffect(() => {
        if (!isCustomModalOpen) return;
        setDate([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            },
        ]);
    }, [isCustomModalOpen]);

    const handleCloseCustomDatePicker = useCallback(() => {
        toggle();
    }, []);

    const handleDateRange = useCallback((_data: { begin: Date | null | string; end: Date | null | string }) => {
        filterFunc({
            name: DateTypes.CUSTOM,
            date: { startDate: _data.begin, endDate: _data.end },
        });
    }, []);

    return (
        <>
            <Modal size={width < 476 ? "fill" : "lg"} active={isCustomModalOpen} toggler={handleCloseCustomDatePicker} dataType="custom-date">
                <ModalHeader dataType="custom-date" onClose={handleCloseCustomDatePicker}>
                    Choose a Date Range
                </ModalHeader>
                <ModalBody dataType="custom-date">
                    <div className="relative flex w-full items-center justify-center" onClick={(e) => e.stopPropagation()} data-type="custom-date">
                        <DateRange
                            editableDateInputs={true}
                            onChange={(rangesByKey: RangeKeyDict) => {
                                setDate([
                                    (
                                        rangesByKey as unknown as {
                                            selection: {
                                                startDate: Date;
                                                endDate: Date;
                                                key: string;
                                            };
                                        }
                                    ).selection,
                                ]);
                            }}
                            // showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={width > 768 ? 2 : undefined}
                            maxDate={new Date()}
                            ranges={date}
                            direction="horizontal"
                        />
                    </div>
                </ModalBody>

                <ModalFooter dataType="custom-date">
                    <div className="flex w-full flex-col space-y-4 2xs:w-max 2xs:flex-row 2xs:space-x-4 2xs:space-y-0" data-type="custom-date">
                        <div className="w-full 2xs:w-max" data-type="custom-date">
                            <ButtonComp
                                type="button"
                                buttonType="secondary"
                                color="grey"
                                ripple="light"
                                fullWidth
                                func={handleCloseCustomDatePicker}
                                dataType="custom-date"
                            >
                                <span data-type="custom-date">Cancel</span>
                            </ButtonComp>
                        </div>
                        <div className="w-full 2xs:w-max" data-type="custom-date">
                            <ButtonComp
                                type="button"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                fullWidth
                                dataType="custom-date"
                                func={() => {
                                    const { startDate, endDate } = date[0];
                                    handleDateRange({
                                        begin: moment(startDate).format().slice(0, 10),
                                        end: moment(endDate).format().slice(0, 10),
                                    });
                                    handleCloseCustomDatePicker();
                                }}
                            >
                                <span data-type="custom-date">Select Date</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CustomDatePickerModal;
