import {
    ZambiaCollectionFeeBearer,
    ZambiaCollectionPaymentChannel,
    ZambiaCollectionSource,
    ZambiaSettlementStatus,
} from "./zambiaCollectionRequest.constant";

import Currency from "../currency";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import ZambiaLencoFeeData from "./zambiaLencoFeeData";

export default class ZambiaSettlementCollectionRequest {
    constructor(
        public id: string,
        public createdAt: Date | null,
        public amount: string,
        public currency: Currency,
        public channels: ZambiaCollectionPaymentChannel[],
        public status: ZambiaSettlementStatus | null,
        public paymentReference: string,
        public source: ZambiaCollectionSource | null,
        public completedAt: Date | null,
        public failedReason: string | null,
        public lencoFee: string | null,
        public lencoFeeData: ZambiaLencoFeeData | null,
        public settlementAmount: string | null,
        public feeBearer: ZambiaCollectionFeeBearer | null
    ) {}

    static create(obj: GenericObject): ZambiaSettlementCollectionRequest {
        return new ZambiaSettlementCollectionRequest(
            Parsers.string(obj.id),
            Parsers.date(obj.createdAt),
            Parsers.string(obj.amount),
            Parsers.classObjectNonNullable(obj.currency, Currency),
            Parsers.nullableEnumArray(obj.channels, ZambiaCollectionPaymentChannel),
            Parsers.nullableEnum(obj.status, ZambiaSettlementStatus),
            Parsers.string(obj.paymentReference),
            Parsers.nullableEnum(obj.source, ZambiaCollectionSource),
            Parsers.date(obj.completedAt),
            Parsers.nullableString(obj.failedReason),
            Parsers.nullableString(obj.lencoFee),
            Parsers.classObject(obj.lencoFeeData, ZambiaLencoFeeData),
            Parsers.nullableString(obj.settlementAmount),
            Parsers.nullableEnum(obj.feeBearer, ZambiaCollectionFeeBearer)
        );
    }
}
