import UserGroup from "../../../models/userGroup";

export enum PeopleStageType {
    INITIAL = 0,
    CATEGORY_LIST = 1,
    CREATE_CATEGORY = 2,
    DELETE_CATEGORY = 3,
    EDIT_CATEGORY = 4,
}

export interface PeopleState {
    peopleStage: PeopleStageType;
    userGroupSelected: UserGroup | null;
    customerAccountList: string[] | null;
    userGroups: UserGroup[] | null;
}
