import {
    resetAllCableTVPurchaseData,
    setCableTVPurchaseAccountId,
    setCableTVVerifiedAccountName,
} from "../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";
import { resetBillPaymentData, setBillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch, useSelector } from "react-redux";

import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import BillPurchase from "../../../../../../models/billPurchase";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import CableTVPurchaseHeaders from "./CableTVPurchaseHeaders";
import ErrorToast from "../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import ProductDropdown from "../../DropDowns/ProductDropdown";
import RecentBillPaymentSkeleton from "../../Skeletons/RecentBillPaymentSkeleton";
import RecentContactsElement from "../../Elements/RecentContactsElement";
import VendorDropdown from "../../DropDowns/VendorDropdown";
import VerifiedAccountCard from "../../Cards/verified-account-card";
import { abortBillPayment } from "../../../services/billPayment/billPaymentApi";
import useCableTVPurchaseAmount from "../../../hooks/state/BillPayment/CableTVPurchase/useCableTVPurchaseAmount";
import useCableTVPurchasePayFrom from "../../../hooks/state/BillPayment/CableTVPurchase/useCableTVPurchasePayFrom";
import useCableTVPurchasePayTo from "../../../hooks/state/BillPayment/CableTVPurchase/useCableTVPurchasePayTo";
import useCableTVPurchaseProduct from "../../../hooks/state/BillPayment/CableTVPurchase/useCableTVPurchaseProduct";
import useCableTVPurchaseVendor from "../../../hooks/state/BillPayment/CableTVPurchase/useCableTVPurchaseVendor";
import { useCallback } from "react";

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();

    const selectedUserAccount = useSelector((state: IRootState) => state.cableTVPurchase.selectedUserAccount);
    const selectedPayToAccount = useSelector((state: IRootState) => state.cableTVPurchase.cableTVPurchaseDetails.accountId);
    const selectedProductName = useSelector((state: IRootState) => state.cableTVPurchase.selectedProductName);
    const selectedVendorName = useSelector((state: IRootState) => state.cableTVPurchase.selectedVendorName);
    const selectedProductFixedAmount = useSelector((state: IRootState) => state.cableTVPurchase.selectedProductFixedAmount);
    const vendorsList = useSelector((state: IRootState) => state.billPayment.vendorsList);
    const recentBillPurchases = useSelector((state: IRootState) => state.billPayment.recentBillPurchases);
    const isRecentBillPurchasesLoading = useSelector((state: IRootState) => state.billPayment.isRecentBillPurchasesLoading);
    const cableTVVerifiedAccountName = useSelector((state: IRootState) => state.cableTVPurchase.cableTVVerifiedAccountName);

    const { handleAmountChange } = useCableTVPurchaseAmount();
    const { selectedAccountId, handleSelectAccount, accounts } = useCableTVPurchasePayFrom();
    const { handleOnChange, cableTVPurchaseVerifyAccountError, isCableTVPurchaseVerifyAccountLoading, smartCardNumber } = useCableTVPurchasePayTo();
    const { handleSelectVendor, handleSelectVendorInstant } = useCableTVPurchaseVendor();
    const { handleSelectProduct, listOfProducts } = useCableTVPurchaseProduct();

    const handleSelectRecentPurchase = useCallback((_data: BillPurchase) => {
        handleAmountChange(String(_data.amount));
        handleOnChange(_data.extraData.account.accountId);
        handleSelectProduct(_data.product);
        handleSelectVendorInstant(_data.vendor);
        dispatch(setCableTVVerifiedAccountName(_data.extraData.account.customerName));
        dispatch(setCableTVPurchaseAccountId(_data.extraData.account.accountId));
    }, []);

    const handleBack = useCallback(() => {
        dispatch(resetBillPaymentData());
        dispatch(resetAllCableTVPurchaseData());
        abortBillPayment();
    }, []);

    const handleNextPage = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.REVIEW_PAYMENT));
    }, []);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <CableTVPurchaseHeaders />

                {!isRecentBillPurchasesLoading && recentBillPurchases && recentBillPurchases.length > 0 && (
                    <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Recent Purchase</p>
                        <div className="flex items-center justify-start space-x-7 overflow-auto">
                            {recentBillPurchases.slice(-3).map((_purchase, index) => (
                                <RecentContactsElement
                                    key={index}
                                    data={_purchase}
                                    onClick={handleSelectRecentPurchase}
                                    selectedAccountId={selectedAccountId}
                                    handleSelectAccount={handleSelectAccount}
                                    isPhoneNumber={false}
                                    productName
                                />
                            ))}
                        </div>
                    </div>
                )}
                {isRecentBillPurchasesLoading && <RecentBillPaymentSkeleton />}
                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Pay from</p>
                        <NewUserAccountDropdown
                            value={selectedAccountId || ""}
                            options={accounts || null}
                            onClick={handleSelectAccount}
                            isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                            dropdownSize="xl"
                            showBalance
                            initiatorCanSelect
                        />
                    </div>

                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                        <VendorDropdown label="Service Provider" value={selectedVendorName} options={vendorsList} onSelect={handleSelectVendor} />
                        <ProductDropdown label="Data Plan" value={selectedProductName} options={listOfProducts} onSelect={handleSelectProduct} />
                        {selectedProductFixedAmount && <VerifiedAccountCard label="Amount" value={selectedProductFixedAmount} isMoney />}
                        <Input label="Smart Card Number" value={smartCardNumber} inputSize="xl" onChange={handleOnChange} />
                    </div>
                    {cableTVVerifiedAccountName && cableTVPurchaseVerifyAccountError.length < 1 && (
                        <VerifiedAccountCard label="Customer Name" value={cableTVVerifiedAccountName} />
                    )}
                    {cableTVPurchaseVerifyAccountError.length > 0 && <ErrorToast error={cableTVPurchaseVerifyAccountError} fullWidth />}
                    {selectedUserAccount && selectedProductFixedAmount && (selectedUserAccount.balance as number) < selectedProductFixedAmount && (
                        <MessageToast
                            message={
                                <p>
                                    You do not have enough funds. Your account balance is <Money amount={selectedUserAccount.balance || 0} />
                                </p>
                            }
                            type="error"
                            fullWidth
                        />
                    )}
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="xl" func={handleBack}>
                        Back
                    </ButtonComp>

                    <ButtonComp
                        size="xl"
                        color="black"
                        buttonType="primary"
                        disable={
                            !!(
                                !selectedUserAccount ||
                                !selectedVendorName ||
                                !selectedProductName ||
                                !selectedProductFixedAmount ||
                                !cableTVVerifiedAccountName ||
                                selectedPayToAccount.length < 5 ||
                                cableTVPurchaseVerifyAccountError.length > 0 ||
                                (selectedUserAccount?.balance as number) < selectedProductFixedAmount
                            )
                        }
                        isLoading={isCableTVPurchaseVerifyAccountLoading}
                        func={handleNextPage}
                    >
                        Review Payment
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default PaymentDetails;
