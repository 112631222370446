import { GenericObject, PaymentRecipient } from "../helpers/types";

import BankAccount from "./bankAccount";
import Parsers from "../utils/parsers";
import UserGroup from "./userGroup";
import getBankName from "../helpers/getBankName";
import { immerable } from "immer";
import { setCustomerAccount } from "../redux/customer-account/customerAccountSlice";
import store from "../redux/store";

export default class CustomerAccount {
    [immerable] = true;

    private constructor(
        public id: string,
        public bankAccount: BankAccount | null,
        public alias: string | null,
        public userGroupId: string | null,
        public userGroup: UserGroup | null
    ) {}

    get accountName(): string {
        return this.bankAccount ? this.bankAccount.accountName : "";
    }

    get aliasAndName(): string {
        let res = "";
        if (this.alias) {
            res += this.alias;
            if (this.accountName) {
                res += ` (${this.accountName})`;
            }
        } else if (this.accountName) {
            res += this.accountName;
        }
        return res;
    }

    get singleName(): string {
        return this.alias || this.accountName;
    }

    get bankCode(): string {
        return this.bankAccount ? this.bankAccount.bankCode : "";
    }

    get bankName(): string {
        return getBankName(this.bankAccount ? this.bankAccount.bankCode : "");
    }

    get accountNumber(): string {
        return this.bankAccount ? this.bankAccount.accountNumber : "";
    }

    static create(obj: GenericObject): CustomerAccount {
        // there should be only 1 instance of CustomerAccount for each id
        const customerAccount = new CustomerAccount(
            Parsers.string(obj.id),
            Parsers.classObject(obj.bankAccount, BankAccount),
            Parsers.nullableString(obj.alias),
            Parsers.nullableString(obj.userGroupId),
            Parsers.classObject(obj.userGroup, UserGroup)
        );
        store.dispatch(setCustomerAccount(customerAccount));
        return store.getState().customerAccount.customerAccounts.get(customerAccount.id) || customerAccount;
    }

    update(customerAccount: CustomerAccount): void {
        this.bankAccount = customerAccount.bankAccount;
        this.alias = customerAccount.alias;
        this.userGroupId = customerAccount.userGroupId;
        this.userGroup = customerAccount.userGroup;
    }

    toPaymentRecipient(): PaymentRecipient {
        return {
            customerAccountId: this.id,
            bankAccountId: this.bankAccount ? this.bankAccount.id : "",
            bankCode: this.bankCode,
            accountNumber: this.accountNumber,
        };
    }

    get hasUserGroup(): boolean {
        return !!this.userGroup || !!this.userGroupId;
    }

    get hasAlias(): boolean {
        return !!this.alias;
    }
}
