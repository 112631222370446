import {
    resetTerminalTransactionsDateFilterState,
    resetTerminalTransactionsFilterState,
    setIsTerminalTransactionsPaginationLoading,
    setSelectedTerminalTransactionFilterState,
    setTerminalTransactionsFilterState,
    setTerminalTransactionsList,
    setTerminalTransactionsListGroupSize,
    setTerminalTransactionsListTotal,
    setTerminalTransactionsPaginationOffset,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import { PosTransactionsListRequest } from "../../Services/terminalsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { TerminalTransactionFilterTypes } from "../../../../../redux/terminals/slice/terminalsSlice.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { produce } from "immer";
import { terminalTransactionsList } from "../../Services/terminalsApi";
import { useAppSelector } from "../../../../../redux/hooks";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FilterItemSelectType, Item, TableFilterProps } from "../../../../../components/Table/components/TableFilter";
import { BasicFinalStatus, TransactionType } from "../../../../../models/posTransaction.constants";
import { getTerminalTransactionStatusName, getTerminalTransactionTypeName } from "../../Services/terminals.constants";
import { DateObj } from "../../Types";

function useSpecificTerminalTransactions() {
    const dispatch = useDispatch();

    const specificallySelectedTerminal = useAppSelector((state) => state.terminals.specificallySelectedTerminal);
    const selectedTransactionFilterState = useAppSelector((state) => state.terminals.selectedTransactionFilterState);
    const [filter, setFilter] = useState<TerminalTransactionFilterTypes>({ types: [], statuses: [], terminalId: specificallySelectedTerminal?.id });

    const handleFilteredTerminalTransactions = useCallback(
        <T>(data: T) => {
            const updatedList = produce(selectedTransactionFilterState, (draft) => {
                return (draft = { ...draft, ...data, terminalId: specificallySelectedTerminal?.id || "" });
            });
            dispatch(setSelectedTerminalTransactionFilterState());
            dispatch(setTerminalTransactionsFilterState(updatedList as TerminalTransactionFilterTypes));
            dispatch(setIsTerminalTransactionsPaginationLoading(true));
            void handleGetTerminalTransactions(updatedList as TerminalTransactionFilterTypes);
        },
        [selectedTransactionFilterState, specificallySelectedTerminal]
    );

    const handleGetTerminalTransactions = useCallback(
        async (_data: TerminalTransactionFilterTypes) => {
            try {
                dispatch(setIsTerminalTransactionsPaginationLoading(true));
                const res = await terminalTransactionsList(_data as PosTransactionsListRequest);
                dispatch(setTerminalTransactionsList(res.transactions));
                dispatch(setTerminalTransactionsListTotal(res.total));
                dispatch(setTerminalTransactionsListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsTerminalTransactionsPaginationLoading(false));
            }
        },
        [dispatch]
    );

    const handleClearFilter = useCallback(() => {
        setFilter({
            types: [],
            statuses: [],
            terminalId: specificallySelectedTerminal?.id,
        });
        handleGetTerminalTransactions({
            types: [],
            statuses: [],
            terminalId: specificallySelectedTerminal?.id,
        });
    }, [filter]);

    const filterOption: TableFilterProps = useMemo(
        () =>
            ({
                items: [
                    {
                        text: "Date",
                        selectType: FilterItemSelectType.DATE_OPTION,
                        onClick: (data) => {
                            setFilter((prev) => ({ ...prev, startDate: (data as DateObj)?.begin, endDate: (data as DateObj)?.end }));
                        },
                    },
                    {
                        text: "Type",
                        selectType: FilterItemSelectType.CHECKBOX_OPTION,
                        subList: [
                            { text: getTerminalTransactionTypeName(TransactionType.CARD_PAYMENT), value: TransactionType.CARD_PAYMENT },
                            { text: getTerminalTransactionTypeName(TransactionType.TRANSFER_IN), value: TransactionType.TRANSFER_IN },
                            { text: getTerminalTransactionTypeName(TransactionType.TRANSFER_OUT), value: TransactionType.TRANSFER_OUT },
                        ],
                        onClick: (data: { value: TransactionType }) => {
                            if (filter?.types?.includes(data?.value)) {
                                filter.types = filter.types?.filter((_el) => _el != data?.value);
                            } else {
                                filter.types?.push(data?.value);
                            }
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Status",
                        selectType: FilterItemSelectType.CHECKBOX_OPTION,
                        subList: [
                            { text: getTerminalTransactionStatusName(BasicFinalStatus.SUCCESSFUL), value: BasicFinalStatus.SUCCESSFUL },
                            { text: getTerminalTransactionStatusName(BasicFinalStatus.FAILED), value: BasicFinalStatus.FAILED },
                        ],
                        onClick: (data: { value: BasicFinalStatus }) => {
                            if (filter?.statuses?.includes(data?.value)) {
                                filter.statuses = filter.statuses?.filter((_el) => _el != data?.value);
                            } else {
                                filter.statuses?.push(data?.value);
                            }
                            setFilter({ ...filter });
                        },
                    },
                ] as Item[],
                handleApplyFilter: () => {
                    handleGetTerminalTransactions(filter);
                },
            }) as TableFilterProps,
        [filter]
    );

    const handlePaginationRequest = useCallback((offset: number) => {
        dispatch(setTerminalTransactionsPaginationOffset(offset));
        handleFilteredTerminalTransactions({ offset });
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetTerminalTransactionsFilterState());
        dispatch(resetTerminalTransactionsDateFilterState());
        void handleGetTerminalTransactions({
            query: "",
            offset: 0,
            types: [],
            statuses: [],
            terminalId: specificallySelectedTerminal?.id || "",
            startDate: "",
            endDate: "",
        });
    }, [specificallySelectedTerminal]);

    return {
        handleClearAll,
        handlePaginationRequest,
        handleFilteredTerminalTransactions,
        handleClearFilter,
        filter,
        filterOption,
    };
}

export default useSpecificTerminalTransactions;
