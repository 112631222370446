import { setDataPurchaseAccountId } from "../../../../../../../redux/payments/dataPurchase/slice/dataPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseDataPurchasePayToInterface {
    handleOnChange: (_vendor: string) => void;
    handleSelectPayToAccount: (_accountId: string) => void;
}

function useDataPurchasePayTo(): UseDataPurchasePayToInterface {
    const dispatch = useDispatch();

    const handleSelectPayToAccount = useCallback((_accountId: string) => {
        dispatch(setDataPurchaseAccountId(_accountId));
    }, []);

    const handleOnChange = useCallback((_vendorId: string) => {
        dispatch(setDataPurchaseAccountId(_vendorId));
    }, []);

    return {
        handleOnChange,
        handleSelectPayToAccount,
    };
}

export default useDataPurchasePayTo;
