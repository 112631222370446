import { ExternalDirectorSubPageType, PersonalDetailsPageType } from "../../service/director.constants";

import DirectorPersonalDetails from "./director-personal-details";

interface Props {
    stage: ExternalDirectorSubPageType;
}

function PersonalDetails(props: Props): JSX.Element {
    return (
        <>
            <div className="flex h-full w-full flex-row items-start justify-start">
                {props.stage.trim() === (PersonalDetailsPageType.PERSONAL_DETAILS as string) && <DirectorPersonalDetails />}
            </div>
        </>
    );
}

export default PersonalDetails;
