import { IRootState } from "../../../../../redux/rootReducer";
import React from "react";
import { useSelector } from "react-redux";
import SkeletonElement from "../../../../../components/skeleton/SkeletonElement";

export default function ShowCardDetailSkeleton(): JSX.Element {
    const showCVV = useSelector((state: IRootState) => state.cards.showCVV);
    return (
        <div className="w-full space-y-1">
            <div className="relative h-6 text-sm tracking-widest">
                <div className={`flex h-full w-full flex-row space-x-1 ${"opacity-100"}`}>
                    <div className="flex flex-row items-center justify-center space-x-1">
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center space-x-1">
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center space-x-1">
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center space-x-1">
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                        <div className="flex h-2.5 w-2.5 rounded-full">
                            <SkeletonElement box />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex w-full flex-row items-center justify-start space-x-4">
                {showCVV && (
                    <div className="flex min-w-5 flex-row items-center justify-start space-x-1">
                        <span className="text-sm">CVV</span>

                        <div className="flex flex-row items-center justify-center space-x-1">
                            <div className="flex h-2.5 w-2.5 rounded-full">
                                <SkeletonElement box />
                            </div>
                            <div className="flex h-2.5 w-2.5 rounded-full">
                                <SkeletonElement box />
                            </div>
                            <div className="flex h-2.5 w-2.5 rounded-full">
                                <SkeletonElement box />
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex flex-row items-center justify-start space-x-1">
                    <span className="text-sm font-normal uppercase text-black-quat">Exp</span>
                    <div className="flex flex-row items-center justify-center">
                        <div className="flex flex-row items-center justify-center space-x-1">
                            <div className="flex h-2.5 w-2.5 rounded-full">
                                <SkeletonElement box />
                            </div>
                            <div className="flex h-2.5 w-2.5 rounded-full">
                                <SkeletonElement box />
                            </div>
                        </div>
                        <span>{" / "}</span>

                        <div className="flex flex-row items-center justify-center space-x-1">
                            <div className="flex h-2.5 w-2.5 rounded-full">
                                <SkeletonElement box />
                            </div>
                            <div className="flex h-2.5 w-2.5 rounded-full">
                                <SkeletonElement box />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
