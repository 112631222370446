import React, { ReactNode } from "react";
import Modal from "../../../../../components/modal/Modal";
import ModalHeader from "../../../../../components/modal/modal-header";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ButtonComp from "../../../../../components/button/ButtonComp";

interface Props {
    isActive: boolean;
    toggler: () => void;
    isLoading?: boolean;
    onDelete?: () => Promise<void>;
    title: string | ReactNode;
    subText: string | ReactNode;
}

export default function ConfirmDeleteModal({ isActive, isLoading, title, subText, onDelete, toggler }: Props) {
    return (
        <Modal size="md" active={isActive} toggler={toggler} isChildFixed>
            <ModalHeader onClose={toggler}>{title}</ModalHeader>
            <ModalBody>
                <div className="-mt-2 flex w-full flex-col space-y-4">
                    <div className="flex w-full flex-col items-start justify-start text-sm font-normal">
                        <div className="flex w-full flex-col items-start text-start">
                            <span className="text-left text-base font-normal text-black-secondary">{subText}</span>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                    <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                        <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="md" fullWidth func={toggler}>
                            <span>Back</span>
                        </ButtonComp>
                    </div>
                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                        <ButtonComp
                            type="button"
                            onClick={onDelete}
                            color="red"
                            size="md"
                            ripple="light"
                            buttonType="primary"
                            fullWidth
                            isLoading={isLoading}
                        >
                            <span>Remove</span>
                        </ButtonComp>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}
