import { RoleOptions, UserRole, UserRoleText, UserRolesForSignatories } from "../../../../../../../../../../../models/userAccount.constants";

import { AddNewSignatoryItem } from "../../../../../../../../services/zambia-application/registered/new/accountSignatories.types";
import LencoInput from "../../../../../../../../../../../components/inputs/Input";
import SingleSelectDropdown from "../../../../../../../../../../../components/dropdown/single-select/single-select";
import useDimension from "../../../../../../../../../../../hooks/useDimension";

interface Props {
    data: AddNewSignatoryItem;
    index: number;
    handleDeleteNewSignatories: (_id: string) => void;
    handleChangeNewSignatoryValue: (_userId: string, _key: string, _value: string | UserRole | boolean | null | 0) => void;
}

function NewSignatory(props: Props): JSX.Element {
    const { width } = useDimension();

    return (
        <>
            <div className="flex w-full flex-col items-start justify-start space-y-6">
                <div className="flex w-full flex-row items-center justify-between text-black-secondary">
                    <h3 className="font-medium text-black-secondary">Director {props.index} </h3>
                    {props.data.canDelete && (
                        <div
                            className="max-w-4 cursor-pointer text-black-secondary hover:text-error"
                            onClick={() => props.handleDeleteNewSignatories(props.data.index as string)}
                        >
                            <span className="material-symbols-outlined text-xl">delete</span>
                        </div>
                    )}
                </div>

                <div className="flex w-full flex-col items-start space-y-4 text-base font-normal lg:items-center lg:px-0">
                    <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                        <div className="w-full lg:w-50%">
                            <LencoInput
                                type="text"
                                placeholder="First Name"
                                name="firstName"
                                value={props.data.firstName}
                                isDisabled={props.data.isDisabled}
                                onChange={(_value) => props.handleChangeNewSignatoryValue(props.data.index as string, "firstName", _value)}
                                autoFocus={width > 475}
                            />
                        </div>
                        <div className="w-full lg:w-50%">
                            <LencoInput
                                type="text"
                                placeholder="Last Name"
                                name="surname"
                                value={props.data.surname}
                                isDisabled={props.data.isDisabled}
                                onChange={(_value) => props.handleChangeNewSignatoryValue(props.data.index as string, "surname", _value)}
                            />
                        </div>
                    </div>
                    <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                        <div className={"w-full " + `${props.data.isSignatory ? "lg:w-50%" : ""}`}>
                            <LencoInput
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={props.data.email}
                                isDisabled={props.data.isDisabled}
                                onChange={(_value) => props.handleChangeNewSignatoryValue(props.data.index as string, "email", _value)}
                                autoFocus={width < 476}
                            />
                        </div>
                        {props.data.isSignatory && (
                            <div className="w-full lg:w-50%">
                                <SingleSelectDropdown
                                    placeholder="Role"
                                    options={UserRolesForSignatories.map((el) => {
                                        return {
                                            text: UserRoleText[el],
                                            value: el,
                                            subtext: RoleOptions[el].subtext,
                                        };
                                    })}
                                    value={props.data.userRole}
                                    onChange={(_value) => {
                                        if (_value) {
                                            props.handleChangeNewSignatoryValue(props.data.index as string, "userRole", _value);
                                        }
                                    }}
                                    fitHeight
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewSignatory;
