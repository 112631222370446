import { Recipient } from "../../../../modules/dashboard/payments-new/hooks/state/pending-approval/use-saved-recipients-state.types";

export interface RecipientsState {
    recipients: Map<string, Recipient>;
    pagination: { total: number; groupSize: number };
    action: {
        type: RecipientActionType | undefined;
        customerAccountId: string | undefined;
    };
}

export enum RecipientActionType {
    EDIT_RECIPIENT_GROUP = 1,
    REMOVE_FROM_GROUP = 2,
    EDIT_ALIAS = 3,
    DELETE = 4,
}
