import { setFundCardAmount } from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface useFundCardAmountInterface {
    handleAmountChange: (_amount: string) => void;
}

function useFundCardAmount(): useFundCardAmountInterface {
    const dispatch = useDispatch();

    const handleAmountChange = useCallback((_amount: string) => {
        dispatch(setFundCardAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useFundCardAmount;
