import { useCallback, useState } from "react";

import { RemoveZambiaApiAccessKeyWebhookUrlRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { removeZambiaApiAccessKeyWebhookUrl } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch } from "react-redux";

export interface UseRemoveZambiaApiAccessKeyWebhookUrlInterface {
    isRemoveZambiaApiAccessKeyWebhookUrlLoading: boolean;
    removeZambiaApiAccessKeyWebhookUrlErrorMessage: string;
    handleRemoveZambiaApiAccessKeyWebhookUrl: (_data: RemoveZambiaApiAccessKeyWebhookUrlRequest) => void;
}

function useRemoveZambiaApiAccessKeyWebhookUrl(): UseRemoveZambiaApiAccessKeyWebhookUrlInterface {
    const dispatch = useDispatch();
    const [isRemoveZambiaApiAccessKeyWebhookUrlLoading, setIsRemoveZambiaApiAccessKeyWebhookUrlLoading] = useState(false);
    const [removeZambiaApiAccessKeyWebhookUrlErrorMessage, setRemoveZambiaApiAccessKeyWebhookUrlErrorMessage] = useState<string>("");

    const handleRemoveZambiaApiAccessKeyWebhookUrl = useCallback(
        async (_data: RemoveZambiaApiAccessKeyWebhookUrlRequest) => {
            try {
                setIsRemoveZambiaApiAccessKeyWebhookUrlLoading(true);
                setRemoveZambiaApiAccessKeyWebhookUrlErrorMessage("");
                const res = await removeZambiaApiAccessKeyWebhookUrl(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key webhook removed successfully."));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRemoveZambiaApiAccessKeyWebhookUrlErrorMessage(errorMessage);
            } finally {
                setIsRemoveZambiaApiAccessKeyWebhookUrlLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isRemoveZambiaApiAccessKeyWebhookUrlLoading,
        removeZambiaApiAccessKeyWebhookUrlErrorMessage,
        handleRemoveZambiaApiAccessKeyWebhookUrl,
    };
}

export default useRemoveZambiaApiAccessKeyWebhookUrl;
