import { useCallback, useEffect } from "react";

import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import SignatureCard from "../../../../dashboard/applicationn/components/cards/signatureCard";
import useReferenceSignature from "../hookss/State/useReferenceSignature";

interface Props {
    a: string;
    b: string;
    handleRefereeDocumentSigned: () => void;
}

function ReferenceSignatureComponent(props: Props): JSX.Element {
    const { a, b } = props;

    const {
        uploadSignatureError,
        uploadSignatureLoading,
        uploadSignatureResponse,
        uploadRefereeDocumentError,
        uploadRefereeDocumentLoading,
        uploadRefereeDocumentResponse,
        // handlePreviewDocument,
        handleUploadSignature,
        handleUploadRefereeDocument,
        handleDownloadRefereeDocument,
    } = useReferenceSignature();

    useEffect(() => {
        if (uploadRefereeDocumentResponse || uploadSignatureResponse) {
            props.handleRefereeDocumentSigned();
        }
    }, [uploadSignatureResponse, uploadRefereeDocumentResponse]);

    const handleUploadSignatureFunc = useCallback((file: Blob) => {
        void handleUploadSignature({ a, b, file });
    }, []);
    const handleUploadRefereeDocumentFunc = useCallback((file: File) => {
        void handleUploadRefereeDocument({ a, b, file });
    }, []);

    const handleDownloadDocumentToSignFunc = useCallback(() => {
        void handleDownloadRefereeDocument({ a, b });
    }, []);

    return (
        <div className="w-full">
            {(uploadSignatureError || uploadRefereeDocumentError) && (
                <div className="my-4 flex w-full flex-col items-start justify-start text-center">
                    <ErrorToast error={uploadSignatureError || uploadRefereeDocumentError || ""} />
                </div>
            )}
            <div className="relative w-full">
                <SignatureCard
                    canAppendSignature={false}
                    signDocumentResponse={uploadSignatureResponse}
                    isSignDocumentLoading={uploadSignatureLoading}
                    isUploadSignedDocumentLoading={uploadRefereeDocumentLoading}
                    handleSignDocument={handleUploadSignatureFunc}
                    handleDownloadDocumentToSign={handleDownloadDocumentToSignFunc}
                    handleUploadSignedDocument={handleUploadRefereeDocumentFunc}
                    isCreator
                />
            </div>
        </div>
    );
}

export default ReferenceSignatureComponent;
