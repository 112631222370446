import {
    NigeriaRegisteredRoutes,
    NigeriaRegisteredSections,
    NigeriaUnregisteredRoutes,
    NigeriaUnregisteredSections,
} from "./nigeria-application.types";

import { ApplicationPage } from "../application.types";
import { GenericApplicationPageType } from "../application.constants";
import { Routes } from "../../../../../routes/routes.constants";

//Navigation
export const RegisteredNigeriaNavigation: {
    [type in NigeriaRegisteredRoutes]: string[];
} = {
    [NigeriaRegisteredRoutes.BUSINESS_DETAILS]: ["business details"],
    [NigeriaRegisteredRoutes.BUSINESS_CONTACT]: ["business details", "business contact details"],
    [NigeriaRegisteredRoutes.BUSINESS_INFORMATION]: ["business details", "business information"],

    [NigeriaRegisteredRoutes.SIGNATORY_DETAILS]: ["directors and signatories", " invite directors and signatories"],
    [NigeriaRegisteredRoutes.SIGNATORY_PERSONNEL]: ["directors and signatories", " ownership"],

    [NigeriaRegisteredRoutes.SIGNATORY_SIGNATURE]: ["personal details", " signatory details"],
    [NigeriaRegisteredRoutes.SIGNATORY_PHOTOGRAPH]: ["personal details", " photograph"],
    [NigeriaRegisteredRoutes.SIGNATORY_MEANS_OF_ID]: ["personal details", " means of ID"],
    [NigeriaRegisteredRoutes.SIGNATORY_NEXT_OF_KIN]: ["personal details", " next of kin"],
    [NigeriaRegisteredRoutes.SIGNATORY_PERSONAL_DETAILS]: ["personal details", " personal information"],

    [NigeriaRegisteredRoutes.DOCUMENT_UPLOAD]: ["business document", " upload company document"],
    [NigeriaRegisteredRoutes.DOCUMENT_AGREEMENT]: ["business agreements"],
    [NigeriaRegisteredRoutes.REFEREE_CHOICE]: ["business referees"],
    [NigeriaRegisteredRoutes.REFEREE_DETAILS]: ["business referees", " invite"],
    [NigeriaRegisteredRoutes.REVIEW]: ["review"],
};

export const InvitedRegisteredNigeriaNavigation: {
    [type in NigeriaRegisteredRoutes]: string[];
} = {
    [NigeriaRegisteredRoutes.BUSINESS_DETAILS]: ["business details"],
    [NigeriaRegisteredRoutes.BUSINESS_CONTACT]: ["business details", "business contact details"],
    [NigeriaRegisteredRoutes.BUSINESS_INFORMATION]: ["business details", "business information"],
    // [NigeriaRegisteredRoutes.SIGNATORY_BVN]: ["directors and signatories", "bvn"],
    [NigeriaRegisteredRoutes.SIGNATORY_DETAILS]: ["directors and signatories", " invite directors and signatories"],
    [NigeriaRegisteredRoutes.SIGNATORY_PERSONNEL]: ["directors and signatories", " ownership"],

    [NigeriaRegisteredRoutes.SIGNATORY_SIGNATURE]: ["personal details", " signatory details"],
    [NigeriaRegisteredRoutes.SIGNATORY_PHOTOGRAPH]: ["personal details", " photograph"],
    [NigeriaRegisteredRoutes.SIGNATORY_MEANS_OF_ID]: ["personal details", " means of ID"],
    [NigeriaRegisteredRoutes.SIGNATORY_NEXT_OF_KIN]: ["personal details", " next of kin"],
    // [NigeriaRegisteredRoutes.SIGNATORY_KEY_CONTACT]: ["personal details", " key contact"],
    [NigeriaRegisteredRoutes.SIGNATORY_PERSONAL_DETAILS]: ["personal details", " personal information"],
    [NigeriaRegisteredRoutes.DOCUMENT_UPLOAD]: ["business document", " upload company document"],
    [NigeriaRegisteredRoutes.DOCUMENT_AGREEMENT]: ["business agreements"],
    [NigeriaRegisteredRoutes.REFEREE_CHOICE]: ["business referees"],
    [NigeriaRegisteredRoutes.REFEREE_DETAILS]: ["business referees", " invite"],
    [NigeriaRegisteredRoutes.REVIEW]: ["review"],
};

export const UnregisteredNigeriaNavigation: {
    [type in NigeriaUnregisteredRoutes]: string[];
} = {
    //Unregistered
    // [NigeriaUnregisteredRoutes.BVN]: ["personal details", " bvn"],
    [NigeriaUnregisteredRoutes.PERSONAL_DETAILS]: ["personal details", " personal information"],
    [NigeriaUnregisteredRoutes.BUSINESS_DETAILS]: ["business details"],
    [NigeriaUnregisteredRoutes.ID]: ["personal details", " means of ID"],
    [NigeriaUnregisteredRoutes.PHOTOGRAPH]: ["personal details", " photograph"],
    [NigeriaUnregisteredRoutes.REVIEW]: ["review"],
};

export const getInvitedRegisteredNigeriaNavigationPath = (
    _section: NigeriaRegisteredSections | GenericApplicationPageType,
    _stage: NigeriaRegisteredRoutes
): string[] => {
    switch (_section) {
        case NigeriaRegisteredSections.COMPANY:
            if (!_stage) return [""];
            return InvitedRegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.SIGNATORY:
            if (!_stage) return InvitedRegisteredNigeriaNavigation[NigeriaRegisteredRoutes.SIGNATORY_DETAILS];
            return InvitedRegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.AGREEMENT:
            if (!_stage) return InvitedRegisteredNigeriaNavigation[NigeriaRegisteredRoutes.DOCUMENT_AGREEMENT];
            return InvitedRegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.DOCUMENT:
            if (!_stage) return InvitedRegisteredNigeriaNavigation[NigeriaRegisteredRoutes.DOCUMENT_UPLOAD];
            return InvitedRegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.REFEREES:
            if (!_stage) return [""];
            return InvitedRegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.REVIEW:
            if (!_stage) return InvitedRegisteredNigeriaNavigation[NigeriaRegisteredRoutes.REVIEW];
            return [""];
        default:
            return [""];
    }
};

export const getRegisteredNigeriaNavigationPath = (
    _section: NigeriaRegisteredSections | GenericApplicationPageType,
    _stage: NigeriaRegisteredRoutes
): string[] => {
    switch (_section) {
        case NigeriaRegisteredSections.COMPANY:
            if (!_stage) return [""];
            return RegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.SIGNATORY:
            if (!_stage) return RegisteredNigeriaNavigation[NigeriaRegisteredRoutes.SIGNATORY_DETAILS];
            return RegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.AGREEMENT:
            if (!_stage) return RegisteredNigeriaNavigation[NigeriaRegisteredRoutes.DOCUMENT_AGREEMENT];
            return RegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.DOCUMENT:
            if (!_stage) return RegisteredNigeriaNavigation[NigeriaRegisteredRoutes.DOCUMENT_UPLOAD];
            return RegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.REFEREES:
            if (!_stage) return [""];
            return RegisteredNigeriaNavigation[_stage];
        case NigeriaRegisteredSections.REVIEW:
            if (!_stage) return RegisteredNigeriaNavigation[NigeriaRegisteredRoutes.REVIEW];
            return [""];
        default:
            return [""];
    }
};

export const getUnregisteredNigeriaNavigationPath = (
    _section: NigeriaUnregisteredSections | GenericApplicationPageType,
    _stage: NigeriaUnregisteredRoutes
): string[] => {
    switch (_section) {
        case NigeriaUnregisteredSections.BUSINESS:
            return UnregisteredNigeriaNavigation[NigeriaUnregisteredRoutes.BUSINESS_DETAILS];
        case NigeriaUnregisteredSections.IDENTITY:
            return UnregisteredNigeriaNavigation[_stage];
        case NigeriaUnregisteredSections.PERSONAL:
            return UnregisteredNigeriaNavigation[NigeriaUnregisteredRoutes.PERSONAL_DETAILS];
        case NigeriaUnregisteredSections.REVIEW:
            if (!_stage) return UnregisteredNigeriaNavigation[NigeriaUnregisteredRoutes.REVIEW];
            return [""];
        default:
            return [""];
    }
};

//sections
export const NigeriaRegisteredPages: ApplicationPage[] = [
    {
        value: NigeriaRegisteredSections.COMPANY,
        title: "Business Details",
        description: "Tell us about your company",
        index: 1,
        route: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION,
        progress: 0,
    },
    {
        value: NigeriaRegisteredSections.SIGNATORY,
        title: "Signatories and Directors",
        description: "Tell us about your account signatories and directors",
        index: 2,
        route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL,
        progress: 0,
    },
    {
        value: NigeriaRegisteredSections.DOCUMENT,
        title: "Business Documents",
        description: "Upload documents to verify your company",
        index: 3,
        route: Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT,
        progress: 0,
    },
    {
        value: NigeriaRegisteredSections.AGREEMENT,
        title: "Agreements",
        description: "Review and sign our terms and conditions",
        index: 4,
        route: Routes.ACCOUNT_OPENING.REGISTERED.AGREEMENT,
        progress: 0,
    },
    {
        value: NigeriaRegisteredSections.REFEREES,
        title: "Invite Referees",
        description: "Invite two current account holders as your referees",
        index: 5,
        route: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS,
        progress: 0,
    },
    {
        value: NigeriaRegisteredSections.PERSONAL,
        title: "Personal Details",
        description: "Tell us about yourself",
        index: 6,
        route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
        progress: 0,
    },
];
export const NigeriaUnregisteredPages: ApplicationPage[] = [
    {
        value: NigeriaUnregisteredSections.BUSINESS,
        title: "Business Details",
        description: "Tell us about your business",
        index: 1,
        route: Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS,
        progress: 0,
    },
    {
        value: NigeriaUnregisteredSections.PERSONAL,
        title: "Personal Details",
        description: "Tell us about yourself",
        index: 2,
        route: Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS,
        progress: 0,
    },
    {
        value: NigeriaUnregisteredSections.IDENTITY,
        title: "Verify your Identity",
        description: "Take a photo and upload an identity card",
        index: 3,
        route: Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID,
        progress: 0,
    },
];
