import CardSpendLimitBar from "../components/CardSpendLimitBar";
import { CardSpendLimitType } from "../Type/CardSpendLimitType";
import { DataColumn } from "../Type/DataRow";
import { TextType } from "../Type/TextType";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function TransactionProgress({ dataColumn, dataType }: Props) {
    const { spendingLimit, text, textType } = dataColumn;
    const cartInterval =
        textType === TextType.SINGLE_PAYMENT_CARD_PROGRESS
            ? CardSpendLimitType.SINGLE_PAYMENT
            : textType === TextType.DAILY_ACCUMULATIVE_CARD_PROGRESS
              ? CardSpendLimitType.DAILY_ACCUMULATIVE
              : textType === TextType.WEEKLY_ACCUMULATIVE_CARD_PROGRESS
                ? CardSpendLimitType.WEEKLY_ACCUMULATIVE
                : textType === TextType.MONTHLY_ACCUMULATIVE_CARD_PROGRESS
                  ? CardSpendLimitType.MONTHLY_ACCUMULATIVE
                  : null;
    return (
        <div data-type={dataType}>
            {cartInterval && spendingLimit ? (
                <CardSpendLimitBar spendingLimit={spendingLimit} amountSpent={Number(text)} interval={cartInterval} />
            ) : (
                "-"
            )}
        </div>
    );
}
