import React from "react";
import SkeletonElement from "../../../../../components/skeleton";

function SavedRecipientSkeleton(): JSX.Element {
    return (
        <tr className="h-14">
            <td className="w-20% px-4">
                <SkeletonElement text />
            </td>
            <td className="w-20% px-4">
                <SkeletonElement text />
            </td>
            <td className="w-20% px-4">
                <SkeletonElement text />
            </td>
            <td className="w-15% px-4">
                <SkeletonElement text />
            </td>
            <td className="w-20% px-4">
                <SkeletonElement text />
            </td>
            <td className="w-5%">
                <SkeletonElement box />
            </td>
        </tr>
    );
}

export default SavedRecipientSkeleton;
