import { AgreementFieldTexts, AgreementType } from "../../../../services/nigeria-application/registered/new/agreement.types";
import {
    appendBusinessAgreementSignature,
    downloadBusinessAgreement,
    downloadBusinessAgreementBoardResolution,
    downloadBusinessAgreementToSign,
    signBusinessAgreement,
    uploadBusinessAgreement,
} from "../../../../services/nigeria-application/registered/new/agreement.api";
import { downloadBlob, openBlobInNewTab } from "../../../../../../../utils/blob";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setAgreement } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface useAgreementComponentInterface {
    isPreviewDownloading: boolean;
    isSignBusinessAgreementDone: boolean;
    isSignBusinessAgreementLoading: boolean;
    isDownloadAgreementToSignLoading: boolean;
    isUploadBusinessAgreementLoading: boolean;
    isAppendBusinessAgreementSignatureLoading: boolean;

    handlePreviewDocument: () => Promise<void>;
    handleSignBusinessAgreement: (data: Blob) => Promise<void>;
    handleDownloadAgreementToSign: () => Promise<void>;
    handleUploadSignedAgreementDocument: (data: Blob) => Promise<void>;
    handleAppendBusinessAgreementSignature: () => Promise<void>;
}
const useAgreement = (type: AgreementType, onComplete?: () => void): useAgreementComponentInterface => {
    const [isPreviewDownloading, setIsPreviewDownloading] = useState<boolean>(false);
    const [isSignBusinessAgreementDone, setIsSignBusinessAgreementDone] = useState<boolean>(false);
    const [isSignBusinessAgreementLoading, setIsSignBusinessAgreementLoading] = useState<boolean>(false);
    const [isUploadBusinessAgreementLoading, setIsUploadBusinessAgreementLoading] = useState<boolean>(false);
    const [isDownloadAgreementToSignLoading, setIsDownloadAgreementToSignLoading] = useState<boolean>(false);
    const [isAppendBusinessAgreementSignatureLoading, setIsAppendBusinessAgreementSignatureLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleDownloadAgreementToSign = useCallback(async (): Promise<void> => {
        try {
            setIsDownloadAgreementToSignLoading(true);
            if (type === AgreementType.BOARD_RESOLUTION) {
                const res = await downloadBusinessAgreementBoardResolution();
                downloadBlob(res, `${AgreementFieldTexts[type]} Document.pdf`);
            } else {
                const res = await downloadBusinessAgreementToSign({ type });
                downloadBlob(res, `${AgreementFieldTexts[type]} Document.pdf`);
            }
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue(errorMessage));
        } finally {
            setIsDownloadAgreementToSignLoading(false);
        }
    }, [type, dispatch]);

    const handleAppendBusinessAgreementSignature = useCallback(async (): Promise<void> => {
        try {
            setIsAppendBusinessAgreementSignatureLoading(true);
            const res = await appendBusinessAgreementSignature({ type });
            dispatch(setAgreement(res));
            onComplete && onComplete();
            dispatch(messageTrue({ message: "Document Signed Successfully" }));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue(errorMessage));
        } finally {
            setIsAppendBusinessAgreementSignatureLoading(false);
        }
    }, [type, dispatch]);

    const handleSignBusinessAgreement = useCallback(
        async (data: Blob): Promise<void> => {
            try {
                setIsSignBusinessAgreementDone(false);
                setIsSignBusinessAgreementLoading(true);
                const res = await signBusinessAgreement({ type, file: data as File });
                setIsSignBusinessAgreementDone(true);
                dispatch(setAgreement(res));
                onComplete && onComplete();
                dispatch(messageTrue({ message: "Document Signed Successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsSignBusinessAgreementLoading(false);
            }
        },
        [type, dispatch]
    );

    const handleUploadSignedAgreementDocument = useCallback(
        async (data: Blob): Promise<void> => {
            try {
                setIsUploadBusinessAgreementLoading(true);
                const res = await uploadBusinessAgreement({ type, file: data as File });
                dispatch(setAgreement(res));
                onComplete && onComplete();
                dispatch(messageTrue({ message: "Signature uploaded" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsUploadBusinessAgreementLoading(false);
            }
        },
        [type, dispatch]
    );

    const handlePreviewDocument = useCallback(async () => {
        try {
            setIsPreviewDownloading(true);
            dispatch(messageTrue({ message: "Generating preview" }));
            const blob = await downloadBusinessAgreement({ type });
            openBlobInNewTab(blob, `${AgreementFieldTexts[type]} Document.pdf`);
            dispatch(messageTrue({ message: "Preview generated" }));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsPreviewDownloading(false);
        }
    }, [type, dispatch]);

    return {
        isPreviewDownloading,
        isSignBusinessAgreementDone,
        isSignBusinessAgreementLoading,
        isDownloadAgreementToSignLoading,
        isUploadBusinessAgreementLoading,
        isAppendBusinessAgreementSignatureLoading,

        handlePreviewDocument,
        handleSignBusinessAgreement,
        handleDownloadAgreementToSign,
        handleUploadSignedAgreementDocument,
        handleAppendBusinessAgreementSignature,
    };
};
export default useAgreement;
