import Money from "../../../../../../components/money";

interface Props {
    label?: string;
    value?: string | number;
    isMoney?: boolean;
}

function VerifiedAccountCard(props: Props) {
    return (
        <>
            <div className="relative flex min-h-14 w-full rounded-lg bg-grey-tertiary px-4">
                <label htmlFor="Account Name" className="absolute left-4 top-0 text-2xs leading-8 text-black-tertiary">
                    {props.label || "Account Name"}
                </label>
                <p className="h-fit w-full self-end pb-1.5 text-base text-black-secondary">
                    {props.isMoney ? <Money amount={props.value || ""} /> : props.value}
                </p>
            </div>
        </>
    );
}

export default VerifiedAccountCard;
