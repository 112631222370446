import { BulkTransferHeaderReview, BulkTransferRowReview } from "../../../hooks/state/send-money/payments.constants";
import { useCallback, useEffect } from "react";

import AvatarDetails from "../../../../../../components/avatar-details";
import BulkPaymentHeaders from "./BulkPaymentHeaders";
import { BulkPaymentInitRequest } from "../../../services/send-money/bulkTransferApi.types";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import { PaymentDetail } from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import Table from "../../../../../../components/Table";
import getInitials from "../../../../../../helpers/get-initials";
import { setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { useAppSelector } from "../../../../../../redux/hooks";
import useBulkTransfer from "../../../hooks/state/send-money/BulkTransfer/useBulkTransfer";
// import BulkTransferDetailRow from "../../TableRows/BulkTransferDetailRow";
import { useDispatch } from "react-redux";

function ReviewPayment(): JSX.Element {
    const dispatch = useDispatch();
    const { bulkPaymentResponse, handleBulkTransfer, isBulkPaymentLoading } = useBulkTransfer();

    const companyId = useAppSelector((state) => state.init.selectedCompanyId);
    const companies = useAppSelector((state) => state.init.account?.companies);

    const transferDetailArray = useAppSelector((state) => state.bulkTransfer.transferDetailArray);
    const bulkTransfersAmountTotal = useAppSelector((state) => state.bulkTransfer.bulkTransfersAmountTotal);
    const currentCompany = companies?.find((_) => _.id === companyId);

    useEffect(() => {
        if (!bulkPaymentResponse) return;
        dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
    }, [bulkPaymentResponse]);

    const handleInitiatePayment = useCallback(() => {
        const paymentsArray: PaymentDetail[] = transferDetailArray.map((_el) => ({
            originatingAccountId: _el.originatingAccountId as string,
            recipient: _el.recipient,
            amount: _el.amount,
            purpose: _el.purpose,
            key: _el.key,
        }));
        const bulkPaymentDetails: BulkPaymentInitRequest = {
            payments: paymentsArray,
            otp: "",
        };
        void handleBulkTransfer(bulkPaymentDetails);
    }, []);

    return (
        <>
            <div className="flex h-full w-full flex-col">
                <div className="relative flex h-full w-full flex-col items-center justify-start">
                    <div className="relative flex h-full w-full flex-col">
                        <div className="left-0 top-0 flex h-full max-h-full w-full flex-col space-y-4">
                            <BulkPaymentHeaders />
                            <div className="flex flex-col items-center justify-center space-y-3 pb-6">
                                <AvatarDetails
                                    size="lg"
                                    initials={getInitials(currentCompany?.name || "")}
                                    icon="outflow"
                                    color="white"
                                    fullWidth={false}
                                />
                                <p className="text-sm font-normal !leading-[100%] text-black-tertiary">You&apos;re sending</p>
                                <p className="text-[32px] font-medium !leading-[100%] text-black">
                                    <Money amount={bulkTransfersAmountTotal} />
                                </p>
                            </div>
                            <div className="mx-auto w-full max-w-lg">
                                <MessageToast
                                    type="security"
                                    message="Ensure you verify that the recipient is genuine as payments can not be reversed after approval."
                                    fullWidth
                                />
                            </div>

                            <div className="mt-4 h-full w-full">
                                <Table
                                    heads={BulkTransferHeaderReview}
                                    dataType="bulk-transfer"
                                    isPaginateLoading={false}
                                    rows={transferDetailArray.map((_transfer) => ({
                                        record: BulkTransferRowReview(_transfer),
                                    }))}
                                    isMaxHeight
                                />
                            </div>
                            {/* <div className="relative flex h-full w-full flex-1 flex-shrink flex-grow">
                                <div className="relative mt-6 flex h-full w-full flex-1">
                                    <div className="flex h-fit w-full flex-1">
                                        <div className="left-0 top-0 h-full w-full">
                                            <table className="tableT w-full">
                                                <thead className="tableheader relative z-10 rounded-bl-2xl
                                                border-grey-backdrop text-left text-sm text-black">
                                                    <tr className="relative">
                                                        <th className="tableTh1 px-6 py-3 font-normal">FROM</th>
                                                        <th className="px-6 py-3 font-normal">TO</th>
                                                        <th className="px-6 py-3 font-normal">PURPOSE</th>
                                                        <th className="px-6 py-3 font-normal">AMOUNT</th>
                                                        <th className="tableTh2 px-6 py-3 font-normal"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transferDetailArray &&
                                                        transferDetailArray.map((el, index) => (
                                                            <BulkTransferDetailRow reviewMode={false} data={el} key={index} />
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex w-full flex-row items-center justify-center space-x-4 py-6">
                        <ButtonComp
                            color="grey"
                            buttonType="secondary"
                            func={() => dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS))}
                            disable={isBulkPaymentLoading}
                        >
                            Back
                        </ButtonComp>

                        <ButtonComp
                            color="black"
                            buttonType="primary"
                            func={() => {
                                if (transferDetailArray.some((el) => !el.canApprove)) {
                                    handleInitiatePayment();
                                } else {
                                    dispatch(setPaymentStage(PaymentStageType.MAKE_PAYMENT));
                                }
                            }}
                            isLoading={isBulkPaymentLoading}
                        >
                            {transferDetailArray.some((el) => !el.canApprove) ? <span>Initiate Payment</span> : <span>Make Payment</span>}
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReviewPayment;
