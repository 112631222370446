import { CardStatus, CardTransactionChannel, CardType, CardWalletType } from "../../../../models/card.constants";

import Card from "../../../../models/card";
import CardInformation from "../../../../models/cardInformation";
import CardMain from "../../../../models/cardMain";
import CardRequest from "../../../../models/cardRequest";
import User from "../../../../models/user";

export interface TeamMembersWithManageAccessResponse {
    success: boolean;
    message: string;
    teamMembersWithManageAccess: User[];
}

export type CardsListRequest = {
    query?: string;
    offset?: number | undefined;
    walletType?: CardWalletType;
    cardStatuses?: CardStatus[];
    assignedToIds?: string[];
};

export interface CardsListResponse {
    success: boolean;
    message: string;
    total: number;
    groupSize: number;
    cardsData: CardMain[];
}

export interface NGNPhysical {
    showCvv: boolean;
}

export interface CardShowCVVResponse {
    success: boolean;
    message: string;
    ngnPhysical: NGNPhysical;
}

export interface CardsGeneralSettignsResponse {
    success: boolean;
    message: string;
    ngnPhysical: NGNPhysical;
}

export type CardShowCVVRequest = {
    showCvv: boolean;
};

export type CardInitRequest = {
    offset: number | undefined;
};
export interface CardInitResponse {
    success: boolean;
    message: string;
    groupSize: number;
    total: number;
    cardsData: CardMain[];
    teamMembersWithManageAccess: User[];
    teamMembersWithViewAccess: User[];
    ngnPhysical: NGNPhysical;
}

export type RequestNewCardRequest = {
    purpose: string;
    linkedAccountId: string;
    debitAccountId: string;
    name: string;
    assignedToId: string;
    cardholderPhone: string;
    cardholderEmail: string;
    type: CardType;
    walletType: CardWalletType;
    deliveryPhone: string;
    deliveryAddress: string;
};

export interface RequestNewCardResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export type ListCreateCardRequestRequest = {
    offset: string;
};

export interface ListCreateCardRequestResponse {
    success: boolean;
    message: string;
    createCardRequests: Array<CardRequest>;
    groupSize: number;
    total: number;
}

export type CreateCardRequestDetailsRequest = {
    requestId: string;
};

export interface CreateCardRequestDetailsResponse {
    success: boolean;
    message: string;
    createCardRequest: CardRequest;
}

export interface FreezeCardResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export interface UnFreezeCardResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export interface UpdateCardPaymentChannelResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export interface ReassignCardRequestDetailsResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export type ListCardsRequest = {
    query: string;
    offset: number;
    statuses: string[];
    assignedToIds: string[];
};

export interface ListCardsResponse {
    success: boolean;
    message: string;
    cards: Array<Card>;
    groupSize: number;
    total: number;
}

export type CardDetailsRequest = {
    cardId: string;
};

export interface CardDetailsResponse {
    success: boolean;
    message: string;
    card: Card;
}

export type CardBalanceRequest = {
    createRequestId: string;
};

export interface CardBalanceResponse {
    success: boolean;
    message: string;
    balance: number | null;
}

export type CardInformationRequest = {
    createRequestId: string;
};

export interface CardInformationResponse {
    success: boolean;
    message: string;
    info: CardInformation;
}

export type ActivateCardRequest = {
    // cardId: string;
    // last6Digits: string;
    // cvv: string;
    createRequestId: string;
    last6Digits: string;
    expiryMonth: string;
    expiryYear: string;
};

export interface ActivateCardResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export type DeactivateCardRequest = {
    reason?: CancelCardOptionsEnum | string;
    otherReasonText?: string;
    createRequestId: string;
};

export interface DeactivateCardResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export type RemoveCardUserAccessDTO = {
    memberId: string;
};

export type AddManageCardAccessRequest = {
    memberIds: string[];
};

export type AddViewCardAccessRequest = {
    memberIds: string[];
};

export type AddRequestCard = {
    // memberIds: string[];
    purpose: string;
    linkedAccountId: string;
    assignedToId: string;
    debitAccountId: string;
    name: string;
    // cardHolderName: string;
    walletType: number;
    type: number;
    cardholderPhone: string;
    cardholderEmail: string;
    deliveryPhone: string;
    deliveryAddress: string;
};

export interface AddManageCardAccessResponse {
    success: boolean;
    message: string;
    teamMembersWithManageAccess: User[];
}

export interface AddViewCardAccessResponse {
    success: boolean;
    message: string;
    teamMembersWithViewAccess: User[];
}

export type RemoveManageCardAccessRequest = {
    memberId: string;
};

export interface RemoveManageCardAccessResponse {
    success: boolean;
    message: string;
    teamMembersWithManageAccess: User[];
}
export interface RemoveViewCardAccessResponse {
    success: boolean;
    message: string;
    teamMembersWithViewAccess: User[];
}

export type GetCardPinRequest = {
    createRequestId: string;
};

export interface GetCardPinResponse {
    success: boolean;
    message: string;
    defaultPin: string;
}

export type RemoveSpendingLimitRequest = {
    createRequestId: string;
};

export interface RemoveSpendingLimitResponse {
    success: boolean;
    message: string;
    cardData: CardMain;
}

export type freezeCardDTO = {
    createRequestId: string;
};

export type unFreezeCardDTO = {
    createRequestId: string;
};

export type ActivateCardDTO = {
    createRequestId: string;
    last6Digits: string;
    expiryMonth: string;
    expiryYear: string;
};

export type udpateCardSpendingLimitDTO = {
    createRequestId: string;
    type: number;
    limit: number;
};

export type ReassignCardDTO = {
    createRequestId: string;
    assignedToCorporateAccountMemberUid: string;
};

export type updateCardPaymentChannelDTO = {
    createRequestId: string;
    channels: CardTransactionChannel[];
};

export interface ActivateCardFormDTO {
    last6Digits: string;
    expiryMonth: string;
    expiryYear: string;
}

export interface ICardRequestStatusUpdate {
    data: CardMain | null;
    isActivateCardLoading: boolean;
    isSelectedCardDetailsOpen: boolean;
    onActivateCard: (input: ActivateCardDTO) => void;
}
export interface IActivateCard {
    isActivateCardLoading: boolean;
    onCancel: () => void;
    onCardActivation: (input: ActivateCardFormDTO) => void;
}

export enum CardSectionEnum {
    CREDIT_CARD_SECTION = "1",
    CARD_REQUEST_STATUS_UPDATE_SECTION = "2",
    ASSIGN_CARD_TO_SECTION = "3",
    UPDATE_CARD_SPENDING_LIMIT_SECTION = "4",
    REASSIGN_CARD_SECTION = "5",
    RESENT_TRANSACTION_SECTION = "6",
    CANCEL_CARD_SECTION = "7",
    SET_CARD_PAYMENT_CHANNEL_SECTION = "8",
    ACCOUNT_LINKED_TO_CARD = "9",
    ACTIVATE_CARD = "10",
    REQUEST_DEFAULT_PIN = "11",
    SECURITY_CVV = "12",
}

export enum CancelCardOptionsEnum {
    LOST_CARD = 1,
    STOLEN_CARD = 2,
    DAMAGED_CARD = 3,
    DID_NOT_RECEIVE = 4,
    OTHER = 5,
}
export enum DeactivateCardReason {
    LOST = 1,
    STOLEN = 2,
    DAMAGED = 3,
    NEVER_RECEIVED = 4,
}

export interface CancelCardRequest {
    reason?: CancelCardOptionsEnum;
    otherReasonText?: string;
}
