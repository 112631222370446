import { useCallback, useState } from "react";

import { AddZambiaApiAccessKeyAccountAccessRequest } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { addZambiaApiAccessKeyAccountAccessApi } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { setUpdateZambiaApiAccessKey } from "../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch } from "react-redux";

export interface UseAddZambiaApiAccessKeyAccountAccessInterface {
    isAddZambiaApiAccessKeyAccountAccessLoading: boolean;
    addZambiaApiAccessKeyAccountAccessErrorMessage: string;
    handleAddZambiaApiAccessKeyAccountAccess: (_data: AddZambiaApiAccessKeyAccountAccessRequest) => void;
}

interface Props {
    onComplete: () => void;
}

function useAddZambiaApiAccessKeyAccountAccess(props: Props): UseAddZambiaApiAccessKeyAccountAccessInterface {
    const dispatch = useDispatch();
    const [isAddZambiaApiAccessKeyAccountAccessLoading, setIsAddZambiaApiAccessKeyAccountAccessLoading] = useState(false);
    const [addZambiaApiAccessKeyAccountAccessErrorMessage, setAddZambiaApiAccessKeyAccountAccessErrorMessage] = useState<string>("");

    const handleAddZambiaApiAccessKeyAccountAccess = useCallback(
        async (_data: AddZambiaApiAccessKeyAccountAccessRequest) => {
            try {
                setIsAddZambiaApiAccessKeyAccountAccessLoading(true);
                setAddZambiaApiAccessKeyAccountAccessErrorMessage("");
                const res = await addZambiaApiAccessKeyAccountAccessApi(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key account added successfully."));
                props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setAddZambiaApiAccessKeyAccountAccessErrorMessage(errorMessage);
            } finally {
                setIsAddZambiaApiAccessKeyAccountAccessLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isAddZambiaApiAccessKeyAccountAccessLoading,
        addZambiaApiAccessKeyAccountAccessErrorMessage,
        handleAddZambiaApiAccessKeyAccountAccess,
    };
}

export default useAddZambiaApiAccessKeyAccountAccess;
