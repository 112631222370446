import React, { useCallback, useEffect, useState } from "react";
import NewDropdownItem from "../../../../new-dropdown-item";
import Checkbox from "../../../../checkbox";
import titleCase from "../../../../../hooks/titleCase";
import { FilterItemSelectType } from "..";
import { GenericObject } from "../../../../../helpers/types";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { setClearAllFilter } from "../../../../../redux/application/applicationSlice";

export type ItemSubList = {
    onClick?: (data: GenericObject) => void;
    text?: string;
    subText?: string;
    value?: string;
};

export default function CheckboxOption({ text, subText, value, onClick }: ItemSubList) {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState(false);
    const clearAllFilter = useAppSelector((state) => state.application.clearAllFilter);
    const handleOnClick = useCallback(() => {
        onClick?.({ value, type: FilterItemSelectType.CHECKBOX_OPTION, key: "checkbox", checked: selected });
        setSelected((prev) => !prev);
    }, []);

    useEffect(() => {
        if (clearAllFilter) {
            setSelected(false);
            dispatch(setClearAllFilter(false));
        }
        return () => {};
    }, [clearAllFilter]);

    return (
        <NewDropdownItem size="lg">
            <div className="flex h-full w-full flex-row items-center justify-start pr-4 text-sm">
                <div className="max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap" onClick={handleOnClick}>
                    <Checkbox
                        text={
                            <div className="ml-1">
                                <p className="text-sm font-medium text-black-tertiary">{titleCase(text || "")}</p>
                                {subText && <p className="text-xs text-black-tertiary">{subText}</p>}
                            </div>
                        }
                        id={value || ""}
                        checked={selected}
                        size="sm"
                        data-type="transaction"
                    />
                </div>
            </div>
        </NewDropdownItem>
    );
}
