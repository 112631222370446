import { NigeriaRegisteredPages, NigeriaUnregisteredPages } from "../services/nigeria-application/nigeria-application.constants";
import React, { useEffect } from "react";
import { ZambiaRegisteredPages, ZambiaUnregisteredPages } from "../services/zambia-application/zambia-application.constants";
import { useDispatch, useSelector } from "react-redux";

import { ApplicationBusinessType } from "../services/application.constants";
import ApplicationLayout from "../components/layout";
import { IRootState } from "../../../../redux/rootReducer";
import NigeriaApplicationStatus from "../components/nigeria-application/components/application-status";
import { PageTitle } from "../../../../helpers/app-constants";
import { Routes } from "../../../../routes/routes.constants";
import ZambiaApplicationStatus from "../components/zambia-application/components/application-status";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import { setApplicationBusinessType } from "../../../../redux/application/applicationSlice";
import { setZambiaApplicationBusinessType } from "../../../../redux/application/zambiaApplicationSlice";
import { useNavigate } from "react-router-dom";

function AccountOpening(): JSX.Element {
    document.title = PageTitle.APPLICATION_PAGE;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const accountInitLoading = useSelector((state: IRootState) => state.init.isAccountOpeningLoading);
    const zambiaAccountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    const isRegistered = accountOpening?.applicationMin.isRegistered;
    const isZambiaRegistered = zambiaAccountOpening?.application.isRegistered;

    useEffect(() => {
        if (!accountInitLoading && !accountOpening && !zambiaAccountOpening) {
            // console.log("no account opening");
            // setTimeout(() => {
            //     // console.log(accountInitLoading, "acc opening");
            //     if (!accountInitLoading && !accountOpening && !zambiaAccountOpening) {
            //         return navigate(Routes.DASHBOARD);
            //     }
            // }, 1000);
        }
        accountOpening
            ? isNullOrUndefined(accountOpening.applicationMin.isRegistered)
                ? navigate(Routes.ACCOUNT_OPENING.SET_REGISTRATION_STATUS)
                : accountOpening.applicationMin.isRegistered
                  ? dispatch(setApplicationBusinessType(ApplicationBusinessType.REGISTERED))
                  : dispatch(setApplicationBusinessType(ApplicationBusinessType.UNREGISTERED))
            : undefined;
        zambiaAccountOpening
            ? isNullOrUndefined(zambiaAccountOpening.application.isRegistered)
                ? navigate(Routes.ACCOUNT_OPENING.SET_REGISTRATION_STATUS)
                : zambiaAccountOpening.application.isRegistered
                  ? dispatch(setZambiaApplicationBusinessType(ApplicationBusinessType.REGISTERED))
                  : dispatch(setZambiaApplicationBusinessType(ApplicationBusinessType.UNREGISTERED))
            : undefined;
    }, [accountInitLoading, accountOpening, zambiaAccountOpening]);

    // console.log(accountInitLoading, "===== account opening");
    return (
        <>
            <ApplicationLayout>
                {(accountOpening || zambiaAccountOpening) &&
                    (zambiaAccountOpening ? (
                        <ZambiaApplicationStatus pages={isZambiaRegistered ? ZambiaRegisteredPages : ZambiaUnregisteredPages} />
                    ) : (
                        <NigeriaApplicationStatus pages={isRegistered ? NigeriaRegisteredPages : NigeriaUnregisteredPages} />
                    ))}
            </ApplicationLayout>
        </>
    );
}

export default AccountOpening;
