import { AddMoneyFundingStageType, AddMoneyPhase } from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import { setAddMoneyFundingStage, setAddMoneyPhase } from "../../../../../../redux/payments/addMoney/slice/addMoneySlice";
import { useEffect, useState } from "react";

import MobileMoneyMakePayment from "./Component/MakePayment";
import MobileMoneyPaymentDetails from "./Component/PaymentDetails";
import MobileMoneyProcessPayment from "./Component/ProcessPayment";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

interface Props {
    selectedAccountId: string;
    handleBack: () => void;
    handleClose: () => void;
}

const enum MobileMoneyStage {
    PAYMENT_DETAILS = "payment-details",
    MAKE_PAYMENT = "make-payment",
    PROCESSING = "processing",
}

type LocationState = {
    selectedAccountId?: string;
};

function MobileMoney(props: Props): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const [key, setKey] = useState(`${Math.floor(Math.random() * 100000000000000) + 1}`);
    const [stage, setStage] = useState(MobileMoneyStage.PAYMENT_DETAILS);
    const [phoneNumber, setPhoneNumber] = useState("");

    const selectedAccountId = locationState?.selectedAccountId || props.selectedAccountId;

    useEffect(() => {
        if (!selectedAccountId) {
            dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.INITIAL));
            dispatch(setAddMoneyPhase(AddMoneyPhase.ACCOUNT_AND_METHOD));
            navigate("/payments/add-money/make");
        } else {
            dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.MAKE_PAYMENT));
        }
    }, [selectedAccountId]);

    const handleUpdateState = (_key: string, _phoneNumber: string) => {
        setKey(_key);
        setPhoneNumber(_phoneNumber);
    };

    return (
        <>
            <div className="flex w-full flex-col items-center justify-start space-y-6">
                {stage === MobileMoneyStage.PAYMENT_DETAILS && (
                    <MobileMoneyPaymentDetails
                        keyId={key}
                        selectedAccountId={selectedAccountId}
                        handleBack={props.handleBack}
                        handleNext={() => {
                            setStage(MobileMoneyStage.MAKE_PAYMENT);
                            dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.MAKE_PAYMENT));
                            setAddMoneyFundingStage;
                        }}
                        handleLast={() => {
                            setStage(MobileMoneyStage.PROCESSING);
                            dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.PROCESS_PAYMENT));
                        }}
                        handleUpdateKeyId={() => setKey(`${Math.floor(Math.random() * 100000000000000) + 1}`)}
                        handleUpdatePhoneNumber={(_value) => setPhoneNumber(_value)}
                    />
                )}
                {stage === MobileMoneyStage.MAKE_PAYMENT && (
                    <MobileMoneyMakePayment
                        keyId={key}
                        recipientPhoneNumber={phoneNumber}
                        handleBack={() => {
                            setStage(MobileMoneyStage.PAYMENT_DETAILS);
                            dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.PAYMENT_DETAILS));
                        }}
                        handleNext={() => {
                            setStage(MobileMoneyStage.PROCESSING);
                            dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.PROCESS_PAYMENT));
                        }}
                    />
                )}
                {stage === MobileMoneyStage.PROCESSING && (
                    <MobileMoneyProcessPayment keyId={key} handleClose={props.handleClose} handleUpdateState={() => handleUpdateState("", "")} />
                )}
            </div>
        </>
    );
}

export default MobileMoney;
