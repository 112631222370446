import ConfirmModal from "../../../../../components/modal/confirm-modal";
import useRemoveFromUserGroup from "../../hooks/state/SavedRecipient/useRemoveFromUserGroup";

function RemoveFromUserGroupModal(): JSX.Element {
    const data = useRemoveFromUserGroup();

    return (
        <ConfirmModal
            active={data.isModalOpen && !!data.recipient}
            onClose={data.closeModal}
            isSubmitting={data.isSubmitting}
            onConfirm={() => {
                if (data.recipient) {
                    void data.handleRemoveFromUserGroup(data.recipient);
                }
            }}
            errorMessage={data.errorMessage}
            header="Remove from Group?"
            cancelText="Cancel"
            confirmColor="red"
            confirmText="Remove"
        >
            {data.recipient && (
                <span className="w-full whitespace-normal break-words text-left text-base font-normal">
                    Remove {data.recipient.customerAccount.accountName} from
                    {data.recipient.customerAccount.userGroup ? <> &apos;{data.recipient.customerAccount.userGroup.name}&apos; </> : <> the </>}
                    group?
                </span>
            )}
        </ConfirmModal>
    );
}

export default RemoveFromUserGroupModal;
