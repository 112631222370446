import { HintType, ListStyle } from "../../../../../../element/hint/hint.constant";
import { useLocation, useNavigate } from "react-router";
import { useRef, useState } from "react";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../../../services/nigeria-application/registered/company.types";
import { FileDrop } from "react-file-drop";
import GoogleIcon from "../../../../../../../../../../components/google-icon";
import Hint from "../../../../../../element/hint";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../../../components/spinner";
import NewUploadedCard from "../../../../../../cards/new-uploaded-card";
import PreviewModal from "../../../../../../modals/preview-modal";
import RadioButton from "../../../../../../../../../../components/radio-button";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import useIdentityUploadComponent from "../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useIdentityUploadComponent";
import usePreview from "../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/usePreview";
import { useSelector } from "react-redux";

const MeansOfIdHints: CompanyHint[] = [
    {
        index: 0,
        span: <span>Choose your preferred means of identification before uploading.</span>,
    },
    {
        index: 1,
        span: <span>Ensure your ID number is visible on your means of ID.</span>,
    },
    {
        index: 2,
        span: <span>Ensure your ID is within its validity period.</span>,
    },
];

type LocationState = {
    from?: string;
};

function MeansOfId(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
        downloadedDocumentName: "Identification Document",
    });
    const { handleSubmitIdentification, isSubmitIdentificationLoading } = useIdentityUploadComponent({
        onComplete: () => setIsUploaded(true),
    });

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const currentSignatory = useSelector((state: IRootState) => state.application.currentSignatory);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [type, setType] = useState<number | null>(null);
    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);
    const [uploaded, setIsUploaded] = useState(!!currentSignatory?.identification?.fileId);
    // const [selectedFileName, setSelectedName] = useState<string | null>(null);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    const isRegistered = accountOpening?.applicationMin.isRegistered;

    // const onFileChange = (_files: File | null) => {
    //     if (_files) {
    //         // setSelectedName(_files.name);
    //         void handleSubmitIdentification({ typeId: type as number, file: _files });
    //     }
    // };

    const handleReUpload = () => {
        setIsUploaded(false);
        // setSelectedName(null);
    };

    // const onTargetClick = () => {
    //     fileInputRef.current && fileInputRef.current.value === "";
    //     fileInputRef.current && fileInputRef.current.click();
    // };

    const handleBack = () => {
        navigate(
            locationState?.from ||
                (isRegistered ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.NEXT_OF_KIN : Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS)
        );
    };

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Identification Preview"
                />
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    {accountOpening && (
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Means of ID"
                                subTitle="Provide us with a valid means of ID"
                                progress={accountOpening.progress.personalCompletedProgress()}
                                withBorder={!uploaded}
                            >
                                <div className="w-full">
                                    {!uploaded && (
                                        <div className="flex w-full flex-col items-start justify-start space-y-4 xs:flex-row xs:justify-between xs:space-x-4 xs:space-y-0">
                                            <div className="flex w-64 flex-col items-start justify-start space-y-4">
                                                {accountOpening?.options.idType?.map((el) => (
                                                    <RadioButton
                                                        id={String(el.id)}
                                                        checked={type === el.id}
                                                        text={el.name}
                                                        key={el.id}
                                                        func={() => setType(el.id)}
                                                        size="sm"
                                                    />
                                                ))}
                                            </div>
                                            <div
                                                className="flex h-24 w-full cursor-pointer items-center justify-center self-end rounded-lg border border-dashed border-grey bg-blue-senary xs:min-w-15 xs:max-w-15"
                                                onClick={() => {
                                                    fileInputRef.current && fileInputRef.current.value === "";
                                                    fileInputRef.current && fileInputRef.current.click();
                                                }}
                                            >
                                                <FileDrop
                                                    onDrop={(files: FileList | null) => {
                                                        if (files && files[0].size > 5242880) {
                                                            return setIsFileSizeExceeded(true);
                                                        }
                                                        setIsFileSizeExceeded(false);

                                                        files &&
                                                            files[0] &&
                                                            void handleSubmitIdentification({ typeId: type as number, file: files[0] });
                                                    }}
                                                >
                                                    <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
                                                        {isSubmitIdentificationLoading ? (
                                                            <LencoSpinner color="blue" size="sm" />
                                                        ) : (
                                                            <GoogleIcon icon="upload" className="text-blue" isOutlined />
                                                        )}
                                                        <p className="text-center text-xs font-medium text-blue">Click or Drag and Drop</p>
                                                        {isFileSizeExceeded && <span className="text-xs text-error">*File exceeded 5MB</span>}
                                                    </div>
                                                </FileDrop>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    className="hidden"
                                                    onChange={(event) =>
                                                        event.target.files &&
                                                        event.target.files[0] &&
                                                        void handleSubmitIdentification({ typeId: type, file: event.target.files[0] })
                                                    }
                                                    accept="image/jpeg, image/png, application/pdf, .pdf"
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {uploaded && (
                                        <NewUploadedCard
                                            text={
                                                accountOpening?.options.idType.find((_) => _.id === currentSignatory?.identification?.type)?.name ||
                                                "Means of ID"
                                            }
                                            handleRedo={handleReUpload}
                                            handlePreviewDocument={() => void handlePreviewDocument(currentSignatory?.identification?.fileId || "")}
                                        />
                                    )}
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp
                                        type="button"
                                        color="grey"
                                        ripple="light"
                                        buttonType="secondary"
                                        func={currentSignatory?.identification?.fileId && !uploaded ? () => setIsUploaded(true) : handleBack}
                                        fullWidth
                                    >
                                        <span>
                                            {currentSignatory?.identification?.fileId && !uploaded
                                                ? "Cancel"
                                                : `Back${locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}`}
                                        </span>
                                    </ButtonComp>
                                </div>
                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                    <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                        <ButtonComp
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            disable={!uploaded}
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname: isRegistered
                                                            ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH
                                                            : Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.PHOTOGRAPH,
                                                    },
                                                    {
                                                        state: {
                                                            from: isRegistered
                                                                ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID
                                                                : Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID,
                                                        },
                                                    }
                                                )
                                            }
                                            fullWidth
                                        >
                                            <span>Continue</span>
                                        </ButtonComp>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={MeansOfIdHints} />
                </div>
            </div>
        </>
    );
}

export default MeansOfId;
