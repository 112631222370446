import React, { ReactNode } from "react";
import { DataColumn } from "../Type/DataRow";
import { ReactComponent as MediumGreenTrend } from "../svg/medium-green-trend.svg";
import { ReactComponent as MediumRedTrend } from "../svg/medium-red-trend.svg";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatTime } from "../../../utils/formatTime";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function TrendLogo({ dataColumn, dataType }: Props) {
    return (
        <div className="flex items-center gap-x-2" data-type={dataType}>
            <div
                data-type={dataType}
                className={`relative flex h-5 w-5 items-center justify-center rounded-full text-xs uppercase text-black-tertiary ${dataColumn.gainTrend !== undefined && dataColumn.gainTrend === true ? "bg-success-backdrop" : ""} ${dataColumn.gainTrend !== undefined && dataColumn.gainTrend === false ? "bg-error-backdrop" : ""}`}
            >
                {dataColumn.gainTrend !== undefined && (
                    <>
                        {dataColumn.gainTrend === true && <MediumGreenTrend />}
                        {dataColumn.gainTrend === false && <MediumRedTrend />}
                    </>
                )}
            </div>
            <div data-type={dataType} className="text-sm font-medium capitalize text-black-secondary">
                {dataColumn.textType === TextType.DATE
                    ? formatDate(dataColumn.text as Date)
                    : dataColumn.textType === TextType.TIME
                      ? formatTime(dataColumn.text as Date)
                      : dataColumn.textType === TextType.DATE_TIME
                        ? formatDateAndTime(dataColumn.text as Date)
                        : (dataColumn.text as string | ReactNode)}
            </div>
        </div>
    );
}
