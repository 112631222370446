import * as Yup from "yup";

import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../../../element/hint/hint.constant";
import {
    PersonalDetailForm,
    getPersonalDetailFormHints,
} from "../../../../../../../services/zambia-application/registered/new/currentSignatory.types";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import CustomSelectWithOther from "../../../../../../../../../../components/dropdown/custom-select-with-other";
import DateComponent from "../../../../../../../../../../components/inputs/date-input";
import Hint from "../../../../../../element/hint";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import LencoFormInput from "../../../../../../../../../../components/inputs/FormInput";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import SingleSelectDropdown from "../../../../../../../../../../components/dropdown/single-select/single-select";
import formikHasError from "../../../../../../../../../../helpers/formikHasError";
import isNullOrUndefined from "../../../../../../../../../../utils/isNullOrUndefined";
import moment from "moment";
import useBio from "../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useBio";
import { useEffect } from "react";
import { useSelector } from "react-redux";

type LocationState = {
    from?: string;
};

function PersonalDetails(): JSX.Element {
    const navigate = useNavigate();

    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isBioLoading, handleReset, handleUpdateBio } = useBio();

    const isRegistered = useSelector((state: IRootState) => state.zambiaApplication.init?.application.isRegistered);
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const currentSignatory = useSelector((state: IRootState) => state.zambiaApplication.currentSignatory);

    const INITIAL_FORM_STATE: PersonalDetailForm = {
        firstName: currentSignatory?.firstName || "",
        otherName: currentSignatory?.otherName || "",
        surname: currentSignatory?.surname || "",
        dateOfBirth: currentSignatory?.dateOfBirth ? new Date(currentSignatory?.dateOfBirth) : null,
        phone: currentSignatory?.phone || "",
        isZambian: isNullOrUndefined(currentSignatory?.isZambian)
            ? null
            : currentSignatory?.isZambian === false
              ? false
              : currentSignatory?.isZambian || null,
        nonZambianNationality: currentSignatory?.nonZambianNationality || null,
        roleId: currentSignatory?.role?.id || 0,
        otherRoleText: currentSignatory?.otherRoleText || null,
        gender: currentSignatory?.gender || 0,
        address: currentSignatory?.address || "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        firstName: Yup.string().required("Required"),
        otherName: Yup.string(),
        surname: Yup.string().required("Required"),
        dateOfBirth: Yup.date().nullable().required("Required"),
        phone: Yup.string().required("Required"),
        isZambian: Yup.boolean().nullable(),
        nonZambianNationality: Yup.string().nullable(),
        roleId: Yup.number(),
        otherRoleText: Yup.string().nullable(),
        gender: Yup.number().nullable().required("Required"),
        address: Yup.string().required("Required"),
    });

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    const handleBack = () => {
        navigate(locationState?.from || Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.DETAILS);
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    void handleUpdateBio({ ...values, dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD") });
                }}
                enableReinitialize
                validateOnChange
            >
                {(formik) => {
                    return (
                        <Form className="flex w-full flex-row items-start justify-start space-x-6">
                            <div className="flex w-full flex-row items-start justify-start space-x-6">
                                {accountOpening && (
                                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                                        <ApplicationHeader
                                            header="Personal Information"
                                            subTitle="Please review and update your personal information"
                                            progress={accountOpening.progress.personalCompletedProgress()}
                                        >
                                            <div className="flex w-full flex-col items-start space-y-4">
                                                <div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <div className="w-full lg:w-6/12">
                                                        <LencoFormInput
                                                            type="text"
                                                            placeholder="First Name"
                                                            name="firstName"
                                                            value={formik.values.firstName || ""}
                                                        />
                                                    </div>
                                                    <div className="lg:w-6/12">
                                                        <LencoFormInput
                                                            type="text"
                                                            placeholder="Last Name"
                                                            name="surname"
                                                            value={formik.values.surname || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <div className="w-full lg:w-50%">
                                                        <LencoFormInput
                                                            type="text"
                                                            placeholder="Other Name (Optional)"
                                                            name="otherName"
                                                            value={formik.values.otherName || ""}
                                                        />
                                                    </div>
                                                    <div className="w-full lg:w-50%">
                                                        <LencoFormInput
                                                            type="text"
                                                            placeholder="Phone Number"
                                                            name="phone"
                                                            value={formik.values.phone || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <div className="w-full lg:w-50%">
                                                        <DateComponent
                                                            date={formik.values.dateOfBirth || null}
                                                            handleSetDate={(_date) => void formik.getFieldHelpers("dateOfBirth").setValue(_date)}
                                                            placeholder="Date of Birth"
                                                        />
                                                    </div>
                                                    <div className="w-full lg:w-50%">
                                                        <CustomSelectWithOther
                                                            placeholder="Nationality"
                                                            value={formik.values.isZambian || undefined}
                                                            otherValue={formik.values.nonZambianNationality || undefined}
                                                            options={[
                                                                {
                                                                    text: "Zambian",
                                                                    value: true,
                                                                },
                                                            ]}
                                                            otherOptionValue={false}
                                                            reset={() => {
                                                                void formik.getFieldHelpers("isZambian").setValue(null);
                                                                void formik.getFieldHelpers("nonZambianNationality").setValue(undefined);
                                                                void formik.getFieldHelpers("residencyPermit.issueDate").setValue(null);
                                                                void formik.getFieldHelpers("residencyPermit.expiryDate").setValue(null);
                                                                void formik.getFieldHelpers("residencyPermit?.permitNumber").setValue("");
                                                            }}
                                                            resetDropdownValue={() => {
                                                                void formik.getFieldHelpers("isZambian").setValue(null);
                                                            }}
                                                            onSelect={(value, otherText) => {
                                                                if (!isNullOrUndefined(value)) {
                                                                    void formik.getFieldHelpers("isZambian").setValue(value);
                                                                }
                                                                void formik.getFieldHelpers("nonZambianNationality").setValue(otherText || null);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <div className="lg:w-6/12">
                                                        <CustomSelectWithOther
                                                            placeholder="Role in Business"
                                                            value={formik.values.roleId || undefined}
                                                            otherValue={formik.values.otherRoleText || undefined}
                                                            options={accountOpening.options.role.map((item) => item.toDropdownItem())}
                                                            otherOptionValue={null}
                                                            reset={() => {
                                                                void formik.getFieldHelpers("roleId").setValue(0);
                                                                void formik.getFieldHelpers("otherRoleText").setValue(undefined);
                                                            }}
                                                            resetDropdownValue={() => {
                                                                void formik.getFieldHelpers("roleId").setValue(0);
                                                            }}
                                                            onSelect={(value, otherText) => {
                                                                void formik.getFieldHelpers("roleId").setValue(value || 0);
                                                                void formik.getFieldHelpers("otherRoleText").setValue(otherText || null);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="lg:w-6/12">
                                                        <SingleSelectDropdown
                                                            placeholder="Gender"
                                                            options={accountOpening?.options.gender.map((el) => {
                                                                return {
                                                                    text: el.name,
                                                                    value: el.id,
                                                                };
                                                            })}
                                                            onChange={(value) => void formik.getFieldHelpers("gender").setValue(value || null)}
                                                            value={formik.values.gender}
                                                            defaultValue={(currentSignatory && currentSignatory?.gender) || undefined}
                                                            bigDropdown={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <LencoFormInput
                                                        type="textarea"
                                                        placeholder="Address"
                                                        name="address"
                                                        value={formik.values.address || ""}
                                                    />
                                                </div>
                                            </div>
                                        </ApplicationHeader>
                                        <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                            <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                <ButtonComp
                                                    type="button"
                                                    color="grey"
                                                    ripple="light"
                                                    buttonType="secondary"
                                                    func={handleBack}
                                                    fullWidth
                                                >
                                                    <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                                </ButtonComp>
                                            </div>
                                            <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                <ButtonComp
                                                    type={formik.dirty ? "submit" : "button"}
                                                    color="black"
                                                    ripple="light"
                                                    buttonType="primary"
                                                    isLoading={isBioLoading}
                                                    func={() => {
                                                        navigate(
                                                            {
                                                                pathname: isRegistered
                                                                    ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID
                                                                    : Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID,
                                                            },
                                                            {
                                                                state: {
                                                                    from: isRegistered
                                                                        ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS
                                                                        : Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                    disable={
                                                        !!(
                                                            formikHasError(formik.errors) ||
                                                            (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW && !formik.dirty) ||
                                                            isNullOrUndefined(formik.values.isZambian) ||
                                                            (formik.values.isZambian === false &&
                                                                (formik.values.nonZambianNationality?.length || 0) < 1) ||
                                                            (formik.values.roleId === 0 && (formik.values.otherRoleText?.length || 0) < 1)
                                                        )
                                                    }
                                                    fullWidth
                                                >
                                                    {locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? "Save changes" : "Continue"}
                                                </ButtonComp>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Hint
                                    // hintType={HintType.TIP}
                                    // listStyle={ListStyle.PLAIN}
                                    // firstList={PersonalDetailsHints}
                                    // secondHint
                                    hintType={HintType.GUIDE}
                                    listStyle={ListStyle.CHECK}
                                    firstList={getPersonalDetailFormHints(
                                        Object.keys(INITIAL_FORM_STATE || []),
                                        [
                                            ...Object.keys(formik.errors || []),
                                            ...(!formik.values.roleId && !formik.values.otherRoleText ? ["roleId"] : []),
                                            ...(!formik.values.isZambian && !formik.values.nonZambianNationality ? ["isZambian"] : []),
                                        ],
                                        [
                                            "otherName",
                                            "otherRoleText",
                                            "nonZambianNationality",
                                            formik.values.isZambian || isNullOrUndefined(formik.values.isZambian) ? "residencyPermit" : "",
                                        ],
                                        [
                                            { primary: "roleId", secondary: "otherRoleText" },
                                            { primary: "isZambian", secondary: "nonZambianNationality" },
                                        ]
                                    )}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default PersonalDetails;
