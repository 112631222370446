import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../../redux/rootReducer";
import { setIsZambiaPayoutsDetailCardOpen } from "../../../../../../redux/zambia/payouts/zambiaPayoutsSlice";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import CurrencyCode from "../../../../../../components/currency-code";
import formatNumber from "../../../../../../utils/formatNumber";
import PayoutStatusTag from "../Status/PayoutStatusTag";
import { formatDateAndTime } from "../../../../../../utils/formatDate";

const DATA_TYPE = "zambia-payout";

function ZambiaLencoPayPayoutsTray(): JSX.Element {
    const dispatch = useDispatch();

    const zambiaPayoutsDetail = useSelector((state: IRootState) => state.zambiaPayouts.zambiaPayoutsDetail);
    const isAddMoneyCollectionRequestDetailCardOpen: boolean = useSelector((state: IRootState) => state.zambiaPayouts.isZambiaPayoutsDetailCardOpen);

    const handleCloseAddMoneyCollectionRequestTray = () => {
        dispatch(setIsZambiaPayoutsDetailCardOpen(false));
    };

    return (
        <>
            <FullPageTray
                active={isAddMoneyCollectionRequestDetailCardOpen}
                dataType={DATA_TYPE}
                handleReset={handleCloseAddMoneyCollectionRequestTray}
                withClickOutside
            >
                <FullPageTrayHeader toggler={handleCloseAddMoneyCollectionRequestTray} dataType={DATA_TYPE}>
                    Payout Details
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={DATA_TYPE}>
                    <div className="mb-10 flex w-full flex-col space-y-4">
                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between space-x-8 py-2">
                                <p className="text-2xl font-bold">
                                    <CurrencyCode currency={zambiaPayoutsDetail?.currency} />
                                    {formatNumber(zambiaPayoutsDetail?.amount || 0)}
                                </p>

                                <div className="">
                                    <PayoutStatusTag transaction={zambiaPayoutsDetail} />
                                </div>
                            </div>
                        </div>

                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Date</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {formatDateAndTime(zambiaPayoutsDetail?.createdOn as Date)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Payout Amount</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <CurrencyCode currency={zambiaPayoutsDetail?.currency} />
                                    {formatNumber(zambiaPayoutsDetail?.amount)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Settled Amount</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <CurrencyCode currency={zambiaPayoutsDetail?.currency} />
                                    {formatNumber(zambiaPayoutsDetail?.amount)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Reference</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaPayoutsDetail?.transactionReference}
                                </p>
                            </div>{" "}
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Merchant Reference</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaPayoutsDetail?.clientReference}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Fees</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <CurrencyCode currency={zambiaPayoutsDetail?.currency} />
                                    {formatNumber(zambiaPayoutsDetail?.totalCharges)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Fees Paid By</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaPayoutsDetail?.isSplitPayment ? "Split Payment" : zambiaPayoutsDetail?.isCharge ? "Self" : "Recipient"}
                                </p>
                            </div>
                        </div>
                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            {zambiaPayoutsDetail?.isCardPayment ? (
                                <>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {zambiaPayoutsDetail?.initiatedBy?.name}
                                        </p>
                                    </div>{" "}
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">PAN</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {zambiaPayoutsDetail?.cardPayment?.cardDetails?.panLast4Digits?.replaceAll(/x/gi, "•")}{" "}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {zambiaPayoutsDetail?.initiatedBy?.name}
                                        </p>
                                    </div>{" "}
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Phone Number</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {zambiaPayoutsDetail?.initiatedBy?.phones?.[0]}
                                        </p>
                                    </div>{" "}
                                </>
                            )}
                        </div>
                    </div>
                </FullPageTrayBody>
                {/* <FullPageTrayBody dataType={DATA_TYPE}>
                    <div className="mb-10 flex w-full flex-col space-y-4">
                        {zambiaPayoutsDetail && <TransactionDetailCardHead transaction={zambiaPayoutsDetail} dataType={DATA_TYPE} />}
                        {zambiaPayoutsDetail && <TransactionDetailCardDetails transaction={zambiaPayoutsDetail} dataType={DATA_TYPE} />}
                    </div>
                </FullPageTrayBody> */}
            </FullPageTray>
            {/* )} */}
        </>
    );
}

export default ZambiaLencoPayPayoutsTray;
