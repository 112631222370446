import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddFundsCard from "../Cards/AddFundsCard";
import FullPageTray from "../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import { IRootState } from "../../../../../redux/rootReducer";
import SearchBar from "../../../../../components/search-bar";
import { closeAddFundsModal } from "../../../../../redux/accounts/slice/accountsSlice";

function AddFundsTray(): JSX.Element {
    const dispatch = useDispatch();

    const isAddFundsModalOpen = useSelector((state: IRootState) => state.account.isAddFundsModalOpen);
    const userAccounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts || []);

    // const [active, setActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [inputValue, setInputValue] = useState("");

    const handleCloseAddFundsModal = useCallback(() => {
        dispatch(closeAddFundsModal());
    }, []);

    const handleReset = () => {
        dispatch(closeAddFundsModal());
    };

    return (
        <>
            <FullPageTray active={isAddFundsModalOpen} dataType="add-funds-tray" handleReset={handleReset} withClickOutside>
                <FullPageTrayHeader
                    toggler={handleCloseAddFundsModal}
                    dataType="add-funds-tray"
                    subTitle="You can add funds to your account via Bank transfer with the details below"
                >
                    <p className="text-2xl font-medium">Add Money</p>
                </FullPageTrayHeader>
                <FullPageTrayBody dataType="add-funds-tray">
                    <div className="max-h-full w-full space-y-8">
                        <div className="flex space-x-3 rounded-md border border-info bg-info-backdrop p-4">
                            <div>
                                <span className="material-symbols-outlined text-xl text-info">campaign</span>
                            </div>
                            <div className="space-y-3">
                                <p className="text-base font-medium text-black">Bank Partner Update</p>
                                <p className="text-xs text-black-tertiary">
                                    You can now receive money into your Lenco account with <span className="font-medium">URE MFB</span> or
                                    <span className="font-medium"> Providus </span>
                                    bank using the same account number
                                </p>
                                <div className="flex items-center space-x-1 text-info">
                                    <span className="material-symbols-outlined text-sm">bolt</span>
                                    <p className="text-xs font-medium text-info"> URE MFB is recommended and faster</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex h-full w-full flex-col">
                            <div className="flex flex-col items-start justify-start text-left text-sm font-normal">
                                {userAccounts.length > 5 && (
                                    <>
                                        <div className="relative mx-auto mb-2 h-10 w-full">
                                            <SearchBar
                                                placeholder="Search for your account details"
                                                size="xl"
                                                value={inputValue}
                                                onChange={(_value) => {
                                                    setInputValue(_value);
                                                    setSearchTerm(_value);
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="mt-4 flex w-full flex-col items-start justify-start space-y-4 pb-16">
                                {userAccounts
                                    .filter((_userAccount) => {
                                        if (searchTerm === "") {
                                            return _userAccount;
                                        } else if (
                                            _userAccount.bankAccount?.accountName.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
                                            _userAccount.bankAccount?.accountNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                                        ) {
                                            return _userAccount;
                                        } else {
                                            return "";
                                        }
                                    })

                                    .sort(function (a, b) {
                                        const nameA = a.bankAccount?.accountName.toLowerCase(),
                                            nameB = b.bankAccount ? b.bankAccount.accountName.toLowerCase() : "";
                                        if (nameA && nameA < nameB)
                                            //sort string ascending
                                            return -1;
                                        if (nameA && nameA > nameB) return 1;
                                        return 0; //default return value (no sorting)
                                    })
                                    .map((_userAccount) => (
                                        <AddFundsCard userAccount={_userAccount} key={_userAccount.id} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default AddFundsTray;
