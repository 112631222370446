import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import CustomerAccount from "../../models/customerAccount";
import { CustomerAccountState } from "./customerAccountSlice.types";

const initialState: CustomerAccountState = {
    customerAccounts: new Map<string, CustomerAccount>(),
};

export const customerAccountSlice = createSlice({
    name: "customerAccount",
    initialState,
    reducers: {
        setCustomerAccount: (state: CustomerAccountState, action: PayloadAction<CustomerAccount>) => {
            state.customerAccounts.set(action.payload.id, action.payload);
        },
        setMultipleCustomerAccount: (state: CustomerAccountState, action: PayloadAction<CustomerAccount[]>) => {
            action.payload.forEach((c) => state.customerAccounts.set(c.id, c));
        },
        clearCustomerAccounts: (state: CustomerAccountState) => {
            state.customerAccounts.clear();
        },
    },
});

export const { setCustomerAccount, setMultipleCustomerAccount, clearCustomerAccounts } = customerAccountSlice.actions;

export default customerAccountSlice.reducer;
