import React, { FormEvent, useCallback, useEffect, useState } from "react";

import { AccountRestrictionType } from "../../../../../../models/accountRestriction.constants";
import { AccountRestrictionTypeDetails } from "../../../Services/account-settings/account-settings.constants";
import AccountSettingsActionButtons from "../AccountSettingsActionButtons";
import AddNewRecipientForm from "./AddNewRecipientForm";
import Card from "../../../../../../models/card";
import CustomSelect from "../../../../../../components/dropdown/custom-select";
import CustomerAccount from "../../../../../../models/customerAccount";
import ErrorToast from "../../../../../../components/message-toast/sub/error-toast";
import RecipientAccountItem from "./RecipientAccountItem";
import UserAccount from "../../../../../../models/userAccount";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { mainUpdateUserAccountCallback } from "../../../../../../redux/init/slice/initSlice";
import { produce } from "immer";
import { resetAllSingleTransferData } from "../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import { updateAccountRestrictions } from "../../../Services/account-settings/account-settings.api";
import { useDispatch } from "react-redux";

interface Props {
    account: UserAccount;
    handleCancel: () => void;
}

function EditAccountRestrictions({ account, handleCancel }: Props): JSX.Element {
    const dispatch = useDispatch();
    const [type, setType] = useState<AccountRestrictionType>(account.accountRestrictions.type);
    const [recipientAccounts, setRecipientAccounts] = useState<Array<CustomerAccount | UserAccount | Card>>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [initialType, setInitialType] = useState<AccountRestrictionType>(type);

    const handleUpdateAccountRestriction = useCallback(
        async (payload: { type: AccountRestrictionType; recipientAccounts: Array<CustomerAccount | UserAccount | Card> }) => {
            try {
                setIsSubmitting(true);
                const res = await updateAccountRestrictions(account.id, {
                    type: payload.type,
                    customerAccountIds:
                        payload.type === AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS
                            ? payload.recipientAccounts
                                  .filter((recipientAccount) => recipientAccount instanceof CustomerAccount)
                                  .map((recipientAccount) => recipientAccount.id)
                            : [],
                    userAccountIds:
                        payload.type === AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS
                            ? payload.recipientAccounts
                                  .filter((recipientAccount) => recipientAccount instanceof UserAccount)
                                  .map((recipientAccount) => recipientAccount.id)
                            : [],
                    cardIds:
                        payload.type === AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS
                            ? payload.recipientAccounts
                                  .filter((_recipientCard) => _recipientCard instanceof Card)
                                  .map((_recipientCard) => _recipientCard.id)
                            : [],
                });
                dispatch(
                    mainUpdateUserAccountCallback({
                        id: res.userAccountId,
                        callback: (userAccount: UserAccount) => {
                            userAccount.accountRestrictions = res.restrictions;
                            userAccount.balanceInstructions = res.balanceInstructions;
                        },
                    })
                );
                setIsSubmitting(false);
                handleCancel();
            } catch (err) {
                dispatch(errorTrue({ message: getErrorMessage(err) }));
                setIsSubmitting(false);
            }
        },
        [account]
    );

    useEffect(() => {
        setType(account.accountRestrictions.type);
        setRecipientAccounts([
            ...account.accountRestrictions.cards,
            ...account.accountRestrictions.userAccounts,
            ...account.accountRestrictions.customerAccounts,
        ]);
    }, [account]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isFormValid) {
            void handleUpdateAccountRestriction({ type, recipientAccounts });
        }
    };

    const addRecipientAccount = (recipientAccount: CustomerAccount | UserAccount | Card): void => {
        setRecipientAccounts(
            produce((draft) => {
                // check that the recipientAccount has not been added before
                if (!draft.some((item) => item.id === recipientAccount.id)) {
                    draft.push(recipientAccount);
                }
            })
        );
    };

    const removeRecipientAccount = (index: number): void => {
        setRecipientAccounts(
            produce((draft) => {
                if (draft.length > index) {
                    draft.splice(index, 1);
                }
            })
        );
    };

    useEffect(() => {
        setIsFormValid(
            !!type && type !== initialType && (type !== AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS || recipientAccounts.length > 0)
        );
    }, [type, recipientAccounts]);

    return (
        <form className="w-ful relative h-full" onSubmit={handleSubmit}>
            <div className="space-y-6">
                <div className="space-y-4">
                    <CustomSelect
                        label="Select Rule"
                        value={type}
                        options={Object.values(AccountRestrictionTypeDetails)}
                        onSelect={(value) => {
                            if (value) {
                                setType(value);
                            }
                        }}
                        big
                    />

                    {type === AccountRestrictionType.CANNOT_SEND_MONEY && (
                        <ErrorToast
                            error={
                                <p className="text-xs text-error">
                                    You have restricted this account (<span className="text-xs font-bold">{account.lencoNameMin}</span>) from making a
                                    payout
                                </p>
                            }
                            isReactNode
                            fullWidth
                        />
                    )}
                </div>

                {type === AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS && (
                    <div className="space-y-5">
                        <AddNewRecipientForm recipientAccounts={recipientAccounts} handleSelectRecipient={addRecipientAccount} />
                        {recipientAccounts.length > 0 && (
                            <>
                                <hr className="border-grey-secondary" />
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-base font-medium">Specific Recipients</p>
                                    </div>
                                    {/*If restriction type is "send to specific recipients", show the recipients*/}
                                    {recipientAccounts.map((recipientAccount, index) => (
                                        <RecipientAccountItem
                                            key={`recipient-${recipientAccount.id}`}
                                            data={recipientAccount}
                                            id={recipientAccount.id}
                                            canRemove
                                            handleRemoveIconClick={() => removeRecipientAccount(index)}
                                            index={index}
                                            accountLength={recipientAccounts.length}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            <div
                className={
                    type === AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS
                        ? "absolute bottom-0 left-0 right-0"
                        : "mt-44 flex w-full items-center justify-center"
                }
            >
                <AccountSettingsActionButtons
                    isSubmitting={isSubmitting}
                    handleCancel={() => {
                        handleCancel();
                        dispatch(resetAllSingleTransferData());
                    }}
                    isFormValid={isFormValid}
                    initialType={initialType}
                    handleInitialType={() => setInitialType(type)}
                />
            </div>
        </form>
    );
}

export default EditAccountRestrictions;
