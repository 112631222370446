import { useCallback, useEffect, useRef } from "react";

import { KEEP_ALIVE_PING } from "../../app-constants";
import { useDispatch } from "react-redux";
import usePing from "./use-ping";

interface UsePing {
    initPing: () => void;
}

function usePingHook(): UsePing {
    const dispatch = useDispatch();
    const { mutate: mutatePing } = usePing();

    const intervalRef = useRef<ReturnType<typeof setInterval>>();
    // const backUpRef = useRef<ReturnType<typeof setInterval>>();

    const pingHandler = useCallback(() => {
        // do not logout, if the ping fails. Instead let the idle timeout log the user out
        void mutatePing();
    }, [dispatch]);

    const initPing = useCallback(() => {
        pingHandler();
        intervalRef.current = setInterval(pingHandler, KEEP_ALIVE_PING * 60 * 1000);
    }, [pingHandler]);

    // clear interval on unmount
    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return { initPing };
}

export default usePingHook;
