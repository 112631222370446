import {
    resetTransferDetailRecipient,
    setSelectedUserAccount,
    setSelectedUserAccountMeta,
    setTransferDetailOriginatingAccount,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";
import doesUserHaveAccessToAccount from "../../../../../../../helpers/doesUserHaveAccessToAccount";

interface useBulkTransferPayFromInterface {
    accounts: UserAccount[] | undefined;
    selectedAccountId: string | null;
    currentUserAccountMeta: UserAccountMeta | null;
    handleSelectAccount: (_accountId: string) => void;
    handleResetUserAccountMeta: () => void;
    handleSelectAccountUpdatingAccount: (_accountId: string) => void;
}

function useBulkTransferPayFrom(): useBulkTransferPayFromInterface {
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
    const originatingAccountId = useSelector((state: IRootState) =>
        state.bulkTransfer.isUpdatingTransferDetail
            ? state.bulkTransfer.tempTransferDetail?.originatingAccountId || ""
            : state.bulkTransfer.transferDetail.originatingAccountId
    );
    const currentUserAccountMeta = useSelector((state: IRootState) =>
        state.bulkTransfer.isUpdatingTransferDetail
            ? state.bulkTransfer.tempTransferDetail?.selectedUserAccountMeta || null
            : state.bulkTransfer.transferDetail.selectedUserAccountMeta || null
    );
    const isUpdatingTransferDetail = useSelector((state: IRootState) => state.bulkTransfer.isUpdatingTransferDetail);

    // const [maxApprovalAmount, setMaxApprovalAmount] = useState<number | null>(null);
    // const [currentUserAccountMeta, setCurrentUserAccountMeta] = useState<UserAccountMeta | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!accounts) return;
        if (accounts.length < 2) {
            if (doesUserHaveAccessToAccount(accounts[0].id)) {
                handleSelectAccount(accounts[0].id);
            }
        }
    }, [accounts]);

    const handleSelectAccount = useCallback(
        (_accountId: string) => {
            const doesAccountExist = accounts?.some((el) => el.id === _accountId);
            if (doesAccountExist) {
                const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
                const userAccountMeta = userAccountsMeta?.find((_it) => _it.userAccountId === userAccount.id);
                // setCurrentUserAccountMeta(userAccountMeta || null);
                dispatch(setTransferDetailOriginatingAccount(_accountId));
                dispatch(setSelectedUserAccount(userAccount));
                if (userAccountMeta) dispatch(setSelectedUserAccountMeta(userAccountMeta));
                // setMaxApprovalAmount(userAccountMeta?.maxApprovalAmount || null);
                // dispatch(setCanApprove(userAccountMeta?.isApprover || false));
                if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) dispatch(resetTransferDetailRecipient());
            }
        },
        [accounts, userAccountsMeta, isUpdatingTransferDetail]
    );
    const handleSelectAccountUpdatingAccount = useCallback(
        (_accountId: string) => {
            const doesAccountExist = accounts?.some((el) => el.id === _accountId);
            if (doesAccountExist) {
                const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
                const userAccountMeta = userAccountsMeta?.find((_it) => _it.userAccountId === userAccount.id);
                dispatch(setTransferDetailOriginatingAccount(_accountId));
                dispatch(setSelectedUserAccount(userAccount));
                if (userAccountMeta) dispatch(setSelectedUserAccountMeta(userAccountMeta));
            }
        },
        [accounts, userAccountsMeta, isUpdatingTransferDetail]
    );

    const handleResetUserAccountMeta = useCallback(() => {
        dispatch(setSelectedUserAccountMeta(null));
    }, []);

    return {
        accounts,
        currentUserAccountMeta,
        selectedAccountId: originatingAccountId,
        handleSelectAccount,
        handleResetUserAccountMeta,
        handleSelectAccountUpdatingAccount,
    };
}

export default useBulkTransferPayFrom;
