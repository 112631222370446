import React, { useCallback, useEffect } from "react";

import { AccountSettingsUrlParams } from "../../Services/account-settings/account-settings.types";
import { IRootState } from "../../../../../redux/rootReducer";
import UserAccount from "../../../../../models/userAccount";
import UserAccountSelect from "../../../../../components/user-account-select";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
    selectedAccountId: string | undefined;
    handleSelectAccount: (id: string) => void;
}

function AccountSettingsSelectAccount({ selectedAccountId, handleSelectAccount }: Props): JSX.Element {
    const { accountId: accountIdFromUrl } = useParams<AccountSettingsUrlParams>();

    // const userAccounts = useSelector<IRootState, UserAccount[]>((state) => state.init.main?.companyDetails.accounts || []);
    const userAccounts = useSelector<IRootState, UserAccount[]>((state: IRootState) => state.init.main?.companyDetails.accounts || []);

    const findAccount = useCallback(
        (id: string | undefined): UserAccount | undefined => {
            if (isNullOrUndefined(id)) {
                return undefined;
            }
            return userAccounts.find((userAccount) => userAccount.id === id);
        },
        [userAccounts]
    );

    useEffect(() => {
        let accountToSelect: UserAccount | undefined;
        if (!selectedAccountId) {
            // select default account
            // check if there is an accountId in the url
            if (accountIdFromUrl) {
                accountToSelect = findAccount(accountIdFromUrl);
                window.history.replaceState({}, "Settings", "/settings/account");
            }
            // get the main account, or the first account in the list
            else if (userAccounts.length > 0) {
                accountToSelect = userAccounts.find((userAccount) => userAccount.isMain) || userAccounts[0];
            }
        }

        if (accountToSelect) {
            handleSelectAccount(accountToSelect.id);
        }
    }, [accountIdFromUrl, userAccounts]);

    return (
        <>
            <UserAccountSelect
                label="Select Account"
                value={selectedAccountId || ""}
                options={userAccounts}
                onClick={handleSelectAccount}
                isDisabled={userAccounts.length < 2}
                size="xl"
            />
        </>
    );
}

export default AccountSettingsSelectAccount;
