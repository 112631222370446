export const routes = Object.freeze({
    home: "/",
    dashboard: "/dashboard",
    terminals: "/terminals",
    accountSettings: "/account-settings",
    security: "/security",
    LENCO_PAY: {
        APIS: "/lenco-pay/api-access",
        CONFIGURATION: "/lenco-pay/configuration",
    },
});
