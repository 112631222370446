import AvatarDetails from "../../../avatar-details";
import Currency from "../../../../models/currency";
import Money from "../../../money";
import UserAccount from "../../../../models/userAccount";
import getInitials from "../../../../helpers/get-initials";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import titleCase from "../../../../hooks/titleCase";

interface Props {
    data: UserAccount;
    size?: "xs" | "2xs" | "sm" | "md" | "lg";
    message?: string;
    dataType?: string;
    currency?: Currency | undefined | null;
    avatarColor?: "white" | "grey";
    showBalance?: boolean;
    isHideInitials?: boolean;
}

function UserAccountDetails(props: Props): JSX.Element {
    return (
        <>
            {props.data && (
                <div className="flex w-full items-center justify-between space-x-4">
                    <AvatarDetails
                        size={props.size}
                        initials={getInitials(props.data.name || "")}
                        color={props.avatarColor}
                        dataType={props.dataType}
                        isHideInitials={props.isHideInitials}
                        title={
                            <div className="flex items-center justify-start space-x-3" data-type={props.dataType}>
                                <p className="w-max max-w-96 truncate text-sm font-medium text-black" data-type={props.dataType}>
                                    {titleCase(props.data.accountName)}
                                </p>
                            </div>
                        }
                        subtitle={
                            props.message ? (
                                <div
                                    className="flex w-full flex-row items-center justify-start space-x-2 truncate text-xs"
                                    data-type={props.dataType}
                                >
                                    {props.message}
                                </div>
                            ) : props.showBalance ? (
                                <div className="flex items-center justify-start text-xs text-black-tertiary" data-type={props.dataType}>
                                    {/* <p data-type={props.dataType}>Available Bal:</p> */}
                                    {props.showBalance && !isNullOrUndefined(props.data.balance) && (
                                        <Money amount={props.data.balance} currency={props.currency} dataType={props.dataType} />
                                    )}
                                </div>
                            ) : null
                        }
                    />
                    <div>
                        {props.data.isMain && (
                            <div
                                className={
                                    "flex h-5 items-center justify-center rounded-[4px] border border-grey bg-grey-tertiary px-2 " +
                                    ` ${props.avatarColor === "white" ? "bg-white" : ""}` +
                                    ` ${isNullOrUndefined(props.avatarColor) || props.avatarColor === "grey" ? "bg-grey-tertiary" : ""}`
                                }
                                data-type={props.dataType}
                            >
                                <p className="text-xs font-medium text-black-secondary" data-type={props.dataType}>
                                    Main
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default UserAccountDetails;
