import KinRelationship from "../../../../../models/meta/kinRelationship";
import MetaEntity from "../../../../../models/meta/metaEntity";
import OtherDirector from "../../../../../models/account-opening/otherDirector";

export enum InviteType {
    S = "s",
    M = "m",
    B = "b",
    D = "d",
}

export type ExternalInitOptions = {
    gender: MetaEntity[];
    nameTitle: MetaEntity[];
    idType: MetaEntity[];
    role: MetaEntity[];
    kinRelationship: KinRelationship[];
    state: MetaEntity[];
};

export type ExternalInitRequest = {
    a: string;
    b: string;
    type: InviteType;
};

export type ExternalInitResponse = {
    businessName: string;
    creator: string;
    director: OtherDirector;
    options: ExternalInitOptions;
};

export type ExternalApplicationResponse = {
    director: OtherDirector;
};

export type ExternalDirectorBioRequest = {
    firstName: string;
    surname: string;
    otherName: string;
    title: number | undefined;
    motherMaidenName: string;
    dateOfBirth: string;
    gender: number | undefined;
    phones: string[];
    bvn: string;
    role: number | undefined;
    otherRoleText: string | null;
    address: string;
    isNigerian: boolean;
    nonNigerianNationality: string | null;
    residencyPermit: {
        permitNumber: string;
        issueDate: string;
        expiryDate: string;
    };
};

export type ExternalDirectorNextOfKinRequest = {
    relationship: number | null;
    otherRelationshipText: string | null;
    gender: number | null;
    name: string;
    phone: string;
    address: string;
};

export type ExternalDirectorIdentificationRequest = {
    a: string;
    b: string;
    type: InviteType.S;
};
