import * as Yup from "yup";

import { BusinessInformationFields, getBusinessInformationHints } from "../../../../../../services/zambia-application/registered/new/company.types";
import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../../element/hint/hint.constant";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../element/ApplicationHeader";
import BusinessInformationReview from "../../business-review/components/review/BusinessInformationReview";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import FormInput from "../../../../../../../../../components/inputs/FormInput";
import Hint from "../../../../../element/hint";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import { Routes } from "../../../../../../../../../routes/routes.constants";
import SingleSelectDropdown from "../../../../../../../../../components/dropdown/single-select/single-select";
import useBusinessInformation from "../../../../../../Hooks/zambia-application/registered-business/business-details/useBusinessInformation";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface Props {
    isUnregisteredBusiness?: boolean;
}
type LocationState = {
    from?: string;
};

function BusinessInformation(props?: Props): JSX.Element {
    const navigate = useNavigate();
    const { isBusinessInformationLoading, handleReset, handleUpdateBusinessInformation } = useBusinessInformation();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const isRegistered = useSelector((state: IRootState) => state.zambiaApplication.init?.application.isRegistered);
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const businessDetails = useSelector((state: IRootState) => state.zambiaApplication.businessDetails);

    const INITIAL_FORM_STATE = {
        [BusinessInformationFields.NAME]: businessDetails?.name || "",
        [BusinessInformationFields.INDUSTRY]: Number(businessDetails?.industry?.id) || null,
        [BusinessInformationFields.BUSINESS_CATEGORY]: props?.isUnregisteredBusiness ? 1 : businessDetails?.registrationCategory || null,
        [BusinessInformationFields.BUSINESS_NATURE]: businessDetails?.businessNature || "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        [BusinessInformationFields.NAME]: Yup.string().required("Required"),
        [BusinessInformationFields.INDUSTRY]: Yup.number().nullable().required("Required"),
        [BusinessInformationFields.BUSINESS_CATEGORY]: Yup.number().nullable().required("Required"),
        [BusinessInformationFields.BUSINESS_NATURE]: Yup.string().required("Required"),
    });

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    void handleUpdateBusinessInformation(values);
                }}
                enableReinitialize
                validateOnMount
                validateOnBlur
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <div className="flex w-full flex-row items-start justify-start space-x-6">
                                {accountOpening && (
                                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                                        <ApplicationHeader
                                            header="Business Information"
                                            subTitle="To get started, tell us about your business"
                                            progress={accountOpening.progress.businessCompletedProgress()}
                                        >
                                            {!accountOpening?.isCreator && (
                                                <div className="flex w-full flex-col items-start justify-start">
                                                    <BusinessInformationReview data={businessDetails} />
                                                </div>
                                            )}

                                            {accountOpening?.isCreator && (
                                                <div className="w-full">
                                                    <div className="flex w-full flex-col space-y-4">
                                                        <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                            <div className="w-full md:w-50%">
                                                                <FormInput
                                                                    type="text"
                                                                    placeholder="Legal Business Name"
                                                                    name="name"
                                                                    value={formik.values.name || ""}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                />
                                                            </div>
                                                            <div className="md:w-6/12">
                                                                <SingleSelectDropdown
                                                                    placeholder="Sector/Industry"
                                                                    options={accountOpening.options.industry.map((el) => {
                                                                        return {
                                                                            text: el.name,
                                                                            value: el.id,
                                                                        };
                                                                    })}
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            void formik.getFieldHelpers("industryId").setValue(value);
                                                                        }
                                                                    }}
                                                                    defaultValue={formik.values.industryId || undefined}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                    bigDropdown={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        {!props?.isUnregisteredBusiness && (
                                                            <div className="w-full">
                                                                <SingleSelectDropdown
                                                                    placeholder="Business Type"
                                                                    options={accountOpening.options.businessCategory.map((el) => {
                                                                        return {
                                                                            text: el.name,
                                                                            value: el.id,
                                                                        };
                                                                    })}
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            void formik.getFieldHelpers("registrationCategory").setValue(value);
                                                                        }
                                                                    }}
                                                                    defaultValue={formik.values.registrationCategory || undefined}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                    bigDropdown={false}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="w-full">
                                                            <FormInput
                                                                type="textarea"
                                                                placeholder="Describe Your Business"
                                                                name="businessNature"
                                                                value={formik.values.businessNature}
                                                                isDisabled={!accountOpening.isCreator}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </ApplicationHeader>
                                        {!accountOpening?.isCreator && (
                                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                    <ButtonComp
                                                        type="button"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        func={() => navigate(locationState?.from || Routes.ACCOUNT_OPENING.STATUS)}
                                                        fullWidth
                                                    >
                                                        <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                                    </ButtonComp>
                                                </div>
                                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                        <ButtonComp
                                                            type="button"
                                                            color="black"
                                                            ripple="light"
                                                            buttonType="primary"
                                                            func={() => navigate(Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT)}
                                                            fullWidth
                                                        >
                                                            <span>{props?.isUnregisteredBusiness ? "Save And Continue" : "Continue"}</span>
                                                        </ButtonComp>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {accountOpening.isCreator && (
                                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                    <ButtonComp
                                                        type="button"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        func={() => navigate(locationState?.from || Routes.ACCOUNT_OPENING.STATUS)}
                                                        fullWidth
                                                    >
                                                        {locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? "Back to Review" : "Back"}
                                                    </ButtonComp>
                                                </div>
                                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                    <ButtonComp
                                                        type={formik.dirty ? "submit" : "button"}
                                                        color="black"
                                                        ripple="light"
                                                        buttonType="primary"
                                                        isLoading={isBusinessInformationLoading}
                                                        func={() => {
                                                            !formik.dirty
                                                                ? navigate(
                                                                      {
                                                                          pathname: isRegistered
                                                                              ? Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT
                                                                              : Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DOCUMENT,
                                                                      },
                                                                      {
                                                                          state: {
                                                                              from: isRegistered
                                                                                  ? Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION
                                                                                  : Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS,
                                                                          },
                                                                      }
                                                                  )
                                                                : undefined;
                                                        }}
                                                        disable={
                                                            !!(
                                                                (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW && !formik.dirty) ||
                                                                !accountOpening.isCreator ||
                                                                formik.errors.name ||
                                                                formik.errors.registrationCategory ||
                                                                formik.errors.businessNature ||
                                                                formik.errors.industryId
                                                            )
                                                        }
                                                        fullWidth
                                                    >
                                                        {locationState?.from === Routes.ACCOUNT_OPENING.REVIEW
                                                            ? "Save changes"
                                                            : props?.isUnregisteredBusiness
                                                              ? "Save and Continue"
                                                              : "Continue"}
                                                    </ButtonComp>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {accountOpening?.isCreator && (
                                    <Hint
                                        hintType={HintType.GUIDE}
                                        listStyle={ListStyle.CHECK}
                                        firstList={getBusinessInformationHints(Object.keys(formik.values || []), Object.keys(formik.errors || []), [
                                            BusinessInformationFields.SCUML_NUMBER,
                                        ])}
                                    />
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default BusinessInformation;
