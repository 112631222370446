import {
    ExportTransactionRequest,
    ExportTransactionResponse,
    ExportZambiaTransactionsRequest,
    ExportZambiaTransactionsResponse,
    TransactionsListResponse,
} from "./transactionApi.types";
import { makeRequestDownloadFile, makeRequestWithSignal } from "../../../../helpers/request/makeRequest";

import Parsers from "../../../../utils/parsers";
import Transaction from "../../../../models/transaction";
import { TransactionFilterTypes } from "../../../../redux/transactions/slice/transactionsSlice.types";
import { downloadBlob } from "../../../../utils/blob";

let controller = new AbortController();

//get list of all transactions
export const transactions = async (data: TransactionFilterTypes): Promise<TransactionsListResponse> => {
    controller = new AbortController();
    const res = await makeRequestWithSignal("/main/transactions", data, controller.signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        groupSize: Parsers.number(res.groupSize),
        total: Parsers.number(res.total),
        transactions: Parsers.classObjectArray(res.transactions, Transaction),
    };
};

//export transactions as csv or pdf
export const exportTransaction = async (data: ExportTransactionRequest): Promise<ExportTransactionResponse> => {
    const res = await makeRequestDownloadFile("/main/transactions/export", data);
    if (res instanceof Blob) {
        downloadBlob(res, `Transactions.${data.type}`);
        return {
            success: true,
            message: "",
        };
    } else {
        throw res;
    }
};

export const exportZambiaTransactions = async (_data: ExportZambiaTransactionsRequest): Promise<ExportZambiaTransactionsResponse> => {
    controller = new AbortController();

    const res = await makeRequestWithSignal("/main/transactions/export/2", _data, controller.signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        eventName: Parsers.string(res.eventName),
        key: Parsers.string(res.key),
    };
};

export const abortTransactionTransfer = (): void => {
    controller.abort();
};
