import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import React from "react";
import ZambiaBusinessDetails from "../../../../../../../../../../models/account-opening/zambiaBusinessDetails";
import { useSelector } from "react-redux";

interface Props {
    data: ZambiaBusinessDetails | null;
}

function BusinessInformationReview(props: Props): JSX.Element {
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    return (
        <>
            {props.data && accountOpening && (
                <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-black-quin p-5">
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Legal Business Name</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data.name || "-"}</span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Describe your Business</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.businessNature || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Sector / Industry</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {accountOpening.options.industry.find((_industry) => _industry.id === Number(props.data?.industry?.id || 0))?.name || "-"}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export default BusinessInformationReview;
