import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../redux/rootReducer";
import { SpecificTerminalStageType } from "../../../../../redux/terminals/slice/terminalsSlice.types";
import TerminalSettings from "./Settings";
import {
    resetAllTerminalStageToSpecificTerminal,
    resetAllTerminalTransactionsData,
    setSpecificTerminalStage,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import SpecificTerminalTransactions from "../SpecificTerminalTransactions";
import TerminalTransactionSummary from "../../Elements/Card/TerminalSummary/TerminalTransactionSummary";
import TabButton from "../../../../../components/TabButton";
import TableFilter from "../../../../../components/Table/components/TableFilter";
import useSpecificTerminalTransactions from "../../Hooks/State/useSpecificTerminalTransactions";

function SpecificTerminal(): JSX.Element {
    const dispatch = useDispatch();

    const specificTerminalStage = useSelector((state: IRootState) => state.terminals.specificTerminalStage);
    const { filterOption, filter, handleClearFilter } = useSpecificTerminalTransactions();

    const handleGoToSpecificTerminalSettings = () => {
        dispatch(setSpecificTerminalStage(SpecificTerminalStageType.SETTINGS));
    };

    const handleGoToSpecificTerminalTransactions = () => {
        dispatch(setSpecificTerminalStage(SpecificTerminalStageType.TERMINAL_TRANSACTIONS));
    };

    useEffect(() => {
        return () => {
            dispatch(resetAllTerminalStageToSpecificTerminal());
            dispatch(resetAllTerminalTransactionsData());
        };
    }, []);

    return (
        <div className="w-full space-y-2">
            <TerminalTransactionSummary />
            <div className="flex w-full items-center justify-between pb-2">
                <TabButton
                    buttons={[
                        {
                            text: "Transactions",
                            onClick: handleGoToSpecificTerminalTransactions,
                            isActive: specificTerminalStage === SpecificTerminalStageType.TERMINAL_TRANSACTIONS,
                        },
                        {
                            text: "Settings",
                            onClick: handleGoToSpecificTerminalSettings,
                            isActive: specificTerminalStage === SpecificTerminalStageType.SETTINGS,
                        },
                    ]}
                />
                {specificTerminalStage === SpecificTerminalStageType.TERMINAL_TRANSACTIONS && (
                    <TableFilter
                        totalSelectedItems={(filter?.startDate ? 1 : 0) + (filter?.statuses?.length || 0) + (filter?.types?.length || 0)}
                        items={filterOption.items}
                        handleApplyFilter={filterOption.handleApplyFilter}
                        handleClearFilter={handleClearFilter}
                        placement="left"
                    />
                )}
            </div>

            {specificTerminalStage === SpecificTerminalStageType.TERMINAL_TRANSACTIONS && <SpecificTerminalTransactions />}
            {specificTerminalStage === SpecificTerminalStageType.SETTINGS && <TerminalSettings />}
        </div>
    );
}

export default SpecificTerminal;
