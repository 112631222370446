import { DirectorPageType, ExternalDirectorPageType, ExternalDirectorSubPageType } from "./service/director.constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect } from "react";

import DirectorApplicationDone from "../componentss/application-done";
import DirectorReview from "./components/director-review/DirectorReview";
import DirectorStatus from "./components/status";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import ExternalDirectorLayout from "./components/layout";
import ExternalFormLayout from "../componentss/layoutt";
import { IRootState } from "../../../../redux/rootReducer";
import IdentificationForm from "./components/identification-form/IdentificationForm";
import { InviteType } from "./service/externalApplication.types";
import LencoSpinner from "../../../../components/spinner";
import NextOfKinForm from "./components/next-of-kin-form/NextOfKinForm";
import { PageTitle } from "../../../../helpers/AppConstants";
import PersonalDetails from "./components/personal-details";
import { resetAllExternalApplicationState } from "../../../../redux/externalApplication/externalApplicationSlice";
import useExternalApplicationInit from "./hookss/useExternalApplicationInit";
import { useParams } from "react-router";

type DirectorUrlParams = {
    a: string;
    b: string;
    stage: ExternalDirectorSubPageType;
    section: ExternalDirectorPageType;
};

function DirectorForm(): JSX.Element {
    document.title = PageTitle.DIRECTORS_PAGE;

    const dispatch = useDispatch();

    const { a, b, stage, section } = useParams<DirectorUrlParams>();

    const { externalApplicationInitError, isExternalApplicationInitLoading, handleRest, handleNigeriaAccountManager, handleExternalApplicationInit } =
        useExternalApplicationInit();

    const externalLink = useSelector((state: IRootState) => state.externalApplication.externalLink);
    const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

    useLayoutEffect(() => {
        if (a && b) {
            void handleExternalApplicationInit({
                a,
                b,
                type: InviteType.S,
            });
            void handleNigeriaAccountManager();
        }
    }, [a, b]);

    useEffect(() => {
        return () => {
            handleRest();
            dispatch(resetAllExternalApplicationState());
        };
    }, []);

    return (
        <>
            <ExternalFormLayout url={externalLink || ""}>
                {isExternalApplicationInitLoading && (
                    <div className="flex h-full w-full items-center justify-center">
                        <LencoSpinner />
                    </div>
                )}
                {externalApplicationInitError && (
                    <div className="flex h-full w-full flex-col items-center justify-center">
                        <ErrorToast error={externalApplicationInitError} />
                    </div>
                )}
                {externalApplicationInit && !externalApplicationInitError && !isExternalApplicationInitLoading && (
                    <>
                        {!section ? (
                            <DirectorStatus />
                        ) : (
                            <>
                                {section === DirectorPageType.DONE && <DirectorApplicationDone url={externalLink || ""} />}
                                {section !== DirectorPageType.DONE && (
                                    <ExternalDirectorLayout section={section} stage={stage} url={externalLink || ""}>
                                        {section === DirectorPageType.PERSONAL && stage && <PersonalDetails stage={stage} />}
                                        {section === DirectorPageType.NEXT_OF_KIN && <NextOfKinForm />}
                                        {section === DirectorPageType.MEANS_OF_ID && <IdentificationForm />}
                                        {section === DirectorPageType.REVIEW && <DirectorReview />}
                                    </ExternalDirectorLayout>
                                )}
                            </>
                        )}
                    </>
                )}
            </ExternalFormLayout>
        </>
    );
}

export default DirectorForm;
