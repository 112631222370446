import {
    NigeriaApplicationSectionType,
    NigeriaApplicationStagesType,
    NigeriaRegisteredRefereeStages,
} from "../../../../../services/nigeria-application/nigeria-application.types";
import React, { useLayoutEffect } from "react";

import { GenericApplicationPageType } from "../../../../../services/application.constants";
import RefereesChoice from "./components/RefereesChoice";
import RefereesInvite from "./components/RefereesInvite";
import { useParams } from "react-router";
import useRefereeInit from "../../../../../Hooks/nigeria-application/registered-business/invite-referee/useRefereesInit";
import LencoSpinner from "../../../../../../../../components/spinner";

type ApplicationUrlParams = {
    stage: NigeriaApplicationStagesType | undefined;
    section: NigeriaApplicationSectionType | GenericApplicationPageType;
};

function InviteReferees(): JSX.Element {
    const { stage } = useParams<ApplicationUrlParams>();

    const { isRefereesInitLoading, handleReset, handleRefereesInit } = useRefereeInit();

    useLayoutEffect(() => {
        void handleRefereesInit();
        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            {isRefereesInitLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {!isRefereesInitLoading && (
                <div className="relative h-full w-full">
                    {stage === NigeriaRegisteredRefereeStages.REFEREE_CHOICE && <RefereesChoice />}
                    {stage === NigeriaRegisteredRefereeStages.REFEREE_DETAILS && <RefereesInvite />}
                </div>
            )}
        </>
    );
}

export default InviteReferees;
