import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";
import { makeRequestDownloadFile, makeRequestUploadFileWithSignal, makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";
import ApiAccess from "../../../../../models/zambia/lencoPay/apiAccess";
import { toFormData } from "../../../../../utils/converters";
import Parsers from "../../../../../utils/parsers";
import {
    AddZambiaApiAccessKeyAccountAccessRequest,
    AddZambiaApiAccessKeyAccountAccessResponse,
    AddZambiaApiAccessKeyWhitelistIPAddressRequest,
    AddZambiaApiAccessKeyWhitelistIPAddressResponse,
    DownloadZambiaApiAccessKeyLogoRequest,
    RemoveZambiaApiAccessKeyAccountAccessRequest,
    RemoveZambiaApiAccessKeyAccountAccessResponse,
    RemoveZambiaApiAccessKeyLogoRequest,
    RemoveZambiaApiAccessKeyLogoResponse,
    RemoveZambiaApiAccessKeyWebhookUrlRequest,
    RemoveZambiaApiAccessKeyWebhookUrlResponse,
    RemoveZambiaApiAccessKeyWhitelistIPAddressRequest,
    RemoveZambiaApiAccessKeyWhitelistIPAddressResponse,
    UpdateZambiaApiAccessKeyCollectionFeeSourceRequest,
    UpdateZambiaApiAccessKeyCollectionFeeSourceResponse,
    UpdateZambiaApiAccessKeyScopeRequest,
    UpdateZambiaApiAccessKeyScopeResponse,
    UpdateZambiaApiAccessKeySettlementAccountRequest,
    UpdateZambiaApiAccessKeySettlementAccountResponse,
    UpdateZambiaApiAccessKeyWebhookUrlRequest,
    UpdateZambiaApiAccessKeyWebhookUrlResponse,
    UpdateZambiaApiAccessKeyWidgetColorThemeRequest,
    UpdateZambiaApiAccessKeyWidgetColorThemeResponse,
    UploadZambiaApiAccessKeyLogoRequest,
    UploadZambiaApiAccessKeyLogoResponse,
} from "./apiAccessKeySettingsApi.types";

export enum ApiAccessKeySettingsRequestType {
    UPDATE_WEBHOOK_URL = "api-access-key-settings.update-webhook-url",
    REMOVE_WEBHOOK_URL = "api-access-key-settings.remove-webhook-url",
    ADD_WHITELIST_IP_ADDRESS = "api-access-key-settings.add-whitelist-ip-address",
    REMOVE_WHITELIST_IP_ADDRESS = "api-access-key-settings.remove-whitelist-ip-address",
    UPLOAD_LOGO = "api-access-key-settings.upload-zambia-api-access-key-logo",
    REMOVE_LOGO = "api-access-key-settings.remove-zambia-api-access-key-logo",
    UPDATE_SETTLEMENT_ACCOUNT = "api-access-key-settings.remove-zambia-api-access-key-update-settlement-account",
    UPDATE_SCOPE = "api-access-key-settings.remove-zambia-api-access-key-update-scope",
    ADD_ACCOUNT_ACCESS = "api-access-key-settings.remove-zambia-api-access-key-add-account-access",
    REMOVE_ACCOUNT_ACCESS = "api-access-key-settings.remove-zambia-api-access-key-remove-account-access",
}

export const updateZambiaApiAccessKeyWebhookUrl = async (
    _data: UpdateZambiaApiAccessKeyWebhookUrlRequest
): Promise<UpdateZambiaApiAccessKeyWebhookUrlResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.UPDATE_WEBHOOK_URL);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/webhook-url/update", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const removeZambiaApiAccessKeyWebhookUrl = async (
    _data: RemoveZambiaApiAccessKeyWebhookUrlRequest
): Promise<RemoveZambiaApiAccessKeyWebhookUrlResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.REMOVE_WEBHOOK_URL);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/webhook-url/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const addZambiaApiAccessKeyWhitelistIPAddress = async (
    _data: AddZambiaApiAccessKeyWhitelistIPAddressRequest
): Promise<AddZambiaApiAccessKeyWhitelistIPAddressResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.ADD_WHITELIST_IP_ADDRESS);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/ip-whitelist/add", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const removeZambiaApiAccessKeyWhitelistIPAddress = async (
    _data: RemoveZambiaApiAccessKeyWhitelistIPAddressRequest
): Promise<RemoveZambiaApiAccessKeyWhitelistIPAddressResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.REMOVE_WHITELIST_IP_ADDRESS);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/ip-whitelist/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export async function uploadZambiaApiAccessKeyLogoApi(_data: UploadZambiaApiAccessKeyLogoRequest): Promise<UploadZambiaApiAccessKeyLogoResponse> {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.UPLOAD_LOGO);
    const res = await makeRequestUploadFileWithSignal("/main/lenco-pay/api-access-keys/logo/upload", toFormData(_data), signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
}

export const removeZambiaApiAccessKeyLogoApi = async (_data: RemoveZambiaApiAccessKeyLogoRequest): Promise<RemoveZambiaApiAccessKeyLogoResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.REMOVE_WHITELIST_IP_ADDRESS);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/logo/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const downloadZambiaApiAccessKeyLogoApi = async (_data: DownloadZambiaApiAccessKeyLogoRequest): Promise<Blob> => {
    // const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOWNLOAD_TO_SIGN);
    const res = await makeRequestDownloadFile("/main/lenco-pay/api-access-keys/logo/download", _data);
    if (res instanceof Error) {
        throw res;
    }
    return res;
};

export const updateZambiaApiAccessKeyWidgetColorThemeApi = async (
    _data: UpdateZambiaApiAccessKeyWidgetColorThemeRequest
): Promise<UpdateZambiaApiAccessKeyWidgetColorThemeResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.UPDATE_WEBHOOK_URL);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/color-theme/update", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const updateZambiaApiAccessKeySettlementAccountApi = async (
    _data: UpdateZambiaApiAccessKeySettlementAccountRequest
): Promise<UpdateZambiaApiAccessKeySettlementAccountResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.UPDATE_SETTLEMENT_ACCOUNT);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/settlement-account/update", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const updateZambiaApiAccessKeyScopeApi = async (
    _data: UpdateZambiaApiAccessKeyScopeRequest
): Promise<UpdateZambiaApiAccessKeyScopeResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.UPDATE_SCOPE);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/scopes/update", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const addZambiaApiAccessKeyAccountAccessApi = async (
    _data: AddZambiaApiAccessKeyAccountAccessRequest
): Promise<AddZambiaApiAccessKeyAccountAccessResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.ADD_ACCOUNT_ACCESS);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/account-access/add", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const removeZambiaApiAccessKeyAccountAccessApi = async (
    _data: RemoveZambiaApiAccessKeyAccountAccessRequest
): Promise<RemoveZambiaApiAccessKeyAccountAccessResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.REMOVE_ACCOUNT_ACCESS);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/account-access/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export const updateZambiaApiAccessKeyCollectionFeeSourceApi = async (
    _data: UpdateZambiaApiAccessKeyCollectionFeeSourceRequest
): Promise<UpdateZambiaApiAccessKeyCollectionFeeSourceResponse> => {
    const signal = getAbortControllerSignal(ApiAccessKeySettingsRequestType.UPDATE_SCOPE);
    const res = await makeRequestWithSignal("/main/lenco-pay/api-access-keys/collection-fee-source/update", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        apiAccessKey: Parsers.classObjectNonNullable(res.apiAccessKey, ApiAccess),
    };
};

export function abortApiAccessKeySettingsRequest(type: ApiAccessKeySettingsRequestType): void {
    abortRequest(type);
}

export function abortAllApiAccessKeySettingsRequests(): void {
    Object.values(ApiAccessKeySettingsRequestType).forEach((type) => abortRequest(type));
}
