import React from "react";

export interface DropdownLinkProps {
    big?: boolean;
    color?: "red";
    isLink?: boolean;
    noHover?: boolean | (() => boolean);
    children: React.ReactNode;
    dataType?: string;
    redHover?: boolean;
    noPadding?: boolean;
    fitHeight?: boolean;

    onClick?: (e: React.MouseEvent) => void;
}

const getClass = (props: DropdownLinkProps): string => {
    const classes: string[] = [
        "flex justify-start items-center",
        "w-full text-black-tertiary font-normal antialiased",
        "transition-all ease-in-out duration-300",
        "overflow-ellipsis overflow-hidden whitespace-nowrap",
    ];

    classes.push(!props.noPadding ? "px-4" : "");
    classes.push(props.fitHeight ? "h-fit" : props.big ? "h-14" : "h-10");
    classes.push(props.noHover ? "cursor-default pointer-events-none" : "cursor-pointer pointer-events-auto");

    classes.push(
        props.noHover
            ? props.color === "red"
                ? "bg-error-backdrop text-error"
                : "bg-gray-100 text-black-secondary"
            : props.redHover
              ? "hover:bg-error-backdrop hover:text-error"
              : props.isLink
                ? "hover:bg-blue-senary hover:text-blue"
                : "hover:bg-grey-dropdown"
    );

    return classes.join(" ");
};

function DropdownLink(props: DropdownLinkProps): JSX.Element {
    return (
        <div className={getClass(props)} onClick={(e) => props.onClick && props.onClick(e)} data-type={props.dataType || "dropdown"}>
            {props.children}
        </div>
    );
}

export default DropdownLink;
