import ConfirmModal from "../../../../../components/modal/confirm-modal";
import useDeleteRecipient from "../../hooks/state/SavedRecipient/useDeleteRecipient";

function DeleteRecipientModal(): JSX.Element {
    const data = useDeleteRecipient();

    return (
        <ConfirmModal
            active={data.isModalOpen}
            onClose={data.closeModal}
            isSubmitting={data.isSubmitting}
            onConfirm={() => {
                if (data.recipient) {
                    void data.handleDeleteRecipient(data.recipient);
                }
            }}
            errorMessage={data.errorMessage}
            header="Delete Recipient?"
            confirmColor="red"
            cancelText="Cancel"
            confirmText="Remove"
        >
            {data.recipient && (
                <span className="w-full whitespace-normal break-words text-left text-base font-normal">
                    Remove {data.recipient.customerAccount.accountName} from recipients list?
                </span>
            )}
        </ConfirmModal>
    );
}

export default DeleteRecipientModal;
