import { useCallback, useState } from "react";

import { BillPurchaseInitRequest } from "../../../../services/billPayment/billPaymentApi.types";
import { billPurchaseInit } from "../../../../services/billPayment/billPaymentApi";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { setAirtimePurchaseResponse } from "../../../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import { useDispatch } from "react-redux";

interface UseAirtimePurchaseInterface {
    isAirtimePurchaseLoading: boolean;
    airtimePurchaseError: boolean;
    handleAirtimePurchase: (_data: BillPurchaseInitRequest) => Promise<void>;
}

function useAirtimePurchase(): UseAirtimePurchaseInterface {
    const dispatch = useDispatch();
    const [isAirtimePurchaseLoading, setIsAirtimePurchaseLoading] = useState(false);
    const [airtimePurchaseError, setAirtimePurchaseError] = useState<boolean>(false);

    const handleAirtimePurchase = useCallback(
        async (_data: BillPurchaseInitRequest) => {
            try {
                setIsAirtimePurchaseLoading(true);
                setAirtimePurchaseError(false);
                const res = await billPurchaseInit(_data);
                dispatch(setAirtimePurchaseResponse(res));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setAirtimePurchaseError(true);
            } finally {
                setIsAirtimePurchaseLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isAirtimePurchaseLoading,
        airtimePurchaseError,
        handleAirtimePurchase,
    };
}

export default useAirtimePurchase;
