import { DirectorFormOptions } from "../../../service/directorForm.types";
import NextOfKin from "../../../../../../../models/account-opening/nextOfKin";
import React from "react";

interface Props {
    data: NextOfKin | null;
    options: DirectorFormOptions;
}

function DirectorNextOfKinReview(props: Props): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-black-quin p-5">
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Full Name</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data?.name || "-"}</span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Phone</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data?.phone || "-"}</span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Gender</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data?.gender || "-"}</span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Relationship</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                        {props.data?.relationship &&
                            !props.data?.otherRelationshipText &&
                            (props.options.kinRelationship.find((_relationship) => _relationship.id === props.data?.relationship)?.name || "")}
                        {!props.data?.relationship && (props.data?.otherRelationshipText || "-")}
                    </span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Address</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data?.address || "-"}</span>
                </div>
            </div>
        </>
    );
}

export default DirectorNextOfKinReview;
