import { setDataPurchaseAmount } from "../../../../../../../redux/payments/dataPurchase/slice/dataPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseDataPurchaseAmountInterface {
    handleAmountChange: (_amount: string) => void;
}

function useDataPurchaseAmount(): UseDataPurchaseAmountInterface {
    const dispatch = useDispatch();

    const handleAmountChange = useCallback((_amount: string) => {
        dispatch(setDataPurchaseAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useDataPurchaseAmount;
