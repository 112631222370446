import { ActivateCardDTO, CardSectionEnum, GetCardPinRequest } from "../Services/cardsApi.types";
import { CardRequestType, abortCardRequest, activateCard, getCardPin } from "../Services/cardsApi";
import { addToActiveIDintNotGetCardRequest, setActiveCardTraySections, updateCard } from "../../../../redux/cards/slice/cardsSlice";
import { useCallback, useState } from "react";

import Card from "../../../../models/card";
import { RequestCancelledError } from "../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { mainInitAddCard } from "../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

interface UseCardsInterface {
    cardPin: string;
    getCardPinError: string | null;
    isGetCardPinLoading: boolean;

    activateCardError: string | null;
    isActivateCardDone: boolean;
    isActivateCardLoading: boolean;

    handleReset: () => void;
    onIDintGetTheCard: (requestId: string) => void;
    handleActivateCard: (_data: ActivateCardDTO) => Promise<void>;
    handleGetCardPinCard: (_data: GetCardPinRequest) => Promise<void>;
}

const useActivateCard = (): UseCardsInterface => {
    const dispatch = useDispatch();
    const [activateCardError, setActivateCardError] = useState<string | null>(null);
    const [isActivateCardDone, setIsActivateCardDone] = useState<boolean>(false);
    const [isActivateCardLoading, setIsActivateCardLoading] = useState<boolean>(false);

    const [cardPin, setCardPin] = useState<string>("");
    const [getCardPinError, setGetCardPinError] = useState<string | null>(null);
    const [isGetCardPinLoading, setIsGetCardPinLoading] = useState<boolean>(false);

    const onIDintGetTheCard = useCallback(
        (id: string) => {
            try {
                dispatch(addToActiveIDintNotGetCardRequest(id));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setActivateCardError(errorMessage);
            } finally {
                setIsActivateCardLoading(false);
            }
        },
        [dispatch]
    );

    const handleActivateCard = useCallback(
        async (_data: ActivateCardDTO) => {
            try {
                setIsActivateCardDone(false);
                setActivateCardError(null);
                setIsActivateCardLoading(true);
                const res = await activateCard(_data);
                setIsActivateCardDone(true);
                dispatch(mainInitAddCard(res.cardData.card as Card));
                dispatch(updateCard(res.cardData));
                dispatch(messageTrue({ message: "Card Activated Successfully" }));
                dispatch(setActiveCardTraySections([CardSectionEnum.CREDIT_CARD_SECTION, CardSectionEnum.ACTIVATE_CARD]));
                void handleGetCardPinCard({ createRequestId: _data.createRequestId });
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setActivateCardError(errorMessage);
            } finally {
                setIsActivateCardLoading(false);
            }
        },
        [dispatch]
    );

    const handleGetCardPinCard = useCallback(
        async (_data: GetCardPinRequest) => {
            try {
                setCardPin("");
                setGetCardPinError(null);
                setIsGetCardPinLoading(true);
                const res = await getCardPin(_data);
                setCardPin(res.defaultPin);
                // dispatch(messageTrue({message: "Card Activated Successfully"}));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setGetCardPinError(errorMessage);
            } finally {
                setIsGetCardPinLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsActivateCardDone(false);
        setActivateCardError(null);
        setIsActivateCardLoading(false);
        abortCardRequest(CardRequestType.ACTIVATE_CARD);
    }, []);

    return {
        cardPin,
        getCardPinError,
        isGetCardPinLoading,
        activateCardError,
        isActivateCardDone,
        isActivateCardLoading,
        handleReset,
        onIDintGetTheCard,
        handleActivateCard,
        handleGetCardPinCard,
    };
};

export default useActivateCard;
