import { CardAccessForm, CardUsageStage } from "../../../Types";
import { CardType, CardWalletType } from "../../../../../../models/card.constants";
import { FieldHelperProps, FormikErrors } from "formik";

import { ReactComponent as CardChip } from "../../../.././../../assets/svg/Cards/Chip.svg";
import { CorporateAccountType } from "../../../../../../models/corporate.constants";
import CurrencyCode from "../../../../../../components/currency-code";
import FormInput from "../../../../../../components/inputs/FormInput";
import LencoButton from "../../../../../../components/button/button";
import MessageToast from "../../../../../../components/message-toast";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import { ReactComponent as PlainCard } from "../../../.././../../assets/svg/Cards/PlanCard.svg";
import RadioButton from "../../../../../../components/radio-button";
import UserAccountSelect from "../../../../../../components/user-account-select";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useEffect } from "react";

interface ICardCustomizationProps {
    values: CardAccessForm;
    errors: FormikErrors<CardAccessForm>;
    getFieldHelpers: <Value>(name: string) => FieldHelperProps<Value>;
    onNext: (stage: CardUsageStage) => void;
}

function CardCustomization({ getFieldHelpers, values, onNext, errors }: ICardCustomizationProps): JSX.Element {
    const accounts = useAppSelector((state) => state.init.main?.companyDetails.accounts);
    const mainAccount = useAppSelector(
        (state) => state.init.main?.companyDetails.company.shortName || state.init.main?.companyDetails.company.name || ""
    );
    const newCards = useAppSelector((state) => state.cards.newCards);
    const firstRequest = newCards.length < 1;
    const isStarterAccount = useAppSelector((state) => state.init.main?.companyDetails.company.type === CorporateAccountType.STARTER);

    useEffect(() => {
        if (accounts && accounts?.length < 2) {
            getFieldHelpers("debitAccountId").setValue(accounts[0].id);
            if (values.walletType === CardWalletType.LINKED_TO_ACCOUNT) {
                getFieldHelpers("linkedAccountId").setValue(accounts[0].id);
            }
        }
    }, [accounts]);

    return (
        <>
            <ModalBody>
                <div className="flex flex-col items-start space-y-4 text-base font-normal lg:items-center lg:px-0">
                    <div className="z-30 flex w-full flex-col space-y-4">
                        <UserAccountSelect
                            label="Account to Debit"
                            value={values.debitAccountId || null}
                            options={accounts || null}
                            onClick={(value) => {
                                getFieldHelpers("debitAccountId").setValue(value);
                                if (values.walletType === CardWalletType.LINKED_TO_ACCOUNT) {
                                    getFieldHelpers("linkedAccountId").setValue(value);
                                }
                            }}
                            size="lg"
                            isDisabled={!!(accounts && accounts.length < 2)}
                            showBalance
                        />
                        <div className="w-full">
                            <FormInput placeholder="Name on Card" name="name" type="text" inputSize="lg" />
                            <div className="flex w-full items-center justify-between">
                                {values.name.length > 0 && (
                                    <span className="pt-2 text-xs font-normal text-black-tertiary">
                                        {accounts?.find((acct) => acct.id === values.debitAccountId)?.lencoNameMin ||
                                            mainAccount ||
                                            "Main Account Name"}{" "}
                                        - {values.name || "Sub-account Name"}
                                    </span>
                                )}
                                {values.name.length > 15 ? (
                                    <span className="px-4 pt-2 text-xs font-normal text-error">{22 - values.name.length}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex justify-between space-x-4">
                            <div
                                className={
                                    "w-full cursor-pointer rounded-lg border p-4 " +
                                    `${values.type === CardType.NGN_PHYSICAL ? "border-blue-secondary" : "border-grey"}`
                                }
                            >
                                <RadioButton
                                    id="physicalCard"
                                    text={
                                        <span className={`${values.type === CardType.NGN_PHYSICAL ? "text-blue-secondary" : "text-grey"}`}>
                                            Physical Card
                                        </span>
                                    }
                                    checked={values.type === CardType.NGN_PHYSICAL}
                                    func={() => {
                                        getFieldHelpers("type").setValue(CardType.NGN_PHYSICAL);
                                    }}
                                    readOnly={true}
                                    hideRadioButton
                                >
                                    <PlainCard />
                                </RadioButton>
                            </div>
                            <div className="w-full cursor-pointer rounded-lg border border-grey p-4 text-black-quin">
                                <RadioButton id="virtualCard" text="Virtual Card" readOnly={true} isDisabled={true} hideRadioButton>
                                    <CardChip className={`${"fill-grey"}`} />
                                </RadioButton>
                            </div>
                        </div>
                    </div>
                </div>{" "}
            </ModalBody>

            <>
                <div className="my-5 flex items-center justify-center space-x-2">
                    {!isStarterAccount && firstRequest && (
                        <MessageToast
                            message={
                                <span>
                                    Issuing Cost is <span className="font-bold">FREE</span> (₦0.00) for your first card
                                </span>
                            }
                            className="mx-auto w-max lg:w-full"
                            type="info"
                            fullWidth={false}
                            size="sm"
                        />
                    )}
                    {(!firstRequest || isStarterAccount) && (
                        <MessageToast
                            message={
                                <span>
                                    Card Cost is <span className="font-bold">{<CurrencyCode />}1,000.00 </span> only
                                </span>
                            }
                            className="mx-auto w-max lg:w-full"
                            type="info"
                            fullWidth={false}
                            size="sm"
                        />
                    )}
                </div>
                <ModalFooter>
                    <>
                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                            <LencoButton
                                onClick={() => onNext(CardUsageStage.USAGE_DESCRIPTION)}
                                type="button"
                                color="primary"
                                size="sm"
                                variant="bordered"
                                isText
                            >
                                <span className="normal-case tracking-wider">Back</span>
                            </LencoButton>
                        </div>
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <LencoButton
                                type="button"
                                color="primary"
                                size="sm"
                                variant="solid"
                                isText
                                isDisabled={!!errors.name || !!errors.debitAccountId}
                                onClick={() => onNext(CardUsageStage.HOLDER)}
                            >
                                <span className="normal-case tracking-wider">Proceed</span>
                            </LencoButton>
                        </div>
                    </>
                </ModalFooter>
            </>
        </>
    );
}

export default CardCustomization;
