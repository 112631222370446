import AvatarDetails from "../../../avatar-details";
import BankAccount from "../../../../models/bankAccount";
import Card from "../../../../models/card";
import Currency from "../../../../models/currency";
import CustomerAccount from "../../../../models/customerAccount";
import Money from "../../../money";
import UserAccount from "../../../../models/userAccount";
import getBankName from "../../../../helpers/getBankName";
import getInitials from "../../../../helpers/get-initials";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import titleCase from "../../../../hooks/titleCase";

interface Props {
    data: UserAccount | CustomerAccount | Card | BankAccount;
    size?: "xs" | "2xs" | "sm" | "md" | "lg";
    message?: string;
    dataType?: string;
    currency?: Currency | undefined | null;
    avatarColor?: "white" | "grey";
    cardBalance?: number;
    showBalance?: boolean;
}

function AllAccountDetails(props: Props): JSX.Element {
    return (
        <>
            <div className="flex w-full items-center justify-between space-x-4">
                <AvatarDetails
                    size={props.size}
                    initials={getInitials(
                        (props.data instanceof BankAccount && props.data.accountName) ||
                            (props.data instanceof UserAccount && props.data.accountName) ||
                            (props.data instanceof CustomerAccount && props.data.singleName) ||
                            (props.data instanceof Card && props.data.name) ||
                            ""
                    )}
                    color={props.avatarColor}
                    dataType={props.dataType}
                    title={
                        <div className="flex items-center justify-start space-x-3" data-type={props.dataType}>
                            <p className="text-sm font-medium text-black" data-type={props.dataType}>
                                {titleCase(
                                    (props.data instanceof BankAccount && props.data.accountName) ||
                                        (props.data instanceof UserAccount && props.data.accountName) ||
                                        (props.data instanceof CustomerAccount && props.data.singleName) ||
                                        (props.data instanceof Card && props.data.name) ||
                                        ""
                                )}
                            </p>
                        </div>
                    }
                    subtitle={
                        props.message ? (
                            <div className="flex flex-row items-center justify-start space-x-2 text-xs" data-type={props.dataType}>
                                {props.message}
                            </div>
                        ) : (
                            <>
                                {props.showBalance &&
                                    props.data instanceof UserAccount &&
                                    !isNullOrUndefined(props.data instanceof UserAccount && props.data.balance) && (
                                        <div className="flex flex-row items-center justify-start space-x-2 text-xs" data-type="dropdown">
                                            <Money amount={(props.data instanceof UserAccount && props.data.balance) || 0} />
                                        </div>
                                    )}
                                {!props.showBalance && (props.data instanceof UserAccount || props.data instanceof CustomerAccount) && (
                                    <div className="flex flex-row items-center justify-start space-x-2 text-xs">
                                        <span>{props.data.bankAccount && props.data.bankAccount.accountNumber} </span>
                                        <div>
                                            <div className="h-1 w-1 rounded-full bg-black-tertiary" />
                                        </div>
                                        <div>{getBankName(props.data.bankAccount?.bankCode)}</div>
                                    </div>
                                )}
                                {!props.showBalance && props.data instanceof BankAccount && (
                                    <div className="flex flex-row items-center justify-start space-x-2 text-xs">
                                        <span>{props.data && props.data.accountNumber} </span>
                                        <div>
                                            <div className="h-1 w-1 rounded-full bg-black-tertiary" />
                                        </div>
                                        <div>{getBankName(props.data?.bankCode)}</div>
                                    </div>
                                )}
                                {props.data instanceof Card && (
                                    <>
                                        {!!props.cardBalance && (
                                            <div className="text-xs">
                                                <Money amount={props.cardBalance} />
                                            </div>
                                        )}
                                        {!props.cardBalance && (
                                            <div className="w-max text-xs">
                                                <span>••••{props.data.maskedPan.slice(-4)}</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )
                    }
                />
                <div>
                    {props.data instanceof Card && (
                        <div
                            className={
                                "flex h-5 items-center justify-center rounded-[4px] border border-grey bg-grey-tertiary px-2 " +
                                ` ${props.avatarColor === "white" ? "bg-white" : ""}` +
                                ` ${isNullOrUndefined(props.avatarColor) || props.avatarColor === "grey" ? "bg-grey-tertiary" : ""}`
                            }
                            data-type={props.dataType}
                        >
                            <p className="text-xs font-medium text-black-secondary" data-type={props.dataType}>
                                Card
                            </p>
                        </div>
                    )}
                    {props.data instanceof UserAccount && props.data.isMain && (
                        <div
                            className={
                                "flex h-5 items-center justify-center rounded-[4px] border border-grey bg-grey-tertiary px-2 " +
                                ` ${props.avatarColor === "white" ? "bg-white" : ""}` +
                                ` ${isNullOrUndefined(props.avatarColor) || props.avatarColor === "grey" ? "bg-grey-tertiary" : ""}`
                            }
                            data-type={props.dataType}
                        >
                            <p className="text-xs font-medium text-black-secondary" data-type={props.dataType}>
                                Main
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AllAccountDetails;
