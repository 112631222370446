import ApiKeys from "./Components/ApiKeys";
import ApiSettingsLayout from "./Components/Layout";
import { IRootState } from "../../../../../../redux/rootReducer";
import React from "react";
import Setup from "./Components/Setup";
import Webhook from "./Components/Webhooks";
import WhitelistedIpAddress from "./Components/WhitelistedIpAddress";
import Widgets from "./Components/Widgets";
import { ZambiaApiAccessKeySettingsTab } from "../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice.types";
import { useSelector } from "react-redux";

function ApiSettings(): JSX.Element {
    const apiAccessKeySettingTab = useSelector((state: IRootState) => state.zambiaApiAccessKeys.apiAccessKeySettingTab);

    return (
        <>
            <ApiSettingsLayout>
                <div>
                    {apiAccessKeySettingTab === ZambiaApiAccessKeySettingsTab.API_KEYS && <ApiKeys />}
                    {apiAccessKeySettingTab === ZambiaApiAccessKeySettingsTab.WEBHOOK && <Webhook />}
                    {apiAccessKeySettingTab === ZambiaApiAccessKeySettingsTab.WHITELISTED_IP_ADDRESS && <WhitelistedIpAddress />}
                    {apiAccessKeySettingTab === ZambiaApiAccessKeySettingsTab.WIDGETS && <Widgets />}
                    {apiAccessKeySettingTab === ZambiaApiAccessKeySettingsTab.SETUP && <Setup />}
                </div>
            </ApiSettingsLayout>
        </>
    );
}

export default ApiSettings;
