import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { TransactionFilterTypes } from "../../../../../redux/transactions/slice/transactionsSlice.types";
import { TransactionsListResponse } from "../../../Transactions/Services/transactionApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { setMultipleTransactions } from "../../../../../redux/transaction/slice/transactionSlice";
import { transactions } from "../../../Transactions/Services/transactionApi";
import { useDispatch } from "react-redux";

interface UseCardAssign {
    recentCardTransactions: TransactionsListResponse | null;
    recentCardTransactionsError: string | null;
    isRecentCardTransactionLoading: boolean;

    handleReset: () => void;
    handleGetTransactions: (_data: TransactionFilterTypes) => void;
}

const useRecentCardTransactions = (): UseCardAssign => {
    const dispatch = useDispatch();

    const [recentCardTransactions, setRecentCardTransactions] = useState<TransactionsListResponse | null>(null);
    const [recentCardTransactionsError, setRecentCardTransactionsError] = useState<string | null>(null);
    const [isRecentCardTransactionLoading, setIsRecentCardTransactionLoading] = useState<boolean>(false);

    const handleGetTransactions = useCallback(
        async (_data: TransactionFilterTypes) => {
            try {
                setRecentCardTransactions(null);
                setRecentCardTransactionsError(null);
                setIsRecentCardTransactionLoading(true);
                const res = await transactions(_data);
                setRecentCardTransactions(res);
                dispatch(setMultipleTransactions(res.transactions));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRecentCardTransactionsError(errorMessage);
            } finally {
                setIsRecentCardTransactionLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setRecentCardTransactionsError(null);
        setIsRecentCardTransactionLoading(false);
        setRecentCardTransactions(null);
    }, []);

    return {
        recentCardTransactions,
        recentCardTransactionsError,
        isRecentCardTransactionLoading,

        handleReset,
        handleGetTransactions,
    };
};

export default useRecentCardTransactions;
