import React, { useEffect, useState } from "react";

import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import Modal from "../../../../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../../../../components/modal/modal-header";
import OtpInputForm from "../../../../../../../../../components/otp";
import useMakePayment from "../../../../../../Hooks/State/Zambia/MobileMoney/useMakePayment";

interface Props {
    keyId: string;
    recipientPhoneNumber: string;
    handleBack: () => void;
    handleNext: () => void;
    handleClose: () => void;
}

function MobileMoneyMakePayment(props: Props): JSX.Element {
    // const [resendOtpText, setResendOtpText] = useState(false);
    // const {minutes, seconds, isInitOTPLoading, handleOTPInit, handleResendOTPInit} = useOtpInit({});

    const [otp, setOtp] = useState("");

    const { isMakePaymentLoading, makePaymentError, handleMakePayment } = useMakePayment({
        onComplete: () => props.handleNext(),
    });

    // useLayoutEffect(() => {
    // if (isAuthAppSetup) return;
    // handleOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
    // }, [handleOTPInit, isAuthAppSetup]);

    useEffect(() => {
        if (otp.length < 6) return;
        void handleMakePayment({
            key: props.keyId,
            otp: otp,
        });
    }, [otp]);

    const handleOtpChange = (_otp: string) => {
        setOtp(_otp);
    };

    return (
        <>
            <Modal size="md" active toggler={props.handleClose}>
                <ModalHeader
                    onClose={props.handleClose}
                    subTitle={`Enter the OTP sent to your phone number ending with ••••${props.recipientPhoneNumber.slice(-4)} to authorize payment
								`}
                >
                    Authorize Payment
                </ModalHeader>

                <ModalBody>
                    {/* {!isAuthAppSetup && isInitOTPLoading && (
										<>
											<div className="flex flex-row items-center justify-start space-x-4 text-sm">
												<TailSpin color="#5466F9" height={20} width={20} />
												<span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
											</div>
										</>
									)} */}
                    {/* {!isInitOTPLoading && ( */}
                    <>
                        <div className="flex w-full max-w-sm flex-col space-y-8">
                            <div className="mx-auto flex w-full flex-col">
                                <OtpInputForm value={otp || undefined} onChange={handleOtpChange} isError={makePaymentError} />
                                {/* {!isAuthAppSetup && (
														<div className="flex items-start justify-start pt-4">
															<div>
																{minutes === 0 && seconds === 0 ? (
																	<div>
																		<span
																			className="cursor-pointer  text-sm text-blue"
																			onClick={() => {
																				handleResendOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
																				setResendOtpText(true);
																			}}
																		>
																			Resend OTP
																		</span>
																	</div>
																) : (
																	<span className="cursor-not-allowed  text-sm text-black-tertiary">
																		Resend OTP in{" "}
																		<span className="text-black-secondary">
																			{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
																		</span>
																	</span>
																)}
															</div>
														</div>
													)} */}
                            </div>
                        </div>
                    </>
                    {/* )} */}
                </ModalBody>
                <ModalFooter>
                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                            <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleBack} fullWidth>
                                <span>Back</span>
                            </ButtonComp>
                        </div>
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <ButtonComp
                                type="submit"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                isLoading={isMakePaymentLoading}
                                disable={otp.length < 6 || isMakePaymentLoading}
                                func={() =>
                                    void handleMakePayment({
                                        key: props.keyId,
                                        otp: otp,
                                    })
                                }
                            >
                                <span>Authorize</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default MobileMoneyMakePayment;
