import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";
import ZambiaSettlement from "../../../../../models/zambia/zambiaSettlement";
import Parsers from "../../../../../utils/parsers";
import {
    ExportZambiaCollectionsSettlementsRequest,
    ExportZambiaCollectionsSettlementsResponse,
    ZambiaCollectionsSettlementRequest,
    ZambiaCollectionsSettlementResponse,
} from "./zambiaCollectionsSettlementApi.types";

export enum ZambiaCollectionsSettlementsRequestType {
    SETTLEMENT_LIST = "zambia-settlement.settlement-list",
    EXPORT = "zambia-settlement.export",
}

export const zambiaCollectionsSettlement = async (_data: ZambiaCollectionsSettlementRequest): Promise<ZambiaCollectionsSettlementResponse> => {
    const signal = getAbortControllerSignal(ZambiaCollectionsSettlementsRequestType.SETTLEMENT_LIST);
    const res = await makeRequestWithSignal("/main/lenco-pay/settlements/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        settlements: Parsers.classObjectArray(res.settlements, ZambiaSettlement),
    };
};

export const exportZambiaCollectionsSettlements = async (
    _data: ExportZambiaCollectionsSettlementsRequest
): Promise<ExportZambiaCollectionsSettlementsResponse> => {
    const signal = getAbortControllerSignal(ZambiaCollectionsSettlementsRequestType.EXPORT);
    const res = await makeRequestWithSignal("/main/lenco-pay/settlements/export", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        eventName: Parsers.string(res.eventName),
        key: Parsers.string(res.key),
    };
};

export function abortZambiaCollectionsSettlementRequest(type: ZambiaCollectionsSettlementsRequestType): void {
    abortRequest(type);
}

export function abortAllZambiaCollectionsSettlementRequests(): void {
    Object.values(ZambiaCollectionsSettlementsRequestType).forEach((type) => abortRequest(type));
}
