import { getIcon, getSections } from "../../../../Hooks/useApplicationStatus";
import { useLocation, useNavigate } from "react-router-dom";

import { ApplicationBusinessType } from "../../../../services/application.constants";
import { ApplicationPage } from "../../../../services/application.types";
import ApplicationSectionCard from "../../../cards/applicationSectionCard";
import { IRootState } from "../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../components/spinner";
import Support from "../../../cards/supportCard";
import Tags from "../../../tags/ApplicationStatusTag";
import { useSelector } from "react-redux";

interface Props {
    pages: ApplicationPage[];
}

function ApplicationStatus({ pages }: Props): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const zambiaAccountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const zambiaAccountManager = useSelector((state: IRootState) => state.zambiaApplication.accountManager);
    const applicationBusinessType = useSelector((state: IRootState) => state.zambiaApplication.applicationBusinessType);

    const sections = getSections(pages, zambiaAccountOpening?.progress, applicationBusinessType, zambiaAccountOpening?.isCreator || false, true);

    return (
        <>
            {zambiaAccountOpening && (sections.todoList.length > 0 || sections.underReviewList.length > 0 || sections.preview.length > 0) ? (
                <div className="w-full pb-24">
                    <div className="flex h-full w-full flex-col justify-between xl:flex-row">
                        <div className="flex w-full flex-1 flex-col space-y-6 xl:min-w-max xl:max-w-3xl">
                            <div>
                                <h3 className="text-xl font-medium capitalize text-black">Application Overview</h3>
                                <p className="text-base text-black-tertiary">
                                    We need a few more details about your business to approve your account.
                                </p>
                            </div>
                            <div className="flex w-full flex-col items-start justify-start space-y-6">
                                {sections.todoList.length > 0 && (
                                    <div className="flex w-full flex-col items-start justify-start space-y-4">
                                        <Tags text="To do" type="info" />
                                        {sections.todoList.sort().map((page) => {
                                            return (
                                                <ApplicationSectionCard
                                                    key={page.value}
                                                    icon={<img {...getIcon(page)} />}
                                                    title={page.title}
                                                    description={page.description}
                                                    onClick={() => {
                                                        navigate(
                                                            {
                                                                pathname: page.route,
                                                            },
                                                            {
                                                                state: {
                                                                    from: location.pathname,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                    progress={page.progress}
                                                    isDisabled={
                                                        !!(
                                                            !zambiaAccountOpening.progress.isBusinessCompleted() &&
                                                            (page.index === 3 || page.index === 4) &&
                                                            applicationBusinessType === ApplicationBusinessType.REGISTERED
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                                {sections.underReviewList.length > 0 && (
                                    <div className="flex w-full flex-col items-start justify-start space-y-4">
                                        <Tags text="Under Review" type="warning" />
                                        {sections.underReviewList.sort().map((page) => (
                                            <ApplicationSectionCard
                                                key={page.value}
                                                icon={<img {...getIcon(page)} />}
                                                title={page.title}
                                                description={page.description}
                                                onClick={() => {
                                                    navigate(
                                                        {
                                                            pathname: page.route,
                                                        },
                                                        {
                                                            state: {
                                                                from: location.pathname,
                                                            },
                                                        }
                                                    );
                                                }}
                                                progress={page.progress}
                                                isDisabled={
                                                    !!(
                                                        !zambiaAccountOpening.progress.isBusinessCompleted() &&
                                                        (page.index === 3 || page.index === 4) &&
                                                        applicationBusinessType === ApplicationBusinessType.REGISTERED
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                                {sections.preview.length > 0 && (
                                    <div className="flex w-full flex-col items-start justify-start space-y-4">
                                        <Tags text="Other Details" type="info" />
                                        {sections.preview.sort().map((page) => (
                                            <ApplicationSectionCard
                                                key={page.value}
                                                icon={<img {...getIcon(page)} />}
                                                title={page.title}
                                                description={page.description}
                                                onClick={() => {
                                                    navigate(
                                                        {
                                                            pathname: page.route,
                                                        },
                                                        {
                                                            state: {
                                                                from: location.pathname,
                                                            },
                                                        }
                                                    );
                                                }}
                                                progress={page.progress}
                                                isDisabled={
                                                    !!(
                                                        !zambiaAccountOpening.progress.isBusinessCompleted() &&
                                                        (page.index === 3 || page.index === 4) &&
                                                        applicationBusinessType === ApplicationBusinessType.REGISTERED
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                                {/* {sections.reviewed.length > 0 && (
									<div className="flex flex-col justify-start items-start space-y-4 w-full">
										<Tags text="Reviewed" type={ToastType.SUCCESS_TOAST} />
										{sections.reviewed.sort().map((page) => (
											<ApplicationSectionCard
												key={page.value}
												icon={<img src={CompletedIcon as string} alt="icon to depict a completed section" />}
												title={page.title}
												description={page.description}
												onClick={() => {
													navigate({
														pathname: page.route,
														state: {
															from: location.pathname,
														},
													});
												}}
												progress={page.progress}
												isDisabled={
													!!(
														!zambiaAccountOpening.progress.isBusinessCompleted() &&
														(page.index === 3 || page.index === 4) &&
														applicationBusinessType === ApplicationBusinessType.REGISTERED
													)
												}
											/>
										))}
									</div>
								)} */}
                            </div>
                            {/* <div className="!mt-12 border-t border-grey-secondary pt-12">
                                <Support
                                    email={zambiaAccountManager?.email}
                                    name={zambiaAccountManager?.name}
                                    phone={zambiaAccountManager?.phone}
                                    photo={zambiaAccountManager?.photo}
                                    username={`${zambiaAccountOpening.user.firstName}` + ` ${zambiaAccountOpening.user.lastName}`}
                                />
                            </div> */}
                        </div>
                        <div className="fixed bottom-4 z-20 w-full max-w-full pr-8 lg:pr-[319px]">
                            <Support
                                email={zambiaAccountManager?.email}
                                name={zambiaAccountManager?.name}
                                phone={zambiaAccountManager?.phone}
                                photo={zambiaAccountManager?.photo}
                                username={`${zambiaAccountOpening.user.firstName}` + ` ${zambiaAccountOpening.user.lastName}`}
                            />
                        </div>
                        {/* <div className="mt-2 flex w-full items-start justify-center pt-10 lg:pt-28 xl:h-full xl:w-30%">
                            <Support
                                email={zambiaAccountManager?.email}
                                name={zambiaAccountManager?.name}
                                phone={zambiaAccountManager?.phone}
                                photo={zambiaAccountManager?.photo}
                            />
                        </div> */}
                    </div>
                </div>
            ) : (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
        </>
    );
}

export default ApplicationStatus;
