import React, { useLayoutEffect, useRef } from "react";

// import AccountSettingImg from "../../../../../assets/svg/Application/RegisteredBusiness/Done/account-setting.svg";
// import AccountSettingImgSecond from "../../../../../assets/svg/Application/RegisteredBusiness/Done/account-setting-2.svg";
// import {ReactComponent as ArrowNext} from "../../../../../assets/svg/general/ArrowTail/arrow-right.svg";
import { ReactComponent as BlueCheck } from "../../../../../assets/svg/dashboard/application/blue-circle-check.svg";
import ButtonComp from "../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../redux/rootReducer";
// import IntegrationImg from "../../../../../assets/svg/Application/RegisteredBusiness/Done/integration.svg";
// import IntegrationImgSecond from "../../../../../assets/svg/Application/RegisteredBusiness/Done/integration-2.svg";
// import LencoInvoiceImg from "../../../../../assets/svg/Application/RegisteredBusiness/Done/lenco-invoice.svg";
// import LencoInvoiceImgSecond from "../../../../../assets/svg/Application/RegisteredBusiness/Done/lenco-invoice-2.svg";
import { Routes } from "../../../../../routes/routes.constants";
// import TeamBankingImg from "../../../../../assets/svg/Application/RegisteredBusiness/Done/team-banking.svg";
// import TeamBankingImgSecond from "../../../../../assets/svg/Application/RegisteredBusiness/Done/team-banking-2.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ApplicationDone(): JSX.Element {
    const navigate = useNavigate();
    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const accountManager = useSelector((state: IRootState) => state.application.accountManager);
    const zambiaAccountManager = useSelector((state: IRootState) => state.zambiaApplication.accountManager);
    const zambiaAccountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    const divRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        divRef.current?.scrollIntoView();
    }, []);

    return (
        <>
            <div className="w-full pb-20" ref={divRef}>
                <div className="flex w-full flex-col items-start justify-start space-y-6">
                    <div className="flex w-full flex-col items-center justify-center space-y-8 rounded-lg bg-white p-8 shadow-sm">
                        <div className="flex w-full flex-col items-center justify-center space-y-4">
                            <BlueCheck />
                            <h1 className="text-2xl font-medium text-black">All done!</h1>
                            <div className="text-center text-sm text-black-secondary">
                                <p>
                                    Your application has been submitted successfully and is being reviewed by{" "}
                                    <span className="text-blue">{accountManager?.name || zambiaAccountManager?.name || ""}.</span>
                                </p>
                                <p>
                                    {zambiaAccountOpening &&
                                        "We estimate that you will hear back from us within 1 business day. If you have more information to provide please add them to your application."}
                                    {accountOpening &&
                                        "We estimate that we will reach out to you within the next 1-2 business days. Have a great day"}
                                </p>
                            </div>
                        </div>
                        <ButtonComp
                            type="button"
                            color="black"
                            ripple="light"
                            buttonType="primary"
                            func={() => navigate(Routes.ACCOUNT_OPENING.STATUS)}
                        >
                            <span>Return to Application</span>
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApplicationDone;
