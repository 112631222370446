import ButtonComp from "../../../../../../../components/button/ButtonComp";
import FullPageTrayFooter from "../../../../../../../components/Trays/FullPageTray/FullPageTrayFooter";
import Transaction from "../../../../../../../models/transaction";
import UserAccount from "../../../../../../../models/userAccount";

interface Props {
    dataType: string;
    transaction: Transaction;
    handleShowActiveAccountModal: () => void;
    handleSetAccountToActivate: (account: UserAccount | null) => void;
}

function TransactionDetailCardPendingActivation({
    dataType,
    transaction,
    handleShowActiveAccountModal,
    handleSetAccountToActivate,
}: Props): JSX.Element {
    return (
        <>
            {transaction && transaction.isActivationPending && (
                <FullPageTrayFooter dataType={dataType}>
                    <ButtonComp
                        type="button"
                        ripple="light"
                        color="black"
                        buttonType="primary"
                        func={(e) => {
                            e.preventDefault();
                            handleShowActiveAccountModal();
                            handleSetAccountToActivate(transaction.origination?.userAccount || null);
                        }}
                        dataType={dataType}
                    >
                        <span data-type={dataType}>Activate Account</span>
                    </ButtonComp>
                </FullPageTrayFooter>
            )}
        </>
    );
}

export default TransactionDetailCardPendingActivation;
