import { useCallback, useEffect, useState } from "react";

import { NotificationChannel } from "../../../../../../models/userAccount.constants";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { removeTeamMemberNotificationChannelForAccount } from "../../account-settings/account-settings.api";
import { useDispatch } from "react-redux";

export interface UseRemoveTeamMemberNotificationChannelResponse {
    isRemoving: boolean;
    handleRemoveNotificationChannel: (accountMeta: UserAccountMeta, channel: NotificationChannel) => Promise<void>;
}

function useRemoveTeamMemberNotificationChannel(
    updateAccountMeta: (accountMeta: UserAccountMeta) => void
): UseRemoveTeamMemberNotificationChannelResponse {
    const dispatch = useDispatch();

    const [isRemoving, setIsRemoving] = useState(false);

    useEffect(() => {
        return () => {
            setIsRemoving(false);
        };
    }, []);

    const handleRemoveNotificationChannel = useCallback(async (accountMeta: UserAccountMeta, channel: NotificationChannel) => {
        setIsRemoving(true);
        try {
            const res = await removeTeamMemberNotificationChannelForAccount(accountMeta.id, accountMeta.userAccountId, channel);
            updateAccountMeta(res);
            dispatch(messageTrue("Team Member Notification Channel Updated"));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsRemoving(false);
        }
    }, []);

    return {
        isRemoving,
        handleRemoveNotificationChannel,
    };
}

export default useRemoveTeamMemberNotificationChannel;
