import {
    NigeriaApplicationSectionType,
    NigeriaApplicationStagesType,
    NigeriaRegisteredSections,
    NigeriaUnregisteredSections,
} from "../../services/nigeria-application/nigeria-application.types";

import AccountSignatories from "./components/registered-business/account-signatories";
import Agreements from "./components/registered-business/agreements";
import ApplicationBusinessLayout from "./components/layout";
import BusinessReview from "./components/registered-business/business-review";
import { GenericApplicationPageType } from "../../services/application.constants";
import InviteReferees from "./components/registered-business/invite-referees";
import PersonalInformation from "./components/unregistered-business/personal-information";
import React from "react";
import RegisteredBusinessDetails from "./components/registered-business/business-details";
import RegisteredBusinessDocuments from "./components/registered-business/business-documents";
import UnregisteredBusinessDetails from "./components/unregistered-business/business-details";
import UnregisteredBusinessReview from "./components/unregistered-business/business-review";

interface Props {
    stage: NigeriaApplicationStagesType | undefined;
    section: NigeriaApplicationSectionType | GenericApplicationPageType;
    isRegistered: boolean;
}

function NigeriaApplication(props: Props): JSX.Element {
    return (
        <>
            <ApplicationBusinessLayout section={props.section} stage={props.stage} isRegistered={props.isRegistered}>
                {props.section === NigeriaRegisteredSections.AGREEMENT && <Agreements />}

                {props.section === NigeriaRegisteredSections.REFEREES && <InviteReferees />}
                {props.section === NigeriaRegisteredSections.SIGNATORY && <AccountSignatories />}
                {props.section === NigeriaRegisteredSections.COMPANY && <RegisteredBusinessDetails />}

                {props.section === NigeriaRegisteredSections.DOCUMENT && <RegisteredBusinessDocuments />}

                {props.section === NigeriaRegisteredSections.REVIEW && props.isRegistered && <BusinessReview />}

                {props.section === NigeriaUnregisteredSections.BUSINESS && <UnregisteredBusinessDetails />}
                {props.section === NigeriaUnregisteredSections.REVIEW && !props.isRegistered && <UnregisteredBusinessReview />}
                {(props.section === NigeriaUnregisteredSections.PERSONAL || props.section === NigeriaUnregisteredSections.IDENTITY) && (
                    <PersonalInformation />
                )}
            </ApplicationBusinessLayout>
        </>
    );
}

export default NigeriaApplication;
