import BasicFeeData from "./basicFeeData";
import { CollectionStatus } from "./collection.constants";
import Currency from "./currency";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { ZambiaMobileMoneyServiceProvider } from "./zambia/zambiaCollectionRequest.constant";
import { immerable } from "immer";

export default class Collection {
    [immerable] = true;

    constructor(
        public id: string,
        public createdAt: Date | null,
        public companyId: string,
        public amount: string,
        public currency: Currency | null,
        public status: CollectionStatus | null,
        public serviceProvider: ZambiaMobileMoneyServiceProvider | null,
        public debitPhoneNumber: string,
        public debitAccountName: string | null,
        public completedAt: string | null,
        public failedReason: string | null,
        public lencoFee: string,
        public lencoFeeData: BasicFeeData,
        public settlementAmount: string | null
    ) {}

    static create(obj: GenericObject): Collection {
        return new Collection(
            Parsers.string(obj.id),
            Parsers.date(obj.createdAt),
            Parsers.string(obj.id),
            Parsers.string(obj.amount),
            Parsers.classObject(obj.currency, Currency),
            Parsers.nullableEnum(obj.status, CollectionStatus),
            Parsers.nullableEnum(obj.serviceProvider, ZambiaMobileMoneyServiceProvider),
            Parsers.string(obj.debitPhoneNumber),
            Parsers.nullableString(obj.debitAccountName),
            Parsers.nullableString(obj.completedAt),
            Parsers.nullableString(obj.failedReason),
            Parsers.string(obj.lencoFee),
            Parsers.classObjectNonNullable(obj.lencoFeeData, BasicFeeData),
            Parsers.nullableString(obj.settlementAmount)
        );
    }

    get isSuccess(): boolean {
        return this.status === CollectionStatus.SUCCESSFUL;
    }

    get isFailed(): boolean {
        return this.status === CollectionStatus.FAILED;
    }

    get isProcessing(): boolean {
        return this.status === CollectionStatus.PENDING;
    }

    get serviceProviderText(): string {
        return this.serviceProvider === ZambiaMobileMoneyServiceProvider.AIRTEL
            ? "Airtel"
            : this.serviceProvider === ZambiaMobileMoneyServiceProvider.MTN
              ? "MTN"
              : this.serviceProvider === ZambiaMobileMoneyServiceProvider.ZAMTEL
                ? "Zamtel"
                : "";
    }
}
