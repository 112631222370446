import Card from "./card";
import CardRequest from "./cardRequest";
import CardSpend from "./cardSpend";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";
import { CardStatus, CreateCardRequestBankingAppStatus } from "./card.constants";
import { TextType } from "../components/Table/Type/TextType";

export default class CardMain {
    [immerable] = true;

    constructor(
        public createRequest: CardRequest,
        public card: Card | null,
        public balance: number | null,
        public cardSpend: CardSpend | null
    ) {}

    static create(obj: GenericObject): CardMain {
        return new CardMain(
            Parsers.classObjectNonNullable(obj.createRequest, CardRequest),
            Parsers.classObject(obj.card, Card),
            Parsers.number(obj.balance),
            Parsers.classObject(obj.cardSpend, CardSpend)
        );
    }

    // get cardStatus () : {
    //     if(this?.createRequest?.status === CreateCardRequestBankingAppStatus.DELIVERED)  (

    // if(this.card?.status === CardStatus.ACTIVE) ? "Active" : ""}
    //      if(this.card?.status === CardStatus.FROZEN) ? "Frozen" : ""}
    //      if(this.card?.status === CardStatus.UNACTIVATED) ? "Unactivated" : ""}
    //      if(this.card?.status === CardStatus.EXPIRED) ? "Expired" : ""}
    //      if(this.card?.status === CardStatus.DEACTIVATED) ? "Deactivated" : ""}

    //      ) else (

    //      if(this.createRequest.status === CreateCardRequestBankingAppStatus.APPROVED) ? "Approved" : ""}
    //      if(this.createRequest.status === CreateCardRequestBankingAppStatus.DECLINED) ? "Declined" : ""}
    //      if(this.createRequest.status === CreateCardRequestBankingAppStatus.PRINTED) ? "Printed" : ""}
    //      if(this.createRequest.status === CreateCardRequestBankingAppStatus.REQUESTED) ? "Requested" : ""}
    //      if(this.createRequest.status === CreateCardRequestBankingAppStatus.SHIPPED) ? "Shipped" : ""}

    //      )
    // }

    get cardStatus(): CardStatus | CreateCardRequestBankingAppStatus {
        if (this.createRequest.status === CreateCardRequestBankingAppStatus.DELIVERED) {
            if (this?.card?.status === CardStatus.ACTIVE) return CardStatus.ACTIVE;
            if (this?.card?.status === CardStatus.FROZEN) return CardStatus.FROZEN;
            if (this?.card?.status === CardStatus.EXPIRED) return CardStatus.EXPIRED;
            if (this?.card?.status === CardStatus.DEACTIVATED) return CardStatus.DEACTIVATED;
            return CardStatus.UNACTIVATED;
        } else {
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.APPROVED) return CreateCardRequestBankingAppStatus.APPROVED;
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.DECLINED) return CreateCardRequestBankingAppStatus.DECLINED;
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.PRINTED) return CreateCardRequestBankingAppStatus.PRINTED;
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.SHIPPED) return CreateCardRequestBankingAppStatus.SHIPPED;
            return CreateCardRequestBankingAppStatus.REQUESTED;
        }
    }

    get cardStatusText(): string {
        if (this.createRequest.status === CreateCardRequestBankingAppStatus.DELIVERED) {
            if (this?.card?.status === CardStatus.ACTIVE) return "Active";
            if (this?.card?.status === CardStatus.FROZEN) return "Frozen";
            if (this?.card?.status === CardStatus.EXPIRED) return "Expired";
            if (this?.card?.status === CardStatus.DEACTIVATED) return "Deactivated";
            return "Unactivated";
        } else {
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.APPROVED) return "Approved";
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.DECLINED) return "Declined";
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.PRINTED) return "Printed";
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.SHIPPED) return "Shipped";
            return "Requested";
        }
    }

    get cardStatusTableTextType(): TextType {
        if (this.createRequest.status === CreateCardRequestBankingAppStatus.DELIVERED) {
            if (this?.card?.status === CardStatus.ACTIVE) return TextType.CARD_STATUS_ACTIVE;
            if (this?.card?.status === CardStatus.FROZEN) return TextType.CARD_STATUS_FROZEN;
            if (this?.card?.status === CardStatus.EXPIRED) return TextType.CARD_STATUS_EXPIRED;
            if (this?.card?.status === CardStatus.DEACTIVATED) return TextType.CARD_STATUS_DEACTIVATED;
            return TextType.CARD_STATUS_UNACTIVATED;
        } else {
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.APPROVED) return TextType.CARD_REQUEST_STATUS_APPROVED;
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.DECLINED) return TextType.CARD_REQUEST_STATUS_DECLINED;
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.PRINTED) return TextType.CARD_REQUEST_STATUS_PRINTED;
            if (this?.createRequest?.status === CreateCardRequestBankingAppStatus.SHIPPED) return TextType.CARD_REQUEST_STATUS_SHIPPED;
            return TextType.CARD_REQUEST_STATUS_REQUESTED;
        }
    }
}
