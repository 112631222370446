import { useEffect, useState } from "react";

import CompletedWaitList from "../components/completed";
import JoinWaitList from "../components/join";
import { WaitListStage } from "../services/WaitList.constant";
import { useParams } from "react-router";

type ApplicationUrlParams = {
    stage: WaitListStage;
};

function WaitList(): JSX.Element {
    const params = useParams<ApplicationUrlParams>();

    const [stage, setStage] = useState<WaitListStage>(WaitListStage.JOIN);

    useEffect(() => {
        if (!params.stage) return;
        setStage(params.stage);
    }, [params]);

    return (
        <>
            {stage === WaitListStage.JOIN && (
                <JoinWaitList
                    onComplete={() => {
                        setStage(WaitListStage.COMPLETE);
                    }}
                />
            )}
            {stage === WaitListStage.COMPLETE && <CompletedWaitList />}
        </>
    );
}
export default WaitList;
