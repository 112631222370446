import { BillPurchaseCategoryOptions, BillPurchaseStatusTypeOptions } from "../../../../services/billPayment/billPaymentApi.constants";
import {
    setBillHistoryTransactionDateType,
    setUpdatedSelectedBillHistoryFilter,
} from "../../../../../../../redux/payments/billPayment/slice/billPaymentSlice";

import { BillPurchasesHistoryRequest } from "../../../../services/billPayment/billPaymentApi.types";
import DateTag from "../../../../../../../components/filter/Tags/DateTag";
import { FilterDateTypes } from "../../../../../../../components/filter/filter.constant";
import FilterDropdown from "./FilterDropDown";
import GenericFilterTag from "../../../../../../../components/filter/Tags/GenericTag";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface Props {
    handleClearAll: () => void;
    handleFilteredDetails: (_data: BillPurchasesHistoryRequest) => void;
}

function BillHistoryFilterSection(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const account = useAppSelector((state) => state.init.main?.companyDetails?.accounts);
    const vendorsList = useAppSelector((state) => state.billPayment.vendorsList);
    const billHistoryFilterState = useAppSelector((state) => state.billPayment.selectedBillHistoryFilterState);
    const billHistoryTransactionDateType = useAppSelector((state) => state.billPayment.billHistoryTransactionDateType);

    return (
        <>
            <div className="-mt-2 flex w-full flex-col flex-wrap items-center 4xs:flex-row">
                <div className="w-max pr-3 pt-2">
                    <FilterDropdown handleFilteredDetails={props.handleFilteredDetails} handleClearAll={props.handleClearAll} />
                </div>
                {billHistoryFilterState.date && billHistoryFilterState.date.begin && billHistoryFilterState.date.end && (
                    <DateTag
                        endDate={billHistoryFilterState.date.end || ""}
                        startDate={billHistoryFilterState.date.begin || ""}
                        handleCancelSelection={() => {
                            const updatedFilter = { ...billHistoryFilterState, date: { begin: "", end: "" } };
                            dispatch(setUpdatedSelectedBillHistoryFilter(updatedFilter));
                            dispatch(setBillHistoryTransactionDateType(FilterDateTypes.ALL_TIME));
                            props.handleFilteredDetails(updatedFilter);
                        }}
                        selectedFilterDate={billHistoryTransactionDateType}
                    />
                )}
                {(billHistoryFilterState.userAccountIds || []).length > 0 && (
                    <GenericFilterTag
                        title="Account"
                        onCancel={() => {
                            dispatch(setUpdatedSelectedBillHistoryFilter({ userAccountIds: [] }));
                            props.handleFilteredDetails({ ...billHistoryFilterState, userAccountIds: [] });
                        }}
                        onIndividualCancel={(_value) => {
                            const updatedFilter = {
                                ...billHistoryFilterState,
                                userAccountIds: billHistoryFilterState?.userAccountIds?.filter((_el) => _el !== _value) || [],
                            };
                            dispatch(setUpdatedSelectedBillHistoryFilter(updatedFilter));
                            props.handleFilteredDetails(updatedFilter);
                        }}
                        options={account?.map((_userAccount) => ({
                            text: _userAccount.accountName,
                            value: _userAccount.id,
                        }))}
                        values={billHistoryFilterState.userAccountIds || []}
                    />
                )}
                {(billHistoryFilterState.vendorIds || []).length > 0 && (
                    <GenericFilterTag
                        title="Vendor"
                        onCancel={() => {
                            dispatch(setUpdatedSelectedBillHistoryFilter({ vendorIds: [] }));
                            props.handleFilteredDetails({ ...billHistoryFilterState, vendorIds: [] });
                        }}
                        onIndividualCancel={(_value) => {
                            const updatedFilter = {
                                ...billHistoryFilterState,
                                userAccountIds: billHistoryFilterState?.vendorIds?.filter((_el) => _el !== _value) || [],
                            };
                            dispatch(setUpdatedSelectedBillHistoryFilter(updatedFilter));
                            props.handleFilteredDetails(updatedFilter);
                        }}
                        options={vendorsList?.map((_vendor) => ({
                            text: _vendor.name || "",
                            value: _vendor.id,
                        }))}
                        values={billHistoryFilterState.vendorIds || []}
                    />
                )}
                {(billHistoryFilterState.categories || []).length > 0 && (
                    <GenericFilterTag
                        title="Category"
                        onCancel={() => {
                            dispatch(setUpdatedSelectedBillHistoryFilter({ categories: [] }));
                            props.handleFilteredDetails({ ...billHistoryFilterState, categories: [] });
                        }}
                        onIndividualCancel={(_value) => {
                            const updatedFilter = {
                                ...billHistoryFilterState,
                                categories: billHistoryFilterState?.categories?.filter((_el) => _el !== _value) || [],
                            };
                            dispatch(setUpdatedSelectedBillHistoryFilter(updatedFilter));
                            props.handleFilteredDetails(updatedFilter);
                        }}
                        options={BillPurchaseCategoryOptions}
                        values={billHistoryFilterState.categories || []}
                    />
                )}
                {(billHistoryFilterState.statuses || []).length > 0 && (
                    <GenericFilterTag
                        title="Status"
                        onCancel={() => {
                            dispatch(setUpdatedSelectedBillHistoryFilter({ statuses: [] }));
                            props.handleFilteredDetails({ ...billHistoryFilterState, statuses: [] });
                        }}
                        onIndividualCancel={(_value) => {
                            const updatedFilter = {
                                ...billHistoryFilterState,
                                statuses: billHistoryFilterState?.statuses?.filter((_el) => _el !== _value) || [],
                            };
                            dispatch(setUpdatedSelectedBillHistoryFilter(updatedFilter));
                            props.handleFilteredDetails(updatedFilter);
                        }}
                        options={BillPurchaseStatusTypeOptions}
                        values={billHistoryFilterState.statuses || []}
                    />
                )}
            </div>
        </>
    );
}

export default BillHistoryFilterSection;
