import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import UserAccount from "./userAccount";
import { immerable } from "immer";

export default class ApiRequestPayment {
    [immerable] = true;

    constructor(
        public apiAccess: boolean | null,
        public clientReference: string,
        public settlementAccount: UserAccount
    ) {}

    static create(obj: GenericObject): ApiRequestPayment {
        return new ApiRequestPayment(
            Parsers.nullableBoolean(obj.apiAccess),
            Parsers.string(obj.clientReference),
            Parsers.classObjectNonNullable(obj.settlementAccount, UserAccount)
        );
    }
}
