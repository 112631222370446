import { GenericObject } from "../helpers/types";
import { MerchantSettlementType } from "./terminal.constants";
import MerchantTerminalGroup from "./merchantTerminalGroup";
import Parsers from "../utils/parsers";
import TerminalFee from "./terminalFee";

export default class Merchant {
    constructor(
        public id: string,
        public businessName: string,
        public isAggregator: boolean,
        public settlementType: MerchantSettlementType,
        public feeType: TerminalFee | null,
        public canAcceptTransfer: boolean,
        public createdAt: string,
        public merchantTerminalGroups: MerchantTerminalGroup
    ) {}

    static create(obj: GenericObject): Merchant {
        return new Merchant(
            Parsers.string(obj.id),
            Parsers.string(obj.businessName),
            Parsers.boolean(obj.isAggregator),
            Parsers.number(obj.settlementType),
            Parsers.classObject(obj.feeType, TerminalFee),
            Parsers.boolean(obj.canAcceptTransfer),
            Parsers.string(obj.createdAt),
            Parsers.classObjectNonNullable(obj.merchantTerminalGroups, MerchantTerminalGroup)
        );
    }

    get merchantSettlementType(): string {
        return this.settlementType === MerchantSettlementType.MANUAL
            ? "Manuel"
            : this.settlementType === MerchantSettlementType.INSTANT
              ? "Instant"
              : "Next day";
    }
}
