import { useLayoutEffect, useState } from "react";

import ButtonComp from "../../../../components/button/ButtonComp";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import LoginFooter from "../../../../components/layouts/components/landing/login-footer";
import { OtpRecipientItem } from "../servicess/resetPasswordApi.types";
import RadioButton from "../../../../components/radio-button";
import { ResetForgotUrlParams } from "../hookss/statee/useResetForgotPasswordState";
import { UseResetPasswordSendOtpInterface } from "../hookss/statee/useResetPasswordSendOtp";

interface Props {
    params: UseResetPasswordSendOtpInterface;
    baseParams: ResetForgotUrlParams;
    otpRecipients: OtpRecipientItem[];
}

function ResetPasswordSelectOtpRecipientForm({ params, baseParams, otpRecipients }: Props): JSX.Element {
    const [selectedRecipient, setSelectedRecipient] = useState<string | null>(null);

    useLayoutEffect(() => {
        if (otpRecipients.length === 1) {
            setSelectedRecipient(otpRecipients[0].key);
        }
    }, [otpRecipients]);

    return (
        <>
            <div className="relative mx-auto flex w-full max-w-[476px] flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex w-full flex-col items-center justify-start space-y-4">
                    <h3 className="break-words text-center text-2xl font-medium text-black">Reset Your Password</h3>
                    <div className="flex w-full flex-col items-center justify-start">
                        <p className="break-words text-center text-sm font-normal text-black-secondary">
                            To continue, we would send an SMS OTP to your registered phone number.
                        </p>
                        {otpRecipients.length > 1 && (
                            <p className="break-words text-center text-sm font-normal text-black-secondary">
                                Select the phone number that should receive the SMS OTP.
                            </p>
                        )}
                    </div>
                </div>

                {params.errorMessage && (
                    <div className="mb-4 mt-4 flex w-max flex-col items-center justify-center text-center">
                        <ErrorToast error={params.errorMessage} />
                    </div>
                )}

                <form
                    className="flex w-full flex-col items-center justify-center space-y-8"
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        void params.handleSubmitForm({ ...baseParams, recipient: selectedRecipient || "" });
                    }}
                >
                    <div className="flex flex-col items-start justify-start space-y-4">
                        {otpRecipients?.map((el) => (
                            <RadioButton
                                id={String(el.key)}
                                checked={selectedRecipient === el.key}
                                text={`Send SMS to ${el.text}`}
                                key={el.key}
                                func={() => setSelectedRecipient(el.key)}
                                size="sm"
                            />
                        ))}
                    </div>

                    <ButtonComp
                        type="submit"
                        color="black"
                        buttonType="primary"
                        disable={!selectedRecipient}
                        isLoading={params.isSubmitting}
                        fullWidth
                    >
                        Send OTP
                    </ButtonComp>
                </form>
            </div>
            <LoginFooter />
        </>
    );
}

export default ResetPasswordSelectOtpRecipientForm;
