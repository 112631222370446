import { EditOtherDirectorRequest, EditSignatoryRequest } from "../../../../../services/zambia-application/registered/new/accountSignatories.types";
import { editAccountSignatory, editOtherDirector } from "../../../../../services/zambia-application/registered/new/accountSignatories.api";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { setZambiaAccountSignatories } from "../../../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface UseEditSignatoryInterface {
    isEditSignatoryLoading: boolean;
    handleEditSignatory: (data: EditSignatoryRequest) => Promise<void>;
    handleEditOtherDirector: (data: EditOtherDirectorRequest) => Promise<void>;
}

const useEditSignatory = (onComplete: () => void): UseEditSignatoryInterface => {
    const dispatch = useDispatch();

    const [isEditSignatoryLoading, setIsEditSignatoryLoading] = useState<boolean>(false);

    const handleEditSignatory = useCallback(async (_data: EditSignatoryRequest) => {
        try {
            setIsEditSignatoryLoading(true);
            const res = await editAccountSignatory(_data);
            onComplete();
            dispatch(setZambiaAccountSignatories(res));
            dispatch(messageTrue({ message: `Signatory updated successfully` }));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsEditSignatoryLoading(false);
        }
    }, []);

    const handleEditOtherDirector = useCallback(async (_data: EditOtherDirectorRequest) => {
        try {
            setIsEditSignatoryLoading(true);
            const res = await editOtherDirector(_data);
            onComplete();
            dispatch(setZambiaAccountSignatories(res));
            dispatch(messageTrue({ message: `Signatory updated successfully` }));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsEditSignatoryLoading(false);
        }
    }, []);

    return {
        isEditSignatoryLoading,
        handleEditSignatory,
        handleEditOtherDirector,
    };
};
export default useEditSignatory;
