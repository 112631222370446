// import Transaction from "../../../../../models/transaction";
// import TransactionCategory from "../../../../../models/transactionCategory";
// import UserGroup from "../../../../../models/userGroup";

import { TransactionFilterTypes } from "../../../../../redux/transactions/slice/transactionsSlice.types";
import { TransactionType } from "../../../../../models/transaction.constants";
import { TransactionsListResponse } from "../../Services/transactionApi.types";
// import {TransactionFilterTypes, TransactionState} from "../../transactionsSlice.types";

export interface OffsetDetail {
    offset: number;
}
export interface DateDetail {
    date: { begin: string; end: string };
}
export interface TransactionTypesDetail {
    transactionTypes: Array<TransactionType>;
}
export interface UserGroupIdsDetail {
    userGroupIds: Array<string>;
}
export interface UserAccountIdsDetail {
    userAccountIds: Array<string>;
}
export interface CategoryIdsDetail {
    categoryIds: Array<string>;
}
export interface TransactionStatusesDetail {
    transactionStatuses: Array<string>;
}
export interface CompanyIdDetail {
    companyId: string;
}
export interface QueryDetail {
    query: string;
}

export type TransactionsUrlParams = {
    type: string;
    query: string;
};

export enum TransactionFilter {
    INFLOW = "inflow",
    PAYOUT = "payout",
    ACCOUNT = "account",
    SEARCH = "search",
    CARDS = "cards",
}

export interface UseFilterTransactionsStateInterface {
    transactionsResponse: TransactionsListResponse | null;
    handleGetTransactions: (e: TransactionFilterTypes) => Promise<void>;
    handleFilteredDetails: (e: unknown) => void;
    handleClearAll: () => void;
}

export interface UseExportTransactionsStateInterface {
    handleExportTransactionsDetails: (e: { type: string }) => void;
}
