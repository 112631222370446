import CreateAccountRequest from "../../../../../models/createAccountRequest";
import { IRootState } from "../../../../../redux/rootReducer";
import React from "react";
import titleCase from "../../../../../hooks/titleCase";
import { useSelector } from "react-redux";

interface Props {
    data: CreateAccountRequest;
}

function PendingSubAccountCard(props: Props): JSX.Element {
    const mainAccount = useSelector((state: IRootState) => state.init.main?.companyDetails.company.name);

    return (
        <>
            <div className="flex w-full flex-row items-center justify-between rounded-lg border p-4">
                <div className="text-sm text-black-secondary">
                    <div>
                        {mainAccount} - {titleCase(props.data.name)}
                    </div>
                    <div>••••••••••••</div>
                    <div>•••••••••</div>
                </div>
                <div>{props.data.isPending && <span className="text-sm text-warning-quat">Processing</span>}</div>
            </div>
        </>
    );
}

export default PendingSubAccountCard;
