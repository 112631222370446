import { LencoSpinnerProps, getSpinnerColor } from "./spinner.contant";

import { Spinner } from "@nextui-org/react";

function LencoSpinner(props: LencoSpinnerProps) {
    return (
        <Spinner
            size={props.size || "md"}
            label={props.label || ""}
            color={undefined}
            labelColor={undefined}
            classNames={{
                label: getSpinnerColor(props.color),
                circle1: getSpinnerColor(props.color),
                circle2: getSpinnerColor(props.color),
            }}
        />
    );
}

export default LencoSpinner;
