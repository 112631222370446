import { Countries } from "../../../../components/layouts/services/country.constant";

export interface WaitListForm {
    email: string;
    country: Countries | null;
}

export enum WaitListStage {
    JOIN = "join",
    COMPLETE = "completed",
}
