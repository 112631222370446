import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { UpdateZambiaApiAccessKeyScopeRequest } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { setUpdateZambiaApiAccessKey } from "../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { updateZambiaApiAccessKeyScopeApi } from "../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { useDispatch } from "react-redux";

export interface UseUpdateZambiaApiAccessKeyScopeInterface {
    isUpdateZambiaApiAccessKeyScopeLoading: boolean;
    updateZambiaApiAccessKeyScopeErrorMessage: string;
    handleUpdateZambiaApiAccessKeyScope: (_data: UpdateZambiaApiAccessKeyScopeRequest) => void;
}

interface Props {
    onComplete: () => void;
}

function useUpdateZambiaApiAccessKeyScope(props: Props): UseUpdateZambiaApiAccessKeyScopeInterface {
    const dispatch = useDispatch();
    const [isUpdateZambiaApiAccessKeyScopeLoading, setIsUpdateZambiaApiAccessKeyScopeLoading] = useState(false);
    const [updateZambiaApiAccessKeyScopeErrorMessage, setUpdateZambiaApiAccessKeyScopeErrorMessage] = useState<string>("");

    const handleUpdateZambiaApiAccessKeyScope = useCallback(
        async (_data: UpdateZambiaApiAccessKeyScopeRequest) => {
            try {
                setIsUpdateZambiaApiAccessKeyScopeLoading(true);
                setUpdateZambiaApiAccessKeyScopeErrorMessage("");
                const res = await updateZambiaApiAccessKeyScopeApi(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key channels updated successfully."));
                props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setUpdateZambiaApiAccessKeyScopeErrorMessage(errorMessage);
            } finally {
                setIsUpdateZambiaApiAccessKeyScopeLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isUpdateZambiaApiAccessKeyScopeLoading,
        updateZambiaApiAccessKeyScopeErrorMessage,
        handleUpdateZambiaApiAccessKeyScope,
    };
}

export default useUpdateZambiaApiAccessKeyScope;
