import { useCallback, useState } from "react";

import { downloadAccountDetails } from "../../Services/accounts-api";
import { downloadAccountDetailsRequest } from "../../Services/accounts-api.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseDownloadAccountDetailsInterface {
    downloadAccountDetailsError: string;
    downloadAccountDetailsResponse: boolean;
    isDownloadAccountDetailsLoading: boolean;

    handleResetAll: () => void;
    handleDownloadAccountDetails: (_data: downloadAccountDetailsRequest) => Promise<void>;
}

function useDownloadAccountDetails(): UseDownloadAccountDetailsInterface {
    const dispatch = useDispatch();

    const [isDownloadAccountDetailsLoading, setIsDownloadAccountDetailsLoading] = useState<boolean>(false);
    const [downloadAccountDetailsResponse, setDownloadAccountDetailsResponse] = useState<boolean>(false);
    const [downloadAccountDetailsError, setDownloadAccountDetailsError] = useState<string>("");

    const handleDownloadAccountDetails = useCallback(
        async (_data: downloadAccountDetailsRequest) => {
            try {
                setDownloadAccountDetailsError("");
                setIsDownloadAccountDetailsLoading(true);
                dispatch(
                    messageTrue({
                        message: "Downloading ...",
                    })
                );
                await downloadAccountDetails(_data);
                setDownloadAccountDetailsResponse(true);
                dispatch(
                    messageTrue({
                        message: "Downloaded successfully",
                    })
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDownloadAccountDetailsError(errorMessage);
            } finally {
                setIsDownloadAccountDetailsLoading(false);
            }
        },
        [dispatch]
    );

    const handleResetAll = useCallback(() => {
        setDownloadAccountDetailsError("");
        setIsDownloadAccountDetailsLoading(false);
        setDownloadAccountDetailsResponse(false);
    }, []);

    return {
        downloadAccountDetailsError,
        downloadAccountDetailsResponse,
        isDownloadAccountDetailsLoading,

        handleResetAll,
        handleDownloadAccountDetails,
    };
}

export default useDownloadAccountDetails;
