import { AccountRestrictionType } from "../../../../../models/accountRestriction.constants";
import { DropdownItem } from "../../../../../helpers/types";

export enum AccountSettingsSectionValue {
    RESTRICTIONS = 1,
    MINIMUM_BALANCE = 2,
    // EXCESS_BALANCE = 3,
    SPLIT_INFLOW = 3,
}

export const AccountSettingsSections: {
    [value in AccountSettingsSectionValue]: DropdownItem<AccountSettingsSectionValue>;
} = {
    [AccountSettingsSectionValue.RESTRICTIONS]: {
        value: AccountSettingsSectionValue.RESTRICTIONS,
        text: "Account Restrictions",
        subtext: "Set how this account sends or receives money",
    },
    [AccountSettingsSectionValue.MINIMUM_BALANCE]: {
        value: AccountSettingsSectionValue.MINIMUM_BALANCE,
        text: "Balance Instructions",
        subtext: "Get notified when your balance falls below or exceeds a certain amount",
    },
    // [AccountSettingsSectionValue.EXCESS_BALANCE]: {
    //     value: AccountSettingsSectionValue.EXCESS_BALANCE,
    //     text: "Excess Balance Instructions",
    //     subtext: "Get notified when your balance exceeds a certain amount",
    // },
    [AccountSettingsSectionValue.SPLIT_INFLOW]: {
        value: AccountSettingsSectionValue.SPLIT_INFLOW,
        text: "Split Inflow Instructions",
        // subtext: "Partition inflow to different bank accounts",
        subtext: "Share inflow to your different bank accounts",
    },
};

export const AccountRestrictionTypeDetails: {
    [value in AccountRestrictionType]: DropdownItem<AccountRestrictionType>;
} = {
    [AccountRestrictionType.SEND_MONEY_TO_ANY_ACCOUNT]: {
        value: AccountRestrictionType.SEND_MONEY_TO_ANY_ACCOUNT,
        text: "Send money to any account",
        subtext: "You can send money to any account",
    },
    [AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS]: {
        value: AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS,
        text: "Send money to specific accounts only",
        subtext: "You can only send to specific recipients",
    },
    [AccountRestrictionType.CANNOT_SEND_MONEY]: {
        value: AccountRestrictionType.CANNOT_SEND_MONEY,
        text: "Can not send money",
        subtext: "You can not send money from this account",
    },
};

export const AccountRestrictionTypeText: {
    [type in AccountRestrictionType]: string;
} = {
    [AccountRestrictionType.SEND_MONEY_TO_ANY_ACCOUNT]: "You can send money to any account",
    [AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS]: "You can only send to specific recipients",
    [AccountRestrictionType.CANNOT_SEND_MONEY]: "You can not send money from this account",
};
