import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";
import { GenericObject } from "../../../../../helpers/types";
import User from "../../../../../models/user";
import DefaultSetup from "../../../../../models/zambia/lencoPay/defaultSetup";
import Parsers from "../../../../../utils/parsers";
import {
    GrantUserLencoPayAccessRequest,
    GrantUserLencoPayManagerAccessResponse,
    GrantUserLencoPayViewAccessResponse,
    LencoPayDefaultSetupInitResponse,
    LencoPayDefaultSetupRequest,
    LencoPayDefaultSetupResponse,
    SignleUserLencoPayAccessRequest,
} from "./lencoPayDefaultSetupApi.types";

export enum LencoPayDefaultSetupRequestType {
    INIT = "lenco-pay.init",
    UPDATE = "lenco-pay.update",
    ADD_MANAGER = "lenco-pay.add-manager",
    REMOVE_MANAGER = "lenco-pay.remove-manager",
    ADD_VIEWER = "lenco-pay.add-viewer",
    REMOVE_VIEWER = "lenco-pay.remove-viewer",
}

export const lencoPayDefaultSetupInit = async (): Promise<LencoPayDefaultSetupInitResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.UPDATE);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/all", {}, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        defaultSetup: Parsers.classObject(res.defaultSetup, DefaultSetup),
        teamMemberAccess: {
            manageAccess: Parsers.classObjectArray((res.teamMemberAccess as GenericObject).manageAccess, User),
            viewAccess: Parsers.classObjectArray((res.teamMemberAccess as GenericObject).viewAccess, User),
        },
    };
};

export const updateLencoPayDefaultSetup = async (_data: LencoPayDefaultSetupRequest): Promise<LencoPayDefaultSetupResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.UPDATE);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/default-setup/update", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        defaultSetup: Parsers.classObject(res.defaultSetup, DefaultSetup),
    };
};

export const addLencoPayUserManagerAccess = async (_data: GrantUserLencoPayAccessRequest): Promise<GrantUserLencoPayManagerAccessResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.ADD_MANAGER);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/manage-access/add", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        manageAccess: Parsers.classObjectArray(res.manageAccess, User),
    };
};

export const addLencoPayUserViewAccess = async (_data: GrantUserLencoPayAccessRequest): Promise<GrantUserLencoPayViewAccessResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.ADD_VIEWER);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/view-access/add", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        viewAccess: Parsers.classObjectArray(res.viewAccess, User),
    };
};

export const removeLencoPaySingleUserManageAccess = async (
    _data: SignleUserLencoPayAccessRequest
): Promise<GrantUserLencoPayManagerAccessResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.REMOVE_MANAGER);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/manage-access/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        manageAccess: Parsers.classObjectArray(res.manageAccess, User),
    };
};

export const removeLencoPaySingleUserViewerAccess = async (_data: SignleUserLencoPayAccessRequest): Promise<GrantUserLencoPayViewAccessResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.REMOVE_MANAGER);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/view-access/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        viewAccess: Parsers.classObjectArray(res.viewAccess, User),
    };
};

export const removeLencoPayUserManageAccess = async (_data: GrantUserLencoPayAccessRequest): Promise<GrantUserLencoPayManagerAccessResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.REMOVE_MANAGER);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/manage-access/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        manageAccess: Parsers.classObjectArray(res.manageAccess, User),
    };
};

export const removeLencoPayUserViewAccess = async (_data: GrantUserLencoPayAccessRequest): Promise<GrantUserLencoPayViewAccessResponse> => {
    const signal = getAbortControllerSignal(LencoPayDefaultSetupRequestType.REMOVE_VIEWER);

    const res = await makeRequestWithSignal("/main/lenco-pay/settings/view-access/remove", _data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        viewAccess: Parsers.classObjectArray(res.viewAccess, User),
    };
};

export function abortLencoPayRequest(type: LencoPayDefaultSetupRequestType): void {
    abortRequest(type);
}

export function abortAllLencoPayRequests(): void {
    Object.values(LencoPayDefaultSetupRequestType).forEach((type) => abortRequest(type));
}
