import { useCallback, useState } from "react";

import { downloadDocument } from "../../../../services/nigeria-application/registered/document.api";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { openBlobInNewTab } from "../../../../../../../utils/blob";
import { useDispatch } from "react-redux";

interface usePreviewInterface {
    previewBlob: Blob | null;
    isPreviewDownloading: boolean;
    handleReset: () => void;
    handlePreviewDocument: (_data: string, _isMandatory?: boolean) => Promise<void>;
}

interface Props {
    onComplete?: () => void;
    downloadedDocumentName?: string;
}

const usePreview = (props: Props): usePreviewInterface => {
    const dispatch = useDispatch();
    const [previewBlob, setPreviewBlob] = useState<Blob | null>(null);
    const [isPreviewDownloading, setIsPreviewDownloading] = useState<boolean>(false);

    const handlePreviewDocument = useCallback(
        async (_data: string, _isMandatory?: boolean) => {
            try {
                if (!_isMandatory) {
                    setIsPreviewDownloading(true);
                    dispatch(messageTrue({ message: "Generating preview" }));
                }
                const blob = await downloadDocument(_data);
                setPreviewBlob(blob);
                if (!_isMandatory) {
                    dispatch(messageTrue({ message: "Preview generated" }));
                    if (blob.type === "image/png" || blob.type === "image/jpeg" || blob.type === "image/jpg") {
                        props.onComplete && props.onComplete();
                        return;
                    }
                    if (blob.type === "application/pdf") {
                        openBlobInNewTab(blob, props.downloadedDocumentName || "Document");
                        return;
                    }
                }
            } catch (err) {
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsPreviewDownloading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setPreviewBlob(null);
        setIsPreviewDownloading(false);
        // abortCurrentSignatoryRequest(CurrentSignatoryRequests.d);
    }, []);

    return {
        previewBlob,
        isPreviewDownloading,
        handleReset,
        handlePreviewDocument,
    };
};
export default usePreview;
