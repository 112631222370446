import React, { useEffect, useRef } from "react";

import { ReactComponent as ActiveStageIcon } from "../../../../../../assets/svg/dashboard/send-money/active-payment-stage.svg";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import { ReactComponent as InactiveStageIcon } from "../../../../../../assets/svg/dashboard/send-money/inactive-payment-stage.svg";
import PageLayout from "../../../../../../components/layouts/page-layout";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { useSelector } from "react-redux";

interface Props {
    children: React.ReactNode;
}

function SendMoneyLayout(props: Props): JSX.Element {
    const divRef = useRef<HTMLDivElement | null>(null);

    const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);

    useEffect(() => {
        divRef.current?.scrollIntoView();
    }, [paymentStage]);

    return (
        <>
            <PageLayout pageTitle="Payments" subTitle="Send Money using your Lenco accounts">
                {paymentStage !== PaymentStageType.PROCESS_PAYMENT && (
                    <div className="hidden h-11 min-h-[44px] flex-row items-center justify-start space-x-4 overflow-x-auto rounded-md bg-white px-6 lg:flex">
                        <div className="flex items-center justify-start space-x-2">
                            {paymentStage > PaymentStageType.INITIAL && <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />}
                            {paymentStage === PaymentStageType.INITIAL && <ActiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${paymentStage > PaymentStageType.INITIAL ? "font-medium" : ""} ` +
                                    `${paymentStage === PaymentStageType.INITIAL ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Type of Transfer
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-grey"></div>
                        <div className="flex items-center justify-start space-x-2">
                            {paymentStage > PaymentStageType.PAYMENT_DETAILS && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {paymentStage === PaymentStageType.PAYMENT_DETAILS && <ActiveStageIcon />}
                            {paymentStage < PaymentStageType.PAYMENT_DETAILS && <InactiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${paymentStage > PaymentStageType.PAYMENT_DETAILS ? "font-medium" : "text-sm"} ` +
                                    `${paymentStage === PaymentStageType.PAYMENT_DETAILS ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Payment Details
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-grey"></div>
                        <div className="flex items-center justify-start space-x-2">
                            {paymentStage > PaymentStageType.REVIEW_PAYMENT && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {paymentStage === PaymentStageType.REVIEW_PAYMENT && <ActiveStageIcon />}
                            {paymentStage < PaymentStageType.REVIEW_PAYMENT && <InactiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${paymentStage > PaymentStageType.REVIEW_PAYMENT ? "font-medium" : "text-sm"} ` +
                                    `${paymentStage === PaymentStageType.REVIEW_PAYMENT ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Review Payment
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-grey"></div>
                        <div className="flex items-center justify-start space-x-2">
                            {paymentStage > PaymentStageType.MAKE_PAYMENT && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {paymentStage === PaymentStageType.MAKE_PAYMENT && <ActiveStageIcon />}
                            {paymentStage < PaymentStageType.MAKE_PAYMENT && <InactiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${paymentStage > PaymentStageType.MAKE_PAYMENT ? "font-medium" : "text-sm"} ` +
                                    `${paymentStage === PaymentStageType.MAKE_PAYMENT ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Make Payment
                            </p>
                        </div>
                    </div>
                )}
                <div className={"flex w-full flex-1 flex-col" + `${paymentStage !== PaymentStageType.PROCESS_PAYMENT ? " pt-12" : ""}`} ref={divRef}>
                    {props.children}
                </div>
            </PageLayout>
        </>
    );
}

export default SendMoneyLayout;
