import ButtonComp from "../../../../../../../components/button/ButtonComp";
import { DeclineReason } from "../../../../hooks/state/pending-approval/usePendingPayments.types";
import GoogleIcon from "../../../../../../../components/google-icon";
import LencoSpinner from "../../../../../../../components/spinner";
import NewDropdownHead from "../../../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../../../components/new-dropdown-item";
// import PendingApproval from "..";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import UseDeclinePendingPayment from "../../../../hooks/state/pending-approval/useDeclinePendingPayment";

interface Props {
    transaction: Transaction;
    handleShowModal: () => void;
    handleUpdateState: () => void;
}

function StandAlonePendingApproval(props: Props): JSX.Element {
    const { declineReason, isDeclineTransactionLoading, handleIsApprove, handleDeclineTransaction } = UseDeclinePendingPayment({
        onComplete: () => props.handleUpdateState(),
    });

    return (
        <>
            <div className="flex items-center justify-center space-x-10">
                <NewDropdownHead
                    content={
                        <div className="flex flex-col items-center justify-center space-y-3">
                            <div className="flex max-h-12 min-h-12 min-w-[48px] max-w-[48px] items-center justify-center rounded-[10px] bg-error-backdrop">
                                {isDeclineTransactionLoading ? (
                                    <LencoSpinner size="sm" color="red" />
                                ) : (
                                    <GoogleIcon icon="cancel" size="lg" className="text-error" />
                                )}
                            </div>
                            <p className="text-xs font-normal text-error">Decline Payment</p>
                        </div>
                    }
                    color="red"
                    buttonType="tertiary"
                    dropdownWidth="max"
                    isNoArrow
                    isTriggerLower
                >
                    <NewDropdownItem
                        isDisabled={declineReason === DeclineReason.INCORRECT_DETAILS}
                        onClick={() =>
                            void handleDeclineTransaction({
                                reason: DeclineReason.INCORRECT_DETAILS,
                                transactionId: props.transaction.id,
                            })
                        }
                        color="red"
                        size="sm"
                    >
                        <p className="text-sm">Incorrect Details</p>
                    </NewDropdownItem>
                    <NewDropdownItem
                        isDisabled={declineReason === DeclineReason.INCORRECT_AMOUNT}
                        onClick={() =>
                            void handleDeclineTransaction({
                                reason: DeclineReason.INCORRECT_AMOUNT,
                                transactionId: props.transaction.id,
                            })
                        }
                        color="red"
                        size="sm"
                    >
                        <p className="text-sm">Incorrect Amount</p>
                    </NewDropdownItem>
                    <NewDropdownItem
                        isDisabled={declineReason === DeclineReason.WRONG_PURPOSE}
                        onClick={() =>
                            void handleDeclineTransaction({
                                reason: DeclineReason.WRONG_PURPOSE,
                                transactionId: props.transaction.id,
                            })
                        }
                        color="red"
                        size="sm"
                    >
                        <p className="text-sm">Wrong Purpose</p>
                    </NewDropdownItem>
                    <NewDropdownItem
                        isDisabled={declineReason === DeclineReason.OTHERS}
                        onClick={() =>
                            void handleDeclineTransaction({
                                reason: DeclineReason.OTHERS,
                                transactionId: props.transaction.id,
                            })
                        }
                        color="red"
                        size="sm"
                    >
                        <p className="text-sm">Others</p>
                    </NewDropdownItem>
                </NewDropdownHead>
                <ButtonComp
                    buttonType="tertiary"
                    color="transparent"
                    onClick={() => {
                        handleIsApprove();
                        props.handleShowModal();
                    }}
                >
                    <div className="flex flex-col items-center justify-center space-y-3">
                        <div className="flex max-h-12 min-h-12 min-w-[48px] max-w-[48px] items-center justify-center rounded-[10px] bg-success-backdrop">
                            <GoogleIcon icon="check_circle" size="lg" className="text-success" />
                        </div>
                        <p className="text-xs font-normal text-success">Approve Payment</p>
                    </div>
                </ButtonComp>
            </div>
        </>
    );
}

export default StandAlonePendingApproval;
