import { Link } from "react-router-dom";
import React from "react";
import { useAppSelector } from "../../../../redux/hooks";

interface Props {
    isLogin?: boolean;
}

function LoginFooter(props: Props) {
    const inviteURL = useAppSelector((state) => state.landing.inviteURL);

    return (
        <>
            <div className="py-8">
                {props.isLogin ? (
                    <p className="flex flex-row items-center justify-center space-x-1">
                        <span className="text-sm font-normal text-black-tertiary"> Already have an account?</span>
                        <Link to={inviteURL ? `/login/${inviteURL}` : "/login"}>
                            <span className="whitespace-nowrap text-sm font-medium text-black">Log In</span>
                        </Link>
                    </p>
                ) : (
                    <p className="flex flex-row items-center justify-center space-x-1">
                        <span className="text-sm font-normal text-black-tertiary">Don&apos;t have a Lenco account?</span>
                        <Link to={inviteURL ? `/signup/account/${inviteURL}` : "/signup"}>
                            <span className="whitespace-nowrap text-sm font-medium text-black">Sign Up</span>
                        </Link>
                    </p>
                )}
            </div>
        </>
    );
}

export default LoginFooter;
