import {
    ExportZambiaCollectionsSettlementsRequest,
    ExportZambiaCollectionsSettlementsResponse,
} from "../../Services/Collections/zambiaCollectionsSettlementApi.types";
import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { exportZambiaCollectionsSettlements } from "../../Services/Collections/zambiaCollectionsSettlementApi";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseExportZambiaCollectionsSettlementsInterface {
    isExportZambiaCollectionsSettlementsLoading: boolean;
    exportZambiaCollectionsSettlementsErrorMessage: string;
    handleExportZambiaCollectionsSettlements: (_data: ExportZambiaCollectionsSettlementsRequest) => void;
}

interface Props {
    onComplete: (_data: ExportZambiaCollectionsSettlementsResponse) => void;
}

function useExportZambiaCollectionsSettlements(props: Props): UseExportZambiaCollectionsSettlementsInterface {
    const dispatch = useDispatch();

    const [isExportZambiaCollectionsSettlementsLoading, setIsExportZambiaCollectionsSettlementsLoading] = useState(false);
    const [exportZambiaCollectionsSettlementsErrorMessage, setExportZambiaCollectionsSettlementsErrorMessage] = useState<string>("");

    const handleExportZambiaCollectionsSettlements = useCallback(
        async (_data: ExportZambiaCollectionsSettlementsRequest) => {
            try {
                setIsExportZambiaCollectionsSettlementsLoading(true);
                setExportZambiaCollectionsSettlementsErrorMessage("");
                const res = await exportZambiaCollectionsSettlements(_data);
                dispatch(messageTrue("Statement Requested Successful"));
                props.onComplete(res);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setExportZambiaCollectionsSettlementsErrorMessage(errorMessage);
            } finally {
                setIsExportZambiaCollectionsSettlementsLoading(false);
            }
        },
        [dispatch]
    );
    return {
        isExportZambiaCollectionsSettlementsLoading,
        exportZambiaCollectionsSettlementsErrorMessage,
        handleExportZambiaCollectionsSettlements,
    };
}

export default useExportZambiaCollectionsSettlements;
