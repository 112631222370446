import { GenericObject } from "../../helpers/types";
import { OtherDirectorStatus } from "./accountOpening.constants";
import Parsers from "../../utils/parsers";
import { UserRole } from "../userAccount.constants";
import { immerable } from "immer";
import joinArray from "../../utils/joinArray";

export default class SignatoryMin {
    [immerable] = true;

    constructor(
        public id: string,
        public userId: string | null,
        public firstName: string,
        public surname: string,
        public email: string,
        public isKeyContact: boolean,
        public isDirector: boolean,
        // public isSignatory: boolean,
        public isSignedUp: boolean,
        public isCompleted: boolean,
        public userRole: UserRole | null // public tempKeyContact = false, // public tempDirector = false
    ) {}

    static create(obj: GenericObject): SignatoryMin {
        return new SignatoryMin(
            Parsers.string(obj.id),
            Parsers.nullableString(obj.userId),
            Parsers.string(obj.firstName),
            Parsers.string(obj.surname),
            Parsers.string(obj.email),
            Parsers.boolean(obj.isKeyContact),
            Parsers.boolean(obj.isDirector),
            // Parsers.boolean(obj.isSignatory),
            Parsers.boolean(obj.isSignedUp),
            Parsers.boolean(obj.isCompleted),
            Parsers.nullableNumber(obj.userRole)
        );
    }

    get name(): string {
        return `${this.firstName} ${this.surname}`.trim();
    }

    get userRoleText(): string {
        switch (this.userRole) {
            case UserRole.BOTH:
                return "Initiator and Approver";
            case UserRole.APPROVER:
                return "Approver only";
            case UserRole.INITIATOR:
                return "Initiator only";
            default:
                return "";
        }
    }

    get signatoryDesignation(): string {
        const res = ["Signatory"];
        if (this.isDirector) {
            res.unshift("Director");
        }
        if (this.isKeyContact) {
            res.unshift("Key Contact");
        }
        return joinArray(res);
    }

    // used when passing SignatoryMin as OtherDirectorMin
    get status(): OtherDirectorStatus {
        if (this.isCompleted) {
            return OtherDirectorStatus.COMPLETED;
        }
        return OtherDirectorStatus.INVITE_PENDING;
    }

    get isPending(): boolean {
        return this.status === OtherDirectorStatus.INVITE_PENDING;
    }

    get statusText(): string {
        return this.isCompleted ? "Completed" : this.isSignedUp ? "Ongoing" : "Pending";
    }
}
