import {
    CurrentSignatoryRequests,
    abortCurrentSignatoryRequest,
    nextOfKinUpdate,
} from "../../../../services/nigeria-application/registered/currentSignatory.api";
import { useCallback, useState } from "react";

import { NextOfKinForm } from "../../../../services/nigeria-application/registered/currentSignatory.types";
import { Routes } from "../../../../../../../routes/routes.constants";
import { errorTrue, messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { updateCurrentSignatory } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

interface useNextOfKinInterface {
    submitNextOfKinError: string | null;
    isSubmitNextOfKinLoading: boolean | null;
    handleReset: () => void;
    handleSubmitNextOfKin: (data: NextOfKinForm) => Promise<void>;
}

type LocationState = {
    from?: string;
};

const useNextOfKin = (): useNextOfKinInterface => {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const [submitNextOfKinError, setSubmitNextOfKinError] = useState<string | null>(null);
    const [isSubmitNextOfKinLoading, setIsSubmitNextOfKinLoading] = useState<boolean | null>(false);

    const dispatch = useDispatch();

    const handleSubmitNextOfKin = useCallback(async (_data: NextOfKinForm) => {
        try {
            setSubmitNextOfKinError(null);
            setIsSubmitNextOfKinLoading(true);
            const res = await nextOfKinUpdate(_data);
            if (res.signatory) {
                dispatch(updateCurrentSignatory(res));
            }
            if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                dispatch(messageTrue("Business contact updated successfully"));
                return;
            }
            navigate(
                {
                    pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID,
                },
                {
                    state: {
                        from: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.NEXT_OF_KIN,
                    },
                }
            );
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
            setSubmitNextOfKinError(getErrorMessage(err));
        } finally {
            setIsSubmitNextOfKinLoading(false);
        }
    }, []);

    const handleReset = useCallback(() => {
        setSubmitNextOfKinError(null);
        setIsSubmitNextOfKinLoading(false);
        abortCurrentSignatoryRequest(CurrentSignatoryRequests.NEXT_OF_KIN_UPDATE);
    }, []);

    return {
        submitNextOfKinError,
        isSubmitNextOfKinLoading,
        handleReset,
        handleSubmitNextOfKin,
    };
};
export default useNextOfKin;
