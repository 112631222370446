import {
    AccountSignatoriesRequests,
    abortAccountSignatoriesRequest,
    removeOtherDirector,
} from "../../../../services/nigeria-application/registered/accountSignatories.api";
import { useCallback, useState } from "react";

import { RemoveOtherDirectorRequest } from "../../../../services/nigeria-application/registered/accountSignatories.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setAccountSignatories } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface useOtherDirectorsInterface {
    removeOtherDirectorError: string;
    isRemoveOtherDirectorLoading: boolean;
    handleReset: () => void;
    handleRemoveOtherDirector: (data: RemoveOtherDirectorRequest) => Promise<void>;
}
interface Props {
    onComplete: () => void;
}

const useRemoveOtherDirector = (props?: Props): useOtherDirectorsInterface => {
    const dispatch = useDispatch();

    const [removeOtherDirectorError, setRemoveOtherDirectorError] = useState<string>("");
    const [isRemoveOtherDirectorLoading, setIsRemoveOtherDirectorLoading] = useState<boolean>(false);

    const handleRemoveOtherDirector = useCallback(async (_data: RemoveOtherDirectorRequest) => {
        try {
            setRemoveOtherDirectorError("");
            setIsRemoveOtherDirectorLoading(true);
            const res = await removeOtherDirector(_data);
            dispatch(setAccountSignatories(res));
            dispatch(messageTrue({ message: `Other director successfully removed` }));
            props?.onComplete();
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
            setRemoveOtherDirectorError(getErrorMessage(err));
        } finally {
            setIsRemoveOtherDirectorLoading(false);
        }
    }, []);

    const handleReset = () => {
        setRemoveOtherDirectorError("");
        setIsRemoveOtherDirectorLoading(false);
        abortAccountSignatoriesRequest(AccountSignatoriesRequests.REMOVE_OTHER_DIRECTOR);
    };

    return {
        removeOtherDirectorError,
        isRemoveOtherDirectorLoading,
        handleReset,
        handleRemoveOtherDirector,
    };
};
export default useRemoveOtherDirector;
