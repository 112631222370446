import {
    ZambiaCollectionPaymentChannel,
    ZambiaSettlementStatus,
    ZambiaSettlementType,
} from "../../../../../models/zambia/zambiaCollectionRequest.constant";

export enum ZambiaCollectionsSettlementsFilters {
    DATE = "date",
    TYPE = "type",
    STATUS = "status",
    CHANNEL = "channel",
}

export const ZambiaCollectionsSettlementsFiltersArray = [
    ZambiaCollectionsSettlementsFilters.DATE,
    ZambiaCollectionsSettlementsFilters.STATUS,
    ZambiaCollectionsSettlementsFilters.CHANNEL,
    ZambiaCollectionsSettlementsFilters.TYPE,
];

export const ZambiaCollectionsSettlementsFiltersText = {
    [ZambiaCollectionsSettlementsFilters.DATE]: "Date",
    [ZambiaCollectionsSettlementsFilters.STATUS]: "Status",
    [ZambiaCollectionsSettlementsFilters.TYPE]: "Type",
    [ZambiaCollectionsSettlementsFilters.CHANNEL]: "Channel",
};
//Status
export const ZambiaCollectionsSettlementsStatusArray = [ZambiaSettlementStatus.SETTLED, ZambiaSettlementStatus.PENDING];

export const getZambiaCollectionsSettlementsStatusName = (_status: ZambiaSettlementStatus): string => {
    switch (_status) {
        case ZambiaSettlementStatus.SETTLED:
            return "Settled";
        case ZambiaSettlementStatus.PENDING:
            return "Pending";
        default:
            return "";
    }
};

export const ZambiaCollectionsSettlementsStatusOptions = ZambiaCollectionsSettlementsStatusArray.map((_status) => ({
    name: getZambiaCollectionsSettlementsStatusName(_status),
    value: _status,
}));

//Channel
export const ZambiaCollectionsSettlementsChannelArray = [
    ZambiaCollectionPaymentChannel.CARD,
    ZambiaCollectionPaymentChannel.BANK_ACCOUNT,
    ZambiaCollectionPaymentChannel.MOBILE_MONEY,
];

export const getZambiaCollectionChannelName = (_status: ZambiaCollectionPaymentChannel): string => {
    switch (_status) {
        case ZambiaCollectionPaymentChannel.CARD:
            return "Card";
        case ZambiaCollectionPaymentChannel.BANK_ACCOUNT:
            return "Bank Account";
        case ZambiaCollectionPaymentChannel.MOBILE_MONEY:
            return "Mobile Money";
        default:
            return "";
    }
};

export const ZambiaCollectionsSettlementsChannelOptions = ZambiaCollectionsSettlementsChannelArray.map((_status) => ({
    name: getZambiaCollectionChannelName(_status),
    value: _status,
}));

//Type
export const ZambiaCollectionsSettlementsTypeArray = [ZambiaSettlementType.INSTANT, ZambiaSettlementType.NEXT_DAY];

export const getZambiaCollectionsSettlementsTypeName = (_source: ZambiaSettlementType): string => {
    switch (_source) {
        case ZambiaSettlementType.INSTANT:
            return "Instant";
        case ZambiaSettlementType.NEXT_DAY:
            return "Next Day";
        default:
            return "";
    }
};

export const ZambiaCollectionsSettlementsTypeOptions = ZambiaCollectionsSettlementsTypeArray.map((_source) => ({
    name: getZambiaCollectionsSettlementsTypeName(_source),
    value: _source,
}));

//|=====done

export const ZambiaCollectionsSettlementsFiltersDefaultValues = {
    [ZambiaCollectionsSettlementsFilters.STATUS]: ZambiaSettlementStatus.PENDING,
    [ZambiaCollectionsSettlementsFilters.CHANNEL]: ZambiaCollectionPaymentChannel.CARD,
    [ZambiaCollectionsSettlementsFilters.TYPE]: ZambiaSettlementType.NEXT_DAY,
};

export const ZambiaCollectionsSettlementsFiltersOptions: {
    [type in ZambiaCollectionsSettlementsFilters]:
        | {
              name: string;
              value: ZambiaSettlementStatus | ZambiaCollectionPaymentChannel | ZambiaSettlementType;
          }[]
        | undefined;
} = {
    [ZambiaCollectionsSettlementsFilters.STATUS]: ZambiaCollectionsSettlementsStatusOptions,
    [ZambiaCollectionsSettlementsFilters.CHANNEL]: ZambiaCollectionsSettlementsChannelOptions,
    [ZambiaCollectionsSettlementsFilters.TYPE]: ZambiaCollectionsSettlementsTypeOptions,
    [ZambiaCollectionsSettlementsFilters.DATE]: undefined,
};

export const ZambiaCollectionsSettlementsFiltersData = ZambiaCollectionsSettlementsFiltersArray.map((_collectionRequest) => ({
    name: ZambiaCollectionsSettlementsFiltersText[_collectionRequest],
    value: _collectionRequest,
    options: ZambiaCollectionsSettlementsFiltersOptions[_collectionRequest],
}));
