import { BaseResponse, makeRequestWithSignal } from "../../../../helpers/request/makeRequest";
import { SetPasswordData, VerifyEmailData, VerifyEmailDataResponse, VerifyEmailOtpRequest, VerifyEmailOtpResponse } from "./email-verification.types";
import { abortRequest, getAbortControllerSignal } from "../../../../helpers/request/abortControllers";

import Parsers from "../../../../utils/parsers";

export enum EmailVerificationRequests {
    VERIFY_EMAIL = "account.verify-email",
    SET_PASSWORD = "account.set-password",
    VERIFY_EMAIL_OTP = "signup.verify-email-otp",
}

export const verifyEmail = async (data: VerifyEmailData): Promise<VerifyEmailDataResponse> => {
    const signal = getAbortControllerSignal(EmailVerificationRequests.VERIFY_EMAIL);

    const res = await makeRequestWithSignal("/accounts/verify-email", data, signal);

    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        username: Parsers.string(res.username),
        setPassword: Parsers.boolean(res.setPassword),
        previouslyVerified: Parsers.boolean(res.previouslyVerified),
    };
};

export const setPassword = async (data: SetPasswordData): Promise<BaseResponse> => {
    const signal = getAbortControllerSignal(EmailVerificationRequests.SET_PASSWORD);

    const res = await makeRequestWithSignal("/accounts/verify-email/set-password", data, signal);

    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};

export const verifyEmailOtp = async (data: VerifyEmailOtpRequest): Promise<VerifyEmailOtpResponse> => {
    const signal = getAbortControllerSignal(EmailVerificationRequests.VERIFY_EMAIL_OTP);

    const res = await makeRequestWithSignal("/accounts/verify-email/otp", data, signal);

    if (res instanceof Error) {
        throw res;
    }

    return {
        message: Parsers.string(res.message),
        username: Parsers.string(res.username),
        setPassword: Parsers.boolean(res.setPassword),
        previouslyVerified: Parsers.boolean(res.previouslyVerified),
    };
};

export function abortEmailVerificationRequest(type: EmailVerificationRequests): void {
    abortRequest(type);
}

export function abortAllEmailVerificationRequests(): void {
    Object.values(EmailVerificationRequests).forEach((type) => abortRequest(type));
}
