import AllAccountDetails from "../all-account-details";
import Card from "../../../../models/card";
import Currency from "../../../../models/currency";
import CustomerAccount from "../../../../models/customerAccount";
import NewDropdownItem from "../../../new-dropdown-item";
import UserAccount from "../../../../models/userAccount";

interface Props {
    data: UserAccount | CustomerAccount | Card;
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    message?: string;
    dataType?: string;
    currency?: Currency | undefined | null;
    isDisabled?: boolean;
    cardBalance?: number;
    showBalance?: boolean;
    onClick?: (e: string) => void;
}

function AllAccountItem(props: Props): JSX.Element {
    return (
        <NewDropdownItem
            key={props.data.id}
            size={props.size || "2xl"}
            onClick={() => props.onClick && props.onClick(props.data.id)}
            dataType={props.dataType}
            isDisabled={props.isDisabled}
        >
            <AllAccountDetails
                data={props.data}
                size={props.size === "md" || props.size === "sm" ? "2xs" : "xs"}
                message={props.message}
                currency={props.currency}
                dataType={props.dataType}
                cardBalance={props.cardBalance}
                showBalance={props.showBalance}
            />
        </NewDropdownItem>
    );
}

export default AllAccountItem;
