import { useCallback, useEffect } from "react";

import { IRootState } from "../../../../../../../redux/rootReducer";
import useElectricityPurchaseVerifyAccount from "./useElectricityPurchaseVerifyAccount";
import { useDispatch, useSelector } from "react-redux";
import {
    resetElectricityVerifiedAccountName,
    setElectricityPurchaseMeterNumber,
} from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";

interface UseElectricityPurchasePayToInterface {
    meterNumber: string;
    electricityPurchaseVerifyAccountError: string;
    isElectricityPurchaseVerifyAccountLoading: boolean;

    handleSetMeterNumber: (_vendor: string) => void;
    handleSetMeterNumberInstant: (_vendor: string) => void;
}

function useElectricityPurchasePayTo(): UseElectricityPurchasePayToInterface {
    const { electricityPurchaseVerifyAccountError, handleElectricityPurchase, handleResetError, isElectricityPurchaseVerifyAccountLoading } =
        useElectricityPurchaseVerifyAccount();
    const dispatch = useDispatch();

    const productId = useSelector((state: IRootState) => state.electricityPurchase.electricityPurchaseDetails.productId);
    const meterNumber = useSelector((state: IRootState) => state.electricityPurchase.meterNumber);
    const electricityVerifiedAccountName = useSelector((state: IRootState) => state.electricityPurchase.electricityVerifiedAccountName);

    useEffect(() => {
        if (meterNumber.length < 10 || electricityVerifiedAccountName) return;
        void handleElectricityPurchase({
            productId,
            accountId: meterNumber,
        });
    }, [meterNumber, electricityVerifiedAccountName]);

    // useEffect(() => {
    // if (meterNumber.length === 10) return;
    // dispatch(resetElectricityVerifiedAccountName());
    // }, [meterNumber]);

    useEffect(() => {
        if (meterNumber.length !== 9) return;
        handleResetError();
    }, [meterNumber]);

    const handleSetMeterNumber = useCallback((_meterNumber: string) => {
        dispatch(resetElectricityVerifiedAccountName());
        dispatch(setElectricityPurchaseMeterNumber(_meterNumber));
    }, []);

    const handleSetMeterNumberInstant = useCallback((_meterNumber: string) => {
        dispatch(setElectricityPurchaseMeterNumber(_meterNumber));
    }, []);

    return {
        meterNumber,
        electricityPurchaseVerifyAccountError,
        isElectricityPurchaseVerifyAccountLoading,

        handleSetMeterNumber,
        handleSetMeterNumberInstant,
    };
}

export default useElectricityPurchasePayTo;
