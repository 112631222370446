import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { DropdownItem as DropdownItemType, DropdownItemValueType } from "../../../../helpers/types";

interface Props<T extends DropdownItemValueType> {
    img: string | null;
    options: Array<DropdownItemType<T>>;
    isDisabled?: boolean;

    onClick: (_value: T) => void;
}

function CountrySelect<T extends DropdownItemValueType>(props: Props<T>) {
    return (
        <>
            <Dropdown placement="bottom-end" isDisabled={props.isDisabled}>
                <DropdownTrigger>
                    <Avatar isBordered as="button" className="h-6 w-6 transition-transform" src={props.img || undefined} />
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="flat">
                    {props.options.map((_option, _index) => (
                        <DropdownItem key={_index} className="h-14 gap-2" onClick={() => props.onClick(_option.value)}>
                            <p>{_option.text}</p>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default CountrySelect;
