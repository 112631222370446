import React, { useState } from "react";
import Transaction from "../../../../../../../models/transaction";
// import Clipboard from "../../../../../../../components/clipboard";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardNIP({ dataType, transaction }: Props): JSX.Element {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    return (
        <>
            {transaction && transaction.nipSessionId && (
                <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                    <p className="text-sm text-black-secondary" data-type={dataType}>
                        NIP Session ID
                    </p>
                    <div className="space-y-3">
                        <p className="whitespace-normal break-words text-right text-sm font-medium text-black-secondary" data-type={dataType}>
                            {transaction.nipSessionId}
                        </p>

                        {transaction.nipSessionId && (
                            <div className="flex w-full justify-end text-sm text-blue">
                                {/* <Clipboard className="[&>button]:min-w-max" codeString={transaction.nipSessionId}>
                                    <span>Copy ID</span>
                                </Clipboard> */}
                                <CopyToClipboard text={transaction.nipSessionId}>
                                    <div className="h-5">
                                        {!isCopied && (
                                            <div className="flex min-w-max cursor-pointer items-center justify-center space-x-1" onClick={handleCopy}>
                                                <span className="!text-base">
                                                    <span className="material-symbols-outlined !text-base">content_copy</span>
                                                </span>
                                                <span className="text-sm font-medium text-blue">Copy ID</span>
                                            </div>
                                        )}
                                        {isCopied && (
                                            <div className="flex min-w-max animate-iconThree cursor-pointer items-center justify-center space-x-1">
                                                <span className="!text-base">
                                                    <span className="material-symbols-outlined text-xl text-black-tertiary">check</span>
                                                </span>
                                                <span className="text-sm font-medium text-black-tertiary">Copied</span>
                                            </div>
                                        )}
                                    </div>
                                </CopyToClipboard>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardNIP;
