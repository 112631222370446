import React, { useState } from "react";
import AddWhitelistIpAddressTray from "./Components/Tray/AddWhitelistIpAddressTray";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import useRemoveZambiaApiAccessKeyWhitelistIPAddress from "../../../../../hookss/ApiAccessKeySettings/useRemoveZambiaApiAccessKeyWhitelistIPAddress";
import { useSelector } from "react-redux";
import GoogleIcon from "../../../../../../../../components/google-icon";
import { TableHead } from "../../../../../../../../components/Table/Type/HeadRow";
import { TableColumnType } from "../../../../../../../../components/Table/Type/TableColumnType";
import { TextType } from "../../../../../../../../components/Table/Type/TextType";
import Table from "../../../../../../../../components/Table";
import ConfirmDeleteModal from "./Components/Modals/ConfirmDeleteModal";

const whiteListedIpHeader: TableHead = [
    { text: "IP Address", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "Status", pos: 2, headType: TableColumnType.STATUS },
    { text: "Action", pos: 3, headType: TableColumnType.ACTION },
];

function WhitelistedIpAddress(): JSX.Element {
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<{
        id: string;
        ip: string;
    } | null>(null);

    const { isRemoveZambiaApiAccessKeyWhitelistIPAddressLoading, handleRemoveZambiaApiAccessKeyWhitelistIPAddress } =
        useRemoveZambiaApiAccessKeyWhitelistIPAddress();

    const selectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.selectedApiAccessKey);

    const [isAddWhiteListAddressTrayOpen, setIsAddWhiteListAddressTrayOpen] = useState(false);

    return (
        <>
            <ConfirmDeleteModal
                isActive={!!showConfirmDeleteModal}
                isLoading={isRemoveZambiaApiAccessKeyWhitelistIPAddressLoading}
                onDelete={async () => {
                    if (showConfirmDeleteModal) await handleRemoveZambiaApiAccessKeyWhitelistIPAddress(showConfirmDeleteModal);
                    setShowConfirmDeleteModal(null);
                }}
                title="Remove Address"
                subText={<span>Are you sure you want to delete {showConfirmDeleteModal?.ip}&apos;s IP? </span>}
                toggler={() => setShowConfirmDeleteModal(null)}
            />
            <AddWhitelistIpAddressTray
                apiAccessKeyId={selectedApiAccessKey?.id || ""}
                active={isAddWhiteListAddressTrayOpen}
                toggler={() => setIsAddWhiteListAddressTrayOpen(false)}
            />

            <div className="flex w-full flex-col space-y-6">
                <div className="flex w-full flex-col justify-between space-y-4 rounded-lg bg-white px-4 py-4 2xs:flex-row 2xs:items-center 2xs:space-x-12 2xs:space-y-0">
                    <div className="space-y-1">
                        <p className="text-base font-medium"> Whitelisted IP Address</p>
                        <p className="text-sm text-black-tertiary">Only requests from the IP addresses on the list below will be granted access </p>
                    </div>
                    <div className="w-full 2xs:max-w-max">
                        <ButtonComp
                            color="blue"
                            type="button"
                            buttonType="tertiary"
                            size="md"
                            fullWidth
                            disable={!selectedApiAccessKey?.isAccessible}
                            func={() => setIsAddWhiteListAddressTrayOpen(true)}
                        >
                            <div className="flex items-center space-x-2 rounded-md bg-blue-backdrop px-2 py-1 text-xs font-medium">
                                <GoogleIcon icon="add_circle" className="stroke-current" size="xs" />
                                <p className="font-medium">Add IP</p>
                            </div>
                        </ButtonComp>
                    </div>
                </div>{" "}
                <Table
                    heads={whiteListedIpHeader}
                    dataType={"TERMINAL_TRANSACTION_DATATYPE"}
                    isLoading={!!isRemoveZambiaApiAccessKeyWhitelistIPAddressLoading}
                    noRecordText="You have no IP address"
                    rows={
                        selectedApiAccessKey?.ipWhitelist?.map((_ipAddress) => ({
                            onRecordClick: () => undefined,
                            record: [
                                {
                                    key: "IP Address",
                                    text: _ipAddress,
                                },
                                { key: "Status", text: "", textType: TextType.CARD_STATUS_ACTIVE },
                                {
                                    key: "Action",
                                    text: "",
                                    textType: TextType.EDIT_DELETE,
                                    onDelete: () => {
                                        setShowConfirmDeleteModal({
                                            id: selectedApiAccessKey.id,
                                            ip: _ipAddress,
                                        });
                                    },
                                },
                            ],
                        })) || []
                    }
                />
            </div>
        </>
    );
}

export default WhitelistedIpAddress;
