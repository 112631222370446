import { HintType, ListStyle } from "../../../../../element/hint/hint.constant";
import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../../services/nigeria-application/registered/company.types";
import Hint from "../../../../../element/hint";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import MultipleRefereeInviteForm from "./RefereeInviteForms/MultipleRefereeInviteForm";
import RefereeCard from "./RefereeCard";
import { Routes } from "../../../../../../../../../routes/routes.constants";
import SingleRefereeInviteForm from "./RefereeInviteForms/SingleRefereeInviteForm";
import { useSelector } from "react-redux";

const RefereeChoiceHints: CompanyHint[] = [
    {
        index: 0,
        span: <span>Two (2) referees are required.</span>,
    },
    {
        index: 0,
        span: <span>The referees must be corporate account holders not individuals.</span>,
    },
    {
        index: 0,
        span: <span>A referee{"'"}s account must not be less than 6 months old.</span>,
    },
];
type LocationState = {
    from?: string;
    beforeFrom?: string;
};

function RefereesInvite(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const referees = useSelector((state: IRootState) => state.application.referees);
    const accountOpening = useSelector((state: IRootState) => state.application.init);

    // useEffect(() => {
    // if (accountOpening?.applicationMin.requestedProAccount) navigate(Routes.ACCOUNT_OPENING.STATUS);
    // }, [accountOpening]);

    const handleBack = useCallback(() => {
        navigate(
            {
                pathname:
                    locationState?.from ||
                    (accountOpening?.isCreator ? Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS : Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT),
            },
            {
                state: {
                    from: locationState?.beforeFrom,
                },
            }
        );
    }, [location, accountOpening]);

    const handleContinue = useCallback(() => {
        navigate(
            {
                pathname: Routes.ACCOUNT_OPENING.REVIEW,
            },
            {
                state: {
                    from: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.INVITE,
                },
            }
        );
    }, []);

    return (
        <>
            {accountOpening && (
                <div className="hover:sc flex w-full flex-row items-start justify-start space-x-6">
                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                        {(!accountOpening.isCreator || accountOpening.applicationMin.requestedProAccount) && (
                            <>
                                <ApplicationHeader
                                    header="Invite your Referees"
                                    subTitle="Please inform your referees to look out for a reference letter from Lenco"
                                    progress={
                                        accountOpening.applicationMin.requestedProAccount ? 100 : accountOpening.progress.refereesCompletedProgress()
                                    }
                                >
                                    <div className="grid w-full grid-cols-2 gap-4">
                                        {referees &&
                                            referees.length === 2 &&
                                            referees.map((_referee, index) => <RefereeCard data={_referee} key={index} isReview />)}
                                        {(!referees || referees?.length < 2) && (
                                            <>
                                                <RefereeCard isNull isReview />
                                                <RefereeCard isNull isReview />
                                            </>
                                        )}
                                    </div>
                                </ApplicationHeader>
                                <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                    <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                        <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                            <span>Back</span>
                                        </ButtonComp>
                                    </div>
                                    <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                        <ButtonComp
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            func={() => navigate(Routes.ACCOUNT_OPENING.STATUS)}
                                            fullWidth
                                        >
                                            <span>Return To Application</span>
                                        </ButtonComp>
                                    </div>
                                </div>
                            </>
                        )}

                        {accountOpening.isCreator && !accountOpening.applicationMin.requestedProAccount && (
                            <div className="w-full">
                                {(referees?.length || 0) < 1 && <MultipleRefereeInviteForm />}
                                {referees?.length === 1 && <SingleRefereeInviteForm />}
                                {referees?.length === 2 && (
                                    <>
                                        <ApplicationHeader
                                            header="Invite your Referees"
                                            subTitle="Please inform your referees to look out for a reference letter from Lenco"
                                            progress={
                                                accountOpening.applicationMin.requestedProAccount
                                                    ? 100
                                                    : accountOpening.progress.refereesCompletedProgress()
                                            }
                                        >
                                            <div className="flex w-full flex-col space-y-4">
                                                {referees?.map((_referee, index) => <RefereeCard key={index} data={_referee} />)}
                                            </div>
                                        </ApplicationHeader>
                                        <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                            <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                <ButtonComp
                                                    type="button"
                                                    color="grey"
                                                    ripple="light"
                                                    buttonType="secondary"
                                                    func={handleBack}
                                                    fullWidth
                                                >
                                                    <span>Back</span>
                                                </ButtonComp>
                                            </div>
                                            <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                <ButtonComp
                                                    type="button"
                                                    color="black"
                                                    ripple="light"
                                                    buttonType="primary"
                                                    func={handleContinue}
                                                    fullWidth
                                                >
                                                    <span>Review Application</span>
                                                </ButtonComp>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    {accountOpening?.isCreator && <Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={RefereeChoiceHints} />}
                </div>
            )}
        </>
    );
}

export default RefereesInvite;
