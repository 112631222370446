import React, { useEffect, useRef, useState } from "react";

interface Props {
    show?: boolean;
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | undefined;
    color: string;
    children: React.ReactNode;
    dataType?: string | null;
    fullBorder?: boolean;
    borderSmall?: boolean;
    borderXSmall?: boolean;
}

function Ripples(props: Props): JSX.Element {
    const [isRipple, setIsRipple] = useState<boolean>(false);
    const [coords, setCoords] = useState<{ x: number; y: number }>({ x: -1, y: -1 });
    const [parentOffset, setParentOffset] = useState<{ x: number; y: number }>({ x: -1, y: -1 });
    const domNode = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const timeOut = setTimeout(() => setIsRipple(false), 1000);
        if (!isRipple) {
            setCoords({ x: -1, y: -1 });
        }
        return () => {
            clearTimeout(timeOut);
        };
    }, [isRipple]);

    useEffect(() => {
        if (!domNode) return;
        setParentOffset({ x: domNode.current?.getBoundingClientRect().left as number, y: domNode.current?.getBoundingClientRect().top as number });
    }, [domNode, domNode.current, domNode.current?.getBoundingClientRect().left, domNode.current?.getBoundingClientRect().top]);

    const handleClick = (e: React.MouseEvent) => {
        setCoords({ x: e.clientX - parentOffset.x, y: e.clientY - parentOffset.y });
        setIsRipple(true);
    };

    return (
        <>
            <div
                className={
                    `relative h-full w-full ` +
                    ` ${props.show ? "overflow-hidden" : ""}` +
                    ` ${props.show && props.fullBorder ? "rounded-full" : ""}` +
                    ` ${props.show && props.borderSmall ? "rounded-md" : ""}` +
                    ` ${props.show && props.borderXSmall ? "rounded-[4px]" : ""}` +
                    ` ${props.show && !props.fullBorder && !props.borderSmall && !props.borderXSmall ? (props.size === "lg" || props.size === "xl" || props.size === "2xl" ? "rounded-lg" : "rounded-md") : ""}`
                }
                onClick={(e) => handleClick(e)}
                ref={domNode}
                data-type={props.dataType && props.dataType}
            >
                {props.show && isRipple && (
                    <div
                        className="pointer-events-none absolute z-10 h-6 w-6 animate-buttonRipple rounded-full bg-white"
                        style={{ transform: "translate(-50%, -50%)", left: coords.x, top: coords.y, backgroundColor: props.color }}
                        data-type={props.dataType && props.dataType}
                    ></div>
                )}
                {props.children}
            </div>
        </>
    );
}

export default Ripples;
