import Checkbox, { CheckType } from "./Checkbox/Checkbox";
import React, { useEffect, useState } from "react";

import { CancelPaymentOption } from "../Type/CancelPaymentOption";
import DropdownHead from "../../dropdown/dropdown-components/dropdown-head";
import DropdownLink from "../../dropdown/dropdown-components/dropdown-link";
// import DropdownHead from "../../dropdown/dropdown-components/DropdownHead";
// import DropdownLink from "../../dropdown/dropdown-components/DropdownLink";
import { IsCancelledType } from "../Type/IsCancelledType";

interface Props {
    id?: string;
    isApproved?: boolean;
    isCancelled?: IsCancelledType;
    isCancelTransactionLoading?: boolean;
    onOptionSelect: (id: string | null) => void;
    onApproveSelect: (id: string | null) => void;
    options: CancelPaymentOption[];
}

function ApprovePayment(props: Props): JSX.Element {
    const [isHoverCancel, setIsHoverCancel] = useState<boolean>(false);
    const [isCancelled, setIsCancelled] = useState<boolean>(false);

    const [isHoverApprove, setIsHoverApproval] = useState<boolean>(false);
    const [isApproved, setIsApproved] = useState<boolean>(false);

    useEffect(() => {
        if (props.isCancelled) {
            setIsCancelled(!!props.isCancelled);
            setIsApproved(false);
            props.onApproveSelect?.(null);
        } else {
            setIsCancelled(false);
        }
        return () => {};
    }, []);

    useEffect(() => {
        if (props.isApproved) {
            setIsApproved(!!props.isApproved);
            setIsCancelled(false);
            props.onOptionSelect(null);
        } else {
            setIsApproved(false);
        }
        return () => {};
    }, []);

    return (
        <div className="mx-auto flex w-max flex-row justify-between space-x-4">
            <div
                className="mx-auto flex w-max cursor-pointer flex-col items-center justify-center"
                onMouseEnter={() => setIsHoverCancel(true)}
                onMouseLeave={() => setIsHoverCancel(false)}
                onClick={() => {
                    if (isCancelled) {
                        props?.onOptionSelect && props.onOptionSelect(null);
                        setIsCancelled(false);
                    }
                }}
            >
                <DropdownHead
                    placeholder={
                        <div
                            className={
                                `center flex h-full items-center justify-center space-x-1 bg-red-300 text-xs font-normal` +
                                `${isCancelled ? "text-error" : "text-current"}`
                            }
                        >
                            <Checkbox type={CheckType.ERROR} id={"props.transaction.id"} checked={isCancelled} size="sm" readOnly />
                            <span
                                className={`h-full border-r pr-2 ${isCancelled ? "border-error-quin text-error-secondary" : "border-grey-secondary"} flex items-center`}
                            >
                                Cancel
                            </span>
                        </div>
                    }
                    noYPadding
                    borderColor={isCancelled ? "border-error-quin" : "border-grey-secondary"}
                    size="xs"
                    clickAndClose
                    placement="right"
                    isLoading={props.isCancelTransactionLoading}
                    color="red"
                    isHover={!!isHoverCancel || !!isCancelled}
                    isSelected={!!isCancelled}
                    noOutlineBorder={false}
                    outline
                    fitDropdown
                >
                    {props.options.map((_el, idx) => (
                        <DropdownLink
                            key={idx}
                            noHover={_el.id === props.isCancelled?.id}
                            onClick={() => {
                                setIsCancelled(true);
                                props?.onOptionSelect(_el.id);
                                setIsApproved(false);
                                props.onApproveSelect?.(null);
                            }}
                            redHover
                            color="red"
                        >
                            <div className="flex items-center justify-start px-4 text-sm">{_el.text}</div>
                        </DropdownLink>
                    ))}
                </DropdownHead>
            </div>
            <div
                className="flex cursor-pointer flex-col items-center justify-center space-y-2"
                onMouseEnter={() => setIsHoverApproval(true)}
                onMouseLeave={() => setIsHoverApproval(false)}
                onClick={() => {
                    if (isApproved) {
                        props.onApproveSelect(null);
                        setIsApproved(false);
                    } else {
                        if (props.id) props.onApproveSelect(props.id);
                        setIsApproved(true);
                        setIsCancelled(false);
                        props.onOptionSelect(null);
                    }
                }}
            >
                <div
                    className={
                        `flex h-8 items-center justify-center rounded-lg border px-2 duration-150 ease-in-out ` +
                        `${isApproved ? "border-success-quin bg-success-backdrop text-success" : "border-grey-secondary text-black-tertiary"} ` +
                        `${isHoverApprove ? "bg-success-backdrop text-success" : ""} `
                    }
                >
                    <Checkbox
                        type={CheckType.SUCCESS}
                        text={<span className={`text-xs ${isApproved || isHoverApprove ? "text-success" : "text-black-tertiary"}`}>Approve</span>}
                        id={"approveId"}
                        checked={isApproved}
                        size="sm"
                        readOnly
                    />
                </div>
            </div>
        </div>
    );
}

export default ApprovePayment;
