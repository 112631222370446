import { PaymentStageType, PaymentType } from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { setPaymentStage, setPaymentType } from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { useDispatch, useSelector } from "react-redux";

import BulkTransferIcon from "../../../../../assets/images/dashboard/send-money/bulk-transfer.png";
import FundCardIcon from "../../../../../assets/images/dashboard/send-money/fund-card.png";
import { IRootState } from "../../../../../redux/rootReducer";
import SectionCard from "../Cards/SectionCard";
import SingleTransferIcon from "../../../../../assets/images/dashboard/send-money/single-transfer.png";
import TransferBtwAccountsIcon from "../../../../../assets/images/dashboard/send-money/transfer-between-accounts.png";

function TypeOfTransfer(): JSX.Element {
    const dispatch = useDispatch();
    const main = useSelector((state: IRootState) => state.init.main);
    const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-8">
                <div className="flex w-full flex-col items-center justify-center space-y-2">
                    <span className="text-xl font-medium text-black md:text-2xl">Choose a type of transfer</span>
                    {/* <span className="text-sm text-black-secondary md:text-base">Select a mode of transfer</span> */}
                </div>
                <div className="flex w-full flex-col justify-center space-y-6 py-2">
                    <SectionCard
                        text="Single Transfer"
                        subtext="Send money to a recipient"
                        icon={SingleTransferIcon}
                        func={() => {
                            dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
                            dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
                        }}
                    />
                    {main && main.companyDetails.accounts.length > 1 && (
                        <SectionCard
                            text="Transfer Between Accounts"
                            subtext="Send money to your sub-account"
                            icon={TransferBtwAccountsIcon}
                            func={() => {
                                dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
                                dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
                            }}
                        />
                    )}
                    {isNigeria && (
                        <SectionCard
                            text="Create a Bulk Transfer"
                            subtext="Send to multiple recipients or sub-accounts all at once"
                            icon={BulkTransferIcon}
                            func={() => {
                                dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
                                dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
                            }}
                        />
                    )}
                    {isNigeria && (
                        <SectionCard
                            text="Fund Card"
                            subtext="Fund your prepaid corporate card"
                            icon={FundCardIcon}
                            func={() => {
                                dispatch(setPaymentType(PaymentType.FUND_CARDS));
                                dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default TypeOfTransfer;
