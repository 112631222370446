import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class CardRequestedBy {
    [immerable] = true;

    constructor(
        public id: string,
        public firstName: string,
        public lastName: string
    ) {}

    static create(obj: GenericObject): CardRequestedBy {
        return new CardRequestedBy(Parsers.string(obj.id), Parsers.string(obj.firstName), Parsers.string(obj.lastName));
    }
}
