import React, { useEffect } from "react";
import {
    ZambiaApiAccessKeySettingsTabText,
    ZambiaApiAccessKeySettingsTabs,
} from "../../../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice.types";
import {
    resetSelectedZambiaApiAccessKey,
    resetTempSelectedZambiaApiAccessKey,
    resetZambiaApiAccessKeySecretKey,
    resetZambiaApiAccessKeyTab,
    setZambiaApiAccessKeyTab,
    setZambiaApiAccessKeysStageToList,
} from "../../../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import { ToastType } from "../../../../../../../../helpers/AppConstants";
import MessageToasts from "../../../../../../../../components/general/MessageToasts/MessageToasts";
import TabButton from "../../../../../../../../components/TabButton";

interface Props {
    children: React.ReactNode;
}

function ApiSettingsLayout(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const selectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.selectedApiAccessKey);
    const apiAccessKeySettingTab = useSelector((state: IRootState) => state.zambiaApiAccessKeys.apiAccessKeySettingTab);

    useEffect(() => {
        return () => {
            dispatch(setZambiaApiAccessKeysStageToList());
            dispatch(resetZambiaApiAccessKeyTab());
            dispatch(resetZambiaApiAccessKeySecretKey());
            dispatch(resetSelectedZambiaApiAccessKey());
            dispatch(resetTempSelectedZambiaApiAccessKey());
        };
    }, []);

    return (
        <>
            <div className="w-full">
                <TabButton
                    buttons={[
                        {
                            text: "APIs",
                            onClick: () => {
                                dispatch(setZambiaApiAccessKeysStageToList());
                                dispatch(resetZambiaApiAccessKeyTab());
                                dispatch(resetZambiaApiAccessKeySecretKey());
                                dispatch(resetSelectedZambiaApiAccessKey());
                                dispatch(resetTempSelectedZambiaApiAccessKey());
                            },
                            isActive: true,
                            subTab: {
                                text: `${selectedApiAccessKey?.name}`,
                                onClose: (e) => {
                                    e?.preventDefault();
                                    e?.stopPropagation();
                                    dispatch(setZambiaApiAccessKeysStageToList());
                                    dispatch(resetZambiaApiAccessKeyTab());
                                    dispatch(resetZambiaApiAccessKeySecretKey());
                                    dispatch(resetSelectedZambiaApiAccessKey());
                                    dispatch(resetTempSelectedZambiaApiAccessKey());
                                },
                            },
                            showSubTab: true,
                        },
                    ]}
                />
                <div className="py-4">
                    <TabButton
                        buttons={ZambiaApiAccessKeySettingsTabs.map((_tab) => ({
                            text: ZambiaApiAccessKeySettingsTabText[_tab],
                            onClick: () => dispatch(setZambiaApiAccessKeyTab(_tab)),
                            isActive: _tab === apiAccessKeySettingTab,
                        }))}
                    />
                </div>

                {selectedApiAccessKey?.isDisabledByLenco && (
                    <div className="max-w-2xl pb-4">
                        <MessageToasts
                            toastMessage="This API key has been disabled. Please contact Lenco support team for assistance."
                            toastType={ToastType.ERROR}
                        />
                    </div>
                )}
                <div className="flex w-full items-start justify-start space-x-12">
                    <div className="w-full max-w-2xl pb-8">{props.children}</div>
                </div>
            </div>
        </>
    );
}

export default ApiSettingsLayout;
