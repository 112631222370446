import { NigeriaRegisteredSections, NigeriaUnregisteredSections } from "../services/nigeria-application/nigeria-application.types";

import AccountOpeningProgress from "../../../../models/account-opening/progress/accountOpeningProgress";
import AgreementsIcon from "../../../../assets/images/dashboard/application/agreements.png";
import { ApplicationBusinessType } from "../services/application.constants";
import { ApplicationPage } from "../services/application.types";
import BusinessDetailsIcon from "../../../../assets/images/dashboard/application/business-details.png";
import BusinessDocumentIcon from "../../../../assets/images/dashboard/application/business-documents.png";
import CompletedIcon from "../../../../assets/images/dashboard/application/reviewed.png";
// import CompletedIfcon from "../../../../assets/images/dashboard/application/reviewed.png";
import InviteRefereesIcon from "../../../../assets/images/dashboard/application/invite-referees.png";
import NewAccountOpeningProgress from "../../../../models/account-opening/progress/newAccountOpeningProgress";
import PersonalIcon from "../../../../assets/images/dashboard/application/personal.png";
import { Routes } from "../../../../routes/routes.constants";
import SignatoriesIcon from "../../../../assets/images/dashboard/application/account-directors.png";
import { ZambiaUnregisteredSections } from "../services/zambia-application/zambia-application.types";

export function getIcon(page: ApplicationPage): { src: string; alt: string } {
    switch (page.value) {
        case NigeriaRegisteredSections.COMPANY:
        case NigeriaUnregisteredSections.BUSINESS:
            return { src: BusinessDetailsIcon, alt: "icon to depict a company" };
        case NigeriaRegisteredSections.SIGNATORY:
            return { src: SignatoriesIcon, alt: "icon to depict signatories" };
        case NigeriaRegisteredSections.PERSONAL:
        case NigeriaUnregisteredSections.PERSONAL:
            return { src: PersonalIcon, alt: "icon to depict a person" };
        case NigeriaRegisteredSections.REFEREES:
            return { src: InviteRefereesIcon, alt: "icon to depict referees" };
        case NigeriaRegisteredSections.UPLOAD:
        case NigeriaRegisteredSections.DOCUMENT:
        case ZambiaUnregisteredSections.DOCUMENT:
            return { src: BusinessDocumentIcon, alt: "icon to depict a document to sign" };
        case NigeriaRegisteredSections.SIGNATURE:
        case NigeriaRegisteredSections.AGREEMENT:
            return { src: AgreementsIcon, alt: "icon to depict an agreement" };
        case NigeriaUnregisteredSections.IDENTITY:
            return { src: BusinessDocumentIcon, alt: "icon to depict a document to sign" };
        default:
            return { src: "", alt: "" };
    }
}

export function getPreviewIcon(page: ApplicationPage, progress: NewAccountOpeningProgress): { src: string; alt: string } {
    switch (page.value) {
        case NigeriaRegisteredSections.COMPANY:
        case NigeriaUnregisteredSections.BUSINESS:
            if (isCompleted(page, progress)) {
                return { src: CompletedIcon, alt: "icon to depict a completed section" };
            } else {
                return getIcon(page);
            }
        case NigeriaRegisteredSections.SIGNATORY:
        case NigeriaUnregisteredSections.PERSONAL:
            if (isCompleted(page, progress)) {
                return { src: CompletedIcon, alt: "icon to depict a completed section" };
            } else {
                return getIcon(page);
            }
        case NigeriaRegisteredSections.REFEREES:
            if (isCompleted(page, progress)) {
                return { src: CompletedIcon, alt: "icon to depict a completed section" };
            } else {
                return getIcon(page);
            }
        case NigeriaRegisteredSections.UPLOAD:
        case NigeriaRegisteredSections.DOCUMENT:
        case ZambiaUnregisteredSections.DOCUMENT:
            if (isCompleted(page, progress)) {
                return { src: CompletedIcon, alt: "icon to depict a completed section" };
            } else {
                return getIcon(page);
            }
        case NigeriaRegisteredSections.SIGNATURE:
        case NigeriaRegisteredSections.AGREEMENT:
            if (isCompleted(page, progress)) {
                return { src: CompletedIcon, alt: "icon to depict a completed section" };
            } else {
                return getIcon(page);
            }
        case NigeriaUnregisteredSections.IDENTITY:
            if (isCompleted(page, progress)) {
                return { src: CompletedIcon, alt: "icon to depict a completed section" };
            } else {
                return getIcon(page);
            }
        default:
            return { src: "", alt: "" };
    }
}

export function isCompleted(page: ApplicationPage, progress: NewAccountOpeningProgress): boolean {
    switch (page.value) {
        case NigeriaUnregisteredSections.BUSINESS:
            return progress.isBusinessCompleted();
        // case NigeriaUnregisteredSections.IDENTITY:
        // return progress.isIdentityCompleted();
        case NigeriaUnregisteredSections.PERSONAL:
            return progress.isPersonalCompleted();
        case NigeriaRegisteredSections.COMPANY:
            return progress.isBusinessCompleted();
        case NigeriaRegisteredSections.SIGNATORY:
            // return progress.hasInvitedSignatories()
            // return isCreator ? progress.isSignatoriesCompleted() : progress.isCurrentSignatoryCompleted();
            return progress.isSignatoriesCompleted();
        case NigeriaRegisteredSections.REFEREES:
            return progress.isRefereesCompleted();
        case NigeriaRegisteredSections.UPLOAD:
        case NigeriaRegisteredSections.DOCUMENT:
        case ZambiaUnregisteredSections.DOCUMENT:
            return progress.isDocumentsCompleted();
        case NigeriaRegisteredSections.SIGNATURE:
        case NigeriaRegisteredSections.AGREEMENT:
            // return progress.isAgreementsCompleted()
            // return isCreator ? progress.isAgreementsCompleted() : progress.isCurrentSignatoryAgreementCompleted();
            return progress.isAgreementsCompleted();

        default:
            return false;
    }
}

export function continuationRoute(
    page: ApplicationPage[],
    progress: AccountOpeningProgress,
    applicationBusinessType: ApplicationBusinessType
): string {
    //unregistered business
    if (applicationBusinessType === ApplicationBusinessType.UNREGISTERED) {
        if (!progress.isBusinessCompleted()) {
            return page.sort()[0].route;
        }
        if (!progress.isPersonalCompleted()) {
            return page.sort()[1].route;
        }
        if (!progress.isIdentityCompleted()) {
            return page.sort()[2].route;
        }
    }

    //registered business
    if (applicationBusinessType === ApplicationBusinessType.REGISTERED) {
        if (!progress.isBusinessCompleted()) {
            return page.sort()[0].route;
        }
        if (!progress.isSignatoriesCompleted()) {
            return page.sort()[1].route;
        }
        if (!progress.isDocumentsCompleted()) {
            return page.sort()[2].route;
        }
        if (!progress.isAgreementsCompleted()) {
            return page.sort()[3].route;
        }
        if (!progress.isRefereesCompleted()) {
            return page.sort()[4].route;
        }
    }
    return page.sort()[0].route;
}

export function getSections(
    page: ApplicationPage[],
    progress: NewAccountOpeningProgress | undefined,
    applicationBusinessType: ApplicationBusinessType,
    isCreator: boolean,
    isZambia?: boolean
): { todoList: ApplicationPage[]; underReviewList: ApplicationPage[]; reviewed: ApplicationPage[]; preview: ApplicationPage[] } {
    const reviewed: ApplicationPage[] = [];
    const preview: ApplicationPage[] = [];
    const todoList: ApplicationPage[] = [];
    const underReviewList: ApplicationPage[] = [];

    if (progress) {
        if (applicationBusinessType === ApplicationBusinessType.UNREGISTERED) {
            // const identityPage = page.sort().find((_page) => _page.value === NigeriaUnregisteredSections.IDENTITY);
            const businessDetailsPage = page.sort().find((_page) => _page.value === NigeriaUnregisteredSections.BUSINESS);
            const personalDetailsPage = page.sort().find((_page) => _page.value === NigeriaUnregisteredSections.PERSONAL);
            const documentsDetailsPage = page.sort().find((_page) => _page.value === ZambiaUnregisteredSections.DOCUMENT);
            businessDetailsPage &&
                (!progress.isBusinessCompleted()
                    ? todoList.push({ ...businessDetailsPage, progress: progress.businessCompletedProgress() })
                    : underReviewList.push({ ...businessDetailsPage, progress: progress.businessCompletedProgress() }));
            isZambia &&
                documentsDetailsPage &&
                (!progress.isDocumentsCompleted()
                    ? todoList.push({ ...documentsDetailsPage, progress: progress.documentsCompletedProgress() })
                    : underReviewList.push({ ...documentsDetailsPage, progress: progress.documentsCompletedProgress() }));
            personalDetailsPage &&
                (!progress.isPersonalCompleted()
                    ? todoList.push({ ...personalDetailsPage, progress: progress.personalCompletedProgress() })
                    : underReviewList.push({ ...personalDetailsPage, progress: progress.personalCompletedProgress() }));

            // identityPage &&
            // (!progress.isIdentityCompleted()
            // ? todoList.push({...identityPage, progress: progress.identityCompletedProgress()})
            // : underReviewList.push({...identityPage, progress: progress.identityCompletedProgress()}));
        }

        //registered business
        if (applicationBusinessType === ApplicationBusinessType.REGISTERED) {
            const businessDetailsPage = page.sort().find((_page) => _page.value === NigeriaRegisteredSections.COMPANY);
            const signatoriesDirectorsPage = page.sort().find((_page) => _page.value === NigeriaRegisteredSections.SIGNATORY);
            const personalDetailsPage = page.sort().find((_page) => _page.value === NigeriaRegisteredSections.PERSONAL);
            const businessDocumentsPage = page.sort().find((_page) => _page.value === NigeriaRegisteredSections.DOCUMENT);
            const agreementsPage = page.sort().find((_page) => _page.value === NigeriaRegisteredSections.AGREEMENT);
            const refereesPage = page.sort().find((_page) => _page.value === NigeriaRegisteredSections.REFEREES);
            if (!isCreator) {
                businessDetailsPage && preview.push({ ...businessDetailsPage, progress: progress.businessCompletedProgress() });
                personalDetailsPage &&
                    (!progress.isPersonalCompleted()
                        ? todoList.push({
                              ...personalDetailsPage,
                              route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                              progress: progress.personalCompletedProgress(),
                          })
                        : underReviewList.push({
                              ...personalDetailsPage,
                              route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                              progress: progress.personalCompletedProgress(),
                          }));
                businessDocumentsPage && preview.push({ ...businessDocumentsPage, progress: progress.documentsCompletedProgress() });
                !isZambia &&
                    agreementsPage &&
                    (!progress.isCurrentSignatoryAgreementCompleted()
                        ? todoList.push({ ...agreementsPage, progress: progress.currentSignatoryAgreementProgress() })
                        : underReviewList.push({ ...agreementsPage, progress: progress.currentSignatoryAgreementProgress() }));
                !isZambia &&
                    refereesPage &&
                    preview.push({
                        ...refereesPage,
                        route: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.INVITE,
                        progress: progress.referees ? progress.refereesCompletedProgress() : 100,
                    });
            } else {
                businessDetailsPage &&
                    (!progress.isBusinessCompleted()
                        ? todoList.push({ ...businessDetailsPage, progress: progress.businessCompletedProgress() })
                        : underReviewList.push({ ...businessDetailsPage, progress: progress.businessCompletedProgress() }));

                signatoriesDirectorsPage &&
                    (!progress.isSignatoriesCompleted()
                        ? todoList.push({
                              ...signatoriesDirectorsPage,
                              progress: progress.signatoriesCompletedProgress(),
                          })
                        : underReviewList.push({
                              ...signatoriesDirectorsPage,
                              progress: progress.signatoriesCompletedProgress(),
                          }));

                personalDetailsPage &&
                    (!progress.isPersonalCompleted()
                        ? todoList.push({
                              ...personalDetailsPage,
                              route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                              progress: progress.personalCompletedProgress(),
                          })
                        : underReviewList.push({
                              ...personalDetailsPage,
                              route: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                              progress: progress.personalCompletedProgress(),
                          }));

                businessDocumentsPage &&
                    (!progress.isDocumentsCompleted()
                        ? todoList.push({ ...businessDocumentsPage, progress: progress.documentsCompletedProgress() })
                        : underReviewList.push({ ...businessDocumentsPage, progress: progress.documentsCompletedProgress() }));
                !isZambia &&
                    agreementsPage &&
                    (!progress.isAgreementsCompleted()
                        ? todoList.push({ ...agreementsPage, progress: progress.agreementsCompletedProgress() })
                        : underReviewList.push({ ...agreementsPage, progress: progress.agreementsCompletedProgress() }));
                !isZambia &&
                    refereesPage &&
                    (progress.referees
                        ? !progress.isRefereesCompleted()
                            ? todoList.push({ ...refereesPage, progress: progress.refereesCompletedProgress() })
                            : underReviewList.push({ ...refereesPage, progress: progress.refereesCompletedProgress() })
                        : underReviewList.push({ ...refereesPage, progress: 100 }));
            }
        }
    }
    return { todoList, underReviewList, preview, reviewed };
}
