import { Link } from "react-router-dom";
import SkeletonElement from "../../../../../components/skeleton";
import Transaction from "../../../../../models/transaction";
import { formatDate } from "../../../../../utils/formatDate";
import { showTransactionDetails } from "../../../../../redux/transaction/slice/transactionSlice";
import titleCase from "../../../../../hooks/titleCase";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as EmptyList } from "../../../../../assets/svg/EmptyList.svg";
import AvatarDetails from "../../../../../components/avatar-details";
import Money from "../../../../../components/money";

interface Props {
    isLoaded: boolean;
    transactions: Transaction[];
    type: "Transfer" | "Inflow" | "Payout";
}

function TransactionCard({ isLoaded, transactions, type }: Props): JSX.Element {
    const dispatch = useDispatch();

    const handleShowTransactionDetail = useCallback(
        (transaction: Transaction) => {
            dispatch(showTransactionDetails(transaction.id));
        },
        [dispatch]
    );

    return (
        <div className="flex min-h-72 flex-col rounded-xl bg-white p-4 shadow">
            <>
                {isLoaded ? (
                    <div className="text-black-secondary">
                        {type && type === "Transfer" ? (
                            <div className="flex flex-row items-center justify-between font-medium text-black-secondary">
                                <p>Pending Approval</p>
                                {isLoaded && type === "Transfer" && transactions.length > 1 && (
                                    <Link to={`/payments/pending`}>
                                        <p className="cursor-pointer text-base font-medium text-blue">View all</p>
                                    </Link>
                                )}
                            </div>
                        ) : (
                            <div className="flex flex-row items-center justify-between font-medium text-black-secondary">
                                <p>
                                    {type === "Inflow" ? "Inflows" : type}
                                    {/* <div className="px-2 text-black-secondary">-</div>
                                Last 30 days */}
                                </p>

                                {isLoaded ? (
                                    <>
                                        {type && type === "Inflow" ? (
                                            <Link to={`/transactions/inflow`}>
                                                <p
                                                    className={`${transactions.length > 4 ? "block" : "hidden"} cursor-pointer text-base font-medium text-blue`}
                                                >
                                                    View all
                                                </p>
                                            </Link>
                                        ) : type === "Payout" ? (
                                            <Link to={`/transactions/payout`}>
                                                <p
                                                    className={`${transactions.length > 4 ? "block" : "hidden"} cursor-pointer text-base font-medium text-blue`}
                                                >
                                                    View all
                                                </p>
                                            </Link>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="flex w-full flex-col items-end justify-end">
                                            <div className="mt-3 flex h-5 w-16 items-end justify-end">
                                                <SkeletonElement box />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="mb-2 h-8 w-52">
                        <SkeletonElement box />{" "}
                    </div>
                )}
                <div
                    className={
                        "flex h-full flex-col items-center text-xs text-black " +
                        `${transactions.length > 0 ? "justify-start pt-4" : "justify-center"}`
                    }
                >
                    {isLoaded ? (
                        transactions.length > 0 ? (
                            transactions.slice(0, 4).map((transaction, index) => {
                                return (
                                    <div
                                        className={`${index === transactions.length - 1 ? "" : "border-b-0.2 border-grey-secondary"} flex w-full cursor-pointer flex-row items-center justify-between space-x-2.5 py-3`}
                                        key={index}
                                        onClick={() => {
                                            handleShowTransactionDetail(transaction);
                                        }}
                                        data-type="transaction"
                                    >
                                        <AvatarDetails
                                            dataType="transaction"
                                            icon={type === "Inflow" ? "inflow" : "outflow"}
                                            title={titleCase(
                                                type === "Inflow"
                                                    ? (transaction.origination &&
                                                          transaction.origination.userAccount &&
                                                          transaction.origination.userAccount.isMain &&
                                                          "MAIN") ||
                                                          (transaction.origination &&
                                                              transaction.origination.userAccount &&
                                                              !transaction.origination.userAccount.isMain &&
                                                              transaction.origination.userAccount.subAccountShortName) ||
                                                          (transaction.origination &&
                                                              transaction.origination.customerAccount &&
                                                              transaction.origination.customerAccount.bankAccount &&
                                                              transaction.origination.customerAccount.bankAccount.accountName) ||
                                                          (transaction.origination &&
                                                              transaction.origination.card &&
                                                              transaction.origination.card.name) ||
                                                          (transaction.origination && transaction.origination.narration) ||
                                                          ""
                                                    : (transaction.destination &&
                                                          transaction.destination.userAccount &&
                                                          transaction.destination.userAccount.isMain &&
                                                          "MAIN") ||
                                                          (transaction.destination &&
                                                              transaction.destination.userAccount &&
                                                              !transaction.destination.userAccount.isMain &&
                                                              transaction.destination.userAccount.subAccountShortName) ||
                                                          (transaction.destination &&
                                                              transaction.destination.customerAccount &&
                                                              transaction.destination.customerAccount.bankAccount &&
                                                              transaction.destination.customerAccount.bankAccount.accountName) ||
                                                          (transaction.destination &&
                                                              transaction.destination.card &&
                                                              transaction.destination.card.name) ||
                                                          (transaction.destination && transaction.destination.narration) ||
                                                          ""
                                            )}
                                            subtitle={transaction.createdOn ? formatDate(transaction.createdOn) : ""}
                                            // size="sm"
                                            fullWidth
                                        />
                                        {/* <CardRight /> */}
                                        <div className="w-full text-right" data-type="transaction">
                                            <p
                                                className={`text-sm font-medium ${type === "Inflow" ? "text-success" : "text-black"} `}
                                                data-type="transaction"
                                            >
                                                <Money amount={transaction.amount} positive currency={transaction.currency} />
                                            </p>
                                            {type === "Transfer" && <p className="text-2xs font-medium text-warning">Pending Approval</p>}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="flex h-full w-full flex-col items-center justify-center space-y-3 border-black-tertiary text-center text-xs text-black-secondary">
                                <EmptyList />
                                {type === "Inflow" ? <p>You have no transaction</p> : <p>You have no transfers pending approval</p>}
                                {/* {type === "Inflow" && <InflowSvg className="h-16 w-16" />}
                                {type === "Payout" && <PayoutSvg className="h-16 w-16" />}
                                {type === "Transfer" && <PendingTransfersSvg className="h-16 w-16" />} */}
                                {/* {type === "Inflow" && "You have no inflow"}
                                {type === "Payout" && "You have no payout"}
                                {type === "Transfer" && "You have no transfers pending approval"} */}
                            </div>
                        )
                    ) : (
                        <>
                            <div className="flex max-h-full w-full flex-col items-center justify-between space-y-3">
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-9 w-full">
                                    <SkeletonElement box />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        </div>
    );
}

export default TransactionCard;
