import React from "react";
// import { ReactComponent as RemoveUserIcon } from "../../../../../../assets/svg/TeamMember/RemoveUserIcon.svg";

function RemoveFromAccountButton(): JSX.Element {
    return (
        <div className="mt-2 max-w-max">
            <div className="flex h-11 w-full items-center justify-center rounded-custom border border-error hover:bg-error-backdrop">
                <div className="flex w-full items-center justify-start">
                    <div className="flex items-center justify-center">
                        <div className="h-4 w-4">{/* <RemoveUserIcon className="stroke-current text-error" /> */}</div>
                        <span className="ml-4 text-xs font-normal text-error">Remove From Account</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RemoveFromAccountButton;
