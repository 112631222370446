import * as Yup from "yup";

import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../../dashboard/applicationn/components/element/hint/hint.constant";
import {
    NextOfKinForm,
    getNextOfKinFormHints,
} from "../../../../../dashboard/applicationn/services/nigeria-application/registered/currentSignatory.types";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../dashboard/applicationn/components/element/ApplicationHeader";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import CustomSelectWithOther from "../../../../../../components/dropdown/custom-select-with-other";
import FormInput from "../../../../../../components/inputs/FormInput";
import Hint from "../../../../../dashboard/applicationn/components/element/hint";
import KinRelationship from "../../../../../../models/meta/kinRelationship";
import { Routes } from "../../../../../../routes/routes.constants";
import SingleSelectDropdown from "../../../../../../components/dropdown/single-select/single-select";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useEffect } from "react";
import useExternalDirectorNextOfKin from "../../hookss/useExternalDirectorNextOfKin";

interface LocationState {
    from?: string;
}

function NextOfKin(): JSX.Element {
    const navigate = useNavigate();

    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const { isExternalDirectorNextOfKinLoading, handleReset, handleUpdateExternalDirectorNextOfKin } = useExternalDirectorNextOfKin();

    const externalLink = useAppSelector((state) => state.externalApplication.externalLink || "");
    const externalParams = useAppSelector((state) => state.externalApplication.externalParams);
    const externalApplicationInit = useAppSelector((state) => state.externalApplication.init);

    const director = externalApplicationInit?.director || null;

    const NextOfKinInitialState: NextOfKinForm = {
        name: director?.nextOfKin?.name || "",
        phone: director?.nextOfKin?.phone || "",
        genderId: director?.nextOfKin?.gender || null,
        otherRelationshipText: director?.nextOfKin?.otherRelationshipText || null,
        relationshipId: director?.nextOfKin?.relationship || null,
        address: director?.nextOfKin?.address || "",
    };

    const NextOfKimFormValidation = Yup.object().shape({
        name: Yup.string().required("Required"),
        phone: Yup.string().matches(new RegExp("[0-9]")).required("Required"),
        genderId: Yup.number().nullable().required("Required"),
        relationshipId: Yup.string().nullable(),
        otherRelationshipText: Yup.string().nullable(),
        address: Yup.string().required("Required"),
    });

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    ...NextOfKinInitialState,
                }}
                validationSchema={NextOfKimFormValidation}
                onSubmit={(values) => {
                    if (externalParams) {
                        void handleUpdateExternalDirectorNextOfKin(externalParams, values);
                    }
                }}
                validateOnMount
                validateOnBlur
            >
                {(formik) => {
                    return (
                        <Form className="w-full pb-32">
                            <div className="flex w-full flex-row items-start justify-start space-x-6">
                                {externalApplicationInit && director && (
                                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                                        <ApplicationHeader
                                            header="Next of Kin"
                                            subTitle="Tell us about your next of Kin"
                                            progress={externalApplicationInit.director.nextOfKinProgress()}
                                        >
                                            <div className="flex flex-col items-start space-y-4 text-base font-normal lg:items-center lg:px-0">
                                                <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <div className="w-full lg:w-50%">
                                                        <FormInput type="text" placeholder="Full Name" name="name" />
                                                    </div>
                                                    <div className="w-full lg:w-50%">
                                                        <FormInput type="text" placeholder="Phone" name="phone" />
                                                    </div>
                                                </div>
                                                <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <div className="lg:w-6/12">
                                                        <SingleSelectDropdown
                                                            placeholder="Gender"
                                                            options={externalApplicationInit.options.gender.map((el) => {
                                                                return {
                                                                    text: el.name,
                                                                    value: el.id,
                                                                };
                                                            })}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    void formik.getFieldHelpers("genderId").setValue(value || "");
                                                                }
                                                            }}
                                                            value={formik.values.genderId}
                                                            defaultValue={director.nextOfKin?.gender}
                                                            bigDropdown={false}
                                                            isDisabled={
                                                                !!externalApplicationInit.options.kinRelationship.find(
                                                                    (_rel) => _rel.id === formik.values.relationshipId
                                                                )?.genderId
                                                            }
                                                        />
                                                    </div>
                                                    <div className="lg:w-6/12">
                                                        <CustomSelectWithOther
                                                            placeholder="Relationship"
                                                            value={Number(formik.values.relationshipId) || undefined}
                                                            otherValue={formik.values.otherRelationshipText || undefined}
                                                            options={externalApplicationInit.options.kinRelationship.map((item: KinRelationship) =>
                                                                item.toDropdownItem()
                                                            )}
                                                            otherOptionValue={null}
                                                            reset={() => {
                                                                void formik.getFieldHelpers("relationshipId").setValue(null);
                                                                void formik.getFieldHelpers("otherRelationshipText").setValue(null);
                                                            }}
                                                            resetDropdownValue={() => {
                                                                void formik.getFieldHelpers("relationshipId").setValue(null);
                                                            }}
                                                            onSelect={(value, otherText) => {
                                                                if (value) {
                                                                    void formik
                                                                        .getFieldHelpers("genderId")
                                                                        .setValue(
                                                                            externalApplicationInit.options.kinRelationship.find(
                                                                                (_rel) => _rel.id === value
                                                                            )?.genderId || null
                                                                        );
                                                                }
                                                                void formik.getFieldHelpers("relationshipId").setValue(value || null);
                                                                void formik.getFieldHelpers("otherRelationshipText").setValue(otherText || null);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <FormInput type="textarea" placeholder="Address" name="address" value={formik.values.address} />
                                                </div>
                                            </div>
                                        </ApplicationHeader>
                                        <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                            <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                <ButtonComp
                                                    type="button"
                                                    color="grey"
                                                    ripple="light"
                                                    buttonType="secondary"
                                                    func={() =>
                                                        navigate(
                                                            locationState?.from === externalLink
                                                                ? externalLink
                                                                : `${externalLink}${Routes.EXTERNAL_DIRECTOR.PERSONAL_DETAILS.DETAILS}`
                                                        )
                                                    }
                                                    fullWidth
                                                >
                                                    <span>Back</span>
                                                </ButtonComp>
                                            </div>
                                            <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                <ButtonComp
                                                    type="submit"
                                                    color="black"
                                                    ripple="light"
                                                    buttonType="primary"
                                                    isLoading={isExternalDirectorNextOfKinLoading}
                                                    fullWidth
                                                    disable={
                                                        !!(
                                                            formik.errors.name ||
                                                            formik.errors.phone ||
                                                            formik.errors.address ||
                                                            formik.errors.genderId
                                                        ) ||
                                                        !formik.values.genderId ||
                                                        !!(!formik.values.otherRelationshipText && formik.errors.relationshipId) ||
                                                        !!(!formik.values.otherRelationshipText && !formik.values.relationshipId)
                                                    }
                                                >
                                                    <span>Continue</span>
                                                </ButtonComp>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Hint
                                    hintType={HintType.GUIDE}
                                    listStyle={ListStyle.CHECK}
                                    firstList={getNextOfKinFormHints(
                                        Object.keys(formik.values || []),
                                        [
                                            ...Object.keys(formik.errors || []),
                                            ...(!formik.values.relationshipId && !formik.values.otherRelationshipText ? ["relationshipId"] : []),
                                            ...(!formik.values.genderId ? ["gender"] : []),
                                        ],
                                        ["otherRelationshipText"],
                                        [{ primary: "relationshipId", secondary: "otherRelationshipText" }]
                                    )}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default NextOfKin;
