import { PurchaseType, TerminalRequestStatus } from "../../../../models/terminal.constants";

import Merchant from "../../../../models/merchant";
import PosTransaction from "../../../../models/posTransaction";
import Terminal from "../../../../models/terminal";
import TerminalRequest from "../../../../models/terminalRequest";
import User from "../../../../models/user";

export type TerminalsInitRequest = {
    offset: number | undefined;
};
export interface TerminalsInitResponse {
    success: boolean;
    message: string;
    meta: {
        setupVideoUrl: string | null;
        purchaseCost: number | null;
        cautionFee: number | null;
    };
    merchant: Merchant | null;
    terminalsData: { terminals: Array<Terminal>; groupSize: number; total: number };
    terminalRequestsData: { terminalRequests: Array<TerminalRequest>; groupSize: number; total: number };
    settings: { teamMembersWithManageAccess: Array<User>; teamMembersWithViewAccess: Array<User> };
}

export type RequestTerminalRequest = {
    estimatedDailyTransactionVolume: string;
    estimatedDailyTransactionCount: string;
    deliveryAddress: string;
    debitAccountId: string;
    purchaseType: number;
    numUnits: string;
};

export interface RequestTerminalResponse {
    success: boolean;
    message: string;
    terminalRequest: TerminalRequest;
}

export type ListTerminalRequestsRequest = {
    offset: string;
    statuses: TerminalRequestStatus[];
    purchaseTypes: PurchaseType[];
};

export interface ListTerminalRequestsResponse {
    success: boolean;
    message: string;
    total: number;
    groupSize: number;
    terminalRequests: Array<TerminalRequest>;
}

export interface ListTerminalsResponseDTO {
    total: number;
    groupSize: number;
    terminals: Array<Terminal>;
}

export type PosTransactionsListRequest = {
    // offset: number;
    // query: string;
    // userAccountIds: Array<string>;
    // transactionTypes: Array<string>;
    // transactionMediums: Array<string>;
    // transactionStatuses: Array<string>;
    // userGroupIds: Array<string>;
    // categoryIds: Array<string>;
    // cardIds: Array<string>;
    // date: {
    // begin: string;
    // end: string;
    // };
    types?: number[];
    query?: string;
    offset?: number;
    endDate?: string;
    statuses?: number[];
    startDate?: string;
    terminalId?: string;
};

export interface PosTransactionsListResponse {
    success: boolean;
    message: string;
    transactions: Array<PosTransaction>;
    groupSize: number;
    total: number;
}

export enum TerminalRequestType {
    INIT = "terminal.init",
    REQUEST_NEW_TERMINAL = "terminal.request-new-terminal",
    LIST_TERMINAL_REQUESTS = "terminal.list-terminal-requests",
    LIST_TERMINAL_TRANSACTIONS = "terminal.list-terminal-transactions",
    CREATE_TERMINAL_REQUEST_DETAILS = "terminal.create-terminal-request-details",
    REMOVE_TERMINAL_MANAGE_ACCESS = "terminal.manage-access",
    REMOVE_TERMINAL_VIEWER_ACCESS = "terminal.viewer-access",
    LIST_TERMINALS = "terminal.list-terminals",
    TERMINAL_DETAILS = "terminal.terminal-details",
    TERMINAL_INFORMATION = "terminal.terminal-information",
    ACTIVATE_TERMINAL = "terminal.activate-terminal",
    DEACTIVATE_TERMINAL = "terminal.deactivate-terminal",
    TERMINAL_BALANCE = "terminal.terminal-balance",
    ADD_CREATE_TERMINAL_ACCESS = "terminal.add-create-terminal-access",
    REMOVE_CREATE_TERMINAL_ACCESS = "terminal.remove-create-terminal-access",
    TERMINAL_SETTINGS = "terminal.settings",
}

export type RemoveLencoTerminalUserAccessDTO = {
    memberId: string;
};

export type GrantUserLencoTerminalAccessRequest = {
    memberIds: string[];
};

export type GrantUserLencoTerminalManagerAccessResponse = {
    success: boolean;
    message: string;
    teamMembersWithManageAccess: User[];
};

export type GrantUserLencoTerminalViewAccessResponse = {
    success: boolean;
    message: string;
    teamMembersWithViewAccess: User[];
};

export type TerminalListDTO = {
    offset?: number;
    query?: string;
    groupId?: string;
};

export type UpdateTerminalSettingsDto = {
    terminalName?: string;
    serialNumber: string;
    settlementAccountId?: string;
};

export type UpdateTerminalSettingsResponse = {
    success: boolean;
    message: string;
    terminal: Terminal;
};
