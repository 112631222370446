import {
    setCanVerifyRecipientAccountDetails,
    setSingleTransferRecipient,
    setSingleTransferRecipientAccountNumber,
} from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BankAccount from "../../../../../../../models/bankAccount";
import Card from "../../../../../../../models/card";
import CustomerAccount from "../../../../../../../models/customerAccount";
import { IRootState } from "../../../../../../../redux/rootReducer";
import { RecipientAutocompleteRequest } from "../../../../services/send-money/singleTransferApi.types";
import { RequestCancelledError } from "../../../../../../../helpers/request/requestErrors";
import UserAccount from "../../../../../../../models/userAccount";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import { recipientAutocomplete } from "../../../../services/send-money/singleTransferApi";

interface AccountAutoCompleteDetailsInterface {
    query: string;
    includeUserAccounts: boolean;
}

interface RecipientAutocompleteResponse {
    success: boolean;
    message: string;
    userAccounts: Array<UserAccount>;
    customerAccounts: Array<CustomerAccount>;
    cards: Array<Card>;
}

interface UseSingleTransferAccountNumberAutocompleteInterface {
    suggestedRecipients: (UserAccount | CustomerAccount | Card)[] | null;
    accountAutoCompleteDetails: AccountAutoCompleteDetailsInterface;
    isRecipientAutocompleteLoading: boolean;
    recipientAutocompleteResponse: RecipientAutocompleteResponse | null;
    handleSelectRecipient: (_bankAccount: BankAccount) => void;
    handleAccountNumberChange: (_accountNumber: string) => void;
}

interface Props {
    handleSetVerifiedAccount: (_bankAccount: BankAccount) => void;
    handleResetVerifiedAccount: () => void;
}

function useSingleTransferAccountNumberAutocomplete({
    handleSetVerifiedAccount,
    handleResetVerifiedAccount,
}: Props): UseSingleTransferAccountNumberAutocompleteInterface {
    const dispatch = useDispatch();

    const customerAccountList = useSelector<IRootState, Map<string, CustomerAccount>>((state) => state.customerAccount.customerAccounts);
    const originatingAccountId = useSelector((state: IRootState) => state.singleTransfer.singleTransferDetails.originatingAccountId);
    const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);

    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

    const [accountAutoCompleteDetails, setAccountAutoCompleteDetails] = useState<AccountAutoCompleteDetailsInterface>({
        query: "",
        includeUserAccounts: true,
    });
    const [isRecipientAutocompleteLoading, setIsRecipientAutocompleteLoading] = useState(false);
    const [recipientAutocompleteResponse, setRecipientAutocompleteResponse] = useState<RecipientAutocompleteResponse | null>(null);
    const [suggestedRecipients, setSuggestedRecipients] = useState<(UserAccount | CustomerAccount | Card)[] | null>(null);

    useEffect(() => {
        const { query } = accountAutoCompleteDetails;
        if (query.length < 3) return;
        void handleRecipientAutoComplete(accountAutoCompleteDetails);
    }, [accountAutoCompleteDetails]);

    useEffect(() => {
        if (!accounts) return;
        const doesAccountExist = accounts.some((el) => el.id === originatingAccountId);
        if (!doesAccountExist && !recipientAutocompleteResponse) return;
        if (doesAccountExist) {
            const userAccount = accounts.find((el) => el.id === originatingAccountId) as UserAccount;
            if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) {
                if (userAccount.accountRestrictions.customerAccountIds.length > 0) {
                    const customerAccounts = userAccount.accountRestrictions.customerAccountIds.map((el) =>
                        customerAccountList.get(el)
                    ) as CustomerAccount[];
                    return setSuggestedRecipients([
                        ...userAccount.accountRestrictions.userAccounts,
                        ...customerAccounts,
                        ...userAccount.accountRestrictions.cards,
                    ]);
                }
                return setSuggestedRecipients([...userAccount.accountRestrictions.userAccounts, ...userAccount.accountRestrictions.cards]);
            }
            if (recipientAutocompleteResponse) {
                return setSuggestedRecipients([
                    ...recipientAutocompleteResponse.customerAccounts.filter(
                        (el) => el.bankAccount && el.bankAccount.id !== userAccount.bankAccount?.id
                    ),
                    ...recipientAutocompleteResponse.userAccounts.filter((el) => el.bankAccount && el.bankAccount.id !== userAccount.bankAccount?.id),
                    ...recipientAutocompleteResponse.cards,
                ]);
            }
        } else if (recipientAutocompleteResponse) {
            return setSuggestedRecipients([
                ...recipientAutocompleteResponse.customerAccounts,
                ...recipientAutocompleteResponse.userAccounts,
                ...recipientAutocompleteResponse.cards,
            ]);
        }
    }, [recipientAutocompleteResponse, accounts, originatingAccountId, customerAccountList]);

    useEffect(() => {
        if (!payTo || !customerAccountList) return;
        const recipient = customerAccountList.get(payTo);
        if (!isNullOrUndefined(recipient)) {
            dispatch(setCanVerifyRecipientAccountDetails(false));
            dispatch(setSingleTransferRecipient(recipient.bankAccount as BankAccount));
            handleSetVerifiedAccount(recipient.bankAccount as BankAccount);
        }
    }, [payTo, customerAccountList]);

    const handleRecipientAutoComplete = useCallback(
        async (_data: RecipientAutocompleteRequest) => {
            try {
                setIsRecipientAutocompleteLoading(true);
                const res = await recipientAutocomplete(_data);
                setRecipientAutocompleteResponse(res);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            }
            setIsRecipientAutocompleteLoading(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    const handleAccountNumberChange = useCallback((_accountNumber: string) => {
        setAccountAutoCompleteDetails((prev): AccountAutoCompleteDetailsInterface => {
            return {
                ...prev,
                query: _accountNumber,
            };
        });
        dispatch(setSingleTransferRecipientAccountNumber(_accountNumber));
        dispatch(setCanVerifyRecipientAccountDetails(true));
        handleResetVerifiedAccount();
        // setVerifyAccount((prev) => {
        // return {
        // ...prev,
        // accountNumber:_accountNumber,
        // };
        // });
    }, []);

    const handleSelectRecipient = useCallback((_bankAccount: BankAccount) => {
        dispatch(setCanVerifyRecipientAccountDetails(false));
        dispatch(setSingleTransferRecipient(_bankAccount));
        handleSetVerifiedAccount(_bankAccount);
    }, []);

    return {
        suggestedRecipients,
        accountAutoCompleteDetails,
        isRecipientAutocompleteLoading,
        recipientAutocompleteResponse,
        handleSelectRecipient,
        handleAccountNumberChange,
    };
}

export default useSingleTransferAccountNumberAutocomplete;
