import { RemoveSpendingLimitRequest, udpateCardSpendingLimitDTO } from "../../Services/cardsApi.types";
import { removeSpendingLimit, updateSpendingLimit } from "../../Services/cardsApi";
import { useCallback, useState } from "react";

import Card from "../../../../../models/card";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { mainInitAddCard } from "../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { updateCard } from "../../../../../redux/cards/slice/cardsSlice";
import { useDispatch } from "react-redux";

interface UseCardsInterface {
    spendingLimitError: string | null;
    isSpendingLimitDone: boolean;
    isSpendingLimitLoading: boolean;

    removeSpendingLimitError: string | null;
    isRemoveSpendingLimitDone: boolean;
    isRemoveSpendingLimitLoading: boolean;

    handleReset: () => void;
    handleRemoveSpendingLimit: (_data: RemoveSpendingLimitRequest) => void;
    handleUpdateSpendingLimit: (_data: udpateCardSpendingLimitDTO) => Promise<void>;
}
// interface Props {
// onComplete: () => void;
// }

// const useSpendLimit = (props: Props): UseCardsInterface => {
const useSpendLimit = (): UseCardsInterface => {
    const dispatch = useDispatch();
    const [spendingLimitError, setSpendingLimitError] = useState<string | null>(null);
    const [isSpendingLimitDone, setIsSpendingLimitDone] = useState<boolean>(false);
    const [isSpendingLimitLoading, setIsSpendingLimitLoading] = useState<boolean>(false);

    const [removeSpendingLimitError, setRemoveSpendingLimitError] = useState<string | null>(null);
    const [isRemoveSpendingLimitDone, setIsRemoveSpendingLimitDone] = useState<boolean>(false);
    const [isRemoveSpendingLimitLoading, setIsRemoveSpendingLimitLoading] = useState<boolean>(false);

    const handleUpdateSpendingLimit = useCallback(
        async (_data: udpateCardSpendingLimitDTO) => {
            try {
                setIsSpendingLimitDone(false);
                setSpendingLimitError(null);
                setIsSpendingLimitLoading(true);
                const res = await updateSpendingLimit(_data);
                setIsSpendingLimitDone(true);
                dispatch(mainInitAddCard(res.cardData.card as Card));
                dispatch(updateCard(res.cardData));
                dispatch(messageTrue({ message: "Card Spending Limit Updated Successfully" }));
                // props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setSpendingLimitError(errorMessage);
            } finally {
                setIsSpendingLimitLoading(false);
            }
        },
        [dispatch]
    );

    const handleRemoveSpendingLimit = useCallback(
        async (_data: RemoveSpendingLimitRequest) => {
            try {
                setIsRemoveSpendingLimitDone(false);
                setRemoveSpendingLimitError(null);
                setIsRemoveSpendingLimitLoading(true);
                const res = await removeSpendingLimit(_data);
                setIsRemoveSpendingLimitDone(true);
                dispatch(mainInitAddCard(res.cardData.card as Card));
                dispatch(updateCard(res.cardData));
                dispatch(messageTrue({ message: "Card Spending Limit Removed Successfully" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRemoveSpendingLimitError(errorMessage);
            } finally {
                setIsRemoveSpendingLimitLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsSpendingLimitDone(false);
        setSpendingLimitError(null);
        setIsSpendingLimitLoading(false);
    }, []);

    return {
        spendingLimitError,
        isSpendingLimitDone,
        isSpendingLimitLoading,

        removeSpendingLimitError,
        isRemoveSpendingLimitDone,
        isRemoveSpendingLimitLoading,
        handleRemoveSpendingLimit,

        handleUpdateSpendingLimit,
        handleReset,
    };
};

export default useSpendLimit;
