import Checkbox, { CheckType } from "../../../../../../components/checkbox";
import { DeclineReason, DeclinedTransaction } from "../../../hooks/state/pending-approval/usePendingPayments.types";
import React, { useState } from "react";

import DropdownHead from "../../../../../../components/dropdown/dropdown-components/dropdown-head";
import NewDropdownItem from "../../../../../../components/new-dropdown-item";
import Transaction from "../../../../../../models/transaction";
import useClickOutside from "../../../../../../hooks/useClickOutside";

interface Props {
    isCancelled: boolean;
    transaction: Transaction;
    cancelledReason: DeclineReason | undefined;
    isCancelTransactionLoading?: boolean;
    handleSelectOrChangeCancelReason: (_data: DeclinedTransaction) => void;
    handleRemoveCancelOrChangeToCancel?: (_data: Transaction) => void;
}

function CancelPayment(props: Props): JSX.Element {
    const [isHoverCancel, setIsHoverCancel] = useState<boolean>(false);
    const [isCancelActive, setIsCancelActive] = useState<boolean>(false);

    const domNode = useClickOutside(() => {
        setIsCancelActive(false);
    });

    return (
        <>
            <div
                className="mx-auto flex w-max cursor-pointer flex-col items-center justify-center"
                ref={domNode}
                onMouseEnter={() => setIsHoverCancel(true)}
                onMouseLeave={() => setIsHoverCancel(false)}
                onClick={() => {
                    if (props.isCancelled) {
                        props.handleRemoveCancelOrChangeToCancel && props.handleRemoveCancelOrChangeToCancel(props.transaction);
                        setIsCancelActive(false);
                    } else {
                        setIsCancelActive(true);
                    }
                }}
            >
                <DropdownHead
                    placeholder={
                        <div
                            className={
                                `flex h-full items-center justify-start space-x-2 text-xs font-normal` +
                                `${isCancelActive || props.isCancelled ? " text-error" : " text-current"}`
                            }
                        >
                            <Checkbox type={CheckType.ERROR} id={props.transaction.id} checked={props.isCancelled} size="xs" readOnly isErrorCheck />
                            <span>Cancel</span>
                        </div>
                    }
                    noYPadding
                    borderColor={props.isCancelled ? "border-error-quin" : "border-grey-secondary"}
                    size="2xs"
                    clickAndClose
                    placement="right"
                    isLoading={props.isCancelTransactionLoading}
                    color="red"
                    isActive={isCancelActive}
                    isHover={isHoverCancel || props.isCancelled}
                    isSelected={props.isCancelled}
                    noOutlineBorder={false}
                    outline
                    fitDropdown
                >
                    <div className="w-full border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary">
                        <NewDropdownItem
                            isDisabled={props.cancelledReason === DeclineReason.INCORRECT_DETAILS}
                            onClick={() =>
                                props.handleSelectOrChangeCancelReason({
                                    reason: DeclineReason.INCORRECT_DETAILS,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <p className="text-sm">Incorrect Details</p>
                        </NewDropdownItem>
                        <NewDropdownItem
                            isDisabled={props.cancelledReason === DeclineReason.INCORRECT_AMOUNT}
                            onClick={() =>
                                props.handleSelectOrChangeCancelReason({
                                    reason: DeclineReason.INCORRECT_AMOUNT,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <p className="text-sm">Incorrect Amount</p>
                        </NewDropdownItem>
                        <NewDropdownItem
                            isDisabled={props.cancelledReason === DeclineReason.WRONG_PURPOSE}
                            onClick={() =>
                                props.handleSelectOrChangeCancelReason({
                                    reason: DeclineReason.WRONG_PURPOSE,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <p className="text-sm">Wrong Purpose</p>
                        </NewDropdownItem>
                        <NewDropdownItem
                            isDisabled={props.cancelledReason === DeclineReason.OTHERS}
                            onClick={() =>
                                props.handleSelectOrChangeCancelReason({
                                    reason: DeclineReason.OTHERS,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <p className="text-sm">Others</p>
                        </NewDropdownItem>
                    </div>
                </DropdownHead>
            </div>
        </>
    );
}

export default CancelPayment;
