import React, { useEffect, useState } from "react";

import Accordion from "../../../../../../../../../components/accordion";
import AgreementSignatureComponent from "./AgreementSignatureComponent";
import { AgreementType } from "../../../../../../services/nigeria-application/registered/new/agreement.types";
import GoogleIcon from "../../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import PreviewModal from "../../../../../modals/preview-modal";
import SignatoryMin from "../../../../../../../../../models/account-opening/signatoryInfoMin";
import UploadedCard from "../../../../../cards/UploadedCard";
import titleCase from "../../../../../../../../../hooks/titleCase";
import usePreviewBusinessAgreement from "../../../../../../Hooks/nigeria-application/registered-business/agreement/usePreviewBusinessAgreement";
import { useSelector } from "react-redux";

interface Props {
    isOpen: boolean;
    isExternalLink?: boolean;
    toggle: () => void;
}

function AccountMandateComponent(props: Props): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreviewBusinessAgreement({
        downloadedDocumentName: "Mandate Document",
        onComplete: () => setShowPreviewModal(true),
    });

    const isCreator = useSelector((state: IRootState) => state.application.init?.isCreator);
    const createdBy = useSelector((state: IRootState) => state.application.agreement?.createdBy.name);
    const signatories = useSelector((state: IRootState) => state.application.agreement?.signatories);
    const currentUser = useSelector((state: IRootState) => state.application.agreement?.currentUser);
    const mandateCard = useSelector((state: IRootState) => state.application.agreement?.agreements.mandateCard);

    const [isSigned, setIsSigned] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    const isUploaded = !!(mandateCard?.signedDocumentId && mandateCard?.signedBy.length < 1);
    const listOfMandateCardSignedSignatories: SignatoryMin[] = mandateCard?.signedBy || [];
    const listOfMandateCardUnsignedSignatories: SignatoryMin[] =
        signatories?.filter((f) => !mandateCard?.signedBy.some((_el) => _el.id === f.id)) || [];

    useEffect(() => {
        setIsSigned(!!(mandateCard?.signedBy.some((_el) => _el.userId === currentUser?.id) || mandateCard?.signedDocumentId) || false);
    }, [props.isOpen, mandateCard]);

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Account Mandate Preview"
                />

                <div className="flex w-full flex-col justify-start">
                    <Accordion
                        header="Account Mandate"
                        checkActive={!!mandateCard?.signedDocumentId}
                        isOpened={props.isOpen}
                        toggle={props.toggle}
                        hasCheck
                    >
                        <div className="relative flex w-full flex-col items-start justify-start space-y-6">
                            <div className="ml-[25px] flex flex-col">
                                <div className="text-xs text-black-tertiary lg:text-sm">
                                    {mandateCard?.signedDocumentId && listOfMandateCardSignedSignatories.length < 1 && (
                                        <div className="flex flex-row items-center justify-start space-x-3 text-success">
                                            <GoogleIcon icon="check" size="lg" />

                                            <span>Uploaded by: {titleCase(createdBy || "")}</span>
                                        </div>
                                    )}
                                    {listOfMandateCardSignedSignatories.length > 0 &&
                                        listOfMandateCardSignedSignatories.map((_signedSig, index) => (
                                            <div className="flex flex-row items-center justify-start space-x-3 text-success" key={index}>
                                                <GoogleIcon icon="check" size="lg" />

                                                <span>Signed by: {titleCase(_signedSig.name)}</span>
                                            </div>
                                        ))}
                                    {!mandateCard?.signedDocumentId &&
                                        listOfMandateCardUnsignedSignatories.map((_unsignedSig, index) => (
                                            <div className="flex flex-row items-center justify-start space-x-3" key={index}>
                                                <GoogleIcon icon="check" className="text-black-quin" size="lg" />
                                                <span>
                                                    Expecting{" "}
                                                    <span className="font-medium capitalize text-black-secondary">
                                                        {titleCase(_unsignedSig.name)}
                                                    </span>{" "}
                                                    to sign
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div className="relative w-full">
                                {!isSigned ? (
                                    <AgreementSignatureComponent
                                        type={AgreementType.ACCOUNT_MANDATE_CARD}
                                        isCreator={!!isCreator}
                                        onComplete={() => setIsSigned(true)}
                                        canAppendSignature={!!currentUser?.hasSignature}
                                    />
                                ) : (
                                    <UploadedCard
                                        text={isUploaded ? "Document Uploaded" : "Document Signed"}
                                        primaryButtonText="Sign again"
                                        handlePreviewDocumentFunc={() => void handlePreviewDocument(AgreementType.ACCOUNT_MANDATE_CARD)}
                                        handleRedo={() => setIsSigned(false)}
                                        isNoPrimaryButton={isUploaded}
                                    />
                                )}
                            </div>
                        </div>
                    </Accordion>
                </div>
            </div>
        </>
    );
}

export default AccountMandateComponent;
