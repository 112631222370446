import {
    AllTerminalsStageType,
    SpecificTerminalStageType,
    TerminalFilterTypes,
    TerminalRequestsFilterTypes,
    TerminalSettings,
    TerminalTransactionDateType,
    TerminalTransactionFilterTypes,
    TerminalsStageType,
    TerminalsState,
} from "./terminalsSlice.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PurchaseType, TerminalRequestStatus } from "../../../models/terminal.constants";
import { FilterDateTypes } from "../../../components/filter/filter.constant";
import PosTransaction from "../../../models/posTransaction";
import Terminal from "../../../models/terminal";
import TerminalRequest from "../../../models/terminalRequest";
import { TerminalsInitResponse } from "../../../modules/dashboard/terminals/Types";
import { BasicFinalStatus, TransactionType } from "../../../models/posTransaction.constants";
import User from "../../../models/user";

const initialState: TerminalsState = {
    terminalStage: TerminalsStageType.ALL_TERMINALS,
    allTerminalStage: AllTerminalsStageType.INITIAL,
    specificTerminalStage: SpecificTerminalStageType.TERMINAL_TRANSACTIONS,
    specificallySelectedTerminal: null,
    selectedTerminalNameInTerminalStage: null,
    initMeta: null,
    merchant: null,
    terminals: [],
    initTerminals: [],
    requestedTerminals: [],
    initRequestedTerminals: [],
    initTerminalRequestsListTotal: 0,
    initTerminalRequestsListGroupSize: 0,
    isSettingUpTerminalModalOpen: false,
    isRequestingTerminalModalOpen: false,
    isExportTerminalsLoading: false,
    isTerminalPaginationLoading: false,
    terminalListTotal: 0,
    terminalListGroupSize: 0,
    terminalPaginationOffset: 0,
    terminalFilterState: {
        offset: 0,
        query: "",
        groupId: "",
    },
    selectedTerminalFilterState: {
        offset: 0,
        query: "",
        groupId: "",
    },

    isTerminalRequestsPaginationLoading: false,
    isExportTerminalRequestsLoading: false,
    terminalRequestsListTotal: 0,
    terminalRequestsListGroupSize: 0,
    terminalRequestsPaginationOffset: 0,
    terminalRequestsFilterState: {
        offset: 0,
        statuses: [],
        purchaseTypes: [],
    },
    selectedTerminalRequestsFilterState: {
        offset: 0,
        statuses: [],
        purchaseTypes: [],
    },
    settings: { teamMembersWithManageAccess: [], teamMembersWithViewAccess: [] },
    teamMembers: [],
    noRequestedOrActiveTerminal: undefined,
    transactions: [],
    terminalTransactionDetail: null,
    isTerminalTransactionDetailCardOpen: false,
    isTransactionPaginationLoading: false,
    transactionPaginationOffset: 0,
    transactionsListTotal: 0,
    transactionsListGroupSize: 0,
    isExportTransactionsLoading: false,
    isTransactionListEmpty: false,
    isTransactionSearchQuery: false,
    transactionSearchQuery: "",
    transactionAccountQuery: "",
    isTransactionAccountQuery: false,
    transactionDate: FilterDateTypes.ALL_TIME,
    selectedTransactionDate: FilterDateTypes.ALL_TIME,
    isCustomModalOpen: false,
    transactionFilterState: {
        offset: 0,
        query: "",
        endDate: "",
        startDate: "",
        types: [],
        statuses: [],
        terminalId: "",
    },
    selectedTransactionFilterState: {
        offset: 0,
        query: "",
        endDate: "",
        startDate: "",
        types: [],
        statuses: [],
        terminalId: "",
    },
};

export const terminalsSlice = createSlice({
    name: "terminals",
    initialState,
    reducers: {
        setTerminalStage: (state: TerminalsState, action: PayloadAction<TerminalsStageType>) => {
            state.terminalStage = action.payload;
        },
        setTeamMembers: (state: TerminalsState, action: PayloadAction<User[]>) => {
            state.teamMembers = action.payload;
        },
        setSpecificTerminalStage: (state: TerminalsState, action: PayloadAction<SpecificTerminalStageType>) => {
            state.specificTerminalStage = action.payload;
        },
        setTerminalSettings: (state: TerminalsState, action: PayloadAction<TerminalSettings>) => {
            state.settings = action.payload;
        },
        updateTerminalManageAccessList: (state: TerminalsState, action: PayloadAction<User[]>) => {
            state.settings.teamMembersWithManageAccess = action.payload;
        },
        updateTerminalViewAccessList: (state: TerminalsState, action: PayloadAction<User[]>) => {
            state.settings.teamMembersWithViewAccess = action.payload;
        },
        removeTerminalManageAccessList: (state: TerminalsState, action: PayloadAction<string[]>) => {
            state.settings.teamMembersWithManageAccess = state.settings.teamMembersWithManageAccess?.filter(
                (user) => !action.payload?.includes(user.id)
            );
        },
        removeTerminalViewAccessList: (state: TerminalsState, action: PayloadAction<string[]>) => {
            state.settings.teamMembersWithViewAccess = state.settings.teamMembersWithViewAccess?.filter((user) => !action.payload?.includes(user.id));
        },
        setAllTerminalStageToInitial: (state: TerminalsState) => {
            state.allTerminalStage = AllTerminalsStageType.INITIAL;
        },
        setAllTerminalStageToSpecificTerminal: (state: TerminalsState, action: PayloadAction<Terminal>) => {
            state.allTerminalStage = AllTerminalsStageType.SPECIFIC_TERMINAL_TRANSACTION;
            state.specificallySelectedTerminal = action.payload;
            state.selectedTerminalNameInTerminalStage = action.payload.serialNumber;
        },
        resetAllTerminalStageToSpecificTerminal: (state: TerminalsState) => {
            state.specificTerminalStage = SpecificTerminalStageType.TERMINAL_TRANSACTIONS;
            state.specificallySelectedTerminal = null;
            state.selectedTerminalNameInTerminalStage = null;
        },

        setInitTerminalDetails: (state: TerminalsState, action: PayloadAction<TerminalsInitResponse>) => {
            state.initMeta = action.payload.meta;
            state.merchant = action.payload.merchant;
            state.initTerminals = action.payload.terminalsData.terminals;
            state.initRequestedTerminals = action.payload.terminalRequestsData.terminalRequests;
            state.initTerminalRequestsListTotal = action.payload.terminalRequestsData.total;
            state.initTerminalRequestsListGroupSize = action.payload.terminalRequestsData.groupSize;
            state.noRequestedOrActiveTerminal = !action.payload.terminalRequestsData?.total && !action.payload.terminalsData?.total;
        },

        //<===for terminals in general===>
        setTerminals: (state: TerminalsState, action: PayloadAction<Terminal[]>) => {
            state.terminals = action.payload;
        },
        setUpdateTerminalsList: (state: TerminalsState, action: PayloadAction<Terminal>) => {
            state.terminals = state.terminals?.map((_prev) => (_prev.id === action.payload.id ? action.payload : _prev));
        },
        setRequestedTerminals: (state: TerminalsState, action: PayloadAction<TerminalRequest[]>) => {
            state.requestedTerminals = action.payload;
        },
        setRequestedTerminal: (state: TerminalsState, action: PayloadAction<TerminalRequest>) => {
            state.requestedTerminals = [action.payload, ...state.requestedTerminals];
            state.initRequestedTerminals = [action.payload, ...state.initRequestedTerminals];
        },

        //<===for terminal modals===>
        setIsSettingUpTerminalModalOpen: (state: TerminalsState, action: PayloadAction<boolean>) => {
            state.isSettingUpTerminalModalOpen = action.payload;
        },
        setIsRequestingTerminalModalOpen: (state: TerminalsState, action: PayloadAction<boolean>) => {
            state.isRequestingTerminalModalOpen = action.payload;
        },

        //<===for terminal filter===>
        setIsExportTerminalsLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
            state.isExportTerminalsLoading = action.payload;
        },
        setIsTerminalPaginationLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
            state.isTerminalPaginationLoading = action.payload;
        },
        setTerminalListTotal: (state: TerminalsState, action: PayloadAction<number>) => {
            state.terminalListTotal = action.payload;
        },
        setTerminalPaginationOffset: (state: TerminalsState, action: PayloadAction<number>) => {
            state.terminalPaginationOffset = action.payload;
        },
        setTerminalListGroupSize: (state: TerminalsState, action: PayloadAction<number>) => {
            state.terminalListGroupSize = action.payload;
        },
        setTerminalFilterState: (state: TerminalsState, action: PayloadAction<TerminalFilterTypes>) => {
            state.terminalFilterState = { ...state.terminalFilterState, ...action.payload };
        },
        setTerminalQueryFilter: (state: TerminalsState, action: PayloadAction<string>) => {
            state.selectedTerminalFilterState.query = action.payload;
            state.terminalFilterState.query = action.payload;
        },
        setTerminalGroupIdFilter: (state: TerminalsState, action: PayloadAction<string>) => {
            state.selectedTerminalFilterState.groupId = action.payload;
            state.terminalFilterState.groupId = action.payload;
        },
        setSelectedTerminalFilterState: (state: TerminalsState) => {
            state.selectedTerminalFilterState = { ...state.terminalFilterState };
        },
        resetTerminalQueryFilterState: (state: TerminalsState) => {
            state.selectedTerminalFilterState = { ...state.selectedTerminalFilterState, query: "" };
            state.terminalFilterState = { ...state.terminalFilterState, query: "" };
        },
        resetTerminalGroupIdFilterState: (state: TerminalsState) => {
            state.selectedTerminalFilterState = { ...state.selectedTerminalFilterState, groupId: "" };
            state.terminalFilterState = { ...state.terminalFilterState, groupId: "" };
        },

        resetTerminalFilterState: (state: TerminalsState) => {
            state.terminalFilterState = {
                offset: 0,
                query: "",
                groupId: "",
            };
            state.selectedTerminalFilterState = {
                offset: 0,
                query: "",
                groupId: "",
            };
        },

        //<===for terminal request filter===>
        setSelectedTerminalRequestsFilterState: (state: TerminalsState) => {
            state.selectedTerminalRequestsFilterState = { ...state.terminalRequestsFilterState };
        },
        setIsExportTerminalRequestsLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
            state.isExportTerminalRequestsLoading = action.payload;
        },
        setIsTerminalRequestsPaginationLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
            state.isTerminalRequestsPaginationLoading = action.payload;
        },
        setTerminalRequestsListTotal: (state: TerminalsState, action: PayloadAction<number>) => {
            state.terminalRequestsListTotal = action.payload;
        },
        updateTerminalRequestsListTotalByOne: (state: TerminalsState) => {
            if (state.initTerminalRequestsListTotal !== 100) {
                state.terminalRequestsListTotal = state.terminalRequestsListTotal + 1;
                state.initTerminalRequestsListTotal = state.initTerminalRequestsListTotal + 1;
            }
        },
        setTerminalRequestsPaginationOffset: (state: TerminalsState, action: PayloadAction<number>) => {
            state.terminalRequestsPaginationOffset = action.payload;
        },
        setTerminalRequestsListGroupSize: (state: TerminalsState, action: PayloadAction<number>) => {
            state.terminalRequestsListGroupSize = action.payload;
        },
        setTerminalRequestsFilterState: (state: TerminalsState, action: PayloadAction<TerminalRequestsFilterTypes>) => {
            state.terminalRequestsFilterState = { ...state.terminalRequestsFilterState, ...action.payload };
        },
        setRequestPurchaseTypesFilter: (state: TerminalsState, action: PayloadAction<PurchaseType>) => {
            state.selectedTerminalRequestsFilterState.purchaseTypes = state.selectedTerminalRequestsFilterState.purchaseTypes.filter(
                (_types) => _types !== action.payload
            );
            state.terminalRequestsFilterState.purchaseTypes = state.terminalRequestsFilterState.purchaseTypes.filter(
                (_types) => _types !== action.payload
            );
        },
        setRequestStatusesFilter: (state: TerminalsState, action: PayloadAction<TerminalRequestStatus>) => {
            state.selectedTerminalRequestsFilterState.statuses = state.selectedTerminalRequestsFilterState.statuses.filter(
                (_status) => _status !== action.payload
            );
            state.terminalRequestsFilterState.statuses = state.terminalRequestsFilterState.statuses.filter((_status) => _status !== action.payload);
        },
        setSelectedTerminalTransactionsDetails: (state, action: PayloadAction<string>) => {
            const terminal = state.transactions?.find((_sub) => _sub.id === action.payload);
            if (terminal) {
                state.terminalTransactionDetail = terminal;
            }
        },
        setIsTerminalTransactionsDetailCardOpen: (state, action: PayloadAction<boolean>) => {
            state.isTerminalTransactionDetailCardOpen = action.payload;
        },

        setIsCustomModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isCustomModalOpen = action.payload;
        },
        setIsExportTerminalTransactionsLoading: (state, action: PayloadAction<boolean>) => {
            state.isExportTransactionsLoading = action.payload;
        },

        setIsTerminalTransactionsPaginationLoading: (state, action: PayloadAction<boolean>) => {
            state.isTransactionPaginationLoading = action.payload;
        },
        setIsTerminalTransactionsListEmpty: (state, action: PayloadAction<boolean>) => {
            state.isTransactionListEmpty = action.payload;
        },
        setTerminalTransactionsDate: (state, action: PayloadAction<TerminalTransactionDateType>) => {
            if (action.payload.date.startDate && action.payload.date.endDate) {
                state.transactionFilterState = {
                    ...state.transactionFilterState,
                    startDate: action.payload.date.startDate,
                    endDate: action.payload.date.endDate,
                };
                state.transactionDate = action.payload.name as unknown as FilterDateTypes;
            }
        },
        setTerminalTransactionsList: (state, action: PayloadAction<PosTransaction[]>) => {
            state.transactions = action.payload;
        },
        setTerminalTransactionsListTotal: (state, action: PayloadAction<number>) => {
            state.transactionsListTotal = action.payload;
        },
        setTerminalTransactionsPaginationOffset: (state, action: PayloadAction<number>) => {
            state.transactionPaginationOffset = action.payload;
        },
        setTerminalTransactionsListGroupSize: (state, action: PayloadAction<number>) => {
            state.transactionsListGroupSize = action.payload;
        },
        setTerminalTransactionsFilterState: (state, action: PayloadAction<TerminalTransactionFilterTypes>) => {
            state.transactionFilterState = { ...state.transactionFilterState, ...action.payload };
        },
        setTerminalTransactionsDateFilter: (state) => {
            state.selectedTransactionFilterState = { ...state.selectedTransactionFilterState, endDate: "", startDate: "" };
            state.transactionFilterState = { ...state.transactionFilterState, endDate: "", startDate: "" };
            state.selectedTransactionDate = FilterDateTypes.ALL_TIME;
            state.transactionDate = FilterDateTypes.ALL_TIME;
        },
        setSelectedTerminalTransactionFilterState: (state) => {
            state.selectedTransactionFilterState = { ...state.transactionFilterState };
            state.selectedTransactionDate = state.transactionDate;
        },
        setSelectedTerminalTransactionFilterStateInstant: (state, action: PayloadAction<TerminalTransactionFilterTypes>) => {
            state.selectedTransactionFilterState = { ...state.selectedTransactionFilterState, ...action.payload };
            state.transactionFilterState = { ...state.transactionFilterState, ...action.payload };
        },
        setTerminalTransactionsTerminalIdFilter: (state, action: PayloadAction<string>) => {
            state.selectedTransactionFilterState.terminalId = action.payload;
            state.transactionFilterState.terminalId = action.payload;
        },
        setTerminalTransactionsTypesFilter: (state, action: PayloadAction<TransactionType>) => {
            state.selectedTransactionFilterState.types = state.selectedTransactionFilterState.types.filter((_types) => _types !== action.payload);
            state.transactionFilterState.types = state.transactionFilterState.types.filter((_types) => _types !== action.payload);
        },
        setTerminalTransactionsStatusesFilter: (state, action: PayloadAction<BasicFinalStatus>) => {
            state.selectedTransactionFilterState.statuses = state.selectedTransactionFilterState.statuses.filter(
                (_status) => _status !== action.payload
            );
            state.transactionFilterState.statuses = state.transactionFilterState.statuses.filter((_status) => _status !== action.payload);
        },

        resetTerminalTransactionsList: (state) => {
            state.transactions = [];
        },
        resetTerminalTransactionsDateFilterState: (state) => {
            state.selectedTransactionFilterState = { ...state.selectedTransactionFilterState, endDate: "", startDate: "" };
            state.transactionFilterState = { ...state.transactionFilterState, endDate: "", startDate: "" };
            state.selectedTransactionDate = FilterDateTypes.ALL_TIME;
            state.transactionDate = FilterDateTypes.ALL_TIME;
        },
        resetTerminalTransactionsTerminalIdFilterState: (state) => {
            state.selectedTransactionFilterState = { ...state.selectedTransactionFilterState, terminalId: "" };
            state.transactionFilterState = { ...state.transactionFilterState, terminalId: "" };
        },
        resetTerminalTransactionsStatusesFilterState: (state) => {
            state.selectedTransactionFilterState = { ...state.selectedTransactionFilterState, statuses: [] };
            state.transactionFilterState = { ...state.transactionFilterState, statuses: [] };
        },
        resetTerminalTransactionsTypesFilterState: (state) => {
            state.selectedTransactionFilterState = { ...state.selectedTransactionFilterState, types: [] };
            state.transactionFilterState = { ...state.transactionFilterState, types: [] };
        },
        resetTerminalTransactionsQueryFilterState: (state) => {
            state.selectedTransactionFilterState = { ...state.selectedTransactionFilterState, query: "" };
            state.transactionFilterState = { ...state.transactionFilterState, query: "" };
        },
        resetTerminalTransactionsFilterState: (state) => {
            state.transactionFilterState = {
                query: "",
                offset: 0,
                endDate: "",
                startDate: "",
                types: [],
                statuses: [],
                terminalId: "",
            };
            state.selectedTransactionFilterState = {
                query: "",
                offset: 0,
                endDate: "",
                startDate: "",
                types: [],
                statuses: [],
                terminalId: "",
            };
        },
        resetAllTerminalTransactionsData: (state) => {
            state.terminalTransactionDetail = null;
            state.isTerminalTransactionDetailCardOpen = false;
            state.transactions = [];
            state.transactionsListTotal = 0;
            state.transactionPaginationOffset = 0;
            state.transactionsListGroupSize = 0;
            state.isExportTransactionsLoading = false;
            state.isTransactionListEmpty = false;
            state.isTransactionSearchQuery = false;
            state.transactionSearchQuery = "";
            state.transactionAccountQuery = "";
            state.isTransactionAccountQuery = false;
            state.isTransactionPaginationLoading = false;
            state.transactionDate = FilterDateTypes.ALL_TIME;
            state.selectedTransactionDate = FilterDateTypes.ALL_TIME;
            state.isCustomModalOpen = false;
            state.transactionFilterState = {
                query: "",
                offset: 0,

                endDate: "",
                startDate: "",
                types: [],
                statuses: [],
                terminalId: "",
            };
            state.selectedTransactionFilterState = {
                query: "",
                offset: 0,

                endDate: "",
                startDate: "",
                types: [],
                statuses: [],
                terminalId: "",
            };
        },

        resetRequestPurchaseTypesState: (state: TerminalsState) => {
            state.selectedTerminalRequestsFilterState = { ...state.selectedTerminalRequestsFilterState, purchaseTypes: [] };
            state.terminalRequestsFilterState = { ...state.terminalRequestsFilterState, purchaseTypes: [] };
        },
        resetRequestStatusesState: (state: TerminalsState) => {
            state.selectedTerminalRequestsFilterState = { ...state.selectedTerminalRequestsFilterState, statuses: [] };
            state.terminalRequestsFilterState = { ...state.terminalRequestsFilterState, statuses: [] };
        },
        resetTerminalRequestFilterState: (state: TerminalsState) => {
            state.terminalRequestsFilterState = {
                offset: 0,
                statuses: [],
                purchaseTypes: [],
            };
            state.selectedTerminalRequestsFilterState = {
                offset: 0,
                statuses: [],
                purchaseTypes: [],
            };
        },
        resetAllTerminalRequestData: (state: TerminalsState) => {
            state.requestedTerminals = state.initRequestedTerminals;
            state.terminalRequestsListTotal = state.initTerminalRequestsListTotal;
            state.terminalRequestsListGroupSize = state.initTerminalRequestsListGroupSize;
            state.isTerminalRequestsPaginationLoading = false;
            state.isExportTerminalRequestsLoading = false;
            state.terminalRequestsPaginationOffset = 0;
            state.terminalRequestsFilterState = {
                offset: 0,
                statuses: [],
                purchaseTypes: [],
            };
            state.selectedTerminalRequestsFilterState = {
                offset: 0,
                statuses: [],
                purchaseTypes: [],
            };
        },

        resetAllTerminalData: (state: TerminalsState) => {
            //<===for terminal stages and inner stages===>
            state.terminalStage = TerminalsStageType.ALL_TERMINALS;
            state.allTerminalStage = AllTerminalsStageType.INITIAL;
            state.specificTerminalStage = SpecificTerminalStageType.TERMINAL_TRANSACTIONS;
            state.selectedTerminalNameInTerminalStage = null;

            //<===for terminals in general===>
            state.initMeta = null;
            state.merchant = null;
            state.terminals = [];
            state.initTerminals = [];
            state.initRequestedTerminals = [];

            //<===for terminal modals===>
            state.isSettingUpTerminalModalOpen = false;
            state.isRequestingTerminalModalOpen = false;

            //<===for terminal filter===>
            state.isExportTerminalsLoading = false;
            state.terminalListTotal = 0;
            state.terminalListGroupSize = 0;
            state.terminalPaginationOffset = 0;
            state.terminalFilterState = {
                offset: 0,
                query: "",
                groupId: "",
            };
            state.selectedTerminalFilterState = {
                offset: 0,
                query: "",
                groupId: "",
            };

            //<===for terminal request filter===>
            state.isTerminalRequestsPaginationLoading = false;
            state.isExportTerminalRequestsLoading = false;
            state.terminalRequestsListTotal = 0;
            state.terminalRequestsListGroupSize = 0;
            state.terminalRequestsPaginationOffset = 0;
            state.terminalRequestsFilterState = { offset: 0, statuses: [], purchaseTypes: [] };
            state.selectedTerminalRequestsFilterState = {
                offset: 0,
                statuses: [],
                purchaseTypes: [],
            };
        },
    },
});

export const {
    setTerminalStage,
    setSpecificTerminalStage,
    setAllTerminalStageToInitial,
    setAllTerminalStageToSpecificTerminal,
    resetAllTerminalStageToSpecificTerminal,
    setInitTerminalDetails,
    setTerminals,
    setRequestedTerminal,
    setRequestedTerminals,
    setIsSettingUpTerminalModalOpen,
    setIsRequestingTerminalModalOpen,
    setIsExportTerminalsLoading,
    setTerminalListTotal,
    setTerminalPaginationOffset,
    setTerminalListGroupSize,
    setTerminalFilterState,
    setSelectedTerminalFilterState,
    setIsTerminalPaginationLoading,
    setTerminalQueryFilter,
    setTerminalGroupIdFilter,
    resetTerminalQueryFilterState,
    resetTerminalGroupIdFilterState,
    resetTerminalFilterState,
    resetAllTerminalData,
    setSelectedTerminalRequestsFilterState,
    setIsTerminalRequestsPaginationLoading,
    setIsExportTerminalRequestsLoading,
    setTerminalRequestsListTotal,
    updateTerminalRequestsListTotalByOne,
    setTerminalRequestsPaginationOffset,
    setTerminalRequestsListGroupSize,
    setTerminalRequestsFilterState,
    setRequestPurchaseTypesFilter,
    setRequestStatusesFilter,
    resetAllTerminalRequestData,
    resetRequestPurchaseTypesState,
    resetRequestStatusesState,
    resetTerminalRequestFilterState,
    setTerminalSettings,
    setTeamMembers,
    updateTerminalManageAccessList,
    updateTerminalViewAccessList,
    setSelectedTerminalTransactionsDetails,
    setIsTerminalTransactionsDetailCardOpen,
    setIsCustomModalOpen,
    setTerminalTransactionsList,
    setTerminalTransactionsListTotal,
    setTerminalTransactionsPaginationOffset,
    setTerminalTransactionsFilterState,
    setIsTerminalTransactionsListEmpty,
    setTerminalTransactionsListGroupSize,
    setIsExportTerminalTransactionsLoading,
    setIsTerminalTransactionsPaginationLoading,
    setTerminalTransactionsDate,
    setTerminalTransactionsDateFilter,
    setSelectedTerminalTransactionFilterStateInstant,
    setTerminalTransactionsTypesFilter,
    setTerminalTransactionsStatusesFilter,
    setSelectedTerminalTransactionFilterState,
    resetTerminalTransactionsList,
    resetTerminalTransactionsFilterState,
    resetTerminalTransactionsDateFilterState,
    resetTerminalTransactionsQueryFilterState,
    resetTerminalTransactionsStatusesFilterState,
    resetTerminalTransactionsTypesFilterState,
    resetAllTerminalTransactionsData,
    setUpdateTerminalsList,
    removeTerminalManageAccessList,
    removeTerminalViewAccessList,
} = terminalsSlice.actions;

export default terminalsSlice.reducer;
