import CardMain from "../../../../../../models/cardMain";
import { CardStatus } from "../../../../../../models/card.constants";
import { ReactComponent as EditIcon } from "../../../../../../assets/svg/Cards/Edit.svg";
import React from "react";
import ReassignCard from "./ReassignCard";
import titleCase from "../../../../../../hooks/titleCase";
import useCardAssign from "../../../Hooks/State/useCardAssign";
import ButtonComp from "../../../../../../components/button/ButtonComp";

interface ICardAssignedTo {
    cardData: CardMain;
    isSelectedCardDetailsOpen: boolean;
    handleBack: () => void;
    handleReassignCard: () => void;
}

function CardAssignedTo({ isSelectedCardDetailsOpen, cardData, handleBack, handleReassignCard }: ICardAssignedTo): JSX.Element {
    const { activeOpenedReassignCardForms, onEditAssignToCardForm } = useCardAssign();
    return (
        <>
            {!activeOpenedReassignCardForms.includes(cardData.createRequest.id) ? (
                <div
                    className={
                        "w-full pb-6 pt-6 " +
                        `${
                            cardData.card && (cardData.card.isActive || cardData.card.isFrozen || cardData.card.isDeactivated)
                                ? "border-b border-grey-secondary"
                                : ""
                        } ` +
                        `${cardData.card && cardData.card.isDeactivated ? "pt-12" : ""} `
                    }
                    data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}
                >
                    <div className="max-w-md space-y-3" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
                        <div className="flex items-center justify-between">
                            <h3 className="text-base font-medium text-black">Assigned To</h3>
                            <ButtonComp
                                color="black"
                                buttonType="tertiary"
                                func={() => {
                                    handleReassignCard();
                                    onEditAssignToCardForm(cardData?.createRequest?.id);
                                }}
                                disable={!(cardData.card?.status === CardStatus.ACTIVE || cardData.card?.status === CardStatus.FROZEN)}
                            >
                                <EditIcon className="stroke-current" /> <span className="ml-1 capitalize">Edit</span>
                            </ButtonComp>
                        </div>
                        <p>
                            <span
                                className="rounded-full bg-grey-tertiary pb-1 pl-2 pr-2 pt-1 text-sm font-normal text-black-secondary"
                                data-type="card"
                            >
                                {titleCase(cardData.createRequest.assignedTo?.fullName || "")}
                            </span>
                        </p>
                    </div>
                </div>
            ) : null}
            {activeOpenedReassignCardForms.includes(cardData.createRequest.id) ? (
                <ReassignCard cardData={cardData} isSelectedCardDetailsOpen={isSelectedCardDetailsOpen} handleBack={handleBack} />
            ) : null}
        </>
    );
}
export default CardAssignedTo;
