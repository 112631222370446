import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../../../../../../models/card";
import { IRootState } from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import { setTransferBetweenRecipient } from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";

interface useTransferBetweenAccountNumberAutocompleteInterface {
    getPayToOptions: Array<Card | UserAccount>;
    handleSelectRecipient: (e: string) => void;
}

function useTransferBetweenAccountNumberAutocomplete(): useTransferBetweenAccountNumberAutocompleteInterface {
    const dispatch = useDispatch();

    const accounts = useSelector<IRootState, UserAccount[] | undefined>((state: IRootState) => state.init.main?.companyDetails.accounts);
    const originatingAccountId = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails.originatingAccountId);

    const handleSelectRecipient = useCallback(
        (_accountId: string) => {
            const userAccount = accounts?.find((el) => el.id === _accountId);
            if (userAccount && userAccount.bankAccount) {
                dispatch(setTransferBetweenRecipient(userAccount));
            }
        },
        [accounts]
    );

    const getPayToOptions = useMemo((): Array<Card | UserAccount> => {
        if (!accounts) return [];
        const doesAccountExist = accounts.some((el) => el.id === originatingAccountId);
        if (!doesAccountExist) {
            return [...accounts];
        } else {
            const userAccount = accounts.find((el) => el.id === originatingAccountId) as UserAccount;
            if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) return [...userAccount.accountRestrictions.userAccounts];
            return accounts.filter((el) => el.id !== originatingAccountId);
        }
    }, [accounts, originatingAccountId]);

    return {
        getPayToOptions,
        handleSelectRecipient,
    };
}

export default useTransferBetweenAccountNumberAutocomplete;
