import React, { useLayoutEffect } from "react";
import {
    ZambiaApplicationSectionType,
    ZambiaApplicationStagesType,
    ZambiaRegisteredCompanyStages,
} from "../../../../../services/zambia-application/zambia-application.types";

import BusinessContact from "./business-contact";
import BusinessInformation from "./business-information";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { GenericApplicationPageType } from "../../../../../services/application.constants";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../components/spinner";
import useBusinessDetailsInit from "../../../../../Hooks/zambia-application/registered-business/business-details/useBusinessDetailsInit";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

type ApplicationUrlParams = {
    stage: ZambiaApplicationStagesType | undefined;
    section: ZambiaApplicationSectionType | GenericApplicationPageType;
};

function RegisteredBusinessDetails(): JSX.Element {
    const { stage } = useParams<ApplicationUrlParams>();

    const { isBusinessDetailsInitLoading, businessDetailsInitError, handleReset, handleBusinessDetailsInit } = useBusinessDetailsInit();

    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    useLayoutEffect(() => {
        void handleBusinessDetailsInit();

        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            {isBusinessDetailsInitLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {businessDetailsInitError && (
                <div className="flex h-full w-full items-start justify-center">
                    <ErrorToast error={businessDetailsInitError} />
                </div>
            )}
            {accountOpening && !isBusinessDetailsInitLoading && !businessDetailsInitError && (
                <div className="flex h-full w-full flex-row items-start justify-start">
                    {stage === ZambiaRegisteredCompanyStages.BUSINESS_INFORMATION && <BusinessInformation />}
                    {stage === ZambiaRegisteredCompanyStages.BUSINESS_CONTACT && <BusinessContact />}
                </div>
            )}
        </>
    );
}

export default RegisteredBusinessDetails;
