import Modal from "../../../../../../../components/modal/Modal";
import ModalHeader from "../../../../../../../components/modal/modal-header";
import TestCodeForm from "./TestCodeForm";

interface SetupTokenModalProps {
    active: boolean;
    closeFunc: () => void;
}

function SetupTokenModal({ active, closeFunc }: SetupTokenModalProps): JSX.Element {
    const doneHandler = () => {
        closeFunc();
    };

    return (
        <>
            <Modal size="sm" active={active} toggler={doneHandler}>
                <ModalHeader onClose={doneHandler}>
                    <span>Setup token app</span>
                </ModalHeader>
                {active && <TestCodeForm closeFunc={closeFunc} />}
            </Modal>
        </>
    );
}

export default SetupTokenModal;
