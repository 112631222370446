import { DateTypes } from "../../../components/Table/Type";
import { BasicFinalStatus, TransactionType } from "../../../models/posTransaction.constants";
import { PurchaseType, TerminalRequestStatus } from "../../../models/terminal.constants";
import { FilterDateTypes } from "../../../components/filter/filter.constant";
import Merchant from "../../../models/merchant";
import PosTransaction from "../../../models/posTransaction";
import Terminal from "../../../models/terminal";
import TerminalRequest from "../../../models/terminalRequest";
import User from "../../../models/user";

export enum TerminalsStageType {
    TERMINAL_TRANSACTION = 1,
    ALL_TERMINALS = 2,
    TERMINAL_REQUEST = 3,
}
export enum AllTerminalsStageType {
    INITIAL = 1,
    SPECIFIC_TERMINAL_TRANSACTION = 2,
}
export enum SpecificTerminalStageType {
    TERMINAL_TRANSACTIONS = 1,
    SETTINGS = 2,
}

export type DateRange = { endDate?: string; startDate?: string };

export type TerminalTransactionFilterTypes = {
    query?: string;
    offset?: number;
    types?: Array<TransactionType>;
    statuses?: Array<BasicFinalStatus>;
    terminalId?: string;
} & DateRange;

export interface TerminalSettings {
    teamMembersWithManageAccess: User[];
    teamMembersWithViewAccess: User[];
}

export interface TerminalsState {
    terminalStage: TerminalsStageType;
    allTerminalStage: AllTerminalsStageType;
    specificTerminalStage: SpecificTerminalStageType;
    selectedTerminalNameInTerminalStage: string | null;
    specificallySelectedTerminal: Terminal | null;

    initMeta: {
        setupVideoUrl: string | null;
        purchaseCost: number | null;
        cautionFee: number | null;
    } | null;
    merchant: Merchant | null;
    terminals: Terminal[];
    initTerminals: Terminal[];
    requestedTerminals: TerminalRequest[];
    initRequestedTerminals: TerminalRequest[];
    initTerminalRequestsListTotal: number;
    initTerminalRequestsListGroupSize: number;
    teamMembers?: User[];

    isSettingUpTerminalModalOpen: boolean;
    isRequestingTerminalModalOpen: boolean;

    isExportTerminalsLoading: boolean;
    isTerminalPaginationLoading: boolean;
    terminalListTotal: number;
    terminalListGroupSize: number;
    terminalPaginationOffset: number;
    terminalFilterState: {
        offset: number;
        query: string;
        groupId: string;
    };
    selectedTerminalFilterState: {
        offset: number;
        query: string;
        groupId: string;
    };
    isExportTerminalRequestsLoading: boolean;
    isTerminalRequestsPaginationLoading: boolean;
    terminalRequestsListTotal: number;
    terminalRequestsListGroupSize: number;
    terminalRequestsPaginationOffset: number;
    terminalRequestsFilterState: {
        offset: number;
        statuses: TerminalRequestStatus[];
        purchaseTypes: PurchaseType[];
    };
    selectedTerminalRequestsFilterState: {
        offset: number;
        statuses: TerminalRequestStatus[];
        purchaseTypes: PurchaseType[];
    };
    settings: TerminalSettings;
    noRequestedOrActiveTerminal?: boolean;
    transactions: PosTransaction[];
    terminalTransactionDetail: PosTransaction | null;
    isTerminalTransactionDetailCardOpen: boolean;
    isTransactionPaginationLoading: boolean;
    transactionPaginationOffset: number;
    isExportTransactionsLoading: boolean;
    isTransactionAccountQuery: boolean;
    isTransactionSearchQuery: boolean;
    transactionsListTotal: number;
    transactionsListGroupSize: number;
    isTransactionListEmpty: boolean;
    transactionSearchQuery: string;
    transactionAccountQuery: string;
    transactionDate: FilterDateTypes;
    selectedTransactionDate: FilterDateTypes;
    isCustomModalOpen: boolean;

    transactionFilterState: {
        query: string;
        offset: number;
        types: TransactionType[];
        statuses: BasicFinalStatus[];
        terminalId: string;
        startDate: Date | string;
        endDate: Date | string;
    };
    selectedTransactionFilterState: {
        query: string;
        offset: number;
        types: TransactionType[];
        statuses: BasicFinalStatus[];
        terminalId: string;
        startDate: Date | string;
        endDate: Date | string;
    };
}

export type TerminalRequestsFilterTypes = { offset: number } | { statuses?: Array<TerminalRequestStatus> } | { purchaseTypes?: Array<PurchaseType> };

export type TerminalFilterTypes = { offset: number } | { query: string } | { groupId: string };

export type TerminalRequestsFilterState = {
    offset: number;
    statuses: TerminalRequestStatus[];
    purchaseTypes: PurchaseType[];
};

export type TransactionDateObj = {
    startDate: Date | null | string;
    endDate: Date | null | string;
};

export type TerminalTransactionDateType = {
    name: DateTypes;
    date: TransactionDateObj;
};
