import { forgotPasswordData, forgotPasswordDataResponse } from "./forgotPasswordApi.types";

import Parsers from "../../../../utils/parsers";
import { makeRequest } from "../../../../helpers/request/makeRequest";

export const forgotPassword = async (data: forgotPasswordData): Promise<forgotPasswordDataResponse> => {
    const res = await makeRequest("/accounts/forgot-password", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};
