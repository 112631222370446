import ButtonComp from "../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../redux/rootReducer";
import SendableTextarea from "../../../../../../../components/text-area/sendable-text-area";
import Transaction from "../../../../../../../models/transaction";
import titleCase from "../../../../../../../hooks/titleCase";
import useClickOutside from "../../../../../../../hooks/useClickOutside";
import { useDispatch, useSelector } from "react-redux";
import useTransactionDetailsCardHeadSender from "../Hooks/useTransactionDetailsCardHeadSender";
import { setIsEditTransactionAliasModal } from "../../../../../../../redux/accounts/slice/accountsSlice";

interface Props {
    transaction: Transaction;
}

function TransactionDetailsCardHeadSender({ transaction }: Props): JSX.Element {
    const {
        isSubmitting,
        submitForm,

        showEditAliasForm,
        // handleShowEditForm,
        handleHideEditForm,

        senderAlias,
        setSenderAlias,
    } = useTransactionDetailsCardHeadSender(transaction);

    const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);

    const dispatch = useDispatch();

    const domNode = useClickOutside(() => {
        handleHideEditForm();
    });

    const handleOpenIsEditTransactionAliasModal = () => dispatch(setIsEditTransactionAliasModal(true));

    return (
        <>
            {transaction && transaction.isCredit() && (
                <div className="w-full" ref={domNode}>
                    {showEditAliasForm && (
                        <form
                            className="w-full"
                            onSubmit={(e) => {
                                e.preventDefault();
                                void submitForm(transaction, senderAlias || "");
                            }}
                        >
                            <SendableTextarea
                                placeholder="Edit sender alias"
                                transactionCard
                                value={senderAlias}
                                onClickFunc={() => void submitForm(transaction, senderAlias || "")}
                                onChangeFunc={(e) => setSenderAlias(e)}
                                isLoading={isSubmitting}
                                data-type="transaction-active"
                                buttonText="Edit Alias"
                            />
                        </form>
                    )}

                    {!showEditAliasForm && (
                        <>
                            <div className="relative flex flex-col items-end justify-start">
                                <p
                                    className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                    data-type="transaction-active"
                                >
                                    {titleCase(transaction.origination?.alias || transaction.description)}
                                </p>
                                {isNigeria && (
                                    // <ButtonComp buttonType="tertiary" color="blue" size="sm" func={() => handleShowEditForm(transaction)}>
                                    <ButtonComp
                                        buttonType="tertiary"
                                        color="blue"
                                        size="sm"
                                        func={() => {
                                            // handleShowEditForm(transaction);
                                            handleOpenIsEditTransactionAliasModal();
                                        }}
                                    >
                                        <span className="text-sm">Edit Alias</span>
                                    </ButtonComp>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default TransactionDetailsCardHeadSender;
