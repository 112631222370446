import { useEffect, useState } from "react";

import BillProduct from "../../../../../models/billProduct";
import NewSelect from "../../../../../components/new-select";
import ProductItem from "./ProductItem";

export enum DropdownType {
    CONTACT_DROPDOWN = 0,
    METER_NUMBER_DROPDOWN = 1,
    SMART_CARD_DROPDOWN = 2,
}

interface ProductDropdownProps {
    label: string;
    value: string | null;
    options: BillProduct[] | null;
    isDisabled?: boolean;
    onSelect: (e: BillProduct) => void;
}
function ProductDropdown(props: ProductDropdownProps): JSX.Element {
    const { value, options, onSelect, label, isDisabled = false } = props;
    const [data, setData] = useState<BillProduct[] | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        if (!options) return;
        setData(options);
    }, [options]);

    useEffect(() => {
        const filteredData: BillProduct[] = (options || []).filter((item: BillProduct): boolean => {
            if (!searchTerm || searchTerm.trim().length === 0) {
                return true;
            }
            return ((item.name as string) || "").toLowerCase().includes(searchTerm.toLowerCase());
        });
        setData(filteredData);
    }, [options, searchTerm]);

    const handleChangeSearchTerm = (e: string) => {
        setSearchTerm(e);
    };

    return (
        <>
            {data && (
                <NewSelect
                    size="xl"
                    label={label}
                    value={value || ""}
                    isDisabled={isDisabled}
                    searchTerm={searchTerm}
                    optionsLength={data.length}
                    handleChangeSearchTerm={handleChangeSearchTerm}
                    dropdownSize="xl"
                    isSearchable
                    clickAndClose
                >
                    {(_index) => <ProductItem data={data[_index]} onSelect={onSelect} />}
                </NewSelect>
            )}
        </>
    );
}

export default ProductDropdown;
