import {
    resetZambiaCollectionsTransactionsDateFilterState,
    resetZambiaCollectionsTransactionsFilterState,
    setIsZambiaCollectionsTransactionsPaginationLoading,
    setZambiaCollectionsTransactionsList,
    setZambiaCollectionsTransactionsListGroupSize,
    setZambiaCollectionsTransactionsListTotal,
    setZambiaCollectionsTransactionsPaginationOffset,
} from "../../../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { ZambiaCollectionsTransactionsRequest } from "../../Services/Collections/zambiaCollectionsTransactionsApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useAppSelector } from "../../../../../redux/hooks";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { zambiaCollectionsTransactions } from "../../Services/Collections/zambiaCollectionsTransactionsApi";
import { FilterItemSelectType, Item, TableFilterProps } from "../../../../../components/Table/components/TableFilter";
import { DateObj } from "../../../../../components/Table/Type";
import {
    ZambiaCollectionsTransactionsChannelOptions,
    ZambiaCollectionsTransactionsSourceOptions,
    ZambiaCollectionsTransactionsStatusOptions,
} from "./zambiaCollectionsTransactions.constants";
import {
    ZambiaCollectionPaymentChannel,
    ZambiaCollectionSource,
    ZambiaCollectionStatus,
} from "../../../../../models/zambia/zambiaCollectionRequest.constant";

function useZambiaCollectionsTransactions() {
    const dispatch = useDispatch();

    const [filter, setFilter] = useState<ZambiaCollectionsTransactionsRequest>({});

    const zambiaCollectionsTransactionsList = useAppSelector((state) => state.zambiaCollectionsTransactions.zambiaCollectionsTransactionsList);
    const zambiaCollectionsTransactionsListTotal = useAppSelector(
        (state) => state.zambiaCollectionsTransactions.zambiaCollectionsTransactionsListTotal
    );
    const zambiaCollectionsTransactionsListGroupSize = useAppSelector(
        (state) => state.zambiaCollectionsTransactions.zambiaCollectionsTransactionsListGroupSize
    );
    const zambiaCollectionsTransactionsPaginationOffset = useAppSelector(
        (state) => state.zambiaCollectionsTransactions.zambiaCollectionsTransactionsPaginationOffset
    );
    const isZambiaCollectionsTransactionsPaginationLoading = useAppSelector(
        (state) => state.zambiaCollectionsTransactions.isZambiaCollectionsTransactionsPaginationLoading
    );

    const handleGetZambiaCollectionsTransactions = useCallback(
        async (_data: ZambiaCollectionsTransactionsRequest) => {
            try {
                dispatch(setIsZambiaCollectionsTransactionsPaginationLoading(true));
                const res = await zambiaCollectionsTransactions(_data);
                dispatch(setZambiaCollectionsTransactionsList(res.collections));
                dispatch(setZambiaCollectionsTransactionsListTotal(res.total));
                dispatch(setZambiaCollectionsTransactionsListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsZambiaCollectionsTransactionsPaginationLoading(false));
            }
        },
        [dispatch]
    );
    const handleFilteredZambiaCollectionsTransactions = useCallback(async (_data: ZambiaCollectionsTransactionsRequest) => {
        try {
            dispatch(setIsZambiaCollectionsTransactionsPaginationLoading(true));
            const res = await zambiaCollectionsTransactions(_data);
            dispatch(setZambiaCollectionsTransactionsList(res.collections));
            dispatch(setZambiaCollectionsTransactionsListTotal(res.total));
            dispatch(setZambiaCollectionsTransactionsListGroupSize(res.groupSize));
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            dispatch(setIsZambiaCollectionsTransactionsPaginationLoading(false));
        }
    }, []);

    const handleClearFilter = useCallback(async () => {
        setFilter({ query: "" });
        await handleFilteredZambiaCollectionsTransactions({ offset: 0, query: "" });
    }, [filter]);

    const filterOption: TableFilterProps = useMemo(
        () =>
            ({
                items: [
                    {
                        text: "Date",
                        selectType: FilterItemSelectType.DATE_OPTION,
                        onClick: (data) => {
                            setFilter((prev) => ({ ...prev, date: { begin: (data as DateObj)?.begin, end: (data as DateObj)?.end } }));
                        },
                    },
                    {
                        text: "Status",
                        selectType: FilterItemSelectType.RADIO_OPTION,
                        subList: ZambiaCollectionsTransactionsStatusOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: ZambiaCollectionStatus }) => {
                            filter.status = data?.value;
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Channel",
                        selectType: FilterItemSelectType.RADIO_OPTION,
                        subList: ZambiaCollectionsTransactionsChannelOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: ZambiaCollectionPaymentChannel }) => {
                            filter.channel = data?.value;
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Source",
                        selectType: FilterItemSelectType.RADIO_OPTION,
                        subList: ZambiaCollectionsTransactionsSourceOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: ZambiaCollectionSource }) => {
                            filter.source = data?.value;
                            setFilter({ ...filter });
                        },
                    },
                ] as Item[],
                handleApplyFilter: async () => {
                    await handleFilteredZambiaCollectionsTransactions(filter);
                },
            }) as TableFilterProps,
        [filter]
    );

    const handlePaginationRequest = useCallback((offset: number) => {
        dispatch(setZambiaCollectionsTransactionsPaginationOffset(offset));
        handleFilteredZambiaCollectionsTransactions({ offset });
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetZambiaCollectionsTransactionsFilterState());
        dispatch(resetZambiaCollectionsTransactionsDateFilterState());
        void handleGetZambiaCollectionsTransactions({
            query: "",
            offset: 0,
            channel: undefined,
            status: undefined,
            date: {
                begin: "",
                end: "",
            },
        });
    }, []);

    return {
        filter,
        filterOption,
        zambiaCollectionsTransactionsList,
        zambiaCollectionsTransactionsListTotal,
        zambiaCollectionsTransactionsListGroupSize,
        zambiaCollectionsTransactionsPaginationOffset,
        isZambiaCollectionsTransactionsPaginationLoading,
        handleClearFilter,
        handleClearAll,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsTransactions,
    };
}

export default useZambiaCollectionsTransactions;
