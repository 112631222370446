import {
    resetAllElectricityPurchaseData,
    setElectricityPurchaseAccountId,
    setElectricityVerifiedAccountName,
} from "../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { resetBillPaymentData, setBillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch, useSelector } from "react-redux";

import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import BillProduct from "../../../../../../models/billProduct";
import BillPurchase from "../../../../../../models/billPurchase";
import BillVendor from "../../../../../../models/billVendor";
import BillVendorMin from "../../../../../../models/billVendorMin";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import ElectricityPurchaseHeaders from "./ElectricityPurchaseHeaders";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import MoneyInput from "../../../../../../components/inputs/money-input";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import RecentBillPaymentSkeleton from "../../Skeletons/RecentBillPaymentSkeleton";
import RecentContactsElement from "../../Elements/RecentContactsElement";
import VendorDropdown from "../../DropDowns/VendorDropdown";
import VerifiedAccountCard from "../../Cards/verified-account-card";
import { abortBillPayment } from "../../../services/billPayment/billPaymentApi";
import { useCallback } from "react";
import useElectricityPurchaseAmount from "../../../hooks/state/BillPayment/ElectricityPurchase/useElectricityPurchaseAmount";
import useElectricityPurchasePayFrom from "../../../hooks/state/BillPayment/ElectricityPurchase/useElectricityPurchasePayFrom";
import useElectricityPurchasePayTo from "../../../hooks/state/BillPayment/ElectricityPurchase/useElectricityPurchasePayTo";
import useElectricityPurchaseProduct from "../../../hooks/state/BillPayment/ElectricityPurchase/useElectricityPurchaseProduct";
import useElectricityPurchaseVendor from "../../../hooks/state/BillPayment/ElectricityPurchase/useElectricityPurchaseVendor";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();

    const vendorsList = useSelector((state: IRootState) => state.billPayment.vendorsList);
    const recentBillPurchases = useSelector((state: IRootState) => state.billPayment.recentBillPurchases);
    const selectedVendorName = useSelector((state: IRootState) => state.electricityPurchase.selectedVendorName);
    const selectedUserAccount = useSelector((state: IRootState) => state.electricityPurchase.selectedUserAccount);
    const selectedProductName = useSelector((state: IRootState) => state.electricityPurchase.selectedProductName);
    const isRecentBillPurchasesLoading = useSelector((state: IRootState) => state.billPayment.isRecentBillPurchasesLoading);
    const selectedProductRange = useSelector((state: IRootState) => state.electricityPurchase.selectedProductRange);
    const electricityPurchaseDetails = useSelector((state: IRootState) => state.electricityPurchase.electricityPurchaseDetails);
    const selectedPayToAccount = useSelector((state: IRootState) => state.electricityPurchase.electricityPurchaseDetails.accountId);
    const electricityVerifiedAccountName = useSelector((state: IRootState) => state.electricityPurchase.electricityVerifiedAccountName);
    // const selectedVendorRange = useSelector((state: IRootState) => state.airtimePurchase.selectedVendorRange);

    const { handleSelectVendor } = useElectricityPurchaseVendor();
    const { handleAmountChange } = useElectricityPurchaseAmount();
    const { handleSelectProduct } = useElectricityPurchaseProduct();
    const { accounts, selectedAccountId, handleSelectAccount } = useElectricityPurchasePayFrom();
    const {
        meterNumber,
        electricityPurchaseVerifyAccountError,
        isElectricityPurchaseVerifyAccountLoading,
        handleSetMeterNumber,
        handleSetMeterNumberInstant,
    } = useElectricityPurchasePayTo();

    const handleSelectRecentPurchase = useCallback(
        (_data: BillPurchase) => {
            dispatch(setElectricityPurchaseAccountId(_data.extraData.account.accountId));
            dispatch(setElectricityVerifiedAccountName(_data.extraData.account.customerName));
            handleAmountChange(String(_data.amount));
            handleSelectVendor(_data.vendor);
            handleSelectProduct(vendorsList?.find((_vendor) => _vendor.id === _data.product.vendor?.id)?.products[0] as BillProduct);
            handleSetMeterNumberInstant(_data.extraData.account.accountId);
        },
        [vendorsList]
    );

    const handleSelectVendorAndProduct = useCallback(
        (_data: BillVendor | BillVendorMin) => {
            handleSelectVendor(_data);
            handleSelectProduct(vendorsList?.find((_vendor) => _vendor.id === _data?.id)?.products[0] as BillProduct);
        },
        [vendorsList]
    );

    const handleBack = useCallback(() => {
        dispatch(resetBillPaymentData());
        dispatch(resetAllElectricityPurchaseData());
        abortBillPayment();
    }, []);

    const handleNextPage = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.REVIEW_PAYMENT));
    }, []);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <ElectricityPurchaseHeaders />

                {!isRecentBillPurchasesLoading && recentBillPurchases && recentBillPurchases.length > 0 && (
                    <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Recent Purchase</p>
                        <div className="flex items-center justify-start space-x-7 overflow-auto">
                            {recentBillPurchases.slice(-3).map((_purchase, index) => (
                                <RecentContactsElement
                                    key={index}
                                    data={_purchase}
                                    onClick={handleSelectRecentPurchase}
                                    isPhoneNumber={false}
                                    selectedAccountId={selectedAccountId}
                                    handleSelectAccount={handleSelectAccount}
                                    productName
                                />
                            ))}
                        </div>
                    </div>
                )}
                {isRecentBillPurchasesLoading && <RecentBillPaymentSkeleton />}
                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Pay from</p>
                        <NewUserAccountDropdown
                            value={selectedAccountId || ""}
                            options={accounts || null}
                            onClick={handleSelectAccount}
                            isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                            dropdownSize="xl"
                            showBalance
                            initiatorCanSelect
                        />
                    </div>

                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Payment Details</p>

                        <VendorDropdown
                            label="Service Provider"
                            value={selectedVendorName}
                            options={vendorsList}
                            onSelect={handleSelectVendorAndProduct}
                        />
                        <MoneyInput
                            label="Amount"
                            value={electricityPurchaseDetails.amount || ""}
                            inputSize="xl"
                            onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                        />
                        <Input label="Meter Number" value={meterNumber} inputSize="xl" onChange={handleSetMeterNumber} />
                        {electricityVerifiedAccountName && electricityPurchaseVerifyAccountError.length < 1 && (
                            <VerifiedAccountCard label="Customer Name" value={electricityVerifiedAccountName} />
                        )}
                    </div>
                    {electricityPurchaseVerifyAccountError.length > 0 && (
                        <MessageToast message={electricityPurchaseVerifyAccountError} type="error" fullWidth />
                    )}
                    {electricityPurchaseDetails.amount.length > 0 &&
                        !!useMoneyToNumber(electricityPurchaseDetails.amount) &&
                        (useMoneyToNumber(electricityPurchaseDetails.amount) > (selectedProductRange?.max as number) ||
                            useMoneyToNumber(electricityPurchaseDetails.amount) < (selectedProductRange?.min as number)) && (
                            <MessageToast
                                message={
                                    <p>
                                        Amount must be between <Money amount={selectedProductRange?.min || 0} /> and{" "}
                                        <Money amount={selectedProductRange?.max || 0} />
                                    </p>
                                }
                                type="error"
                                fullWidth
                            />
                        )}
                    {useMoneyToNumber(electricityPurchaseDetails.amount) > (selectedUserAccount?.balance as number) && (
                        <div className="w-full max-w-sm">
                            <MessageToast
                                message={
                                    <p>
                                        You do not have enough funds. Your account balance is <Money amount={selectedUserAccount?.balance || 0} />
                                    </p>
                                }
                                type="error"
                                fullWidth
                            />
                        </div>
                    )}
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="xl" func={handleBack}>
                        Back
                    </ButtonComp>

                    <ButtonComp
                        size="xl"
                        color="black"
                        isLoading={isElectricityPurchaseVerifyAccountLoading}
                        buttonType="primary"
                        func={handleNextPage}
                        disable={
                            !selectedUserAccount ||
                            !selectedVendorName ||
                            !selectedProductName ||
                            !electricityVerifiedAccountName ||
                            selectedPayToAccount.length < 5 ||
                            electricityPurchaseVerifyAccountError.length > 0 ||
                            useMoneyToNumber(electricityPurchaseDetails.amount) > (selectedProductRange?.max as number) ||
                            useMoneyToNumber(electricityPurchaseDetails.amount) < (selectedProductRange?.min as number) ||
                            useMoneyToNumber(electricityPurchaseDetails.amount) === 0 ||
                            useMoneyToNumber(electricityPurchaseDetails.amount) > (selectedUserAccount?.balance as number)
                        }
                    >
                        Review Payment
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default PaymentDetails;
