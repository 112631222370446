import {
    setIsZambiaDeactivateApiAccessKeyModalOpen,
    setIsZambiaRegenerateApiAccessKeyModalOpen,
    setTempSelectedZambiaApiAccessKey,
} from "../../../../../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";

import ApiAccess from "../../../../../../../../../../models/zambia/lencoPay/apiAccess";
import { ReactComponent as DeactivateIcon } from "../../../../../../../../../../assets/svg/LencoPay/DeactivateIcon.svg";
import DropdownHead from "../../../../../../../../../../components/dropdown/dropdown-components/dropdown-head";
import DropdownLink from "../../../../../../../../../../components/dropdown/dropdown-components/dropdown-link";
import { ReactComponent as PauseIcon } from "../../../../../../../../../../assets/svg/LencoPay/PauseIcon.svg";
import { ReactComponent as PlayIcon } from "../../../../../../../../../../assets/svg/LencoPay/PlayIcon.svg";
import React from "react";
import { ReactComponent as RefreshIcon } from "../../../../../../../../../../assets/svg/LencoPay/RefreshIcon.svg";
import { useDispatch } from "react-redux";
import usePauseZambiaApiAccessKey from "../../../../../../../hookss/ApiAccessKeys/usePauseZambiaApiAccessKey";
import useRestoreZambiaApiAccessKey from "../../../../../../../hookss/ApiAccessKeys/useRestoreZambiaApiAccessKey";

const DATA_TYPE = "api-access-key";

interface Props {
    apiAccessKey: ApiAccess;
}

function ApiAccessKeySettingDropdown(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const { handlePauseZambiaApiAccessKey, isPauseZambiaApiAccessKeyLoading } = usePauseZambiaApiAccessKey();
    const { handleRestoreZambiaApiAccessKey, isRestoreZambiaApiAccessKeyLoading } = useRestoreZambiaApiAccessKey();

    return (
        <>
            <DropdownHead
                placeholder={
                    <p
                        className={
                            `text-xs font-medium ` +
                            `${props.apiAccessKey.isActive ? " text-success-secondary" : props.apiAccessKey.isPaused ? " text-warning" : " text-error-quat"}`
                        }
                    >
                        {props.apiAccessKey.statusText}
                    </p>
                }
                loadingColor={props.apiAccessKey.isPaused ? "#F07911" : undefined}
                outlineBorder
                borderColor={
                    props.apiAccessKey.isActive
                        ? "border-success-quin bg-success-senary hover:text-success text-success "
                        : props.apiAccessKey.isPaused
                          ? "border-warning-quin hover:border-warning bg-warning-backdrop hover:bg-warning-backdrop hover:text-warning text-warning "
                          : "border-error-quin hover:border-error-quin bg-error-backdrop hover:bg-error-backdrop hover:text-error-quat text-error-quat "
                }
                placement="right"
                isDisabled={!props.apiAccessKey.isAccessible}
                clickAndClose
                isLoading={isPauseZambiaApiAccessKeyLoading || isRestoreZambiaApiAccessKeyLoading}
                size="xs"
            >
                {props.apiAccessKey.isPaused && (
                    <DropdownLink data-type={DATA_TYPE} onClick={() => handleRestoreZambiaApiAccessKey({ id: props.apiAccessKey.id })}>
                        <div className="flex w-full flex-row items-center justify-start space-x-4 px-4" data-type={DATA_TYPE}>
                            <PlayIcon data-type={DATA_TYPE} />
                            <span className="text-xs" data-type={DATA_TYPE}>
                                Go Live
                            </span>
                        </div>
                    </DropdownLink>
                )}
                {props.apiAccessKey.isActive && (
                    <DropdownLink data-type={DATA_TYPE} onClick={() => handlePauseZambiaApiAccessKey({ id: props.apiAccessKey.id })}>
                        <div className="flex w-full flex-row items-center justify-start space-x-4 px-4" data-type={DATA_TYPE}>
                            <PauseIcon data-type={DATA_TYPE} />
                            <span className="text-xs" data-type={DATA_TYPE}>
                                Pause
                            </span>
                        </div>
                    </DropdownLink>
                )}

                <DropdownLink
                    data-type={DATA_TYPE}
                    onClick={() => {
                        dispatch(setTempSelectedZambiaApiAccessKey(props.apiAccessKey));
                        dispatch(setIsZambiaRegenerateApiAccessKeyModalOpen(true));
                    }}
                >
                    <div className="flex w-full flex-row items-center justify-start space-x-4 px-4" data-type={DATA_TYPE}>
                        <RefreshIcon data-type={DATA_TYPE} />
                        <span className="text-xs" data-type={DATA_TYPE}>
                            Regenerate Keys
                        </span>
                    </div>
                </DropdownLink>

                <DropdownLink
                    data-type={DATA_TYPE}
                    color="red"
                    onClick={() => {
                        dispatch(setTempSelectedZambiaApiAccessKey(props.apiAccessKey));
                        dispatch(setIsZambiaDeactivateApiAccessKeyModalOpen(true));
                    }}
                >
                    <div className="flex w-full flex-row items-center justify-start space-x-4 px-4" data-type={DATA_TYPE}>
                        <DeactivateIcon data-type={DATA_TYPE} />
                        <span className="text-xs text-error" data-type={DATA_TYPE}>
                            Deactivate
                        </span>
                    </div>
                </DropdownLink>
            </DropdownHead>
        </>
    );
}

export default ApiAccessKeySettingDropdown;
