import React from "react";
import SkeletonElement from "../../../../../../components/skeleton";

function TeamMemberDetailsSkeleton(): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-row items-center justify-between space-x-4 py-4">
                <div className="flex w-full flex-col items-start justify-start space-y-8 border-grey-secondary pb-4 sm:border-none">
                    <SkeletonElement title />
                    <SkeletonElement title />
                </div>

                <div className="flex w-full flex-col space-y-8 border-b-0.2 border-grey-secondary pb-4 sm:border-none">
                    <SkeletonElement title />
                    <SkeletonElement title />
                </div>

                <div className="item-start flex w-full flex-col justify-start space-y-8 border-b-0.2 border-grey-secondary pb-4 sm:border-none">
                    <SkeletonElement title />

                    <div className="item-start flex w-full flex-row justify-start space-x-8">
                        <SkeletonElement title />
                        <SkeletonElement title />
                    </div>
                </div>

                <div className="flex items-start justify-start sm:w-2/4 sm:items-center sm:justify-center">
                    <SkeletonElement title />
                    <SkeletonElement title />
                </div>
            </div>
        </>
    );
}

export default TeamMemberDetailsSkeleton;
