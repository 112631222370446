import React, { useLayoutEffect, useState } from "react";

import EmailPreviouslyVerified from "../components/email-previously-verified";
import EmailVerified from "../components/email-verified";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import LencoSpinner from "../../../../components/spinner";
import SetPassword from "../components/set-password";
import { VerifyEmailData } from "../services/email-verification.types";
import useEmailVerification from "../hooks/use-email-verification";
import { useParams } from "react-router-dom";

function EmailVerification(): JSX.Element {
    // document.title = PageTitle.EMAIL_VERIFIED_PAGE;

    const urlDataParams = useParams<VerifyEmailData>();

    const { data, error, isPending, mutate } = useEmailVerification();

    const urlData = { a: urlDataParams.a || "", b: urlDataParams.b || "", c: urlDataParams.c || "" };

    const [hasSubmittedSetPassword, setHasSubmittedSetPassword] = useState<boolean>(false);

    useLayoutEffect(() => {
        mutate(urlData);
    }, []);

    return (
        <>
            {isPending && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner size="lg" />
                </div>
            )}

            {error && (
                <div className="mx-auto flex w-max items-center justify-center pt-8">
                    <ErrorToast error={error} />
                </div>
            )}

            {!isPending && data && (
                <>
                    {data.previouslyVerified ? (
                        <EmailPreviouslyVerified />
                    ) : data.setPassword && !hasSubmittedSetPassword ? (
                        <SetPassword urlData={urlData} onComplete={() => setHasSubmittedSetPassword(true)} />
                    ) : (
                        <EmailVerified username={data.username} justSetPassword={data.setPassword && hasSubmittedSetPassword} />
                    )}
                </>
            )}
        </>
    );
}
export default EmailVerification;
