// import { TableHead } from "../Type/HeadRow";
// import { TableColumnType } from "../Type/TableColumnType";

import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";

export const headerData: TableHead = [
    { text: "Account", pos: 1, headType: TableColumnType.LOGO_TREND_SUBTEXT_FROM_TEXT },
    { text: "Recipient/Sender", pos: 2, headType: TableColumnType.LOGO_FROM_TEXT },
    { text: "Type", pos: 3, headType: TableColumnType.TREND_LOGO },
    { text: "Amount", pos: 4, headType: TableColumnType.AMOUNT },
    { text: "Balance", pos: 5, headType: TableColumnType.AMOUNT },
];
