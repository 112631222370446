import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../element/ApplicationHeader";
import BusinessInformationReview from "./components/review/BusinessInformationReview";
import BusinessPersonalDetailsReview from "../../registered-business/business-review/components/review/BusinessPersonalDetailsReview";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../components/spinner";
import PreviewCard from "../../../../cards/PreviewCard";
import PreviewModal from "../../../../modals/preview-modal";
import { Routes } from "../../../../../../../../routes/routes.constants";
import Skeleton from "../../../../../../../../components/skeleton";
import useAccountSignatoriesInit from "../../../../../Hooks/nigeria-application/registered-business/account-signatories/useAccountSignatoriesInit";
import useBusinessDetailsInit from "../../../../../Hooks/nigeria-application/registered-business/business-details/useBusinessDetailsInit";
import useNigeriaInit from "../../../../../Hooks/nigeria-application/general/useNigeriaInit";
import usePreview from "../../../../../Hooks/nigeria-application/registered-business/account-signatories/usePreview";
import { useSelector } from "react-redux";

type LocationState = {
    from?: string;
};

function UnregisteredBusinessReview(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
    });
    const { currentSignatoryError, isCurrentSignatoryLoading, handleCurrentSignatoryInit } = useAccountSignatoriesInit();
    const { businessDetailsInitError, isBusinessDetailsInitLoading, handleBusinessDetailsInit } = useBusinessDetailsInit();
    const {
        nigeriaAccountOpeningInitError,
        isNigeriaAccountOpeningInitLoading,
        handleReset: handleResetNigeriaInit,
        handleNigeriaAccountOpeningInit,
    } = useNigeriaInit();

    const divRef = useRef<HTMLDivElement | null>(null);

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const businessDetails = useSelector((state: IRootState) => state.application.businessDetails);
    const currentSignatory = useSelector((state: IRootState) => state.application.currentSignatory);

    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    useLayoutEffect(() => {
        void handleBusinessDetailsInit();
        void handleCurrentSignatoryInit();
        void handleNigeriaAccountOpeningInit();
    }, []);

    useEffect(() => {
        if (currentSignatory) void handlePreviewDocument(currentSignatory?.photoId || "", true);
    }, [currentSignatory]);

    useEffect(() => {
        return () => {
            handleResetNigeriaInit();
            // handleReset();
            // handleResetBusinessDetails();
        };
    }, []);

    const handleBack = () => {
        if (locationState?.from === Routes.ACCOUNT_OPENING.STATUS) {
            navigate(Routes.ACCOUNT_OPENING.STATUS);
            return;
        }
        navigate(Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.PHOTOGRAPH);
    };

    return (
        <>
            <PreviewModal active={showPreviewModal} toggler={() => setShowPreviewModal(false)} data={previewBlob} title="Preview" />

            {(isCurrentSignatoryLoading || isBusinessDetailsInitLoading || isNigeriaAccountOpeningInitLoading) && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <LencoSpinner />
                </div>
            )}
            {(currentSignatoryError || businessDetailsInitError || nigeriaAccountOpeningInitError) && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <ErrorToast error={currentSignatoryError || businessDetailsInitError || nigeriaAccountOpeningInitError} />
                </div>
            )}
            {accountOpening &&
                currentSignatory &&
                !(isCurrentSignatoryLoading || isBusinessDetailsInitLoading || isNigeriaAccountOpeningInitLoading) &&
                !(currentSignatoryError || businessDetailsInitError || nigeriaAccountOpeningInitError) && (
                    <div className="flex w-full flex-row items-start justify-start space-x-6">
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Review your Application"
                                subTitle="You can make any necessary changes as you complete your application."
                                progress={accountOpening.progress.unregisteredProgress()}
                            >
                                <div className="relative flex w-full flex-col items-start justify-start space-y-8">
                                    <div className="flex w-full items-center justify-center">
                                        <div className="h-24 w-24 overflow-hidden rounded-full border">
                                            {previewBlob ? (
                                                <img className="h-full w-full object-cover" src={URL.createObjectURL(previewBlob)} />
                                            ) : (
                                                <Skeleton box />
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                                        <div className="flex w-full flex-row items-start justify-between">
                                            <h3 className="font-medium text-black-secondary">Business information</h3>
                                            <ButtonComp
                                                type="button"
                                                color="blue"
                                                ripple="light"
                                                buttonType="tertiary"
                                                func={() =>
                                                    navigate(
                                                        {
                                                            pathname: Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS,
                                                        },
                                                        {
                                                            state: {
                                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                                            },
                                                        }
                                                    )
                                                }
                                                size="sm"
                                            >
                                                <span>Change</span>
                                            </ButtonComp>
                                        </div>
                                        <BusinessInformationReview data={businessDetails} />
                                    </div>

                                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                                        <div className="flex w-full flex-row items-start justify-between">
                                            <h3 className="font-medium text-black-secondary">Personal Details</h3>
                                            <ButtonComp
                                                type="button"
                                                color="blue"
                                                ripple="light"
                                                buttonType="tertiary"
                                                func={() =>
                                                    navigate(
                                                        {
                                                            pathname: Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS,
                                                        },
                                                        {
                                                            state: {
                                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                                            },
                                                        }
                                                    )
                                                }
                                                size="sm"
                                            >
                                                <span>Change</span>
                                            </ButtonComp>
                                        </div>
                                        <BusinessPersonalDetailsReview data={currentSignatory || undefined} />
                                    </div>

                                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                                        <div className="flex w-full flex-row items-start justify-between">
                                            <h3 className="font-medium text-black-secondary">Means of ID</h3>
                                            <ButtonComp
                                                type="button"
                                                color="blue"
                                                ripple="light"
                                                buttonType="tertiary"
                                                func={() =>
                                                    navigate(
                                                        {
                                                            pathname: Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID,
                                                        },
                                                        {
                                                            state: {
                                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                                            },
                                                        }
                                                    )
                                                }
                                                size="sm"
                                            >
                                                <span>Change</span>
                                            </ButtonComp>
                                        </div>
                                        <PreviewCard
                                            icon={
                                                <div className="flex max-w-[20px] items-center justify-center overflow-hidden text-xl">
                                                    <span className="material-symbols-outlined">add_notes</span>
                                                </div>
                                            }
                                            text={
                                                accountOpening.options.idType?.find((_el) => _el.id === currentSignatory?.identification?.type)
                                                    ?.name || ""
                                            }
                                            canNotPreview={!currentSignatory.identification?.fileId}
                                            handlePreviewDocumentFunc={() =>
                                                void handlePreviewDocument(currentSignatory.identification?.fileId || "")
                                            }
                                        />
                                    </div>

                                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                                        <div className="flex w-full flex-row items-start justify-between">
                                            <h3 className="font-medium text-black-secondary">Photograph</h3>
                                            <ButtonComp
                                                type="button"
                                                color="blue"
                                                ripple="light"
                                                buttonType="tertiary"
                                                func={() =>
                                                    navigate(
                                                        {
                                                            pathname: Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.PHOTOGRAPH,
                                                        },
                                                        {
                                                            state: {
                                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                                            },
                                                        }
                                                    )
                                                }
                                                size="sm"
                                            >
                                                <span>Change</span>
                                            </ButtonComp>
                                        </div>
                                        <PreviewCard
                                            icon={
                                                <div className="flex max-w-[20px] items-center justify-center overflow-hidden text-xl">
                                                    <span className="material-symbols-rounded">account_circle</span>
                                                </div>
                                            }
                                            text="Photograph"
                                            canNotPreview={!currentSignatory.identification?.fileId}
                                            handlePreviewDocumentFunc={() => void handlePreviewDocument(currentSignatory?.photoId || "")}
                                        />
                                    </div>
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4" ref={divRef}>
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span>Back</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                    <ButtonComp
                                        type="button"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        func={() => navigate(Routes.ACCOUNT_OPENING.DONE)}
                                        disable={accountOpening.progress.unregisteredProgress() !== 100}
                                        fullWidth
                                    >
                                        <span>Complete Application</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </div>
                        {/* <div className="fixed bottom-16 right-8 z-40">
                            <ButtonComp
                                color="white"
                                buttonType="flat"
                                size="sm"
                                fullBorder
                                func={() => {
                                    divRef.current?.scrollIntoView({ behavior: "smooth" });
                                }}
                            >
                                <div className="flex max-w-[20px] items-center justify-center overflow-hidden">
                                    <span className="material-symbols-rounded">keyboard_double_arrow_down</span>
                                </div>
                                <span className="ml-2 text-sm font-medium normal-case text-blue">Jump to end</span>
                            </ButtonComp>
                        </div> */}
                    </div>
                )}
        </>
    );
}

export default UnregisteredBusinessReview;
