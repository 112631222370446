export enum ApiAccessStatus {
    ACTIVE = "active",
    DEACTIVATED = "deactivated",
    PAUSED = "paused",
    DISABLED_BY_LENCO = "disabled-by-lenco",
}

export enum ApiAccessScope {
    COLLECTIONS_MOBILE_MONEY = "collections/mobile-money",
    COLLECTIONS_CARD = "collections/card",
    DISBURSEMENT_BANK_ACCOUNT = "disbursement/bank-account",
    DISBURSEMENT_MOBILE_MONEY = "disbursement/mobile-money",
}

export enum ApiAccessSource {
    MERCHANT = "merchant",
    CUSTOMER = "customer",
    EITHER = "either",
}
