import BillProduct from "./billProduct";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class BillVendor {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string | null,
        public logoUrl: string | null,
        public products: BillProduct[]
    ) {}

    static create(obj: GenericObject): BillVendor {
        return new BillVendor(
            Parsers.string(obj.id),
            Parsers.nullableString(obj.name),
            Parsers.nullableString(obj.logoUrl),
            Parsers.classObjectArray(obj.products, BillProduct)
        );
    }

    update(billVendor: BillVendor): void {
        // todo -> might ignore some if the data is not complete (i.e the object is not full)
        this.products = billVendor.products;
        // if (userAccount.monthBalance) {
        // this.monthBalance = userAccount.monthBalance;
        // }
        // this.type = userAccount.type;
        // if (!isNullOrUndefined(userAccount.todayTransfersTotal)) {
        // this.todayTransfersTotal = userAccount.todayTransfersTotal;
        // }
    }

    // get splitInflowInstruction(): AccountBalanceInstruction | undefined {
    // return this.balanceInstructions.find((b) => b.type === AccountBalanceInstructionType.SPLIT_INFLOW);
    // }

    // getBalanceInstructionByType(type: AccountBalanceInstructionType): AccountBalanceInstruction | undefined {
    // return this.balanceInstructions.find((instruction) => instruction.type === type);
    // }
}
