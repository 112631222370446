import CollectionAccount from "./collectionAccount";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import PosTransactionStat from "./PosTransactionStat";
import { TerminalStatus } from "./terminal.constants";
import UserAccount from "./userAccount";
import { immerable } from "immer";
import { TextType } from "../components/Table/Type/TextType";

export default class Terminal {
    [immerable] = true;

    constructor(
        public id: string,
        public assignedAt: string,
        public name: string | null,
        public serialNumber: string,
        public model: string | null,
        public settlementAccount: UserAccount | null,
        public collectionsAccount: CollectionAccount | null,
        public status: TerminalStatus | null,
        public revokedAt: string | null,
        public stats: PosTransactionStat | null
    ) {}

    static create(obj: GenericObject): Terminal {
        return new Terminal(
            Parsers.string(obj.id),
            Parsers.string(obj.assignedAt),
            Parsers.nullableString(obj.name),
            Parsers.string(obj.serialNumber),
            Parsers.nullableString(obj.model),
            Parsers.classObject(obj.settlementAccount, UserAccount),
            Parsers.classObject(obj.collectionsAccount, CollectionAccount),
            Parsers.number(obj.status),
            Parsers.nullableString(obj.revokedAt),
            Parsers.classObject(obj.stats, PosTransactionStat)
        );
    }

    getType(): string {
        return "Digital";
        // return this.type === TerminalType.DIGITAL ? "Digital" : "";
    }
    getStatusText(): string {
        return this.status === TerminalStatus.ACTIVE ? "Active" : "Revoked";
    }

    getTableStatusType(): TextType {
        return this.status === TerminalStatus.ACTIVE ? TextType.TERMINAL_STATUS_ACTIVE : TextType.TERMINAL_STATUS_REVOKED;
    }

    // get isSuccess(): boolean {
    // return this.status === TerminalTransactionStatus.SUCCESS;
    // }
}
