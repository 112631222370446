import { BillPaymentStage, BillPaymentType } from "../../redux/payments/billPayment/slice/billPaymentSlice.types";
import { Link, Outlet } from "react-router-dom";
import { PaymentStageType, PaymentType } from "../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { closeAddFundsModal, closeZambiaAddFundsModal } from "../../redux/accounts/slice/accountsSlice";
import { setBillPaymentStage, setBillPaymentType } from "../../redux/payments/billPayment/slice/billPaymentSlice";
import { setPaymentStage, setPaymentType } from "../../redux/payments/sendMoney/slice/sendMoneySlice";
import { useDispatch, useSelector } from "react-redux";

import AccountDropdown from "./components/dashboard/account-dropdown";
import AddFundsTray from "../../modules/dashboard/Accounts/Components/Trays/AddFundsTray";
import AddNewBusinessModal from "./components/dashboard/modals/add-new-businesss";
import BusinessDropdown from "./components/dashboard/business-dropdown";
import ButtonComp from "../button/ButtonComp";
import DashboardSearchBar from "./components/dashboard/dashboard-search-bar";
import GoogleIcon from "../google-icon";
import { IRootState } from "../../redux/rootReducer";
import IdleModal from "./components/dashboard/modals/idle";
import IntercomHelper from "../../helpers/intercom";
import { LencoActiveCountries } from "../../redux/init/slice/initSlice.types";
import LencoSpinner from "../spinner";
import { ReactComponent as Logo } from "../../assets/svg/lencoLogo.svg";
import MenuItem from "./components/dashboard/menu-item";
import MenuItemWithChildren from "./components/dashboard/menu-item-with-children";
import NigeriaFlag from "../../assets/images/countries/nigeria.png";
import Overlay from "./components/dashboard/overlay";
import { Routes } from "../../routes/routes.constants";
import SendMoneyDropdown from "./components/dashboard/send-money-dropdown";
import TransactionDetailCard from "../../modules/dashboard/Transactions/Components/Cards/TransactionDetailCard";
import ZambiaAddFundsModal from "../../modules/dashboard/Accounts/Components/Modals/ZambiaAddMoneyModal";
import ZambiaFlag from "../../assets/images/countries/zambia.png";
import { hideTransactionDetails } from "../../redux/transaction/slice/transactionSlice";
import { useAppSelector } from "../../redux/hooks";
import useDimension from "../../hooks/useDimension";
import useLogout from "../init/hooks/use-logout";
import usePingHook from "../../helpers/ping/hook/use-ping-hook";

declare global {
    interface Window {
        Headway: {
            init: (e: unknown) => void;
        };
    }
}

interface Props {
    isLoading?: boolean;
    children?: React.ReactNode;
}

export default function DashboardLayout(props: Props) {
    const { initPing } = usePingHook();
    // const location = useLocation();
    const dispatch = useDispatch();
    const { width } = useDimension();

    const { mutate: logoutMutate } = useLogout();

    const main = useAppSelector((state) => state.init.main);
    const isAdmin = useAppSelector((state) => state.init.main?.companyDetails?.user.isAdmin);
    const isLencoPayEnabled = useAppSelector((state) => state.init.main?.meta.lencoPay.isEnabled);
    const isNigeria = useAppSelector(
        (state) =>
            state.init.main?.companyDetails?.company.isNigeria ||
            state.init.account?.companies.find((_) => _.id === state.init.selectedCompanyId)?.country === LencoActiveCountries.NIGERIA
    );
    const isZambia = useAppSelector(
        (state) =>
            state.init.main?.companyDetails?.company.isZambia ||
            state.init.account?.companies.find((_) => _.id === state.init.selectedCompanyId)?.country === LencoActiveCountries.ZAMBIA
    );
    const canAccessCards = useAppSelector((state) => state.init.canAccessCards);
    const canAccessTerminals = useAppSelector((state) => state.init.canAccessTerminals);
    const hasViewLencoPayAccess = useAppSelector((state) => state.init.main?.companyDetails.user.hasViewLencoPayAccess);
    const canApprove = useAppSelector(
        (state) => state.init.main?.companyDetails.userAccountsMeta.some((accountMeta) => accountMeta.canApprove()) || false
    );

    const [showNav, setShowNav] = useState<boolean>(false);
    const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false);
    const [showAddNewBusinessModal, setShowAddNewBusinessModal] = useState<boolean>(false);
    const isZambiaAddFundsModalOpen = useSelector((state: IRootState) => state.account.isZambiaAddFundsModalOpen);

    useLayoutEffect(() => {
        initPing();
        setIsAccountCreated(!!main?.companyDetails.company.accountCreated);
    }, [main]);

    useEffect(() => {
        setShowNav(false);
        document.body.style.overflow = "auto";
        dispatch(hideTransactionDetails());
        dispatch(closeAddFundsModal());
        dispatch(closeZambiaAddFundsModal());
    }, [location]);

    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://cdn.headwayapp.co/widget.js";
        document.head.appendChild(script);
        const config = {
            selector: ".headway-badge",
            account: process.env.REACT_APP_HEADWAY_KEY,
            trigger: ".headway-trigger",
        };
        script.onload = function () {
            window.Headway.init(config);
        };
    }, []);

    const handleOpenNav = () => {
        if (width < 1025) {
            setShowNav(true);
            document.body.style.overflow = "hidden";
        }
    };

    const handleCloseNav = () => {
        if (width < 1025) {
            setShowNav(false);
            document.body.style.overflow = "auto";
        }
    };

    const handleShowIntercom = () => {
        IntercomHelper.show();
    };

    return (
        <>
            <div className="h-full min-h-screen w-full">
                <div className="relative min-h-screen bg-white lg:grid-cols-dash 4xs:grid" id="dashboard" tabIndex={-1}>
                    <IdleModal />
                    <AddNewBusinessModal active={showAddNewBusinessModal} toggler={() => setShowAddNewBusinessModal(false)} />
                    <TransactionDetailCard />
                    {showNav && <Overlay onClick={handleCloseNav} />}
                    <AddFundsTray />
                    {isZambiaAddFundsModalOpen && <ZambiaAddFundsModal />}
                    <aside
                        className={
                            `${showNav ? "translate-x-0" : "-translate-x-full"} ` +
                            ` fixed z-40 flex w-80% max-w-16 transform flex-col items-center justify-between border-r border-grey-secondary bg-white transition-all -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available lg:relative lg:w-auto lg:-translate-x-0 lg:transition-none`
                        }
                        tabIndex={-1}
                    >
                        <div className="flex h-full w-full flex-col items-start justify-between pt-4 scrollbar-hide">
                            <div className="relative h-full w-full">
                                <div className="absolute left-0 top-0 flex h-full w-full flex-col">
                                    <div className="flex h-6 items-center justify-between px-6">
                                        <Link to={Routes.DASHBOARD} tabIndex={showNav ? 0 : -1}>
                                            <div className="flex w-28 cursor-pointer items-center justify-center">
                                                <Logo />
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="mt-10 px-4">
                                        <BusinessDropdown handleAddNewBusiness={() => setShowAddNewBusinessModal(true)} />
                                    </div>
                                    <div className="flex w-full flex-grow flex-col justify-between py-8">
                                        <div className="relative flex w-full flex-grow overflow-y-auto">
                                            <div className="absolute left-0 top-0 flex w-full flex-col space-y-2 px-4 pb-8">
                                                {!isAccountCreated ? (
                                                    <MenuItem
                                                        onClick={handleOpenNav}
                                                        path="/application"
                                                        icon="assignment"
                                                        text="Application"
                                                        isIconOutlined
                                                        hasSubRoutes
                                                    />
                                                ) : (
                                                    <>
                                                        <MenuItem
                                                            onClick={handleOpenNav}
                                                            path={Routes.DASHBOARD}
                                                            icon="home"
                                                            text="Home"
                                                            isIconOutlined
                                                        />

                                                        <MenuItem
                                                            onClick={handleOpenNav}
                                                            path="/transactions"
                                                            icon="list_alt"
                                                            text="Transactions"
                                                            isIconOutlined
                                                        />

                                                        <MenuItemWithChildren
                                                            onClick={handleOpenNav}
                                                            path="/payments"
                                                            icon="account_balance_wallet"
                                                            text="Payments"
                                                            isIconOutlined
                                                            subItems={[
                                                                {
                                                                    path: "/payments/make",
                                                                    text: "Send Money",
                                                                    onClick: () => {
                                                                        dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
                                                                        dispatch(setPaymentStage(PaymentStageType.INITIAL));
                                                                    },
                                                                },
                                                                {
                                                                    path: "/payments/bill/make",
                                                                    text: "Bill Payments",
                                                                    onClick: () => {
                                                                        dispatch(setBillPaymentType(BillPaymentType.AIRTIME_PAYMENT));
                                                                        dispatch(setBillPaymentStage(BillPaymentStage.INITIAL));
                                                                    },
                                                                    hide: !canApprove || !isNigeria,
                                                                },
                                                                {
                                                                    path: "/payments/add-money/make",
                                                                    optionalPath: "/payments/add-money/history",
                                                                    text: "Add Money",
                                                                    hide: !!isNigeria || !canApprove,
                                                                },
                                                                { path: "/payments/pending", text: "Pending Payments" },
                                                                { path: "/payments/recipients", text: "Recipients" },
                                                            ]}
                                                        />

                                                        <MenuItem
                                                            onClick={handleOpenNav}
                                                            path="/accounts"
                                                            icon="business_center"
                                                            text="Accounts"
                                                            isIconOutlined
                                                        />
                                                        {isNigeria && canAccessCards && (
                                                            <MenuItem onClick={handleOpenNav} path="/cards" icon="credit_card" text="Cards" />
                                                        )}
                                                        {isNigeria && canAccessTerminals && (
                                                            <MenuItem
                                                                onClick={handleOpenNav}
                                                                path="/terminals"
                                                                icon="point_of_sale"
                                                                text="Terminals"
                                                            />
                                                        )}
                                                        {isZambia && hasViewLencoPayAccess && !isLencoPayEnabled && (
                                                            <MenuItem
                                                                onClick={handleOpenNav}
                                                                path="/lenco-pay/get-started"
                                                                icon="integration_instructions"
                                                                text="Lenco Pay"
                                                            />
                                                        )}
                                                        {isZambia && hasViewLencoPayAccess && isLencoPayEnabled && (
                                                            <MenuItemWithChildren
                                                                onClick={handleOpenNav}
                                                                path="/lenco-pay"
                                                                icon="integration_instructions"
                                                                text="Lenco Pay"
                                                                subItems={[
                                                                    {
                                                                        path: "/lenco-pay/collections/transactions",
                                                                        optionalPath: "/lenco-pay/collections",
                                                                        text: "Collections",
                                                                    },
                                                                    { path: "/lenco-pay/payouts", text: "Payouts" },
                                                                    { path: "/lenco-pay/api-access", text: "APIs" },
                                                                    { path: "/lenco-pay/configuration", text: "Configuration" },
                                                                ]}
                                                            />
                                                        )}

                                                        <MenuItemWithChildren
                                                            onClick={handleOpenNav}
                                                            path="/settings"
                                                            icon="settings"
                                                            text="Settings"
                                                            isIconOutlined
                                                            subItems={[
                                                                { path: "/settings/account", text: "Account Settings" },
                                                                { path: "/settings/team-members", text: "Team Members", hide: !isAdmin },
                                                                { path: "/settings/security", text: "Security" },
                                                            ]}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex w-full flex-col space-y-2 border-t border-grey-secondary px-4 pt-8">
                                            <ButtonComp
                                                type="button"
                                                buttonType="tertiary"
                                                color="transparent"
                                                data-type="section"
                                                onClick={handleShowIntercom}
                                                fullWidth
                                            >
                                                <div
                                                    className="relative flex h-10 w-full items-center justify-start rounded-md px-4 hover:bg-grey-tertiary"
                                                    data-type="section"
                                                    tabIndex={-1}
                                                >
                                                    <GoogleIcon icon="headset_mic" dataType="section" isOutlined />
                                                    <span
                                                        className="ml-2 truncate text-base text-black-secondary duration-150 hover:text-black"
                                                        data-type="section"
                                                    >
                                                        Help
                                                    </span>
                                                </div>
                                            </ButtonComp>

                                            <ButtonComp
                                                type="button"
                                                buttonType="tertiary"
                                                color="transparent"
                                                data-type="section"
                                                onClick={() => logoutMutate()}
                                                noTabIndex
                                                fullWidth
                                            >
                                                <div
                                                    className="relative flex h-10 w-full items-center justify-start rounded-md px-4 hover:bg-grey-tertiary"
                                                    data-type="section"
                                                    tabIndex={-1}
                                                >
                                                    <GoogleIcon icon="logout" dataType="section" isOutlined />
                                                    <span
                                                        className="ml-2 truncate text-base text-black-secondary duration-150 hover:text-black"
                                                        data-type="section"
                                                    >
                                                        Logout
                                                    </span>
                                                </div>
                                            </ButtonComp>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <section className="w-full">
                        <div className="flex h-screen max-h-screen w-full flex-col items-start justify-start 4xs:h-full">
                            <header className="flex h-16 w-full flex-row items-center justify-center border-b-0.2 border-grey py-1" tabIndex={-1}>
                                <div className="mx-auto flex w-full flex-row items-center justify-between px-4 lg:px-8">
                                    <div className="flex w-full justify-between space-x-6 pb-2 pt-2 sm:space-x-12">
                                        <div className="flex w-max flex-col items-center justify-center lg:w-full">
                                            <div className="flex h-full items-center justify-center space-x-2 lg:hidden" tabIndex={-1}>
                                                <ButtonComp color="black" buttonType="tertiary" onClick={handleOpenNav}>
                                                    <GoogleIcon icon="menu" isOutlined />
                                                </ButtonComp>
                                                <div
                                                    className="headway-badge-hamburger pointer-events-none -mt-3 flex lg:hidden"
                                                    id="headway-badge-hamburger"
                                                />
                                            </div>

                                            {isAccountCreated && <DashboardSearchBar />}
                                        </div>

                                        <div className="flex w-max flex-row items-center justify-center space-x-5" tabIndex={-1}>
                                            {isAccountCreated && (
                                                <>
                                                    <SendMoneyDropdown />
                                                    <div className="hidden h-full w-[1px] bg-grey xs:block"></div>
                                                </>
                                            )}
                                            <div className="headway-trigger" tabIndex={0}>
                                                <div
                                                    className="headway-badge relative [&>span]:!absolute [&>span]:!-right-1.5 [&>span]:!-top-1.5"
                                                    id="headway-badge"
                                                >
                                                    <div className="flex h-10 w-10 items-center justify-center rounded-lg border border-grey">
                                                        <ButtonComp buttonType="tertiary" color="black">
                                                            <GoogleIcon icon="notifications" className="text-black" />
                                                        </ButtonComp>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="hidden w-max xs:block">
                                                <AccountDropdown />
                                            </div>

                                            <div className="flex h-full w-6 items-center justify-center">
                                                {isNigeria && !isZambia && (
                                                    <img
                                                        className="rounded-full border border-black-quat"
                                                        src={NigeriaFlag}
                                                        alt="Nigerian flag"
                                                        width="24px"
                                                        height="24px"
                                                    />
                                                )}
                                                {isZambia && !isNigeria && (
                                                    <img
                                                        className="rounded-full border border-black-quat"
                                                        src={ZambiaFlag}
                                                        alt="Zambian flag"
                                                        width="24px"
                                                        height="24px"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>

                            <main className="relative flex w-full flex-shrink flex-grow basis-auto flex-col items-center justify-start overflow-y-auto bg-grey-backdrop px-4 -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available lg:px-8">
                                {props.isLoading ? (
                                    <div className="flex h-full items-center justify-center">
                                        <LencoSpinner size="lg" />
                                    </div>
                                ) : (
                                    <Outlet />
                                )}
                            </main>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
