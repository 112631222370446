import BillVendor from "../../../../../../../models/billVendor";
import BillVendorMin from "../../../../../../../models/billVendorMin";
import { setElectricityPurchaseVendorId } from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseElectricityPurchaseVendorInterface {
    handleSelectVendor: (_vendor: BillVendor | BillVendorMin) => void;
}

function useElectricityPurchaseVendor(): UseElectricityPurchaseVendorInterface {
    const dispatch = useDispatch();

    const handleSelectVendor = useCallback((_vendor: BillVendor | BillVendorMin) => {
        dispatch(setElectricityPurchaseVendorId(_vendor));
    }, []);

    return { handleSelectVendor };
}

export default useElectricityPurchaseVendor;
