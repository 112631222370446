import {
    AccountSignatoriesRequests,
    abortAccountSignatoriesRequest,
    accountSignatories,
} from "../../../../../services/zambia-application/registered/new/accountSignatories.api";
import {
    CurrentSignatoryRequests,
    abortCurrentSignatoryRequest,
    currentSignatory,
} from "../../../../../services/zambia-application/registered/new/currentSignatory.api";
import {
    resetZambiaAccountSignatories,
    resetZambiaCurrentSignatory,
    setZambiaAccountSignatories,
    updateZambiaCurrentSignatory,
} from "../../../../../../../../redux/application/zambiaApplicationSlice";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface useAccountSignatoriesInitInterface {
    currentSignatoryError: string | null;
    isCurrentSignatoryLoading: boolean;
    accountSignatoriesError: string | null;
    isAccountSignatoriesLoading: boolean;
    handleReset: () => void;
    handleCurrentSignatoryInit: () => Promise<void>;
    handleAccountSignatoriesInit: () => Promise<void>;
}

const useAccountSignatoriesInit = (): useAccountSignatoriesInitInterface => {
    const dispatch = useDispatch();

    const [currentSignatoryError, setCurrentSignatoryError] = useState<string | null>(null);
    const [isCurrentSignatoryLoading, setIsCurrentSignatoryLoading] = useState<boolean>(false);

    const [accountSignatoriesError, setZambiaAccountSignatoriesError] = useState<string | null>(null);
    const [isAccountSignatoriesLoading, setIsAccountSignatoriesLoading] = useState<boolean>(false);

    const handleCurrentSignatoryInit = useCallback(async (): Promise<void> => {
        try {
            setCurrentSignatoryError(null);
            setIsCurrentSignatoryLoading(true);
            const res = await currentSignatory();
            if (res.signatory) {
                dispatch(updateZambiaCurrentSignatory(res));
            }
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            setCurrentSignatoryError(errorMessage);
            dispatch(errorTrue(errorMessage));
        } finally {
            setIsCurrentSignatoryLoading(false);
        }
    }, [dispatch]);

    const handleAccountSignatoriesInit = useCallback(async (): Promise<void> => {
        try {
            setZambiaAccountSignatoriesError(null);
            setIsAccountSignatoriesLoading(true);
            const res = await accountSignatories();
            dispatch(setZambiaAccountSignatories(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            setZambiaAccountSignatoriesError(errorMessage);
            dispatch(errorTrue(errorMessage));
        } finally {
            setIsAccountSignatoriesLoading(false);
        }
    }, [dispatch]);

    const handleReset = () => {
        setCurrentSignatoryError(null);
        setIsCurrentSignatoryLoading(false);
        setZambiaAccountSignatoriesError(null);
        setIsAccountSignatoriesLoading(false);
        dispatch(resetZambiaCurrentSignatory());
        dispatch(resetZambiaAccountSignatories());
        abortCurrentSignatoryRequest(CurrentSignatoryRequests.INIT);
        abortAccountSignatoriesRequest(AccountSignatoriesRequests.INIT);
    };

    return {
        currentSignatoryError,
        isCurrentSignatoryLoading,
        accountSignatoriesError,
        isAccountSignatoriesLoading,
        handleReset,
        handleCurrentSignatoryInit,
        handleAccountSignatoriesInit,
    };
};
export default useAccountSignatoriesInit;
