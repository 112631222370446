import { BillPaymentStage, BillPaymentType } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import { setBillPaymentStage, setBillPaymentType } from "../../../../../redux/payments/billPayment/slice/billPaymentSlice";

import Airtime from "../../../../../assets/images/dashboard/send-money/bill-payment/airtime.png";
import { BillPurchaseCategoriesType } from "../../../../../models/billPurchase.constant";
import CableTV from "../../../../../assets/images/dashboard/send-money/bill-payment/cable-tv.png";
import Data from "../../../../../assets/images/dashboard/send-money/bill-payment/data.png";
import Electricity from "../../../../../assets/images/dashboard/send-money/bill-payment/electricity.png";
import SectionCard from "../Cards/SectionCard";
import useBillPayment from "../../hooks/state/BillPayment/useBillPayment";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

function TypeOfBill(): JSX.Element {
    const dispatch = useDispatch();
    // const main = useSelector((state: IRootState) => state.init.main);
    const { handleBillPaymentVendors, handleBillPaymentRecent } = useBillPayment();

    const handleSelectAirtimePurchase = useCallback(() => {
        void handleBillPaymentVendors({
            categories: [`${BillPurchaseCategoriesType.AIRTIME}`],
        });
        void handleBillPaymentRecent({
            category: `${BillPurchaseCategoriesType.AIRTIME}`,
        });
        dispatch(setBillPaymentType(BillPaymentType.AIRTIME_PAYMENT));
        dispatch(setBillPaymentStage(BillPaymentStage.PAYMENT_DETAILS));
    }, []);

    const handleSelectDataPayment = useCallback(() => {
        void handleBillPaymentVendors({
            categories: [`${BillPurchaseCategoriesType.MOBILE_DATA}`],
        });
        void handleBillPaymentRecent({
            category: `${BillPurchaseCategoriesType.MOBILE_DATA}`,
        });
        dispatch(setBillPaymentType(BillPaymentType.DATA_PAYMENT));
        dispatch(setBillPaymentStage(BillPaymentStage.PAYMENT_DETAILS));
    }, []);

    const handleSelectCableTVPayment = useCallback(() => {
        void handleBillPaymentVendors({
            categories: [`${BillPurchaseCategoriesType.CABLE_TV}`],
        });
        void handleBillPaymentRecent({
            category: `${BillPurchaseCategoriesType.CABLE_TV}`,
        });
        dispatch(setBillPaymentType(BillPaymentType.CABLE_TV_PAYMENT));
        dispatch(setBillPaymentStage(BillPaymentStage.PAYMENT_DETAILS));
    }, []);

    const handleSelectElectricityPayment = useCallback(() => {
        void handleBillPaymentVendors({
            categories: [`${BillPurchaseCategoriesType.ELECTRICITY}`],
        });
        void handleBillPaymentRecent({
            category: `${BillPurchaseCategoriesType.ELECTRICITY}`,
        });
        dispatch(setBillPaymentType(BillPaymentType.ELECTRICITY_PAYMENT));
        dispatch(setBillPaymentStage(BillPaymentStage.PAYMENT_DETAILS));
    }, []);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-8">
                <div className="flex w-full flex-col items-center justify-center space-y-2">
                    <span className="text-xl font-medium text-black md:text-2xl">Choose a type of bill</span>
                    <span className="text-sm text-black-secondary md:text-base">Select a bill type</span>
                </div>
                <div className="flex w-full flex-col justify-center space-y-6 py-2">
                    <SectionCard
                        text="Airtime"
                        subtext="Purchase airtime with your Lenco account"
                        icon={Airtime}
                        func={handleSelectAirtimePurchase}
                    />
                    <SectionCard text="Data" subtext="Pay for internet subscription" icon={Data} func={handleSelectDataPayment} />
                    <SectionCard text="Cable TV" subtext="Pay for cable subscription" icon={CableTV} func={handleSelectCableTVPayment} />
                    <SectionCard
                        text="Electricity"
                        subtext="Buy prepaid and postpaid electricity token"
                        icon={Electricity}
                        func={handleSelectElectricityPayment}
                    />
                </div>
            </div>
        </>
    );
}

export default TypeOfBill;
