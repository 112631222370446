import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BillProduct from "../../../../../../../models/billProduct";
import { IRootState } from "../../../../../../../redux/rootReducer";
import { setCableTVPurchaseProductId } from "../../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";

interface UseCableTVPurchaseProductIdInterface {
    listOfProducts: BillProduct[] | null;
    handleSelectProduct: (_vendor: BillProduct) => void;
}

function useCableTVPurchaseProduct(): UseCableTVPurchaseProductIdInterface {
    const dispatch = useDispatch();
    const vendorsList = useSelector((state: IRootState) => state.billPayment.vendorsList);
    const selectedVendorId = useSelector((state: IRootState) => state.cableTVPurchase.selectedVendorId);
    const [listOfProducts, setListOfProducts] = useState<BillProduct[] | null>(null);

    useEffect(() => {
        if (!vendorsList || !selectedVendorId) return;

        const doesVendorExist = vendorsList.some((_vendor) => _vendor.id === selectedVendorId);

        if (!doesVendorExist) return;

        setListOfProducts(vendorsList.find((_vendor) => _vendor.id === selectedVendorId)?.products as BillProduct[]);
    }, [vendorsList, selectedVendorId]);

    const handleSelectProduct = useCallback((_vendor: BillProduct) => {
        dispatch(setCableTVPurchaseProductId(_vendor));
    }, []);

    return { listOfProducts, handleSelectProduct };
}

export default useCableTVPurchaseProduct;
