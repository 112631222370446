import React, { useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import SetupTokenModal from "./setup/SetupTokenModal";
import useUnlinkTokenApp from "../../../Services/security/token-app/hooks/useUnlinkTokenApp";
import SetupTokenTray from "./setup/SetupTokenTray";

function TokenAppSettings(): JSX.Element {
    const { isLinked, deviceName, isUnlinking, handleUnlinkTokenApp } = useUnlinkTokenApp();
    const [showModal, setShowModal] = useState<boolean>(false);

    const [isSetupTokenTray, setIsSetupTokenTray] = useState(false);

    const handleToggleTray = (isOpen: boolean) => {
        setIsSetupTokenTray(isOpen);
        // setShowModal(true);
    };

    return (
        <>
            <SetupTokenModal active={showModal} closeFunc={() => setShowModal(false)} />

            <SetupTokenTray active={isSetupTokenTray} handleToggleTray={() => setIsSetupTokenTray(false)} />

            <div className="flex w-full flex-col items-start justify-between space-y-3 rounded-custom bg-white p-5 lg:flex-row lg:items-center lg:space-y-0">
                <div className="flex w-max flex-col items-start justify-start">
                    <span className="text-base font-medium text-black">Lenco Token App</span>
                    <span className="text-sm font-normal text-black-tertiary">
                        {isLinked && deviceName && `Your Account is linked to ${deviceName}`}
                        {isLinked && !deviceName && "our Account is linked"}
                        {!isLinked && "Setup the Lenco Token App"}
                    </span>
                </div>

                {!isLinked && (
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="black" size="md" func={() => handleToggleTray(true)}>
                        <span>Setup Token App</span>
                    </ButtonComp>
                )}

                {isLinked && (
                    <ButtonComp
                        type="button"
                        ripple="light"
                        buttonType="secondary"
                        color="blue"
                        size="md"
                        func={() => void handleUnlinkTokenApp()}
                        isLoading={isUnlinking}
                    >
                        <span>Unlink Token App</span>
                    </ButtonComp>
                )}
            </div>
        </>
    );
}

export default TokenAppSettings;
