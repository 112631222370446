import { FundCardResponse, FundCardState } from "./fundCardSlice.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import Card from "../../../../models/card";
import UserAccount from "../../../../models/userAccount";

const initialState: FundCardState = {
    fundCardDetails: {
        originatingAccountId: "",
        recipientCardId: "",
        amount: "",
        purpose: "",
        otp: "",
    },
    fundCardResponse: null,
    selectedUserAccount: null,
    recipientCardDetails: null,
    canVerifyRecipientAccountDetails: true,
};

export const fundCardSlice = createSlice({
    name: "fundCard",
    initialState,
    reducers: {
        setFundCardOriginatingAccount: (state: FundCardState, action: PayloadAction<string>) => {
            state.fundCardDetails.originatingAccountId = action.payload;
        },
        setFundCardAmount: (state: FundCardState, action: PayloadAction<string>) => {
            state.fundCardDetails.amount = action.payload;
        },
        setFundCardPurpose: (state: FundCardState, action: PayloadAction<string>) => {
            state.fundCardDetails.purpose = action.payload;
        },
        setFundCardOtp: (state: FundCardState, action: PayloadAction<string>) => {
            state.fundCardDetails.otp = action.payload;
        },
        setFundCardRecipient: (state: FundCardState, action: PayloadAction<Card>) => {
            state.fundCardDetails.recipientCardId = action.payload.id;
            state.recipientCardDetails = action.payload;
        },
        setSelectedUserAccount: (state: FundCardState, action: PayloadAction<UserAccount>) => {
            state.selectedUserAccount = action.payload;
        },
        setFundCardResponse: (state: FundCardState, action: PayloadAction<FundCardResponse>) => {
            state.fundCardResponse = action.payload;
        },
        resetFundCardRecipient: (state: FundCardState) => {
            state.fundCardDetails.recipientCardId = "";
            state.recipientCardDetails = null;
        },
        resetAllFundCardData: (state: FundCardState) => {
            state.fundCardResponse = null;
            state.selectedUserAccount = null;
            state.recipientCardDetails = null;
            state.canVerifyRecipientAccountDetails = true;
            state.fundCardDetails = {
                originatingAccountId: "",
                recipientCardId: "",
                amount: "",
                purpose: "",
                otp: "",
            };
        },
    },
});

export const {
    setFundCardOtp,
    setFundCardAmount,
    setFundCardPurpose,
    setFundCardResponse,
    resetAllFundCardData,
    setFundCardRecipient,
    setSelectedUserAccount,
    resetFundCardRecipient,
    setFundCardOriginatingAccount,
} = fundCardSlice.actions;

export default fundCardSlice.reducer;
