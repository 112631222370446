import { LoginRequest, LoginResponse } from "../services/login-api.types";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { login } from "../services/login-api";
import { loginSuccess } from "../../../../redux/init/slice/initSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    onComplete: (_isAuthAppSetup: boolean) => void;
}

function useLogin(props: Props): UseMutationResult<LoginResponse, AxiosError | null, LoginRequest, unknown> {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const setPasswordMutation = useMutation({
        mutationFn: async (_data: LoginRequest) => await login(_data),
        onSuccess(data) {
            if (data.otp) {
                props.onComplete(data.isAuthAppSetup);
            }
            if (!data.otp) {
                dispatch(loginSuccess());
                navigate("/");
            }
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return setPasswordMutation;
}

export default useLogin;
