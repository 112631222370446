import React from "react";
import TerminalRequest from "../../../../../models/terminalRequest";
import { TerminalRequestStatus } from "../../../../../models/terminal.constants";

interface Props {
    request: TerminalRequest | null;
}

export default function TerminalRequestStatusTag({ request }: Props) {
    return (
        <div>
            {request?.status === TerminalRequestStatus.APPROVED && (
                <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                    {request.getStatusText}
                </span>
            )}
            {request?.status === TerminalRequestStatus.DECLINED && (
                <span className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error">
                    {request.getStatusText}{" "}
                </span>
            )}
            {request?.status === TerminalRequestStatus.DELIVERED && (
                <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                    {request.getStatusText}
                </span>
            )}
            {request?.status === TerminalRequestStatus.REQUESTED && (
                <span className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning">
                    {request.getStatusText}
                </span>
            )}
            {request?.status === TerminalRequestStatus.SHIPPED && (
                <span className="h-6 rounded border border-info-quin bg-info-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-info">
                    {request.getStatusText}
                </span>
            )}
            {request?.status === TerminalRequestStatus.TERMINAL_ASSIGNED && (
                <span className="h-6 rounded border border-info-quin bg-info-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-info">
                    {request.getStatusText}
                </span>
            )}
        </div>
    );
}
