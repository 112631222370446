import { HintType, ListStyle } from "../../../../../../element/hint/hint.constant";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import CaptureModal from "../../../../../../modals/capture-modal";
import { CompanyHint } from "../../../../../../../services/zambia-application/registered/new/company.types";
import Hint from "../../../../../../element/hint";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import PhotographCard from "../../../../../../cards/PhotographCard";
import PreviewModal from "../../../../../../modals/preview-modal";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import usePhotoUploadComponent from "../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/usePhotoUploadComponent";
import usePreview from "../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/usePreview";
import { useSelector } from "react-redux";
import { useState } from "react";

// const PhotographGuides: CompanyHint[] = [
// {
// index: 0,
// span: (
// <span>
// Use <span className="font-medium">Get Link</span> to share and capture photo on your preferred device.
// </span>
// ),
// },
// ];

const PhotographTips: CompanyHint[] = [
    {
        index: 0,
        span: <span>Use natural daylight and avoid flash.</span>,
    },
    {
        index: 1,
        span: <span>The photo should be well-lit.</span>,
    },
    {
        index: 2,
        span: <span>All facial features should be captured.</span>,
    },
    {
        index: 3,
        span: <span>No glasses.</span>,
    },
    {
        index: 4,
        span: <span>Fully clothed body.</span>,
    },
    {
        index: 5,
        span: <span>Grant access to your device camera.</span>,
    },
];
type LocationState = {
    from?: string;
};

function Photograph(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isUploadPhotoLoading, handleUploadPhoto } = usePhotoUploadComponent(() => {
        setIsUploaded(true);
        setShowUploadModal(false);
    });

    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
    });

    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const currentSignatoryPhotoId = useSelector((state: IRootState) => state.zambiaApplication.currentSignatory?.passportPhotoUploadId);

    const isRegistered = accountOpening?.application.isRegistered;

    const [isUploaded, setIsUploaded] = useState<boolean>(!!currentSignatoryPhotoId);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    const handleBack = () => {
        navigate(
            locationState?.from ||
                (isRegistered ? Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID : Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY.ID)
        );
    };

    return (
        <>
            <div className="w-full">
                <CaptureModal
                    active={showUploadModal}
                    toggler={() => setShowUploadModal(false)}
                    handleUploadPhoto={(file) => void handleUploadPhoto(file)}
                    isUploadPhotoLoading={isUploadPhotoLoading}
                />
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Photo Preview"
                    handleToggleShowUploadModal={() => {
                        setShowUploadModal(true);
                        setShowPreviewModal(false);
                    }}
                />
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    {accountOpening && (
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Photograph"
                                subTitle="Provide us with a live image of yourself"
                                progress={isUploaded ? 100 : 0}
                            >
                                <div className="w-full">
                                    <div className="flex w-full flex-col items-start justify-start space-y-4">
                                        <div className="flex w-full flex-col items-center space-x-0 space-y-4 lg:space-x-10 lg:space-y-0">
                                            <PhotographCard
                                                isUploaded={isUploaded}
                                                handleReUpload={() => setIsUploaded(false)}
                                                handleLaunchCamera={() => {
                                                    setShowUploadModal(true);
                                                    setShowPreviewModal(false);
                                                }}
                                                handlePreviewDocumentFunc={() =>
                                                    currentSignatoryPhotoId && void handlePreviewDocument(currentSignatoryPhotoId)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp
                                        type="button"
                                        color="grey"
                                        ripple="light"
                                        buttonType="secondary"
                                        func={currentSignatoryPhotoId && !isUploaded ? () => setIsUploaded(true) : handleBack}
                                        fullWidth
                                    >
                                        <span>
                                            {currentSignatoryPhotoId && !isUploaded
                                                ? "Cancel"
                                                : locationState?.from === Routes.ACCOUNT_OPENING.REVIEW
                                                  ? "Back to Review"
                                                  : "Back"}
                                        </span>
                                    </ButtonComp>
                                </div>
                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                    <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                        <ButtonComp
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            disable={!isUploaded}
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname:
                                                            accountOpening?.isCreator && isRegistered
                                                                ? Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT
                                                                : Routes.ACCOUNT_OPENING.REVIEW,
                                                    },
                                                    {
                                                        state: {
                                                            from: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH,
                                                        },
                                                    }
                                                )
                                            }
                                            fullWidth
                                        >
                                            <span>
                                                {isRegistered
                                                    ? accountOpening?.isCreator
                                                        ? "Save and Continue"
                                                        : "Review Application"
                                                    : "Review Application"}
                                            </span>
                                        </ButtonComp>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <Hint hintType={HintType.TIP} listStyle={ListStyle.BULLET} firstList={PhotographTips} />
                </div>
            </div>
        </>
    );
}

export default Photograph;
