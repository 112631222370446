import { Recipient, SavedRecipientsUrlParams } from "./use-saved-recipients-state.types";
import { setRecipients, updatePagination } from "../../../../../../redux/payments/recipients/slice/recipientsSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../redux/rootReducer";
import { ListRecipientsRequest } from "../../../services/send-money/singleTransferApi.types";
import { MainInitState } from "../../../../../../redux/init/slice/initSlice.types";
import { PageTitle } from "../../../../../../helpers/AppConstants";
import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import UserGroup from "../../../../../../models/userGroup";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { listRecipients } from "../../../services/savedRecipients/savedRecipients.api";
import { setMultipleCustomerAccount } from "../../../../../../redux/customer-account/customerAccountSlice";
import useDebounce from "../../../../../../hooks/useDebounce";
import { useParams } from "react-router-dom";

export interface UseSavedRecipientsStateInterface {
    query: string;
    offset: number;
    recipients: Map<string, Recipient>;
    paginationData: { total: number; groupSize: number };
    isLoading: boolean;
    handleSetQuery: (_query: string) => void;
    handleSelectUserGroup: (_userGroup: UserGroup | null) => void;
    handleSetOffset: (_offset: number) => void;
}

[].slice();

export function useSavedRecipientsState(): UseSavedRecipientsStateInterface {
    const dispatch = useDispatch();
    const { query: queryFromUrl } = useParams<SavedRecipientsUrlParams>();

    document.title = PageTitle.SAVED_RECIPIENTS_PAGE;

    const main = useSelector((state: IRootState) => state.init.main);
    const recipients = useSelector((state: IRootState) => state.recipients.recipients);
    const pagination = useSelector((state: IRootState) => state.recipients.pagination);

    // const [sort, setSort] = useState<PaymentRecipientSort>(PaymentRecipientSort.DEFAULT);
    const [query, setQuery] = useState<string>(queryFromUrl ?? "");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [recipients, setRecipients] = useState<Recipient[]>([]);
    // const [paginationData, setPaginationData] = useState({total: 0, groupSize: 0});
    const [offset, setOffset] = useState(0);
    const [userGroup, setUserGroup] = useState<UserGroup | null>(null);

    const handleGetRecipients = useCallback(
        async (_data: ListRecipientsRequest) => {
            try {
                setIsLoading(true);
                const res = await listRecipients(_data);
                /*setRecipients(
					res.customerAccounts.map((customerAccount) => ({
						customerAccount: customerAccount,
						bankName: getBankName(customerAccount.bankCode),
					}))
				);
				setPaginationData({groupSize: res.groupSize, total: res.total});*/
                dispatch(setRecipients(res.recipients));
                dispatch(updatePagination({ total: res.total, groupSize: res.groupSize }));
                dispatch(setMultipleCustomerAccount(res.recipients.map((r) => r.customerAccount)));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            }
            setIsLoading(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    const checkAndSearch = useCallback((_main: MainInitState | null, _query: string, _offset: number, _userGroup: UserGroup | null) => {
        if (_main) {
            void handleGetRecipients({
                query: _query,
                offset: _offset,
                userGroupId: _userGroup?.id ?? undefined,
            });
        }
    }, []);

    const debounceCheckAndSearch = useCallback(useDebounce(checkAndSearch, 400), []);

    useEffect(() => {
        // check and search immediately
        checkAndSearch(main, query, offset, userGroup);
    }, [offset, userGroup]);

    useEffect(() => {
        // delay (use the debounce) before searching
        debounceCheckAndSearch(main, query, offset, userGroup);
    }, [query]);

    return {
        query,
        offset,
        recipients,
        paginationData: pagination,
        isLoading,
        handleSetQuery: setQuery,
        handleSelectUserGroup: setUserGroup,
        handleSetOffset: setOffset,
    };
}
