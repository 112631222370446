import { useCallback, useState } from "react";

import { BillPurchaseInitRequest } from "../../../../services/billPayment/billPaymentApi.types";
import { billPurchaseInit } from "../../../../services/billPayment/billPaymentApi";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { setCableTVPurchaseResponse } from "../../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";
import { useDispatch } from "react-redux";

interface UseCableTVPurchaseInterface {
    isCableTVPurchaseLoading: boolean;
    cableTVPurchaseError: boolean;
    handleCableTVPurchase: (_data: BillPurchaseInitRequest) => Promise<void>;
}

function useCableTVPurchase(): UseCableTVPurchaseInterface {
    const dispatch = useDispatch();
    const [isCableTVPurchaseLoading, setIsCableTVPurchaseLoading] = useState(false);
    const [cableTVPurchaseError, setCableTVPurchaseError] = useState<boolean>(false);

    const handleCableTVPurchase = useCallback(
        async (_data: BillPurchaseInitRequest) => {
            try {
                setIsCableTVPurchaseLoading(true);
                setCableTVPurchaseError(false);
                const res = await billPurchaseInit(_data);
                dispatch(setCableTVPurchaseResponse(res));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setCableTVPurchaseError(true);
            } finally {
                setIsCableTVPurchaseLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isCableTVPurchaseLoading,
        cableTVPurchaseError,
        handleCableTVPurchase,
    };
}

export default useCableTVPurchase;
