import GoogleIcon from "../../../../../components/google-icon";
import React from "react";
// import { ReactComponent as ArrowDownIcon } from "../../../../../assets/svg/General/arrowDownIcon.svg";
import { canShowPreReleaseFeatures } from "../../../../../utils/preReleaseConfig";

interface SectionCardInterface {
    text: string;
    icon: React.ReactNode | string;
    subtext?: string;
    isPreRelease?: boolean;

    func: () => void;
}

function SectionCard({ text, icon, subtext, isPreRelease = false, func }: SectionCardInterface): JSX.Element {
    if (isPreRelease && !canShowPreReleaseFeatures) {
        return <></>;
    }

    return (
        <div className="flex w-full cursor-pointer flex-row items-center justify-between rounded-lg border border-grey bg-white p-4" onClick={func}>
            <div className="flex w-full items-center justify-start space-x-4">
                <div>
                    {/* <div className="flex h-10 w-10 min-w-[40px] items-center justify-center rounded-full">{icon}</div> */}
                    <img className="h-10 w-10 min-w-[40px]" src={icon as string} alt={text} />
                </div>
                <div className="flex flex-col justify-start">
                    <p className="text-base font-medium text-black">{text}</p>
                    <p className="text-sm text-black-tertiary">{subtext}</p>
                </div>
            </div>

            <div className="flex h-6 w-6 items-center justify-center rounded-[4px] bg-grey-secondary">
                <GoogleIcon icon="chevron_right" className="text-black-tertiary" />
            </div>
        </div>
    );
}

export default SectionCard;
