import * as Yup from "yup";

import { Form, Formik } from "formik";

import ButtonComp from "../../../../../components/button/ButtonComp";
import CustomSelect from "../../../../../components/dropdown/custom-select";
import FormInput from "../../../../../components/inputs/FormInput";
import LoginFooter from "../../../../../components/layouts/components/landing/login-footer";
import { WaitListCountryOptions } from "../../../../../components/layouts/services/country.constant";
import { WaitListForm } from "../../services/WaitList.constant";
import formikHasError from "../../../../../helpers/formikHasError";

interface Props {
    onComplete: () => void;
}

function JoinWaitList(props: Props): JSX.Element {
    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().email().required("Required"),
        country: Yup.string().required("Required"),
        // country: Yup.mixed<Countries>().oneOf(Object.values(Countries)).nonNullable().required("Required"),
    });

    const INITIAL_FORM_STATE: WaitListForm = {
        email: "",
        country: null,
    };

    return (
        <>
            <div className="relative flex w-full max-w-26 flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex w-full flex-col items-center justify-start space-y-4">
                    <h3 className="break-words text-center text-2xl font-medium text-black">Join our Waitlist</h3>
                    <div className="break-words text-center text-sm font-normal text-black-tertiary">
                        You will be the first to know when we launch in your country
                    </div>
                </div>

                <Formik
                    initialValues={{
                        ...INITIAL_FORM_STATE,
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={() => {
                        props.onComplete();
                    }}
                    enableReinitialize
                    validateOnChange
                    validateOnMount
                    validateOnBlur
                >
                    {(formik) => {
                        // console.log(formik);
                        return (
                            <Form className="flex w-full flex-col items-center justify-start space-y-8">
                                <div className="flex w-full flex-col items-center justify-start space-y-4">
                                    <FormInput label="Email" name="email" />
                                    <CustomSelect
                                        size="xl"
                                        value={formik.values.country}
                                        label="Select Country"
                                        options={WaitListCountryOptions}
                                        onSelect={(_value) => {
                                            if (_value) {
                                                void formik.getFieldHelpers("country").setValue(_value);
                                            }
                                        }}
                                        withIcon
                                    />
                                </div>
                                <ButtonComp type="submit" color="black" buttonType="primary" disable={formikHasError(formik.errors)} fullWidth isText>
                                    Join Waitlist
                                </ButtonComp>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <LoginFooter isLogin />
        </>
    );
}

export default JoinWaitList;
