import { ExternalApplicationRequests, abortExternalApplicationRequest, uploadExternalDirectorId } from "../service/externalApplication.api";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ExternalInitRequest } from "../service/externalApplication.types";
import { IRootState } from "../../../../../redux/rootReducer";
import { IdentificationRequest } from "../../../../dashboard/applicationn/services/nigeria-application/registered/currentSignatory.types";
// import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { updateExternalDirector } from "../../../../../redux/externalApplication/externalApplicationSlice";

export interface UseUploadExternalDirectorIdInterface {
    isExternalDirectorIdError: string | null;
    isExternalDirectorIdLoading: boolean | null;
    handleReset: () => void;
    handleUploadExternalDirectorId: (_init: ExternalInitRequest, _data: IdentificationRequest) => Promise<void>;
}

interface Props {
    onComplete: () => void;
}

const useUploadExternalDirectorId = (props: Props): UseUploadExternalDirectorIdInterface => {
    const dispatch = useDispatch();

    const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

    const [isExternalDirectorIdError, setIsExternalDirectorIdError] = useState<string | null>(null);
    const [isExternalDirectorIdLoading, setIsExternalDirectorIdLoading] = useState<boolean | null>(false);

    const handleUploadExternalDirectorId = useCallback(
        async (_init: ExternalInitRequest, _data: IdentificationRequest): Promise<void> => {
            try {
                setIsExternalDirectorIdLoading(true);
                const res = await uploadExternalDirectorId(_init, _data);
                dispatch(updateExternalDirector(res.director));
                dispatch(
                    messageTrue({
                        message: `${
                            externalApplicationInit?.options.idType?.find((_type) => _type.id === _data.typeId)?.name || ""
                        } uploaded successfully`,
                    })
                );
                props.onComplete && props.onComplete();
            } catch (err) {
                getErrorMessage(err);
                // dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsExternalDirectorIdLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsExternalDirectorIdError(null);
        setIsExternalDirectorIdLoading(false);
        abortExternalApplicationRequest(ExternalApplicationRequests.UPDATE_EXTERNAL_DIRECTOR_BIO);
    }, []);

    return {
        isExternalDirectorIdError,
        isExternalDirectorIdLoading,
        handleReset,
        handleUploadExternalDirectorId,
    };
};
export default useUploadExternalDirectorId;
