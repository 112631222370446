import { BillProductAmountType } from "./billProduct.constant";
import BillProductRangeAmount from "./BillProductRangeAmount";
import BillVendorMin from "./billVendorMin";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class BillProduct {
    [immerable] = true;

    constructor(
        public vendor: BillVendorMin | null,
        public id: string,
        public name: string | null,
        public amountType: BillProductAmountType,
        public fixedAmount: number | null,
        public rangeAmount: BillProductRangeAmount | null
    ) {}

    static create(obj: GenericObject): BillProduct {
        return new BillProduct(
            Parsers.classObject(obj.vendor, BillVendorMin),
            Parsers.string(obj.id),
            Parsers.nullableString(obj.name),
            Parsers.number(obj.amountType),
            Parsers.nullableNumber(obj.fixedAmount),
            Parsers.classObject(obj.rangeAmount, BillProductRangeAmount)
        );
    }
}
