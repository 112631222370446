import { BusinessRefereeRequests, abortBusinessRefereeRequest, removeReferee } from "../../../../services/nigeria-application/registered/referee.api";
import { useCallback, useState } from "react";

import { RemoveBusinessRefereeRequest } from "../../../../services/nigeria-application/registered/referee.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setReferees } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

export interface useInviteRefereesInterface {
    isRevokeRefereeInviteError: string;
    isRevokeRefereeInviteLoading: boolean;

    handleRest: () => void;
    handleRevokeRefereeInvite: (data: RemoveBusinessRefereeRequest) => Promise<void>;
}

const useRevokeRefereeInvite = (): useInviteRefereesInterface => {
    const dispatch = useDispatch();

    const [isRevokeRefereeInviteError, setIsRevokeRefereeInviteError] = useState<string>("");
    const [isRevokeRefereeInviteLoading, setIsRevokeRefereeInviteLoading] = useState<boolean>(false);

    const handleRevokeRefereeInvite = useCallback(
        async (data: RemoveBusinessRefereeRequest): Promise<void> => {
            try {
                setIsRevokeRefereeInviteError("");
                setIsRevokeRefereeInviteLoading(true);
                const res = await removeReferee(data);
                dispatch(setReferees(res));
                dispatch(messageTrue({ message: `Referee Invitation Revoked` }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue(errorMessage));
                setIsRevokeRefereeInviteError(errorMessage);
            } finally {
                setIsRevokeRefereeInviteLoading(false);
            }
        },
        [dispatch]
    );

    const handleRest = () => {
        setIsRevokeRefereeInviteError("");
        setIsRevokeRefereeInviteLoading(false);
        abortBusinessRefereeRequest(BusinessRefereeRequests.INVITE);
    };

    return {
        isRevokeRefereeInviteError,
        isRevokeRefereeInviteLoading,

        handleRest,
        handleRevokeRefereeInvite,
    };
};
export default useRevokeRefereeInvite;
