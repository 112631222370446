import ButtonComp from "../../../../../components/button/ButtonComp";
// import LencoSpinner from "../../../../../components/spinner";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import Money from "../../../../../components/money";
import Otp from "../../../../../components/otp";
import { OtpActivityType } from "../../../../../helpers/AppConstants";
import Transaction from "../../../../../models/transaction";
import useApprovePendingPayment from "../../hooks/state/pending-approval/useApprovePendingPayment";
import { useEffect } from "react";

interface PendingApprovalModalProps {
    data: Transaction;
    active: boolean;

    toggler: () => void;
    onCompleteFunc: () => void;
}
function PendingApprovalModal(props: PendingApprovalModalProps): JSX.Element {
    const { active, data, toggler, onCompleteFunc } = props;

    const {
        otp,
        isInitOTPLoading,
        approveTransactionError,
        approveTransactionResponse,
        isApproveTransactionLoading,

        handleOTPInit,
        handleOtpChange,
        handleResendOTPInit,
        handleApproveTransaction,
    } = useApprovePendingPayment({ active, onComplete: () => onCompleteFunc() });

    useEffect(() => {
        if (!data || !active) return;
        void handleOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION });
    }, [active, data, handleOTPInit]);

    useEffect(() => {
        if (!otp || otp.length < 6 || approveTransactionResponse) return;
        void handleApproveTransaction({
            transactionId: data.id,
            otp: otp,
        });
    }, [data, handleApproveTransaction, otp, approveTransactionResponse]);

    return (
        <>
            <Modal size="md" active={active} toggler={toggler}>
                <ModalHeader onClose={toggler}>
                    <h3>Approve Payment</h3>
                    <p className="pt-2 font-normal text-black-tertiary">
                        {isInitOTPLoading ? (
                            <div className="flex w-full flex-row items-start justify-start space-x-1 pb-4">
                                {/* <LencoSpinner size="sm" />  */}
                                <span className="text-left text-sm">Generating OTP...</span>
                            </div>
                        ) : (
                            <div className="flex w-full flex-col space-y-2">
                                <span className="text-left text-sm text-black-tertiary">
                                    Enter the OTP sent to your email address to approve the payment of <Money amount={data.amount} />
                                </span>
                            </div>
                        )}
                    </p>
                </ModalHeader>
                <ModalBody>
                    <Otp
                        value={otp || ""}
                        active={active}
                        isError={approveTransactionError}
                        onChange={handleOtpChange}
                        resendMins={3}
                        handleResend={() => handleResendOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION })}
                        isResendOtpLoading={isInitOTPLoading}
                        canResendOtp
                    />
                </ModalBody>
                <ModalFooter>
                    <ButtonComp buttonType="secondary" color="grey" func={toggler}>
                        <span>Back</span>
                    </ButtonComp>

                    <ButtonComp
                        color="black"
                        buttonType="primary"
                        isLoading={isApproveTransactionLoading}
                        disable={!isApproveTransactionLoading || !otp || otp.length < 6 || !!approveTransactionResponse}
                        onClick={() =>
                            otp &&
                            void handleApproveTransaction({
                                transactionId: data.id,
                                otp: otp,
                            })
                        }
                    >
                        <span>Authorize</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default PendingApprovalModal;
