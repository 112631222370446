import { AppToastItem, AppToastType } from "../../../../../redux/app-toast/app-toast-slice.types";
import { useEffect, useState } from "react";

import AppToast from "./component/app-toast";
import { ErrorMessage } from "../../../../../helpers/request/makeRequest";
import Parsers from "../../../../../utils/parsers";
import { resetAppToast } from "../../../../../redux/app-toast/app-toast-slice";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import useLogout from "../../../../init/hooks/use-logout";
import { useNavigate } from "react-router-dom";

function DashboardToast(): JSX.Element {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { mutate: mutateLogout } = useLogout();

    const appToastMessage = useAppSelector((state) => state.appToast.message);
    const globalActiveDataType = useAppSelector((state) => state.init.globalActiveDataType);

    const [toastList, setToastList] = useState<AppToastItem[]>([]);
    const [isNewLocation, setIsNewLocation] = useState(true);

    useEffect(() => {
        setToastList([]);
        dispatch(resetAppToast());
        setTimeout(() => {
            setIsNewLocation(false);
        }, 2000);
    }, [location.pathname]);

    useEffect(() => {
        if (isNewLocation) return;
        if (!appToastMessage) return;
        if (appToastMessage.type === AppToastType.ERROR) {
            const errorMessage = Parsers.nullableEnum(appToastMessage.message, ErrorMessage);
            if (errorMessage === ErrorMessage.UNAUTHORIZED_ERROR) {
                console.log("Unauthorized");
                mutateLogout();
                return;
            }
            if (errorMessage === ErrorMessage.ACCOUNT_CREATED_ERROR) {
                return navigate("/");
            }
            if (errorMessage === ErrorMessage.AXIOS_CANCEL_ERROR) {
                return;
            }
        }
        // console.log("this is the culprate", appToastMessage);
        setToastList((prev) => [...prev, appToastMessage]);
    }, [appToastMessage]);

    useEffect(() => {
        return () => {
            dispatch(resetAppToast());
        };
    }, []);

    // console.log(toastList, appToastMessage);
    return (
        <>
            <div
                className="fixed bottom-2 z-80 flex w-full flex-col items-center justify-end px-2 2xs:bottom-unset 2xs:right-10 2xs:top-10 2xs:w-max 2xs:flex-col-reverse 2xs:items-end 2xs:justify-start 2xs:px-0"
                data-type={globalActiveDataType}
            >
                {toastList.length > 0 &&
                    toastList.map((_item, index) => (
                        <AppToast
                            data={_item}
                            dataType={globalActiveDataType}
                            onRemove={(_id) => setToastList((prev) => prev.filter((_) => _.index !== _id))}
                            key={index}
                        />
                    ))}
            </div>
        </>
    );
}

export default DashboardToast;
