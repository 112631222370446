import { FundCardSections } from "../../../hooks/state/send-money/payments.constants";
import { IRootState } from "../../../../../../redux/rootReducer";
import PaymentHeader from "../../Layouts/payment/components/page-header";
import { useSelector } from "react-redux";

function FundCardHeaders(): JSX.Element {
    const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);
    return <PaymentHeader header={FundCardSections[paymentStage].text} subtitle={FundCardSections[paymentStage].subtext} />;
}

export default FundCardHeaders;
