import ButtonComp from "../../../../components/button/ButtonComp";
import Clipboard from "../../../../components/clipboard";
import { Link } from "react-router-dom";
import MessageToast from "../../../../components/message-toast";
import { ReactComponent as UserCertificate } from "../../../../assets/svg/user-certificate.svg";

interface Props {
    email?: string;
    username: string;
    justSetPassword: boolean;
}

function EmailVerified({ email, username, justSetPassword }: Props): JSX.Element {
    return (
        <div className="relative mx-auto flex w-full max-w-md flex-col items-center justify-center rounded-lg bg-white p-4 3xs:p-8">
            <div className="flex flex-col items-center justify-start space-y-8">
                <div>
                    <UserCertificate />
                </div>

                <div className="flex flex-col items-center justify-start space-y-12">
                    <div className="flex flex-col items-center justify-start space-y-2">
                        <h3 className="text-center text-2xl font-medium text-black">
                            {justSetPassword ? "Your password has been set." : "Your business profile has been created."}
                        </h3>
                        <h3 className="text-center text-sm font-normal text-black-secondary">
                            You will be able to login with the username below or your registered email address
                            {email && <span>({email || ""})</span>}
                        </h3>
                    </div>

                    <div className="flex flex-col items-center justify-start space-y-6">
                        <div className="w-max rounded-lg bg-grey-tertiary px-5 py-3">
                            <Clipboard className="[&>button]:min-w-max" codeString={username}>
                                <span className="text-xl font-medium uppercase text-black">{username}</span>
                            </Clipboard>
                        </div>

                        <MessageToast message={<p className="">Copy and save the username above for future login</p>} type="info" size="md" />
                    </div>
                </div>

                <Link to="/login" className="w-full">
                    <ButtonComp type="button" buttonType="primary" color="black" isText fullWidth>
                        Continue
                    </ButtonComp>
                </Link>
            </div>
        </div>
    );
}
export default EmailVerified;
