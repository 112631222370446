import { abortTransactionTransfer, transactions } from "../../Services/transactionApi";
import {
    resetTransactionDateFilterState,
    resetTransactionFilterState,
    resetTransactionList,
    setIsTransactionListEmpty,
    setIsTransactionPaginationLoading,
    setSelectedTransactionFilterState,
    setTransactionFilterState,
    setTransactionList,
    setTransactionsListGroupSize,
    setTransactionsListTotal,
} from "../../../../../redux/transactions/slice/transactionsSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../redux/rootReducer";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import Transaction from "../../../../../models/transaction";
import { TransactionFilterTypes } from "../../../../../redux/transactions/slice/transactionsSlice.types";
import { TransactionsListResponse } from "../../Services/transactionApi.types";
import { UseFilterTransactionsStateInterface } from "./useTransactionsState.type";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { produce } from "immer";
import { setMultipleTransactions } from "../../../../../redux/transaction/slice/transactionSlice";

function useFilterTransactionsState(): UseFilterTransactionsStateInterface {
    const dispatch = useDispatch();

    const transactionFilterState = useSelector((state: IRootState) => state.transactions.selectedTransactionFilterState);
    const transactionsStore = useSelector<IRootState, Map<string, Transaction>>((state: IRootState) => state.transaction.transactions);

    const [transactionsResponse, setTransactionsResponse] = useState<TransactionsListResponse | null>(null);

    const handleGetTransactions = useCallback(
        async (_data: TransactionFilterTypes) => {
            abortTransactionTransfer();
            try {
                setTransactionsResponse(null);
                dispatch(setIsTransactionPaginationLoading(true));
                dispatch(setIsTransactionListEmpty(false));
                dispatch(resetTransactionList());
                const res = await transactions(_data);
                setTransactionsResponse(res);
                dispatch(setMultipleTransactions(res.transactions));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsTransactionPaginationLoading(false));
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (!transactionsResponse || !transactionsResponse.success || !transactionsStore || transactionsStore.size < 1) return;
        if (transactionsResponse.transactions.length < 1) {
            dispatch(setIsTransactionListEmpty(true));
        }
        dispatch(
            setTransactionList(
                transactionsResponse.transactions.map((t) => transactionsStore.get(t.id) as Transaction).filter((el) => el !== undefined)
            )
        );
        dispatch(setTransactionsListTotal(transactionsResponse.total));
        dispatch(setTransactionsListGroupSize(transactionsResponse.groupSize));
    }, [transactionsResponse, transactionsStore]);

    const handleFilteredDetails = useCallback(
        <T>(data: T) => {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = { ...draft, ...data });
            });
            dispatch(setSelectedTransactionFilterState());
            dispatch(setTransactionFilterState(updatedList));
            dispatch(setIsTransactionPaginationLoading(true));
            void handleGetTransactions(updatedList);
        },
        [transactionFilterState]
    );

    const handleClearAll = useCallback(() => {
        dispatch(resetTransactionFilterState());
        dispatch(resetTransactionDateFilterState());
        void handleGetTransactions({
            offset: 0,
            date: { begin: "", end: "" },
            transactionTypes: [],
            userGroupIds: [],
            userAccountIds: [],
            categoryIds: [],
            transactionStatuses: [],
            query: "",
        });
    }, []);

    return {
        transactionsResponse,
        handleGetTransactions,
        handleFilteredDetails,
        handleClearAll,
    };
}

export default useFilterTransactionsState;
