import { DeclineReason } from "../../../../hooks/state/pending-approval/usePendingPayments.types";
import GoogleIcon from "../../../../../../../components/google-icon";
import LencoSpinner from "../../../../../../../components/spinner";
import NewDropdownHead from "../../../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../../../components/new-dropdown-item";
import Transaction from "../../../../../../../models/transaction";
import useCancelPendingPayment from "../../../../hooks/state/pending-approval/useCancelPendingPayment";

interface Props {
    transaction: Transaction;
}

function StandAloneCancelPayment(props: Props): JSX.Element {
    const { cancelReason, isCancelPaymentLoading, handleCancelPayment } = useCancelPendingPayment();

    return (
        <>
            <NewDropdownHead
                content={
                    <div className="flex flex-col items-center justify-center space-y-3">
                        <div className="flex max-h-12 min-h-12 min-w-[48px] max-w-[48px] items-center justify-center rounded-[10px] bg-error-backdrop">
                            {isCancelPaymentLoading ? (
                                <LencoSpinner size="sm" color="red" />
                            ) : (
                                <GoogleIcon icon="block" size="lg" className="text-error" />
                            )}
                        </div>
                        <p className="text-xs font-normal text-error">Cancel Payment</p>
                    </div>
                }
                color="red"
                buttonType="tertiary"
                dropdownWidth="max"
                isNoArrow
                isTriggerLower
            >
                <NewDropdownItem
                    isDisabled={cancelReason === DeclineReason.INCORRECT_DETAILS}
                    onClick={() =>
                        void handleCancelPayment({
                            reason: DeclineReason.INCORRECT_DETAILS,
                            transactionId: props.transaction.id,
                        })
                    }
                    color="red"
                    size="sm"
                >
                    <p className="text-sm">Incorrect Details</p>
                </NewDropdownItem>
                <NewDropdownItem
                    isDisabled={cancelReason === DeclineReason.INCORRECT_AMOUNT}
                    onClick={() =>
                        void handleCancelPayment({
                            reason: DeclineReason.INCORRECT_AMOUNT,
                            transactionId: props.transaction.id,
                        })
                    }
                    color="red"
                    size="sm"
                >
                    <p className="text-sm">Incorrect Amount</p>
                </NewDropdownItem>
                <NewDropdownItem
                    isDisabled={cancelReason === DeclineReason.WRONG_PURPOSE}
                    onClick={() =>
                        void handleCancelPayment({
                            reason: DeclineReason.WRONG_PURPOSE,
                            transactionId: props.transaction.id,
                        })
                    }
                    color="red"
                    size="sm"
                >
                    <p className="text-sm">Wrong Purpose</p>
                </NewDropdownItem>
                <NewDropdownItem
                    isDisabled={cancelReason === DeclineReason.OTHERS}
                    onClick={() =>
                        void handleCancelPayment({
                            reason: DeclineReason.OTHERS,
                            transactionId: props.transaction.id,
                        })
                    }
                    color="red"
                    size="sm"
                >
                    <p className="text-sm">Others</p>
                </NewDropdownItem>
            </NewDropdownHead>
            {/* <CancelPayment
                isCancelled={!!cancelReason}
                transaction={props.transaction}
                cancelledReason={cancelReason || undefined}
                isCancelTransactionLoading={isCancelPaymentLoading}
                handleSelectOrChangeCancelReason={({ reason, transaction }) =>
                    void handleCancelPayment({ reason: reason, transactionId: transaction.id })
                }
            /> */}
        </>
    );
}

export default StandAloneCancelPayment;
