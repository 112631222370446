import React, { useLayoutEffect } from "react";
import {
    setIsZambiaCreateApiAccessKeyModalOpen,
    setIsZambiaDeactivateApiAccessKeyModalOpen,
    setIsZambiaRegenerateApiAccessKeyModalOpen,
    setSelectedZambiaApiAccessKey,
    setTempSelectedZambiaApiAccessKey,
} from "../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import CreateApiKeyModal from "./Components/Modals/CreateApiKeyModal";
import Table from "../../../../../../components/Table";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import { useDispatch } from "react-redux";
import usePauseZambiaApiAccessKey from "../../../hookss/ApiAccessKeys/usePauseZambiaApiAccessKey";
import useRestoreZambiaApiAccessKey from "../../../hookss/ApiAccessKeys/useRestoreZambiaApiAccessKey";
import useZambiaApiAccessKeys from "../../../hookss/ApiAccessKeys/useZambiaApiAccessKeys";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../../components/google-icon";

const DATA_TYPE = "data-type-api-listt";
const allApiListHeader: TableHead = [
    { text: "API Name", pos: 1, headType: TableColumnType.TEXT },
    { text: "Last Used", pos: 2, headType: TableColumnType.TEXT },
    { text: "Date Created", pos: 3, headType: TableColumnType.TEXT },
    { text: "Status", pos: 4, headType: TableColumnType.STATUS },
    { text: "Action", pos: 5, headType: TableColumnType.ACTION },
];

function ApiList(): JSX.Element {
    const dispatch = useDispatch();
    const {
        apiAccessKeysList,
        apiAccessKeysListTotal,
        apiAccessKeysListGroupSize,
        apiAccessKeysPaginationOffset,
        isApiAccessKeysPaginationLoading,
        handleFilteredZambiaApiAccessKeys,
        handlePaginationRequest,
    } = useZambiaApiAccessKeys();

    const { handlePauseZambiaApiAccessKey } = usePauseZambiaApiAccessKey();
    const { handleRestoreZambiaApiAccessKey } = useRestoreZambiaApiAccessKey();

    useLayoutEffect(() => {
        void handleFilteredZambiaApiAccessKeys({
            offset: 0,
            query: "",
        });
    }, []);

    return (
        <div className="relative flex h-full w-full flex-col items-start justify-start">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-start">
                <CreateApiKeyModal />
                <Table
                    dataType={DATA_TYPE}
                    heads={allApiListHeader}
                    isPaginateLoading={isApiAccessKeysPaginationLoading}
                    groupSize={apiAccessKeysListGroupSize}
                    offset={apiAccessKeysPaginationOffset}
                    total={apiAccessKeysListTotal}
                    isLoading={!!isApiAccessKeysPaginationLoading}
                    paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                    noRecordText={
                        <span className="ju flex flex-col items-center space-y-2">
                            <span className="text-xl font-medium text-black">Create your first API</span>
                            <span className="text-xs font-normal text-black-tertiary">Create and manage your API access</span>
                            <span className="pt-2">
                                <ButtonComp
                                    className="mtt-2"
                                    type="button"
                                    color="black"
                                    buttonType="primary"
                                    size="md"
                                    onClick={() => dispatch(setIsZambiaCreateApiAccessKeyModalOpen(true))}
                                >
                                    <span className="px-2">New API Key</span>
                                </ButtonComp>
                            </span>
                        </span>
                    }
                    rows={
                        apiAccessKeysList?.map((obj) => ({
                            onRecordClick: () => {
                                dispatch(setSelectedZambiaApiAccessKey(obj));
                            },
                            record: [
                                {
                                    key: "API Name",
                                    text: obj?.name,
                                },
                                {
                                    key: "Last Used",
                                    text: obj?.lastUsedAt,
                                    textType: TextType.DATE_TIME,
                                },
                                {
                                    key: "Date Created",
                                    text: obj?.createdAt,
                                    textType: TextType.DATE_TIME,
                                },
                                {
                                    key: "Status",
                                    text: obj?.statusText,
                                    textType: obj.getTableStatus(),
                                },
                                {
                                    key: "Action",
                                    text: "drop-down",
                                    textType: TextType.MEAT_BALL_MENU,
                                    dropDownOptions: [
                                        {
                                            icon: <GoogleIcon icon="play_circle" size="xs" data-type={DATA_TYPE} />,
                                            text: "Go Live",
                                            func: () => handleRestoreZambiaApiAccessKey({ id: obj.id }),
                                            show: !!obj?.isPaused,
                                        },
                                        {
                                            icon: <GoogleIcon icon="pause_circle" size="xs" data-type={DATA_TYPE} />,
                                            text: " Pause",
                                            func: () => handlePauseZambiaApiAccessKey({ id: obj.id }),
                                            show: !!obj?.isActive,
                                        },
                                        {
                                            icon: <GoogleIcon icon="refresh" size="xs" data-type={DATA_TYPE} />,
                                            text: "Regenerate Keys",
                                            func: () => {
                                                dispatch(setTempSelectedZambiaApiAccessKey(obj));
                                                dispatch(setIsZambiaRegenerateApiAccessKeyModalOpen(true));
                                            },
                                            show: true,
                                        },
                                        {
                                            icon: <GoogleIcon className="text-error" icon="block" size="xs" data-type={DATA_TYPE} />,
                                            text: "Deactivate",
                                            isDanger: true,
                                            func: () => () => {
                                                dispatch(setTempSelectedZambiaApiAccessKey(obj));
                                                dispatch(setIsZambiaDeactivateApiAccessKeyModalOpen(true));
                                            },
                                            show: true,
                                        },
                                    ],
                                },
                            ],
                        })) || []
                    }
                />
            </div>
        </div>
    );
}

export default ApiList;
