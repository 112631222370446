import { InviteSignUpRequest, InviteSignUpResponse } from "../services/sign-up-api.types";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { inviteSignup } from "../services/sign-up-api";

interface Props {
    onComplete: () => void;
}
function useSignUpByInvite(props: Props): UseMutationResult<InviteSignUpResponse, AxiosError | null, InviteSignUpRequest, unknown> {
    const filter = useMutation({
        mutationFn: async (_data: InviteSignUpRequest) => {
            const res = await inviteSignup(_data);
            return res;
        },
        onSuccess() {
            props.onComplete();
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return filter;
}

export default useSignUpByInvite;
