import { ApiAccessScope, ApiAccessSource, ApiAccessStatus } from "./apiAccess.constant";

import ApiAccessWebhook from "./apiAccessWebhook";
import { GenericObject } from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import UserAccount from "../../userAccount";
import { immerable } from "immer";
import { TextType } from "../../../components/Table/Type/TextType";

export default class ApiAccess {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string,
        public publicKey: string,
        public status: ApiAccessStatus | null,
        public scopes: ApiAccessScope[],
        public accountsAccess: UserAccount[],
        public collectionsSettlementAccount: UserAccount | null,
        public hasUploadedLogo: boolean,
        public customThemeColor: string | null,
        public collectionFeeSource: ApiAccessSource | null,
        public webhookSignatureKey: string,
        public ipWhitelist: string[],
        public webhookUrl: string | null,
        public webhookCustomHeaders: ApiAccessWebhook[],
        public createdAt: Date | null,
        public lastUsedAt: Date | null
    ) {}

    static create(obj: GenericObject): ApiAccess {
        return new ApiAccess(
            Parsers.string(obj.id),
            Parsers.string(obj.name),
            Parsers.string(obj.publicKey),
            Parsers.nullableEnum(obj.status, ApiAccessStatus),
            Parsers.nullableEnumArray(obj.scopes, ApiAccessScope),
            Parsers.classObjectArray(obj.accountsAccess, UserAccount),
            Parsers.classObject(obj.collectionsSettlementAccount, UserAccount),
            Parsers.boolean(obj.hasUploadedLogo),
            Parsers.nullableString(obj.customThemeColor),
            Parsers.nullableEnum(obj.collectionFeeSource, ApiAccessSource),
            Parsers.string(obj.webhookSignatureKey),
            Parsers.stringArray(obj.ipWhitelist),
            Parsers.string(obj.webhookUrl),
            Parsers.classObjectArray(obj.webhookCustomHeaders, ApiAccessWebhook),
            Parsers.date(obj.createdAt),
            Parsers.date(obj.lastUsedAt)
        );
    }

    get isActive(): boolean {
        return this.status === ApiAccessStatus.ACTIVE;
    }

    get isPaused(): boolean {
        return this.status === ApiAccessStatus.PAUSED;
    }
    get isDeactivated(): boolean {
        return this.status === ApiAccessStatus.DEACTIVATED;
    }
    get isDisabledByLenco(): boolean {
        return this.status === ApiAccessStatus.DISABLED_BY_LENCO;
    }
    get isAccessible(): boolean {
        return !this.isDeactivated && !this.isDisabledByLenco;
    }
    get statusText(): string {
        return this.isActive
            ? "Live"
            : this.isPaused
              ? "Paused"
              : this.isDeactivated
                ? "Deactivated"
                : this.isDisabledByLenco
                  ? "Disabled by Lenco"
                  : "";
    }

    getTableStatus() {
        return this.isActive
            ? TextType.API_LIVE
            : this.isPaused
              ? TextType.API_PAUSED
              : this.isDeactivated
                ? TextType.API_DEACTIVATED
                : this.isDisabledByLenco
                  ? TextType.API_DISABLED_BY_LENCO
                  : TextType.API_DEACTIVATED;
    }
}
