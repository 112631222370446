import { useCallback, useState } from "react";

import { PauseApiAccessKeysRequest } from "../../Services/ApiAccessKeys/apiAccessKeysApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { pauseApiAccessKey } from "../../Services/ApiAccessKeys/apiAccessKeysApi";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch } from "react-redux";

export interface UsePauseZambiaApiAccessKeysInterface {
    isPauseZambiaApiAccessKeyLoading: boolean;
    pauseZambiaApiAccessKeyErrorMessage: string;
    handlePauseZambiaApiAccessKey: (_data: PauseApiAccessKeysRequest) => void;
}

function usePauseZambiaApiAccessKey(): UsePauseZambiaApiAccessKeysInterface {
    const dispatch = useDispatch();
    const [isPauseZambiaApiAccessKeyLoading, setIsPauseZambiaApiAccessKeyLoading] = useState(false);
    const [pauseZambiaApiAccessKeyErrorMessage, setPauseZambiaApiAccessKeyErrorMessage] = useState<string>("");

    const handlePauseZambiaApiAccessKey = useCallback(
        async (_data: PauseApiAccessKeysRequest) => {
            try {
                setIsPauseZambiaApiAccessKeyLoading(true);
                setPauseZambiaApiAccessKeyErrorMessage("");
                const res = await pauseApiAccessKey(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API Access Key Paused Successfully."));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setPauseZambiaApiAccessKeyErrorMessage(errorMessage);
            } finally {
                setIsPauseZambiaApiAccessKeyLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isPauseZambiaApiAccessKeyLoading,
        pauseZambiaApiAccessKeyErrorMessage,
        handlePauseZambiaApiAccessKey,
    };
}

export default usePauseZambiaApiAccessKey;
