import { ZambiaCollectionFeeBearer, ZambiaMobileMoneyServiceProvider, ZambiaSettlementStatus } from "./zambiaCollectionRequest.constant";

import Currency from "../currency";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import ZambiaLencoFeeData from "./zambiaLencoFeeData";

export default class ZambiaSettlementMobileMoneyCollection {
    constructor(
        public id: string,
        public createdAt: Date | null,
        public amount: string,
        public currency: Currency,
        public status: ZambiaSettlementStatus | null,
        public serviceProvider: ZambiaMobileMoneyServiceProvider | null,
        public debitPhoneNumber: string,
        public debitAccountName: string | null,
        public completedAt: Date | null,
        public failedReason: string | null,
        public lencoFee: string | null,
        public lencoFeeData: ZambiaLencoFeeData | null,
        public settlementAmount: string | null,
        public feeBearer: ZambiaCollectionFeeBearer | null
    ) {}

    static create(obj: GenericObject): ZambiaSettlementMobileMoneyCollection {
        return new ZambiaSettlementMobileMoneyCollection(
            Parsers.string(obj.id),
            Parsers.date(obj.createdAt),
            Parsers.string(obj.amount),
            Parsers.classObjectNonNullable(obj.currency, Currency),
            Parsers.nullableEnum(obj.status, ZambiaSettlementStatus),
            Parsers.nullableEnum(obj.serviceProvider, ZambiaMobileMoneyServiceProvider),
            Parsers.string(obj.debitPhoneNumber),
            Parsers.nullableString(obj.debitAccountName),
            Parsers.date(obj.completedAt),
            Parsers.nullableString(obj.failedReason),
            Parsers.nullableString(obj.lencoFee),
            Parsers.classObject(obj.lencoFeeData, ZambiaLencoFeeData),
            Parsers.nullableString(obj.settlementAmount),
            Parsers.nullableEnum(obj.feeBearer, ZambiaCollectionFeeBearer)
        );
    }

    get serviceProviderText(): string {
        return this.serviceProvider === ZambiaMobileMoneyServiceProvider.AIRTEL
            ? "Airtel"
            : this.serviceProvider === ZambiaMobileMoneyServiceProvider.MTN
              ? "MTN"
              : this.serviceProvider === ZambiaMobileMoneyServiceProvider.ZAMTEL
                ? "Zamtel"
                : "";
    }
}
