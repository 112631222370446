import { useCallback, useState } from "react";

import { TeamMemberApproverLimitRequest } from "../team-members.types";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { setApproverLimit } from "../team-members.api";
import { useDispatch } from "react-redux";

export interface useCancelTeamMemberApproverLimitResponse {
    isError: string | null;
    isLoading: boolean;
    isSetApproverLimitDone: boolean;
    handleReset: () => void;
    handleSetApproverLimit: (id: TeamMemberApproverLimitRequest) => Promise<void>;
}

// function useCancelTeamMemberApproverLimit(onComplete: (id: string) => void): useCancelTeamMemberApproverLimitResponse {
function useCancelTeamMemberApproverLimit(updateAccountMeta: (accountMeta: UserAccountMeta) => void): useCancelTeamMemberApproverLimitResponse {
    const dispatch = useDispatch();

    const [isError, setIsError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSetApproverLimitDone, setIsSetApproverLimitDone] = useState<boolean>(false);

    const handleSetApproverLimit = useCallback(async (_data: TeamMemberApproverLimitRequest) => {
        setIsError(null);
        setIsLoading(true);
        setIsSetApproverLimitDone(false);

        try {
            const res = await setApproverLimit(_data);
            setIsSetApproverLimitDone(true);
            // onComplete(id)
            updateAccountMeta(res.accountMember);
            dispatch(messageTrue("Approval limit canceled successfully"));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
            setIsError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleReset = useCallback(() => {
        setIsError(null);
        setIsLoading(false);
        setIsSetApproverLimitDone(false);
    }, []);

    return {
        isError,
        isLoading,
        isSetApproverLimitDone,

        handleReset,
        handleSetApproverLimit,
    };
}

export default useCancelTeamMemberApproverLimit;
