import BillHistoryCardApprovedOrDeclined from "./BillHistoryCardApprovedOrDeclined";
import BillPurchase from "../../../../../../../models/billPurchase";
import { IRootState } from "../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../components/spinner";
import { useSelector } from "react-redux";

interface Props {
    data: BillPurchase;
    dataType: string;
}

export default function BillPurchaseStatusCard({ dataType }: Props): JSX.Element {
    const transaction = useSelector((state: IRootState) => state.billPayment.selectedBillHistoryTransaction);

    return (
        <div className="flex w-full flex-col space-y-4 rounded-lg border-grey-secondary bg-grey-backdrop p-4" data-type={dataType}>
            {transaction && <BillHistoryCardApprovedOrDeclined transaction={transaction} dataType={dataType} />}
            {!transaction && (
                <div className="pointer-events-none ml-3 flex h-10 items-center justify-center">
                    <LencoSpinner size="sm" />
                </div>
            )}
        </div>
    );
}
