import {
    setUserList,
    updateDefaultSetup,
    updateManagerAccessList,
    updateTotalAccessList,
    updateViewerAccessList,
} from "../../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import { useCallback, useState } from "react";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { lencoPayDefaultSetupInit } from "../../Services/DefaultSetup/lencoPayDefaultSetupApi";
import { listTeamMembers } from "../../../settings-new/Services/team-members/team-members.api";
import { useDispatch } from "react-redux";

function useLencoPayDefaultSetupInit() {
    const dispatch = useDispatch();
    const [isLencoPayDefaultSetupInitLoading, setIsLencoPayDefaultSetupInitLoading] = useState(false);
    const [updateLencoPayDefaultSetupErrorMessage, setLencoPayDefaultSetupInitErrorMessage] = useState<string>("");

    const handleLencoPayDefaultSetupInit = useCallback(async () => {
        try {
            setIsLencoPayDefaultSetupInitLoading(true);
            setLencoPayDefaultSetupInitErrorMessage("");
            const res = await lencoPayDefaultSetupInit();
            if (res.defaultSetup) {
                dispatch(updateDefaultSetup(res.defaultSetup));
            }
            dispatch(updateManagerAccessList(res?.teamMemberAccess?.manageAccess || []));
            dispatch(updateViewerAccessList(res?.teamMemberAccess?.viewAccess || []));
            dispatch(updateTotalAccessList([...(res?.teamMemberAccess?.manageAccess || []), ...(res?.teamMemberAccess?.viewAccess || [])]));

            const usersRes = await listTeamMembers();
            const users = usersRes.map((_el) => _el.teamMember).filter((_user) => !_user.isInvitePending);
            dispatch(setUserList(users));
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
            setLencoPayDefaultSetupInitErrorMessage(errorMessage);
        } finally {
            setIsLencoPayDefaultSetupInitLoading(false);
        }
    }, [dispatch]);

    return {
        isLencoPayDefaultSetupInitLoading,
        updateLencoPayDefaultSetupErrorMessage,
        handleLencoPayDefaultSetupInit,
    };
}

export default useLencoPayDefaultSetupInit;
