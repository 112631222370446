export const mockAccounts = [
    { text: "The Wallers Inc", value: "The Wallers Inc", restricted: true },
    { text: "The Staffers Inc2", value: "The Staffers Inc2", restricted: false },
];

export const mockSpecificAccounts = [
    { text: "Marilyn Monroe", value: "03747484832" },
    { text: "Maggie Maggot", value: "03747484831" },
    { text: "Lagbaja Ekpeikot ", value: "0137474848" },
];

export const mockSendMoneyRule = [
    { text: "Send Money to Any Account", value: "Send Money to Any Account", subtext: "You can send money to any account" },
    {
        text: "Send Money to Specific Accounts Only",
        value: "Send Money to Specific Accounts Only",
        subtext: "You can only send to specific recipients",
    },
    { text: "Can Not Send Money", value: "Can Not Send Money", subtext: "You can not send money from this account" },
];

export const mockBank = [
    { text: "Prov Bank", value: "Prov Bank" },
    { text: "Lagbaja MFB", value: "Emeka MFB" },
    { text: "Emela MFB", value: "Emeka MFB" },
];
