import { useCallback, useEffect, useRef } from "react";

// import {BulkPaymentV2StatusItem} from "../../../../Services/SendMoney/bulkTransferApi.types";
import { RequestCancelledError } from "../../../../../../../helpers/request/requestErrors";
import { TransactionStatus } from "../../../../../../../models/transaction.constants";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getBulkTransferStatus } from "../../../../services/send-money/bulkTransferApi";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { updateTransferDetailStatus } from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useDispatch } from "react-redux";

// interface CheckBulkStatusInterface {
// isBulkPaymentStatusesLoading: boolean;
// bulkPaymentStatuses: BulkPaymentV2StatusItem[];
// handleGetBulkPaymentStatus: (_data: string) => Promise<void>;
// }

export default function useCheckBulkStatus(canFetch: boolean, _bulkTransferId: string): void {
    const dispatch = useDispatch();
    // const [isBulkPaymentStatusesLoading, setIsBulkPaymentStatusesLoading] = useState(false);
    // const [bulkPaymentStatuses, setBulkPaymentStatuses] = useState<BulkPaymentV2StatusItem[]>([]);

    const pollingRef = useRef<ReturnType<typeof setInterval> | null>();

    useEffect(() => {
        if (!canFetch)
            return () => {
                if (pollingRef.current) {
                    clearInterval(pollingRef.current);
                }
            };
        pollingRef.current = setInterval(() => {
            void handleGetBulkPaymentStatus();
        }, 10000);
        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
        };
    }, [canFetch]);

    const handleGetBulkPaymentStatus = useCallback(async (): Promise<void> => {
        try {
            // setIsBulkPaymentStatusesLoading(true);
            const res = await getBulkTransferStatus({
                bulkPaymentKey: _bulkTransferId,
            });
            // setBulkPaymentStatuses(res.payments);
            for (const payment of res.payments) {
                dispatch(
                    updateTransferDetailStatus({
                        key: Number(payment.key),
                        status: payment.status || TransactionStatus.PROCESSING,
                        transactionId: payment.transactionId || "",
                    })
                );
            }
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            // setIsBulkPaymentStatusesLoading(false);
        }
    }, []);
    // return {
    // bulkPaymentStatuses,
    // isBulkPaymentStatusesLoading,
    // handleGetBulkPaymentStatus,
    // };
}
