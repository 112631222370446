import { DeclineReason, DeclinedTransaction, SelectedTransaction, UsePendingApprovalInterface } from "./usePendingPayments.types";
import { abortAllPendingPaymentRequests, pendingPaymentLists } from "../../../services/pending-approval/pendingPayments";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../redux/rootReducer";
import { PageTitle } from "../../../../../../helpers/AppConstants";
import { PendingPaymentListsRequest } from "../../../services/pending-approval/pendingPayments.types";
import Transaction from "../../../../../../models/transaction";
import { TransactionStatus } from "../../../../../../models/transaction.constants";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { setMultipleTransactions } from "../../../../../../redux/transaction/slice/transactionSlice";
import useBulkCancelPayment from "./useBulkCancelPayment";
import useBulkPayment from "./useBulkPayment";

const usePendingApprovalState = (): UsePendingApprovalInterface => {
    const dispatch = useDispatch();

    document.title = PageTitle.PENDING_APPROVAL_PAGE;

    const isAppLoading = useSelector((state: IRootState) => state.init.loading);
    const transactions = useSelector<IRootState, Map<string, Transaction>>((state: IRootState) => state.transaction.transactions);
    const currentUserId = useSelector((state: IRootState) => state.init.main?.companyDetails.user.id);
    const userAccountMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);

    const [showModal, setShowModal] = useState(false);
    const [selectedArr, setSelectedArr] = useState<Array<SelectedTransaction>>([]);
    const [pendingPaymentList, setPendingPaymentList] = useState<Transaction[]>([]);
    const [pendingPaymentListsTotal, setPendingPaymentListsTotal] = useState<number>(0);
    const [pendingPaymentListsOffset, setPendingPaymentListsOffset] = useState<number>(0);
    const [isPendingPaymentListsLoading, setIsPendingPaymentListsLoading] = useState<boolean>(false);
    const [pendingPaymentListsGroupSize, setPendingPaymentListsGroupSize] = useState<number>(0);

    const isPendingApprovalRequired = selectedArr.some((el) => el.isApproved);
    const isAllSelectedPaymentCancelled = selectedArr.every((_payment) => _payment.isCancelled);
    const isApprovedAndCancelledPayment = selectedArr.some((el) => el.isApproved || el.isDeclined) && selectedArr.some((el) => el.isCancelled);

    const userCanApprovePayment = pendingPaymentList?.some(
        (_payment) =>
            _payment.initiatedBy?.id !== currentUserId && userAccountMeta?.find((_el) => _el.userAccountId === _payment?.userAccount?.id)?.isApprover
    );
    const userInitiatedAnyPayment = pendingPaymentList?.some(
        (_payment) =>
            _payment.initiatedBy?.id === currentUserId && userAccountMeta?.find((_el) => _el.userAccountId === _payment?.userAccount?.id)?.isInitiator
    );
    const userInitiatedEveryPayment = pendingPaymentList?.every(
        (_payment) =>
            _payment.initiatedBy?.id === currentUserId && userAccountMeta?.find((_el) => _el.userAccountId === _payment?.userAccount?.id)?.isInitiator
    );

    const { isBulkPaymentLoading, handleBulkProcessing } = useBulkPayment({
        active: false,
        onComplete: isApprovedAndCancelledPayment ? undefined : () => setSelectedArr([]),
    });

    const { isBulkCancelPaymentLoading, handleBulkCancelPayment } = useBulkCancelPayment({
        onComplete: () => setSelectedArr([]),
    });

    useEffect(() => {
        if (!transactions) return;
        setPendingPaymentList((prev) => prev.map((t) => transactions.get(t.id) || t));
    }, [transactions]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            const doesPaymentsThatHaveBeenProcessedExist = pendingPaymentList.some(
                (_payment) => _payment.status !== TransactionStatus.PENDING_APPROVAL
            );
            if (doesPaymentsThatHaveBeenProcessedExist) {
                setPendingPaymentList((prev) => prev.filter((_payment) => _payment.status === TransactionStatus.PENDING_APPROVAL));
            }
        }, 5000);

        return () => {
            clearTimeout(timeOut);
        };
    }, [pendingPaymentList]);

    const handlePendingLists = useCallback(
        async (_data: PendingPaymentListsRequest) => {
            try {
                setIsPendingPaymentListsLoading(true);
                const res = await pendingPaymentLists(_data);
                dispatch(setMultipleTransactions(res.transactions));
                setPendingPaymentListsGroupSize(res.groupSize);
                setPendingPaymentListsTotal(res.total);
                setPendingPaymentList(res.transactions);
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsPendingPaymentListsLoading(false);
            }
        },
        [dispatch]
    );

    useLayoutEffect(() => {
        if (isAppLoading) return;
        void handlePendingLists({ offset: 0 });
    }, [handlePendingLists, isAppLoading]);

    useEffect(() => {
        return () => {
            abortAllPendingPaymentRequests();
        };
    }, []);

    const handleApprovePayment = useCallback((_data: Transaction) => {
        setSelectedArr((prev: SelectedTransaction[]): SelectedTransaction[] => {
            const _doesTransactionExist = prev.some((el) => el.transaction.id === _data.id);
            if (_doesTransactionExist) {
                const _isTransactionApproved = prev.find((el) => el.transaction.id === _data.id)?.isApproved;
                return _isTransactionApproved
                    ? prev.filter((el) => el.transaction.id !== _data.id)
                    : prev.map((el) =>
                          el.transaction.id === _data.id
                              ? {
                                    ...el,
                                    isApproved: true,
                                    isDeclined: false,
                                    isCancelled: false,
                                    declinedReason: undefined,
                                    cancelledReason: undefined,
                                }
                              : el
                      );
            } else {
                return [
                    ...prev,
                    {
                        transaction: _data,
                        isApproved: true,
                        isDeclined: false,
                        isCancelled: false,
                        declinedReason: undefined,
                        cancelledReason: undefined,
                    },
                ];
            }
        });
    }, []);

    const handleDeclinePayment = useCallback((_data: Transaction) => {
        setSelectedArr((prev: SelectedTransaction[]): SelectedTransaction[] => {
            const _doesTransactionExist = prev.some((el) => el.transaction.id === _data.id);
            if (_doesTransactionExist) {
                const _isTransactionDeclined = prev.find((el) => el.transaction.id === _data.id)?.isDeclined;
                return _isTransactionDeclined
                    ? prev.filter((el) => el.transaction.id !== _data.id)
                    : prev.map((el) =>
                          el.transaction.id === _data.id
                              ? {
                                    ...el,
                                    isApproved: false,
                                    isDeclined: true,
                                    isCancelled: false,
                                    declinedReason: DeclineReason.OTHERS,
                                    cancelledReason: undefined,
                                }
                              : el
                      );
            } else {
                return [
                    ...prev,
                    {
                        transaction: _data,
                        isApproved: false,
                        isDeclined: true,
                        isCancelled: false,
                        declinedReason: DeclineReason.OTHERS,
                        cancelledReason: undefined,
                    },
                ];
            }
        });
    }, []);

    const handleSelectOrChangeDeclineReason = useCallback((_data: DeclinedTransaction) => {
        setSelectedArr((prev: SelectedTransaction[]) => {
            const _doesTransactionExist = prev.some((el) => el.transaction.id === _data.transaction.id);
            return _doesTransactionExist
                ? prev.map((el) =>
                      el.transaction.id === _data.transaction.id
                          ? {
                                ...el,
                                isApproved: false,
                                isDeclined: true,
                                isCancelled: false,
                                declinedReason: _data.reason,
                                cancelledReason: undefined,
                            }
                          : el
                  )
                : [
                      ...prev,
                      {
                          transaction: _data.transaction,
                          isApproved: false,
                          isDeclined: true,
                          isCancelled: false,
                          declinedReason: _data.reason,
                          cancelledReason: undefined,
                      },
                  ];
        });
    }, []);

    const handleSelectOrChangeCancelReason = useCallback((_data: DeclinedTransaction) => {
        setSelectedArr((prev: SelectedTransaction[]) => {
            const _doesTransactionExist = prev.some((el) => el.transaction.id === _data.transaction.id);
            return _doesTransactionExist
                ? prev.map((el) =>
                      el.transaction.id === _data.transaction.id
                          ? {
                                ...el,
                                isApproved: false,
                                isDeclined: false,
                                isCancelled: true,
                                declinedReason: undefined,
                                cancelledReason: _data.reason,
                            }
                          : el
                  )
                : [
                      ...prev,
                      {
                          transaction: _data.transaction,
                          isApproved: false,
                          isDeclined: false,
                          isCancelled: true,
                          declinedReason: undefined,
                          cancelledReason: _data.reason,
                      },
                  ];
        });
    }, []);

    const handleApproveAllPayments = useCallback(() => {
        const _updatedList: SelectedTransaction[] = pendingPaymentList
            .filter((_transaction) => {
                const currentUserAccountMeta = userAccountMeta?.find((_el) => _el.userAccountId === _transaction?.userAccount?.id);
                const isApprover = !!(
                    currentUserAccountMeta?.isApprover &&
                    (currentUserAccountMeta.maxApprovalAmount ? Number(_transaction.amount) <= currentUserAccountMeta.maxApprovalAmount : true)
                );
                return isApprover;
            })
            .map((_transaction) => ({
                transaction: _transaction,
                isApproved: true,
                isDeclined: false,
                isCancelled: false,
                declinedReason: undefined,
                cancelledReason: undefined,
            }));

        setSelectedArr(_updatedList);
    }, [userAccountMeta, pendingPaymentList]);

    const handleDeclineAllPayments = useCallback(() => {
        const _updatedList: SelectedTransaction[] = pendingPaymentList
            .filter(
                (_transaction) => userAccountMeta?.find((_el) => _el.userAccountId === _transaction.originatingUserAccount?.id)?.isApprover || false
            )
            .map((_transaction) => ({
                transaction: _transaction,
                isApproved: false,
                isDeclined: true,
                isCancelled: false,
                declinedReason: DeclineReason.OTHERS,
                cancelledReason: undefined,
            }));

        setSelectedArr(_updatedList);
    }, [userAccountMeta, pendingPaymentList]);

    const handleCancelAllPayments = useCallback(() => {
        const _updatedList: SelectedTransaction[] = pendingPaymentList
            .filter(
                (_transaction) =>
                    _transaction.initiatedBy?.id === currentUserId &&
                    (userAccountMeta?.find((_el) => _el.userAccountId === _transaction.originatingUserAccount?.id)?.isInitiator || false)
            )
            .map((_transaction) => ({
                transaction: _transaction,
                isApproved: false,
                isDeclined: false,
                isCancelled: true,
                declinedReason: undefined,
                cancelledReason: DeclineReason.OTHERS,
            }));
        setSelectedArr(_updatedList);
    }, [currentUserId, userAccountMeta, pendingPaymentList]);

    const handleRemoveDeclineOrChangeToDecline = useCallback((data: Transaction) => {
        setSelectedArr((prev) => prev.filter((el) => el.transaction.id !== data.id));
    }, []);

    const handleRemoveCancelOrChangeToCancel = useCallback((data: Transaction) => {
        setSelectedArr((prev) => prev.filter((el) => el.transaction.id !== data.id));
    }, []);

    const handleProcessBulkApprovedAndCancelledPayment = useCallback(() => {
        try {
            void handleBulkProcessing({
                transactions: selectedArr
                    .filter((el) => !el.isCancelled)
                    .map((el) => ({ id: el.transaction.id, approve: el.isApproved, reason: el.declinedReason })),
            });
            void handleBulkCancelPayment({
                transactionIds: selectedArr.filter((el) => el.isCancelled).map((el) => el.transaction.id),
                reason: DeclineReason.OTHERS,
            });
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        }
    }, [selectedArr]);

    const handleProcessBulkPayment = useCallback(() => {
        isAllSelectedPaymentCancelled
            ? void handleBulkCancelPayment({
                  transactionIds: selectedArr.map((el) => el.transaction.id),
                  reason: DeclineReason.OTHERS,
              })
            : isPendingApprovalRequired
              ? setShowModal(true)
              : isApprovedAndCancelledPayment
                ? handleProcessBulkApprovedAndCancelledPayment()
                : void handleBulkProcessing({
                      transactions: selectedArr.map((el) => ({ id: el.transaction.id, approve: el.isApproved, reason: el.declinedReason })),
                  });
    }, [selectedArr, isPendingApprovalRequired, isAllSelectedPaymentCancelled]);

    const handleCloseBulkProcessingModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleClearSelectedArr = useCallback(() => {
        setSelectedArr([]);
        if (showModal) setShowModal(false);
    }, [showModal]);

    const handleUpdateOffset = useCallback((_newOffset: number) => {
        handleClearSelectedArr();
        setPendingPaymentListsOffset(_newOffset);
        void handlePendingLists({ offset: _newOffset });
    }, []);

    return {
        showModal,
        selectedArr,
        pendingPaymentList,
        isBulkPaymentLoading,
        userCanApprovePayment,
        userInitiatedAnyPayment,
        pendingPaymentListsTotal,
        pendingPaymentListsOffset,
        userInitiatedEveryPayment,
        isBulkCancelPaymentLoading,
        pendingPaymentListsGroupSize,
        isPendingPaymentListsLoading,
        isAllSelectedPaymentCancelled,

        handleUpdateOffset,
        handleDeclinePayment,
        handleApprovePayment,
        handleClearSelectedArr,
        handleCancelAllPayments,
        handleApproveAllPayments,
        handleDeclineAllPayments,
        handleProcessBulkPayment,
        handleCloseBulkProcessingModal,
        handleSelectOrChangeCancelReason,
        handleSelectOrChangeDeclineReason,
        handleRemoveCancelOrChangeToCancel,
        handleRemoveDeclineOrChangeToDecline,
    };
};

export default usePendingApprovalState;
