import { NotificationChannel, NotificationType, UserRole } from "../../../../../../models/userAccount.constants";
import { NotificationChannelOptions, NotificationTypeOptions, RoleOptions } from "../team-members.constants";
import { TeamMemberAccountSettings, TeamMemberItem } from "../team-members.types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddTeamMemberToAccountsRequest } from "../../account-settings/account-settings.types";
import Currency from "../../../../../../models/currency";
import { DropdownItem } from "../../../../../../helpers/types";
import { IRootState } from "../../../../../../redux/rootReducer";
import { MainInitState } from "../../../../../../redux/init/slice/initSlice.types";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import { addTeamMemberToAccounts } from "../../account-settings/account-settings.api";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import titleCase from "../../../../../../hooks/titleCase";
import useStateRef from "../../../../../../hooks/useStateRef";

export interface useAddTeamMemberToAccountsResponse {
    options: {
        role: Array<DropdownItem<UserRole>>;
        account: Array<DropdownItem<string>>;
        notificationType: Array<DropdownItem<NotificationType>>;
        notificationChannel: Array<DropdownItem<NotificationChannel>>;
    };
    error: string | null;
    currency: Currency | null | undefined;
    isFormValid: boolean;
    isSubmitting: boolean;
    settingsForm: TeamMemberAccountSettings;
    isSettingApprovalLevel: boolean;

    handleFormSubmit: () => void;

    setRole: (newValue: UserRole | undefined) => void;
    setUserAccountIds: (newValue: string[]) => void;
    setNotificationType: (newValue: NotificationType | undefined) => void;
    setMaxApprovalAmount: (newValue: string) => void;
    setNotificationChannels: (newValue: NotificationChannel[]) => void;
    handleToggleApprovalLevel: () => void;
}

function useAddTeamMemberToAccounts(
    item: TeamMemberItem,
    active: boolean,

    onComplete: (item: TeamMemberItem, _accounts: UserAccountMeta[]) => void
): useAddTeamMemberToAccountsResponse {
    const dispatch = useDispatch();

    const main = useSelector<IRootState, MainInitState | null>((state) => state.init.main);
    const currency = useSelector<IRootState, Currency | null | undefined>((state) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);

    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isFormValid, setIsFormValid, isFormValidRef] = useStateRef<boolean>(false);
    const [isSettingApprovalLevel, setIsSettingApprovalLevel] = useState<boolean>(false);
    const [accountOptions, setAccountOptions] = useState<Array<DropdownItem<string>>>([]);
    const [settingsForm, setSettingsForm, settingsFormRef] = useStateRef<TeamMemberAccountSettings>({
        role: undefined,
        userAccountIds: [],
        minApprovalAmount: null,
        maxApprovalAmount: null,
        notificationType: undefined,
        notificationChannels: [],
    });

    useEffect(() => {
        if (!active) {
            setError(null);
            setSettingsForm({
                role: undefined,
                notificationType: undefined,
                notificationChannels: [],
                userAccountIds: [],
                maxApprovalAmount: null,
                minApprovalAmount: null,
            });
            setIsSettingApprovalLevel(false);
        }
    }, [active]);

    useEffect(() => {
        if (item && main) {
            const accounts = main.companyDetails.accounts.filter((f) => !item.accountsMeta.some((_accMeta) => _accMeta.userAccountId === f.id));
            setAccountOptions(
                accounts.map((account) => ({
                    value: account.id,
                    text: account.lencoNameMin || account.bankAccount?.accountName || "",
                    subtext: account.accountName || undefined,
                }))
            );
        }
    }, [item, main]);

    const handleAddTeamMemberToAccounts = useCallback(
        async (data: AddTeamMemberToAccountsRequest) => {
            setError(null);
            setIsSubmitting(true);
            try {
                const res = await addTeamMemberToAccounts(data);
                onComplete(item, res);
                dispatch(
                    messageTrue({
                        message: `${titleCase(item.teamMember.name)} has been added`,
                    })
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch, item]
    );

    const handleFormSubmit = useCallback(() => {
        if (
            !isFormValidRef.current ||
            isNullOrUndefined(settingsFormRef.current.role) ||
            isNullOrUndefined(settingsFormRef.current.notificationType)
        ) {
            return setError("Fill all required fields");
        }
        void handleAddTeamMemberToAccounts({
            ...settingsFormRef.current,
            memberId: item.id,
            role: settingsFormRef.current.role,
            notificationType: settingsFormRef.current.notificationType,
        });
    }, [isFormValidRef.current, item, settingsFormRef]);

    const setUserAccountIds = useCallback(
        (newValue: string[]) => {
            setSettingsForm({
                ...settingsFormRef.current,
                userAccountIds: newValue,
            });
        },
        [settingsFormRef]
    );

    const setRole = useCallback(
        (newValue: UserRole | undefined) => {
            setSettingsForm({
                ...settingsFormRef.current,
                role: newValue,
            });
        },
        [settingsFormRef]
    );

    const setNotificationType = useCallback(
        (newValue: NotificationType | undefined) => {
            setSettingsForm({
                ...settingsFormRef.current,
                notificationType: newValue,
            });
        },
        [settingsFormRef]
    );

    const setNotificationChannels = useCallback(
        (newValue: NotificationChannel[]) => {
            setSettingsForm({
                ...settingsFormRef.current,
                notificationChannels: newValue,
            });
        },
        [settingsFormRef]
    );

    const setMaxApprovalAmount = useCallback(
        (newValue: string) => {
            setSettingsForm({
                ...settingsFormRef.current,
                maxApprovalAmount: newValue,
            });
        },
        [settingsFormRef]
    );

    const handleToggleApprovalLevel = useCallback(() => {
        setIsSettingApprovalLevel((prev) => !prev);
    }, []);

    // check if the form is valid
    useEffect(() => {
        if (settingsForm.notificationType === NotificationType.NONE) {
            setIsFormValid(
                !!(settingsForm.userAccountIds.length > 0 && !isNullOrUndefined(settingsForm.role) && settingsForm.notificationChannels.length < 1)
            );
        } else if (settingsForm.role === UserRole.NOTIFICATION_ONLY) {
            setIsFormValid(
                !!(
                    settingsForm.userAccountIds.length > 0 &&
                    !isNullOrUndefined(settingsForm.notificationType) &&
                    settingsForm.notificationType === NotificationType.TRUNCATED &&
                    settingsForm.notificationChannels.length > 0
                )
            );
        } else {
            setIsFormValid(
                !!(
                    settingsForm.userAccountIds.length > 0 &&
                    settingsForm.notificationChannels.length > 0 &&
                    !isNullOrUndefined(settingsForm.role) &&
                    !isNullOrUndefined(settingsForm.notificationType)
                )
            );
        }
    }, [settingsForm]);

    // set AccountOptions from state.init.main
    // useEffect(() => {
    // const accounts: UserAccount[] = main?.companyDetails?.accounts || [];
    // setAccountOptions(
    // accounts.map((account) => ({
    // value: account.id,
    // text: account.lencoNameMin || account.bankAccount?.accountName || "",
    // subtext: account.accountName || undefined,
    // }))
    // );
    // }, [main]);

    return {
        error,
        currency,
        isFormValid,
        isSubmitting,
        settingsForm,
        isSettingApprovalLevel,
        options: {
            role: RoleOptions,
            account: accountOptions,
            notificationType: NotificationTypeOptions,
            notificationChannel: NotificationChannelOptions,
        },

        setRole,
        handleFormSubmit,
        setUserAccountIds,
        setNotificationType,
        setMaxApprovalAmount,
        setNotificationChannels,
        handleToggleApprovalLevel,
    };
}

export default useAddTeamMemberToAccounts;
