import { useEffect, useState } from "react";

import Clipboard from "../../../../../components/clipboard";
import UserAccount from "../../../../../models/userAccount";
import getBankName from "../../../../../helpers/getBankName";
import titleCase from "../../../../../hooks/titleCase";

interface AddFundsCardProps {
    isCard?: boolean;
    userAccount: UserAccount;
}

function AddFundsCard(props: AddFundsCardProps): JSX.Element {
    const { userAccount } = props;
    const [clipSuccess, setClipSuccess] = useState(false);

    useEffect(() => {
        if (clipSuccess) {
            setTimeout(() => {
                setClipSuccess(false);
            }, 800);
        } else {
            return;
        }
    }, [clipSuccess]);

    return (
        <div
            className={
                "box-border flex w-full flex-row items-center justify-between space-x-2 rounded-lg px-4 py-3" +
                ` ${props.isCard ? "bg-grey-backdrop" : "border"}`
            }
        >
            <div className="flex flex-row items-center">
                <div className={"flex flex-col " + `${!props.isCard ? "space-y-2" : ""}`}>
                    <div className={"flex w-full flex-col " + `${!props.isCard ? "space-y-2" : ""}`}>
                        {!props.isCard ? (
                            <>
                                <p className={"whitespace-nowrap font-medium " + `${props.isCard ? "text-lg" : "text-sm"}`}>
                                    {titleCase(userAccount.bankAccount?.accountName.toLocaleLowerCase() || "")}
                                </p>
                                <p className="text-sm font-normal text-black-tertiary">{userAccount.bankAccount?.accountNumber}</p>
                                <div className="flex items-center space-x-2">
                                    <span className="text-sm font-medium text-info">URE MFB</span>
                                    <div className="h-0.5 w-0.5 rounded-full bg-black-tertiary" />
                                    <span className="text-sm font-normal text-black-tertiary">
                                        {titleCase(getBankName(userAccount.bankAccount?.bankCode))}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className={"whitespace-nowrap font-medium " + `${props.isCard ? "text-lg" : "text-sm"}`}>
                                    {titleCase(userAccount.bankAccount?.accountName.toLocaleLowerCase() || "")}
                                </p>
                                <p className="text-sm font-normal text-black-tertiary">{userAccount.bankAccount?.accountNumber}</p>
                                <span className="text-sm font-normal text-black-tertiary">
                                    {titleCase(getBankName(userAccount.bankAccount?.bankCode))}
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-end">
                <Clipboard codeString={userAccount.bankAccount?.accountNumber} size="md"></Clipboard>
            </div>
        </div>
    );
}

export default AddFundsCard;
