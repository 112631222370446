import React from "react";
// import { ReactComponent as Check } from "../../../../../../../../assets/svg/Settings/check.svg";

function ViewerDescription() {
    return (
        <div className="item-start flex w-full flex-col justify-start space-y-2">
            <div className="relative flex w-[260px] flex-col items-start justify-center space-y-2">
                <div className="relative inline-flex items-center space-x-2">
                    {/* <Check /> */}
                    <span className="material-symbols-outlined text-base text-blue">check</span>
                    <div className="whitespace-nowrap text-xs text-black-secondary">Limited Access</div>
                </div>
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-blue">check</span>
                    <p className="whitespace-nowrap text-xs text-black-secondary">Can see transaction history and statements</p>
                </div>
            </div>
        </div>
    );
}

export default ViewerDescription;
