import { useEffect, useState } from "react";

import Input from "../../../../../components/inputs/Input";
import SimpleModal from "../../../../../components/modal/simple-modal";
import useEditRecipientAlias from "../../hooks/state/SavedRecipient/useEditRecipientAlias";

function EditRecipientAliasModal(): JSX.Element {
    const data = useEditRecipientAlias();
    const [alias, setAlias] = useState<string>("");

    useEffect(() => {
        setAlias(data.recipient?.customerAccount.alias || "");
    }, [data.recipient]);

    return (
        <SimpleModal
            active={data.isModalOpen}
            onClose={data.closeModal}
            isSubmitting={data.isSubmitting}
            onSubmit={() => {
                if (data.recipient) {
                    void data.handleEditRecipientAlias(data.recipient, alias);
                }
            }}
            canSubmit={!!alias}
            errorMessage={data.errorMessage}
            header="Edit Alias"
            confirmText="Update"
        >
            <Input placeholder="Alias" value={alias} onChange={setAlias} />
        </SimpleModal>
    );
}

export default EditRecipientAliasModal;
