import React from "react";
import SkeletonElement from "../../../../../components/skeleton";

function RecentBillPaymentSkeleton(): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                <div className="w-40">
                    <SkeletonElement text />
                </div>
                <div className="flex items-center justify-start space-x-7 overflow-auto">
                    {new Array(2).fill("").map((_data: string, index) => (
                        <div
                            className="flex h-24 w-28 cursor-pointer flex-col items-center justify-center rounded-[10px] border border-grey-secondary bg-grey-backdrop px-2 pb-2 pt-1"
                            key={index + _data}
                        >
                            <div>
                                <div className="h-10 w-10 overflow-hidden rounded-full">
                                    <SkeletonElement box />
                                </div>
                            </div>
                            <div className="mt-1 w-full">
                                <SkeletonElement text />
                            </div>
                            <div className="mt-1 w-full">
                                <SkeletonElement text />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default RecentBillPaymentSkeleton;
