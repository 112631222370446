import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import UserApp from "./userApp";
import { immerable } from "immer";

export default class User {
    [immerable] = true;

    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public phones: string[],
        public email: string,
        public authApp: UserApp,
        public mobileApp: UserApp,
        public isAdmin: boolean,
        public isDirector: boolean,
        public isKeyContact: boolean,
        public isInvitePending: boolean,
        public hasManageCardAccess: boolean,
        public hasManageLencoPayAccess: boolean,
        public hasViewCardAccess: boolean,
        public hasViewLencoPayAccess: boolean,
        public hasViewTerminalAccess: boolean,
        public hasManageTerminalAccess: boolean
    ) {}

    static create(obj: GenericObject): User {
        return new User(
            Parsers.string(obj.id),
            Parsers.string(obj.firstName),
            Parsers.string(obj.lastName) || Parsers.string(obj.surname),
            Parsers.stringArray(obj.phones),
            Parsers.string(obj.email),
            obj.authApp ? Parsers.classObjectNonNullable(obj.authApp, UserApp) : UserApp.createDefault(),
            obj.mobileApp ? Parsers.classObjectNonNullable(obj.mobileApp, UserApp) : UserApp.createDefault(),
            Parsers.boolean(obj.isAdmin),
            Parsers.boolean(obj.isDirector),
            Parsers.boolean(obj.isKeyContact),
            Parsers.boolean(obj.isInvitePending),
            Parsers.boolean(obj.hasManageCardAccess),
            Parsers.boolean(obj.hasManageLencoPayAccess),
            Parsers.boolean(obj.hasViewCardAccess),
            Parsers.boolean(obj.hasViewLencoPayAccess),
            Parsers.boolean(obj.hasViewTerminalAccess),
            Parsers.boolean(obj.hasManageTerminalAccess)
        );
    }

    get name(): string {
        return `${this.firstName} ${this.lastName}`.trim();
    }

    get nameAcronym(): string {
        return `${this.firstName.trim()[0]}`.toUpperCase();
    }

    get hasAuthApp(): boolean {
        return !!this.authApp && this.authApp.isSetup;
    }

    get hasTokenApp(): boolean {
        return this.hasAuthApp;
    }

    get hasMobileApp(): boolean {
        return !!this.mobileApp && this.mobileApp.isSetup;
    }
}
