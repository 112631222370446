import Parsers from "../utils/parsers";
import { CreateAccountRequestStatus, CreateAccountRequestType } from "./createAccountRequest.constants";
import UserMin from "./userMin";
import { GenericObject } from "../helpers/types";
import UserAccount from "./userAccount";
import { immerable } from "immer";

export default class CreateAccountRequest {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string,
        public fullAccountName: string,
        public description: string | null,
        public type: CreateAccountRequestType,
        public status: CreateAccountRequestStatus,
        public requestedBy: UserMin | null,
        public requestedOn: Date | null,
        public createdAccount: UserAccount | null
    ) {}

    static create(obj: GenericObject): CreateAccountRequest {
        return new CreateAccountRequest(
            Parsers.string(obj.id),
            Parsers.string(obj.name),
            Parsers.string(obj.fullAccountName),
            Parsers.nullableString(obj.description),
            Parsers.number(obj.type),
            Parsers.number(obj.status),
            Parsers.classObject(obj.requestedBy, UserMin),
            Parsers.date(obj.requestedOn),
            Parsers.classObject(obj.createdAccount, UserAccount)
        );
    }

    update(createAccountRequest: CreateAccountRequest): void {
        // todo -> might ignore some, if the data is not complete (i.e the object is not full)
        this.id = createAccountRequest.id;
        this.name = createAccountRequest.name;
        this.fullAccountName = createAccountRequest.fullAccountName;
        this.description = createAccountRequest.description;
        this.type = createAccountRequest.type;
        this.status = createAccountRequest.status;
        this.requestedBy = createAccountRequest.requestedBy;
        this.requestedOn = createAccountRequest.requestedOn;
        this.createdAccount = createAccountRequest.createdAccount;
    }

    get isPending(): boolean {
        return this.status === CreateAccountRequestStatus.PENDING;
    }

    get isCreated(): boolean {
        return this.status === CreateAccountRequestStatus.CREATED;
    }
}
