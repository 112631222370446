import useResetForgotPasswordState, { ResetPasswordState } from "../hookss/statee/useResetForgotPasswordState";

import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import LencoSpinner from "../../../../components/spinner";
import { PageTitle } from "../../../../helpers/AppConstants";
import ResetPasswordCompleted from "../componentss/ResetPasswordCompleted";
import ResetPasswordEnterOtpForm from "../componentss/ResetPasswordEnterOtpForm";
import ResetPasswordSelectOtpRecipientForm from "../componentss/ResetPasswordSelectOtpRecipientForm";
import ResetPasswordSetNewPasswordForm from "../componentss/ResetPasswordSetNewPasswordForm";

function ResetForgotPassword(): JSX.Element {
    const {
        resetPasswordState,
        baseParams,
        otpRecipients,
        selectedOtpRecipient,
        verifyLinkParams,
        sendOtpParams,
        verifyOtpParams,
        setNewPasswordParams,
    } = useResetForgotPasswordState();

    document.title = PageTitle.LOGIN_PAGE;

    return (
        <>
            <div className="w-full">
                {resetPasswordState === ResetPasswordState.INITIALIZING && (
                    <div className="flex h-full w-full items-center justify-center">
                        <LencoSpinner />
                    </div>
                )}

                {resetPasswordState === ResetPasswordState.INIT_FAILED && (
                    <div className="flex h-full w-full items-center justify-center">
                        <ErrorToast error={verifyLinkParams.errorMessage || "An Error Occurred"} />
                    </div>
                )}

                {resetPasswordState === ResetPasswordState.SELECT_OTP_RECIPIENT && (
                    <ResetPasswordSelectOtpRecipientForm baseParams={baseParams} params={sendOtpParams} otpRecipients={otpRecipients} />
                )}

                {resetPasswordState === ResetPasswordState.ENTER_OTP && (
                    <ResetPasswordEnterOtpForm baseParams={baseParams} params={verifyOtpParams} recipient={selectedOtpRecipient} />
                )}

                {resetPasswordState === ResetPasswordState.ENTER_PASSWORD && (
                    <ResetPasswordSetNewPasswordForm baseParams={baseParams} params={setNewPasswordParams} />
                )}

                {resetPasswordState === ResetPasswordState.COMPLETED && <ResetPasswordCompleted />}
            </div>
        </>
    );
}
export default ResetForgotPassword;
