import { makeRequest } from "../../../../../../helpers/request/makeRequest";
import Parsers from "../../../../../../utils/parsers";
import { GenerateQrCodeResponse, TestCodeResponse } from "./token-app.types";
import UserApp from "../../../../../../models/userApp";

export async function generateQrCode(): Promise<GenerateQrCodeResponse> {
    const res = await makeRequest("/main/settings/security/token/setup/generate-qr-code");
    if (res instanceof Error) {
        throw res;
    }
    return {
        qrCode: Parsers.string(res.qrCode),
        shortCode: Parsers.string(res.shortCode),
    };
}

export async function isQrCodeScanned(): Promise<boolean> {
    const res = await makeRequest("/main/settings/security/token/setup/is-scanned");
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.boolean(res.isScanned);
}

export async function testCode(code: string): Promise<TestCodeResponse> {
    const res = await makeRequest("/main/settings/security/token/setup/test-code", { code });
    if (res instanceof Error) {
        throw res;
    }
    return {
        authApp: Parsers.classObjectNonNullable(res.authApp, UserApp),
    };
}

export async function unlinkTokenApp(): Promise<boolean> {
    const res = await makeRequest("/main/settings/security/token/unlink-device");
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.boolean(res.success);
}
