import LencoSpinner from "../../../spinner";
import OtpTimer from "./components/otp-timer";

interface Props {
    isLoading: boolean;
    resendMins?: 1 | 3;
    handleResend: () => void;
}

function ResendOtp(props: Props) {
    return (
        <div className="mx-auto flex h-6 w-full items-center justify-start">
            {props.handleResend && (
                <>{props.isLoading ? <LencoSpinner size="sm" /> : <OtpTimer handleResend={props.handleResend} resendMins={props.resendMins} />}</>
            )}
        </div>
    );
}

export default ResendOtp;
