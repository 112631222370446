import * as Yup from "yup";

import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../../../element/hint/hint.constant";
import { useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../../../services/zambia-application/registered/new/company.types";
import { CurrentSignatoryPersonnelRequest } from "../../../../../../../services/zambia-application/registered/new/currentSignatory.types";
import Hint from "../../../../../../element/hint";
import RadioCard from "../../../../../../../../../../components/radio-card";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import isNullOrUndefined from "../../../../../../../../../../utils/isNullOrUndefined";
import { useAppSelector } from "../../../../../../../../../../redux/hooks";
import useIsDirector from "../../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useIsDirector";

export const SignatoryPersonnelHints: CompanyHint[] = [
    {
        index: 0,
        span: <span>All the business directors details are required</span>,
    },
];
interface LocationState {
    from?: string;
}

function Personnel(): JSX.Element {
    const navigate = useNavigate();

    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isDirectorLoading, handleReset, handleIsDirectorInit } = useIsDirector();

    const accountOpening = useAppSelector((state) => state.zambiaApplication.init);
    const currentSignatory = useAppSelector((state) => state.zambiaApplication.currentSignatory);

    const INITIAL_FORM_STATE: CurrentSignatoryPersonnelRequest = {
        isDirector: currentSignatory && !isNullOrUndefined(currentSignatory?.isDirector) ? currentSignatory.isDirector : null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        isDirector: Yup.boolean().nullable(),
    });

    useLayoutEffect(() => {
        if (accountOpening && !accountOpening.isCreator) {
            navigate(Routes.ACCOUNT_OPENING.STATUS);
        }
    }, [accountOpening]);

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    const handleBack = () => {
        navigate(locationState?.from || Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT);
    };

    return (
        <>
            <div className="flex w-full flex-row items-start justify-start space-x-6">
                {accountOpening && (
                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE,
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values) => {
                                void handleIsDirectorInit(values);
                            }}
                            enableReinitialize
                            validateOnMount
                            validateOnBlur
                        >
                            {(formik) => {
                                return (
                                    <Form className="w-full">
                                        <ApplicationHeader
                                            header="Are you a Director in this business?"
                                            subTitle="Indicate if you are a director in this business"
                                            progress={accountOpening.progress.signatoriesCompletedProgress()}
                                        >
                                            <div className="flex flex-col items-start space-y-8 text-base font-normal md:items-center md:px-0">
                                                <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                                                    <div className="h-full w-full">
                                                        <RadioCard
                                                            title="Yes, I am Director/Signatory"
                                                            subtitle="Select this if you are a director in the business and your details are in the PACRA documents"
                                                            checked={!!formik.values.isDirector}
                                                            func={() => {
                                                                if (formik.values.isDirector) {
                                                                    void formik.getFieldHelpers("isDirector").setValue(null);
                                                                } else {
                                                                    void formik.getFieldHelpers("isDirector").setValue(true);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="h-full w-full">
                                                        <RadioCard
                                                            title="No, I am not"
                                                            subtitle="Select this if you are only completing this application on behalf of the directors and signatories"
                                                            checked={formik.values.isDirector === false}
                                                            func={() => {
                                                                if (formik.values.isDirector === false) {
                                                                    void formik.getFieldHelpers("isDirector").setValue(null);
                                                                } else {
                                                                    void formik.getFieldHelpers("isDirector").setValue(false);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </ApplicationHeader>
                                        <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                            <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                <ButtonComp
                                                    type="button"
                                                    color="grey"
                                                    ripple="light"
                                                    buttonType="secondary"
                                                    func={handleBack}
                                                    fullWidth
                                                >
                                                    <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                                </ButtonComp>
                                            </div>
                                            <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                <ButtonComp
                                                    type={formik.dirty ? "submit" : "button"}
                                                    color="black"
                                                    ripple="light"
                                                    buttonType="primary"
                                                    isLoading={isDirectorLoading}
                                                    func={() => {
                                                        !formik.dirty
                                                            ? navigate(
                                                                  {
                                                                      pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.DETAILS,
                                                                  },
                                                                  {
                                                                      state: {
                                                                          from: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL,
                                                                      },
                                                                  }
                                                              )
                                                            : undefined;
                                                    }}
                                                    disable={
                                                        !!(
                                                            (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW && !formik.dirty) ||
                                                            isNullOrUndefined(formik.values.isDirector)
                                                        )
                                                    }
                                                    fullWidth
                                                >
                                                    {locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? "Save changes" : "Continue"}
                                                </ButtonComp>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                )}
                <Hint hintType={HintType.GUIDE} listStyle={ListStyle.PLAIN} firstList={SignatoryPersonnelHints} />
            </div>
        </>
    );
}

export default Personnel;
