import * as Yup from "yup";

import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../../element/hint/hint.constant";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../../services/nigeria-application/registered/company.types";
import CurrencyCode from "../../../../../../../../../components/currency-code";
import Hint from "../../../../../element/hint";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import RadioCard from "../../../../../../../../../components/radio-card";
import { RefereeChoiceForm } from "../../../../../../services/nigeria-application/registered/referee.types";
import { Routes } from "../../../../../../../../../routes/routes.constants";
import useAccountType from "../../../../../../Hooks/nigeria-application/registered-business/invite-referee/useAccountType";
import { useSelector } from "react-redux";

export const RefereeChoiceHints: CompanyHint[] = [
    {
        index: 0,
        span: <span>Two (2) referees are required.</span>,
    },
    {
        index: 0,
        span: <span>The referees must be corporate account holders not individuals.</span>,
    },
    {
        index: 0,
        span: <span>A referee{"'"}s account must not be less than 6 months old.</span>,
    },
];

type LocationState = {
    from?: string;
};

function RefereesChoice(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isAccountTypeLoading, handleRest, handleAccountType } = useAccountType();

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const currency = useSelector((state: IRootState) => state.init.main?.companyDetails?.accounts[0]?.bankAccount?.currency);

    const INITIAL_FORM_STATE: RefereeChoiceForm = {
        isRefereesAvailable: accountOpening?.applicationMin.requestedProAccount === false,
        isRefereesNotAvailable: accountOpening?.applicationMin.requestedProAccount || false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        isRefereesAvailable: Yup.boolean(),
        isRefereesNotAvailable: Yup.boolean(),
    });

    useEffect(() => {
        return () => {
            handleRest();
        };
    }, []);

    const handleBack = useCallback(() => {
        navigate(locationState?.from || (accountOpening?.isCreator ? Routes.ACCOUNT_OPENING.REGISTERED.AGREEMENT : Routes.ACCOUNT_OPENING.STATUS));
    }, [location, accountOpening]);

    return (
        <>
            <div className="flex w-full flex-row items-start justify-start space-x-6">
                {accountOpening && (
                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE,
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values) => {
                                void handleAccountType({
                                    requestedProAccount: values.isRefereesNotAvailable,
                                });
                            }}
                            validateOnMount
                            validateOnBlur
                        >
                            {(formik) => {
                                return (
                                    <Form className="w-full">
                                        <ApplicationHeader
                                            header="Do you have Referees?"
                                            subTitle="Please inform your referees to look out for a reference letter from Lenco"
                                            progress={accountOpening.progress.refereesCompletedProgress()}
                                        >
                                            <div className="flex w-full flex-col items-start space-y-8 text-base font-normal md:items-center md:px-0">
                                                <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                    <div className="w-full md:w-50%">
                                                        <RadioCard
                                                            title="Yes, I have Referees"
                                                            subtitle={
                                                                <div className="flex flex-col space-y-1">
                                                                    <span>You&apos;ll get a Prime account with:</span>
                                                                    <span>
                                                                        <CurrencyCode currency={currency} />
                                                                        <span className="font-medium">100M</span> Daily transaction limit
                                                                    </span>
                                                                </div>
                                                            }
                                                            checked={formik.values.isRefereesAvailable}
                                                            func={() => {
                                                                if (formik.values.isRefereesNotAvailable) {
                                                                    void formik.getFieldHelpers("isRefereesNotAvailable").setValue(false);
                                                                }
                                                                void formik
                                                                    .getFieldHelpers("isRefereesAvailable")
                                                                    .setValue(!formik.values.isRefereesAvailable);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="w-full md:w-50%">
                                                        <RadioCard
                                                            title="No, I do not"
                                                            subtitle={
                                                                <div className="flex flex-col space-y-1">
                                                                    <span>You&apos;ll get a Pro account with:</span>
                                                                    <span>
                                                                        <CurrencyCode currency={currency} />
                                                                        <span className="font-medium">10M</span> Daily transaction limit
                                                                    </span>
                                                                </div>
                                                            }
                                                            checked={formik.values.isRefereesNotAvailable}
                                                            func={() => {
                                                                if (formik.values.isRefereesAvailable) {
                                                                    void formik.getFieldHelpers("isRefereesAvailable").setValue(false);
                                                                }
                                                                void formik
                                                                    .getFieldHelpers("isRefereesNotAvailable")
                                                                    .setValue(!formik.values.isRefereesNotAvailable);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </ApplicationHeader>
                                        <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                            <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                <ButtonComp
                                                    type="button"
                                                    color="grey"
                                                    ripple="light"
                                                    buttonType="secondary"
                                                    func={handleBack}
                                                    fullWidth
                                                >
                                                    <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                                </ButtonComp>
                                            </div>
                                            <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                <ButtonComp
                                                    type={formik.dirty ? "submit" : "button"}
                                                    color="black"
                                                    ripple="light"
                                                    buttonType="primary"
                                                    isLoading={isAccountTypeLoading}
                                                    func={() => {
                                                        !formik.dirty
                                                            ? navigate(
                                                                  {
                                                                      pathname: accountOpening?.applicationMin.requestedProAccount
                                                                          ? Routes.ACCOUNT_OPENING.REVIEW
                                                                          : Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.INVITE,
                                                                  },
                                                                  {
                                                                      state: {
                                                                          from: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS,
                                                                          beforeFrom:
                                                                              locationState?.from === Routes.ACCOUNT_OPENING.REVIEW
                                                                                  ? Routes.ACCOUNT_OPENING.REVIEW
                                                                                  : undefined,
                                                                      },
                                                                  }
                                                              )
                                                            : undefined;
                                                    }}
                                                    disable={
                                                        !!(
                                                            (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW &&
                                                                !formik.dirty &&
                                                                accountOpening?.applicationMin.requestedProAccount) ||
                                                            (!formik.values.isRefereesAvailable && !formik.values.isRefereesNotAvailable)
                                                        )
                                                    }
                                                    fullWidth
                                                >
                                                    <span>Continue</span>
                                                </ButtonComp>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                )}
                {accountOpening?.isCreator && <Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={RefereeChoiceHints} />}
            </div>
        </>
    );
}

export default RefereesChoice;
