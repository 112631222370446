import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { DeactivateCardRequest } from "../../Services/cardsApi.types";
import Modal from "../../../../../components/modal/Modal";
import ModalHeader from "../../../../../components/modal/modal-header";
import ModalBody from "../../../../../components/modal/modal-body";
import MessageToasts from "../../../../../components/general/MessageToasts/MessageToasts";
import ModalFooter from "../../../../../components/modal/modal-footer";
import { ToastType } from "../../../../../helpers/AppConstants";
import useDeactivateCard from "../../Hooks/State/useDeactivateCard";
import ButtonComp from "../../../../../components/button/ButtonComp";
import FormInput from "../../../../../components/inputs/FormInput";

function DeactivateCardModal(): ReactElement {
    // const dispatch = useDispatch();

    const { deactivateCardError, isDeactivateCardDone, isDeactivateCardLoading, handleReset, handleDeactivateCard } = useDeactivateCard();

    // const cardIdToDeactivate = useSelector((state: IRootState) => state.cards.cardIdToDeactivate);

    const formikRef = useRef<FormikProps<DeactivateCardRequest> | null>(null);

    const initialFormState: DeactivateCardRequest = {
        createRequestId: "",
        reason: "",
    };

    const formValidation = Yup.object().shape({
        cardId: Yup.string().required("Required"),
        reason: Yup.string(),
    });

    useEffect(() => {
        if (!isDeactivateCardDone) return;
        // dispatch(resetCardIdToDeactivate());
        handleReset();
    }, [handleReset, isDeactivateCardDone]);

    const handleToggle = useCallback(() => {
        // dispatch(resetCardIdToDeactivate());
        handleReset();
    }, [handleReset]);

    return (
        <>
            <Modal size="md" active={!!false} toggler={handleToggle} dataType="card">
                <ModalHeader onClose={handleToggle} dataType="card">
                    Deactivate Card
                </ModalHeader>
                <Formik
                    initialValues={initialFormState}
                    innerRef={formikRef}
                    validationSchema={formValidation}
                    onSubmit={(values) => void handleDeactivateCard(values)}
                    enableReinitialize={true}
                    validateOnChange
                    validateOnMount
                >
                    {/* data-type="card" */}
                    {/* {({errors}) => { */}
                    {() => {
                        return (
                            <Form className="w-full" data-type="card">
                                <ModalBody dataType="card">
                                    <div className="flex w-full flex-col items-center justify-center" data-type="card">
                                        {!!deactivateCardError && (
                                            <div className="w-full pb-4" data-type="card">
                                                <MessageToasts
                                                    toastMessage={deactivateCardError || ""}
                                                    toastType={ToastType.ERROR}
                                                    data-type="card"
                                                />
                                            </div>
                                        )}
                                        <div className="flex w-full flex-col items-start justify-start pb-4 text-sm font-normal" data-type="card">
                                            <div className="flex w-full flex-col items-start text-start" data-type="card">
                                                <span className="text-left text-base font-normal" data-type="card">
                                                    Are you sure you want to deactivate this card?{" "}
                                                    {/* <span className="break-words max-w-xs m-auto">
														{teamMemberToRemoveCreateAccess?.name}&apos;s access ?
													</span> */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-full" data-type="card">
                                            <FormInput type="text" placeholder="Reason(optional)" name="reason" isActive={!!false} dataType="card" />
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter dataType="card">
                                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4" data-type="card">
                                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0" data-type="card">
                                            <ButtonComp
                                                type="button"
                                                ripple="light"
                                                buttonType="secondary"
                                                color="grey"
                                                func={handleToggle}
                                                fullWidth
                                                dataType="card"
                                            >
                                                <span data-type="card">Cancel</span>
                                            </ButtonComp>
                                        </div>
                                        <div className="order-1 w-full 2xs:order-2 2xs:w-max" data-type="card">
                                            <ButtonComp
                                                type="submit"
                                                color="red"
                                                ripple="light"
                                                buttonType="primary"
                                                // disable={formikHasError(errors)}
                                                isLoading={isDeactivateCardLoading}
                                                fullWidth
                                                dataType="card"
                                            >
                                                <span data-type="card">Deactivate Card</span>
                                            </ButtonComp>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}

export default DeactivateCardModal;
