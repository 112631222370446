export enum AccountOpeningStatus {
    IN_PROGRESS = 0,
    REVIEWING = 1,
    CREATING = 2,
    CREATED = 3,
}

export enum ZambiaAccountOpeningStatus {
    ONGOING = 1,
    UNDER_REVIEW = 2,
    CREATED = 3,
    DELETED = 4,
}

export enum OtherDirectorStatus {
    INVITE_PENDING = 1,
    COMPLETED = 2,
    REMOVED = 3,
}

export enum BusinessCategory {
    LLC = 1,
    SOLE_PROPRIETORSHIP = 2,
    PARTNERSHIP = 3,
}

export enum ProofOfResidenceType {
    UTILITY_BILL = 1,
    BANK_STATEMENT = 2,
}

export enum BusinessRegistrationCategory {
    LLC = 1,
    BUSINESS_NAME = 2,
    NGO = 3,
}

export enum SignatoryStatus {
    INVITE_PENDING = 1,
    SIGNED_UP = 2,
    REMOVED = 3,
}

export enum IncorporationDocumentType {
    INCORPORATION_CERTIFICATE = 1,
    MEMORANDUM_OF_ASSOCIATION = 2,
    ZAMBIA__FORM_3 = 102,
    ZAMBIA__PACRA_PRINT_OUT = 104,
    ZAMBIA__ASSOCIATION_CONSTITUTION = 106,
    ZAMBIA__OPERATING_LICENSE = 107,
}
