import {
    ExportZambiaCollectionsTransactionsRequest,
    ExportZambiaCollectionsTransactionsResponse,
} from "../../Services/Collections/zambiaCollectionsTransactionsApi.types";
import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { exportZambiaCollectionsTransactions } from "../../Services/Collections/zambiaCollectionsTransactionsApi";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseExportZambiaCollectionsTransactionsInterface {
    isExportZambiaCollectionsTransactionsLoading: boolean;
    exportZambiaCollectionsTransactionsErrorMessage: string;
    handleExportZambiaCollectionsTransactions: (_data: ExportZambiaCollectionsTransactionsRequest) => void;
}

interface Props {
    onComplete: (_data: ExportZambiaCollectionsTransactionsResponse) => void;
}

function useExportZambiaCollectionsTransactions(props: Props): UseExportZambiaCollectionsTransactionsInterface {
    const dispatch = useDispatch();

    const [isExportZambiaCollectionsTransactionsLoading, setIsExportZambiaCollectionsTransactionsLoading] = useState(false);
    const [exportZambiaCollectionsTransactionsErrorMessage, setExportZambiaCollectionsTransactionsErrorMessage] = useState<string>("");

    const handleExportZambiaCollectionsTransactions = useCallback(
        async (_data: ExportZambiaCollectionsTransactionsRequest) => {
            try {
                setIsExportZambiaCollectionsTransactionsLoading(true);
                setExportZambiaCollectionsTransactionsErrorMessage("");
                const res = await exportZambiaCollectionsTransactions(_data);
                dispatch(messageTrue("Statement Requested Successful"));
                props.onComplete(res);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setExportZambiaCollectionsTransactionsErrorMessage(errorMessage);
            } finally {
                setIsExportZambiaCollectionsTransactionsLoading(false);
            }
        },
        [dispatch]
    );
    return {
        isExportZambiaCollectionsTransactionsLoading,
        exportZambiaCollectionsTransactionsErrorMessage,
        handleExportZambiaCollectionsTransactions,
    };
}

export default useExportZambiaCollectionsTransactions;
