import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import BillProduct from "../../../../models/billProduct";
import { BillPurchaseInitResponse } from "../../../../modules/dashboard/payments-new/services/billPayment/billPaymentApi.types";
import BillVendor from "../../../../models/billVendor";
import BillVendorMin from "../../../../models/billVendorMin";
import { CableTVPurchaseState } from "./cableTVPurchaseSlice.types";
import UserAccount from "../../../../models/userAccount";

const initialState: CableTVPurchaseState = {
    cableTVPurchaseDetails: {
        debitAccountId: "",
        productId: "",
        accountId: "",
        phone: "",
        amount: "",
        otp: "",
        bioAuth: "",
        contactName: "",
    },
    smartCardNumber: "",
    selectedVendorId: null,
    selectedVendorName: null,
    selectedUserAccount: null,
    selectedProductName: null,
    cableTVPurchaseResponse: null,
    selectedProductFixedAmount: null,
    cableTVVerifiedAccountName: null,
};

/* Since the accountId and phone number require the same data no need for a set phone number reducer */

export const cableTVPurchaseSlice = createSlice({
    name: "cableTVPurchase",
    initialState,
    reducers: {
        setCableTVPurchaseDebitAccountId: (state: CableTVPurchaseState, action: PayloadAction<string>) => {
            state.cableTVPurchaseDetails.debitAccountId = action.payload;
        },
        setCableTVPurchaseSelectedUserAccount: (state: CableTVPurchaseState, action: PayloadAction<UserAccount>) => {
            state.selectedUserAccount = action.payload;
        },
        setCableTVPurchaseAccountId: (state: CableTVPurchaseState, action: PayloadAction<string>) => {
            state.cableTVPurchaseDetails.accountId = action.payload;
            state.cableTVPurchaseDetails.phone = action.payload;
        },
        setCableTVPurchaseVendorId: (state: CableTVPurchaseState, action: PayloadAction<BillVendor | BillVendorMin>) => {
            state.selectedVendorName = action.payload.name;
            state.selectedVendorId = action.payload.id;
        },
        setCableTVVerifiedAccountName: (state: CableTVPurchaseState, action: PayloadAction<string>) => {
            state.cableTVVerifiedAccountName = action.payload;
        },
        setCableTVPurchaseProductId: (state: CableTVPurchaseState, action: PayloadAction<BillProduct>) => {
            state.cableTVPurchaseDetails.productId = action.payload.id;
            state.cableTVPurchaseDetails.amount = String(action.payload.fixedAmount);
            state.selectedProductName = action.payload.name;
            state.selectedProductFixedAmount = action.payload.fixedAmount;
        },
        setCableTVPurchaseAmount: (state: CableTVPurchaseState, action: PayloadAction<string>) => {
            state.cableTVPurchaseDetails.amount = action.payload;
        },
        setCableTVPurchaseOtp: (state: CableTVPurchaseState, action: PayloadAction<string>) => {
            state.cableTVPurchaseDetails.otp = action.payload;
        },
        setCableTVPurchaseResponse: (state: CableTVPurchaseState, action: PayloadAction<BillPurchaseInitResponse>) => {
            state.cableTVPurchaseResponse = action.payload;
        },
        setCableTVPurchaseSmartCardNumber: (state: CableTVPurchaseState, action: PayloadAction<string>) => {
            state.smartCardNumber = action.payload;
        },
        resetSmartCardNumber: (state: CableTVPurchaseState) => {
            state.smartCardNumber = "";
        },
        resetCableTVVerifiedAccountName: (state: CableTVPurchaseState) => {
            state.cableTVVerifiedAccountName = null;
        },
        resetCableTVPurchaseProductId: (state: CableTVPurchaseState) => {
            state.cableTVPurchaseDetails.productId = "";
            state.cableTVPurchaseDetails.amount = "";
            state.selectedProductName = null;
            state.selectedProductFixedAmount = null;
        },
        resetAllCableTVPurchaseData: (state: CableTVPurchaseState) => {
            state.smartCardNumber = "";
            state.selectedVendorId = null;
            state.selectedVendorName = null;
            state.selectedUserAccount = null;
            state.selectedProductName = null;
            state.cableTVPurchaseResponse = null;
            state.selectedProductFixedAmount = null;
            state.cableTVVerifiedAccountName = null;
            state.cableTVPurchaseDetails = {
                debitAccountId: "",
                productId: "",
                accountId: "",
                phone: "",
                amount: "",
                otp: "",
                bioAuth: "",
                contactName: "",
            };
        },
    },
});

export const {
    resetSmartCardNumber,
    setCableTVPurchaseOtp,
    setCableTVPurchaseAmount,
    setCableTVPurchaseResponse,
    setCableTVPurchaseVendorId,
    resetAllCableTVPurchaseData,
    setCableTVPurchaseAccountId,
    setCableTVPurchaseProductId,
    resetCableTVPurchaseProductId,
    setCableTVVerifiedAccountName,
    resetCableTVVerifiedAccountName,
    setCableTVPurchaseDebitAccountId,
    setCableTVPurchaseSmartCardNumber,
    setCableTVPurchaseSelectedUserAccount,
} = cableTVPurchaseSlice.actions;

export default cableTVPurchaseSlice.reducer;
