import { BasicStatusWithNone, MerchantSettlementType } from "./terminal.constants";

import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";

export default class Settlement {
    constructor(
        public id: string,
        public merchantSettlementType: MerchantSettlementType,
        public amount: string,
        public status: BasicStatusWithNone
    ) {}

    static create(obj: GenericObject): Settlement {
        return new Settlement(
            Parsers.string(obj.id),
            Parsers.number(obj.merchantSettlementType),
            Parsers.string(obj.amount),
            Parsers.number(obj.status)
        );
    }

    get isSuccessful(): boolean {
        return this.status === BasicStatusWithNone.SUCCESSFUL;
    }
    get isFailed(): boolean {
        return this.status === BasicStatusWithNone.FAILED;
    }
    get isPending(): boolean {
        return this.status === BasicStatusWithNone.PENDING;
    }
    get isNone(): boolean {
        return this.status === BasicStatusWithNone.NONE;
    }
    get getStatus(): string {
        return this.status === BasicStatusWithNone.SUCCESSFUL
            ? "Settled"
            : BasicStatusWithNone.PENDING
              ? "Pending"
              : BasicStatusWithNone.FAILED
                ? "Failed"
                : "None";
    }
}
