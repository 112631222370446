import { setPageFrom, setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import AccountNumberInput from "../../../../../../components/account-number-input";
import AccountNumberSelect from "../../selects/account-number-select";
import BankAccount from "../../../../../../models/bankAccount";
import BankSelect from "../../selects/bank-select";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import Card from "../../../../../../models/card";
import CustomerAccount from "../../../../../../models/customerAccount";
import Fee from "../../../../../../components/fees/fees";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import MoneyInput from "../../../../../../components/inputs/money-input";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { PaymentsType } from "../../../hooks/state/send-money/payments.constants";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import UserAccount from "../../../../../../models/userAccount";
import VerifiedAccountCard from "../../Cards/verified-account-card";
import { resetAllSingleTransferData } from "../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import titleCase from "../../../../../../hooks/titleCase";
import { useCallback } from "react";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";
import useSingleTransferAccountNumberAutocomplete from "../../../hooks/state/send-money/single-transfer/useSingleTransferAccountNumberAutocomplete";
import useSingleTransferAmount from "../../../hooks/state/send-money/single-transfer/useSingleTransferAmount";
import useSingleTransferPayFrom from "../../../hooks/state/send-money/single-transfer/useSingleTransferPayFrom";
import useSingleTransferPurpose from "../../../hooks/state/send-money/single-transfer/useSingleTransferPurpose";
import useSingleTransferRecipientBankCode from "../../../hooks/state/send-money/single-transfer/useSingleTransferRecipientBankCode";
import useSingleTransferVerifyRecipientAccountDetails from "../../../hooks/state/send-money/single-transfer/useSingleTransferVerifyRecipientAccountDetails";

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const selectedUserAccount = useSelector((state: IRootState) => state.singleTransfer.selectedUserAccount);
    const singleTransferDetails = useSelector((state: IRootState) => state.singleTransfer.singleTransferDetails);
    const selectedUserAccountMeta = useSelector((state: IRootState) => state.singleTransfer.selectedUserAccountMeta);

    const { handleAmountChange } = useSingleTransferAmount();
    const { handlePurposeChange } = useSingleTransferPurpose();
    const { handleSelectBank } = useSingleTransferRecipientBankCode();
    const { verifyAccountError, verifiedAccount, isVerifyAccountLoading, handleSetVerifiedAccount, handleResetVerifiedAccount } =
        useSingleTransferVerifyRecipientAccountDetails();
    const { isRecipientAutocompleteLoading, suggestedRecipients, handleSelectRecipient, handleAccountNumberChange } =
        useSingleTransferAccountNumberAutocomplete({ handleSetVerifiedAccount, handleResetVerifiedAccount });

    const { accounts, selectedAccountId, handleSelectAccount } = useSingleTransferPayFrom();

    const handleBack = useCallback(() => {
        dispatch(setPaymentStage(PaymentStageType.INITIAL));
        dispatch(resetAllSingleTransferData());
    }, []);
    // console.log(selectedUserAccountMeta);
    return (
        <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
            <SinglePaymentHeaders />
            <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                <div className="flex w-full flex-col space-y-4">
                    <p className="text-sm font-medium text-black-secondary">Pay from</p>
                    <NewUserAccountDropdown
                        value={selectedAccountId || ""}
                        options={accounts || null}
                        onClick={handleSelectAccount}
                        isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                        dropdownSize="2xl"
                        showBalance
                        initiatorCanSelect
                    />
                </div>

                <div className="flex w-full flex-col space-y-4">
                    <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                    <MoneyInput
                        label="Amount"
                        value={singleTransferDetails.amount || ""}
                        onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                        fee={
                            selectedUserAccount?.bankCode !== singleTransferDetails.recipient?.bankCode ? (
                                <div className="w-max text-xs text-black-secondary">
                                    Fee: <span className="font-bold">{<Fee value={singleTransferDetails.amount} />}</span>
                                </div>
                            ) : undefined
                        }
                        inputSize="xl"
                    />
                    <Input placeholder="Purpose" value={singleTransferDetails.purpose} onChange={handlePurposeChange} inputSize="xl" fullWidth />
                </div>

                {selectedUserAccountMeta?.maxApprovalAmount && !(selectedUserAccountMeta?.maxApprovalAmount === 0) && (
                    <MessageToast
                        message={
                            <p>
                                You can only send <Money amount={selectedUserAccountMeta?.maxApprovalAmount} /> or less on this account. Any amount
                                more than this will be initiated and need to be approved by an admin.
                            </p>
                        }
                        type="info"
                        fullWidth
                    />
                )}
                {useMoneyToNumber(singleTransferDetails.amount) > (selectedUserAccount?.balance as number) && (
                    <MessageToast
                        message={
                            <p>
                                You do not have enough funds. Your account balance is <Money amount={selectedUserAccount?.balance || 0} />
                            </p>
                        }
                        type="error"
                        fullWidth
                    />
                )}
                {selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
                    <MessageToast message="You can only send to specific recipients" type="info" fullWidth />
                )}

                {selectedUserAccount?.accountRestrictions.cannotSendMoney ? (
                    <MessageToast message="You can not send money from this account" type="error" fullWidth />
                ) : (
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Recipient Details</p>
                        {selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts ? (
                            <>
                                <AccountNumberSelect
                                    onClick={(e) => {
                                        if (e instanceof UserAccount || e instanceof CustomerAccount) {
                                            handleSelectRecipient(e.bankAccount as BankAccount);
                                        }
                                        if (e instanceof Card) {
                                            dispatch(setPageFrom(location.pathname));
                                            navigate(
                                                {
                                                    pathname: "/payments/make/single",
                                                    search: `?to=${e.id}`,
                                                },
                                                {
                                                    state: {
                                                        typeOfTransfer: PaymentsType.FUND_CARD,
                                                        from: selectedAccountId,
                                                        pageFrom: location.pathname,
                                                        to: e.id,
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                    options={suggestedRecipients}
                                    value={singleTransferDetails.recipient.accountNumber}
                                />
                                {!verifyAccountError && verifiedAccount && <VerifiedAccountCard value={titleCase(verifiedAccount.accountName)} />}
                            </>
                        ) : (
                            <>
                                <AccountNumberInput
                                    data={suggestedRecipients as UserAccount[] | null}
                                    label="Account Number or Name"
                                    onChange={handleAccountNumberChange}
                                    isLoading={isRecipientAutocompleteLoading}
                                    inputValue={singleTransferDetails.recipient.accountNumber}
                                    onClick={(e) => {
                                        if (e instanceof UserAccount || e instanceof CustomerAccount) {
                                            handleSelectRecipient(e.bankAccount as BankAccount);
                                        }
                                        if (e instanceof Card) {
                                            dispatch(setPageFrom(location.pathname));
                                            navigate(
                                                {
                                                    pathname: "/payments/make/single",
                                                    search: `?to=${e.id}`,
                                                },
                                                {
                                                    state: {
                                                        typeOfTransfer: PaymentsType.FUND_CARD,
                                                        from: selectedAccountId,
                                                        temp: true,
                                                        pageFrom: location.pathname,
                                                        to: e.id,
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                />
                                <BankSelect
                                    accountNumber={singleTransferDetails.recipient.accountNumber}
                                    bankCode={singleTransferDetails.recipient.bankCode}
                                    onClick={handleSelectBank}
                                />
                                {verifyAccountError && !verifiedAccount && (
                                    <MessageToast message={verifyAccountError.message} type="error" fullWidth />
                                )}
                                {!verifyAccountError && verifiedAccount && <VerifiedAccountCard value={titleCase(verifiedAccount.accountName)} />}
                            </>
                        )}
                    </div>
                )}
            </div>
            <div className="flex w-full flex-row items-center justify-center space-x-4">
                <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="xl" func={handleBack}>
                    Back
                </ButtonComp>

                <ButtonComp
                    color="black"
                    func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
                    isLoading={isVerifyAccountLoading}
                    buttonType="primary"
                    size="xl"
                    disable={
                        !!(
                            !selectedUserAccount ||
                            verifyAccountError ||
                            !verifiedAccount ||
                            selectedUserAccount.accountRestrictions.cannotSendMoney ||
                            !singleTransferDetails.purpose ||
                            !singleTransferDetails.amount ||
                            useMoneyToNumber(singleTransferDetails.amount) === 0 ||
                            useMoneyToNumber(singleTransferDetails.amount) > (selectedUserAccount?.balance as number)
                        )
                    }
                >
                    Review Payment
                </ButtonComp>
            </div>
        </div>
    );
}

export default PaymentDetails;
