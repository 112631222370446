import {
    CurrentSignatoryRequests,
    abortCurrentSignatoryRequest,
    identificationUpload,
} from "../../../../../services/zambia-application/registered/new/currentSignatory.api";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../../redux/rootReducer";
import { IdentificationRequest } from "../../../../../services/zambia-application/registered/new/currentSignatory.types";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { updateZambiaCurrentSignatory } from "../../../../../../../../redux/application/zambiaApplicationSlice";

interface useIdentityUploadComponentInterface {
    isSubmitIdentificationLoading: boolean | null;
    handleReset: () => void;
    handleSubmitIdentification: (data: IdentificationRequest) => Promise<void>;
}
interface Props {
    onComplete?: () => void;
}

const useIdentityUploadComponent = (props: Props): useIdentityUploadComponentInterface => {
    const dispatch = useDispatch();

    const accountOpening = useSelector((state: IRootState) => state.application.init);

    const [isSubmitIdentificationLoading, setIsSubmitIdentificationLoading] = useState<boolean | null>(false);

    const handleSubmitIdentification = useCallback(
        async (_data: IdentificationRequest) => {
            try {
                setIsSubmitIdentificationLoading(true);
                const res = await identificationUpload(_data);
                if (res.signatory) {
                    dispatch(updateZambiaCurrentSignatory(res));
                }
                dispatch(
                    messageTrue({
                        message: `${accountOpening?.options.idType?.find((_type) => _type.id === _data.type)?.name || ""} uploaded successfully`,
                    })
                );
                props.onComplete && props.onComplete();
            } catch (err) {
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitIdentificationLoading(false);
            }
        },
        [accountOpening]
    );

    const handleReset = useCallback(() => {
        setIsSubmitIdentificationLoading(false);
        abortCurrentSignatoryRequest(CurrentSignatoryRequests.IDENTIFICATION_UPLOAD);
    }, []);

    return {
        isSubmitIdentificationLoading,
        handleReset,
        handleSubmitIdentification,
    };
};
export default useIdentityUploadComponent;
