import { abortAllNigerianCompanyRequests, businessDetails } from "../../../../services/nigeria-application/registered/company.api";
import { resetBusinessDetails, setBusinessDetails } from "../../../../../../../redux/application/applicationSlice";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface useBusinessDetailsInitInterface {
    businessDetailsInitError: string | null;
    isBusinessDetailsInitLoading: boolean;

    handleReset: () => void;
    handleBusinessDetailsInit: () => Promise<void>;
}
const useBusinessDetailsInit = (): useBusinessDetailsInitInterface => {
    const dispatch = useDispatch();

    const [businessDetailsInitError, setBusinessDetailsInitError] = useState<string | null>(null);
    const [isBusinessDetailsInitLoading, setIsBusinessDetailsInitLoading] = useState<boolean>(false);

    const handleBusinessDetailsInit = useCallback(async (): Promise<void> => {
        try {
            setBusinessDetailsInitError(null);
            setIsBusinessDetailsInitLoading(true);
            const res = await businessDetails();
            dispatch(setBusinessDetails(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            setBusinessDetailsInitError(errorMessage);
            dispatch(errorTrue(errorMessage));
        } finally {
            setIsBusinessDetailsInitLoading(false);
        }
    }, [dispatch]);

    const handleReset = () => {
        setBusinessDetailsInitError(null);
        setIsBusinessDetailsInitLoading(false);
        dispatch(resetBusinessDetails());
        abortAllNigerianCompanyRequests();
    };

    return {
        businessDetailsInitError,
        isBusinessDetailsInitLoading,
        handleReset,
        handleBusinessDetailsInit,
    };
};
export default useBusinessDetailsInit;
