import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class TransferIn {
    [immerable] = true;

    private constructor(
        public sender: string | null,
        public description: string,
        public sessionId: string | null
    ) {}

    static create(obj: GenericObject): TransferIn {
        return new TransferIn(Parsers.nullableString(obj.sender), Parsers.string(obj.description), Parsers.nullableString(obj.sessionId));
    }
}
