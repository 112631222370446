import {
    resetAllBillPaymentData,
    setSelectedBillHistoryTransaction,
    setSelectedBillPurchase,
} from "../../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BillPurchase from "../../../../../../../models/billPurchase";
import { IRootState } from "../../../../../../../redux/rootReducer";
import { getTransactionDetails } from "../../../../componentss/Cards/BillPurchaseDetailCard/Services/transactionDetailApi";
import { setSingleTransaction } from "../../../../../../../redux/transaction/slice/transactionSlice";

interface UserBillPurchaseHistoryDetail {
    selectedBillPurchase: BillPurchase | null;
    canShowCard: boolean;
    handleCloseDetailsCard: () => void;
}

export default function useBillPurchaseDetailsCard(): UserBillPurchaseHistoryDetail {
    const dispatch = useDispatch();
    const selectedBillPurchase = useSelector((state: IRootState) => state.billPayment.selectedBillPurchase);
    const transactions = useSelector((state: IRootState) => state.transaction.transactions);

    const handleCloseDetailsCard = useCallback(() => {
        dispatch(setSelectedBillPurchase(null));
    }, []);

    const getSelectedTransaction = useCallback(async (transactionId: string): Promise<void> => {
        const selectedTransaction = await getTransactionDetails(transactionId);
        dispatch(setSelectedBillHistoryTransaction(selectedTransaction));
        dispatch(setSingleTransaction(selectedTransaction));
    }, []);

    useEffect(() => {
        if (selectedBillPurchase) {
            const transacton = transactions.get(selectedBillPurchase.transactionId);
            if (transacton) {
                dispatch(setSelectedBillHistoryTransaction(transacton));
            } else {
                dispatch(setSelectedBillHistoryTransaction(null));
                void getSelectedTransaction(selectedBillPurchase.transactionId);
            }
        }
    }, [selectedBillPurchase, transactions]);

    useEffect(() => {
        return () => {
            dispatch(resetAllBillPaymentData());
        };
    }, []);

    return { selectedBillPurchase, canShowCard: !!selectedBillPurchase, handleCloseDetailsCard };
}
