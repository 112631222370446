import { AccountBalanceAlertType, AccountBalanceInstructionType } from "../../../../../../models/userAccount.constants";
import { Dispatch, SetStateAction, useCallback, useLayoutEffect, useState } from "react";
import { errorTrue, messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import {
    setIsBalanceInstructionTray,
    setIsEditExcessBalance,
    setIsEditMinimumBalance,
} from "../../../../../../redux/settings/security/settingsSecuritySlice";

import AccountBalanceInstruction from "../../../../../../models/accountBalanceInstruction";
import UserAccount from "../../../../../../models/userAccount";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { mainUpdateUserAccountCallback } from "../../../../../../redux/init/slice/initSlice";
import { updateAccountBalanceInstructions } from "../account-settings.api";
import { useDispatch } from "react-redux";

export interface UseBalanceAlertInstructionResponse {
    isEditMode: boolean;
    setIsEditMode: Dispatch<SetStateAction<boolean>>;

    minBalanceInstruction: AccountBalanceInstruction | undefined;
    excessBalanceInstruction: AccountBalanceInstruction | undefined;

    // isRemoving: boolean;
    instruction: AccountBalanceInstruction | undefined;

    handleUpdateBalanceInstruction: (amount: number | null, setIsSubmitting: Dispatch<SetStateAction<boolean>>) => Promise<void>;
    handleRemoveInstruction: (_type: AccountBalanceAlertType, _setIsSubmitting: Dispatch<SetStateAction<boolean>>) => void;
}

function useBalanceAlertInstruction(account: UserAccount, type: AccountBalanceAlertType): UseBalanceAlertInstructionResponse {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [instruction, setInstruction] = useState<AccountBalanceInstruction | undefined>(undefined);

    const [minBalanceInstruction, setMinBalanceInstruction] = useState<AccountBalanceInstruction | undefined>(undefined);
    const [excessBalanceInstruction, setExcessBalanceInstruction] = useState<AccountBalanceInstruction | undefined>(undefined);

    const handleUpdateBalanceInstruction = useCallback(
        async (amount: number | null, setIsSubmitting: Dispatch<SetStateAction<boolean>>, _type?: AccountBalanceAlertType) => {
            try {
                setIsSubmitting(true);
                const res = await updateAccountBalanceInstructions(account.id, {
                    amount: amount,
                    type: _type || type,
                    ratio: null,
                });
                dispatch(
                    mainUpdateUserAccountCallback({
                        id: res.userAccountId,
                        callback: (userAccount: UserAccount) => {
                            userAccount.accountRestrictions = res.restrictions;
                            userAccount.balanceInstructions = res.balanceInstructions;
                        },
                    })
                );
                setIsSubmitting(false);
                setIsEditMode(false);
                dispatch(setIsEditMinimumBalance(false));
                dispatch(setIsEditExcessBalance(false));
                dispatch(setIsBalanceInstructionTray(false));
                dispatch(
                    messageTrue(
                        `${type === AccountBalanceInstructionType.EXCESS_BALANCE_ALERT ? "Excess balance alert" : type === AccountBalanceInstructionType.LOW_BALANCE_ALERT ? "Low balance alert" : "Split inflow"}  updated`
                    )
                );
            } catch (err) {
                dispatch(errorTrue({ message: getErrorMessage(err) }));
                setIsSubmitting(false);
            }
        },
        [account, type]
    );

    useLayoutEffect(() => {
        setInstruction(account.getBalanceInstructionByType(type));
        setMinBalanceInstruction(account.getBalanceInstructionByType(AccountBalanceInstructionType.LOW_BALANCE_ALERT));
        setExcessBalanceInstruction(account.getBalanceInstructionByType(AccountBalanceInstructionType.EXCESS_BALANCE_ALERT));
    }, [account, type]);

    const handleRemoveInstruction = (_type: AccountBalanceAlertType, _setIsSubmitting: Dispatch<SetStateAction<boolean>>) => {
        void handleUpdateBalanceInstruction(null, _setIsSubmitting, _type);
    };

    return {
        isEditMode,
        setIsEditMode,

        instruction,

        minBalanceInstruction,
        excessBalanceInstruction,

        handleUpdateBalanceInstruction,
        handleRemoveInstruction,
    };
}

export default useBalanceAlertInstruction;
