import React, { useCallback, useEffect, useRef, useState } from "react";
import CardMain from "../../../../../../models/cardMain";
import { CardTransactionChannel } from "../../../../../../models/card.constants";
import { updateCardPaymentChannelDTO } from "../../../Services/cardsApi.types";
import useDebounce from "../../../../../../hooks/useDebounce";
import usePaymentChannel from "../../../Hooks/State/usePaymentChannel";
import ToggleSwitch from "../../../../../../components/general/ToggleSwitch/ToggleSwitch";
import ButtonComp from "../../../../../../components/button/ButtonComp";

interface ISetPaymentChannel {
    cardData: CardMain;
    isSelectedCardDetailsOpen: boolean;
    handleBack: () => void;
}

function SetPaymentChannel({ cardData, handleBack }: ISetPaymentChannel): JSX.Element {
    const { isPaymentChannelLoading, handleUpdatePaymentChannel } = usePaymentChannel();
    const debounceUpdatePaymentChannel = useCallback(useDebounce(handleUpdatePaymentChannel, 400), []);

    const divRef = useRef<HTMLDivElement | null>(null);

    const [selectedChannels, setSelectedChannels] = useState<CardTransactionChannel[]>(cardData.card?.allowedChannels || []);

    const defaultSelectedChannel = [...(cardData.card?.allowedChannels || [])].sort();

    useEffect(() => {
        divRef.current?.scrollIntoView();
    }, []);

    const handleRequest = useCallback((input: updateCardPaymentChannelDTO) => {
        void debounceUpdatePaymentChannel(input);
    }, []);

    return (
        <div className="mt-6 w-full space-y-4 pb-6" data-type="card-active" ref={divRef}>
            <div className="flex flex-col py-4" data-type="card-active">
                <h3 className="text-base font-medium leading-none text-black" data-type="card-active">
                    Set Payment Channels
                </h3>
                <p className="pt-2 text-sm font-normal leading-none text-black-secondary" data-type="card-active">
                    Set payment channels this card can be used
                </p>
            </div>

            <div className="w-full" data-type="card-active">
                <div className="flex w-full items-center justify-between border-b pb-4" data-type="card-active">
                    <div className="space-y-2" data-type="card-active">
                        <p className="text-sm font-normal text-black-secondary" data-type="card-active">
                            POS
                        </p>
                        <p className="text-xs font-normal text-black-tertiary" data-type="card-active">
                            Allow POS Terminal withdrawals
                        </p>
                    </div>
                    <ToggleSwitch
                        isActive={selectedChannels.includes(CardTransactionChannel.POS_TERMINAL)}
                        onToggle={() => {
                            setSelectedChannels((prev) =>
                                prev.includes(CardTransactionChannel.POS_TERMINAL)
                                    ? prev.filter((_) => _ !== CardTransactionChannel.POS_TERMINAL).sort()
                                    : [...prev, CardTransactionChannel.POS_TERMINAL].sort()
                            );
                        }}
                    />
                </div>
                <div className="flex w-full items-center justify-between border-b pb-4 pt-4" data-type="card-active">
                    <div className="space-y-2" data-type="card-active">
                        <p className="text-sm font-normal text-black-secondary" data-type="card-active">
                            ATM
                        </p>
                        <p className="text-xs font-normal text-black-tertiary" data-type="card-active">
                            Allow ATM withdrawals
                        </p>
                    </div>
                    <ToggleSwitch
                        isActive={selectedChannels.includes(CardTransactionChannel.ATM)}
                        onToggle={() => {
                            setSelectedChannels((prev) =>
                                prev.includes(CardTransactionChannel.ATM)
                                    ? prev.filter((_) => _ !== CardTransactionChannel.ATM).sort()
                                    : [...prev, CardTransactionChannel.ATM].sort()
                            );
                        }}
                    />
                </div>
                <div className="flex w-full items-center justify-between pt-4" data-type="card-active">
                    <div className="space-y-2" data-type="card-active">
                        <p className="text-sm font-normal text-black-secondary" data-type="card-active">
                            Online Payment
                        </p>
                        <p className="text-xs font-normal text-black-tertiary" data-type="card-active">
                            Allow online payment withdrawals
                        </p>
                    </div>
                    <ToggleSwitch
                        isActive={selectedChannels.includes?.(CardTransactionChannel.WEB)}
                        onToggle={() => {
                            setSelectedChannels((prev) =>
                                prev.includes(CardTransactionChannel.WEB)
                                    ? prev.filter((_) => _ !== CardTransactionChannel.WEB).sort()
                                    : [...prev, CardTransactionChannel.WEB].sort()
                            );
                        }}
                    />
                </div>
            </div>

            <div className="flex w-full flex-row items-center justify-center space-x-4 pt-6" data-type="card-active">
                <ButtonComp type="button" buttonType="secondary" color="grey" onClick={handleBack} dataType="card-active" size="md" fullWidth>
                    <span className="w-full text-center" data-type="card-active">
                        Cancel
                    </span>
                </ButtonComp>
                <ButtonComp
                    fullWidth
                    type="button"
                    buttonType="primary"
                    color="black"
                    disable={defaultSelectedChannel.every((v, i) => v === selectedChannels[i])}
                    isLoading={isPaymentChannelLoading}
                    onClick={() => {
                        handleRequest({
                            channels: selectedChannels,
                            createRequestId: cardData.createRequest.id,
                        });
                    }}
                    size="md"
                    dataType="card-active"
                >
                    <span className="w-full text-center" data-type="card-active">
                        Save Changes
                    </span>
                </ButtonComp>
            </div>
        </div>
    );
}
export default SetPaymentChannel;
