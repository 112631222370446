import { Accordion, AccordionItem, Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { menuItemCustomClasses, menuItemCustomOnClick } from "../../../../dropdown/dropdown.types";

import AvatarDetails from "../../../../avatar-details";
import BusinessDropdownItem from "./component/business-dropdown-item";
import { CompanyInterface } from "../../../../../helpers/types";
import { LencoActiveCountries } from "../../../../../redux/init/slice/initSlice.types";
import { ReactComponent as NewBusiness } from "../../../../../assets/svg/dashboard/new-business.svg";
import NigeriaFlag from "../../../../../assets/images/countries/nigeria.png";
import SearchBar from "../../../../search-bar";
import ZambiaFlag from "../../../../../assets/images/countries/zambia.png";
import { getButtonClass } from "../../../../button/button.constant";
import { useAppSelector } from "../../../../../redux/hooks";

interface Props {
    handleAddNewBusiness: () => void;
}

function BusinessDropdown(props: Props): JSX.Element {
    const account = useAppSelector((state) => state.init.account);
    const companyId = useAppSelector((state) => state.init.selectedCompanyId);
    const accounts = useAppSelector((state) => state.init.main?.companyDetails?.accounts);

    const companies = account?.companies;
    const mainAccount = accounts?.find((el) => el.isMain === true);
    const currentCompany = companies?.find((_) => _.id === companyId);

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [selectedCountry, setSelectedCountry] = useState<LencoActiveCountries | null>(currentCompany?.country || null);

    const [nigerianCompanies, setNigerianCompanies] = useState<CompanyInterface[]>(
        companies?.filter((_company) => _company.country === LencoActiveCountries.NIGERIA && _company.id !== companyId) || []
    );
    const [zambianCompanies, setZambianCompanies] = useState<CompanyInterface[]>(
        companies?.filter((_company) => _company.country === LencoActiveCountries.ZAMBIA && _company.id !== companyId) || []
    );

    useEffect(() => {
        if (!companies) return;
        if (searchTerm !== "") {
            const filteredData = companies.filter((item) => {
                return Object.values(item.name).join("").toLowerCase().includes(searchTerm.toLowerCase());
            });
            setNigerianCompanies(filteredData.filter((_company) => _company.country === LencoActiveCountries.NIGERIA && _company.id !== companyId));
            setZambianCompanies(filteredData.filter((_company) => _company.country === LencoActiveCountries.ZAMBIA && _company.id !== companyId));
        } else {
            setNigerianCompanies(companies?.filter((_company) => _company.country === LencoActiveCountries.NIGERIA && _company.id !== companyId));
            setZambianCompanies(companies?.filter((_company) => _company.country === LencoActiveCountries.ZAMBIA && _company.id !== companyId));
        }
    }, [searchTerm, companies]);

    return (
        <>
            {companies && currentCompany && (
                <Dropdown
                    classNames={{ content: "!p-0 overflow-hidden !rounded-lg w-full max-w-14 min-w-14" }}
                    onOpenChange={(_isOpen) => {
                        setIsOpen(_isOpen);
                        if (!_isOpen) {
                            setSelectedCountry(currentCompany?.country);
                        }
                    }}
                >
                    <DropdownTrigger>
                        <Button
                            size={undefined}
                            color={undefined}
                            radius="sm"
                            className={getButtonClass({ variant: "bordered", buttonColor: "grey", buttonSize: "xl", fullWidth: true })}
                            variant="bordered"
                        >
                            <div className="flex w-full items-center justify-between space-x-2">
                                <AvatarDetails
                                    title={currentCompany.name}
                                    subtitle={mainAccount ? mainAccount?.bankAccount?.accountNumber : undefined}
                                />
                                <div
                                    className={
                                        `!-mr-1 flex max-w-[20px] transform items-center justify-center overflow-hidden text-lg text-black-secondary transition-all ` +
                                        `${isOpen ? "rotate-0" : "rotate-180"} `
                                    }
                                >
                                    <span className="material-symbols-outlined text-xl">keyboard_arrow_up</span>
                                </div>
                            </div>
                        </Button>
                    </DropdownTrigger>

                    <DropdownMenu
                        variant="flat"
                        classNames={{
                            list: "!p-0 [&>li:not(:first-child)]:rounded-none overflow-hidden !gap-0",
                            emptyContent: "!p-0",
                            base: "!p-0",
                        }}
                        closeOnSelect={false}
                        emptyContent
                    >
                        {companies.length > 5 ? (
                            <DropdownItem textValue="Search" {...menuItemCustomClasses({ size: "sm", color: "none" })}>
                                <SearchBar
                                    placeholder="Search business name"
                                    size="sm"
                                    value={searchTerm}
                                    onChange={(_value) => setSearchTerm(_value)}
                                />
                            </DropdownItem>
                        ) : (
                            <DropdownItem textValue="" className="hidden"></DropdownItem>
                        )}

                        <DropdownItem key="countries" textValue="Countries" className="bg-white !p-0 hover:!bg-white">
                            <Accordion className="!px-0" keepContentMounted selectedKeys={[...[selectedCountry || ""]]} showDivider={false}>
                                {nigerianCompanies.length > 0 ? (
                                    <AccordionItem
                                        classNames={{
                                            heading: "bg-grey-backdrop px-4 !py-0.5 !h-7 [&>button]:!py-0 border-y border-grey-secondary",
                                            content:
                                                "[&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary overflow-y-auto max-h-48 !py-0",
                                            title: "!text-xs text-black-tertiary",
                                        }}
                                        textValue=""
                                        startContent={
                                            <img
                                                className="rounded-full border border-black-quat"
                                                src={NigeriaFlag}
                                                alt="Nigerian flag"
                                                width="16px"
                                                height="16px"
                                            />
                                        }
                                        key={LencoActiveCountries.NIGERIA}
                                        aria-label="Nigeria"
                                        title="Nigeria"
                                        onPress={() =>
                                            setSelectedCountry((prev) =>
                                                prev === LencoActiveCountries.NIGERIA ? null : LencoActiveCountries.NIGERIA
                                            )
                                        }
                                    >
                                        {nigerianCompanies.map((_item, index) => (
                                            <React.Fragment key={index}>
                                                <BusinessDropdownItem company={_item} />
                                            </React.Fragment>
                                        ))}
                                    </AccordionItem>
                                ) : (
                                    <AccordionItem className="hidden" textValue=""></AccordionItem>
                                )}

                                {zambianCompanies.length > 0 ? (
                                    <AccordionItem
                                        classNames={{
                                            heading: "bg-grey-backdrop px-4 !py-0.5 !h-7 [&>button]:!py-0 border-y border-grey-secondary",
                                            content:
                                                "[&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary overflow-y-auto max-h-48 !py-0",
                                            title: "!text-xs text-black-tertiary",
                                        }}
                                        textValue=""
                                        startContent={
                                            <img
                                                className="rounded-full border border-black-quat"
                                                src={ZambiaFlag}
                                                alt="Zambia flag"
                                                width="16px"
                                                height="16px"
                                            />
                                        }
                                        key={LencoActiveCountries.ZAMBIA}
                                        aria-label="Zambia"
                                        title="Zambia"
                                        onPress={() =>
                                            setSelectedCountry((prev) => (prev === LencoActiveCountries.ZAMBIA ? null : LencoActiveCountries.ZAMBIA))
                                        }
                                    >
                                        {zambianCompanies.map((_item, index) => (
                                            <React.Fragment key={index}>
                                                <BusinessDropdownItem company={_item} />
                                            </React.Fragment>
                                        ))}
                                    </AccordionItem>
                                ) : (
                                    <AccordionItem className="hidden" textValue=""></AccordionItem>
                                )}
                            </Accordion>
                        </DropdownItem>

                        <DropdownItem
                            key="add new business"
                            textValue="new business"
                            {...menuItemCustomClasses({ size: "md", color: "none" })}
                            {...menuItemCustomOnClick(() => props.handleAddNewBusiness())}
                        >
                            <div className="flex w-full items-center justify-start space-x-2">
                                <NewBusiness className="stroke-current ease-in-out" />
                                <div className="text-sm text-black-tertiary">Add a New Business</div>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            )}
        </>
    );
}

export default BusinessDropdown;
