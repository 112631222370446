import ButtonComp from "../../../../../../components/button/ButtonComp";
import React from "react";
interface Props {
    text: string;
    icon: React.ReactNode;
    canNotPreview?: boolean;
    handlePreviewDocumentFunc: () => void;
}

function PreviewCard(props: Props): JSX.Element {
    return (
        <div className="flex w-full flex-col items-center justify-center space-y-10">
            <div className="flex h-full w-full flex-col items-center justify-between space-y-4 rounded-lg border border-grey p-4 4xs:flex-row 4xs:space-y-0">
                <div className="flex flex-row items-center space-x-2 4xs:space-x-4">
                    <div className="flex h-full w-full max-w-[20px] items-center justify-center overflow-hidden">{props.icon}</div>
                    <span className="whitespace-nowrap text-sm text-black-tertiary 4xs:text-base">{props.text}</span>
                </div>
                <div className="flex flex-row justify-start space-x-2">
                    {props.canNotPreview ? (
                        "-"
                    ) : (
                        <ButtonComp color="blue" buttonType="flat" size="sm" func={props.handlePreviewDocumentFunc}>
                            <p>Preview</p>
                            <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden">
                                <span className="material-symbols-outlined icon-fill text-xl">visibility</span>
                            </div>
                        </ButtonComp>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PreviewCard;
