import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useRef } from "react";

import { ApiAccessSource } from "../../../../../../models/zambia/lencoPay/apiAccess.constant";
import { updateCollectionFeeSourceDefaultSetupConfiguration } from "../../../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import { useDispatch } from "react-redux";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import RadioButton from "../../../../../../components/radio-button";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { useAppSelector } from "../../../../../../redux/hooks";

const DATA_TYPE = "api-access-key-channels";

interface Props {
    active: boolean;
    toggler: () => void;
}

function DefaultSetupCollectionFeesTray(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const zambiaLencoPayDefaultSetup = useAppSelector((state) => state.zambiaDefaultSetup.defaultSetup);
    const formikRef = useRef<FormikProps<{ collectionFeeSource: ApiAccessSource | null }> | null>(null);

    const INITIAL_FORM_STATE = {
        collectionFeeSource: zambiaLencoPayDefaultSetup?.collectionFeeSource || null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        collectionFeeSource: Yup.mixed<ApiAccessSource>().oneOf(Object.values(ApiAccessSource)).required(),
    });

    const onSubmit = useCallback((values: { collectionFeeSource: ApiAccessSource | null }) => {
        if (values?.collectionFeeSource) {
            dispatch(updateCollectionFeeSourceDefaultSetupConfiguration(values?.collectionFeeSource));
            props.toggler();
        }
    }, []);

    return (
        <>
            <FullPageTray active={props.active} dataType={DATA_TYPE} handleReset={props.toggler} withClickOutside>
                <FullPageTrayHeader dataType={DATA_TYPE} subTitle="Select who pays the collection fees" toggler={props.toggler}>
                    Collection Fees
                </FullPageTrayHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={INITIAL_FORM_STATE}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={onSubmit}
                    enableReinitialize
                    validateOnMount
                    validateOnChange
                >
                    {(formik) => {
                        return (
                            <Form className="w-full">
                                <FullPageTrayBody dataType={DATA_TYPE}>
                                    <div className="flex w-full flex-col space-y-4">
                                        <div
                                            className={
                                                "flex min-h-24 cursor-pointer items-center justify-start space-x-4 rounded-lg border px-4 py-4 " +
                                                ` ${formik.values.collectionFeeSource === ApiAccessSource.MERCHANT && " !border-blue-secondary"} `
                                            }
                                            onClick={() => {
                                                formik
                                                    .getFieldHelpers("collectionFeeSource")
                                                    .setValue(
                                                        formik.values.collectionFeeSource === ApiAccessSource.MERCHANT
                                                            ? null
                                                            : ApiAccessSource.MERCHANT
                                                    );
                                            }}
                                        >
                                            <RadioButton
                                                id={ApiAccessSource.MERCHANT}
                                                checked={formik.values.collectionFeeSource === ApiAccessSource.MERCHANT}
                                            />
                                            <div>
                                                <p className="text-base font-medium text-black-secondary">Merchants</p>
                                                <p className="text-sm text-black-tertiary">You will pay the collection fee</p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "flex min-h-24 cursor-pointer items-center justify-start space-x-4 rounded-lg border px-4 py-4 " +
                                                ` ${formik.values.collectionFeeSource === ApiAccessSource.CUSTOMER && " !border-blue-secondary"} `
                                            }
                                            onClick={() => {
                                                formik
                                                    .getFieldHelpers("collectionFeeSource")
                                                    .setValue(
                                                        formik.values.collectionFeeSource === ApiAccessSource.CUSTOMER
                                                            ? null
                                                            : ApiAccessSource.CUSTOMER
                                                    );
                                            }}
                                        >
                                            <RadioButton
                                                id={ApiAccessSource.CUSTOMER}
                                                checked={formik.values.collectionFeeSource === ApiAccessSource.CUSTOMER}
                                            />
                                            <div>
                                                <p className="text-base font-medium">Customers</p>
                                                <p className="text-sm text-black-tertiary">
                                                    The fee will be added to the total amount to be paid by the customer
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "flex min-h-24 cursor-pointer items-center justify-start space-x-4 rounded-lg border px-4 py-4 " +
                                                ` ${formik.values.collectionFeeSource === ApiAccessSource.EITHER && " !border-blue-secondary"} `
                                            }
                                            onClick={() => {
                                                formik
                                                    .getFieldHelpers("collectionFeeSource")
                                                    .setValue(
                                                        formik.values.collectionFeeSource === ApiAccessSource.EITHER ? null : ApiAccessSource.EITHER
                                                    );
                                            }}
                                        >
                                            <RadioButton
                                                id={ApiAccessSource.EITHER}
                                                checked={formik.values.collectionFeeSource === ApiAccessSource.EITHER}
                                            />
                                            <div>
                                                <p className="text-base font-medium">Either</p>
                                                <p className="text-sm text-black-tertiary">
                                                    You will pass who will be debited for the collection fee through the API
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-12 flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-10">
                                        <ButtonComp fullWidth type="button" size="md" buttonType="secondary" onClick={props.toggler} color={"grey"}>
                                            <span className="w-full text-center text-black-tertiary">Cancel</span>
                                        </ButtonComp>

                                        <ButtonComp
                                            fullWidth
                                            type="submit"
                                            size="md"
                                            buttonType="primary"
                                            disable={formik.values.collectionFeeSource === INITIAL_FORM_STATE.collectionFeeSource}
                                            color={"black"}
                                        >
                                            <span className="w-full text-center">Save Changes</span>
                                        </ButtonComp>
                                    </div>
                                </FullPageTrayBody>
                            </Form>
                        );
                    }}
                </Formik>
            </FullPageTray>
        </>
    );
}

export default DefaultSetupCollectionFeesTray;
