import { HintType, ListStyle } from "../../../../../../element/hint/hint.constant";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { CompanyHint } from "../../../../../../../services/nigeria-application/registered/company.types";
import Hint from "../../../../../../element/hint";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
// import MessageToast from "../../../../../../../../../../components/message-toast";
import NewUploadedCard from "../../../../../../cards/new-uploaded-card";
import PreviewModal from "../../../../../../modals/preview-modal";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import SignComponentTest from "../../../../../../cards/signatureCard";
import usePreview from "../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/usePreview";
import { useSelector } from "react-redux";
import useSignature from "../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useSignature";

const PhotographGuides: CompanyHint[] = [
    {
        index: 0,
        span: (
            <span>
                {/* Use <span className="font-medium">Get Link</span> to share and capture photo on your preferred device. */}
                You can use the tip of your finger (if using a touch device)
            </span>
        ),
    },
];

type LocationState = {
    from?: string;
};

function Signature(): JSX.Element {
    const {
        isUploadSignatureLoading,
        isAppendSignatureLoading,
        isUploadSignatoryDocumentLoading,

        handleReset,
        handleAppendSignature,
        handleUploadSignature,
        handleUploadSignatoryDocument,
        handleDownloadSignatoryDocument,
    } = useSignature(() => setIsSigned(true));

    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
        downloadedDocumentName: "Signatory Document",
    });

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const currentSignatory = useSelector((state: IRootState) => state.application.currentSignatory);

    const [isSigned, setIsSigned] = useState(!!currentSignatory?.isCompletedMandate());
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    const handleBack = () => {
        navigate(locationState?.from || Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH);
    };

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Identification Preview"
                />
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    {accountOpening && (
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Signatory Details"
                                subTitle="Provide us with a signature"
                                progress={accountOpening.progress.personalCompletedProgress()}
                            >
                                <div className="flex w-full flex-col items-start justify-start space-y-4">
                                    {!isSigned && (
                                        <div className="flex w-full flex-col items-center space-x-0 space-y-4 lg:space-x-10 lg:space-y-0">
                                            <SignComponentTest
                                                signDocumentResponse={currentSignatory?.signedDocumentId}
                                                canAppendSignature={!!(currentSignatory?.signedDocumentId || currentSignatory?.user?.hasSignature)}
                                                isSignDocumentLoading={isUploadSignatureLoading}
                                                isUploadSignedDocumentLoading={isUploadSignatoryDocumentLoading}
                                                isAppendSignatureToDocumentLoading={isAppendSignatureLoading}
                                                handleSignDocument={(_data) => void handleUploadSignature(_data)}
                                                handleUploadSignedDocument={(_data) => void handleUploadSignatoryDocument(_data)}
                                                handleDownloadDocumentToSign={() => void handleDownloadSignatoryDocument()}
                                                handleAppendSignatureToDocument={() => void handleAppendSignature()}
                                                isCreator
                                            />
                                        </div>
                                    )}

                                    {isSigned && (
                                        <>
                                            {/* {!accountOpening.progress.isBusinessCompleted() && (
                                                <MessageToast
                                                    message={
                                                        accountOpening.isCreator
                                                            ? "Fill business details before proceeding"
                                                            : "Wait for your business director to fill business details before proceeding"
                                                    }
                                                    type="info"
                                                    fullWidth
                                                />
                                            )} */}

                                            <NewUploadedCard
                                                text="Signatory Form"
                                                handleRedo={() => setIsSigned(false)}
                                                primaryButtonText="Sign again"
                                                handlePreviewDocument={() => void handlePreviewDocument(currentSignatory?.signedDocumentId || "")}
                                            />
                                        </>
                                    )}
                                </div>
                            </ApplicationHeader>

                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp
                                        type="button"
                                        color="grey"
                                        ripple="light"
                                        buttonType="secondary"
                                        func={currentSignatory?.signedDocumentId && !isSigned ? () => setIsSigned(true) : handleBack}
                                        fullWidth
                                    >
                                        <span>
                                            {currentSignatory?.signedDocumentId && !isSigned
                                                ? "Cancel"
                                                : `Back${locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}`}
                                        </span>
                                    </ButtonComp>
                                </div>
                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                    <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                        <ButtonComp
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            func={() => {
                                                navigate(
                                                    {
                                                        pathname: !accountOpening.progress.isBusinessCompleted()
                                                            ? Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION
                                                            : accountOpening?.isCreator
                                                              ? Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT
                                                              : !accountOpening.progress.isBusinessCompleted()
                                                                ? Routes.ACCOUNT_OPENING.STATUS
                                                                : Routes.ACCOUNT_OPENING.REGISTERED.AGREEMENT,
                                                    },
                                                    {
                                                        state: {
                                                            from: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.SIGNATURE,
                                                        },
                                                    }
                                                );
                                            }}
                                            disable={!isSigned}
                                            fullWidth
                                        >
                                            <span>
                                                {!accountOpening.isCreator && !accountOpening.progress.isBusinessCompleted()
                                                    ? "Return Application"
                                                    : "Save and Continue"}
                                            </span>
                                        </ButtonComp>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={PhotographGuides} />
                </div>
            </div>
        </>
    );
}

export default Signature;
