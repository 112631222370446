import { CompanyDocumentFieldTexts, CompanyDocumentTypes } from "../../../../../../services/nigeria-application/registered/document.types";
import React, { useState } from "react";

import BusinessDocumentPreviewItem from "../../business-review/components/BusinessDocumentPreviewItem";
import IncorporationDocumentNigeria from "../../../../../../../../../models/account-opening/incorporation-documents/incorporationDocumentNigeria";
import PreviewModal from "../../../../../modals/preview-modal";
import usePreview from "../../../../../../Hooks/nigeria-application/registered-business/account-signatories/usePreview";

interface Props {
    data: IncorporationDocumentNigeria[] | null;
    isLLC: boolean;
    utilityBillId: string;
    isRequireSCUML: boolean;
    isCanNotPreview?: boolean;
}

function BusinessDocumentsPreview(props: Props): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
    });

    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [selectedBusinessDocument, setSelectedBusinessDocument] = useState<CompanyDocumentTypes | null>(null);

    const certificateId = props.data?.find((_doc) => _doc.type === CompanyDocumentTypes.CERTIFICATE)?.uploadId;
    // const formC02Id = props.data?.find((_doc) => _doc.type === CompanyDocumentTypes.FORM_C02)?.uploadId;
    // const formC07Id = props.data?.find((_doc) => _doc.type === CompanyDocumentTypes.FORM_C07)?.uploadId;
    const incorporationDocumentId = props.data?.find((_doc) => _doc.type === CompanyDocumentTypes.INCORPORATION_DOCUMENT)?.uploadId;
    const memorandumOfAssociationId = props.data?.find((_doc) => _doc.type === CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION)?.uploadId;
    const scumlCertificate = props.data?.find((_doc) => _doc.type === CompanyDocumentTypes.SCUML_CERTIFICATE)?.uploadId;

    const handlePreviewBusinessDocument = (_type: CompanyDocumentTypes, _id: string) => {
        setSelectedBusinessDocument(_type);
        void handlePreviewDocument(_id || "");
    };

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title={selectedBusinessDocument ? CompanyDocumentFieldTexts[selectedBusinessDocument] : "Business Document Preview"}
                />

                <div className="flex w-full flex-col items-start justify-start space-y-6 rounded-lg border border-black-quin p-5">
                    <BusinessDocumentPreviewItem
                        text="Incorporation Certificate"
                        isCanNotPreview={props.isCanNotPreview}
                        handlePreviewDocumentFunc={() => handlePreviewBusinessDocument(CompanyDocumentTypes.CERTIFICATE, certificateId || "")}
                    />
                    {props.isLLC && (
                        <BusinessDocumentPreviewItem
                            text="Memorandum and Articles of Association"
                            isCanNotPreview={props.isCanNotPreview}
                            handlePreviewDocumentFunc={() =>
                                handlePreviewBusinessDocument(CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION, memorandumOfAssociationId || "")
                            }
                        />
                    )}
                    <BusinessDocumentPreviewItem
                        text="Status Report"
                        isCanNotPreview={props.isCanNotPreview}
                        handlePreviewDocumentFunc={() =>
                            handlePreviewBusinessDocument(CompanyDocumentTypes.INCORPORATION_DOCUMENT, incorporationDocumentId || "")
                        }
                    />
                    {props.isRequireSCUML && (
                        <BusinessDocumentPreviewItem
                            text="SCUML Certificate"
                            isCanNotPreview={props.isCanNotPreview}
                            handlePreviewDocumentFunc={() =>
                                handlePreviewBusinessDocument(CompanyDocumentTypes.SCUML_CERTIFICATE, scumlCertificate || "")
                            }
                        />
                    )}
                    <BusinessDocumentPreviewItem
                        text="Utility Bill"
                        isCanNotPreview={props.isCanNotPreview}
                        handlePreviewDocumentFunc={() => handlePreviewBusinessDocument(CompanyDocumentTypes.UTILITY_BILL, props.utilityBillId || "")}
                    />
                </div>
            </div>
        </>
    );
}

export default BusinessDocumentsPreview;
