import {
    NigerianCompanyRequests,
    abortNigerianCompanyRequest,
    businessContact,
} from "../../../../services/nigeria-application/registered/company.api";
import { errorTrue, messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { BusinessContactForm } from "../../../../services/nigeria-application/registered/company.types";
import { Routes } from "../../../../../../../routes/routes.constants";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { setBusinessDetails } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

export interface useBusinessContactInterface {
    businessContactError: string | null;
    isBusinessContactLoading: boolean;
    handleReset: () => void;
    handleUpdateBusinessContact: (_data: BusinessContactForm) => Promise<void>;
}

type LocationState = {
    from?: string;
};

const useBusinessContact = (): useBusinessContactInterface => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const [businessContactError, setBusinessContactError] = useState<string | null>(null);
    const [isBusinessContactLoading, setIsBusinessContactLoading] = useState<boolean>(false);

    const handleUpdateBusinessContact = useCallback(
        async (data: BusinessContactForm): Promise<void> => {
            try {
                setBusinessContactError(null);
                setIsBusinessContactLoading(true);
                const res = await businessContact(data);
                dispatch(setBusinessDetails(res));
                if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                    dispatch(messageTrue("Business contact updated successfully"));
                    return;
                }
                navigate(
                    {
                        pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL,
                    },
                    {
                        state: {
                            from: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT,
                        },
                    }
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setBusinessContactError(errorMessage);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsBusinessContactLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = () => {
        setBusinessContactError(null);
        setIsBusinessContactLoading(false);
        abortNigerianCompanyRequest(NigerianCompanyRequests.CONTACT_INFO);
    };

    return {
        businessContactError,
        isBusinessContactLoading,
        handleReset,
        handleUpdateBusinessContact,
    };
};
export default useBusinessContact;
