import { AccountSettingsSectionValue, AccountSettingsSections } from "../../Services/account-settings/account-settings.constants";

import AccountSettingsSelectAccount from "./AccountSettingsSelectAccount";
import AccountSettingsSideMenuItem from "./AccountSettingsSideMenuItem";
import React from "react";

interface AccountSettingsSideMenuProps {
    stage: AccountSettingsSectionValue;
    handleSelectStage: (value: AccountSettingsSectionValue) => void;
    selectedAccountId: string | undefined;
    handleSelectAccount: (id: string) => void;
    canSplitInstructions?: boolean;
}

function AccountSettingsSideMenu({
    stage,
    handleSelectStage,
    selectedAccountId,
    handleSelectAccount,
    canSplitInstructions = true,
}: AccountSettingsSideMenuProps): JSX.Element {
    return (
        <div className="flex h-fit w-full flex-col space-y-8 pb-3 md:max-w-sm md:border-r-0.2 md:pb-0 lg:max-w-none">
            {/* <div className="flex w-full flex-col space-y-8 border-b-0.2 pb-3
             md:max-w-sm md:border-r-0.2 md:pb-0 md:pr-4 lg:max-w-none lg:overflow-y-auto"> */}
            <div className="w-full max-w-xs">
                <AccountSettingsSelectAccount selectedAccountId={selectedAccountId} handleSelectAccount={handleSelectAccount} />
            </div>
            {/* <div className="flex !flex-col flex-col 4xs:flex-row"> */}
            <div className="flex max-w-xs flex-row md:w-full md:flex-col md:space-y-2">
                {canSplitInstructions &&
                    Object.values(AccountSettingsSections).map((item) => (
                        <AccountSettingsSideMenuItem
                            key={`account-settings-${item.value}`}
                            hasSelectedAccount={!!selectedAccountId}
                            isActive={stage === item.value}
                            text={item.text}
                            handleMenuItemClick={() => handleSelectStage(item.value)}
                        />
                    ))}
                {!canSplitInstructions &&
                    Object.values(AccountSettingsSections)
                        .filter((el) => el.value !== AccountSettingsSectionValue.SPLIT_INFLOW)
                        .map((item) => (
                            <AccountSettingsSideMenuItem
                                key={`account-settings-${item.value}`}
                                hasSelectedAccount={!!selectedAccountId}
                                isActive={stage === item.value}
                                text={item.text}
                                handleMenuItemClick={() => handleSelectStage(item.value)}
                            />
                        ))}
            </div>
        </div>
    );
}

export default AccountSettingsSideMenu;
