import React from "react";
import Card from "../../../../../../models/card";

interface Props {
    data: Card;
    handleConfirm: () => void;
}

function ConfirmCard({ data, handleConfirm }: Props): JSX.Element {
    return (
        <div className="flex w-full flex-row items-center justify-between rounded-lg bg-blue-senary px-3 py-4 text-xs font-normal">
            {data && (
                <>
                    <div className="flex flex-row items-center justify-start space-x-4">
                        <div> {data.name}</div> <div>{data.maskedPan} </div>{" "}
                    </div>

                    <span className="cursor-pointer whitespace-nowrap text-blue" onClick={() => handleConfirm()}>
                        Add card
                    </span>
                </>
            )}
        </div>
    );
}

export default ConfirmCard;
