import React, { HTMLInputTypeAttribute, InputHTMLAttributes, useEffect, useRef, useState } from "react";

// import GoogleIcon from "../google-icon";
import useClickOutside from "../../hooks/useClickOutside";
import { useField } from "formik";

interface FormInputProps {
    type?: HTMLInputTypeAttribute | "textarea";
    name: string;
    icon?: React.ReactNode;
    label?: string;
    value?: string;
    isError?: boolean;
    dataType?: string;
    isActive?: boolean;
    inputSize?: "sm" | "md" | "lg" | "xl" | "2xl";
    isLoading?: boolean;
    isDisabled?: boolean;
    helperText?: React.ReactNode;
    placeholder?: string;
    customStyle?: string;
    borderCurve?: string | undefined | null;
    inputPlaceholder?: string;
    mobileHelperText?: boolean;
    passwordCustomStyle?: boolean;
}
function FormInput({
    type = "text",
    name,
    icon,
    label,
    // isError = false,
    isActive = false,
    dataType = "",
    inputSize = "xl",
    isLoading = false,
    helperText = "",
    isDisabled = false,
    borderCurve = "",
    customStyle = "",
    placeholder,
    inputPlaceholder,
    mobileHelperText = false,
    passwordCustomStyle = false,
    ...otherProps
}: FormInputProps & InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>): JSX.Element {
    // const [error, setError] = useState(false);
    const [active, setActive] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const [field] = useField<string>(name);

    const domNode = useClickOutside(() => setActive(false), undefined, ["mousedown", "focusout"]);

    const configInputField = {
        ...field,
        ...otherProps,
        className:
            `h-fit w-full self-end pb-1 w-full z-10 focus:outline-none focus:border-none ` +
            ` ${type === "textarea" ? "mt-5" : ""}` +
            ` ${type !== "textarea" && inputSize === "sm" ? "pb-1" : ""}` +
            ` ${type !== "textarea" && inputSize === "md" ? "pb-1" : ""}` +
            ` ${type !== "textarea" && inputSize === "lg" ? "pb-1" : ""}` +
            ` ${type !== "textarea" && inputSize === "xl" ? "pb-1.5" : ""}` +
            ` ${type !== "textarea" && inputSize === "2xl" ? "pb-2" : ""}` +
            ` ${!icon ? "px-4" : "ml-1"} ` +
            ` ${isDisabled ? "text-black-quat bg-transparent " : "text-black-secondary"} ` +
            ` ${customStyle || ""} ` +
            ` ${passwordCustomStyle ? "pr-10" : ""} `,
        type: type === "password" && showPassword ? "text" : type,
        placeholder: inputPlaceholder,
    };

    // useEffect(() => {
    //     const showError = (!!meta && meta.touched && !!meta.error) || isError;
    //     setError(showError);
    // }, [isError, meta]);

    useEffect(() => {
        if (active || isActive) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }
    }, [active, isActive]);

    useEffect(() => {
        if (inputRef.current) {
            window.onload = function () {
                if (inputRef.current) {
                    if (window.getComputedStyle(inputRef.current).backgroundColor === "rgb(232, 240, 254)") {
                        setActive(true);
                    }
                }
            };
        }
    }, [inputRef.current]);

    return (
        <div className="relative flex w-full flex-col" data-type={dataType}>
            <div
                className={
                    `overflow-hidden` +
                    ` ${type === "textarea" ? "leading-1 relative flex w-full items-center justify-between whitespace-nowrap border border-solid bg-white text-left font-normal antialiased shadow-none outline-none transition-all duration-150 focus:outline-none" : ""} ` +
                    ` ${type !== "textarea" ? "leading-1 relative flex h-12 w-full items-center justify-between whitespace-nowrap border border-solid bg-transparent text-left font-normal antialiased shadow-none outline-none transition-all duration-150 focus:outline-none" : ""} ` +
                    ` ${type !== "textarea" && inputSize === "2xl" ? "h-16" : ""} ` +
                    ` ${type !== "textarea" && inputSize === "xl" ? "h-14" : ""} ` +
                    ` ${type !== "textarea" && inputSize === "lg" ? "h-12" : ""} ` +
                    ` ${type !== "textarea" && inputSize === "md" ? "h-10" : ""} ` +
                    ` ${type !== "textarea" && inputSize === "sm" ? "h-8" : ""} ` +
                    ` ${field.value?.length > 0 && active ? "border-black-secondary lg:hover:border-black-secondary lg:focus:border-black-secondary" : ""} ` +
                    ` ${field.value?.length > 0 && !active ? "border-black-quin text-black-secondary lg:hover:border-black-secondary" : ""} ` +
                    ` ${field.value?.length === 0 && active ? "border-black-secondary" : ""} ` +
                    ` ${field.value?.length === 0 && !active ? "border-black-quin text-black-tertiary lg:hover:border-black-secondary lg:hover:text-black lg:focus:border-black-secondary" : ""} ` +
                    ` ${borderCurve ? borderCurve : "rounded-lg"} ` +
                    ` ${isDisabled ? "pointer-events-none" : "group/input"} ` +
                    ` ${isLoading ? "pointer-events-none" : ""} ` +
                    ` ${icon ? "px-4" : ""}`
                }
                ref={domNode}
                onBlur={() => setActive(false)}
                onClick={() => setActive(true)}
                onFocus={() => setActive(true)}
                data-type={dataType}
            >
                {icon && (
                    <span
                        className={
                            `flex cursor-default items-center justify-end text-black-secondary transition-all duration-75 ease-in-out ` +
                            `${placeholder ? (active || (!!field.value && String(field.value).length > 0) ? "opacity-100" : "opacity-0") : ""}`
                        }
                    >
                        {icon}
                    </span>
                )}
                {type === "textarea" ? (
                    <>
                        <textarea
                            ref={textareaRef}
                            {...configInputField}
                            {...otherProps}
                            rows={3}
                            style={{ resize: "none" }}
                            disabled={isDisabled}
                            data-type={dataType}
                            maxLength={256}
                        />
                    </>
                ) : (
                    <input
                        ref={inputRef}
                        {...configInputField}
                        {...otherProps}
                        disabled={isDisabled}
                        autoComplete={type === "password" ? "new-password" : "false"}
                        data-type={dataType}
                        maxLength={256}
                    />
                )}

                {(placeholder || label) && (
                    <label
                        htmlFor={placeholder || label}
                        className={
                            // `${active || field.value?.length > 0 ? "-top-2 left-4 bg-white text-xs" : type === "textarea" ?
                            //  "left-4 top-4 text-base" : "left-4 top-3 text-base"} ` +
                            `pointer-events-none absolute left-4 z-10 !ml-0 max-w-70% truncate leading-6 duration-150 group-hover/input:text-black-tertiary` +
                            ` ${active ? "top-0 text-2xs text-black-tertiary" : " top-3 text-base"} ` +
                            ` ${field.value?.length > 0 ? "!top-0 !text-2xs !text-black-tertiary" : "text-black-quat"} ` +
                            ` ${inputSize === "xl" ? (active || (!!field.value && String(field.value).length > 0) ? "leading-8" : "!top-3.5 leading-7") : ""}` +
                            ` ${inputSize === "2xl" ? (active || (!!field.value && String(field.value).length > 0) ? "leading-10" : "!top-3.5 leading-9") : ""}` +
                            ` ${isDisabled ? "text-black-quat" : ""} `
                        }
                        data-type={dataType}
                    >
                        <span className="h-fit max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{placeholder || label}</span>
                    </label>
                )}

                {type === "password" && (
                    <div className="absolute right-4 top-0 z-40 flex h-full cursor-pointer items-center justify-center">
                        <button className="self-center focus:outline-none" type="button" onClick={() => setShowPassword((prev) => !prev)}>
                            <span
                                className={
                                    "text-xs font-medium uppercase text-black"
                                    // +
                                    // `${!error && active ? "text-black" : ""} ` +
                                    // `${!active ? `${field.value?.length > 0 ? "text-black-tertiary" : "text-black-tertiary"} ` : ""} `
                                }
                            >
                                {/* {showPassword ? <GoogleIcon icon="visibility_off" size="xs" /> : <GoogleIcon icon="visibility" size="xs" />} */}
                                {showPassword ? "hide" : "show"}
                            </span>
                        </button>
                    </div>
                )}
            </div>
            {helperText && (
                <span
                    className={
                        `pt-2 text-xs leading-4 text-black-tertiary ` +
                        `${mobileHelperText ? "lg:hidden" : ""} ` +
                        `${!mobileHelperText ? "" : ""} ` +
                        `${isDisabled ? "text-black-quat" : ""} `
                    }
                    data-type={dataType}
                >
                    {helperText}
                </span>
            )}
        </div>
    );
}

export default FormInput;
