import { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import { getBlobFromCanvas } from "../../../../../../utils/blob";

interface UploadModalProps {
    active: boolean;
    isUploadPhotoLoading: boolean | null;

    toggler: () => void;
    handleUploadPhoto: (form: { file: File | Blob }) => void;
}
function CaptureModal(props: UploadModalProps): JSX.Element {
    const { active, toggler, isUploadPhotoLoading, handleUploadPhoto } = props;

    const [isPhotoTaken, setIsPhotoTaken] = useState<boolean>(false);

    const mediaStream = useRef<MediaStream | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const photoRef = useRef<HTMLCanvasElement | null>(null);
    const divRef = useRef<HTMLDivElement | null>(null);

    const startStream = useCallback(() => {
        navigator.mediaDevices
            .getUserMedia({
                video: { facingMode: "user" },
            })
            .then((stream) => {
                mediaStream.current = stream;
                const video = videoRef.current;
                if (!video) {
                    return;
                }
                video.srcObject = stream;
                void video.play();
            })
            .catch(() => {
                return;
            });
    }, [navigator, mediaStream, videoRef.current]);

    const endStream = useCallback(() => {
        if (mediaStream.current) {
            mediaStream.current.getTracks().forEach((track) => track.stop());
        }
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    }, [mediaStream.current, videoRef.current]);

    const takePicture = useCallback(() => {
        if (!divRef.current) return;
        const width = divRef.current.getBoundingClientRect().width || 0;
        const video = videoRef.current;
        const photo = photoRef.current;
        const height = video ? (width * video.videoHeight) / video.videoWidth : 0;
        if (!photo) return;
        photo.width = width;
        photo.height = height;
        const ctx = photo?.getContext("2d");
        ctx?.drawImage(video as CanvasImageSource, 0, 0, width, height);
    }, [
        divRef.current,
        divRef.current?.getBoundingClientRect().width,
        divRef.current?.getBoundingClientRect().height,
        photoRef.current,
        videoRef.current,
    ]);

    const clearImage = () => {
        const photo = photoRef.current;
        const ctx = photo?.getContext("2d");
        if (!photo) return;
        ctx?.clearRect(0, 0, photo?.width, photo?.height);
    };

    useEffect(() => {
        if (!active) return;
        startStream();
        return () => {
            setIsPhotoTaken(false);
            endStream();
        };
    }, [active]);

    useEffect(() => {
        if (active) return;
        setIsPhotoTaken(false);
        endStream();
    }, [active]);

    const handleTakeImage = useCallback(() => {
        takePicture();
        setIsPhotoTaken(true);
    }, []);

    const handleClearImage = useCallback(() => {
        clearImage();
        setIsPhotoTaken(false);
    }, []);

    const handleSubmit = useCallback(() => {
        if (photoRef.current)
            handleUploadPhoto({
                file: getBlobFromCanvas(photoRef.current),
            });
    }, [photoRef.current]);

    return (
        <>
            <div className={`${!active ? "pointer-events-none" : ""}`}>
                <Modal size="fit" active={active} toggler={toggler}>
                    <ModalHeader onClose={toggler}>Live Capture</ModalHeader>
                    <ModalBody>
                        <div className="relative flex flex-col items-center justify-center">
                            <div className="relative w-full" ref={divRef}>
                                <div className={`${isPhotoTaken ? "hidden" : ""}`}>
                                    <video ref={videoRef} style={{ width: "500px" }}></video>
                                </div>
                                <div className={`${isPhotoTaken ? "mx-auto" : "hidden"}`}>
                                    <canvas className="mx-auto" ref={photoRef}></canvas>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {!isPhotoTaken && (
                            <div className="flex w-full flex-col space-x-0 space-y-4 2xs:w-max 2xs:flex-row 2xs:space-x-4 2xs:space-y-0">
                                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                    <ButtonComp type="button" color="grey" buttonType="secondary" fullWidth func={toggler}>
                                        <span>Cancel</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                    <ButtonComp type="button" color="black" buttonType="primary" fullWidth func={handleTakeImage}>
                                        <span>Capture</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        )}
                        {isPhotoTaken && (
                            <div className="flex w-full flex-col space-x-0 space-y-4 2xs:w-max 2xs:flex-row 2xs:space-x-4 2xs:space-y-0">
                                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                    <ButtonComp type="button" color="grey" buttonType="secondary" fullWidth func={handleClearImage}>
                                        <span>Clear Image</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                    <ButtonComp
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        isLoading={isUploadPhotoLoading}
                                        fullWidth
                                        func={handleSubmit}
                                    >
                                        <span>Upload</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        </>
    );
}

export default CaptureModal;
