import { resetAllBillPaymentData, resetBillPaymentData } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import { ReactComponent as FailedPayment } from "../../../../../../assets/svg/dashboard/send-money/failed-payment.svg";
import { IRootState } from "../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../components/spinner";
import Money from "../../../../../../components/money";
import { ReactComponent as ProcessingPaymentIcon } from "../../../../../../assets/svg/dashboard/send-money/processing-payment.svg";
import { ReactComponent as SuccessfulPayment } from "../../../../../../assets/svg/dashboard/send-money/successful-payment.svg";
import Transaction from "../../../../../../models/transaction";
import nl2br from "../../../../../../helpers/noBreakingSpaceFormatter";
import { resetAllElectricityPurchaseData } from "../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { sanitize } from "dompurify";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useCallback } from "react";
import useCheckBillStatus from "../../../../../../hooks/use-check-bill-status";
import useGetTransaction from "../../../../Transactions/Hooks/State/useGetTransaction";
import { useNavigate } from "react-router-dom";

function ProcessingPayment(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedVendorName = useAppSelector((state) => state.electricityPurchase.selectedVendorName);
    const electricityPurchaseDetails = useAppSelector((state) => state.electricityPurchase.electricityPurchaseDetails);
    const electricityPurchaseResponse = useAppSelector((state) => state.electricityPurchase.electricityPurchaseResponse);

    const transaction = useSelector<IRootState, Transaction | undefined>((state) => {
        return state.transaction.transactions.get(electricityPurchaseResponse?.purchase.transactionId || "");
    });

    useGetTransaction(electricityPurchaseResponse?.purchase.transactionId || "");
    useCheckBillStatus(electricityPurchaseResponse || null);

    const handleDone = useCallback(() => {
        navigate("/");
        dispatch(resetAllBillPaymentData());
        dispatch(resetAllElectricityPurchaseData());
    }, []);

    const handleMakeAnotherPurchase = useCallback(() => {
        dispatch(resetBillPaymentData());
        dispatch(resetAllElectricityPurchaseData());
    }, []);

    return (
        <>
            {transaction ? (
                <>
                    <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-9">
                        <div className="flex w-full flex-col items-start justify-center space-y-6">
                            {transaction.isProcessing && (
                                <div className="flex w-full flex-col items-center justify-center space-y-4">
                                    <ProcessingPaymentIcon className="h-[72px] w-[72px]" />
                                    <div className="flex flex-col items-center justify-center space-y-4">
                                        <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">Payment on the way...</h2>
                                        <p className="text-center text-base text-black-secondary">
                                            Making a payment of <Money amount={transaction.amount} /> for {selectedVendorName} Electricity
                                        </p>
                                        <p className="!-mt-0.5 text-center text-base text-black-secondary">
                                            Customer ID: {electricityPurchaseDetails.accountId || ""}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {transaction.isSuccess && (
                                <div className="flex w-full flex-col items-center justify-center space-y-4">
                                    <SuccessfulPayment className="h-[72px] w-[72px]" />
                                    <div className="flex flex-col items-center justify-center space-y-4">
                                        <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">Payment Successful!</h2>
                                        <p className="text-center text-base text-black-secondary">
                                            You made a payment of <Money amount={transaction.amount} /> for {selectedVendorName} Electricity
                                        </p>
                                        <p className="!-mt-0.5 text-center text-base text-black-secondary">
                                            Customer ID: {electricityPurchaseDetails.accountId || ""}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {transaction.isFailed && (
                                <div className="flex w-full flex-col items-center justify-center space-y-4">
                                    <FailedPayment className="h-[72px] w-[72px]" />
                                    <div className="flex flex-col items-center justify-center space-y-4">
                                        <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">Payment Failed!</h2>
                                        <p className="text-center text-base text-black-secondary">
                                            {transaction.failedReason || "Something went wrong, please try again or review payment"}
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop bg-white p-4">
                                <div
                                    className="w-full"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitize(nl2br(electricityPurchaseResponse?.purchase.instructions || null)),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex w-full flex-row items-center justify-center space-x-4">
                            <ButtonComp size="xl" color="grey" ripple="light" buttonType="secondary" func={handleMakeAnotherPurchase}>
                                Make Another Purchase
                            </ButtonComp>
                            <ButtonComp size="xl" color="black" ripple="light" buttonType="primary" func={handleDone}>
                                Done
                            </ButtonComp>
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
        </>
    );
}

export default ProcessingPayment;
