import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import React from "react";
import ZambiaSignatoryInfo from "../../../../../../../../../../models/account-opening/zambiaSignatoryInfo";
import { useSelector } from "react-redux";

interface Props {
    data: ZambiaSignatoryInfo | undefined;
}

function BusinessPersonalDetailsReview(props: Props): JSX.Element {
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

    return (
        <>
            {props.data && accountOpening && (
                <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey px-4 py-5">
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">First Name</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.firstName || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Other Name</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.otherName || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Last Name</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.surname || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Date of Birth</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.dateOfBirth || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Nationality</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.isZambian ? "Zambian" : ""}
                            {!props.data.isZambian && (props.data.nonZambianNationality || "-")}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Role in Business</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.role &&
                                !props.data.otherRoleText &&
                                (accountOpening.options.role.find((_role) => _role.id === props.data?.role?.id)?.name || "")}
                            {!props.data.role && (props.data.otherRoleText || "-")}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Gender</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {accountOpening.options.gender.find((_gender) => _gender.id === props.data?.gender)?.name || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Address</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.address || "-"}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export default BusinessPersonalDetailsReview;
