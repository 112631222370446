import { RoleOptions, UserRole, UserRoleText, UserRolesForSignatories } from "../../../../../../../../../../../models/userAccount.constants";

import { AddNewSignatoryItem } from "../../../../../../../../services/nigeria-application/registered/accountSignatories.types";
import Checkbox from "../../../../../../../../../../../components/checkbox";
import Input from "../../../../../../../../../../../components/inputs/Input";
import SingleSelectDropdown from "../../../../../../../../../../../components/dropdown/single-select/single-select";
import useDimension from "../../../../../../../../../../../hooks/useDimension";

interface Props {
    data: AddNewSignatoryItem;
    index: number;
    isSignatoriesMaxed: boolean;
    handleDeleteNewSignatories: (_id: string) => void;
    handleChangeNewSignatoryValue: (_userId: string, _key: string, _value: string | UserRole | boolean | null | 0) => void;
}

function NewSignatory(props: Props): JSX.Element {
    const { width } = useDimension();

    return (
        <>
            <div className="flex w-full flex-col items-start justify-start space-y-6">
                <div className="flex w-full flex-row items-center justify-between text-black-secondary">
                    <h3 className="font-medium text-black-secondary">Director {props.index} </h3>
                    {props.data.canDelete && (
                        <div
                            className="max-w-4 cursor-pointer text-black-secondary hover:text-error"
                            onClick={() => props.handleDeleteNewSignatories(props.data.index as string)}
                        >
                            <span className="material-symbols-outlined text-xl">delete</span>
                        </div>
                    )}
                </div>

                <div className="flex w-full flex-col items-start space-y-4 text-base font-normal lg:items-center lg:px-0">
                    <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
                        <Input
                            type="text"
                            placeholder="First Name"
                            name="firstName"
                            value={props.data.firstName}
                            isDisabled={props.data.isDisabled}
                            onChange={(_value) => props.handleChangeNewSignatoryValue(props.data.index as string, "firstName", _value)}
                            autoFocus={width > 475}
                        />
                        <Input
                            type="text"
                            placeholder="Last Name"
                            name="surname"
                            value={props.data.surname}
                            isDisabled={props.data.isDisabled}
                            onChange={(_value) => props.handleChangeNewSignatoryValue(props.data.index as string, "surname", _value)}
                        />
                        <div className="w-full">
                            <Input
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={props.data.email}
                                isDisabled={props.data.isDisabled}
                                onChange={(_value) => props.handleChangeNewSignatoryValue(props.data.index as string, "email", _value)}
                                autoFocus={width < 476}
                            />
                        </div>
                        {props.data.isSignatory && (
                            <div className="w-full">
                                <SingleSelectDropdown
                                    placeholder="Role"
                                    options={UserRolesForSignatories.map((el) => {
                                        return {
                                            text: UserRoleText[el],
                                            value: el,
                                            subtext: RoleOptions[el].subtext,
                                        };
                                    })}
                                    value={props.data.userRole}
                                    onChange={(_value) => {
                                        if (_value) {
                                            props.handleChangeNewSignatoryValue(props.data.index as string, "userRole", _value);
                                        }
                                    }}
                                    fitHeight
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                        <div className="w-full lg:w-50%">
                            <div>
                                <Checkbox
                                    text={
                                        <>
                                            <div>
                                                <p className="text-sm font-medium leading-none">Account Signatory</p>
                                                {/* <p className="text-sm text-black-tertiary pt-1">
													This person will be the first contact person as regard this account
												</p> */}
                                            </div>
                                        </>
                                    }
                                    id="key contact"
                                    size="sm"
                                    checked={props.data.isSignatory}
                                    readOnly={!props.data.isSignatory && props.isSignatoriesMaxed}
                                    func={() => {
                                        // props.handleChangeNewKeySignatoryValue(props.data.index as string);
                                        props.handleChangeNewSignatoryValue(props.data.index as string, "isSignatory", !props.data.isSignatory);
                                        if (props.data.isSignatory) props.handleChangeNewSignatoryValue(props.data.index as string, "userRole", 0);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-full lg:w-50%">
                            <div>
                                <Checkbox
                                    text={
                                        <>
                                            <div>
                                                <p className="text-sm font-medium leading-none">Business Director</p>
                                                {/* <p className="text-sm text-black-tertiary pt-1">
												This person is identified as a director in the incorporation document
											</p> */}
                                            </div>
                                        </>
                                    }
                                    func={() => {
                                        props.handleChangeNewSignatoryValue(props.data.index as string, "isDirector", !props.data.isDirector);
                                    }}
                                    id="business director"
                                    checked={props.data.isDirector}
                                    size="sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewSignatory;
