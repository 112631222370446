import { setDataPurchaseOtp } from "../../../../../../../redux/payments/dataPurchase/slice/dataPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseDataOtpInterface {
    handleOtpChange: (_amount: string) => void;
}

function useDataPurchaseOtp(): UseDataOtpInterface {
    const dispatch = useDispatch();

    const handleOtpChange = useCallback((_otp: string) => {
        dispatch(setDataPurchaseOtp(_otp));
    }, []);

    return { handleOtpChange };
}

export default useDataPurchaseOtp;
