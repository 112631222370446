import BandTax from "./bandTax";
import { FeeType } from "../redux/init/slice/initSlice.types";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import TransferBand from "./transferBand";
import { immerable } from "immer";

export default class BandData {
    [immerable] = true;

    constructor(
        public band: TransferBand,
        public feeType: FeeType | null,
        public value: number,
        public percentageCap: number | null,
        public tax: BandTax | null
    ) {}

    static create(obj: GenericObject): BandData {
        return new BandData(
            Parsers.classObjectNonNullable(obj.band, TransferBand),
            Parsers.nullableEnum(obj.feeType, FeeType),
            Parsers.number(obj.value),
            Parsers.nullableNumber(obj.percentageCap),
            Parsers.classObject(obj.tax, BandTax)
        );
    }

    get isFlatFee(): boolean {
        return this.feeType === FeeType.FLAT_FEE;
    }
    get isPercentageFee(): boolean {
        return this.feeType === FeeType.PERCENTAGE;
    }
    get hasPercentageCap(): boolean {
        return !!this.percentageCap || !!(this.percentageCap && this.percentageCap > 0);
    }
    get hasTax(): boolean {
        return !!this.tax;
    }
}
