import { AccountBalanceAlertType, AccountBalanceInstructionType } from "../../../../../../models/userAccount.constants";
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";

import AccountSettingsActionButtons from "../AccountSettingsActionButtons";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import MoneyInput from "../../../../../../components/inputs/money-input";
import UserAccount from "../../../../../../models/userAccount";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import CurrencyCode from "../../../../../../components/currency-code";

interface Props {
    type: AccountBalanceAlertType;
    account: UserAccount;
    canCancel?: boolean;
    handleCancel?: () => void;
    handleUpdateBalanceInstruction: (amount: number | null, setIsSubmitting: Dispatch<SetStateAction<boolean>>) => Promise<void>;
}

function EditBalanceAlertInstruction({ account, type, canCancel, handleCancel, handleUpdateBalanceInstruction }: Props): JSX.Element {
    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setAmount(account.getBalanceInstructionByType(type)?.amount || undefined);
    }, [account, type]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!isNullOrUndefined(amount)) {
            void handleUpdateBalanceInstruction(amount, setIsSubmitting);
        }
    };

    useEffect(() => {
        setIsFormValid(!!Number(amount) && Number(amount) > 0 && Number(amount) !== account.getBalanceInstructionByType(type)?.amount);
    }, [amount]);

    return (
        <div className="relative h-full w-full">
            <form onSubmit={handleSubmit}>
                <div className="w-full space-y-4">
                    <MoneyInput
                        placeholder="Enter Amount"
                        type="number"
                        currency={<CurrencyCode currency={account.bankAccountCurrency} />}
                        value={String(amount)}
                        onChange={(value) => setAmount(Number(value))}
                    />

                    {(amount || 0) > 0 && (
                        <MessageToast
                            message={
                                <>
                                    {type === AccountBalanceInstructionType.LOW_BALANCE_ALERT
                                        ? "You will be notified when your balance falls below "
                                        : "You will be notified when your balance exceeds "}
                                    <Money currency={account.bankAccountCurrency} amount={amount || 0} className="font-medium" />
                                </>
                            }
                            type="info"
                            fullWidth
                        />
                    )}
                </div>
                <div className="mt-44 flex w-full items-center justify-center">
                    <AccountSettingsActionButtons
                        canCancel={canCancel}
                        isFormValid={isFormValid}
                        isSubmitting={isSubmitting}
                        handleCancel={handleCancel}
                    />
                </div>
            </form>
        </div>
    );
}

export default EditBalanceAlertInstruction;
