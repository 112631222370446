import React, { useEffect, useState } from "react";

import FullPageTrayFooter from "../../../../../../../components/Trays/FullPageTray/FullPageTrayFooter";
import { IRootState } from "../../../../../../../redux/rootReducer";
import StandAloneCancelPayment from "../../../../../payments-new/componentss/Elements/CancelPayment/StandAloneCancelPayment";
import Transaction from "../../../../../../../models/transaction";
import { useSelector } from "react-redux";

interface TransactionsDetailCardCancelApprovalActionsInterface {
    dataType: string;
    transaction: Transaction;
}

function TransactionsDetailCardCancelApprovalActions({ dataType, transaction }: TransactionsDetailCardCancelApprovalActionsInterface): JSX.Element {
    const userAccountMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);

    const [transactionDetail, setTransactionDetail] = useState<Transaction | null>(null);

    const currentUserAccountMeta = userAccountMeta?.find((_el) => _el.userAccountId === transaction?.origination?.userAccount?.id);
    const isTransactionInitiator = !!currentUserAccountMeta?.isInitiator && !currentUserAccountMeta?.isApprover;

    useEffect(() => {
        if (!transaction) return;
        setTransactionDetail(transaction);
    }, [transaction]);

    return (
        <>
            {transactionDetail &&
                isTransactionInitiator &&
                transactionDetail.isPendingApproval &&
                transactionDetail.destination &&
                (transactionDetail.destination.customerAccount ||
                    transactionDetail.destination.userAccount ||
                    transactionDetail.destination.card) && (
                    <FullPageTrayFooter dataType={dataType}>
                        <StandAloneCancelPayment transaction={transactionDetail} />
                    </FullPageTrayFooter>
                )}
        </>
    );
}

export default TransactionsDetailCardCancelApprovalActions;
