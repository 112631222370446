import { useCallback, useEffect, useState } from "react";

import AccountNumberInput from "../../../../../../components/account-number-input";
import BankAccount from "../../../../../../models/bankAccount";
import BankSelect from "../../../../payments-new/componentss/selects/bank-select";
import Card from "../../../../../../models/card";
import ConfirmCard from "./ConfirmCard";
import ConfirmNewAccount from "./ConfirmNewAccount";
import CustomerAccount from "../../../../../../models/customerAccount";
import MessageToast from "../../../../../../components/message-toast";
import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import UserAccount from "../../../../../../models/userAccount";
import { createRecipient } from "../../../../payments-new/services/savedRecipients/savedRecipients.api";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { resetAllSingleTransferData } from "../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import { useDispatch } from "react-redux";
import useSingleTransferAccountNumberAutocomplete from "../../../../payments-new/hooks/state/send-money/single-transfer/useSingleTransferAccountNumberAutocomplete";
import useSingleTransferVerifyRecipientAccountDetails from "../../../../payments-new/hooks/state/send-money/single-transfer/useSingleTransferVerifyRecipientAccountDetails";
import { verifyAccount } from "../../../../payments-new/services/send-money/singleTransferApi";

interface Props {
    handleSelectRecipient: (recipient: CustomerAccount | UserAccount | Card) => void;
    recipientAccounts: (CustomerAccount | UserAccount | Card)[];
}

function AddNewRecipientForm(props: Props): JSX.Element {
    const handleRecipient = props.handleSelectRecipient;

    const dispatch = useDispatch();

    const { handleSetVerifiedAccount, handleResetVerifiedAccount } = useSingleTransferVerifyRecipientAccountDetails();
    const { isRecipientAutocompleteLoading, suggestedRecipients, handleAccountNumberChange } = useSingleTransferAccountNumberAutocomplete({
        handleSetVerifiedAccount,
        handleResetVerifiedAccount,
    });

    const [accountNumber, setAccountNumber] = useState<string>("");
    const [selectedBankCode, setSelectedBankCode] = useState<string | undefined>(undefined);

    const [selectedAccount, setSelectedAccount] = useState<UserAccount | CustomerAccount | undefined>(undefined);
    const [selectedNewBankAccount, setSelectedNewBankAccount] = useState<BankAccount | undefined>(undefined);
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const [selectedAccountType, setSelectedAccountType] = useState<"card" | "recipient" | "new-account" | null>(null);
    const [isVerifyingAccount, setIsVerifyingAccount] = useState<boolean>(false);
    const [verifyAccountError, setVerifyAccountError] = useState<string | undefined>(undefined);
    const [isCreatingRecipient, setIsCreatingRecipient] = useState<boolean>(false);
    const [dropdownSuggestedRecipients, setDropdownSuggestedRecipients] = useState<(CustomerAccount | UserAccount | Card)[]>([]);

    useEffect(() => {
        if (!props.recipientAccounts || !suggestedRecipients) return;
        props.recipientAccounts.forEach((_recipient) => {
            setDropdownSuggestedRecipients(suggestedRecipients.filter((_el) => _el.id !== _recipient.id));
        });
    }, [suggestedRecipients, props.recipientAccounts]);

    const handleVerifyAccount = async (_accountNumber: string, bankCode: string) => {
        try {
            setSelectedAccountType("new-account");
            setIsVerifyingAccount(true);
            setVerifyAccountError(undefined);
            const res = await verifyAccount({ accountNumber: _accountNumber, bankCode });
            setSelectedNewBankAccount(res.bankAccount);
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            setVerifyAccountError(getErrorMessage(err));
        }
        setIsVerifyingAccount(false); // set outside catch block, because finally will ignore the return in catch block
    };

    const handleCreateRecipient = async (bankAccount: BankAccount) => {
        try {
            setIsCreatingRecipient(true);
            const res = await createRecipient({
                accountNumber: bankAccount.accountNumber,
                bankCode: bankAccount.bankCode,
                bankAccountId: bankAccount.id,
                alias: "",
            });
            if (res instanceof Error) {
                throw res;
            }
            handleRecipient(res.customerAccount);
            setSelectedNewBankAccount(undefined);
            dispatch(messageTrue({ message: "Account has been added successfully" }));
            reset();
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        }
        setIsCreatingRecipient(false); // set outside catch block, because finally will ignore the return in catch block
    };

    const handleConfirmNewAccount = useCallback(() => {
        if (selectedNewBankAccount) {
            void handleCreateRecipient(selectedNewBankAccount);
        }
    }, [selectedNewBankAccount]);

    const handleConfirmAccount = useCallback(() => {
        if (selectedAccount) {
            handleRecipient(selectedAccount);
            reset();
        }
    }, [selectedAccount]);

    const handleConfirmCard = useCallback(() => {
        if (selectedCard) {
            handleRecipient(selectedCard);
            reset();
        }
    }, [selectedCard]);

    const reset = useCallback(() => {
        setAccountNumber("");
        setSelectedBankCode(undefined);
        setSelectedNewBankAccount(undefined);
        setSelectedAccount(undefined);
        setSelectedCard(null);
        setIsVerifyingAccount(false);
        setVerifyAccountError(undefined);
        setIsCreatingRecipient(false);
        dispatch(resetAllSingleTransferData());
    }, []);

    const handleSetAccountNumber = useCallback((_accountNumber: string) => {
        handleAccountNumberChange(_accountNumber);
        setAccountNumber(_accountNumber);
    }, []);

    useEffect(() => {
        if (accountNumber.length === 10 && selectedBankCode) {
            void handleVerifyAccount(accountNumber, selectedBankCode);
        }
    }, [accountNumber, selectedBankCode]);

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    return (
        <div className="w-full space-y-4">
            <div className="w-full space-y-4">
                <p className="text-base font-medium">Add New Recipient</p>

                <div className="space-y-4">
                    <AccountNumberInput
                        data={dropdownSuggestedRecipients as UserAccount[] | null}
                        label="Account Number or Name"
                        onChange={handleSetAccountNumber}
                        isLoading={isRecipientAutocompleteLoading}
                        inputValue={accountNumber}
                        onClick={(e) => {
                            if (e instanceof UserAccount || e instanceof CustomerAccount) {
                                setSelectedAccount(e);
                                setSelectedAccountType("recipient");
                                setSelectedCard(null);
                            }
                            if (e instanceof Card) {
                                setSelectedCard(e);
                                setSelectedAccountType("card");
                                setSelectedAccount(undefined);
                            }
                            setAccountNumber("");
                        }}
                    />
                    <div className="space-y-4">
                        <BankSelect bankCode={selectedBankCode || ""} accountNumber={accountNumber} onClick={setSelectedBankCode} />
                        {verifyAccountError && (
                            <div className="mt-2">
                                <MessageToast type="error" message={verifyAccountError} />
                            </div>
                        )}
                        {!verifyAccountError &&
                            (selectedNewBankAccount || isVerifyingAccount || isCreatingRecipient) &&
                            selectedAccountType === "new-account" && (
                                <ConfirmNewAccount
                                    bankAccount={selectedNewBankAccount}
                                    isSearching={isVerifyingAccount}
                                    isSubmitting={isCreatingRecipient}
                                    handleConfirm={handleConfirmNewAccount}
                                />
                            )}
                    </div>
                </div>
            </div>

            {selectedAccount && selectedAccountType === "recipient" && (
                <ConfirmNewAccount bankAccount={selectedAccount.bankAccount as BankAccount} handleConfirm={handleConfirmAccount} />
            )}
            {selectedCard && selectedAccountType === "card" && <ConfirmCard data={selectedCard} handleConfirm={handleConfirmCard} />}
        </div>
    );
}

export default AddNewRecipientForm;
