import { activateAccountFinish, activateAccountInit, activateAccountResendOtp } from "../../Services/accounts-api";
import { activateAccountFinishRequest, activateAccountInitRequest } from "../../Services/accounts-api.types";
import { useCallback, useEffect, useState } from "react";

import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseActivateAccountInterface {
    otp: string;
    minutes: number;
    seconds: number;
    isInitOTPLoading: boolean;
    initOTPResponse: boolean;
    initOTPError: string | null;
    isActivateAccountFinishLoading: boolean;
    activateAccountFinishResponse: boolean;
    isActivateAccountFinishError: string | null;
    handleOTPInit: (data: activateAccountInitRequest) => Promise<void>;
    handleResendOTPInit: (data: activateAccountInitRequest) => Promise<void>;
    handleActivateAccountFinish: (data: activateAccountFinishRequest) => Promise<void>;
    handleOtpChange: (e: string) => void;
    handleReset: () => void;
}

const useActivateAccountState = (active?: boolean): UseActivateAccountInterface => {
    const dispatch = useDispatch();

    const [otp, setOtp] = useState<string>("");
    const [minutes, setMinutes] = useState<number>(3);
    const [seconds, setSeconds] = useState<number>(0);

    const [isInitOTPLoading, setIsInitOTPLoading] = useState<boolean>(false);
    const [initOTPResponse, setInitOTPResponse] = useState<boolean>(false);
    const [initOTPError, setInitOTPError] = useState<string | null>(null);

    const [isActivateAccountFinishLoading, setIsActivateAccountFinishLoading] = useState<boolean>(false);
    const [activateAccountFinishResponse, setActivateAccountFinishResponse] = useState<boolean>(false);
    const [isActivateAccountFinishError, setIsActivateAccountFinishError] = useState<string | null>(null);
    // const [activationError, setActivationError] = useState(false);

    useEffect(() => {
        if (!active || initOTPError) return;
        const optTimerInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(optTimerInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(optTimerInterval);
        };
    });

    useEffect(() => {
        if (!active) {
            setOtp("");
            setMinutes(2);
            setSeconds(59);
            setIsActivateAccountFinishError(null);
        }
    }, [active]);

    const handleOTPInit = useCallback(
        async (data: activateAccountInitRequest) => {
            try {
                setIsInitOTPLoading(true);
                const res = await activateAccountInit(data);
                setInitOTPResponse(res.success);
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setInitOTPError(errorMessage);
                dispatch(
                    errorTrue({
                        message: errorMessage,
                    })
                );
            } finally {
                setIsInitOTPLoading(false);
            }
        },
        [dispatch]
    );

    const handleResendOTPInit = useCallback(
        async (data: activateAccountInitRequest) => {
            try {
                setMinutes(2);
                setSeconds(59);
                setIsInitOTPLoading(true);
                const res = await activateAccountResendOtp(data);
                setInitOTPResponse(res.success);
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setInitOTPError(errorMessage);
                dispatch(
                    errorTrue({
                        message: errorMessage,
                    })
                );
            } finally {
                setIsInitOTPLoading(false);
            }
        },
        [dispatch]
    );

    const handleActivateAccountFinish = useCallback(
        async (data: activateAccountFinishRequest) => {
            try {
                setIsActivateAccountFinishLoading(true);
                setIsActivateAccountFinishError(null);
                const res = await activateAccountFinish(data);
                setActivateAccountFinishResponse(res.success);
                dispatch(messageTrue({ message: "Account activated" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setIsActivateAccountFinishError(errorMessage);
                dispatch(
                    errorTrue({
                        message: errorMessage,
                    })
                );
            } finally {
                setIsActivateAccountFinishLoading(false);
            }
        },
        [dispatch]
    );

    const handleOtpChange = useCallback((e: string) => {
        setOtp(e);
    }, []);

    const handleReset = useCallback(() => {
        setActivateAccountFinishResponse(false);
    }, []);

    return {
        otp,
        minutes,
        seconds,
        isInitOTPLoading,
        initOTPResponse,
        initOTPError,
        isActivateAccountFinishLoading,
        activateAccountFinishResponse,
        isActivateAccountFinishError,
        handleOTPInit,
        handleResendOTPInit,
        handleActivateAccountFinish,
        handleOtpChange,
        handleReset,
    };
};

export default useActivateAccountState;
