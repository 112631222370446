import {
    CompanyDocumentFieldTexts,
    CompanyDocumentTypes,
    IncorporationDocumentType,
} from "../../../../../../services/zambia-application/registered/new/document.types";
import React, { useState } from "react";

import BusinessDocumentPreviewItem from "../../business-review/components/BusinessDocumentPreviewItem";
import IncorporationDocumentZambia from "../../../../../../../../../models/account-opening/incorporation-documents/incorporationDocumentZambia";
import PreviewModal from "../../../../../modals/preview-modal";
import usePreview from "../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/usePreview";

interface Props {
    data: IncorporationDocumentZambia[] | null;
    isLLC: boolean;
    isNGO: boolean;
    isBusinessName: boolean;
    proofOfResidenceId: string;
    isCanNotPreview?: boolean;
}

function BusinessDocumentsPreview(props: Props): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
    });

    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [selectedBusinessDocument, setSelectedBusinessDocument] = useState<CompanyDocumentTypes | null>(null);

    const certificateId = props.data?.find((_doc) => _doc.type === IncorporationDocumentType.INCORPORATION_CERTIFICATE)?.uploadId;
    const memorandumOfAssociationId = props.data?.find((_doc) => _doc.type === IncorporationDocumentType.MEMORANDUM_OF_ASSOCIATION)?.uploadId;
    const associationConstitutionId = props.data?.find((_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__ASSOCIATION_CONSTITUTION)?.uploadId;
    const form3Id = props.data?.find((_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__FORM_3)?.uploadId;
    const operatingLicenseId = props.data?.find((_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__OPERATING_LICENSE)?.uploadId;
    const pacraPrintOutId = props.data?.find((_doc) => _doc.type === IncorporationDocumentType.ZAMBIA__PACRA_PRINT_OUT)?.uploadId;

    const handlePreviewBusinessDocument = (_type: CompanyDocumentTypes, _id: string) => {
        setSelectedBusinessDocument(_type);
        void handlePreviewDocument(_id || "");
    };

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title={selectedBusinessDocument ? CompanyDocumentFieldTexts[selectedBusinessDocument] : "Business Document Preview"}
                />

                <div className="flex w-full flex-col items-start justify-start space-y-6 rounded-lg border border-black-quin p-5">
                    {props.isBusinessName && (
                        <>
                            <BusinessDocumentPreviewItem
                                text={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                isCanNotPreview={props.isCanNotPreview}
                                handlePreviewDocumentFunc={() =>
                                    handlePreviewBusinessDocument(CompanyDocumentTypes.INCORPORATION_CERTIFICATE, certificateId || "")
                                }
                            />
                            <BusinessDocumentPreviewItem
                                text={CompanyDocumentFieldTexts[CompanyDocumentTypes.PROOF_OF_RESIDENCE]}
                                isCanNotPreview={props.isCanNotPreview}
                                handlePreviewDocumentFunc={() =>
                                    handlePreviewBusinessDocument(CompanyDocumentTypes.PROOF_OF_RESIDENCE, props.proofOfResidenceId || "")
                                }
                            />
                        </>
                    )}
                    {props.isNGO && (
                        <>
                            <BusinessDocumentPreviewItem
                                text={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                isCanNotPreview={props.isCanNotPreview}
                                handlePreviewDocumentFunc={() =>
                                    handlePreviewBusinessDocument(CompanyDocumentTypes.INCORPORATION_CERTIFICATE, certificateId || "")
                                }
                            />
                            <BusinessDocumentPreviewItem
                                text={CompanyDocumentFieldTexts[CompanyDocumentTypes.ASSOCIATION_CONSTITUTION]}
                                isCanNotPreview={props.isCanNotPreview}
                                handlePreviewDocumentFunc={() =>
                                    handlePreviewBusinessDocument(CompanyDocumentTypes.ASSOCIATION_CONSTITUTION, associationConstitutionId || "")
                                }
                            />
                        </>
                    )}
                    {props.isLLC && (
                        <>
                            <BusinessDocumentPreviewItem
                                text={CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_CERTIFICATE]}
                                isCanNotPreview={props.isCanNotPreview}
                                handlePreviewDocumentFunc={() =>
                                    handlePreviewBusinessDocument(CompanyDocumentTypes.INCORPORATION_CERTIFICATE, certificateId || "")
                                }
                            />
                            <BusinessDocumentPreviewItem
                                text={CompanyDocumentFieldTexts[CompanyDocumentTypes.OPERATING_LICENSE]}
                                isCanNotPreview={props.isCanNotPreview}
                                handlePreviewDocumentFunc={() =>
                                    handlePreviewBusinessDocument(CompanyDocumentTypes.OPERATING_LICENSE, operatingLicenseId || "")
                                }
                            />
                            <BusinessDocumentPreviewItem
                                text={CompanyDocumentFieldTexts[CompanyDocumentTypes.SHAREHOLDERS_INFORMATION]}
                                isCanNotPreview={props.isCanNotPreview}
                                handlePreviewDocumentFunc={() =>
                                    handlePreviewBusinessDocument(
                                        CompanyDocumentTypes.SHAREHOLDERS_INFORMATION,
                                        memorandumOfAssociationId || form3Id || pacraPrintOutId || ""
                                    )
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default BusinessDocumentsPreview;
