import { MoreAction, moreActionsOption } from "../hooks/state/pending-approval/usePendingPayments.types";

import BulkProcessingModal from "../componentss/modals/BulkProcessingModal";
import ButtonComp from "../../../../components/button/ButtonComp";
// import DropdownLink from "../../../../components/dropdown/dropdown-components/dropdown-link";
import { ReactComponent as EmptyX } from "../../../../assets/svg/EmptyX.svg";
import NewDropdownHead from "../../../../components/new-dropdown";
import NewDropdownItem from "../../../../components/new-dropdown-item";
// import NewSelect from "../../../../components/new-select";
import PageLayout from "../../../../components/layouts/page-layout";
import Pagination from "../../../../components/pagination";
import PendingApprovalData from "../componentss/TableRows/PendingApprovalData";
import PendingApprovalSkeleton from "../componentss/Skeletons/PendingApprovalSkeleton";
// import Pagination from "../../../../components/pagination";
import SkeletonElement from "../../../../components/skeleton";
import usePendingPayments from "../hooks/state/pending-approval/usePendingPayments";

// import { ReactComponent as EmptyX } from "./svg/EmptyX.svg";

function PendingPayments(): JSX.Element {
    const {
        showModal,
        selectedArr,
        pendingPaymentList,
        isBulkPaymentLoading,
        userCanApprovePayment,
        userInitiatedAnyPayment,
        pendingPaymentListsTotal,
        pendingPaymentListsOffset,
        userInitiatedEveryPayment,
        isBulkCancelPaymentLoading,
        pendingPaymentListsGroupSize,
        isPendingPaymentListsLoading,
        isAllSelectedPaymentCancelled,

        handleUpdateOffset,
        handleDeclinePayment,
        handleApprovePayment,
        handleClearSelectedArr,
        handleCancelAllPayments,
        handleApproveAllPayments,
        handleDeclineAllPayments,
        handleProcessBulkPayment,
        handleCloseBulkProcessingModal,
        handleSelectOrChangeCancelReason,
        handleSelectOrChangeDeclineReason,
        handleRemoveCancelOrChangeToCancel,
        handleRemoveDeclineOrChangeToDecline,
    } = usePendingPayments();

    return (
        <>
            {userCanApprovePayment && (
                <BulkProcessingModal
                    active={showModal}
                    data={selectedArr}
                    toggler={handleCloseBulkProcessingModal}
                    handleUpdatePendingPaymentsState={handleClearSelectedArr}
                />
            )}

            <PageLayout pageTitle="Pending Payments" subTitle="List of transfers to be accepted or declined">
                <div className="flex w-full items-center justify-between">
                    <div className="pt-4 md:pt-0">
                        {!isPendingPaymentListsLoading && pendingPaymentList.length > 0 && (
                            <NewDropdownHead
                                content={<p className="text-sm text-black-tertiary">More Actions</p>}
                                color="grey"
                                size="md"
                                buttonType="secondary"
                                paddingSize="sm"
                                dropdownSize="sm"
                                clickAndClose
                            >
                                <>
                                    {moreActionsOption.map((_, _index) => {
                                        if (
                                            (_.value === MoreAction.DECLINE_ALL && userInitiatedEveryPayment) ||
                                            (_.value === MoreAction.APPROVE_ALL && userInitiatedEveryPayment) ||
                                            (_.value === MoreAction.CANCEL_ALL && !userInitiatedAnyPayment) ||
                                            (_.value === MoreAction.CLEAR_ALL && !(userInitiatedAnyPayment || userCanApprovePayment))
                                        ) {
                                            return;
                                        }

                                        return (
                                            <NewDropdownItem
                                                key={_index}
                                                size="md"
                                                color={_.value === MoreAction.APPROVE_ALL ? "green" : "red"}
                                                onClick={
                                                    _.value === MoreAction.APPROVE_ALL
                                                        ? handleApproveAllPayments
                                                        : _.value === MoreAction.DECLINE_ALL
                                                          ? handleDeclineAllPayments
                                                          : _.value === MoreAction.CANCEL_ALL
                                                            ? handleCancelAllPayments
                                                            : handleClearSelectedArr
                                                }
                                            >
                                                <p className="text-sm">{_.text}</p>
                                            </NewDropdownItem>
                                        );
                                    })}
                                </>
                            </NewDropdownHead>
                        )}
                        {isPendingPaymentListsLoading && (
                            <div className="h-10 w-32">
                                <SkeletonElement box />
                            </div>
                        )}
                    </div>
                    <div>
                        {isPendingPaymentListsLoading ? (
                            <div className="h-10 w-32">
                                <SkeletonElement box />
                            </div>
                        ) : (
                            <ButtonComp
                                size="md"
                                color="black"
                                disable={selectedArr.length < 1}
                                isLoading={isBulkPaymentLoading || isBulkCancelPaymentLoading}
                                buttonType="primary"
                                func={handleProcessBulkPayment}
                            >
                                <span>
                                    {selectedArr.length < 1
                                        ? "Process Payment"
                                        : isAllSelectedPaymentCancelled
                                          ? `Cancel Payment${selectedArr.length > 1 ? "s" : ""}`
                                          : `Process Payment${selectedArr.length > 1 ? "s" : ""}`}
                                </span>
                            </ButtonComp>
                        )}
                    </div>
                </div>

                <div className="mt-4 flex h-full w-full flex-col items-center justify-start pb-8">
                    <div
                        className={
                            "left-0 top-0 h-fit w-full rounded-custom border border-grey"
                            // +
                            // `${pendingPaymentList.length < 1 && !isPendingPaymentListsLoading ? "rounded-rb-none
                            // rounded-l-none rounded-tl-[10px] rounded-tr-[10px] border-b-0" : "rounded-custom"}`
                        }
                    >
                        <table className="w-full">
                            <thead className="custom-table-head">
                                <tr className="relative">
                                    <th className="w-20%" scope="col">
                                        Account
                                    </th>
                                    <th className="w-20%" scope="col">
                                        Recipient
                                    </th>
                                    <th className="w-20%" scope="col">
                                        Purpose
                                    </th>
                                    <th className="w-20%" scope="col">
                                        Amount
                                    </th>
                                    <th className="w-20%" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white [&>tr:not(:last-child)>td]:border-b [&>tr:not(:last-child)>td]:!border-b-grey-secondary [&>tr>td]:px-4">
                                {!isPendingPaymentListsLoading &&
                                    pendingPaymentList.length > 0 &&
                                    pendingPaymentList.map((el, index) => {
                                        const selectedPayment = selectedArr.find((_el) => _el.transaction.id === el.id);
                                        return (
                                            <PendingApprovalData
                                                data={el}
                                                key={`id-${index}`}
                                                selectedPayment={selectedPayment}
                                                handleApprovePayment={handleApprovePayment}
                                                handleDeclinePayment={handleDeclinePayment}
                                                handleSelectOrChangeCancelReason={handleSelectOrChangeCancelReason}
                                                handleRemoveCancelOrChangeToCancel={handleRemoveCancelOrChangeToCancel}
                                                handleRemoveDeclineOrChangeToDecline={handleRemoveDeclineOrChangeToDecline}
                                                handleSelectOrChangeDeclineReason={handleSelectOrChangeDeclineReason}
                                            />
                                        );
                                    })}
                                {isPendingPaymentListsLoading && <PendingApprovalSkeleton />}
                                {isPendingPaymentListsLoading && <PendingApprovalSkeleton />}
                            </tbody>
                        </table>
                        {pendingPaymentList.length < 1 && !isPendingPaymentListsLoading && (
                            <div className="flex min-h-40 w-full flex-col items-center justify-center space-y-2 rounded-b-lg bg-white py-16">
                                <EmptyX className="h-16 w-16" />
                                <span className="text-sm text-black-tertiary">
                                    You have no transaction pending approval
                                    {pendingPaymentListsTotal > 100 && " on this list"}
                                </span>
                            </div>
                        )}
                    </div>

                    {pendingPaymentList.length > 0 && (
                        <Pagination
                            offset={pendingPaymentListsOffset}
                            total={pendingPaymentListsTotal}
                            groupSize={pendingPaymentListsGroupSize}
                            onSelect={(_page, _offset) => handleUpdateOffset(_offset)}
                            isLoading={isPendingPaymentListsLoading}
                        />
                    )}
                </div>
            </PageLayout>
        </>
    );
}

export default PendingPayments;
