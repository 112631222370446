import React from "react";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { setIsTerminalTransactionsDetailCardOpen } from "../../../../../../redux/terminals/slice/terminalsSlice";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import CurrencyCode from "../../../../../../components/currency-code";
import formatNumber from "../../../../../../utils/formatNumber";
import { formatDateAndTime } from "../../../../../../utils/formatDate";
import TerminalStatusTag from "../../Statustag/TerminalStatusTag";
import TerminalSettlementStatusTag from "../../Statustag/TerminalSettlementStatusTag";
import { TransactionType } from "../../../../../../models/posTransaction.constants";

export const TERMINAL_TRANSACTION_DATATYPE = "terminal-transaction";

function TerminalTransactionDetailsCard(): JSX.Element {
    const dispatch = useDispatch();

    const terminalTransactionDetail = useAppSelector((state) => state.terminals.terminalTransactionDetail);
    const isTerminalTransactionDetailCardOpen: boolean = useAppSelector((state) => state.terminals.isTerminalTransactionDetailCardOpen);

    const handleCloseTransactionDetailCard = () => {
        dispatch(setIsTerminalTransactionsDetailCardOpen(false));
    };

    return (
        <>
            <FullPageTray
                active={isTerminalTransactionDetailCardOpen}
                dataType={TERMINAL_TRANSACTION_DATATYPE}
                handleReset={handleCloseTransactionDetailCard}
                withClickOutside
            >
                <FullPageTrayHeader toggler={handleCloseTransactionDetailCard} dataType={TERMINAL_TRANSACTION_DATATYPE}>
                    Transaction Details
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={TERMINAL_TRANSACTION_DATATYPE}>
                    <div className="mb-10 flex w-full flex-col space-y-4">
                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between space-x-8 py-2">
                                <p className="text-2xl font-bold">
                                    <CurrencyCode />
                                    {formatNumber(terminalTransactionDetail?.transactionAmount || 0)}
                                </p>

                                <div className="">
                                    <TerminalStatusTag terminal={terminalTransactionDetail} />
                                </div>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Terminal Name</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {terminalTransactionDetail?.terminal?.name || terminalTransactionDetail?.terminal?.serialNumber}
                                </p>
                            </div>
                        </div>

                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Type</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {terminalTransactionDetail?.getType}
                                </p>
                            </div>
                            {terminalTransactionDetail?.type === TransactionType.CARD_PAYMENT && (
                                <>
                                    {terminalTransactionDetail?.cardPayment?.maskedPan && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">PAN</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.cardPayment.maskedPan}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.cardPayment?.rrn && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">RRN</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.cardPayment.rrn}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.cardPayment?.maskedPan && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">STAN</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.cardPayment.maskedPan}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                            {terminalTransactionDetail?.type === TransactionType.TRANSFER_IN && (
                                <>
                                    {terminalTransactionDetail?.transferIn?.sender && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Sender Name</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferIn?.sender}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferIn?.description && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Narration</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferIn?.description}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferIn?.sessionId && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Session ID</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferIn?.sessionId}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                            {terminalTransactionDetail?.type === TransactionType.TRANSFER_OUT && (
                                <>
                                    {terminalTransactionDetail?.transferOut?.recipient?.accountName && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferOut?.recipient.accountName}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferOut?.recipient?.bankName && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Bank</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferOut?.recipient.bankName}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferOut?.recipient?.accountNumber && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Account Number</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {"********" + terminalTransactionDetail?.transferOut?.recipient.accountNumber?.slice(-4) || ""}
                                            </p>
                                        </div>
                                    )}

                                    {terminalTransactionDetail?.transferOut?.sessionId && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Session ID</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferOut?.sessionId}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Settlement Status</p>

                                <div className="">
                                    <TerminalSettlementStatusTag terminal={terminalTransactionDetail} />
                                </div>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Reference</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {terminalTransactionDetail?.reference}
                                </p>
                            </div>

                            {(terminalTransactionDetail?.type === TransactionType.TRANSFER_IN ||
                                terminalTransactionDetail?.type === TransactionType.TRANSFER_OUT) && (
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Stamp Duty</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        <CurrencyCode />
                                        {formatNumber(terminalTransactionDetail?.stampDuty || 0)}
                                    </p>
                                </div>
                            )}
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Fee</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <CurrencyCode />
                                    {formatNumber(terminalTransactionDetail?.lencoFee || 0)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Time</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {formatDateAndTime(terminalTransactionDetail?.datetime as Date)}
                                </p>
                            </div>
                        </div>
                    </div>
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default TerminalTransactionDetailsCard;
