interface SkeletonElementProps {
    box?: boolean;
    text?: boolean;
    title?: boolean;
    avatar?: boolean;
    isDarker?: boolean;
    thumbnail?: boolean;
}

function Skeleton({ avatar, text, title, thumbnail, isDarker, box }: SkeletonElementProps): JSX.Element {
    return (
        <div
            className={
                `animate-skeleton rounded ${isDarker ? "bg-gray-400" : "bg-gray-100"} ` +
                `${title ? "h-5 w-1/3 rounded" : ""} ` +
                `${text ? "h-3.5 w-full rounded" : ""} ` +
                `${box ? "h-full w-full rounded" : ""} ` +
                `${thumbnail ? "h-24 w-24 rounded" : ""} ` +
                `${avatar ? "h-full w-full rounded-full" : ""} `
            }
        />
    );
}

export default Skeleton;
