import {
    NigeriaApplicationSectionType,
    NigeriaApplicationStagesType,
    NigeriaRegisteredSignatoryStages,
} from "../../../../../services/nigeria-application/nigeria-application.types";

import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { GenericApplicationPageType } from "../../../../../services/application.constants";
import LencoSpinner from "../../../../../../../../components/spinner";
import MeansOfId from "./components/means-of-id";
import NextOfKin from "./components/next-of-kin";
import PersonalDetails from "./components/personal-details";
import Personnel from "./components/personnel";
import Photograph from "./components/photograph";
import SignatoriesDetails from "./components/signatories";
import Signature from "./components/signature";
import useAccountSignatoriesInit from "../../../../../Hooks/nigeria-application/registered-business/account-signatories/useAccountSignatoriesInit";
import { useLayoutEffect } from "react";
import { useParams } from "react-router";

type ApplicationUrlParams = {
    stage: NigeriaApplicationStagesType | undefined;
    section: NigeriaApplicationSectionType | GenericApplicationPageType;
};

function AccountSignatories(): JSX.Element {
    const { stage } = useParams<ApplicationUrlParams>();

    const {
        currentSignatoryError,
        accountSignatoriesError,
        isCurrentSignatoryLoading,
        isAccountSignatoriesLoading,
        handleReset,
        handleCurrentSignatoryInit,
        handleAccountSignatoriesInit,
    } = useAccountSignatoriesInit();

    useLayoutEffect(() => {
        void handleCurrentSignatoryInit();
        void handleAccountSignatoriesInit();
        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            {(isCurrentSignatoryLoading || isAccountSignatoriesLoading) && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {(currentSignatoryError || accountSignatoriesError) && (
                <div className="flex h-full w-full items-start justify-center">
                    <ErrorToast error={currentSignatoryError || accountSignatoriesError} />
                </div>
            )}
            {!(isCurrentSignatoryLoading || isAccountSignatoriesLoading) && !(currentSignatoryError || accountSignatoriesError) && (
                <div className="flex h-full w-full flex-row items-start justify-start">
                    {/* {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_BVN && <BVN />} */}

                    {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_PERSONNEL && <Personnel />}
                    {!stage && <SignatoriesDetails />}

                    {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_PERSONAL_DETAILS && <PersonalDetails />}
                    {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_NEXT_OF_KIN && <NextOfKin />}
                    {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_MEANS_OF_ID && <MeansOfId />}
                    {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_PHOTOGRAPH && <Photograph />}
                    {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_SIGNATURE && <Signature />}
                    {/* {stage === NigeriaRegisteredSignatoryStages.SIGNATORY_KEY_CONTACT && <KeyContact />} */}
                </div>
            )}
        </>
    );
}

export default AccountSignatories;
