import { CSSProperties, KeyboardEvent, useEffect, useRef, useState } from "react";

import AccountNumberInputItem from "./component/account-number-input-item";
import BarLoader from "react-spinners/BarLoader";
import Card from "../../models/card";
import CustomerAccount from "../../models/customerAccount";
import { ReactComponent as EmptyXX } from "../../assets/images/empty/empty-xx.svg";
import Input from "../inputs/Input";
import { FixedSizeList as List } from "react-window";
import SearchBar from "../search-bar";
import UserAccount from "../../models/userAccount";
import titleCase from "../../hooks/titleCase";
import { useAppSelector } from "../../redux/hooks";
import useClickOutside from "../../hooks/useClickOutside";

interface Props {
    data?: Array<UserAccount | CustomerAccount | Card> | null;
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    label?: string;
    dataType?: string;
    isLoading?: boolean;
    isPayFrom?: boolean;
    inputValue?: string;
    isDisabled?: boolean;
    showBalance?: boolean;
    isInputDisabled?: boolean;
    showBalanceHelperText?: boolean;
    // canInitiatorSelect

    onClick?: (e: UserAccount | CustomerAccount | Card) => void;
    onChange?: (e: string) => void;
}

function AccountNumberInput(props: Props): JSX.Element {
    const {
        data,
        size = "xl",
        label,
        dataType,
        isLoading,
        isPayFrom = false,
        inputValue,
        isDisabled,
        showBalance = false,
        isInputDisabled = false,
        onClick,
        onChange,
    } = props;

    const [active, setActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredAccounts, setFilteredAccounts] = useState<Array<UserAccount | CustomerAccount | Card>>([]);

    const user = useAppSelector((state) => state.init.main?.companyDetails.user);
    const cardBalances = useAppSelector((state) => state.transferBetween.cardBalances);
    const userAccountMeta = useAppSelector((state) => state.init.main?.companyDetails.userAccountsMeta);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const domNode = useClickOutside(() => setActive(false));

    const searchDropdownHeight = 64;

    useEffect(() => {
        const accounts = isPayFrom
            ? (data || [])
                  .filter((_account) => !userAccountMeta?.some((_meta) => _meta.userAccountId === _account.id && _meta.isApproverOnly))
                  .filter((_account) => (_account instanceof Card ? _account.assignedToCorporateAccountMember?.id === user?.id : _account))
            : data || [];
        const query = searchTerm.trim().toLowerCase();

        if (!query.length) {
            setFilteredAccounts(accounts);
        } else {
            setFilteredAccounts(
                accounts.filter((item: UserAccount | CustomerAccount | Card) => {
                    if (item instanceof UserAccount) {
                        return (
                            item.accountName.toLowerCase().includes(query) ||
                            item.accountNumber.toLowerCase().includes(query) ||
                            item.lencoNameMin.toLowerCase().includes(query)
                        );
                    }
                    if (item instanceof CustomerAccount) {
                        return (
                            item.accountName.toLowerCase().includes(query) ||
                            item.accountNumber.toLowerCase().includes(query) ||
                            item.alias?.toLowerCase().includes(query)
                        );
                    }
                    if (item instanceof Card) {
                        return item.name.toLowerCase().includes(query) || item.maskedPan.toLowerCase().includes(query);
                    }
                })
            );
        }
    }, [data, isPayFrom, searchTerm]);

    const handleKeypress = (event: KeyboardEvent<HTMLDivElement>) => {
        //it triggers by pressing the enter key
        if (event.key === "Enter") {
            !isInputDisabled && inputRef?.current?.focus();
            setActive((prev) => !prev);
        }
    };

    return (
        <div
            className={`relative flex h-full w-full flex-col items-center justify-start ` + `${isDisabled ? "pointer-events-none" : ""}`}
            ref={domNode}
        >
            <div className="relative w-full overflow-hidden rounded-lg" onClick={() => setActive(true)} onKeyDown={handleKeypress}>
                <Input label={label} value={titleCase(inputValue || "")} inputSize={size} onChange={onChange} isDisabled={isInputDisabled} />
                {isLoading && (
                    <div className="absolute bottom-0 left-0 w-full">
                        <BarLoader color="#5466F9" className="!w-full" />
                    </div>
                )}
            </div>
            <div
                className={
                    `absolute top-full my-2 w-full origin-top overflow-y-auto rounded-lg bg-white shadow-dropdown duration-300 ease-in-out` +
                    ` ${active ? "z-40 max-h-76 opacity-100" : "pointer-events-none max-h-0 opacity-0"}`
                }
                data-type={dataType}
            >
                {data && data.length > 6 && (
                    <div className="flex h-16 w-full items-center justify-start border-b border-grey-secondary px-4" data-type={dataType}>
                        <SearchBar placeholder="Search for account" value={searchTerm} onChange={(_value) => setSearchTerm(_value)} />
                    </div>
                )}
                {filteredAccounts.length > 0 && (
                    <div className="w-full" data-type={dataType}>
                        <List
                            width="100%"
                            height={filteredAccounts.length * searchDropdownHeight > 168 ? 168 : filteredAccounts.length * searchDropdownHeight}
                            itemSize={searchDropdownHeight}
                            itemCount={filteredAccounts.length}
                            className="w-full [&>div>div:not(:first-child)]:border-t [&>div>div:not(:first-child)]:border-grey-secondary"
                        >
                            {({ index, style }: { index: number; style: CSSProperties | undefined }) => {
                                return (
                                    <div className="w-full" style={style} key={index} data-type={dataType}>
                                        <AccountNumberInputItem
                                            key={index}
                                            data={filteredAccounts[index]}
                                            onClick={() => {
                                                onClick && onClick(filteredAccounts[index]);
                                                setActive((prev) => !prev);
                                            }}
                                            cardBalance={cardBalances.get(
                                                filteredAccounts[index] instanceof Card ? (filteredAccounts[index] as Card).createRequestId : ""
                                            )}
                                            showBalance={showBalance}
                                        />
                                    </div>
                                );
                            }}
                        </List>
                    </div>
                )}
                {data && data.length > 0 && filteredAccounts.length < 1 && (
                    <div className="flex w-full flex-col items-center justify-center space-y-2 py-4" data-type={dataType}>
                        <EmptyXX className="h-10 w-10" data-type={dataType} />
                        <span className="text-sm text-black-tertiary" data-type={dataType}>
                            Nothing Found
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AccountNumberInput;
