import DropdownHead from "../dropdown-components/dropdown-head";
import { ReactComponent as LineDropdownIcon } from "../../../assets/svg/LineDropdownIcon.svg";
import React from "react";

interface Props {
    size?: "xs" | "sm" | "lg";
    children: React.ReactNode;
    placement?: "right" | "left";
    noOverflow?: boolean;
    isDisabled?: boolean;
}

function LineDropdown(props: Props): JSX.Element {
    return (
        <DropdownHead
            placeholder={
                props?.isDisabled ? (
                    <LineDropdownIcon className="pointer-events-none fill-grey text-current ease-in-out" />
                ) : (
                    <LineDropdownIcon className="pointer-events-none fill-current text-current ease-in-out" />
                )
            }
            size={props.size || "xs"}
            placement={props.placement || "right"}
            noOverflow={props.noOverflow}
            clickAndClose
            icon
        >
            {props.children}
        </DropdownHead>
    );
}

export default LineDropdown;
