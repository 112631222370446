import * as Yup from "yup";

import { BusinessContactFields, getBusinessContactHints } from "../../../../../../services/zambia-application/registered/new/company.types";
import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../../element/hint/hint.constant";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../element/ApplicationHeader";
import BusinessContactReview from "../../business-review/components/review/BusinessContactReview";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import FormInput from "../../../../../../../../../components/inputs/FormInput";
import Hint from "../../../../../element/hint";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import { Routes } from "../../../../../../../../../routes/routes.constants";
import useBusinessContact from "../../../../../../Hooks/zambia-application/registered-business/business-details/useBusinessContact";
import { useEffect } from "react";
import { useSelector } from "react-redux";

type LocationState = {
    from?: string;
};

function BusinessContact(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isBusinessContactLoading, handleReset, handleUpdateBusinessContact } = useBusinessContact();

    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const businessDetails = useSelector((state: IRootState) => state.zambiaApplication.businessDetails);

    const INITIAL_FORM_STATE = {
        email: businessDetails?.email || "",
        phone: businessDetails?.phone || "",
        website: businessDetails?.website || "",
        operatingAddress: businessDetails?.operatingAddress || "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().email("Invalid email"),
        phone: Yup.string().matches(new RegExp("[0-9]")),
        website: Yup.string(),
        operatingAddress: Yup.string().required("Required"),
    });

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    const handleBack = () => {
        navigate(locationState?.from || Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION);
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    void handleUpdateBusinessContact(values);
                }}
                enableReinitialize
                validateOnMount
                validateOnBlur
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <div className="flex w-full flex-row items-start justify-start space-x-6">
                                {accountOpening && (
                                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                                        <ApplicationHeader
                                            header="Business Contact Details"
                                            subTitle="To get started, tell us about your business"
                                            progress={accountOpening.progress.businessCompletedProgress()}
                                        >
                                            {!accountOpening?.isCreator && (
                                                <div className="flex w-full flex-col items-start justify-start">
                                                    <BusinessContactReview data={businessDetails} />
                                                </div>
                                            )}
                                            {accountOpening?.isCreator && (
                                                <div className="w-full">
                                                    <div className="flex w-full flex-col space-y-4">
                                                        <div className="w-full">
                                                            <FormInput
                                                                type="textarea"
                                                                placeholder="Registered Business Address"
                                                                value={formik.values.operatingAddress || ""}
                                                                name="operatingAddress"
                                                            />
                                                        </div>
                                                        <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                            <div className="w-full md:w-50%">
                                                                <FormInput
                                                                    type="text"
                                                                    placeholder="Phone Number (Optional)"
                                                                    name="phone"
                                                                    value={formik.values.phone || ""}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                />
                                                            </div>
                                                            <div className="md:w-6/12">
                                                                <FormInput
                                                                    type="text"
                                                                    placeholder="Email Address (Optional)"
                                                                    name="email"
                                                                    value={formik.values.email || ""}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-full">
                                                            <FormInput
                                                                type="text"
                                                                placeholder="Website (Optional)"
                                                                name="website"
                                                                value={formik.values.website || ""}
                                                                isDisabled={!accountOpening.isCreator}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </ApplicationHeader>
                                        {!accountOpening?.isCreator && (
                                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                    <ButtonComp
                                                        type="button"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        func={handleBack}
                                                        fullWidth
                                                    >
                                                        <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                                    </ButtonComp>
                                                </div>
                                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                        <ButtonComp
                                                            type="button"
                                                            color="black"
                                                            ripple="light"
                                                            buttonType="primary"
                                                            func={() => navigate(Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT)}
                                                            fullWidth
                                                        >
                                                            <span>Save and Continue</span>
                                                        </ButtonComp>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {accountOpening.isCreator && (
                                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                    <ButtonComp
                                                        type="button"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        func={handleBack}
                                                        fullWidth
                                                    >
                                                        <span>Back</span>
                                                    </ButtonComp>
                                                </div>
                                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                    <ButtonComp
                                                        type={formik.dirty ? "submit" : "button"}
                                                        color="black"
                                                        ripple="light"
                                                        buttonType="primary"
                                                        isLoading={isBusinessContactLoading}
                                                        func={() => {
                                                            // locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW &&
                                                            navigate(
                                                                {
                                                                    pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL,
                                                                },
                                                                {
                                                                    state: {
                                                                        from: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        disable={
                                                            !!(
                                                                (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW && !formik.dirty) ||
                                                                !accountOpening.isCreator ||
                                                                formik.errors.operatingAddress
                                                            )
                                                        }
                                                        fullWidth
                                                    >
                                                        {locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? "Save changes" : "Save and Continue"}
                                                    </ButtonComp>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {accountOpening?.isCreator && (
                                    <Hint
                                        hintType={HintType.GUIDE}
                                        listStyle={ListStyle.CHECK}
                                        firstList={getBusinessContactHints(Object.keys(formik.values || []), Object.keys(formik.errors || []), [
                                            BusinessContactFields.EMAIL,
                                            BusinessContactFields.PHONE,
                                            BusinessContactFields.WEBSITE,
                                        ])}
                                    />
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default BusinessContact;
