import { useCallback, useState } from "react";

import { VerifyPhoneMobileMoneyRequest } from "../../../../Services/Zambia/mobileMoney.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";
import { verifyPhoneMobileMoney } from "../../../../Services/Zambia/mobileMoney";

interface UseMakePaymentInterface {
    makePaymentError: string;
    isMakePaymentLoading: boolean;
    handleMakePayment: (_data: VerifyPhoneMobileMoneyRequest) => Promise<void>;
}

interface Props {
    onComplete: () => void;
}

function useMakePayment(props: Props): UseMakePaymentInterface {
    const dispatch = useDispatch();
    const [makePaymentError, setMakePaymentError] = useState<string>("");
    const [isMakePaymentLoading, setIsMakePaymentLoading] = useState<boolean>(false);

    const handleMakePayment = useCallback(
        async (_data: VerifyPhoneMobileMoneyRequest) => {
            try {
                setMakePaymentError("");
                setIsMakePaymentLoading(true);
                await verifyPhoneMobileMoney(_data);
                props.onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setMakePaymentError(errorMessage);
            } finally {
                setIsMakePaymentLoading(false);
            }
        },
        [dispatch]
    );

    return {
        makePaymentError,
        isMakePaymentLoading,
        handleMakePayment,
    };
}

export default useMakePayment;
