import CurrencyCode from "../../../../../../components/currency-code";
import PosTransaction from "../../../../../../models/posTransaction";
import React from "react";
import { ReactComponent as SmallTerminal } from "../../../../../../assets/svg/Terminal/terminals-bg.svg";
import TableSummary from "../../../../../../components/Table/Widgets/TableSummary";
import { commaSeparator } from "../../../../../../utils/formatNumber";
import { useAppSelector } from "../../../../../../redux/hooks";

function TerminalTransactionSummary(): JSX.Element {
    const transactions = useAppSelector((state) => state?.terminals?.transactions);
    const totalTransactionCount = transactions?.length || 0;
    const totalTransactionVolume =
        transactions?.reduce((accumulator: number, currentValue: PosTransaction) => accumulator + (Number(currentValue.transactionAmount) || 0), 0) ||
        0;
    const successfulTransactionVolume =
        transactions
            ?.filter((_transaction) => _transaction.isSuccessful)
            .reduce((accumulator: number, currentValue: PosTransaction) => accumulator + (Number(currentValue.transactionAmount) || 0), 0) || 0;
    const successfulTransactionCount = transactions?.filter((_transaction) => _transaction.isSuccessful).length || 0;

    return (
        <TableSummary banner={<SmallTerminal className="pt-4" />}>
            <div className="mr-5 flex h-full w-full items-center space-x-5 px-6">
                <div className="border-r-0.5 border-black-quin pr-5">
                    <p className="whitespace-nowrap text-xs tracking-tighter text-black-tertiary">Total Transaction Volume</p>
                    <p className="text-base font-medium">
                        <CurrencyCode />
                        {commaSeparator(totalTransactionVolume)}
                    </p>
                </div>
                <div className="border-r-0.5 border-black-quin pr-5">
                    <p className="whitespace-nowrap text-xs tracking-tighter text-black-tertiary">Total Transaction Count</p>
                    <p className="text-base font-medium">{totalTransactionCount}</p>
                </div>
                <div className="border-r-0.5 border-black-quin pr-5">
                    <p className="whitespace-nowrap text-xs tracking-tighter text-black-tertiary">Successful Transaction Volume</p>
                    <p className="text-base font-medium">
                        <CurrencyCode />
                        {commaSeparator(successfulTransactionVolume)}
                    </p>
                </div>
                <div>
                    <p className="whitespace-nowrap text-xs tracking-tighter text-black-tertiary">Successful Transaction Count</p>
                    <p className="text-base font-medium">{successfulTransactionCount}</p>
                </div>
            </div>
        </TableSummary>
    );
}

export default TerminalTransactionSummary;
