import {
    InviteSignUpRequest,
    InviteSignUpResponse,
    SignUpRequest,
    SignUpResponse,
    VerifySignUpInviteRequest,
    VerifySignUpInviteResponse,
} from "./sign-up-api.types";
import { abortRequest, getAbortControllerSignal } from "../../../../helpers/request/abortControllers";

import { GenericObject } from "../../../../helpers/types";
import Parsers from "../../../../utils/parsers";
import { makeRequestWithSignal } from "../../../../helpers/request/makeRequest";

export enum SignupRequests {
    INIT = "signup.init",
    SIGNUP = "signup.signup",
    INVITE_SIGNUP = "signup.invite",
}

export const signup = async (data: SignUpRequest): Promise<SignUpResponse> => {
    const signal = getAbortControllerSignal(SignupRequests.SIGNUP);
    const res = await makeRequestWithSignal("/accounts/signup", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        message: Parsers.string(res.message),
        emailVerification: {
            key: Parsers.string((res.emailVerification as GenericObject).key),
            email: Parsers.string((res.emailVerification as GenericObject).email),
        },
    };
};

export const inviteSignup = async (data: InviteSignUpRequest): Promise<InviteSignUpResponse> => {
    const signal = getAbortControllerSignal(SignupRequests.INVITE_SIGNUP);
    const res = await makeRequestWithSignal("/accounts/signup/invite", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        message: Parsers.string(res.message),
        emailVerification: {
            key: Parsers.string((res.emailVerification as GenericObject).key),
            email: Parsers.string((res.emailVerification as GenericObject).email),
        },
    };
};

export const verifyInvite = async (data: VerifySignUpInviteRequest): Promise<VerifySignUpInviteResponse> => {
    const signal = getAbortControllerSignal(SignupRequests.INVITE_SIGNUP);
    const res = await makeRequestWithSignal(`/accounts/${data.auth}/invite/init`, data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        email: Parsers.string(res.email),
        phone: Parsers.string(res.phone),
        lastName: Parsers.string(res.lastName),
        firstName: Parsers.string(res.firstName),
        businessName: Parsers.string(res.businessName),
    };
};

export function abortSignUpRequest(type: SignupRequests): void {
    abortRequest(type);
}

export function abortAllSignUpRequests(): void {
    Object.values(SignupRequests).forEach((type) => abortRequest(type));
}
