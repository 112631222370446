import ChangePassword from "../Components/security/change-password/ChangePassword";
import MobileAppSettings from "../Components/security/mobile-app/MobileAppSettings";
import { PageTitle } from "../../../../helpers/AppConstants";
import React from "react";
import TokenAppSettings from "../Components/security/token-app/TokenAppSettings";
import PageLayout from "../../../../components/layouts/page-layout";

function Security(): JSX.Element {
    document.title = PageTitle.SECURITY_PAGE;

    return (
        <>
            <PageLayout pageTitle="Security" subTitle="Manage your Lenco account's security">
                <div className="flex h-full w-full flex-col">
                    <div className="flex w-full flex-col pr-0 lg:h-full lg:w-4/5 lg:flex-row 2xl:w-3/5">
                        <div className="flex w-full flex-col gap-y-6">
                            <ChangePassword />
                            <TokenAppSettings />
                            <MobileAppSettings />
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default Security;
