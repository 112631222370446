import { ReactComponent as Android } from "../../../../../../../assets/svg/Android.svg";
import { ReactComponent as AppleStore } from "../../../../../../../assets/svg/AppleStore.svg";
import { Link } from "react-router-dom";
import React from "react";

// const AppLinks = {
//     AppleAppStore: process.env.REACT_APP_TOKEN_APP_APPLE_STORE_URL,
//     GooglePlayStore: process.env.REACT_APP_TOKEN_APP_GOOGLE_PLAY_URL,
// };

function DownloadTokenApp(): JSX.Element {
    return (
        <div className="space-y-6">
            <div className="flex w-full flex-col items-start justify-start space-y-3">
                <span className="text-sm font-semibold text-black-secondary">Step 1</span>
                <span className="text-sm font-normal text-black-secondary">Download the Lenco Token App</span>
            </div>
            <div className="flex flex-col items-start justify-start space-x-0 space-y-4 rounded-custom bg-grey-backdrop py-7 md:flex-row md:justify-center md:space-x-2 md:space-y-0">
                <Link to="https://play.google.com/store/apps/details?id=co.lencotoken" target="_blank" rel="noopener noreferrer">
                    <Android className="h-10" />
                </Link>
                <Link to="https://apps.apple.com/us/app/lenco-banking-token/id1569340897" target="_blank" rel="noopener noreferrer">
                    <AppleStore className="h-10" />
                </Link>
            </div>
        </div>
    );
}

export default DownloadTokenApp;
