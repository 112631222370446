import {
    ErrorMessage,
    makeRequest,
    makeRequestDownloadFile,
    makeRequestUploadFileWithSignal,
    makeRequestWithSignal,
} from "../../../../../helpers/request/makeRequest";
import {
    ExternalApplicationResponse,
    ExternalDirectorIdentificationRequest,
    ExternalInitRequest,
    ExternalInitResponse,
} from "./externalApplication.types";
import {
    IdentificationRequest,
    NextOfKinForm,
    SignatoryPersonalDetailsForm,
} from "../../../../dashboard/applicationn/services/nigeria-application/registered/currentSignatory.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";

import { GenericObject } from "../../../../../helpers/types";
import KinRelationship from "../../../../../models/meta/kinRelationship";
import MetaEntity from "../../../../../models/meta/metaEntity";
import OtherDirector from "../../../../../models/account-opening/otherDirector";
import Parsers from "../../../../../utils/parsers";
import { toFormData } from "../../../../../utils/converters";

export enum ExternalApplicationRequests {
    INIT = "external-application.init",
    UPDATE_EXTERNAL_DIRECTOR_BIO = "external-application.update-external-director-bio",
    UPDATE_EXTERNAL_DIRECTOR_NEXT_OF_KIN = "external-application.update-external-director-next-of-kin",
    UPLOAD_EXTERNAL_DIRECTOR_ID = "external-application.upload-external-director-id",
    SET_SIGNATORY_AS_DIRECTOR = "external-application.preview-external-director-id",
    FINISH_EXTERNAL_DIRECTOR_APPLICATION = "external-application.finish-external-director-application",
}

function getExternalInitResponse(res: GenericObject | Error): ExternalApplicationResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        director: Parsers.classObjectNonNullable(res.director, OtherDirector),
    };
}

export const externalDirectorInit = async (_data: ExternalInitRequest): Promise<ExternalInitResponse> => {
    const res = await makeRequest("/account-opening/ng/external/director/init", _data || {});
    if (res instanceof Error) {
        throw res;
    }
    const options: GenericObject = res.options as GenericObject;
    return {
        businessName: Parsers.string(res.businessName),
        creator: Parsers.string(res.creator),
        director: Parsers.classObjectNonNullable(res.director, OtherDirector),
        options: {
            gender: Parsers.classObjectArray(options.gender, MetaEntity),
            nameTitle: Parsers.classObjectArray(options.nameTitle, MetaEntity),
            idType: Parsers.classObjectArray(options.idType, MetaEntity),
            role: Parsers.classObjectArray(options.role, MetaEntity),
            kinRelationship: Parsers.classObjectArray(options.kinRelationship, KinRelationship),
            state: Parsers.classObjectArray(options.state, MetaEntity),
        },
    };
};

export const updateExternalDirectorBio = async (
    _init: ExternalInitRequest,
    _data: SignatoryPersonalDetailsForm
): Promise<ExternalApplicationResponse> => {
    const signal = getAbortControllerSignal(ExternalApplicationRequests.UPDATE_EXTERNAL_DIRECTOR_BIO);
    const res = await makeRequestWithSignal("/account-opening/ng/external/director/bio/update", { ..._init, ..._data }, signal);
    return getExternalInitResponse(res);
};
export const updateExternalDirectorNextOfKin = async (_init: ExternalInitRequest, _data: NextOfKinForm): Promise<ExternalApplicationResponse> => {
    const signal = getAbortControllerSignal(ExternalApplicationRequests.UPDATE_EXTERNAL_DIRECTOR_NEXT_OF_KIN);
    const res = await makeRequestWithSignal("/account-opening/ng/external/director/next-of-kin/update", { ..._init, ..._data }, signal);
    return getExternalInitResponse(res);
};
export const uploadExternalDirectorId = async (_init: ExternalInitRequest, _data: IdentificationRequest): Promise<ExternalApplicationResponse> => {
    const signal = getAbortControllerSignal(ExternalApplicationRequests.UPLOAD_EXTERNAL_DIRECTOR_ID);
    const res = await makeRequestUploadFileWithSignal(
        "/account-opening/ng/external/director/identification/upload",
        toFormData({ ..._init, ..._data }),
        signal
    );
    return getExternalInitResponse(res);
};
export const previewExternalDirectorId = async (_init: ExternalInitRequest): Promise<Blob> => {
    const res = await makeRequestDownloadFile("/account-opening/ng/external/director/identification/preview", _init);
    if (res instanceof Error) {
        throw new Error(ErrorMessage.GENERIC_ERROR);
    }
    return res;
};
export const finishExternalDirectorApplication = async (_data: ExternalDirectorIdentificationRequest): Promise<void> => {
    const signal = getAbortControllerSignal(ExternalApplicationRequests.FINISH_EXTERNAL_DIRECTOR_APPLICATION);
    const res = await makeRequestWithSignal("/account-opening/ng/external/director/finish", _data, signal);
    if (res instanceof Error) {
        throw new Error(ErrorMessage.GENERIC_ERROR);
    }
};

export function abortExternalApplicationRequest(type: ExternalApplicationRequests): void {
    abortRequest(type);
}

export function abortAllExternalApplicationRequests(): void {
    Object.values(ExternalApplicationRequests).forEach((type) => abortRequest(type));
}
