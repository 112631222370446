import "yup-phone";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useRef, useState } from "react";
import CardCustomization from "../cardDescription/CardCustomization";
import CardSelection from "../cardDescription/CardSelection";
import CardsDeliveryDetails from "../cardDescription/CardsDeliveryDetails";
import Modal from "../../../../../../components/modal/Modal";
import ModalHeader from "../../../../../../components/modal/modal-header";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import { ReactComponent as VerveCard } from "../../../../../../assets/svg/Cards/VerveCard.svg";
import { ReactComponent as CheckGreenCircle } from "../../../../../../assets/svg/Cards/Illustration-success.svg";
import useRequestNewCard from "../../../Hooks/State/useRequestNewCard";
import LencoButton from "../../../../../../components/button/button";
import RequestCardHeeder from "./RequestCardHeeder";
import { CardAccessForm, CardUsageStage, CreateCardProps } from "../../../Types";
import CardHolder from "../cardDescription/CardHolder";
import ModalBody from "../../../../../../components/modal/modal-body";
import { RequestNewCardRequest } from "../../../Services/cardsApi.types";

function CardUsageModal(props: CreateCardProps): JSX.Element {
    const { isRequestNewCardLoading, handleRequestNewCard, handleReset, InitialCardAccessState, CardAccessFormValidation } = useRequestNewCard();

    const formikRef = useRef<FormikProps<CardAccessForm> | null>(null);

    const [stage, setStage] = useState<CardUsageStage>(CardUsageStage.USAGE_DESCRIPTION);

    const onSubmit = useCallback(async (values: RequestNewCardRequest) => {
        await handleRequestNewCard({ ...values });
        formikRef.current?.resetForm();
        setStage(CardUsageStage.CARD_REQUEST);
    }, []);

    const handleCloseModal = useCallback(() => {
        props.toggler();
        formikRef.current?.resetForm();
        handleReset();
        setStage(CardUsageStage.USAGE_DESCRIPTION);
    }, []);

    const onNextStage = useCallback((nextStage: CardUsageStage) => {
        setStage(nextStage);
    }, []);

    return (
        <Modal size="md" active={props.active} toggler={handleCloseModal}>
            <ModalHeader subTitle="" onClose={handleCloseModal}>
                <RequestCardHeeder stage={stage} />
            </ModalHeader>

            <Formik
                innerRef={formikRef}
                initialValues={{
                    ...InitialCardAccessState,
                }}
                validationSchema={CardAccessFormValidation}
                onSubmit={onSubmit}
                enableReinitialize
                validateOnMount
                validateOnChange
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            {stage === CardUsageStage.USAGE_DESCRIPTION && (
                                <CardSelection {...formik} onCancel={handleCloseModal} onNext={onNextStage} />
                            )}
                            {stage === CardUsageStage.CUSTOMIZATION && <CardCustomization {...formik} onNext={onNextStage} />}
                            {stage === CardUsageStage.HOLDER && <CardHolder formikRef={formikRef.current} onNext={onNextStage} />}
                            {stage === CardUsageStage.DELIVERY_DETAILS && (
                                <CardsDeliveryDetails isLoading={isRequestNewCardLoading} formikRef={formikRef.current} onNext={onNextStage} />
                            )}
                            {stage === CardUsageStage.CARD_REQUEST && (
                                <div className="mx-auto -mt-6">
                                    <ModalBody>
                                        <div className="flex w-full flex-col items-center space-y-2">
                                            <CheckGreenCircle />
                                            <p className="text-2xl font-medium text-black">Card Request Received</p>
                                            <div className="flex flex-col items-center">
                                                <p className="text-sm font-normal text-black-tertiary">
                                                    Your request has been received and is being processed.
                                                </p>
                                                <VerveCard />
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="-mt-10 flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                                            <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                <LencoButton
                                                    type="button"
                                                    color="primary"
                                                    size="sm"
                                                    variant="solid"
                                                    isText
                                                    onClick={handleCloseModal}
                                                >
                                                    <span className="normal-case tracking-wider">Done</span>
                                                </LencoButton>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

export default CardUsageModal;
