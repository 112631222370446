import {
    addToActiveOpenReassignCardForms,
    addToActiveOpenedCardActivationForms,
    closeSelectedCardDetails,
    openSelectedCardDetails,
    setActiveCardTraySections,
    setSelectedCardDetails,
} from "../../../../../redux/cards/slice/cardsSlice";
import { useDispatch, useSelector } from "react-redux";

import { CardSectionEnum } from "../../Services/cardsApi.types";
import { IRootState } from "../../../../../redux/rootReducer";
import { PaymentsType } from "../../../payments-new/hooks/state/send-money/payments.constants";
import { useCallback } from "react";
import useFreezeCard from "./useFreezeCard";
import { useNavigate } from "react-router-dom";

interface UseCardTray {
    isFreezeCardLoading: boolean;
    isUnFreezeCardLoading: boolean;
    activeCardTraySections: CardSectionEnum[];
    onOpenActivateCardSection: (is: string) => void;
    onOpenSetPaymentChannelsCardSection: (is: string) => void;
    onOpenReassignCardCardSection: (is: string) => void;
    onOpenSpendingLimitCardSection: (is: string) => void;
    onOpenCancelCardSection: (is: string) => void;
    onSendMoney: (is: string, cardId: string) => void;
    onFreezeCard: (is: string) => void;
    onUnfreezeCard: (id: string) => void;
    onOpenDefaultCardSection: () => void;
    onOpenRequestDefaultPINSection: () => void;
    onOpenSecurityCardSection: (id: string) => void;
}

function useCardTray(): UseCardTray {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activeCardTraySections, selectedCardDetails } = useSelector((state: IRootState) => state.cards);

    const { isFreezeCardLoading, isUnFreezeCardLoading, handleFreezeCard, handleUnFreezeCard } = useFreezeCard();

    const onOpenDefaultCardSection = useCallback(() => {
        dispatch(
            setActiveCardTraySections([
                CardSectionEnum.CREDIT_CARD_SECTION,
                CardSectionEnum.ASSIGN_CARD_TO_SECTION,
                CardSectionEnum.RESENT_TRANSACTION_SECTION,
                CardSectionEnum.ACCOUNT_LINKED_TO_CARD,
                ...(selectedCardDetails?.card?.linkedAccount ? [CardSectionEnum.ACCOUNT_LINKED_TO_CARD] : []),
                ...(!(
                    selectedCardDetails?.card?.isActive ||
                    selectedCardDetails?.card?.isFrozen ||
                    selectedCardDetails?.card?.isExpired ||
                    selectedCardDetails?.card?.isDeactivated
                )
                    ? [CardSectionEnum.CARD_REQUEST_STATUS_UPDATE_SECTION]
                    : []),
            ])
        );
    }, []);

    const onOpenActivateCardSection = useCallback((id: string) => {
        dispatch(setSelectedCardDetails(id));
        dispatch(addToActiveOpenedCardActivationForms(id));
        dispatch(setActiveCardTraySections([CardSectionEnum.CREDIT_CARD_SECTION, CardSectionEnum.CARD_REQUEST_STATUS_UPDATE_SECTION]));
        dispatch(openSelectedCardDetails());
    }, []);

    const onOpenSecurityCardSection = useCallback((id: string) => {
        dispatch(setSelectedCardDetails(id));
        // dispatch(addToActiveOpenedCardActivationForms(id));
        dispatch(
            setActiveCardTraySections([CardSectionEnum.CREDIT_CARD_SECTION, CardSectionEnum.ASSIGN_CARD_TO_SECTION, CardSectionEnum.SECURITY_CVV])
        );
        dispatch(openSelectedCardDetails());
    }, []);

    const onOpenSetPaymentChannelsCardSection = useCallback((id: string) => {
        dispatch(setSelectedCardDetails(id));
        dispatch(setActiveCardTraySections([CardSectionEnum.CREDIT_CARD_SECTION, CardSectionEnum.SET_CARD_PAYMENT_CHANNEL_SECTION]));
        dispatch(openSelectedCardDetails());
    }, []);

    const onOpenRequestDefaultPINSection = useCallback(() => {
        dispatch(setActiveCardTraySections([CardSectionEnum.CREDIT_CARD_SECTION, CardSectionEnum.REQUEST_DEFAULT_PIN]));
        dispatch(openSelectedCardDetails());
    }, []);

    const onOpenReassignCardCardSection = useCallback((id: string) => {
        dispatch(setSelectedCardDetails(id));
        dispatch(addToActiveOpenReassignCardForms(id));
        dispatch(
            setActiveCardTraySections([
                CardSectionEnum.CREDIT_CARD_SECTION,
                CardSectionEnum.ASSIGN_CARD_TO_SECTION,
                // CardSectionEnum.ACCOUNT_LINKED_TO_CARD,
            ])
        );
        dispatch(openSelectedCardDetails());
    }, []);

    const onOpenSpendingLimitCardSection = useCallback((id: string) => {
        dispatch(setSelectedCardDetails(id));
        dispatch(
            setActiveCardTraySections([
                CardSectionEnum.CREDIT_CARD_SECTION,
                // CardSectionEnum.ASSIGN_CARD_TO_SECTION,
                // CardSectionEnum.ACCOUNT_LINKED_TO_CARD,
                CardSectionEnum.UPDATE_CARD_SPENDING_LIMIT_SECTION,
            ])
        );
        dispatch(openSelectedCardDetails());
    }, []);

    const onOpenCancelCardSection = useCallback((id: string) => {
        dispatch(setSelectedCardDetails(id));
        dispatch(setActiveCardTraySections([CardSectionEnum.CREDIT_CARD_SECTION, CardSectionEnum.CANCEL_CARD_SECTION]));
        dispatch(openSelectedCardDetails());
    }, []);

    const onSendMoney = useCallback((id: string, cardId: string) => {
        navigate("/payments/make/single", {
            state: {
                typeOfTransfer: PaymentsType.TRANSFER_BETWEEN_ACCOUNT,
                from: cardId,
                pageFrom: "cards",
                temp: true,
            },
        });
        dispatch(setSelectedCardDetails(id));
        dispatch(setActiveCardTraySections([]));
        dispatch(closeSelectedCardDetails());
    }, []);

    const onFreezeCard = useCallback((id: string) => {
        // dispatch(setSelectedCardDetails(id));
        // onOpenDefaultCardSection();
        handleFreezeCard({ createRequestId: id });
        // dispatch(openSelectedCardDetails());
    }, []);

    const onUnfreezeCard = useCallback((id: string) => {
        // dispatch(setSelectedCardDetails(id));
        // onOpenDefaultCardSection();
        handleUnFreezeCard({ createRequestId: id });
        // dispatch(openSelectedCardDetails());
    }, []);

    return {
        isFreezeCardLoading,
        isUnFreezeCardLoading,
        activeCardTraySections,
        onOpenActivateCardSection,
        onOpenSetPaymentChannelsCardSection,
        onOpenReassignCardCardSection,
        onOpenSpendingLimitCardSection,
        onOpenCancelCardSection,
        onSendMoney,
        onOpenDefaultCardSection,
        onFreezeCard,
        onUnfreezeCard,
        onOpenRequestDefaultPINSection,
        onOpenSecurityCardSection,
    };
}

export default useCardTray;
