import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import TransferOutRecipient from "./transferOutRecipient";
import { immerable } from "immer";

export default class TransferOut {
    [immerable] = true;

    private constructor(
        public recipient: TransferOutRecipient,
        public sessionId: string | null
    ) {}

    static create(obj: GenericObject): TransferOut {
        return new TransferOut(Parsers.classObjectNonNullable(obj.recipient, TransferOutRecipient), Parsers.nullableString(obj.sessionId));
    }
}
