import { PurchaseType, TerminalRequestStatus } from "./terminal.constants";

import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import RequestedBy from "./RequestedBy";
import UserAccount from "./userAccount";
import { immerable } from "immer";
import { TextType } from "../components/Table/Type/TextType";

export default class TerminalRequest {
    [immerable] = true;

    constructor(
        public id: string,
        public createdAt: Date | null,
        public estimatedDailyTransactionVolume: string,
        public estimatedDailyTransactionCount: string,
        public deliveryAddress: string,
        public debitAccount: UserAccount | null,
        public purchaseType: PurchaseType,
        public numUnits: number,
        public unitPurchaseCost: string | null,
        public unitCautionFee: string | null,
        public requestedBy: RequestedBy | null,
        public status: TerminalRequestStatus,
        public assignedTerminalIds: string[] | null,
        public declineReason: string | null,
        public requestedAt: Date | null,
        public declinedAt: Date | null,
        public approvedAt: Date | null,
        public debitedAt: Date | null,
        public terminalAssignedAt: Date | null,
        public shippedAt: Date | null,
        public deliveredAt: Date | null
    ) {}

    static create(obj: GenericObject): TerminalRequest {
        const assignedTerminalIds = obj.assignedTerminalIds ? obj.assignedTerminalIds : [];
        return new TerminalRequest(
            Parsers.string(obj.id),
            Parsers.date(obj.createdAt),
            Parsers.string(obj.estimatedDailyTransactionVolume),
            Parsers.string(obj.estimatedDailyTransactionCount),
            Parsers.string(obj.deliveryAddress),
            Parsers.classObject(obj.debitAccount, UserAccount),
            Parsers.number(obj.purchaseType),
            Parsers.number(obj.numUnits),

            Parsers.nullableString(obj.unitPurchaseCost),
            Parsers.nullableString(obj.unitCautionFee),
            Parsers.classObject(obj.requestedBy, RequestedBy),
            Parsers.number(obj.status),
            Parsers.stringArray(assignedTerminalIds),
            Parsers.nullableString(obj.declineReason),
            Parsers.date(obj.requestedAt),
            Parsers.date(obj.declinedAt),
            Parsers.date(obj.approvedAt),
            Parsers.date(obj.debitedAt),
            Parsers.date(obj.terminalAssignedAt),
            Parsers.date(obj.shippedAt),
            Parsers.date(obj.deliveredAt)
        );
    }
    get getPurchaseText(): string {
        return this.purchaseType === PurchaseType.LEASE ? "Lease POS" : "Outright Purchase";
    }
    get isRequested(): boolean {
        return this.status === TerminalRequestStatus.REQUESTED;
    }
    get isApproved(): boolean {
        return this.status === TerminalRequestStatus.APPROVED;
    }
    get isDeclined(): boolean {
        return this.status === TerminalRequestStatus.DECLINED;
    }
    get isAssigned(): boolean {
        return this.status === TerminalRequestStatus.TERMINAL_ASSIGNED;
    }
    get isShipped(): boolean {
        return this.status === TerminalRequestStatus.SHIPPED;
    }
    get isDelivered(): boolean {
        return this.status === TerminalRequestStatus.DELIVERED;
    }

    get deliveredDate(): Date | null {
        return this.deliveredAt;
    }

    get getStatusText(): string {
        return this.status === TerminalRequestStatus.APPROVED
            ? "Approved"
            : this.status === TerminalRequestStatus.DECLINED
              ? "Declined"
              : this.status === TerminalRequestStatus.DELIVERED
                ? "Delivered"
                : this.status === TerminalRequestStatus.REQUESTED
                  ? "Requested"
                  : this.status === TerminalRequestStatus.SHIPPED
                    ? "Shipped"
                    : "Terminal Assigned";
    }

    get requestTableStatus(): TextType {
        return this.status === TerminalRequestStatus.APPROVED
            ? TextType.TERMINAL_REQUEST_STATUS_APPROVED
            : this.status === TerminalRequestStatus.DECLINED
              ? TextType.TERMINAL_REQUEST_STATUS_DECLINED
              : this.status === TerminalRequestStatus.DELIVERED
                ? TextType.TERMINAL_REQUEST_STATUS_DELIVERED
                : this.status === TerminalRequestStatus.REQUESTED
                  ? TextType.TERMINAL_REQUEST_STATUS_REQUESTED
                  : this.status === TerminalRequestStatus.SHIPPED
                    ? TextType.TERMINAL_REQUEST_STATUS_SHIPPED
                    : TextType.TERMINAL_REQUEST_STATUS_TERMINAL_ASSIGNED;
    }

    get requestByFullName(): string {
        return `${this.requestedBy?.firstName} ${this.requestedBy?.lastName}`;
    }
}
