import AddMoneyCard from "./Card/AddMoneyCard";
import ButtonComp from "../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../redux/rootReducer";
import MessageToast from "../../../../../../../components/message-toast";
import Modal from "../../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../../components/modal/modal-header";
import React from "react";
import { useSelector } from "react-redux";

interface Props {
    selectedAccountId: string;
    handleDone: () => void;
    handleBack: () => void;
    handleClose: () => void;
}

function BankTransfer(props: Props): JSX.Element {
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const addMoneyBankAccount = useSelector((state: IRootState) => state.init.main?.meta.addMoneyBankAccount);

    const account = useSelector(
        (state: IRootState) => state.init.main?.companyDetails.accounts.find((acc) => acc.id === props.selectedAccountId) || null
    );

    return (
        <>
            <Modal size="md" active toggler={props.handleClose}>
                <ModalHeader onClose={props.handleClose} subTitle="You can add money to your account via Bank Transfer">
                    Add Money
                </ModalHeader>
                <ModalBody>
                    <div className="flex w-full flex-col space-y-4">
                        <AddMoneyCard
                            accountName={addMoneyBankAccount?.accountName || ""}
                            accountNumber={addMoneyBankAccount?.accountNumber || ""}
                            medium={addMoneyBankAccount?.bank || ""}
                            branch={addMoneyBankAccount?.branch || ""}
                            swift={addMoneyBankAccount?.swiftCode || ""}
                        />
                        <MessageToast
                            message={
                                <div className="flex w-full flex-col space-y-2">
                                    <h6 className="text-sm font-medium text-black-secondary">Fill out the memo as written below</h6>
                                    <p className="text-xs text-black-tertiary">
                                        FFC - {account?.accountNumber || ""} - {account?.accountName || ""}
                                    </p>
                                </div>
                            }
                            type="info"
                            fullWidth
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                        {(accounts?.length || 0) > 1 && (
                            <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleBack} fullWidth>
                                    <span>Back</span>
                                </ButtonComp>
                            </div>
                        )}
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <ButtonComp type="button" color="black" ripple="light" buttonType="primary" fullWidth func={props.handleDone}>
                                <span>I&apos;ve Sent the Money</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default BankTransfer;
