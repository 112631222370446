import ButtonComp from "../../../../components/button/ButtonComp";
import { ReactComponent as CheckCircle } from "../../../../assets/svg/circle-check.svg";
import { Link } from "react-router-dom";
import LoginFooter from "../../../../components/layouts/components/landing/login-footer";

function ResetPasswordCompleted(): JSX.Element {
    return (
        <>
            <div className="relative mx-auto flex w-full max-w-[476px] flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex w-full flex-col items-center justify-start space-y-8">
                    <div>
                        <CheckCircle />
                    </div>
                    <div className="flex flex-col items-center justify-start space-y-4">
                        <h3 className="text-center text-2xl font-medium text-black">Update successful!</h3>
                        <p className="text-center font-normal text-black-secondary">Your password has been updated.</p>
                    </div>
                    <Link to="/login" className="w-full">
                        <ButtonComp color="black" buttonType="primary" fullWidth>
                            <span>Login to Continue</span>
                        </ButtonComp>
                    </Link>
                </div>
            </div>
            <LoginFooter isLogin />
        </>
    );
}

export default ResetPasswordCompleted;
