import {
    setAddZambiaApiAccessKey,
    setIsZambiaCreateApiAccessKeyModalOpen,
    setSelectedZambiaApiAccessKey,
    setZambiaApiAccessKeySecretKey,
} from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useCallback, useState } from "react";

import { CreateApiAccessKeyRequest } from "../../Services/ApiAccessKeys/apiAccessKeysApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { createApiAccessKey } from "../../Services/ApiAccessKeys/apiAccessKeysApi";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseCreateZambiaApiAccessKeysInterface {
    isCreateZambiaApiAccessKeyLoading: boolean;
    createZambiaApiAccessKeyErrorMessage: string;
    handleCreateZambiaApiAccessKey: (_data: CreateApiAccessKeyRequest) => void;
}

function useCreateZambiaApiAccessKey(): UseCreateZambiaApiAccessKeysInterface {
    const dispatch = useDispatch();
    const [isCreateZambiaApiAccessKeyLoading, setIsCreateZambiaApiAccessKeyLoading] = useState(false);
    const [createZambiaApiAccessKeyErrorMessage, setCreateZambiaApiAccessKeyErrorMessage] = useState<string>("");

    const handleCreateZambiaApiAccessKey = useCallback(
        async (_data: CreateApiAccessKeyRequest) => {
            try {
                setIsCreateZambiaApiAccessKeyLoading(true);
                setCreateZambiaApiAccessKeyErrorMessage("");
                const res = await createApiAccessKey(_data);
                dispatch(setAddZambiaApiAccessKey(res.apiAccessKey));
                dispatch(setSelectedZambiaApiAccessKey(res.apiAccessKey));
                dispatch(setZambiaApiAccessKeySecretKey(res.secretKey));
                dispatch(setIsZambiaCreateApiAccessKeyModalOpen(false));
                dispatch(messageTrue("API Access Key Created Successfully."));

                //Todo ===> set to settings stage
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setCreateZambiaApiAccessKeyErrorMessage(errorMessage);
            } finally {
                setIsCreateZambiaApiAccessKeyLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isCreateZambiaApiAccessKeyLoading,
        createZambiaApiAccessKeyErrorMessage,
        handleCreateZambiaApiAccessKey,
    };
}

export default useCreateZambiaApiAccessKey;
