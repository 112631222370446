import { AllTerminalsStageType, TerminalsStageType } from "../../../redux/terminals/slice/terminalsSlice.types";
import { useEffect, useState } from "react";

import AllTerminals from "./components/AllTerminals";
import AllTerminalsTransactions from "./components/AllTerminalsTransactions";
import { ReactComponent as BlueCirclePlus } from "../../../assets/svg/Button/blue-circled-plus.svg";
import ButtonComp from "../../../components/button/ButtonComp";
import LencoSpinner from "../../../components/spinner";
import NoTerminal from "./components/NoTerminal";
import PageLayout from "../../../components/layouts/page-layout";
import RequestTerminalModal from "./Elements/Modals/TerminalRequestModal";
import { ReactComponent as ShadowedProfile } from "../../../assets/svg/Button/shadowed-profile.svg";
import SpecificTerminal from "./components/SpecificTerminal";
import TerminalRequests from "./components/TerminalRequests";
import TerminalTeamAccessModal from "./Elements/Modals/TerminalTeamAccessModal";
import TerminalTransactionDetailsCard from "./components/Cards/TerminalTransactionDetailsCard/TerminalTransactionDetailsCard";
import TerminalsLayout from "./Elements/Layout/TerminalsLayout";
import { getCurrentUser } from "../../../hooks/app-hooks/get-current-user";
import { resetAllTerminalData } from "../../../redux/terminals/slice/terminalsSlice";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import useTerminals from "./Hooks/State/useTerminals";

export default function Terminals() {
    const dispatch = useDispatch();
    const [isTeamMemberAccessModalOpen, setIsTeamMemberAccessModalOpen] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const { user } = getCurrentUser();

    const terminalStage = useAppSelector((state) => state.terminals.terminalStage);
    const allTerminalStage = useAppSelector((state) => state.terminals.allTerminalStage);

    const { noRequestedOrActiveTerminal } = useAppSelector((state) => state.terminals);

    const { handleTerminalInit, isTerminalInitLoading } = useTerminals();

    useEffect(() => {
        void handleTerminalInit();

        return () => {
            dispatch(resetAllTerminalData());
        };
    }, []);

    return (
        <PageLayout
            pageTitle="Terminals"
            subTitle="Improve your cash collections"
            headerButtons={
                <div className={"flex justify-center space-x-3 " + `${isTerminalInitLoading && " cursor-not-allowed"}`}>
                    {!isTerminalInitLoading && (
                        <ButtonComp
                            color="grey"
                            func={() => setIsTeamMemberAccessModalOpen((prev) => !prev)}
                            type="button"
                            buttonType="secondary"
                            size="md"
                            radiusSize="sm"
                        >
                            <div className="flex h-16 items-center justify-center space-x-1 rounded">
                                <ShadowedProfile />
                                <span>Terminal Access</span>
                            </div>
                        </ButtonComp>
                    )}

                    {user?.hasManageTerminalAccess && !noRequestedOrActiveTerminal && !isTerminalInitLoading && (
                        <ButtonComp
                            color="grey"
                            type="button"
                            buttonType="secondary"
                            radiusSize="xs"
                            size="md"
                            func={() => setShowRequestModal((prev) => !prev)}
                        >
                            <div className="flex items-center justify-center space-x-1">
                                <BlueCirclePlus /> <span>Request a Terminal</span>
                            </div>
                        </ButtonComp>
                    )}
                </div>
            }
        >
            <TerminalTeamAccessModal toggle={() => setIsTeamMemberAccessModalOpen((prev) => !prev)} show={isTeamMemberAccessModalOpen} />
            <RequestTerminalModal active={showRequestModal} toggle={() => setShowRequestModal((prev) => !prev)} />
            <TerminalTransactionDetailsCard />
            {isTerminalInitLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
            {!isTerminalInitLoading && (
                <>
                    <div className="flex h-full w-full flex-col items-start justify-start">
                        {noRequestedOrActiveTerminal !== undefined && noRequestedOrActiveTerminal ? (
                            <NoTerminal toggleRequestTerminalModal={() => setShowRequestModal((prev) => !prev)} />
                        ) : null}

                        {noRequestedOrActiveTerminal !== undefined && !noRequestedOrActiveTerminal ? (
                            <TerminalsLayout>
                                {terminalStage === TerminalsStageType.TERMINAL_TRANSACTION && <AllTerminalsTransactions />}
                                {terminalStage === TerminalsStageType.TERMINAL_REQUEST && <TerminalRequests />}
                                {terminalStage === TerminalsStageType.ALL_TERMINALS &&
                                    allTerminalStage === AllTerminalsStageType.SPECIFIC_TERMINAL_TRANSACTION && <SpecificTerminal />}
                                {terminalStage === TerminalsStageType.ALL_TERMINALS && allTerminalStage === AllTerminalsStageType.INITIAL && (
                                    <AllTerminals />
                                )}
                            </TerminalsLayout>
                        ) : null}
                    </div>
                </>
            )}
        </PageLayout>
    );
}
