import React from "react";
import { DataColumn } from "../Type/DataRow";
import { PendingApprovalType } from "../Type/PendingApprovalType";
import stopEventPropagation from "../utils/stopEventPropagation";
import CancelPayment from "../components/CancelPayment";
import ApprovePayment from "../components/ApprovePayment";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function PendingApproval({ dataColumn, dataType }: Props) {
    const { cancelPaymentParam, approvePaymentParam } = dataColumn;

    return (
        <div data-type={dataType} className="" onClick={stopEventPropagation}>
            {dataColumn.pendingApprovalType === PendingApprovalType.SUCCESS && (
                <div data-type={dataType} className="flex items-center justify-center">
                    <div data-type={dataType} className="items-center rounded-lg bg-success-backdrop text-xs">
                        <div data-type={dataType} className="p-2 px-4 text-success">
                            Approved
                        </div>
                    </div>
                </div>
            )}
            {dataColumn.pendingApprovalType === PendingApprovalType.DECLINED && (
                <div data-type={dataType} className="flex items-center justify-center">
                    <div data-type={dataType} className="items-center rounded-lg bg-error-backdrop text-xs">
                        <div data-type={dataType} className="p-2 px-4 text-error">
                            Declined
                        </div>
                    </div>
                </div>
            )}
            {dataColumn.pendingApprovalType === PendingApprovalType.CANCELLED && (
                <div data-type={dataType} className="flex items-center justify-center">
                    <div data-type={dataType} className="items-center rounded-lg bg-error-backdrop text-xs">
                        <div data-type={dataType} className="p-2 px-4 text-error">
                            Cancelled
                        </div>
                    </div>
                </div>
            )}
            {dataColumn.pendingApprovalType === PendingApprovalType.PENDING_APPROVER_APPROVAL && cancelPaymentParam && approvePaymentParam && (
                <div data-type={dataType} className="items-center justify-center">
                    <ApprovePayment
                        isCancelTransactionLoading={cancelPaymentParam.isLoading}
                        isCancelled={cancelPaymentParam.isCanceled}
                        onOptionSelect={cancelPaymentParam.onOptionSelect}
                        options={cancelPaymentParam.options}
                        onApproveSelect={approvePaymentParam?.onApproveSelect}
                        isApproved={approvePaymentParam.isApprove}
                    />
                </div>
            )}
            {dataColumn.pendingApprovalType === PendingApprovalType.PENDING_INITIATOR_CANCEL && cancelPaymentParam && (
                <div data-type={dataType} className="items-center justify-center">
                    <CancelPayment
                        isCancelTransactionLoading={cancelPaymentParam.isLoading}
                        isCancelled={cancelPaymentParam.isCanceled}
                        onOptionSelect={cancelPaymentParam.onOptionSelect}
                        options={cancelPaymentParam.options}
                    />
                </div>
            )}
        </div>
    );
}
