import React from "react";
import Transaction from "../../../../../../../models/transaction";
import TransactionDetailCommentForm from "./TransactionDetailCommentForm";

interface Props {
    dataType: string;
    transaction: Transaction;
    showTransactionDetailCard: boolean;
}

function TransactionDetailCommentSection({ dataType, transaction, showTransactionDetailCard }: Props): JSX.Element {
    return (
        <>
            {(transaction.isSuccess || transaction.isPendingApproval || transaction.isProcessing || transaction.isFailed) && (
                <div className={`w-full ${!showTransactionDetailCard ? "hidden" : ""}`} data-type={dataType}>
                    <TransactionDetailCommentForm dataType={dataType} transaction={transaction} />
                </div>
            )}
        </>
    );
}

export default TransactionDetailCommentSection;
