import { editUserGroup, removeUserGroup } from "../../../../../redux/people/slice/peopleSlice";

import ButtonComp from "../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../components/google-icon";
import UserGroup from "../../../../../models/userGroup";
import { useDispatch } from "react-redux";

interface UserGroupProps {
    data: UserGroup;
}

function UserGroupItem(props: UserGroupProps): JSX.Element {
    const { data } = props;
    const dispatch = useDispatch();

    return (
        <div className="flex w-full flex-row justify-between">
            <div className="max-w-15 truncate rounded-[4px] bg-grey-secondary px-2 py-1">
                <p className="w-full truncate text-base font-medium leading-[100%] text-black">{data.name}</p>
            </div>
            <div className="flex flex-row items-center justify-center space-x-6">
                <ButtonComp buttonType="tertiary" color="grey" onClick={() => dispatch(editUserGroup(data))}>
                    <GoogleIcon icon="border_color" isOutlined className="text-black-tertiary duration-150 hover:text-blue-secondary" />
                </ButtonComp>

                <ButtonComp buttonType="tertiary" color="grey" onClick={() => dispatch(removeUserGroup(data))}>
                    <GoogleIcon icon="delete" className="text-black-tertiary duration-150 hover:text-error-secondary" />
                </ButtonComp>
            </div>
        </div>
    );
}

export default UserGroupItem;
