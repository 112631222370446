import { useDispatch, useSelector } from "react-redux";

import { ExportTransactionRequest } from "../../Services/transactionApi.types";
import { IRootState } from "../../../../../redux/rootReducer";
import { PageTitle } from "../../../../../helpers/AppConstants";
import { UseExportTransactionsStateInterface } from "./useTransactionsState.type";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { exportTransaction } from "../../Services/transactionApi";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { produce } from "immer";
import { setIsExportTransactionsLoading } from "../../../../../redux/transactions/slice/transactionsSlice";
import { useCallback } from "react";

function useExportTransactionsState(): UseExportTransactionsStateInterface {
    document.title = PageTitle.TRANSACTIONS_PAGE;

    const dispatch = useDispatch();

    const transactionFilterState = useSelector((state: IRootState) => state.transactions.selectedTransactionFilterState);

    const handleExportTransaction = useCallback(
        async (_data: ExportTransactionRequest) => {
            try {
                dispatch(setIsExportTransactionsLoading(true));
                await exportTransaction(_data);
                dispatch(
                    messageTrue({
                        message: "Transactions downloaded Successfully",
                    })
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsExportTransactionsLoading(false));
            }
        },
        [dispatch]
    );

    const handleExportTransactionsDetails = useCallback(
        (data: { type: string }) => {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = { ...draft, ...data });
            });
            void handleExportTransaction({ ...updatedList, ...data });
        },
        [handleExportTransaction, transactionFilterState]
    );

    return {
        handleExportTransactionsDetails,
    };
}

export default useExportTransactionsState;
