import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class TerminalFee {
    [immerable] = true;

    constructor(
        public id: string,
        public feeCap: string,
        public feePercentage: string
    ) {}

    static create(obj: GenericObject): TerminalFee {
        return new TerminalFee(Parsers.string(obj.id), Parsers.string(obj.feeCap), Parsers.string(obj.feePercentage));
    }
}
