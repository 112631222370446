import { makeRequest } from "../../../../../../helpers/request/makeRequest";
import Parsers from "../../../../../../utils/parsers";
import { ChangePasswordRequest, ChangePasswordResponse } from "./change-password.types";

export async function changePassword(form: ChangePasswordRequest): Promise<ChangePasswordResponse> {
    const res = await makeRequest("/main/settings/security/change-password", { ...form });
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        requiresOtp: Parsers.boolean(res.otp),
    };
}

export async function changePasswordWithOtp(form: ChangePasswordRequest, otp: string): Promise<boolean> {
    const res = await makeRequest("/main/settings/security/change-password/otp", { ...form, otp });
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.boolean(res.success);
}
