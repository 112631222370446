import { setAirtimePurchaseAmount } from "../../../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseAirtimePurchaseAmountInterface {
    handleAmountChange: (_amount: string) => void;
}

function useAirtimePurchaseAmount(): UseAirtimePurchaseAmountInterface {
    const dispatch = useDispatch();

    const handleAmountChange = useCallback((_amount: string) => {
        dispatch(setAirtimePurchaseAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useAirtimePurchaseAmount;
