import BusinessPersonalDetailsReview from "../review/BusinessPersonalDetailsReview";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import PreviewCard from "../../../../../../cards/PreviewCard";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

interface Props {
    handlePreviewDocument: (_data: string) => void;
}

function InvitedReview(props: Props): JSX.Element {
    const navigate = useNavigate();
    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const currentSignatory = useSelector((state: IRootState) => state.zambiaApplication.currentSignatory);

    return (
        <>
            {accountOpening && (
                <>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Personal Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessPersonalDetailsReview data={currentSignatory || undefined} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Means of ID</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">add_notes</span>}
                            text={accountOpening?.options.idType?.find((_el) => _el.id === currentSignatory?.identification?.type)?.name || "-"}
                            canNotPreview={!currentSignatory?.identification?.uploadId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.identification?.uploadId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Photograph</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">account_circle</span>}
                            text="Photograph"
                            canNotPreview={!currentSignatory?.passportPhotoUploadId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.passportPhotoUploadId || "")}
                        />
                    </div>
                </>
            )}
        </>
    );
}

export default InvitedReview;
