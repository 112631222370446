import ButtonComp from "../../../../../../components/button/ButtonComp";

interface Props {
    text: string;
    primaryButtonText?: string;
    isNoPrimaryButton?: boolean;

    handleRedo: () => void;
    handlePreviewDocument: () => void;
}

function NewUploadedCard(props: Props): JSX.Element {
    return (
        <div className="flex w-full flex-col items-center justify-center space-y-10">
            <div className="flex h-full w-full flex-col items-center justify-between space-x-0 space-y-4 overflow-x-auto rounded-lg border border-grey px-2 py-4 scrollbar-hide 3xs:h-20 3xs:flex-row 3xs:space-x-4 3xs:space-y-0 3xs:py-0 xs:px-6">
                <div className="flex flex-row items-center space-x-2 3xs:space-x-4">
                    <div className="flex h-full w-max items-center justify-center">
                        <div className="flex h-full w-full max-w-[20px] items-center justify-center overflow-hidden text-xl text-success">
                            <span className="material-symbols-rounded">check</span>
                        </div>
                    </div>
                    <span className="text-sm 3xs:text-base">{props.text}</span>
                </div>

                <div className="flex flex-row justify-start space-x-4">
                    {!props.isNoPrimaryButton && (
                        <ButtonComp color="blue" buttonType="flat" size="sm" func={props.handleRedo}>
                            <div className="flex w-full items-center justify-center">
                                <p className="text-sm font-medium">{props.primaryButtonText || "Re-upload"}</p>
                                <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden text-xl">
                                    <span className="material-symbols-outlined">restart_alt</span>
                                </div>
                            </div>
                        </ButtonComp>
                    )}
                    {!props.isNoPrimaryButton && (
                        <div className="flex w-[1px] items-center justify-center">
                            <div className="h-6 w-full bg-black-quin"></div>
                        </div>
                    )}
                    <ButtonComp color="blue" buttonType="flat" size="sm" func={props.handlePreviewDocument}>
                        <div className="flex w-full items-center justify-center">
                            <p className="text-sm font-medium">Preview</p>
                            <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden text-xl">
                                <span className="material-symbols-outlined icon-fill">visibility</span>
                            </div>
                        </div>
                    </ButtonComp>
                </div>
            </div>
        </div>
    );
}

export default NewUploadedCard;
