import Currency from "../../models/currency";
import { CurrencyCodes } from "../../models/currency.constants";
import { sanitize } from "dompurify";
import { useAppSelector } from "../../redux/hooks";

interface CurrencyCodeProps {
    currency?: Currency | null;
    dataType?: string;
}

export default function CurrencyCode({ currency, dataType }: CurrencyCodeProps): JSX.Element {
    const defaultCurrency = useAppSelector((state) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);

    return (
        <>
            {
                <span
                    data-type={dataType}
                    dangerouslySetInnerHTML={{ __html: sanitize(currency?.htmlString || defaultCurrency?.htmlString || CurrencyCodes.NAIRA) }}
                />
            }
        </>
    );
}
