import React, { useCallback, useEffect, useRef, useState } from "react";
import useCreateSubAccount from "../../Hooks/State/useCreateSubAccount";
import LencoButton from "../../../../../components/button/button";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { AddAccountForm } from "../../Types";
import * as Yup from "yup";
import FormInput from "../../../../../components/inputs/FormInput";
import ButtonComp from "../../../../../components/button/ButtonComp";
import { ToastType } from "../../../../../helpers/app-constants";
import MessageToasts from "../../../../../components/general/MessageToasts/MessageToasts";
import FullPageTray from "../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayHeader from "../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTrayBody from "../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import PendingSubAccountLoader from "../Cards/PendingSubAccountLoader";
import PendingSubAccountCard from "../Cards/PendingSubAccountCard";
import ActivateSubAccountCard from "../Cards/ActivateSubAccountCard";
import { useAppSelector } from "../../../../../redux/hooks";
import UserAccount from "../../../../../models/userAccount";
import ActivateAccountSection from "../Element/ActivateAccountSection";
import { mainUpdateUserAccountCallback } from "../../../../../redux/init/slice/initSlice";
import { useDispatch } from "react-redux";
import { ActivationStatus } from "../../../../../models/userAccount.constants";

const CREATE_SUB_ACCOUNT_DATATYPE = "create_sub_account";

interface CreateSubAccountTrayProps {
    active: boolean;
    toggler: () => void;
}

function CreateSubAccountTray(props: CreateSubAccountTrayProps): JSX.Element {
    const dispatch = useDispatch();

    const { active, toggler } = props;
    const formikRef = useRef<FormikProps<AddAccountForm> | null>(null);
    const [formError, setFormError] = useState("");
    const [numberOfRequest, setNumberOfRequest] = useState(0);
    const mainAccount = useAppSelector((state) => state.init.main?.companyDetails.company.shortName);
    const pendingSubAccountsToBeActivatedList = useAppSelector((state) => state.account.pendingSubAccountsToBeActivatedList);
    const listOfSubAccountIdsToBeActivated = useAppSelector((state) => state.account.listOfSubAccountsToBeActivated);
    const accounts = useAppSelector((state) => state.init.main?.companyDetails.accounts || []);

    const [listOfSubAccountsToBeActivated, setListOfSubAccountsToBeActivated] = useState<UserAccount[]>([]);

    const { createSubAccountError, handleCreateSubAccount, handleClearError, isCreateSubAccountLoading } = useCreateSubAccount();
    const [individualData, setIndividualData] = useState<UserAccount | null>(null);
    const [isActivating, setIsActivating] = useState<boolean>(false);

    const initialFormValues: AddAccountForm = { account_requests: [{ name: "", description: "" }] };
    const initialFormSchema = Yup.object().shape({
        account_requests: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().min(5).required("Name is required"),
                description: Yup.string(),
            })
        ),
    });

    useEffect(() => {
        if (listOfSubAccountIdsToBeActivated?.length < 1 || accounts.length < 1) return;
        setListOfSubAccountsToBeActivated(
            listOfSubAccountIdsToBeActivated.map((a) => accounts.find((_a) => _a.id === a) as UserAccount).filter((el) => el !== undefined)
        );
    }, [listOfSubAccountIdsToBeActivated, accounts]);

    const handleToggle = useCallback(() => {
        toggler();
    }, []);

    const handleActiveSubAccount = useCallback((_userAccount: UserAccount) => {
        setIndividualData(_userAccount);
        setIsActivating(true);
    }, []);

    const handleCloseActiveAccountModal = useCallback(() => {
        setIsActivating(false);
        setIndividualData(null);
    }, []);

    const handleAddAccountSubmission = useCallback(async () => {
        setFormError("");
        setNumberOfRequest(0);

        const errors = await formikRef?.current?.validateForm();
        if (!errors?.account_requests && formikRef?.current?.values?.account_requests) {
            const requests = formikRef?.current?.values?.account_requests;
            setNumberOfRequest(requests?.length || 0);
            requests?.forEach((request) => {
                void handleCreateSubAccount(request);
            });

            // console.log(formikRef?.current?.values, errors);
        } else {
            setFormError((errors as AddAccountForm)?.account_requests?.[0].name?.replaceAll("account_requests[0].", ""));
        }
    }, []);

    const handleCloseTray = useCallback(() => {
        handleToggle();
        setFormError("");
        formikRef?.current?.resetForm({ values: { account_requests: [{ name: "", description: "" }] } });
    }, []);

    const handleAccountActivationCompleted = useCallback((account: UserAccount) => {
        dispatch(
            mainUpdateUserAccountCallback({
                id: account.id,
                callback: (userAccount: UserAccount) => {
                    userAccount.activationStatus = ActivationStatus.ACTIVATED;
                },
            })
        );
    }, []);

    return (
        <FullPageTray active={active} dataType={CREATE_SUB_ACCOUNT_DATATYPE} handleReset={handleCloseTray} withClickOutside>
            <Formik
                onSubmit={() => undefined}
                innerRef={formikRef}
                initialValues={initialFormValues}
                validationSchema={initialFormSchema}
                enableReinitialize
                validateOnMount
            >
                {(formik) => {
                    return (
                        <Form className="h-screen w-full">
                            <FullPageTrayHeader
                                subTitle="Create sub-accounts for your different business purposes. Sub-accounts created will have unique account numbers."
                                toggler={handleCloseTray}
                                dataType={CREATE_SUB_ACCOUNT_DATATYPE}
                            >
                                Create Sub-account
                            </FullPageTrayHeader>
                            <FullPageTrayBody dataType={CREATE_SUB_ACCOUNT_DATATYPE}>
                                <div className="w-full space-y-4">
                                    {(createSubAccountError || formError) && (
                                        <MessageToasts
                                            className="mb-4"
                                            toastMessage={createSubAccountError || formError || ""}
                                            toastType={ToastType.ERROR}
                                            onClick={handleClearError}
                                        />
                                    )}

                                    {listOfSubAccountsToBeActivated.map((_sub, index) => (
                                        <ActivateSubAccountCard
                                            data={_sub}
                                            handleActivate={() => handleActiveSubAccount(_sub)}
                                            key={`${index}-s${_sub.id}`}
                                        />
                                    ))}
                                    {pendingSubAccountsToBeActivatedList.map((_sub, index) => (
                                        <PendingSubAccountCard data={_sub} key={`${index}-${_sub.id}`} />
                                    ))}
                                    {isCreateSubAccountLoading &&
                                        !!numberOfRequest &&
                                        new Array(numberOfRequest).fill(null).map((_, index) => <PendingSubAccountLoader key={index} />)}
                                    <FieldArray name="account_requests">
                                        {(arrayFunc) => (
                                            <div className="flex w-full flex-col items-start justify-start space-y-6">
                                                {formik &&
                                                    formik?.values?.account_requests?.map?.((form, index) => (
                                                        <div className="flex w-full flex-col items-start justify-start" key={index}>
                                                            <div className="flex w-full flex-col items-start space-y-4 text-base font-normal">
                                                                <div className="flex w-full flex-col space-y-4">
                                                                    <div className="w-full">
                                                                        <FormInput
                                                                            type="text"
                                                                            name={`account_requests.${index}.name`}
                                                                            placeholder="Account Name"
                                                                        />
                                                                        <span className="px-4 pt-2 text-xs font-normal text-black-tertiary">
                                                                            {mainAccount || "Main Account Name"}- {form.name || "Sub-account Name"}
                                                                        </span>
                                                                    </div>
                                                                    <FormInput
                                                                        type="textarea"
                                                                        name={`account_requests.${index}.description`}
                                                                        placeholder="Account Description (Optional)"
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        `relative flex w-full items-center ` +
                                                                        `${formik?.values?.account_requests?.length - 1 === index ? "justify-between" : "justify-end"} `
                                                                    }
                                                                >
                                                                    {formik?.values?.account_requests.length - 1 === index && (
                                                                        <div className="relative flex w-full items-center justify-start pb-2">
                                                                            <ButtonComp
                                                                                type="button"
                                                                                buttonType="tertiary"
                                                                                color="blue"
                                                                                size="sm"
                                                                                func={() => arrayFunc?.push({ name: "", account_description: "" })}
                                                                            >
                                                                                <span>Add Another Account</span>
                                                                            </ButtonComp>
                                                                        </div>
                                                                    )}
                                                                    {index >= 1 && (
                                                                        <ButtonComp
                                                                            type="button"
                                                                            color="red"
                                                                            buttonType="tertiary"
                                                                            size="sm"
                                                                            func={() => arrayFunc?.remove?.(index)}
                                                                        >
                                                                            <span>Remove</span>
                                                                        </ButtonComp>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {formik?.values?.account_requests?.length < 1 && (
                                                                    <div className="relative mt-6 flex w-full items-center justify-start pb-2">
                                                                        <ButtonComp
                                                                            type="button"
                                                                            buttonType="tertiary"
                                                                            color="blue"
                                                                            size="sm"
                                                                            func={() => arrayFunc?.push({ name: "", account_description: "" })}
                                                                        >
                                                                            <span>Add Another Account</span>
                                                                        </ButtonComp>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </FieldArray>
                                    {isActivating && (
                                        <ActivateAccountSection
                                            active={isActivating}
                                            data={individualData}
                                            toggler={handleCloseActiveAccountModal}
                                            onCompleteFunc={(account) => handleAccountActivationCompleted(account)}
                                        />
                                    )}
                                </div>
                                <div className="flex w-full items-center space-x-4 pt-6">
                                    <div className="order-2 w-full pt-4 2xs:order-1 2xs:pt-0">
                                        <LencoButton
                                            fullWidth
                                            type="button"
                                            color="primary"
                                            size="sm"
                                            variant="bordered"
                                            isText
                                            onClick={handleCloseTray}
                                        >
                                            <span className="normal-case tracking-wider">Cancel</span>
                                        </LencoButton>
                                    </div>
                                    <div className="order-1 w-full 2xs:order-2">
                                        <LencoButton
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            size="sm"
                                            variant="solid"
                                            onClick={handleAddAccountSubmission}
                                            isLoading={isCreateSubAccountLoading}
                                            isText
                                        >
                                            <span className="normal-case tracking-wider">Create Sub-Accounts</span>
                                        </LencoButton>
                                    </div>
                                </div>
                            </FullPageTrayBody>{" "}
                            {/* <FullPageTrayFooter dataType={CREATE_SUB_ACCOUNT_DATATYPE}></FullPageTrayFooter> */}
                        </Form>
                    );
                }}
            </Formik>
        </FullPageTray>
    );
}

export default CreateSubAccountTray;
