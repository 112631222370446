import AccountOpeningProgress from "../../../../../../models/account-opening/progress/accountOpeningProgress";
import BusinessDetails from "../../../../../../models/account-opening/businessDetails";
import CompanyInfo from "../../../../../../models/account-opening/companyInfo";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import { hintMarkItem } from "../../../components/element/hint/hint.constant";

export enum CompanyStages {
    INITIAL = 1,
    BUSINESS_INFORMATION = 2,
    BUSINESS_CONTACT = 3,
}

export interface CompanyDetailsResponse {
    company: CompanyInfo;
    progress: AccountOpeningProgress;
}

export type CompanyHint = {
    index: number;
    span: JSX.Element;
};

export type CompanyDetailsForm = BusinessDetailsForm & BusinessInformationForm & BusinessContactForm;

export type BusinessDetailsResponse = {
    success: boolean;
    message: string;
    progress: NewAccountOpeningProgress;
    application: BusinessDetails;
};

export type BusinessInformationResponse = {
    success: boolean;
    message: string;
    application: BusinessDetails;
    progress: NewAccountOpeningProgress;
};

export type BusinessDetailsForm = {
    name: string;
    rcbnNumber: string;
};

export enum BusinessInformationFields {
    NAME = "name",
    INDUSTRY = "industryId",
    TURNOVER = "annualTurnoverId",
    TAX_NUMBER = "taxNumber",
    // RCBN_NUMBER = "rcbnNumber",
    SCUML_NUMBER = "scumlNumber",
    BUSINESS_NATURE = "businessNature",
    BUSINESS_CATEGORY = "registrationCategoryId",
    // YEAR_OF_INCORPORATION = "yearOfIncorporation",
}

export type BusinessInformationForm = {
    [BusinessInformationFields.NAME]: string;
    [BusinessInformationFields.INDUSTRY]: number | null;
    [BusinessInformationFields.TURNOVER]: number | null;
    [BusinessInformationFields.TAX_NUMBER]: string;
    // [BusinessInformationFields.RCBN_NUMBER]: string;
    [BusinessInformationFields.SCUML_NUMBER]: string;
    [BusinessInformationFields.BUSINESS_NATURE]: string;
    [BusinessInformationFields.BUSINESS_CATEGORY]: number | null;
    // [BusinessInformationFields.YEAR_OF_INCORPORATION]: string | null;
};

export const BusinessInformationFieldTexts: {
    [type in BusinessInformationFields]: string;
} = {
    [BusinessInformationFields.NAME]: "Legal Business Name",
    [BusinessInformationFields.INDUSTRY]: "Sector/Industry",
    [BusinessInformationFields.TURNOVER]: "Annual Turnover",
    [BusinessInformationFields.TAX_NUMBER]: "TIN",
    // [BusinessInformationFields.RCBN_NUMBER]: "RC Number",
    [BusinessInformationFields.SCUML_NUMBER]: "SCUML Number",
    [BusinessInformationFields.BUSINESS_NATURE]: "Describe Your Business",
    [BusinessInformationFields.BUSINESS_CATEGORY]: "Business Type",
    // [BusinessInformationFields.YEAR_OF_INCORPORATION]: "Year of Incorporation",
};

export const BusinessInformationTextArray = [
    { formikName: BusinessInformationFields.NAME, text: BusinessInformationFieldTexts[BusinessInformationFields.NAME] },
    { formikName: BusinessInformationFields.INDUSTRY, text: BusinessInformationFieldTexts[BusinessInformationFields.INDUSTRY] },
    { formikName: BusinessInformationFields.TURNOVER, text: BusinessInformationFieldTexts[BusinessInformationFields.TURNOVER] },
    { formikName: BusinessInformationFields.TAX_NUMBER, text: BusinessInformationFieldTexts[BusinessInformationFields.TAX_NUMBER] },
    // {formikName: "rcbnNumber", text: BusinessInformationFieldTexts[BusinessInformationFields.RCBN_NUMBER]},
    { formikName: BusinessInformationFields.SCUML_NUMBER, text: BusinessInformationFieldTexts[BusinessInformationFields.SCUML_NUMBER] },
    { formikName: BusinessInformationFields.BUSINESS_NATURE, text: BusinessInformationFieldTexts[BusinessInformationFields.BUSINESS_NATURE] },
    { formikName: BusinessInformationFields.BUSINESS_CATEGORY, text: BusinessInformationFieldTexts[BusinessInformationFields.BUSINESS_CATEGORY] },
    // {formikName: "yearOfIncorporation", text: BusinessInformationFieldTexts[BusinessInformationFields.YEAR_OF_INCORPORATION]},
];

export const BusinessInformationTextMap = BusinessInformationTextArray.reduce((accumulator, obj) => {
    return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getBusinessInformationHints(_formikList: string[], _uncompleted: string[], _exempted: string[]): hintMarkItem[] {
    let computedList: hintMarkItem[] = [];
    const _completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));
    for (const _string of _completed) {
        computedList.push({
            isChecked: true,
            text: BusinessInformationTextMap.get(_string) || "",
        });
    }
    for (const _string of _uncompleted) {
        computedList = computedList.map((_) =>
            _.text === BusinessInformationTextMap.get(_string)
                ? {
                      isChecked: false,
                      text: _.text,
                  }
                : _
        );
    }

    return computedList;
}

export type BusinessContactForm = {
    email: string;
    phone: string;
    website: string;
    operatingAddress: string;
    registeredAddress: string;
    sameRegisteredOperatingAddress: boolean;
};
export enum BusinessContactFields {
    PHONE = "phone",
    EMAIL = "email",
    WEBSITE = "website",
    OPERATING_ADDRESS = "operatingAddress",
    REGISTERED_ADDRESS = "registeredAddress",
    SAME_REGISTERED_OPERATING_ADDRESS = "sameRegisteredOperatingAddress",
}
export const BusinessContactFieldTexts: {
    [type in BusinessContactFields]: string;
} = {
    [BusinessContactFields.PHONE]: "Phone Number",
    [BusinessContactFields.EMAIL]: "Email Address",
    [BusinessContactFields.WEBSITE]: "Website",
    [BusinessContactFields.OPERATING_ADDRESS]: "Operating Business Address",
    [BusinessContactFields.REGISTERED_ADDRESS]: "Registered Business Address",
    [BusinessContactFields.SAME_REGISTERED_OPERATING_ADDRESS]: "Same as Operating Address",
};
export const BusinessContactTextArray = [
    { formikName: BusinessContactFields.PHONE, text: BusinessContactFieldTexts[BusinessContactFields.PHONE] },
    { formikName: BusinessContactFields.EMAIL, text: BusinessContactFieldTexts[BusinessContactFields.EMAIL] },
    { formikName: BusinessContactFields.WEBSITE, text: BusinessContactFieldTexts[BusinessContactFields.WEBSITE] },
    {
        formikName: BusinessContactFields.OPERATING_ADDRESS,
        text: BusinessContactFieldTexts[BusinessContactFields.OPERATING_ADDRESS],
    },
    {
        formikName: BusinessContactFields.REGISTERED_ADDRESS,
        text: BusinessContactFieldTexts[BusinessContactFields.REGISTERED_ADDRESS],
    },
    {
        formikName: BusinessContactFields.SAME_REGISTERED_OPERATING_ADDRESS,
        text: BusinessContactFieldTexts[BusinessContactFields.SAME_REGISTERED_OPERATING_ADDRESS],
    },
];
export const BusinessContactTextMap = BusinessContactTextArray.reduce((accumulator, obj) => {
    return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getBusinessContactHints(_formikList: string[], _uncompleted: string[], _exempted: string[]): hintMarkItem[] {
    let computedList: hintMarkItem[] = [];
    const _completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));
    for (const _string of _completed) {
        computedList.push({
            isChecked: true,
            text: BusinessContactTextMap.get(_string) || "",
        });
    }
    for (const _string of _uncompleted) {
        computedList = computedList.map((_) =>
            _.text === BusinessContactTextMap.get(_string)
                ? {
                      isChecked: false,
                      text: _.text,
                  }
                : _
        );
    }
    // const _completed = _formikList.filter((_) => !_uncompleted.some((_un) => _un === _)).filter((_) => !_exempted.some((_un) => _un === _));
    // for (const _string of _completed) {
    // computedList.push({
    // isChecked: true,
    // text: BusinessContactTextMap.get(_string) || "",
    // });
    // }
    // for (const _string of _uncompleted) {
    // computedList.push({
    // isChecked: false,
    // text: BusinessContactTextMap.get(_string) || "",
    // });
    // }

    return computedList;
}
