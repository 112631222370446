import { setAirtimePurchaseAccountId } from "../../../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseAirtimePurchasePayToInterface {
    handleOnChange: (_vendor: string) => void;
    handleSelectPayToAccount: (_accountId: string) => void;
}

function useAirtimePurchasePayTo(): UseAirtimePurchasePayToInterface {
    const dispatch = useDispatch();

    const handleSelectPayToAccount = useCallback((_accountId: string) => {
        dispatch(setAirtimePurchaseAccountId(_accountId));
    }, []);

    const handleOnChange = useCallback((_vendorId: string) => {
        dispatch(setAirtimePurchaseAccountId(_vendorId));
    }, []);

    return {
        handleOnChange,
        handleSelectPayToAccount,
    };
}

export default useAirtimePurchasePayTo;
