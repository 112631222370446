import { useCallback, useState } from "react";

import { AgreementFieldTexts } from "../../../../services/nigeria-application/registered/new/agreement.types";
import { AgreementType } from "../../../../services/nigeria-application/registered/new/agreement.types";
import { downloadBusinessAgreement } from "../../../../services/nigeria-application/registered/new/agreement.api";
// import {downloadAgreement} from "../../../../services/nigeria-application/registered/agreement.api";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { openBlobInNewTab } from "../../../../../../../utils/blob";
import { useDispatch } from "react-redux";

interface UsePreviewBusinessAgreementInterface {
    previewBlob: Blob | null;
    isPreviewDownloading: boolean;

    handlePreviewDocument: (_data: AgreementType) => Promise<void>;
}
interface Props {
    onComplete?: () => void;
    downloadedDocumentName?: string;
}

const usePreviewBusinessAgreement = (props: Props): UsePreviewBusinessAgreementInterface => {
    const dispatch = useDispatch();

    const [previewBlob, setPreviewBlob] = useState<Blob | null>(null);
    const [isPreviewDownloading, setIsPreviewDownloading] = useState<boolean>(false);

    const handlePreviewDocument = useCallback(
        async (_data: AgreementType) => {
            try {
                setIsPreviewDownloading(true);
                dispatch(messageTrue({ message: "Generating preview" }));
                const blob = await downloadBusinessAgreement({ type: _data });
                dispatch(messageTrue({ message: "Preview generated" }));
                setPreviewBlob(blob);
                if (blob.type === "image/png" || blob.type === "image/jpeg" || blob.type === "image/jpg") {
                    props.onComplete && props.onComplete();
                    return;
                }
                if (blob.type === "application/pdf") {
                    openBlobInNewTab(blob, props.downloadedDocumentName || `${AgreementFieldTexts[_data]} Document` || "Document");
                    return;
                }
            } catch (err) {
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsPreviewDownloading(false);
            }
        },
        [dispatch]
    );

    return {
        previewBlob,
        isPreviewDownloading,

        handlePreviewDocument,
    };
};
export default usePreviewBusinessAgreement;
