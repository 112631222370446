import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class ApplicationIndustry {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string,
        public requireSCUML: boolean
    ) {}
    static create(obj: GenericObject): ApplicationIndustry {
        return new ApplicationIndustry(Parsers.string(obj.id), Parsers.string(obj.name), Parsers.boolean(obj.requireSCUML));
    }
}
