import React, { useCallback } from "react";
import { resetSelectedUserGroup, setPeopleStage } from "../../../../../redux/people/slice/peopleSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../redux/rootReducer";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalHeader from "../../../../../components/modal/modal-header";
import { PeopleStageType } from "../../../../../redux/people/slice/peopleSlice.types";
import UserGroup from "../../../../../models/userGroup";
import UserGroupItem from "../rows/user-group";

function ListOfCategoriesModal(): JSX.Element {
    const dispatch = useDispatch();

    const peopleStage = useSelector((state: IRootState) => state.people.peopleStage);
    const userGroups = useSelector((state: IRootState) => state.init.main?.companyDetails.userGroups.filter((el) => !el.isDeleted) || []).sort(
        (u1: UserGroup, u2: UserGroup) => u1.name.toLowerCase().localeCompare(u2.name.toLowerCase())
    );

    const handleToggle = useCallback(() => {
        dispatch(resetSelectedUserGroup());
        dispatch(setPeopleStage(PeopleStageType.INITIAL));
    }, []);

    return (
        <>
            <Modal size="md" active={!!(peopleStage === PeopleStageType.CATEGORY_LIST)} toggler={handleToggle}>
                <ModalHeader onClose={handleToggle}>Manage Group</ModalHeader>
                <ModalBody>
                    <div className="flex w-full flex-col justify-start space-y-12">
                        <div className="flex w-full flex-col items-center justify-start space-y-4">
                            {userGroups.map((category) => (
                                <UserGroupItem key={category.id} data={category} />
                            ))}
                        </div>
                        <div className="flex w-full items-center justify-between">
                            <ButtonComp color="blue" buttonType="tertiary" onClick={() => dispatch(setPeopleStage(PeopleStageType.CREATE_CATEGORY))}>
                                <span className="normal-case">Create a Group</span>
                            </ButtonComp>
                            <div className="flex items-center justify-start space-x-4">
                                <ButtonComp color="grey" buttonType="secondary" size="lg" onClick={handleToggle}>
                                    Cancel
                                </ButtonComp>
                                <ButtonComp color="black" buttonType="primary" size="lg" onClick={handleToggle}>
                                    Done
                                </ButtonComp>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default ListOfCategoriesModal;
