export type SpinnerSize = "sm" | "md" | "lg";
export type SpinnerColor = "black" | "white" | "blue" | "red";

export type LencoSpinnerProps = {
    size?: SpinnerSize;
    label?: string;
    color?: SpinnerColor;
};

export const getSpinnerColor = (_color: SpinnerColor = "black"): string => {
    if (_color === "white") {
        return "border-b-white text-white";
    } else if (_color === "black") {
        return "border-b-black text-black";
    } else if (_color === "blue") {
        return "border-b-blue text-blue";
    } else if (_color === "red") {
        return "border-b-error text-error";
    } else {
        return "border-b-white text-white";
    }
};
