import { FilterDateType, FilterDateTypes } from "../../../../../../components/filter/filter.constant";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    TransactionFilterTypes,
    transactionStatusList,
    transactionStatusText,
    transactionTypesList,
    transactionTypesText,
} from "../../../../../../redux/transactions/slice/transactionsSlice.types";
import { TransactionFilters, TransactionFiltersData } from "../../../Services/transactions.constants";
import { TransactionStatus, TransactionType } from "../../../../../../models/transaction.constants";
import {
    setIsCustomModalOpen,
    setSelectedFiltersCount,
    setTransactionDate,
    setTransactionFilterState,
    setUpdatedTransactionDateFilter,
} from "../../../../../../redux/transactions/slice/transactionsSlice";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CustomDatePickerModal from "../../Modal/CustomDatePickerModal";
import FilterRow from "../../../../../../components/filter/Row/FilterRow";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import NewDropdownHead from "../../../../../../components/new-dropdown";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

// import { TransactionState } from "../../../../../../redux/transactions/slice/transactionsSlice.types";

interface Props {
    handleClearAll: () => void;
    handleFilteredDetails: (_data: TransactionFilterTypes) => void;
}

function FilterDropdown(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const transactionDate = useAppSelector((state: IRootState) => state.transactions.transactionDate);
    const selectedFiltersCount = useAppSelector((state: IRootState) => state.transactions.selectedFiltersCount);
    const transactionFilterState = useAppSelector((state: IRootState) => state.transactions.transactionFilterState);
    const selectedTransactionDate = useAppSelector((state: IRootState) => state.transactions.selectedTransactionDate);
    const selectedTransactionFilterState = useAppSelector((state: IRootState) => state.transactions.selectedTransactionFilterState);

    const cards = useAppSelector((state: IRootState) => state.init.main?.companyDetails.cards || []);
    const accounts = useAppSelector((state: IRootState) => state.init.main?.companyDetails.accounts || []);
    const categories = useAppSelector((state: IRootState) => state.init.main?.companyDetails.transactionCategories || []);
    const userGroups = useAppSelector(
        (state: IRootState) => state.init.main?.companyDetails.userGroups.filter((_userGroups) => !_userGroups.isDeleted) || []
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeRow, setActiveRow] = useState<TransactionFilters | null>(null);

    const domRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        dispatch(setSelectedFiltersCount(handleCleanFilterState()));
    }, [transactionFilterState]);

    const handleApplyFilter = useCallback(() => {
        setIsOpen(false);
        props.handleFilteredDetails(transactionFilterState);
    }, [transactionFilterState]);

    const handleClearAll = useCallback(() => {
        setIsOpen(false);
        props.handleClearAll();
    }, []);

    const handleOpenFilter = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setIsOpen(false);
        dispatch(setTransactionFilterState(selectedTransactionFilterState));
    }, [selectedTransactionFilterState]);

    const handleAdditionalCheck = useCallback((target: HTMLElement): boolean => {
        return !domRef.current?.contains(target);
    }, []);

    const handleCleanFilterState = () => {
        const arr = Object.values(transactionFilterState).filter((item) => Array.isArray(item) || Object.keys(item).length > 1);

        const cleanFilterState = arr.filter((item) => {
            const count = Array.isArray(item) ? item.length > 0 : Object.values(item).length && Object.values(item)[0].length > 0;
            return count;
        });

        const counter = 0;

        const countFilter = cleanFilterState.map((item) => {
            const count = Array.isArray(item)
                ? counter + item.length
                : Object.values(item).length && Object.values(item)[0].length > 0
                  ? counter + 1
                  : counter;
            return count;
        });

        const sumCountFilter = countFilter.reduce((a, b) => {
            return a + b;
        }, 0);

        return sumCountFilter;
    };

    const handleSetBillPaymentHistoryDate = (_data: FilterDateType) => {
        dispatch(setTransactionFilterState({ ...transactionFilterState, date: _data.date }));
        dispatch(setTransactionDate(_data));
    };
    const handleFilterTransactionHistoryDate = () => {
        const updatedFilter = { ...selectedTransactionFilterState, date: { begin: "", end: "" } };
        dispatch(setTransactionFilterState(updatedFilter));
        dispatch(setUpdatedTransactionDateFilter(FilterDateTypes.ALL_TIME));
    };

    const handleFilterTransactionUserAccountIds = (_value: string) => {
        const updatedList = [
            ...((transactionFilterState?.userAccountIds || []).includes(_value)
                ? (transactionFilterState?.userAccountIds || []).filter((_el) => _el !== _value)
                : [...(transactionFilterState?.userAccountIds || []), _value]),
        ];
        dispatch(setTransactionFilterState({ userAccountIds: updatedList }));
    };

    const handleFilterTransactionCategory = (_value: string) => {
        const updatedList = [
            ...((transactionFilterState?.categoryIds || []).includes(_value)
                ? (transactionFilterState?.categoryIds || []).filter((_el) => _el !== _value)
                : [...(transactionFilterState?.categoryIds || []), _value]),
        ];
        dispatch(setTransactionFilterState({ categoryIds: updatedList }));
    };
    const handleFilterTransactionStatus = (_value: TransactionStatus) => {
        const updatedList = [
            ...((transactionFilterState?.transactionStatuses || []).includes(_value)
                ? (transactionFilterState?.transactionStatuses || []).filter((_el) => _el !== _value)
                : [...(transactionFilterState?.transactionStatuses || []), _value]),
        ];
        dispatch(setTransactionFilterState({ transactionStatuses: updatedList }));
    };

    const handleFilterTransactionType = (_value: TransactionType) => {
        const updatedList = [
            ...((transactionFilterState?.transactionTypes || []).includes(_value)
                ? (transactionFilterState?.transactionTypes || []).filter((_el) => _el !== _value)
                : [...(transactionFilterState?.transactionTypes || []), _value]),
        ];
        dispatch(setTransactionFilterState({ transactionTypes: updatedList }));
    };
    const handleFilterTransactionUserGroupIds = (_value: string) => {
        const updatedList = [
            ...((transactionFilterState?.userGroupIds || []).includes(_value)
                ? (transactionFilterState?.userGroupIds || []).filter((_el) => _el !== _value)
                : [...(transactionFilterState?.userGroupIds || []), _value]),
        ];
        dispatch(setTransactionFilterState({ userGroupIds: updatedList }));
    };

    const handleFilterTransactionCardIds = (_value: string) => {
        const updatedList = [
            ...((transactionFilterState?.cardIds || []).includes(_value)
                ? (transactionFilterState?.cardIds || []).filter((_el) => _el !== _value)
                : [...(transactionFilterState?.cardIds || []), _value]),
        ];
        dispatch(setTransactionFilterState({ cardIds: updatedList }));
    };

    return (
        <div className="w-full" ref={domRef}>
            <CustomDatePickerModal />

            <NewDropdownHead
                content={
                    <div className="flex items-center justify-start space-x-2">
                        <span className="text-sm font-normal">Filter</span>
                        <GoogleIcon icon="filter_list" className="-mr-1" />
                    </div>
                }
                size="md"
                color="grey"
                buttonType="secondary"
                paddingSize="xs"
                dropdownWidth="max"
                isActive={isOpen}
                handleOpen={handleOpenFilter}
                handleClose={handleCloseFilter}
                handleAdditionalCheck={handleAdditionalCheck}
                isNoArrow
                isFreeFormDropdown
            >
                <div className="absolute -top-12 left-20 z-10 mt-[1px] pl-1.5">
                    <div className="w-64 rounded-lg bg-white pb-2.5 shadow-dropdown">
                        <div className="flex h-10 w-full flex-row items-center justify-between px-4">
                            <p className="text-base font-medium text-black-secondary">Filter by</p>
                            <ButtonComp
                                buttonType="tertiary"
                                color="blue"
                                size="sm"
                                disable={
                                    !(
                                        selectedTransactionFilterState.query.length > 0 ||
                                        selectedTransactionFilterState.userAccountIds.length > 0 ||
                                        selectedTransactionFilterState.userGroupIds.length > 0 ||
                                        selectedTransactionFilterState.categoryIds.length > 0 ||
                                        selectedTransactionFilterState.transactionTypes.length > 0 ||
                                        selectedTransactionFilterState.transactionStatuses.length > 0 ||
                                        selectedTransactionFilterState.cardIds.length > 0 ||
                                        selectedTransactionDate !== FilterDateTypes.ALL_TIME
                                    )
                                }
                                func={handleClearAll}
                            >
                                <span className="text-xs font-medium">Clear all</span>
                            </ButtonComp>
                        </div>
                        {/* Filter Menu */}
                        <div className="w-full border-t border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary">
                            {TransactionFiltersData.map((_filter, index) => {
                                // Typescript Hack
                                // Todo ===> Find better solution
                                return (
                                    <React.Fragment key={index}>
                                        {_filter.value === TransactionFilters.DATE && (
                                            <FilterRow
                                                date={transactionDate}
                                                title={_filter.text}
                                                dataType="transactions"
                                                isHover={activeRow === TransactionFilters.DATE}
                                                onHover={() => setActiveRow(TransactionFilters.DATE)}
                                                handleSetDate={handleSetBillPaymentHistoryDate}
                                                handleFilterDate={handleFilterTransactionHistoryDate}
                                                handleOpenDateModal={() => dispatch(setIsCustomModalOpen(true))}
                                                isDate
                                            />
                                        )}

                                        {_filter.value === TransactionFilters.ACCOUNTS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={accounts?.map((_userAccount) => ({
                                                    text: _userAccount.accountName,
                                                    value: _userAccount.id,
                                                }))}
                                                dataType="transactions"
                                                isHover={activeRow === TransactionFilters.ACCOUNTS}
                                                onHover={() => setActiveRow(TransactionFilters.ACCOUNTS)}
                                                filteredList={transactionFilterState.userAccountIds || []}
                                                handleFilterState={handleFilterTransactionUserAccountIds}
                                                // isRadio
                                            />
                                        )}
                                        {_filter.value === TransactionFilters.RECIPIENTS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={userGroups.map((_userAccount) => ({
                                                    text: _userAccount.name,
                                                    value: _userAccount.id,
                                                }))}
                                                dataType="transactions"
                                                isHover={activeRow === TransactionFilters.RECIPIENTS}
                                                onHover={() => setActiveRow(TransactionFilters.RECIPIENTS)}
                                                filteredList={transactionFilterState.userGroupIds || []}
                                                handleFilterState={handleFilterTransactionUserGroupIds}
                                            />
                                        )}
                                        {_filter.value === TransactionFilters.CATEGORIES && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={categories.map((_userAccount) => ({
                                                    text: _userAccount.name,
                                                    value: _userAccount.id,
                                                }))}
                                                dataType="transactions"
                                                isHover={activeRow === TransactionFilters.CATEGORIES}
                                                onHover={() => setActiveRow(TransactionFilters.CATEGORIES)}
                                                filteredList={transactionFilterState.categoryIds || []}
                                                handleFilterState={handleFilterTransactionCategory}
                                                // isRadio
                                            />
                                        )}
                                        {_filter.value === TransactionFilters.TYPE && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={transactionTypesList.map((_) => ({
                                                    text: transactionTypesText[_],
                                                    value: _,
                                                }))}
                                                dataType="transactions"
                                                isHover={activeRow === TransactionFilters.TYPE}
                                                onHover={() => setActiveRow(TransactionFilters.TYPE)}
                                                filteredList={transactionFilterState.transactionTypes || []}
                                                handleFilterState={handleFilterTransactionType}
                                            />
                                        )}
                                        {_filter.value === TransactionFilters.STATUS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={transactionStatusList.map((_) => ({
                                                    text: transactionStatusText[_],
                                                    value: _,
                                                }))}
                                                dataType="transactions"
                                                isHover={activeRow === TransactionFilters.STATUS}
                                                onHover={() => setActiveRow(TransactionFilters.STATUS)}
                                                filteredList={transactionFilterState.transactionStatuses || []}
                                                handleFilterState={handleFilterTransactionStatus}
                                            />
                                        )}
                                        {_filter.value === TransactionFilters.CARDS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={cards.map((_) => ({
                                                    text: _.name,
                                                    value: _.id,
                                                }))}
                                                dataType="transactions"
                                                isHover={activeRow === TransactionFilters.CARDS}
                                                onHover={() => setActiveRow(TransactionFilters.CARDS)}
                                                filteredList={transactionFilterState.cardIds || []}
                                                handleFilterState={handleFilterTransactionCardIds}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}

                            {/* Apply Button */}
                            <div
                                className={`${selectedFiltersCount > 0 ? "w-full justify-between" : "w-full justify-end"} flex items-center px-4 pt-2`}
                            >
                                {selectedFiltersCount > 0 ? (
                                    <p className="text-xs text-black-tertiary">
                                        {selectedFiltersCount} Filter{selectedFiltersCount > 1 && "s"} Selected
                                    </p>
                                ) : null}
                                <ButtonComp
                                    size="sm"
                                    type="button"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    func={handleApplyFilter}
                                    disable={selectedFiltersCount < 1}
                                    borderSmall
                                >
                                    <span className="text-xs">Apply Filter</span>
                                </ButtonComp>
                            </div>
                        </div>
                    </div>
                </div>
            </NewDropdownHead>
        </div>
    );
}

export default FilterDropdown;
