import { resetBillPaymentData, setBillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch, useSelector } from "react-redux";

import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import BillPurchase from "../../../../../../models/billPurchase";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import DataPurchaseHeaders from "./DataPurchaseHeaders";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import ProductDropdown from "../../DropDowns/ProductDropdown";
import RecentBillPaymentSkeleton from "../../Skeletons/RecentBillPaymentSkeleton";
import RecentContactsElement from "../../Elements/RecentContactsElement";
import VendorDropdown from "../../DropDowns/VendorDropdown";
import VerifiedAccountCard from "../../Cards/verified-account-card";
import { abortBillPayment } from "../../../services/billPayment/billPaymentApi";
import { resetAllDataPurchaseData } from "../../../../../../redux/payments/dataPurchase/slice/dataPurchaseSlice";
import { useCallback } from "react";
import useDataPurchaseAmount from "../../../hooks/state/BillPayment/DataPurchase/useDataPurchaseAmount";
import useDataPurchasePayFrom from "../../../hooks/state/BillPayment/DataPurchase/useDataPurchasePayFrom";
import useDataPurchasePayTo from "../../../hooks/state/BillPayment/DataPurchase/useDataPurchasePayTo";
import useDataPurchaseProduct from "../../../hooks/state/BillPayment/DataPurchase/useDataPurchaseProduct";
import useDataPurchaseVendor from "../../../hooks/state/BillPayment/DataPurchase/useDataPurchaseVendor";

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();

    const vendorsList = useSelector((state: IRootState) => state.billPayment.vendorsList);
    const selectedVendorName = useSelector((state: IRootState) => state.dataPurchase.selectedVendorName);
    const recentBillPurchases = useSelector((state: IRootState) => state.billPayment.recentBillPurchases);
    const selectedProductName = useSelector((state: IRootState) => state.dataPurchase.selectedProductName);
    const selectedUserAccount = useSelector((state: IRootState) => state.dataPurchase.selectedUserAccount);
    const selectedPayToAccount = useSelector((state: IRootState) => state.dataPurchase.dataPurchaseDetails.accountId);
    const dataPurchaseDetailAmount = useSelector((state: IRootState) => state.dataPurchase.dataPurchaseDetails.amount);
    const selectedProductFixedAmount = useSelector((state: IRootState) => state.dataPurchase.selectedProductFixedAmount);
    const isRecentBillPurchasesLoading = useSelector((state: IRootState) => state.billPayment.isRecentBillPurchasesLoading);

    const { handleOnChange } = useDataPurchasePayTo();
    const { handleAmountChange } = useDataPurchaseAmount();
    const { handleSelectProduct, listOfProducts } = useDataPurchaseProduct();
    const { handleSelectVendor, handleSelectVendorInstant } = useDataPurchaseVendor();
    const { selectedAccountId, handleSelectAccount, accounts } = useDataPurchasePayFrom();

    const handleSelectRecentPurchase = useCallback((_data: BillPurchase) => {
        handleAmountChange(String(_data.amount));
        handleOnChange(_data.extraData.mobile.phone);
        handleSelectProduct(_data.product);
        handleSelectVendorInstant(_data.vendor);
    }, []);

    const handleBack = useCallback(() => {
        dispatch(resetBillPaymentData());
        dispatch(resetAllDataPurchaseData());
        abortBillPayment();
    }, []);

    const handleNextPage = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.REVIEW_PAYMENT));
    }, []);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <DataPurchaseHeaders />

                {!isRecentBillPurchasesLoading && recentBillPurchases && recentBillPurchases.length > 0 && (
                    <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Recent Purchase</p>
                        <div className="flex items-center justify-start space-x-7 overflow-auto">
                            {recentBillPurchases.slice(-3).map((_purchase, index) => (
                                <RecentContactsElement
                                    key={index}
                                    data={_purchase}
                                    onClick={handleSelectRecentPurchase}
                                    selectedAccountId={selectedAccountId}
                                    handleSelectAccount={handleSelectAccount}
                                    productName
                                />
                            ))}
                        </div>
                    </div>
                )}
                {isRecentBillPurchasesLoading && <RecentBillPaymentSkeleton />}
                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Pay from</p>
                        <NewUserAccountDropdown
                            value={selectedAccountId || ""}
                            options={accounts || null}
                            onClick={handleSelectAccount}
                            isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                            dropdownSize="xl"
                            showBalance
                            initiatorCanSelect
                        />
                    </div>

                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                        <Input placeholder="Phone Number" value={selectedPayToAccount} onChange={handleOnChange} inputSize="xl" />
                        <VendorDropdown label="Service Provider" value={selectedVendorName} options={vendorsList} onSelect={handleSelectVendor} />
                        <ProductDropdown label="Data Plan" value={selectedProductName} options={listOfProducts} onSelect={handleSelectProduct} />
                        {dataPurchaseDetailAmount && <VerifiedAccountCard label="Amount" value={dataPurchaseDetailAmount} isMoney />}
                        {/* {dataPurchaseDetailAmount && (
                            <MoneyInput
                                label="Amount"
                                value={dataPurchaseDetailAmount || ""}
                                onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                                inputSize="xl"
                                isDisabled
                            />
                        )} */}
                    </div>
                    {selectedUserAccount && selectedProductFixedAmount && (selectedUserAccount.balance as number) < selectedProductFixedAmount && (
                        <MessageToast
                            message={
                                <p>
                                    You do not have enough funds. Your account balance is <Money amount={selectedUserAccount.balance || 0} />
                                </p>
                            }
                            type="error"
                            fullWidth
                        />
                    )}
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="xl" func={handleBack}>
                        Back
                    </ButtonComp>

                    <ButtonComp
                        size="xl"
                        color="black"
                        buttonType="primary"
                        disable={
                            !!(
                                !selectedUserAccount ||
                                !selectedVendorName ||
                                !selectedProductName ||
                                !selectedProductFixedAmount ||
                                selectedPayToAccount.length < 5 ||
                                (selectedUserAccount?.balance as number) < selectedProductFixedAmount
                            )
                        }
                        func={handleNextPage}
                    >
                        Review Payment
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default PaymentDetails;
