import * as Yup from "yup";

import { Form, Formik } from "formik";

import ButtonComp from "../../../../components/button/ButtonComp";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import FormInput from "../../../../components/inputs/FormInput";
import LoginFooter from "../../../../components/layouts/components/landing/login-footer";
import PasswordHints from "../../../../utils/password-hints/password-hints";
import { PasswordRules } from "../../../../utils/password-hints/password-hints.service";
import { ResetForgotUrlParams } from "../hookss/statee/useResetForgotPasswordState";
import { UseResetPasswordSetNewPasswordInterface } from "../hookss/statee/useResetPasswordSetNewPassword";

const INITIAL_FORM_STATE = {
    password: "",
    repeatPassword: "",
};

const passwordValidation = Yup.string().required("Required");
for (const rule of Object.values(PasswordRules)) {
    passwordValidation.matches(rule.regex, rule.text);
}

const FORM_VALIDATION = Yup.object().shape({
    password: passwordValidation,
    repeatPassword: Yup.string()
        .oneOf([Yup.ref("password"), undefined], "Password must match")
        .required("Confirm password is required"),
});

interface Props {
    params: UseResetPasswordSetNewPasswordInterface;
    baseParams: ResetForgotUrlParams;
}

function ResetPasswordSetNewPasswordForm({ params, baseParams }: Props): JSX.Element {
    return (
        <>
            <div className="relative mx-auto flex w-full max-w-[476px] flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex w-full flex-col items-center justify-start space-y-4">
                    <h3 className="break-words text-center text-2xl font-medium text-black">Reset Your Password</h3>
                    <p className="text-center text-base text-black-secondary">Choose a new password</p>
                </div>
                {params.errorMessage && (
                    <div className="mb-4 mt-4 flex w-max flex-col items-center justify-center text-center">
                        <ErrorToast error={params.errorMessage} />
                    </div>
                )}

                <Formik
                    initialValues={{
                        ...INITIAL_FORM_STATE,
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => {
                        void params.handleSubmitForm({ ...baseParams, ...values });
                    }}
                    enableReinitialize
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <>
                                <Form className="flex w-full flex-col items-center justify-center space-y-8">
                                    <div className="flex w-full flex-col items-start justify-start space-y-2">
                                        <FormInput placeholder="Choose a Password" name="password" type="password" />
                                        {formik.values.password.length > 0 && (
                                            <PasswordHints
                                                password={formik.values.password}
                                                className="flex items-center space-x-2 pb-1 text-xs"
                                                show={!!(formik.values.password.length > 0)}
                                            />
                                        )}
                                        <FormInput placeholder="Re-Enter Password" name="repeatPassword" type="password" passwordCustomStyle />
                                        {!formik.errors.password &&
                                            formik.values.repeatPassword.length > 0 &&
                                            formik.values.repeatPassword !== formik.values.password && (
                                                <div>
                                                    <li className="flex items-center space-x-1 text-xs">
                                                        <span className="mx-1 flex h-2 w-2 rounded-full bg-error" />
                                                        <span className="text-error">Password does not match</span>
                                                    </li>
                                                </div>
                                            )}
                                    </div>

                                    <ButtonComp
                                        type="submit"
                                        color="black"
                                        buttonType="primary"
                                        disable={!!(formik.errors.password || formik.errors.repeatPassword)}
                                        isLoading={params.isSubmitting}
                                        fullWidth
                                    >
                                        <span>Reset Password</span>
                                    </ButtonComp>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </div>
            <LoginFooter />
        </>
    );
}

export default ResetPasswordSetNewPasswordForm;
