import {
    ZambiaAccountOpeningInitRequests,
    abortZambiaAccountOpeningInitRequest,
    zambiaAccountOpeningInit,
} from "../../../services/zambia-application/zambiaAccountOpeningInit";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { setZambiaAccountOpeningInit } from "../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface UseZambiaInitInterface {
    zambiaAccountOpeningInitError: string | null;
    isZambiaAccountOpeningInitLoading: boolean;
    handleReset: () => void;
    handleZambiaAccountOpeningInit: () => Promise<void>;
}

const useZambiaInit = (): UseZambiaInitInterface => {
    const [zambiaAccountOpeningInitError, setZambiaAccountOpeningInitError] = useState<string | null>(null);
    const [isZambiaAccountOpeningInitLoading, setZambiaAccountOpeningInitLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleZambiaAccountOpeningInit = useCallback(async (): Promise<void> => {
        try {
            setZambiaAccountOpeningInitError(null);
            setZambiaAccountOpeningInitLoading(true);
            const res = await zambiaAccountOpeningInit();
            dispatch(setZambiaAccountOpeningInit(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            setZambiaAccountOpeningInitError(errorMessage);
            dispatch(errorTrue(errorMessage));
        } finally {
            setZambiaAccountOpeningInitLoading(false);
        }
    }, [dispatch]);

    const handleReset = () => {
        setZambiaAccountOpeningInitError(null);
        setZambiaAccountOpeningInitLoading(false);
        abortZambiaAccountOpeningInitRequest(ZambiaAccountOpeningInitRequests.INIT);
    };

    return {
        zambiaAccountOpeningInitError,
        isZambiaAccountOpeningInitLoading,
        handleReset,
        handleZambiaAccountOpeningInit,
    };
};
export default useZambiaInit;
