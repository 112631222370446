interface Props {
    header: string;
    subtitle?: string;
}

function PaymentHeader(props: Props): JSX.Element {
    return (
        <div className="flex w-full flex-col items-center justify-center space-y-2">
            <span className="text-xl font-medium text-black md:text-2xl">{props.header}</span>
            {props.subtitle && <span className="text-sm text-black-secondary md:text-base">{props.subtitle}</span>}
        </div>
    );
}

export default PaymentHeader;
