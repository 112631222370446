import { CardStatus, CardWalletType } from "../../../../models/card.constants";
import { CancelCardOptionsEnum } from "./cardsApi.types";

export enum CARD_CREATION {
    FEE = 2000,
}

export enum CardsFilters {
    STATUS = "Status",
    WALLET = "Type",
    ASSIGNED = "Assigned To",
}

export const CardsFiltersArray = [CardsFilters.ASSIGNED, CardsFilters.STATUS, CardsFilters.WALLET];

export const CardStatusArray = [CardStatus.ACTIVE, CardStatus.DEACTIVATED, CardStatus.EXPIRED, CardStatus.FROZEN, CardStatus.UNACTIVATED];

export const CardStatusName = {
    [CardStatus.ACTIVE]: "Active",
    [CardStatus.DEACTIVATED]: "Deactivated",
    [CardStatus.EXPIRED]: "Expired",
    [CardStatus.FROZEN]: "Frozen",
    [CardStatus.UNACTIVATED]: "UnActivated",
};

export const CardWalletArray = [CardWalletType.INDEPENDENT_WALLET, CardWalletType.LINKED_TO_ACCOUNT];

export const CardWalletName = {
    [CardWalletType.LINKED_TO_ACCOUNT]: "Direct Debit",
    [CardWalletType.INDEPENDENT_WALLET]: "Prepaid",
};

export const CancelCardOptionsList = [
    {
        value: CancelCardOptionsEnum.LOST_CARD,
        text: "Lost Card",
    },
    {
        value: CancelCardOptionsEnum.DAMAGED_CARD,
        text: "The Card is Damaged ",
    },
    {
        value: CancelCardOptionsEnum.STOLEN_CARD,
        text: "Stolen Card",
    },
    {
        value: CancelCardOptionsEnum.DID_NOT_RECEIVE,
        text: "I Didn't Receive Card",
    },
    {
        value: CancelCardOptionsEnum.OTHER,
        text: "Other",
        hasAnyOtherReason: true,
    },
];
