import { useCallback, useEffect, useState } from "react";

import Checkbox from "../../checkbox";
import { ReactComponent as EmptyXX } from "../../../assets/images/empty/empty-xx.svg";
import NewDropdownItem from "../../new-dropdown-item";
import RadioButton from "../../radio-button";
import SearchBar from "../../search-bar";

interface Props<T extends { name?: string; text?: string; value: U }, U> {
    name: string;
    options: T[];
    isRadio?: boolean;
    dataType?: string;
    filteredList: U[];
    handleFilterState: (_value: U) => void;
}

function GenericFilterRow<T extends { name?: string; text?: string; value: U }, U>(props: Props<T, U>): JSX.Element {
    const [options, setOptions] = useState<Array<T>>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        const filteredData: T[] = (props.options || []).filter((item: T): boolean => {
            if (!searchTerm || searchTerm.trim().length === 0) {
                return true;
            }
            return (item.text || item.name || "").toLowerCase().includes(searchTerm.toLowerCase());
        });
        setOptions(filteredData);
    }, [props.options, searchTerm]);

    const handleChangeSearchTerm = useCallback((e: string) => {
        setSearchTerm(e);
    }, []);

    return (
        <>
            {props.options.length > 7 && (
                <div className="relative flex h-12 w-full items-center justify-center px-4" tabIndex={-1} data-type={props.dataType || ""}>
                    <SearchBar placeholder="Search" value={searchTerm} onChange={handleChangeSearchTerm} data-type={props.dataType || ""} />
                </div>
            )}

            <div
                className={
                    "h-fit max-h-88 overflow-y-auto border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary" +
                    `${props.options.length > 7 ? " border-t" : ""}`
                }
                data-type={props.dataType || ""}
            >
                {options.map((_option, index) => {
                    return (
                        <NewDropdownItem
                            key={index}
                            data-type={props.dataType || ""}
                            onClick={() => {
                                props.handleFilterState(_option.value);
                            }}
                            size="md"
                        >
                            <div className="flex h-full w-full flex-row items-center justify-start text-sm" data-type={props.dataType || ""}>
                                <div className="pointer-events-none w-fit max-w-full" data-type={props.dataType || ""}>
                                    {props.isRadio ? (
                                        <RadioButton
                                            text={_option.text || _option.name || ""}
                                            id={_option.text || _option.name || ""}
                                            checked={!!props.filteredList.includes(_option.value)}
                                            readOnly
                                            size="sm"
                                            data-type={props.dataType || ""}
                                            truncate
                                        />
                                    ) : (
                                        <Checkbox
                                            text={_option.text || _option.name || ""}
                                            id={_option.text || _option.name || ""}
                                            checked={!!props.filteredList.includes(_option.value)}
                                            readOnly
                                            size="sm"
                                            data-type={props.dataType || ""}
                                            truncate
                                            fullWidth
                                        />
                                    )}
                                </div>
                            </div>
                        </NewDropdownItem>
                    );
                })}
            </div>

            {options && options.length < 1 && (
                <div className="flex h-10 w-full flex-row items-center justify-center space-x-2 text-sm text-black-tertiary">
                    <EmptyXX className="h-6 w-6" />
                    <span>No {props.name} Found</span>
                </div>
            )}
        </>
    );
}

export default GenericFilterRow;
