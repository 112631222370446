import Checkbox, { CheckType } from "../../../../../../components/checkbox";
import { DeclineReason, DeclinedTransaction } from "../../../hooks/state/pending-approval/usePendingPayments.types";

import DropdownHead from "../../../../../../components/dropdown/dropdown-components/dropdown-head";
import NewDropdownItem from "../../../../../../components/new-dropdown-item";
import Transaction from "../../../../../../models/transaction";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import { useState } from "react";
import useTransactionDetailsCard from "../../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsCard";

interface Props {
    isApproved: boolean;
    isDeclined: boolean;
    transaction: Transaction;
    declinedReason: DeclineReason | undefined;
    isDeclineTransactionLoading?: boolean;
    handleApprovePayment: (_data: Transaction) => void;
    handleSelectOrChangeDeclineReason: (_data: DeclinedTransaction) => void;
    handleRemoveDeclineOrChangeToDecline?: (_data: Transaction) => void;
}

function PendingApproval(props: Props): JSX.Element {
    const [isHoverActive, setIsHoverActive] = useState<boolean>(false);
    const [isHoverDecline, setIsHoverDecline] = useState<boolean>(false);
    const [isDeclineActive, setIsDeclineActive] = useState<boolean>(false);

    const { canShowTransaction } = useTransactionDetailsCard();

    const domNode = useClickOutside(() => {
        setIsDeclineActive(false);
    });

    return (
        <>
            <div className={`${canShowTransaction ? "flex-row space-x-2.5" : "flex-col space-y-2.5"} mx-auto flex w-max items-center justify-center`}>
                <div
                    className="flex cursor-pointer flex-col items-center justify-center space-y-2"
                    ref={domNode}
                    onMouseEnter={() => setIsHoverDecline(true)}
                    onMouseLeave={() => setIsHoverDecline(false)}
                    onClick={() => {
                        if (props.isDeclined) {
                            setIsDeclineActive(true);
                            props.handleRemoveDeclineOrChangeToDecline && props.handleRemoveDeclineOrChangeToDecline(props.transaction);
                        } else {
                            setIsDeclineActive(true);
                        }
                    }}
                >
                    <DropdownHead
                        placeholder={
                            <div
                                className={
                                    `flex h-full items-center justify-start space-x-2 text-xs font-normal` +
                                    `${isDeclineActive || props.isDeclined ? " text-error" : " text-current"}`
                                }
                            >
                                <Checkbox
                                    type={CheckType.ERROR}
                                    id={props.transaction.id}
                                    checked={props.isDeclined}
                                    size="xs"
                                    readOnly
                                    isErrorCheck
                                />
                                <span className="font-medium">Decline</span>
                            </div>
                        }
                        noYPadding
                        withArrowBorder
                        borderColor={props.isDeclined ? "border-error-quin" : "border-grey-secondary"}
                        size="2xs"
                        clickAndClose
                        placement="right"
                        isLoading={props.isDeclineTransactionLoading}
                        color="red"
                        isActive={isDeclineActive}
                        isHover={isHoverDecline || props.isDeclined}
                        isSelected={props.isDeclined}
                        noOutlineBorder={false}
                        outline
                        fitDropdown
                    >
                        <NewDropdownItem
                            isDisabled={props.declinedReason === DeclineReason.INCORRECT_DETAILS}
                            onClick={() =>
                                props.handleSelectOrChangeDeclineReason({
                                    reason: DeclineReason.INCORRECT_DETAILS,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <div className="flex items-center justify-start text-sm">Incorrect Details</div>
                        </NewDropdownItem>
                        <NewDropdownItem
                            isDisabled={props.declinedReason === DeclineReason.INCORRECT_AMOUNT}
                            onClick={() =>
                                props.handleSelectOrChangeDeclineReason({
                                    reason: DeclineReason.INCORRECT_AMOUNT,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <div className="flex items-center justify-start text-sm">Incorrect Amount</div>
                        </NewDropdownItem>
                        <NewDropdownItem
                            isDisabled={props.declinedReason === DeclineReason.WRONG_PURPOSE}
                            onClick={() =>
                                props.handleSelectOrChangeDeclineReason({
                                    reason: DeclineReason.WRONG_PURPOSE,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <div className="flex items-center justify-start text-sm">Wrong Purpose</div>
                        </NewDropdownItem>
                        <NewDropdownItem
                            isDisabled={props.declinedReason === DeclineReason.OTHERS}
                            onClick={() =>
                                props.handleSelectOrChangeDeclineReason({
                                    reason: DeclineReason.OTHERS,
                                    transaction: props.transaction,
                                })
                            }
                            color="red"
                            size="sm"
                        >
                            <div className="flex items-center justify-start text-sm">Others</div>
                        </NewDropdownItem>
                    </DropdownHead>
                </div>
                <div
                    className="flex w-full cursor-pointer flex-col items-center justify-center space-y-2"
                    onMouseEnter={() => setIsHoverActive(true)}
                    onMouseLeave={() => setIsHoverActive(false)}
                    onClick={() => props.handleApprovePayment(props.transaction)}
                >
                    <div
                        className={
                            `flex h-6 w-full items-center justify-start rounded-[4px] border px-2 duration-150 ease-in-out ` +
                            `${
                                props.isApproved
                                    ? "border-success-quin bg-success-backdrop text-success"
                                    : "border-grey-secondary text-black-tertiary"
                            } ` +
                            `${isHoverActive ? "bg-success-backdrop text-success" : ""} `
                        }
                    >
                        <Checkbox
                            type={CheckType.SUCCESS}
                            text={<span className={`select-none text-xs ${props.isApproved ? "text-success" : "text-black-tertiary"}`}>Approve</span>}
                            id={props.transaction.id}
                            checked={props.isApproved}
                            size="xs"
                            readOnly
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PendingApproval;
