import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../element/ApplicationHeader";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import CreatorReview from "./components/creatorReview";
import ErrorToast from "../../../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import InvitedReview from "./components/InvitedReview";
import LencoSpinner from "../../../../../../../../components/spinner";
import PreviewModal from "../../../../modals/preview-modal";
import { Routes } from "../../../../../../../../routes/routes.constants";
import Skeleton from "../../../../../../../../components/skeleton";
import useAccountSignatoriesInit from "../../../../../Hooks/zambia-application/registered-business/account-signatories/new/useAccountSignatoriesInit";
import useBusinessDetailsInit from "../../../../../Hooks/zambia-application/registered-business/business-details/useBusinessDetailsInit";
import useBusinessDocumentsInit from "../../../../../Hooks/zambia-application/registered-business/business-documents/useBusinessDocumentsInit";
import usePreview from "../../../../../Hooks/zambia-application/registered-business/account-signatories/new/usePreview";
import { useSelector } from "react-redux";
import useZambiaInit from "../../../../../Hooks/zambia-application/general/useZambiaInit";

type LocationState = {
    from?: string;
};

function BusinessReview(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
    });

    const { businessDetailsInitError, isBusinessDetailsInitLoading, handleBusinessDetailsInit } = useBusinessDetailsInit();
    const {
        currentSignatoryError,
        accountSignatoriesError,
        isCurrentSignatoryLoading,
        isAccountSignatoriesLoading,
        handleCurrentSignatoryInit,
        handleAccountSignatoriesInit,
    } = useAccountSignatoriesInit();
    const { documentDetailsError, isDocumentDetailsLoading, handleDocumentDetailsInit } = useBusinessDocumentsInit();
    const { zambiaAccountOpeningInitError, isZambiaAccountOpeningInitLoading, handleZambiaAccountOpeningInit } = useZambiaInit();

    const accountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);
    const currentSignatory = useSelector((state: IRootState) => state.zambiaApplication.currentSignatory);

    const divRef = useRef<HTMLDivElement | null>(null);

    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    useLayoutEffect(() => {
        void handleZambiaAccountOpeningInit();
        void handleBusinessDetailsInit();
        void handleCurrentSignatoryInit();
        void handleAccountSignatoriesInit();
        void handleDocumentDetailsInit();
    }, []);

    useEffect(() => {
        if (currentSignatory) void handlePreviewDocument(currentSignatory?.passportPhotoUploadId || "", true);
    }, [currentSignatory]);

    const handleBack = () => {
        navigate(
            locationState?.from ||
                (accountOpening?.isCreator ? Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT : Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH)
        );
    };

    return (
        <>
            <PreviewModal active={showPreviewModal} toggler={() => setShowPreviewModal(false)} data={previewBlob} title="Preview" />

            {(isBusinessDetailsInitLoading ||
                isCurrentSignatoryLoading ||
                isDocumentDetailsLoading ||
                isAccountSignatoriesLoading ||
                isZambiaAccountOpeningInitLoading) && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <LencoSpinner />
                </div>
            )}
            {(businessDetailsInitError ||
                currentSignatoryError ||
                accountSignatoriesError ||
                documentDetailsError ||
                zambiaAccountOpeningInitError) && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <ErrorToast error={currentSignatoryError || businessDetailsInitError || zambiaAccountOpeningInitError} />
                </div>
            )}
            {!isBusinessDetailsInitLoading &&
                !isCurrentSignatoryLoading &&
                !isDocumentDetailsLoading &&
                !isAccountSignatoriesLoading &&
                !isZambiaAccountOpeningInitLoading &&
                !businessDetailsInitError &&
                !currentSignatoryError &&
                !accountSignatoriesError &&
                !documentDetailsError &&
                !zambiaAccountOpeningInitError &&
                accountOpening && (
                    <div
                        className={
                            `flex w-full flex-row items-start justify-start space-x-6`
                            //  +
                            // `relative flex w-full flex-shrink flex-grow flex-col items-center justify-start pb-28 px-4
                            // -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available `
                        }
                    >
                        <div className="w-full lg:min-w-60% lg:max-w-3xl">
                            <ApplicationHeader
                                header="Review your Application"
                                subTitle="Below is the summary of the information you provided. You can make any necessary changes as you complete your application. "
                                progress={
                                    accountOpening.isCreator
                                        ? accountOpening.progress.zambiaRegisteredProgress()
                                        : accountOpening.progress.personalCompletedProgress()
                                }
                            >
                                <div
                                    className={
                                        "relative flex w-full flex-col items-start justify-start space-y-8 " +
                                        "relative flex flex-shrink flex-grow basis-auto overflow-y-auto"
                                    }
                                >
                                    <div className="flex w-full items-center justify-center">
                                        <div className="h-24 w-24 overflow-hidden rounded-full border">
                                            {previewBlob ? (
                                                <img className="h-full w-full object-cover" src={URL.createObjectURL(previewBlob)} />
                                            ) : (
                                                <Skeleton box />
                                            )}
                                        </div>
                                    </div>
                                    {accountOpening.isCreator ? (
                                        <CreatorReview handlePreviewDocument={(_data) => void handlePreviewDocument(_data)} />
                                    ) : (
                                        <InvitedReview handlePreviewDocument={(_data) => void handlePreviewDocument(_data)} />
                                    )}
                                </div>
                            </ApplicationHeader>
                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4" ref={divRef}>
                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                        <span>Back</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                    <ButtonComp
                                        type="button"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        func={() =>
                                            navigate(
                                                {
                                                    pathname: Routes.ACCOUNT_OPENING.DONE,
                                                },
                                                {
                                                    state: {
                                                        from: Routes.ACCOUNT_OPENING.REVIEW,
                                                    },
                                                }
                                            )
                                        }
                                        disable={
                                            accountOpening.isCreator
                                                ? accountOpening.progress.zambiaRegisteredProgress() !== 100
                                                : accountOpening.progress.invitedZambiaRegisteredProgress() !== 100
                                        }
                                        fullWidth
                                    >
                                        <span>Complete Application</span>
                                    </ButtonComp>
                                </div>
                            </div>
                        </div>
                        {/* <div className="fixed bottom-16 right-8 z-40">
                            <ButtonComp
                                color="white"
                                buttonType="flat"
                                size="sm"
                                fullBorder
                                func={() => {
                                    divRef.current?.scrollIntoView({ behavior: "smooth" });
                                }}
                            >
                                <div className="flex max-w-[20px] items-center justify-center overflow-hidden">
                                    <span className="material-symbols-rounded">keyboard_double_arrow_down</span>
                                </div>
                                <span className="ml-2 text-sm font-medium normal-case text-blue">Jump to end</span>
                            </ButtonComp>
                        </div> */}
                        {/* <div className="flex justify-end items-end w-full h-full relative">
							<div
								className="flex flex-row justify-center items-center fixed top-90% cursor-pointer"
								onClick={() => {
									divRef.current?.scrollIntoView({behavior: "smooth"});
								}}
							></div>
						</div> */}
                    </div>
                )}
        </>
    );
}

export default BusinessReview;
