import {
    resetAddMoneyCollectionRequestDateFilterState,
    resetAddMoneyCollectionRequestFilterState,
    setAddMoneyCollectionRequestFilterState,
    setAddMoneyCollectionRequestList,
    setAddMoneyCollectionRequestListGroupSize,
    setAddMoneyCollectionRequestListTotal,
    setAddMoneyCollectionRequestPaginationOffset,
    setIsAddMoneyCollectionRequestPaginationLoading,
    setSelectedTerminalTransactionFilterState,
} from "../../../../../../redux/payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice";
import { useDispatch, useSelector } from "react-redux";

import { AddMoneyHistoryRequest } from "../../../services/AddMoney/addMoneyApi.types";
import { IRootState } from "../../../../../../redux/rootReducer";
import { RequestCancelledError } from "../../../../../../helpers/request/requestErrors";
import { addMoneyHistory } from "../../../services/AddMoney/addMoneyApi";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { produce } from "immer";
import { useCallback } from "react";

export interface UseAddMoneyCollectionRequestInterface {
    handleClearAll: () => void;
    handlePaginationRequest: (e: number) => void;
    handleFilteredAddMoneyCollectionRequest: (e: unknown) => void;
}

function useAddMoneyHistory(): UseAddMoneyCollectionRequestInterface {
    const dispatch = useDispatch();

    const selectedAddMoneyCollectionRequestFilterState = useSelector(
        (state: IRootState) => state.addMoneyHistory.selectedAddMoneyCollectionRequestFilterState
    );

    const handleGetAddMoneyCollectionRequest = useCallback(
        async (_data: AddMoneyHistoryRequest) => {
            try {
                dispatch(setIsAddMoneyCollectionRequestPaginationLoading(true));
                const res = await addMoneyHistory(_data);
                dispatch(setAddMoneyCollectionRequestList(res.collections));
                dispatch(setAddMoneyCollectionRequestListTotal(res.total));
                dispatch(setAddMoneyCollectionRequestListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsAddMoneyCollectionRequestPaginationLoading(false));
            }
        },
        [dispatch]
    );

    const handleFilteredAddMoneyCollectionRequest = useCallback(
        <T>(data: T) => {
            const updatedList = produce(selectedAddMoneyCollectionRequestFilterState, (draft) => {
                return (draft = { ...draft, ...data });
            });
            dispatch(setSelectedTerminalTransactionFilterState());
            dispatch(setAddMoneyCollectionRequestFilterState(updatedList));
            dispatch(setIsAddMoneyCollectionRequestPaginationLoading(true));
            void handleGetAddMoneyCollectionRequest({ ...updatedList, channel: updatedList.channel[0], status: updatedList.status[0] });
        },
        [selectedAddMoneyCollectionRequestFilterState]
    );

    const handlePaginationRequest = useCallback((offset: number) => {
        dispatch(setAddMoneyCollectionRequestPaginationOffset(offset));
        handleFilteredAddMoneyCollectionRequest({ offset });
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetAddMoneyCollectionRequestFilterState());
        dispatch(resetAddMoneyCollectionRequestDateFilterState());
        void handleGetAddMoneyCollectionRequest({
            query: "",
            offset: 0,
            channel: undefined,
            status: undefined,
            date: {
                begin: "",
                end: "",
            },
        });
    }, []);

    return {
        handleClearAll,
        handlePaginationRequest,
        handleFilteredAddMoneyCollectionRequest,
    };
}

export default useAddMoneyHistory;
