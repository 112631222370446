import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { TransactionStatus } from "./transaction.constants";
import { immerable } from "immer";

export default class BulkPaymentV2StatusItem {
    [immerable] = true;

    constructor(
        public key: string,
        public transactionId: string | null,
        public status: TransactionStatus | null,
        public failedReason: string | null
    ) {}

    static create(obj: GenericObject): BulkPaymentV2StatusItem {
        return new BulkPaymentV2StatusItem(
            Parsers.string(obj.key),
            Parsers.nullableString(obj.transactionId),
            Parsers.nullableNumber(obj.status),
            Parsers.nullableString(obj.failedReason)
        );
    }
}
