import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";

export default class CardPaymentMin {
    [immerable] = true;

    constructor(
        public code: string,
        public maskedPan: string,
        public rrn: string,
        public stan: string
    ) {}

    static create(obj: GenericObject): CardPaymentMin {
        return new CardPaymentMin(Parsers.string(obj.code), Parsers.string(obj.maskedPan), Parsers.string(obj.rrn), Parsers.string(obj.stan));
    }
}
