import React, { useEffect } from "react";

import Referee from "../../../../../../../../../models/account-opening/referee";
import RefereesLineDropdown from "./RefereesLineDropdown";
import SignatoryIcon from "../../../../../../../../../assets/images/dashboard/application/signatory.png";
import titleCase from "../../../../../../../../../hooks/titleCase";
import useRevokeRefereeInvite from "../../../../../../Hooks/nigeria-application/registered-business/invite-referee/useRevokeRefereeInvite";

interface Props {
    data?: Referee;
    isNull?: boolean;
    isReview?: boolean;
}

function RefereeCard(props: Props): JSX.Element {
    const { isRevokeRefereeInviteError, isRevokeRefereeInviteLoading, handleRest, handleRevokeRefereeInvite } = useRevokeRefereeInvite();

    useEffect(() => {
        return () => {
            handleRest();
        };
    }, []);

    return (
        <>
            <div className="flex w-full flex-row items-center justify-center space-x-4 rounded-lg border border-grey p-4">
                <div className="flex h-full flex-grow items-center justify-center">
                    <div className="flex w-16 items-center justify-center rounded-lg bg-blue-senary">
                        <img src={SignatoryIcon} alt="icon depicting a referee" />
                    </div>
                </div>
                <div className="flex w-full flex-col items-start justify-start">
                    <span className="whitespace-pre-line break-all text-base font-medium text-black">
                        {!props.isNull ? titleCase(props.data?.name || "") : "N/A"}
                    </span>
                    <span className="whitespace-pre-line break-all text-sm text-black-tertiary">
                        {!props.isNull ? titleCase(props.data?.email || "") : "-"}
                    </span>
                    <span className="whitespace-pre-line break-all text-sm text-black-tertiary">
                        {!props.isNull ? titleCase(props.data?.phone || "") : "-"}
                    </span>
                </div>
                {!props.isReview && props.data && (
                    <div className="-mt-2.5 flex h-full w-max flex-col items-end justify-end self-start pt-0.5">
                        <div onClick={(e) => e.preventDefault()}>
                            {!props.data.isCompleted && (
                                <RefereesLineDropdown
                                    data={props.data}
                                    isDeleteLoaded={isRevokeRefereeInviteLoading}
                                    deleteFunc={() => void handleRevokeRefereeInvite({ id: props.data?.id || "" })}
                                    removeRefereeError={isRevokeRefereeInviteError}
                                />
                            )}
                        </div>
                        <p
                            className={
                                `mt-2 rounded-[4px] border px-2 py-1 text-left text-xs font-medium ` +
                                ` ${props.data.statusText === "Pending" ? "border-warning-quin bg-warning-backdrop text-warning" : ""} ` +
                                ` ${props.data.statusText === "Completed" ? "border-success-quin bg-success-backdrop text-success" : ""} ` +
                                ` ${props.data.statusText === "Revoked" ? "border-error-quin bg-error-backdrop text-error" : ""} `
                            }
                        >
                            {props.data.statusText}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
}

export default RefereeCard;
