import {
    IdentificationInterface,
    ResidencyPermitSubForm,
} from "../../modules/dashboard/applicationn/services/nigeria-application/registered/signatory.types";

import { GenericObject } from "../../helpers/types";
import NextOfKin from "./nextOfKin";
import Parsers from "../../utils/parsers";
import UserMin from "../userMin";
import { UserRole } from "../userAccount.constants";
import { immerable } from "immer";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

export default class SignatoryInfo {
    [immerable] = true;

    constructor(
        public id: string,
        public user: UserMin | null,
        public firstName: string,
        public surname: string,
        public otherName: string,
        public title: number,
        public motherMaidenName: string,
        public dateOfBirth: string,
        public gender: number,
        public phones: string[],
        public email: string,
        public bvn: string,
        public role: number | null,
        public otherRoleText: string | null,
        public address: string,
        public nextOfKin: NextOfKin | null,
        public isNigerian: boolean,
        public isDirector: boolean,
        public isKeyContact: boolean,
        public nonNigerianNationality: string | null,
        public residencyPermit: ResidencyPermitSubForm | null,
        public identification: IdentificationInterface | null,
        public photoId: string | null,
        public signedDocumentId: string | null,
        public userRole: UserRole | null
    ) {}

    static create(obj: GenericObject): SignatoryInfo {
        return new SignatoryInfo(
            Parsers.string(obj.id),
            Parsers.classObject(obj.user, UserMin),
            Parsers.string(obj.firstName),
            Parsers.string(obj.surname),
            Parsers.string(obj.otherName),
            Parsers.number(obj.title),
            Parsers.string(obj.motherMaidenName),
            Parsers.string(obj.dateOfBirth),
            Parsers.number(obj.gender),
            Parsers.stringArray(obj.phones),
            Parsers.string(obj.email),
            Parsers.string(obj.bvn),
            Parsers.nullableNumber(obj.role),
            Parsers.nullableString(obj.otherRoleText),
            Parsers.string(obj.address),
            Parsers.classObject(obj.nextOfKin, NextOfKin),
            Parsers.boolean(obj.isNigerian),
            Parsers.boolean(obj.isDirector),
            Parsers.boolean(obj.isKeyContact),
            obj.nonNigerianNationality === null || obj.nonNigerianNationality === undefined ? null : Parsers.string(obj.nonNigerianNationality),
            isNullOrUndefined(obj.residencyPermit) ? null : (obj.residencyPermit as ResidencyPermitSubForm),
            isNullOrUndefined(obj.identification) ? null : (obj.identification as IdentificationInterface),
            Parsers.nullableString(obj.photoId),
            Parsers.nullableString(obj.signedDocumentId),
            Parsers.nullableNumber(obj.userRole)
        );
    }

    isCompletedBio(): boolean {
        return !!this.surname && !!this.title && (!!this.role || !!this.otherRoleText) && !!this.address;
    }

    isCompletedNextOfKin(): boolean {
        return (
            !!this.nextOfKin &&
            !!this.nextOfKin.name &&
            !!this.nextOfKin.address &&
            !!this.nextOfKin.phone &&
            // !!this.nextOfKin.state &&
            (!!this.nextOfKin.relationship || !!this.nextOfKin.otherRelationshipText)
        );
    }

    isCompletedIdentification(): boolean {
        return !!this.identification && !!this.identification.type && !!this.identification.fileId;
    }

    isCompletedMinIdentification(): boolean {
        return !!this.identification && !!this.identification.fileId;
    }

    isCompletedPhoto(): boolean {
        return !!this.photoId;
    }

    isCompletedMandate(): boolean {
        return !!this.signedDocumentId;
    }

    isCompleted(): boolean {
        return (
            this.isCompletedBio() &&
            this.isCompletedNextOfKin() &&
            this.isCompletedIdentification() &&
            this.isCompletedPhoto() &&
            this.isCompletedMandate()
        );
    }

    isStarterPersonalDetailsCompleted(): boolean {
        return (
            !!this.firstName &&
            !!this.surname &&
            !!this.phones &&
            !!this.email &&
            !!this.address &&
            !!this.dateOfBirth &&
            !!this.bvn &&
            !!(this.role || this.otherRoleText) &&
            !!(this.isNigerian || this.nonNigerianNationality)
        );
    }
}
