import Checkbox, { CheckType } from "./Checkbox/Checkbox";
import React, { useEffect, useState } from "react";

import { CancelPaymentOption } from "../Type/CancelPaymentOption";
import DropdownHead from "../../dropdown/dropdown-components/dropdown-head";
import DropdownLink from "../../dropdown/dropdown-components/dropdown-link";
import { IsCancelledType } from "../Type/IsCancelledType";

interface Props {
    isCancelled?: IsCancelledType;
    isCancelTransactionLoading?: boolean;
    onOptionSelect: (id: string | null) => void;
    options: CancelPaymentOption[];
}

function CancelPayment(props: Props): JSX.Element {
    const [isHoverCancel, setIsHoverCancel] = useState<boolean>(false);
    const [isCancelled, setIsCancelled] = useState<boolean>(false);

    useEffect(() => {
        if (props.isCancelled) setIsCancelled(!!props.isCancelled.isCancelled);
        else setIsCancelled(false);
        return () => {};
    }, []);

    return (
        <>
            <div
                className="mx-auto flex w-max cursor-pointer flex-col items-center justify-center"
                onMouseEnter={() => setIsHoverCancel(true)}
                onMouseLeave={() => setIsHoverCancel(false)}
                onClick={() => {
                    if (isCancelled) {
                        props?.onOptionSelect && props.onOptionSelect(null);
                        setIsCancelled(false);
                    }
                }}
            >
                <DropdownHead
                    placeholder={
                        <div
                            className={
                                `center flex h-full items-center justify-center space-x-1 bg-red-300 text-xs font-normal` +
                                `${isCancelled ? "text-error" : "text-current"}`
                            }
                        >
                            <Checkbox type={CheckType.ERROR} id={"props.transaction.id"} checked={isCancelled} size="sm" readOnly />
                            <span
                                className={`h-full border-r pr-2 ${isCancelled ? "border-error-quin text-error-secondary" : "border-grey-secondary"} flex items-center`}
                            >
                                Cancel
                            </span>
                        </div>
                    }
                    noYPadding
                    borderColor={isCancelled ? "border-error-quin" : "border-grey-secondary"}
                    size="xs"
                    clickAndClose
                    placement="right"
                    isLoading={props.isCancelTransactionLoading}
                    color="red"
                    isHover={!!isHoverCancel || !!isCancelled}
                    isSelected={!!isCancelled}
                    noOutlineBorder={false}
                    outline
                    fitDropdown
                >
                    {props.options.map((_el, idx) => (
                        <DropdownLink
                            key={idx}
                            noHover={_el.id === props.isCancelled?.id}
                            onClick={() => {
                                setIsCancelled(true);
                                props?.onOptionSelect(_el.id);
                            }}
                            redHover
                            color="red"
                        >
                            <div className="flex items-center justify-start px-4 text-sm">{_el.text}</div>
                        </DropdownLink>
                    ))}
                </DropdownHead>
            </div>
        </>
    );
}

export default CancelPayment;
