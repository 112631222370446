import { FormikProps } from "formik";
import { IRootState } from "../../../../../../redux/rootReducer";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../../../../components/dropdown/custom-select";
import Checkbox from "../../../../../../components/checkbox";
import FormInput from "../../../../../../components/inputs/FormInput";
import { CardAccessForm, CardUsageStage } from "../../../Types";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import LencoButton from "../../../../../../components/button/button";
import { isValidNigerianPhoneNumber } from "../../../../../../utils/isNigerianPhoneNumber";
import { updateUseHolderInfoToCreateCard } from "../../../../../../redux/cards/slice/cardsSlice";

interface Props {
    formikRef: FormikProps<CardAccessForm> | null;
    onNext: (stage: CardUsageStage) => void;
}

function CardHolder(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const usersList = useSelector((state: IRootState) => state.cards.users);
    const useHolderInfoToCreateCard = useSelector((state: IRootState) => !!state.cards.useHolderInfoToCreateCard);

    useEffect(() => {
        if (props?.formikRef?.values.assignedToId && useHolderInfoToCreateCard) {
            const holder = usersList?.find((user) => user.id === props.formikRef?.values?.assignedToId);
            props.formikRef?.getFieldHelpers("cardholderPhone").setValue(holder?.phones?.[0]);
            props.formikRef?.getFieldHelpers("cardholderEmail").setValue(holder?.email);
        }
        return () => {};
    }, [useHolderInfoToCreateCard, props.formikRef?.values.assignedToId]);

    return (
        <>
            <ModalBody>
                <div className="space-y-5">
                    <CustomSelect
                        placeholder="Assign Card"
                        value={props.formikRef?.values.assignedToId || null}
                        options={usersList.map((el) => {
                            return {
                                text: el.name,
                                value: el.id,
                            };
                        })}
                        onSelect={(value) => {
                            props.formikRef?.getFieldHelpers("assignedToId").setValue(value);
                        }}
                        size="lg"
                    />

                    <div
                        className={"group flex cursor-pointer space-x-2"}
                        onClick={() => {
                            dispatch(updateUseHolderInfoToCreateCard(!useHolderInfoToCreateCard));
                        }}
                    >
                        <div className="mt-0.5">
                            <Checkbox size="md" id="existingCard" checked={useHolderInfoToCreateCard} />
                        </div>
                        <div className={"cursor-pointer space-y-1"}>
                            <p className="font-medium text-black-secondary">Use existing card holder details</p>
                            <p className="text-xs text-black-tertiary">Tokens will be sent to this number and email address for online payment.</p>
                        </div>
                    </div>
                    {useHolderInfoToCreateCard && !!props?.formikRef?.values.assignedToId && (
                        <div className="flex flex-col space-y-1 rounded-lg border border-grey-secondary px-3 py-3">
                            <div className="flex w-full items-center justify-between">
                                <h3 className="text-sm font-normal text-black-secondary">Phone Number</h3>
                                <p className="text-sm font-medium">
                                    {usersList?.find((user) => user.id === props.formikRef?.values?.assignedToId)?.phones?.join(", ")}
                                </p>
                            </div>
                            <div className="flex w-full items-center justify-between">
                                <h3 className="text-sm font-normal text-black-secondary">Email Address </h3>
                                <p className="text-sm font-medium">
                                    {usersList?.find((user) => user.id === props.formikRef?.values?.assignedToId)?.email}
                                </p>
                            </div>
                        </div>
                    )}
                    {!useHolderInfoToCreateCard && (
                        <div className="space-y-5">
                            <FormInput type="string" name="cardholderPhone" placeholder="Card Holder Mobile Number" inputSize="lg" />
                            <FormInput type="string" name="cardholderEmail" placeholder="Card Holder Email" inputSize="lg" />
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <>
                    <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                        <LencoButton
                            onClick={() => props?.onNext(CardUsageStage.CUSTOMIZATION)}
                            type="button"
                            color="primary"
                            size="sm"
                            variant="bordered"
                            isText
                        >
                            <span className="normal-case tracking-wider">Back</span>
                        </LencoButton>
                    </div>
                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                        <LencoButton
                            type="button"
                            color="primary"
                            size="sm"
                            variant="solid"
                            isText
                            isDisabled={
                                useHolderInfoToCreateCard
                                    ? !props?.formikRef?.values.assignedToId
                                    : !props?.formikRef?.values.assignedToId ||
                                      !props?.formikRef?.values.cardholderEmail ||
                                      !props?.formikRef?.values.cardholderPhone ||
                                      !isValidNigerianPhoneNumber(props?.formikRef?.values.cardholderPhone || "")
                            }
                            onClick={() => props?.onNext(CardUsageStage.DELIVERY_DETAILS)}
                        >
                            <span className="normal-case tracking-wider">Proceed</span>
                        </LencoButton>
                    </div>
                </>
            </ModalFooter>
        </>
    );
}

export default CardHolder;
