import {
    ZambiaApplicationSectionType,
    ZambiaApplicationStagesType,
    ZambiaRegisteredSections,
    ZambiaUnregisteredSections,
} from "../../services/zambia-application/zambia-application.types";

import AccountSignatories from "./components/registered-business/account-signatories";
import ApplicationBusinessLayout from "./components/layout";
import BusinessReview from "./components/registered-business/business-review";
import { GenericApplicationPageType } from "../../services/application.constants";
import PersonalInformation from "./components/unregistered-business/personal-information";
import RegisteredBusinessDetails from "./components/registered-business/business-details";
import RegisteredBusinessDocuments from "./components/registered-business/business-documents";
import UnregisteredBusinessDetails from "./components/unregistered-business/business-details";
import UnregisteredBusinessReview from "./components/unregistered-business/business-review";

interface Props {
    stage: ZambiaApplicationStagesType | undefined;
    section: ZambiaApplicationSectionType | GenericApplicationPageType;
    isRegistered: boolean;
}

function ZambiaApplication(props: Props): JSX.Element {
    return (
        <>
            <ApplicationBusinessLayout section={props.section} stage={props.stage} isRegistered={props.isRegistered}>
                {props.section === ZambiaRegisteredSections.COMPANY && <RegisteredBusinessDetails />}
                {props.section === ZambiaRegisteredSections.SIGNATORY && <AccountSignatories />}
                {props.section === ZambiaRegisteredSections.DOCUMENT && <RegisteredBusinessDocuments />}
                {props.section === ZambiaRegisteredSections.REVIEW && props.isRegistered && <BusinessReview />}

                {props.section === ZambiaUnregisteredSections.DOCUMENT && <RegisteredBusinessDocuments />}
                {props.section === ZambiaUnregisteredSections.BUSINESS && <UnregisteredBusinessDetails />}
                {props.section === ZambiaUnregisteredSections.REVIEW && !props.isRegistered && <UnregisteredBusinessReview />}
                {(props.section === ZambiaUnregisteredSections.PERSONAL || props.section === ZambiaUnregisteredSections.IDENTITY) && (
                    <PersonalInformation />
                )}
            </ApplicationBusinessLayout>
        </>
    );
}

export default ZambiaApplication;
