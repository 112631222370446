import AvatarDetails from "../../../../../components/avatar-details";
import React from "react";
import { Recipient } from "../../hooks/state/pending-approval/use-saved-recipients-state.types";
import RecipientItemActions from "./RecipientItemActions";
import getInitials from "../../../../../helpers/get-initials";
import titleCase from "../../../../../hooks/titleCase";

interface Props {
    recipient: Recipient;
}

function RecipientItemTableRow({ recipient }: Props): JSX.Element {
    return (
        <>
            {recipient && (
                <tr>
                    <td className="h-14 items-center justify-start">
                        <AvatarDetails
                            initials={getInitials(recipient.customerAccount.accountName)}
                            title={
                                <div className="w-full max-w-full truncate text-base font-normal">
                                    {titleCase(recipient.customerAccount.accountName)}
                                </div>
                            }
                            size="xs"
                        />
                    </td>
                    <td className="h-14 items-center justify-start">
                        <span className="w-full max-w-full truncate">{recipient.customerAccount.accountNumber}</span>
                    </td>
                    <td className="h-14 items-center justify-start">
                        <span className="w-full max-w-full truncate">
                            {titleCase(recipient.bankName || recipient.customerAccount.bankAccount?.bankName || "")}
                        </span>
                    </td>
                    <td className="h-14 items-center justify-start">
                        <span className="w-full max-w-full truncate">{titleCase(recipient.customerAccount.alias || "-")}</span>
                    </td>
                    <td className="h-14 items-center justify-start">
                        <span className="w-full max-w-full truncate">{titleCase(recipient.customerAccount.userGroup?.name || "-")}</span>
                    </td>
                    <td className="h-14 items-center justify-start">
                        <div className="flex items-end justify-end">
                            <RecipientItemActions recipient={recipient} />
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

export default RecipientItemTableRow;
