import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { SearchPageItem } from "../../../../../../helpers/Data/SearchBar/listOfPages";
import { useAppSelector } from "../../../../../../redux/hooks";

interface PagesSearchProps {
    index: number;
    pagesData: SearchPageItem;
    onClick: (e: React.MouseEvent) => void;
}

function PagesSearch({ pagesData, onClick }: PagesSearchProps): JSX.Element {
    const accounts = useAppSelector((state) => state.init.main?.companyDetails.accounts || []);
    const userAccount = useAppSelector((state) => state.init.main?.companyDetails.user);
    const userAccountMeta = useAppSelector((state) => state.init.main?.companyDetails.userAccountsMeta);

    const [canApprove, setCanApprove] = useState<boolean>(false);

    useEffect(() => {
        if (!userAccount || !userAccountMeta) return;
        // const canApprove = userAccountMeta.find((el) => el.userAccountId === userAccount?.id)?.canApprove();
        setCanApprove(userAccountMeta.find((el) => el.id === userAccount.id)?.canApprove() || false);
    }, [userAccount, userAccountMeta]);

    return (
        <>
            {canApprove && (
                <>
                    {!(accounts.length < 2 && pagesData.url === "/payments/make/accounts") && (
                        <Link
                            to={{
                                pathname: pagesData.url,
                            }}
                            state={{ typeOfTransfer: pagesData.url.replace("/payments/make/", "") }}
                            onClick={onClick}
                            tabIndex={-1}
                        >
                            <div className="" data-type="transaction">
                                <div
                                    className="flex cursor-pointer flex-col items-start justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop"
                                    data-type="transaction"
                                >
                                    <span className="text-xs text-black-secondary" data-type="transaction">
                                        {pagesData.name}
                                    </span>
                                </div>
                            </div>
                        </Link>
                    )}
                </>
            )}

            {!canApprove && (
                <>
                    {!(accounts.length < 2 && pagesData.url === "/payments/make/accounts") && !!(pagesData.url !== "/settings/teammembers") && (
                        <Link
                            to={{
                                pathname: pagesData.url,
                            }}
                            state={{ typeOfTransfer: pagesData.url.replace("/payments/make/", "") }}
                            onClick={onClick}
                            tabIndex={-1}
                        >
                            <div data-type="transaction">
                                <div
                                    className="flex cursor-pointer flex-col items-start justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop"
                                    data-type="transaction"
                                >
                                    <span className="text-xs text-black-secondary" data-type="transaction">
                                        {pagesData.name}
                                    </span>
                                </div>
                            </div>
                        </Link>
                    )}
                </>
            )}
        </>
    );
}

export default PagesSearch;
