import React from "react";
import TerminalApprovedCard from "./TerminalApprovedCard";
import TerminalArrivedCard from "./TerminalArrivedCard";
import TerminalDispatchedCard from "./TerminalDispatchedCard";
import TerminalRequestedCard from "./TerminalRequestedCard";
import { canShowCardDelivered } from "../../../../../../helpers/cardStatus";
import { useAppSelector } from "../../../../../../redux/hooks";
import TableSummary from "../../../../../../components/Table/Widgets/TableSummary";
import { ReactComponent as SmallTerminal } from "../../../../../../assets/svg/Terminal/terminals-bg.svg";

interface Props {
    sm?: boolean;
}

function TerminalRequestStatusCards(props: Props): JSX.Element {
    const requestedTerminal = useAppSelector((state) =>
        state.terminals.initRequestedTerminals.filter((requestTerminal) => !requestTerminal.isDelivered).length >= 1
            ? state.terminals.initRequestedTerminals.find((requestTerminal) => canShowCardDelivered(requestTerminal.deliveredAt))
            : null
    );

    return (
        <>
            {!requestedTerminal?.isAssigned && !requestedTerminal?.isDeclined && (
                <TableSummary banner={!requestedTerminal?.isShipped && <SmallTerminal className="pt-4" />}>
                    {requestedTerminal?.isRequested && <TerminalRequestedCard sm={props.sm} />}
                    {requestedTerminal?.isApproved && <TerminalApprovedCard sm={props.sm} />}
                    {requestedTerminal?.isShipped && <TerminalDispatchedCard sm={props.sm} />}
                    {requestedTerminal?.isDelivered && <TerminalArrivedCard sm={props.sm} />}
                </TableSummary>
            )}
        </>
    );
}

export default TerminalRequestStatusCards;
