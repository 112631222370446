import { formatDate, formatTime } from "../../../../../../utils/formatDate";

import AvatarDetails from "../../../../../avatar-details";
import Money from "../../../../../money";
import Transaction from "../../../../../../models/transaction";
import getInitials from "../../../../../../helpers/get-initials";
import titleCase from "../../../../../../hooks/titleCase";

interface TransactionsSearchProps {
    index: number;
    transaction: Transaction;
    handleShowTransactionDetail: (transaction: Transaction) => void;
}

function TransactionsSearch({ index, transaction, handleShowTransactionDetail }: TransactionsSearchProps): JSX.Element {
    const customerAccount = transaction.isCredit() ? transaction.origination?.customerAccount : transaction.destination?.customerAccount;
    return (
        <div key={index} onClick={() => handleShowTransactionDetail(transaction)} data-type="transaction" className="w-full">
            <div
                className="flex cursor-pointer flex-row items-center justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop"
                data-type="transaction"
            >
                <div className="flex w-full items-center justify-start space-x-2" data-type="transaction">
                    <AvatarDetails
                        initials={getInitials(customerAccount?.accountName || "")}
                        icon={transaction.isCredit() ? "inflow" : "outflow"}
                        title={
                            <div className="flex w-full flex-row items-center justify-start space-x-2">
                                <span className="w-max whitespace-nowrap text-xs font-medium">{titleCase(customerAccount?.accountName || "")}</span>
                                <div className="flex w-full items-center justify-start space-x-2 divide-x-1">
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        {transaction.createdOn && formatDate(transaction.createdOn)}
                                    </p>
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap pl-2 text-xs capitalize text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        {transaction.createdOn && formatTime(transaction.createdOn)}
                                    </p>
                                </div>
                            </div>
                        }
                        dataType="transaction"
                        size="xs"
                    />
                </div>
                <div className="pl-6" data-type="transaction">
                    <Money
                        amount={transaction.amount}
                        currency={transaction.currency}
                        positive={transaction.isCredit()}
                        negative={transaction.isDebit()}
                        deleted={transaction.isFailed}
                        className={`text-sm ${
                            transaction.isDeclined || transaction.isProcessing
                                ? "text-warning"
                                : `${transaction.isCredit() && transaction.isSuccess ? "text-success" : "text-black-secondary"} `
                        }`}
                        dataType="transaction"
                    />
                </div>
            </div>
        </div>
    );
}

export default TransactionsSearch;
