import { useCallback, useEffect } from "react";

import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { getTransactionDetails } from "../../Components/Cards/TransactionDetailCard/Services/transactionDetailApi";
import { setSingleTransaction } from "../../../../../redux/transaction/slice/transactionSlice";
import { useDispatch } from "react-redux";

export default function useGetTransaction(transactionId: string): void {
    const dispatch = useDispatch();

    const handleGetTransaction = useCallback(async (): Promise<void> => {
        try {
            const updatedTransaction = await getTransactionDetails(transactionId);
            dispatch(setSingleTransaction(updatedTransaction));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        }
    }, [dispatch]);

    useEffect(() => {
        void handleGetTransaction();
    }, []);
}
