import { setCableTVPurchaseAmount } from "../../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseCableTVPurchaseAmountInterface {
    handleAmountChange: (_amount: string) => void;
}

function useCableTVPurchaseAmount(): UseCableTVPurchaseAmountInterface {
    const dispatch = useDispatch();

    const handleAmountChange = useCallback((_amount: string) => {
        dispatch(setCableTVPurchaseAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useCableTVPurchaseAmount;
