import { ApiAccessScope, ApiAccessSource } from "./apiAccess.constant";

import ApiAccessWebhook from "./apiAccessWebhook";
import { GenericObject } from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import UserAccount from "../../userAccount";
import { immerable } from "immer";

export default class DefaultSetup {
    [immerable] = true;

    constructor(
        public createdAt: Date | null,
        public scopes: ApiAccessScope[],
        public otherAccountsAccess: UserAccount[],
        public collectionsSettlementAccount: UserAccount | null,
        public collectionFeeSource: ApiAccessSource | null,
        public ipWhitelist: string[],
        public webhookUrl: string | null,
        public webhookCustomHeaders: ApiAccessWebhook[]
    ) {}

    static create(obj: GenericObject): DefaultSetup {
        return new DefaultSetup(
            Parsers.date(obj.createdAt),
            Parsers.nullableEnumArray(obj.scopes, ApiAccessScope),
            Parsers.classObjectArray(obj.otherAccountsAccess, UserAccount),
            Parsers.classObject(obj.collectionsSettlementAccount, UserAccount),
            Parsers.nullableEnum(obj.collectionFeeSource, ApiAccessSource),
            Parsers.stringArray(obj.ipWhitelist),
            Parsers.string(obj.webhookUrl),
            Parsers.classObjectArray(obj.webhookCustomHeaders, ApiAccessWebhook)
        );
    }
}
