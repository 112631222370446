import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef } from "react";

import AddMoneyHeader from "../../../../Layouts/AddMoney/Components/AddMoneyHeader";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import CurrencyCode from "../../../../../../../../components/currency-code";
import { InitiateMobileMoneyRequest } from "../../../../../../Accounts/Services/Zambia/mobileMoney.types";
import Input from "../../../../../../../../components/inputs/Input";
import MessageToasts from "../../../../../../../../components/message-toast";
import MoneyInput from "../../../../../../../../components/inputs/money-input";
import VerifiedAccountCard from "../../../../Cards/verified-account-card";
import formikHasError from "../../../../../../../../helpers/formikHasError";
import titleCase from "../../../../../../../../hooks/titleCase";
import useMobileMoney from "../../../../../../Accounts/Hooks/State/Zambia/MobileMoney/useMobileMoney";
import { useMoneyToNumber } from "../../../../../../../../hooks/useMoneyToNumber";
import useVerifyMobileMoneyPhone from "../../../../../../Accounts/Hooks/State/Zambia/MobileMoney/useVerifyMobileMoneyPhone";

interface Props {
    keyId: string;
    selectedAccountId: string;
    handleBack: () => void;
    handleNext: () => void;
    handleLast: () => void;
    handleUpdateKeyId: () => void;
    handleUpdatePhoneNumber: (_phone: string) => void;
}

function MobileMoneyPaymentDetails(props: Props): JSX.Element {
    const initialFormState: InitiateMobileMoneyRequest = {
        key: props.keyId,
        amount: 0,
        operator: "",
        phoneNumber: "",
        recipientAccountId: props.selectedAccountId,
    };

    const formValidation = Yup.object().shape({
        key: Yup.string().required("Required"),
        amount: Yup.number().min(1).required("Required"),
        operator: Yup.string().required("Required"),
        phoneNumber: Yup.string().required("Required"),
    });

    const formikRef = useRef<FormikProps<InitiateMobileMoneyRequest> | null>(null);

    const { isMobileMoneyLoading, handleMobileMoney } = useMobileMoney({
        onComplete: (_phone: string) => {
            props.handleNext();
            props.handleUpdatePhoneNumber(_phone);
        },
        onUpdate: () => {
            props.handleUpdateKeyId();
        },
        onLast: () => {
            props.handleLast();
        },
    });

    const { telcoImg, isZamtelLine, bankCode, verifiedAccount, verifyAccountError, isVerifyAccountLoading, handleAccountNumberChange } =
        useVerifyMobileMoneyPhone();

    useEffect(() => {
        if (formikRef.current) {
            void formikRef.current.getFieldHelpers("key").setValue(props.keyId);
            void formikRef.current.getFieldHelpers("operator").setValue(bankCode);
        }
    }, [formikRef.current, props.keyId, bankCode]);

    return (
        <>
            <Formik
                initialValues={initialFormState}
                innerRef={formikRef}
                validationSchema={formValidation}
                onSubmit={(values) => void handleMobileMoney({ ...values })}
                enableReinitialize
                validateOnChange
                validateOnMount
            >
                {(formik) => {
                    return (
                        <Form className="flex w-full flex-col items-center justify-start space-y-6">
                            <AddMoneyHeader title="Add Money" subtitle="You can add money to your account via Mobile Money" />
                            <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                                <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                                <div className="flex w-full flex-col space-y-4">
                                    <MoneyInput
                                        placeholder="Amount"
                                        icon={<CurrencyCode />}
                                        value={String(formik.values.amount)}
                                        onChange={(value) => {
                                            void formik.getFieldHelpers("amount").setValue(useMoneyToNumber(String(value)));
                                        }}
                                    />
                                    <Input
                                        placeholder="Phone Number"
                                        value={titleCase(formik.values.phoneNumber)}
                                        onChange={(value: string) => {
                                            void formik.getFieldHelpers("phoneNumber").setValue(value);
                                            handleAccountNumberChange(value);
                                        }}
                                        appendIcon={
                                            telcoImg ? (
                                                <div className="flex h-12 w-16 items-center justify-center pr-4">
                                                    <img className="h-9 rounded-full border border-black-quin" src={telcoImg} alt="telco" />
                                                </div>
                                            ) : undefined
                                        }
                                        fullWidth
                                    />
                                    {isZamtelLine && <MessageToasts message="Funding with Zamtel coming soon" type="error" fullWidth />}
                                    {verifyAccountError && !verifiedAccount && <MessageToasts message={verifyAccountError} type="error" fullWidth />}
                                    {!verifyAccountError && verifiedAccount && <VerifiedAccountCard value={titleCase(verifiedAccount.accountName)} />}
                                </div>
                            </div>
                            <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                    <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={props.handleBack} fullWidth>
                                        <span>Back</span>
                                    </ButtonComp>
                                </div>
                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                    <ButtonComp
                                        type="submit"
                                        color="black"
                                        buttonType="primary"
                                        isLoading={isVerifyAccountLoading || isMobileMoneyLoading}
                                        disable={formikHasError(formik.errors) || !verifiedAccount || isZamtelLine}
                                    >
                                        Add Money
                                    </ButtonComp>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default MobileMoneyPaymentDetails;
