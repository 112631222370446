export enum PaymentStageType {
    INITIAL = 1,
    PAYMENT_DETAILS = 2,
    REVIEW_PAYMENT = 3,
    MAKE_PAYMENT = 4,
    PROCESS_PAYMENT = 5,
}

export enum PaymentType {
    SINGLE_TRANSFER = "single",
    TRANSFER_BETWEEN_ACCOUNT = "accounts",
    BULK_TRANSFER = "bulk",
    FUND_CARDS = "cards",
}

export interface SendMoneyState {
    paymentStage: PaymentStageType;
    paymentType: PaymentType;
    payFrom: string;
    payTo: string;
    pageFrom: string;
    temp: boolean;
}
