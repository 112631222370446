import { ReactNode } from "react";

export enum AppToastType {
    ERROR = "error",
    MESSAGE = "message",
    WARNING = "warning",
    INFORMATION = "info",
}

export type AppToastItem = {
    type: AppToastType;
    index: number;
    message: string | ReactNode;
};

export type AppToastState = {
    message: AppToastItem | null;
};
