import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { LandingState } from "./landingSlice.types";
import { LencoActiveCountries } from "../init/slice/initSlice.types";

const initialState: LandingState = {
    country: undefined,
    inviteURL: null,
};

export const landingSlice = createSlice({
    name: "landing",
    initialState,
    reducers: {
        setLandingCountry: (state: LandingState, action: PayloadAction<LencoActiveCountries>) => {
            state.country = action.payload;
        },
        setLandingLayoutInviteURL: (state: LandingState, action: PayloadAction<string>) => {
            state.inviteURL = action.payload;
        },

        resetLandingCountry: (state: LandingState) => {
            state.country = undefined;
        },

        resetLandingLayoutState: (state: LandingState) => {
            state.inviteURL = null;
        },
    },
});

export const { setLandingCountry, setLandingLayoutInviteURL, resetLandingCountry, resetLandingLayoutState } = landingSlice.actions;

export default landingSlice.reducer;
