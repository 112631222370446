import React, { useEffect, useState } from "react";

import ApplicationDeleteModal from "../../../../../../../../modals/deleteModal";
import DropdownHead from "../../../../../../../../../../../../components/dropdown/dropdown-components/dropdown-head";
import DropdownLink from "../../../../../../../../../../../../components/dropdown/dropdown-components/dropdown-link";
import SignatoryMin from "../../../../../../../../../../../../models/account-opening/signatoryInfoMin";
import { TailSpin } from "react-loader-spinner";
import useRemoveAsDirector from "../../../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useRemoveAsDirector";
import useRemoveSignatory from "../../../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useRemoveSignatory";
import useSetAsDirector from "../../../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useSetAsDirector";
import useSetAsKeyContact from "../../../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useSetAsKeyContact";
import GoogleIcon from "../../../../../../../../../../../../components/google-icon";

interface AccountSignatoriesLineDropdownProps {
    data: SignatoryMin;
    clickAndClose: boolean;

    editFunc: () => void;
}

function AccountSignatoriesLineDropdown(props: AccountSignatoriesLineDropdownProps): JSX.Element {
    const [isRemoveSignatoryModalOpen, setIsRemoveSignatoryModalOpen] = useState<boolean>(false);

    const { isSetAsDirectorLoading, handleSetAsDirector, handleReset: handleRestSetAsDirector } = useSetAsDirector();
    const { isSetAsKeyContactLoading, handleSetAsKeyContact, handleReset: handleResetSetAsKeyContact } = useSetAsKeyContact();
    const { isRemoveAsDirectorLoading, handleRemoveAsDirector, handleReset: handleResetRemoveAsDirector } = useRemoveAsDirector();
    const {
        removeSignatoryError,
        isRemoveSignatoryLoading,
        handleRemoveSignatory,
        handleReset: handleResetRemoveSignatory,
    } = useRemoveSignatory(() => setIsRemoveSignatoryModalOpen(false));

    useEffect(() => {
        return () => {
            handleRestSetAsDirector();
            handleResetSetAsKeyContact();
            handleResetRemoveAsDirector();
            handleResetRemoveSignatory();
        };
    }, []);

    return (
        <>
            <ApplicationDeleteModal
                header="Remove Signatory"
                onSubmit={() => void handleRemoveSignatory({ id: props.data.id, signatoryId: props.data.userId || "" })}
                error={removeSignatoryError}
                deleteButtonText="Yes"
                active={isRemoveSignatoryModalOpen}
                isSubmitLoading={!!isRemoveSignatoryLoading}
                toggler={() => {
                    handleResetRemoveSignatory();
                    setIsRemoveSignatoryModalOpen(false);
                }}
                body={
                    <>
                        <span className="w-full whitespace-normal break-words text-left text-base font-normal">
                            Remove {props.data.name} as signatory ?
                        </span>
                    </>
                }
            />
            <div>
                {isRemoveAsDirectorLoading && (
                    <div className="flex h-10 w-8 items-center justify-center">
                        <TailSpin color="#C5046C" height={15} width={15} />
                    </div>
                )}
                {(isSetAsKeyContactLoading || isSetAsDirectorLoading) && (
                    <div className="flex h-10 w-8 items-center justify-center">
                        <TailSpin color="#5466F9" height={15} width={15} />
                    </div>
                )}
                {!(isSetAsKeyContactLoading || isRemoveAsDirectorLoading || isSetAsDirectorLoading) && (
                    <>
                        <DropdownHead placeholder={<GoogleIcon icon="more_horiz" size="lg" />} placement="right" clickAndClose icon>
                            {!props.data.isSignedUp && (
                                <DropdownLink onClick={() => setIsRemoveSignatoryModalOpen(true)} redHover>
                                    <span className="text-sm">Remove Signatory</span>
                                </DropdownLink>
                            )}
                            {!props.data.isSignedUp && (
                                <DropdownLink onClick={() => props.editFunc()} isLink>
                                    <span className="text-sm">Edit Signatory Details</span>
                                </DropdownLink>
                            )}
                            {!props.data.isKeyContact && (
                                <DropdownLink
                                    onClick={() => void handleSetAsKeyContact({ id: props.data.id, signatoryId: props.data.userId || "" })}
                                    isLink
                                >
                                    <span className="text-sm">Make Key Contact</span>
                                </DropdownLink>
                            )}
                            {props.data.isDirector && (
                                <DropdownLink
                                    onClick={() => void handleRemoveAsDirector({ id: props.data.id, signatoryId: props.data.userId || "" })}
                                    redHover
                                >
                                    <span className="text-sm">Remove as Director</span>
                                </DropdownLink>
                            )}

                            {!props.data.isDirector && (
                                <DropdownLink
                                    onClick={() => void handleSetAsDirector({ id: props.data.id, signatoryId: props.data.userId || "" })}
                                    isLink
                                >
                                    <span className="text-sm">Set as Director</span>
                                </DropdownLink>
                            )}
                        </DropdownHead>
                    </>
                )}
            </div>
        </>
    );
}

export default AccountSignatoriesLineDropdown;
