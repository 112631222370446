import { AccountOpeningStatus } from "../accountOpening.constants";
import { GenericObject } from "../../../helpers/types";
import NewAccountOpeningProgressGeneric from "./newAccountOpeningProgressGeneric";
import Parsers from "../../../utils/parsers";
import { immerable } from "immer";

export default class NewAccountOpeningProgress {
    [immerable] = true;

    constructor(
        public status: AccountOpeningStatus,

        public business: NewAccountOpeningProgressGeneric,
        public signatories: NewAccountOpeningProgressGeneric | null,
        public documents: NewAccountOpeningProgressGeneric | null,
        public agreements: NewAccountOpeningProgressGeneric | null,
        public currentSignatoryAgreements: NewAccountOpeningProgressGeneric | null,
        public referees: NewAccountOpeningProgressGeneric | null,
        public personal: NewAccountOpeningProgressGeneric // public identity: NewAccountOpeningProgressGeneric
    ) {}

    static create(obj: GenericObject): NewAccountOpeningProgress {
        return new NewAccountOpeningProgress(
            Parsers.number(obj.status),
            Parsers.classObjectNonNullable(obj.business, NewAccountOpeningProgressGeneric),
            Parsers.classObject(obj.signatories, NewAccountOpeningProgressGeneric),
            Parsers.classObject(obj.documents, NewAccountOpeningProgressGeneric),
            Parsers.classObject(obj.agreements, NewAccountOpeningProgressGeneric),
            Parsers.classObject(obj.currentSignatoryAgreements, NewAccountOpeningProgressGeneric),
            Parsers.classObject(obj.referees, NewAccountOpeningProgressGeneric),
            Parsers.classObjectNonNullable(obj.personal, NewAccountOpeningProgressGeneric)
        );
    }

    get isInProgress(): boolean {
        return this.status === AccountOpeningStatus.IN_PROGRESS;
    }

    get isBeingReviewed(): boolean {
        return this.status === AccountOpeningStatus.REVIEWING;
    }

    get isBeingCreated(): boolean {
        return this.status === AccountOpeningStatus.CREATING;
    }

    get isCreated(): boolean {
        return this.status === AccountOpeningStatus.CREATED;
    }

    unregisteredProgress(): number {
        return Number(((this.business.progressPercentage() + this.personal.progressPercentage()) / 2).toFixed());
    }
    zambiaUnregisteredProgress(): number {
        return Number(
            ((this.business.progressPercentage() + this.personal.progressPercentage() + (this.documents?.progressPercentage() || 0)) / 3).toFixed()
        );
    }
    registeredProgress(): number {
        return Number(
            (
                (this.business.progressPercentage() +
                    this.personal.progressPercentage() +
                    (this.signatories?.progressPercentage() || 0) +
                    (this.documents?.progressPercentage() || 0) +
                    (this.agreements?.progressPercentage() || 0) +
                    (this.currentSignatoryAgreements?.progressPercentage() || 0) +
                    (this.referees?.progressPercentage() || 0)) /
                (this.referees ? 7 : 6)
            ).toFixed()
        );
    }
    invitedRegisteredProgress(): number {
        return Number(((this.personal.progressPercentage() + (this.currentSignatoryAgreements?.progressPercentage() || 0)) / 2).toFixed());
    }

    zambiaRegisteredProgress(): number {
        return Number(
            (
                (this.business.progressPercentage() +
                    this.personal.progressPercentage() +
                    (this.signatories?.progressPercentage() || 0) +
                    (this.documents?.progressPercentage() || 0)) /
                4
            ).toFixed()
        );
    }

    invitedZambiaRegisteredProgress(): number {
        return Number(this.personal.progressPercentage().toFixed());
    }

    isBusinessCompleted(): boolean {
        return this.business.isCompleted;
    }

    businessCompletedProgress(): number {
        return this.business.progressPercentage();
    }

    isPersonalCompleted(): boolean {
        return this.personal.isCompleted;
    }
    personalCompletedProgress(): number {
        return this.personal.progressPercentage();
    }

    isSignatoriesCompleted(): boolean {
        return this.signatories?.isCompleted || false;
    }
    signatoriesCompletedProgress(): number {
        return this.signatories?.progressPercentage() || 0;
        // return Number(this.signatories.progressPercentage().toFixed());
    }

    isDocumentsCompleted(): boolean {
        return this.documents?.isCompleted || false;
    }

    documentsCompletedProgress(): number {
        return this.documents?.progressPercentage() || 0;
    }

    isRefereesCompleted(): boolean {
        return this.referees ? this.referees?.isCompleted || false : true;
    }

    refereesCompletedProgress(): number {
        return this.referees?.progressPercentage() || 0;
    }

    isAgreementsCompleted(): boolean {
        return this.agreements?.isCompleted || false;
    }

    agreementsCompletedProgress(): number {
        return this.agreements?.progressPercentage() || 0;
    }

    isCurrentSignatoryAgreementCompleted(): boolean {
        return this.currentSignatoryAgreements?.isCompleted || false;
    }

    currentSignatoryAgreementProgress(): number {
        return this.currentSignatoryAgreements?.progressPercentage() || 0;
    }
}
