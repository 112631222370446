import { ExcludeProps, InputPropsToExclude } from "./types";
import React, { InputHTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";

import GoogleIcon from "../google-icon";
import useClickOutside from "../../hooks/useClickOutside";

// use to generate a unique id for the input
let inputCounter = 0;

function IconContainer({ show, children, className }: { show: boolean; children: React.ReactNode; className?: string }): JSX.Element {
    return (
        <div
            className={
                "flex cursor-default items-center justify-end text-black-secondary transition-all duration-75 ease-in-out " +
                `${show ? "opacity-100" : "opacity-0"} ` +
                `${className || ""}`
            }
        >
            {children}
        </div>
    );
}

interface InputProps extends ExcludeProps<InputHTMLAttributes<HTMLInputElement>, InputPropsToExclude> {
    icon?: ReactNode;
    label?: string;
    type?: string;
    value?: string | number | undefined;
    iconType?: string;
    isExpiry?: boolean;
    readOnly?: boolean;
    iconRight?: ReactNode;
    inputSize?: "sm" | "md" | "lg" | "xl" | "2xl";
    isActive?: boolean;
    isLoading?: boolean;
    fullWidth?: boolean;
    isDisabled?: boolean;
    helperText?: React.ReactNode;
    appendIcon?: ReactNode;
    placeholder?: ReactNode;
    appendOuterIcon?: ReactNode;
    inputPlaceholder?: string;
    mobileHelperText?: boolean;
    iconRightClassName?: string;
    bg?: string;

    onChange?: (value: string) => void;
    onClickRightIcon?: () => void;
}
function Input({
    icon,
    type,
    value,
    label,
    readOnly = false,
    isExpiry = false,
    iconType,
    fullWidth = false,
    inputSize = "xl",
    isActive,
    isLoading,
    iconRight,
    appendIcon,
    helperText = "",
    isDisabled = false,
    placeholder,
    appendOuterIcon,
    inputPlaceholder = "",
    mobileHelperText = false,
    iconRightClassName = "",
    bg = "",
    onChange,
    onClickRightIcon,
    ...otherProps
}: InputProps): JSX.Element {
    const [active, setActive] = useState(false);
    const [hasValue, setHasValue] = useState(false);
    const [uniqueId, setUniqueId] = useState("");

    const inputRef = useRef<HTMLInputElement | null>(null);

    const domNode = useClickOutside(() => setActive(false));

    useEffect(() => {
        setUniqueId(`input-${++inputCounter}`);
    }, []);

    useEffect(() => {
        setHasValue(!!value && String(value).length > 0);
    }, [value]);

    useEffect(() => {
        if (!isActive) return;
        inputRef.current && inputRef.current.focus();
        setActive(true);
    }, [isActive]);

    const [maxLength] = useState(9);

    const correctInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const inputValue = target.value;
        const key = event.key;
        let typeKey;
        const monthMin = "01";
        const monthMax = "12";
        const yearMin = new Date().getFullYear() - 2000;
        const yearMax = new Date().getFullYear() - 2000 + 25;

        if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
            if (/(\d)/.test(key)) {
                typeKey = "number";
            } else {
                typeKey = "specSymbol";
            }

            if (inputValue.length === 0 && Number(key) > 1) {
                // 2 -> 02/
                target.value = `0${key}/`;
                event.preventDefault();
            }
            // if (inputValue.length === 0 && key > "1") {
            // // 2 -> 02/
            // target.value = `0${key}${"/"}`;
            // event.preventDefault();
            // }

            if (inputValue.length === 1 && inputValue[0] !== "0" && Number(key) > 2) {
                // 16 -> 12/
                target.value = `${monthMax}/`;
                event.preventDefault();
                return;
            }
            // if (inputValue.length === 1 && inputValue[0] !== "0" && key > "2") {
            // // 16 -> 12/
            // target.value = `${monthMax}/`;
            // event.preventDefault();
            // return;
            // }

            if (inputValue.length === 1 && typeKey !== "specSymbol") {
                // 12 backspace -> 1
                target.value = `${inputValue}${key}/`;
                event.preventDefault();
            }

            if (inputValue.length === 2 && typeKey !== "specSymbol") {
                // 2 -> 02/
                target.value = `${inputValue}/${key}`;
                event.preventDefault();
                return;
            }

            if (inputValue === "0" && key === "0") {
                // 00 -> 01
                target.value = `${monthMin}/`;
                event.preventDefault();
                return;
            }

            if (target.value.length + 1 === maxLength) {
                // 12/11 -> 12/16
                const arr = target.value.split("/");

                if (arr[0].length === 2 && parseInt(arr[1] + key, 10) < yearMin) {
                    target.value = `${arr[0]}/${yearMin}`;
                    event.preventDefault();
                    return;
                }

                if (arr[0].length === 2 && parseInt(arr[1] + key, 10) > yearMax) {
                    // 12/55 -> 12/41
                    target.value = `${arr[0]}/${yearMax}`;
                    event.preventDefault();
                    return;
                }
            }
        } else {
            event.preventDefault();
            return;
        }

        onChange && onChange(target.value);
    };

    return (
        <div className={"relative flex w-full flex-col items-center " + (fullWidth ? "w-full" : "")}>
            <div
                className={
                    `relative flex h-full w-full flex-col items-center justify-start ` +
                    `${isDisabled ? "pointer-events-none" : ""} ` +
                    `${readOnly ? "pointer-events-none" : ""} ` +
                    `${!(readOnly && isDisabled) ? "cursor-text" : ""}`
                }
                ref={domNode}
            >
                <div
                    className={
                        `relative flex w-full items-center justify-between space-x-1 shadow-none duration-150 ` +
                        ` overflow-hidden rounded-lg border bg-white outline-none focus:outline-none` +
                        ` ${active ? "border-black-secondary" : "border-black-quin lg:hover:border-black-tertiary"} ` +
                        ` ${inputSize === "sm" ? "h-12" : ""} ` +
                        ` ${inputSize === "md" ? "h-12" : ""} ` +
                        ` ${inputSize === "lg" ? "h-12" : ""} ` +
                        ` ${inputSize === "xl" ? "h-14" : ""} ` +
                        ` ${inputSize === "2xl" ? "h-16" : ""} ` +
                        ` ${isLoading ? "pointer-events-none" : ""} ` +
                        ` ${isDisabled ? "pointer-events-none !border-black-quin lg:hover:!border-black-quin" : "group/input focus:border-black-secondary"} ` +
                        ` ${icon ? "px-4" : ""}` +
                        ` ${bg} `
                    }
                    ref={domNode}
                    onBlur={() => setActive(false)}
                    onClick={() => {
                        inputRef.current && inputRef.current.focus();
                        setActive(true);
                    }}
                    onFocus={() => {
                        inputRef.current && inputRef.current.focus();
                        setActive(true);
                    }}
                >
                    {icon && (
                        <div
                            className={
                                `flex cursor-default items-center justify-end self-end duration-75 ease-in-out ` +
                                ` ${inputSize === "sm" ? "pb-1" : ""}` +
                                ` ${inputSize === "md" ? "pb-1" : ""}` +
                                ` ${inputSize === "lg" ? "pb-1" : ""}` +
                                ` ${inputSize === "xl" ? "pb-1.5" : ""}` +
                                ` ${inputSize === "2xl" ? "pb-2" : ""}` +
                                ` ${isDisabled ? "text-black-quat" : "text-black-secondary"}` +
                                ` ${placeholder || label ? (!isActive ? (active ? "opacity-100" : "opacity-0") : "") : ""}`
                            }
                        >
                            {icon}
                        </div>
                    )}
                    <input
                        ref={inputRef}
                        type={type ? type : "text"}
                        value={value || ""}
                        onKeyDown={isExpiry ? correctInput : undefined}
                        maxLength={isExpiry ? 5 : 256}
                        onChange={(e) => onChange && onChange(e.target.value)}
                        className={
                            `h-fit w-full self-end !bg-transparent text-base focus:border-none focus:outline-none ` +
                            ` ${inputSize === "sm" ? "pb-1" : ""}` +
                            ` ${inputSize === "md" ? "pb-1" : ""}` +
                            ` ${inputSize === "lg" ? "pb-1" : ""}` +
                            ` ${inputSize === "xl" ? "pb-1.5" : ""}` +
                            ` ${inputSize === "2xl" ? "pb-2" : ""}` +
                            ` ${type === "password" ? "text-xl" : ""}` +
                            ` ${!icon ? "px-4" : ""}` +
                            ` ${isDisabled ? "text-black-quat" : "text-black-secondary"}`
                        }
                        id={otherProps.id || uniqueId}
                        disabled={isDisabled}
                        tabIndex={readOnly || isDisabled ? -1 : 0}
                        {...otherProps}
                        placeholder={placeholder || label ? (active ? inputPlaceholder : undefined) : inputPlaceholder}
                    />
                    {iconRight && (
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClickRightIcon && onClickRightIcon();
                            }}
                            className={
                                `flex h-full cursor-pointer items-center justify-center border-l border-black-quin py-0 pl-3 pr-4 text-black-secondary transition-all duration-75 ease-in-out ` +
                                `${iconRightClassName} ` +
                                `${!isActive ? (active || hasValue ? "opacity-100" : "pointer-events-none opacity-0") : ""}`
                            }
                        >
                            {iconRight}
                        </div>
                    )}
                    {iconType === "percentage" ? (
                        <div
                            className={`cursor-default pr-4 transition-all duration-75 ease-in-out ${
                                active || hasValue ? "opacity-100" : "opacity-0"
                            }`}
                        >
                            <GoogleIcon icon="percent" />
                        </div>
                    ) : (
                        appendIcon && <IconContainer show={active || hasValue}>{appendIcon}</IconContainer>
                    )}
                    {appendOuterIcon && (
                        <div className="px-3">
                            <IconContainer
                                show
                                className={
                                    `text-black-quat peer-hover/input:text-black-secondary peer-focus/input:text-black` + ""
                                    // `${iconRightClassName} ` +
                                }
                            >
                                {appendOuterIcon}
                            </IconContainer>
                        </div>
                    )}
                    {(placeholder || label) && (
                        <label
                            htmlFor={otherProps.id || uniqueId}
                            className={
                                `pointer-events-none absolute left-4 z-10 !ml-0 leading-6 duration-150 group-hover/input:text-black-tertiary ` +
                                ` ${active ? "top-0 text-2xs text-black-tertiary" : " top-3 text-base"}` +
                                // ` ${!active && inputSize === "xl" ? "!top-2.5" : ""}` +
                                ` ${inputSize === "sm" ? "leading-6" : ""}` +
                                ` ${inputSize === "md" ? "leading-6" : ""}` +
                                ` ${inputSize === "lg" ? "leading-6" : ""}` +
                                ` ${inputSize === "xl" ? (active || hasValue ? "leading-8" : "!top-3.5 leading-7") : ""}` +
                                ` ${inputSize === "2xl" ? (active || hasValue ? "leading-10" : "!top-3.5 leading-9") : ""}` +
                                // ` ${inputSize === "2xl" ? "leading-9" : ""}` +
                                ` ${hasValue ? "!top-0 !text-2xs !text-black-tertiary" : "text-black-quat"}` +
                                ` ${isDisabled ? "!text-black-quat" : ""}`
                            }
                            onClick={() => {
                                if (inputRef.current) {
                                    inputRef.current.focus();
                                }
                            }}
                        >
                            <span className="h-fit max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{placeholder || label}</span>
                        </label>
                    )}
                </div>
            </div>
            {helperText && (
                <span
                    className={
                        `w-full pt-2 text-left text-xs leading-4 text-black-tertiary ` +
                        `${mobileHelperText ? "lg:hidden" : ""} ` +
                        `${isDisabled ? "text-black-quat" : ""} `
                    }
                >
                    {helperText}
                </span>
            )}
        </div>
    );
}

export default Input;
