import BillVendor from "../../../../../../../models/billVendor";
import { setAirtimePurchaseProductId } from "../../../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseSingleTransferRecipientBankCodeInterface {
    handleSelectProduct: (_vendor: BillVendor) => void;
}

function useAirtimePurchaseProduct(): UseSingleTransferRecipientBankCodeInterface {
    const dispatch = useDispatch();

    const handleSelectProduct = useCallback((_vendor: BillVendor) => {
        dispatch(setAirtimePurchaseProductId(_vendor));
    }, []);

    return { handleSelectProduct };
}

export default useAirtimePurchaseProduct;
