import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../../../redux/rootReducer";
import React from "react";
import { formatDateAndTime } from "../../../../../../../utils/formatDate";
import formatNumber from "../../../../../../../utils/formatNumber";
import { setIsZambiaCollectionsSettlementsDetailCardOpen } from "../../../../../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";
import FullPageTray from "../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayHeader from "../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import FullPageTrayBody from "../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import CurrencyCode from "../../../../../../../components/currency-code";
import titleCase from "../../../../../../../hooks/titleCase";
import SettlementStatusTag from "../../Transactions/Status/SettlementStatusTag";

const DATA_TYPE = "zambia-collections-settlement";

function ZambiaCollectionsTransaction(): JSX.Element {
    const dispatch = useDispatch();

    const zambiaCollectionsSettlementsDetail = useSelector(
        (state: IRootState) => state.zambiaCollectionsSettlements.zambiaCollectionsSettlementsDetail
    );

    const isZambiaCollectionsSettlementsDetailCardOpen: boolean = useSelector(
        (state: IRootState) => state.zambiaCollectionsSettlements.isZambiaCollectionsSettlementsDetailCardOpen
    );

    const handleCloseAddMoneyCollectionRequestTray = () => {
        dispatch(setIsZambiaCollectionsSettlementsDetailCardOpen(false));
    };

    return (
        <>
            {zambiaCollectionsSettlementsDetail && (
                <FullPageTray
                    active={isZambiaCollectionsSettlementsDetailCardOpen}
                    dataType={DATA_TYPE}
                    handleReset={handleCloseAddMoneyCollectionRequestTray}
                    withClickOutside
                >
                    <FullPageTrayHeader toggler={handleCloseAddMoneyCollectionRequestTray} dataType={DATA_TYPE}>
                        Settlement Details
                    </FullPageTrayHeader>
                    <FullPageTrayBody dataType={DATA_TYPE}>
                        <div className="mb-10 flex w-full flex-col space-y-4">
                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                                <div className="flex w-full justify-between space-x-8 py-2">
                                    <p className="text-2xl font-bold">
                                        <CurrencyCode currency={zambiaCollectionsSettlementsDetail?.currency} />
                                        {formatNumber(zambiaCollectionsSettlementsDetail.settlementAmount || 0)}
                                    </p>

                                    <div className="">
                                        <SettlementStatusTag settlement={zambiaCollectionsSettlementsDetail} />
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Channel</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {titleCase(zambiaCollectionsSettlementsDetail.channelText || "")}
                                    </p>
                                </div>
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Type</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {titleCase(zambiaCollectionsSettlementsDetail.mobileMoneyCollection?.serviceProviderText || "-")}
                                    </p>
                                </div>
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Date</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {formatDateAndTime(zambiaCollectionsSettlementsDetail?.createdAt as Date)}
                                    </p>
                                </div>
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Transaction Reference</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {zambiaCollectionsSettlementsDetail.collectionRequest?.paymentReference}
                                    </p>
                                </div>
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Transaction Amount</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        <CurrencyCode currency={zambiaCollectionsSettlementsDetail.collectionRequest?.currency} />
                                        {formatNumber(zambiaCollectionsSettlementsDetail.collectionRequest?.amount)}
                                    </p>
                                </div>

                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Fees</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        <CurrencyCode currency={zambiaCollectionsSettlementsDetail.collectionRequest?.currency} />
                                        {formatNumber(zambiaCollectionsSettlementsDetail.collectionRequest?.lencoFee)}
                                    </p>
                                </div>
                            </div>
                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                                {zambiaCollectionsSettlementsDetail.isCard ? (
                                    <>
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {zambiaCollectionsSettlementsDetail.cardPaymentCollection?.cardDetails?.name}
                                            </p>
                                        </div>{" "}
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">PAN</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {zambiaCollectionsSettlementsDetail.cardPaymentCollection?.cardDetails?.maskedPan?.replaceAll(
                                                    /x/gi,
                                                    "•"
                                                )}{" "}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {zambiaCollectionsSettlementsDetail.cardPaymentCollection?.cardDetails?.name}
                                            </p>
                                        </div>{" "}
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Phone Number</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {zambiaCollectionsSettlementsDetail.mobileMoneyCollection?.debitPhoneNumber}
                                            </p>
                                        </div>{" "}
                                    </>
                                )}
                            </div>
                        </div>
                    </FullPageTrayBody>
                </FullPageTray>
            )}
        </>
    );
}

export default ZambiaCollectionsTransaction;
