import * as Yup from "yup";

import {
    BusinessInformationFields,
    CompanyHint,
    getBusinessInformationHints,
} from "../../../../../../services/nigeria-application/registered/company.types";
import { Form, Formik } from "formik";
import { HintType, ListStyle } from "../../../../../element/hint/hint.constant";
import { useLocation, useNavigate } from "react-router";

import ApplicationHeader from "../../../../../element/ApplicationHeader";
import BusinessInformationReview from "../../business-review/components/review/BusinessInformationReview";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import FormInput from "../../../../../../../../../components/inputs/FormInput";
import Hint from "../../../../../element/hint";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import { Routes } from "../../../../../../../../../routes/routes.constants";
import SingleSelectDropdown from "../../../../../../../../../components/dropdown/single-select/single-select";
import useBusinessInformation from "../../../../../../Hooks/nigeria-application/registered-business/business-details/useBusinessInformation";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const BusinessInformationHints: CompanyHint[] = [
    {
        index: 0,
        span: <span>TIN - Tax Identification Number</span>,
    },
    {
        index: 1,
        span: <span>SCUML - Special Control Unit Against Money Laundry</span>,
    },
];

type LocationState = {
    from?: string;
};

function BusinessInformation(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isBusinessInformationLoading, handleReset, handleUpdateBusinessInformation } = useBusinessInformation();

    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const businessDetails = useSelector((state: IRootState) => state.application.businessDetails);

    const INITIAL_FORM_STATE = {
        [BusinessInformationFields.NAME]: businessDetails?.name || "",
        [BusinessInformationFields.INDUSTRY]: Number(businessDetails?.industry?.id) || null,
        [BusinessInformationFields.BUSINESS_CATEGORY]: businessDetails?.registrationCategory || null,
        [BusinessInformationFields.TURNOVER]: businessDetails?.annualTurnover || null,
        [BusinessInformationFields.TAX_NUMBER]: businessDetails?.taxNumber || "",
        [BusinessInformationFields.SCUML_NUMBER]: businessDetails?.scumlNumber || "",
        [BusinessInformationFields.BUSINESS_NATURE]: businessDetails?.businessNature || "",
        // [BusinessInformationFields.YEAR_OF_INCORPORATION]: businessDetails?.dateOfInc || null,
        // [BusinessInformationFields.RCBN_NUMBER]: businessDetails?.rcNumber || "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        [BusinessInformationFields.NAME]: Yup.string().required("Required"),
        [BusinessInformationFields.INDUSTRY]: Yup.number().nullable().required("Required"),
        [BusinessInformationFields.BUSINESS_CATEGORY]: Yup.number().nullable().required("Required"),
        [BusinessInformationFields.TURNOVER]: Yup.number().nullable().required("Required"),
        [BusinessInformationFields.TAX_NUMBER]: Yup.string().nullable().required("Required"),
        [BusinessInformationFields.SCUML_NUMBER]: Yup.string(),
        [BusinessInformationFields.BUSINESS_NATURE]: Yup.string().required("Required"),
        // [BusinessInformationFields.YEAR_OF_INCORPORATION]: Yup.string().nullable().required("Required"),
        // [BusinessInformationFields.RCBN_NUMBER]: Yup.string().required("Required"),
    });

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    void handleUpdateBusinessInformation(values);
                }}
                enableReinitialize
                validateOnMount
                validateOnBlur
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <div className="flex w-full flex-row items-start justify-start space-x-6">
                                {accountOpening && (
                                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                                        <ApplicationHeader
                                            header="Business Information"
                                            subTitle="To get started, tell us about your business"
                                            progress={accountOpening.progress.businessCompletedProgress()}
                                        >
                                            {!accountOpening?.isCreator && (
                                                <div className="flex w-full flex-col items-start justify-start">
                                                    <BusinessInformationReview data={businessDetails} />
                                                </div>
                                            )}
                                            {accountOpening?.isCreator && (
                                                <div className="w-full">
                                                    <div className="flex w-full flex-col space-y-4">
                                                        <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                            <div className="w-full md:w-50%">
                                                                <FormInput
                                                                    label="Legal Business Name"
                                                                    name="name"
                                                                    isDisabled={!accountOpening.isCreator}
                                                                />
                                                            </div>
                                                            <div className="md:w-6/12">
                                                                <SingleSelectDropdown
                                                                    placeholder="Sector/Industry"
                                                                    options={accountOpening.options.industry.map((el) => {
                                                                        return {
                                                                            text: el.name,
                                                                            value: el.id,
                                                                        };
                                                                    })}
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            void formik.getFieldHelpers("industryId").setValue(value);
                                                                        }
                                                                    }}
                                                                    defaultValue={formik.values.industryId || undefined}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                    bigDropdown={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="grid-col-1 grid w-full gap-4 md:grid-cols-2">
                                                            <div className="w-full">
                                                                <SingleSelectDropdown
                                                                    placeholder="Business Type"
                                                                    options={accountOpening.options.registrationCategory.map((el) => {
                                                                        return {
                                                                            text: el.name,
                                                                            value: el.id,
                                                                        };
                                                                    })}
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            void formik.getFieldHelpers("registrationCategoryId").setValue(value);
                                                                        }
                                                                    }}
                                                                    defaultValue={formik.values.registrationCategoryId || undefined}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                    bigDropdown={false}
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <FormInput label="SCUML Number (Optional)" name="scumlNumber" />
                                                            </div>
                                                        </div>
                                                        <div className="flex w-full flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                                                            <div className="w-full md:w-50%">
                                                                <SingleSelectDropdown
                                                                    placeholder="Annual Turnover"
                                                                    options={
                                                                        accountOpening.options.turnover.map((el) => {
                                                                            return {
                                                                                text: el.name,
                                                                                value: el.id,
                                                                            };
                                                                        }) || []
                                                                    }
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            void formik.getFieldHelpers("annualTurnoverId").setValue(value);
                                                                        }
                                                                    }}
                                                                    defaultValue={formik.values.annualTurnoverId || undefined}
                                                                    isDisabled={!accountOpening.isCreator}
                                                                    bigDropdown={false}
                                                                />
                                                            </div>
                                                            <div className="relative md:w-6/12">
                                                                <FormInput label="TIN" name="taxNumber" isDisabled={!accountOpening.isCreator} />
                                                            </div>
                                                        </div>
                                                        <div className="w-full">
                                                            <FormInput
                                                                type="textarea"
                                                                placeholder="Describe Your Business"
                                                                name="businessNature"
                                                                value={formik.values.businessNature}
                                                                isDisabled={!accountOpening.isCreator}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </ApplicationHeader>
                                        {accountOpening.isCreator ? (
                                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                    <ButtonComp
                                                        type="button"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        func={() => {
                                                            if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                                                                navigate(Routes.ACCOUNT_OPENING.REVIEW);
                                                                return;
                                                            }
                                                            navigate(Routes.ACCOUNT_OPENING.STATUS);
                                                        }}
                                                        fullWidth
                                                    >
                                                        <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                                    </ButtonComp>
                                                </div>
                                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                                    <ButtonComp
                                                        type={formik.dirty ? "submit" : "button"}
                                                        color="black"
                                                        ripple="light"
                                                        buttonType="primary"
                                                        isLoading={isBusinessInformationLoading}
                                                        func={() => {
                                                            !formik.dirty
                                                                ? navigate(
                                                                      {
                                                                          pathname: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT,
                                                                      },
                                                                      {
                                                                          state: {
                                                                              from: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION,
                                                                          },
                                                                      }
                                                                  )
                                                                : undefined;
                                                        }}
                                                        fullWidth
                                                        disable={
                                                            !!(
                                                                (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW && !formik.dirty) ||
                                                                !accountOpening.isCreator ||
                                                                formik.errors.name ||
                                                                formik.errors.registrationCategoryId ||
                                                                formik.errors.businessNature ||
                                                                formik.errors.industryId ||
                                                                formik.errors.annualTurnoverId ||
                                                                // formik.errors.rcbnNumber ||
                                                                formik.errors.taxNumber
                                                            )
                                                        }
                                                    >
                                                        <span>
                                                            {locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? "Save changes" : "Continue"}
                                                        </span>
                                                    </ButtonComp>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                                                <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                                    <ButtonComp
                                                        type="button"
                                                        color="grey"
                                                        ripple="light"
                                                        buttonType="secondary"
                                                        func={() => {
                                                            if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                                                                navigate(Routes.ACCOUNT_OPENING.REVIEW);
                                                                return;
                                                            }
                                                            navigate(Routes.ACCOUNT_OPENING.STATUS);
                                                        }}
                                                        fullWidth
                                                    >
                                                        <span>Back</span>
                                                    </ButtonComp>
                                                </div>
                                                {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                        <ButtonComp
                                                            type="button"
                                                            color="black"
                                                            ripple="light"
                                                            buttonType="primary"
                                                            func={() =>
                                                                navigate(
                                                                    {
                                                                        pathname: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT,
                                                                    },
                                                                    {
                                                                        state: {
                                                                            from: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                            fullWidth
                                                        >
                                                            <span>Continue</span>
                                                        </ButtonComp>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {accountOpening?.isCreator && (
                                    <Hint
                                        hintType={HintType.GUIDE}
                                        listStyle={ListStyle.CHECK}
                                        firstList={getBusinessInformationHints(Object.keys(formik.values || []), Object.keys(formik.errors || []), [
                                            BusinessInformationFields.SCUML_NUMBER,
                                        ])}
                                        secondHint
                                        secondHintType={HintType.TOAST_INFO}
                                        secondListStyle={ListStyle.BULLET}
                                        secondList={BusinessInformationHints}
                                    />
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default BusinessInformation;
