import { useCallback, useState } from "react";

import { DownloadZambiaApiAccessKeyLogoRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { downloadZambiaApiAccessKeyLogoApi } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface UseDownloadZambiaApiAccessKeyLogoInterface {
    zambiaApiAccessKeyLogo: Blob | null;
    isDownloadZambiaApiAccessKeyLogoLoading: boolean;
    downloadZambiaApiAccessKeyLogoErrorMessage: string;
    handleReset: () => void;
    handleDownloadZambiaApiAccessKeyLogo: (_data: DownloadZambiaApiAccessKeyLogoRequest) => void;
}

// interface Props {
// onComplete: (blob: Blob) => void;
// }

function useDownloadZambiaApiAccessKeyLogo(): UseDownloadZambiaApiAccessKeyLogoInterface {
    const dispatch = useDispatch();
    const [zambiaApiAccessKeyLogo, setZambiaApiAccessKeyLogo] = useState<Blob | null>(null);
    const [isDownloadZambiaApiAccessKeyLogoLoading, setIsDownloadZambiaApiAccessKeyLogoLoading] = useState(false);
    const [downloadZambiaApiAccessKeyLogoErrorMessage, setDownloadZambiaApiAccessKeyLogoErrorMessage] = useState<string>("");

    const handleDownloadZambiaApiAccessKeyLogo = useCallback(
        async (_data: DownloadZambiaApiAccessKeyLogoRequest) => {
            try {
                setZambiaApiAccessKeyLogo(null);
                setIsDownloadZambiaApiAccessKeyLogoLoading(true);
                setDownloadZambiaApiAccessKeyLogoErrorMessage("");
                const res = await downloadZambiaApiAccessKeyLogoApi(_data);
                setZambiaApiAccessKeyLogo(res);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDownloadZambiaApiAccessKeyLogoErrorMessage(errorMessage);
            } finally {
                setIsDownloadZambiaApiAccessKeyLogoLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = () => {
        setZambiaApiAccessKeyLogo(null);
        setIsDownloadZambiaApiAccessKeyLogoLoading(false);
        setDownloadZambiaApiAccessKeyLogoErrorMessage("");
    };

    return {
        zambiaApiAccessKeyLogo,
        isDownloadZambiaApiAccessKeyLogoLoading,
        downloadZambiaApiAccessKeyLogoErrorMessage,
        handleReset,
        handleDownloadZambiaApiAccessKeyLogo,
    };
}

export default useDownloadZambiaApiAccessKeyLogo;
