import { FilterDateObj, FilterDateType, FilterDateTypes } from "../../../../../../components/filter/filter.constant";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DateModal from "../../../../../../components/modal/date-modal";
import { DateParse } from "../../../../../../utils/dateParsers";
import ExportModal from "../../../../../../components/modal/export-modal";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MultiSelectDropdown from "../../../../settings-new/Components/DropDowns/TeamMembers/MultiSelectDropdown";
import { TransactionStatus } from "../../../../../../models/transaction.constants";
import { ZambiaPayoutsStatusOptions } from "../../../hookss/payoutss/zambiaPayouts.constants";
import { setIsExportZambiaPayoutsTrayOpen } from "../../../../../../redux/zambia/payouts/zambiaPayoutsSlice";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import useExportZambiaPayouts from "../../../hookss/payoutss/useExportZambiaPayouts";
import ButtonComp from "../../../../../../components/button/ButtonComp";

const DATA_TYPE = "export-zambia-payouts";

function ExportZambiaPayoutsTray(): JSX.Element {
    const dispatch = useDispatch();

    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts || []);
    const isExportZambiaPayoutsTrayOpen: boolean = useSelector((state: IRootState) => state.zambiaPayouts.isExportZambiaPayoutsTrayOpen);

    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [isExportedModalOpen, setIsExportedModalOpen] = useState(false);

    const [exportFilter, setExportFilter] = useState<{
        query: string;
        offset: number;
        statuses: TransactionStatus[];
        userAccountIds: string[];
        selectedDateType: FilterDateTypes;
        date: FilterDateObj;
    }>({
        query: "",
        offset: 0,
        statuses: [],
        userAccountIds: [],
        selectedDateType: FilterDateTypes.ALL_TIME,
        date: {
            begin: null,
            end: null,
        },
    });

    const { isExportZambiaPayoutsLoading, handleExportZambiaPayouts } = useExportZambiaPayouts({
        onComplete: () => {
            setIsExportedModalOpen(true);
            dispatch(setIsExportZambiaPayoutsTrayOpen(false));
        },
    });

    useEffect(() => {
        if (!isExportZambiaPayoutsTrayOpen) {
            setTimeout(() => {
                setExportFilter({
                    query: "",
                    offset: 0,
                    statuses: [],
                    userAccountIds: [],
                    selectedDateType: FilterDateTypes.ALL_TIME,
                    date: {
                        begin: null,
                        end: null,
                    },
                });
            }, 500);
        }
    }, [isExportZambiaPayoutsTrayOpen]);

    const handleCloseExportZambiaPayoutsTray = () => {
        dispatch(setIsExportZambiaPayoutsTrayOpen(false));
    };

    const domNode = useClickOutside(
        () => {
            handleCloseExportZambiaPayoutsTray();
        },
        (eventTarget: HTMLElement) => {
            return !!(eventTarget.dataset.type !== DATA_TYPE || !eventTarget.dataset.type.includes(DATA_TYPE));
        },
        ["mousedown", "keydown"]
    );

    return (
        <>
            {/*<===Terminal Transaction Details Code goes here===>*/}
            {/* {zambiaPayoutsDetail && ( */}
            <div className="w-full" ref={domNode}>
                <DateModal
                    isActive={isDateModalOpen}
                    is3MonthsMax
                    dataType={DATA_TYPE}
                    handleToggleModal={() => setIsDateModalOpen(false)}
                    handleSelectDate={(_data: FilterDateType) =>
                        setExportFilter((prev) => ({
                            ...prev,
                            selectedDateType: _data.name,
                            date: _data.date,
                        }))
                    }
                />
                <ExportModal active={isExportedModalOpen} handleClose={() => setIsExportedModalOpen(false)} />
                <FullPageTray
                    active={isExportZambiaPayoutsTrayOpen}
                    dataType={DATA_TYPE}
                    handleReset={handleCloseExportZambiaPayoutsTray}
                    // withClickOutside
                >
                    <FullPageTrayHeader toggler={handleCloseExportZambiaPayoutsTray} dataType={DATA_TYPE} menu={<></>}>
                        Export
                    </FullPageTrayHeader>
                    <FullPageTrayBody dataType={DATA_TYPE}>
                        <div className="mb-10 flex w-full flex-col space-y-4">
                            <div className="cursor-pointer" onClick={() => setIsDateModalOpen(true)}>
                                <Input
                                    type="text"
                                    placeholder="Date"
                                    name="date"
                                    value={
                                        exportFilter.date.begin && exportFilter.date.end
                                            ? `From ${DateParse.getDateString(exportFilter.date.begin)} To ${DateParse.getDateString(
                                                  exportFilter.date.end
                                              )}`
                                            : undefined
                                    }
                                    readOnly
                                    inputSize="lg"
                                />
                            </div>
                            <MultiSelectDropdown
                                placeholder="Status"
                                value={exportFilter.statuses}
                                options={ZambiaPayoutsStatusOptions.map((item) => ({
                                    text: item.name,
                                    value: item.value,
                                }))}
                                onChange={(value) => {
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        statuses: value,
                                    }));
                                }}
                                size="lg"
                            />
                            <MultiSelectDropdown
                                placeholder="User Account"
                                value={exportFilter.userAccountIds}
                                options={accounts.map((item) => ({
                                    text: item.lencoNameMin,
                                    value: item.id,
                                }))}
                                onChange={(value) => {
                                    setExportFilter((prev) => ({
                                        ...prev,
                                        userAccountIds: value,
                                    }));
                                }}
                                size="lg"
                            />
                        </div>
                        <div className="flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-6">
                            <ButtonComp
                                fullWidth
                                type="button"
                                size="md"
                                buttonType="secondary"
                                onClick={handleCloseExportZambiaPayoutsTray}
                                color={"grey"}
                            >
                                <span className="w-full text-center text-black-tertiary">Cancel</span>
                            </ButtonComp>

                            <ButtonComp
                                fullWidth
                                type="button"
                                size="md"
                                buttonType="primary"
                                disable={exportFilter.statuses.length < 1 && exportFilter.userAccountIds.length < 1 && !exportFilter.date.begin}
                                isLoading={isExportZambiaPayoutsLoading}
                                onClick={() =>
                                    handleExportZambiaPayouts({
                                        date: exportFilter.date,
                                        query: exportFilter.query,
                                        offset: exportFilter.offset,
                                        statuses: exportFilter.statuses,
                                        userAccountIds: exportFilter.userAccountIds,
                                    })
                                }
                                color={"black"}
                            >
                                <span className="w-full text-center">Export</span>
                            </ButtonComp>
                        </div>
                    </FullPageTrayBody>
                </FullPageTray>
            </div>
            {/* )} */}
        </>
    );
}

export default ExportZambiaPayoutsTray;
