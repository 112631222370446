import React, { useCallback, useEffect, useState } from "react";

import GoogleIcon from "../google-icon";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

export enum CheckType {
    NORMAL = 1,
    SUCCESS = 2,
    ERROR = 3,
}

interface Props {
    id: string;
    text?: React.ReactNode;
    size?: "xs" | "sm" | "md";
    type?: CheckType;
    checked?: boolean;
    subText?: React.ReactNode;
    dataType?: string;
    truncate?: boolean;
    readOnly?: boolean;
    fullWidth?: boolean;
    isErrorCheck?: boolean;

    func?: () => void;
}

function Checkbox({ type = CheckType.NORMAL, ...props }: Props): JSX.Element {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const { truncate = false } = props;

    const getCheckColor = useCallback((cColor: CheckType) => {
        if (cColor === CheckType.NORMAL) return "border-blue bg-blue";
        if (cColor === CheckType.SUCCESS) return "bg-success-secondary";
        if (cColor === CheckType.ERROR) return "bg-error-secondary";
        return "";
    }, []);

    useEffect(() => {
        if (isNullOrUndefined(props.checked)) return;
        setIsChecked(props.checked);
    }, [props.checked]);

    const handleClick = () => {
        setIsChecked((prev) => !prev);
        props.func && props.func();
    };

    return (
        <div className={props.fullWidth ? "w-full" : "w-max"}>
            <div
                className={
                    `flex flex-row items-center justify-start ` +
                    `${props.readOnly ? "pointer-events-none" : "cursor-pointer"} ` +
                    `${props.fullWidth ? "w-full" : "w-max"}`
                }
                onClick={handleClick}
                data-type={props.dataType}
            >
                <div className="w-max" data-type={props.dataType}>
                    <div
                        className={
                            `relative flex items-center justify-center rounded transition-all duration-150 ` +
                            `${props.size === "xs" ? "h-3 w-3" : props.size === "sm" ? "h-4 w-4" : "h-5 w-5"} ` +
                            `${isChecked ? getCheckColor(type) : ""} ` +
                            `${
                                !isChecked && type === CheckType.NORMAL
                                    ? "border border-solid border-black-quin"
                                    : !isChecked && type !== CheckType.NORMAL
                                      ? "border border-solid border-black-quin"
                                      : isChecked && type === CheckType.SUCCESS
                                        ? "border border-solid border-success-secondary"
                                        : isChecked && type === CheckType.ERROR
                                          ? "border border-solid border-error-tertiary"
                                          : "border border-solid border-black-tertiary"
                            }` +
                            `${props.readOnly ? " border-black-quin" : ""} `
                        }
                        data-type={props.dataType}
                    >
                        <input
                            className="absolute left-0 top-0 hidden h-full w-full placeholder-transparent outline-none focus:outline-none"
                            checked={isChecked}
                            type={"checkbox"}
                            id={props.id}
                            readOnly
                            data-type={props.dataType}
                        />
                        <div
                            className={
                                "absolute left-0 top-0 flex h-full w-full items-center justify-center text-white " +
                                `${!isChecked ? "opacity-0" : ""}`
                            }
                        >
                            {props.isErrorCheck ? (
                                <GoogleIcon icon="close" size={props.size === "xs" ? "xs" : undefined} />
                            ) : (
                                <GoogleIcon icon="check" size={props.size === "xs" ? "xs" : undefined} />
                            )}
                        </div>
                    </div>
                </div>

                {props.text && (
                    <p
                        className={
                            `pointer-events-none w-fit pl-2 text-left font-medium capitalize ` +
                            `${truncate ? "max-w-90% overflow-hidden overflow-ellipsis whitespace-nowrap leading-none" : "leading-4"} ` +
                            `${props.readOnly ? "text-black-tertiary" : " text-black-secondary"} ` +
                            `${props.size === "sm" ? "text-sm" : "text-base"} ` +
                            `${props.fullWidth ? "w-full" : ""}`
                        }
                        data-type={props.dataType}
                    >
                        {props.text || ""}
                    </p>
                )}
            </div>
            {props.subText && (
                <p
                    className={
                        `pointer-events-none w-fit max-w-full pl-6 ` +
                        `${truncate ? "overflow-hidden overflow-ellipsis whitespace-nowrap leading-none" : "leading-4"} ` +
                        `${props.readOnly ? "text-black-tertiary" : " text-black-secondary"} ` +
                        `${props.size === "sm" ? "text-xs" : "text-sm"} `
                    }
                    data-type={props.dataType}
                >
                    {props.subText || ""}
                </p>
            )}
        </div>
    );
}

export default Checkbox;
