import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange, RangeKeyDict } from "react-date-range";
import { FilterDateObj, FilterDateTypes } from "../../../../../components/filter/filter.constant";
import React, { useCallback, useEffect, useState } from "react";
import { setIsCustomModalOpen, setTransactionDate } from "../../../../../redux/transactions/slice/transactionsSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../redux/rootReducer";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import moment from "moment";
import useDimension from "../../../../../hooks/useDimension";

interface CustomDateObj {
    startDate: Date;
    endDate: Date;
    key: string;
}

function CustomDatePickerModal(): JSX.Element {
    const dispatch = useDispatch();
    const { width } = useDimension();
    const isCustomModalOpen = useSelector((state: IRootState) => state.transactions.isCustomModalOpen);

    const [date, setDate] = useState<CustomDateObj[]>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    //reset filled
    useEffect(() => {
        if (!isCustomModalOpen) return;
        setDate([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            },
        ]);
    }, [isCustomModalOpen]);

    const handleCloseCustomDatePicker = useCallback(() => {
        dispatch(setIsCustomModalOpen(false));
    }, []);

    const handleDateRange = useCallback((_data: FilterDateObj) => {
        dispatch(
            setTransactionDate({
                name: FilterDateTypes.CUSTOM,
                date: { begin: _data.begin as string, end: _data.end as string },
            })
        );
    }, []);

    return (
        <>
            <Modal size={width < 476 ? "fill" : "lg"} active={isCustomModalOpen} toggler={handleCloseCustomDatePicker} dataType="transaction">
                <ModalHeader dataType="transaction" onClose={handleCloseCustomDatePicker}>
                    Choose a Date Range
                </ModalHeader>
                <ModalBody dataType="transaction">
                    <div className="relative flex w-full items-center justify-center [&>div]:w-full" data-type="transaction">
                        <DateRange
                            editableDateInputs={true}
                            onChange={(rangesByKey: RangeKeyDict) => {
                                setDate([
                                    (
                                        rangesByKey as unknown as {
                                            selection: {
                                                startDate: Date;
                                                endDate: Date;
                                                key: string;
                                            };
                                        }
                                    ).selection,
                                ]);
                            }}
                            // showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={width > 768 ? 2 : undefined}
                            maxDate={new Date()}
                            ranges={date}
                            direction="horizontal"
                            data-type="transaction"
                        />
                    </div>
                </ModalBody>

                <ModalFooter dataType="transaction">
                    <div className="flex w-full flex-col space-y-4 2xs:w-max 2xs:flex-row 2xs:space-x-4 2xs:space-y-0" data-type="transaction">
                        <div className="w-full 2xs:w-max" data-type="transaction">
                            <ButtonComp
                                type="button"
                                buttonType="secondary"
                                color="grey"
                                ripple="light"
                                fullWidth
                                func={handleCloseCustomDatePicker}
                                dataType="transaction"
                            >
                                <span data-type="transaction">Cancel</span>
                            </ButtonComp>
                        </div>
                        <div className="w-full 2xs:w-max" data-type="transaction">
                            <ButtonComp
                                type="button"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                fullWidth
                                dataType="transaction"
                                func={() => {
                                    handleCloseCustomDatePicker();
                                    const { startDate, endDate } = date[0];
                                    handleDateRange({
                                        begin: moment(startDate).format().slice(0, 10),
                                        end: moment(endDate).format().slice(0, 10),
                                    });
                                }}
                            >
                                <span data-type="transaction">Select Date</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CustomDatePickerModal;
