import { SignUpRequest, SignUpResponse } from "../services/sign-up-api.types";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { signup } from "../services/sign-up-api";

interface Props {
    onComplete: (_key: string) => void;
}

function useSignUpUser(props: Props): UseMutationResult<SignUpResponse, AxiosError | null, SignUpRequest, unknown> {
    const filter = useMutation({
        mutationFn: async (_data: SignUpRequest) => await signup(_data),
        onSuccess(data) {
            props.onComplete(data.emailVerification.key);
        },
        onError(error: AxiosError) {
            return Errorhandler(error, true);
        },
    });

    return filter;
}

export default useSignUpUser;
