import AddNewRecipient from "../componentss/Recipients/AddNewRecipient";
import CreateCategoryModal from "../../People/components/modals/create-category-modal";
import DeleteRecipientModal from "../componentss/Recipients/DeleteRecipientModal";
import DeleteUserGroup from "../../People/components/modals/delete-user-group-modal";
import EditCategoryModal from "../../People/components/modals/edit-category-modal";
import EditRecipientAliasModal from "../componentss/Recipients/EditRecipientAliasModal";
import { ReactComponent as EmptyX } from "../../../../assets/svg/EmptyX.svg";
import ListOfCategoriesModal from "../../People/components/modals/list-of-categories-modal";
import ManageCategoriesButton from "../../People/components/buttons/manage-categories-button";
import PageLayout from "../../../../components/layouts/page-layout";
import Pagination from "../../../../components/pagination";
import RecipientItemTableRow from "../componentss/Recipients/RecipientItemTableRow";
import RemoveFromUserGroupModal from "../componentss/Recipients/RemoveFromUserGroupModal";
import SavedRecipientSkeleton from "../componentss/Skeletons/SavedRecipientsSkeleton";
import SearchBar from "../../../../components/search-bar";
import SetRecipientGroupModal from "../componentss/Recipients/SetRecipientGroupModal";
import UserGroupDropdown from "../../People/components/dropdowns/user-group-dropdown";
import { abortAllSavedRecipientRequests } from "../services/savedRecipients/savedRecipients.api";
import { useEffect } from "react";
import { useSavedRecipientsState } from "../hooks/state/pending-approval/useSavedRecipientsState";

function SavedRecipients(): JSX.Element {
    const { query, offset, recipients, paginationData, isLoading, handleSetQuery, handleSelectUserGroup, handleSetOffset } =
        useSavedRecipientsState();

    useEffect(() => {
        return () => {
            abortAllSavedRecipientRequests();
        };
    }, []);

    return (
        <>
            <ListOfCategoriesModal />
            <CreateCategoryModal />
            <EditCategoryModal />
            <DeleteUserGroup />
            <RemoveFromUserGroupModal />
            <SetRecipientGroupModal />
            <EditRecipientAliasModal />
            <DeleteRecipientModal />

            <PageLayout pageTitle="Recipients" subTitle="List of payment recipients" headerButtons={<AddNewRecipient />}>
                <div className="flex w-full flex-col justify-start space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
                    <div className="w-full max-w-sm">
                        <SearchBar size="xl" placeholder="Search Recipients" value={query} onChange={(e) => handleSetQuery(e)} withWhitBg />
                    </div>

                    <div className="flex w-max items-center justify-center space-x-4">
                        <UserGroupDropdown onSelect={handleSelectUserGroup} />
                        <ManageCategoriesButton />
                    </div>
                </div>

                <div className="mt-4 flex h-full w-full flex-col items-center justify-start pb-8">
                    <div
                        className={
                            "left-0 top-0 h-fit w-full border border-grey " +
                            `${Array.from(recipients.values()).length < 1 && !isLoading ? "rounded-rb-none rounded-l-none rounded-tl-[10px] rounded-tr-[10px] border-b-0" : "rounded-custom"}`
                        }
                    >
                        <table className="w-full">
                            <thead className="custom-table-head">
                                <tr className="relative">
                                    <th className="w-20%" scope="col">
                                        Recipient Name
                                    </th>
                                    <th className="w-20%" scope="col">
                                        Account Number
                                    </th>
                                    <th className="w-20%" scope="col">
                                        Bank
                                    </th>
                                    <th className="w-15%" scope="col">
                                        Alias
                                    </th>
                                    <th className="w-20%" scope="col">
                                        Group
                                    </th>
                                    <th className="w-5%" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white [&>tr:not(:last-child)>td]:border-b [&>tr:not(:last-child)>td]:!border-b-grey-secondary [&>tr>td]:px-4">
                                {!isLoading &&
                                    Array.from(recipients.values()).length > 0 &&
                                    Array.from(recipients.values()).map((el, index) => {
                                        return <RecipientItemTableRow key={index} recipient={el} />;
                                    })}
                                {isLoading && <SavedRecipientSkeleton />}
                                {isLoading && <SavedRecipientSkeleton />}
                            </tbody>
                        </table>
                        {Array.from(recipients.values()).length < 1 && !isLoading && (
                            <div className="flex min-h-40 w-full flex-col items-center justify-center space-y-2 rounded-b-lg bg-white py-16">
                                <EmptyX className="h-16 w-16" />
                                <span className="text-sm text-black-tertiary">You have no recipients</span>
                            </div>
                        )}
                    </div>
                    {Array.from(recipients.values()).length > 0 && (
                        <Pagination
                            total={paginationData.total}
                            offset={offset}
                            onSelect={(_page, _offset) => handleSetOffset(_offset)}
                            groupSize={paginationData.groupSize}
                            isLoading={isLoading}
                        />
                    )}
                </div>
                {/* <div className="flex h-full w-full flex-col items-start justify-start pb-8 pt-8">
                    <div className="relative flex h-full w-full flex-col items-start justify-start">
                        <Table
                            dataType={SAVED_RECIPIENTS_DATATYPE}
                            heads={allSavedRecipientsHeader}
                            isPaginateLoading={isLoading}
                            groupSize={paginationData.groupSize}
                            offset={offset}
                            total={paginationData.total}
                            isLoading={isLoading}
                            paginateFunction={(_, pageOffset) => handleSetOffset(pageOffset)}
                            rows={Array.from(recipients.values())?.map((obj) => ({
                                record: [
                                    {
                                        key: "Name",
                                        text: obj?.customerAccount.accountName || "-",
                                    },
                                    {
                                        key: "Account Number",
                                        text: obj.customerAccount.accountNumber || "-",
                                    },
                                    {
                                        key: "Bank",
                                        text: obj?.bankName || obj?.customerAccount.bankAccount?.bankName || "-",
                                    },
                                    {
                                        key: "Alias",
                                        text: obj?.customerAccount.alias || "-",
                                    },
                                    {
                                        key: "Category",
                                        text: obj?.customerAccount.userGroup?.name,
                                    },
                                    {
                                        key: "Action",
                                        text: "-",
                                        textType: TextType.MEAT_BALL_MENU,
                                        dropDownOptions: [],
                                    },
                                ],
                            }))}
                        />
                    </div>
                </div> */}
            </PageLayout>
        </>
    );
}

export default SavedRecipients;
