import {
    ZambiaCollectionPaymentChannel,
    ZambiaCollectionSource,
    ZambiaCollectionStatus,
} from "../../../../../models/zambia/zambiaCollectionRequest.constant";

export enum ZambiaCollectionsTransactionsFilters {
    DATE = "date",
    STATUS = "status",
    SOURCE = "source",
    CHANNEL = "channel",
}

export const ZambiaCollectionsTransactionsFiltersArray = [
    ZambiaCollectionsTransactionsFilters.DATE,
    ZambiaCollectionsTransactionsFilters.STATUS,
    ZambiaCollectionsTransactionsFilters.CHANNEL,
    ZambiaCollectionsTransactionsFilters.SOURCE,
];

export const ZambiaCollectionsTransactionsFiltersText = {
    [ZambiaCollectionsTransactionsFilters.DATE]: "Date",
    [ZambiaCollectionsTransactionsFilters.STATUS]: "Status",
    [ZambiaCollectionsTransactionsFilters.SOURCE]: "Source",
    [ZambiaCollectionsTransactionsFilters.CHANNEL]: "Channel",
};
//Status
export const ZambiaCollectionsTransactionsStatusArray = [
    ZambiaCollectionStatus.FAILED,
    ZambiaCollectionStatus.EXPIRED,
    ZambiaCollectionStatus.PENDING,
    ZambiaCollectionStatus.CANCELLED,
    ZambiaCollectionStatus.SUCCESSFUL,
];

export const getZambiaCollectionsTransactionsStatusName = (_status: ZambiaCollectionStatus): string => {
    switch (_status) {
        case ZambiaCollectionStatus.FAILED:
            return "Failed";
        case ZambiaCollectionStatus.EXPIRED:
            return "Expired";
        case ZambiaCollectionStatus.PENDING:
            return "Pending";
        case ZambiaCollectionStatus.CANCELLED:
            return "Cancelled";
        case ZambiaCollectionStatus.SUCCESSFUL:
            return "Successful";
        default:
            return "";
    }
};

export const ZambiaCollectionsTransactionsStatusOptions = ZambiaCollectionsTransactionsStatusArray.map((_status) => ({
    name: getZambiaCollectionsTransactionsStatusName(_status),
    value: _status,
}));

//Channel
export const ZambiaCollectionsTransactionsChannelArray = [
    ZambiaCollectionPaymentChannel.CARD,
    ZambiaCollectionPaymentChannel.BANK_ACCOUNT,
    ZambiaCollectionPaymentChannel.MOBILE_MONEY,
];

export const getZambiaCollectionChannelName = (_status: ZambiaCollectionPaymentChannel): string => {
    switch (_status) {
        case ZambiaCollectionPaymentChannel.CARD:
            return "Card";
        case ZambiaCollectionPaymentChannel.BANK_ACCOUNT:
            return "Bank Account";
        case ZambiaCollectionPaymentChannel.MOBILE_MONEY:
            return "Mobile Money";
        default:
            return "";
    }
};

export const ZambiaCollectionsTransactionsChannelOptions = ZambiaCollectionsTransactionsChannelArray.map((_status) => ({
    name: getZambiaCollectionChannelName(_status),
    value: _status,
}));

//Source
export const ZambiaCollectionsTransactionsSourceArray = [ZambiaCollectionSource.API, ZambiaCollectionSource.BANKING_APP];

export const getZambiaCollectionsTransactionsSourceName = (_source: ZambiaCollectionSource): string => {
    switch (_source) {
        case ZambiaCollectionSource.API:
            return "API";
        case ZambiaCollectionSource.BANKING_APP:
            return "Banking App";
        default:
            return "";
    }
};

export const ZambiaCollectionsTransactionsSourceOptions = ZambiaCollectionsTransactionsSourceArray.map((_source) => ({
    name: getZambiaCollectionsTransactionsSourceName(_source),
    value: _source,
}));

//|=====done

export const ZambiaCollectionsTransactionsFiltersDefaultValues = {
    [ZambiaCollectionsTransactionsFilters.STATUS]: ZambiaCollectionStatus.PENDING,
    [ZambiaCollectionsTransactionsFilters.CHANNEL]: ZambiaCollectionPaymentChannel.CARD,
    [ZambiaCollectionsTransactionsFilters.SOURCE]: ZambiaCollectionSource.BANKING_APP,
};

export const ZambiaCollectionsTransactionsFiltersOptions: {
    [type in ZambiaCollectionsTransactionsFilters]:
        | {
              name: string;
              value: ZambiaCollectionStatus | ZambiaCollectionPaymentChannel | ZambiaCollectionSource;
          }[]
        | undefined;
} = {
    [ZambiaCollectionsTransactionsFilters.STATUS]: ZambiaCollectionsTransactionsStatusOptions,
    [ZambiaCollectionsTransactionsFilters.CHANNEL]: ZambiaCollectionsTransactionsChannelOptions,
    [ZambiaCollectionsTransactionsFilters.SOURCE]: ZambiaCollectionsTransactionsSourceOptions,
    [ZambiaCollectionsTransactionsFilters.DATE]: undefined,
};

export const ZambiaCollectionsTransactionsFiltersData = ZambiaCollectionsTransactionsFiltersArray.map((_collectionRequest) => ({
    name: ZambiaCollectionsTransactionsFiltersText[_collectionRequest],
    value: _collectionRequest,
    options: ZambiaCollectionsTransactionsFiltersOptions[_collectionRequest],
}));
