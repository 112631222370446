import BillPurchase from "../../../../../models/billPurchase";
import Money from "../../../../../components/money";
import { useCallback } from "react";

interface RecentContactsElementInterface {
    data: BillPurchase;
    productName?: boolean;
    isPhoneNumber?: boolean;
    selectedAccountId: string;
    onClick: (_data: BillPurchase) => void;
    handleSelectAccount: (_accountId: string) => void;
}

function RecentContactsElement({
    data,
    productName = false,
    isPhoneNumber = true,
    selectedAccountId,
    onClick,
    handleSelectAccount,
}: RecentContactsElementInterface): JSX.Element {
    const handleClick = useCallback(() => {
        if (!selectedAccountId) {
            handleSelectAccount(data.debitAccount.id);
        }
        onClick(data);
    }, [selectedAccountId]);

    return (
        <>
            <div
                className="flex max-w-7 cursor-pointer flex-col items-center justify-center rounded-[10px] border border-grey-secondary bg-grey-backdrop px-2 pb-2 pt-1"
                onClick={handleClick}
            >
                <div>
                    <div className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border bg-white p-2" data-type="dropdown">
                        <img
                            className="h-full w-full rounded-full object-cover"
                            src={data.vendor.logoUrl as string}
                            alt={data.vendor.name as string}
                        />
                    </div>
                </div>
                {!productName && (
                    <p className="w-full max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-center text-xs font-medium text-black-secondary">
                        <Money amount={data.amount} />
                    </p>
                )}
                {productName && (
                    <p className="w-full max-w-10 overflow-hidden overflow-ellipsis whitespace-nowrap text-center text-xs font-medium text-black-secondary">
                        {data.product.name}
                    </p>
                )}
                {isPhoneNumber && <p className="mt-1 text-center text-xs text-black-tertiary">{data.extraData.mobile.phone}</p>}
                {!isPhoneNumber && <p className="mt-1 text-center text-xs text-black-tertiary">{data.extraData.account.accountId}</p>}
            </div>
        </>
    );
}

export default RecentContactsElement;
