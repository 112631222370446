import { useCallback, useState } from "react";

import UserAccountMeta from "../../../../../../models/userAccountMeta";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { removeTeamMemberFromAccount } from "../../account-settings/account-settings.api";
import { useDispatch } from "react-redux";

export interface UseRemoveFromAccountResponse {
    isRemoving: boolean;
    handleRemoveFromAccount: (accountMeta: UserAccountMeta) => Promise<void>;
}

function useRemoveFromAccount(
    removeAccountMetaFromTeamMember: (accountMetaToRemove: UserAccountMeta) => void,
    onRemoveFromTeam: (memberId: string) => void
): UseRemoveFromAccountResponse {
    const dispatch = useDispatch();

    const [isRemoving, setIsRemoving] = useState(false);

    const handleRemoveFromAccount = useCallback(async (accountMeta: UserAccountMeta) => {
        setIsRemoving(true);
        try {
            const res = await removeTeamMemberFromAccount(accountMeta.id, accountMeta.userAccountId);
            if (res.removedFromTeam) {
                onRemoveFromTeam(accountMeta.id);
                dispatch(
                    messageTrue({
                        message: `${accountMeta.name.toLocaleUpperCase()} has been removed from the team`,
                    })
                );
            } else {
                removeAccountMetaFromTeamMember(accountMeta);
                dispatch(
                    messageTrue({
                        message: `${accountMeta.name.toLocaleUpperCase()} has been removed from the account`,
                    })
                );
            }
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsRemoving(false);
        }
    }, []);

    return {
        isRemoving,
        handleRemoveFromAccount,
    };
}

export default useRemoveFromAccount;
