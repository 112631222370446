import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TransactionCountOptions, TransactionVolumeOptions } from "../../../Services/terminals.constants";

// import AddFundsCard from "../../Card/AddFundsCard";
import { ReactComponent as BlueSuccessCheck } from "../../../../../../assets/svg/Terminal/blue-success-check.svg";
import Checkbox from "../../../../../../components/checkbox";
import CurrencyCode from "../../../../../../components/currency-code";
import FormInput from "../../../../../../components/inputs/FormInput";
import LencoButton from "../../../../../../components/button/button";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import MoneyInput from "../../../../../../components/inputs/money-input";
import { PurchaseType } from "../../../../../../models/terminal.constants";
import RadioButton from "../../../../../../components/radio-button";
import { RequestTerminalRequest } from "../../../Services/terminalsApi.types";
import { RequestTerminalStage } from "../../../Types";
import SingleSelectDropdown from "../../../../settings-new/Components/DropDowns/TeamMembers/SingleSelectDropdown";
import UserAccount from "../../../../../../models/userAccount";
import UserAccountSelect from "../../../../../../components/user-account-select";
import { commaSeparator } from "../../../../../../utils/formatNumber";
import doesUserHaveAccessToAccount from "../../../../../../helpers/doesUserHaveAccessToAccount";
import { getFormInit } from "../../../Hooks/get-form-init";
import { getUserAccounts } from "../../../../../../hooks/app-hooks/get-user-accounts";
import { useAppSelector } from "../../../../../../redux/hooks";
import useRequestTerminal from "../../../Hooks/State/useRequestTerminal";

interface Props {
    active: boolean;
    toggle: () => void;
}

function removeNonNumericChars(string: string | number): number {
    return Number(String(string).replace(new RegExp(`[^0-9.]`, "gi"), ""));
}

export default function RequestTerminalModal(props: Props) {
    const { accounts } = getUserAccounts();
    const { terminalRequestFormFormInit, terminalRequestValidation } = getFormInit();

    const operatingAddress = useAppSelector((state) => state.init.main?.companyDetails.company.operatingAddress);
    const leasePurchaseCost = useAppSelector((state) => state.terminals.initMeta?.cautionFee || 0);
    const outrightPurchaseCost = useAppSelector((state) => state.terminals.initMeta?.purchaseCost || 0);
    const currency = useAppSelector((state) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);

    const [requestTerminalStage, setRequestTerminalStage] = useState<RequestTerminalStage>(RequestTerminalStage.STAGE_ONE);
    const [costForTerminal, setCostForTerminal] = useState<number>(outrightPurchaseCost);
    const [insufficientFund, setInsufficientFund] = useState<boolean>(false);
    const [selectedCautionFeeAccount, setSelectedCautionFeeAccount] = useState<UserAccount | null>(null);
    const [isDeliveryAddressOperatingAddress, setIsDeliveryAddressOperatingAddress] = useState<boolean>(false);

    const { isRequestTerminalLoading, handleRequestTerminal } = useRequestTerminal();

    const formikRef = useRef<FormikProps<RequestTerminalRequest> | null>(null);

    const handleGoToStageTwo = () => {
        setRequestTerminalStage(RequestTerminalStage.STAGE_TWO);
    };

    const onTerminalRequestSubmit = useCallback(async (values: RequestTerminalRequest) => {
        await handleRequestTerminal(values);
        setRequestTerminalStage(RequestTerminalStage.STAGE_THREE);
    }, []);

    const handleGoToStageOne = useCallback(() => {
        setRequestTerminalStage(RequestTerminalStage.STAGE_ONE);
        setSelectedCautionFeeAccount(null);
        void formikRef.current?.getFieldHelpers("purchaseType").setValue(PurchaseType.OUTRIGHT_PURCHASE);
        void formikRef.current?.getFieldHelpers("numUnits").setValue(0);
        void formikRef.current?.getFieldHelpers("debitAccountId").setValue("");
    }, []);

    const handleChangeNumberOfTerminals = useCallback(
        (_number: string) => {
            const pureNumber = removeNonNumericChars(_number);
            void formikRef.current?.getFieldHelpers("numUnits").setValue(pureNumber?.toString());
            const cost = formikRef.current?.values.purchaseType === PurchaseType.OUTRIGHT_PURCHASE ? outrightPurchaseCost : leasePurchaseCost;
            setCostForTerminal(pureNumber * cost);
        },
        [outrightPurchaseCost, leasePurchaseCost, formikRef.current?.values?.purchaseType]
    );

    const handleUpdateCostOfTerminals = useCallback(
        (_number: string, _purchase: PurchaseType) => {
            const pureNumber = removeNonNumericChars(_number);
            void formikRef.current?.getFieldHelpers("numUnits").setValue(pureNumber?.toString());
            const cost = _purchase === PurchaseType.OUTRIGHT_PURCHASE ? outrightPurchaseCost : leasePurchaseCost;
            setCostForTerminal(pureNumber * cost);
        },
        [outrightPurchaseCost, leasePurchaseCost]
    );

    const handleReset = () => {
        formikRef.current?.resetForm();
        props.toggle();
        setRequestTerminalStage(RequestTerminalStage.STAGE_ONE);
        setCostForTerminal(outrightPurchaseCost);
        setInsufficientFund(false);
        setSelectedCautionFeeAccount(null);
        setIsDeliveryAddressOperatingAddress(false);
    };

    useEffect(() => {
        if (!props.active) {
            setRequestTerminalStage(RequestTerminalStage.STAGE_ONE);
            setIsDeliveryAddressOperatingAddress(false);
            setSelectedCautionFeeAccount(null);
            setCostForTerminal(outrightPurchaseCost);
        }
    }, [props.active, outrightPurchaseCost]);

    useEffect(() => {
        if (!accounts || !formikRef.current) return;
        if (accounts.length < 2) {
            if (doesUserHaveAccessToAccount(accounts[0].id)) {
                void formikRef.current?.getFieldHelpers("debitAccountId").setValue(accounts[0].id);
                setSelectedCautionFeeAccount(accounts[0]);
            }
        }
    }, [accounts, formikRef.current]);

    useEffect(() => {
        if (!selectedCautionFeeAccount || costForTerminal < 1) return;
        setInsufficientFund((selectedCautionFeeAccount.balance || 0) < costForTerminal);
    }, [costForTerminal, selectedCautionFeeAccount]);

    useEffect(() => {
        if (!props.active) {
            formikRef?.current?.resetForm();
            setRequestTerminalStage(RequestTerminalStage.STAGE_ONE);
            setCostForTerminal(outrightPurchaseCost);
            setInsufficientFund(false);
            setSelectedCautionFeeAccount(null);
            setIsDeliveryAddressOperatingAddress(false);
        }
        return () => {
            handleReset();
        };
    }, []);

    return (
        <Modal size="md" active={props.active} toggler={handleReset}>
            <ModalHeader
                onClose={handleReset}

                // headingType={requestTerminalStage === RequestTerminalStage.STAGE_THREE ? ToastType.SUCCESS_TOAST : undefined}
            >
                {requestTerminalStage !== RequestTerminalStage.STAGE_THREE ? (
                    <div className="flex flex-col space-y-2">
                        <span className="text-base font-medium text-black-secondary">Request for Terminal</span>
                        <span className="flex space-x-2">
                            <span className="h-0 w-8 rounded-full border-2 border-blue"></span>
                            <span
                                className={
                                    "h-0 w-8 rounded-full border-2" +
                                    ` ${requestTerminalStage === RequestTerminalStage.STAGE_ONE ? "border-blue-senary" : "border-blue"}`
                                }
                            ></span>
                        </span>
                    </div>
                ) : null}
            </ModalHeader>
            <Formik
                initialValues={terminalRequestFormFormInit}
                innerRef={formikRef}
                validationSchema={terminalRequestValidation}
                onSubmit={onTerminalRequestSubmit}
                enableReinitialize={true}
                validateOnChange
                validateOnMount
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <ModalBody>
                                <div className="w-full">
                                    {requestTerminalStage === RequestTerminalStage.STAGE_ONE && (
                                        <div className="flex w-full flex-col space-y-4">
                                            <SingleSelectDropdown
                                                placeholder="Estimated Daily Transaction Volume"
                                                options={TransactionVolumeOptions}
                                                value={formikRef.current?.values.estimatedDailyTransactionVolume || ""}
                                                onChange={(_value) => {
                                                    if (_value) {
                                                        void formik.getFieldHelpers("estimatedDailyTransactionVolume").setValue(_value);
                                                    }
                                                }}
                                                size="lg"
                                                active={props.active}
                                                fitHeight
                                            />
                                            <SingleSelectDropdown
                                                placeholder="Estimated Daily Transaction Count"
                                                options={TransactionCountOptions}
                                                value={formikRef.current?.values.estimatedDailyTransactionCount || ""}
                                                onChange={(_value) => {
                                                    if (_value) {
                                                        void formik.getFieldHelpers("estimatedDailyTransactionCount").setValue(_value);
                                                    }
                                                }}
                                                size="lg"
                                                active={props.active}
                                                fitHeight
                                            />
                                            <Checkbox
                                                text="Deliver to my operating address"
                                                id="deliver-address"
                                                checked={isDeliveryAddressOperatingAddress}
                                                func={() => {
                                                    setIsDeliveryAddressOperatingAddress((prev) => {
                                                        void formik.setFieldValue("deliveryAddress", operatingAddress);
                                                        return !prev;
                                                    });
                                                    // void formik.getFieldHelpers("deliveryAddress").setValue(operatingAddress);
                                                }}
                                                size="sm"
                                            />
                                            <FormInput
                                                type="textarea"
                                                name="deliveryAddress"
                                                placeholder="Delivery Address"
                                                isDisabled={isDeliveryAddressOperatingAddress}
                                                onChange={(e) => {
                                                    !isDeliveryAddressOperatingAddress &&
                                                        void formik.getFieldHelpers("deliveryAddress").setValue(e.target.value);
                                                }}
                                                value={
                                                    isDeliveryAddressOperatingAddress ? operatingAddress : formikRef.current?.values.deliveryAddress
                                                }
                                            />
                                        </div>
                                    )}
                                    {requestTerminalStage === RequestTerminalStage.STAGE_TWO && (
                                        <div className="w-full">
                                            <h2 className="mb-2 text-black-secondary">Select purchase type</h2>
                                            <div className="flex w-full flex-col space-y-4">
                                                <div className="grid grid-cols-2 gap-3">
                                                    <div
                                                        className={
                                                            `flex items-center justify-start pl-3 ` +
                                                            `h-12 cursor-pointer rounded-lg !border` +
                                                            `${
                                                                formik.values.purchaseType === PurchaseType.OUTRIGHT_PURCHASE
                                                                    ? " border-blue-secondary bg-blue-backdrop"
                                                                    : " border-black-quat"
                                                            } `
                                                        }
                                                        onClick={() => {
                                                            void formik.setFieldValue("purchaseType", PurchaseType.OUTRIGHT_PURCHASE);
                                                            if (Number(formik?.values?.numUnits) > 0) {
                                                                handleUpdateCostOfTerminals(
                                                                    String(formik.values.numUnits),
                                                                    PurchaseType.OUTRIGHT_PURCHASE
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <RadioButton
                                                            id="outright-purchase"
                                                            text={
                                                                <span
                                                                    className={`text-sm ${
                                                                        formik?.values?.purchaseType === PurchaseType.OUTRIGHT_PURCHASE
                                                                            ? "text-blue-secondary"
                                                                            : "text-black-secondary"
                                                                    }`}
                                                                >
                                                                    Outright Purchase
                                                                </span>
                                                            }
                                                            checked={formik.values.purchaseType === PurchaseType.OUTRIGHT_PURCHASE}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            `flex items-center justify-start pl-3 ` +
                                                            ` h-12 cursor-pointer rounded-lg border` +
                                                            `${
                                                                formik.values.purchaseType === PurchaseType.LEASE
                                                                    ? " border-blue-secondary bg-blue-backdrop"
                                                                    : " border-black-quat"
                                                            } `
                                                        }
                                                        onClick={() => {
                                                            void formik.setFieldValue("purchaseType", PurchaseType.LEASE);
                                                            if (Number(formik.values.numUnits) > 0) {
                                                                handleUpdateCostOfTerminals(String(formik.values.numUnits), PurchaseType.LEASE);
                                                            }
                                                        }}
                                                    >
                                                        <RadioButton
                                                            id="lease-pos"
                                                            text={
                                                                <span
                                                                    className={`text-sm ${
                                                                        formik.values.purchaseType === PurchaseType.LEASE
                                                                            ? "text-blue-secondary"
                                                                            : "text-black-secondary"
                                                                    }`}
                                                                >
                                                                    Lease POS
                                                                </span>
                                                            }
                                                            checked={formik.values.purchaseType === PurchaseType.LEASE}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <FormInput
                                                    name="numUnits"
                                                    placeholder="Number of Terminals"
                                                    onChange={(e) => handleChangeNumberOfTerminals(e.target.value)}
                                                    value={formik.values.numUnits}
                                                    type="text"
                                                    inputSize="lg"
                                                />
                                                {Number(formik.values.numUnits) > 0 && (
                                                    <MoneyInput
                                                        label={
                                                            formik?.values?.purchaseType === PurchaseType.OUTRIGHT_PURCHASE
                                                                ? "Cost for Terminals"
                                                                : "Caution Fee"
                                                        }
                                                        icon={<CurrencyCode currency={currency} />}
                                                        value={String(costForTerminal)}
                                                        isDisabled
                                                        readOnly
                                                        isActive
                                                        inputSize="lg"
                                                    />
                                                )}
                                                <div className="flex w-full flex-col space-y-2">
                                                    <UserAccountSelect
                                                        label="Pay from"
                                                        size="lg"
                                                        value={formik?.values?.debitAccountId || ""}
                                                        options={accounts || null}
                                                        onClick={(_accountId) => {
                                                            const doesAccountExist = accounts?.some((el: UserAccount) => el?.id === _accountId);
                                                            if (doesAccountExist) {
                                                                const userAccount = accounts?.find(
                                                                    (el: UserAccount) => el.id === _accountId
                                                                ) as UserAccount;
                                                                setSelectedCautionFeeAccount(userAccount);
                                                                void formik.getFieldHelpers("debitAccountId").setValue(userAccount.id);
                                                            }
                                                        }}
                                                        isDisabled={!!(accounts && accounts.length < 2)}
                                                        showBalance
                                                        approverCanSelect
                                                    />
                                                    <p className="m-0 text-xs font-normal text-black-tertiary">
                                                        Select an account to withdraw the caution fee
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {requestTerminalStage === RequestTerminalStage.STAGE_THREE && (
                                        <div className="-mt-6 flex w-full flex-col justify-start space-y-3">
                                            <BlueSuccessCheck className="mx-auto w-60 lg:w-80" />
                                            <h3 className="text-center text-2xl font-medium text-black-secondary">Terminal Requested</h3>
                                            {!insufficientFund ? (
                                                <div className="flex w-full flex-col space-y-4">
                                                    <p className="w-full text-center text-sm text-black-secondary">
                                                        Your request has been received and is being processed. You will receive a decision on your
                                                        terminal request within 48 hours.
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="flex w-full flex-col space-y-4">
                                                    <p className="w-full text-center text-sm text-black-secondary">
                                                        Your request has been received. Please fund your account with a sum of{"\n"}
                                                        <span className="font-medium">
                                                            <CurrencyCode currency={currency} />
                                                            {commaSeparator(costForTerminal)}
                                                        </span>
                                                    </p>
                                                </div>
                                            )}
                                            {/* <div className="mt-2">
                                                <AddFundsCard bg="grey" userAccount={selectedCautionFeeAccount} />
                                            </div> */}
                                        </div>
                                    )}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <div className="mt-4 flex w-full max-w-sm flex-row items-center justify-end space-x-4">
                                    {requestTerminalStage === RequestTerminalStage.STAGE_ONE && (
                                        <>
                                            <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                                <LencoButton onClick={handleReset} type="button" color="primary" size="sm" variant="bordered" isText>
                                                    <span className="normal-case tracking-wider">Cancel</span>
                                                </LencoButton>
                                            </div>
                                            <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                <LencoButton
                                                    type="button"
                                                    color="primary"
                                                    size="sm"
                                                    variant="solid"
                                                    isText
                                                    onClick={handleGoToStageTwo}
                                                    isDisabled={
                                                        !!(
                                                            formik.values.estimatedDailyTransactionCount.length < 1 ||
                                                            formik.values.estimatedDailyTransactionVolume.length < 1 ||
                                                            formik.errors.estimatedDailyTransactionCount ||
                                                            formik.errors.estimatedDailyTransactionVolume ||
                                                            formik.errors.estimatedDailyTransactionCount ||
                                                            formik.errors.estimatedDailyTransactionVolume ||
                                                            formik.errors.deliveryAddress
                                                        )
                                                    }
                                                >
                                                    <span className="normal-case tracking-wider">Proceed</span>
                                                </LencoButton>
                                            </div>
                                        </>
                                    )}
                                    {requestTerminalStage === RequestTerminalStage.STAGE_TWO && (
                                        <>
                                            <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                                <LencoButton
                                                    onClick={handleGoToStageOne}
                                                    type="button"
                                                    color="primary"
                                                    size="sm"
                                                    variant="bordered"
                                                    isText
                                                >
                                                    <span className="normal-case tracking-wider">Back</span>
                                                </LencoButton>
                                            </div>
                                            <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                <LencoButton
                                                    type="submit"
                                                    color="primary"
                                                    size="sm"
                                                    variant="solid"
                                                    isLoading={isRequestTerminalLoading}
                                                    onClick={handleGoToStageTwo}
                                                    isDisabled={
                                                        !!(
                                                            formik.errors.purchaseType ||
                                                            formik.values.purchaseType < 1 ||
                                                            !selectedCautionFeeAccount ||
                                                            formik.errors.numUnits ||
                                                            Number(formik.values.numUnits) < 1
                                                        )
                                                    }
                                                >
                                                    <span className="normal-case tracking-wider">Proceed</span>
                                                </LencoButton>
                                            </div>
                                        </>
                                    )}
                                    {requestTerminalStage === RequestTerminalStage.STAGE_THREE && (
                                        <LencoButton type="submit" color="primary" size="sm" variant="solid" onClick={handleReset}>
                                            <span className="normal-case tracking-wider">Done</span>
                                        </LencoButton>
                                    )}
                                </div>
                            </ModalFooter>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}
