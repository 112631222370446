import { useCallback, useState } from "react";

import { AddSignatoryRequest } from "../../../../../services/zambia-application/registered/new/accountSignatories.types";
import { addAccountSignatory } from "../../../../../services/zambia-application/registered/new/accountSignatories.api";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { setZambiaAccountSignatories } from "../../../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface UseAddSignatoriesInterface {
    isAddSignatoryLoading: boolean;
    handleAddSignatory: (data: AddSignatoryRequest) => Promise<void>;
}

const useAddSignatories = (onComplete: () => void): UseAddSignatoriesInterface => {
    const dispatch = useDispatch();

    const [isAddSignatoryLoading, setIsAddSignatoryLoading] = useState<boolean>(false);

    const handleAddSignatory = useCallback(async (_data: AddSignatoryRequest) => {
        try {
            setIsAddSignatoryLoading(true);
            const res = await addAccountSignatory(_data);
            dispatch(setZambiaAccountSignatories(res));
            onComplete();
            dispatch(messageTrue({ message: `Signator${_data.signatories.length > 1 ? "ies" : "y"} invited successfully` }));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsAddSignatoryLoading(false);
        }
    }, []);

    return {
        isAddSignatoryLoading,
        handleAddSignatory,
    };
};
export default useAddSignatories;
