import { useCallback, useState } from "react";

import { RemoveZambiaApiAccessKeyWhitelistIPAddressRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { removeZambiaApiAccessKeyWhitelistIPAddress } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch } from "react-redux";

function useRemoveZambiaApiAccessKeyWhitelistIPAddress() {
    const dispatch = useDispatch();
    const [isRemoveZambiaApiAccessKeyWhitelistIPAddressLoading, setIsRemoveZambiaApiAccessKeyWhitelistIPAddressLoading] = useState(false);
    const [removeZambiaApiAccessKeyWhitelistIPAddressErrorMessage, setRemoveZambiaApiAccessKeyWhitelistIPAddressErrorMessage] = useState<string>("");

    const handleRemoveZambiaApiAccessKeyWhitelistIPAddress = useCallback(
        async (_data: RemoveZambiaApiAccessKeyWhitelistIPAddressRequest) => {
            try {
                setIsRemoveZambiaApiAccessKeyWhitelistIPAddressLoading(true);
                setRemoveZambiaApiAccessKeyWhitelistIPAddressErrorMessage("");
                const res = await removeZambiaApiAccessKeyWhitelistIPAddress(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key whitelist IP removed successfully."));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRemoveZambiaApiAccessKeyWhitelistIPAddressErrorMessage(errorMessage);
            } finally {
                setIsRemoveZambiaApiAccessKeyWhitelistIPAddressLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isRemoveZambiaApiAccessKeyWhitelistIPAddressLoading,
        removeZambiaApiAccessKeyWhitelistIPAddressErrorMessage,
        handleRemoveZambiaApiAccessKeyWhitelistIPAddress,
    };
}

export default useRemoveZambiaApiAccessKeyWhitelistIPAddress;
