import { IncorporationDocumentType, shareholdersOptions } from "../../../../../../services/zambia-application/registered/new/document.types";
import { useEffect, useRef, useState } from "react";

import ApplicationDeleteModal from "../../../../../modals/deleteModal";
import ButtonComp from "../../../../../../../../../components/button/ButtonComp";
import { FileDrop } from "react-file-drop";
import GoogleIcon from "../../../../../../../../../components/google-icon";
import IncorporationDocumentZambia from "../../../../../../../../../models/account-opening/incorporation-documents/incorporationDocumentZambia";
import LencoSpinner from "../../../../../../../../../components/spinner";
import PreviewModal from "../../../../../modals/preview-modal";
import RadioButton from "../../../../../../../../../components/radio-button";
import usePreview from "../../../../../../Hooks/zambia-application/registered-business/account-signatories/new/usePreview";
import useUploadBusinessDocuments from "../../../../../../Hooks/zambia-application/registered-business/business-documents/useUploadBusinessDocuments";

interface ShareHoldersAccordionProps {
    title: string;
    active: boolean;
    description: string;
    isExternalLink?: boolean;
    uploadedDocument: IncorporationDocumentZambia | null;

    toggler: () => void;
}

function ShareHoldersAccordion(props: ShareHoldersAccordionProps): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreview({
        onComplete: () => setShowPreviewModal(true),
        downloadedDocumentName: "Shareholder's Information",
    });

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const {
        deleteIncorporationDocumentError,
        isUploadBusinessDocumentsLoading,
        isDeleteIncorporationDocumentLoading,
        handleReset,
        handleUploadBusinessDocuments,
        handleDeleteIncorporationDocument,
    } = useUploadBusinessDocuments({
        onComplete: () => {
            setIsDeleteModalOpen(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        },
        onError: () => {
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        },
    });

    const [type, setType] = useState<IncorporationDocumentType | null>(null);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);

    useEffect(() => {
        setType(props.uploadedDocument?.type || null);
        setIsUploaded(!!props.uploadedDocument?.uploadId);
    }, [props.active, props.uploadedDocument]);

    return (
        <div>
            <PreviewModal active={showPreviewModal} toggler={() => setShowPreviewModal(false)} data={previewBlob} title={`${props.title} Preview`} />
            <ApplicationDeleteModal
                header="Delete Document"
                onSubmit={() => void handleDeleteIncorporationDocument({ type: type || IncorporationDocumentType.INCORPORATION_CERTIFICATE })}
                error={deleteIncorporationDocumentError || ""}
                deleteButtonText="Delete"
                active={isDeleteModalOpen}
                isSubmitLoading={isDeleteIncorporationDocumentLoading}
                toggler={() => (setIsDeleteModalOpen(false), handleReset())}
                body={
                    <>
                        <span className="w-full whitespace-normal break-words text-left text-base font-normal">
                            This action cannot be undone. Are you sure you want to delete this document?
                        </span>
                    </>
                }
            />
            <div className="flex w-full flex-col space-y-6 rounded-md border border-grey px-5 py-6">
                <div className="flex flex-row justify-start border-b border-grey-secondary pb-6">
                    <div className="flex h-full w-full max-w-[20px] items-center justify-center overflow-hidden">
                        {isUploaded && <span className="material-symbols-rounded text-xl text-success">check</span>}
                        {!isUploaded && <span className="material-symbols-rounded text-xl">add_notes</span>}
                    </div>
                    <div className="ml-3 flex flex-col items-start justify-start">
                        <h3 className="font-medium text-black-secondary">{props.title}</h3>
                        {props.description && <div className="text-sm font-normal text-black-tertiary">{props.description}</div>}
                    </div>
                </div>
                <div className="flex w-full flex-col space-y-4 xs:flex-row xs:space-x-4 xs:space-y-0">
                    <div className="flex flex-col items-start justify-start space-y-4 2xs:min-w-max">
                        {shareholdersOptions.map((el) => (
                            <RadioButton
                                id={String(el.id)}
                                checked={type === el.id}
                                text={el.name}
                                key={el.id}
                                func={() => {
                                    if (fileInputRef?.current) {
                                        fileInputRef.current.value = "";
                                    }
                                    handleReset();
                                    type === el.id ? setType(null) : setType(el.id);
                                }}
                                size="sm"
                            />
                        ))}
                    </div>
                    <div className="flex w-full flex-grow items-end justify-end overflow-x-auto">
                        <div className="flex w-full items-center justify-center 4xs:items-end 4xs:justify-end 4xs:self-end">
                            {!isUploaded ? (
                                <>
                                    <div
                                        className="flex h-24 w-full min-w-15 max-w-15 cursor-pointer items-center justify-center rounded-lg border border-dashed border-grey bg-blue-senary"
                                        onClick={() => {
                                            fileInputRef.current && fileInputRef.current.value === "";
                                            fileInputRef.current && fileInputRef.current.click();
                                        }}
                                    >
                                        <FileDrop
                                            onDrop={(files: FileList | null) => {
                                                if (files && files[0].size > 5242880) {
                                                    return setIsFileSizeExceeded(true);
                                                }
                                                setIsFileSizeExceeded(false);

                                                files && files[0] && type && void handleUploadBusinessDocuments({ file: files[0], type: type });
                                            }}
                                        >
                                            <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
                                                {isUploadBusinessDocumentsLoading ? (
                                                    <LencoSpinner color="blue" size="sm" />
                                                ) : (
                                                    <GoogleIcon icon="upload" className="text-blue" isOutlined />
                                                )}
                                                <p className="text-center text-xs font-medium text-blue">
                                                    Click or Drag and Drop
                                                    {/* Click to upload
                                            <br />
                                            or
                                            <br />
                                            Drag and Drop */}
                                                </p>
                                                {isFileSizeExceeded && <span className="text-xs text-error">*File exceeded 5MB</span>}
                                            </div>
                                        </FileDrop>
                                    </div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        onChange={(event) => {
                                            if (event.target.files && event.target.files[0].size > 5242880) {
                                                return setIsFileSizeExceeded(true);
                                            }
                                            setIsFileSizeExceeded(false);
                                            const files = event.target.files;
                                            if (files && files[0] && type) {
                                                void handleUploadBusinessDocuments({ file: files[0], type: type });
                                            }
                                        }}
                                        accept="image/jpeg, image/png, application/pdf, .pdf"
                                        multiple
                                    />
                                </>
                            ) : (
                                <div className="flex flex-col items-center justify-center space-y-4 self-center 4xs:flex-row 4xs:justify-start 4xs:space-x-4 4xs:space-y-0">
                                    <ButtonComp
                                        color="blue"
                                        buttonType="flat"
                                        size="sm"
                                        isLoading={isUploadBusinessDocumentsLoading}
                                        func={() => {
                                            if (fileInputRef.current) {
                                                fileInputRef.current.value = "";
                                            }
                                            setIsUploaded(false);
                                        }}
                                    >
                                        <span>Re-upload</span>
                                        <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden">
                                            <span className="material-symbols-outlined text-xl">restart_alt</span>
                                        </div>
                                    </ButtonComp>
                                    <div className="hidden w-[1px] items-center justify-center 4xs:flex">
                                        <div className="h-6 w-full bg-black-quin"></div>
                                    </div>
                                    <ButtonComp
                                        color="blue"
                                        buttonType="flat"
                                        size="sm"
                                        func={() => void handlePreviewDocument(props.uploadedDocument?.uploadId || "")}
                                    >
                                        <div className="flex w-full items-center justify-center">
                                            <p className="text-sm font-medium">Preview</p>
                                            <div className="ml-2 flex max-w-[20px] items-center justify-center overflow-hidden text-xl">
                                                <span className="material-symbols-outlined icon-fill">visibility</span>
                                            </div>
                                        </div>
                                    </ButtonComp>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShareHoldersAccordion;
