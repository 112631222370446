import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureAccessLevelsArrayType, FeatureAccessLevelsType, TeamMembersSettingsState } from "./teamMembersSettingsSlice.types";
import { TeamMemberItem } from "../../../../modules/dashboard/settings-new/Services/team-members/team-members.types";

const initialState: TeamMembersSettingsState = {
    teamMemberTab: 0,
    accessLevel: 0,
    featureAccessSelected: { featureNumber: 0, level: 0 },
    selectedMember: null,
    featureAccessLevels: [
        {
            id: 0,
            name: "",
            access: 0,
        },
    ],
    featureAccessLevelsArray: [
        {
            memberId: "",
            pos: 2,
            cards: 2,
        },
    ],
};

export const teamMembersSettingsSlice = createSlice({
    name: "teamMembersSettings",
    initialState,
    reducers: {
        setTeamMemberTab: (state: TeamMembersSettingsState, action: PayloadAction<number>) => {
            state.teamMemberTab = action.payload;
        },
        setFeatureAccesslevel: (state: TeamMembersSettingsState, action: PayloadAction<number>) => {
            state.accessLevel = action.payload;
        },
        updateFeatureAccess: (state: TeamMembersSettingsState, action: PayloadAction<{ featureNumber: number; level: number }>) => {
            state.featureAccessSelected = action.payload;
        },
        setSelectedMember: (state: TeamMembersSettingsState, action: PayloadAction<TeamMemberItem | null>) => {
            state.selectedMember = action.payload;
        },
        setFeatureAccessLevels: (state: TeamMembersSettingsState, action: PayloadAction<FeatureAccessLevelsType[]>) => {
            state.featureAccessLevels = action.payload;
        },
        updateFeatureAccessLevelsArray: (state: TeamMembersSettingsState, action: PayloadAction<FeatureAccessLevelsArrayType[]>) => {
            state.featureAccessLevelsArray = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setTeamMemberTab,
    setFeatureAccesslevel,
    updateFeatureAccess,
    setSelectedMember,
    setFeatureAccessLevels,
    updateFeatureAccessLevelsArray,
} = teamMembersSettingsSlice.actions;

export default teamMembersSettingsSlice.reducer;
