import SkeletonElement from "../../../../../../components/skeleton";

function MemberCardHeaderSkeleton(): JSX.Element {
    return (
        <>
            <div className="flex h-40 w-full flex-col items-start justify-start space-y-2 pt-9 sm:flex-row sm:space-x-10 sm:space-y-0 sm:pt-0 xl:space-x-16">
                <div className="flex w-full flex-row items-start justify-start space-x-4 rounded-md sm:items-center">
                    <div className="h-14 w-14">
                        <SkeletonElement avatar />
                    </div>
                    <div className="flex w-32 flex-col items-start justify-start space-x-4">
                        <SkeletonElement text />
                        <div className="mt-2 w-12">
                            <SkeletonElement text />
                        </div>
                    </div>
                </div>

                <div className="item-center mr-6 flex h-full w-40 flex-col justify-between pt-2 sm:ml-0 sm:pl-0">
                    <SkeletonElement text />
                    <SkeletonElement text />
                </div>

                <div className="flex w-full items-center justify-end pt-2">
                    <SkeletonElement title />
                </div>
            </div>
        </>
    );
}

export default MemberCardHeaderSkeleton;
