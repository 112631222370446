import Money from "../../../../../components/money";
import { Num } from "../../../../../utils/math";
// import { ReactComponent as History } from "../../../../../assets/svg/DashboardLayout/Sections/transactionsIcon1.svg";
import { PaymentsType } from "../../../payments-new/hooks/state/send-money/payments.constants";
import TooltipIcons from "../../../../../components/tooltip-icons";
// import { ReactComponent as SingleTransferIcon } from "../../../../../assets/svg/DashboardLayout/SendMoneyDropdown/SingleTransferIcon.svg";
// import TooltipIcons from "../../../../../components/general/TooltipIcons/TooltipIcons";
import UserAccount from "../../../../../models/userAccount";
import titleCase from "../../../../../hooks/titleCase";
import { useNavigate } from "react-router-dom";

interface Props {
    accountData: UserAccount;
    showAmount: boolean;
    arrayLength: number;
    index: number;
}

function AccountCardDetail({ accountData, showAmount, arrayLength, index }: Props): JSX.Element {
    const navigate = useNavigate();

    return (
        <>
            <div
                className={`${index === arrayLength - 1 ? "" : "border-b border-grey-secondary"} flex w-full flex-row items-center justify-between py-4`}
            >
                <div className="flex w-70% flex-row justify-between">
                    <div className="flex flex-col items-center justify-center text-start">
                        <div className="flex flex-row items-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-base text-black-tertiary">
                                {titleCase(accountData?.lencoNameMin || "")}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col text-center">
                        <div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-medium text-black-secondary">
                            {showAmount ? (
                                <>
                                    {/* <CurrencyCode />
                                    {commaSeparator(accountData?.balance || 0)} */}
                                    <Money
                                        amount={(accountData?.balance || (0 as unknown as Num)).valueOf()}
                                        currency={accountData.bankAccountCurrency && accountData.bankAccountCurrency}
                                    />
                                </>
                            ) : (
                                <div className="flex flex-row items-center justify-center space-x-1.5 pt-2">
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="relative">
                    <div className="flex w-30% flex-row items-start space-x-6">
                        <div className="">
                            <TooltipIcons
                                onClick={() =>
                                    navigate(
                                        {
                                            pathname: "/payments/make/single",
                                            search: `?from=${accountData.id}`,
                                        },
                                        {
                                            state: {
                                                typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                                                from: accountData.id,
                                            },
                                        }
                                    )
                                }
                                icon={
                                    <div className="rounded bg-grey-tertiary px-2 py-1">
                                        <span className="material-symbols-outlined text-base">send</span>
                                    </div>
                                    // <SingleTransferIcon className="stroke-current text-blue" />
                                }
                                placement="left"
                                message="Send Money"
                            />
                        </div>
                        <div>
                            <TooltipIcons
                                onClick={() => navigate(`/transactions/account/${accountData.id}`)}
                                icon={
                                    <div className="rounded bg-grey-tertiary px-2 py-1">
                                        <span className="material-symbols-outlined text-base">list_alt</span>
                                    </div>
                                    // <History className="stroke-current text-blue" />
                                }
                                placement="left"
                                message="Transactions"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountCardDetail;
