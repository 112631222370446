export enum TerminalType {
    DIGITAL = 1,
}

export enum TerminalStatus {
    ACTIVE = 1,
    REVOKED = 2,
}

export enum ModeType {
    LENCO = 1,
    FINCRA = 2,
}

export enum RequestStatus {
    REQUESTED = 1,
    APPROVED = 2,
    DISPATCHED = 3,
    ARRIVED = 4,
}

export enum TerminalMedium {
    BANK_TRANSFER = 1,
    USSD = 2,
    CARD_PAYMENT = 3,
    CASH_DEPOSIT = 4,
    CHEQUE = 5,
}

export enum ChargeType {
    VAT = 1,
    NIPSS_CHARGE = 2,
    NIP_AND_VAT = 3,
    STAMP_DUTY = 4,
    SMS_CHARGE = 5,
    ACCOUNT_MAINTENANCE = 6,
    API_FEE = 7,
}

// to change
export enum SettlementType {
    ONE = 1,
    TWO = 2,
}
export enum MerchantSettlementType {
    MANUAL = 1,
    INSTANT = 2,
    NEXT_DAY = 3,
}
export enum BasicStatusWithNone {
    NONE = 0,
    PENDING = 1,
    SUCCESSFUL = 2,
    FAILED = 3,
}

export enum PurchaseType {
    OUTRIGHT_PURCHASE = 1,
    LEASE = 2,
}
export enum TerminalRequestStatus {
    REQUESTED = 1,
    APPROVED = 2,
    DECLINED = 3,
    TERMINAL_ASSIGNED = 4,
    SHIPPED = 5,
    DELIVERED = 6,
}
