import { AgreementType } from "../../../../../../services/nigeria-application/registered/new/agreement.types";
import React from "react";
import SignatureCard from "../../../../../cards/signatureCard";
import useAgreementComponent from "../../../../../../Hooks/nigeria-application/registered-business/agreement/useAgreement";

interface Props {
    type: AgreementType;
    isCreator: boolean;
    canAppendSignature: boolean;
    onComplete: () => void;
}

function AgreementSignatureComponent(props: Props): JSX.Element {
    const {
        isSignBusinessAgreementDone,

        // isPreviewDownloading,
        // isDownloadAgreementToSignLoading,
        isSignBusinessAgreementLoading,
        isUploadBusinessAgreementLoading,
        isAppendBusinessAgreementSignatureLoading,

        handlePreviewDocument,
        handleSignBusinessAgreement,
        handleDownloadAgreementToSign,
        handleUploadSignedAgreementDocument,
        handleAppendBusinessAgreementSignature,
    } = useAgreementComponent(props.type, props.onComplete);

    return (
        <>
            <SignatureCard
                isCreator={props.isCreator}
                canAppendSignature={props.canAppendSignature}
                signDocumentResponse={isSignBusinessAgreementDone}
                isSignDocumentLoading={isSignBusinessAgreementLoading}
                isUploadSignedDocumentLoading={isUploadBusinessAgreementLoading}
                isAppendSignatureToDocumentLoading={isAppendBusinessAgreementSignatureLoading}
                handleSignDocument={(_data) => void handleSignBusinessAgreement(_data)}
                handlePreviewDocument={() => void handlePreviewDocument()}
                handleUploadSignedDocument={(_data) => void handleUploadSignedAgreementDocument(_data)}
                handleDownloadDocumentToSign={() => void handleDownloadAgreementToSign()}
                handleAppendSignatureToDocument={() => void handleAppendBusinessAgreementSignature()}
                isPreviewBeforeSign
            />
        </>
    );
}

export default AgreementSignatureComponent;
