import { AddMoneyFundingLink, AddMoneyFundingType, AddMoneyFundingTypes } from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import { setAddMoneyFundingType, setAddMoneySelectedId } from "../../../../../../redux/payments/addMoney/slice/addMoneySlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import AddMoneyHeader from "../../Layouts/AddMoney/Components/AddMoneyHeader";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import CustomSelect from "../../../../../../components/dropdown/custom-select";
import { IRootState } from "../../../../../../redux/rootReducer";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import { useNavigate } from "react-router";

interface Props {
    fundingType: AddMoneyFundingType | null;
    selectedAccountId: string;
}

function TypeOfAddMoney(props: Props): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const [accountId, setAccountId] = useState(props.selectedAccountId || "");
    const [fundingType, setFundingType] = useState<AddMoneyFundingType | null>(props.fundingType || null);

    useEffect(() => {
        setAccountId(props.selectedAccountId);
    }, [props.selectedAccountId]);

    useEffect(() => {
        setFundingType(props.fundingType);
    }, [props.fundingType]);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <AddMoneyHeader title="Add Money" subtitle="Select an account to fund and payment method" />

                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Fund Details</p>
                        <NewUserAccountDropdown
                            value={accountId || ""}
                            options={accounts || null}
                            onClick={(_value) => setAccountId(_value)}
                            isDisabled={!!(accounts && accounts.length < 2 && accountId)}
                            dropdownSize="xl"
                            initiatorCanSelect
                        />
                        <CustomSelect
                            label="Payment Method"
                            value={fundingType}
                            options={AddMoneyFundingTypes.map((item) => ({
                                text: item.name,
                                value: item.id,
                                icon: item.icon,
                            }))}
                            onSelect={(_value) => _value && setFundingType(_value)}
                        />
                    </div>
                </div>
                <div className="flex w-full flex-row items-center justify-center">
                    <ButtonComp
                        color="black"
                        buttonType="primary"
                        size="xl"
                        disable={!(accountId && fundingType)}
                        func={() => {
                            if (fundingType && accountId) {
                                dispatch(setAddMoneySelectedId(accountId));
                                dispatch(setAddMoneyFundingType(fundingType));
                                navigate(
                                    { pathname: AddMoneyFundingLink[fundingType].link },
                                    {
                                        state: {
                                            selectedAccountId: accountId,
                                        },
                                    }
                                );
                            }
                        }}
                    >
                        Next
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default TypeOfAddMoney;
