import Parsers from "../utils/parsers";
import { GenericObject } from "../helpers/types";
import { immerable } from "immer";

export default class UserMin {
    [immerable] = true;

    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public intercomHash: string,
        public phones: string[],
        public email: string,
        public hasSignature: boolean
    ) {}

    get name(): string {
        return `${this.firstName} ${this.lastName}`.trim();
    }

    get nameAcronym(): string {
        return `${this.firstName[0]}${this.lastName[0]}`.trim().toUpperCase();
    }

    static create(obj: GenericObject): UserMin {
        return new UserMin(
            Parsers.string(obj.id),
            Parsers.string(obj.firstName),
            Parsers.string(obj.lastName) || Parsers.string(obj.surname),
            Parsers.string(obj.intercomHash),
            Parsers.stringArray(obj.phones as unknown[]),
            Parsers.string(obj.email),
            Parsers.boolean(obj.hasSignature)
        );
    }
}
