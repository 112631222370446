import { makeRequest, makeRequestDownloadFile, makeRequestUploadFile } from "../../../../../helpers/request/makeRequest";
import {
    refereeReferenceFormData,
    refereeReferenceFormDataResponse,
    referenceDownloadDocumentData,
    referenceFormVerifyData,
    referenceFormVerifyDataResponse,
    referenceUploadDocumentData,
    referenceUploadDocumentDataResponse,
    referenceUploadSignatureData,
    referenceUploadSignatureDataResponse,
} from "./referenceFormApi.types";

import { GenericObject } from "../../../../../helpers/types";
import Parsers from "../../../../../utils/parsers";
import { downloadBlob } from "../../../../../utils/blob";
import { toFormData } from "../../../../../utils/converters";

export const verifyReferenceForm = async (data: referenceFormVerifyData): Promise<referenceFormVerifyDataResponse> => {
    const res = await makeRequest("/referee/verify", data);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        company: Parsers.string(res.company),
        creator: Parsers.string(res.creator),
        referee: {
            name: Parsers.string((res.referee as GenericObject).name),
            phone: Parsers.string((res.referee as GenericObject).phone),
        },
    };
};

export const refereeReferenceForm = async (data: refereeReferenceFormData): Promise<refereeReferenceFormDataResponse> => {
    const res = await makeRequest("/account-opening/ng/external/reference-form/data/update", data);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};

export const referenceFormUploadSignature = async (data: referenceUploadSignatureData): Promise<referenceUploadSignatureDataResponse> => {
    const res = await makeRequestUploadFile("/account-opening/ng/external/reference-form/sign", toFormData(data));
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};

export const downloadDocumentToSign = async (data: referenceDownloadDocumentData): Promise<void> => {
    const res = await makeRequestDownloadFile("/account-opening/ng/external/reference-form/download-to-sign", data);
    if (res instanceof Blob) {
        downloadBlob(res, "lenco-reference-form");
        return;
    } else {
        throw res;
    }
};

export const referenceFormUploadDownloadedDocument = async (data: referenceUploadDocumentData): Promise<referenceUploadDocumentDataResponse> => {
    const res = await makeRequestUploadFile("/account-opening/ng/external/reference-form/upload", toFormData(data));
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
    };
};
