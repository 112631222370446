import {
    BusinessDocumentResponse,
    DeleteIncorporationDocumentRequest,
    DeleteProofOfResidenceRequest,
    IncorporationDocumentRequest,
    ProofOfResidenceRequest,
} from "./document.types";
import {
    ErrorMessage,
    makeRequestDownloadFile,
    makeRequestUploadFileWithSignal,
    makeRequestWithSignal,
} from "../../../../../../../helpers/request/makeRequest";
import { abortRequest, getAbortControllerSignal } from "../../../../../../../helpers/request/abortControllers";

import { GenericObject } from "../../../../../../../helpers/types";
import IncorporationDocumentZambia from "../../../../../../../models/account-opening/incorporation-documents/incorporationDocumentZambia";
import NewAccountOpeningProgress from "../../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../../utils/parsers";
import ProofOfResidence from "../../../../../../../models/account-opening/proofOfResidence";
import ZambiaBusinessDetails from "../../../../../../../models/account-opening/zambiaBusinessDetails";
import { toFormData } from "../../../../../../../utils/converters";

export enum BusinessDocumentRequests {
    INIT = "business-document.init",
    UPLOAD_INCORPORATION_DOCUMENT = "business-document.upload-incorporation-document",
    ADD_INCORPORATION_DOCUMENT_FILE = "business-document.add-incorporation-document-file",
    DELETE_INCORPORATION_DOCUMENT = "business-document.delete-incorporation-document",
    UPLOAD_PROOF_OF_RESIDENCE = "business-document.upload-proof-of-residence",
    ADD_PROOF_OF_RESIDENCE_FILE = "business-document.add-proof-of-residence-file",
    DELETE_PROOF_OF_RESIDENCE = "business-document.delete-proof-of-residence",
}

function getBusinessDocumentResponse(res: GenericObject | Error): BusinessDocumentResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        application: Parsers.classObjectNonNullable(res.application, ZambiaBusinessDetails),
        incorporationDocuments: Parsers.classObjectArray(res.incorporationDocuments, IncorporationDocumentZambia),
        proofOfResidence: Parsers.classObject(res.proofOfResidence, ProofOfResidence),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
    };
}

export async function documentDetailsInit(): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/zm/document/details", {}, signal);
    return getBusinessDocumentResponse(res);
}
export async function uploadIncorporationDocument(_data: IncorporationDocumentRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.UPLOAD_INCORPORATION_DOCUMENT);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/document/incorporation-document/upload", toFormData(_data), signal);
    return getBusinessDocumentResponse(res);
}
export async function addIncorporationDocumentFile(_data: IncorporationDocumentRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.ADD_INCORPORATION_DOCUMENT_FILE);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/document/incorporation-document/add-file", toFormData(_data), signal);
    return getBusinessDocumentResponse(res);
}
export async function deleteIncorporationDocument(_data: DeleteIncorporationDocumentRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.DELETE_INCORPORATION_DOCUMENT);
    const res = await makeRequestWithSignal("/account-opening/zm/document/incorporation-document/delete", _data, signal);
    return getBusinessDocumentResponse(res);
}
export async function uploadProofOfResidence(_data: ProofOfResidenceRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.UPLOAD_PROOF_OF_RESIDENCE);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/document/proof-of-residence/upload", toFormData(_data), signal);
    return getBusinessDocumentResponse(res);
}
export async function addProofOfResidenceFile(_data: ProofOfResidenceRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.ADD_PROOF_OF_RESIDENCE_FILE);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/document/proof-of-residence/add-file", toFormData(_data), signal);
    return getBusinessDocumentResponse(res);
}
export async function deleteProofOfResidence(_data: DeleteProofOfResidenceRequest): Promise<BusinessDocumentResponse> {
    const signal = getAbortControllerSignal(BusinessDocumentRequests.DELETE_PROOF_OF_RESIDENCE);
    const res = await makeRequestWithSignal("/account-opening/zm/document/proof-of-residence/delete", _data, signal);
    return getBusinessDocumentResponse(res);
}

export async function downloadDocument(documentId: string): Promise<Blob> {
    const res = await makeRequestDownloadFile("/account-opening/zm/download", { id: documentId });
    if (res instanceof Error) {
        throw new Error(ErrorMessage.GENERIC_ERROR);
    }
    return res;
}

export function abortBusinessDocumentRequest(type: BusinessDocumentRequests): void {
    abortRequest(type);
}

export function abortAllBusinessDocumentRequests(): void {
    Object.values(BusinessDocumentRequests).forEach((type) => abortRequest(type));
}
