import * as Yup from "yup";

import { CardType, CardWalletType } from "../../../../../models/card.constants";
import {
    setIsCardsFiltering,
    setNewCard,
    updateUseExistingBusinessAddress,
    updateUseExistingMobileNumber,
    updateUseHolderInfoToCreateCard,
} from "../../../../../redux/cards/slice/cardsSlice";
import { useCallback, useState } from "react";

import CardRequest from "../../../../../models/cardRequest";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { RequestNewCardRequest } from "../../Services/cardsApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { requestNewCard } from "../../Services/cardsApi";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";

const useRequestNewCard = () => {
    const dispatch = useDispatch();
    const accounts = useAppSelector((state) => state.init.main?.companyDetails.accounts);

    const [requestedNewCard, setRequestedNewCard] = useState<CardRequest | null>(null);
    const [requestNewCardError, setRequestNewCardError] = useState<string | null>(null);
    const [isRequestNewCardLoading, setIsRequestNewCardLoading] = useState<boolean>(false);

    const InitialCardAccessState = {
        // memberIds: [],
        name: "",
        type: CardType.NGN_PHYSICAL,
        purpose: "",
        walletType: CardWalletType.LINKED_TO_ACCOUNT,
        deliveryPhone: "",
        debitAccountId: accounts?.length === 1 ? accounts[0]?.id : "",
        linkedAccountId: "",
        deliveryAddress: "",

        assignedToId: "",
        cardholderPhone: "",
        cardholderEmail: "",
    };

    const CardAccessFormValidation = Yup.object().shape({
        name: Yup.string().min(2, "Name is too short").max(60, "Name is too long").required("Required"),
        type: Yup.string().required("Required"),
        assignedToId: Yup.string().required("Required"),
        deliveryPhone: Yup.string().required("Required"),
        debitAccountId: Yup.string().required("Required"),
        // cardholderPhone: Yup.string().phone("Invalid Phone Number").required("Required"),
        cardholderEmail: Yup.string().email("Invalid email").required("Required"),
        deliveryAddress: Yup.string().min(3, "Address is too short").max(150, "Address is too Long").required("Required"),
    });

    const handleRequestNewCard = useCallback(
        async (_data: RequestNewCardRequest) => {
            try {
                setRequestedNewCard(null);
                setRequestNewCardError(null);
                setIsRequestNewCardLoading(true);
                const res = await requestNewCard(_data);
                setRequestedNewCard(res.cardData.createRequest);
                dispatch(setNewCard(res.cardData));
                dispatch(setIsCardsFiltering(true));
                dispatch(messageTrue({ message: "Card requested successfully" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setRequestNewCardError(errorMessage);
            } finally {
                setIsRequestNewCardLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setRequestedNewCard(null);
        setRequestNewCardError(null);
        setIsRequestNewCardLoading(false);

        dispatch(updateUseExistingBusinessAddress(false));
        dispatch(updateUseExistingMobileNumber(false));
        dispatch(updateUseHolderInfoToCreateCard(true));
    }, []);

    return {
        requestedNewCard,
        requestNewCardError,
        isRequestNewCardLoading,
        InitialCardAccessState,
        CardAccessFormValidation,
        handleReset,
        handleRequestNewCard,
    };
};

export default useRequestNewCard;
