import { ElectricitySections } from "../../../hooks/state/BillPayment/billPayments.constants";
import { IRootState } from "../../../../../../redux/rootReducer";
import PaymentHeader from "../../Layouts/payment/components/page-header";
import { useSelector } from "react-redux";

function ElectricityPurchaseHeaders(): JSX.Element {
    const billPaymentStage = useSelector((state: IRootState) => state.billPayment.billPaymentStage);
    return <PaymentHeader header={ElectricitySections[billPaymentStage].text} subtitle={ElectricitySections[billPaymentStage].subtext} />;
}

export default ElectricityPurchaseHeaders;
