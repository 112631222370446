import React, { KeyboardEvent, useEffect, useState } from "react";

import ButtonComp from "../../../../button/ButtonComp";
import GoogleIcon from "../../../../google-icon";
import MenuItemChild from "../menu-item-child";
import { canShowPreReleaseFeatures } from "../../../../../utils/preReleaseConfig";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { useLocation } from "react-router-dom";

interface Props {
    icon: string;
    path: string;
    text: string;
    isIconFilled?: boolean;
    isPreRelease?: boolean;
    optionalPath?: string;
    isIconOutlined?: boolean;
    onClick: (e: React.MouseEvent) => void;
    subItems: Array<{
        path: string;
        text: string;
        hide?: boolean;
        isPreRelease?: boolean;
        optionalPath?: string;
        onClick?: (e: React.MouseEvent) => void;
    }>;
}

function MenuItemWithChildren({
    path,
    icon,
    text,
    subItems,
    isPreRelease = false,
    isIconFilled,
    optionalPath,
    isIconOutlined,
    onClick,
}: Props): JSX.Element {
    if (isPreRelease && !canShowPreReleaseFeatures) {
        return <></>;
    }

    const location = useLocation();

    const [open, setOpen] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);

    const domNode = useClickOutside(() => {
        if (location.pathname.includes(optionalPath || path)) return;
        setOpen(false);
    });

    useEffect(() => {
        if (location.pathname.includes(optionalPath || path)) {
            setActive(true);
            setOpen(true);
        } else {
            setActive(false);
            setOpen(false);
        }
    }, [location.pathname]);

    const handleKeypress = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === "Enter") {
            domNode.current?.scrollIntoView();
            setActive((prev) => !prev);
            setOpen((prev) => !prev);
        }
    };

    return (
        <div className="relative" ref={domNode} data-type="section" tabIndex={-1}>
            <ButtonComp
                type="button"
                buttonType="tertiary"
                color="transparent"
                data-type="section"
                onClick={() => {
                    domNode.current?.scrollIntoView();
                    setOpen((prev) => !prev);
                }}
                onKeyDown={handleKeypress}
                noTabIndex
                fullWidth
            >
                <div
                    className={
                        "relative flex h-10 w-full items-center justify-between rounded-md px-4 " + ` ${active ? "" : "hover:bg-grey-tertiary"}`
                    }
                    data-type="section"
                    tabIndex={-1}
                >
                    <div className="flex items-center justify-start">
                        <GoogleIcon icon={icon} isFilled={active || isIconFilled} isOutlined={isIconOutlined} />
                        <span
                            className={
                                "ml-2 truncate text-base duration-150 " +
                                ` ${active ? "font-medium text-black" : "text-black-secondary hover:text-black"}`
                            }
                            data-type="section"
                        >
                            {text}
                        </span>
                    </div>

                    <div
                        className={`flex items-center justify-center transition-all ` + `${open ? "rotate-0 transform" : "-rotate-180"} `}
                        data-type="section"
                    >
                        <GoogleIcon icon="keyboard_arrow_up" dataType="section" isOutlined />
                    </div>
                </div>
            </ButtonComp>
            <div className={`${open ? "max-h-96 " : "max-h-0"} ` + "overflow-hidden transition-all duration-300 ease-in-out"} tabIndex={-1}>
                <div className="relative flex flex-col space-y-1" tabIndex={-1}>
                    {subItems
                        .filter((subItem) => !subItem.hide)
                        .map((subItem, index) => (
                            <MenuItemChild
                                key={index}
                                onClick={(e) => {
                                    onClick(e);
                                    if (subItem.onClick) {
                                        subItem.onClick(e);
                                    }
                                }}
                                path={subItem.path}
                                optionalPath={subItem.optionalPath}
                                text={subItem.text}
                                isParentOpen={open}
                                isPreRelease={subItem.isPreRelease || false}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}

export default MenuItemWithChildren;
