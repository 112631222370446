import { setAirtimePurchaseOtp } from "../../../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseAirtimeOtpInterface {
    handleOtpChange: (_amount: string) => void;
}

function useAirtimePurchaseOtp(): UseAirtimeOtpInterface {
    const dispatch = useDispatch();

    const handleOtpChange = useCallback((_otp: string) => {
        dispatch(setAirtimePurchaseOtp(_otp));
    }, []);

    return { handleOtpChange };
}

export default useAirtimePurchaseOtp;
