import React, { useState } from "react";

// import { handleNameAbbrev } from "../../../../Transactions/Hooks/State/useTransactions";
import AvatarDetails from "../../../../../../components/avatar-details";
import User from "../../../../../../models/user";
import titleCase from "../../../../../../hooks/titleCase";

interface TeamMemberListItemProps {
    isActive: boolean;
    onClick: () => void;
    teamMember: User;
}

function TeamMemberListItem({ isActive, onClick, teamMember }: TeamMemberListItemProps): JSX.Element {
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            className={`flex w-full cursor-pointer flex-row items-center justify-start rounded-md px-3 py-1 hover:bg-white ${isActive ? "rounded-md bg-white" : ""}`}
            onClick={onClick}
            key={`team-member-list-${teamMember.id}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <AvatarDetails
                dataType="team-members"
                color={isHover || isActive ? "grey" : "white"}
                title={titleCase(teamMember.name)}
                subtitle={teamMember.isAdmin ? "Admin" : "Member"}
                size="xs"
                fullWidth
            />
        </div>
    );
}

export default TeamMemberListItem;
