import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../../components/button/ButtonComp";
import CurrencyCode from "../../../../../../../components/currency-code";
import ErrorToast from "../../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../components/spinner";
import { OtpActivityType } from "../../../../../../../helpers/AppConstants";
import OtpInputForm from "../../../../../../../components/otp";
import { PaymentStageType } from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import formatNumber from "../../../../../../../utils/formatNumber";
import { setPaymentStage } from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { setZambiaSingleTransferOtp } from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import titleCase from "../../../../../../../hooks/titleCase";
import { useMoneyToNumber } from "../../../../../../../hooks/useMoneyToNumber";
import useOtpInit from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useOtpInit";
import useSingleTransfer from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransfer";
import useSingleTransferOtp from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransferOtp";

function MakePayment(): JSX.Element {
    const dispatch = useDispatch();

    const { handleOtpChange } = useSingleTransferOtp();
    const { otpInitError, isInitOTPLoading, handleOTPInit, handleResendOTPInit } = useOtpInit({});
    const { isSinglePaymentLoading, singlePaymentError, singlePaymentResponse, handleSingleTransfer } = useSingleTransfer();

    const singleTransferDetails = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails);
    const recipientBankAccountName = useSelector((state: IRootState) => state.zambiaSingleTransfer.recipientBankAccountName);
    const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

    const [resendOtpText, setResendOtpText] = useState(false);

    useLayoutEffect(() => {
        if (isAuthAppSetup) return;
        void handleOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION });
    }, [handleOTPInit, isAuthAppSetup]);

    useEffect(() => {
        if (singleTransferDetails.otp.length < 6) return;
        void handleSingleTransfer({ ...singleTransferDetails, amount: useMoneyToNumber(singleTransferDetails.amount) });
    }, [singleTransferDetails]);

    useEffect(() => {
        if (!singlePaymentResponse) return;
        dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
    }, [singlePaymentResponse]);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center">
                <SinglePaymentHeaders />
                {!isAuthAppSetup && isInitOTPLoading && (
                    <div className="flex w-full flex-row items-center justify-center space-x-4 pt-2 text-sm">
                        <LencoSpinner size="sm" />
                        <span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
                    </div>
                )}
                {!isInitOTPLoading && (
                    <>
                        <div className="flex w-full flex-col space-y-9">
                            <p className="pt-2 text-center text-base text-black-secondary">
                                {isAuthAppSetup && "Enter the OTP generated from your token App to approve the payment of "}
                                {!isAuthAppSetup && "One-time password (OTP) has been sent to your email to approve payment of "}
                                <CurrencyCode />
                                {formatNumber(singleTransferDetails.amount)} to {titleCase(recipientBankAccountName)}
                            </p>
                            <div className="flex w-full flex-col rounded-lg bg-white p-6">
                                <OtpInputForm
                                    value={singleTransferDetails.otp || undefined}
                                    active={!isInitOTPLoading}
                                    isError={singlePaymentError}
                                    onChange={handleOtpChange}
                                    canResendOtp={!isAuthAppSetup}
                                    isResendOtpLoading={isInitOTPLoading}
                                    handleResend={() => {
                                        handleResendOTPInit({ action: OtpActivityType.APPROVE_TRANSACTION });
                                        setResendOtpText(true);
                                    }}
                                />
                            </div>
                            <div className="flex w-full flex-row items-center justify-center space-x-4">
                                <ButtonComp
                                    type="button"
                                    size="xl"
                                    color="grey"
                                    ripple="light"
                                    buttonType="secondary"
                                    func={() => {
                                        dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT));
                                        dispatch(setZambiaSingleTransferOtp(""));
                                    }}
                                >
                                    Back
                                </ButtonComp>

                                <ButtonComp
                                    type="submit"
                                    size="xl"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    isLoading={isSinglePaymentLoading}
                                    disable={singleTransferDetails.otp.length < 6}
                                    func={() =>
                                        void handleSingleTransfer({
                                            ...singleTransferDetails,
                                            amount: useMoneyToNumber(singleTransferDetails.amount),
                                        })
                                    }
                                >
                                    Authorize Payment
                                </ButtonComp>
                            </div>
                        </div>
                    </>
                )}
                {otpInitError && <ErrorToast error={<p>{otpInitError}</p>} isReactNode />}
            </div>
        </>
    );
}

export default MakePayment;
