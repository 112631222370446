import moment from "moment";

export function canShowCardDelivered(_cardDeliveredAt: Date | null): boolean {
    if (_cardDeliveredAt) {
        const currentDate = moment().format("YYYY-MM-DD");
        const formattedStartDate = moment(_cardDeliveredAt, "YYYY-MM-DD");
        const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");
        const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asDays();
        return !(daysBetweenStart > 14);
    } else {
        return true;
    }
}
