import React, { ReactElement } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import UserAccountMeta from "../../../../../models/userAccountMeta";

interface Props {
    backFunc: () => void;
    active: boolean;
    accountMeta: UserAccountMeta;
    isRemoving: boolean;
    handleRemoveFromAccount: () => void;
}

function RemoveFromAccountModal(props: Props): ReactElement {
    return (
        <>
            <Modal size="xs" active={props.active} toggler={props.backFunc}>
                <ModalHeader onClose={props.backFunc}>Remove From Account</ModalHeader>
                <ModalBody>
                    <div className="flex w-full flex-col">
                        {/* {displayError && (
							<MessageToasts toastMessage={isDeleteUserGroupError || ""} toastType={ToastType.ERROR} onClick={handleClearError} />
						)} */}
                        <div className="flex w-full flex-col items-center justify-center text-sm font-normal">
                            {/* <Caution /> */}
                            <div className="flex w-full flex-col items-center text-center">
                                <span className="text-left text-base font-normal">
                                    Are you sure you want to remove <span className="capitalize">{props.accountMeta.name}</span> from account?{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp
                        type="button"
                        ripple="light"
                        buttonType="secondary"
                        color="grey"
                        func={() => {
                            props.backFunc();
                        }}
                    >
                        <span>Back</span>
                    </ButtonComp>
                    <ButtonComp
                        type="submit"
                        color="red"
                        ripple="light"
                        buttonType="primary"
                        isLoading={props.isRemoving}
                        func={() => {
                            props.handleRemoveFromAccount();
                        }}
                    >
                        <span>Delete</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default RemoveFromAccountModal;
