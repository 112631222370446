import { addAccount, addSubAccountToActivate, removePendingAccount } from "../../../../../redux/accounts/slice/accountsSlice";
import { useCallback, useEffect, useRef, useState } from "react";

import CreateAccountRequest from "../../../../../models/createAccountRequest";
import { GenericObject } from "../../../../../helpers/types";
import { MainInitCompanyDetailsInterface } from "../../../../../redux/init/slice/initSlice.types";
import Parsers from "../../../../../utils/parsers";
import SocketWrapper from "../../../../../helpers/websocket/socketWrapper";
import UserAccount from "../../../../../models/userAccount";
import UserAccountMeta from "../../../../../models/userAccountMeta";
import { WebSocketEvent } from "../../../../../helpers/websocket/websocket.constants";
import { createSubAccountStatus } from "../../Services/accounts-api";
import { createSubAccountStatusRequest } from "../../Services/accounts-api.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { mainUpdateCompanyDetails } from "../../../../../redux/init/slice/initSlice";
import { useDispatch } from "react-redux";

interface useAccountCreationStatusInterface {
    isAccountCreated: boolean;
}

const useAccountCreationStatus = (data: CreateAccountRequest): useAccountCreationStatusInterface => {
    const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false);
    const dispatch = useDispatch();

    const pollingRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const handleCreateSubAccountStatus = useCallback(
        async (_data: createSubAccountStatusRequest) => {
            try {
                const res = await createSubAccountStatus(_data);
                if (res.success && res.account) {
                    setIsAccountCreated(true);
                    const account = Parsers.classObjectNonNullable(res.account, UserAccount);
                    const userAccountMeta = Parsers.classObjectNonNullable(res.userAccountMeta, UserAccountMeta);
                    dispatch(addAccount(account.id));
                    dispatch(removePendingAccount(data));
                    dispatch(
                        mainUpdateCompanyDetails({
                            callback: (companyDetails: MainInitCompanyDetailsInterface) => {
                                companyDetails.userAccountsMeta.push(userAccountMeta);
                                companyDetails.accounts.push(account);
                            },
                        })
                    );
                }
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(
                    errorTrue({
                        message: errorMessage,
                    })
                );
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (!data || isAccountCreated) return;
        listenForAccountCreationStatusEvent();
        pollingRef.current = setInterval(() => {
            void handleCreateSubAccountStatus({
                pendingAccountId: data.id,
            });
        }, 20000);
        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
            stopListenerForAccountCreationStatusEvent();
        };
    }, [data, isAccountCreated, handleCreateSubAccountStatus]);

    const handleAccountCreationStatusEvent = useCallback(
        (payload: GenericObject) => {
            if (isAccountCreated) return;
            const pendingAccount = Parsers.classObjectNonNullable(payload.pendingAccount, CreateAccountRequest);
            const account = Parsers.classObjectNonNullable(payload.account, UserAccount);
            const userAccountMeta = Parsers.classObjectNonNullable(payload.userAccountMeta, UserAccountMeta);
            if (pendingAccount.id === data.id) {
                setIsAccountCreated(true);
                dispatch(addAccount(account.id));
                dispatch(removePendingAccount(data));
                dispatch(addSubAccountToActivate(account.id));
                dispatch(
                    mainUpdateCompanyDetails({
                        callback: (companyDetails: MainInitCompanyDetailsInterface) => {
                            companyDetails.userAccountsMeta.push(userAccountMeta);
                            companyDetails.accounts.push(account);
                        },
                    })
                );
            }
        },
        [data, isAccountCreated]
    );

    /* Listening for the event SUB_ACCOUNT_CREATED. */
    const listenForAccountCreationStatusEvent = useCallback(() => {
        if (SocketWrapper.socket) {
            SocketWrapper.socket.registerEventHandler(WebSocketEvent.SUB_ACCOUNT_CREATED, handleAccountCreationStatusEvent);
        }
    }, []);
    /* Removing the event handler for the event SUB_ACCOUNT_CREATED. */
    const stopListenerForAccountCreationStatusEvent = useCallback(() => {
        if (SocketWrapper.socket) {
            SocketWrapper.socket.deregisterEventHandler(WebSocketEvent.SUB_ACCOUNT_CREATED, handleAccountCreationStatusEvent);
        }
    }, []);

    return {
        isAccountCreated,
    };
};

export default useAccountCreationStatus;
