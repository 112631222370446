import { useEffect, useState } from "react";

import NewDropdownHead from "../new-dropdown";
import NewTransactionIcon from "../new-transaction-icon";
import UserAccount from "../../models/userAccount";
import UserAccountDetails from "./components/user-account-details";
import UserAccountItem from "./components/user-account-item";
import { useAppSelector } from "../../redux/hooks";

interface Props {
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | undefined;
    dropdownSize?: "sm" | "md" | "lg" | "xl" | "2xl" | undefined;
    value: string | null;
    options: UserAccount[] | null;
    isDisabled?: boolean;
    showBalance?: boolean;
    isHideInitials?: boolean;
    approverCanSelect?: boolean;
    initiatorCanSelect?: boolean;

    onClick: (e: string) => void;
}

function NewUserAccountDropdown(props: Props): JSX.Element {
    const {
        size = "2xl",
        dropdownSize,
        value,
        options,
        isDisabled = false,
        showBalance = false,
        approverCanSelect = false,
        initiatorCanSelect = false,

        onClick,
    } = props;

    const userAccountMeta = useAppSelector((state) => state.init.main?.companyDetails.userAccountsMeta);
    const selectedAccount = useAppSelector((state) => state.init.main?.companyDetails.accounts.find((a) => a.id === value));

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredAccounts, setFilteredAccounts] = useState<UserAccount[]>([]);

    const normalAccounts = filteredAccounts.filter((_account) => userAccountMeta?.find((el) => el.userAccountId === _account.id)?.isInitiator);

    useEffect(() => {
        const accounts = options ? [...options.filter((_option) => _option.id !== selectedAccount?.id)] : [];
        if (approverCanSelect) {
            accounts.sort((account1, account2): number => {
                if (userAccountMeta?.find((el) => el.userAccountId === account1.id)?.isInitiatorAndApprover && account1.isMain) return -1;
                if (userAccountMeta?.find((el) => el.userAccountId === account2.id)?.isInitiatorAndApprover && account2.isMain) return 1;

                return 0;
            });
        } else {
            accounts.sort((account1, account2): number => {
                if (account1?.isMain) return -1;
                if (account2?.isMain) return 1;

                return account1?.lencoNameMin.localeCompare(account2?.lencoNameMin);
            });
        }

        const query = searchTerm.trim().toLowerCase();

        if (!query.length) {
            setFilteredAccounts(accounts);
        } else {
            setFilteredAccounts(
                accounts.filter((item: UserAccount) => {
                    return (
                        item.accountName.toLowerCase().includes(query) ||
                        item.accountNumber.toLowerCase().includes(query) ||
                        item.lencoNameMin.toLowerCase().includes(query)
                    );
                })
            );
        }
    }, [options, selectedAccount, searchTerm, approverCanSelect]);

    return (
        <>
            {!approverCanSelect && initiatorCanSelect && filteredAccounts && (
                <NewDropdownHead
                    content={
                        selectedAccount ? (
                            <UserAccountDetails
                                data={selectedAccount}
                                currency={selectedAccount.bankAccountCurrency}
                                showBalance={props.showBalance}
                                avatarColor="white"
                                isHideInitials={props.isHideInitials}
                            />
                        ) : (
                            <div className="flex items-center justify-start space-x-4">
                                <NewTransactionIcon icon="business_center" color="white" size="md" />
                                <p className="text-sm font-medium text-black-tertiary">Select Business Account</p>
                            </div>
                        )
                    }
                    size={size}
                    color={props.isHideInitials ? "dark-grey" : "grey"}
                    buttonType={props.isHideInitials ? "secondary" : "primary"}
                    isDisabled={isDisabled}
                    searchTerm={searchTerm}
                    paddingSize="sm"
                    dropdownSize={dropdownSize || "lg"}
                    optionsLength={filteredAccounts.length}
                    searchPlaceholder="Search for account"
                    handleChangeSearchTerm={setSearchTerm}
                    fullWidth
                    isSearchable
                    clickAndClose
                >
                    {(_index) => {
                        const canInitiate = userAccountMeta?.find((el) => el.userAccountId === filteredAccounts[_index].id)?.isInitiator;
                        return (
                            <UserAccountItem
                                key={_index}
                                size={dropdownSize || "lg"}
                                data={filteredAccounts[_index]}
                                onClick={isDisabled ? undefined : onClick}
                                message={!canInitiate ? "User role - You do not have access to initiate payment" : undefined}
                                currency={filteredAccounts[_index].bankAccountCurrency}
                                isDisabled={!canInitiate}
                                showBalance={!canInitiate ? false : showBalance}
                            />
                        );
                    }}
                </NewDropdownHead>
            )}
            {approverCanSelect && !initiatorCanSelect && filteredAccounts && (
                <NewDropdownHead
                    content={
                        selectedAccount ? (
                            <UserAccountDetails
                                data={selectedAccount}
                                currency={selectedAccount.bankAccountCurrency}
                                showBalance={props.showBalance}
                                avatarColor="white"
                                isHideInitials={props.isHideInitials}
                            />
                        ) : (
                            <div className="flex items-center justify-start space-x-4">
                                <NewTransactionIcon icon="business_center" color="white" size="md" />
                                <p className="text-sm font-medium text-black-tertiary">Select Business Account</p>
                            </div>
                        )
                    }
                    size={size}
                    color={props.isHideInitials ? "dark-grey" : "grey"}
                    buttonType={props.isHideInitials ? "secondary" : "primary"}
                    searchTerm={searchTerm}
                    paddingSize="sm"
                    dropdownSize={dropdownSize || "lg"}
                    optionsLength={filteredAccounts.length}
                    searchPlaceholder="Search for account"
                    handleChangeSearchTerm={setSearchTerm}
                    fullWidth
                    isSearchable
                >
                    {(_index) => {
                        const canApprove = userAccountMeta?.find((el) => el.userAccountId === filteredAccounts[_index].id)?.isApprover;
                        return (
                            <UserAccountItem
                                key={_index}
                                size={dropdownSize || "lg"}
                                data={filteredAccounts[_index]}
                                onClick={isDisabled ? undefined : onClick}
                                currency={filteredAccounts[_index].bankAccountCurrency}
                                showBalance={!canApprove ? false : showBalance}
                                isDisabled={!canApprove}
                                message={!canApprove ? "User role - You do not have access to approve payment" : undefined}
                            />
                        );
                    }}
                </NewDropdownHead>
            )}
            {!approverCanSelect && !initiatorCanSelect && normalAccounts && (
                <NewDropdownHead
                    content={
                        selectedAccount ? (
                            <UserAccountDetails
                                data={selectedAccount}
                                currency={selectedAccount.bankAccountCurrency}
                                showBalance={props.showBalance}
                                avatarColor="white"
                                isHideInitials={props.isHideInitials}
                            />
                        ) : (
                            <div className="flex items-center justify-start space-x-4">
                                <NewTransactionIcon icon="business_center" color="white" size="md" />
                                <p className="text-sm font-medium text-black-tertiary">Select Business Account</p>
                            </div>
                        )
                    }
                    size={size}
                    color={props.isHideInitials ? "dark-grey" : "grey"}
                    buttonType={props.isHideInitials ? "secondary" : "primary"}
                    searchTerm={searchTerm}
                    paddingSize="sm"
                    dropdownSize={dropdownSize || "lg"}
                    optionsLength={normalAccounts.length}
                    searchPlaceholder="Search for account"
                    handleChangeSearchTerm={setSearchTerm}
                    fullWidth
                    isSearchable
                >
                    {(_index) => (
                        <UserAccountItem
                            key={_index}
                            size={dropdownSize || "lg"}
                            data={normalAccounts[_index]}
                            onClick={isDisabled ? undefined : onClick}
                            currency={filteredAccounts[_index].bankAccountCurrency}
                            showBalance={showBalance}
                        />
                    )}
                </NewDropdownHead>
            )}
        </>
    );
}

export default NewUserAccountDropdown;
