import React from "react";
import Transaction from "../../../../../../../models/transaction";
import { formatDateAndTime } from "../../../../../../../utils/formatDate";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardApprovedOrDeclined({ dataType, transaction }: Props): JSX.Element {
    return (
        <>
            {transaction && transaction.isDebit() && (transaction.initiatedBy || transaction.approvedBy || transaction.declinedBy) && (
                <div className="flex w-full flex-col space-y-4" data-type={dataType}>
                    {/*Initiated By*/}
                    {transaction.initiatedBy && (
                        <>
                            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                <p className="text-sm text-black-secondary" data-type={dataType}>
                                    Initiated by
                                </p>
                                <div className="flex max-w-55% flex-col text-right" data-type={dataType}>
                                    <p className="text-sm font-medium text-black-secondary" data-type={dataType}>
                                        {transaction.initiatedBy?.name}{" "}
                                    </p>
                                    <p className="text-xs text-black-tertiary" data-type={dataType}>
                                        {transaction.initiatedOn && <>on {formatDateAndTime(transaction.initiatedOn)}</>}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {/*Cancelled By*/}
                    {transaction.cancelledBy && (
                        <>
                            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                <p className="text-sm text-black-tertiary" data-type={dataType}>
                                    Cancelled By:{" "}
                                </p>
                                <div className="flex max-w-55% flex-col text-right" data-type={dataType}>
                                    <p className="text-sm font-medium text-black-secondary" data-type={dataType}>
                                        {transaction.cancelledBy?.name}{" "}
                                    </p>
                                    <p className="text-xs text-black-tertiary" data-type={dataType}>
                                        {transaction.cancelledOn && <>on {formatDateAndTime(transaction.cancelledOn)}</>}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}

                    {/*Approved By*/}
                    {transaction.approvedBy && (
                        <>
                            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                <p className="text-sm text-black-tertiary" data-type={dataType}>
                                    Approved By:{" "}
                                </p>
                                <div className="flex max-w-55% flex-col text-right" data-type={dataType}>
                                    <p className="text-sm font-medium text-black-secondary" data-type={dataType}>
                                        {transaction.approvedBy?.name}{" "}
                                    </p>
                                    <p className="text-xs text-black-tertiary" data-type={dataType}>
                                        {transaction.approvedOn && <>on {formatDateAndTime(transaction.approvedOn)}</>}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}

                    {/*Declined By*/}
                    {transaction.declinedBy && (
                        <>
                            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                <p className="text-sm text-black-tertiary" data-type={dataType}>
                                    Declined By:{" "}
                                </p>
                                <div className="flex max-w-55% flex-col text-right" data-type={dataType}>
                                    <p className="text-sm font-medium text-black-secondary" data-type={dataType}>
                                        {transaction.declinedBy?.name}{" "}
                                    </p>
                                    <p className="text-xs text-black-tertiary" data-type={dataType}>
                                        {transaction.declinedOn && <>on {formatDateAndTime(transaction.declinedOn)}</>}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}

            {/*Cancelled Reason*/}
            {transaction && transaction.isCancelled && (transaction.cancelledBy || transaction.cancelledOn) && (
                <>
                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                        <p className="text-sm text-black-tertiary" data-type={dataType}>
                            Cancelled Reason
                        </p>
                        <div className="flex max-w-55% flex-col text-right" data-type={dataType}>
                            <p className="text-sm font-medium text-black-secondary" data-type={dataType}>
                                {transaction.cancelReason}
                            </p>
                        </div>
                    </div>
                </>
            )}

            {/*Decline Reason*/}
            {transaction && transaction.isDeclined && (transaction.declinedBy || transaction.declinedOn) && (
                <>
                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                        <p className="text-sm text-black-tertiary" data-type={dataType}>
                            Declined Reason
                        </p>
                        <div className="flex max-w-55% flex-col text-right" data-type={dataType}>
                            <p className="text-sm font-medium text-black-secondary" data-type={dataType}>
                                {transaction.declineReason}
                            </p>
                        </div>
                    </div>
                </>
            )}

            {/*Failed Reason*/}
            {transaction && transaction.isFailed && transaction.failedReason && (
                <>
                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                        <p className="text-sm text-black-tertiary" data-type={dataType}>
                            Reason
                        </p>
                        <div className="flex max-w-55% flex-col text-right" data-type={dataType}>
                            <p className="text-sm font-medium text-error" data-type={dataType}>
                                {transaction.failedReason}
                            </p>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default TransactionDetailCardApprovedOrDeclined;
