import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import Transaction from "../../../models/transaction";
import { TransactionState } from "./transactionSlice.types";
import { handleUpdatePendingTransaction } from "../utils/transaction.utils";

const initialState: TransactionState = {
    transactions: new Map<string, Transaction>(),
    selectedTransactionId: null,
    updatedTransactionUserGroup: null,
    updatedUserAccount: null,
    updatedTransaction: null,
    showTransactionDetailCard: false,
    transactionDetail: null,
};

export const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        setMultipleTransactions: (state: TransactionState, action: PayloadAction<Transaction[]>) => {
            action.payload.forEach((t) => state.transactions.set(t.id, t));
        },
        removeMultipleTransaction: (state: TransactionState, action: PayloadAction<string[]>) => {
            action.payload.forEach((id) => state.transactions.delete(id));
        },
        setSingleTransaction: (state: TransactionState, action: PayloadAction<Transaction>) => {
            state.transactions.set(action.payload.id, action.payload);
        },
        updatePendingTransaction: (
            state: TransactionState,
            action: PayloadAction<Array<{ id: string; approve: boolean; isCancelled?: boolean }>>
        ) => {
            action.payload.forEach((t) =>
                state.transactions.set(t.id, handleUpdatePendingTransaction({ prevData: state.transactions, nextData: t }))
            );
        },
        removeSingleTransaction: (state: TransactionState, action: PayloadAction<string>) => {
            state.transactions.delete(action.payload);
        },
        clearTransactions: (state: TransactionState) => {
            state.transactions.clear();
        },

        showTransactionDetails: (state: TransactionState, action: PayloadAction<string>) => {
            state.selectedTransactionId = action.payload;
        },
        hideTransactionDetails: (state: TransactionState) => {
            state.selectedTransactionId = null;
        },

        resetTransactionState: (state: TransactionState) => {
            state.transactions.clear();
            state.selectedTransactionId = null;

            // todo -> remove these
            state.updatedTransactionUserGroup = null;
            state.updatedUserAccount = null;
            state.updatedTransaction = null;
            state.showTransactionDetailCard = false;
            state.transactionDetail = null;
        },
    },
});

export const {
    setMultipleTransactions,
    removeMultipleTransaction,
    setSingleTransaction,
    removeSingleTransaction,
    clearTransactions,
    showTransactionDetails,
    hideTransactionDetails,
    resetTransactionState,
    updatePendingTransaction,
} = transactionSlice.actions;

export default transactionSlice.reducer;
