import React, { ReactNode } from "react";

import { DataColumn } from "../Type/DataRow";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatTime } from "../../../utils/formatTime";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function Text({ dataColumn, dataType }: Props) {
    return (
        <>
            {dataColumn.textType === TextType.ERROR ? (
                <p className="text-error">{typeof dataColumn.text === "string" ? dataColumn.text : ""}</p>
            ) : (
                <span
                    data-type={dataType}
                    className={`text-sms font-normal leading-none text-black-secondary ${dataColumn.bgGray && "flex items-center justify-center rounded-full bg-grey-backdrop px-2 py-1"}`}
                >
                    {dataColumn.textType === TextType.DATE
                        ? formatDate(dataColumn.text as Date)
                        : dataColumn.textType === TextType.TIME
                          ? formatTime(dataColumn.text as Date)
                          : dataColumn.textType === TextType.DATE_TIME
                            ? formatDateAndTime(dataColumn.text as Date)
                            : (dataColumn.text as string | ReactNode)}
                </span>
            )}
        </>
    );
}
