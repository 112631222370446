import {
    CurrentSignatoryRequests,
    abortCurrentSignatoryRequest,
    isDirectorUpdate,
} from "../../../../services/nigeria-application/registered/currentSignatory.api";
import { errorTrue, messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import {
    setIsDirectorOrSignatoryFalse,
    setIsDirectorOrSignatoryTrue,
    updateCurrentSignatory,
} from "../../../../../../../redux/application/applicationSlice";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { CurrentSignatoryPersonnelRequest } from "../../../../services/nigeria-application/registered/currentSignatory.types";
import { Routes } from "../../../../../../../routes/routes.constants";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface useIsDirectorInterface {
    isDirectorError: string | null;
    isDirectorLoading: boolean | null;
    handleReset: () => void;
    handleIsDirectorInit: (_data: CurrentSignatoryPersonnelRequest) => Promise<void>;
}
type LocationState = {
    from?: string;
};

const useIsDirector = (): useIsDirectorInterface => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const locationState = location.state ? (location.state as LocationState) : null;

    const [isDirectorError, setIsDirectorError] = useState<string | null>(null);
    const [isDirectorLoading, setIsDirectorLoading] = useState<boolean | null>(false);

    const handleIsDirectorInit = useCallback(
        async (_data: CurrentSignatoryPersonnelRequest) => {
            try {
                setIsDirectorError(null);
                setIsDirectorLoading(true);
                const res = await isDirectorUpdate(_data);

                if (res.signatory) {
                    dispatch(updateCurrentSignatory(res));
                }

                dispatch(_data.isDirector ? setIsDirectorOrSignatoryTrue() : setIsDirectorOrSignatoryFalse());
                if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                    dispatch(messageTrue("Business personnel updated successfully"));
                    return;
                }
                navigate(
                    {
                        pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.DETAILS,
                    },
                    {
                        state: {
                            from: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONNEL,
                        },
                    }
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setIsDirectorError(errorMessage);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsDirectorLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsDirectorError(null);
        setIsDirectorLoading(false);
        abortCurrentSignatoryRequest(CurrentSignatoryRequests.IS_DIRECTOR_UPDATE);
    }, []);

    return {
        isDirectorError,
        isDirectorLoading,
        handleReset,
        handleIsDirectorInit,
    };
};
export default useIsDirector;
