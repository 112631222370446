import { DropdownItem, DropdownItemValueType } from "../../../helpers/types";
import DropdownLink, { DropdownLinkProps } from "./dropdown-link";

import DropdownLinkContent from "./dropdown-link-content";
import { Link, To } from "react-router-dom";

interface Props<T extends DropdownItemValueType> extends Omit<DropdownLinkProps, "children"> {
    to?: string | To;
    state?: unknown;
    content: DropdownItem<T>;
}

function SimpleDropdownLink<T extends DropdownItemValueType>(props: Props<T>): JSX.Element {
    return (
        <DropdownLink {...props}>
            {props.to ? (
                <Link to={props.to} state={props.state}>
                    <DropdownLinkContent content={props.content} />
                </Link>
            ) : (
                <DropdownLinkContent content={props.content} />
            )}
        </DropdownLink>
    );
}

export default SimpleDropdownLink;
