import { Link } from "react-router-dom";
import Skeleton from "../../../../../../components/skeleton";

interface Props {
    name: string | undefined;
    photo: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    username: string | undefined;
}

function Support(props: Props): JSX.Element {
    const phoneLink = "tel:" + (props.phone || "");
    const emailLink = "mailto:" + (props.email || "");

    return (
        <>
            <div className="flex w-full flex-col items-center justify-start space-x-2 space-y-6 rounded-lg border border-grey bg-white p-4">
                {/* <div className="w-full rounded-[5px] border border-blue-senary bg-blue-backdrop px-4 py-2 text-left">
                    <h4 className="text-base font-medium text-black">Hi {props.username}, I&apos;m here to assist you anytime you need it.</h4>
                    <p className="mx-auto w-full pt-1.5 text-xs text-black-secondary"></p>
                </div> */}
                <div className="flex w-full items-center space-x-4 divide-x-1">
                    <div className="flex w-full items-center justify-start space-x-4">
                        <div>
                            <div className="h-12 w-12 rounded-full 2xs:h-12 2xs:w-12">
                                {props.photo ? (
                                    <img
                                        src={`data:image/png;base64,${props.photo}`}
                                        alt="account manager"
                                        className="h-full w-full rounded-full border-grey-secondary"
                                    />
                                ) : (
                                    <Skeleton avatar />
                                )}
                            </div>
                        </div>
                        <div>
                            {props.name ? (
                                <>
                                    <h4 className="text-sm font-medium text-black">
                                        Hi <span className="capitalize text-blue">{props.username}</span>, I&apos;m here to assist you anytime you
                                        need it.
                                    </h4>
                                </>
                            ) : (
                                <div className="h-4 w-36">
                                    <Skeleton box />
                                </div>
                            )}
                            {props.name ? (
                                <div className="mt-2 flex w-full items-center space-x-2 divide-x-1">
                                    <p className="text-xs text-black-tertiary">{props.name || ""}</p>
                                    <p className="pl-2 text-xs text-black-tertiary">Account manager</p>
                                </div>
                            ) : (
                                <div className="mt-2 h-3 w-24">
                                    <Skeleton box />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex w-full items-center justify-end">
                        <div>
                            <Link to={{ pathname: phoneLink }} target="_blank" className="mx-auto w-max text-black-secondary hover:text-black">
                                {props.phone ? (
                                    <div className="flex items-center justify-center space-x-2 pb-2">
                                        <span className="material-symbols-outlined icon-fill text-base text-blue">call</span>
                                        <p className="text-sm">{props.phone}</p>
                                    </div>
                                ) : (
                                    <div className="mx-auto h-3 w-44">
                                        <Skeleton box />
                                    </div>
                                )}
                            </Link>
                            <Link to={{ pathname: emailLink }} target="_blank" className="mx-auto mt-0.5 w-max text-black-secondary hover:text-black">
                                {props.email ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <span className="material-symbols-outlined icon-fill text-base text-blue">forward_to_inbox</span>
                                        <span className="text-sm">{props.email}</span>
                                    </div>
                                ) : (
                                    <div className="mx-auto mt-1 h-3 w-44">
                                        <Skeleton box />
                                    </div>
                                )}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="flex w-full flex-col items-center justify-start space-y-6 rounded-lg border border-grey bg-white p-4">
                <div className="w-full rounded-[5px] border border-blue-senary bg-blue-backdrop px-4 py-2 text-left">
                    <h4 className="text-base font-medium text-black">Hi {props.username},</h4>
                    <p className="mx-auto w-full pt-1.5 text-xs text-black-secondary">I&apos;m here to assist you anytime you need it.</p>
                </div>
                <div className="flex w-full items-center justify-between divide-x-1">
                    <div className="flex w-full items-center justify-start space-x-4">
                        <div className="h-12 w-12 rounded-full 2xs:h-12 2xs:w-12">
                            {props.photo ? (
                                <img
                                    src={`data:image/png;base64,${props.photo}`}
                                    alt="account manager"
                                    className="rounded-full border-grey-secondary"
                                />
                            ) : (
                                <Skeleton avatar />
                            )}
                        </div>
                        <div>
                            {props.name ? (
                                <h3 className="text-sm font-medium text-black 2xs:text-base">{props.name || ""}</h3>
                            ) : (
                                <div className="h-4 w-36">
                                    <Skeleton box />
                                </div>
                            )}
                            {props.name ? (
                                <p className="text-xs text-black-tertiary">Account manager</p>
                            ) : (
                                <div className="mt-0.5 h-3 w-24">
                                    <Skeleton box />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex w-full items-center justify-end">
                        <div>
                            <Link to={{ pathname: phoneLink }} target="_blank" className="mx-auto w-max text-black-secondary hover:text-black">
                                {props.phone ? (
                                    <div className="flex items-center justify-center space-x-2 pb-2">
                                        <span className="material-symbols-outlined icon-fill text-base text-blue">call</span>
                                        <p className="text-sm">{props.phone}</p>
                                    </div>
                                ) : (
                                    <div className="mx-auto h-3 w-44">
                                        <Skeleton box />
                                    </div>
                                )}
                            </Link>
                            <Link to={{ pathname: emailLink }} target="_blank" className="mx-auto mt-0.5 w-max text-black-secondary hover:text-black">
                                {props.email ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <span className="material-symbols-outlined icon-fill text-base text-blue">forward_to_inbox</span>
                                        <span className="text-sm">{props.email}</span>
                                    </div>
                                ) : (
                                    <div className="mx-auto mt-1 h-3 w-44">
                                        <Skeleton box />
                                    </div>
                                )}
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="mx-auto w-full min-w-max max-w-[352px] rounded-lg border border-grey bg-white p-4">
                <div className="w-full text-center">
                    <h4 className="text-base font-medium text-black 2xs:text-lg">Hi there,</h4>
                    <p className="mx-auto w-full pb-5 pt-2 text-xs text-black-tertiary 2xs:text-sm">
                        I&apos;m here to assist you anytime you need it.
                    </p>
                </div>

                <div className="flex min-w-min flex-col items-center justify-center">
                    <div className="h-28 w-28 rounded-full 2xs:h-36 2xs:w-36">
                        {props.photo ? (
                            <img src={`data:image/png;base64,${props.photo}`} alt="account manager" className="rounded-full" />
                        ) : (
                            <Skeleton avatar />
                        )}
                    </div>
                    {props.name ? (
                        <h3 className="mt-2 text-sm font-medium text-black 2xs:text-base">{props.name || ""}</h3>
                    ) : (
                        <div className="mt-2 h-4 w-36">
                            <Skeleton box />
                        </div>
                    )}
                    {props.name ? (
                        <p className="text-xs text-black-tertiary">Account manager</p>
                    ) : (
                        <div className="mt-0.5 h-3 w-24">
                            <Skeleton box />
                        </div>
                    )}

                    <div className="mt-3 border-t border-grey-secondary pt-3">
                        <Link to={{ pathname: phoneLink }} target="_blank" className="mx-auto w-max text-black-secondary hover:text-black">
                            {props.phone ? (
                                <div className="flex items-center justify-center space-x-2 pb-2">
                                    <span className="material-symbols-outlined icon-fill text-base text-blue">call</span>
                                    <p className="text-sm">{props.phone}</p>
                                </div>
                            ) : (
                                <div className="mx-auto h-3 w-44">
                                    <Skeleton box />
                                </div>
                            )}
                        </Link>
                        <Link to={{ pathname: emailLink }} target="_blank" className="mx-auto w-max text-black-secondary hover:text-black">
                            {props.email ? (
                                <div className="flex items-center justify-center space-x-2">
                                    <span className="material-symbols-outlined icon-fill text-base text-blue">forward_to_inbox</span>
                                    <span className="text-sm">{props.email}</span>
                                </div>
                            ) : (
                                <div className="mx-auto mt-1 h-3 w-44">
                                    <Skeleton box />
                                </div>
                            )}
                        </Link>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Support;
