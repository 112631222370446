import {
    addTransferDetailToTransferList,
    removeTransferDetailToTransferList,
    setCanApprove,
    setCanVerifyRecipientAccountDetails,
    setIsReviewingTransferDetail,
    setIsUpdatingTransferDetail,
    setNewTransferDetail,
    setTransferDetail,
    updateTransferDetailToTransferList,
} from "../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";

import AccountNumberInput from "../../../../../components/account-number-input";
import AccountNumberSelect from "../selects/account-number-select";
import AvatarDetails from "../../../../../components/avatar-details";
import BankSelect from "../selects/bank-select";
import ButtonComp from "../../../../../components/button/ButtonComp";
import CustomerAccount from "../../../../../models/customerAccount";
import Fee from "../../../../../components/fees/fees";
import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import Input from "../../../../../components/inputs/Input";
import MessageToast from "../../../../../components/message-toast";
import Money from "../../../../../components/money";
import MoneyInput from "../../../../../components/inputs/money-input";
import NewUserAccountDropdown from "../../../../../components/user-account-dropdown";
import SendMoneyErrorCard from "../Cards/send-money-error-card";
import { TransferDetail } from "../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import UserAccount from "../../../../../models/userAccount";
import VerifiedAccountCard from "../Cards/verified-account-card";
import doesUserHaveAccessToAccount from "../../../../../helpers/doesUserHaveAccessToAccount";
import getBankName from "../../../../../helpers/getBankName";
import getInitials from "../../../../../helpers/get-initials";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import titleCase from "../../../../../hooks/titleCase";
import useBulkTransferAccountNumberAutocomplete from "../../hooks/state/send-money/BulkTransfer/useBulkTransferAccountNumberAutocomplete";
import useBulkTransferAmount from "../../hooks/state/send-money/BulkTransfer/useBulkTransferAmount";
import useBulkTransferPayFrom from "../../hooks/state/send-money/BulkTransfer/useBulkTransferPayFrom";
import useBulkTransferPurpose from "../../hooks/state/send-money/BulkTransfer/useBulkTransferPurpose";
import useBulkTransferRecipientBankCode from "../../hooks/state/send-money/BulkTransfer/useBulkTransferRecipientBankCode";
import useBulkTransferVerifyRecipientAccountDetails from "../../hooks/state/send-money/BulkTransfer/useBulkTransferVerifyRecipientAccountDetails";
import { useMoneyToNumber } from "../../../../../hooks/useMoneyToNumber";

// interface AddRecipientsProps {
// handleBack: () => void;
// handlePageSummary: () => void;
// }

enum Stage {
    IS_NEW = "is_new",
    IS_REVIEW = "is_review",
    IS_UPDATE = "is_update",
}

function AddRecipients(): JSX.Element {
    const dispatch = useDispatch();

    const { handleAmountChange } = useBulkTransferAmount();
    const { handlePurposeChange } = useBulkTransferPurpose();
    const { handleSelectBank } = useBulkTransferRecipientBankCode();

    const { accounts, selectedAccountId, currentUserAccountMeta, handleSelectAccount, handleResetUserAccountMeta } = useBulkTransferPayFrom();
    const { verifyAccountError, isVerifyAccountLoading, handleSetVerifiedAccount, handleResetVerifiedAccount } =
        useBulkTransferVerifyRecipientAccountDetails();

    const {
        suggestedRecipients,
        isRecipientAutocompleteLoading,

        // handleSelectRecipient,
        handleAccountNumberChange,
        handleSelectUserAccountRecipient,
        handleSelectCustomerAccountRecipient,
    } = useBulkTransferAccountNumberAutocomplete({ handleSetVerifiedAccount, handleResetVerifiedAccount });

    const payFrom = useSelector((state: IRootState) => state.sendMoney.payFrom);
    const transferDetail = useSelector((state: IRootState) => state.bulkTransfer.transferDetail);
    const tempTransferDetail = useSelector((state: IRootState) => state.bulkTransfer.tempTransferDetail);
    const isNewTransferDetail = useSelector((state: IRootState) => state.bulkTransfer.isNewTransferDetail);
    const transferDetailArray = useSelector((state: IRootState) => state.bulkTransfer.transferDetailArray);
    const isUpdatingTransferDetail = useSelector((state: IRootState) => state.bulkTransfer.isUpdatingTransferDetail);

    const [stage, setStage] = useState<Stage>(Stage.IS_NEW);
    const [mainTransferDetail, setMainTransferDetail] = useState<TransferDetail | null>(null);
    const [indexOfTransferDetail, setIndexOfTransferDetail] = useState<number | null>(null);

    useEffect(() => {
        if (!payFrom) return;
        handleSelectAccount(payFrom);
    }, [payFrom]);

    useEffect(() => {
        return () => {
            handleResetVerifiedAccount();
            handleResetUserAccountMeta();
        };
    }, []);

    useLayoutEffect(() => {
        const index = transferDetailArray.findIndex((_) => _.key === transferDetail.key);
        setMainTransferDetail(isUpdatingTransferDetail ? tempTransferDetail : transferDetail);
        setIndexOfTransferDetail(index < 0 ? transferDetailArray.length : index);
    }, [tempTransferDetail, transferDetail, transferDetailArray, isUpdatingTransferDetail]);

    useEffect(() => {
        if (mainTransferDetail && currentUserAccountMeta && currentUserAccountMeta.isApprover) {
            if (currentUserAccountMeta.maxApprovalAmount) {
                if (useMoneyToNumber(mainTransferDetail.amount) <= currentUserAccountMeta.maxApprovalAmount) {
                    dispatch(setCanApprove(true));
                    return;
                } else {
                    dispatch(setCanApprove(false));
                    return;
                }
            } else {
                dispatch(setCanApprove(true));
                return;
            }
        } else {
            dispatch(setCanApprove(false));
        }
    }, [mainTransferDetail, currentUserAccountMeta]);

    useEffect(() => {
        if (!accounts) return;
        if (accounts.length < 2) {
            if (doesUserHaveAccessToAccount(accounts[0].id)) {
                handleSelectAccount(accounts[0].id);
            }
        }
    }, [accounts]);

    return (
        <>
            {mainTransferDetail && (
                <div className="flex w-full flex-col space-y-4 rounded-lg border border-grey bg-white p-6">
                    <div className="flex w-full items-center justify-between space-x-4">
                        <h3 className="text-lg font-medium">
                            {!isNewTransferDetail ? "Bulk " : ""}Payment
                            <span className={isNewTransferDetail ? "font-medium" : "font-normal"}>
                                {isNewTransferDetail
                                    ? ` ${transferDetailArray.length + 1}`
                                    : `(${(indexOfTransferDetail || 0) + 1} of ${transferDetailArray.length})`}
                            </span>
                        </h3>

                        {stage === Stage.IS_REVIEW && (
                            <div className="flex items-center justify-center space-x-2">
                                <ButtonComp
                                    size="sm"
                                    color="grey"
                                    buttonType="flat"
                                    paddingSize="2xs"
                                    func={() => {
                                        dispatch(setIsUpdatingTransferDetail(true));
                                        dispatch(setCanVerifyRecipientAccountDetails(false));
                                        setStage(Stage.IS_UPDATE);
                                    }}
                                >
                                    <GoogleIcon icon="edit" />
                                    <p className="ml-2">Edit</p>
                                </ButtonComp>
                                <ButtonComp
                                    size="sm"
                                    color="grey"
                                    buttonType="flat"
                                    paddingSize="2xs"
                                    func={() => {
                                        //todo
                                        dispatch(removeTransferDetailToTransferList(mainTransferDetail.key));
                                        dispatch(setNewTransferDetail());
                                        setStage(Stage.IS_NEW);
                                    }}
                                >
                                    <GoogleIcon icon="delete" />
                                    <p className="ml-2">Delete</p>
                                </ButtonComp>
                            </div>
                        )}
                    </div>
                    {stage === Stage.IS_REVIEW && (
                        <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                            <div className="flex flex-col items-center justify-center space-y-3 pb-6">
                                <AvatarDetails
                                    size="lg"
                                    initials={getInitials(mainTransferDetail.selectedUserAccount?.bankAccount?.accountName || "")}
                                    icon="outflow"
                                    fullWidth={false}
                                />
                                <p className="text-sm font-normal !leading-[100%] text-black-tertiary">You&apos;re sending</p>
                                <p className="text-[32px] font-medium !leading-[100%] text-black">
                                    <Money amount={useMoneyToNumber(mainTransferDetail.amount)} />
                                </p>
                                {mainTransferDetail.selectedUserAccount?.bankCode !== mainTransferDetail.recipient.externalAccount?.bankCode && (
                                    <p className="text-sm font-normal !leading-[100%] text-black-tertiary">
                                        Fee: <span> {<Fee value={mainTransferDetail.amount} />}</span>
                                    </p>
                                )}
                            </div>
                            <div className="flex w-full flex-col space-y-3">
                                <p className="text-sm font-medium leading-[100%] text-black-tertiary">From</p>
                                <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Account Name</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">
                                            {mainTransferDetail.selectedUserAccount &&
                                                mainTransferDetail.selectedUserAccount?.bankAccount?.accountName}
                                        </p>
                                    </div>
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Account Number</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">
                                            {mainTransferDetail.selectedUserAccount &&
                                                mainTransferDetail.selectedUserAccount?.bankAccount?.accountNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-col space-y-3">
                                <p className="text-sm font-medium leading-[100%] text-black-tertiary">To</p>
                                <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Account Name</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">
                                            {titleCase(mainTransferDetail.recipientBankAccount?.accountName || "")}
                                        </p>
                                    </div>
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Account Number</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">
                                            {mainTransferDetail.recipientBankAccount?.accountNumber}
                                        </p>
                                    </div>
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Bank Name</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">
                                            {titleCase(getBankName(mainTransferDetail.recipientBankAccount?.bankCode))}
                                        </p>
                                    </div>
                                    <div className="flex w-full flex-row justify-between">
                                        <span className="text-sm text-black-tertiary">Purpose</span>
                                        <p className="text-right text-sm font-medium text-black-secondary">{mainTransferDetail.purpose}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(stage === Stage.IS_NEW || stage === Stage.IS_UPDATE) && (
                        <>
                            <div className="flex w-full flex-col space-y-4">
                                <p className="text-sm font-medium text-black-secondary">Pay from</p>
                                <NewUserAccountDropdown
                                    value={selectedAccountId || ""}
                                    options={accounts || null}
                                    onClick={handleSelectAccount}
                                    isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                                    dropdownSize="xl"
                                    showBalance
                                    initiatorCanSelect
                                />
                            </div>

                            <div className="flex w-full flex-col space-y-4">
                                <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                                <MoneyInput
                                    label="Amount"
                                    value={mainTransferDetail.amount || ""}
                                    onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                                    fee={
                                        mainTransferDetail.selectedUserAccount?.bankCode !==
                                        mainTransferDetail.recipient.externalAccount?.bankCode ? (
                                            <div className="w-max text-xs text-black-secondary">
                                                Fee: <span className="font-bold">{<Fee value={mainTransferDetail.amount} />}</span>
                                            </div>
                                        ) : undefined
                                    }
                                />
                                <Input placeholder="Purpose" value={mainTransferDetail.purpose} onChange={handlePurposeChange} fullWidth />
                            </div>

                            {currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
                                <SendMoneyErrorCard maxApprovalAmount={currentUserAccountMeta?.maxApprovalAmount || 0} isApprovalError fullWidth />
                            )}
                            {mainTransferDetail.selectedUserAccount &&
                                !isNullOrUndefined(mainTransferDetail?.selectedUserAccount?.balance) &&
                                useMoneyToNumber(mainTransferDetail.amount) > mainTransferDetail?.selectedUserAccount?.balance && (
                                    <SendMoneyErrorCard balance={mainTransferDetail?.selectedUserAccount?.balance} isInsufficientFunds fullWidth />
                                )}
                            {mainTransferDetail.selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
                                <SendMoneyErrorCard canSendMoneyToSpecificAccounts fullWidth />
                            )}

                            {mainTransferDetail.selectedUserAccount?.accountRestrictions.cannotSendMoney ? (
                                <SendMoneyErrorCard canNotSendFromAccount fullWidth />
                            ) : (
                                <div className="flex w-full flex-col space-y-4">
                                    <p className="text-sm font-medium text-black-secondary">Recipient Details</p>
                                    {mainTransferDetail.selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts ? (
                                        <>
                                            <AccountNumberSelect
                                                onClick={(e) => {
                                                    if (e instanceof UserAccount) {
                                                        handleSelectUserAccountRecipient(e);
                                                    }
                                                    if (e instanceof CustomerAccount) {
                                                        handleSelectCustomerAccountRecipient(e);
                                                    }
                                                }}
                                                options={suggestedRecipients}
                                                value={mainTransferDetail.recipient.externalAccount?.accountNumber}
                                            />
                                            {!verifyAccountError && mainTransferDetail.recipientBankAccount && (
                                                <VerifiedAccountCard value={titleCase(mainTransferDetail.recipientBankAccount.accountName)} />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <AccountNumberInput
                                                data={suggestedRecipients as UserAccount[] | null}
                                                label="Account Number or Name"
                                                onChange={handleAccountNumberChange}
                                                isLoading={isRecipientAutocompleteLoading}
                                                inputValue={mainTransferDetail.recipient.externalAccount?.accountNumber}
                                                onClick={(e) => {
                                                    if (e instanceof UserAccount) {
                                                        handleSelectUserAccountRecipient(e);
                                                    }
                                                    if (e instanceof CustomerAccount) {
                                                        handleSelectCustomerAccountRecipient(e);
                                                    }
                                                }}
                                            />
                                            <BankSelect
                                                bankCode={mainTransferDetail.recipient.externalAccount?.bankCode || ""}
                                                accountNumber={mainTransferDetail.recipient.externalAccount?.accountNumber || ""}
                                                onClick={handleSelectBank}
                                            />
                                            {verifyAccountError && !mainTransferDetail.recipientBankAccount && (
                                                <MessageToast message={verifyAccountError.message} type="error" fullWidth />
                                            )}
                                            {!verifyAccountError && mainTransferDetail.recipientBankAccount && (
                                                <VerifiedAccountCard value={titleCase(mainTransferDetail.recipientBankAccount.accountName)} />
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    <div className="!mb-2 !mt-6 h-[1px] min-h-[1px] w-full bg-grey"></div>

                    {stage === Stage.IS_UPDATE ? (
                        <div className="flex items-center justify-center space-x-4">
                            <ButtonComp
                                size="lg"
                                color="red"
                                buttonType="flat"
                                func={() => {
                                    setStage(Stage.IS_REVIEW);
                                    dispatch(setIsReviewingTransferDetail());
                                }}
                                fullWidth
                            >
                                <GoogleIcon icon="close" />
                                <p className="ml-2">Cancel</p>
                            </ButtonComp>
                            <ButtonComp
                                size="lg"
                                color="blue"
                                buttonType="flat"
                                isLoading={isVerifyAccountLoading}
                                disable={
                                    !!(
                                        !mainTransferDetail.selectedUserAccount ||
                                        verifyAccountError ||
                                        !mainTransferDetail.recipientBankAccount ||
                                        mainTransferDetail.selectedUserAccount.accountRestrictions.cannotSendMoney ||
                                        !mainTransferDetail.purpose ||
                                        !mainTransferDetail.amount ||
                                        useMoneyToNumber(mainTransferDetail.amount) === 0 ||
                                        useMoneyToNumber(mainTransferDetail.amount) > (mainTransferDetail.selectedUserAccount?.balance as number)
                                    )
                                }
                                func={() => {
                                    dispatch(updateTransferDetailToTransferList(mainTransferDetail));
                                    dispatch(messageTrue("Transfer Updated"));
                                    setStage(Stage.IS_REVIEW);
                                    dispatch(setIsReviewingTransferDetail());
                                }}
                                fullWidth
                            >
                                <GoogleIcon icon="check" />
                                <p className="ml-2">Update Payment</p>
                            </ButtonComp>
                        </div>
                    ) : (
                        <div className="w-full">
                            {isNewTransferDetail ? (
                                <div className="flex w-full items-center justify-center space-x-4">
                                    {transferDetailArray.length > 0 && (
                                        <ButtonComp
                                            size="lg"
                                            color="blue"
                                            buttonType="flat"
                                            disable={!!((indexOfTransferDetail || 0) < 1)}
                                            func={() => {
                                                if (isNullOrUndefined(indexOfTransferDetail) || indexOfTransferDetail - 1 < 0) return;
                                                dispatch(setTransferDetail(transferDetailArray[indexOfTransferDetail - 1]));
                                                setStage(Stage.IS_REVIEW);
                                                dispatch(setIsReviewingTransferDetail());
                                            }}
                                            fullWidth
                                        >
                                            <GoogleIcon icon="chevron_left" />
                                            <p className="ml-2">Previous Payment</p>
                                        </ButtonComp>
                                    )}
                                    <ButtonComp
                                        buttonType="flat"
                                        color="blue"
                                        size="lg"
                                        isLoading={isVerifyAccountLoading}
                                        disable={
                                            !!(
                                                !mainTransferDetail.selectedUserAccount ||
                                                verifyAccountError ||
                                                !mainTransferDetail.recipientBankAccount ||
                                                mainTransferDetail.selectedUserAccount.accountRestrictions.cannotSendMoney ||
                                                !mainTransferDetail.purpose ||
                                                !mainTransferDetail.amount ||
                                                useMoneyToNumber(mainTransferDetail.amount) === 0 ||
                                                useMoneyToNumber(mainTransferDetail.amount) >
                                                    (mainTransferDetail.selectedUserAccount?.balance as number)
                                            )
                                        }
                                        func={() => {
                                            dispatch(addTransferDetailToTransferList(mainTransferDetail));
                                            dispatch(messageTrue("Transfer Added"));
                                            dispatch(setNewTransferDetail());
                                            handleResetVerifiedAccount();
                                            handleResetUserAccountMeta();
                                        }}
                                        fullWidth
                                    >
                                        <GoogleIcon icon="add_circle" />
                                        <p className="ml-2">Add Payment</p>
                                    </ButtonComp>
                                </div>
                            ) : (
                                <div className="flex w-full items-center justify-center space-x-4">
                                    <ButtonComp
                                        size="lg"
                                        color="blue"
                                        buttonType="flat"
                                        disable={!!((indexOfTransferDetail || 0) < 1)}
                                        func={() => {
                                            if (isNullOrUndefined(indexOfTransferDetail) || indexOfTransferDetail - 1 < 0) return;
                                            dispatch(setTransferDetail(transferDetailArray[indexOfTransferDetail - 1]));
                                            if (stage !== Stage.IS_REVIEW) setStage(Stage.IS_REVIEW);
                                        }}
                                        fullWidth
                                    >
                                        <GoogleIcon icon="chevron_left" />
                                        <p className="ml-2">Previous Payment</p>
                                    </ButtonComp>
                                    <ButtonComp
                                        size="lg"
                                        color="blue"
                                        buttonType="flat"
                                        func={() => {
                                            if (isNullOrUndefined(indexOfTransferDetail)) return;
                                            if ((indexOfTransferDetail || 0) + 1 === transferDetailArray.length) {
                                                dispatch(setNewTransferDetail());
                                                setStage(Stage.IS_NEW);
                                            } else {
                                                dispatch(setTransferDetail(transferDetailArray[indexOfTransferDetail + 1]));
                                            }
                                        }}
                                        fullWidth
                                    >
                                        {(indexOfTransferDetail || 0) + 1 === transferDetailArray.length ? (
                                            <>
                                                <GoogleIcon icon="add_circle" />
                                                <p className="ml-2">New Payment</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="ml-2">Next Payment</p>
                                                <GoogleIcon icon="chevron_right" />
                                            </>
                                        )}
                                    </ButtonComp>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default AddRecipients;
