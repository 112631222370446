import * as Yup from "yup";

import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import { FormikProps } from "formik";
import { ObjectSchema } from "yup";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { mainUpdateUserAuthApp } from "../../../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { testCode } from "../token-app.api";
import { useDispatch } from "react-redux";

interface TestCodeForm {
    code: string;
}

export interface UseTestCodeFormResponse {
    isSubmitting: boolean;
    handleTestCode: (data: TestCodeForm) => Promise<void>;
    initialFormState: TestCodeForm;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValidation: ObjectSchema<any>;
    formikRef: MutableRefObject<FormikProps<TestCodeForm>>;
}

function useTestCodeForm(closeModal: () => void): UseTestCodeFormResponse {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [error, setError] = useState<string|null>(null);

    const handleTestCode = useCallback(
        async (data: TestCodeForm) => {
            // setError(null);
            setIsSubmitting(true);

            try {
                const res = await testCode(data.code);
                dispatch(mainUpdateUserAuthApp(res.authApp));
                dispatch(messageTrue({ message: "Token App Linked Successfully" }));

                // close the modal and reset the form
                closeModal();
                formikRef.current?.resetForm();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                // setError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    const formikRef = useRef<FormikProps<TestCodeForm>>();

    const initialFormState: TestCodeForm = {
        code: "",
    };

    const formValidation = Yup.object().shape({
        code: Yup.string().required("Required").length(6, "Enter the 6 digit code"),
    });

    useEffect(() => {
        // if code is 6 chars in length, automatically trigger submit
        if (formikRef.current && formikRef.current.values.code.length === 6) {
            void handleTestCode(formikRef.current.values);
        }
    }, [formikRef.current]);

    useEffect(() => {
        // reset the form on unmount
        return () => formikRef.current?.resetForm();
    }, []);

    return {
        isSubmitting,
        handleTestCode,
        initialFormState,
        formValidation,
        formikRef: formikRef as MutableRefObject<FormikProps<TestCodeForm>>,
    };
}

export default useTestCodeForm;
