import React, { useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import ChangePasswordModal from "./ChangePasswordModal";

function ChangePassword(): JSX.Element {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <ChangePasswordModal active={showModal} closeFunc={() => setShowModal(false)} />

            <div className="flex w-full flex-col items-start justify-between space-y-3 rounded-custom bg-white p-5 lg:flex-row lg:items-center lg:space-y-0">
                <div className="flex w-max flex-col">
                    <span className="text-base font-medium text-black">Password</span>
                    <span className="text-sm font-normal text-black-tertiary">Set a new password for your account</span>
                </div>

                <ButtonComp type="button" ripple="light" buttonType="secondary" color="black" size="md" func={() => setShowModal(true)}>
                    <div>Change Password</div>
                </ButtonComp>
            </div>
        </>
    );
}

export default ChangePassword;
