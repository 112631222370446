import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../components/spinner";
import { useSelector } from "react-redux";
import useEditSplitInflowInstruction from "../../Services/account-settings/hooks/useEditSplitInflowInstruction";
import UserAccount from "../../../../../models/userAccount";
import { SplitInflowRatio } from "../../Services/account-settings/account-settings.types";
import { Dispatch, SetStateAction } from "react";

interface Props {
    account: UserAccount;
    accountsMap: Map<string | number, UserAccount>;
    handleUpdateSplitInflowInstruction?: (ratio: SplitInflowRatio[], setIsSubmitting: Dispatch<SetStateAction<boolean>>) => Promise<void>;
    size?: "sm" | "md";
    canEdit?: boolean;
    children?: React.ReactNode;
    fullWidth?: boolean;
    canRemove?: boolean;
    isRemoving?: boolean;
    percentageInfo?: { name: string; percentage: string; type: string }[] | undefined;
    handleEditIconClick?: () => void;
    handleRemoveIconClick?: () => void;
}

function AccountSettingsSummaryItem({
    account,
    accountsMap,
    handleUpdateSplitInflowInstruction,
    size = "md",
    canEdit = true,
    fullWidth,
    handleEditIconClick,
    children,
    canRemove = false,
    handleRemoveIconClick,
    isRemoving = false,
}: Props): JSX.Element {
    const isAdmin = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.isAdmin || false);
    const isSplitInflowScreen = useSelector((state: IRootState) => state.settingsSecurity.isSplitInflowScreen);

    const { form } = useEditSplitInflowInstruction(account, accountsMap, handleUpdateSplitInflowInstruction);

    const filteredAccounts = Array.from(accountsMap.values()).filter((acc, idx) => idx < form.numParts);

    return (
        <div
            className={`space-y-2 rounded-md border border-black-quin bg-white px-4 ${fullWidth ? "w-full" : ""} ${size === "md" ? "py-2.5" : "flex h-11 items-center justify-start"} `}
        >
            <div className="flex h-8 w-full items-center justify-between text-left text-sm font-normal">
                {children ? (
                    typeof children === "string" ? (
                        <p className="text-left text-sm font-normal">{children}</p>
                    ) : (
                        <div className="text-left text-sm font-normal">{children}</div>
                    )
                ) : (
                    <h6 className="text-base font-medium text-black">Split Inflow</h6>
                )}
                <div className="flex items-center space-x-2">
                    {canEdit && isAdmin && (!canRemove || !isRemoving) ? (
                        <div className="flex cursor-pointer text-black-tertiary duration-150 hover:text-blue" onClick={handleEditIconClick}>
                            <GoogleIcon icon="border_color" size="sm" isOutlined />
                        </div>
                    ) : (
                        <div className="relative h-5 w-5 cursor-not-allowed text-black-tertiary text-opacity-disabled">
                            <GoogleIcon icon="border_color" size="sm" isOutlined />
                        </div>
                    )}

                    {canRemove && isAdmin && (
                        <div className="flex h-6 w-6 items-center justify-center">
                            {isRemoving ? (
                                <div className="flex h-full items-center justify-center">
                                    <LencoSpinner size="sm" />
                                </div>
                            ) : (
                                <div
                                    className="cursor-pointer text-black-tertiary duration-150 hover:text-error-tertiary"
                                    onClick={handleRemoveIconClick}
                                >
                                    <GoogleIcon icon="delete" size="sm" isOutlined />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {isSplitInflowScreen && form && form.ratio && (
                <div className="space-y-4">
                    {form.ratio.map((info, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <div>
                                <p className="text-sm text-black-secondary">
                                    {form.ratio[index].userAccountId !== account.id && form.ratio[index].userAccountId ? "Send" : "Keep"}{" "}
                                    {info.percentage}%{" "}
                                    {form.ratio[index].userAccountId !== account.id && form.ratio[index].userAccountId ? "to" : "in"}
                                </p>
                            </div>
                            <div className="rounded bg-grey-secondary px-2">
                                <p className="text-sm font-medium text-black">
                                    {
                                        filteredAccounts.find((acc) => acc.id === form.ratio[index].userAccountId || acc.id === account.id)
                                            ?.mainAccountShortName
                                    }{" "}
                                    {form.ratio[index].userAccountId === account.id
                                        ? ` (${account.lencoNameMin})`
                                        : `- ${accountsMap.get(info.userAccountId)?.lencoNameMin}`}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default AccountSettingsSummaryItem;
