import { useCallback, useState } from "react";

import { PhotoRequest } from "../../../../services/nigeria-application/registered/currentSignatory.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { photoUpload } from "../../../../services/nigeria-application/registered/currentSignatory.api";
import { updateCurrentSignatory } from "../../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

interface usePhotoUploadComponentInterface {
    // uploadPhotoResponse: PersonalDetailsResponse | null;
    isUploadPhotoLoading: boolean | null;
    handleUploadPhoto: (_data: PhotoRequest) => Promise<void>;
}

const usePhotoUploadComponent = (props?: () => void): usePhotoUploadComponentInterface => {
    const dispatch = useDispatch();

    const [isUploadPhotoLoading, setIsUploadPhotoLoading] = useState<boolean | null>(false);
    // const [uploadPhotoResponse, setUploadPhotoResponse] = useState<PersonalDetailsResponse | null>(null);

    const handleUploadPhoto = useCallback(async (_data: PhotoRequest) => {
        try {
            setIsUploadPhotoLoading(true);
            const res = await photoUpload(_data);
            if (res.signatory) {
                dispatch(updateCurrentSignatory(res));
            }
            dispatch(messageTrue({ message: "Photo uploaded successfully" }));
            props && props();
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsUploadPhotoLoading(false);
        }
    }, []);

    return {
        // uploadPhotoResponse,
        isUploadPhotoLoading,
        handleUploadPhoto,
    };
};
export default usePhotoUploadComponent;
