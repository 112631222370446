import AccountOpeningProgress from "../../../../../../models/account-opening/progress/accountOpeningProgress";
import BusinessDetailsMin from "../../../../../../models/account-opening/businessDetailsMin";
import CompanyDocument from "../../../../../../models/account-opening/companyDocument";
import IncorporationDocumentNigeria from "../../../../../../models/account-opening/incorporation-documents/incorporationDocumentNigeria";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import UtilityBill from "../../../../../../models/account-opening/utilityBill";
import { hintMarkItem } from "../../../components/element/hint/hint.constant";

export enum CompanyDocumentTypes {
    CERTIFICATE = "certificate",
    MEMORANDUM_OF_ASSOCIATION = "memorandumOfAssociation",
    // FORM_C02 = "formC02",
    // FORM_C07 = "formC07",
    INCORPORATION_DOCUMENT = "incorporationDocument",
    UTILITY_BILL = "utilityBill",
    SCUML_CERTIFICATE = "scumlCertificate",
}

export const AllCompanyDocuments = [
    CompanyDocumentTypes.CERTIFICATE,
    // CompanyDocumentTypes.FORM_C02,
    // CompanyDocumentTypes.FORM_C07,
    CompanyDocumentTypes.INCORPORATION_DOCUMENT,
    CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION,
    CompanyDocumentTypes.SCUML_CERTIFICATE,
    CompanyDocumentTypes.UTILITY_BILL,
];

export const LLCCompanyDocuments = [
    CompanyDocumentTypes.CERTIFICATE,
    CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION,
    // CompanyDocumentTypes.FORM_C07,
    // CompanyDocumentTypes.FORM_C02,
    CompanyDocumentTypes.UTILITY_BILL,
];

export const notLLCCompanyDocuments = [
    CompanyDocumentTypes.CERTIFICATE,
    CompanyDocumentTypes.INCORPORATION_DOCUMENT,
    CompanyDocumentTypes.UTILITY_BILL,
];

export type UploadCompanyDocumentForm = {
    certificate: Blob | null;
    memorandumOfAssociation: Blob | null;
    formC02: Blob | null;
    formC07: Blob | null;
    incorporationDocument: Blob | null;
    utilityBillType: number | null;
    utilityBill: Blob | null;
    scumlCertificate: Blob | null;
};

export const CompanyDocumentFieldTexts: {
    [type in CompanyDocumentTypes]: string;
} = {
    [CompanyDocumentTypes.CERTIFICATE]: "Certificate",
    // [CompanyDocumentTypes.FORM_C02]: "",
    // [CompanyDocumentTypes.FORM_C07]: "Status Report",
    // [CompanyDocumentTypes.INCORPORATION_DOCUMENT]: "Incorporation Document",
    [CompanyDocumentTypes.INCORPORATION_DOCUMENT]: "Status Report",
    [CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION]: "Memorandum and Articles of Association",
    [CompanyDocumentTypes.SCUML_CERTIFICATE]: "SCUML Certificate",
    [CompanyDocumentTypes.UTILITY_BILL]: "Utility Bill",
};
export const CompanyDocumentFieldSubTexts: {
    [type in CompanyDocumentTypes]: string;
} = {
    [CompanyDocumentTypes.CERTIFICATE]: "This is your company registration certificate issued by Corporate Affair Commission (CAC)",
    // [CompanyDocumentTypes.FORM_C02]: "This information about the shareholders of the company",
    // [CompanyDocumentTypes.FORM_C02]: "",
    // [CompanyDocumentTypes.FORM_C07]: "TBD jkhjlkhljkhlkjhjkhljklk",
    [CompanyDocumentTypes.INCORPORATION_DOCUMENT]: "This contains information about the share holders and directors",
    [CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION]: "This contains information about the business structure",
    [CompanyDocumentTypes.SCUML_CERTIFICATE]: "SCUML: Special Control Unit against Money Laundering",
    [CompanyDocumentTypes.UTILITY_BILL]: "Only utility bills less than 3 months are accepted",
};

export const CompanyDocumentTextArray = [
    // { formikName: "formC02", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.FORM_C02] },
    // { formikName: "formC07", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.FORM_C07] },
    { formikName: "certificate", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.CERTIFICATE] },
    { formikName: "utilityBill", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.UTILITY_BILL] },
    // {formikName: "utilityBillType", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.UTILITY_BILL_TYPE]},
    { formikName: "scumlCertificate", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.SCUML_CERTIFICATE] },
    { formikName: "incorporationDocument", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.INCORPORATION_DOCUMENT] },
    { formikName: "memorandumOfAssociation", text: CompanyDocumentFieldTexts[CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION] },
];

export const CompanyDocumentTextMap = CompanyDocumentTextArray.reduce((accumulator, obj) => {
    return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getCompanyDocumentHints(_formikList: string[], _uncompleted: string[], _exempted: string[]): hintMarkItem[] {
    let computedList: hintMarkItem[] = [];
    const _completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));

    for (const _string of _completed) {
        computedList.push({
            isChecked: true,
            text: CompanyDocumentTextMap.get(_string) || "",
        });
    }
    for (const _string of _uncompleted) {
        computedList = computedList.map((_) =>
            _.text === CompanyDocumentTextMap.get(_string)
                ? {
                      isChecked: false,
                      text: _.text,
                  }
                : _
        );
    }
    return computedList;
}

export interface CompanyDocumentResponse {
    companyDocument: CompanyDocument;
    progress: AccountOpeningProgress;
}

export type IncorporationDocumentRequest = {
    type: CompanyDocumentTypes;
    file: File;
};

export type DeleteIncorporationDocumentRequest = {
    type: CompanyDocumentTypes;
};

export type UtilityBillRequest = {
    utilityBillTypeId: number;
    file: File;
};

export type BusinessDocumentResponse = {
    applicationMin: BusinessDetailsMin;
    incorporationDocuments: IncorporationDocumentNigeria[];
    utilityBill: UtilityBill | null;
    progress: NewAccountOpeningProgress;
};
