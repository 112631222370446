import { BusinessContactForm, BusinessDetailsResponse, BusinessInformationForm, BusinessInformationResponse } from "./company.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../../helpers/request/abortControllers";

import { GenericObject } from "../../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../../utils/parsers";
import ZambiaBusinessDetails from "../../../../../../../models/account-opening/zambiaBusinessDetails";
import { makeRequestWithSignal } from "../../../../../../../helpers/request/makeRequest";

function parseResponse(res: GenericObject | Error): BusinessDetailsResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
        application: Parsers.classObjectNonNullable(res.application, ZambiaBusinessDetails),
    };
}

export enum ZambiaCompanyRequests {
    INIT = "zambia-business.init",
    BASIC_INFO = "zambia-business.basic-info",
    CONTACT_INFO = "zambia-business.contact-info",
}

export const businessDetails = async (): Promise<BusinessDetailsResponse> => {
    const signal = getAbortControllerSignal(ZambiaCompanyRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/zm/business/details", {}, signal);
    return parseResponse(res);
};

export const businessInformation = async (data: BusinessInformationForm): Promise<BusinessInformationResponse> => {
    const signal = getAbortControllerSignal(ZambiaCompanyRequests.BASIC_INFO);
    const res = await makeRequestWithSignal("/account-opening/zm/business/basic-info/update", data, signal);
    return parseResponse(res);
};

export const businessContact = async (data: BusinessContactForm): Promise<BusinessInformationResponse> => {
    const signal = getAbortControllerSignal(ZambiaCompanyRequests.CONTACT_INFO);
    const res = await makeRequestWithSignal("/account-opening/zm/business/contact-info/update", data, signal);
    return parseResponse(res);
};

export function abortZambiaCompanyRequest(type: ZambiaCompanyRequests): void {
    abortRequest(type);
}

export function abortAllZambiaCompanyRequests(): void {
    Object.values(ZambiaCompanyRequests).forEach((type) => abortRequest(type));
}
