import { setElectricityPurchaseOtp } from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseElectricityOtpInterface {
    handleOtpChange: (_amount: string) => void;
}

function useElectricityPurchaseOtp(): UseElectricityOtpInterface {
    const dispatch = useDispatch();

    const handleOtpChange = useCallback((_otp: string) => {
        dispatch(setElectricityPurchaseOtp(_otp));
    }, []);

    return { handleOtpChange };
}

export default useElectricityPurchaseOtp;
