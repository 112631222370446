import { useEffect, useState } from "react";

import ButtonComp from "../../../../button/ButtonComp";

interface Props {
    resendMins?: 1 | 3;
    handleResend: () => void;
}

function OtpTimer(props: Props) {
    const [minutes, setMinutes] = useState<number>(props.resendMins || 1);
    const [seconds, setSeconds] = useState<number>(0);

    useEffect(() => {
        const optTimerInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(optTimerInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => {
            clearInterval(optTimerInterval);
        };
    });

    return (
        <>
            {minutes === 0 && seconds === 0 ? (
                <ButtonComp color="black" buttonType="tertiary" onClick={props.handleResend}>
                    <span className="text-base text-black-tertiary">Resend OTP</span>
                </ButtonComp>
            ) : (
                <p className="cursor-not-allowed text-sm text-black-tertiary">
                    Resend OTP in
                    <span className="font-medium text-blue">
                        {" "}
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                </p>
            )}
        </>
    );
}

export default OtpTimer;
