import { useCallback, useState } from "react";

import UserAccountMeta from "../../../../../../models/userAccountMeta";
import { UserRole } from "../../../../../../models/userAccount.constants";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { updateTeamMemberRoleForAccount } from "../../account-settings/account-settings.api";
import { useDispatch } from "react-redux";

export interface UseTeamMemberRoleResponse {
    isSubmitting: boolean;
    handleUpdateRole: (accountMeta: UserAccountMeta, role: UserRole) => Promise<void>;
}

function useTeamMemberRole(updateAccountMeta: (accountMeta: UserAccountMeta) => void): UseTeamMemberRoleResponse {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleUpdateRole = useCallback(async (accountMeta: UserAccountMeta, role: UserRole) => {
        setIsSubmitting(true);

        try {
            const res = await updateTeamMemberRoleForAccount(accountMeta.id, accountMeta.userAccountId, role);
            updateAccountMeta(res);
            dispatch(messageTrue("Team Member Role Updated"));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsSubmitting(false);
        }
    }, []);

    return {
        isSubmitting,
        handleUpdateRole,
    };
}

export default useTeamMemberRole;
