import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import NextOfKin from "../../../../../../../../../../models/account-opening/nextOfKin";
import React from "react";
import { useSelector } from "react-redux";

interface Props {
    data: NextOfKin | null;
}

function BusinessNextOfKinReview(props: Props): JSX.Element {
    const accountOpening = useSelector((state: IRootState) => state.application.init);

    return (
        <>
            <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey px-4 py-5">
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Full Name</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data?.name || "-"}</span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Phone</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data?.phone || "-"}</span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Gender</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                        {props.data?.gender || "-"}
                        {accountOpening?.options.gender.find((_gender) => _gender.id === props.data?.gender)?.name || ""}
                    </span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Relationship</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                        {props.data?.relationship &&
                            !props.data?.otherRelationshipText &&
                            (accountOpening?.options.kinRelationship.find((_relationship) => _relationship.id === props.data?.relationship)?.name ||
                                "")}
                        {!props.data?.relationship && (props.data?.otherRelationshipText || "-")}
                    </span>
                </div>
                <div className="flex w-full flex-row items-start justify-between space-x-4">
                    <span className="w-52 text-black-tertiary">Address</span>
                    <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data?.address || "-"}</span>
                </div>
            </div>
        </>
    );
}

export default BusinessNextOfKinReview;
