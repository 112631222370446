import BillVendor from "../../../../../../../models/billVendor";
import BillVendorMin from "../../../../../../../models/billVendorMin";
import { resetDataPurchaseProductId, setDataPurchaseVendorId } from "../../../../../../../redux/payments/dataPurchase/slice/dataPurchaseSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseDataPurchaseVendorInterface {
    handleSelectVendor: (_vendor: BillVendor | BillVendorMin) => void;
    handleSelectVendorInstant: (_vendor: BillVendor | BillVendorMin) => void;
}

function useDataPurchaseVendor(): UseDataPurchaseVendorInterface {
    const dispatch = useDispatch();

    const handleSelectVendor = useCallback((_vendor: BillVendor | BillVendorMin) => {
        dispatch(setDataPurchaseVendorId(_vendor));
        dispatch(resetDataPurchaseProductId());
    }, []);
    const handleSelectVendorInstant = useCallback((_vendor: BillVendor | BillVendorMin) => {
        dispatch(setDataPurchaseVendorId(_vendor));
    }, []);

    return { handleSelectVendor, handleSelectVendorInstant };
}

export default useDataPurchaseVendor;
