import { useCallback, useState } from "react";

import { BillPurchaseInitRequest } from "../../../../services/billPayment/billPaymentApi.types";
import { billPurchaseInit } from "../../../../services/billPayment/billPaymentApi";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { setDataPurchaseResponse } from "../../../../../../../redux/payments/dataPurchase/slice/dataPurchaseSlice";
import { useDispatch } from "react-redux";

interface UseDataPurchaseInterface {
    isDataPurchaseLoading: boolean;
    dataPurchaseError: boolean;
    handleDataPurchase: (_data: BillPurchaseInitRequest) => Promise<void>;
}

function useDataPurchase(): UseDataPurchaseInterface {
    const dispatch = useDispatch();
    const [isDataPurchaseLoading, setIsDataPurchaseLoading] = useState(false);
    const [dataPurchaseError, setDataPurchaseError] = useState<boolean>(false);

    const handleDataPurchase = useCallback(
        async (_data: BillPurchaseInitRequest) => {
            try {
                setIsDataPurchaseLoading(true);
                setDataPurchaseError(false);
                const res = await billPurchaseInit(_data);
                dispatch(setDataPurchaseResponse(res));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDataPurchaseError(true);
            } finally {
                setIsDataPurchaseLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isDataPurchaseLoading,
        dataPurchaseError,
        handleDataPurchase,
    };
}

export default useDataPurchase;
