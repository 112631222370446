import React, { useEffect, useState } from "react";

import Accordion from "../../../../../../../../../components/accordion";
import AgreementSignatureComponent from "./AgreementSignatureComponent";
import { AgreementType } from "../../../../../../services/nigeria-application/registered/new/agreement.types";
import GoogleIcon from "../../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../../redux/rootReducer";
import PreviewModal from "../../../../../modals/preview-modal";
import UploadedCard from "../../../../../cards/UploadedCard";
import joinArray from "../../../../../../../../../utils/joinArray";
import titleCase from "../../../../../../../../../hooks/titleCase";
import usePreviewBusinessAgreement from "../../../../../../Hooks/nigeria-application/registered-business/agreement/usePreviewBusinessAgreement";
import { useSelector } from "react-redux";

interface Props {
    isOpen: boolean;
    isExternalLink?: boolean;

    toggle: () => void;
}

function AccountOpeningDeclarationComponent(props: Props): JSX.Element {
    const { previewBlob, handlePreviewDocument } = usePreviewBusinessAgreement({
        downloadedDocumentName: "Opening Declaration Document",
        onComplete: () => setShowPreviewModal(true),
    });

    const isCreator = useSelector((state: IRootState) => state.application.init?.isCreator);
    const directors = useSelector((state: IRootState) => state.application.agreement?.directors);
    const createdBy = useSelector((state: IRootState) => state.application.agreement?.createdBy.name);
    const currentUser = useSelector((state: IRootState) => state.application.agreement?.currentUser);
    const declaration = useSelector((state: IRootState) => state.application.agreement?.agreements.declaration);

    const [isSigned, setIsSigned] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    const isUploaded = !!(declaration?.signedDocumentId && declaration?.signedBy.length < 1) || false;
    const listOfDeclarationSignedDirectors = declaration?.signedBy || [];
    const listOfDeclarationUnsignedDirectors = directors?.filter((f) => !declaration?.signedBy.some((_el) => _el.id === f.id)) || [];

    useEffect(() => {
        setIsSigned(!!(declaration?.signedBy.some((_el) => _el.userId === currentUser?.id) || declaration?.signedDocumentId) || false);
    }, [props.isOpen, declaration]);

    return (
        <>
            <div className="w-full">
                <PreviewModal
                    active={showPreviewModal}
                    toggler={() => setShowPreviewModal(false)}
                    data={previewBlob}
                    title="Account Opening Declaration Preview"
                />
                <Accordion
                    header="Account Opening Declaration"
                    checkActive={!!declaration?.signedDocumentId}
                    isOpened={props.isOpen}
                    toggle={props.toggle}
                    hasCheck
                >
                    <div className="relative flex w-full flex-col items-start justify-start space-y-6">
                        <div className="ml-[25px] flex flex-col">
                            <div className="text-xs text-black-tertiary lg:text-sm">
                                {/* if only one person has uploaded */}
                                {declaration?.signedDocumentId && listOfDeclarationSignedDirectors.length < 1 && (
                                    <div className="flex flex-row items-center justify-start space-x-3 text-success">
                                        <GoogleIcon icon="check" />
                                        <span>Uploaded by: {titleCase(createdBy || "")}</span>
                                    </div>
                                )}
                                {/* if it has been signed regardless of who completed it so far at least one person did */}
                                {declaration?.signedDocumentId &&
                                    listOfDeclarationSignedDirectors.length > 0 &&
                                    listOfDeclarationSignedDirectors.map((_signedSig, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-3 text-success" key={index}>
                                            <GoogleIcon icon="check" />
                                            <span>Signed by: {titleCase(_signedSig.name)}</span>
                                        </div>
                                    ))}

                                {/* if one person has signed but document has not been completed */}
                                {!declaration?.signedDocumentId &&
                                    listOfDeclarationSignedDirectors.map((_signedSig, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-3 text-success" key={index}>
                                            <GoogleIcon icon="check" />
                                            <span>Signed by: {titleCase(_signedSig.name)}</span>
                                        </div>
                                    ))}

                                {/* if one and no signatory */}
                                {!declaration?.signedDocumentId &&
                                    // listOfDeclarationSignedDirectors.length < 1 &&
                                    listOfDeclarationUnsignedDirectors.length === 1 &&
                                    listOfDeclarationUnsignedDirectors.map((_unsignedDirector, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-3" key={index}>
                                            <GoogleIcon icon="check" className="text-black-quin" size="lg" />
                                            <span>
                                                Expecting{" "}
                                                <span className="font-medium capitalize text-black-secondary">
                                                    {titleCase(_unsignedDirector.name)}
                                                </span>{" "}
                                                to sign
                                            </span>
                                        </div>
                                    ))}
                                {/* if two and no signatory */}
                                {!declaration?.signedDocumentId &&
                                    listOfDeclarationSignedDirectors.length < 1 &&
                                    listOfDeclarationUnsignedDirectors.length === 2 &&
                                    listOfDeclarationUnsignedDirectors.map((_unsignedDirector, index) => (
                                        <div className="flex flex-row items-center justify-start space-x-3" key={index}>
                                            <GoogleIcon icon="check" className="text-black-quin" size="lg" />
                                            <span>
                                                Expecting{" "}
                                                <span className="font-medium capitalize text-black-secondary">
                                                    {titleCase(_unsignedDirector.name)}
                                                </span>{" "}
                                                to sign
                                            </span>
                                        </div>
                                    ))}
                                {/* if one has singed and two are left */}
                                {!declaration?.signedDocumentId &&
                                    listOfDeclarationSignedDirectors.length === 1 &&
                                    listOfDeclarationUnsignedDirectors.length === 2 && (
                                        <div className="flex flex-row items-center justify-start space-x-3">
                                            <GoogleIcon icon="check" className="text-black-quin" size="lg" />
                                            <p className="text-xs text-black-tertiary lg:text-sm">
                                                Expecting{" "}
                                                <span className="font-medium capitalize text-black-secondary">
                                                    {listOfDeclarationUnsignedDirectors.length > 1 ? "one of" : ""}{" "}
                                                    {titleCase(joinArray(listOfDeclarationUnsignedDirectors.map((el) => el.name) || []))}
                                                </span>{" "}
                                                to sign
                                            </p>
                                        </div>
                                    )}
                                {/* if three and no signatory */}
                                {!declaration?.signedDocumentId &&
                                    listOfDeclarationSignedDirectors.length < 1 &&
                                    listOfDeclarationUnsignedDirectors.length === 3 && (
                                        <div className="flex flex-row items-center justify-start space-x-3">
                                            <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                            <p className="text-xs text-black-tertiary lg:text-sm">
                                                Expecting{" "}
                                                <span className="font-medium capitalize text-black-secondary">
                                                    {listOfDeclarationUnsignedDirectors.length > 1 ? "two of" : ""}{" "}
                                                    {titleCase(joinArray(listOfDeclarationUnsignedDirectors.map((el) => el.name) || []))}
                                                </span>{" "}
                                                to sign
                                            </p>
                                        </div>
                                    )}
                                {/* if the directors are greater than 1 then two directors should
                                sign but if its just one director, only one should sign */}
                                {!declaration?.signedDocumentId &&
                                    listOfDeclarationSignedDirectors.length < 1 &&
                                    listOfDeclarationUnsignedDirectors.length > 0 && (
                                        <>
                                            <div className="flex flex-row items-center justify-start space-x-3">
                                                <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                                <p className="text-xs text-black-tertiary lg:text-sm">
                                                    Or{" "}
                                                    <span className="font-medium capitalize text-black-secondary">{titleCase(createdBy || "")}</span>{" "}
                                                    to upload the signed document
                                                </p>
                                            </div>
                                        </>
                                    )}
                                {!declaration?.signedDocumentId &&
                                    listOfDeclarationSignedDirectors.length < 1 &&
                                    listOfDeclarationUnsignedDirectors.length < 1 && (
                                        <>
                                            <div className="flex flex-row items-center justify-start space-x-3">
                                                <GoogleIcon icon="check" className="text-black-quin" size="lg" />

                                                <p className="text-xs text-black-tertiary lg:text-sm">
                                                    {/* <span className="font-medium text-black-secondary capitalize">{createdBy || ""}</span>  */}
                                                    Invite a <span className="font-medium capitalize text-black-secondary">Director</span> to sign or
                                                    upload the signed document
                                                </p>
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>
                        <div className="relative w-full">
                            {!isSigned && (
                                <AgreementSignatureComponent
                                    type={AgreementType.ACCOUNT_OPENING_DECLARATION}
                                    isCreator={!!isCreator}
                                    onComplete={() => setIsSigned(true)}
                                    canAppendSignature={!!currentUser?.hasSignature}
                                />
                            )}
                            {isSigned && (
                                <UploadedCard
                                    text={isUploaded ? "Document Uploaded" : "Document Signed"}
                                    primaryButtonText="Sign again"
                                    handlePreviewDocumentFunc={() => void handlePreviewDocument(AgreementType.ACCOUNT_OPENING_DECLARATION)}
                                    handleRedo={() => setIsSigned(false)}
                                    isNoPrimaryButton={isUploaded}
                                />
                            )}
                        </div>
                    </div>
                </Accordion>
            </div>
        </>
    );
}

export default AccountOpeningDeclarationComponent;
