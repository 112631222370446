import ButtonComp from "../../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../../components/message-toast/sub/error-toast";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
// import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import React from "react";

interface Props {
    active: boolean;
    error: string;
    header: string;
    body: React.ReactNode;
    deleteButtonText: string;
    isSubmitLoading: boolean;

    toggler: () => void;
    onSubmit: () => void;
}

function ApplicationDeleteModal(props: Props): JSX.Element {
    return (
        <Modal size="md" active={props.active} toggler={props.toggler}>
            <ModalHeader onClose={props.toggler}>{props.header}</ModalHeader>
            <form
                className="w-full"
                onSubmit={(e) => {
                    e.preventDefault();
                    props.onSubmit();
                }}
            >
                <ModalBody>
                    <div className="flex w-full flex-col">
                        {props.error && (
                            <div className="w-full pb-2">
                                <ErrorToast error={props.error} />
                            </div>
                        )}
                        {/* : (
							<div className="pb-2 w-full">
								<MessageToasts message={"This action cannot be undone"} type="error" />
							</div>
						)} */}
                        <div className="flex w-full flex-col items-start justify-start break-words text-sm font-normal text-black-secondary">
                            {props.body}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.toggler}>
                        <span>Cancel</span>
                    </ButtonComp>
                    <ButtonComp type="submit" ripple="light" buttonType="primary" color="red" isLoading={props.isSubmitLoading}>
                        <span>{props.deleteButtonText}</span>
                    </ButtonComp>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default ApplicationDeleteModal;
