import { CardSpendLimitType } from "./card.constants";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";
import { TextType } from "../components/Table/Type/TextType";

export default class CardSpend {
    [immerable] = true;

    constructor(
        public type: CardSpendLimitType,
        public limit: string,
        public spent: string
    ) {}

    static create(obj: GenericObject): CardSpend {
        return new CardSpend(Parsers.number(obj.type), Parsers.string(obj.limit), Parsers.string(obj.spent));
    }

    get textType(): TextType {
        if (this.type === CardSpendLimitType.DAILY_ACCUMULATIVE) return TextType.DAILY_ACCUMULATIVE_CARD_PROGRESS;
        if (this.type === CardSpendLimitType.WEEKLY_ACCUMULATIVE) return TextType.WEEKLY_ACCUMULATIVE_CARD_PROGRESS;
        if (this.type === CardSpendLimitType.MONTHLY_ACCUMULATIVE) return TextType.MONTHLY_ACCUMULATIVE_CARD_PROGRESS;
        return TextType.SINGLE_PAYMENT_CARD_PROGRESS;
    }
}
