import * as Yup from "yup";

import { Form, Formik } from "formik";

import ButtonComp from "../../../../components/button/ButtonComp";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import FormInput from "../../../../components/inputs/FormInput";
import LencoSpinner from "../../../../components/spinner";
import { PageTitle } from "../../../../helpers/AppConstants";
import ReCAPTCHA from "react-google-recaptcha";
import VerifyEmail from "../componentss/Stages/VerifyEmail";
import useForgotPasswordState from "../hookss/State/useForgotPasswordState";

const INITIAL_FORM_STATE = {
    username: "",
};
const FORM_VALIDATION = Yup.object().shape({
    username: Yup.string().required("Required"),
});

function ForgotPassword(): JSX.Element {
    const {
        reCaptchaRef,
        captchaToken,
        isCaptchaOpen,
        forgotPasswordError,
        forgotPasswordMessage,
        isResetPasswordLoading,

        handleToken,
        handleExpire,
        handleOpenCaptcha,
        handleForgotPassword,
    } = useForgotPasswordState();

    document.title = PageTitle.FORGOT_PASSWORD_PAGE;

    return (
        <>
            <div className="flex w-full flex-col items-center justify-center">
                {forgotPasswordMessage && <VerifyEmail infoTitle="Check your email." message={forgotPasswordMessage || ""} forgotPassword />}
                {!forgotPasswordMessage && (
                    <>
                        {forgotPasswordError && (
                            <div className="absolute top-0 -mt-8 flex items-center justify-center pb-4 lg:-mt-16">
                                <ErrorToast error={forgotPasswordError || ""} />
                            </div>
                        )}
                        <div className="relative flex w-full max-w-[476px] flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                            <div className="flex w-full flex-col items-center justify-start space-y-4">
                                <h3 className="break-words text-center text-2xl font-medium text-black">Forgot your password</h3>
                                <div className="max-w-70% break-words text-center text-sm font-normal text-black-secondary">
                                    A password reset link will be sent to the email associated with your account.
                                </div>
                            </div>

                            <Formik
                                initialValues={{
                                    ...INITIAL_FORM_STATE,
                                }}
                                validationSchema={FORM_VALIDATION}
                                onSubmit={(values) => {
                                    handleForgotPassword(values);
                                }}
                                enableReinitialize
                                validateOnMount
                            >
                                {(formik) => {
                                    return (
                                        <Form className="w-full max-w-lg" tabIndex={-1}>
                                            {isCaptchaOpen && (
                                                <div className="flex w-full flex-col justify-center space-y-8">
                                                    <div className="flex flex-col items-center justify-center text-center">
                                                        <div className="pointer-events-none absolute left-0 top-2 z-0 flex h-full w-full items-center justify-center">
                                                            <LencoSpinner />
                                                        </div>
                                                        <div className="relative z-10 h-20">
                                                            <ReCAPTCHA
                                                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                                                                onChange={handleToken}
                                                                onExpired={handleExpire}
                                                                ref={reCaptchaRef}
                                                            />
                                                        </div>
                                                    </div>
                                                    <ButtonComp
                                                        size="xl"
                                                        type="submit"
                                                        color="black"
                                                        buttonType="primary"
                                                        isLoading={isResetPasswordLoading}
                                                        disable={process.env.NODE_ENV === "development" ? false : !captchaToken}
                                                        fullWidth
                                                    >
                                                        Reset Password
                                                    </ButtonComp>
                                                </div>
                                            )}
                                            {!isCaptchaOpen && (
                                                <div className="flex w-full flex-col justify-center space-y-8">
                                                    <FormInput type="text" name="username" placeholder="Username or Email Address" />
                                                    <ButtonComp
                                                        size="xl"
                                                        color="black"
                                                        buttonType="primary"
                                                        disable={formik.values.username.length < 1}
                                                        func={handleOpenCaptcha}
                                                        fullWidth
                                                    >
                                                        Continue
                                                    </ButtonComp>
                                                </div>
                                            )}
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default ForgotPassword;
