import { BusinessTypeText, BusinessTypes } from "../service/business-type.constant";

import ActiveRegBusiness from "../../../../assets/images/general/active-reg.svg";
import ActiveUnRegBusiness from "../../../../assets/images/general/active-un-reg.svg";
import BusinessTypeCard from "../components/business-type-card";
import ButtonComp from "../../../../components/button/ButtonComp";
import InActiveRegBusiness from "../../../../assets/images/general/inactive-reg.svg";
import InActiveUnRegBusiness from "../../../../assets/images/general/inactive-un-reg.svg";
import { LencoActiveCountries } from "../../../../redux/init/slice/initSlice.types";
import { Link } from "react-router-dom";
import LoginFooter from "../../../../components/layouts/components/landing/login-footer";
import Parsers from "../../../../utils/parsers";
import { useAppSelector } from "../../../../redux/hooks";
import { useState } from "react";

function BusinessType(): JSX.Element {
    const inviteURL = useAppSelector((state) => state.landing.inviteURL);

    const queryParams = new URLSearchParams(location.search);
    const countryParam = queryParams.get("country");
    const selectedCountry: LencoActiveCountries | undefined = countryParam
        ? Parsers.nullableEnum(countryParam, LencoActiveCountries) || undefined
        : undefined;
    const isCountryZambia = selectedCountry === LencoActiveCountries.ZAMBIA || false;
    const [selectedBusiness, setSelectedBusiness] = useState<BusinessTypes | null>(null);

    return (
        <>
            <div className="flex w-full flex-col items-center justify-center space-y-12 px-4 pt-12 3xs:px-8">
                <div className="relative flex w-full max-w-lg flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                    <div className="flex w-full max-w-lg flex-col items-center justify-center space-y-8">
                        <div className="flex w-full flex-col items-center justify-start space-y-1">
                            <h3 className="break-words text-center text-2xl font-medium text-black">Get Started</h3>
                            <div className="break-words text-center text-sm font-normal text-black-tertiary">Tell us about your business</div>
                        </div>
                        <div className="flex w-full flex-col items-center justify-start space-y-4">
                            <BusinessTypeCard
                                icon={<img src={InActiveRegBusiness} alt="Registered Business Icon" />}
                                hoverIcon={<img src={ActiveRegBusiness} alt="Registered Business Icon" />}
                                header="Registered Business"
                                onClick={() => setSelectedBusiness(BusinessTypes.REGISTERED)}
                                isActive={selectedBusiness === BusinessTypes.REGISTERED}
                                isLoading={false}
                                subtitleOne={
                                    BusinessTypeText[isCountryZambia ? LencoActiveCountries.ZAMBIA : LencoActiveCountries.NIGERIA].registered.Subtitle
                                }
                            />
                            <BusinessTypeCard
                                icon={<img src={InActiveUnRegBusiness} alt="UnRegistered Business Icon" />}
                                hoverIcon={<img src={ActiveUnRegBusiness} alt="UnRegistered Business Icon" />}
                                header="Unregistered Business"
                                onClick={() => setSelectedBusiness(BusinessTypes.UNREGISTERED)}
                                isLoading={false}
                                isActive={selectedBusiness === BusinessTypes.UNREGISTERED}
                                subtitleOne={
                                    BusinessTypeText[isCountryZambia ? LencoActiveCountries.ZAMBIA : LencoActiveCountries.NIGERIA].unregistered
                                        .Subtitle
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <Link
                            className={"w-full " + `${!selectedBusiness ? "pointer-events-none" : ""}`}
                            to={{ pathname: `/signup/account${inviteURL || ""}`, search: `${location.search}&type=${selectedBusiness || ""}` }}
                        >
                            <ButtonComp size="xl" color="black" buttonType="primary" disable={!selectedBusiness} fullWidth isText>
                                Continue
                            </ButtonComp>
                        </Link>
                    </div>
                </div>
            </div>
            <LoginFooter isLogin />
        </>
    );
}
export default BusinessType;
