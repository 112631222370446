import { addToActiveOpenReassignCardForms, removeFromActiveOpenedReassignCardForms, updateCard } from "../../../../../redux/cards/slice/cardsSlice";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../../../../models/card";
import { IRootState } from "../../../../../redux/rootReducer";
import { ReassignCardDTO } from "../../Services/cardsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { mainInitAddCard } from "../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { reassignCardRequest } from "../../Services/cardsApi";

interface UseCardAssign {
    isReassignCardError: string | null;
    sisReassignCardDone: boolean;
    activeOpenedReassignCardForms: string[];
    isIsReassignCardLoading: boolean;
    onEditAssignToCardForm: (requestId: string) => void;
    onCloseEditAssignCardToForm: (requestId: string) => void;
    openReAssignCardForm: boolean;
    handleReassignCard: (input: ReassignCardDTO) => void;
    handleReset: () => void;
}

const useCardAssign = (): UseCardAssign => {
    const dispatch = useDispatch();
    const { openReAssignCardForm, activeOpenedReassignCardForms } = useSelector((state: IRootState) => state.cards);

    const [isReassignCardError, setIsReassignCardError] = useState<string | null>(null);
    const [sisReassignCardDone, setIsReassignCardDone] = useState<boolean>(false);
    const [isIsReassignCardLoading, setIsIsReassignCardLoading] = useState<boolean>(false);

    const onEditAssignToCardForm = useCallback((requestId: string) => {
        dispatch(addToActiveOpenReassignCardForms(requestId));
    }, []);

    const onCloseEditAssignCardToForm = useCallback((requestId: string) => {
        dispatch(removeFromActiveOpenedReassignCardForms(requestId));
    }, []);

    const handleReassignCard = useCallback(
        async (_data: ReassignCardDTO) => {
            try {
                setIsReassignCardDone(false);
                setIsReassignCardError(null);
                setIsIsReassignCardLoading(true);
                const res = await reassignCardRequest(_data);
                setIsReassignCardDone(true);
                dispatch(mainInitAddCard(res.cardData.card as Card));
                dispatch(updateCard(res.cardData));
                onCloseEditAssignCardToForm(_data?.createRequestId);
                dispatch(messageTrue({ message: "Card Reassigned Successfully" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setIsReassignCardError(errorMessage);
            } finally {
                setIsIsReassignCardLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsReassignCardDone(false);
        setIsReassignCardError(null);
        setIsIsReassignCardLoading(false);
    }, []);

    return {
        isReassignCardError,
        sisReassignCardDone,
        isIsReassignCardLoading,
        openReAssignCardForm,
        handleReassignCard,
        handleReset,
        onEditAssignToCardForm,
        onCloseEditAssignCardToForm,
        activeOpenedReassignCardForms,
    };
};

export default useCardAssign;
