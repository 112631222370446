import BillVendor from "../../../../../models/billVendor";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import { useCallback } from "react";

interface Props {
    data: BillVendor;
    onSelect: (e: BillVendor) => void;
}

function VendorItem({ data, onSelect }: Props): JSX.Element {
    const handleSelect = useCallback(() => {
        onSelect(data);
    }, [data]);

    return (
        <NewDropdownItem size="2xl" onClick={handleSelect}>
            <div className="flex w-full items-center justify-start space-x-4">
                <div>
                    <div className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border border-grey-secondary bg-white p-2">
                        <img className="h-full w-full rounded-full object-cover" src={data.logoUrl as string} alt={data.name + "ISP"} />
                    </div>
                </div>
                <div className="flex h-full w-full flex-col items-start justify-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <span className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-sm">{data.name}</span>
                </div>
            </div>
        </NewDropdownItem>
    );
}

export default VendorItem;
