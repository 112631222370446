import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useManageCardAccess from "../../Hooks/State/useManageCardAccess";
import ModalBody from "../../../../../components/modal/modal-body";
import Modal from "../../../../../components/modal/Modal";
import ModalHeader from "../../../../../components/modal/modal-header";
import ModalFooter from "../../../../../components/modal/modal-footer";
import MultiSelectDropdown from "../../../../../components/dropdown/multi-select-dropdown";
import LencoButton from "../../../../../components/button/button";
import TeamAccessAdminButton from "../../Elements/TeamAccessAdminButton";
import SingleSelectDropdown from "../../../settings-new/Components/DropDowns/TeamMembers/SingleSelectDropdown";
import { LencoCardAccessLevel, LencoCardAccessLevels, LencoCardAccessLevelText } from "../../Types";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import MessageToast from "../../../../../components/message-toast";
import { Link } from "react-router-dom";
import User from "../../../../../models/user";
import { useAppSelector } from "../../../../../redux/hooks";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

interface ManageCardProps {
    active: boolean;
    toggler: () => void;
}

type CardAccessForm = {
    memberIds: string[];
    accessLevel: LencoCardAccessLevel | null;
};

enum CardAccessStage {
    RESET = 0,
    LIST_OF_TEAM_MEMBERS = 1,
    CREATE_ACCESS = 2,
    DONE = 3,
}

function removeDuplicateIds(list: User[]) {
    const uniqueIds = new Set();
    const filteredList: User[] = [];

    list?.forEach((item) => {
        if (!uniqueIds.has(item?.id)) {
            uniqueIds.add(item?.id);
            filteredList.push(item);
        }
    });

    return filteredList;
}

function CardAccessModal(props: ManageCardProps): JSX.Element {
    const dispatch = useDispatch();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<User | null>(null);
    const currentUser = useAppSelector((state) => state?.init.main?.companyDetails.user);
    const [teamMembersWithAccess, setTeamMembersWithAccess] = useState<User[]>([]);
    const {
        isManageCardAccessDone,
        isManageCardAccessLoading,
        isRemoveViewerAccessLoading,
        isRemoveManageAccessLoading,
        handleReset,
        handleManageCardAccess,
        handleAddViewCardAccess,
        handleRemoveManageAccess,
        handleRemoveViewerAccess,
    } = useManageCardAccess();
    const usersList = useAppSelector((state) => state.cards.users);
    const { teamMembersWithManageAccess, teamMembersWithViewAccess } = useAppSelector((state) => state.cards);

    const [stage, setStage] = useState<CardAccessStage>(CardAccessStage.LIST_OF_TEAM_MEMBERS);
    const formikRef = useRef<FormikProps<CardAccessForm> | null>(null);
    const InitialCardAccessState = {
        memberIds: [],
        accessLevel: null,
    };

    const CardAccessFormValidation = Yup.object().shape({
        memberIds: Yup.array().of(Yup.string().required("Required")).required("Required"),
        accessLevel: Yup.mixed<LencoCardAccessLevel>().oneOf(Object.values(LencoCardAccessLevel)).required(),
    });
    const handleMemberAccess = useCallback(async (values: { memberIds: string[]; accessLevel: LencoCardAccessLevel | null }) => {
        if (!currentUser?.hasManageCardAccess) {
            dispatch(messageTrue({ message: "You don't have permission to add User Access" }));
            return handleBack?.();
        }
        if (values.accessLevel === LencoCardAccessLevel.MANAGER) {
            await handleManageCardAccess({ memberIds: values.memberIds });
            return handleBack?.();
        }
        if (values.accessLevel === LencoCardAccessLevel.VIEWER) {
            await handleAddViewCardAccess({ memberIds: values.memberIds });
            return handleBack?.();
        }
    }, []);

    useEffect(() => {
        if (!isManageCardAccessDone) return;
        handleReset();
        formikRef.current?.resetForm();
        setStage(CardAccessStage.LIST_OF_TEAM_MEMBERS);
    }, [formikRef.current, isManageCardAccessDone, handleReset]);

    const handleCloseModal = useCallback(() => {
        handleReset();
        props.toggler();
        formikRef?.current?.resetForm();
        setStage(CardAccessStage.LIST_OF_TEAM_MEMBERS);
    }, [formikRef?.current, props.toggler, handleReset]);

    const handleBack = useCallback(() => {
        setStage(CardAccessStage.LIST_OF_TEAM_MEMBERS);
        formikRef?.current?.resetForm();
    }, []);

    const handleRemoveMemberAccess = useCallback(async (user?: User | null): Promise<void> => {
        if (!currentUser?.hasManageCardAccess) {
            dispatch(messageTrue({ message: "You don't have permission to remove User Access" }));
            return handleBack?.();
        }
        if (user?.hasManageCardAccess) {
            await handleRemoveManageAccess({ memberId: user.id });
            await handleRemoveViewerAccess({ memberId: user.id });
            return handleBack?.();
        } else {
            await handleRemoveViewerAccess({ memberId: user?.id || "" });
            return handleBack?.();
        }
    }, []);

    const onSelectAddNewMember = useCallback(() => {
        setStage(CardAccessStage.CREATE_ACCESS);
    }, []);

    const initTeamMemberWithAccess = useCallback(() => {
        if (!removeDuplicateIds(teamMembersWithManageAccess)?.length && !removeDuplicateIds(teamMembersWithViewAccess)?.length)
            return setTeamMembersWithAccess([currentUser as User]?.filter((_) => !!_));
        setTeamMembersWithAccess(removeDuplicateIds([...teamMembersWithManageAccess, ...teamMembersWithViewAccess]));
    }, [teamMembersWithManageAccess, teamMembersWithViewAccess, currentUser]);

    useEffect(() => {
        initTeamMemberWithAccess();
        return () => {};
    }, [teamMembersWithManageAccess, teamMembersWithViewAccess, currentUser]);

    useEffect(() => {
        if (props.active) {
            setStage(CardAccessStage.LIST_OF_TEAM_MEMBERS);
            formikRef?.current?.resetForm();
        }
        return () => {};
    }, [props.active]);

    return (
        <>
            <ConfirmDeleteModal
                isActive={!!showConfirmDeleteModal}
                isLoading={isRemoveViewerAccessLoading || isRemoveManageAccessLoading}
                onDelete={async () => {
                    await handleRemoveMemberAccess(showConfirmDeleteModal);
                    setShowConfirmDeleteModal(null);
                }}
                title="Remove Access"
                subText={<span>Are you sure you want to remove {showConfirmDeleteModal?.name}&apos;s access? </span>}
                toggler={() => setShowConfirmDeleteModal(null)}
            />
            <Modal size="md" active={props.active && !showConfirmDeleteModal} toggler={handleCloseModal}>
                <ModalHeader subTitle="Add who can create and assign cards to other team members" onClose={handleCloseModal}>
                    Manage Card Access
                </ModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={InitialCardAccessState}
                    validationSchema={CardAccessFormValidation}
                    onSubmit={handleMemberAccess}
                    enableReinitialize
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <Form className="w-full">
                                <ModalBody>
                                    {stage === CardAccessStage.LIST_OF_TEAM_MEMBERS && (
                                        <div className="flex w-full flex-col items-center justify-start space-y-4">
                                            {teamMembersWithAccess?.map((teamMember, idx) => (
                                                <TeamAccessAdminButton
                                                    key={idx}
                                                    name={teamMember.name}
                                                    canManage={
                                                        teamMember.id !== currentUser?.id && !teamMember.isAdmin && currentUser?.hasManageCardAccess
                                                    }
                                                    isManager={teamMember.hasManageCardAccess}
                                                    isAdmin={teamMember.isAdmin}
                                                    onDelete={() => {
                                                        setShowConfirmDeleteModal(teamMember);
                                                    }}
                                                />
                                            ))}

                                            {!usersList?.filter?.((_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id))
                                                ?.length && (
                                                <MessageToast
                                                    message={
                                                        <span>
                                                            Add more users to your team under{" "}
                                                            <Link className="font-medium underline" to={"/settings/team-members"}>
                                                                settings
                                                            </Link>
                                                        </span>
                                                    }
                                                    className="mx-auto w-max lg:w-full"
                                                    type="info"
                                                    fullWidth={true}
                                                    size="sm"
                                                />
                                            )}
                                        </div>
                                    )}
                                    {stage === CardAccessStage.CREATE_ACCESS && (
                                        <div className="flex w-full flex-col space-y-4">
                                            <MultiSelectDropdown
                                                placeholder="Team Member"
                                                value={formik.values.memberIds}
                                                options={usersList
                                                    .filter((_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id))
                                                    .map((el) => {
                                                        return {
                                                            text: el.name,
                                                            value: el.id,
                                                        };
                                                    })}
                                                onChange={(value) => {
                                                    formik.getFieldHelpers("memberIds").setValue(value);
                                                }}
                                                isDisabled={usersList.length < 2}
                                                active={props.active}
                                            />
                                            <SingleSelectDropdown
                                                placeholder="Access level"
                                                value={formik.values.accessLevel}
                                                options={LencoCardAccessLevels.map((_) => ({
                                                    text: LencoCardAccessLevelText[_],
                                                    value: _,
                                                }))}
                                                onChange={(value) => void formik.getFieldHelpers("accessLevel").setValue(value || null)}
                                                bigDropdown
                                                isDisabled={!!(formik.values.memberIds.length < 1)}
                                                size="lg"
                                            />
                                        </div>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                                        {stage === CardAccessStage.LIST_OF_TEAM_MEMBERS && (
                                            <>
                                                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                                    <LencoButton
                                                        onClick={onSelectAddNewMember}
                                                        type="button"
                                                        color="primary"
                                                        size="sm"
                                                        variant="bordered"
                                                        isText
                                                        isDisabled={
                                                            isManageCardAccessLoading ||
                                                            !usersList?.filter?.(
                                                                (_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id)
                                                            )?.length
                                                        }
                                                    >
                                                        <span className="normal-case tracking-wider">Add Another</span>
                                                    </LencoButton>
                                                </div>
                                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                    <LencoButton
                                                        onClick={handleCloseModal}
                                                        type="button"
                                                        color="primary"
                                                        size="sm"
                                                        variant="solid"
                                                        isText
                                                    >
                                                        <span className="normal-case tracking-wider">Done</span>
                                                    </LencoButton>
                                                </div>
                                            </>
                                        )}
                                        {stage === CardAccessStage.CREATE_ACCESS && (
                                            <>
                                                <>
                                                    <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                                        <LencoButton
                                                            onClick={handleBack}
                                                            type="button"
                                                            color="primary"
                                                            size="sm"
                                                            variant="bordered"
                                                            isText
                                                        >
                                                            <span className="normal-case tracking-wider">Back</span>
                                                        </LencoButton>
                                                    </div>
                                                    <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                        <LencoButton
                                                            type="submit"
                                                            color="primary"
                                                            size="sm"
                                                            variant="solid"
                                                            isText
                                                            isLoading={isManageCardAccessLoading}
                                                            isDisabled={!!(formik.values.memberIds.length < 1) || !formik.values.accessLevel}
                                                        >
                                                            <span className="normal-case tracking-wider">Grant Access</span>
                                                        </LencoButton>
                                                    </div>
                                                </>
                                            </>
                                        )}
                                    </div>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}

export default CardAccessModal;
