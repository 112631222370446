import { useEffect, useState } from "react";

import AllAccountDetails from "./components/all-account-details";
import AllAccountItem from "./components/all-account-item";
import BankAccount from "../../models/bankAccount";
import Card from "../../models/card";
import CustomerAccount from "../../models/customerAccount";
import NewDropdownHead from "../new-dropdown";
import NewTransactionIcon from "../new-transaction-icon";
import UserAccount from "../../models/userAccount";
import { useAppSelector } from "../../redux/hooks";

interface Props {
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | undefined;
    dropdownSize?: "sm" | "md" | "lg" | "xl" | "2xl" | undefined;
    value: UserAccount | CustomerAccount | Card | BankAccount | null;
    options: Array<UserAccount | CustomerAccount | Card> | null;
    isCard?: boolean;
    isPayFrom?: boolean;
    isDisabled?: boolean;
    showBalance?: boolean;

    onClick: (e: string) => void;
}

function NewAllAccountDropdown(props: Props): JSX.Element {
    const {
        size = "2xl",
        value,
        isCard = false,
        options,
        isPayFrom = false,
        isDisabled = false,
        showBalance = false,
        dropdownSize,

        onClick,
    } = props;

    const user = useAppSelector((state) => state.init.main?.companyDetails.user);
    const cardBalances = useAppSelector((state) => state.transferBetween.cardBalances);
    const userAccountMeta = useAppSelector((state) => state.init.main?.companyDetails.userAccountsMeta);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredAccounts, setFilteredAccounts] = useState<Array<UserAccount | CustomerAccount | Card>>([]);

    useEffect(() => {
        const query = searchTerm.trim().toLowerCase();
        const accounts = options
            ? isPayFrom
                ? [
                      ...options
                          .filter((_account) => !userAccountMeta?.some((_meta) => _meta.userAccountId === _account.id && _meta.isApproverOnly))
                          .filter((_account) => (_account instanceof Card ? _account.assignedToCorporateAccountMember?.id === user?.id : _account)),
                  ]
                : [...options]
            : [];

        if (!query.length) {
            setFilteredAccounts(accounts);
        } else {
            setFilteredAccounts(
                accounts.filter((item: UserAccount | CustomerAccount | Card) => {
                    if (item instanceof UserAccount) {
                        return (
                            item.accountName.toLowerCase().includes(query) ||
                            item.accountNumber.toLowerCase().includes(query) ||
                            item.lencoNameMin.toLowerCase().includes(query)
                        );
                    }
                    if (item instanceof CustomerAccount) {
                        return (
                            item.accountName.toLowerCase().includes(query) ||
                            item.accountNumber.toLowerCase().includes(query) ||
                            item.alias?.toLowerCase().includes(query)
                        );
                    }
                    if (item instanceof Card) {
                        return item.name.toLowerCase().includes(query) || item.maskedPan.toLowerCase().includes(query);
                    }
                })
            );
        }
    }, [isPayFrom, options, searchTerm]);

    return (
        <>
            <NewDropdownHead
                content={
                    value ? (
                        <AllAccountDetails
                            data={value}
                            currency={value instanceof UserAccount ? value.bankAccountCurrency : null}
                            showBalance={props.showBalance}
                            avatarColor="white"
                        />
                    ) : (
                        <div className="flex items-center justify-start space-x-4">
                            <NewTransactionIcon icon={isCard ? "credit_card" : "business_center"} color="white" size="md" />
                            <p className="text-sm font-medium text-black-tertiary">{isCard ? "Select Business Card" : "Select Business Account"}</p>
                        </div>
                    )
                }
                size={size}
                color="grey"
                buttonType="primary"
                isDisabled={isDisabled}
                searchTerm={searchTerm}
                paddingSize="sm"
                dropdownSize={dropdownSize || "lg"}
                optionsLength={filteredAccounts.length}
                searchPlaceholder="Search"
                handleChangeSearchTerm={setSearchTerm}
                fullWidth
                isSearchable
                clickAndClose
            >
                {(_index) => {
                    const _cardBalance = cardBalances.get(
                        filteredAccounts[_index] instanceof Card ? (filteredAccounts[_index] as Card).createRequestId : ""
                    );

                    return (
                        <AllAccountItem
                            key={_index}
                            data={filteredAccounts[_index]}
                            size={dropdownSize || "lg"}
                            onClick={isDisabled ? undefined : onClick}
                            currency={
                                filteredAccounts[_index] instanceof UserAccount ? (filteredAccounts[_index] as UserAccount).bankAccountCurrency : null
                            }
                            cardBalance={_cardBalance}
                            showBalance={showBalance}
                        />
                    );
                }}
            </NewDropdownHead>
        </>
    );
}

export default NewAllAccountDropdown;
