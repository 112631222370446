import BusinessDetails from "../../../../../../../../../../models/account-opening/businessDetails";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import React from "react";
import { useSelector } from "react-redux";

interface Props {
    data: BusinessDetails | null;
}

function BusinessInformationReview(props: Props): JSX.Element {
    const accountOpening = useSelector((state: IRootState) => state.application.init);

    return (
        <>
            {props.data && accountOpening && (
                <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey px-4 py-5">
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Legal Business Name</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">{props.data.name}</span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Sector/Industry</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {accountOpening.options.industry.find((_el) => _el.id === Number(props.data?.industry?.id || 0))?.name || "-"}
                        </span>
                    </div>
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Business Type</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {accountOpening.options.registrationCategory.find(
                                (_el) => _el.id === (props.data?.registrationCategory ? (props.data?.registrationCategory as number) : undefined)
                            )?.name || "-"}
                        </span>
                    </div>
                    {props.data.scumlNumber && (
                        <div className="flex w-full flex-row items-start justify-between space-x-4">
                            <span className="w-52 text-black-tertiary">SCUML Number</span>
                            <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                                {props.data.scumlNumber || "-"}
                            </span>
                        </div>
                    )}

                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Annual Turnover</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {accountOpening.options.turnover.find((_el) => _el.id === props.data?.annualTurnover)?.name || "-"}
                        </span>
                    </div>

                    {props.data.taxNumber && (
                        <div className="flex w-full flex-row items-start justify-between space-x-4">
                            <span className="w-52 text-black-tertiary">TIN</span>
                            <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                                {props.data.taxNumber || "-"}
                            </span>
                        </div>
                    )}
                    <div className="flex w-full flex-row items-start justify-between space-x-4">
                        <span className="w-52 text-black-tertiary">Describe your Business</span>
                        <span className="w-56 max-w-55% whitespace-normal break-words text-right text-black-secondary">
                            {props.data.businessNature}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export default BusinessInformationReview;
