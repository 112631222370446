import { AgreementType } from "../../../../../../../services/nigeria-application/registered/new/agreement.types";
import AgreementsPreviewItem from "../AgreementsPreviewItem";
import BusinessNextOfKinReview from "../review/BusinessNextOfKinReview";
import BusinessPersonalDetailsReview from "../review/BusinessPersonalDetailsReview";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import PreviewCard from "../../../../../../cards/PreviewCard";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

interface Props {
    handlePreviewDocument: (_data: string) => void;
    handlePreviewAgreementDocument: (_data: AgreementType) => void;
}

function InvitedReview(props: Props): JSX.Element {
    const navigate = useNavigate();
    const agreement = useSelector((state: IRootState) => state.application.agreement?.agreements);
    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const currentSignatory = useSelector((state: IRootState) => state.application.currentSignatory);
    const canHaveBoardResolution = useSelector((state: IRootState) => state.application.agreement?.canHaveBoardResolution);

    return (
        <>
            {accountOpening && (
                <>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Personal Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessPersonalDetailsReview data={currentSignatory || undefined} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Next of Kin</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.NEXT_OF_KIN,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessNextOfKinReview data={currentSignatory?.nextOfKin || null} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Means of ID</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">add_notes</span>}
                            text={accountOpening?.options.idType?.find((_el) => _el.id === currentSignatory?.identification?.type)?.name || "-"}
                            canNotPreview={!currentSignatory?.identification?.fileId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.identification?.fileId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Photograph</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">account_circle</span>}
                            text="Photograph"
                            canNotPreview={!currentSignatory?.identification?.fileId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.photoId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Signatory Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.SIGNATURE,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">signature</span>}
                            text="Signatory Form"
                            canNotPreview={!currentSignatory?.signedDocumentId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.signedDocumentId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Agreements</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.AGREEMENT,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey p-4 [&>div:not(:last-child)]:border-b [&>div:not(:last-child)]:border-grey-secondary [&>div:not(:last-child)]:pb-4">
                            <AgreementsPreviewItem
                                text="Account Mandate"
                                isCanNotPreview={
                                    !accountOpening.progress.isCurrentSignatoryAgreementCompleted && !agreement?.mandateCard.signedDocumentId
                                }
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.ACCOUNT_MANDATE_CARD)}
                            />
                            <AgreementsPreviewItem
                                text="Authority to Debit Account for Search Report"
                                isCanNotPreview={
                                    !accountOpening.progress.isCurrentSignatoryAgreementCompleted && !agreement?.searchReport.signedDocumentId
                                }
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.SEARCH_REPORT)}
                            />
                            <AgreementsPreviewItem
                                text="Account Opening Declaration"
                                isCanNotPreview={
                                    !accountOpening.progress.isCurrentSignatoryAgreementCompleted && !agreement?.declaration.signedDocumentId
                                }
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.ACCOUNT_OPENING_DECLARATION)}
                            />
                            <AgreementsPreviewItem
                                text="Partner Bank's Terms and Conditions"
                                isCanNotPreview={!accountOpening.progress.isCurrentSignatoryAgreementCompleted && !agreement?.terms.signedDocumentId}
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.TERMS_AND_CONDITIONS)}
                            />
                            {canHaveBoardResolution && (
                                <AgreementsPreviewItem
                                    text="Board Resolution"
                                    isCanNotPreview={
                                        !accountOpening.progress.isCurrentSignatoryAgreementCompleted && !agreement?.boardResolution.signedDocumentId
                                    }
                                    handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.BOARD_RESOLUTION)}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default InvitedReview;
