import React, { useEffect } from "react";
import { resetAllBillPaymentData, setBillPaymentPage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";

import { BillPaymentPage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import PageLayout from "../../../../../../components/layouts/page-layout";
import { abortBillPayment } from "../../../services/billPayment/billPaymentApi";
import { useDispatch } from "react-redux";

interface BillPaymentLayoutInterface {
    children: React.ReactNode;
}

function BillPaymentLayout({ children }: BillPaymentLayoutInterface): JSX.Element {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            abortBillPayment();
            dispatch(resetAllBillPaymentData());
            dispatch(setBillPaymentPage(BillPaymentPage.MAKE_PAYMENT));
        };
    }, []);
    return (
        <>
            <PageLayout pageTitle="Bill Payments" subTitle="Make bill payments using your Lenco accounts">
                <div className="flex h-full w-full flex-col">{children}</div>
            </PageLayout>
        </>
    );
}

export default BillPaymentLayout;
