import {
    resetAllTerminalRequestData,
    setRequestedTerminal,
    setTerminalStage,
    updateTerminalRequestsListTotalByOne,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { RequestTerminalRequest } from "../../Services/terminalsApi.types";
import { TerminalsStageType } from "../../../../../redux/terminals/slice/terminalsSlice.types";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { requestTerminal } from "../../Services/terminalsApi";
import { useDispatch } from "react-redux";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { AppToastType } from "../../../../../redux/app-toast/app-toast-slice.types";

interface UseRequestTerminal {
    requestTerminalError: string | null;
    isRequestTerminalLoading: boolean;

    handleRequestTerminal: (_data: RequestTerminalRequest) => Promise<void>;
}

const useRequestTerminal = (): UseRequestTerminal => {
    const dispatch = useDispatch();
    const [isRequestTerminalLoading, setIsRequestTerminalLoading] = useState<boolean>(false);
    const [requestTerminalError, setRequestTerminalError] = useState<string | null>(null);

    const handleRequestTerminal = useCallback(
        async (_data: RequestTerminalRequest) => {
            try {
                setRequestTerminalError(null);
                setIsRequestTerminalLoading(true);
                const res = await requestTerminal(_data);
                dispatch(setRequestedTerminal(res.terminalRequest));
                dispatch(updateTerminalRequestsListTotalByOne());
                dispatch(setTerminalStage(TerminalsStageType.TERMINAL_REQUEST));
                dispatch(resetAllTerminalRequestData());
                dispatch(messageTrue("Terminal Requested Successfully"));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                dispatch(messageTrue({ message: errorMessage, type: AppToastType.ERROR }));
                setRequestTerminalError(errorMessage);
            } finally {
                setIsRequestTerminalLoading(false);
            }
        },
        [dispatch]
    );

    return {
        requestTerminalError,
        isRequestTerminalLoading,

        handleRequestTerminal,
    };
};

export default useRequestTerminal;
