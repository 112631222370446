import {
    resetTempSelectedZambiaApiAccessKey,
    setIsZambiaDeactivateApiAccessKeyModalOpen,
} from "../../../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import Modal from "../../../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../../../components/modal/modal-body";
import ModalHeader from "../../../../../../../../components/modal/modal-header";
import React from "react";
import useDeactivateZambiaApiAccessKey from "../../../../../hookss/ApiAccessKeys/useDeactivateZambiaApiAccessKey";

const DATA_TYPE = "api-access-key";

function DeactivateKeyModal(): JSX.Element {
    const dispatch = useDispatch();

    const { handleDeactivateZambiaApiAccessKey, isDeactivateZambiaApiAccessKeyLoading } = useDeactivateZambiaApiAccessKey();

    const tempSelectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.tempSelectedApiAccessKey);
    const isDeactivateApiAccessKeyModalOpen = useSelector((state: IRootState) => state.zambiaApiAccessKeys.isDeactivateApiAccessKeyModalOpen);

    const handleToggle = () => {
        dispatch(resetTempSelectedZambiaApiAccessKey());
        dispatch(setIsZambiaDeactivateApiAccessKeyModalOpen(false));
    };

    return (
        <>
            <Modal size="md" active={isDeactivateApiAccessKeyModalOpen} toggler={handleToggle} dataType={DATA_TYPE}>
                <ModalHeader dataType={DATA_TYPE} onClose={handleToggle}>
                    Deactivate Key
                </ModalHeader>
                <ModalBody dataType={DATA_TYPE}>
                    <p className="-mt-3 w-full text-sm text-black-tertiary" data-type={DATA_TYPE}>
                        Are you sure you want to deactivate this API key?{" "}
                    </p>

                    <div className="flex w-full flex-row items-center justify-end space-x-4 pt-6">
                        <ButtonComp type="button" size="md" buttonType="secondary" onClick={handleToggle} color={"grey"}>
                            <span className="w-full text-center text-black-tertiary">Cancel</span>
                        </ButtonComp>
                        <ButtonComp
                            type="button"
                            size="md"
                            buttonType="primary"
                            dataType={DATA_TYPE}
                            isLoading={isDeactivateZambiaApiAccessKeyLoading}
                            onClick={() => handleDeactivateZambiaApiAccessKey({ id: tempSelectedApiAccessKey?.id || "" })}
                            color={"red"}
                        >
                            <span className="w-full text-center">Continue</span>
                        </ButtonComp>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default DeactivateKeyModal;
