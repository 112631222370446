import { setTransferDetailPurpose } from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface UseBulkTransferPurposeInterface {
    handlePurposeChange: (_purpose: string) => void;
}

function useBulkTransferPurpose(): UseBulkTransferPurposeInterface {
    const dispatch = useDispatch();

    const handlePurposeChange = useCallback((_purpose: string) => {
        dispatch(setTransferDetailPurpose(_purpose));
    }, []);

    return {
        handlePurposeChange,
    };
}

export default useBulkTransferPurpose;
