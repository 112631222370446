import { AccountBalanceAlertType, AccountBalanceInstructionType } from "../../../models/userAccount.constants";
import { AccountSettingsEnum, SettingsSecurityState, SpecificAccountsType, mockAccountsType } from "./settingsSecuritySLice.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { mockAccounts, mockSpecificAccounts } from "../../../modules/dashboard/settings-new/Services/settings.constants";

const initialState: SettingsSecurityState = {
    isChangePasswordModal1: false,
    isSendMoneyRuleTray: false,
    specificAccounts: mockSpecificAccounts,
    selectedAcc: "",
    isSelectedAccRestricted: false,
    accountSettings: AccountSettingsEnum.ACCOUNT_RESTRICITON,
    minBalanceAmount: "",
    excessBalanceAmount: "",
    mockAccs: mockAccounts,
    isBalanceInstructionTray: false,
    isEditMinimumBalance: false,
    isEditExcessBalance: false,
    balanceType: AccountBalanceInstructionType.LOW_BALANCE_ALERT,
    isSplitInflowScreen: false,
};

export const settingsSecuritySlice = createSlice({
    name: "settingsSecurity",
    initialState,
    reducers: {
        setIsChangePasswordModal: (state, action: PayloadAction<boolean>) => {
            state.isChangePasswordModal1 = action.payload;
        },
        setSendMoneyRuleTray: (state, action: PayloadAction<boolean>) => {
            state.isSendMoneyRuleTray = action.payload;
        },
        setSpecificAccounts: (state, action: PayloadAction<SpecificAccountsType[]>) => {
            state.specificAccounts = action.payload;
        },
        addSpecificRecipient: (state, action: PayloadAction<SpecificAccountsType>) => {
            state.specificAccounts.push(action.payload);
        },
        removeSpecificRecipient: (state, action: PayloadAction<string>) => {
            const index = state.specificAccounts.findIndex((item) => item.value === action.payload);
            if (index !== -1) {
                state.specificAccounts.splice(index, 1);
            }
        },
        setSelectedAcc: (state, action: PayloadAction<string>) => {
            state.selectedAcc = action.payload;
        },
        setIsSelectedAccRestricted: (state, action: PayloadAction<boolean>) => {
            // console.log("payload is ", action.payload);
            state.isSelectedAccRestricted = action.payload;
        },
        setAccountSettings: (state, action: PayloadAction<AccountSettingsEnum>) => {
            state.accountSettings = action.payload;
        },
        setMinBalanceAmount: (state, action: PayloadAction<string>) => {
            state.minBalanceAmount = action.payload;
        },
        setExcessBalanceAmount: (state, action: PayloadAction<string>) => {
            state.excessBalanceAmount = action.payload;
        },
        setMockAccs: (state, action: PayloadAction<mockAccountsType[]>) => {
            state.mockAccs = action.payload;
        },
        setIsBalanceInstructionTray: (state, action: PayloadAction<boolean>) => {
            state.isBalanceInstructionTray = action.payload;
        },
        setIsEditMinimumBalance: (state, action: PayloadAction<boolean>) => {
            state.isEditMinimumBalance = action.payload;
        },
        setIsEditExcessBalance: (state, action: PayloadAction<boolean>) => {
            state.isEditExcessBalance = action.payload;
        },
        setBalanceType: (state, action: PayloadAction<AccountBalanceAlertType>) => {
            state.balanceType = action.payload;
        },
        setIsSplitInflowScreen: (state, action: PayloadAction<boolean>) => {
            state.isSplitInflowScreen = action.payload;
        },
    },
    // extraReducers: (builder) => {
    //     builder.addCase(fetchUser.pending, (state) => {
    //         state.isLoading = true;
});

export const {
    setIsChangePasswordModal,
    setSpecificAccounts,
    setSendMoneyRuleTray,
    removeSpecificRecipient,
    addSpecificRecipient,
    setSelectedAcc,
    setIsSelectedAccRestricted,
    setAccountSettings,
    setMinBalanceAmount,
    setExcessBalanceAmount,
    setMockAccs,
    setIsBalanceInstructionTray,
    setIsEditMinimumBalance,
    setIsEditExcessBalance,
    setBalanceType,
    setIsSplitInflowScreen,
} = settingsSecuritySlice.actions;

export default settingsSecuritySlice.reducer;
