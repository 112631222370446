import React, { ReactElement, useCallback, useEffect } from "react";
import { resetSelectedUserGroup, setPeopleStage } from "../../../../../redux/people/slice/peopleSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../redux/rootReducer";
import Input from "../../../../../components/inputs/Input";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import { PeopleStageType } from "../../../../../redux/people/slice/peopleSlice.types";
import useEditUserGroup from "../../hooks/use-edit-user-group";

function EditCategoryModal(): ReactElement {
    const {
        userGroupName,
        editUserGroupError,
        editUserGroupResponse,
        isEditUserGroupLoading,

        handleResetAll,
        handleEditUserGroup,
        handleChangeUserGroupName,
    } = useEditUserGroup();

    const dispatch = useDispatch();
    const peopleStage = useSelector((state: IRootState) => state.people.peopleStage);
    const userGroupSelected = useSelector((state: IRootState) => state.people.userGroupSelected);

    useEffect(() => {
        if (!userGroupSelected) return;
        handleChangeUserGroupName(userGroupSelected.name);
    }, [userGroupSelected]);

    useEffect(() => {
        if (!editUserGroupResponse) return;
        dispatch(setPeopleStage(PeopleStageType.CATEGORY_LIST));
        handleResetAll();
    }, [handleResetAll, editUserGroupResponse]);

    const handleToggle = useCallback(() => {
        dispatch(setPeopleStage(PeopleStageType.INITIAL));
        dispatch(resetSelectedUserGroup());
    }, []);

    const handleBack = useCallback(() => {
        dispatch(setPeopleStage(PeopleStageType.CATEGORY_LIST));
        dispatch(resetSelectedUserGroup());
    }, []);

    const handleEdit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!userGroupSelected) return;
            void handleEditUserGroup({ name: userGroupName, userGroupId: userGroupSelected.id });
        },
        [userGroupSelected, userGroupName]
    );

    return (
        <>
            {userGroupSelected && (
                <Modal size="md" active={!!(peopleStage === PeopleStageType.EDIT_CATEGORY)} toggler={handleToggle}>
                    <ModalHeader onClose={handleToggle}>Edit Group</ModalHeader>
                    <form className="w-full" onSubmit={handleEdit}>
                        <ModalBody>
                            <div className="flex w-full flex-col">
                                {editUserGroupError && (
                                    <div className="w-full pb-4">
                                        <ErrorToast error={editUserGroupError} />
                                    </div>
                                )}
                                <Input placeholder="Group Name" value={userGroupName} onChange={handleChangeUserGroupName} />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonComp type="button" buttonType="secondary" color="grey" ripple="light" size="lg" func={handleBack}>
                                <span>Back</span>
                            </ButtonComp>
                            <ButtonComp type="submit" color="black" ripple="light" buttonType="primary" size="lg" isLoading={isEditUserGroupLoading}>
                                <span>Update</span>
                            </ButtonComp>
                        </ModalFooter>
                    </form>
                </Modal>
            )}
        </>
    );
}

export default EditCategoryModal;
