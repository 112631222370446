import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

// import { ReactComponent as GreenCheckIcon } from "../../../assets/svg/accordion-green-check.svg";
// import { ReactComponent as GreyCheckIcon } from "../../../assets/svg/accordion-grey-check.svg";

// import useClickOutside from "../../../hooks/useClickOutside";

interface Props {
    header: ReactNode;
    children: ReactNode;
    isOpened?: boolean | undefined;
    isClosed?: boolean | undefined;
    hasCheck?: boolean | undefined;
    subTitle?: string;
    subTitle2?: string;
    checkActive?: boolean | undefined;
    noPaddingTop?: boolean | undefined;

    toggle?: () => void;
}

function Accordion(props: Props): JSX.Element {
    const childDomRef = useRef<HTMLDivElement | null>(null);

    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (props.isOpened === undefined) return;
        if (!props.isOpened) return setIsAccordionOpen(false);
        setIsAccordionOpen(true);
    }, [props.isOpened]);

    useEffect(() => {
        if (!props.isClosed) return;
        setIsAccordionOpen(true);
    }, [props.isClosed]);

    const handleToggleAccordion = useCallback(() => {
        setIsAccordionOpen((prev) => !prev);
        props.toggle && props.toggle();
    }, [props.toggle]);

    return (
        // <div className="w-full" ref={domNode}>
        <div
            className={"w-full rounded-md border border-grey-secondary px-5 py-6 " + `${!props.isOpened ? "cursor-pointer" : ""}`}
            onClick={!props.isOpened ? handleToggleAccordion : undefined}
        >
            <div className="w-full">
                <div className={`w-full cursor-pointer justify-between ` + `${props.noPaddingTop ? "" : ""} `} onClick={props.toggle}>
                    <div className="text-md flex cursor-pointer flex-row items-center justify-between font-normal">
                        <div className="text-md flex flex-col justify-start font-medium text-black-secondary">
                            <div className="flex items-start justify-start">
                                <div className="-mt-[1.5px] flex max-w-[20px] items-start justify-start overflow-hidden">
                                    {props.hasCheck && props.checkActive && (
                                        <span className="material-symbols-rounded icon-fill text-xl text-success">check_circle</span>
                                    )}
                                    {props.hasCheck && !props.checkActive && (
                                        <span className="material-symbols-rounded icon-fill text-xl text-black-quin">check_circle</span>
                                    )}
                                </div>
                                <span className={`${props.hasCheck ? "ml-2" : ""}`}>{props.header}</span>
                            </div>
                            {props.subTitle && (
                                <div className="text-sm font-normal text-black-tertiary">
                                    {props.subTitle} <br />
                                    {props.subTitle2}
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                `ml-4 flex max-w-[20px] transform cursor-pointer items-center justify-center overflow-hidden text-lg duration-300 ` +
                                `${!isAccordionOpen ? "rotate-180" : ""} `
                            }
                        >
                            <span className="material-symbols-outlined">keyboard_arrow_up</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`w-full ` + `${isAccordionOpen ? `block pt-4` : "hidden"} `}
                // className={
                // `w-full px-4 ` +
                // `transition-all transform duration-150 ease-in-out ` +
                // `${isAccordionOpen ? `pt-4 opacity-100` : "max-h-0 opacity-0 pointer-events-none"} `
                // }
                // style={{
                // maxHeight: isAccordionOpen ? `${childHeight + 16}px` : "0px",
                // }}
            >
                <div className="w-full" ref={childDomRef}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Accordion;
