import { PasswordRuleKey, PasswordRules, isAllRulesPassed, isRulePassed } from "./password-hints.service";

import GoogleIcon from "../../components/google-icon";

interface PasswordHintsProps {
    password: string;
    className?: string;
    show?: boolean;
}

function PasswordHints({ password, className = "", show = true }: PasswordHintsProps): JSX.Element {
    return (
        <>
            {!isAllRulesPassed(password) && show && (
                <div className={className}>
                    <ul>
                        {Object.keys(PasswordRules).map((key) => {
                            const ruleKey: PasswordRuleKey = key as PasswordRuleKey;
                            const passed = isRulePassed(password, ruleKey);
                            return (
                                <li className="flex items-center space-x-1 pb-2" key={`password-hint-${key}`}>
                                    <GoogleIcon icon="check" className={`${passed ? "text-success" : "text-black-quat"}`} />

                                    <span className={`${passed ? "text-success" : "text-black-secondary"}`}>{PasswordRules[ruleKey].text}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
}

export default PasswordHints;
