import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class ProofOfResidence {
    [immerable] = true;

    constructor(
        public type: number,
        public uploadId: string | null
    ) {}

    static create(obj: GenericObject): ProofOfResidence {
        return new ProofOfResidence(Parsers.number(obj.type), Parsers.nullableString(obj.uploadId));
    }
}
