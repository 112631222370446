import { DropdownItem, GenericObject } from "../../helpers/types";

import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class TypeEntity {
    [immerable] = true;

    constructor(
        public id: number,
        public name: string
    ) {}

    static create(obj: GenericObject): TypeEntity {
        return new TypeEntity(Parsers.number(obj.id), Parsers.string(obj.name));
    }

    toDropdownItem(): DropdownItem<number> {
        return {
            text: this.name,
            value: this.id,
        };
    }
}
