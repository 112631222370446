import ButtonComp from "../../../../../components/button/ButtonComp";
import { Link } from "react-router-dom";
import { ReactComponent as Mailbox } from "../../../../../assets/svg/mail-box.svg";

function CompletedWaitList(): JSX.Element {
    return (
        <>
            <div className="relative mx-auto flex w-full max-w-md flex-col items-center justify-center rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex w-full flex-col items-center justify-start space-y-6">
                    <div className="flex w-full flex-col items-center justify-start space-y-8">
                        <div>
                            <Mailbox />
                        </div>
                        <div className="flex w-full max-w-16 flex-col items-center justify-start space-y-4">
                            <h3 className="text-center text-2xl font-medium text-black">Awesome</h3>
                            <p className="text-center font-normal text-black-secondary">We will let you know once we launch in your country</p>
                        </div>
                    </div>
                    <Link to="/login" className="w-full">
                        <ButtonComp type="button" color="black" buttonType="primary" fullWidth isText>
                            Back to Homepage
                        </ButtonComp>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default CompletedWaitList;
