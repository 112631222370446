import { CreateRecipientRequest, CreateRecipientResponse, ListRecipientsRequest } from "../send-money/singleTransferApi.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";

import CustomerAccount from "../../../../../models/customerAccount";
import { GenericObject } from "../../../../../helpers/types";
import { ListRecipientsResponse } from "../pending-approval/pendingPayments.types";
import Parsers from "../../../../../utils/parsers";
import { Recipient } from "../../hooks/state/pending-approval/use-saved-recipients-state.types";
import getBankName from "../../../../../helpers/getBankName";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";

export enum SavedRecipientRequestType {
    LIST = "saved-recipient.list",
    CREATE = "saved-recipient.create",
    DETAILS = "saved-recipient.details",
    EDIT_ALIAS = "saved-recipient.edit-alias",
    DELETE = "saved-recipient.delete",
    SET_GROUP = "saved-recipient.group.set",
    REMOVE_FROM_GROUP = "saved-recipient.group.remove",
}

export function getRecipient(customerAccount: unknown): Recipient {
    const customerAccountEntity = Parsers.classObjectNonNullable(customerAccount, CustomerAccount);
    return {
        customerAccount: customerAccountEntity,
        bankName: getBankName(customerAccountEntity.bankCode),
    };
}

export async function listRecipients(data: ListRecipientsRequest): Promise<ListRecipientsResponse> {
    const signal = getAbortControllerSignal(SavedRecipientRequestType.LIST);
    const res = await makeRequestWithSignal("/main/payments/recipients", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    if (!res.recipients || !Array.isArray(res.recipients)) {
        throw new Error("Something went wrong. Please try again or contact support");
    }
    return {
        /*customerAccounts: Parsers.classObjectArray(
			res.recipients.map((r: GenericObject) => r.customerAccount),
			CustomerAccount
		),*/
        recipients: res.recipients.map((r: GenericObject) => getRecipient(r.customerAccount)),
        groupSize: Parsers.number(res.groupSize),
        total: Parsers.number(res.total),
    };
}

export const createRecipient = async (data: CreateRecipientRequest): Promise<CreateRecipientResponse | Error> => {
    const signal = getAbortControllerSignal(SavedRecipientRequestType.CREATE);
    const res = await makeRequestWithSignal("/main/payments/recipients/create", data, signal);
    if (res instanceof Error) {
        return res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        customerAccount: Parsers.classObjectNonNullable(res.customerAccount, CustomerAccount),
    };
};

export const getRecipientDetails = async (id: string): Promise<Recipient> => {
    const signal = getAbortControllerSignal(SavedRecipientRequestType.DETAILS);
    const res = await makeRequestWithSignal("/main/payments/recipients/details", { id }, signal);
    if (res instanceof Error) {
        throw res;
    }
    return getRecipient(res.customerAccount);
};

export const editRecipientAlias = async (id: string, alias: string | undefined): Promise<Recipient> => {
    const signal = getAbortControllerSignal(SavedRecipientRequestType.EDIT_ALIAS);
    const res = await makeRequestWithSignal("/main/payments/recipients/alias/edit", { id, alias }, signal);
    if (res instanceof Error) {
        throw res;
    }
    return getRecipient(res.customerAccount);
};

export const deleteRecipient = async (id: string): Promise<void> => {
    const signal = getAbortControllerSignal(SavedRecipientRequestType.DELETE);
    const res = await makeRequestWithSignal("/main/payments/recipients/delete", { id }, signal);
    if (res instanceof Error) {
        throw res;
    }
};

export const setRecipientGroup = async (id: string, userGroupId: string): Promise<Recipient> => {
    const signal = getAbortControllerSignal(SavedRecipientRequestType.SET_GROUP);
    const res = await makeRequestWithSignal("/main/payments/recipients/group/set", { id, userGroupId }, signal);
    if (res instanceof Error) {
        throw res;
    }
    return getRecipient(res.customerAccount);
};

export const removeRecipientFromGroup = async (id: string): Promise<Recipient> => {
    const signal = getAbortControllerSignal(SavedRecipientRequestType.REMOVE_FROM_GROUP);
    const res = await makeRequestWithSignal("/main/payments/recipients/group/remove", { id }, signal);
    if (res instanceof Error) {
        throw res;
    }
    return getRecipient(res.customerAccount);
};

export function abortSavedRecipientRequest(type: SavedRecipientRequestType): void {
    abortRequest(type);
}

export function abortAllSavedRecipientRequests(): void {
    Object.values(SavedRecipientRequestType).forEach((type) => abortRequest(type));
}
