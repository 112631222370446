import { useCallback, useState } from "react";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { UploadZambiaApiAccessKeyLogoRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { uploadZambiaApiAccessKeyLogoApi } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { useDispatch } from "react-redux";

export interface UploadZambiaApiAccessKeyLogoInterface {
    isUploadZambiaApiAccessKeyLogoLoading: boolean;
    uploadZambiaApiAccessKeyLogoErrorMessage: string;
    handleUploadZambiaApiAccessKeyLogo: (_data: UploadZambiaApiAccessKeyLogoRequest) => void;
}

// interface Props {
// onComplete: () => void;
// }

function useUploadZambiaApiAccessKeyLogo(): UploadZambiaApiAccessKeyLogoInterface {
    const dispatch = useDispatch();
    const [isUploadZambiaApiAccessKeyLogoLoading, setIsUploadZambiaApiAccessKeyLogoLoading] = useState(false);
    const [uploadZambiaApiAccessKeyLogoErrorMessage, setUploadZambiaApiAccessKeyLogoErrorMessage] = useState<string>("");

    const handleUploadZambiaApiAccessKeyLogo = useCallback(
        async (_data: UploadZambiaApiAccessKeyLogoRequest) => {
            try {
                setIsUploadZambiaApiAccessKeyLogoLoading(true);
                setUploadZambiaApiAccessKeyLogoErrorMessage("");
                const res = await uploadZambiaApiAccessKeyLogoApi(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key logo uploaded successfully."));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setUploadZambiaApiAccessKeyLogoErrorMessage(errorMessage);
            } finally {
                setIsUploadZambiaApiAccessKeyLogoLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isUploadZambiaApiAccessKeyLogoLoading,
        uploadZambiaApiAccessKeyLogoErrorMessage,
        handleUploadZambiaApiAccessKeyLogo,
    };
}

export default useUploadZambiaApiAccessKeyLogo;
