import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import { addNewCompany, setCompanyId } from "../../../../../../redux/init/slice/initSlice";
import { useCallback, useRef, useState } from "react";

import ButtonComp from "../../../../../button/ButtonComp";
import CustomSelect from "../../../../../dropdown/custom-select";
import ErrorToast from "../../../../../message-toast/sub/error-toast";
import FormInput from "../../../../../inputs/FormInput";
import { LencoActiveCountries } from "../../../../../../redux/init/slice/initSlice.types";
import Modal from "../../../../../modal/Modal";
import ModalBody from "../../../../../modal/modal-body";
import ModalFooter from "../../../../../modal/modal-footer";
import ModalHeader from "../../../../../modal/modal-header";
import { addCompany } from "../../../../../init/services/init-api";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { lencoCountryDashboardOptions } from "../../../../services/country.constant";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export type AddNewBusinessFormNew = {
    businessName: string;
    country: string;
};

interface Props {
    active: boolean;
    toggler: () => void;
}

function AddNewBusinessModal(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formikRef = useRef<FormikProps<AddNewBusinessFormNew> | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const initialFormState: AddNewBusinessFormNew = {
        businessName: "",
        country: "",
    };

    const formValidation = Yup.object().shape({
        businessName: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
    });

    const handleAddNewBusiness = useCallback(
        async (data: AddNewBusinessFormNew) => {
            try {
                setError(null);
                setIsSubmitting(true);
                const res = await addCompany(data);
                dispatch(addNewCompany(res.company));
                dispatch(setCompanyId(res.company.id));
                navigate("/application/registration-status");
                props.toggler();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    return (
        <>
            <Modal size="md" active={props.active} toggler={props.toggler}>
                <ModalHeader onClose={props.toggler}>Add a new business</ModalHeader>
                <Formik
                    initialValues={initialFormState}
                    innerRef={formikRef}
                    validationSchema={formValidation}
                    onSubmit={(values) =>
                        handleAddNewBusiness({
                            businessName: values.businessName,
                            country: values.country === (LencoActiveCountries.ZAMBIA as string) ? "ZM" : "NG",
                        })
                    }
                    enableReinitialize={true}
                    validateOnChange
                    validateOnMount
                >
                    {({ isValid, values, getFieldHelpers }) => {
                        return (
                            <Form className="w-full">
                                <ModalBody>
                                    <div className="flex w-full flex-col items-center justify-center">
                                        {!!error && (
                                            <div className="w-full pb-4">
                                                <ErrorToast error={error || ""} fullWidth />
                                            </div>
                                        )}
                                        <div className="flex w-full flex-col space-y-4">
                                            <FormInput type="text" placeholder="Business Name" name="businessName" isActive={props.active} />
                                            <CustomSelect
                                                size="xl"
                                                value={values.country}
                                                label="Select Country"
                                                options={lencoCountryDashboardOptions}
                                                onSelect={(_value) => {
                                                    if (_value) {
                                                        void getFieldHelpers("country").setValue(_value);
                                                    }
                                                }}
                                                withIcon
                                            />
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <div className="flex w-full flex-row flex-wrap items-end justify-end">
                                        <ButtonComp
                                            type="submit"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            isLoading={isSubmitting}
                                            disable={!isValid}
                                        >
                                            <span>Add Business</span>
                                        </ButtonComp>
                                    </div>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}

export default AddNewBusinessModal;
