import { produce } from "immer";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import {
    setIsTerminalRequestsPaginationLoading,
    setRequestedTerminals,
    setTerminalRequestsListTotal,
    setTerminalRequestsListGroupSize,
    setSelectedTerminalRequestsFilterState,
    setTerminalRequestsFilterState,
    setTerminalRequestsPaginationOffset,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import {
    setIsTransactionPaginationLoading,
    resetTransactionFilterState,
    resetTransactionDateFilterState,
} from "../../../../../redux/transactions/slice/transactionsSlice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { listTerminalRequest } from "../../Services/terminalsApi";
import { useAppSelector } from "../../../../../redux/hooks";
import { ListTerminalRequestsRequest } from "../../Services/terminalsApi.types";

export interface UseTerminalRequestsInterface {
    handleClearAll: () => void;
    handlePaginationRequest: (e: number) => void;
    handleFilteredTerminalRequests: (e: unknown) => void;
}

function useTerminalRequests(): UseTerminalRequestsInterface {
    const dispatch = useDispatch();

    const selectedTerminalRequestsFilterState = useAppSelector((state) => state.terminals.selectedTerminalRequestsFilterState);

    const handleGetTerminalRequests = useCallback(
        async (_data: ListTerminalRequestsRequest) => {
            try {
                dispatch(setIsTerminalRequestsPaginationLoading(true));
                const res = await listTerminalRequest(_data);
                dispatch(setRequestedTerminals(res.terminalRequests));
                dispatch(setTerminalRequestsListTotal(res.total));
                dispatch(setTerminalRequestsListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsTerminalRequestsPaginationLoading(false));
            }
        },
        [dispatch]
    );

    const handleFilteredTerminalRequests = useCallback(
        <T>(data: T) => {
            const updatedList = produce(selectedTerminalRequestsFilterState, (draft) => {
                return (draft = { ...draft, ...data });
            });
            dispatch(setSelectedTerminalRequestsFilterState());
            dispatch(setTerminalRequestsFilterState(updatedList));
            dispatch(setIsTransactionPaginationLoading(true));
            void handleGetTerminalRequests(updatedList as unknown as ListTerminalRequestsRequest);
        },
        [selectedTerminalRequestsFilterState]
    );

    const handlePaginationRequest = useCallback((offset: number) => {
        dispatch(setTerminalRequestsPaginationOffset(offset));
        handleFilteredTerminalRequests({ offset });
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetTransactionFilterState());
        dispatch(resetTransactionDateFilterState());
        void handleGetTerminalRequests({
            offset: (0)?.toString(),
            purchaseTypes: [],
            statuses: [],
        });
    }, []);

    return {
        handleClearAll,
        handlePaginationRequest,
        handleFilteredTerminalRequests,
    };
}

export default useTerminalRequests;
