import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import { PingResponse } from "../service/ping-api.constant";
import errorhandler from "../../request/error-handler";
import { pingApi } from "../service/ping-api";

function usePing(): UseMutationResult<PingResponse, unknown, void, unknown> {
    const Ping = useMutation({
        mutationFn: async () => await pingApi(),
        onError(error: AxiosError) {
            errorhandler(error);
        },
    });

    return Ping;
}

export default usePing;
