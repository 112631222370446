export const colors = [
    {
        id: 1,
        code: "#000000",
    },
    {
        id: 2,
        code: "#E23F45",
    },
    {
        id: 3,
        code: "#EB9327",
    },
    {
        id: 4,
        code: "#D9D926",
    },
    {
        id: 5,
        code: "#3D9D4E",
    },
    {
        id: 6,
        code: "#0086FF",
    },
    {
        id: 7,
        code: "#71349D",
    },
    {
        id: 8,
        code: "#FC74FC",
    },
];
