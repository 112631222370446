import GoogleIcon from "../google-icon";
import React from "react";

type MessageToastProps = {
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    type?: "error" | "info" | "success" | "warning" | "security";
    noIcon?: boolean;
    message: string | React.ReactNode;
    dataType?: string;
    className?: string;
    fullWidth?: boolean;

    onClick?: () => void;
};

function MessageToast({ size = "xl", noIcon, dataType, className, type = "success", message, fullWidth = false }: MessageToastProps): JSX.Element {
    return (
        <div
            className={
                `flex transform flex-row items-center justify-start space-x-4 rounded-lg border px-4 py-2 text-center opacity-100 transition-all duration-700 ` +
                `${size === "sm" ? "min-h-8" : ""} ` +
                `${size === "md" ? "min-h-10" : ""} ` +
                `${size === "lg" ? "min-h-12" : ""} ` +
                `${size === "xl" ? "min-h-14" : ""} ` +
                `${size === "2xl" ? "min-h-16" : ""} ` +
                `${fullWidth ? "w-full" : "w-max"} ` +
                `${type === "info" ? "border-info-quin bg-info-backdrop text-info" : ""} ` +
                `${type === "error" ? "border-error-quin bg-error-backdrop text-error" : ""} ` +
                `${type === "warning" || type === "security" ? "border-warning-quin bg-warning-backdrop text-warning" : ""} ` +
                `${type === "success" ? "border-success-quin bg-success-backdrop text-success" : ""} ` +
                `${className || ""} `
            }
            data-type={dataType}
        >
            {!noIcon && type === "info" && <GoogleIcon icon="info" data-type={dataType} />}
            {!noIcon && type === "error" && <GoogleIcon icon="cancel" data-type={dataType} />}
            {!noIcon && type === "warning" && <GoogleIcon icon="warning" data-type={dataType} />}
            {!noIcon && type === "success" && <GoogleIcon icon="check_circle" data-type={dataType} />}
            {!noIcon && type === "security" && <GoogleIcon icon="lock" data-type={dataType} />}
            <div className="whitespace-normal text-left text-sm font-normal">
                {typeof message === "string" ? <p data-type={dataType}>{message}</p> : message}
            </div>{" "}
        </div>
    );
}
export default MessageToast;
