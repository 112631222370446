import { useCallback, useState } from "react";

import { ForgotPasswordVerifyOtpData } from "../../servicess/resetPasswordApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { changePasswordVerifyOtp } from "../../servicess/resetPasswordApi";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface UseResetPasswordVerifyOtpInterface {
    isSubmitting: boolean;
    errorMessage: string | null;
    isSuccessful: boolean;

    handleSubmitForm: (data: ForgotPasswordVerifyOtpData) => Promise<void>;
}

const useResetPasswordVerifyOtp = (onSuccessCallback: () => void): UseResetPasswordVerifyOtpInterface => {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
    // const [form, setForm] = useState<Form>({recipient: ""});

    const handleSubmitForm = useCallback(
        async (data: ForgotPasswordVerifyOtpData): Promise<void> => {
            try {
                setIsSubmitting(true);
                setErrorMessage(null);
                const res = await changePasswordVerifyOtp(data);
                setErrorMessage(res.success ? null : res.message);
                setIsSuccessful(res.success);
                if (res.success) {
                    onSuccessCallback();
                }
            } catch (err) {
                if (err instanceof RequestCancelledError) return; // do nothing
                setErrorMessage(getErrorMessage(err));
                setIsSuccessful(false);
            }
            setIsSubmitting(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    return {
        isSubmitting,
        errorMessage,
        isSuccessful,
        // form,

        handleSubmitForm,
    };
};

export default useResetPasswordVerifyOtp;
