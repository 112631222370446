import React from "react";
import TerminalApproved from "../../../../../../assets/svg/Terminal/terminal-approved.svg";
import { ReactComponent as TerminalApprovedStepper } from "../../../../../../assets/svg/Terminal/terminal-approved-stepper.svg";

interface Props {
    sm?: boolean;
    isTray?: boolean;
}

function TerminalApprovedCard(props: Props): JSX.Element {
    return (
        <>
            {!props.sm && (
                <div className="flex flex-col items-start justify-start space-y-5 px-6 py-4">
                    <div className="flex flex-col items-start justify-start space-y-3">
                        <div className="flex items-center justify-start space-x-2">
                            <img src={TerminalApproved} alt="check mark" />
                            <span className="text-base font-medium text-black-secondary md:text-lg">Terminal Approved</span>
                        </div>
                        <TerminalApprovedStepper />
                    </div>
                    <div className="flex flex-col">
                        <span className="text-xs font-normal text-black-tertiary md:text-sm">Your request for a terminal has been approved</span>
                        <span className="text-xs font-normal text-black-tertiary md:text-sm">
                            We are processing your terminal and it will be delivered in the next 3 working days
                        </span>
                    </div>
                </div>
            )}
            {props.sm && (
                <div className={"flex flex-col items-start justify-start " + `${props.isTray ? "space-y-4" : "space-y-1"}`}>
                    <div className="flex items-center justify-start space-x-2">
                        <img src={TerminalApproved} alt="check mark" />
                        <span className="text-base font-medium text-black-secondary">Terminal Approved</span>
                    </div>
                    {props.isTray && <TerminalApprovedStepper />}
                    <div className={"flex flex-col " + `${props.isTray ? "text-black-secondary" : "text-black-tertiary"}`}>
                        <span className="text-sm font-normal text-black-tertiary">Your request for a terminal has been approved</span>
                        <span className="text-sm font-normal text-black-tertiary">
                            We are processing your terminal and it will be delivered in the next 3 working days
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export default TerminalApprovedCard;
