// import { useEffect, useState } from "react";

import Currency from "../../models/currency";
import CurrencyCode from "../currency-code";
import formatNumber from "../../utils/formatNumber";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

interface MoneyProps {
    amount: number | string;
    deleted?: boolean;
    dataType?: string;
    negative?: boolean;
    positive?: boolean;
    currency?: Currency | null | undefined;
    className?: string;
    noSuperscript?: boolean;
    hideDecimalsIfZero?: boolean;
}

function Money({
    currency,
    amount,
    positive = false,
    negative = false,
    deleted = false,
    className = "",
    dataType,
    // noSuperscript = true,
    // hideDecimalsIfZero = false,
}: MoneyProps): JSX.Element {
    // const [formattedAmount, setFormattedAmount] = useState<string>("");
    // const [formattedAmountSplit, setFormattedAmountSplit] = useState<[string, string]>(["", ""]);
    // const [isZero, setIsZero] = useState<boolean>(false);

    const numberAmount = typeof amount === "string" ? Number(String(amount).replace(new RegExp(`[^0-9-.]`, "gi"), "")) : amount;

    // useEffect(() => {
    //     // formattedAmount
    //     const formatted = formatNumber(Math.abs(numberAmount), !hideDecimalsIfZero);
    //     setFormattedAmount(formatted);

    //     const split = formatted.split(".", 2);
    //     setFormattedAmountSplit([split[0], split[1]]);

    //     // isZero
    //     setIsZero(numberAmount === 0);
    // }, [numberAmount]);

    return isNullOrUndefined(numberAmount) ? (
        <span className={"inline-flex " + className} data-type={dataType}>
            &mdash;
        </span>
    ) : (
        <span className={"inline-flex " + `${deleted ? "line-through" : ""} ` + className} data-type={dataType}>
            {(negative || numberAmount < 0) && (
                <span className="mr-0.25 !leading-[100%]" data-type={dataType}>
                    &ndash;
                </span>
            )}
            {(positive || numberAmount < 0) && (
                <span className="mr-0.25 !leading-[100%]" data-type={dataType}>
                    &#43;
                </span>
            )}
            <span className="!leading-[100%]" data-type={dataType}>
                <CurrencyCode currency={currency} dataType={dataType} />
            </span>
            <span className="!leading-[100%]" data-type={dataType}>
                {formatNumber(numberAmount)}
            </span>
            {/* <span className="mr-0.25 !leading-[100%]" dangerouslySetInnerHTML={{ __html: currency?.htmlString || "" }} /> */}
            {/* {noSuperscript ? ( */}
            {/* ) : (
                <>
                    <span className="!leading-[100%]" data-type={dataType}>
                        {formattedAmountSplit[0]}
                    </span>
                    {!isZero && formattedAmountSplit[1] && (
                        <span className={"self-start text-xs !leading-[100%]"} data-type={dataType}>
                            .{formattedAmountSplit[1]}
                        </span>
                    )}
                </>
            )} */}
        </span>
    );
}

export default Money;
