export enum BillPurchaseCategoriesType {
    OTHER_BILLS = 0,
    AIRTIME = 1,
    AIRTIME_PIN = 2,
    MOBILE_DATA = 3,
    MOBILE_COMBO = 4,
    CABLE_TV = 5,
    ELECTRICITY = 6,
    INTERNET = 7,
}

export enum BillPurchaseStatusType {
    PENDING = 1,
    SUCCESS = 2,
    FAILED = 3,
}
