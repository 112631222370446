import { BillPaymentPage, BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import React, { useCallback, useEffect, useRef } from "react";
import { setBillPaymentPage, setBillPaymentTypeBeforeBillHistoryType } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ActiveStageIcon } from "../../../../../../assets/svg/dashboard/send-money/active-payment-stage.svg";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import { ReactComponent as InactiveStageIcon } from "../../../../../../assets/svg/dashboard/send-money/inactive-payment-stage.svg";
import PageLayout from "../../../../../../components/layouts/page-layout";

interface BillPaymentLayoutInterface {
    children: React.ReactNode;
}

function BillPaymentLayout(props: BillPaymentLayoutInterface): JSX.Element {
    const divRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();

    const billPaymentStage = useSelector((state: IRootState) => state.billPayment.billPaymentStage);
    const billPaymentType = useSelector((state: IRootState) => state.billPayment.billPaymentType);

    useEffect(() => {
        divRef.current?.scrollIntoView();
    }, [billPaymentStage]);

    const handleBillPaymentHistory = useCallback(() => {
        dispatch(setBillPaymentTypeBeforeBillHistoryType(billPaymentType));
        dispatch(setBillPaymentPage(BillPaymentPage.PAYMENT_HISTORY));
    }, [billPaymentType]);

    return (
        <>
            <PageLayout
                pageTitle="Bill Payments"
                subTitle="Make bill payments using your Lenco accounts"
                headerButtons={
                    <ButtonComp size="md" color="grey" buttonType="secondary" func={handleBillPaymentHistory} borderSmall>
                        <span className="text-black">Bill Payment History</span>
                    </ButtonComp>
                }
            >
                {billPaymentStage !== BillPaymentStage.PROCESS_PAYMENT && (
                    <div className="hidden h-11 min-h-[44px] flex-row items-center justify-start space-x-4 overflow-x-auto rounded-md bg-white px-6 lg:flex">
                        <div className="flex items-center justify-start space-x-2">
                            {billPaymentStage > BillPaymentStage.INITIAL && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {billPaymentStage === BillPaymentStage.INITIAL && <ActiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${billPaymentStage > BillPaymentStage.INITIAL ? "font-medium" : ""} ` +
                                    `${billPaymentStage === BillPaymentStage.INITIAL ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Type of Transfer
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-grey"></div>
                        <div className="flex items-center justify-start space-x-2">
                            {billPaymentStage > BillPaymentStage.PAYMENT_DETAILS && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {billPaymentStage === BillPaymentStage.PAYMENT_DETAILS && <ActiveStageIcon />}
                            {billPaymentStage < BillPaymentStage.PAYMENT_DETAILS && <InactiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${billPaymentStage > BillPaymentStage.PAYMENT_DETAILS ? "font-medium" : "text-sm"} ` +
                                    `${billPaymentStage === BillPaymentStage.PAYMENT_DETAILS ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Payment Details
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-grey"></div>
                        <div className="flex items-center justify-start space-x-2">
                            {billPaymentStage > BillPaymentStage.REVIEW_PAYMENT && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {billPaymentStage === BillPaymentStage.REVIEW_PAYMENT && <ActiveStageIcon />}
                            {billPaymentStage < BillPaymentStage.REVIEW_PAYMENT && <InactiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${billPaymentStage > BillPaymentStage.REVIEW_PAYMENT ? "font-medium" : "text-sm"} ` +
                                    `${billPaymentStage === BillPaymentStage.REVIEW_PAYMENT ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Review Payment
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-grey"></div>
                        <div className="flex items-center justify-start space-x-2">
                            {billPaymentStage > BillPaymentStage.MAKE_PAYMENT && (
                                <GoogleIcon icon="check_circle" size="lg" className="text-blue" isFilled />
                            )}
                            {billPaymentStage === BillPaymentStage.MAKE_PAYMENT && <ActiveStageIcon />}
                            {billPaymentStage < BillPaymentStage.MAKE_PAYMENT && <InactiveStageIcon />}

                            <p
                                className={
                                    `sm:whitespace-nowrap sm:text-nowrap ` +
                                    `${billPaymentStage > BillPaymentStage.MAKE_PAYMENT ? "font-medium" : "text-sm"} ` +
                                    `${billPaymentStage === BillPaymentStage.MAKE_PAYMENT ? "!text-base font-medium text-blue" : "text-black-secondary"} `
                                }
                            >
                                Make Payment
                            </p>
                        </div>
                    </div>
                )}
                <div className={"flex w-full flex-col" + `${billPaymentStage !== BillPaymentStage.PROCESS_PAYMENT ? " pt-12" : ""}`} ref={divRef}>
                    {props.children}
                </div>
            </PageLayout>
        </>
    );
}

export default BillPaymentLayout;
