import {
    resetTempSelectedZambiaApiAccessKey,
    setIsZambiaDeactivateApiAccessKeyModalOpen,
    setUpdateZambiaApiAccessKey,
} from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useCallback, useState } from "react";

import { DeactivateApiAccessKeysRequest } from "../../Services/ApiAccessKeys/apiAccessKeysApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { deactivateApiAccessKey } from "../../Services/ApiAccessKeys/apiAccessKeysApi";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseDeactivateZambiaApiAccessKeysInterface {
    isDeactivateZambiaApiAccessKeyLoading: boolean;
    deactivateZambiaApiAccessKeyErrorMessage: string;
    handleDeactivateZambiaApiAccessKey: (_data: DeactivateApiAccessKeysRequest) => void;
}

function useDeactivateZambiaApiAccessKey(): UseDeactivateZambiaApiAccessKeysInterface {
    const dispatch = useDispatch();
    const [isDeactivateZambiaApiAccessKeyLoading, setIsDeactivateZambiaApiAccessKeyLoading] = useState(false);
    const [deactivateZambiaApiAccessKeyErrorMessage, setDeactivateZambiaApiAccessKeyErrorMessage] = useState<string>("");

    const handleDeactivateZambiaApiAccessKey = useCallback(
        async (_data: DeactivateApiAccessKeysRequest) => {
            try {
                setIsDeactivateZambiaApiAccessKeyLoading(true);
                setDeactivateZambiaApiAccessKeyErrorMessage("");
                const res = await deactivateApiAccessKey(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(resetTempSelectedZambiaApiAccessKey());
                dispatch(setIsZambiaDeactivateApiAccessKeyModalOpen(false));
                dispatch(messageTrue("API Access Key Deactivated Successfully."));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setDeactivateZambiaApiAccessKeyErrorMessage(errorMessage);
            } finally {
                setIsDeactivateZambiaApiAccessKeyLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isDeactivateZambiaApiAccessKeyLoading,
        deactivateZambiaApiAccessKeyErrorMessage,
        handleDeactivateZambiaApiAccessKey,
    };
}

export default useDeactivateZambiaApiAccessKey;
