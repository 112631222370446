import { LencoActiveCountries } from "../../../../redux/init/slice/initSlice.types";

export enum BusinessTypes {
    REGISTERED = 1,
    UNREGISTERED = 2,
}

export const BusinessTypeText: {
    [type in LencoActiveCountries]: {
        registered: {
            title: string;
            Subtitle: string;
        };
        unregistered: {
            title: string;
            Subtitle: string;
        };
    };
} = {
    [LencoActiveCountries.NIGERIA]: {
        registered: {
            title: "Registered Business",
            Subtitle:
                "This business has an incorporation certificate. It is suitable for Sole Proprietor, Limited Liability Companies, NGO and all type of registered entities.",
        },
        unregistered: {
            title: "Unregistered Business",
            Subtitle: "This business is NOT registered. It is suitable for Freelancers, Individuals, Social Media Vendor and Stores.",
        },
    },
    [LencoActiveCountries.ZAMBIA]: {
        registered: {
            title: "Registered Business",
            Subtitle:
                "The business has an incorporation certificate. Sole Proprietor, Limited Liability, NGO and others. Suitable for all type of registered entities.",
        },
        unregistered: {
            title: "Unregistered Business",
            Subtitle:
                "The business is NOT registered with the PACRA. For individuals, one-person business. Suitable for social media vendors and stores.",
        },
    },
};
