import { useEffect, useState } from "react";

import NewSelect from "../new-select";
import UserAccount from "../../models/userAccount";
import UserAccountItem from "../user-account-dropdown/components/user-account-item";
import titleCase from "../../hooks/titleCase";
import { useAppSelector } from "../../redux/hooks";

interface Props {
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | undefined;
    label: string;
    value: string | null;
    options: UserAccount[] | null;
    dataType?: string;
    isDisabled?: boolean;
    showBalance?: boolean;
    approverCanSelect?: boolean;
    searchPlaceholder?: string;
    dropdownAlwaysDown?: boolean;

    onClick: (e: string) => void;
}

function UserAccountSelect(props: Props) {
    const {
        size = "2xl",
        label,
        value,
        options,
        dataType = "",
        isDisabled = false,
        showBalance = false,
        approverCanSelect = false,
        searchPlaceholder = "",
        dropdownAlwaysDown = false,

        onClick,
    } = props;

    const userAccountMeta = useAppSelector((state) => state.init.main?.companyDetails.userAccountsMeta);
    const selectedAccount = useAppSelector((state) => state.init.main?.companyDetails.accounts.find((a) => a.id === value));

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredAccounts, setFilteredAccounts] = useState<UserAccount[]>([]);

    const normalAccounts = filteredAccounts.filter((_account) => userAccountMeta?.find((el) => el.userAccountId === _account.id)?.isInitiator);

    useEffect(() => {
        const accounts = options ? [...options] : [];
        if (approverCanSelect) {
            accounts.sort((account1, account2): number => {
                if (userAccountMeta?.find((el) => el.userAccountId === account1.id)?.isInitiatorAndApprover && account1.isMain) return -1;
                if (userAccountMeta?.find((el) => el.userAccountId === account2.id)?.isInitiatorAndApprover && account2.isMain) return 1;

                return 0;
            });
        } else {
            accounts.sort((account1, account2): number => {
                if (account1?.isMain) return -1;
                if (account2?.isMain) return 1;

                return account1?.lencoNameMin.localeCompare(account2?.lencoNameMin);
            });
        }

        const query = searchTerm.trim().toLowerCase();

        if (!query.length) {
            setFilteredAccounts(accounts);
        } else {
            setFilteredAccounts(
                accounts.filter((item: UserAccount) => {
                    return (
                        item.accountName.toLowerCase().includes(query) ||
                        item.accountNumber.toLowerCase().includes(query) ||
                        item.lencoNameMin.toLowerCase().includes(query)
                    );
                })
            );
        }
    }, [options, searchTerm, approverCanSelect]);
    return (
        <>
            <NewSelect
                size={size || "lg"}
                label={label}
                value={titleCase(selectedAccount?.lencoNameMin || "")}
                dataType={dataType}
                searchTerm={searchTerm}
                isDisabled={isDisabled}
                dropdownSize={size || "lg"}
                optionsLength={options?.length}
                searchPlaceholder={searchPlaceholder || "Search for account"}
                dropdownAlwaysDown={dropdownAlwaysDown}
                handleChangeSearchTerm={(_value) => setSearchTerm(_value)}
                clickAndClose
                isSearchable
                fitHeight
            >
                {(_index) =>
                    filteredAccounts[_index] && (
                        <UserAccountItem
                            key={_index}
                            size={size || "lg"}
                            data={normalAccounts[_index]}
                            onClick={isDisabled ? undefined : onClick}
                            currency={filteredAccounts[_index]?.bankAccountCurrency || null}
                            showBalance={showBalance}
                            dataType={dataType}
                        />
                    )
                }
            </NewSelect>
        </>
    );
}

export default UserAccountSelect;
