import React, { useCallback, useEffect, useState } from "react";

// import { ReactComponent as ArrowDownIcon } from "../../../../../../assets/svg/general/arrowDownIcon.svg";
import { DropdownItem } from "../../../../../../helpers/types";
// import DropdownLink from "../../../../../../components/dropdown/dropdown-components/dropdown-link";
import NewDropdownItem from "../../../../../../components/new-dropdown-item";
import { TailSpin } from "react-loader-spinner";
import TeamMemberDropdownUserRoles from "./TeamMemberDropdownUserRoles";
import useClickOutside from "../../../../../../hooks/useClickOutside";

interface TeamMemberDropdownProps<T extends number> {
    canUpdate: boolean;
    text: string;
    currentValue?: T | undefined;
    isSubmitting: boolean;
    onDropdownItemSelect: (value: T) => void;
    options: Array<DropdownItem<T>>;
    handleOpenSetApprovalLimitModal?: () => void;
    roleType?: boolean;
}

function TeamMemberDropdown<T extends number>(props: TeamMemberDropdownProps<T>): JSX.Element {
    const { roleType = false } = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [tempText, setTempText] = useState<string | null>(null);

    const domNode = useClickOutside(() => setIsDropdownOpen(false));

    const toggleDropdown = useCallback(() => {
        setIsDropdownOpen((prev) => !prev);
    }, []);

    const closeDropdown = useCallback(() => {
        setIsDropdownOpen(false);
    }, []);

    // when it finishes submitting, clear tempText
    useEffect(() => {
        if (!props.isSubmitting) {
            setTempText(null);
        }
    }, [props.isSubmitting]);

    return (
        <div className="relative w-max max-w-max" ref={domNode}>
            <div
                className={
                    `flex h-6 max-w-max items-center justify-center rounded-md border border-grey px-2 py-1 text-xs ${props.canUpdate ? "cursor-pointer bg-white hover:bg-grey-tertiary" : "bg-grey-tertiary"} ` +
                    "relative mb-1 cursor-default whitespace-nowrap text-black-secondary"
                }
                onClick={toggleDropdown}
            >
                {props.isSubmitting && tempText ? tempText : props.text}
                {props.canUpdate && (
                    <>
                        {props.isSubmitting && (
                            <div className="pointer-events-none ml-3 flex h-full items-center justify-center">
                                <TailSpin color="#5466F9" height={15} width={15} />
                            </div>
                        )}

                        {!props.isSubmitting && (
                            <div
                                className={`ml-2 flex transform items-center transition-transform duration-300 ease-in-out ${
                                    isDropdownOpen ? "-rotate-180" : "rotate-0"
                                }`}
                            >
                                <span className="material-symbols-outlined text-sm text-black-secondary">keyboard_arrow_down</span>
                            </div>
                        )}
                    </>
                )}
            </div>

            {props.canUpdate && (
                <div
                    className={
                        "absolute top-6 z-50 w-max max-w-max rounded-b-md bg-white shadow " +
                        " left-0 mt-1 origin-top-left transform rounded-md transition-none duration-300 ease-in-out" +
                        " w-full [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary" +
                        ` ${!isDropdownOpen ? " pointer-events-none scale-0 opacity-0" : " scale-100 opacity-100"}`
                    }
                >
                    {roleType &&
                        props.options.map((item) => {
                            return (
                                <TeamMemberDropdownUserRoles
                                    data={item}
                                    key={item.value}
                                    roleType={roleType}
                                    handleSetApprovalLimit={props.handleOpenSetApprovalLimitModal}
                                />
                            );
                        })}
                    {!roleType &&
                        props.options.map((item) => {
                            return (
                                <NewDropdownItem
                                    key={item.value}
                                    isDisabled={props.currentValue === item.value}
                                    padding="sm"
                                    size="sm"
                                    onClick={() => {
                                        setTempText(item.text);
                                        props.onDropdownItemSelect(item.value);
                                        closeDropdown();
                                    }}
                                >
                                    <p className="text-xs">{item.text}</p>
                                </NewDropdownItem>
                            );
                        })}
                </div>
            )}
        </div>
    );
}

export default TeamMemberDropdown;
