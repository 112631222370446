import React, { useRef } from "react";
import Transaction from "../../../../../models/transaction";
import { TransactionStatus } from "../../../../../models/transaction.constants";
import formatNumber from "../../../../../utils/formatNumber";
import { formatTime } from "../../../../../utils/formatDate";
import { setGlobalActiveDataType } from "../../../../../redux/init/slice/initSlice";
import { showTransactionDetails } from "../../../../../redux/transaction/slice/transactionSlice";
import titleCase from "../../../../../hooks/titleCase";
import { useDispatch } from "react-redux";
import CurrencyCode from "../../../../../components/currency-code";

interface CardDetailsRowInterface {
    data: Transaction;
    onClick: () => void;
}

function CardTransactionRow(props: CardDetailsRowInterface): JSX.Element {
    const dispatch = useDispatch();

    const trRef = useRef<HTMLTableRowElement | null>(null);

    const cardTransactionName = props.data.cardPayment?.merchant || props.data.origination?.card?.name || props.data.destination?.card?.name || "";

    return (
        <>
            <tr
                className="cursor-pointer"
                data-type="card"
                ref={trRef}
                onClick={() => {
                    props.onClick();
                    trRef.current?.scrollIntoView();
                    dispatch(setGlobalActiveDataType("card"));
                    dispatch(showTransactionDetails(props.data.id));
                }}
            >
                <td data-type="card" className="relative truncate whitespace-nowrap py-4 pr-4 font-normal">
                    <div className="flex items-center justify-start space-x-4" data-type="card">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-senary text-blue" data-type="card">
                            {cardTransactionName.slice(0, 1)}
                        </span>
                        <div data-type="card">
                            <p className="m-0 max-w-10 truncate text-sm text-black-secondary" data-type="card">
                                {titleCase(cardTransactionName || "")}
                            </p>
                            {props.data.singleDatetime && (
                                <span className="text-xs text-black-tertiary" data-type="card">
                                    {formatTime(props.data.singleDatetime)}
                                </span>
                            )}
                        </div>
                    </div>
                </td>
                <td data-type="card" className="p-4 text-right">
                    {props.data.status === TransactionStatus.SUCCESS && props.data.isDebit() && (
                        <span className="flex items-center justify-end text-black-secondary" data-type="card">
                            <span>-</span> <CurrencyCode data-type="card" />
                            {formatNumber(props.data.amount)}
                        </span>
                    )}
                    {props.data.status === TransactionStatus.SUCCESS && props.data.isCredit() && (
                        <span className="text-right text-success" data-type="card">
                            <CurrencyCode data-type="card" />
                            {formatNumber(props.data.amount)}
                        </span>
                    )}
                    {(props.data.status === TransactionStatus.PENDING_APPROVAL ||
                        props.data.status === TransactionStatus.PROCESSING ||
                        props.data.isActivationPending) && (
                        <span className="text-right text-black-secondary" data-type="card">
                            <CurrencyCode data-type="card" />
                            {formatNumber(props.data.amount)}
                        </span>
                    )}
                    {(props.data.status === TransactionStatus.DECLINED ||
                        props.data.status === TransactionStatus.CANCELLED ||
                        props.data.status === TransactionStatus.FAILED) && (
                        <span className="text-right text-black-secondary line-through" data-type="card">
                            <CurrencyCode data-type="card" />
                            {formatNumber(props.data.amount)}
                        </span>
                    )}
                </td>
            </tr>
        </>
    );
}

export default CardTransactionRow;
