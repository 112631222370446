import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { accountInitFailure, accountInitSuccess, initRequest } from "../../../redux/init/slice/initSlice";
import { useLocation, useNavigate } from "react-router-dom";

import { AccountInitState } from "../../../redux/init/slice/initSlice.types";
import { AxiosError } from "axios";
import { CompanyInterface } from "../../../helpers/types";
import IntercomHelper from "../../../helpers/intercom";
import { accountInit } from "../services/init-api";
import errorhandler from "../../../helpers/request/error-handler";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { useDispatch } from "react-redux";

function useAccountInit(): UseMutationResult<AccountInitState, AxiosError | null, void, unknown> {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const accountInitApi = useMutation({
        mutationFn: async () => {
            dispatch(initRequest());
            // dispatch(resetZambiaAllApplicationState());
            // dispatch(resetAllApplicationState());
            return await accountInit();
        },
        onSuccess(data) {
            // if there is only one company or if there is selectedCompanyId, select it
            let selectedCompany: CompanyInterface | null = null;

            selectedCompany =
                data.companies.length === 1 ? data.companies[0] : data.companies.find((_company) => _company.id === data.selectedCompanyId) || null;

            dispatch(
                accountInitSuccess({
                    account: data,
                    selectedCompanyId: selectedCompany?.id,
                })
            );
            IntercomHelper.update({
                user_id: data.user.id,
                email: data.user.email,
                name: data.user.name,
                user_hash: data.user.intercomHash, // HMAC using SHA-256
            });

            //routing issues
            // if (!isNullOrUndefined(selectedCompany?.accountCreated)) navigate(selectedCompany?.accountCreated ? "/" : "/application");
            if (
                !isNullOrUndefined(selectedCompany?.accountCreated) &&
                !selectedCompany?.accountCreated &&
                !location.pathname.includes("/application")
            )
                navigate("/application");
        },
        onError(error: AxiosError) {
            dispatch(accountInitFailure(getErrorMessage(error)));
            errorhandler(error);
        },
    });

    return accountInitApi;
}

export default useAccountInit;
