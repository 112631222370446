import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../redux/rootReducer";
import UserApp from "../../../../../../models/userApp";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { mainUpdateUserMobileApp } from "../../../../../../redux/init/slice/initSlice";
import { unlinkMobileApp } from "./mobile-app.api";

export interface UseUnlinkMobileAppResponse {
    isLinked: boolean;
    deviceName: string | null;
    handleUnlinkMobileApp: () => Promise<void>;
    isUnlinking: boolean;
}

function useUnlinkMobileApp(): UseUnlinkMobileAppResponse {
    const isLinked = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.hasMobileApp || false);
    const deviceName = useSelector<IRootState, string | null>((state) => state.init.main?.companyDetails.user.mobileApp.device || null);

    const dispatch = useDispatch();

    const [isUnlinking, setIsUnlinking] = useState<boolean>(false);

    const handleUnlinkMobileApp = useCallback(async () => {
        setIsUnlinking(true);

        try {
            await unlinkMobileApp();
            dispatch(mainUpdateUserMobileApp(UserApp.createDefault()));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsUnlinking(false);
        }
    }, [dispatch]);

    return {
        isLinked,
        deviceName,
        handleUnlinkMobileApp,
        isUnlinking,
    };
}

export default useUnlinkMobileApp;
