import {
    resetZambiaPayoutsDateFilterState,
    resetZambiaPayoutsFilterState,
    setIsZambiaPayoutsPaginationLoading,
    setZambiaPayoutsList,
    setZambiaPayoutsListGroupSize,
    setZambiaPayoutsListTotal,
    setZambiaPayoutsPaginationOffset,
} from "../../../../../redux/zambia/payouts/zambiaPayoutsSlice";

import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { ZambiaPayoutsRequest } from "../../Services/Payouts/zambiaPayoutsApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useAppSelector } from "../../../../../redux/hooks";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { zambiaPayouts } from "../../Services/Payouts/zambiaPayoutsApi";
import { FilterItemSelectType, Item, TableFilterProps } from "../../../../../components/Table/components/TableFilter";
import { DateObj } from "../../../../../components/Table/Type";
import { TransactionStatus } from "../../../../../models/transaction.constants";
import { ZambiaPayoutsStatusOptions, ZambiaPayoutsUserAccountIdsOptions } from "./zambia-payouts.constants";

function useZambiaPayouts() {
    const dispatch = useDispatch();

    const [filter, setFilter] = useState<ZambiaPayoutsRequest>({
        statuses: [],
        userAccountIds: [],
    });
    const selectedZambiaPayoutsFilterState = useAppSelector((state) => state.zambiaPayouts.selectedZambiaPayoutsFilterState);
    const zambiaPayoutsList = useAppSelector((state) => state?.zambiaPayouts?.zambiaPayoutsList);
    const zambiaPayoutsListTotal = useAppSelector((state) => state?.zambiaPayouts?.zambiaPayoutsListTotal);
    const zambiaPayoutsListGroupSize = useAppSelector((state) => state?.zambiaPayouts?.zambiaPayoutsListGroupSize);
    const zambiaPayoutsPaginationOffset = useAppSelector((state) => state?.zambiaPayouts?.zambiaPayoutsPaginationOffset);
    const isZambiaPayoutsPaginationLoading = useAppSelector((state) => state?.zambiaPayouts?.isZambiaPayoutsPaginationLoading);

    const handleGetZambiaPayouts = useCallback(
        async (_data: ZambiaPayoutsRequest) => {
            try {
                dispatch(setIsZambiaPayoutsPaginationLoading(true));
                const res = await zambiaPayouts(_data);
                dispatch(setZambiaPayoutsList(res.transactions));
                dispatch(setZambiaPayoutsListTotal(res.total));
                dispatch(setZambiaPayoutsListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsZambiaPayoutsPaginationLoading(false));
            }
        },
        [dispatch]
    );

    const handleFilteredZambiaPayouts = useCallback(
        async (_data: ZambiaPayoutsRequest) => {
            try {
                dispatch(setIsZambiaPayoutsPaginationLoading(true));
                const res = await zambiaPayouts(_data);
                dispatch(setZambiaPayoutsList(res.transactions));
                dispatch(setZambiaPayoutsListTotal(res.total));
                dispatch(setZambiaPayoutsListGroupSize(res.groupSize));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsZambiaPayoutsPaginationLoading(false));
            }
        },
        [selectedZambiaPayoutsFilterState]
    );

    const handlePaginationRequest = useCallback((offset: number) => {
        dispatch(setZambiaPayoutsPaginationOffset(offset));
        handleFilteredZambiaPayouts({ offset });
    }, []);

    const handleClearAll = useCallback(() => {
        dispatch(resetZambiaPayoutsFilterState());
        dispatch(resetZambiaPayoutsDateFilterState());
        void handleGetZambiaPayouts({
            query: "",
            offset: 0,
            statuses: undefined,
            userAccountIds: undefined,
            date: {
                begin: "",
                end: "",
            },
        });
    }, []);

    const handleClearFilter = useCallback(async () => {
        setFilter({
            query: "",
            offset: 0,
            statuses: [],
            userAccountIds: [],
            date: {
                begin: "",
                end: "",
            },
        });
        await handleFilteredZambiaPayouts({ offset: 0, query: "" });
    }, [filter]);

    const filterOption: TableFilterProps = useMemo(
        () =>
            ({
                items: [
                    {
                        text: "Date",
                        selectType: FilterItemSelectType.DATE_OPTION,
                        onClick: (data) => {
                            setFilter((prev) => ({ ...prev, date: { begin: (data as DateObj)?.begin, end: (data as DateObj)?.end } }));
                        },
                    },
                    {
                        text: "Status",
                        selectType: FilterItemSelectType.CHECKBOX_OPTION,
                        subList: ZambiaPayoutsStatusOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: TransactionStatus }) => {
                            if (filter?.statuses?.includes(data?.value)) {
                                filter.statuses = filter.statuses?.filter((_el) => _el != data?.value);
                            } else {
                                filter.statuses?.push(data?.value);
                            }
                            setFilter({ ...filter });
                        },
                    },
                    {
                        text: "Account",
                        selectType: FilterItemSelectType.CHECKBOX_OPTION,
                        subList: ZambiaPayoutsUserAccountIdsOptions.map((_) => ({ text: _.name, value: _.value })),
                        onClick: (data: { value: string }) => {
                            if (filter?.userAccountIds?.includes(data?.value)) {
                                filter.userAccountIds = filter.userAccountIds?.filter((_el) => _el != data?.value);
                            } else {
                                filter.userAccountIds?.push(data?.value);
                            }
                            setFilter({ ...filter });
                        },
                    },
                ] as Item[],
                handleApplyFilter: async () => {
                    await handleFilteredZambiaPayouts(filter);
                },
            }) as TableFilterProps,
        [filter]
    );

    return {
        zambiaPayoutsList,
        zambiaPayoutsListTotal,
        zambiaPayoutsListGroupSize,
        zambiaPayoutsPaginationOffset,
        isZambiaPayoutsPaginationLoading,
        filter,
        filterOption,
        handleClearFilter,
        handleClearAll,
        handlePaginationRequest,
        handleFilteredZambiaPayouts,
    };
}

export default useZambiaPayouts;
