import { AgreementType } from "../../../../../../../services/nigeria-application/registered/new/agreement.types";
import AgreementsPreviewItem from "../AgreementsPreviewItem";
import BusinessContactReview from "../review/BusinessContactReview";
// import { ReactComponent as BusinessDocumentIcon } from "../../../../../../../../../../assets/svg/general/Documents/business-document.svg";
import BusinessDocumentPreviewItem from "../BusinessDocumentPreviewItem";
import BusinessInformationReview from "../review/BusinessInformationReview";
import BusinessNextOfKinReview from "../review/BusinessNextOfKinReview";
import BusinessPersonalDetailsReview from "../review/BusinessPersonalDetailsReview";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import { CompanyDocumentTypes } from "../../../../../../../services/nigeria-application/registered/document.types";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import OtherDirectorCard from "../../../account-signatories/components/signatories/components/OtherDirectorCard";
// import { ReactComponent as PhotographIcon } from "../../../../../../../../../../assets/svg/general/User/user-photograph.svg";
import PreviewCard from "../../../../../../cards/PreviewCard";
import React from "react";
import RefereeCard from "../../../invite-referees/components/RefereeCard";
import { Routes } from "../../../../../../../../../../routes/routes.constants";
import SignatoryCard from "../../../account-signatories/components/signatories/components/SignatoryCard";
// import { ReactComponent as SignatureIcon } from "../../../../../../../../../../assets/svg/general/Signature/signature.svg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

interface Props {
    handlePreviewDocument: (_data: string) => void;
    handlePreviewAgreementDocument: (_data: AgreementType) => void;
}

function CreatorReview(props: Props): JSX.Element {
    const navigate = useNavigate();
    const referees = useSelector((state: IRootState) => state.application.referees);
    const agreement = useSelector((state: IRootState) => state.application.agreement?.agreements);
    const signatories = useSelector((state: IRootState) => state.application.accountSignatories?.signatories || []);
    const accountOpening = useSelector((state: IRootState) => state.application.init);
    const otherDirectors = useSelector((state: IRootState) => state.application.accountSignatories?.otherDirectors || []);
    const businessDetails = useSelector((state: IRootState) => state.application.businessDetails);
    const currentSignatory = useSelector((state: IRootState) => state.application.currentSignatory);
    const businessDocuments = useSelector((state: IRootState) => state.application.businessDocuments);
    const canHaveBoardResolution = useSelector((state: IRootState) => state.application.agreement?.canHaveBoardResolution);

    // const formC02Id = businessDocuments?.incorporationDocuments.find((_doc) => _doc.type === CompanyDocumentTypes.FORM_C02)?.uploadId;
    const certificateId = businessDocuments?.incorporationDocuments.find((_doc) => _doc.type === CompanyDocumentTypes.CERTIFICATE)?.uploadId;
    // const formC07Id = businessDocuments?.incorporationDocuments.find((_doc) => _doc.type === CompanyDocumentTypes.FORM_C07)?.uploadId;
    const incorporationDocumentId = businessDocuments?.incorporationDocuments.find(
        (_doc) => _doc.type === CompanyDocumentTypes.INCORPORATION_DOCUMENT
    )?.uploadId;
    const memorandumOfAssociationId = businessDocuments?.incorporationDocuments.find(
        (_doc) => _doc.type === CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION
    )?.uploadId;
    const scumlCertificateId = businessDocuments?.incorporationDocuments.find(
        (_doc) => _doc.type === CompanyDocumentTypes.SCUML_CERTIFICATE
    )?.uploadId;
    const utilityBillId = businessDocuments?.utilityBill?.uploadId;

    return (
        <>
            {accountOpening && (
                <>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Business information</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.INFORMATION,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessInformationReview data={businessDetails} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Business Contact Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.COMPANY.CONTACT,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessContactReview data={businessDetails} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="w-full text-left font-medium text-black-secondary">Account Signatories</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.DETAILS,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <div
                            className={
                                "grid w-full gap-4 " + `${signatories.length === 1 ? "grid-cols-1 xl:grid-cols-2" : "grid-cols-1 sm:grid-cols-2"}`
                            }
                        >
                            {signatories.map((_signatories, index) => (
                                <SignatoryCard key={index} data={_signatories} isReview />
                            ))}
                        </div>
                    </div>
                    {otherDirectors.length > 0 && (
                        <div className="flex w-full flex-col items-center justify-start space-y-4">
                            <div className="flex w-full flex-row items-center justify-between">
                                <h3 className="w-full text-left font-medium text-black-secondary">Other Directors</h3>
                                <ButtonComp
                                    type="button"
                                    color="blue"
                                    ripple="light"
                                    buttonType="tertiary"
                                    func={() =>
                                        navigate(
                                            {
                                                pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.DETAILS,
                                            },
                                            {
                                                state: {
                                                    from: Routes.ACCOUNT_OPENING.REVIEW,
                                                },
                                            }
                                        )
                                    }
                                    size="sm"
                                >
                                    <span>Change</span>
                                </ButtonComp>
                            </div>
                            <div
                                className={
                                    "grid w-full gap-4 " +
                                    `${otherDirectors.length === 1 ? "grid-cols-1 xl:grid-cols-2" : "grid-cols-1 sm:grid-cols-2"}`
                                }
                            >
                                {otherDirectors.map((_otherDirectors, index) => (
                                    <OtherDirectorCard key={index} data={_otherDirectors} isReview />
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Personal Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PERSONAL_DETAILS,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessPersonalDetailsReview data={currentSignatory || undefined} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Next of Kin</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.NEXT_OF_KIN,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <BusinessNextOfKinReview data={currentSignatory?.nextOfKin || null} />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Means of ID</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.MEANS_OF_ID,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">add_notes</span>}
                            text={accountOpening?.options.idType?.find((_el) => _el.id === currentSignatory?.identification?.type)?.name || "-"}
                            canNotPreview={!currentSignatory?.identification?.fileId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.identification?.fileId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Photograph</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.PHOTOGRAPH,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">account_circle</span>}
                            text="Photograph"
                            canNotPreview={!currentSignatory?.identification?.fileId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.photoId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Signature Details</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.SIGNATURE,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <PreviewCard
                            icon={<span className="material-symbols-rounded text-xl text-black-tertiary">signature</span>}
                            text="Signatory Form"
                            canNotPreview={!currentSignatory?.signedDocumentId}
                            handlePreviewDocumentFunc={() => props.handlePreviewDocument(currentSignatory?.signedDocumentId || "")}
                        />
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Business Documents</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey p-4 [&>div:not(:last-child)]:border-b [&>div:not(:last-child)]:border-grey-secondary [&>div:not(:last-child)]:pb-4">
                            <BusinessDocumentPreviewItem
                                text="Incorporation Certificate"
                                isCanNotPreview={!certificateId}
                                handlePreviewDocumentFunc={() => props.handlePreviewDocument(certificateId || "")}
                            />
                            {businessDocuments?.applicationMin.isLLC && (
                                <BusinessDocumentPreviewItem
                                    text="Memorandum and Articles of Association"
                                    isCanNotPreview={!memorandumOfAssociationId}
                                    handlePreviewDocumentFunc={() => props.handlePreviewDocument(memorandumOfAssociationId || "")}
                                />
                            )}
                            <BusinessDocumentPreviewItem
                                text="Status Report"
                                isCanNotPreview={!incorporationDocumentId}
                                handlePreviewDocumentFunc={() => props.handlePreviewDocument(incorporationDocumentId || "")}
                            />

                            {businessDetails?.industry?.requireSCUML && (
                                <BusinessDocumentPreviewItem
                                    text="SCUML Certificate"
                                    isCanNotPreview={!scumlCertificateId}
                                    handlePreviewDocumentFunc={() => props.handlePreviewDocument(scumlCertificateId || "")}
                                />
                            )}
                            <BusinessDocumentPreviewItem
                                text="Utility Bill"
                                isCanNotPreview={!utilityBillId}
                                handlePreviewDocumentFunc={() => props.handlePreviewDocument(utilityBillId || "")}
                            />
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Agreements</h3>
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.AGREEMENT,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                        </div>
                        <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg border border-grey p-4 [&>div:not(:last-child)]:border-b [&>div:not(:last-child)]:border-grey-secondary [&>div:not(:last-child)]:pb-4">
                            <AgreementsPreviewItem
                                text="Account Mandate"
                                isCanNotPreview={!agreement?.mandateCard.signedDocumentId}
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.ACCOUNT_MANDATE_CARD)}
                            />
                            <AgreementsPreviewItem
                                text="Authority to Debit Account for Search Report"
                                isCanNotPreview={!agreement?.searchReport.signedDocumentId}
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.SEARCH_REPORT)}
                            />
                            <AgreementsPreviewItem
                                text="Account Opening Declaration"
                                isCanNotPreview={!agreement?.declaration.signedDocumentId}
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.ACCOUNT_OPENING_DECLARATION)}
                            />
                            <AgreementsPreviewItem
                                text="Partner Bank's Terms and Conditions"
                                isCanNotPreview={!agreement?.terms.signedDocumentId}
                                handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.TERMS_AND_CONDITIONS)}
                            />
                            {canHaveBoardResolution && (
                                <AgreementsPreviewItem
                                    text="Board Resolution"
                                    isCanNotPreview={!agreement?.boardResolution.signedDocumentId}
                                    handlePreviewDocumentFunc={() => props.handlePreviewAgreementDocument(AgreementType.BOARD_RESOLUTION)}
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                        <div className="flex w-full flex-row items-center justify-between">
                            <h3 className="font-medium text-black-secondary">Referees</h3>
                            {/* {!accountOpening.progress.isRefereesCompleted() && ( */}
                            <ButtonComp
                                type="button"
                                color="blue"
                                ripple="light"
                                buttonType="tertiary"
                                func={() =>
                                    navigate(
                                        {
                                            pathname: Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS,
                                        },
                                        {
                                            state: {
                                                from: Routes.ACCOUNT_OPENING.REVIEW,
                                            },
                                        }
                                    )
                                }
                                size="sm"
                            >
                                <span>Change</span>
                            </ButtonComp>
                            {/* )} */}
                        </div>
                        <div className="grid w-full grid-cols-1 gap-4 2xs:grid-cols-2">
                            {!accountOpening.applicationMin.requestedProAccount &&
                                referees &&
                                referees.length === 2 &&
                                referees.map((_referee, index) => <RefereeCard data={_referee} key={index} isReview />)}
                            {!accountOpening.applicationMin.requestedProAccount && referees && referees.length === 1 && (
                                <>
                                    {referees.map((_referee, index) => (
                                        <RefereeCard data={_referee} key={index} isReview />
                                    ))}
                                    <RefereeCard isNull isReview />
                                </>
                            )}
                            {(!referees || referees.length === 0 || accountOpening.applicationMin.requestedProAccount) && (
                                <>
                                    <RefereeCard isNull isReview />
                                    <RefereeCard isNull isReview />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default CreatorReview;
