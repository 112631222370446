import Modal, { ModalSize } from "./Modal";

import { ButtonColor } from "../button/button.constant";
import ButtonComp from "../button/ButtonComp";
import { ConfirmModalProps } from "./confirm-modal";
import ErrorToast from "../message-toast/sub/error-toast";
import ModalBody from "./modal-body";
import ModalFooter from "./modal-footer";
import ModalHeader from "./modal-header";
import { ToastType } from "../../helpers/app-constants";

export interface SimpleModalProps extends Omit<ConfirmModalProps, "onConfirm"> {
    size?: ModalSize;
    canSubmit?: boolean;
    isLoading?: boolean;
    cancelText?: string;
    confirmText?: string;
    headingType?: ToastType;
    confirmColor?: ButtonColor;
    onSubmit: () => void;
}

function SimpleModal(props: SimpleModalProps): JSX.Element {
    return (
        <Modal size={props.size ?? "md"} active={props.active} toggler={props.onClose}>
            <ModalHeader onClose={props.onClose}>{props.header}</ModalHeader>

            <form
                className="w-full"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (props.canSubmit !== false) {
                        props.onSubmit();
                    }
                }}
            >
                <ModalBody>
                    <div className="flex w-full flex-col">
                        {props.errorMessage && (
                            <div className="w-full pb-2">
                                <ErrorToast error={props.errorMessage} />
                            </div>
                        )}
                        <div className="flex w-full flex-col items-start justify-start break-words text-sm font-normal">{props.children}</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp size="lg" color="grey" type="button" buttonType="secondary" onClick={props.onClose}>
                        <span className="w-full text-center">{props.cancelText || "Cancel"}</span>
                    </ButtonComp>
                    <ButtonComp
                        size="lg"
                        type="submit"
                        color={props.confirmColor || "black"}
                        buttonType="primary"
                        disable={props.canSubmit === false}
                        isLoading={props.isSubmitting || props.isLoading}
                    >
                        <span className="px-3">{props.confirmText || "Submit"}</span>{" "}
                    </ButtonComp>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default SimpleModal;
