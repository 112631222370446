import { useCallback, useEffect } from "react";

import { IRootState } from "../../../../../../../redux/rootReducer";
import useCableTVPurchaseVerifyAccount from "./useCableTVPurchaseVerifyAccount";
import { useDispatch, useSelector } from "react-redux";
import {
    resetCableTVVerifiedAccountName,
    setCableTVPurchaseSmartCardNumber,
} from "../../../../../../../redux/payments/cableTVPurchase/slice/cableTVPurchaseSlice";

interface UseCableTVPurchasePayToInterface {
    smartCardNumber: string;
    cableTVPurchaseVerifyAccountError: string;
    isCableTVPurchaseVerifyAccountLoading: boolean;

    handleOnChange: (_vendor: string) => void;
}

function useCableTVPurchasePayTo(): UseCableTVPurchasePayToInterface {
    const { cableTVPurchaseVerifyAccountError, handleCableTVPurchase, handleResetError, isCableTVPurchaseVerifyAccountLoading } =
        useCableTVPurchaseVerifyAccount();
    const dispatch = useDispatch();
    const productId = useSelector((state: IRootState) => state.cableTVPurchase.cableTVPurchaseDetails.productId);
    const smartCardNumber = useSelector((state: IRootState) => state.cableTVPurchase.smartCardNumber);
    const cableTVVerifiedAccountName = useSelector((state: IRootState) => state.cableTVPurchase.cableTVVerifiedAccountName);
    // const [smartCardNumber, setSmartCardNumber] = useState("");

    useEffect(() => {
        if (smartCardNumber.length < 10 || cableTVVerifiedAccountName) return;
        void handleCableTVPurchase({
            productId,
            accountId: smartCardNumber,
        });
    }, [smartCardNumber, cableTVVerifiedAccountName]);

    useEffect(() => {
        if (smartCardNumber.length === 10) return;
        dispatch(resetCableTVVerifiedAccountName());
    }, [smartCardNumber]);

    useEffect(() => {
        if (smartCardNumber.length !== 9) return;
        handleResetError();
    }, [smartCardNumber]);

    const handleOnChange = useCallback((_smartCardNumber: string) => {
        dispatch(setCableTVPurchaseSmartCardNumber(_smartCardNumber));
    }, []);

    return {
        smartCardNumber,
        cableTVPurchaseVerifyAccountError,
        isCableTVPurchaseVerifyAccountLoading,

        handleOnChange,
    };
}

export default useCableTVPurchasePayTo;
