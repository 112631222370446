export enum CardType {
    NGN_PHYSICAL = 1,
}

export enum CardCurrency {
    NGN = 1,
}

export enum CardScheme {
    MASTERCARD = 1,
    VERVE = 2,
    VISA = 3,
}

export enum CardStatus {
    UNACTIVATED = 1,
    ACTIVE = 2,
    DEACTIVATED = 3,
    EXPIRED = 4,
    FROZEN = 5,
}

export enum CardWalletType {
    LINKED_TO_ACCOUNT = 1, // direct debit
    INDEPENDENT_WALLET = 2, // prepaid
}

export enum CardTransactionChannel {
    WEB = 1,
    ATM = 2,
    POS_TERMINAL = 3,
}

export enum CardSpendLimitType {
    SINGLE_PAYMENT = 1,
    DAILY_ACCUMULATIVE = 2,
    WEEKLY_ACCUMULATIVE = 3,
    MONTHLY_ACCUMULATIVE = 4,
}

export enum CreateCardRequestBankingAppStatus {
    REQUESTED = 1,
    APPROVED = 2,
    DECLINED = 3,
    PRINTED = 4,
    SHIPPED = 5,
    DELIVERED = 6,
}

export enum CardIssuer {
    MASTERCARD = 1,
    VERVE = 2,
    VISA = 3,
}
