import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
    resetAccountsData,
    resetIndividualAccount,
    resetSubAccountData,
    setSubAccountModalStage,
} from "../../../../redux/accounts/slice/accountsSlice";
import { useDispatch, useSelector } from "react-redux";

import AccountCard from "../Components/Cards/AccountCard";
import AccountCardSkeleton from "../Components/Skeleton/AccountCardSkeleton";
import { AccountModalStageType } from "../../../../redux/accounts/slice/accountsSlice.types";
import AccountsProcessingCard from "../Components/Cards/AccountsProcessingCard";
import ButtonComp from "../../../../components/button/ButtonComp";
import { CorporateAccountType } from "../../../../models/corporate.constants";
import CreateSubAccountTray from "../Components/Trays/CreateSubAccountTray";
import EditAccountDescriptionModal from "../Components/Modals/EditAccountDescriptionModal";
import { IRootState } from "../../../../redux/rootReducer";
import PageLayout from "../../../../components/layouts/page-layout";
import { PageTitle } from "../../../../helpers/AppConstants";
import { ReactComponent as Plus } from "../../../../assets/svg/Button/blue-circled-plus.svg";
// import SubAccountSuccessfulModal from "../Components/Modals/SubAccountSuccessfulModal";
import UserAccount from "../../../../models/userAccount";
import { abortAccountTransfer } from "../Services/accounts-api";
import useAccountsList from "../Hooks/State/useAccountsList";
import { useNavigate } from "react-router";

function Accounts(): JSX.Element {
    document.title = PageTitle.ACCOUNTS_PAGE;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleAccountsDetails, isAccountsDetailsLoading } = useAccountsList();
    const main = useSelector((state: IRootState) => state.init.main);
    const isAdmin = useSelector((state: IRootState) => state.init.main?.companyDetails.user.isAdmin || false);
    const loading = useSelector((state: IRootState) => state.init.loading);
    const listOfAccountsIds = useSelector((state: IRootState) => state.account.accounts);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts || []);
    const pendingAccounts = useSelector((state: IRootState) => state.account.pendingAccounts);
    const isEditAccountDescriptionModalOpen = useSelector((state: IRootState) => state.account.isEditAccountDescriptionModalOpen);
    const subAccountModalStage = useSelector((state: IRootState) => state.account.subAccountModalStage);
    const companyAccountType = useSelector((state: IRootState) =>
        state.init.main?.meta.accountTypes.find((t) => t.type === state.init.main?.companyDetails.company.type)
    );

    // const accountType = useSelector((state: IRootState) => state.init.main?.meta.accountTypes.find((t) => t.type === data.type));

    const [listOfAccounts, setListOfAccounts] = useState<UserAccount[]>([]);

    useLayoutEffect(() => {
        if (!main) return;
        if (main.companyDetails.company.type === CorporateAccountType.STARTER && main.companyDetails.accounts.length < 2) {
            return navigate(`/accounts/${main.companyDetails.accounts[0].id}`);
        }
    }, [main]);

    useEffect(() => {
        if (loading) return;
        void handleAccountsDetails();
    }, [loading]);

    useEffect(() => {
        if (listOfAccountsIds.length < 1 || accounts.length < 1) return;
        setListOfAccounts(listOfAccountsIds.map((a) => accounts.find((_a) => _a.id === a) as UserAccount).filter((el) => el !== undefined));
    }, [listOfAccountsIds, accounts]);

    useEffect(() => {
        if (isEditAccountDescriptionModalOpen) return;
        dispatch(resetIndividualAccount());
    }, [isEditAccountDescriptionModalOpen]);

    useEffect(() => {
        return () => {
            dispatch(resetAccountsData());
            abortAccountTransfer();
        };
    }, []);

    const handleOpenCreateSubAccountModal = useCallback(() => {
        dispatch(setSubAccountModalStage(AccountModalStageType.DETAILS));
    }, []);

    const handleCloseCreateSubAccountModal = useCallback(() => {
        dispatch(resetSubAccountData());
    }, []);

    // console.log(accounts.filter((_) => !_.isActivated));

    return (
        <>
            <EditAccountDescriptionModal />
            {main && (
                <>
                    <CreateSubAccountTray
                        active={!!(subAccountModalStage === AccountModalStageType.DETAILS)}
                        toggler={handleCloseCreateSubAccountModal}
                    />
                    <PageLayout
                        pageTitle="Accounts"
                        subTitle="List of your Lenco accounts"
                        headerButtons={
                            companyAccountType?.subAccount &&
                            isAdmin && (
                                <ButtonComp
                                    color="grey"
                                    type="button"
                                    buttonType="secondary"
                                    radiusSize="xs"
                                    size="md"
                                    func={handleOpenCreateSubAccountModal}
                                >
                                    <div className="flex items-center justify-center space-x-1">
                                        <Plus className="stroke-current" />
                                        <span>Add Account</span>
                                    </div>
                                </ButtonComp>
                            )
                        }
                        isWithShadowContent
                    >
                        <div className="grid gap-5 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                            {!isAccountsDetailsLoading &&
                                listOfAccounts.length > 0 &&
                                listOfAccounts.map((userAccount) => <AccountCard data={userAccount} key={userAccount.id} />)}

                            {!isAccountsDetailsLoading &&
                                pendingAccounts?.length > 0 &&
                                pendingAccounts.map((_account, index) => <AccountsProcessingCard key={index} data={_account} />)}
                            {isAccountsDetailsLoading && (
                                <>
                                    <AccountCardSkeleton />
                                    <AccountCardSkeleton />
                                    <AccountCardSkeleton />
                                </>
                            )}
                        </div>
                    </PageLayout>
                </>
            )}
        </>
    );
}

export default Accounts;
