import { PaymentStageType, PaymentType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { SendMoneyOptionType, ZambiaSendMoneyOptionType } from "./send-money-dropdown.types";
import { setPaymentStage, setPaymentType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";

import AddFundsIcon from "../../../../../../assets/images/dashboard/send-money/add-money.png";
import BankTransferIcon from "../../../../../../assets/images/dashboard/send-money/single-transfer.png";
import BulkTransferIcon from "../../../../../../assets/images/dashboard/send-money/bulk-transfer.png";
import CardPaymentIcon from "../../../../../../assets/images/dashboard/send-money/bulk-transfer.png";
import FundCardIcon from "../../../../../../assets/images/dashboard/send-money/fund-card.png";
import MobileMoneyIcon from "../../../../../../assets/images/dashboard/send-money/fund-card.png";
import { NavigateFunction } from "react-router-dom";
import { PaymentsType } from "../../../../../../modules/dashboard/payments-new/hooks/state/send-money/payments.constants";
import SingleTransferIcon from "../../../../../../assets/images/dashboard/send-money/single-transfer.png";
import TransferBtwAccountsIcon from "../../../../../../assets/images/dashboard/send-money/transfer-between-accounts.png";
import store from "../../../../../../redux/store";

export interface SendMoneyOption {
    icon: string;
    text: string;
    type?: SendMoneyOptionType | ZambiaSendMoneyOptionType;
    subtext?: string;
    onClick?: (navigate: NavigateFunction, handleOpenAddFundsModal?: () => void) => void;
}

export const SendMoneyOptions: Array<SendMoneyOption> = [
    {
        icon: SingleTransferIcon,
        text: "Single Transfer",
        subtext: "Send money to a recipient",
        type: SendMoneyOptionType.SINGLE_TRANSFER,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
            navigate(
                {
                    pathname: "/payments/make/single",
                },
                {
                    state: {
                        typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                    },
                }
            );
        },
    },
    {
        icon: TransferBtwAccountsIcon,
        text: "Transfer Between Accounts",
        type: SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS,
        subtext: "Send money to your sub-account",
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
            navigate(
                {
                    pathname: "/payments/make/accounts",
                },
                {
                    state: { typeOfTransfer: PaymentsType.TRANSFER_BETWEEN_ACCOUNT },
                }
            );
        },
    },
    {
        icon: BulkTransferIcon,
        text: "Create a Bulk Transfer",
        type: SendMoneyOptionType.BULK_TRANSFER,
        subtext: "Send to multiple recipients or sub-accounts all at once",
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            navigate(
                {
                    pathname: "/payments/make/bulk",
                },
                {
                    state: { typeOfTransfer: PaymentsType.BULK_TRANSFER },
                }
            );
        },
    },
    {
        icon: AddFundsIcon,
        text: "Add Money",
        type: SendMoneyOptionType.ADD_MONEY,
        subtext: "Add money to your business accounts",
        onClick: function (navigate: NavigateFunction, handleOpenAddFundsModal): void {
            handleOpenAddFundsModal && handleOpenAddFundsModal();
        },
    },
    {
        icon: FundCardIcon,
        text: "Fund Card",
        type: SendMoneyOptionType.FUND_CARD,
        subtext: "Fund your prepaid corporate card",
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentType(PaymentType.FUND_CARDS));
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            navigate(
                {
                    pathname: "/payments/make/cards",
                },
                {
                    state: { typeOfTransfer: PaymentsType.FUND_CARD },
                }
            );
        },
    },
];

export const ZambiaSendMoneyOptions: Array<SendMoneyOption> = [
    {
        icon: SingleTransferIcon,
        text: "Single Transfer",
        type: SendMoneyOptionType.SINGLE_TRANSFER,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
            navigate(
                {
                    pathname: "/payments/make/single",
                },
                {
                    state: { typeOfTransfer: PaymentsType.SINGLE_TRANSFER },
                }
            );
        },
    },
    {
        icon: TransferBtwAccountsIcon,
        text: "Transfer Between Accounts",
        type: SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
            navigate(
                {
                    pathname: "/payments/make/accounts",
                },
                {
                    state: { typeOfTransfer: PaymentsType.TRANSFER_BETWEEN_ACCOUNT },
                }
            );
        },
    },
    // {
    // icon: BulkTransferIcon,
    // text: "Create a Bulk Transfer",
    // type: SendMoneyOptionType.BULK_TRANSFER,
    // onClick: function (navigate: NavigateFunction): void {
    // store.dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
    // store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
    // return navigate({
    // pathname: "/payments/make/bulk",
    // state: {typeOfTransfer: PaymentsType.BULK_TRANSFER},
    // });
    // },
    // },
    {
        icon: AddFundsIcon,
        text: "Add Money",
        type: SendMoneyOptionType.ADD_MONEY,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
            navigate({
                pathname: "/payments/add-money/make",
            });
        },
    },
    // {
    // icon: FundCardIcon,
    // text: "Fund Card",
    // type: SendMoneyOptionType.FUND_CARD,
    // onClick: function (navigate: NavigateFunction): void {
    // store.dispatch(setPaymentType(PaymentType.FUND_CARDS));
    // store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
    // return navigate({
    // pathname: "/payments/make/cards",
    // state: {typeOfTransfer: PaymentsType.FUND_CARD},
    // });
    // },
    // },
];

export const SendMoneyOptionsPreRelease: Array<SendMoneyOption> = [
    {
        icon: SingleTransferIcon,
        text: "Single Transfer",
        type: SendMoneyOptionType.SINGLE_TRANSFER,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
            navigate(
                {
                    pathname: "/payments/make/single",
                },
                {
                    state: { typeOfTransfer: PaymentsType.SINGLE_TRANSFER },
                }
            );
        },
    },
    {
        icon: TransferBtwAccountsIcon,
        text: "Transfer Between Accounts",
        type: SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
            navigate(
                {
                    pathname: "/payments/make/accounts",
                },
                {
                    state: { typeOfTransfer: PaymentsType.TRANSFER_BETWEEN_ACCOUNT },
                }
            );
        },
    },
    {
        icon: BulkTransferIcon,
        text: "Create a Bulk Transfer",
        type: SendMoneyOptionType.BULK_TRANSFER,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            store.dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
            navigate(
                {
                    pathname: "/payments/make/bulk",
                },
                {
                    state: { typeOfTransfer: PaymentsType.BULK_TRANSFER },
                }
            );
        },
    },
    {
        icon: AddFundsIcon,
        text: "Add Money",
        type: SendMoneyOptionType.ADD_MONEY,
        onClick: function (navigate: NavigateFunction, handleOpenAddFundsModal): void {
            return handleOpenAddFundsModal && handleOpenAddFundsModal();
        },
    },
    {
        icon: FundCardIcon,
        text: "Fund Card",
        type: SendMoneyOptionType.FUND_CARD,
        onClick: function (navigate: NavigateFunction): void {
            store.dispatch(setPaymentType(PaymentType.FUND_CARDS));
            store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
            navigate(
                {
                    pathname: "/payments/make/cards",
                },
                {
                    state: { typeOfTransfer: PaymentsType.FUND_CARD },
                }
            );
        },
    },
];

export const ZambiaAddMoneyInnerOptions: Array<SendMoneyOption> = [
    {
        icon: MobileMoneyIcon,
        text: "Mobile Money",
        type: ZambiaSendMoneyOptionType.MOBILE_MONEY,
        onClick: function (navigate: NavigateFunction): void {
            navigate({ pathname: "/payments/add-money/make/mobile-money" });
            return;
        },
    },
    {
        icon: CardPaymentIcon,
        text: "Card Payment",
        type: ZambiaSendMoneyOptionType.CARD_PAYMENT,
        onClick: function (navigate: NavigateFunction): void {
            navigate({ pathname: "/payments/add-money/make/card-payment" });
            return;
        },
    },
    {
        icon: BankTransferIcon,
        text: "Bank Transfer",
        type: ZambiaSendMoneyOptionType.BANK_TRANSFER,
        onClick: function (navigate: NavigateFunction): void {
            navigate({ pathname: "/payments/add-money/make/bank-transfer" });
            return;
        },
    },
];
