import React from "react";
interface Props {
    children: React.ReactNode;
}
function TeamMembersCardContainer(props: Props): JSX.Element {
    return (
        <>
            {/* <div className="flex flex-col space-y-4 w-full px-4 rounded-lg border divide-y pb-4">{props.children}</div> */}
            <div className="grid w-full grid-cols-1 gap-8 px-4 pb-4 md:grid-cols-2">{props.children}</div>
        </>
    );
}

export default TeamMembersCardContainer;
