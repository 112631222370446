import {
    abortAllNigerianUnregisteredCompanyRequests,
    updateUnregisteredBusinessInformation,
} from "../../../services/nigeria-application/unregistered/business.api";
import { errorTrue, messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { Routes } from "../../../../../../routes/routes.constants";
import { UpdateBusinessDetailsRequest } from "../../../services/nigeria-application/unregistered/business.types";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { setBusinessDetails } from "../../../../../../redux/application/applicationSlice";
import { useDispatch } from "react-redux";

export interface UseUnregisteredBusinessInformationInterface {
    businessInformationError: string | null;
    isBusinessInformationLoading: boolean;
    handleReset: () => void;
    handleUpdateBusinessInformation: (_data: UpdateBusinessDetailsRequest) => Promise<void>;
}
type LocationState = {
    from?: string;
};

const useUnregisteredBusinessInformation = (): UseUnregisteredBusinessInformationInterface => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const [businessInformationError, setBusinessInformationError] = useState<string | null>(null);
    const [isBusinessInformationLoading, setIsBusinessInformationLoading] = useState<boolean>(false);

    const handleUpdateBusinessInformation = useCallback(
        async (data: UpdateBusinessDetailsRequest): Promise<void> => {
            try {
                setBusinessInformationError(null);
                setIsBusinessInformationLoading(true);
                const res = await updateUnregisteredBusinessInformation(data);
                dispatch(setBusinessDetails(res));
                if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
                    dispatch(messageTrue("Business information updated successfully"));
                    return;
                }
                navigate(
                    {
                        pathname: Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL.DETAILS,
                    },
                    {
                        state: {
                            from: Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS,
                        },
                    }
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setBusinessInformationError(errorMessage);
                dispatch(errorTrue(errorMessage));
            } finally {
                setIsBusinessInformationLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = () => {
        setBusinessInformationError(null);
        setIsBusinessInformationLoading(false);
        abortAllNigerianUnregisteredCompanyRequests();
    };

    return {
        businessInformationError,
        isBusinessInformationLoading,
        handleReset,
        handleUpdateBusinessInformation,
    };
};
export default useUnregisteredBusinessInformation;
