import React, { useEffect, useState } from "react";

import { ReactComponent as CloseEyeIcon } from "../../../../../../assets/svg/Cards/CloseEye.svg";
import { GetCardPinRequest } from "../../../Services/cardsApi.types";
import { ReactComponent as OpenEyeIcon } from "../../../../../../assets/svg/Cards/OpenEye.svg";
import { TailSpin } from "react-loader-spinner";
import { ToastType } from "../../../../../../helpers/AppConstants";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";

interface Props {
    cardId: string;
    cardPin: string;
    isPrepaidCard: boolean;
    isGetCardPinLoading: boolean;
    handleGetCardPinCard: (_data: GetCardPinRequest) => void;
}

function RequestDefaultPIN({ cardId, cardPin, isGetCardPinLoading, handleGetCardPinCard }: Props): JSX.Element {
    const [showCardPin, setShowCardPin] = useState(true);

    useEffect(() => {
        if (!cardPin) handleGetCardPinCard({ createRequestId: cardId });
    }, [cardPin]);

    return (
        <div className="mt-4 w-full">
            {/* <div className="max-w-md space-y-3">
				<div className="flex items-center py-3 space-x-1">
					<SuccessIcon />
					<h3 className="text-base font-medium text-black leading-none">Card Activated Successfully</h3>
				</div>
			</div> */}
            <p className="text-sm font-normal text-black-tertiary">
                Your Default PIN is shown below. Change your pin at any ATM close to you before you can use your card
            </p>

            {!isGetCardPinLoading ? (
                <div className="mt-4.5 flex w-full flex-col justify-between">
                    <div className="flex flex-col items-start space-y-2 pt-2 text-base font-normal md:items-center md:px-0 lg:items-center lg:px-0">
                        <div className="flex w-full flex-col space-y-4">
                            <div className="flex w-full flex-col items-start justify-start space-y-4 lg:flex-row">
                                <div className="relative flex h-16 w-full max-w-sm flex-col space-y-2">
                                    <div className="flex h-full items-center justify-center space-x-2 rounded-lg bg-grey-backdrop">
                                        <span className="text-5xl font-normal leading-none text-black-tertiary">
                                            {showCardPin ? cardPin || "" : "••••"}
                                        </span>
                                    </div>
                                    <div className="absolute bottom-0 right-3 top-0 z-10 flex items-center justify-center leading-none">
                                        {showCardPin ? (
                                            <CloseEyeIcon onClick={() => setShowCardPin(false)} className="cursor-pointer stroke-current" />
                                        ) : null}
                                        {!showCardPin ? (
                                            <OpenEyeIcon onClick={() => setShowCardPin(true)} className="cursor-pointer stroke-current" />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-4.5 flex w-full items-center justify-center" style={{ height: "72px" }}>
                    <TailSpin color="#5466F9" height={20} width={20} />
                </div>
            )}

            <div className="mt-4.5 w-full">
                <MessageToasts
                    toastMessage="If you get an error while changing your PIN, note that the PIN change has been effected and you can use your card for transactions"
                    toastType={ToastType.INFORMATION}
                />
            </div>
        </div>
    );
}

export default RequestDefaultPIN;
