import { GenericObject } from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import SignatoryMin from "../signatoryInfoMin";

export default class AgreementGeneric {
    constructor(
        public signedBy: SignatoryMin[],
        public signedDocumentId: string | null
    ) {}

    static create(obj: GenericObject): AgreementGeneric {
        return new AgreementGeneric(Parsers.classObjectArray(obj.signedBy, SignatoryMin), Parsers.nullableString(obj.signedDocumentId));
    }
}
