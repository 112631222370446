import {
    BillPaymentHistoryCategoryOptions,
    BillPaymentHistoryFilters,
    BillPaymentHistoryFiltersData,
    BillPaymentHistoryStatusOptions,
} from "../../../../../hooks/state/BillPayment/billPayments.constants";
import { BillPurchaseCategoriesType, BillPurchaseStatusType } from "../../../../../../../../models/billPurchase.constant";
import { FilterDateType, FilterDateTypes } from "../../../../../../../../components/filter/filter.constant";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    resetSelectedBillHistoryFilterState,
    setBillHistoryFilterState,
    setBillHistoryTransactionDateType,
    setIsBillHistoryCustomModalOpen,
    setSelectedBillHistoryFilterState,
} from "../../../../../../../../redux/payments/billPayment/slice/billPaymentSlice";

import BillHistoryCustomDatePickerModal from "../../../../modals/BillHistoryCustomDatePickerModal";
import { BillPurchasesHistoryRequest } from "../../../../../services/billPayment/billPaymentApi.types";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
// import DropdownHead from "../../../../../../../../components/dropdown/dropdown-components/dropdown-head";
import FilterRow from "../../../../../../../../components/filter/Row/FilterRow";
import GoogleIcon from "../../../../../../../../components/google-icon";
import NewDropdownHead from "../../../../../../../../components/new-dropdown";
import isNullOrUndefined from "../../../../../../../../utils/isNullOrUndefined";
import { setTransactionFilterState } from "../../../../../../../../redux/transactions/slice/transactionsSlice";
// import FilterRow from "./filterRow/filterRow";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface Props {
    handleClearAll: () => void;
    handleFilteredDetails: (_data: BillPurchasesHistoryRequest) => void;
}

function FilterDropdown(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const billHistoryFilterState = useAppSelector((state) => state.billPayment.billHistoryFilterState);
    const billHistoryTransactionDateType = useAppSelector((state) => state.billPayment.billHistoryTransactionDateType);
    const selectedTransactionFilterState = useAppSelector((state) => state.transactions.selectedTransactionFilterState);
    const billPaymentHistoryAccountIdsOptions = useAppSelector((state) =>
        state.init.main?.companyDetails?.accounts.map((_userAccount) => ({ text: _userAccount.accountName, value: _userAccount.id }))
    );
    const billPaymentHistoryVendorsOptions = useAppSelector((state) =>
        state.billPayment.vendorsList?.map((_vendor) => ({ text: _vendor.name || "", value: _vendor.id }))
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeRow, setActiveRow] = useState<BillPaymentHistoryFilters | null>(null);
    const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);

    const domRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setSelectedFiltersCount(handleCleanFilterState());
    }, [billHistoryFilterState]);

    const handleApplyFilter = () => {
        setIsOpen(false);
        dispatch(setSelectedBillHistoryFilterState());
        props.handleFilteredDetails(billHistoryFilterState);
    };

    const handleClearAll = useCallback(() => {
        setIsOpen(false);
        dispatch(resetSelectedBillHistoryFilterState());
        props.handleClearAll();
    }, []);

    const handleOpenFilter = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setIsOpen(false);
        dispatch(setTransactionFilterState(selectedTransactionFilterState));
    }, [selectedTransactionFilterState]);

    // const handleAdditionalCheck = useCallback((target: HTMLElement): boolean => {
    //     return !domRef.current?.contains(target);
    // }, []);

    const handleCleanFilterState = () => {
        const arr = Object.values(billHistoryFilterState).filter(
            (item) => !isNullOrUndefined(item) && (Array.isArray(item) || Object.keys(item).length > 1)
        );

        const cleanFilterState = arr.filter((item) => {
            const count = Array.isArray(item) ? item.length > 0 : Object.values(item).length && Object.values(item)[0].length > 0;
            return count;
        });

        const counter = 0;

        const countFilter = cleanFilterState.map((item) => {
            const count = Array.isArray(item)
                ? counter + item.length
                : Object.values(item).length && Object.values(item)[0].length > 0
                  ? counter + 1
                  : counter;
            return count;
        });

        const sumCountFilter = countFilter.reduce((a, b) => {
            return a + b;
        }, 0);

        return sumCountFilter;
    };

    const handleOpenDateModal = () => {
        dispatch(setIsBillHistoryCustomModalOpen(true));
    };

    const handleSetBillPaymentHistoryDate = (_data: FilterDateType) => {
        dispatch(setBillHistoryFilterState({ ...billHistoryFilterState, date: _data.date }));
        dispatch(setBillHistoryTransactionDateType(_data.name));
    };
    const handleFilterBillPaymentHistoryDate = () => {
        const updatedFilter = { ...billHistoryFilterState, date: { begin: "", end: "" } };
        dispatch(setBillHistoryFilterState(updatedFilter));
        dispatch(setBillHistoryTransactionDateType(FilterDateTypes.ALL_TIME));
    };

    const handleFilterBillPaymentHistoryStatus = useCallback(
        (_value: BillPurchaseStatusType) => {
            const updatedList = [
                ...((billHistoryFilterState?.statuses || []).includes(_value)
                    ? (billHistoryFilterState?.statuses || []).filter((_el) => _el !== _value)
                    : [...(billHistoryFilterState?.statuses || []), _value]),
            ];
            dispatch(setBillHistoryFilterState({ statuses: updatedList }));
        },
        [billHistoryFilterState]
    );

    const handleFilterBillPaymentHistoryUserAccountIds = (_value: string) => {
        const updatedList = [
            ...((billHistoryFilterState?.userAccountIds || []).includes(_value)
                ? (billHistoryFilterState?.userAccountIds || []).filter((_el) => _el !== _value)
                : [...(billHistoryFilterState?.userAccountIds || []), _value]),
        ];
        dispatch(setBillHistoryFilterState({ userAccountIds: updatedList }));
    };

    const handleFilterBillPaymentHistoryCategory = (_category: BillPurchaseCategoriesType) => {
        const updatedList = [
            ...((billHistoryFilterState?.categories || []).includes(_category)
                ? (billHistoryFilterState?.categories || []).filter((_el) => _el !== _category)
                : [...(billHistoryFilterState?.categories || []), _category]),
        ];
        dispatch(setBillHistoryFilterState({ categories: updatedList }));
    };

    const handleFilterBillPaymentHistoryVendors = (_value: string) => {
        const updatedList = [
            ...((billHistoryFilterState?.vendorIds || []).includes(_value)
                ? (billHistoryFilterState?.vendorIds || []).filter((_el) => _el !== _value)
                : [...(billHistoryFilterState?.vendorIds || []), _value]),
        ];
        dispatch(setBillHistoryFilterState({ vendorIds: updatedList }));
    };
    const handleAdditionalCheck = useCallback((target: HTMLElement): boolean => {
        return !domRef.current?.contains(target);
    }, []);

    return (
        <div className="w-full" ref={domRef}>
            <BillHistoryCustomDatePickerModal />

            <NewDropdownHead
                content={
                    <div className="flex items-center justify-start space-x-2">
                        <span className="text-sm font-normal">Filter</span>
                        <GoogleIcon icon="filter_list" className="-mr-1" />
                    </div>
                }
                size="md"
                color="grey"
                buttonType="secondary"
                paddingSize="xs"
                dropdownWidth="max"
                isActive={isOpen}
                handleOpen={handleOpenFilter}
                handleClose={handleCloseFilter}
                handleAdditionalCheck={handleAdditionalCheck}
                isNoArrow
                isFreeFormDropdown
            >
                <div className="absolute -top-12 left-20 z-10 mt-[1px] pl-1.5">
                    <div className="w-64 rounded-lg bg-white pb-2.5 shadow-dropdown">
                        <div className="flex h-10 w-full flex-row items-center justify-between px-4">
                            <p className="text-base font-medium text-black-secondary">Filter by</p>
                            <ButtonComp
                                buttonType="tertiary"
                                color="blue"
                                size="sm"
                                disable={
                                    !(
                                        selectedTransactionFilterState.query.length > 0 ||
                                        selectedTransactionFilterState.userAccountIds.length > 0 ||
                                        selectedTransactionFilterState.userGroupIds.length > 0 ||
                                        selectedTransactionFilterState.categoryIds.length > 0 ||
                                        selectedTransactionFilterState.transactionTypes.length > 0 ||
                                        selectedTransactionFilterState.transactionStatuses.length > 0 ||
                                        selectedTransactionFilterState.cardIds.length > 0 ||
                                        billHistoryTransactionDateType !== FilterDateTypes.ALL_TIME
                                    )
                                }
                                func={handleClearAll}
                            >
                                <span className="text-xs font-medium">Clear all</span>
                            </ButtonComp>
                        </div>

                        {/* Filter Menu */}
                        <div className="w-full border-t border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary">
                            {BillPaymentHistoryFiltersData.map((_filter, index) => {
                                // Typescript Hack
                                // Todo ===> Find better solution
                                return (
                                    <React.Fragment key={index}>
                                        {_filter.value === BillPaymentHistoryFilters.DATE && (
                                            <FilterRow
                                                date={billHistoryTransactionDateType}
                                                title={_filter.text}
                                                dataType="bill-payment-history-filter"
                                                isHover={activeRow === BillPaymentHistoryFilters.DATE}
                                                onHover={() => setActiveRow(BillPaymentHistoryFilters.DATE)}
                                                handleSetDate={handleSetBillPaymentHistoryDate}
                                                handleFilterDate={handleFilterBillPaymentHistoryDate}
                                                handleOpenDateModal={handleOpenDateModal}
                                                isDate
                                            />
                                        )}

                                        {_filter.value === BillPaymentHistoryFilters.VENDORS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={billPaymentHistoryVendorsOptions}
                                                dataType="bill-payment-history-filter"
                                                isHover={activeRow === BillPaymentHistoryFilters.VENDORS}
                                                onHover={() => setActiveRow(BillPaymentHistoryFilters.VENDORS)}
                                                filteredList={billHistoryFilterState.vendorIds || []}
                                                handleFilterState={handleFilterBillPaymentHistoryVendors}
                                                // isRadio
                                            />
                                        )}

                                        {_filter.value === BillPaymentHistoryFilters.CATEGORIES && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={BillPaymentHistoryCategoryOptions}
                                                dataType="bill-payment-history-filter"
                                                isHover={activeRow === BillPaymentHistoryFilters.CATEGORIES}
                                                onHover={() => setActiveRow(BillPaymentHistoryFilters.CATEGORIES)}
                                                filteredList={billHistoryFilterState.categories || []}
                                                handleFilterState={handleFilterBillPaymentHistoryCategory}
                                                // isRadio
                                            />
                                        )}

                                        {_filter.value === BillPaymentHistoryFilters.STATUS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={BillPaymentHistoryStatusOptions}
                                                dataType="bill-payment-history-filter"
                                                isHover={activeRow === BillPaymentHistoryFilters.STATUS}
                                                onHover={() => setActiveRow(BillPaymentHistoryFilters.STATUS)}
                                                filteredList={billHistoryFilterState.statuses || []}
                                                handleFilterState={handleFilterBillPaymentHistoryStatus}
                                                // isRadio
                                            />
                                        )}
                                        {_filter.value === BillPaymentHistoryFilters.ACCOUNTS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={billPaymentHistoryAccountIdsOptions}
                                                dataType="bill-payment-history-filter"
                                                isHover={activeRow === BillPaymentHistoryFilters.ACCOUNTS}
                                                onHover={() => setActiveRow(BillPaymentHistoryFilters.ACCOUNTS)}
                                                filteredList={billHistoryFilterState.userAccountIds || []}
                                                handleFilterState={handleFilterBillPaymentHistoryUserAccountIds}
                                                // isRadio
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}

                            {/* Apply Button */}
                            <div
                                className={`${selectedFiltersCount > 0 ? "w-full justify-between" : "w-full justify-end"} flex items-center px-4 pt-2`}
                            >
                                {selectedFiltersCount > 0 ? (
                                    <p className="text-xs text-black-tertiary">
                                        {selectedFiltersCount} Filter{selectedFiltersCount > 1 && "s"} Selected
                                    </p>
                                ) : null}
                                <ButtonComp
                                    size="sm"
                                    type="button"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    func={handleApplyFilter}
                                    disable={selectedFiltersCount < 1}
                                    borderSmall
                                >
                                    <span className="text-xs">Apply Filter</span>
                                </ButtonComp>
                            </div>
                        </div>
                    </div>
                </div>
            </NewDropdownHead>
        </div>
    );
}
export default FilterDropdown;
