import { AccountOpeningStatus, BusinessCategory } from "./accountOpening.constants";

import ApplicationIndustry from "./applicationIndustry";
import { GenericObject } from "../../helpers/types";
import { LencoActiveCountries } from "../../redux/init/slice/initSlice.types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class BusinessDetails {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string | null,
        public isRegistered: boolean | null,
        public industry: ApplicationIndustry | null,
        public registrationCategory: BusinessCategory | null,
        public status: AccountOpeningStatus,
        public requestedProAccount: boolean,
        public country: LencoActiveCountries | null,
        public businessNature: string | null,
        public taxNumber: string | null,
        public scumlNumber: string | null,
        public website: string | null,
        public email: string | null,
        public phone: string | null,
        public annualTurnover: number | null,
        public operatingAddress: string | null,
        public registeredAddress: string | null,
        public sameRegisteredOperatingAddress: boolean
    ) {}

    static create(obj: GenericObject): BusinessDetails {
        return new BusinessDetails(
            Parsers.string(obj.id),
            obj.name === null ? null : Parsers.string(obj.name),
            Parsers.nullableBoolean(obj.isRegistered),
            Parsers.classObject(obj.industry, ApplicationIndustry),
            Parsers.nullableNumber(obj.registrationCategory),
            Parsers.number(obj.status),
            Parsers.boolean(obj.requestedProAccount),
            Parsers.nullableEnum(obj.country, LencoActiveCountries),
            Parsers.nullableString(obj.businessNature),
            Parsers.nullableString(obj.taxNumber),
            Parsers.nullableString(obj.scumlNumber),
            Parsers.nullableString(obj.website),
            Parsers.nullableString(obj.email),
            Parsers.nullableString(obj.phone),
            Parsers.nullableNumber(obj.annualTurnover),
            Parsers.nullableString(obj.operatingAddress),
            Parsers.nullableString(obj.registeredAddress),
            Parsers.boolean(obj.sameRegisteredOperatingAddress)
        );
    }

    get isLLC(): boolean {
        return this.registrationCategory === BusinessCategory.LLC;
    }
    get isPartnership(): boolean {
        return this.registrationCategory === BusinessCategory.PARTNERSHIP;
    }
    get isSoleProprietorship(): boolean {
        return this.registrationCategory === BusinessCategory.SOLE_PROPRIETORSHIP;
    }
}
