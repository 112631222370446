import { ReactComponent as CardInflowIcon } from "../../../../../../../assets/svg/transactions/card-inflow.svg";
import { ReactComponent as CardOutflowIcon } from "../../../../../../../assets/svg/transactions/card-outflow.svg";
import Money from "../../../../../../../components/money";
import Transaction from "../../../../../../../models/transaction";
import TransactionDetailsCardHeadSender from "./TransactionDetailsCardHeadSender";
import getBankName from "../../../../../../../helpers/getBankName";
import { getTransactionStatusData } from "../Services/transactionDetail.constant";
import titleCase from "../../../../../../../hooks/titleCase";

interface Props {
    transaction: Transaction;
    dataType: string;
}

function TransactionDetailCardHead({ transaction, dataType }: Props): JSX.Element {
    const statusData = getTransactionStatusData(transaction);

    return (
        <>
            {transaction && (
                <div className="w-full rounded-lg bg-grey-backdrop p-4">
                    <div className="flex w-full flex-col items-center justify-center text-black-tertiary" data-type={dataType}>
                        <div className="w-full">
                            <div className="flex w-full items-center justify-between" data-type={dataType}>
                                <div>
                                    <h1 className="text-2xl font-medium text-black-secondary" data-type={dataType}>
                                        <Money amount={transaction.amount} currency={transaction.currency} />
                                    </h1>
                                </div>

                                <div className="flex items-center justify-between space-x-4">
                                    {transaction.isApi && (
                                        <div
                                            className="w-max whitespace-nowrap rounded border border-info bg-info-backdrop px-2"
                                            data-type={dataType}
                                        >
                                            <p className="text-xs font-medium capitalize text-info" data-type={dataType}>
                                                API
                                            </p>
                                        </div>
                                    )}
                                    {transaction.isCardPayment && (
                                        <>
                                            {transaction.isDebit() && <CardOutflowIcon data-type={dataType} />}
                                            {transaction.isCredit() && <CardInflowIcon data-type={dataType} />}
                                        </>
                                    )}
                                    {(transaction.isDebit() || transaction.isCredit()) && !transaction.isFraudReview && (
                                        <div
                                            className={
                                                statusData.bgClass +
                                                statusData.borderClass +
                                                " flex h-6 w-fit items-center justify-center rounded border px-2"
                                            }
                                            data-type={dataType}
                                        >
                                            <p className={"text-xs font-medium capitalize " + statusData.textClass} data-type={dataType}>
                                                {statusData.text}
                                            </p>
                                        </div>
                                    )}
                                    {/* //todo: remove temp fix after correct status */}
                                    {transaction.isFraudReview && (
                                        <div
                                            className="flex h-6 w-fit items-center justify-center rounded border border-warning bg-warning-backdrop px-2"
                                            data-type={dataType}
                                        >
                                            <p className="text-xs font-medium capitalize text-warning" data-type={dataType}>
                                                Passing Fraud Review
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {transaction.cardPayment?.isReversed && (
                                <div className="flex items-center justify-start space-x-1">
                                    <div className="flex h-5 w-5 items-center justify-center rounded-md bg-blue-senary p-1">
                                        {/* <ReversalIcon className="stroke-current text-blue" /> */}
                                    </div>
                                    <p className="text-sm text-black-secondary">Reversed</p>
                                </div>
                            )}
                        </div>
                        <div className="flex w-full flex-col items-center justify-start pt-6" data-type={dataType}>
                            {transaction.isCardPayment && transaction.cardPayment ? (
                                <div className="flex w-full flex-col items-center justify-between space-y-4" data-type={dataType}>
                                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                        <p className="text-sm text-black-secondary" data-type={dataType}>
                                            Card
                                        </p>
                                        <div className="max-w-55% text-right" data-type={dataType}>
                                            <p
                                                className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                data-type={dataType}
                                            >
                                                {transaction.cardPayment.cardDetails.name}
                                            </p>
                                            <p className="max-w-full break-words text-right text-xs text-black-tertiary" data-type={dataType}>
                                                {transaction.cardPayment.cardDetails.panLast4Digits
                                                    ? `••••${transaction.cardPayment.cardDetails.panLast4Digits}`
                                                    : ""}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                        <div data-type={dataType}>
                                            <p className="text-sm text-black-secondary" data-type={dataType}>
                                                Card Assigned To
                                            </p>
                                        </div>
                                        <div className="max-w-55% text-right" data-type={dataType}>
                                            <p
                                                className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                data-type={dataType}
                                            >
                                                {transaction.cardPayment.assignedTo?.fullName || "-"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {/*Has Both Origination and Destination Accounts*/}
                                    {transaction?.origination?.account && transaction?.destination?.account && (
                                        <div className="flex w-full flex-col items-center justify-between space-y-4">
                                            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                                <div>
                                                    <p className="text-sm text-black-secondary">From</p>
                                                </div>

                                                <div className="max-w-55% space-y-2 text-right" data-type={dataType}>
                                                    {transaction.isDebit() && (
                                                        <>
                                                            <p
                                                                className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                                data-type={dataType}
                                                            >
                                                                {titleCase(transaction.origination.account.accountName || "")}{" "}
                                                            </p>
                                                            <p
                                                                className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                                data-type={dataType}
                                                            >
                                                                {transaction.origination.account.accountNumber}{" "}
                                                            </p>
                                                            <p
                                                                className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                                data-type={dataType}
                                                            >
                                                                {getBankName(transaction.origination.account.bankCode)}{" "}
                                                            </p>
                                                        </>
                                                    )}
                                                    {!transaction.isDebit() && (
                                                        <>
                                                            <TransactionDetailsCardHeadSender transaction={transaction} />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                                <div>
                                                    <p className="text-sm text-black-secondary">To</p>
                                                </div>

                                                <div className="max-w-55% space-y-2 text-right" data-type={dataType}>
                                                    <p
                                                        className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                        data-type={dataType}
                                                    >
                                                        {titleCase(transaction.destination.account.accountName)}
                                                    </p>
                                                    {transaction.destination.customerAccount && transaction.destination.customerAccount.alias && (
                                                        <p
                                                            className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                            data-type={dataType}
                                                        >
                                                            Alias: {transaction.destination.customerAccount.alias}{" "}
                                                        </p>
                                                    )}
                                                    <p className="max-w-full break-words text-right text-xs text-black-tertiary" data-type={dataType}>
                                                        {transaction.destination.account.accountNumber}{" "}
                                                    </p>
                                                    <p className="max-w-full break-words text-right text-xs text-black-tertiary" data-type={dataType}>
                                                        {getBankName(transaction.destination.account.bankCode)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!(transaction?.origination?.account && transaction?.destination?.account) && transaction.isDebit() && (
                                        <div className="flex w-full flex-col items-center justify-start space-y-4">
                                            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                                <div>
                                                    <p className="text-sm text-black-secondary">From</p>
                                                </div>

                                                <div className="max-w-55% space-y-2 text-right" data-type={dataType}>
                                                    <p
                                                        className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                        data-type={dataType}
                                                    >
                                                        {titleCase(transaction.origination?.account?.accountName || "")}
                                                    </p>
                                                    <p className="max-w-full break-words text-right text-xs text-black-tertiary" data-type={dataType}>
                                                        {transaction.origination?.account?.accountNumber || ""}{" "}
                                                    </p>
                                                    <p className="max-w-full break-words text-right text-xs text-black-tertiary" data-type={dataType}>
                                                        {getBankName(transaction.origination?.account?.bankCode)}
                                                    </p>
                                                </div>
                                            </div>
                                            {(transaction.destination?.narration || transaction.destination?.account) && (
                                                <>
                                                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                                        <div>
                                                            <p className="text-sm text-black-secondary">To</p>
                                                        </div>

                                                        <div className="max-w-55% space-y-2 text-right" data-type={dataType}>
                                                            {transaction.destination.account && (
                                                                <>
                                                                    <p
                                                                        className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                                        data-type={dataType}
                                                                    >
                                                                        {titleCase(transaction.destination.account.accountName)}
                                                                    </p>
                                                                    {transaction.destination.customerAccount &&
                                                                        transaction.destination.customerAccount.alias && (
                                                                            <p
                                                                                className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                                                data-type={dataType}
                                                                            >
                                                                                Alias: {transaction.destination.customerAccount.alias}{" "}
                                                                            </p>
                                                                        )}
                                                                    <p
                                                                        className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                                        data-type={dataType}
                                                                    >
                                                                        {transaction.destination.account.accountNumber}{" "}
                                                                    </p>
                                                                    <p
                                                                        className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                                        data-type={dataType}
                                                                    >
                                                                        {getBankName(transaction.destination.account.bankCode)}
                                                                    </p>
                                                                </>
                                                            )}
                                                            {transaction.destination.narration && (
                                                                <>
                                                                    <p
                                                                        className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                                        data-type={dataType}
                                                                    >
                                                                        {titleCase(transaction.destination.narration)}
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {transaction.destination?.card && (
                                                <>
                                                    <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                                        <div>
                                                            <p className="text-sm text-black-secondary">To</p>
                                                        </div>

                                                        <div className="max-w-55% space-y-2 text-right" data-type={dataType}>
                                                            {transaction.destination.card && (
                                                                <>
                                                                    <p
                                                                        className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                                        data-type={dataType}
                                                                    >
                                                                        {titleCase(transaction.destination.card.name)}
                                                                    </p>
                                                                    <p
                                                                        className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                                        data-type={dataType}
                                                                    >
                                                                        {transaction.destination.card.panLast4Digits
                                                                            ? `••••${transaction.destination.card.panLast4Digits}`
                                                                            : ""}
                                                                    </p>
                                                                </>
                                                            )}
                                                            {transaction.destination.narration && (
                                                                <>
                                                                    <p
                                                                        className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                                        data-type={dataType}
                                                                    >
                                                                        {titleCase(transaction.destination.narration)}
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {!(transaction.origination?.account && transaction.destination?.account) && transaction.isCredit() && (
                                        <div className="flex w-full flex-col items-center justify-start">
                                            <div className="flex w-full flex-row items-start justify-between pb-4" data-type={dataType}>
                                                <div>
                                                    <p className="text-sm text-black-secondary">From</p>
                                                </div>

                                                <div className="ml-8 w-full max-w-55% text-right" data-type={dataType}>
                                                    <TransactionDetailsCardHeadSender transaction={transaction} />
                                                </div>
                                            </div>
                                            {transaction.destination?.account && (
                                                <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                                    <div>
                                                        <p className="text-sm text-black-secondary">To</p>
                                                    </div>

                                                    <div className="space-y-2 text-right" data-type={dataType}>
                                                        <p
                                                            className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                            data-type={dataType}
                                                        >
                                                            {titleCase(transaction.destination?.account?.accountName || "")}
                                                        </p>

                                                        <p
                                                            className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                            data-type={dataType}
                                                        >
                                                            {transaction.destination.account.accountNumber}{" "}
                                                        </p>
                                                        <p
                                                            className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                            data-type={dataType}
                                                        >
                                                            {getBankName(transaction.destination?.account?.bankCode || "")}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {transaction.destination?.card && (
                                                <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                                                    <div>
                                                        <p className="text-sm text-black-secondary">To</p>
                                                    </div>

                                                    <div className="space-y-2 text-right" data-type={dataType}>
                                                        <p
                                                            className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
                                                            data-type={dataType}
                                                        >
                                                            {titleCase(transaction.destination?.card?.name || "")}
                                                        </p>
                                                        <p
                                                            className="max-w-full break-words text-right text-xs text-black-tertiary"
                                                            data-type={dataType}
                                                        >
                                                            {transaction.destination.card.panLast4Digits
                                                                ? `••••${transaction.destination.card.panLast4Digits}`
                                                                : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardHead;
