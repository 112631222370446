import ButtonComp from "../../button/ButtonComp";
// import { ReactComponent as CancelIcon } from "../../../../assets/svg/Transfer/Cancel.svg";
import { FilterDateTypes } from "../filter.constant";
import GoogleIcon from "../../google-icon";
import { formatDate } from "../../../utils/formatDate";

interface Props {
    endDate: string | Date;
    startDate: string | Date;
    selectedFilterDate: FilterDateTypes;

    handleCancelSelection: () => void;
}

function DateTag(props: Props): JSX.Element {
    return (
        <>
            <div className="w-max pr-3 pt-2">
                {props.selectedFilterDate !== FilterDateTypes.ALL_TIME && props.selectedFilterDate !== FilterDateTypes.CUSTOM && (
                    <div className="flex h-10 max-w-max items-center justify-center space-x-1 rounded-lg bg-grey px-3">
                        <span className="select-none text-xs text-black">Date</span>
                        <ButtonComp
                            size="md"
                            color="black"
                            className="-mr-2 !p-1 text-black hover:text-error-tertiary"
                            buttonType="tertiary"
                            onClick={props.handleCancelSelection}
                        >
                            <GoogleIcon icon="close" size="sm" />
                        </ButtonComp>
                    </div>
                )}
                {props.selectedFilterDate === FilterDateTypes.CUSTOM && (
                    <div className="flex h-10 max-w-max items-center justify-center space-x-1 rounded-lg bg-grey px-3">
                        <span className="select-none text-xs text-black">
                            Date: {formatDate(new Date(props.startDate))} - {formatDate(new Date(props.endDate))}
                        </span>
                        <ButtonComp
                            size="md"
                            color="black"
                            className="-mr-2 !p-1 text-black hover:text-error-tertiary"
                            buttonType="tertiary"
                            onClick={props.handleCancelSelection}
                        >
                            <GoogleIcon icon="close" size="sm" />
                        </ButtonComp>
                    </div>
                )}
            </div>
        </>
    );
}

export default DateTag;
