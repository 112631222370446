import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useRef } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import Currency from "../../../../../../models/currency";
import CurrencyCode from "../../../../../../components/currency-code";
import { IRootState } from "../../../../../../redux/rootReducer";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import MoneyInput from "../../../../../../components/inputs/money-input";
import { TeamMemberApproverLimitRequest } from "../../../Services/team-members/team-members.types";
import { ToastType } from "../../../../../../helpers/AppConstants";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import formatNumber from "../../../../../../utils/formatNumber";
import titleCase from "../../../../../../hooks/titleCase";
import { useSelector } from "react-redux";
import useSetTeamMemberApproverLimit from "../../../Services/team-members/hooks/useSetTeamMemberApproverLimit";

interface EditApprovalLimitModalProps {
    data: UserAccountMeta;
    active: boolean;
    maxApprovalLimit: string | null;
    toggler: () => void;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
}

function EditApprovalLimitModal(props: EditApprovalLimitModalProps): JSX.Element {
    const { isSetApproverLimitDone, isError, isLoading, handleReset, handleSetApproverLimit } = useSetTeamMemberApproverLimit(
        props.updateAccountMeta
    );
    const currency = useSelector<IRootState, Currency | null | undefined>((state) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);

    const formikRef = useRef<FormikProps<TeamMemberApproverLimitRequest> | null>(null);

    const InitialApprovalLimitState: TeamMemberApproverLimitRequest = {
        memberId: "",
        userAccountId: "",
        maxApprovalAmount: null,
        minApprovalAmount: null,
    };
    const ApprovalLimitFormValidation = Yup.object().shape({
        memberId: Yup.string().required("Required"),
        userAccountId: Yup.string().required("Required"),
        maxApprovalAmount: Yup.string().nullable().required("Required"),
        minApprovalAmount: Yup.string().nullable(),
    });

    useEffect(() => {
        void formikRef.current?.validateForm();
        void formikRef.current?.getFieldHelpers("memberId").setValue(props.data.id);
        void formikRef.current?.getFieldHelpers("userAccountId").setValue(props.data.userAccountId);
        void formikRef.current?.getFieldHelpers("minApprovalAmount").setValue(null);
    }, [formikRef.current, props.active, props.data]);

    useEffect(() => {
        if (props.maxApprovalLimit) {
            void formikRef.current?.getFieldHelpers("maxApprovalAmount").setValue(props.maxApprovalLimit);
        }
    }, [props.maxApprovalLimit]);

    const handleCloseModal = useCallback(() => {
        handleReset();
        props.toggler();
        void formikRef.current?.resetForm();
    }, [formikRef.current, props.toggler, handleReset]);

    useEffect(() => {
        if (!isSetApproverLimitDone) return;
        handleReset();
        props.toggler();
        void formikRef.current?.resetForm();
    }, [formikRef.current, isSetApproverLimitDone, handleReset, handleCloseModal]);

    return (
        <>
            <Modal size="md" active={props.active} toggler={handleCloseModal}>
                <ModalHeader subTitle="Set amount based approval access for your team members" onClose={handleCloseModal}>
                    Edit Approval Limit
                </ModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        ...InitialApprovalLimitState,
                    }}
                    validationSchema={ApprovalLimitFormValidation}
                    onSubmit={(values) => {
                        void handleSetApproverLimit(values);
                    }}
                    enableReinitialize
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <Form className="w-full">
                                <ModalBody>
                                    {isError && (
                                        <div className="mx-auto w-max pb-8">
                                            <MessageToasts toastMessage={isError || ""} toastType={ToastType.ERROR} />
                                        </div>
                                    )}
                                    <div className="flex flex-col items-start space-y-4 text-base font-normal lg:items-center lg:px-0">
                                        <MoneyInput
                                            // icon={<CurrencyCode currency={currency} />}
                                            label="Approval Limit"
                                            currency={<CurrencyCode currency={currency} />}
                                            value={formik.values.maxApprovalAmount ? String(formik.values.maxApprovalAmount) : ""}
                                            onChange={(amount) => {
                                                if (!amount || amount === 0 || Number(amount) === 0) {
                                                    void formik.getFieldHelpers("maxApprovalAmount").setValue("");
                                                    return;
                                                }
                                                void formik.getFieldHelpers("maxApprovalAmount").setValue(amount);
                                            }}
                                            isActive={props.active}
                                            helperText={
                                                <span>
                                                    {titleCase(props.data.name)} will only be able to approve payments below{" "}
                                                    <CurrencyCode currency={currency} />
                                                    {formik.values.maxApprovalAmount ? formatNumber(formik.values.maxApprovalAmount) : "0.00"}
                                                </span>
                                            }
                                        />
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                            <ButtonComp
                                                type="button"
                                                color="grey"
                                                ripple="light"
                                                buttonType="secondary"
                                                fullWidth
                                                func={handleCloseModal}
                                            >
                                                <span>Cancel</span>
                                            </ButtonComp>
                                        </div>
                                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                            <ButtonComp
                                                type="submit"
                                                color="black"
                                                ripple="light"
                                                buttonType="primary"
                                                isLoading={isLoading}
                                                disable={!formik.isValid}
                                                fullWidth
                                            >
                                                <span>Update Approval Limit</span>
                                            </ButtonComp>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}
export default EditApprovalLimitModal;
