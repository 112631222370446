import Checkbox from "../../checkbox";
import { DropdownItem } from "../../../helpers/types";
import GoogleIcon from "../../google-icon";
// import GoogleIcon from "../../google-icon";
import NewDropdownItem from "../../new-dropdown-item";
import NewSelect from "../../new-select";
import { useCallback } from "react";

interface MultiSelectDropdownProps<T extends number | string> {
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    value: T[];
    label?: string;
    active?: boolean;
    options: Array<DropdownItem<T>>;
    canCancel?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    onChange: (newValues: Array<T>) => void;
}

function MultiSelectDropdown<T extends number | string>({
    size = "lg",
    label = "",
    value = [],
    options,
    placeholder = "",
    isDisabled = false,
    onChange,
}: MultiSelectDropdownProps<T>): JSX.Element {
    const handleItemClick = useCallback(
        (option: T) => {
            if (value.includes(option)) {
                onChange(value.filter((el) => el !== option));
            } else {
                onChange([...value, option]);
            }
        },
        [value]
    );
    return (
        <NewSelect
            label={label || placeholder}
            value={
                value.length > 0 ? (
                    <div className="flex w-full items-center justify-start space-x-2 overflow-x-auto">
                        {value.map((_, index) => (
                            <div
                                className="flex h-6 items-center space-x-1 rounded bg-grey-secondary px-2"
                                key={index}
                                onClick={(e) => {
                                    handleItemClick(_);
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <span className="text-xs capitalize">{options.find((item) => item.value === _)?.text || ""}</span>
                                <GoogleIcon icon="cancel" size="xs" className="text-black-quat" isFilled />
                            </div>
                        ))}
                    </div>
                ) : (
                    ""
                )
            }
            isDisabled={isDisabled}
            size={size}
        >
            {options &&
                options.map((option, index) => (
                    <NewDropdownItem key={index} size="md" onClick={() => handleItemClick(option.value)}>
                        <Checkbox
                            text={
                                <div className="flex max-w-full flex-col justify-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    <p className="text-left text-sm capitalize text-black-secondary">{option.text}</p>
                                    {option.subtext && (
                                        <p className="inline justify-center text-left text-xs text-black-tertiary">{option.subtext}</p>
                                    )}
                                </div>
                            }
                            id={String(option.value)}
                            checked={value.includes(option.value)}
                            size="sm"
                            readOnly
                        />
                    </NewDropdownItem>
                ))}
        </NewSelect>
    );
}

export default MultiSelectDropdown;
