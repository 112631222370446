import {
    CurrentSignatoryPersonnelRequest,
    IdentificationRequest,
    NewCurrentSignatoryResponse,
    NextOfKinForm,
    PhotoRequest,
    SignatoryPersonalDetailsForm,
    SignatureRequest,
} from "./currentSignatory.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../helpers/request/abortControllers";
import {
    makeRequest,
    makeRequestDownloadFile,
    makeRequestUploadFileWithSignal,
    makeRequestWithSignal,
} from "../../../../../../helpers/request/makeRequest";

import { GenericObject } from "../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../utils/parsers";
import SignatoryInfo from "../../../../../../models/account-opening/signatoryInfo";
import { toFormData } from "../../../../../../utils/converters";

export enum CurrentSignatoryRequests {
    INIT = "current-signatory.init3",
    IS_DIRECTOR_UPDATE = "current-signatory.is-director-update",
    BIO_UPDATE = "current-signatory.bio-info",
    NEXT_OF_KIN_UPDATE = "current-signatory.next",
    IDENTIFICATION_UPLOAD = "current-signatory.id",
    IDENTIFICATION_DELETE = "current-signatory.id-delete",
    PHOTO_UPLOAD = "current-signatory.photo-upload",
    PHOTO_DELETE = "current-signatory.photo-delete",
    DOCUMENT_SIGN = "current-signatory.document-sign",
    APPEND_SIGNATURE = "current-signatory.append",
    DOWNLOAD_TO_SIGN = "current-signatory.download",
    DOCUMENT_UPLOAD = "current-signatory.upload",
    DOCUMENT_DELETE = "current-signatory.delete",
}

function getNewSignatoryInfoResponse(res: GenericObject | Error): NewCurrentSignatoryResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        signatory: Parsers.classObject(res.signatory, SignatoryInfo),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
    };
}

export const currentSignatory = async (): Promise<NewCurrentSignatoryResponse> => {
    // const signal = getAbortControllerSignal(CurrentSignatoryRequests.INIT);
    const res = await makeRequest("/account-opening/ng/personal/details", {});
    return getNewSignatoryInfoResponse(res);
};

export const isDirectorUpdate = async (_data: CurrentSignatoryPersonnelRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.IS_DIRECTOR_UPDATE);
    const res = await makeRequestWithSignal("/account-opening/ng/personal/is-director/update", _data, signal);
    return getNewSignatoryInfoResponse(res);
};
export const bioUpdate = async (_data: SignatoryPersonalDetailsForm): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.BIO_UPDATE);
    const res = await makeRequestWithSignal("/account-opening/ng/personal/bio/update", _data, signal);
    return getNewSignatoryInfoResponse(res);
};
export const nextOfKinUpdate = async (_data: NextOfKinForm): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.NEXT_OF_KIN_UPDATE);
    const res = await makeRequestWithSignal("/account-opening/ng/personal/next-of-kin/update", _data, signal);
    return getNewSignatoryInfoResponse(res);
};
export const identificationUpload = async (_data: IdentificationRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.IDENTIFICATION_UPLOAD);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/personal/identification/upload", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const identificationDelete = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.IDENTIFICATION_DELETE);
    const res = await makeRequestWithSignal("/account-opening/ng/personal/identification/delete", {}, signal);
    return getNewSignatoryInfoResponse(res);
};
export const photoUpload = async (_data: PhotoRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.PHOTO_UPLOAD);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/personal/photo/upload", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const photoDelete = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.PHOTO_DELETE);
    const res = await makeRequestWithSignal("/account-opening/ng/personal/photo/delete", {}, signal);
    return getNewSignatoryInfoResponse(res);
};
export const documentSign = async (_data: SignatureRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOCUMENT_SIGN);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/personal/document/sign", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const documentAppendSignature = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.APPEND_SIGNATURE);
    const res = await makeRequestWithSignal("/account-opening/ng/personal/document/append-signature", {}, signal);
    return getNewSignatoryInfoResponse(res);
};
export const downloadToSign = async (): Promise<Blob> => {
    // const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOWNLOAD_TO_SIGN);
    const res = await makeRequestDownloadFile("/account-opening/ng/personal/document/download-to-sign");
    if (res instanceof Error) {
        throw res;
    }
    return res;
};
export const documentUpload = async (_data: SignatureRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOCUMENT_UPLOAD);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/personal/document/upload", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const documentDelete = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOCUMENT_DELETE);
    const res = await makeRequestWithSignal("/account-opening/ng/personal/document/delete", {}, signal);
    return getNewSignatoryInfoResponse(res);
};

export function abortCurrentSignatoryRequest(type: CurrentSignatoryRequests): void {
    abortRequest(type);
}

export function abortAllCurrentSignatoryRequests(): void {
    Object.values(CurrentSignatoryRequests).forEach((type) => abortRequest(type));
}
