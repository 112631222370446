import AddMoneyCard from "../../../../Accounts/Components/Modals/ZambiaAddMoneyModal/Components/Card/AddMoneyCard";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../redux/rootReducer";
import MessageToast from "../../../../../../components/message-toast";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
    selectedAccountId: string;
    handleDone: () => void;
    handleBack: () => void;
}

type LocationState = {
    selectedAccountId?: string;
};

function BankTransfer(props: Props): JSX.Element {
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const selectedAccountId = locationState?.selectedAccountId || props.selectedAccountId;

    const account = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts.find((acc) => acc.id === selectedAccountId) || null);

    const addMoneyBankAccount = useSelector((state: IRootState) => state.init.main?.meta.addMoneyBankAccount);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <AddMoneyCard
                    accountName={addMoneyBankAccount?.accountName || ""}
                    accountNumber={addMoneyBankAccount?.accountNumber || ""}
                    medium={addMoneyBankAccount?.bank || ""}
                    branch={addMoneyBankAccount?.branch || ""}
                    swift={addMoneyBankAccount?.swiftCode || ""}
                    isWhiteBg
                />
                <MessageToast
                    message={
                        <div className="flex w-full flex-col space-y-2">
                            <h6 className="text-sm font-medium text-black-secondary">Fill out the memo as written below</h6>
                            <p className="text-xs text-black-tertiary">
                                FFC - {account?.accountNumber || ""} - {account?.accountName || ""}
                            </p>
                        </div>
                    }
                    type="info"
                    fullWidth
                />
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp buttonType="secondary" color="grey" size="xl" func={props.handleBack}>
                        Back
                    </ButtonComp>

                    <ButtonComp color="black" buttonType="primary" size="xl" func={props.handleDone}>
                        I&apos;ve Sent the Money
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default BankTransfer;
