import { ZambiaCollectionPaymentChannel, ZambiaSettlementStatus, ZambiaSettlementType } from "./zambiaCollectionRequest.constant";

import Currency from "../currency";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import UserAccount from "../userAccount";
import ZambiaSettlementCollectionsRequest from "./zambiaSettlementCollectionRequest";
import ZambiaSettlementMobileMoneyCollection from "./zambiaSettlementMobileMoneyCollection";
import ZambiaCardPaymentCollection from "./zambiaCardPaymentCollection";

export default class ZambiaSettlement {
    constructor(
        public id: string,
        public createdAt: Date | null,
        public account: UserAccount | null,
        public channel: ZambiaCollectionPaymentChannel | null,
        public transactionAmount: string,
        public settlementAmount: string,
        public currency: Currency,
        public settlementType: ZambiaSettlementType | null,
        public status: ZambiaSettlementStatus | null,
        public settledAt: string | null,
        public collectionRequest: ZambiaSettlementCollectionsRequest | null,
        public mobileMoneyCollection: ZambiaSettlementMobileMoneyCollection | null,
        public cardPaymentCollection: ZambiaCardPaymentCollection | null
    ) {}

    static create(obj: GenericObject): ZambiaSettlement {
        return new ZambiaSettlement(
            Parsers.string(obj.id),
            Parsers.date(obj.createdAt),
            Parsers.classObject(obj.account, UserAccount),
            Parsers.nullableEnum(obj.channel, ZambiaCollectionPaymentChannel),
            Parsers.string(obj.transactionAmount),
            Parsers.string(obj.settlementAmount),
            Parsers.classObjectNonNullable(obj.currency, Currency),
            Parsers.nullableEnum(obj.settlementType, ZambiaSettlementType),
            Parsers.nullableEnum(obj.status, ZambiaSettlementStatus),
            Parsers.nullableString(obj.settledAt),
            Parsers.classObject(obj.collectionRequest, ZambiaSettlementCollectionsRequest),
            Parsers.classObject(obj.mobileMoneyCollection, ZambiaSettlementMobileMoneyCollection),
            Parsers.classObject(obj.cardPaymentCollection, ZambiaCardPaymentCollection)
        );
    }

    get channelText(): string {
        return this.channel === ZambiaCollectionPaymentChannel.BANK_ACCOUNT
            ? "Bank Account"
            : this.channel === ZambiaCollectionPaymentChannel.CARD
              ? "Card"
              : this.channel === ZambiaCollectionPaymentChannel.MOBILE_MONEY
                ? "Mobile Money"
                : "";
    }

    get isMobileMoney(): boolean {
        return this.channel === ZambiaCollectionPaymentChannel.MOBILE_MONEY && !!this.mobileMoneyCollection;
    }

    get isBankAccount(): boolean {
        return this.channel === ZambiaCollectionPaymentChannel.BANK_ACCOUNT;
    }
    get isCard(): boolean {
        return this.channel === ZambiaCollectionPaymentChannel.CARD;
    }

    get isPending(): boolean {
        return this.status === ZambiaSettlementStatus.PENDING;
    }

    get isSettled(): boolean {
        return this.status === ZambiaSettlementStatus.SETTLED;
    }

    get statusText(): string {
        return this.isPending ? "Pending" : this.isSettled ? "Settled" : "";
    }
}
