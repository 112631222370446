import { BasicFinalStatus, TransactionType } from "../../../../models/posTransaction.constants";
import { PurchaseType, TerminalRequestStatus, TerminalStatus } from "../../../../models/terminal.constants";
import { TerminalRequestsFilters, TerminalsFilters, TransactionCount, TransactionVolume } from "../Types";

export const TransactionVolumeArray = [
    TransactionVolume.VOL_0_100K,
    TransactionVolume.VOL_100K_300K,
    TransactionVolume.VOL_300K_500K,
    TransactionVolume.VOL_500K_1M,
    TransactionVolume.VOL_1M_ABOVE,
];

export const transactionVolumeText = (_volume: TransactionVolume): string => {
    switch (_volume) {
        case TransactionVolume.VOL_0_100K:
            return "₦0.00 - ₦100,000.00";
        case TransactionVolume.VOL_100K_300K:
            return "₦100,000.00 - ₦300,000.00";
        case TransactionVolume.VOL_300K_500K:
            return "₦300,000.00 - ₦500,000.00";
        case TransactionVolume.VOL_500K_1M:
            return "₦500,000.00 - ₦1,000,000.00";
        case TransactionVolume.VOL_1M_ABOVE:
            return "₦1,000,000.00 and above";
    }
};

export const TransactionVolumeOptions = TransactionVolumeArray.map((_volume) => ({
    text: transactionVolumeText(_volume),

    value: _volume,
}));

export const TransactionCountArray = [
    TransactionCount.COUNT_0_10,
    TransactionCount.COUNT_10_30,
    TransactionCount.COUNT_30_60,
    TransactionCount.COUNT_60_100,
    TransactionCount.COUNT_100_200,
    TransactionCount.COUNT_200_300,
];

export const transactionCountText = (_count: TransactionCount): string => {
    switch (_count) {
        case TransactionCount.COUNT_0_10:
            return "0 - 10";
        case TransactionCount.COUNT_10_30:
            return "10 - 30";
        case TransactionCount.COUNT_30_60:
            return "30 - 60";
        case TransactionCount.COUNT_60_100:
            return "60 - 100";
        case TransactionCount.COUNT_100_200:
            return "100 - 200";
        case TransactionCount.COUNT_200_300:
            return "200 - 300";
    }
};

export const TransactionCountOptions = TransactionCountArray.map((_count) => ({
    text: transactionCountText(_count),
    value: _count,
}));

export const TerminalsFiltersArray = [TerminalsFilters.DATE, TerminalsFilters.TYPES, TerminalsFilters.STATUS];

export const TerminalsStatusesArray = [TerminalStatus.ACTIVE, TerminalStatus.REVOKED];

const getTerminalStatusName = (_status: TerminalStatus) => {
    switch (_status) {
        case TerminalStatus.ACTIVE:
            return "Active";
        case TerminalStatus.REVOKED:
            return "Revoked";
        default:
            return "";
    }
};

export const TerminalsStatusesDropdownItem = TerminalsStatusesArray.map((_status) => ({
    name: getTerminalStatusName(_status),
    value: _status,
}));

// Terminal request filter

export const TerminalRequestsFiltersArray = [TerminalRequestsFilters.STATUS, TerminalRequestsFilters.PURCHASE_TYPE];

export const TerminalRequestsStatusArray = [
    TerminalRequestStatus.APPROVED,
    TerminalRequestStatus.DECLINED,
    TerminalRequestStatus.DELIVERED,
    TerminalRequestStatus.REQUESTED,
    TerminalRequestStatus.SHIPPED,
    TerminalRequestStatus.TERMINAL_ASSIGNED,
];

export const TerminalRequestStatusName = {
    [TerminalRequestStatus.APPROVED]: "Approved",
    [TerminalRequestStatus.DECLINED]: "Declined",
    [TerminalRequestStatus.DELIVERED]: "Delivered",
    [TerminalRequestStatus.REQUESTED]: "Requested",
    [TerminalRequestStatus.SHIPPED]: "Shipped",
    [TerminalRequestStatus.TERMINAL_ASSIGNED]: "Terminal Assigned",
};

export const TerminalRequestsPurchaseTypesArray = [PurchaseType.LEASE, PurchaseType.OUTRIGHT_PURCHASE];

export const TerminalRequestPurchaseTypesName = {
    [PurchaseType.LEASE]: "Lease",
    [PurchaseType.OUTRIGHT_PURCHASE]: "Outright Purchase",
};

// export const CardWalletArray = [CardWalletType.INDEPENDENT_WALLET, CardWalletType.LINKED_TO_ACCOUNT];

export const TerminalTransactionStatusesArray = [BasicFinalStatus.SUCCESSFUL, BasicFinalStatus.FAILED];

export const getTerminalTransactionStatusName = (_status: BasicFinalStatus) => {
    switch (_status) {
        case BasicFinalStatus.SUCCESSFUL:
            return "Successful ";
        case BasicFinalStatus.FAILED:
            return "Failed";
        default:
            return "";
    }
};

export const TerminalTransactionStatusesDropdownItem = TerminalTransactionStatusesArray.map((_status) => ({
    name: getTerminalTransactionStatusName(_status),
    value: _status,
}));

export const TerminalTransactionTypesArray = [TransactionType.CARD_PAYMENT, TransactionType.TRANSFER_IN];

export const getTerminalTransactionTypeName = (_type: TransactionType) => {
    switch (_type) {
        case TransactionType.CARD_PAYMENT:
            return "Card Payment";
        case TransactionType.TRANSFER_IN:
            return "Transfer In";
        case TransactionType.TRANSFER_OUT:
            return "Transfer Out";
        default:
            return "";
    }
};

export const TerminalTransactionTypesDropdownItem = TerminalTransactionTypesArray.map((_type) => ({
    name: getTerminalTransactionTypeName(_type),
    value: _type,
}));
