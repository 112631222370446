import {
    ZambiaCollectionRequestChannelArray,
    ZambiaCollectionRequestStatusArray,
    getZambiaCollectionChannelName,
    getZambiaCollectionRequestStatusName,
} from "../../../../hooks/state/AddMoney/addMoney.constants";
import {
    resetAddMoneyCollectionRequestChannelFilterState,
    resetAddMoneyCollectionRequestDateFilterState,
    resetAddMoneyCollectionRequestQueryFilterState,
    resetAddMoneyCollectionRequestStatusFilterState,
    setAddMoneyCollectionRequestChannelFilter,
    setAddMoneyCollectionRequestStatusFilter,
} from "../../../../../../../redux/payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice";
import { useDispatch, useSelector } from "react-redux";

import { AddMoneyCollectionRequestFilterTypes } from "../../../../../../../redux/payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice.types";
import DateTag from "../../../../../../../components/filter/Tags/DateTag";
import { FilterDateTypes } from "../../../../../../../components/filter/filter.constant";
import FilterDropdown from "./Dropdown/FilterDropdown";
import GenericTag from "../../../../../../../components/filter/Tags/GenericTag";
// import GenericCard from "../../../../../Cards/Components/Dropdown/FilterDropDown/Cards/GenericCard";
import { IRootState } from "../../../../../../../redux/rootReducer";

interface Props {
    handleClearAll: () => void;
    handleFilteredDetails: (_data: AddMoneyCollectionRequestFilterTypes) => void;
}

function FilterSection(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const selectedAddMoneyCollectionRequestDate = useSelector((state: IRootState) => state.addMoneyHistory.selectedAddMoneyCollectionRequestDate);
    const selectedAddMoneyCollectionRequestFilterState = useSelector(
        (state: IRootState) => state.addMoneyHistory.selectedAddMoneyCollectionRequestFilterState
    );

    return (
        <>
            <div className="-mt-2 flex flex-col flex-wrap 4xs:flex-row">
                <div className="w-max pr-3 pt-2">
                    <FilterDropdown handleFilteredDetails={props.handleFilteredDetails} handleClearAll={props.handleClearAll} />
                </div>
                {selectedAddMoneyCollectionRequestFilterState.query.length > 0 && (
                    <GenericTag
                        title="Search"
                        values={[selectedAddMoneyCollectionRequestFilterState.query]}
                        onCancel={() => {
                            dispatch(resetAddMoneyCollectionRequestQueryFilterState());
                            props.handleFilteredDetails({ ...selectedAddMoneyCollectionRequestFilterState, query: "" });
                        }}
                    />
                )}
                {selectedAddMoneyCollectionRequestDate !== FilterDateTypes.ALL_TIME && (
                    <>
                        <DateTag
                            endDate={selectedAddMoneyCollectionRequestFilterState.date.end || ""}
                            startDate={selectedAddMoneyCollectionRequestFilterState.date.begin || ""}
                            handleCancelSelection={() => {
                                dispatch(resetAddMoneyCollectionRequestDateFilterState());
                                props.handleFilteredDetails({
                                    ...selectedAddMoneyCollectionRequestFilterState,
                                    date: {
                                        begin: "",
                                        end: "",
                                    },
                                });
                            }}
                            selectedFilterDate={selectedAddMoneyCollectionRequestDate}
                        />
                    </>
                )}
                {selectedAddMoneyCollectionRequestFilterState.status.length > 0 && (
                    <GenericTag
                        title="Status"
                        onCancel={() => {
                            dispatch(resetAddMoneyCollectionRequestStatusFilterState());
                            props.handleFilteredDetails({ ...selectedAddMoneyCollectionRequestFilterState, status: [] });
                        }}
                        onIndividualCancel={(_value) => {
                            dispatch(setAddMoneyCollectionRequestStatusFilter(_value));
                            props.handleFilteredDetails({
                                ...selectedAddMoneyCollectionRequestFilterState,
                                status: selectedAddMoneyCollectionRequestFilterState.status.filter((_el) => _el !== _value),
                            });
                        }}
                        options={ZambiaCollectionRequestStatusArray.map((_status) => ({
                            text: getZambiaCollectionRequestStatusName(_status),
                            value: _status,
                        }))}
                        values={selectedAddMoneyCollectionRequestFilterState.status}
                    />
                )}
                {selectedAddMoneyCollectionRequestFilterState.channel.length > 0 && (
                    <GenericTag
                        title="Channel"
                        onCancel={() => {
                            dispatch(resetAddMoneyCollectionRequestChannelFilterState());
                            props.handleFilteredDetails({ ...selectedAddMoneyCollectionRequestFilterState, channel: [] });
                        }}
                        onIndividualCancel={(_value) => {
                            dispatch(setAddMoneyCollectionRequestChannelFilter(_value));
                            props.handleFilteredDetails({
                                ...selectedAddMoneyCollectionRequestFilterState,
                                channel: selectedAddMoneyCollectionRequestFilterState.channel.filter((_el) => _el !== _value),
                            });
                        }}
                        options={ZambiaCollectionRequestChannelArray.map((_collectionRequestType) => ({
                            text: getZambiaCollectionChannelName(_collectionRequestType),
                            value: _collectionRequestType,
                        }))}
                        values={selectedAddMoneyCollectionRequestFilterState.channel}
                    />
                )}
            </div>
        </>
    );
}

export default FilterSection;
