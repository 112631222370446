import { ZambiaCollectionPaymentChannel, ZambiaSettlementStatus, ZambiaSettlementType } from "./zambiaCollectionRequest.constant";

import Currency from "../currency";
import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import UserAccount from "../userAccount";

export default class ZambiaCollectionSettlement {
    constructor(
        public id: string,
        public createdAt: string,
        public account: UserAccount | null,
        public channel: ZambiaCollectionPaymentChannel | null,
        public transactionAmount: string,
        public settlementAmount: string,
        public currency: Currency,
        public settlementType: ZambiaSettlementType | null,
        public status: ZambiaSettlementStatus | null,
        public settledAt: string | null
    ) {}

    static create(obj: GenericObject): ZambiaCollectionSettlement {
        return new ZambiaCollectionSettlement(
            Parsers.string(obj.id),
            Parsers.string(obj.createdAt),
            Parsers.classObject(obj.account, UserAccount),
            Parsers.nullableEnum(obj.channel, ZambiaCollectionPaymentChannel),
            Parsers.string(obj.transactionAmount),
            Parsers.string(obj.settlementAmount),
            Parsers.classObjectNonNullable(obj.currency, Currency),
            Parsers.nullableEnum(obj.settlementType, ZambiaSettlementType),
            Parsers.nullableEnum(obj.status, ZambiaSettlementStatus),
            Parsers.nullableString(obj.settledAt)
        );
    }
}
