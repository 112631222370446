import React, { useEffect, useState } from "react";

import BillPurchase from "../../../../../../../models/billPurchase";
import { BillPurchaseCategoriesType } from "../../../../../../../models/billPurchase.constant";
import Clipboard from "../../../../../../../components/clipboard";
import { formatDate } from "../../../../../../../utils/formatDate";

interface Props {
    data: BillPurchase;
    dataType: string;
}

export default function BillPurchaseDetailsCard({ data, dataType }: Props): JSX.Element {
    const tokenPattern = /Token: (\S+)/;
    const meterPattern = /PrePaid (\d+)/;
    const paymentIdPattern = /Ref: (\d+)/;
    const dataTimeDurationPattern = / - (\d+)/;
    const [token, setToken] = useState("");
    const [meterNumber, setPrepaid] = useState("");
    const [paymentId, setPaymentId] = useState("");
    const [dataTimeDuration, setDataTimeDuration] = useState(0);

    // Define the start and end patterns

    useEffect(() => {
        const tokenMatch = data?.instructions?.match(tokenPattern);
        const tokenValue = tokenMatch ? tokenMatch[1] : "";
        const meterMatch = meterPattern.exec(data?.narration);
        const meterValue = meterMatch ? meterMatch[1] : "";
        const paymentIdMatch = data?.instructions?.match(paymentIdPattern);
        const paymentIdValue = paymentIdMatch ? paymentIdMatch[1] : "";
        const dataDurationMatch = dataTimeDurationPattern.exec(data?.narration);
        const dataDurationValue = dataDurationMatch ? dataDurationMatch[1] : "";

        setToken(tokenValue);
        setPrepaid(meterValue);
        setPaymentId(paymentIdValue);
        if (dataDurationValue) setDataTimeDuration(Number(dataDurationValue));
    }, [data]);

    return (
        <div className="flex w-full flex-col space-y-4 rounded-lg border-grey-secondary bg-grey-backdrop p-4" data-type={dataType}>
            <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                <div className="w-full">
                    <div className="flex w-full items-start justify-between" data-type={dataType}>
                        <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                            Category
                        </h1>
                        <div className="text-right">
                            <div className="text-sm font-medium text-black-secondary">{data.getCategory}</div>
                        </div>
                    </div>
                </div>
            </div>

            {data.product.name && data.category === BillPurchaseCategoriesType.CABLE_TV && (
                <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                    <div className="w-full">
                        <div className="flex w-full items-start justify-between" data-type={dataType}>
                            <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                Product
                            </h1>
                            <div className="flex items-center justify-end text-right">
                                <div className="text-sm font-medium text-black-secondary">{data.product.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {data.requestedBy?.fullName && data.category === BillPurchaseCategoriesType.CABLE_TV && (
                <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                    <div className="w-full">
                        <div className="flex w-full items-start justify-between" data-type={dataType}>
                            <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                Customer Name
                            </h1>
                            <div className="flex items-center justify-end text-right">
                                <div className="text-sm font-medium text-black-secondary">{data.requestedBy?.fullName}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <>
                {data.extraData.mobile.phone && (
                    <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                        <div className="w-full">
                            <div className="flex w-full items-start justify-between" data-type={dataType}>
                                <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                    Phone Number
                                </h1>
                                <div className="flex items-center justify-end text-right">
                                    <div className="text-sm font-medium text-black-secondary">{data.extraData.mobile.phone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {data.datetime && (
                    <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                        <div className="w-full">
                            <div className="flex w-full items-start justify-between" data-type={dataType}>
                                <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                    Subscription Date
                                </h1>
                                <div className="flex items-center justify-end text-right">
                                    <div className="text-sm font-medium text-black-secondary">{formatDate(data.datetime as unknown as Date)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {data.datetime && dataTimeDuration ? (
                    <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                        <div className="w-full">
                            <div className="flex w-full items-start justify-between" data-type={dataType}>
                                <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                    Expiry Date
                                </h1>
                                <div className="flex items-center justify-end text-right">
                                    <div className="text-sm font-medium text-black-secondary">
                                        {formatDate((new Date(data.datetime).getDay() + dataTimeDuration) as unknown as Date)}{" "}
                                        <span>
                                            ({dataTimeDuration} {dataTimeDuration > 1 ? "days" : "day"})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
            <>
                {token && (
                    <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                        <div className="w-full">
                            <div className="flex w-full items-center justify-between" data-type={dataType}>
                                <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                    Token
                                </h1>
                                <div className="flex items-center justify-end text-right">
                                    {/* <div className="text-sm font-medium text-black-secondary">{token}</div> */}
                                    <div className="relative font-medium">
                                        <Clipboard codeString={token}>{token || ""}</Clipboard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {meterNumber && (
                    <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                        <div className="w-full">
                            <div className="flex w-full items-start justify-between" data-type={dataType}>
                                <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                    Meter Number
                                </h1>
                                <div className="text-right">
                                    <div className="text-sm font-medium text-black-secondary">{meterNumber}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {paymentId && (
                    <div className="flex w-full flex-col items-center justify-center pb-4 text-black-tertiary" data-type={dataType}>
                        <div className="w-full">
                            <div className="flex w-full items-start justify-between" data-type={dataType}>
                                <h1 className="text-sm font-normal text-black-tertiary" data-type={dataType}>
                                    Bill Payment ID
                                </h1>
                                <div className="text-right">
                                    <div className="text-sm font-medium text-black-secondary">{paymentId}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
}
