import ButtonComp from "../../../../../components/button/ButtonComp";
import ChartCanvas from "../../../../../components/chart";
import GoogleIcon from "../../../../../components/google-icon";
import { ReactComponent as GreenArrow } from "../../../../../assets/svg/green-trend.svg";
import { Link } from "react-router-dom";
import Money from "../../../../../components/money";
import { ReactComponent as OutflowArrowIcon } from "../../../../../assets/svg/red-trend.svg";
import { PaymentsType } from "../../../payments-new/hooks/state/send-money/payments.constants";
import SkeletonElement from "../../../../../components/skeleton/SkeletonElement";
import UserAccount from "../../../../../models/userAccount";
import doesUserHaveAccessToAccount from "../../../../../helpers/doesUserHaveAccessToAccount";
import { getAccountSummaryResponse } from "../../Services/accounts-api.types";
import moment from "moment";

interface Props {
    isLoading: boolean;
    showAmount: boolean;
    accountSummary: getAccountSummaryResponse | null;
    individualAccount: UserAccount | null;
    handleToggleShowAmount: () => void;
}

function IndividualAccountChartCard(props: Props) {
    const date = new Date();

    return (
        <>
            <div
                className={
                    `flex h-full min-h-72 flex-col space-y-8 rounded-lg px-2 py-5 leading-normal shadow sm:px-8 ` +
                    `${!props.isLoading ? "bg-white" : ""}`
                }
            >
                <div className="flex w-full flex-col items-center justify-start space-y-2">
                    {!props.isLoading ? (
                        <div className="flex w-full items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <p className="text-sm text-black-tertiary">Total Balance</p>
                                <ButtonComp buttonType="tertiary" color="black" onClick={props.handleToggleShowAmount}>
                                    <div className="flex items-center justify-center rounded bg-grey-tertiary p-1">
                                        {props.showAmount ? (
                                            <GoogleIcon icon="visibility_off" className="text-black-secondary" size="xs" isOutlined />
                                        ) : (
                                            <GoogleIcon icon="visibility" className="text-black-secondary" size="xs" isOutlined />
                                        )}
                                    </div>
                                </ButtonComp>
                            </div>

                            {doesUserHaveAccessToAccount(props.individualAccount?.id) && (
                                <Link
                                    to={{
                                        pathname: "/payments/make/single",
                                        search: `?from=${props.individualAccount?.id}`,
                                    }}
                                    state={{
                                        typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                                        from: props.individualAccount?.id,
                                    }}
                                >
                                    <ButtonComp color="grey" buttonType="flat" size="sm">
                                        <div className="flex items-center justify-center space-x-2">
                                            <GoogleIcon icon="send" />
                                            <span className="whitespace-nowrap text-sm">Send Money</span>
                                        </div>
                                    </ButtonComp>
                                </Link>
                            )}
                        </div>
                    ) : (
                        <div className="h-8 w-36 self-start">
                            <SkeletonElement box />
                        </div>
                    )}

                    <div className="flex h-8 w-full items-center justify-start">
                        {!props.isLoading ? (
                            <>
                                {props.showAmount ? (
                                    <p className="text-3xl font-medium text-black-secondary">
                                        {props.individualAccount ? (
                                            <Money amount={props.individualAccount.balance || ""} />
                                        ) : (
                                            <SkeletonElement title />
                                        )}
                                    </p>
                                ) : (
                                    <div className="flex flex-row items-center py-3">
                                        <div className="mr-1 h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                        <div className="mx-1 h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                        <div className="mx-1 h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                        <div className="mx-1 h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                        <div className="mx-1 h-2 w-2 rounded-full bg-black-secondary" />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="h-8 w-48 self-start">
                                <SkeletonElement box />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex h-full w-full flex-col items-center justify-start space-y-2">
                    {!props.isLoading ? (
                        <div className="flex w-full flex-col items-start justify-start space-y-3">
                            <h3 className="text-lg font-medium text-black-secondary">Insight for the Month</h3>
                            <div className="flex w-full max-w-full flex-row justify-between">
                                <div className="flex w-full max-w-1/2 flex-row items-center gap-x-1">
                                    <div className="flex w-full max-w-full flex-col">
                                        <span className="flex max-w-full items-center gap-x-1 text-xs font-normal text-black-secondary">
                                            <span>Inflow ({moment(date).format("MMM 'YY")})</span> <GreenArrow />
                                        </span>{" "}
                                        <span className="max-w-3/4 overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-medium text-black-secondary">
                                            <Money
                                                amount={props.individualAccount?.monthBalance.credit || 0}
                                                currency={props.individualAccount?.bankAccountCurrency}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="flex w-full max-w-1/2 flex-row items-center justify-end gap-x-1">
                                    <div className="flex flex-col">
                                        <span className="flex max-w-full items-center space-x-1 text-xs font-normal text-black-secondary">
                                            <span>Payout ({moment(date).format("MMM 'YY")})</span> <OutflowArrowIcon />
                                        </span>
                                        <span className="max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-medium text-black-secondary">
                                            <Money
                                                amount={props.individualAccount?.monthBalance.debit || 0}
                                                currency={props.individualAccount?.bankAccountCurrency}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex w-full flex-col items-start justify-start space-y-3">
                            <div className="h-6 w-48">
                                <SkeletonElement box />
                            </div>
                            <div className="flex w-full items-center justify-between">
                                <div className="h-10 w-28">
                                    <SkeletonElement box />
                                </div>
                                <div className="h-10 w-28">
                                    <SkeletonElement box />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex w-full flex-grow items-center justify-center">
                        {props.isLoading ? (
                            <SkeletonElement box />
                        ) : (
                            <ChartCanvas
                                data={(props.accountSummary?.chart || []).map((point) => {
                                    return { date: point.date, balance: point.balance as number };
                                })}
                                numPoints={30}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndividualAccountChartCard;
