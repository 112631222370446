import { DataColumn } from "../Type/DataRow";
import { ReactNode } from "react";
import { SubTextType } from "../Type/SubTextType";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatTime } from "../../../utils/formatTime";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function TextWithSubtext({ dataColumn, dataType }: Props) {
    return (
        <span data-type={dataType} className="flex flex-col items-start gap-y-1 leading-none">
            <span data-type={dataType} className="text-sm font-normal capitalize text-black-secondary">
                {dataColumn.textType === TextType.DATE
                    ? formatDate(dataColumn.text as Date)
                    : dataColumn.textType === TextType.TIME
                      ? formatTime(dataColumn.text as Date)
                      : dataColumn.textType === TextType.DATE_TIME
                        ? formatDateAndTime(dataColumn.text as Date)
                        : (dataColumn.text as string | ReactNode)}
            </span>
            {dataColumn.textType !== TextType.PROCESSING && dataColumn.subText && dataColumn.subTextType && (
                <div
                    data-type={dataType}
                    className={`text-xs font-normal leading-none ${dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_PROCESSING ? "text-warning" : dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_SUCCESS ? "text-success" : dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_CANCELLED ? "text-black-secondary" : dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_FAILED ? "text-error" : "text-black-tertiary"}`}
                >
                    {dataColumn.subTextType === SubTextType.DATE && formatDate(dataColumn.subText as Date)}
                    {dataColumn.subTextType === SubTextType.TIME && formatTime(dataColumn.subText as Date)}
                    {dataColumn.subTextType === SubTextType.DATE_TIME && formatDateAndTime(dataColumn.subText as Date)}
                    {dataColumn.subTextType === SubTextType.TRUNCATED_TEXT && (
                        <span className="">••••{typeof dataColumn.subText === "string" ? dataColumn.subText?.toString().slice(-4) : ""}</span>
                    )}
                    {(dataColumn.subTextType === SubTextType.STRING || dataColumn.subTextType === SubTextType.REACT_NODE) &&
                        (dataColumn.subText as string | ReactNode)}
                </div>
            )}
        </span>
    );
}
