import { BusinessAgreementRequest, BusinessAgreementResponse, UploadBusinessAgreementRequest } from "./agreement.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../../helpers/request/abortControllers";
import { makeRequestDownloadFile, makeRequestUploadFileWithSignal, makeRequestWithSignal } from "../../../../../../../helpers/request/makeRequest";

import Agreement from "../../../../../../../models/account-opening/agreement/agreement";
import BusinessDetailsMin from "../../../../../../../models/account-opening/businessDetailsMin";
import CreatedBy from "../../../../../../../models/account-opening/createdBy";
import { GenericObject } from "../../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../../utils/parsers";
import SignatoryMin from "../../../../../../../models/account-opening/signatoryInfoMin";
import UserMin from "../../../../../../../models/userMin";
import { toFormData } from "../../../../../../../utils/converters";

export enum BusinessAgreementRequests {
    INIT = "business-agreement.init",
    UPLOAD_INCORPORATION_DOCUMENT = "business-agreement.upload-incorporation-agreement",
    ADD_INCORPORATION_DOCUMENT_FILE = "business-agreement.add-incorporation-agreement-file",
    DELETE_INCORPORATION_DOCUMENT = "business-agreement.delete-incorporation-agreement",
    UPLOAD_UTILITY_BILL = "business-agreement.upload-utility-bill",
    ADD_UTILITY_BILL_FILE = "business-agreement.add-utility-bill-file",
    DELETE_UTILITY_BILL = "business-agreement.delete-utility-bill",
}
function getBusinessAgreementResponse(res: GenericObject | Error): BusinessAgreementResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        agreements: Parsers.classObjectNonNullable(res.agreements, Agreement),
        applicationMin: Parsers.classObjectNonNullable(res.applicationMin, BusinessDetailsMin),
        canHaveBoardResolution: Parsers.boolean(res.canHaveBoardResolution),
        createdBy: Parsers.classObjectNonNullable(res.createdBy, CreatedBy),
        directors: Parsers.classObjectArray(res.directors, SignatoryMin),
        currentUser: Parsers.classObjectNonNullable(res.currentUser, UserMin),
        signatories: Parsers.classObjectArray(res.signatories, SignatoryMin),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
    };
}

export async function agreementsInit(): Promise<BusinessAgreementResponse> {
    const signal = getAbortControllerSignal(BusinessAgreementRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/ng/agreement/details", {}, signal);
    return getBusinessAgreementResponse(res);
}
export async function uploadBusinessAgreement(_data: UploadBusinessAgreementRequest): Promise<BusinessAgreementResponse> {
    const signal = getAbortControllerSignal(BusinessAgreementRequests.INIT);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/agreement/upload", toFormData(_data), signal);
    return getBusinessAgreementResponse(res);
}
export async function appendBusinessAgreementSignature(_data: BusinessAgreementRequest): Promise<BusinessAgreementResponse> {
    const signal = getAbortControllerSignal(BusinessAgreementRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/ng/agreement/append-signature", _data, signal);
    return getBusinessAgreementResponse(res);
}
export async function signBusinessAgreement(_data: UploadBusinessAgreementRequest): Promise<BusinessAgreementResponse> {
    const signal = getAbortControllerSignal(BusinessAgreementRequests.INIT);
    const res = await makeRequestUploadFileWithSignal("/account-opening/ng/agreement/sign", toFormData(_data), signal);
    return getBusinessAgreementResponse(res);
}
export async function downloadBusinessAgreement(_data: BusinessAgreementRequest): Promise<Blob> {
    // const signal = getAbortControllerSignal(BusinessAgreementRequests.INIT);
    const res = await makeRequestDownloadFile("/account-opening/ng/agreement/download", _data);
    if (res instanceof Error) {
        throw res;
    }
    return res;
}
export async function downloadBusinessAgreementToSign(_data: BusinessAgreementRequest): Promise<Blob> {
    const res = await makeRequestDownloadFile("/account-opening/ng/agreement/download-to-sign", _data);
    if (res instanceof Error) {
        throw res;
    }
    return res;
}
export async function downloadBusinessAgreementBoardResolution(): Promise<Blob> {
    const res = await makeRequestDownloadFile("/account-opening/ng/agreement/board-resolution/download-sample");
    if (res instanceof Error) {
        throw res;
    }
    return res;
}

export function abortBusinessAgreementRequest(type: BusinessAgreementRequests): void {
    abortRequest(type);
}

export function abortAllBusinessAgreementRequests(): void {
    Object.values(BusinessAgreementRequests).forEach((type) => abortRequest(type));
}
