import BillPurchaseCardHead from "./Elements/BillPurchaseAmountCard";
import BillPurchaseDetailsCard from "./Elements/BillPurchaseDetailsCard";
import BillPurchaseStatusCard from "./Elements/BillPurchaseStatusCard";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayFooter from "../../../../../../components/Trays/FullPageTray/FullPageTrayFooter";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import NewTransactionIcon from "../../../../../../components/new-transaction-icon";
import { abortTransactionCardCalls } from "./Services/transactionDetailApi";
import { showTransactionDetails } from "../../../../../../redux/transaction/slice/transactionSlice";
import useBillPurchaseDetailsCard from "../../../hooks/state/BillPayment/BillPurchaseHistory/useBillPurchaseDetailsCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import useTransactionDetailsAccountingReceipt from "./Hooks/useTransactionDetailsAccountingReceipt";
import useTransactionDetailsActivateAccount from "./Hooks/useTransactionDetailsActivateAccount";
import useTransactionDetailsCard from "./Hooks/useTransactionDetailsCard";
import useTransactionDetailsPendingApproval from "./Hooks/useTransactionDetailsPendingApproval";

export default function BillPurchaseDetailCard(): JSX.Element {
    const dispatch = useDispatch();
    const { canShowTransaction, setShowTagRecipientModal } = useTransactionDetailsCard();

    const { canShowCard, handleCloseDetailsCard, selectedBillPurchase: billPurchase } = useBillPurchaseDetailsCard();

    const { handleClosePreviewModal } = useTransactionDetailsAccountingReceipt();

    // Activate Account Hook
    const { setShowActivateAccountModal } = useTransactionDetailsActivateAccount();

    // Pending Transaction Hook
    const { handleClosePendingApprovalShowModal } = useTransactionDetailsPendingApproval();

    useEffect(() => {
        if (!canShowTransaction) {
            handleClosePendingApprovalShowModal;
            setShowTagRecipientModal(false);
            handleClosePreviewModal();
            setShowActivateAccountModal(false);
            abortTransactionCardCalls();
        }
    }, [canShowTransaction]);

    return (
        <>
            <FullPageTray active={canShowCard} handleReset={handleCloseDetailsCard} withClickOutside dataType={"billPurchase"}>
                <FullPageTrayHeader toggler={handleCloseDetailsCard} dataType={"billPurchase"}>
                    Bill Payment Details
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={"billPurchase"}>
                    <div className="flex w-full flex-col space-y-4 pb-4">
                        {billPurchase && <BillPurchaseCardHead data={billPurchase} dataType={"billPurchase"} />}
                        {billPurchase && <BillPurchaseDetailsCard data={billPurchase} dataType={"billPurchase"} />}
                        {billPurchase && <BillPurchaseStatusCard data={billPurchase} dataType={"billPurchase"} />}
                    </div>
                </FullPageTrayBody>
                {billPurchase && (
                    <FullPageTrayFooter dataType={"billPurchase"}>
                        <NewTransactionIcon
                            icon="description"
                            text="Transaction Details"
                            size="md"
                            onClick={() => dispatch(showTransactionDetails(billPurchase?.transactionId || ""))}
                        />
                    </FullPageTrayFooter>
                )}
            </FullPageTray>
        </>
    );
}
