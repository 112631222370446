import { IRootState } from "../../../../../../redux/rootReducer";
import { Link } from "react-router-dom";
import React from "react";
import { ReactComponent as StartVideoImage } from "../../../../../../assets/svg/Terminal/StartVideoImage.svg";
import TerminalDispatched from "../../../../../../assets/svg/Terminal/terminal-dispatched.svg";
import { ReactComponent as TerminalDispatchedStepper } from "../../../../../../assets/svg/Terminal/terminal-dispatched-stepper.svg";
import { useSelector } from "react-redux";
import ButtonComp from "../../../../../../components/button/ButtonComp";

interface Props {
    sm?: boolean;
    isTray?: boolean;
}

function TerminalDispatchedCard(props: Props): JSX.Element {
    // const dispatch = useDispatch();
    const initMeta = useSelector((state: IRootState) => state.terminals.initMeta);
    return (
        <>
            {!props.sm && (
                <div className="flex w-full items-center justify-between px-6 py-4">
                    <div className="flex flex-col items-start justify-start space-y-4">
                        <div className="flex flex-col items-start justify-start space-y-3">
                            <div className="flex items-center justify-start space-x-2">
                                <img src={TerminalDispatched} alt="vehicle" />
                                <span className="text-base font-medium text-black-secondary md:text-lg">Your Terminal is on it&apos;s way</span>
                            </div>
                            <TerminalDispatchedStepper />
                        </div>
                        {initMeta && initMeta.setupVideoUrl && (
                            <div className="flex flex-col">
                                <span className="text-xs font-normal text-black-tertiary md:text-sm">Watch how to set up your terminal</span>
                            </div>
                        )}
                    </div>
                    {initMeta && initMeta.setupVideoUrl && (
                        <Link to={initMeta.setupVideoUrl} target="_blank" className="float-end">
                            <div className="">
                                <div className="relative overflow-hidden rounded-lg" style={{ width: "243px", height: "115px" }}>
                                    <StartVideoImage className="w-full" />
                                </div>
                            </div>
                        </Link>
                    )}
                </div>
            )}

            {props.sm && (
                <>
                    <div className={"flex flex-col items-start justify-start " + `${props.isTray ? "space-y-4" : "space-y-1"}`}>
                        <div className="flex items-center justify-start space-x-2">
                            <img src={TerminalDispatched} alt="vehicle" />
                            <span className="text-base font-medium text-black-secondary">Your Terminal is on it&apos;s way</span>
                        </div>
                        {props.isTray && <TerminalDispatchedStepper />}
                        {initMeta && initMeta.setupVideoUrl && (
                            <div className="flex flex-col">
                                <span className="text-sm font-normal text-black-tertiary">Watch how to set up your terminal </span>
                            </div>
                        )}
                    </div>

                    {initMeta && initMeta.setupVideoUrl && (
                        <Link to={initMeta.setupVideoUrl} target="_blank">
                            <ButtonComp type="button" ripple="light" buttonType="primary" color="blue" size="md">
                                <span className="normal-case">Watch Videos</span>
                            </ButtonComp>
                        </Link>
                    )}
                </>
            )}
        </>
    );
}
export default TerminalDispatchedCard;
