import { ZambiaCollectionRequestTableHeader, ZambiaCollectionRequestTableRow } from "../../../hooks/state/AddMoney/addMoney.constants";
import {
    resetAllAddMoneyCollectionRequestData,
    resetSelectedAddMoneyCollectionRequestDetails,
    setIsAddMoneyCollectionRequestDetailCardOpen,
    setSelectedAddMoneyCollectionRequestDetails,
} from "../../../../../../redux/payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice";
import { useDispatch, useSelector } from "react-redux";

import AddMoneyHistoryTray from "./Tray/AddMoneyHistoryTray";
import FilterSection from "./FilterSection/FilterSection";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import PageLayout from "../../../../../../components/layouts/page-layout";
import Table from "../../../../../../components/Table";
import useAddMoneyHistory from "../../../hooks/state/AddMoney/useAddMoneyHistory";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AddMoneyHistory(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleClearAll, handlePaginationRequest, handleFilteredAddMoneyCollectionRequest } = useAddMoneyHistory();

    const addMoneyCollectionRequestList = useSelector((state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestList);
    const addMoneyCollectionRequestListTotal = useSelector((state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestListTotal);
    const addMoneyCollectionRequestListGroupSize = useSelector((state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestListGroupSize);
    const addMoneyCollectionRequestPaginationOffset = useSelector(
        (state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestPaginationOffset
    );
    const isAddMoneyCollectionRequestPaginationLoading = useSelector(
        (state: IRootState) => state.addMoneyHistory.isAddMoneyCollectionRequestPaginationLoading
    );

    useEffect(() => {
        void handleFilteredAddMoneyCollectionRequest({ offset: 0 });
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetAllAddMoneyCollectionRequestData());
        };
    }, []);

    return (
        <>
            <AddMoneyHistoryTray />

            <PageLayout pageTitle="Add Money History" subTitle="Here's a list of your add money transactions.">
                <div className="relative flex h-full w-full flex-col items-start justify-start space-y-6">
                    <div className="relative flex w-full items-center justify-start space-x-2">
                        <div className="w-max select-none rounded-[4px] bg-white px-2 py-1">
                            <p className="text-xs leading-[100%] text-black-tertiary xs:text-sm">Add Money</p>
                        </div>
                        <GoogleIcon icon="chevron_right" isOutlined />
                        <div
                            className={
                                "flex items-center justify-start text-xs leading-none" +
                                " w-max rounded-[4px] bg-white px-2 py-1" +
                                " font-medium text-black-secondary"
                            }
                        >
                            <p className="select-none text-sm font-medium capitalize leading-[100%] text-black-secondary xs:text-sm">
                                Add Money History
                            </p>
                            <div
                                className="ml-2 max-w-[20px] cursor-pointer overflow-hidden text-black"
                                onClick={(e) => {
                                    dispatch(setIsAddMoneyCollectionRequestDetailCardOpen(false));
                                    dispatch(resetSelectedAddMoneyCollectionRequestDetails());
                                    navigate("/payments/add-money/make");
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <GoogleIcon icon="close" />
                            </div>
                        </div>
                    </div>
                    <div className="relative flex h-full w-full flex-col items-start justify-start">
                        <div className="left-0 top-0 flex h-full w-full flex-col items-start justify-start space-y-4">
                            <FilterSection handleClearAll={handleClearAll} handleFilteredDetails={handleFilteredAddMoneyCollectionRequest} />
                            <Table
                                heads={ZambiaCollectionRequestTableHeader}
                                total={addMoneyCollectionRequestListTotal}
                                offset={addMoneyCollectionRequestPaginationOffset}
                                dataType="zambia-collection-request"
                                groupSize={addMoneyCollectionRequestListGroupSize}
                                isLoading={!!isAddMoneyCollectionRequestPaginationLoading}
                                isPaginateLoading={isAddMoneyCollectionRequestPaginationLoading}
                                rows={(addMoneyCollectionRequestList || []).map((_collectionRequest) => ({
                                    onRecordClick: () => {
                                        dispatch(setIsAddMoneyCollectionRequestDetailCardOpen(true));
                                        dispatch(setSelectedAddMoneyCollectionRequestDetails(_collectionRequest.id));
                                    },
                                    record: ZambiaCollectionRequestTableRow(_collectionRequest),
                                }))}
                                paginateFunction={(_, pageOffset) => handlePaginationRequest(pageOffset)}
                                // isMaxHeight
                            />
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default AddMoneyHistory;
