import { useEffect, useState } from "react";

import ButtonComp from "../../../../components/button/ButtonComp";
import { ReactComponent as Mailbox } from "../../../../assets/svg/mail-box.svg";
import Otp from "../../../../components/otp";
import useVerifyEmailOtp from "../hooks/use-verify-email-otp";

interface Props {
    userKey: string;
    email: string;
    onComplete: (_username: string) => void;
}

function EmailOtp(props: Props) {
    const [otp, setOtp] = useState<string>("");

    const { mutate, isError, isPending } = useVerifyEmailOtp({
        onComplete: (_username) => props.onComplete(_username),
    });

    useEffect(() => {
        if (!otp || otp.length < 6 || !props.userKey) return;
        mutate({
            key: props.userKey,
            otp: otp,
        });
    }, [otp, props.userKey]);

    return (
        <>
            <div className="relative mx-auto flex w-full max-w-2xl flex-col items-center justify-center rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex flex-col items-center justify-start space-y-6">
                    <div className="flex w-full flex-col items-center justify-start space-y-8">
                        <div>
                            <Mailbox />
                        </div>
                        <div className="flex w-full max-w-[360px] flex-col items-center justify-start space-y-4">
                            <h3 className="text-center text-2xl font-medium text-black">Verify your email</h3>
                            <p className="text-center font-normal text-black-secondary">
                                We sent a One-Time Password (OTP) to
                                <span className="font-medium"> {props.email || ""}</span> to authenticate your sign up.
                            </p>
                        </div>
                    </div>
                    <div className="max-w-md">
                        <Otp value={otp} onChange={(_value) => setOtp(_value)} isError={isError} />
                    </div>
                    <div className="mx-auto w-full max-w-md">
                        <ButtonComp color="black" buttonType="primary" isLoading={isPending} fullWidth disable>
                            <span>Continue</span>
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailOtp;
