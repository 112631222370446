import {
    AgreementRequirements,
    AgreementRequirementsWithBoardResolutions,
    AgreementType,
    AllAgreements,
    getAgreementHints,
} from "../../../../../services/nigeria-application/registered/new/agreement.types";
import { HintType, ListStyle } from "../../../../element/hint/hint.constant";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import AccountMandateComponent from "./components/AccountMandateComponent";
import AccountOpeningDeclarationComponent from "./components/AccountOpeningDeclarationComponent";
import ApplicationHeader from "../../../../element/ApplicationHeader";
import BoardResolutionComponent from "./components/BoardResolutionComponent";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import Hint from "../../../../element/hint";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../../../components/spinner";
import { Routes } from "../../../../../../../../routes/routes.constants";
import SearchReportComponent from "./components/SearchReportComponent";
import TermsAndConditionsComponent from "./components/TermsAndConditionsComponent";
import UseAgreementInit from "../../../../../Hooks/nigeria-application/registered-business/agreement/useAgreementInit";
import { useSelector } from "react-redux";

type LocationState = {
    from?: string;
};

function Agreements(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const locationState = location.state ? (location.state as LocationState) : null;

    const { isAgreementDetailsLoading, handleReset, handleAgreementInit } = UseAgreementInit();

    const isCreator = useSelector((state: IRootState) => state.application.init?.isCreator);
    const agreement = useSelector((state: IRootState) => state.application.agreement);
    const accountOpening = useSelector((state: IRootState) => state.application.init);

    const [show, setShow] = useState({
        [AgreementType.ACCOUNT_MANDATE_CARD]: false,
        [AgreementType.ACCOUNT_OPENING_DECLARATION]: false,
        [AgreementType.BOARD_RESOLUTION]: false,
        [AgreementType.SEARCH_REPORT]: false,
        [AgreementType.TERMS_AND_CONDITIONS]: false,
    });
    const completedAgreements = agreement ? AllAgreements.filter((_keys: AgreementType) => agreement.agreements[_keys].signedDocumentId) : [];
    const listOfAgreements = [
        ...(agreement ? (agreement.canHaveBoardResolution ? AgreementRequirementsWithBoardResolutions : AgreementRequirements) : []),
    ];
    const uncompletedAgreements = listOfAgreements.filter(
        (_agreement) => !completedAgreements.some((_completedAgreement) => _completedAgreement === _agreement)
    );

    useLayoutEffect(() => {
        void handleAgreementInit();

        return () => {
            handleReset();
        };
    }, []);

    useEffect(() => {
        toggle(uncompletedAgreements[0]);
    }, [agreement]);

    const toggle = useCallback((key: AgreementType) => {
        setShow((prev) => {
            if (prev[key]) {
                return { ...prev, [key]: !prev[key] };
            } else {
                return {
                    [AgreementType.ACCOUNT_MANDATE_CARD]: false,
                    [AgreementType.ACCOUNT_OPENING_DECLARATION]: false,
                    [AgreementType.BOARD_RESOLUTION]: false,
                    [AgreementType.SEARCH_REPORT]: false,
                    [AgreementType.TERMS_AND_CONDITIONS]: false,
                    [key]: true,
                };
            }
        });
    }, []);

    const handleBack = useCallback(() => {
        if (locationState?.from === Routes.ACCOUNT_OPENING.REVIEW) {
            navigate(Routes.ACCOUNT_OPENING.REVIEW);
            return;
        }
        if (locationState?.from === Routes.ACCOUNT_OPENING.STATUS) {
            navigate(Routes.ACCOUNT_OPENING.STATUS);
            return;
        }
        navigate(isCreator ? Routes.ACCOUNT_OPENING.REGISTERED.DOCUMENT : Routes.ACCOUNT_OPENING.REGISTERED.SIGNATORY.SIGNATURE);
    }, [location, isCreator]);

    return (
        <>
            {isAgreementDetailsLoading && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <LencoSpinner />
                </div>
            )}
            {!isAgreementDetailsLoading && agreement && accountOpening && (
                <div className="flex w-full flex-row items-start justify-start space-x-6">
                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                        <ApplicationHeader
                            header="Agreements"
                            subTitle="Please sign the following documents."
                            progress={
                                isCreator
                                    ? accountOpening.progress.agreementsCompletedProgress()
                                    : accountOpening.progress.currentSignatoryAgreementProgress()
                            }
                        >
                            <div className="relative -mt-1 flex w-full flex-col items-start justify-start space-y-4">
                                <AccountMandateComponent
                                    isOpen={show[AgreementType.ACCOUNT_MANDATE_CARD]}
                                    toggle={() => toggle(AgreementType.ACCOUNT_MANDATE_CARD)}
                                />
                                <SearchReportComponent
                                    isOpen={show[AgreementType.SEARCH_REPORT]}
                                    toggle={() => toggle(AgreementType.SEARCH_REPORT)}
                                />
                                <AccountOpeningDeclarationComponent
                                    isOpen={show[AgreementType.ACCOUNT_OPENING_DECLARATION]}
                                    toggle={() => toggle(AgreementType.ACCOUNT_OPENING_DECLARATION)}
                                />
                                <TermsAndConditionsComponent
                                    isOpen={show[AgreementType.TERMS_AND_CONDITIONS]}
                                    toggle={() => toggle(AgreementType.TERMS_AND_CONDITIONS)}
                                />
                                {agreement.canHaveBoardResolution && (
                                    <BoardResolutionComponent
                                        isOpen={show[AgreementType.BOARD_RESOLUTION]}
                                        toggle={() => toggle(AgreementType.BOARD_RESOLUTION)}
                                    />
                                )}
                            </div>
                        </ApplicationHeader>
                        <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                            <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                <ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
                                    <span>Back{locationState?.from === Routes.ACCOUNT_OPENING.REVIEW ? " to Review" : ""}</span>
                                </ButtonComp>
                            </div>
                            {locationState?.from !== Routes.ACCOUNT_OPENING.REVIEW && (
                                <div className="order-1 w-full pb-4 2xs:order-2 2xs:w-max 2xs:pb-0">
                                    <ButtonComp
                                        type="button"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        disable={locationState?.from === Routes.ACCOUNT_OPENING.REVIEW}
                                        func={() =>
                                            navigate(
                                                {
                                                    pathname: isCreator
                                                        ? Routes.ACCOUNT_OPENING.REGISTERED.REFEREE.DETAILS
                                                        : Routes.ACCOUNT_OPENING.REVIEW,
                                                },
                                                {
                                                    state: {
                                                        from: Routes.ACCOUNT_OPENING.REGISTERED.AGREEMENT,
                                                    },
                                                }
                                            )
                                        }
                                        fullWidth
                                    >
                                        <span>{isCreator ? "Save and Continue" : "Review Application"}</span>
                                    </ButtonComp>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* {isCreator && ( */}
                    <Hint
                        // hintType={HintType.GUIDE}
                        // listStyle={ListStyle.PLAIN}
                        // firstList={AgreementsFirstGuide}
                        // secondHint
                        hintType={HintType.GUIDE}
                        listStyle={ListStyle.CHECK}
                        firstList={getAgreementHints(listOfAgreements, uncompletedAgreements, [])}
                    />
                    {/* )} */}
                </div>
            )}
        </>
    );
}

export default Agreements;
