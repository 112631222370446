import React from "react";

function NoAccessDescription(): JSX.Element {
    return (
        <div className="item-start flex w-full flex-col justify-start space-y-2">
            <div className="relative flex w-[260px] flex-col items-start justify-center space-y-2">
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-error">close</span>
                    <div className="whitespace-nowrap text-xs text-black-secondary">No Access</div>
                </div>
            </div>
        </div>
    );
}

export default NoAccessDescription;
