import { ExternalApplicationRequests, abortExternalApplicationRequest, updateExternalDirectorNextOfKin } from "../service/externalApplication.api";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ExternalInitRequest } from "../service/externalApplication.types";
import { IRootState } from "../../../../../redux/rootReducer";
import { NextOfKinForm } from "../../../../dashboard/applicationn/services/nigeria-application/registered/currentSignatory.types";
import { Routes } from "../../../../../routes/routes.constants";
// import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { updateExternalDirector } from "../../../../../redux/externalApplication/externalApplicationSlice";
import { useNavigate } from "react-router";

export interface UseExternalDirectorNextOfKinInterface {
    isExternalDirectorNextOfKinError: string | null;
    isExternalDirectorNextOfKinLoading: boolean | null;
    handleReset: () => void;
    handleUpdateExternalDirectorNextOfKin: (_externalParams: ExternalInitRequest, _data: NextOfKinForm) => Promise<void>;
}

const useExternalDirectorNextOfKin = (): UseExternalDirectorNextOfKinInterface => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const url = useSelector((state: IRootState) => state.externalApplication.externalLink || "");

    const [isExternalDirectorNextOfKinError, setIsExternalDirectorNextOfKinError] = useState<string | null>(null);
    const [isExternalDirectorNextOfKinLoading, setIsExternalDirectorNextOfKinLoading] = useState<boolean | null>(false);

    const handleUpdateExternalDirectorNextOfKin = useCallback(
        async (_externalParams: ExternalInitRequest, _data: NextOfKinForm): Promise<void> => {
            try {
                setIsExternalDirectorNextOfKinError(null);
                setIsExternalDirectorNextOfKinLoading(true);
                const res = await updateExternalDirectorNextOfKin(_externalParams, _data);
                dispatch(updateExternalDirector(res.director));
                navigate(
                    {
                        pathname: `${url}${Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID}`,
                    },
                    {
                        state: {
                            from: navigate(`${url}${Routes.EXTERNAL_DIRECTOR.NEXT_OF_KIN}`),
                        },
                    }
                );
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setIsExternalDirectorNextOfKinError(errorMessage);
                // dispatch(errorTrue(errorMessage));
            } finally {
                setIsExternalDirectorNextOfKinLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setIsExternalDirectorNextOfKinError(null);
        setIsExternalDirectorNextOfKinLoading(false);
        abortExternalApplicationRequest(ExternalApplicationRequests.UPDATE_EXTERNAL_DIRECTOR_NEXT_OF_KIN);
    }, []);

    return {
        isExternalDirectorNextOfKinError,
        isExternalDirectorNextOfKinLoading,
        handleReset,
        handleUpdateExternalDirectorNextOfKin,
    };
};
export default useExternalDirectorNextOfKin;
