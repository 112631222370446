import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import AvatarDetails from "../../../../../../../components/avatar-details";
import ButtonComp from "../../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../../redux/rootReducer";
import MessageToast from "../../../../../../../components/message-toast";
import Money from "../../../../../../../components/money";
import { PaymentStageType } from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";
// import { ReactComponent as Lock } from "../../../../../../../assets/images/yellowlock.svg";
import ZambiaFee from "../../../../../../../components/fees/ZambiaFee";
import getBankName from "../../../../../../../helpers/getBankName";
import getInitials from "../../../../../../../helpers/get-initials";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import { setCanVerifyZambiaRecipientAccountDetails } from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { setPaymentStage } from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import titleCase from "../../../../../../../hooks/titleCase";
import { useMoneyToNumber } from "../../../../../../../hooks/useMoneyToNumber";
import useSingleTransfer from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransfer";

function ReviewPayment(): JSX.Element {
    const dispatch = useDispatch();

    const { isSinglePaymentLoading, singlePaymentResponse, handleSingleTransfer } = useSingleTransfer();

    const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
    const selectedUserAccount = useSelector((state: IRootState) => state.zambiaSingleTransfer.selectedUserAccount);
    const singleTransferDetails = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails);
    const recipientBankAccountName = useSelector((state: IRootState) => state.zambiaSingleTransfer.recipientBankAccountName);

    const [canApprove, setCanApprove] = useState(false);

    useEffect(() => {
        if (!userAccountsMeta || !selectedUserAccount) return;
        const doesAccountExist = userAccountsMeta.some((userAccountMeta) => userAccountMeta.userAccountId === selectedUserAccount.id);
        if (!isNullOrUndefined(doesAccountExist)) {
            const userAccountMeta = userAccountsMeta.find((_el) => _el.userAccountId === selectedUserAccount.id) as UserAccountMeta;
            if (userAccountMeta.isApprover) {
                setCanApprove(true);
            } else {
                setCanApprove(false);
            }
        }
    }, [userAccountsMeta, selectedUserAccount]);

    useEffect(() => {
        if (canApprove || !singlePaymentResponse) return;
        dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
    }, [canApprove, singlePaymentResponse]);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-9">
                <SinglePaymentHeaders />
                <div className="flex w-full flex-col space-y-6">
                    <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                        <div className="flex flex-col items-center justify-center space-y-3 pb-6">
                            <AvatarDetails
                                size="lg"
                                initials={getInitials(selectedUserAccount?.bankAccount?.accountName || "")}
                                icon="outflow"
                                fullWidth={false}
                            />
                            <p className="text-sm font-normal !leading-[100%] text-black-tertiary">You&apos;re sending</p>
                            <p className="text-[32px] font-medium !leading-[100%] text-black">
                                <Money amount={useMoneyToNumber(singleTransferDetails.amount)} />
                            </p>
                            {singleTransferDetails.paymentMethod && (
                                <div className="text-sm font-normal !leading-[100%] text-black-tertiary">
                                    <ZambiaFee
                                        value={singleTransferDetails?.amount || ""}
                                        paymentMethod={singleTransferDetails.paymentMethod}
                                        isWithTax
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex w-full flex-col space-y-3">
                            <p className="text-sm font-medium leading-[100%] text-black-tertiary">From</p>
                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Account Name</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {selectedUserAccount && selectedUserAccount?.bankAccount?.accountName}
                                    </p>
                                </div>
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Account Number</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {selectedUserAccount && selectedUserAccount?.bankAccount?.accountNumber}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col space-y-3">
                            <p className="text-sm font-medium leading-[100%] text-black-tertiary">To</p>
                            <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Account Name</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">{titleCase(recipientBankAccountName || "")}</p>
                                </div>
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Account Number</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {singleTransferDetails.recipient.accountNumber}
                                    </p>
                                </div>
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Bank Name</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {titleCase(getBankName(singleTransferDetails.recipient.bankCode))}
                                    </p>
                                </div>
                                <div className="flex w-full flex-row justify-between">
                                    <span className="text-sm text-black-tertiary">Purpose</span>
                                    <p className="text-right text-sm font-medium text-black-secondary">
                                        {singleTransferDetails && singleTransferDetails.purpose}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MessageToast
                        type="security"
                        message="Ensure you verify that the recipient is genuine as payments can not be reversed after approval."
                        fullWidth
                    />
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp
                        size="xl"
                        type="button"
                        color="grey"
                        ripple="light"
                        buttonType="secondary"
                        func={() => {
                            dispatch(setCanVerifyZambiaRecipientAccountDetails(false));
                            dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
                        }}
                    >
                        Back
                    </ButtonComp>
                    <ButtonComp
                        size="xl"
                        color="black"
                        ripple="light"
                        buttonType="primary"
                        func={() => {
                            if (canApprove) {
                                dispatch(setPaymentStage(PaymentStageType.MAKE_PAYMENT));
                            } else {
                                void handleSingleTransfer({
                                    ...singleTransferDetails,
                                    amount: useMoneyToNumber(singleTransferDetails.amount),
                                });
                            }
                        }}
                        isLoading={isSinglePaymentLoading}
                    >
                        {canApprove ? "Make Payment" : "Initiate Payment"}
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default ReviewPayment;
