import { CardRequestType, abortCardRequest, freezeCard, unFreezeCard } from "../../Services/cardsApi";
import { freezeCardDTO, unFreezeCardDTO } from "../../Services/cardsApi.types";
import { setSelectedCardDetails, updateCard } from "../../../../../redux/cards/slice/cardsSlice";
import { useCallback, useState } from "react";

import Card from "../../../../../models/card";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { mainInitAddCard } from "../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

interface UsefreezeCard {
    freezeCardError: string | null;
    freezeCardDone: boolean;
    isFreezeCardLoading: boolean;
    isUnFreezeCardLoading: boolean;
    unFreezeCardError: string | null;
    unFreezeCardDone: boolean;
    handleFreezeCard: (input: freezeCardDTO) => void;
    handleUnFreezeCard: (input: unFreezeCardDTO) => void;
    handleReset: () => void;
}

const useFreezeCard = (): UsefreezeCard => {
    const dispatch = useDispatch();

    const [freezeCardError, setfreezeCardError] = useState<string | null>(null);
    const [freezeCardDone, setfreezeCardDone] = useState<boolean>(false);
    const [isFreezeCardLoading, setIsfreezeCardLoading] = useState<boolean>(false);

    const [unFreezeCardError, setUnFreezeCardError] = useState<string | null>(null);
    const [unFreezeCardDone, setUnFreezeCardDone] = useState<boolean>(false);
    const [isUnFreezeCardLoading, setIsUnFreezeCardLoading] = useState<boolean>(false);

    const handleFreezeCard = useCallback(
        async (_data: freezeCardDTO) => {
            try {
                setfreezeCardDone(false);
                setfreezeCardError(null);
                setIsfreezeCardLoading(true);
                const res = await freezeCard(_data);
                setfreezeCardDone(true);
                dispatch(mainInitAddCard(res.cardData.card as Card));
                dispatch(updateCard(res.cardData));
                dispatch(setSelectedCardDetails(_data?.createRequestId));
                dispatch(messageTrue({ message: "Card Frozen Successfully" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setfreezeCardError(errorMessage);
            } finally {
                setIsfreezeCardLoading(false);
            }
        },
        [dispatch]
    );

    const handleUnFreezeCard = useCallback(
        async (_data: unFreezeCardDTO) => {
            try {
                setUnFreezeCardDone(false);
                setUnFreezeCardError(null);
                setIsUnFreezeCardLoading(true);
                const res = await unFreezeCard(_data);
                setUnFreezeCardDone(true);
                dispatch(mainInitAddCard(res.cardData.card as Card));
                dispatch(updateCard(res.cardData));
                dispatch(setSelectedCardDetails(_data?.createRequestId));
                dispatch(messageTrue({ message: "Card Unfrozen Successfully" }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setUnFreezeCardError(errorMessage);
            } finally {
                setIsUnFreezeCardLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setfreezeCardDone(false);
        setfreezeCardError(null);
        setIsfreezeCardLoading(false);
        abortCardRequest(CardRequestType.CARD_FREEZE);
        abortCardRequest(CardRequestType.CARD_UNFREEZE);
    }, []);

    return {
        unFreezeCardError,
        unFreezeCardDone,
        isUnFreezeCardLoading,
        freezeCardError,
        freezeCardDone,
        isFreezeCardLoading,
        handleFreezeCard,
        handleUnFreezeCard,
        handleReset,
    };
};

export default useFreezeCard;
