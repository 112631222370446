import React, { ReactElement, useCallback, useEffect } from "react";
import { resetSelectedUserGroup, setPeopleStage } from "../../../../../redux/people/slice/peopleSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../redux/rootReducer";
import Input from "../../../../../components/inputs/Input";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import { PeopleStageType } from "../../../../../redux/people/slice/peopleSlice.types";
import useCreateUserGroup from "../../hooks/use-create-user-group";

function CreateCategoryModal(): ReactElement {
    const dispatch = useDispatch();

    const {
        createUserGroupError,
        userGroupName,
        createUserGroupResponse,
        handleChangeUserGroupName,
        handleCreateUserGroup,
        handleResetAll,
        isCreateUserGroupLoading,
    } = useCreateUserGroup();

    const peopleStage = useSelector((state: IRootState) => state.people.peopleStage);

    useEffect(() => {
        if (!createUserGroupResponse) return;
        handleResetAll();
        dispatch(setPeopleStage(PeopleStageType.CATEGORY_LIST));
    }, [handleResetAll, createUserGroupResponse]);

    const handleToggle = useCallback(() => {
        dispatch(resetSelectedUserGroup());
        dispatch(setPeopleStage(PeopleStageType.INITIAL));
    }, []);

    const handleBack = useCallback(() => {
        dispatch(resetSelectedUserGroup());
        dispatch(setPeopleStage(PeopleStageType.CATEGORY_LIST));
    }, []);

    const handleCreate = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            void handleCreateUserGroup({ name: userGroupName });
        },
        [userGroupName]
    );

    return (
        <>
            <Modal size="md" active={!!(peopleStage === PeopleStageType.CREATE_CATEGORY)} toggler={handleToggle}>
                <ModalHeader onClose={handleToggle}>Create a Group</ModalHeader>
                <form className="w-full" onSubmit={handleCreate}>
                    <ModalBody>
                        <div className="flex w-full flex-col">
                            {createUserGroupError && (
                                <div className="pb-4">
                                    <ErrorToast error={createUserGroupError} />
                                </div>
                            )}
                            <Input placeholder="Group Name" value={userGroupName} onChange={handleChangeUserGroupName} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonComp type="button" buttonType="secondary" color="grey" ripple="light" size="lg" func={handleBack}>
                            <span>Back</span>
                        </ButtonComp>
                        <ButtonComp type="submit" color="black" ripple="light" buttonType="primary" size="lg" isLoading={isCreateUserGroupLoading}>
                            <span>Create Group</span>
                        </ButtonComp>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
}
export default CreateCategoryModal;
