import React from "react";

function ManagerDescription(): JSX.Element {
    return (
        <div className="item-start flex w-full flex-col justify-start space-y-2">
            <div className="relative flex w-[260px] flex-col items-start justify-center space-y-2">
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-blue">check</span>
                    <div className="whitespace-nowrap text-xs text-black-secondary">Full Access</div>
                </div>
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-blue">check</span>
                    <div className="whitespace-nowrap text-xs text-black-secondary">Can request POS Terminal</div>
                </div>
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-blue">check</span>
                    <p className="whitespace-nowrap text-xs text-black-secondary">Can view and manage your POS Terminal devices</p>
                </div>
            </div>
        </div>
    );
}

export default ManagerDescription;
