import { DeclinedTransaction, SelectedTransaction } from "../../../../hooks/state/pending-approval/usePendingPayments.types";

import CancelPayment from "..";
import React from "react";
import Transaction from "../../../../../../../models/transaction";

interface Props {
    transaction: Transaction;
    selectedPayment: SelectedTransaction | undefined;
    handleSelectOrChangeCancelReason: (_data: DeclinedTransaction) => void;
    handleRemoveCancelOrChangeToCancel: (_data: Transaction) => void;
}

function BulkPaymentCancelPayment(props: Props): JSX.Element {
    return (
        <>
            <CancelPayment
                isCancelled={!!props.selectedPayment?.isCancelled}
                transaction={props.transaction}
                cancelledReason={props.selectedPayment?.cancelledReason}
                handleSelectOrChangeCancelReason={props.handleSelectOrChangeCancelReason}
                handleRemoveCancelOrChangeToCancel={props.handleRemoveCancelOrChangeToCancel}
            />
        </>
    );
}

export default BulkPaymentCancelPayment;
