import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../../assets/svg/lencoLogo.svg";
import React from "react";
import { useAppSelector } from "../../../../../redux/hooks";

interface Props {
    url: string;
    children: React.ReactNode;
}

function ExternalFormLayout(props: Props): JSX.Element {
    const externalApplicationInit = useAppSelector((state) => state.externalApplication.init);

    return (
        <div className="relative flex h-screen max-h-screen min-h-screen w-screen flex-col bg-white" tabIndex={-1}>
            <header
                className="flex h-16 min-h-16 w-full flex-row items-center justify-start border-b-0.2 border-grey-secondary px-4 py-1 2xs:px-10"
                tabIndex={-1}
            >
                <Link to={`${props.url}`} tabIndex={-1}>
                    <div className="flex w-28 items-center justify-center">
                        <Logo />
                    </div>
                </Link>
            </header>
            <main className="relative flex w-full flex-grow flex-col items-center justify-start space-y-10 bg-grey-backdrop">
                {externalApplicationInit && (
                    <div className="mx-auto w-full max-w-7xl px-4 2xs:px-10">
                        <div className="w-full border-b border-grey py-4">
                            <h1 className="text-left text-3xl font-medium text-black">{externalApplicationInit?.businessName || ""}</h1>
                        </div>
                    </div>
                )}

                <div className="relative flex w-full max-w-7xl flex-shrink flex-grow basis-auto flex-col items-center justify-start overflow-y-auto bg-grey-backdrop px-4 -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available 2xs:px-10">
                    <div className="relative mx-4 h-full w-full 2xs:mx-10">
                        <div className="absolute left-0 top-0 h-full w-full">{props.children}</div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default ExternalFormLayout;
