import AvatarDetails from "../../avatar-details";
import { DataColumn } from "../Type/DataRow";
import React from "react";
import { SubTextType } from "../Type/SubTextType";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatTime } from "../../../utils/formatTime";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function LogoSubTextFromText({ dataColumn, dataType }: Props) {
    return (
        <>
            {dataColumn.textType === TextType.ERROR ? (
                <p className="text-error">{typeof dataColumn.text === "string" ? dataColumn.text : ""}</p>
            ) : (
                <AvatarDetails
                    dataType={dataType}
                    title={
                        dataColumn.textType === TextType.DATE
                            ? formatDate(dataColumn.text as Date)
                            : dataColumn.textType === TextType.TIME
                              ? formatTime(dataColumn.text as Date)
                              : dataColumn.textType === TextType.DATE_TIME
                                ? formatDateAndTime(dataColumn.text as Date)
                                : typeof dataColumn.text === "string"
                                  ? dataColumn.text
                                  : ""
                    }
                    subtitle={
                        dataColumn.subTextType === SubTextType.DATE
                            ? formatDate(dataColumn.subText as Date)
                            : dataColumn.subTextType === SubTextType.TIME
                              ? formatTime(dataColumn.subText as Date)
                              : dataColumn.subTextType === SubTextType.DATE_TIME
                                ? formatDateAndTime(dataColumn.subText as Date)
                                : typeof dataColumn.subText === "string"
                                  ? dataColumn.subText
                                  : ""
                    }
                    size="xs"
                />
            )}
        </>
    );
}
