import { KeyboardEvent, useEffect, useState } from "react";

import GoogleIcon from "../../google-icon";
import Input from "../../inputs/Input";
import LencoSpinner from "../../spinner";
import NewDropdownItem from "../../new-dropdown-item";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import titleCase from "../../../hooks/titleCase";
import useClickOutside from "../../../hooks/useClickOutside";

// import { useDispatch } from "react-redux";
// import { setIsAdjustTransactionTrayMargin } from "../../../redux/transactions/slice/transactionsSlice";

interface SendableDropdownProps<T> {
    data: T[];
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    value: string;
    label?: string;
    noLabel?: boolean;
    isActive?: boolean;
    isLoading: boolean;
    inputValue?: string;
    isDisabled?: boolean;
    placeholder?: string;
    clickAndClose: boolean;
    isDropdownPositionUp?: boolean;

    cancelFunc: () => void;
    createFunc: (value: string) => void;
    onClickFunc: (item: T) => void;
    changeValue: (newValue: string) => void;
}

function SendableDropdown<T extends { name: string }>({
    size = "xl",
    data: options,
    label,
    value,
    noLabel,
    isActive,
    isLoading,
    isDisabled = false,
    clickAndClose,
    isDropdownPositionUp,
    createFunc,
    onClickFunc,
    changeValue,
}: SendableDropdownProps<T>): JSX.Element {
    const [y, setY] = useState<number | null>(null);
    const [active, setActive] = useState<boolean>(false);
    const [hasValue, setHasValue] = useState<boolean>(false);
    const [positionTop, setPositionTop] = useState<boolean>(false);
    const [hasExactOption, setHasExactOption] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<T[]>([]);

    const innerHeight = window.innerHeight;

    const domNode = useClickOutside(() => {
        setActive(false);
    });

    useEffect(() => {
        const onScroll = () => {
            if (domNode.current) {
                setY(domNode.current.getBoundingClientRect()?.top);
            }
        };

        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    });

    useEffect(() => {
        if (isActive) {
            setActive(true);
        }
    }, [isActive]);

    useEffect(() => {
        if (isDropdownPositionUp) {
            setPositionTop(true);
            return;
        } else {
            if (y) {
                const shouldSetPositionTop = y > innerHeight / 2;
                setPositionTop(shouldSetPositionTop);
            }
        }
    }, [innerHeight, y, isDropdownPositionUp]);

    useEffect(() => {
        setHasValue(!!value && value.length > 0);
    }, [value]);

    useEffect(() => {
        const filtered = (options || []).filter((item: T) => {
            if (!item || !item.name) {
                return false;
            }
            if (!value || value.trim().length === 0) {
                return true;
            }
            return item.name.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredOptions(filtered);

        const exactMatch = (options || []).find((item) => item.name.toLowerCase() === value.toLowerCase());
        setHasExactOption(!isNullOrUndefined(exactMatch));
    }, [options, value]);

    const handleKeypress = (event: KeyboardEvent<HTMLDivElement>) => {
        //it triggers by pressing the enter key
        if (event.key === "Enter") {
            !hasValue && setActive(true);
        }
    };

    return (
        <div className={`relative flex h-full w-full flex-col items-center justify-start`} ref={domNode}>
            <div className="relative w-full overflow-hidden rounded-lg" onClick={() => setActive(true)} onKeyDown={handleKeypress}>
                <Input
                    label={noLabel ? "" : label}
                    value={titleCase(value || "")}
                    onChange={(_value) => changeValue(_value)}
                    inputSize={size}
                    isDisabled={isDisabled}
                    // iconRight={isLoading ? <LencoSpinner size="sm" /> : undefined}
                />
                <div className="absolute right-0 top-0 flex h-full w-max items-center justify-center px-4">
                    {isLoading ? (
                        <LencoSpinner size="sm" />
                    ) : (
                        <GoogleIcon
                            icon="keyboard_arrow_up"
                            size="sm"
                            className={"!-mr-1 transform transition-all " + `${active ? "rotate-0" : "rotate-180"} `}
                        />
                    )}
                </div>
            </div>
            <div
                id="dropdownDivId"
                className={
                    `absolute my-2 w-full overflow-y-auto rounded-lg bg-white shadow-dropdown duration-300 ease-in-out ` +
                    `[&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary` +
                    ` ${positionTop ? " bottom-full origin-bottom" : " top-full origin-top"} ` +
                    // ` top-full origin-top` +
                    // ` bottom-full origin-bottom` +
                    `${active ? " z-40 max-h-76 opacity-100" : " pointer-events-none max-h-0 opacity-0"}`
                }
                onClick={() => {
                    if (clickAndClose) return setActive((prev) => !prev);
                }}
            >
                {filteredOptions.map((item, index) => (
                    <NewDropdownItem size="md" onClick={() => onClickFunc(item)} key={index}>
                        <span>{item.name}</span>
                    </NewDropdownItem>
                ))}

                {!hasExactOption && value.trim().length > 0 && (
                    <NewDropdownItem size="md" color="blue" onClick={() => createFunc(value)}>
                        <div className="flex w-full items-center justify-start">
                            <span className="text-blue">Create</span>
                            <span className="ml-1 text-blue">&quot;{value}&quot;</span>
                        </div>
                    </NewDropdownItem>
                )}
            </div>
        </div>
    );
}

export default SendableDropdown;
