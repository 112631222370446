import { useEffect, useState } from "react";

import ApplicationDeleteModal from "../../../../../../../../modals/deleteModal";
import DropdownHead from "../../../../../../../../../../../../components/dropdown/dropdown-components/dropdown-head";
import DropdownLink from "../../../../../../../../../../../../components/dropdown/dropdown-components/dropdown-link";
import OtherDirectorMin from "../../../../../../../../../../../../models/account-opening/otherDirectorMin";
import useRemoveOtherDirector from "../../../../../../../../../Hooks/nigeria-application/registered-business/account-signatories/useRemoveOtherDirector";

interface OtherDirectorsLineDropDownProps {
    data: OtherDirectorMin;
    editFunc: () => void;
}
function OtherDirectorsLineDropDown(props: OtherDirectorsLineDropDownProps): JSX.Element {
    const { removeOtherDirectorError, isRemoveOtherDirectorLoading, handleReset, handleRemoveOtherDirector } = useRemoveOtherDirector({
        onComplete: () => setIsRemoveSignatoryModalOpen(false),
    });

    const [isRemoveSignatoryModalOpen, setIsRemoveSignatoryModalOpen] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            handleReset();
        };
    }, []);

    return (
        <>
            <ApplicationDeleteModal
                error={removeOtherDirectorError}
                active={isRemoveSignatoryModalOpen}
                header="Remove Other Director"
                toggler={() => setIsRemoveSignatoryModalOpen(false)}
                onSubmit={() => void handleRemoveOtherDirector({ id: props.data.id, otherDirectorId: props.data.id })}
                isSubmitLoading={!!isRemoveOtherDirectorLoading}
                deleteButtonText="Yes"
                body={
                    <>
                        <span className="w-full whitespace-normal break-words text-left text-base font-normal">
                            Remove {props.data.name} as director ?
                        </span>
                    </>
                }
            />
            <div>
                <DropdownHead
                    placeholder={
                        <div className="flex max-w-[24px] items-center justify-center text-2xl">
                            <span className="material-symbols-rounded">more_horiz</span>
                        </div>
                    }
                    placement="right"
                    clickAndClose
                    icon
                >
                    <DropdownLink onClick={props.editFunc} isLink>
                        <span className="text-sm">Edit Director Details</span>
                    </DropdownLink>
                    <DropdownLink onClick={() => setIsRemoveSignatoryModalOpen(true)} redHover>
                        <span className="text-sm">Remove Director</span>
                    </DropdownLink>
                </DropdownHead>
            </div>
        </>
    );
}

export default OtherDirectorsLineDropDown;
