import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

import Transaction from "../../../../../../../models/transaction";
import { addTransactionComment } from "../Services/transactionDetailApi";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setSingleTransaction } from "../../../../../../../redux/transaction/slice/transactionSlice";
import { useDispatch } from "react-redux";

export interface UseTransactionDetailsCommentsResponse {
    isSubmitting: boolean;
    newCommentText: string;

    setNewCommentText: Dispatch<SetStateAction<string>>;
    handleAddTransactionComment: (transaction: Transaction, commentText: string) => Promise<void>;
}

interface Props {
    onComplete: () => void;
}

function useTransactionDetailsCommentForm(props?: Props): UseTransactionDetailsCommentsResponse {
    const dispatch = useDispatch();

    const [isError, setIsError] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [newCommentText, setNewCommentText] = useState<string>("");

    useEffect(() => {
        if (!isError) return;
        setNewCommentText("");
    }, [isError]);

    const handleAddTransactionComment = useCallback(
        async (transaction: Transaction, commentText: string): Promise<void> => {
            try {
                setIsError(false);
                setIsSubmitting(true);
                const updatedTransaction = await addTransactionComment(transaction.id, commentText);
                dispatch(messageTrue({ message: "Comment Added Successfully" }));
                dispatch(setSingleTransaction(updatedTransaction));
                setNewCommentText("");
                props && props.onComplete();
            } catch (err) {
                setIsError(true);
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    return {
        isSubmitting,
        newCommentText,
        setNewCommentText,
        handleAddTransactionComment,
    };
}

export default useTransactionDetailsCommentForm;
