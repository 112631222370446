import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import BillProduct from "../../../../models/billProduct";
import { BillPurchaseInitResponse } from "../../../../modules/dashboard/payments-new/services/billPayment/billPaymentApi.types";
import BillVendor from "../../../../models/billVendor";
import BillVendorMin from "../../../../models/billVendorMin";
import { ElectricityPurchaseState } from "./electricityPurchaseSlice.types";
import UserAccount from "../../../../models/userAccount";

const initialState: ElectricityPurchaseState = {
    electricityPurchaseDetails: {
        debitAccountId: "",
        productId: "",
        accountId: "",
        phone: "",
        amount: "",
        otp: "",
        bioAuth: "",
        contactName: "",
    },
    meterNumber: "",
    selectedVendorId: null,
    selectedVendorName: null,
    selectedProductName: null,
    selectedUserAccount: null,
    selectedProductRange: null,
    electricityPurchaseResponse: null,
    electricityVerifiedAccountName: null,
};

/* Since the accountId and phone number require the same data no need for a set phone number reducer */

export const electricityPurchaseSlice = createSlice({
    name: "electricityPurchase",
    initialState,
    reducers: {
        setElectricityPurchaseDebitAccountId: (state: ElectricityPurchaseState, action: PayloadAction<string>) => {
            state.electricityPurchaseDetails.debitAccountId = action.payload;
        },
        setElectricityPurchaseSelectedUserAccount: (state: ElectricityPurchaseState, action: PayloadAction<UserAccount>) => {
            state.selectedUserAccount = action.payload;
        },
        setElectricityPurchaseAccountId: (state: ElectricityPurchaseState, action: PayloadAction<string>) => {
            state.electricityPurchaseDetails.accountId = action.payload;
            state.electricityPurchaseDetails.phone = action.payload;
        },
        setElectricityVerifiedAccountName: (state: ElectricityPurchaseState, action: PayloadAction<string>) => {
            state.electricityVerifiedAccountName = action.payload;
        },
        setElectricityPurchaseMeterNumber: (state: ElectricityPurchaseState, action: PayloadAction<string>) => {
            state.meterNumber = action.payload;
        },
        setElectricityPurchaseVendorId: (state: ElectricityPurchaseState, action: PayloadAction<BillVendor | BillVendorMin>) => {
            state.selectedVendorName = action.payload.name;
            state.selectedVendorId = action.payload.id;
        },
        setElectricityPurchaseProductId: (state: ElectricityPurchaseState, action: PayloadAction<BillProduct>) => {
            state.electricityPurchaseDetails.productId = action.payload.id;
            state.selectedProductName = action.payload.name;
            state.selectedProductRange = action.payload.rangeAmount;
        },
        setElectricityPurchaseAmount: (state: ElectricityPurchaseState, action: PayloadAction<string>) => {
            state.electricityPurchaseDetails.amount = action.payload;
        },
        setElectricityPurchaseOtp: (state: ElectricityPurchaseState, action: PayloadAction<string>) => {
            state.electricityPurchaseDetails.otp = action.payload;
        },
        setElectricityPurchaseResponse: (state: ElectricityPurchaseState, action: PayloadAction<BillPurchaseInitResponse>) => {
            state.electricityPurchaseResponse = action.payload;
        },
        resetElectricityVerifiedAccountName: (state: ElectricityPurchaseState) => {
            state.electricityVerifiedAccountName = null;
        },
        resetAllElectricityPurchaseData: (state: ElectricityPurchaseState) => {
            state.meterNumber = "";
            state.selectedVendorId = null;
            state.selectedVendorName = null;
            state.selectedProductName = null;
            state.selectedUserAccount = null;
            state.selectedProductRange = null;
            state.electricityPurchaseResponse = null;
            state.electricityVerifiedAccountName = null;
            state.electricityPurchaseDetails = {
                debitAccountId: "",
                productId: "",
                accountId: "",
                phone: "",
                amount: "",
                otp: "",
                bioAuth: "",
                contactName: "",
            };
        },
    },
});

export const {
    setElectricityPurchaseOtp,
    setElectricityPurchaseAmount,
    setElectricityPurchaseVendorId,
    setElectricityPurchaseResponse,
    resetAllElectricityPurchaseData,
    setElectricityPurchaseAccountId,
    setElectricityPurchaseProductId,
    setElectricityVerifiedAccountName,
    setElectricityPurchaseMeterNumber,
    resetElectricityVerifiedAccountName,
    setElectricityPurchaseDebitAccountId,
    setElectricityPurchaseSelectedUserAccount,
} = electricityPurchaseSlice.actions;

export default electricityPurchaseSlice.reducer;
