import { useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/svg/chevron-down.svg";

interface Data {
    header: string;
    body: string;
}

interface Props {
    items: Data[];
}

function Accordion({ items }: Props): JSX.Element {
    const [openAccordion, setOpenAccordion] = useState(0);

    return (
        <div className="w-full py-6">
            {items?.map((item, idx) => (
                <div key={idx} className={`${"border-b border-grey-tertiary"}`}>
                    <div className="flex items-center justify-between py-3" onClick={() => setOpenAccordion(idx)}>
                        <h3 className="text-base font-medium text-black">{item.header}</h3>
                        <ChevronDown className={`${idx === openAccordion && "rotate-180"}`} />
                    </div>
                    <p
                        className={"w-full overflow-hidden text-sm font-normal text-black-secondary transition-all"}
                        style={{ height: openAccordion === idx ? "max-content" : "0px" }}
                    >
                        {item.body}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default Accordion;
