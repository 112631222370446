import React, { useEffect, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../redux/rootReducer";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import Otp from "../../../../../../components/otp";
import { useSelector } from "react-redux";

interface Props {
    otpFunc: (otp: string) => void;
    resendFunc: () => void;
    error: string | null;
    value: string;
    isLoading: boolean;
    counterMinutes: number;
    counterSeconds: number;
    active: boolean;
    toggler: () => void;
    handleSubmit: () => void;
}

function ChangePasswordOtp({
    otpFunc,
    resendFunc,
    error,
    value,
    isLoading,
    counterMinutes,
    counterSeconds,
    active,
    toggler,
    handleSubmit,
}: Props): JSX.Element {
    const [minutes, setMinutes] = useState<number>(counterMinutes);
    const [seconds, setSeconds] = useState<number>(counterSeconds);

    useEffect(() => {
        setMinutes(counterMinutes);
    }, [counterMinutes]);

    useEffect(() => {
        setSeconds(counterSeconds);
    }, [counterSeconds]);

    useEffect(() => {
        const optTimerInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(optTimerInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(optTimerInterval);
        };
    });

    const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

    return (
        <>
            <Modal size="xs" active={active} toggler={toggler}>
                <ModalHeader onClose={toggler}>Change password</ModalHeader>
                <ModalBody>
                    <div className="relative flex w-full flex-col text-black-secondary">
                        <div className="flex w-full flex-col items-start justify-start space-y-2 text-left">
                            <span className="text-sm font-normal">
                                We sent a one-time password to your {isAuthAppSetup ? "Token App" : "email"} to change your password
                            </span>
                        </div>

                        <div className="flex w-full flex-col items-start justify-start pt-4">
                            <Otp value={value || undefined} onChange={otpFunc} isError={error} />
                            <div className="flex items-center justify-center pt-4">
                                <div>
                                    {minutes === 0 && seconds === 0 ? (
                                        <div
                                            onClick={() => {
                                                setMinutes(2);
                                                setSeconds(59);
                                                resendFunc();
                                            }}
                                        >
                                            <span className="cursor-pointer text-sm text-blue">Resend OTP</span>
                                        </div>
                                    ) : (
                                        <span className="cursor-not-allowed text-sm text-black-tertiary">
                                            Resend OTP in{" "}
                                            <span className="text-black-secondary">
                                                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp
                        type="button"
                        ripple="light"
                        buttonType="secondary"
                        color="grey"
                        func={() => {
                            toggler();
                        }}
                    >
                        <span>Back</span>
                    </ButtonComp>

                    <ButtonComp type="submit" color="blue" ripple="light" buttonType="primary" isLoading={isLoading} onClick={handleSubmit}>
                        <span>Authorize</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ChangePasswordOtp;
