import { setTransferBetweenAmount } from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface useTransferBetweenPurposeInterface {
    handleAmountChange: (_amount: string) => void;
}

function useTransferBetweenAmount(): useTransferBetweenPurposeInterface {
    const dispatch = useDispatch();

    const handleAmountChange = useCallback((_amount: string) => {
        if (!_amount || Number(_amount) === 0) return;
        dispatch(setTransferBetweenAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useTransferBetweenAmount;
