import { DropdownItem } from "../../../../../helpers/types";
import {
    AllNotificationTypes,
    AllUserRoles,
    NotificationChannel,
    NotificationChannelDescription,
    NotificationChannelText,
    NotificationType,
    NotificationTypeDescription,
    NotificationTypeText,
    SelectableNotificationChannels,
    UserRole,
    UserRoleDescription,
    UserRoleText,
} from "../../../../../models/userAccount.constants";

export enum MobileTeamMemberListPageState {
    LIST = 0,
    DETAILS = 1,
}

export const TeamMemberListMobileBreakpoint = 1278;

export const RoleOptions: Array<DropdownItem<UserRole>> = AllUserRoles.map((roleItem) => ({
    value: roleItem,
    text: UserRoleText[roleItem],
    subtext: UserRoleDescription[roleItem],
}));

export const NotificationTypeOptions: Array<DropdownItem<NotificationType>> = AllNotificationTypes.map((type) => ({
    value: type,
    text: NotificationTypeText[type],
    subtext: NotificationTypeDescription[type],
}));

export const NotificationChannelOptions: Array<DropdownItem<NotificationChannel>> = SelectableNotificationChannels.map((channel) => ({
    value: channel,
    text: NotificationChannelText[channel],
    subtext: NotificationChannelDescription[channel],
}));

// export const teamMemberTabs = [
//     {
//         name: "Accounts",
//     },
//     {
//         name: "Feature",
//     },
// ];

export const accessLevelText = [
    {
        value: 0,
        text: "Manager",
    },
    {
        value: 1,
        text: "Viewer Only",
    },
    {
        value: 2,
        text: "No Access Only",
    },
];
