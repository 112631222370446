import React, { CSSProperties, KeyboardEvent, useEffect, useState } from "react";

import { ButtonColor } from "../button/button.constant";
import ButtonComp from "../button/ButtonComp";
import { ReactComponent as EmptyXX } from "../../assets/images/empty/empty-xx.svg";
import GoogleIcon from "../google-icon";
import { FixedSizeList as List } from "react-window";
import SearchBar from "../search-bar";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import useClickOutside from "../../hooks/useClickOutside";

interface Props {
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    color?: ButtonColor;
    ripple?: "light" | "dark";
    content: React.ReactNode;
    children?: ((_index: number) => React.ReactNode) | React.ReactDOM | Element[] | React.ReactElement | React.ReactElement[];
    dataType?: string | null;
    isActive?: boolean;
    fullWidth?: boolean;
    isNoArrow?: boolean;
    buttonType?: "primary" | "secondary" | "tertiary" | "flat";
    fullBorder?: boolean;
    isDisabled?: boolean;
    searchTerm?: string;
    paddingSize?: "2xs" | "xs" | "sm" | "md" | "lg";
    borderSmall?: boolean;
    dropdownSize?: "sm" | "md" | "lg" | "xl" | "2xl";
    isSearchable?: boolean;
    optionsLength?: number;
    clickAndClose?: boolean;
    dropdownWidth?: "full" | "max";
    isTriggerLower?: boolean;
    dropdownPosition?: "left" | "right";
    searchPlaceholder?: string;
    isFreeFormDropdown?: boolean;

    handleOpen?: () => void;
    handleClose?: () => void;
    handleAdditionalCheck?: (e: HTMLElement) => boolean;
    handleChangeSearchTerm?: (e: string) => void;
    // dropdownItem?: (_index: number, _style: React.CSSProperties | undefined) => React.ReactNode;
}

function NewDropdownHead({
    size,
    color = "black",
    ripple,
    content,
    isActive = false,
    children,
    dataType,
    fullWidth,
    isNoArrow = false,
    buttonType,
    searchTerm = "",
    fullBorder,
    isDisabled = false,
    borderSmall,
    paddingSize,
    isSearchable = false,
    dropdownSize,
    clickAndClose,
    isTriggerLower = false,
    optionsLength,
    dropdownWidth = "full",
    dropdownPosition = "left",
    searchPlaceholder = "",
    isFreeFormDropdown = false,

    // dropdownItem = undefined,
    handleOpen,
    handleClose,
    handleAdditionalCheck = undefined,
    handleChangeSearchTerm = undefined,
}: Props): JSX.Element {
    const [y, setY] = useState<number | null>(null);
    const [active, setActive] = useState(false);
    const [positionTop, setPositionTop] = useState(false);

    const innerHeight = window.innerHeight;

    const searchDropdownHeight =
        dropdownSize === "sm"
            ? 32
            : dropdownSize === "md"
              ? 40
              : dropdownSize === "lg"
                ? 48
                : dropdownSize === "xl"
                  ? 56
                  : dropdownSize === "2xl"
                    ? 64
                    : 56;

    const domNode = useClickOutside(() => {
        setActive(false);
        handleChangeSearchTerm && handleChangeSearchTerm("");
    }, handleAdditionalCheck && handleAdditionalCheck);

    useEffect(() => {
        if (domNode.current) {
            setY(domNode.current.getBoundingClientRect().top);
        }
    });

    useEffect(() => {
        setActive(isActive);
    }, [isActive]);

    useEffect(() => {
        if (!active) return;
        handleOpen && handleOpen();
    }, [active]);

    useEffect(() => {
        if (active) return;
        handleClose && handleClose();
    }, [active]);

    useEffect(() => {
        if (y) {
            let shouldSetPositionTop;
            if (isTriggerLower) {
                shouldSetPositionTop = y > innerHeight / 1.25;
            } else {
                shouldSetPositionTop = y > innerHeight / 1.65;
            }
            setPositionTop(shouldSetPositionTop);
        }
    }, [innerHeight, y, isTriggerLower]);

    const handleKeypress = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === "Enter") {
            !isDisabled && setActive((prev) => !prev);
        }
    };

    return (
        <div className={"relative" + ` ${fullWidth ? "w-full" : "w-max"}`} ref={domNode}>
            <ButtonComp
                size={size}
                color={color}
                ripple={ripple}
                disable={isDisabled}
                dataType={dataType}
                fullWidth={fullWidth}
                buttonType={buttonType}
                fullBorder={fullBorder}
                borderSmall={borderSmall}
                paddingSize={paddingSize}
                onKeyDown={handleKeypress}
                onClick={(e) => {
                    e.preventDefault();
                    !isDisabled && setActive((prev) => !prev);
                }}
            >
                <div className="flex w-full items-center justify-between space-x-1" data-type={dataType}>
                    {content}
                    {!isNoArrow && !isDisabled && (
                        <div className={`${active ? "rotate-0" : "rotate-180"} ` + "w-max text-black duration-150"} data-type={dataType}>
                            <GoogleIcon icon="keyboard_arrow_up" dataType={dataType || ""} />
                        </div>
                    )}
                </div>
            </ButtonComp>

            <div
                className={
                    `absolute right-0 my-2 rounded-lg bg-white shadow-dropdown duration-300 ease-in-out ` +
                    ` ${dropdownWidth === "full" ? "w-full" : ""} ` +
                    ` ${dropdownWidth === "max" ? "w-max" : ""} ` +
                    ` ${!isFreeFormDropdown ? "overflow-y-auto" : ""} ` +
                    ` ${dropdownPosition === "left" ? "left-0" : ""} ` +
                    ` ${dropdownPosition === "right" ? "right-0" : ""} ` +
                    ` ${positionTop ? "bottom-full origin-bottom" : "top-full origin-top"}` +
                    ` ${active ? "z-40 max-h-76 opacity-100" : "pointer-events-none max-h-0 opacity-0"}`
                }
                data-type={dataType}
            >
                {isSearchable && handleChangeSearchTerm && (
                    <div
                        className={
                            "flex w-full items-center justify-start border-b border-grey-secondary px-4 " +
                            ` ${size === "2xl" ? "h-16" : ""} ` +
                            ` ${size === "xl" ? "h-14" : ""} ` +
                            ` ${size === "lg" ? "h-12" : ""} ` +
                            ` ${size === "md" ? "h-10" : ""} ` +
                            ` ${size === "sm" ? "h-8" : ""} `
                        }
                        data-type={dataType}
                    >
                        <SearchBar
                            placeholder={searchPlaceholder}
                            value={searchTerm}
                            onChange={handleChangeSearchTerm}
                            dataType={dataType || ""}
                            size={dropdownSize}
                        />
                    </div>
                )}
                {isSearchable && !isNullOrUndefined(optionsLength) && optionsLength > 0 && typeof children === "function" && (
                    <div className="w-full" onClick={() => clickAndClose && setActive((prev) => !prev)} data-type={dataType}>
                        <List
                            width="100%"
                            height={optionsLength * searchDropdownHeight > 168 ? 168 : optionsLength * searchDropdownHeight}
                            itemSize={searchDropdownHeight}
                            itemCount={optionsLength}
                            className="w-full [&>div>div:not(:first-child)]:border-t [&>div>div:not(:first-child)]:border-grey-secondary"
                            data-type={dataType}
                        >
                            {({ index, style }: { index: number; style: CSSProperties | undefined }) => {
                                return (
                                    <div className="w-full" style={style} key={index} data-type={dataType}>
                                        {children && children(index)}
                                    </div>
                                );
                            }}
                        </List>
                    </div>
                )}
                {isSearchable && !isNullOrUndefined(optionsLength) && optionsLength < 1 && (
                    <div className="flex w-full flex-col items-center justify-center space-y-2 py-4" data-type={dataType}>
                        <EmptyXX className="h-10 w-10" data-type={dataType} />
                        <span className="text-sm text-black-tertiary" data-type={dataType}>
                            Nothing Found
                        </span>
                    </div>
                )}

                {!isSearchable && (
                    <div
                        className="w-full [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary"
                        onClick={() => clickAndClose && setActive((prev) => !prev)}
                        data-type={dataType}
                    >
                        {children as React.ReactNode}
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewDropdownHead;
