// import { ReactComponent as Error } from "../../../assets/svg/general/Toast/Error/Icon.svg";
// import { ReactComponent as Info } from "../../../assets/svg/general/Toast/Info/Icon.svg";
// import { ReactComponent as OutlineInfo } from "../../../assets/svg/general/Toast/Info/outline-icon.svg";
import React from "react";
// import { ReactComponent as Success } from "../../../assets/svg/general/Toast/Success/Icon.svg";
import { ToastType } from "../../../helpers/app-constants";
// import { ReactComponent as Warning } from "../../../assets/svg/general/Toast/Warning/Icon.svg";

interface MessageToastsProps {
    outlineIcon?: boolean;
    toastType: ToastType;
    className?: string;
    toastMessage: string | React.ReactNode;
    onClick?: () => void;
}

function MessageToasts({ toastMessage, toastType, outlineIcon = false, className, onClick = undefined }: MessageToastsProps): JSX.Element {
    return (
        <div
            className={
                `flex w-full transform flex-row items-start justify-start space-x-4 rounded-lg px-4 py-2 text-center opacity-100 transition-all duration-700 ` +
                `${toastType === ToastType.ERROR ? "bg-error-backdrop text-error" : ""} ` +
                `${toastType === ToastType.INFORMATION ? "bg-info-backdrop text-info" : ""} ` +
                `${toastType === ToastType.WARNING ? "bg-warning-backdrop text-warning" : ""} ` +
                `${toastType === ToastType.SUCCESS_TOAST ? "bg-success-backdrop text-success" : ""} ` +
                `${className || ""} `
            }
        >
            <div className="flex items-center justify-center rounded-full" onClick={onClick}>
                <span>
                    {outlineIcon ? (
                        <>
                            {/* {toastType === ToastType.ERROR && <Error />}
                            {toastType === ToastType.INFORMATION && <OutlineInfo className="mt-0.25" />}
                            {toastType === ToastType.WARNING && <Warning />}
                            {toastType === ToastType.SUCCESS_TOAST && <Success />} */}
                        </>
                    ) : (
                        <>
                            {/* {toastType === ToastType.ERROR && <Error />}
                            {toastType === ToastType.INFORMATION && <Info />}
                            {toastType === ToastType.WARNING && <Warning />}
                            {toastType === ToastType.SUCCESS_TOAST && <Success />} */}
                        </>
                    )}
                </span>
            </div>
            <div className="pointer-events-none flex items-center justify-center whitespace-normal text-left text-sm font-normal">{toastMessage}</div>{" "}
        </div>
    );
}

export default MessageToasts;
