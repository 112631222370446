import { AccountOpeningStatus, BusinessRegistrationCategory } from "./accountOpening.constants";

import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import ZambiaApplicationIndustry from "./zambiaApplicationIndustry";
import { immerable } from "immer";

export default class ZambiaBusinessDetails {
    [immerable] = true;

    constructor(
        public id: string,
        public companyId: string,
        public createdByUserId: string,
        public name: string | null,
        public isRegistered: boolean | null,
        public industry: ZambiaApplicationIndustry | null,
        public registrationCategory: BusinessRegistrationCategory | null,
        public status: AccountOpeningStatus,
        public businessNature: string | null,
        public website: string | null,
        public email: string | null,
        public phone: string | null,
        public operatingAddress: string | null
    ) {}

    static create(obj: GenericObject): ZambiaBusinessDetails {
        return new ZambiaBusinessDetails(
            Parsers.string(obj.id),
            Parsers.string(obj.companyId),
            Parsers.string(obj.createdByUserId),
            obj.name === null ? null : Parsers.string(obj.name),
            Parsers.nullableBoolean(obj.isRegistered),
            Parsers.classObject(obj.industry, ZambiaApplicationIndustry),
            Parsers.nullableNumber(obj.registrationCategory),
            Parsers.number(obj.status),
            Parsers.nullableString(obj.businessNature),
            Parsers.nullableString(obj.website),
            Parsers.nullableString(obj.email),
            Parsers.nullableString(obj.phone),
            Parsers.nullableString(obj.operatingAddress)
        );
    }

    get isLLC(): boolean {
        return this.registrationCategory === BusinessRegistrationCategory.LLC;
    }
    get isNGO(): boolean {
        return this.registrationCategory === BusinessRegistrationCategory.NGO;
    }
    get isBusinessName(): boolean {
        return this.registrationCategory === BusinessRegistrationCategory.BUSINESS_NAME;
    }
}
