import { useCallback, useState } from "react";

import { PhotoRequest } from "../../../../../services/zambia-application/registered/new/currentSignatory.types";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { photoUpload } from "../../../../../services/zambia-application/registered/new/currentSignatory.api";
import { updateZambiaCurrentSignatory } from "../../../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface usePhotoUploadComponentInterface {
    isUploadPhotoLoading: boolean | null;
    handleUploadPhoto: (_data: PhotoRequest) => Promise<void>;
}

const usePhotoUploadComponent = (props?: () => void): usePhotoUploadComponentInterface => {
    const dispatch = useDispatch();

    const [isUploadPhotoLoading, setIsUploadPhotoLoading] = useState<boolean | null>(false);

    const handleUploadPhoto = useCallback(async (_data: PhotoRequest) => {
        try {
            setIsUploadPhotoLoading(true);
            const res = await photoUpload(_data);
            if (res.signatory) {
                dispatch(updateZambiaCurrentSignatory(res));
            }
            dispatch(messageTrue({ message: "Photo uploaded successfully" }));
            props && props();
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsUploadPhotoLoading(false);
        }
    }, []);

    return {
        isUploadPhotoLoading,
        handleUploadPhoto,
    };
};
export default usePhotoUploadComponent;
