import Parsers from "../../../../../utils/parsers";
import { makeRequest, makeRequestThrowError } from "../../../../../helpers/request/makeRequest";
import { GenericObject } from "../../../../../helpers/types";
import User from "../../../../../models/user";
import UserAccountMeta from "../../../../../models/userAccountMeta";
import {
    AccountBalanceInstructionsForm,
    AccountRestrictionsForm,
    AccountSettingsItem,
    AccountSettingsResponse,
    AddNewTeamMemberToAccountResponse,
    AddNewTeamMemberToAccountsRequest,
    AddTeamMemberToAccountsRequest,
    RemoveTeamMemberFromAccountResponse,
} from "./account-settings.types";
import AccountRestriction from "../../../../../models/accountRestriction";
import AccountBalanceInstruction from "../../../../../models/accountBalanceInstruction";
import { NotificationChannel, NotificationType, UserRole } from "../../../../../models/userAccount.constants";
import { checkRes } from "../team-members/hooks/useTeamMemberAccessLevelRole";
import UserFeatureMeta2 from "../../../../../models/userFeatureMeta2";

function getAccountSettingsItem(obj: GenericObject): AccountSettingsItem {
    return {
        userAccountId: Parsers.string(obj.userAccountId),
        accountsMeta: Parsers.classObjectArray(obj.accountMembers, UserAccountMeta),
        restrictions: Parsers.classObjectNonNullable(obj.restrictions, AccountRestriction),
        balanceInstructions: Parsers.classObjectArray(obj.balanceInstructions, AccountBalanceInstruction),
    };
}

export async function getAccountSettings(): Promise<AccountSettingsResponse> {
    const res = await makeRequestThrowError("/main/settings/accounts/details");
    if (!res.accounts || !Array.isArray(res.accounts)) {
        throw new Error("An Error Occurred");
    }
    return {
        accounts: res.accounts.map((account: GenericObject) => getAccountSettingsItem(account)),
        teamMembers: Parsers.classObjectArray(res.teamMembers, User),
    };
}

export async function updateAccountSettings(
    userAccountId: string,
    restrictions: AccountRestrictionsForm,
    balanceInstructions: Array<AccountBalanceInstructionsForm>
): Promise<AccountSettingsItem> {
    const res = await makeRequestThrowError("/main/settings/accounts/update", { userAccountId, restrictions, balanceInstructions });
    if (!res.account) {
        throw new Error("An Error Occurred");
    }
    return getAccountSettingsItem(res.account as GenericObject);
}

export async function updateAccountRestrictions(userAccountId: string, restrictions: AccountRestrictionsForm): Promise<AccountSettingsItem> {
    const res = await makeRequestThrowError("/main/settings/accounts/update/restrictions", { userAccountId, restrictions });
    if (!res.account) {
        throw new Error("An Error Occurred");
    }
    return getAccountSettingsItem(res.account as GenericObject);
}

export async function updateAccountBalanceInstructions(
    userAccountId: string,
    instruction: AccountBalanceInstructionsForm
): Promise<AccountSettingsItem> {
    const res = await makeRequestThrowError("/main/settings/accounts/update/balance-instruction", { userAccountId, instruction });
    if (!res.account) {
        throw new Error("An Error Occurred");
    }
    return getAccountSettingsItem(res.account as GenericObject);
}

export async function addTeamMemberToAccounts(request: AddTeamMemberToAccountsRequest): Promise<UserAccountMeta[]> {
    const res = await makeRequestThrowError("/main/settings/accounts/members/add", request);
    if (!res.accountMembers || !Array.isArray(res.accountMembers)) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectArray(res.accountMembers, UserAccountMeta);
}

export async function addNewTeamMemberToAccounts(request: AddNewTeamMemberToAccountsRequest): Promise<AddNewTeamMemberToAccountResponse> {
    const res = await makeRequestThrowError("/main/settings/accounts/members/add/new", request);
    return {
        accountMeta: Parsers.classObjectNonNullable(res.accountMember, UserAccountMeta),
        teamMember: Parsers.classObjectNonNullable(res.teamMember, User),
    };
}

export async function removeTeamMemberFromAccount(memberId: string, userAccountId: string): Promise<RemoveTeamMemberFromAccountResponse> {
    const res = await makeRequestThrowError("/main/settings/accounts/members/remove", { memberId, userAccountId });
    return {
        removedFromTeam: Parsers.boolean(res.removedFromTeam),
    };
}

export async function updateTeamMemberRoleForAccount(memberId: string, userAccountId: string, role: UserRole): Promise<UserAccountMeta> {
    const res = await makeRequestThrowError("/main/settings/accounts/members/update/set-role", { memberId, userAccountId, role });
    if (!res.accountMember) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res.accountMember, UserAccountMeta);
}

export async function updateTeamMemberNotificationTypeForAccount(
    memberId: string,
    userAccountId: string,
    type: NotificationType
): Promise<UserAccountMeta> {
    const res = await makeRequestThrowError("/main/settings/accounts/members/update/notifications/set-type", { memberId, userAccountId, type });
    if (!res.accountMember) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res.accountMember, UserAccountMeta);
}

export async function addTeamMemberNotificationChannelForAccount(
    memberId: string,
    userAccountId: string,
    channel: NotificationChannel
): Promise<UserAccountMeta> {
    const res = await makeRequestThrowError("/main/settings/accounts/members/update/notifications/add-channel", { memberId, userAccountId, channel });
    if (!res.accountMember) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res.accountMember, UserAccountMeta);
}

export async function removeTeamMemberNotificationChannelForAccount(
    memberId: string,
    userAccountId: string,
    channel: NotificationChannel
): Promise<UserAccountMeta> {
    const res = await makeRequestThrowError("/main/settings/accounts/members/update/notifications/remove-channel", {
        memberId,
        userAccountId,
        channel,
    });
    if (!res.accountMember) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res.accountMember, UserAccountMeta);
}

// ******************************************* Feature endpoints   ***************************************************

// POS TERMINAL ************************************
// manager access
export async function removeManageTerminalRole(memberId: string): Promise<UserFeatureMeta2> {
    const res = await makeRequestThrowError("/main/point-of-sale/settings/manage-access/remove", { memberId });

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

export async function addManageTerminalRole(memberId: string): Promise<UserFeatureMeta2> {
    await removeViewTerminalRole(memberId);

    const res = await makeRequestThrowError("/main/point-of-sale/settings/manage-access/add", {
        memberIds: [memberId],
    });
    // await checkRes(res);

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

// viewer access
export async function removeViewTerminalRole(memberId: string): Promise<UserFeatureMeta2> {
    const res = await makeRequestThrowError("/main/point-of-sale/settings/view-access/remove", { memberId });

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

export async function addViewTerminalRole(memberId: string): Promise<UserFeatureMeta2> {
    await removeManageTerminalRole(memberId);

    const res = await makeRequestThrowError("/main/point-of-sale/settings/view-access/add", {
        memberIds: [memberId],
    });
    await checkRes(res);

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

// no access
export async function noAccessTerminalRole(memberId: string) {
    await removeManageTerminalRole(memberId);
    return removeViewTerminalRole(memberId);
}

// CORPORATE CARDS ENDPOINTS ******************************
// manager access
export async function removeManageCardRole(memberId: string): Promise<UserFeatureMeta2> {
    const res = await makeRequestThrowError("/main/cards/settings/manage-card-access/remove", { memberId });

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

export async function addManageCardRole(memberId: string): Promise<UserFeatureMeta2> {
    await removeViewCardRole(memberId);

    const res = await makeRequestThrowError("/main/cards/settings/manage-card-access/add", {
        memberIds: [memberId],
    });

    await makeRequest("/main/settings/team-members");

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

// viewer access
export async function removeViewCardRole(memberId: string): Promise<UserFeatureMeta2> {
    const res = await makeRequestThrowError("/main/cards/settings/view-card-access/remove", { memberId });

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

export async function addViewCardRole(memberId: string): Promise<UserFeatureMeta2> {
    await removeManageCardRole(memberId);

    const res = await makeRequestThrowError("/main/cards/settings/view-card-access/add", {
        memberIds: [memberId],
    });

    await makeRequest("/main/settings/team-members");

    // await checkRes(res);

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

// no access
export async function noAccessCardRole(memberId: string) {
    await removeManageCardRole(memberId);
    return removeViewCardRole(memberId);
}

// export async function allCardsRole(memberId: string): Promise<UserFeatureMeta2> {
export async function allCardsRole(): Promise<UserFeatureMeta2> {
    const res = await makeRequestThrowError("/main/cards/settings/all/details");
    await checkRes(res);

    if (!res) {
        throw new Error("An Error Occurred");
    }
    return Parsers.classObjectNonNullable(res, UserFeatureMeta2);
}

// ****************************************** Feature endpoints ended ***********************************************
