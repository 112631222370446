import { abortAllBusinessAgreementRequests, agreementsInit } from "../../../../services/nigeria-application/registered/new/agreement.api";
import { resetAgreement, setAgreement } from "../../../../../../../redux/application/applicationSlice";
import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

interface UseAgreementInitInterface {
    agreementDetailsError: string;
    isAgreementDetailsLoading: boolean;

    handleReset: () => void;
    handleAgreementInit: () => Promise<void>;
}
const UseAgreementInit = (): UseAgreementInitInterface => {
    const [isAgreementDetailsLoading, setAgreementDetailsLoading] = useState<boolean>(false);
    const [agreementDetailsError, setAgreementDetailsError] = useState<string>("");

    const dispatch = useDispatch();

    const handleAgreementInit = useCallback(async (): Promise<void> => {
        try {
            setAgreementDetailsError("");
            setAgreementDetailsLoading(true);
            const res = await agreementsInit();
            dispatch(setAgreement(res));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue(errorMessage));
            setAgreementDetailsError(errorMessage);
        } finally {
            setAgreementDetailsLoading(false);
        }
    }, []);

    const handleReset = () => {
        setAgreementDetailsLoading(false);
        setAgreementDetailsError("");
        abortAllBusinessAgreementRequests();
        dispatch(resetAgreement());
    };

    return {
        agreementDetailsError,
        isAgreementDetailsLoading,
        handleReset,
        handleAgreementInit,
    };
};
export default UseAgreementInit;
