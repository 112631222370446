import {
    resetTransferDetail,
    setCanVerifyRecipientAccountDetails,
    setIsUpdatingTransferDetail,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";

import { useDispatch } from "react-redux";
import { useState } from "react";

export interface Props {
    isEditDetailTrayActive: boolean;
    handleIsEditDetailTrayActive: (_value: boolean) => void;
}

function useBulkTransferEditDetail(): Props {
    const dispatch = useDispatch();

    const [isEditDetailTrayActive, setIsEditDetailTrayActive] = useState<boolean>(false);

    const handleIsEditDetailTrayActive = (_value: boolean) => {
        if (_value) {
            setIsEditDetailTrayActive(true);
        } else {
            dispatch(resetTransferDetail());
            dispatch(setIsUpdatingTransferDetail(false));
            dispatch(setCanVerifyRecipientAccountDetails(false));
            setIsEditDetailTrayActive(false);
        }
    };

    return {
        isEditDetailTrayActive,
        handleIsEditDetailTrayActive,
    };
}

export default useBulkTransferEditDetail;
