import { resetAllSendMoneyData, setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActivateAccount from "../../../../Accounts/Components/Modals/ActivateAccountModal";
import ActivateAccountList from "../../../../Transactions/Components/Modal/ActivateAccountList";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import CategorizeCard from "../../Cards/categorize-card";
import EntertainmentIcon from "../../../../../../assets/images/dashboard/send-money/entertainment.png";
import ErrorToast from "../../../../../../components/message-toast/sub/error-toast";
import { ReactComponent as FailedPayment } from "../../../../../../assets/svg/dashboard/send-money/failed-payment.svg";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../components/spinner";
import MarketingIcon from "../../../../../../assets/images/dashboard/send-money/marketing.png";
import MessageToast from "../../../../../../components/message-toast";
import MiscellaneousIcon from "../../../../../../assets/images/dashboard/send-money/miscellaneous.png";
import Money from "../../../../../../components/money";
import NewTransactionIcon from "../../../../../../components/new-transaction-icon";
import OperationalIcon from "../../../../../../assets/images/dashboard/send-money/operational.png";
import OthersIcon from "../../../../../../assets/images/dashboard/send-money/others.png";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { ReactComponent as ProcessingPaymentIcon } from "../../../../../../assets/svg/dashboard/send-money/processing-payment.svg";
import { Routes } from "../../../../../../routes/routes.constants";
import SendableDropdown from "../../../../../../components/dropdown/sendable-dropdown";
import { ReactComponent as SuccessfulPayment } from "../../../../../../assets/svg/dashboard/send-money/successful-payment.svg";
import TagTransactionRecipient from "../../../../Transactions/Components/Modal/TagTransactionRecipient";
import Transaction from "../../../../../../models/transaction";
import TransactionCommentModal from "../../../../Transactions/Components/Modal/TransactionCommentModal";
import UserAccount from "../../../../../../models/userAccount";
import UtilitiesIcon from "../../../../../../assets/images/dashboard/send-money/utilities.png";
import { resetAllFundCardData } from "../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import { showTransactionDetails } from "../../../../../../redux/transaction/slice/transactionSlice";
import useCheckStatus from "../../../../../../hooks/use-check-status";
import { useNavigate } from "react-router-dom";
import useTransactionDetailsAccountingReceipt from "../../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsAccountingReceipt";
import useTransactionDetailsCard from "../../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsCard";
import useTransactionDetailsCategory from "../../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsCategory";
import useTransactionDetailsLencoReceipt from "../../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsLencoReceipt";

function ProcessingPayment(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const pageFrom = useSelector((state: IRootState) => state.sendMoney.pageFrom);
    const fundCardResponse = useSelector((state: IRootState) => state.fundCard.fundCardResponse);

    const transaction = useSelector<IRootState, Transaction | undefined>((state) => {
        return state.transaction.transactions.get(fundCardResponse?.transactionId || "");
    });

    const { isDownloading, handleDownloadLencoReceipt } = useTransactionDetailsLencoReceipt();
    const { showTagRecipientModal, setShowTagRecipientModal } = useTransactionDetailsCard();
    const { isUploading: isUploadingReceipt, handleUploadAccountingReceipt } = useTransactionDetailsAccountingReceipt();

    const {
        isSubmitting: isCategorySubmitting,
        transactionCategories: categoriesOptions,
        handleSetTransactionCategory,
        handleSetNewTransactionCategory,
    } = useTransactionDetailsCategory();

    useCheckStatus(transaction as Transaction);

    const [otherCategory, setOtherCategory] = useState<string | null>(null);
    const [isOtherCategory, setIsOtherCategory] = useState(true);
    const [accountToActivate, setAccountToActivate] = useState<UserAccount | null>(null);
    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);
    const [accountsToActivate, setAccountsToActivate] = useState<(UserAccount | undefined)[]>();
    const [showActiveAccountModal, setShowActiveAccountModal] = useState(false);
    const [transactionCommentModal, setTransactionCommentModal] = useState(false);
    const [showActiveAccountListModal, setShowActiveAccountListModal] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!fundCardResponse) return;
        setAccountsToActivate(() => fundCardResponse.accountsToActivate.map((el) => accounts?.find((it) => it.id === el)));
    }, [fundCardResponse]);

    useEffect(() => {
        if (!accountsToActivate || accountsToActivate.length < 1 || showActiveAccountModal) return;
        setShowActiveAccountListModal(true);
    }, [accountsToActivate]);

    const handleActivatedAccount = useCallback((e: UserAccount) => {
        setAccountsToActivate((prev) => prev?.filter((el) => el?.id !== e.id));
        setShowActiveAccountListModal(false);
        setShowActiveAccountModal(false);
    }, []);

    const handleSelectPresetCategories = (_value: string) => {
        if (!transaction) return;
        const categoryId = categoriesOptions.find((_) => _.name.toLocaleLowerCase() === _value.toLocaleLowerCase())?.uid;
        if (categoryId) {
            void handleSetTransactionCategory(transaction, categoryId);
        } else {
            void handleSetNewTransactionCategory(transaction, _value);
        }
        setOtherCategory(_value);
        setIsOtherCategory(false);
    };

    const onSelectFile = useCallback(
        (file: File) => {
            transaction && void handleUploadAccountingReceipt(transaction, file);
        },
        [handleUploadAccountingReceipt, transaction]
    );

    const onTargetClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };

    return (
        <>
            {transaction ? (
                <>
                    <ActivateAccount
                        active={showActiveAccountModal}
                        data={accountToActivate as UserAccount}
                        toggler={() => setShowActiveAccountModal(false)}
                        onCompleteFunc={handleActivatedAccount}
                    />

                    <ActivateAccountList
                        ShowAboutModal={showActiveAccountListModal}
                        onClick={(e: UserAccount) => {
                            setAccountToActivate(e);
                            setShowActiveAccountModal(true);
                            setShowActiveAccountListModal(false);
                        }}
                        accountsToActivate={accountsToActivate}
                        SetShowAboutModal={() => setShowActiveAccountListModal(false)}
                        isSingleAccount
                    />

                    <TagTransactionRecipient
                        showModal={showTagRecipientModal}
                        toggler={() => setShowTagRecipientModal(false)}
                        transaction={transaction}
                    />

                    <TransactionCommentModal
                        active={transactionCommentModal}
                        toggler={() => setTransactionCommentModal((prev) => !prev)}
                        transaction={transaction}
                    />

                    <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-9">
                        <div className="flex w-full flex-col items-start justify-center space-y-6">
                            {transaction.isProcessing && (
                                <div className="flex w-full flex-col items-center justify-center space-y-4">
                                    <ProcessingPaymentIcon className="h-[72px] w-[72px]" />
                                    <div className="flex flex-col items-center justify-center space-y-4">
                                        <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">Card is being funded....</h2>
                                        <p className="text-center text-base text-black-secondary">
                                            You are funding your Business Prepaid card with <Money amount={transaction.amount} />
                                        </p>
                                    </div>
                                </div>
                            )}
                            {transaction.isSuccess && (
                                <div className="flex w-full flex-col items-center justify-center space-y-4">
                                    <SuccessfulPayment className="h-[72px] w-[72px]" />
                                    <div className="flex flex-col items-center justify-center space-y-4">
                                        <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">Card Funded!</h2>
                                        <p className="text-center text-base text-black-secondary">
                                            Your Business Prepaid card has been funded with <Money amount={transaction.amount} />
                                        </p>
                                    </div>
                                </div>
                            )}
                            {transaction.isPendingApproval && (
                                <div className="flex w-full flex-col items-center justify-center space-y-4">
                                    <SuccessfulPayment className="h-[72px] w-[72px]" />
                                    <div className="flex flex-col items-center justify-center space-y-4">
                                        <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">
                                            Card Funding has been sent for Approval!
                                        </h2>
                                        <p className="text-center text-base text-black-secondary">
                                            You have initiated a funding your Business Prepaid card with <Money amount={transaction.amount} />
                                        </p>
                                    </div>
                                </div>
                            )}
                            {transaction.isFailed && (
                                <div className="flex w-full flex-col items-center justify-center space-y-4">
                                    <FailedPayment className="h-[72px] w-[72px]" />
                                    <div className="flex flex-col items-center justify-center space-y-4">
                                        <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">Card funding failed!</h2>
                                        <p className="text-center text-base text-black-secondary">
                                            {transaction.failedReason || "Something went wrong, please try again or review payment"}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {accountsToActivate && accountsToActivate.length > 0 && (
                                <div className="cursor-pointer" onClick={() => setShowActiveAccountListModal(true)}>
                                    <MessageToast
                                        message={
                                            <div className="flex items-center justify-between">
                                                <p>Active account for payout</p>
                                                <div className="ml-4">
                                                    <GoogleIcon icon="keyboard_arrow_up" className="rotate-90" />
                                                </div>
                                            </div>
                                        }
                                        type="info"
                                    />
                                </div>
                            )}
                            <div className="flex w-full flex-col items-center justify-center space-y-5 rounded-lg bg-white p-6 shadow-box">
                                <h3 className="text-base font-medium text-black">Categorize Transaction</h3>
                                <div className="grid w-full grid-cols-3 gap-4">
                                    <CategorizeCard
                                        icon={OperationalIcon}
                                        text="Operational"
                                        isActive={otherCategory === "Operational"}
                                        isLoading={otherCategory === "Operational" && isCategorySubmitting}
                                        onClick={() => handleSelectPresetCategories("Operational")}
                                    />
                                    <CategorizeCard
                                        icon={MarketingIcon}
                                        text="Marketing"
                                        isActive={otherCategory === "Marketing"}
                                        isLoading={otherCategory === "Marketing" && isCategorySubmitting}
                                        onClick={() => handleSelectPresetCategories("Marketing")}
                                    />
                                    <CategorizeCard
                                        icon={UtilitiesIcon}
                                        text="Utilities"
                                        isActive={otherCategory === "Utilities"}
                                        isLoading={otherCategory === "Utilities" && isCategorySubmitting}
                                        onClick={() => handleSelectPresetCategories("Utilities")}
                                    />
                                    <CategorizeCard
                                        icon={EntertainmentIcon}
                                        text="Entertainment"
                                        isActive={otherCategory === "Entertainment"}
                                        isLoading={otherCategory === "Entertainment" && isCategorySubmitting}
                                        onClick={() => handleSelectPresetCategories("Entertainment")}
                                    />
                                    <CategorizeCard
                                        icon={MiscellaneousIcon}
                                        text="Miscellaneous"
                                        isActive={otherCategory === "Miscellaneous"}
                                        isLoading={otherCategory === "Miscellaneous" && isCategorySubmitting}
                                        onClick={() => handleSelectPresetCategories("Miscellaneous")}
                                    />
                                    <CategorizeCard
                                        icon={OthersIcon}
                                        text="Others"
                                        isActive={isOtherCategory}
                                        onClick={() => {
                                            setOtherCategory("");
                                            setIsOtherCategory(true);
                                        }}
                                    />
                                </div>
                                {isOtherCategory && (
                                    <SendableDropdown
                                        label="Select or Create a Category"
                                        data={categoriesOptions}
                                        value={otherCategory || ""}
                                        isLoading={isCategorySubmitting}
                                        inputValue={transaction.category?.name || ""}
                                        createFunc={(name) => void handleSetNewTransactionCategory(transaction, name)}
                                        cancelFunc={() => void handleSetTransactionCategory(transaction, "")}
                                        changeValue={(_value) => setOtherCategory(_value)}
                                        onClickFunc={(option) => {
                                            setOtherCategory(option.name);
                                            void handleSetTransactionCategory(transaction, option.id);
                                        }}
                                        isActive
                                        clickAndClose
                                    />
                                )}
                            </div>
                            <div className="flex w-full items-center justify-center space-x-10 rounded-lg bg-white p-6">
                                <NewTransactionIcon icon="comment" text="comment" size="md" onClick={() => setTransactionCommentModal(true)} />
                                <NewTransactionIcon
                                    icon="attach_file"
                                    text="Attach"
                                    size="md"
                                    isLoading={isUploadingReceipt}
                                    onClick={() => onTargetClick()}
                                />
                                <NewTransactionIcon
                                    icon="description"
                                    text="Details"
                                    size="md"
                                    onClick={() => dispatch(showTransactionDetails(transaction?.id || ""))}
                                />
                                {!transaction.isPendingApproval && (
                                    <NewTransactionIcon
                                        icon="download"
                                        text="download"
                                        size="md"
                                        isLoading={isDownloading}
                                        onClick={() => void handleDownloadLencoReceipt(transaction)}
                                    />
                                )}
                                {transaction.isSuccess && !transaction.isPendingApproval && transaction?.destination?.customerAccount && (
                                    <NewTransactionIcon icon="share" text="share" size="md" onClick={() => setShowTagRecipientModal(true)} />
                                )}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    className="hidden"
                                    onChange={(event) => {
                                        if (event.target.files && event.target.files.length > 0) {
                                            if (event.target.files[0].size > 5242880) {
                                                return setIsFileSizeExceeded(true);
                                            }
                                            setIsFileSizeExceeded(false);
                                            onSelectFile(event.target.files[0]);
                                        }
                                    }}
                                    accept="image/jpeg, image/png, application/pdf, .pdf"
                                />
                            </div>
                            {isFileSizeExceeded && <ErrorToast error="File exceeded 5MB" />}
                        </div>
                        <div className="flex w-full flex-row items-center justify-center space-x-4">
                            <ButtonComp
                                size="xl"
                                type="button"
                                color="grey"
                                ripple="light"
                                buttonType="secondary"
                                func={() => {
                                    dispatch(resetAllSendMoneyData());
                                    dispatch(resetAllFundCardData());
                                    dispatch(setPaymentStage(PaymentStageType.INITIAL));
                                }}
                            >
                                Make another payment
                            </ButtonComp>

                            <ButtonComp
                                size="xl"
                                type="button"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                func={() => {
                                    if (pageFrom && payTo) {
                                        navigate(
                                            {
                                                pathname: `/${pageFrom}`,
                                            },
                                            {
                                                state: {
                                                    cardId: payTo,
                                                },
                                            }
                                        );
                                    } else {
                                        navigate({
                                            pathname: Routes.DASHBOARD,
                                        });
                                    }
                                    dispatch(resetAllSendMoneyData());
                                    dispatch(resetAllFundCardData());
                                }}
                            >
                                Done
                            </ButtonComp>
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
        </>
    );
}

export default ProcessingPayment;
