import { Dispatch, SetStateAction, useState } from "react";

import { IRootState } from "../../../../../../redux/rootReducer";
import UserGroup from "../../../../../../models/userGroup";
import { useSelector } from "react-redux";

export interface UseUserGroupsInterface {
    userGroups: UserGroup[];
    selectedUserGroup: UserGroup | null;
    setSelectedUserGroup: Dispatch<SetStateAction<UserGroup | null>>;
}

function useUserGroups(): UseUserGroupsInterface {
    const userGroups = useSelector((state: IRootState) =>
        (state.init.main?.companyDetails.userGroups.filter((el) => !el.isDeleted) || []).sort((u1: UserGroup, u2: UserGroup) =>
            u1.name.toLowerCase().localeCompare(u2.name.toLowerCase())
        )
    );
    const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup | null>(null);

    return {
        userGroups,
        selectedUserGroup,
        setSelectedUserGroup,
    };
}

export default useUserGroups;
