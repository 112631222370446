import React, { useLayoutEffect, useState } from "react";

import ColorSelector from "./Tray/Components/ColorSelectors";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import ImageUpload from "./Tray/Components/ImageUpload";
import LencoButton from "../../../../../../../../components/button/button";
import useDownloadZambiaApiAccessKeyLogo from "../../../../../hookss/ApiAccessKeySettings/useDownloadZambiaApiAccessKeyLogo";
import useRemoveZambiaApiAccessKeyLogo from "../../../../../hookss/ApiAccessKeySettings/useRemoveZambiaApiAccessKeyLogo";
import { useSelector } from "react-redux";
import useUpdateZambiaApiAccessKeyWidgetColorTheme from "../../../../../hookss/ApiAccessKeySettings/useUpdateZambiaApiAccessKeyWidgetColorTheme";
import useUploadZambiaApiAccessKeyLogo from "../../../../../hookss/ApiAccessKeySettings/useUploadZambiaApiAccessKeyLogo";

function Widgets(): JSX.Element {
    const selectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.selectedApiAccessKey);

    const { isUpdateZambiaApiAccessKeyWidgetColorThemeLoading, handleUpdateZambiaApiAccessKeyWidgetColorTheme } =
        useUpdateZambiaApiAccessKeyWidgetColorTheme();

    const { isRemoveZambiaApiAccessKeyLogoLoading, handleRemoveZambiaApiAccessKeyLogo } = useRemoveZambiaApiAccessKeyLogo();

    const { isUploadZambiaApiAccessKeyLogoLoading, handleUploadZambiaApiAccessKeyLogo } = useUploadZambiaApiAccessKeyLogo();

    const { zambiaApiAccessKeyLogo, isDownloadZambiaApiAccessKeyLogoLoading, handleReset, handleDownloadZambiaApiAccessKeyLogo } =
        useDownloadZambiaApiAccessKeyLogo();

    const [color, setColor] = useState<string>("");

    useLayoutEffect(() => {
        if (selectedApiAccessKey?.hasUploadedLogo) {
            if (!zambiaApiAccessKeyLogo) {
                handleDownloadZambiaApiAccessKeyLogo({
                    id: selectedApiAccessKey?.id || "",
                });
            }
        } else {
            handleReset();
        }
    }, [selectedApiAccessKey]);

    return (
        <>
            {selectedApiAccessKey && (
                <>
                    <div className="w-full space-y-4 rounded-lg bg-white px-4 py-4">
                        <div className="space-y-1">
                            <p className="text-base font-medium">Branding</p>
                            <p className="text-sm text-black-tertiary">Upload your business logo and choose your preferred widget colour.</p>
                        </div>
                        <ImageUpload
                            logo={zambiaApiAccessKeyLogo ? URL.createObjectURL(zambiaApiAccessKeyLogo) : ""}
                            isRemoving={isRemoveZambiaApiAccessKeyLogoLoading}
                            isUploading={isUploadZambiaApiAccessKeyLogoLoading}
                            isImgLoading={isDownloadZambiaApiAccessKeyLogoLoading}
                            isDisabled={!selectedApiAccessKey.isAccessible}
                            handleUploadImage={(_file: File) =>
                                handleUploadZambiaApiAccessKeyLogo({
                                    id: selectedApiAccessKey?.id || "",
                                    file: _file,
                                })
                            }
                            handleRemoveImage={() =>
                                handleRemoveZambiaApiAccessKeyLogo({
                                    id: selectedApiAccessKey?.id || "",
                                })
                            }
                        />

                        <div className="w-full border-b border-grey-secondary"></div>
                        <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 2xs:grid-cols-2 2xs:justify-items-end">
                            <div className="w-full">
                                <h5 className="text-base font-medium capitalize text-black">Brand Color</h5>
                            </div>
                            <div className="w-full max-w-md space-y-0.5 rounded-md bg-grey-backdrop p-2 px-3">
                                <ColorSelector
                                    selectedColorCode={color}
                                    handleSelectColor={(_value) => setColor(_value)}
                                    isDisabled={!selectedApiAccessKey.isAccessible}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 flex w-full flex-col items-center justify-center 2xs:flex-row 2xs:space-x-4">
                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                            <LencoButton
                                type="submit"
                                variant="solid"
                                color="danger"
                                isLoading={isUpdateZambiaApiAccessKeyWidgetColorThemeLoading}
                                onClick={() =>
                                    handleUpdateZambiaApiAccessKeyWidgetColorTheme({
                                        id: selectedApiAccessKey?.id || "",
                                        colorTheme: color,
                                    })
                                }
                                isDisabled={
                                    !color ||
                                    !selectedApiAccessKey ||
                                    !selectedApiAccessKey.isAccessible ||
                                    selectedApiAccessKey.customThemeColor === color
                                }
                                fullWidth
                            >
                                <span>Save Changes</span>
                            </LencoButton>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Widgets;
