import React from "react";
import titleCase from "../../hooks/titleCase";
import SkeletonElement from "../skeleton/SkeletonElement";

interface Props {
    isLoading?: boolean;
    text: string;
    sub_text: string;
}

export default function Header({ isLoading, text, sub_text }: Props) {
    return (
        <>
            {!isLoading && (
                <div>
                    <h1 className="text-2xl font-medium text-black">{titleCase(text || "")}</h1>
                    <p className="max-w-sm overflow-hidden overflow-ellipsis text-sm text-black-tertiary">{sub_text || ""}</p>
                </div>
            )}

            {isLoading && (
                <div>
                    <div className="mb-1.5 h-6 w-72">
                        <SkeletonElement box />
                    </div>
                    <div className="mb-1.5 h-4 w-40">
                        <SkeletonElement box />
                    </div>
                </div>
            )}
        </>
    );
}
