import Currency from "../../../../../../models/currency";
import CurrencyCode from "../../../../../../components/currency-code";
import { IRootState } from "../../../../../../redux/rootReducer";
import React from "react";
import RemoveFromAccount from "./RemoveFromAccount";
import TeamMemberNotificationChannels from "./notification-channel/TeamMemberNotificationChannels";
import TeamMemberNotificationType from "./TeamMemberNotificationType";
import TeamMemberRole from "./TeamMemberRole";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import formatNumber from "../../../../../../utils/formatNumber";
import { useSelector } from "react-redux";

function SectionTitle(props: { title: string; className?: string }): JSX.Element {
    return <h1 className={`w-full text-xs text-black sm:hidden ${props.className || ""}`}>{props.title}:</h1>;
}

interface TeamMemberDetailsAccountRowProps {
    isSelf: boolean;
    isLoading?: boolean;
    accountMeta: UserAccountMeta;
    userAccountNames: Map<string, string>;

    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    onRemoveFromTeam: (memberId: string) => void;
    removeAccountMetaFromTeamMember: (accountMetaToRemove: UserAccountMeta) => void;
}

function TeamMemberDetailsAccountRow({
    isSelf,
    accountMeta,
    userAccountNames,

    updateAccountMeta,
    onRemoveFromTeam,
    removeAccountMetaFromTeamMember,
}: TeamMemberDetailsAccountRowProps): JSX.Element {
    const currency = useSelector<IRootState, Currency | null | undefined>((state) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);

    return (
        <>
            {accountMeta && (
                <tr className="border-b border-grey-secondary">
                    <td className="px-4 align-baseline">
                        <div className="flex h-full w-full flex-grow flex-col items-start justify-start space-y-2 border-grey-secondary pb-2 pt-4 sm:space-y-0 sm:border-none">
                            <SectionTitle title="Account" />
                            <div className="flex w-full flex-col items-stretch justify-between">
                                <span className="text-sm capitalize text-black-secondary">
                                    {userAccountNames.get(accountMeta.userAccountId)?.toLowerCase() || ""}
                                </span>
                                {(accountMeta.maxApprovalAmount || accountMeta.minApprovalAmount) && (
                                    <>
                                        <p className="-mt-0.5 block pb-2 pt-4 text-xs text-black-tertiary">
                                            <span>Approval Limit</span>
                                            <span className="block">
                                                <CurrencyCode currency={currency} />
                                                {formatNumber(accountMeta.maxApprovalAmount)}
                                            </span>
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </td>

                    <td className="px-4 align-baseline">
                        <div className="flex h-full w-full flex-col space-y-2 border-b-0.2 border-grey-secondary pb-2 pt-4 sm:space-y-0 sm:border-none">
                            <SectionTitle title="Role" />
                            <TeamMemberRole accountMeta={accountMeta} updateAccountMeta={updateAccountMeta} canUpdate={!accountMeta.isAdmin} />
                        </div>
                    </td>

                    <td className="px-4 align-baseline">
                        <div className="item-start flex h-full w-full flex-col justify-start border-b-0.2 border-grey-secondary pb-2 pt-4 sm:border-none">
                            <SectionTitle title="Notification Rule" className="mb-2 mt-2 sm:mb-0 sm:mt-0" />

                            <div className="flex w-full flex-wrap items-center space-x-2 pb-2.5">
                                {/* <div className="flex w-full items-center space-x-2"> */}
                                <div className="flex">
                                    <TeamMemberNotificationType
                                        accountMeta={accountMeta}
                                        updateAccountMeta={updateAccountMeta}
                                        canUpdate={!accountMeta.isAdmin}
                                    />
                                    {/* <TeamMemberNotificationChannels
                                        accountMeta={accountMeta}
                                        updateAccountMeta={updateAccountMeta}
                                        canUpdate={!accountMeta.isAdmin || isSelf}
                                    /> */}
                                </div>
                                <div>
                                    <TeamMemberNotificationChannels
                                        accountMeta={accountMeta}
                                        updateAccountMeta={updateAccountMeta}
                                        canUpdate={!accountMeta.isAdmin || isSelf}
                                    />
                                </div>
                            </div>
                        </div>
                    </td>

                    <td className="px-4 align-baseline">
                        <div className="flex items-start justify-start sm:w-2/4 sm:items-center sm:justify-center">
                            {!accountMeta.isAdmin && (
                                <RemoveFromAccount
                                    accountMeta={accountMeta}
                                    removeAccountMetaFromTeamMember={removeAccountMetaFromTeamMember}
                                    onRemoveFromTeam={onRemoveFromTeam}
                                />
                            )}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

export default TeamMemberDetailsAccountRow;
