import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import Failed from "../../../../../../../../assets/svg/dashboard/add-money/mobile-money/failed.svg";
import LencoSpinner from "../../../../../../../../components/spinner";
import Success from "../../../../../../../../assets/svg/dashboard/add-money/mobile-money/success.svg";
import Waiting from "../../../../../../../../assets/svg/dashboard/add-money/mobile-money/waiting.svg";
import { useEffect } from "react";
import useMobileMoneyStatus from "../../../../../../Accounts/Hooks/State/Zambia/MobileMoney/useMobileMoneyStatus";

interface Props {
    keyId: string;
    handleClose: () => void;
    handleUpdateState: () => void;
}

function MobileMoneyProcessPayment(props: Props): JSX.Element {
    const { transaction } = useMobileMoneyStatus(props.keyId);

    useEffect(() => {
        if (transaction?.isSuccess || transaction?.isFailed) props.handleUpdateState();
    }, [transaction]);

    return (
        <>
            {transaction ? (
                <div className="w-full">
                    <div className="flex w-full flex-col items-center justify-center space-y-4">
                        <img src={transaction?.isSuccess ? Success : transaction?.isFailed ? Failed : Waiting} alt="process status" />
                        <div className="flex flex-col items-center justify-center space-y-4">
                            <h2 className="max-w-64 text-center text-2xl font-medium text-black-secondary">
                                {transaction?.isSuccess ? "Payment Successful" : transaction?.isFailed ? "Payment Failed" : "Phone Number Verified"}
                            </h2>
                            <p className="text-center text-base text-black-secondary">
                                {transaction?.isSuccess
                                    ? "Your payment has been received successfully"
                                    : transaction?.isFailed
                                      ? transaction.failedReason
                                      : "Check your device to authorize payment"}{" "}
                            </p>
                        </div>
                    </div>
                    <div className="mx-auto w-max pt-9">
                        <ButtonComp color="black" ripple="light" buttonType="primary" disable={transaction?.isProcessing} func={props.handleClose}>
                            Done
                        </ButtonComp>
                    </div>
                </div>
            ) : (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner />
                </div>
            )}
        </>
    );
}

export default MobileMoneyProcessPayment;
