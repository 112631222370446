import React from "react";

interface Props {
    text: string;
    type: "error" | "warning" | "info" | "success";
}

function ApplicationStatusTag(props: Props): JSX.Element {
    return (
        <>
            <div
                className={
                    `w-max rounded-[4px] px-2 py-1 text-sm font-medium capitalize ` +
                    `${props.type === "error" ? "border border-error-quin bg-error-backdrop text-error" : ""} ` +
                    `${props.type === "info" ? "border border-info-quin bg-info-backdrop text-info" : ""} ` +
                    `${props.type === "success" ? "border border-success-quin bg-success-backdrop text-success" : ""} ` +
                    `${props.type === "warning" ? "border border-warning-quin bg-warning-backdrop text-warning" : ""} `
                }
            >
                {props.text}
            </div>
        </>
    );
}

export default ApplicationStatusTag;
