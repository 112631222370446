import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import UserApp from "../../../../../../../models/userApp";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { mainUpdateUserAuthApp } from "../../../../../../../redux/init/slice/initSlice";
import { unlinkTokenApp } from "../token-app.api";

export interface UseUnlinkTokenAppResponse {
    isLinked: boolean;
    deviceName: string | null;
    handleUnlinkTokenApp: () => Promise<void>;
    isUnlinking: boolean;
}

function useUnlinkTokenApp(): UseUnlinkTokenAppResponse {
    const isLinked = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.hasTokenApp || false);
    const deviceName = useSelector<IRootState, string | null>((state) => state.init.main?.companyDetails.user.authApp.device || null);

    const dispatch = useDispatch();

    const [isUnlinking, setIsUnlinking] = useState<boolean>(false);

    const handleUnlinkTokenApp = useCallback(async () => {
        setIsUnlinking(true);

        try {
            await unlinkTokenApp();
            dispatch(mainUpdateUserAuthApp(UserApp.createDefault()));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsUnlinking(false);
        }
    }, [dispatch]);

    return {
        isLinked,
        deviceName,
        handleUnlinkTokenApp,
        isUnlinking,
    };
}

export default useUnlinkTokenApp;
