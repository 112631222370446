import { LoginInviteRequest, LoginInviteResponse } from "../services/login-api.types";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { inviteLogin } from "../services/login-api";
import { loginSuccess } from "../../../../redux/init/slice/initSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    onComplete: (_isAuthAppSetup: boolean) => void;
}

function useLoginInvite(props: Props): UseMutationResult<LoginInviteResponse, AxiosError | null, LoginInviteRequest, unknown> {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const otpInviteLogin = useMutation({
        mutationFn: async (_data: LoginInviteRequest) => await inviteLogin(_data),
        onSuccess(data) {
            if (data.otp) {
                props.onComplete(data.isAuthAppSetup);
            }
            if (!data.otp) {
                dispatch(loginSuccess());
                navigate("/");
            }
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return otpInviteLogin;
}

export default useLoginInvite;
