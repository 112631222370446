import ButtonComp from "../../../../../components/button/ButtonComp";
import Input from "../../../../../components/inputs/Input";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import Transaction from "../../../../../models/transaction";
import { useEffect } from "react";
import useTransactionDetailsCommentForm from "../Cards/TransactionDetailCard/Hooks/useTransactionDetailsCommentForm";

interface Props {
    active: boolean;
    transaction: Transaction;

    toggler(): void;
}

function TransactionCommentModal(props: Props): JSX.Element {
    const { isSubmitting, newCommentText, setNewCommentText, handleAddTransactionComment } = useTransactionDetailsCommentForm({
        onComplete: () => props.toggler(),
    });

    useEffect(() => {
        if (props.active) return;
        setNewCommentText("");
    }, [props.active]);

    return (
        <>
            <Modal size="md" active={props.active} toggler={() => props.toggler()}>
                <ModalHeader onClose={() => props.toggler()} subTitle="Add a note to this transaction">
                    Comment
                </ModalHeader>
                <ModalBody>
                    <Input label="Comment" value={newCommentText} onChange={(_value) => setNewCommentText(_value)} />
                </ModalBody>
                <ModalFooter>
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={() => props.toggler()} disable={isSubmitting}>
                        <span>Cancel</span>
                    </ButtonComp>

                    <ButtonComp
                        type="button"
                        color="black"
                        ripple="light"
                        disable={newCommentText.length === 0}
                        isLoading={isSubmitting}
                        buttonType="primary"
                        func={() => void handleAddTransactionComment(props.transaction, newCommentText)}
                    >
                        <span>Add Comment</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default TransactionCommentModal;
