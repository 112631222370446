import {
    CurrentSignatoryPersonnelRequest,
    IdentificationRequest,
    NewCurrentSignatoryResponse,
    NextOfKinForm,
    PhotoRequest,
    SignatoryPersonalDetailsForm,
    SignatureRequest,
} from "./currentSignatory.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../../../helpers/request/abortControllers";
import { makeRequestDownloadFile, makeRequestUploadFileWithSignal, makeRequestWithSignal } from "../../../../../../../helpers/request/makeRequest";

import { GenericObject } from "../../../../../../../helpers/types";
import NewAccountOpeningProgress from "../../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../../../utils/parsers";
import ZambiaSignatoryInfo from "../../../../../../../models/account-opening/zambiaSignatoryInfo";
import { toFormData } from "../../../../../../../utils/converters";

export enum CurrentSignatoryRequests {
    INIT = "current-signatory.init",
    IS_DIRECTOR_UPDATE = "current-signatory.is-director-update",
    BIO_UPDATE = "current-signatory.bio-info",
    NEXT_OF_KIN_UPDATE = "current-signatory.next-of-kin",
    IDENTIFICATION_UPLOAD = "current-signatory.id-upload",
    IDENTIFICATION_DELETE = "current-signatory.id-delete",
    PHOTO_UPLOAD = "current-signatory.photo-upload",
    PHOTO_DELETE = "current-signatory.photo-delete",
    DOCUMENT_SIGN = "current-signatory.doc-sign",
    APPEND_SIGNATURE = "current-signatory.append-sign",
    DOWNLOAD_TO_SIGN = "current-signatory.download-sign",
    DOCUMENT_UPLOAD = "current-signatory.doc-upload",
    DOCUMENT_DELETE = "current-signatory.doc-delete",
}

function getNewSignatoryInfoResponse(res: GenericObject | Error): NewCurrentSignatoryResponse {
    if (res instanceof Error) {
        throw res;
    }
    return {
        signatory: Parsers.classObject(res.signatory, ZambiaSignatoryInfo),
        progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
    };
}

export const currentSignatory = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.INIT);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/details", {}, signal);
    return getNewSignatoryInfoResponse(res);
};

export const isDirectorUpdate = async (_data: CurrentSignatoryPersonnelRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.IS_DIRECTOR_UPDATE);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/is-director/update", _data, signal);
    return getNewSignatoryInfoResponse(res);
};
export const bioUpdate = async (_data: SignatoryPersonalDetailsForm): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.BIO_UPDATE);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/bio/update", _data, signal);
    return getNewSignatoryInfoResponse(res);
};
export const nextOfKinUpdate = async (_data: NextOfKinForm): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.NEXT_OF_KIN_UPDATE);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/next-of-kin/update", _data, signal);
    return getNewSignatoryInfoResponse(res);
};
export const identificationUpload = async (_data: IdentificationRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.IDENTIFICATION_UPLOAD);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/personal/identification/upload", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const identificationDelete = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.IDENTIFICATION_DELETE);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/identification/delete", {}, signal);
    return getNewSignatoryInfoResponse(res);
};
export const photoUpload = async (_data: PhotoRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.PHOTO_UPLOAD);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/personal/photo/upload", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const photoDelete = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.PHOTO_DELETE);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/photo/delete", {}, signal);
    return getNewSignatoryInfoResponse(res);
};
export const documentSign = async (_data: SignatureRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOCUMENT_SIGN);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/personal/document/sign", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const documentAppendSignature = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.APPEND_SIGNATURE);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/document/append-signature", {}, signal);
    return getNewSignatoryInfoResponse(res);
};
export const downloadToSign = async (): Promise<Blob> => {
    // const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOWNLOAD_TO_SIGN);
    const res = await makeRequestDownloadFile("/account-opening/zm/personal/document/download-to-sign");
    if (res instanceof Error) {
        throw res;
    }
    return res;
};
export const documentUpload = async (_data: SignatureRequest): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOCUMENT_UPLOAD);
    const res = await makeRequestUploadFileWithSignal("/account-opening/zm/personal/document/upload", toFormData(_data), signal);
    return getNewSignatoryInfoResponse(res);
};
export const documentDelete = async (): Promise<NewCurrentSignatoryResponse> => {
    const signal = getAbortControllerSignal(CurrentSignatoryRequests.DOCUMENT_DELETE);
    const res = await makeRequestWithSignal("/account-opening/zm/personal/document/delete", {}, signal);
    return getNewSignatoryInfoResponse(res);
};

export function abortCurrentSignatoryRequest(type: CurrentSignatoryRequests): void {
    abortRequest(type);
}

export function abortAllCurrentSignatoryRequests(): void {
    Object.values(CurrentSignatoryRequests).forEach((type) => abortRequest(type));
}
