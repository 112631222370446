import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../redux/rootReducer";
import MakePayment from "./TransferBetweenAccounts/MakePayment";
import PaymentDetails from "./TransferBetweenAccounts/PaymentDetails";
import { PaymentStageType } from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import ProcessingPayment from "./TransferBetweenAccounts/ProcessingPayment";
import ReviewPayment from "./TransferBetweenAccounts/ReviewPayment";
import { resetAllTransferBetweenData } from "../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";

function TransferBetweenAccounts(): JSX.Element {
    const dispatch = useDispatch();
    const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);

    useEffect(() => {
        return () => {
            dispatch(resetAllTransferBetweenData());
        };
    }, []);

    return (
        <>
            {paymentStage === PaymentStageType.PAYMENT_DETAILS && <PaymentDetails />}
            {paymentStage === PaymentStageType.REVIEW_PAYMENT && <ReviewPayment />}
            {paymentStage === PaymentStageType.MAKE_PAYMENT && <MakePayment />}
            {paymentStage === PaymentStageType.PROCESS_PAYMENT && <ProcessingPayment />}
        </>
    );
}

export default TransferBetweenAccounts;
