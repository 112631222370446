import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import ApplicationHeader from "../../../../../dashboard/applicationn/components/element/ApplicationHeader";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import DirectorNextOfKinReview from "./components/DirectorNextOfKinReview";
import DirectorPersonalDetailsReview from "./components/DirectorPersonalDetailsReview";
import ErrorToast from "../../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../../redux/rootReducer";
import { InviteType } from "../../service/externalApplication.types";
import LencoSpinner from "../../../../../../components/spinner";
import PreviewCard from "../../../../../dashboard/applicationn/components/cards/PreviewCard";
import PreviewModal from "../../../../../dashboard/applicationn/components/modals/preview-modal";
import { Routes } from "../../../../../../routes/routes.constants";
import useExternalApplicationInit from "../../hookss/useExternalApplicationInit";
import useExternalDirectorPreview from "../../hookss/useExternalDirectorPreview";
import { useSelector } from "react-redux";

type DirectorUrlParams = {
    a: string;
    b: string;
};

function DirectorReview(): JSX.Element {
    const navigate = useNavigate();
    const { a, b } = useParams<DirectorUrlParams>();

    const { previewBlob, handlePreviewDocument } = useExternalDirectorPreview({
        onComplete: () => setShowPreviewModal(true),
    });

    const { externalApplicationInitError, isExternalApplicationInitLoading, handleExternalApplicationInit } = useExternalApplicationInit();

    const url = useSelector((state: IRootState) => state.externalApplication.externalLink || "");
    const externalParams = useSelector((state: IRootState) => state.externalApplication.externalParams);
    const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

    const director = externalApplicationInit?.director || null;

    const divRef = useRef<HTMLDivElement | null>(null);

    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (a && b) {
            void handleExternalApplicationInit({
                a,
                b,
                type: InviteType.S,
            });
        }
    }, [a, b]);

    return (
        <>
            <PreviewModal active={showPreviewModal} toggler={() => setShowPreviewModal(false)} data={previewBlob} title="Preview" />

            {isExternalApplicationInitLoading && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <LencoSpinner />
                </div>
            )}
            {externalApplicationInitError && !isExternalApplicationInitLoading && (
                <div className="flex h-full w-full items-center justify-center px-4">
                    <ErrorToast error={externalApplicationInitError} />
                </div>
            )}
            {!isExternalApplicationInitLoading && !externalApplicationInitError && externalApplicationInit && director && (
                <div className="flex w-full flex-row items-start justify-start space-x-6 pb-28">
                    <div className="w-full lg:min-w-60% lg:max-w-3xl">
                        <ApplicationHeader
                            header="Review your Application"
                            subTitle="Below is the summary of the information you provided. You can make any necessary changes as you complete your application. "
                            progress={100}
                        >
                            <div className="relative flex w-full flex-col items-start justify-start space-y-8">
                                <div className="flex w-full flex-col items-center justify-start space-y-4">
                                    <div className="flex w-full flex-row items-start justify-between">
                                        <h3 className="font-medium text-black-secondary">Personal Details</h3>
                                        <ButtonComp
                                            type="button"
                                            color="blue"
                                            ripple="light"
                                            buttonType="tertiary"
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname: `${url}${Routes.EXTERNAL_DIRECTOR.PERSONAL_DETAILS.DETAILS}`,
                                                    },
                                                    {
                                                        state: {
                                                            from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
                                                        },
                                                    }
                                                )
                                            }
                                            size="sm"
                                        >
                                            <span>Change</span>
                                        </ButtonComp>
                                    </div>
                                    <DirectorPersonalDetailsReview data={director || undefined} options={externalApplicationInit.options} />
                                </div>

                                <div className="flex w-full flex-col items-center justify-start space-y-4">
                                    <div className="flex w-full flex-row items-start justify-between">
                                        <h3 className="font-medium text-black-secondary">Next of Kin</h3>
                                        <ButtonComp
                                            type="button"
                                            color="blue"
                                            ripple="light"
                                            buttonType="tertiary"
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname: `${url}${Routes.EXTERNAL_DIRECTOR.NEXT_OF_KIN}`,
                                                    },
                                                    {
                                                        state: {
                                                            from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
                                                        },
                                                    }
                                                )
                                            }
                                            size="sm"
                                        >
                                            <span>Change</span>
                                        </ButtonComp>
                                    </div>
                                    <DirectorNextOfKinReview data={director?.nextOfKin || null} options={externalApplicationInit.options} />
                                </div>

                                <div className="flex w-full flex-col items-center justify-start space-y-4">
                                    <div className="flex w-full flex-row items-start justify-between">
                                        <h3 className="font-medium text-black-secondary">Means of ID</h3>
                                        <ButtonComp
                                            type="button"
                                            color="blue"
                                            ripple="light"
                                            buttonType="tertiary"
                                            func={() =>
                                                navigate(
                                                    {
                                                        pathname: `${url}${Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID}`,
                                                    },
                                                    {
                                                        state: {
                                                            from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
                                                        },
                                                    }
                                                )
                                            }
                                            size="sm"
                                        >
                                            <span>Change</span>
                                        </ButtonComp>
                                    </div>
                                    <PreviewCard
                                        icon={<span className="material-symbols-rounded text-xl text-black-tertiary">add_notes</span>}
                                        text={
                                            externalApplicationInit?.options.idType?.find((_el) => _el.id === director?.identification?.type)?.name ||
                                            "-"
                                        }
                                        canNotPreview={!director.identification?.fileId}
                                        handlePreviewDocumentFunc={() => externalParams && void handlePreviewDocument(externalParams)}
                                        // handlePreviewDocumentFunc={() => handlePreviewDocument(director.identification?.fileId || "")}
                                    />
                                </div>
                            </div>
                        </ApplicationHeader>

                        <div className="flex w-full flex-col items-center justify-center pt-6 2xs:flex-row 2xs:space-x-4">
                            <div className="order-2 w-full 2xs:order-1 2xs:w-max">
                                <ButtonComp
                                    type="button"
                                    color="grey"
                                    ripple="light"
                                    buttonType="secondary"
                                    func={() => navigate(`${url}${Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID}`)}
                                    fullWidth
                                >
                                    <span>Back</span>
                                </ButtonComp>
                            </div>
                            <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                <ButtonComp
                                    type="button"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    func={() =>
                                        navigate(
                                            {
                                                pathname: `${url}${Routes.EXTERNAL_DIRECTOR.DONE}`,
                                            },
                                            {
                                                state: {
                                                    from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
                                                },
                                            }
                                        )
                                    }
                                    fullWidth
                                >
                                    <span>Submit Application</span>
                                </ButtonComp>
                            </div>
                        </div>
                    </div>
                    <div className="fixed bottom-16 right-16 z-40">
                        <ButtonComp
                            color="white"
                            buttonType="flat"
                            size="sm"
                            fullBorder
                            func={() => {
                                divRef.current?.scrollIntoView({ behavior: "smooth" });
                            }}
                        >
                            <div className="flex max-w-[20px] items-center justify-center overflow-hidden">
                                <span className="material-symbols-rounded">keyboard_double_arrow_down</span>
                            </div>
                            <span className="ml-2 text-sm font-medium normal-case text-blue">Jump to end</span>
                        </ButtonComp>
                    </div>
                </div>
            )}
        </>
    );
}

export default DirectorReview;
