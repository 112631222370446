import BillProduct from "../../../../../models/billProduct";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import { useCallback } from "react";

interface Props {
    data: BillProduct;
    onSelect: (e: BillProduct) => void;
}

function ServicePackageItem({ data, onSelect }: Props): JSX.Element {
    const handleSelect = useCallback(() => {
        onSelect(data);
    }, [data]);

    return (
        <NewDropdownItem onClick={handleSelect} key={data.id} size="xl">
            <div className="flex h-10 w-full items-center justify-start space-x-4">
                <div>
                    <div className={`flex h-8 w-8 items-center justify-center overflow-hidden rounded-full`}>
                        <img className="h-8 w-8 rounded-full object-cover" src={data.vendor?.logoUrl as string} alt="ISP" />
                    </div>
                </div>
                <div className="flex h-full w-full flex-col items-start justify-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <span className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-sm">{data.name}</span>
                </div>
            </div>
        </NewDropdownItem>
    );
}

export default ServicePackageItem;
