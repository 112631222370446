import {
    AccountSignatoriesRequests,
    abortAccountSignatoriesRequest,
    setSignatoryAsDirector,
} from "../../../../../services/zambia-application/registered/new/accountSignatories.api";
import { useCallback, useState } from "react";

import { SignatoryActionRequest } from "../../../../../services/zambia-application/registered/new/accountSignatories.types";
import { errorTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../../redux/app-toast/app-toast-slice";
import { setZambiaAccountSignatories } from "../../../../../../../../redux/application/zambiaApplicationSlice";
import { useDispatch } from "react-redux";

interface useSignatoriesInterface {
    isSetAsDirectorLoading: boolean | null;

    handleReset: () => void;
    handleSetAsDirector: (data: SignatoryActionRequest) => Promise<void>;
}

const useSetAsDirector = (): useSignatoriesInterface => {
    const dispatch = useDispatch();

    const [isSetAsDirectorLoading, setIsSetAsDirectorLoading] = useState<boolean | null>(false);

    const handleSetAsDirector = useCallback(async (_data: SignatoryActionRequest) => {
        try {
            setIsSetAsDirectorLoading(true);
            const res = await setSignatoryAsDirector(_data);
            dispatch(setZambiaAccountSignatories(res));
            dispatch(messageTrue({ message: `Signatory updated successfully` }));
        } catch (err) {
            dispatch(errorTrue({ message: getErrorMessage(err) }));
        } finally {
            setIsSetAsDirectorLoading(false);
        }
    }, []);

    const handleReset = useCallback(() => {
        setIsSetAsDirectorLoading(false);
        abortAccountSignatoriesRequest(AccountSignatoriesRequests.SET_SIGNATORY_AS_DIRECTOR);
    }, []);

    return {
        isSetAsDirectorLoading,

        handleReset,
        handleSetAsDirector,
    };
};
export default useSetAsDirector;
