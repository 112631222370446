import { CreateSubAccountResponse, createSubAccountRequest } from "../../Services/accounts-api.types";
import {
    addPendingAccount,
    resetAllCreateSubAccountData,
    resetSubAccountToCreate,
    resetSubAccountsToBeActivated,
    setSubAccountLoadingFalse,
} from "../../../../../redux/accounts/slice/accountsSlice";
import { useCallback, useState } from "react";

import { CreateAccountObj } from "../../../../../redux/accounts/slice/accountsSlice.types";
import { createSubAccount } from "../../Services/accounts-api";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

export interface UseCreateSubAccountInterface {
    createSubAccountError: string;
    createSubAccountResponse: CreateSubAccountResponse | null;
    isCreateSubAccountLoading: boolean;

    handleResetAll: () => void;
    handleClearError: () => void;
    handleCreateSubAccount: (_data: createSubAccountRequest) => Promise<void>;
}

function useCreateSubAccount(): UseCreateSubAccountInterface {
    const dispatch = useDispatch();

    const [isCreateSubAccountLoading, setIsCreateSubAccountLoading] = useState<boolean>(false);
    const [createSubAccountResponse, setCreateSubAccountResponse] = useState<CreateSubAccountResponse | null>(null);
    const [createSubAccountError, setCreateSubAccountError] = useState<string>("");

    const handleCreateSubAccount = useCallback(
        async (_data: createSubAccountRequest | CreateAccountObj) => {
            try {
                setCreateSubAccountError("");
                setIsCreateSubAccountLoading(true);
                const res = await createSubAccount({ name: _data.name, description: _data.description });
                setCreateSubAccountResponse(res);
                dispatch(addPendingAccount(res.pendingAccount));
                if ((_data as CreateAccountObj).index) {
                    dispatch(setSubAccountLoadingFalse((_data as CreateAccountObj).index));
                }
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setCreateSubAccountError(errorMessage);
            } finally {
                setIsCreateSubAccountLoading(false);
            }
        },
        [dispatch]
    );

    const handleClearError = useCallback(() => {
        setCreateSubAccountError("");
    }, []);

    const handleResetAll = useCallback(() => {
        setCreateSubAccountError("");
        setIsCreateSubAccountLoading(false);
        dispatch(resetSubAccountToCreate());
        dispatch(resetSubAccountsToBeActivated());
        dispatch(resetAllCreateSubAccountData());
    }, []);

    return {
        createSubAccountError,
        createSubAccountResponse,
        isCreateSubAccountLoading,

        handleResetAll,
        handleClearError,
        handleCreateSubAccount,
    };
}

export default useCreateSubAccount;
