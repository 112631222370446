import { setSingleTransaction, updatePendingTransaction } from "../../../../../../redux/transaction/slice/transactionSlice";
import { useCallback, useState } from "react";

import { DeclinePendingPaymentRequest } from "../../../services/send-money/singleTransferApi.types";
import { DeclineReason } from "./usePendingPayments.types";
import { declinePendingPayment } from "../../../services/pending-approval/pendingPayments";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export interface UseDeclinePendingPaymentInterface {
    isApprove: boolean;
    declineReason: DeclineReason | null;
    declineTransactionError: string | null;
    isDeclineTransactionLoading: boolean;

    handleIsApprove: () => void;
    handleDeclineTransaction: (_data: DeclinePendingPaymentRequest) => Promise<void>;
}

interface Props {
    onComplete?: () => void;
}

const UseDeclinePendingPayment = ({ onComplete }: Props): UseDeclinePendingPaymentInterface => {
    const dispatch = useDispatch();

    const [isApprove, setIsApprove] = useState<boolean>(false);
    const [declineReason, setDeclineReason] = useState<DeclineReason | null>(null);

    const [declineTransactionError, setDeclineTransactionError] = useState<string | null>("");
    const [isDeclineTransactionLoading, setIsDeclineTransactionLoading] = useState<boolean>(false);

    const handleIsApprove = () => {
        setIsApprove(true);
        setDeclineReason(null);
    };

    const handleDeclineTransaction = useCallback(
        async (_data: DeclinePendingPaymentRequest) => {
            try {
                setDeclineReason(_data.reason);
                setDeclineTransactionError(null);
                setIsApprove(false);

                setIsDeclineTransactionLoading(true);
                const res = await declinePendingPayment(_data);
                dispatch(messageTrue({ message: "Transfer declined successfully" }));
                dispatch(setSingleTransaction(res.transaction));
                dispatch(
                    updatePendingTransaction([
                        {
                            id: _data.transactionId,
                            approve: false,
                        },
                    ])
                );
                onComplete && onComplete();
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setDeclineTransactionError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsDeclineTransactionLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isApprove,
        declineReason,
        declineTransactionError,
        isDeclineTransactionLoading,
        handleIsApprove,
        handleDeclineTransaction,
    };
};

export default UseDeclinePendingPayment;
