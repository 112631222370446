import { AccountOpeningStatus, BusinessCategory } from "./accountOpening.constants";

import ApplicationIndustry from "./applicationIndustry";
import { GenericObject } from "../../helpers/types";
import { LencoActiveCountries } from "../../redux/init/slice/initSlice.types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class BusinessDetailsMin {
    [immerable] = true;

    constructor(
        public id: string,
        public name: string | undefined,
        public isRegistered: boolean | null,
        public industry: ApplicationIndustry | null,
        public registrationCategory: BusinessCategory | null,
        public status: AccountOpeningStatus,
        public requestedProAccount: boolean,
        public country: LencoActiveCountries | null
    ) {}

    static create(obj: GenericObject): BusinessDetailsMin {
        return new BusinessDetailsMin(
            Parsers.string(obj.id),
            obj.name === null ? undefined : Parsers.string(obj.name),
            Parsers.nullableBoolean(obj.isRegistered),
            Parsers.classObject(obj.industry, ApplicationIndustry),
            Parsers.nullableNumber(obj.registrationCategory),
            Parsers.number(obj.status),
            Parsers.boolean(obj.requestedProAccount),
            Parsers.nullableEnum(obj.country, LencoActiveCountries)
        );
    }

    get isLLC(): boolean {
        return this.registrationCategory === BusinessCategory.LLC;
    }
    get isPartnership(): boolean {
        return this.registrationCategory === BusinessCategory.PARTNERSHIP;
    }
    get isSoleProprietorship(): boolean {
        return this.registrationCategory === BusinessCategory.SOLE_PROPRIETORSHIP;
    }
    get isZambia(): boolean {
        return this.country === LencoActiveCountries.ZAMBIA;
    }
    get isNigeria(): boolean {
        return this.country === LencoActiveCountries.NIGERIA;
    }
}
