import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { removeTeamMember } from "../team-members.api";
import { useDispatch } from "react-redux";

export interface useRemoveTeamMemberResponse {
    isRemoving: boolean;
    handleRemoveTeamMember: (id: string) => Promise<void>;
}

function useRemoveTeamMember(onRemove: (id: string) => void): useRemoveTeamMemberResponse {
    const dispatch = useDispatch();

    const [isRemoving, setIsRemoving] = useState(false);

    const handleRemoveTeamMember = useCallback(async (id: string) => {
        setIsRemoving(true);

        try {
            await removeTeamMember(id);
            onRemove(id);
            dispatch(messageTrue("Team member removed"));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsRemoving(false);
        }
    }, []);

    return {
        isRemoving,
        handleRemoveTeamMember,
    };
}

export default useRemoveTeamMember;
