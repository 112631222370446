import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";
import ZambiaCollectionRequest from "../../../../../models/zambia/zambiaCollectionRequest";
import Parsers from "../../../../../utils/parsers";
import {
    ExportZambiaCollectionsTransactionsRequest,
    ExportZambiaCollectionsTransactionsResponse,
    ExportZambiaCollectionsTransactionsStatusRequest,
    ExportZambiaCollectionsTransactionsStatusResponse,
    ZambiaCollectionsTransactionsRequest,
    ZambiaCollectionsTransactionsResponse,
} from "./zambiaCollectionsTransactionsApi.types";

export enum ZambiaCollectionTransactionsRequestType {
    REQUEST_LIST = "zambia-collection-transactions.request-list",
    EXPORT = "zambia-collection-transactions.export",
}

export const zambiaCollectionsTransactions = async (_data: ZambiaCollectionsTransactionsRequest): Promise<ZambiaCollectionsTransactionsResponse> => {
    const signal = getAbortControllerSignal(ZambiaCollectionTransactionsRequestType.REQUEST_LIST);
    const res = await makeRequestWithSignal("/main/lenco-pay/collections/list", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        total: Parsers.number(res.total),
        groupSize: Parsers.number(res.groupSize),
        collections: Parsers.classObjectArray(res.collections, ZambiaCollectionRequest),
    };
};

export const exportZambiaCollectionsTransactions = async (
    _data: ExportZambiaCollectionsTransactionsRequest
): Promise<ExportZambiaCollectionsTransactionsResponse> => {
    const signal = getAbortControllerSignal(ZambiaCollectionTransactionsRequestType.EXPORT);
    const res = await makeRequestWithSignal("/main/lenco-pay/collections/export", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        eventName: Parsers.string(res.eventName),
        key: Parsers.string(res.key),
    };
};

export const exportZambiaCollectionsTransactionsStatus = async (
    _data: ExportZambiaCollectionsTransactionsStatusRequest
): Promise<ExportZambiaCollectionsTransactionsStatusResponse> => {
    const signal = getAbortControllerSignal(ZambiaCollectionTransactionsRequestType.EXPORT);
    const res = await makeRequestWithSignal("/main/lenco-pay/collections/export/status", _data, signal);
    if (res instanceof Error) {
        throw res;
    }

    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        eventName: Parsers.string(res.eventName),
        key: Parsers.string(res.key),
    };
};

export function abortZambiaCollectionsTransactionsRequest(type: ZambiaCollectionTransactionsRequestType): void {
    abortRequest(type);
}

export function abortAllZambiaCollectionsTransactionsRequests(): void {
    Object.values(ZambiaCollectionTransactionsRequestType).forEach((type) => abortRequest(type));
}
