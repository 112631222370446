import {
    ApprovePendingPaymentRequest,
    ApprovePendingPaymentResponse,
    BulkCancelPendingPaymentRequest,
    BulkCancelProcessPendingPaymentResponse,
    BulkProcessPendingPaymentRequest,
    BulkProcessPendingPaymentResponse,
    CancelPendingPaymentRequest,
    CancelPendingPaymentResponse,
    DeclinePendingPaymentRequest,
    DeclinePendingPaymentResponse,
} from "../send-money/singleTransferApi.types";
import { PendingPaymentListsRequest, PendingPaymentListsResponse } from "./pendingPayments.types";
import { abortRequest, getAbortControllerSignal } from "../../../../../helpers/request/abortControllers";

import { GenericObject } from "../../../../../helpers/types";
import Parsers from "../../../../../utils/parsers";
import Transaction from "../../../../../models/transaction";
import { makeRequestWithSignal } from "../../../../../helpers/request/makeRequest";

export enum PendingPaymentsRequestType {
    CANCEL_PAYMENT = "pending-payment.cancel-payment",
    DECLINE_PAYMENT = "pending-payment.decline-payment",
    APPROVE_PAYMENT = "pending-payment.approve-payment",
    CANCEL_BULK_PAYMENTS = "pending-payment.cancel-bulk-payments",
    PENDING_PAYMENT_LIST = "pending-payment.pending-payments",
    PROCESS_BULK_PAYMENTS = "pending-payment.process-bulk-payments",
}

export const pendingPaymentLists = async (data: PendingPaymentListsRequest): Promise<PendingPaymentListsResponse> => {
    const signal = getAbortControllerSignal(PendingPaymentsRequestType.PENDING_PAYMENT_LIST);

    const res = await makeRequestWithSignal("/main/payments/pending", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        transactions: Parsers.classObjectArray(res.transactions, Transaction),
        groupSize: Parsers.number(res.groupSize),
        total: Parsers.number(res.total),
    };
};

export const declinePendingPayment = async (data: DeclinePendingPaymentRequest): Promise<DeclinePendingPaymentResponse> => {
    const signal = getAbortControllerSignal(PendingPaymentsRequestType.DECLINE_PAYMENT);

    const res = await makeRequestWithSignal("/main/payments/pending/decline", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
    };
};

export const cancelPendingPayment = async (data: CancelPendingPaymentRequest): Promise<CancelPendingPaymentResponse> => {
    const signal = getAbortControllerSignal(PendingPaymentsRequestType.CANCEL_PAYMENT);

    const res = await makeRequestWithSignal("/main/payments/pending/cancel", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
    };
};

export const approvePendingPayment = async (data: ApprovePendingPaymentRequest): Promise<ApprovePendingPaymentResponse> => {
    const signal = getAbortControllerSignal(PendingPaymentsRequestType.APPROVE_PAYMENT);

    const res = await makeRequestWithSignal("/main/payments/pending/approve", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
        processing: Parsers.boolean(res.processing),
        accountsToActivate: !res.accountToActivate ? [] : (res.accountToActivate as GenericObject[]).map((item) => Parsers.string(item)),
    };
};

export const bulkProcessPendingPayment = async (data: BulkProcessPendingPaymentRequest): Promise<BulkProcessPendingPaymentResponse> => {
    const signal = getAbortControllerSignal(PendingPaymentsRequestType.PROCESS_BULK_PAYMENTS);

    const res = await makeRequestWithSignal("/main/payments/pending/process-bulk", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        accountsToActivate: Parsers.stringArray(res.accountsToActivate),
    };
};

export const bulkCancelPendingPayment = async (data: BulkCancelPendingPaymentRequest): Promise<BulkCancelProcessPendingPaymentResponse> => {
    const signal = getAbortControllerSignal(PendingPaymentsRequestType.CANCEL_BULK_PAYMENTS);

    const res = await makeRequestWithSignal("/main/payments/pending/bulk/cancel", data, signal);
    if (res instanceof Error) {
        throw res;
    }
    return {
        success: Parsers.boolean(res.success),
        message: Parsers.string(res.message),
        transactions: Parsers.classObjectArray(res.transactions, Transaction),
    };
};

export function abortPendingPaymentRequest(type: PendingPaymentsRequestType): void {
    abortRequest(type);
}

export function abortAllPendingPaymentRequests(): void {
    Object.values(PendingPaymentsRequestType).forEach((type) => abortRequest(type));
}
