import { CardRequestType, abortCardRequest, cardInformation } from "../../Services/cardsApi";
import { useCallback, useState } from "react";

import CardInformation from "../../../../../models/cardInformation";
import { CardInformationRequest } from "../../Services/cardsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { useDispatch } from "react-redux";

interface UseCardInformation {
    cardInformationResponse: CardInformation | null;
    cardInformationError: string | null;
    isCardInformationLoading: boolean;
    handleReset: () => void;
    handleCardInformation: (_data: CardInformationRequest) => Promise<void>;
}

interface Props {
    onComplete: () => void;
}
function useCardInformation(props: Props): UseCardInformation {
    const dispatch = useDispatch();
    const [cardInformationResponse, setCardInformationResponse] = useState<CardInformation | null>(null);
    const [cardInformationError, setCardInformationError] = useState<string | null>(null);
    const [isCardInformationLoading, setIsCardInformationLoading] = useState<boolean>(false);

    const handleCardInformation = useCallback(
        async (_data: CardInformationRequest) => {
            try {
                setCardInformationResponse(null);
                setCardInformationError(null);
                setIsCardInformationLoading(true);
                const res = await cardInformation(_data);
                setCardInformationResponse(res.info);
                props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setCardInformationError(errorMessage);
            } finally {
                setIsCardInformationLoading(false);
            }
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        setCardInformationResponse(null);
        setCardInformationError(null);
        setIsCardInformationLoading(false);
        abortCardRequest(CardRequestType.CARD_INFORMATION);
    }, []);

    return {
        cardInformationError,
        cardInformationResponse,
        isCardInformationLoading,

        handleReset,
        handleCardInformation,
    };
}

export default useCardInformation;
