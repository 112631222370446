import { AxiosError } from "axios";
import MessageToast from "..";
import { getAxiosErrorMessage } from "../../../utils/getErrorMessage";
import { ReactNode } from "react";

interface Props {
    error: ReactNode | AxiosError | string | null;
    isReactNode?: boolean;
    fullWidth?: boolean;
}

function ErrorToast(props: Props) {
    return (
        <MessageToast
            message={
                props.isReactNode ? (props.error as ReactNode) : getAxiosErrorMessage(props.error)
                // isValidElement(props.error)
                // typeof props.error !== "string" && !props.error ? (props.error as ReactNode) : props.error ? "" : getAxiosErrorMessage(props.error)
            }
            type="error"
            fullWidth={props.fullWidth}
        />
    );
}

export default ErrorToast;
