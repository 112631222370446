import {
    resetTransferDetailRecipientBankAccount,
    setCanVerifyRecipientAccountDetails,
    setTransferDetailCustomerAccountRecipient,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BankAccount from "../../../../../../../models/bankAccount";
import { IRootState } from "../../../../../../../redux/rootReducer";
import { RequestCancelledError } from "../../../../../../../helpers/request/requestErrors";
import { VerifyAccountRequest } from "../../../../services/send-money/singleTransferApi.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { verifyAccount } from "../../../../services/send-money/singleTransferApi";

export interface UseBulkTransferVerifyRecipientAccountDetailsInterface {
    isVerifyAccountLoading: boolean;
    verifyAccountError: { message: string } | null;
    handleSetVerifiedAccount: (_bankAccount: BankAccount) => void;
    handleResetVerifiedAccount: () => void;
}

function useBulkTransferVerifyRecipientAccountDetails(): UseBulkTransferVerifyRecipientAccountDetailsInterface {
    const dispatch = useDispatch();
    const transferDetail = useSelector((state: IRootState) =>
        state.bulkTransfer.isUpdatingTransferDetail ? state.bulkTransfer.tempTransferDetail : state.bulkTransfer.transferDetail
    );
    const canVerifyRecipientAccountDetails = useSelector((state: IRootState) => state.bulkTransfer.canVerifyRecipientAccountDetails);
    const selectedUserAccount = useSelector((state: IRootState) =>
        state.bulkTransfer.isUpdatingTransferDetail
            ? state.bulkTransfer.tempTransferDetail?.selectedUserAccount
            : state.bulkTransfer.transferDetail.selectedUserAccount
    );
    const [isVerifyAccountLoading, setIsVerifyAccountLoading] = useState(false);
    const [verifyAccountError, setVerifyAccountError] = useState<{ message: string } | null>(null);

    useEffect(() => {
        if (!selectedUserAccount) return;
        if (selectedUserAccount.accountRestrictions.canSendMoneyToSpecificAccounts) {
            setVerifyAccountError(null);
            if (canVerifyRecipientAccountDetails) {
                dispatch(resetTransferDetailRecipientBankAccount());
            }
        }
    }, [selectedUserAccount]);

    useEffect(() => {
        if (
            !canVerifyRecipientAccountDetails ||
            !transferDetail ||
            !transferDetail.recipient.externalAccount ||
            transferDetail.recipient.externalAccount.bankCode.length < 2 ||
            transferDetail.recipient.externalAccount.accountNumber.length < 10
        )
            return;
        void handleVerifyAccount({
            bankCode: transferDetail.recipient.externalAccount.bankCode,
            accountNumber: transferDetail.recipient.externalAccount.accountNumber,
        });
    }, [canVerifyRecipientAccountDetails, transferDetail?.recipient.externalAccount]);

    const handleVerifyAccount = useCallback(
        async (_data: VerifyAccountRequest) => {
            try {
                dispatch(resetTransferDetailRecipientBankAccount());
                setVerifyAccountError(null);
                setIsVerifyAccountLoading(true);

                const res = await verifyAccount(_data);
                // dispatch(setTransferDetailRecipient(res.bankAccount));
                if (res.bankAccount) {
                    dispatch(setCanVerifyRecipientAccountDetails(false));
                    dispatch(setTransferDetailCustomerAccountRecipient(res.bankAccount));
                }
                setVerifyAccountError(null);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                dispatch(resetTransferDetailRecipientBankAccount());

                setVerifyAccountError({
                    message: errorMessage,
                });
            }
            setIsVerifyAccountLoading(false); // set outside catch block, because finally will ignore the return in catch block
        },
        [dispatch]
    );

    const handleSetVerifiedAccount = useCallback((_bankAccount: BankAccount) => {
        // dispatch(setTransferDetailRecipient(_bankAccount));
        dispatch(setTransferDetailCustomerAccountRecipient(_bankAccount));
    }, []);

    const handleResetVerifiedAccount = useCallback(() => {
        dispatch(resetTransferDetailRecipientBankAccount());
    }, []);

    return {
        isVerifyAccountLoading,
        verifyAccountError,
        handleSetVerifiedAccount,
        handleResetVerifiedAccount,
    };
}

export default useBulkTransferVerifyRecipientAccountDetails;
