import { Form, Formik } from "formik";

import ButtonComp from "../../../../../../../components/button/ButtonComp";
import DownloadTokenApp from "./DownloadTokenApp";
import FormInput from "../../../../../../../components/inputs/FormInput";
import ModalBody from "../../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../../components/modal/modal-footer";
import React from "react";
import ScanCode from "./ScanCode";
import useTestCodeForm from "../../../../Services/security/token-app/hooks/useTestCodeForm";

interface TestCodeFormProps {
    closeFunc: () => void;
}

function TestCodeForm({ closeFunc }: TestCodeFormProps): JSX.Element {
    const { isSubmitting, initialFormState, formValidation, handleTestCode, formikRef } = useTestCodeForm(closeFunc);

    return (
        <>
            <Formik
                initialValues={initialFormState}
                innerRef={formikRef}
                validationSchema={formValidation}
                onSubmit={(values) => void handleTestCode(values)}
                enableReinitialize
            >
                {(formik) => {
                    return (
                        <Form autoComplete="off" className="w-full">
                            <ModalBody>
                                <div className="mb-14 space-y-7">
                                    <DownloadTokenApp />
                                    <ScanCode />
                                    <div className="space-y-6">
                                        <div className="flex flex-col items-start justify-start space-y-3">
                                            <span className="text-sm font-semibold text-black-secondary">Step 3</span>
                                            <span className="text-sm font-normal text-black-secondary">
                                                Enter the OTP from the app and confirm setup
                                            </span>
                                        </div>

                                        <div className="w-full">
                                            <div className="flex flex-col items-start justify-start space-y-4 text-base font-normal md:items-center md:px-0 lg:items-center lg:px-0">
                                                <div className="flex w-full flex-col">
                                                    <FormInput
                                                        type="number"
                                                        placeholder="Enter OTP"
                                                        customStyle="h-14 shadow-none"
                                                        name="code"
                                                        minLength={6}
                                                        maxLength={6}
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <div className="flex w-full items-center justify-end space-x-4 pb-6">
                                    <ButtonComp
                                        type="button"
                                        ripple="light"
                                        buttonType="secondary"
                                        color="grey"
                                        func={() => {
                                            closeFunc();
                                        }}
                                        fullWidth
                                    >
                                        <span className="">Cancel</span>
                                    </ButtonComp>
                                    <ButtonComp
                                        type="submit"
                                        color="black"
                                        ripple="light"
                                        buttonType="primary"
                                        isLoading={isSubmitting}
                                        disable={!!formik.errors.code}
                                        fullWidth
                                    >
                                        <span className="">Confirm Setup</span>
                                    </ButtonComp>
                                </div>
                            </ModalFooter>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default TestCodeForm;
