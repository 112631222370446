import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { AxiosError } from "axios";
import { BaseResponse } from "../../../../helpers/request/makeRequest";
import Errorhandler from "../../../../helpers/request/error-handler";
import { SetPasswordData } from "../services/email-verification.types";
import { setPassword } from "../services/email-verification.api";

interface Props {
    onComplete: () => void;
}

function useSetPassword(props: Props): UseMutationResult<BaseResponse, AxiosError | null, SetPasswordData, unknown> {
    const setPasswordMutation = useMutation({
        mutationFn: async (_data: SetPasswordData) => {
            const res = await setPassword(_data);
            return res;
        },
        onSuccess() {
            props.onComplete();
        },
        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return setPasswordMutation;
}

export default useSetPassword;
