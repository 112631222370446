import React, { ReactElement, useCallback, useEffect } from "react";
import { resetSelectedUserGroup, setPeopleStage } from "../../../../../redux/people/slice/peopleSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import { IRootState } from "../../../../../redux/rootReducer";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import { PeopleStageType } from "../../../../../redux/people/slice/peopleSlice.types";
import useDeleteUserGroup from "../../hooks/use-delete-user-group";

function DeleteUserGroup(): ReactElement {
    const { deleteUserGroupError, isDeleteUserGroupLoading, deleteUserGroupResponse, handleResetAll, handleDeleteUserGroup } = useDeleteUserGroup();
    // const {isDeleteUserGroupLoading, deleteUserGroupResponse, handleResetAll, handleDeleteUserGroup} = useDeleteUserGroup();

    const dispatch = useDispatch();
    const peopleStage = useSelector((state: IRootState) => state.people.peopleStage);
    const userGroupSelected = useSelector((state: IRootState) => state.people.userGroupSelected);

    useEffect(() => {
        if (!deleteUserGroupResponse) return;
        dispatch(setPeopleStage(PeopleStageType.CATEGORY_LIST));
        handleResetAll();
    }, [handleResetAll, deleteUserGroupResponse]);

    const handleToggle = useCallback(() => {
        dispatch(resetSelectedUserGroup());
        dispatch(setPeopleStage(PeopleStageType.INITIAL));
    }, []);

    const handleBack = useCallback(() => {
        dispatch(resetSelectedUserGroup());
        dispatch(setPeopleStage(PeopleStageType.CATEGORY_LIST));
    }, []);

    const handleDelete = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!userGroupSelected) return;
            void handleDeleteUserGroup({ userGroupId: userGroupSelected.id });
        },
        [userGroupSelected]
    );

    return (
        <>
            {userGroupSelected && (
                <Modal size="sm" active={peopleStage === PeopleStageType.DELETE_CATEGORY} toggler={handleToggle}>
                    <ModalHeader onClose={handleToggle}>Delete Group</ModalHeader>
                    <form className="w-full" onSubmit={handleDelete}>
                        <ModalBody>
                            <div className="flex w-full flex-col">
                                {deleteUserGroupError && (
                                    <div className="pb-4">
                                        <ErrorToast error={deleteUserGroupError} />
                                    </div>
                                )}
                                <div className="flex w-full flex-col items-start justify-start text-sm font-normal">
                                    {/* <Caution /> */}
                                    <div className="flex w-full flex-col items-start text-start">
                                        <span className="text-left text-base font-normal">
                                            {/*This category has recipients. Remove recipients.*/}
                                            Are you sure you want to delete{" "}
                                            <span className="m-auto max-w-xs break-words">{userGroupSelected ? userGroupSelected.name : ""} ?</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="lg" func={handleBack}>
                                <span className="">Back</span>
                            </ButtonComp>
                            <ButtonComp type="submit" color="red" ripple="light" buttonType="primary" size="lg" isLoading={isDeleteUserGroupLoading}>
                                <span>Delete</span>
                            </ButtonComp>
                        </ModalFooter>
                    </form>
                </Modal>
            )}
        </>
    );
}

export default DeleteUserGroup;
