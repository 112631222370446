import {
    resetSingleTransferRecipient,
    setSelectedUserAccount,
    setSelectedUserAccountMeta,
    setSingleTransferOriginatingAccount,
} from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import doesUserHaveAccessToAccount from "../../../../../../../helpers/doesUserHaveAccessToAccount";

interface useSingleTransferPayFromInterface {
    accounts: UserAccount[] | undefined;
    selectedAccountId: string;
    handleSelectAccount: (_accountId: string) => void;
}

function useSingleTransferPayFrom(): useSingleTransferPayFromInterface {
    const dispatch = useDispatch();
    const payFrom = useSelector((state: IRootState) => state.sendMoney.payFrom);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
    const originatingAccountId = useSelector((state: IRootState) => state.singleTransfer.singleTransferDetails.originatingAccountId);

    useEffect(() => {
        if (!accounts) return;
        if (accounts.length < 2) {
            if (doesUserHaveAccessToAccount(accounts[0].id)) {
                handleSelectAccount(accounts[0].id);
            }
        }
    }, [accounts]);

    useEffect(() => {
        if (!payFrom) return;
        handleSelectAccount(payFrom);
    }, [payFrom]);

    const handleSelectAccount = useCallback((_accountId: string) => {
        const doesAccountExist = accounts?.some((el) => el.id === _accountId);
        if (doesAccountExist) {
            const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
            const userAccountMeta = userAccountsMeta?.find((_it) => _it.userAccountId === userAccount.id);
            dispatch(setSingleTransferOriginatingAccount(_accountId));
            dispatch(setSelectedUserAccount(userAccount));
            dispatch(setSelectedUserAccountMeta(userAccountMeta || null));
            if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) dispatch(resetSingleTransferRecipient());
        }
    }, []);

    return {
        accounts,
        selectedAccountId: originatingAccountId,
        handleSelectAccount,
    };
}

export default useSingleTransferPayFrom;
