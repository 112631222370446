import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { VerifyLoginInviteRequest, VerifyLoginInviteResponse } from "../services/login-api.types";

import { AxiosError } from "axios";
import Errorhandler from "../../../../helpers/request/error-handler";
import { verifyInvite } from "../services/login-api";

function useVerifyLoginInvite(): UseMutationResult<VerifyLoginInviteResponse, AxiosError | null, VerifyLoginInviteRequest, unknown> {
    // const navigate = useNavigate();
    const verifyLogin = useMutation({
        mutationFn: async (_data: VerifyLoginInviteRequest) => {
            const res = await verifyInvite(_data);
            return res;
        },

        onError(error: AxiosError) {
            Errorhandler(error, true);
        },
    });

    return verifyLogin;
}

export default useVerifyLoginInvite;
