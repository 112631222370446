export enum TransactionType {
    DEBIT = 1,
    CREDIT = 2,
}

export enum TransactionStatus {
    SUCCESS = 1,
    FAILED = 2,
    PENDING_APPROVAL = 3,
    DECLINED = 4,
    CANCELLED = 5,
    PROCESSING = 6,
    PENDING_FRAUD_REVIEW = 7,
}

export enum TransactionMedium {
    BANK_TRANSFER = 1,
    USSD = 2,
    CARD_PAYMENT = 3,
    CASH_DEPOSIT = 4,
    CHEQUE = 5,
}

export enum ChargeType {
    VAT = 1,
    NIPSS_CHARGE = 2,
    NIP_AND_VAT = 3,
    STAMP_DUTY = 4,
    SMS_CHARGE = 5,
    ACCOUNT_MAINTENANCE = 6,
    API_FEE = 7,
}

export enum CardPaymentStatus {
    PENDING = 1,
    FUNDS_LOCKED = 2,
    SUCCESSFUL = 3,
    FAILED = 4,
    REVERSED = 5,
}
