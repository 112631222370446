import { ErrorMessage } from "./makeRequest";

export class UnauthorizedError extends Error {
    constructor() {
        super(ErrorMessage.UNAUTHORIZED_ERROR);
    }
}

export class RequestCancelledError extends Error {
    constructor() {
        super(ErrorMessage.AXIOS_CANCEL_ERROR);
    }
}

export class GenericBackendError extends Error {
    constructor() {
        super(ErrorMessage.BACKEND_GENERIC_ERROR);
    }
}

export class DuplicatePaymentError extends Error {
    constructor() {
        super(ErrorMessage.DUPLICATE_PAYMENT_ERROR);
    }
}
