import { FilterDateType, FilterDateTypes } from "../filter.constant";

import DateRows from "./DateRows";
import GenericFilterRow from "./GenericFilterRow";
import GoogleIcon from "../../google-icon";
import NewDropdownItem from "../../new-dropdown-item";

// import { useState } from "react";

interface Props<T extends { name?: string; text?: string; value: U }, U> {
    title: string;
    options?: T[];
    isRadio?: boolean;
    dataType?: string;
    filteredList?: U[];
    isHover?: boolean;
    onHover?: () => void;
    handleFilterState?: (_value: U) => void;

    date?: FilterDateTypes;
    isDate?: boolean;
    handleSetDate?: (_data: FilterDateType) => void;
    handleFilterDate?: () => void;
    handleOpenDateModal?: () => void;
}

function FilterRow<T extends { name?: string; text?: string; value: U }, U>(props: Props<T, U>): JSX.Element {
    return (
        <>
            <div className="w-full" onMouseEnter={props.onHover}>
                <NewDropdownItem size="md" isActive={props.isHover}>
                    <div className="flex w-full flex-row items-center justify-between">
                        <span className="text-sm font-medium">{props.title}</span>
                        <GoogleIcon icon="chevron_right" />
                    </div>
                </NewDropdownItem>

                <div className={`absolute left-full top-0 pl-[5px] ` + `${!props.isHover ? "hidden" : "flex"}`}>
                    <div className="w-56 flex-col overflow-hidden rounded-lg bg-white shadow-dropdown">
                        {props.isDate && props.date && props.handleSetDate && props.handleFilterDate && props.handleOpenDateModal && (
                            <DateRows
                                filterDate={props.date}
                                handleFilterDate={props.handleFilterDate}
                                handleSetFilterDate={props.handleSetDate}
                                handleOpenDateModal={props.handleOpenDateModal}
                            />
                        )}
                        {!props.isDate && props.options && props.filteredList && props.handleFilterState && (
                            <GenericFilterRow
                                name={props.title}
                                options={props.options}
                                dataType={props.dataType}
                                filteredList={props.filteredList}
                                handleFilterState={props.handleFilterState}
                                isRadio={props.isRadio}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterRow;
