import { GenericObject } from "../../helpers/types";
import Parsers from "../../utils/parsers";
import { immerable } from "immer";

export default class AccountFaqAnswerItem {
    [immerable] = true;

    constructor(
        public id: string,
        public text: string
    ) {}

    static create(obj: GenericObject): AccountFaqAnswerItem {
        return new AccountFaqAnswerItem(Parsers.string(obj.id), Parsers.string(obj.text));
    }
}
