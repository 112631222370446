import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { SendMoneyOptions, ZambiaSendMoneyOptions } from "./service/send-money-dropdown.constants";
import { closeAddFundsModal, openAddFundsModal } from "../../../../../redux/accounts/slice/accountsSlice";
import { dropdownCustomProps, dropdownMenuCustomProps, menuItemCustomClasses, menuItemCustomOnClick } from "../../../../dropdown/dropdown.types";
import { useEffect, useState } from "react";

import GoogleIcon from "../../../../google-icon";
import { SendMoneyOptionType } from "./service/send-money-dropdown.types";
import { getButtonClass } from "../../../../button/button.constant";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function SendMoneyDropdown(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const main = useAppSelector((state) => state.init.main);
    const isNigeria = useAppSelector((state) => state.init.main?.companyDetails?.company.isNigeria);

    const sendMoneyOptions = (isNigeria ? SendMoneyOptions : ZambiaSendMoneyOptions).filter((_option) =>
        (main?.companyDetails.accounts || []).length < 2 ? _option.type !== SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS : _option
    );

    const handleOpenAddFundsModal = () => {
        dispatch(openAddFundsModal());
    };

    useEffect(() => {
        return () => {
            dispatch(closeAddFundsModal());
        };
    }, []);

    return (
        <>
            <Dropdown {...dropdownCustomProps()} onOpenChange={(_isOpen) => setIsOpen(_isOpen)}>
                <DropdownTrigger>
                    <Button variant="solid" className={getButtonClass({ variant: "solid", buttonColor: "black", buttonSize: "md" })}>
                        <div className="flex w-full items-center justify-between space-x-2">
                            <p className="text-white">Send Money</p>
                            <GoogleIcon
                                icon="keyboard_arrow_up"
                                size="sm"
                                className={"!-mr-1 transform text-white transition-all " + `${isOpen ? "rotate-0" : "rotate-180"} `}
                            />
                        </div>
                    </Button>
                </DropdownTrigger>

                <DropdownMenu {...dropdownMenuCustomProps()}>
                    {sendMoneyOptions.map((_option, index) => {
                        return (
                            <DropdownItem
                                key={`${index}-${_option.text}`}
                                textValue={_option.text}
                                {...menuItemCustomClasses()}
                                {...menuItemCustomOnClick(() => _option.onClick && _option.onClick(navigate, handleOpenAddFundsModal))}
                            >
                                <div className="flex w-full items-center justify-start space-x-4">
                                    <div>
                                        <img className="h-10 w-10 min-w-[40px]" src={_option.icon} alt={_option.text} />
                                    </div>
                                    <div className="flex flex-col justify-start">
                                        <p className="text-sm font-medium text-black">{_option.text}</p>
                                        <p className="text-xs text-black-tertiary">{_option.subtext}</p>
                                    </div>
                                </div>
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default SendMoneyDropdown;
