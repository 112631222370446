import React from "react";
import TeamMemberDropdown from "../TeamMemberDropdown";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";
import useAddTeamMemberNotificationChannel from "../../../../Services/team-members/hooks/useAddTeamMemberNotificationChannel";

interface TeamMemberAddNotificationChannelProps {
    accountMeta: UserAccountMeta;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
}

function TeamMemberAddNotificationChannel({ accountMeta, updateAccountMeta }: TeamMemberAddNotificationChannelProps): JSX.Element {
    const { isSubmitting, options, handleAddChannel } = useAddTeamMemberNotificationChannel(updateAccountMeta);
    return (
        <>
            {options.length > 0 && options.some((_el) => !accountMeta.notificationChannels.includes(_el.value)) && (
                <TeamMemberDropdown
                    canUpdate
                    text={"Add Channel"}
                    isSubmitting={isSubmitting}
                    onDropdownItemSelect={(channel) => void handleAddChannel(accountMeta, channel)}
                    options={options.filter((option) => !accountMeta.notificationChannels.includes(option.value))}
                />
            )}
        </>
    );
}

export default TeamMemberAddNotificationChannel;
