import ButtonComp from "../../../../../../components/button/ButtonComp";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";

interface ChangePasswordSuccessProps {
    active: boolean;
    toggler: () => void;
}

function ChangePasswordSuccess({ active, toggler }: ChangePasswordSuccessProps): JSX.Element {
    return (
        <Modal size="sm" active={active} toggler={toggler}>
            <ModalHeader onClose={toggler}>
                <span>Update Successful</span>
            </ModalHeader>
            <ModalBody>
                <div className="flex w-full flex-col items-start justify-start text-left text-sm text-black-secondary">
                    Your password has been updated
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonComp type="submit" color="blue" ripple="light" buttonType="primary" func={() => toggler()}>
                    <span>Done</span>
                </ButtonComp>
            </ModalFooter>
        </Modal>
    );
}

export default ChangePasswordSuccess;
