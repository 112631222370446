export interface CreateCardProps {
    active: boolean;
    toggler: () => void;
}

export type CardAccessForm = {
    name: string;
    type: number;
    purpose: string;
    walletType: number;
    assignedToId: string;
    deliveryPhone: string;
    debitAccountId: string;
    linkedAccountId: string;
    cardholderPhone: string;
    cardholderEmail: string;
    deliveryAddress: string;
};

export type CardHolderDetailsForm = {
    // assignedToId: string;
    // cardholderEmail: string;
    cardholderPhone: string;
};

export enum CardUsageStage {
    USAGE_DESCRIPTION = 1,
    CUSTOMIZATION = 2,
    HOLDER = 3,
    DELIVERY_DETAILS = 4,
    CARD_REQUEST = 5,
}

export enum LencoCardAccessLevel {
    VIEWER = "viewer",
    MANAGER = "manager",
}

export const LencoCardAccessLevelText = { [LencoCardAccessLevel.VIEWER]: "View Only", [LencoCardAccessLevel.MANAGER]: "Manage" };

export const LencoCardAccessLevels = [LencoCardAccessLevel.VIEWER, LencoCardAccessLevel.MANAGER];

export interface ManageCardProps {
    active: boolean;
    toggler: () => void;
}

export enum CardAccessStage {
    RESET = 0,
    LIST_OF_TEAM_MEMBERS = 1,
    CREATE_ACCESS = 2,
    DONE = 3,
}

export type DateObj = {
    begin: string;
    end: string;
};
