import { ForgotPasswordVerifyResponseData, OtpRecipientItem } from "../../servicess/resetPasswordApi.types";
import { useCallback, useLayoutEffect, useState } from "react";
import useResetPasswordSendOtp, { UseResetPasswordSendOtpInterface } from "./useResetPasswordSendOtp";
import useResetPasswordSetNewPassword, { UseResetPasswordSetNewPasswordInterface } from "./useResetPasswordSetNewPassword";
import useResetPasswordVerifyLink, { UseResetPasswordVerifyLinkInterface } from "./useResetPasswordVerifyLink";
import useResetPasswordVerifyOtp, { UseResetPasswordVerifyOtpInterface } from "./useResetPasswordVerifyOtp";

import { useDispatch } from "react-redux";
import { useParams } from "react-router";

export type ResetForgotUrlParams = {
    a: string;
    b: string;
    c: string;
};

export enum ResetPasswordState {
    INITIALIZING,
    INIT_FAILED,
    SELECT_OTP_RECIPIENT,
    ENTER_OTP,
    ENTER_PASSWORD,
    COMPLETED,
}

export interface UseResetForgotPasswordStateInterface {
    resetPasswordState: ResetPasswordState;
    baseParams: ResetForgotUrlParams;
    otpRecipients: OtpRecipientItem[];
    selectedOtpRecipient: OtpRecipientItem | null;
    verifyLinkParams: UseResetPasswordVerifyLinkInterface;
    sendOtpParams: UseResetPasswordSendOtpInterface;
    verifyOtpParams: UseResetPasswordVerifyOtpInterface;
    setNewPasswordParams: UseResetPasswordSetNewPasswordInterface;
}

const useResetForgotPasswordState = (): UseResetForgotPasswordStateInterface => {
    const dispatch = useDispatch();

    const { a, b, c } = useParams<ResetForgotUrlParams>();

    const [resetPasswordState, setResetPasswordState] = useState<ResetPasswordState>(ResetPasswordState.INITIALIZING);
    const [baseParams, setBaseParams] = useState<ResetForgotUrlParams>({ a: "", b: "", c: "" });

    const [otpRecipients, setOtpRecipients] = useState<OtpRecipientItem[]>([]);
    const [selectedOtpRecipient, setSelectedOtpRecipient] = useState<OtpRecipientItem | null>(null);

    const handleInitResponse = useCallback(
        (data: ForgotPasswordVerifyResponseData): void => {
            if (!data.success) {
                setResetPasswordState(ResetPasswordState.INIT_FAILED);
            } else if (data.otpRequired) {
                setResetPasswordState(ResetPasswordState.SELECT_OTP_RECIPIENT);
                setOtpRecipients(data.otpRecipients);
            } else {
                setResetPasswordState(ResetPasswordState.ENTER_PASSWORD);
            }
        },
        [dispatch]
    );

    const onSendOtpSuccessCallback = useCallback(
        (otpRecipient: OtpRecipientItem): void => {
            setResetPasswordState(ResetPasswordState.ENTER_OTP);
            setSelectedOtpRecipient(otpRecipient);
        },
        [dispatch]
    );

    const onVerifyOtpSuccessCallback = useCallback((): void => {
        setResetPasswordState(ResetPasswordState.ENTER_PASSWORD);
    }, [dispatch]);

    const onSetNewPasswordSuccessCallback = useCallback((): void => {
        setResetPasswordState(ResetPasswordState.COMPLETED);
    }, [dispatch]);

    const verifyLinkParams = useResetPasswordVerifyLink(handleInitResponse);
    const sendOtpParams = useResetPasswordSendOtp(onSendOtpSuccessCallback);
    const verifyOtpParams = useResetPasswordVerifyOtp(onVerifyOtpSuccessCallback);
    const setNewPasswordParams = useResetPasswordSetNewPassword(onSetNewPasswordSuccessCallback);

    useLayoutEffect(() => {
        // check if params exists
        if (!a || !b || !c) return;
        setBaseParams({ a, b, c });
        void verifyLinkParams.handleInit({ a, b, c });
    }, [a, b, c]);

    return {
        resetPasswordState,
        baseParams,
        otpRecipients,
        selectedOtpRecipient,
        verifyLinkParams,
        sendOtpParams,
        verifyOtpParams,
        setNewPasswordParams,
    };
};

export default useResetForgotPasswordState;
